import React from "react"
import {
    TextField,
    Box,
    InputAdornment,
    IconButton,
    Typography,
    Button,
} from "@material-ui/core"

import ClearIcon from "@material-ui/icons/Clear"
import useAwaitTranslation from "../../../i18n/useAwaitTranslation"

const MinimumMaximum = (props: {
    config: any
    onChange: (field: string, value: any) => void
}) => {
    const { config, onChange } = props
    const { t } = useAwaitTranslation("criteria")
    const { minimum, maximum } = config

    return (
        <Box width="100%">
            <Box mb={0.5}>
                <Typography color="textSecondary">
                    {t("minimum", "Minimum")} - {t("maximum", "Maximum")}
                </Typography>
            </Box>
            <Box display="flex" width="100%">
                <Box pr={0.5} width="100%">
                    <TextField
                        onChange={(e) => {
                            if (Number(e.target.value) > 0) {
                                onChange("minimum", Number(e.target.value))
                            }
                        }}
                        size="small"
                        variant="outlined"
                        fullWidth
                        value={minimum}
                        type="number"
                    />
                </Box>
                <Box p={0.5}>
                    <Typography variant="h6" color="textSecondary">
                        -
                    </Typography>
                </Box>

                <Box pl={0.5} width="100%">
                    {!!maximum ? (
                        <TextField
                            size="small"
                            type="number"
                            onChange={(e) => {
                                onChange("maximum", Number(e.target.value))
                            }}
                            variant="outlined"
                            fullWidth
                            value={maximum}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            size="small"
                                            onClick={() => {
                                                onChange("slider", false)
                                                onChange("maximum", null)
                                            }}
                                        >
                                            <ClearIcon fontSize="small" />
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        />
                    ) : (
                        <Button
                            onClick={() => onChange("maximum", 10)}
                            style={{
                                width: "100%",
                                height: "100%",
                            }}
                            variant="outlined"
                        >
                            {t("clickToAddMaxiumum", "Click to add maximum")}
                        </Button>
                    )}
                </Box>
            </Box>
        </Box>
    )
}

export default MinimumMaximum
