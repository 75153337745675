import { Typography, Box } from "@material-ui/core"

const Header = (props: {
    title: string
    subtitle?: string
    actions?: React.ReactElement
}) => {
    const { title, subtitle, actions } = props
    return (
        <Box
            display="flex"
            overflow="hidden"
            alignItems="flex-start"
            flexShrink={0}
        >
            <Box p={2} overflow="hidden" width="100%">
                <Typography noWrap variant="h5">
                    {title}
                </Typography>

                {!!subtitle && (
                    <Box mt={1}>
                        <Typography color="textSecondary" variant="subtitle1">
                            {subtitle}
                        </Typography>
                    </Box>
                )}
            </Box>
            {!!actions && (
                <Box p={2} flexShrink={0}>
                    {actions}{" "}
                </Box>
            )}
        </Box>
    )
}

export default Header
