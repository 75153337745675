import { useState } from "react"
import { makeStyles } from "@material-ui/core/styles"
import { useMutation } from "@apollo/client"
import {
    ADD_TEAM_ADMIN,
    REMOVE_TEAM_ADMIN,
    REMOVE_TEAM_MEMBER,
} from "./graphql"
import {
    AddTeamAdminMutation,
    AddTeamAdminMutationVariables,
    RemoveTeamAdminMutation,
    RemoveTeamAdminMutationVariables,
    RemoveTeamMemberMutation,
    RemoveTeamMemberMutationVariables,
} from "./__generated__/graphql"
import { Team, User } from "../../__generated__/types"
import MenuItem from "@material-ui/core/MenuItem"
import { Select, IconButton, Link, CircularProgress } from "@material-ui/core"
import TableCell from "@material-ui/core/TableCell"
import TableRow from "@material-ui/core/TableRow"
import ClearIcon from "@material-ui/icons/Clear"
import { Link as LinkRouter } from "react-router-dom"
import { useAuth } from "../../providers/AuthProvider"
import useAwaitTranslation from "../../i18n/useAwaitTranslation"
import UserAvatar from "../users/UserAvatar"
const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
        padding: theme.spacing(1),
        height: "100%",
    },
    avatar: {
        minWidth: theme.spacing(4),
        minHeight: theme.spacing(4),
        margin: "auto",
    },
    name: {
        fontSize: theme.typography.h6.fontSize,
        fontWeight: theme.typography.fontWeightBold as any,
        letterSpacing: theme.typography.h6.letterSpacing,
        marginBottom: 0,
        marginTop: 0,
        display: "flex",
        justifyContent: "space-between",
    },
    label: {
        color: theme.palette.text.hint,
        fontSize: "0.8em",
        marginBottom: theme.spacing(0.5),
    },
    prop: {
        padding: theme.spacing(1),
    },
    iconContainer: {
        position: "relative",
        width: "100%",
    },
    input: {
        fontSize: theme.typography.body2.fontSize,
        lineHeight: theme.typography.body2.lineHeight,
        "&:hover": {
            backgroundColor: theme.palette.action.hover,
        },
        borderRadius: theme.shape.borderRadius,
        paddingLeft: theme.spacing(0.5),
    },
    actionHover: {
        "&:hover": {
            backgroundColor: theme.palette.action.hover,
        },
        borderRadius: theme.shape.borderRadius,
        paddingLeft: theme.spacing(0.5),
    },
}))

interface TeamMemberLineItemProps {
    team: Team
    user: User
}

const ROLE_VALUES = {
    Member: "Member",
    Admin: "Admin",
}
export default function TeamMemberLineItem(props: TeamMemberLineItemProps) {
    const classes = useStyles()
    const [hovered, setHovered] = useState(false)
    const [saving, setSaving] = useState(false)
    const [removing, setRemoving] = useState(false)
    const { currentUser } = useAuth()
    const { t } = useAwaitTranslation("teams")
    const editable =
        props.team.admin.filter((x) => {
            return x.userId === currentUser?.userId
        }).length > 0 || props.team.leader?.userId === currentUser?.userId
    const [removeMember] = useMutation<
        RemoveTeamMemberMutation,
        RemoveTeamMemberMutationVariables
    >(REMOVE_TEAM_MEMBER)
    const [addAdmin] = useMutation<
        AddTeamAdminMutation,
        AddTeamAdminMutationVariables
    >(ADD_TEAM_ADMIN)
    const [removeAdmin] = useMutation<
        RemoveTeamAdminMutation,
        RemoveTeamAdminMutationVariables
    >(REMOVE_TEAM_ADMIN)
    const admin =
        props.team.admin.filter((x) => {
            return x.userId === props.user.userId
        }).length > 0
    const handleRemoval = async () => {
        setRemoving(true)
        let promises = [
            removeMember({
                variables: {
                    fromId: props.user.userId,
                    toId: props.team.teamId,
                },
            }),
        ]
        if (admin) {
            promises.push(
                removeAdmin({
                    variables: {
                        fromId: props.user.userId,
                        toId: props.team.teamId,
                    },
                })
            )
        }
        await Promise.all(promises)
    }
    const handleChange = async () => {
        setSaving(true)
        if (admin) {
            await removeAdmin({
                variables: {
                    fromId: props.user.userId,
                    toId: props.team.teamId,
                },
            })
        } else {
            await addAdmin({
                variables: {
                    fromId: props.user.userId,
                    toId: props.team.teamId,
                },
            })
        }
        setSaving(false)
        setHovered(false)
    }
    const role =
        props.team.admin.filter((x) => {
            return x.userId === props.user.userId
        }).length > 0
            ? "Admin"
            : "Member"
    return (
        <TableRow
            onMouseOver={() => setHovered(true)}
            onMouseLeave={() => setHovered(false)}
            hover
        >
            <TableCell size="small">
                <UserAvatar user={props.user} size="large" variant="circle" />
            </TableCell>
            <TableCell size="small">
                <Link
                    component={LinkRouter}
                    to={`/user/${props.user?.username}/home`}
                >
                    {`${props.user.firstName} ${props.user.lastName}`}
                </Link>
            </TableCell>

            <TableCell>
                <Link href={`mailto: ${props.user.email}`}>
                    {props.user.email}
                </Link>
            </TableCell>
            <TableCell size="small">
                {editable ? (
                    <Select
                        value={role}
                        onChange={(
                            event: React.ChangeEvent<{ value: String }>
                        ) => {
                            if (event.target.value !== role) {
                                handleChange()
                            }
                        }}
                        inputProps={{
                            className: classes.input,
                        }}
                        disabled={saving}
                        disableUnderline
                    >
                        {Object.keys(ROLE_VALUES).map((item, idx) => {
                            return (
                                <MenuItem key={"USER" + item} value={item}>
                                    {t(item, item)}
                                </MenuItem>
                            )
                        })}
                    </Select>
                ) : (
                    role
                )}
            </TableCell>
            {!!editable && (
                <TableCell size="small">
                    {!!removing ? (
                        <CircularProgress size={20} />
                    ) : (
                        <IconButton
                            size="small"
                            style={{
                                visibility:
                                    hovered && !saving ? "visible" : "hidden",
                            }}
                            onClick={() => handleRemoval()}
                        >
                            <ClearIcon fontSize="small" />
                        </IconButton>
                    )}
                </TableCell>
            )}
        </TableRow>
    )
}
