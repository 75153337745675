import React, { useState, useEffect } from "react"
import { makeStyles, useTheme, Theme } from "@material-ui/core/styles"
import Button from "@material-ui/core/Button"
import Dialog from "@material-ui/core/Dialog"
import DialogActions from "@material-ui/core/DialogActions"
import TextField from "@material-ui/core/TextField"
import Box from "@material-ui/core/Box"
import DialogContent from "@material-ui/core/DialogContent"
import DialogTitle from "@material-ui/core/DialogTitle"
import { useHistory } from "react-router-dom"
import useMediaQuery from "@material-ui/core/useMediaQuery"
import { useMutation } from "@apollo/client"
import Autocomplete from "@material-ui/lab/Autocomplete"
import { UPLOAD_PROFILE_IMAGE } from "../images/graphql"

import {
    UPDATE_TEAM,
    ADD_TEAM_LEADER,
    REMOVE_TEAM_LEADER,
    DELETE_TEAM,
} from "./graphql"
import {
    UpdateTeamMutation,
    UpdateTeamMutationVariables,
    AddTeamLeaderMutation,
    AddTeamLeaderMutationVariables,
    RemoveTeamLeaderMutation,
    RemoveTeamLeaderMutationVariables,
    DeleteTeamMutation,
    DeleteTeamMutationVariables,
} from "./__generated__/graphql"

import {
    UploadProfileImageMutation,
    UploadProfileImageMutationVariables,
} from "../images/__generated__/graphql"
import { DELETE_CONCEPT_LIST } from "../../graphql/conceptListQueries"
import {
    DeleteConceptListMutation,
    DeleteConceptListMutationVariables,
} from "../../graphql/__generated__/conceptListQueries"
import { Avatar } from "@material-ui/core"
import { Loading } from "../Loading"
import { Team } from "../../__generated__/types"
import { User } from "../../__generated__/types"
import useAwaitTranslation from "../../i18n/useAwaitTranslation"
import DeleteForever from "@material-ui/icons/DeleteForever"
const useStyles = makeStyles((theme: Theme) => ({
    avatarButton: {
        position: "relative",
        display: "flex",
    },
    avatar: {
        height: 200,
        width: 200,
        margin: "auto",
    },
    avatarUploadText: {
        opacity: 0,
        textAlign: "center",
        position: "absolute",
        height: "100%",
        width: "100%",
        zIndex: 1000,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        "&:hover": {
            opacity: 1,
            cursor: "pointer",
            backdropFilter: "blur(5px)",
        },
    },
}))

interface TeamSettingsProps {
    onClose: () => void
    team: Team
    onSave: () => Promise<void>
}
export default function TeamSettings(props: TeamSettingsProps) {
    const classes = useStyles()
    const theme: Theme = useTheme()
    const { t } = useAwaitTranslation("teams")
    const mobile = useMediaQuery(theme.breakpoints.down("sm"))
    const [confirm, setConfirm] = useState(false)
    const [teamData, setTeamData] = useState(null)
    const [uploading, setUploading] = useState(false)
    const [saving, setSaving] = useState(false)
    const history = useHistory()
    const [uploadPhoto] = useMutation<
        UploadProfileImageMutation,
        UploadProfileImageMutationVariables
    >(UPLOAD_PROFILE_IMAGE)
    const [updateTeam] = useMutation<
        UpdateTeamMutation,
        UpdateTeamMutationVariables
    >(UPDATE_TEAM)
    const [addTeamLeader] = useMutation<
        AddTeamLeaderMutation,
        AddTeamLeaderMutationVariables
    >(ADD_TEAM_LEADER)
    const [removeTeamLeader] = useMutation<
        RemoveTeamLeaderMutation,
        RemoveTeamLeaderMutationVariables
    >(REMOVE_TEAM_LEADER)
    const [deleteConceptList] = useMutation<
        DeleteConceptListMutation,
        DeleteConceptListMutationVariables
    >(DELETE_CONCEPT_LIST)
    const [deleteTeam] = useMutation<
        DeleteTeamMutation,
        DeleteTeamMutationVariables
    >(DELETE_TEAM, {
        update(cache) {
            cache.evict({ id: cache.identify(props.team) })
        },
    })
    useEffect(() => {
        if (props.team) {
            setTeamData({
                name: props.team.name ?? "",
                description: props.team.description ?? "",
                leader: props.team.leader,
                imageUrl: props.team.imageUrl ?? "",
            })
        }
    }, [props.team])
    const handleSave = async () => {
        setSaving(true)
        let promiseArray = []
        if (props.team.leader?.userId !== teamData.leader?.userId) {
            promiseArray.push(
                removeTeamLeader({
                    variables: {
                        fromId: props.team.leader.userId,
                        toId: props.team.teamId,
                    },
                })
            )
            promiseArray.push(
                addTeamLeader({
                    variables: {
                        fromId: teamData.leader.userId,
                        toId: props.team.teamId,
                    },
                })
            )
        }

        promiseArray.push(
            updateTeam({
                variables: {
                    name: teamData.name,
                    description: teamData.description,
                    imageUrl: teamData.imageUrl,
                    teamId: props.team.teamId,
                },
            })
        )
        await Promise.all(promiseArray)
        await props.onSave()
        props.onClose()
    }

    const handleDelete = async () => {
        setConfirm(false)
        setSaving(true)
        const workspace = props.team.conceptLists.filter((x) => {
            return x.type === "TEAM_WORKSPACE"
        })[0]
        let promises = []
        if (workspace) {
            promises.push(
                deleteConceptList({
                    variables: { id: workspace.id },
                })
            )
        }
        promises.push(
            deleteTeam({
                variables: { teamId: props.team.teamId },
            })
        )
        await Promise.all(promises)

        props.onClose()
        history.push(`/teams`)
    }
    const uploadProfileImage = async (event) => {
        if (event.target?.files[0]) {
            setUploading(true)
            let photoResponse = await uploadPhoto({
                variables: {
                    file: event.target.files[0],
                    imageName: props.team.teamId,
                },
            })
            if (photoResponse && photoResponse.data) {
                await setTeamData({
                    ...teamData,
                    imageUrl: photoResponse.data.uploadProfileImage.fileUrl,
                })
                setUploading(false)
            } else {
                alert("Failed to upload")
            }
        }
    }

    return (
        <Dialog open={true} fullWidth>
            {confirm ? (
                <>
                    <DialogContent>
                        {t(
                            "deleteTeamConfirm",
                            "Are you sure you want to delete team"
                        )}{" "}
                        {props.team.name}?
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={() => {
                                setConfirm(false)
                            }}
                        >
                            {t("no", "No")}
                        </Button>{" "}
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleDelete}
                        >
                            {t("yes", "Yes")}
                        </Button>
                    </DialogActions>
                </>
            ) : saving ? (
                <Loading size={75} hideQuote={true} padding={3} />
            ) : (
                <>
                    <DialogTitle>Team Settings</DialogTitle>
                    <DialogContent>
                        {uploading ? (
                            <Loading size={50} hideQuote={true} />
                        ) : (
                            <Button
                                className={classes.avatarButton}
                                component="label"
                            >
                                <input
                                    type="file"
                                    hidden
                                    onChange={uploadProfileImage}
                                />
                                <span className={classes.avatarUploadText}>
                                    {t("uploadImage", "Upload Image")}
                                </span>
                                <Avatar
                                    variant="rounded"
                                    className={classes.avatar}
                                    src={teamData?.imageUrl}
                                ></Avatar>
                            </Button>
                        )}
                        <Box p={2}>
                            <TextField
                                autoFocus
                                value={teamData?.name}
                                onChange={(e) => {
                                    setTeamData({
                                        ...teamData,
                                        name: e.target.value,
                                    })
                                }}
                                variant="outlined"
                                required
                                label={t("teamName", "Team Name")}
                                fullWidth
                            />
                        </Box>
                        <Box p={2}>
                            <TextField
                                value={teamData?.description}
                                onChange={(e) => {
                                    setTeamData({
                                        ...teamData,
                                        description: e.target.value,
                                    })
                                }}
                                variant="outlined"
                                label={t("teamDescription", "Team Description")}
                                fullWidth
                            />
                        </Box>
                        <Box p={2}>
                            <Autocomplete
                                value={teamData?.leader}
                                options={props.team?.members}
                                disableClearable
                                onChange={(
                                    event: React.ChangeEvent<HTMLInputElement>,
                                    newValue: User
                                ) => {
                                    setTeamData({
                                        ...teamData,
                                        leader: newValue,
                                    })
                                }}
                                getOptionLabel={(option: User) => {
                                    return (
                                        option.lastName +
                                        ", " +
                                        option.firstName
                                    )
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        required
                                        {...params}
                                        label={t("teamLead", "Team Lead")}
                                        placeholder={t(
                                            "selectTeamLead",
                                            "Select Team Lead"
                                        )}
                                        variant="outlined"
                                    />
                                )}
                            />
                        </Box>
                    </DialogContent>
                    <DialogActions>
                        <Box
                            display="flex"
                            justifyContent="space-between"
                            width="100%"
                            alignItems="center"
                        >
                            <Button
                                disabled={uploading}
                                onClick={() => {
                                    setConfirm(true)
                                }}
                                startIcon={<DeleteForever color="error" />}
                            >
                                {t("deleteTeam", "Delete Team")}
                            </Button>

                            <Box>
                                <Button
                                    onClick={() => {
                                        props.onClose()
                                    }}
                                >
                                    {t("discard", "Discard")}
                                </Button>
                                <Button
                                    color="primary"
                                    disabled={
                                        teamData?.name.length === 0 ||
                                        uploading ||
                                        !teamData?.leader
                                    }
                                    onClick={handleSave}
                                >
                                    {t("save", "Save")}
                                </Button>
                            </Box>
                        </Box>
                    </DialogActions>
                </>
            )}
        </Dialog>
    )
}
