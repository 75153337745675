import { Box, IconButton } from "@material-ui/core"
import { Concept } from "../__generated__/types"
import FuzzyConceptSearch from "./FuzzyConceptSearch"
import { CONCEPT_BY_ID } from "../graphql/queries"
import { useQuery } from "@apollo/client"
import ConceptListItem from "./ConceptListItem"
import ClearIcon from "@material-ui/icons/Clear"
import { useTheme } from "@material-ui/core/styles"
interface ConceptSelectorProps {
    conceptId: string | null
    onChange: (value: Concept | null) => void
}
export default function ConceptSelector(props: ConceptSelectorProps) {
    const { conceptId, onChange } = props
    const { data } = useQuery(CONCEPT_BY_ID, {
        variables: {
            id: conceptId,
        },
        skip: !conceptId,
    })
    const selectedConcept = data?.Concept?.[0] ?? null
    const theme = useTheme()
    return (
        <Box
            style={{
                border: `1px solid ${theme.palette.divider}`,
                borderRadius: theme.shape.borderRadius,
            }}
        >
            {!!conceptId && !!selectedConcept ? (
                <Box display="flex" width="100%" alignItems={"center"}>
                    <Box flexGrow={1}>
                        <ConceptListItem
                            disableClick={true}
                            item={selectedConcept}
                            showSummary={true}
                            hideDivider={true}
                        />
                    </Box>
                    <IconButton
                        onClick={() => {
                            onChange(null)
                        }}
                    >
                        <ClearIcon />
                    </IconButton>
                </Box>
            ) : (
                <FuzzyConceptSearch
                    onSelection={(concept: Concept) => {
                        onChange(concept)
                    }}
                    autoFocus={true}
                    variant="outlined"
                    disableAdvancedSearch={true}
                />
            )}
        </Box>
    )
}
