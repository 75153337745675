import {
    Box,
    List,
    TextField,
    IconButton,
    Typography,
    ListSubheader,
    InputAdornment,
} from "@material-ui/core"
import { useTheme, alpha } from "@material-ui/core/styles"
import { ADD_CATEGORY_CRITERIA, ADD_FIELD_SUBFIELD } from "../criteria/graphql"
import {
    AddCategoryCriteriaMutation,
    AddCategoryCriteriaMutationVariables,
    AddFieldSubfieldMutation,
    AddFieldSubfieldMutationVariables,
} from "../criteria/__generated__/graphql"
import { CriteriaType } from "../criteria/types"
import React, { useState } from "react"
import { useApolloClient, useMutation, NetworkStatus } from "@apollo/client"

import { Category, Criteria, SystemRole } from "../../__generated__/types"
import { useInputTools } from "../inputs/useInputTools"
import useCriteriaTypes, {
    DefaultInputSource,
    TOP_LEVEL_CRITERIA_OPTIONS,
} from "../criteria/useCriteriaTypes"
import CriteriaForm from "../criteria/CriteriaForm"
import useAwaitTranslation from "../../i18n/useAwaitTranslation"
import { useAuth } from "../../providers/AuthProvider"
import useCategoryTools from "../categories/useCategoryTools"
import Search from "@material-ui/icons/Search"
import Clear from "@material-ui/icons/Clear"
import FieldRowOption from "./FieldRowOption"
import LoadingOverlayWrapper from "../LoadingOverlayWrapper"
import FieldAutocomplete from "../criteria/FieldAutocomplete"
import FieldTypeSelect from "../criteria/FieldTypeSelect"
import Check from "@material-ui/icons/Check"
import { CONCEPT_INPUTS } from "../inputs/graphql"

interface InputWidgetOptionsProps {
    onAddNewWidget: (config: any) => void
    conceptCategory: Category
}
export default function InputWidgetOptions(props: InputWidgetOptionsProps) {
    const { onCreateNewInput } = useInputTools({})
    const { t } = useAwaitTranslation("criteria")
    const theme = useTheme()
    const { currentUser } = useAuth()
    const client = useApolloClient()
    const { getDefaultCriteriaConfig } = useCriteriaTypes()

    // if it has a collectionId, its a sub
    const [fieldToAddData, setFieldToAddData] = useState<{
        categoryId?: string | null
        collectionId?: string | null
        isSub: boolean
        currentIds?: string[]
    } | null>(null)
    const [showLoader, setShowLoader] = useState(false)
    const { getCategoryArray, onRefetchCategories, networkStatus } =
        useCategoryTools()
    const categories = getCategoryArray(props.conceptCategory?.id)
    const [searchTerm, setSearchTerm] = useState("")
    const [selectedCriteriaType, setSelectedCriteriaType] = useState<
        CriteriaType | "All"
    >("All")
    const [addCategoryCriteria] = useMutation<
        AddCategoryCriteriaMutation,
        AddCategoryCriteriaMutationVariables
    >(ADD_CATEGORY_CRITERIA)

    const [addFieldSubfield] = useMutation<
        AddFieldSubfieldMutation,
        AddFieldSubfieldMutationVariables
    >(ADD_FIELD_SUBFIELD)

    const onAddExistingField = async (
        field: Criteria,
        subfieldToAdd?: Criteria
    ) => {
        if (!!subfieldToAdd) {
            await addFieldSubfield({
                variables: {
                    childFieldId: subfieldToAdd?.id,
                    parentFieldId: field.id,
                },
            })
        } else {
            await addCategoryCriteria({
                variables: {
                    categoryId: props.conceptCategory?.id,
                    criteriaId: field?.id,
                },
            })
        }
    }

    const onAddFieldToWorkspace = async (
        field: Criteria,
        sourceField?: Criteria,
        config?: any
    ) => {
        if (!showLoader) {
            setShowLoader(true)
        }
        let cleanedConfig: any = {}
        const defaultCriteriaConfig = getDefaultCriteriaConfig(
            field.criteriaType as CriteriaType
        )
        let initialConfig = {
            ...defaultCriteriaConfig,
            ...JSON.parse(field?.criteriaOptions),
        }
        if (!!config) {
            initialConfig = {
                ...initialConfig,
                ...config,
            }
        }
        Object.keys(initialConfig).map((field) => {
            if (Object.keys(defaultCriteriaConfig).includes(field)) {
                cleanedConfig[field] = initialConfig[field]
            }
            return cleanedConfig
        })

        if (!!sourceField) {
            cleanedConfig = {
                ...cleanedConfig,
                source: {
                    ...DefaultInputSource,
                    ...cleanedConfig.source,
                    criteriaIds: [{ id: sourceField.id }],
                },
            }
        }

        await onCreateNewInput(field.id, JSON.stringify(cleanedConfig))
        await client.refetchQueries({
            include: [CONCEPT_INPUTS],
        })
        props.onAddNewWidget({
            inputConfig: cleanedConfig,
            criteriaId: field.id,
        })
        setShowLoader(false)
    }
    const fields = categories
        .flatMap((cat) => cat.criteria)
        .filter(
            (value, index, self) =>
                index === self.findIndex((field) => field.id === value.id) &&
                !value.isArchived &&
                !!value.name
        )
        .sort(function (a, b) {
            var textA = a.name.toUpperCase()
            var textB = b.name.toUpperCase()
            return textA < textB ? -1 : textA > textB ? 1 : 0
        })

    const availableTopLevelCategoryFields =
        fields?.filter(
            (c) =>
                c.name?.toLowerCase().indexOf(searchTerm?.toLowerCase()) !==
                    -1 &&
                (selectedCriteriaType === "All" ||
                    c.criteriaType === selectedCriteriaType)
        ) ?? []

    const standardCategoryFields = availableTopLevelCategoryFields?.filter(
        (criteria) =>
            categories
                ?.find((cat) => props.conceptCategory?.id === cat.id)
                ?.criteria?.find((field) => field.id === criteria.id)
    )
    const inheritedCategoryFields = availableTopLevelCategoryFields?.filter(
        (criteria) =>
            !standardCategoryFields?.find((field) => field.id === criteria.id)
    )

    return (
        <LoadingOverlayWrapper
            loading={networkStatus === NetworkStatus.loading || !!showLoader}
        >
            <Box width="100%" position={"relative"} height={500}>
                {!!fieldToAddData ? (
                    <Box
                        display={"flex"}
                        flexDirection="column"
                        height="100%"
                        overflow="auto"
                    >
                        <CriteriaForm
                            onClose={() => setFieldToAddData(null)}
                            onSave={async (criteria?: Criteria) => {
                                await onRefetchCategories()
                            }}
                            hideHeader={true}
                            parent={
                                !!fieldToAddData?.collectionId
                                    ? availableTopLevelCategoryFields?.find(
                                          (field) =>
                                              field.id ===
                                              fieldToAddData.collectionId
                                      )
                                    : null
                            }
                            categoryId={fieldToAddData?.categoryId || null}
                        />
                    </Box>
                ) : (
                    <Box
                        height={500}
                        display="flex"
                        flexDirection={"column"}
                        overflow="hidden"
                    >
                        <Box
                            display="flex"
                            flexDirection="column"
                            flexGrow={1}
                            overflow="hidden"
                        >
                            <Box display="flex" alignItems={"center"} p={1}>
                                <Box mr={2}>
                                    <FieldTypeSelect
                                        typeOptions={TOP_LEVEL_CRITERIA_OPTIONS}
                                        value={selectedCriteriaType}
                                        onChange={(
                                            value: CriteriaType | "All"
                                        ) => {
                                            setSelectedCriteriaType(value)
                                        }}
                                        variant="outlined"
                                        size="small"
                                        includeAll={true}
                                    />
                                </Box>
                                <TextField
                                    label={`${t("search", "Search")} ${
                                        props.conceptCategory?.name ?? ""
                                    } ${t("fields", "Fields")}`}
                                    fullWidth
                                    autoFocus
                                    value={searchTerm}
                                    size="small"
                                    onChange={(ev) =>
                                        setSearchTerm(ev.target.value)
                                    }
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <Search />
                                            </InputAdornment>
                                        ),
                                        endAdornment:
                                            searchTerm.length > 0 ? (
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        size="small"
                                                        onClick={() =>
                                                            setSearchTerm("")
                                                        }
                                                    >
                                                        <Clear />
                                                    </IconButton>
                                                </InputAdornment>
                                            ) : null,
                                    }}
                                    variant="outlined"
                                />
                            </Box>
                            <Box
                                display="flex"
                                alignItems="center"
                                justifyContent={"flex-end"}
                                pr={3}
                            >
                                <Check fontSize="small" />

                                <Typography noWrap variant="caption">
                                    = Added to Workspace
                                </Typography>
                            </Box>
                            <List
                                style={{ flexGrow: 1, overflow: "auto" }}
                                disablePadding
                            >
                                <ListSubheader
                                    style={{
                                        backgroundColor: alpha(
                                            theme.palette.background.paper,
                                            0.95
                                        ),
                                    }}
                                >
                                    {props.conceptCategory?.name
                                        ? `${props.conceptCategory.name} `
                                        : ""}
                                    {t("fields", "Fields")}
                                </ListSubheader>
                                {standardCategoryFields?.map((criteria) => (
                                    <FieldRowOption
                                        criteria={criteria}
                                        onAddFieldToWorkspace={
                                            onAddFieldToWorkspace
                                        }
                                        key={
                                            criteria.id +
                                            "-row-standardCategoryFields"
                                        }
                                        onAddExistingSubfield={async (
                                            subfield: Criteria
                                        ) => {
                                            await onAddExistingField(
                                                criteria,
                                                subfield
                                            )
                                        }}
                                        onCreateNewSubfield={() => {
                                            setFieldToAddData({
                                                collectionId: criteria.id,
                                                isSub: true,
                                            })
                                        }}
                                    />
                                ))}
                                {currentUser.role === SystemRole.ADMIN && (
                                    <Box
                                        component="span"
                                        pr={1}
                                        pl={2}
                                        mt={1}
                                        display="flex"
                                    >
                                        <FieldAutocomplete
                                            optionsAllowed="Top"
                                            currentFieldIds={
                                                standardCategoryFields?.map(
                                                    (item) => item.id
                                                ) || []
                                            }
                                            colorBackground={true}
                                            onSelect={onAddExistingField}
                                            onCreateNewField={() => {
                                                setFieldToAddData({
                                                    categoryId:
                                                        props.conceptCategory
                                                            ?.id,
                                                    isSub: false,
                                                })
                                            }}
                                        />
                                    </Box>
                                )}
                                <ListSubheader
                                    style={{
                                        backgroundColor:
                                            theme.palette.background.paper,
                                        zIndex: 1,
                                    }}
                                >
                                    {t("inheritedFields", "Inherited Fields")}
                                </ListSubheader>
                                {inheritedCategoryFields?.length > 0 ? (
                                    inheritedCategoryFields?.map((criteria) => (
                                        <FieldRowOption
                                            criteria={criteria}
                                            onAddFieldToWorkspace={
                                                onAddFieldToWorkspace
                                            }
                                            key={criteria.id + "-row-inherited"}
                                            onAddExistingSubfield={async (
                                                subfield: Criteria
                                            ) => {
                                                await onAddExistingField(
                                                    criteria,
                                                    subfield
                                                )
                                            }}
                                            onCreateNewSubfield={() => {
                                                setFieldToAddData({
                                                    collectionId: criteria.id,
                                                    isSub: true,
                                                })
                                            }}
                                        />
                                    ))
                                ) : (
                                    <Box ml={3}>
                                        <Typography
                                            variant="caption"
                                            color="textSecondary"
                                        >
                                            {t(
                                                "noInheritedFields",
                                                "No inherited fields"
                                            )}
                                        </Typography>
                                    </Box>
                                )}
                            </List>
                        </Box>
                    </Box>
                )}
            </Box>
        </LoadingOverlayWrapper>
    )
}
