import {
    Box,
    Divider,
    TextField,
    IconButton,
    decomposeColor,
    Typography,
    alpha,
} from "@material-ui/core"
import { Concept } from "../../../__generated__/types"

import {
    Draggable,
    DroppableProvided,
    DroppableStateSnapshot,
} from "react-beautiful-dnd"
import React, { useEffect, useState } from "react"
import { useTheme } from "@material-ui/core/styles"
import ConceptChip from "../../ConceptChip"
import Clear from "@material-ui/icons/Clear"
import DragHandle from "@material-ui/icons/DragHandle"

const IndividualGroup = (props: {
    group: { value: string; id: string }
    inputId: string
    groupConcepts: Concept[]
    snapshot: DroppableStateSnapshot
    provided: DroppableProvided
    onEditGroupTitle: (value: string) => void
    onGroupRemoval: (groupIdToRemove: string) => void
    viewingResults: boolean
    inputConfigurationIsEditable: boolean
}) => {
    const {
        provided,
        snapshot,
        group,
        inputId,
        groupConcepts,
        onEditGroupTitle,
        onGroupRemoval,
        viewingResults,
        inputConfigurationIsEditable,
    } = props
    const [title, setTitle] = useState("")
    const theme = useTheme()
    const [hovered, setHovered] = useState(false)
    useEffect(() => {
        setTitle(group.value)
    }, [group])

    return (
        <Box
            onMouseOver={() => {
                if (!hovered && !!inputConfigurationIsEditable) {
                    setHovered(true)
                }
            }}
            onMouseLeave={() => {
                if (!!hovered && !!inputConfigurationIsEditable) {
                    setHovered(false)
                }
            }}
            display="flex"
            flexDirection="column"
            height="100%"
            width="100%"
            borderRadius={theme.shape.borderRadius}
            style={{
                backgroundColor: alpha(theme.palette.primary.main, 0.05),
            }}
        >
            <Box p={0.5} style={{}} display="flex" alignItems={"center"}>
                {!!inputConfigurationIsEditable && (
                    <DragHandle
                        style={{
                            cursor: "grab",
                            marginRight: theme.spacing(0.5),
                        }}
                        color="action"
                        fontSize="small"
                        className={`input-drag-handle-${inputId}`}
                    />
                )}
                {!!inputConfigurationIsEditable ? (
                    <TextField
                        size="small"
                        variant="standard"
                        value={title}
                        fullWidth
                        InputProps={{
                            disableUnderline: true,
                        }}
                        onBlur={() => {
                            if (title.length > 0) {
                                onEditGroupTitle(title)
                            } else {
                                setTitle(group.value)
                            }
                        }}
                        onChange={(e) => setTitle(e.target.value)}
                    />
                ) : (
                    <Typography variant="body1">{group.value}</Typography>
                )}
                {!!hovered && !!inputConfigurationIsEditable && (
                    <Box
                        style={{
                            display: "flex",
                            position: "absolute",
                            right: 4,
                            top: 4,
                            backgroundColor: `rgba(${
                                decomposeColor(theme.palette.background.paper)
                                    .values
                            }, .9)`,
                        }}
                    >
                        <IconButton
                            size="small"
                            onClick={() => {
                                onGroupRemoval(group.id)
                            }}
                        >
                            <Clear fontSize="small" />
                        </IconButton>
                    </Box>
                )}
            </Box>
            <Divider />
            <div
                ref={provided.innerRef}
                {...provided.droppableProps}
                style={{ height: "calc(100% - 37px)" }}
            >
                <Box
                    style={{
                        overflowY: "auto",
                        overflowX: "-moz-hidden-unscrollable",
                        backgroundColor:
                            snapshot?.isDraggingOver &&
                            theme.palette.action.hover,
                    }}
                    height={"100%"}
                    p={0.5}
                >
                    {groupConcepts.map((item, index) => {
                        return (
                            <Draggable
                                key={item.id + index}
                                isDragDisabled={!!viewingResults}
                                draggableId={item.id}
                                index={index}
                            >
                                {(provided, snapshot) => (
                                    <div
                                        key={item.id}
                                        ref={provided.innerRef}
                                        style={{
                                            overflow: "hidden",
                                        }}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                    >
                                        <Box p={0.5}>
                                            <ConceptChip
                                                item={item}
                                                disableClick={!viewingResults}
                                            />
                                        </Box>
                                    </div>
                                )}
                            </Draggable>
                        )
                    })}
                </Box>
                {provided.placeholder}
            </div>
        </Box>
    )
}

export default IndividualGroup
