import React, { useState } from "react"
import {
    TextField,
    Box,
    List,
    IconButton,
    Button,
    Icon,
    InputAdornment,
    Tooltip,
    Typography,
} from "@material-ui/core"

import ClearIcon from "@material-ui/icons/Clear"
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles"
import useAwaitTranslation from "../../../i18n/useAwaitTranslation"
import IconPicker, { IconValue } from "../../icons/IconPicker"
import ClickableRichTooltip from "../../Popper/ClickableRichTooltip"
import AddCircle from "@material-ui/icons/AddCircle"
import { reorderArray } from "../../../util/fns"
import {
    DragDropContext,
    Draggable,
    DraggableProvidedDragHandleProps,
    Droppable,
} from "react-beautiful-dnd"
import DragIndicator from "@material-ui/icons/DragIndicator"
import AddBox from "@material-ui/icons/AddBox"
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        optionItem: {
            "&:hover": {
                backgroundColor: theme.palette.action.hover,
            },
        },
        stringOptions: {
            border: `1px solid ${theme.palette.divider}`,
            borderRadius: theme.shape.borderRadius,
        },
        listItem: {
            "&:hover": {
                backgroundColor: theme.palette.action.hover,
            },
        },
    })
)

const UniqueOptions = (props: {
    values: {
        value: string
        id: string
        icon?: IconValue
    }[]

    onChange: (value: { value: string; id: string; icon?: IconValue }[]) => void
    allowIcons: boolean
    label?: string
}) => {
    const { onChange, allowIcons, values, label } = props
    const classes = useStyles()
    const [inputValue, setInputValue] = useState("")
    const { t } = useAwaitTranslation("criteria")

    const onDragEnd = (result) => {
        if (!result.destination) {
            return
        }
        const fieldArray: {
            value: string
            id: string
            icon?: IconValue
        }[] = reorderArray(
            values,
            result.source.index,
            result.destination.index
        )

        onChange(fieldArray)
    }
    return (
        <Box>
            <Box p={0.5}>
                <Typography color="textSecondary" variant="subtitle1">
                    {label || ""}
                </Typography>
            </Box>
            <Box className={classes.stringOptions}>
                <Box p={2}>
                    <TextField
                        value={inputValue}
                        onChange={(e) => {
                            setInputValue(e.target.value)
                        }}
                        fullWidth
                        placeholder={t("typeToAddNew", "Type to add new")}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <AddBox color="primary" fontSize="small" />
                                </InputAdornment>
                            ),
                        }}
                        onKeyDown={(e) => {
                            if (
                                e.key === "Enter" &&
                                !values.find(
                                    (item) => item.value === inputValue
                                ) &&
                                !!inputValue
                            ) {
                                setInputValue("")
                                onChange([
                                    {
                                        value: inputValue,
                                        id: JSON.stringify(Date.now()),
                                    },
                                    ...values,
                                ])
                            }
                        }}
                    />
                </Box>
                <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable droppableId={"test"}>
                        {(provided, snapshot) => (
                            <List
                                ref={provided.innerRef}
                                {...provided.droppableProps}
                                disablePadding
                            >
                                {values.map((item, index) => {
                                    return (
                                        <Draggable
                                            key={item.id}
                                            index={index}
                                            draggableId={item.id}
                                        >
                                            {(provided, snapshot) => (
                                                <div
                                                    key={item.id}
                                                    ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                    className={classes.listItem}
                                                >
                                                    <UniqueOption
                                                        key={item.id}
                                                        item={item}
                                                        onRemoval={() => {
                                                            onChange(
                                                                values.filter(
                                                                    (val) =>
                                                                        val.id !==
                                                                        item.id
                                                                )
                                                            )
                                                        }}
                                                        onChange={(value) => {
                                                            onChange(
                                                                values.map(
                                                                    (val) => {
                                                                        if (
                                                                            val.id ===
                                                                            item.id
                                                                        ) {
                                                                            return value
                                                                        } else
                                                                            return val
                                                                    }
                                                                )
                                                            )
                                                        }}
                                                        dragHandleProps={
                                                            provided.dragHandleProps
                                                        }
                                                        isDragging={
                                                            snapshot.isDragging
                                                        }
                                                        allowIcons={allowIcons}
                                                    />
                                                </div>
                                            )}
                                        </Draggable>
                                    )
                                })}
                                {provided.placeholder}
                            </List>
                        )}
                    </Droppable>
                </DragDropContext>
            </Box>
        </Box>
    )
}

const UniqueOption = (props: {
    item: {
        value: string
        id: string
        icon?: IconValue
    }
    onRemoval: () => void
    onChange: (value: { value: string; id: string; icon?: IconValue }) => void
    allowIcons: boolean
    dragHandleProps: DraggableProvidedDragHandleProps
    isDragging: boolean
}) => {
    const {
        item,
        onRemoval,
        onChange,
        allowIcons,
        dragHandleProps,
        isDragging,
    } = props
    const [hovered, setHovered] = useState(false)
    const { t } = useAwaitTranslation("criteria")
    return (
        <Box
            display="flex"
            alignItems="center"
            onMouseOver={() => {
                if (!hovered) {
                    setHovered(true)
                }
            }}
            onMouseLeave={() => {
                if (!!hovered) {
                    setHovered(false)
                }
            }}
            key={item.id}
            p={1}
        >
            <Box width={35} pr={0.5}>
                <Box {...dragHandleProps}>
                    {(!!hovered || !!isDragging) && (
                        <Tooltip title={t("clickToDrag", "Click to drag")}>
                            <IconButton size="small">
                                <DragIndicator />
                            </IconButton>
                        </Tooltip>
                    )}
                </Box>
            </Box>
            {!!allowIcons && (
                <Box width={100} display="flex" justifyContent="center" mr={2}>
                    <ClickableRichTooltip
                        content={
                            <IconPicker
                                value={item.icon}
                                onChange={(value) => {
                                    onChange({ ...item, icon: value })
                                }}
                            />
                        }
                    >
                        {!!item.icon ? (
                            <IconButton size="small">
                                <Icon>{item.icon.name}</Icon>
                            </IconButton>
                        ) : (
                            <Button
                                color="default"
                                startIcon={<AddCircle fontSize="small" />}
                                size="small"
                            >
                                Icon
                            </Button>
                        )}
                    </ClickableRichTooltip>
                </Box>
            )}
            <Box width="100%">
                <TextField
                    fullWidth
                    InputProps={{
                        disableUnderline: !hovered,
                    }}
                    value={item.value}
                    onChange={(e) => {
                        onChange({ ...item, value: e.target.value })
                    }}
                />
            </Box>
            {!!hovered && (
                <Tooltip title={t("deleteOption", "Delete option")}>
                    <IconButton size="small" onClick={onRemoval}>
                        <ClearIcon />
                    </IconButton>
                </Tooltip>
            )}
        </Box>
    )
}

export default UniqueOptions
