import { Divider, Box, Typography, IconButton } from "@material-ui/core"

import ClearIcon from "@material-ui/icons/Clear"
import TreeView from "@material-ui/lab/TreeView"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import ChevronRightIcon from "@material-ui/icons/ChevronRight"
import { Category, SystemRole } from "../../__generated__/types"
import useAwaitTranslation from "../../i18n/useAwaitTranslation"
import useCategoryTools from "./useCategoryTools"
import CategoryItem from "./CategoryItem"
import { useAuth } from "../../providers/AuthProvider"

interface QuickEntryCategorySelectorProps {
    rootOptions: Category[]
    value?: string | null
    setValue: (category?: Category) => void
    onClose: () => void
    cancelEnabled?: boolean
}
export default function QuickEntryCategorySelector(
    props: QuickEntryCategorySelectorProps
) {
    const { value, setValue, onClose, cancelEnabled, rootOptions } = props
    const { getCategoryArray, environmentCategories } = useCategoryTools()
    const { currentUser } = useAuth()
    const categoryArray = getCategoryArray(value)
    const { t: categoryTranslation } = useAwaitTranslation("categories")
    const selectedCategory = categoryArray?.[categoryArray?.length - 1]

    return (
        <Box
            display="flex"
            flexDirection={"column"}
            overflow="hidden"
            height="100%"
        >
            <Box>
                <Box
                    p={1}
                    display="flex"
                    justifyContent={"space-between"}
                    alignItems="center"
                >
                    <Typography variant="body1" style={{ flexGrow: 1 }}>
                        {categoryTranslation(
                            "availableCategories",
                            "Available Categories"
                        )}
                    </Typography>
                    {!!cancelEnabled && (
                        <IconButton
                            size="small"
                            onClick={() => {
                                onClose()
                            }}
                        >
                            <ClearIcon fontSize="small" />
                        </IconButton>
                    )}
                </Box>

                <Divider />
            </Box>
            <Box overflow="auto">
                {rootOptions.map((option, index) => {
                    return (
                        <TreeView
                            key={option.id}
                            defaultCollapseIcon={<ExpandMoreIcon />}
                            defaultExpandIcon={<ChevronRightIcon />}
                            defaultExpanded={
                                rootOptions
                                    ?.map((item) =>
                                        item.isRoot ? item.id : null
                                    )
                                    ?.filter((x) => !!x) ?? []
                            }
                        >
                            <CategoryItem
                                node={option}
                                setValue={(value: Category) => {
                                    setValue(value)
                                    onClose()
                                }}
                                hiddenOptions={
                                    environmentCategories
                                        ?.filter(
                                            (x) =>
                                                currentUser.role !==
                                                    SystemRole.ADMIN &&
                                                !!x.isManaged
                                        )
                                        ?.map((o) => o.id) || []
                                }
                                value={selectedCategory}
                            />
                        </TreeView>
                    )
                })}
            </Box>
        </Box>
    )
}
