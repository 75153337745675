import {
    Avatar,
    Divider,
    Typography,
    ListItem,
    ListItemSecondaryAction,
    ListItemAvatar,
    Slide,
    List,
    IconButton,
    Checkbox,
    ListItemText,
    Tooltip,
} from "@material-ui/core"
import Box from "@material-ui/core/Box"
import { makeStyles, useTheme } from "@material-ui/core/styles"
import TextField from "@material-ui/core/TextField"
import Autocomplete from "@material-ui/lab/Autocomplete"
import React, { useMemo, useRef, useState } from "react"
import MenuOpenIcon from "@material-ui/icons/MenuOpen"
import {
    red,
    indigo,
    blue,
    deepPurple,
    orange,
    yellow,
    teal,
    green,
} from "@material-ui/core/colors"
import {
    ReferenceLine,
    ResponsiveContainer,
    Scatter,
    ScatterChart,
    XAxis,
    YAxis,
    ZAxis,
} from "recharts"
import useAwaitTranslation from "../../i18n/useAwaitTranslation"
import { useEffect } from "react"
import { Concept, User } from "../../__generated__/types"
import ClickableRichTooltip from "../Popper/ClickableRichTooltip"
import {
    InputResponseLabel,
    myCurrentInputResponseLabelVar,
} from "../../providers/GlobalState"
import ConceptAvatar from "../ConceptAvatar"
import ConceptListItem from "../ConceptListItem"
import { useReactiveVar } from "@apollo/client"
const useStyles = makeStyles((theme) => ({
    container: {
        height: "100%",
        display: "flex",
        flexDirection: "column",
        width: "100%",
        overflow: "hidden",
    },
    flex: {
        height: "90%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-around",
        width: "100%",
    },
    axisSelector: {
        width: "100%",
        margin: theme.spacing(1),
    },
    categoryLabel: {
        color: theme.palette.text.hint,
        fontSize: theme.typography.body2.fontSize,
    },
}))

type UserScoreBubbleChartDataObjectValue = {
    criteriaName: string
    value: number
    noResponse?: boolean
}

type UserScoreBubbleChartDataObject = {
    label: string
    user?: User
    scoredConcept: Concept
    values: UserScoreBubbleChartDataObjectValue[]
    primary: boolean
}

export type AxisOption = {
    name: string
    max?: number
    prefix?: string
    suffix?: string
    maxEnforced: boolean
}

interface UserScoreBubbleChartProps {
    axisOptions: AxisOption[]
    data: UserScoreBubbleChartDataObject[]
    labelOptions: InputResponseLabel[]
}

function UserScoreBubbleChart(props: UserScoreBubbleChartProps) {
    // imported hooked, etc.
    const theme = useTheme()
    const classes = useStyles()
    const { t, i18n } = useAwaitTranslation("feedback")

    /// state
    const [x, setX] = useState<AxisOption>(null)
    const [y, setY] = useState<AxisOption>(null)
    const [z, setZ] = useState<AxisOption>(null)
    const [selectedLabels, setSelectedLabels] =
        useState<InputResponseLabel[]>(null)
    const [selectedConceptIds, setSelectedConceptIds] = useState(null)
    const [openSidebar, setOpenSidebar] = useState(true)
    const currentLabel = useReactiveVar(myCurrentInputResponseLabelVar)

    // to reset the concepts and labels when the currentLabel changes
    const labelKey = useMemo(() => {
        return (
            (currentLabel?.user?.userId || "") +
            (currentLabel?.label || "") +
            JSON.stringify(!!currentLabel?.label) +
            JSON.stringify(!!currentLabel?.user)
        )
    }, [currentLabel])

    ///refs
    const axisRef = useRef(props.axisOptions)
    axisRef.current = props.axisOptions

    // variables
    const colorOptions = [
        red,
        indigo,
        blue,
        deepPurple,
        orange,
        yellow,
        teal,
        green,
    ]
    const labelArray = props.data
        .map((item) => item.label)
        .filter((value, index, self) => self.indexOf(value) === index)

    //maniuplation of props data
    let chartData =
        props.data?.map((x) => {
            let object = {
                label: x.label,
                user: !!x.user ? { ...x.user } : null,
                scoredConcept: {
                    ...x.scoredConcept,
                },
                color: colorOptions[labelArray.indexOf(x.label)]?.["500"],
            }
            object["noResponseCriteria"] = props.axisOptions?.filter(
                (op) => !x.values.find((val) => val.criteriaName === op.name)
            )
            object["noResponseCriteria"].map((item) => {
                object[item.name] = 0
                return object
            })
            x.values.map((val) => {
                let axisMax =
                    props.axisOptions?.find(
                        (op) => op.name === val.criteriaName
                    )?.max ?? 100

                object[val.criteriaName] =
                    val.value > axisMax ? axisMax : val.value
                return object
            })
            return object
        }) ?? []
    const concepts = props.data
        ?.map((item) => item.scoredConcept)
        .filter(
            (value, index, self) => !!value && self.indexOf(value) === index
        )
        ?.sort((a, b) => a.title.localeCompare(b.title))

    //useEffects
    useEffect(() => {
        if (axisRef?.current?.length > 0) {
            setX(axisRef?.current?.[0])
            setY(axisRef?.current?.[1] ?? axisRef?.current?.[0])
            if (axisRef?.current?.length > 2) {
                setZ(
                    axisRef?.current?.[2] ??
                        axisRef?.current?.[1] ??
                        axisRef?.current?.[0]
                )
            } else {
                setZ(null)
            }
        }
    }, [])
    useEffect(() => {
        setSelectedConceptIds(null)
        setSelectedLabels(null)
    }, [labelKey])

    useEffect(() => {
        if (!selectedLabels && !!props.data) {
            setSelectedLabels(props.labelOptions)
        }
    }, [props.data, selectedLabels, props.labelOptions])

    useEffect(() => {
        if (!selectedConceptIds && !!props.data) {
            setSelectedConceptIds(concepts.map((concept) => concept.id))
        }
    }, [props.data, selectedConceptIds, concepts])

    //filtering applied
    if (!!selectedLabels) {
        chartData = [
            ...chartData.filter((item) =>
                selectedLabels?.some((x) =>
                    !x.label
                        ? !item.label && item.user?.userId === x.user?.userId
                        : x.label === item.label &&
                          x.user?.userId === item.user?.userId
                )
            ),
        ]
    }

    if (!!selectedConceptIds) {
        chartData = [
            ...chartData.filter((item) =>
                selectedConceptIds.includes(item.scoredConcept?.id)
            ),
        ]
    }

    if (!x || !y || (!z && props.axisOptions.length > 2)) {
        return null
    }

    return (
        <Box display="flex" height="100%" width="100%">
            <Slide
                in={openSidebar}
                mountOnEnter
                unmountOnExit
                direction="right"
            >
                <Box
                    display="flex"
                    flexDirection="column"
                    height="100%"
                    overflow="hidden"
                    flexShrink={2}
                >
                    <Box p={1} pl={2} mt={1}>
                        <Typography variant="body2" color="textSecondary">
                            {t("responses", "Responses")} (
                            {props.labelOptions.length})
                        </Typography>
                    </Box>
                    <List
                        dense
                        style={{
                            overflow: "auto",
                            flexGrow: 1,
                            border: `1px solid ${theme.palette.divider}`,
                            borderRadius: theme.shape.borderRadius,
                            margin: theme.spacing(1.5),
                            marginTop: theme.spacing(0),
                            marginRight: 0,
                            minHeight: theme.spacing(10),
                        }}
                    >
                        {props.labelOptions.map((item, index) => {
                            const checked = !!selectedLabels?.some((x) =>
                                !item.label
                                    ? !x.label &&
                                      item.user?.userId === x.user?.userId
                                    : x.label === item.label &&
                                      item.user?.userId === x.user?.userId
                            )

                            return (
                                <ListItem
                                    dense
                                    key={item.label + item.user?.userId + index}
                                >
                                    <Tooltip
                                        title={`${item.user?.firstName} ${item.user?.lastName}`}
                                    >
                                        <ListItemAvatar
                                            style={{
                                                minWidth: theme.spacing(4),
                                            }}
                                        >
                                            <Avatar
                                                style={{
                                                    border: `3px solid ${
                                                        colorOptions[
                                                            labelArray.indexOf(
                                                                item.label
                                                            )
                                                        ]?.["500"]
                                                    }`,
                                                    backgroundColor:
                                                        colorOptions[
                                                            labelArray.indexOf(
                                                                item.label
                                                            )
                                                        ]?.["500"],
                                                    width: theme.spacing(3),
                                                    height: theme.spacing(3),
                                                }}
                                                src={item.user?.imageUrl}
                                            />
                                        </ListItemAvatar>
                                    </Tooltip>
                                    <Tooltip title={item.label ?? ""}>
                                        <ListItemText
                                            primary={
                                                <Typography
                                                    noWrap
                                                    variant="body2"
                                                >
                                                    {item.label ??
                                                        `${item.user.firstName} ${item.user.lastName}`}
                                                </Typography>
                                            }
                                        />
                                    </Tooltip>
                                    <ListItemSecondaryAction>
                                        <Checkbox
                                            checked={checked}
                                            size="small"
                                            color="secondary"
                                            onChange={(
                                                event: React.ChangeEvent<HTMLInputElement>
                                            ) => {
                                                if (event.target.checked) {
                                                    setSelectedLabels([
                                                        ...selectedLabels,
                                                        item,
                                                    ])
                                                } else {
                                                    setSelectedLabels([
                                                        ...selectedLabels.filter(
                                                            (x) =>
                                                                !item.label
                                                                    ? !!x.label ||
                                                                      x.user
                                                                          ?.userId !==
                                                                          item
                                                                              ?.user
                                                                              ?.userId
                                                                    : x.label !==
                                                                          item.label ||
                                                                      item.user
                                                                          ?.userId !==
                                                                          x
                                                                              ?.user
                                                                              ?.userId
                                                        ),
                                                    ])
                                                }
                                            }}
                                        />
                                    </ListItemSecondaryAction>
                                </ListItem>
                            )
                        })}
                    </List>
                    <Box p={1} mt={1} pl={2}>
                        <Typography variant="body2" color="textSecondary">
                            {t("concepts", "Concepts")} ({concepts.length})
                        </Typography>
                    </Box>
                    <List
                        dense
                        style={{
                            overflow: "auto",
                            flexGrow: 1,
                            border: `1px solid ${theme.palette.divider}`,
                            borderRadius: theme.shape.borderRadius,
                            margin: theme.spacing(1.5),
                            marginBottom: theme.spacing(2),
                            marginTop: theme.spacing(0),
                            marginRight: 0,
                            minHeight: theme.spacing(10),
                        }}
                    >
                        {concepts.map((concept, index) => {
                            const checked =
                                !!selectedConceptIds?.includes(concept.id) ??
                                false
                            const selectedLangString = i18n?.language
                            let translatedContent =
                                concept?.translations.filter(
                                    (t) =>
                                        t.languageString === selectedLangString
                                )[0]
                            return (
                                <ListItem dense key={concept.id}>
                                    <ListItemAvatar
                                        style={{
                                            minWidth: theme.spacing(4),
                                        }}
                                    >
                                        <ConceptAvatar
                                            size="small"
                                            concept={concept}
                                            variant="rounded"
                                        />
                                    </ListItemAvatar>
                                    <Tooltip
                                        title={
                                            translatedContent?.title ||
                                            concept.title
                                        }
                                    >
                                        <ListItemText>
                                            <Typography noWrap variant="body2">
                                                {translatedContent?.title ||
                                                    concept.title}
                                            </Typography>
                                        </ListItemText>
                                    </Tooltip>
                                    <ListItemSecondaryAction>
                                        <Checkbox
                                            color="secondary"
                                            checked={checked}
                                            size="small"
                                            onChange={(
                                                event: React.ChangeEvent<HTMLInputElement>
                                            ) => {
                                                if (event.target.checked) {
                                                    setSelectedConceptIds([
                                                        ...selectedConceptIds,
                                                        concept.id,
                                                    ])
                                                } else {
                                                    setSelectedConceptIds([
                                                        ...selectedConceptIds.filter(
                                                            (id) =>
                                                                id !==
                                                                concept.id
                                                        ),
                                                    ])
                                                }
                                            }}
                                        />
                                    </ListItemSecondaryAction>
                                </ListItem>
                            )
                        })}
                    </List>
                </Box>
            </Slide>
            <Box>
                <IconButton
                    onClick={() => setOpenSidebar((prev) => !prev)}
                    style={{
                        transform: !openSidebar && "rotateY(180deg)",
                    }}
                >
                    <MenuOpenIcon />
                </IconButton>
            </Box>

            <div className={classes.container}>
                <Box
                    display="flex"
                    justifyContent="flex-start"
                    alignItems="center"
                >
                    <Box mt={2} ml={2} fontWeight="bold">
                        {`Y: ${y.name}`} {y.suffix ? ` (${y.suffix})` : null}
                    </Box>
                </Box>
                <Box flexGrow={1} overflow="hidden" width="100%">
                    <ResponsiveContainer width="100%" height="100%">
                        <ScatterChart
                            margin={{
                                left: theme.spacing(1),
                                right: theme.spacing(5),
                                top: theme.spacing(5),
                                bottom: theme.spacing(3),
                            }}
                        >
                            <XAxis
                                type="number"
                                dataKey={x.name}
                                name={x.name + "-x-axis"}
                                domain={[0, x.max ?? 10]}
                                xAxisId={x.name + "-x-axis"}
                                tick={{ fill: theme.palette.text.primary }}
                                tickFormatter={(value) => {
                                    let string =
                                        (!!x.prefix ? `${x.prefix}` : "") +
                                        Number(value)?.toLocaleString()
                                    return string
                                }}
                            />
                            <YAxis
                                type="number"
                                dataKey={y.name}
                                name={y.name + "-y-axis"}
                                domain={[0, y.max ?? 10]}
                                yAxisId={y.name + "-y-axis"}
                                tick={{ fill: theme.palette.text.primary }}
                                tickFormatter={(value) => {
                                    let string =
                                        (!!y.prefix ? `${y.prefix}` : "") +
                                        Number(value)?.toLocaleString()

                                    return string
                                }}
                            />
                            {!!z && (
                                <ZAxis
                                    unit={z.suffix ?? null}
                                    dataKey={z.name}
                                    range={[0, z.max ?? 10]}
                                    name={z.name + "-z-axis"}
                                    zAxisId={z.name + "-z-axis"}
                                />
                            )}
                            <ReferenceLine
                                xAxisId={x.name + "-x-axis"}
                                yAxisId={y.name + "-y-axis"}
                                x={x.max / 2}
                            />
                            <ReferenceLine
                                xAxisId={x.name + "-x-axis"}
                                yAxisId={y.name + "-y-axis"}
                                y={y.max / 2}
                            />
                            <Scatter
                                style={{ cursor: "pointer" }}
                                data={chartData}
                                xAxisId={x.name + "-x-axis"}
                                yAxisId={y.name + "-y-axis"}
                                zAxisId={z?.name + "-z-axis"}
                                shape={
                                    <CustomDot
                                        backgroundColor={theme.palette.divider}
                                        currentAxes={[x, y, z]}
                                    />
                                }
                                fill={theme.palette.primary.main}
                            />
                        </ScatterChart>
                    </ResponsiveContainer>
                </Box>
                <Box
                    display="flex"
                    justifyContent={!!z ? "space-between" : "flex-end"}
                    mr={1}
                    ml={1}
                    fontWeight="bold"
                    mb={2}
                >
                    {!!z && (
                        <Box>
                            {`Size: ${z.name}`}{" "}
                            {z.suffix ? ` (${z.suffix})` : null}
                        </Box>
                    )}
                    <Box>
                        {`X: ${x.name}`} {x.suffix ? ` (${x.suffix})` : null}
                    </Box>
                </Box>

                <Box display="flex" padding={1} pt={0} flexShrink={0}>
                    <Autocomplete<(typeof props.axisOptions)[0], false, boolean>
                        className={classes.axisSelector}
                        options={props.axisOptions}
                        fullWidth
                        getOptionLabel={(option) => option.name}
                        value={x}
                        size="small"
                        disableClearable
                        onChange={(
                            _: React.ChangeEvent<{}>,
                            newValue: AxisOption
                        ) => {
                            setX(newValue)
                        }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label={t("bubbleChart.xAxis", "X Axis")}
                                variant="outlined"
                            />
                        )}
                    />
                    <Autocomplete<(typeof props.axisOptions)[0], false, boolean>
                        className={classes.axisSelector}
                        options={props.axisOptions}
                        getOptionLabel={(option) => option.name}
                        value={y}
                        fullWidth
                        size="small"
                        disableClearable
                        onChange={(
                            _: React.ChangeEvent<{}>,
                            newValue: AxisOption
                        ) => {
                            setY(newValue)
                        }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label={t("bubbleChart.yAxis", "Y Axis")}
                                variant="outlined"
                            />
                        )}
                    />
                    {!!z && (
                        <Autocomplete<
                            (typeof props.axisOptions)[0],
                            false,
                            boolean
                        >
                            className={classes.axisSelector}
                            options={props.axisOptions}
                            size="small"
                            getOptionLabel={(option) => option.name}
                            value={z}
                            fullWidth
                            disableClearable
                            onChange={(
                                _: React.ChangeEvent<{}>,
                                newValue: AxisOption
                            ) => {
                                setZ(newValue)
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label={t("bubbleChart.size", "Size")}
                                    variant="outlined"
                                />
                            )}
                        />
                    )}
                </Box>
            </div>
        </Box>
    )
}

const CustomDot = (props) => {
    const {
        cx,
        cy,
        currentAxes,
        node,
        payload,
        tooltipPayload,
        xAxis,
        yAxis,
        zAxis,
        backgroundColor,
    } = props
    const { t } = useAwaitTranslation("feedback")
    const needsResponse =
        payload.noResponseCriteria.find(
            (item) => item.name === xAxis.dataKey
        ) ||
        payload.noResponseCriteria.find(
            (item) => item.name === yAxis.dataKey
        ) ||
        (payload.noResponseCriteria.find(
            (item) => item.name === zAxis.dataKey
        ) &&
            !zAxis.hide)
    let dotWidthAndHeight = !zAxis?.hide
        ? !!payload.noResponseCriteria.find(
              (item) => item.name === zAxis.dataKey
          )
            ? 20
            : (node.z / zAxis.range?.[1]) * 75
        : 40

    if (
        dotWidthAndHeight < 20 ||
        !!isNaN(dotWidthAndHeight) ||
        !dotWidthAndHeight
    ) {
        dotWidthAndHeight = 20
    }

    const x = cx - dotWidthAndHeight / 2
    const y = cy - dotWidthAndHeight / 2

    return (
        <foreignObject
            x={!!isNaN(x) ? 0 : x}
            y={!!isNaN(y) ? 0 : y}
            width={dotWidthAndHeight}
            height={dotWidthAndHeight}
            key={payload.user?.userId + payload.scoredConcept?.id}
        >
            <ClickableRichTooltip
                placement="left"
                content={
                    <Box
                        key={
                            payload.user?.userId +
                            payload.scoredConcept?.id +
                            "box"
                        }
                        minWidth={300}
                        maxWidth={400}
                        overflow="hidden"
                    >
                        <Box width="100%" p={1}>
                            {!!payload.label
                                ? payload.label
                                : `${payload.user?.firstName} ${payload.user?.lastName}`}
                        </Box>
                        <Divider />
                        <ConceptListItem
                            showSummary={true}
                            item={payload.scoredConcept}
                        />

                        <Box p={1}>
                            {currentAxes
                                ?.filter((x) => !!x)
                                .map((axis, index) => {
                                    const axisPayload = tooltipPayload?.find(
                                        (x) => x.dataKey === axis.name
                                    )

                                    return (
                                        <Box
                                            flexGrow={1}
                                            p={1}
                                            key={axis.name + index}
                                            display="flex"
                                            textAlign="center"
                                        >
                                            <Typography
                                                style={{
                                                    flexGrow: 1,
                                                    textAlign: "left",
                                                    marginRight: 12,
                                                }}
                                                variant="body2"
                                                color="textSecondary"
                                            >
                                                {axis.name}
                                            </Typography>
                                            {!axisPayload ||
                                            !!axisPayload?.payload.noResponseCriteria?.find(
                                                (i) =>
                                                    i.name ===
                                                    axisPayload?.dataKey
                                            ) ? (
                                                <Typography variant="caption">
                                                    {t(
                                                        "noResponse",
                                                        "No Response"
                                                    )}
                                                </Typography>
                                            ) : (
                                                <Box>
                                                    {!!axis.prefix
                                                        ? `${axis.prefix}`
                                                        : ""}
                                                    {axisPayload?.value?.toLocaleString()}
                                                    {!!axis.maxEnforced
                                                        ? `/${axis.max}`
                                                        : ""}
                                                    {!!axis.suffix
                                                        ? axis.suffix === "%"
                                                            ? `${axis.suffix}`
                                                            : ` ${axis.suffix}`
                                                        : ""}
                                                </Box>
                                            )}
                                        </Box>
                                    )
                                })}
                        </Box>
                    </Box>
                }
            >
                <Avatar
                    style={{
                        width: dotWidthAndHeight,
                        height: dotWidthAndHeight,
                        cursor: "pointer",
                        border: `3px ${payload.color} solid`,
                        backgroundColor: backgroundColor,
                        opacity: !!needsResponse ? 0.25 : 1,
                    }}
                    src={payload.scoredConcept?.imageUrl}
                >
                    <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        color="black"
                    >
                        {payload.scoredConcept?.title?.[0]}
                    </Box>
                </Avatar>
            </ClickableRichTooltip>
        </foreignObject>
    )
}

export default React.memo(UserScoreBubbleChart)
