import { FetchPolicy, useQuery } from "@apollo/client"
import { Category } from "../../__generated__/types"
import { ENVIRONMENT_CATEGORIES_AND_FIELDS } from "./graphql"
import {
    EnvironmentCategoriesAndFieldsQuery,
    EnvironmentCategoriesAndFieldsQueryVariables,
} from "./__generated__/graphql"

export default function useCategoryTools(props?: {
    fetchPolicy?: FetchPolicy
}) {
    const { data, refetch, networkStatus } = useQuery<
        EnvironmentCategoriesAndFieldsQuery,
        EnvironmentCategoriesAndFieldsQueryVariables
    >(ENVIRONMENT_CATEGORIES_AND_FIELDS, {
        fetchPolicy: props?.fetchPolicy ?? "cache-first",
    })
    const categories = data?.categories ?? null
    const fields = data?.fields ?? null
    const getCategoriesByIds = (value: string[]) => {
        return value
            .map((id) => categories?.find((cat) => cat.id === id))
            ?.filter((x) => !!x)
    }
    const getCategoryArray = (id?: string): Category[] => {
        let array = []
        if (!!id) {
            const category = categories?.find((cat) => cat.id === id)
            if (category) {
                array = [category, ...array]
                if (!!category.parent) {
                    array = [...getCategoryArray(category.parent?.id), ...array]
                }
            }
        }
        return array.filter((x) => !!x)
    }

    const getCategoryDescendents = (initialChildrenIds: string[]) => {
        let array = []
        const descendents = categories?.filter((cat) =>
            initialChildrenIds.includes(cat.id)
        )
        if (descendents) {
            array = [...initialChildrenIds, ...array]
            descendents.forEach((cat) => {
                if (cat.children) {
                    array = [
                        ...getCategoryDescendents(
                            cat.children.map((child) => child.id)
                        ),
                        ...array,
                    ]
                }
            })
        }
        return array.filter((x) => !!x)
    }

    return {
        getCategoryArray,
        getCategoryDescendents,
        environmentCategories: categories || [],
        allEnvironmentFieldIds: fields?.map((field) => field.id) || [],
        getCategoriesByIds,
        networkStatus,
        onRefetchCategories: refetch,
    }
}
