import { FeedbackField } from "../providers/GlobalState"

export enum LanguageTitle {
    en = "English",
    es = "Spanish",
    de = "German",
}
export type LanguageDataObject = {
    title: string
    summary: string
    description: string
    imageUrl?: string | null
}

export type CompletionData = FeedbackField & { isCompleted: boolean }
