import React, { useState } from "react"

import { makeStyles } from "@material-ui/core/styles"
import CircularProgress from "@material-ui/core/CircularProgress"

import { useMutation } from "@apollo/client"
import { GENERATE_AI } from "../graphql/mutations"
import {
    GenerateAiMutation,
    GenerateAiMutationVariables,
} from "../graphql/__generated__/mutations"
import { IconButton, Tooltip } from "@material-ui/core"
import AssistantIcon from "@material-ui/icons/Assistant"
import { ConceptFieldSettings } from "../util/SystemSettings"

const useStyles = makeStyles((theme) => ({
    root: {
        position: "absolute",
        top: 1,
        right: 0,
    },
}))

interface GenerateAIProps {
    maxLength: number
    prompt: string
    onGenerate: (text: string) => void
    onGenerateError: (error: Error) => void
    tooltip?: string
    disabled?: boolean
    size?: "small"
}

export default function GenerateAI(props: GenerateAIProps) {
    const {
        prompt,
        maxLength,
        onGenerate,
        onGenerateError,
        tooltip,
        disabled,
        size,
    } = props
    const classes = useStyles()
    const [loading, setLoading] = useState(false)

    const [generateAI] = useMutation<
        GenerateAiMutation,
        GenerateAiMutationVariables
    >(GENERATE_AI)

    const handleGenerate = async () => {
        setLoading(true)
        try {
            const { data } = await generateAI({
                variables: {
                    prompt,
                    maxLength: Math.round(maxLength / 4),
                },
            })
            if (data?.generateAI) {
                onGenerate(data.generateAI)
            }
        } catch (error) {
            onGenerateError(error)
        }
        setLoading(false)
    }

    return (
        <div className={classes.root}>
            {loading ? (
                <CircularProgress
                    style={{
                        margin: "8px",
                        height: size ? "16px" : "24px",
                        width: size ? "16px" : "24px",
                    }}
                />
            ) : (
                <Tooltip title={tooltip || ""}>
                    <span>
                        <IconButton
                            disabled={!!disabled}
                            color="primary"
                            onClick={handleGenerate}
                            size={size || "medium"}
                        >
                            <AssistantIcon fontSize={size || "medium"} />
                        </IconButton>
                    </span>
                </Tooltip>
            )}
        </div>
    )
}

export const getTitlePrompt = (inputValue: string, categoryName: string) => {
    return `Using the input text below, write a well-formatted, clear and concise description of a ${
        categoryName?.toLowerCase() || "idea"
    }, less than ${ConceptFieldSettings.Title.maxLength.toString()} characters.\nInput: ${
        inputValue || ""
    }\nResponse:`
}

export const getSummaryPrompt = (title: string, categoryName: string) => {
    return `Further expand upon the summary of the ${
        categoryName?.toLowerCase() || "idea"
    } described in the input text below in less than ${ConceptFieldSettings.Summary.maxLength.toString()} characters.\nInput: ${
        title || ""
    }\nResponse:`
}
