import React, { useState, useEffect, useCallback, useRef } from "react"
import {
    createStyles,
    Theme,
    makeStyles,
    useTheme,
} from "@material-ui/core/styles"
import { useQuery } from "@apollo/client"
import { CONNECTION_VIEWER } from "../graphql/queries"
import {
    ConnectionViewerQuery,
    ConnectionViewerQueryVariables,
} from "../graphql/__generated__/queries"
import { Box, useMediaQuery } from "@material-ui/core"
import {
    _ConceptFilter,
    _ConceptOrdering,
    Concept,
} from "../__generated__/types"
import ConnectionOption from "./ConnectionOption"
import ErrorHandler from "./ErrorHandler"
import VirtualInfiniteList from "./VirtualInfiniteList"
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            display: "flex",
            flexDirection: "column",
            width: "100%",
            height: "100%",
        },
        root: {
            width: "100%",
            height: "100%",
            overflow: "hidden",
            position: "relative",
        },
    })
)
type ConnectionProperties = {
    connectableConceptId?: string
    action: "Toggle" | "Remove" | "None"
}
interface ConnectionViewerProps {
    filter: _ConceptFilter
    orderBy: _ConceptOrdering
    editing?: boolean
    connectionProperties?: ConnectionProperties
    onClick?: (concept: Concept) => void
}

const PAGE_SIZE = 12
function ConnectionViewer(props: ConnectionViewerProps) {
    //hooks
    const classes = useStyles()
    const theme = useTheme()
    const mobile = useMediaQuery(theme.breakpoints.down("sm"))
    //state
    const [filter, setFilter] = useState<_ConceptFilter>({})
    const [limit, setLimit] = useState(PAGE_SIZE)

    //refs
    const containerRef = useRef(null)

    //queries
    const { error, data, fetchMore } = useQuery<
        ConnectionViewerQuery,
        ConnectionViewerQueryVariables
    >(CONNECTION_VIEWER, {
        variables: {
            offset: 0,
            first: limit + PAGE_SIZE, //to overscan so the next page is ready
            connectionsFilter: filter,
            orderBy: [props.orderBy],
            id: props.connectionProperties.connectableConceptId,
        },
        fetchPolicy: "cache-and-network",
    })

    //useEffects
    useEffect(() => {
        if (JSON.stringify(props.filter) !== JSON.stringify(filter)) {
            setFilter(props.filter)
            setLimit(PAGE_SIZE)
        }
    }, [props.filter, filter])

    //variables
    const listData = data?.Concept ?? null

    const onLoadMore = useCallback(
        async (offset) => {
            setLimit(offset)
            if (!fetchMore) return
            const result: { data: ConnectionViewerQuery } = await fetchMore({
                variables: {
                    offset,
                },
            })
            return result.data.Concept.length >= PAGE_SIZE
        },
        [fetchMore]
    )

    //query handling
    if (error) {
        return <ErrorHandler message={error.message} showMessage={true} />
    }
    const itemsPerRow = !!mobile ? 1 : 3

    const size = Math.ceil(listData?.length / itemsPerRow)
    return (
        <Box className={classes.root}>
            <div
                className={classes.container}
                ref={containerRef}
                style={{ overflow: "auto", position: "relative" }}
            >
                {!!containerRef.current && !!listData && (
                    <VirtualInfiniteList
                        key={JSON.stringify(filter)}
                        onFetchMore={onLoadMore}
                        startOffset={limit}
                        overscan={Math.ceil(PAGE_SIZE / itemsPerRow)}
                        pageSize={PAGE_SIZE}
                        size={size}
                        containerRef={containerRef}
                    >
                        {(itemIndex) => (
                            <Box
                                display="grid"
                                gridTemplateColumns={`repeat(${itemsPerRow}, 1fr)`}
                                gridGap="15px"
                            >
                                {new Array(itemsPerRow)
                                    .fill(1)
                                    .map((_, index) => {
                                        const item =
                                            listData[
                                                itemIndex * itemsPerRow + index
                                            ]
                                        return item ? (
                                            <ConnectionOption
                                                key={item.id}
                                                item={item}
                                                connectableConceptId={
                                                    props.connectionProperties
                                                        ?.connectableConceptId
                                                }
                                                onClick={props.onClick}
                                                editing={props.editing}
                                                action={
                                                    props.connectionProperties
                                                        ?.action ?? "None"
                                                }
                                                directionToRemove={
                                                    item.detailedConnections.to?.some(
                                                        (connection) =>
                                                            connection.Concept
                                                                ?.id ===
                                                            props
                                                                .connectionProperties
                                                                .connectableConceptId
                                                    )
                                                        ? "from"
                                                        : "to"
                                                }
                                            />
                                        ) : null
                                    })}
                            </Box>
                        )}
                    </VirtualInfiniteList>
                )}
            </div>
        </Box>
    )
}

export default ConnectionViewer
