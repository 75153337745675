import {
    MultipleChoiceSchema,
    NumberSchema,
    TextResponseSchema,
    RankingSchema,
    CollectionSchema,
    DateSchema,
    ChartSchema,
    GroupingSchema,
} from "./CriteriaConfigSchemas"
import { JSONSchema4Type } from "json-schema"
import { CriteriaType } from "./types"
import { FilterItem } from "../filters/util/types"
import MultipleChoiceInterface from "../inputs/multipleChoice/MultipleChoiceInterface"
import MultipleChoiceResponseViewer from "../inputs/multipleChoice/MultipleChoiceResponseViewer"
import CollectionInterface from "../inputs/collection/CollectionInterface"
import TextResponseInterface from "../inputs/textResponse/TextResponseInterface"
import NumberInterface from "../inputs/number/NumberInterface"
import TextResponseReviewer from "../inputs/textResponse/TextResponseReviewer"
import NumberResponseViewer from "../inputs/number/NumberResponseViewer"
import RankingInterface from "../inputs/ranking/RankingInterface"
import RankingResponseViewer from "../inputs/ranking/RankingResponseViewer"
import CollectionResponseViewer from "../inputs/collection/CollectionResponseViewer"
import DateInterface from "../inputs/date/DateInterface"
import DateResponseViewer from "../inputs/date/DateResponseViewer"
import ChartInterface from "../inputs/chart/ChartInterface"
import ChartResponseViewer from "../inputs/chart/ChartResponseViewer"
import GroupingInterface from "../inputs/grouping/GroupingInterface"
import GroupingResponseViewer from "../inputs/grouping/GoupingResponseViewer"
import { Layout } from "react-grid-layout"
import { IconValue } from "../icons/IconPicker"

import Dns from "@material-ui/icons/Dns"
import TextFieldsIcon from "@material-ui/icons/TextFields"
import { LooksOne } from "@styled-icons/material/LooksOne"
import CalendarTodayIcon from "@material-ui/icons/CalendarToday"
import FormatListNumberedRtlIcon from "@material-ui/icons/FormatListNumberedRtl"
import AssessmentIcon from "@material-ui/icons/Assessment"
import GroupWorkIcon from "@material-ui/icons/GroupWork"
import ListAltIcon from "@material-ui/icons/ListAlt"
import { Concept, CriteriaScore } from "../../__generated__/types"

export enum CollectionView {
    list = "list",
    chip = "chip",
    gallery = "gallery",
    table = "table",
}

export const DefaultFieldPropertyValues = {
    instructions: "",
    allowMultipleResponses: true,
}
export type RadarConfigurationType = {
    stages: string[]
}

export type BubbleAxisConfigurationType = {
    positiveXAxisLabel?: string
    negativeXAxisLabel?: string
    positiveYAxisLabel?: string
    negativeYAxisLabel?: string
    quandrantOneLabel?: string
    quandrantOneBackgroundColor?: string
    quandrantTwoLabel?: string
    quandrantTwoBackgroundColor?: string
    quandrantThreeLabel?: string
    quandrantThreeBackgroundColor?: string
    quandrantFourLabel?: string
    quandrantFourBackgroundColor?: string
}
export enum InputSourceValue {
    allConcepts = "allConcepts",
    connections = "connections",
}

export enum InputSourceResponseType {
    primaryResponseOnly = "primaryResponseOnly",
    usersResponseOnly = "usersResponseOnly",
    allResponses = "allResponses",
}
export enum DefaultChart {
    hypeLifeCycle = "hypeLifeCycle",
    productLifeCycle = "productLifeCycle",
    bubble = "bubble",
    radar = "radar",
}
export const DefaultBubbleAxisConfiguration: BubbleAxisConfigurationType = {
    positiveXAxisLabel: "",
    negativeXAxisLabel: "",
    positiveYAxisLabel: "",
    negativeYAxisLabel: "",
    quandrantOneLabel: "",
    quandrantTwoLabel: "",
    quandrantThreeLabel: "",
    quandrantFourLabel: "",
}

export type InputSource = {
    type: InputSourceValue
    manuallySelect?: boolean
    filters?: FilterItem[]
    inputSourceResponseType?: InputSourceResponseType
    manuallySelectedConceptIds?: { id: string }[]
    criteriaIds?: { id: string }[]
}

export const DefaultInputSource: InputSource = {
    type: InputSourceValue.allConcepts,
    inputSourceResponseType: InputSourceResponseType.primaryResponseOnly,
    filters: null,
    manuallySelect: false,
    manuallySelectedConceptIds: null,
}

export enum CriteriaTypeConfigSchemaName {
    MultipleChoiceSchema = "MultipleChoiceSchema",
    NumberSchema = "NumberSchema",
    TextResponseSchema = "TextResponseSchema",
    RankingSchema = "RankingSchema",
    GroupingSchema = "GroupingSchema",
    CollectionSchema = "CollectionSchema",
    DateSchema = "DateSchema",
    ChartSchema = "ChartSchema",
}

export type MultipleChoiceOption = {
    value: string
    id: string
    icon?: IconValue
}

export type MultipleChoiceType = {
    name: string
    instructions: string
    allowMultipleResponses: boolean
    options: MultipleChoiceOption[]
    source?: InputSource
}
const MultipleChoiceDefault: MultipleChoiceType = {
    name: null,
    ...DefaultFieldPropertyValues,
    options: [],
    source: null,
}

export type DateType = {
    name: string
    instructions: string
    allowMultipleResponses: boolean
    range: boolean
    source: InputSource
}
const DateDefault: DateType = {
    name: null,
    ...DefaultFieldPropertyValues,
    range: false,
    source: null,
}
export type NumberType = {
    name: string
    instructions: string
    allowMultipleResponses: boolean
    minimum: number
    maximum: number
    slider: boolean
    source?: InputSource
    prefix?: string
    suffix?: string
}
const NumberDefault: NumberType = {
    name: null,
    ...DefaultFieldPropertyValues,
    minimum: 0,
    maximum: null,
    slider: false,
    prefix: null,
    suffix: null,
    source: null,
}

export type AddedField = {
    id: string
    subfieldIds?: {
        id: string
    }[]
    inputConfig?: TextResponseType | NumberType | MultipleChoiceType | DateType
}

export type TextResponseType = {
    name: string
    instructions: string
    prompt?: string
    promptFields?: AddedField[]
    allowMultipleResponses: boolean
    source?: InputSource
}
const TextResponseDefault: TextResponseType = {
    name: null,
    prompt: null,
    promptFields: [],
    ...DefaultFieldPropertyValues,
    source: null,
}

export type RankingType = {
    name: string
    instructions: string
    allowMultipleResponses: boolean
    source?: InputSource
}
const RankingDefault: RankingType = {
    name: null,
    ...DefaultFieldPropertyValues,
    source: null,
}

export type GroupingType = {
    name: string
    instructions: string
    allowMultipleResponses: boolean
    source?: InputSource
    groups: { value: string; id: string }[]
    groupLayout: Layout[]
}
const GroupingDefault: GroupingType = {
    name: null,
    ...DefaultFieldPropertyValues,
    groups: [],
    source: null,
    groupLayout: [],
}

export type ChartType = {
    name: string
    instructions: string
    allowMultipleResponses: boolean
    source?: InputSource
    chart?: DefaultChart
    bubbleAxisConfiguration: BubbleAxisConfigurationType
    radarStages?: { value: string; id: string }[]
}

const ChartDefault = {
    name: null,
    ...DefaultFieldPropertyValues,
    source: null,
    chart: DefaultChart.hypeLifeCycle,
    bubbleAxisConfiguration: DefaultBubbleAxisConfiguration,
    radarStages: null,
}

export const INTERFACE_REPOSITORY: {
    [key in CriteriaType]: any
} = {
    [CriteriaType.MultipleChoice]: MultipleChoiceInterface,
    [CriteriaType.Ranking]: RankingInterface,
    [CriteriaType.Number]: NumberInterface,
    [CriteriaType.TextResponse]: TextResponseInterface,
    [CriteriaType.Collection]: CollectionInterface,
    [CriteriaType.Date]: DateInterface,
    [CriteriaType.Chart]: ChartInterface,
    [CriteriaType.Grouping]: GroupingInterface,
}

export const ALL_CRITERIA_TYPE_OPTIONS = [
    CriteriaType.MultipleChoice,
    CriteriaType.Number,
    CriteriaType.TextResponse,
    CriteriaType.Ranking,
    CriteriaType.Grouping,
    CriteriaType.Date,
    CriteriaType.Chart,
    CriteriaType.Collection,
]

export const TOP_LEVEL_CRITERIA_OPTIONS = [
    CriteriaType.MultipleChoice,
    CriteriaType.Number,
    CriteriaType.Collection,
    CriteriaType.TextResponse,
    CriteriaType.Date,
    CriteriaType.Chart,
]

export const SUB_LEVEL_CRITERIA_OPTIONS = [
    CriteriaType.MultipleChoice,
    CriteriaType.Number,
    CriteriaType.TextResponse,
    CriteriaType.Ranking,
    CriteriaType.Grouping,
    CriteriaType.Date,
    CriteriaType.Chart,
]

export const VARIABLE_SIZE_CRITERIA_OPTIONS = [
    CriteriaType.MultipleChoice,
    CriteriaType.Number,
    CriteriaType.Date,
]

export const CRITERIA_TYPE_ICONS: {
    [key in CriteriaType]: React.ReactNode
} = {
    [CriteriaType.MultipleChoice]: <Dns fontSize="small" />,
    [CriteriaType.Ranking]: <FormatListNumberedRtlIcon fontSize="small" />,
    [CriteriaType.Number]: (
        <LooksOne style={{ width: "1.25em", height: "1.25em" }} />
    ),
    [CriteriaType.TextResponse]: <TextFieldsIcon fontSize="small" />,
    [CriteriaType.Collection]: <ListAltIcon fontSize="small" />,
    [CriteriaType.Grouping]: <GroupWorkIcon fontSize="small" />,
    [CriteriaType.Date]: <CalendarTodayIcon fontSize="small" />,
    [CriteriaType.Chart]: <AssessmentIcon fontSize="small" />,
}

export const RESPONSE_VIEWER_REPOSITORY: {
    [key in CriteriaType]: any
} = {
    [CriteriaType.MultipleChoice]: MultipleChoiceResponseViewer,
    [CriteriaType.Ranking]: RankingResponseViewer,
    [CriteriaType.Number]: NumberResponseViewer,
    [CriteriaType.TextResponse]: TextResponseReviewer,
    [CriteriaType.Collection]: CollectionResponseViewer,
    [CriteriaType.Grouping]: GroupingResponseViewer,
    [CriteriaType.Date]: DateResponseViewer,
    [CriteriaType.Chart]: ChartResponseViewer,
}

export type CollectionType = {
    name: string
    instructions: string
    view: CollectionView
    allowMultipleResponses: boolean
    singleSelection: boolean
    subfields?: AddedField[]
    requirePrimaryResponse: boolean
    minimum: number
    maximum?: number
    categories: { id: string }[]
    source: InputSource
    allowConceptCreation: boolean
    allowFiltering: boolean
    allowRanking: boolean
    enableSuggestions: boolean
    suggestionCriteriaIds?: AddedField[]
}

const CollectionDefault: CollectionType = {
    name: null,
    ...DefaultFieldPropertyValues,
    view: CollectionView.list,
    subfields: [],
    requirePrimaryResponse: true,
    categories: [],
    singleSelection: false,
    minimum: 1,
    maximum: null,
    source: DefaultInputSource,
    allowConceptCreation: true,
    allowFiltering: true,
    allowRanking: true,
    enableSuggestions: false,
    suggestionCriteriaIds: [],
}

export const CRITERIA_SCHEMA_REPOSITORY: {
    [key in CriteriaTypeConfigSchemaName]: JSONSchema4Type
} = {
    [CriteriaTypeConfigSchemaName.MultipleChoiceSchema]: MultipleChoiceSchema,
    [CriteriaTypeConfigSchemaName.TextResponseSchema]: TextResponseSchema,
    [CriteriaTypeConfigSchemaName.NumberSchema]: NumberSchema,
    [CriteriaTypeConfigSchemaName.RankingSchema]: RankingSchema,
    [CriteriaTypeConfigSchemaName.DateSchema]: DateSchema,
    [CriteriaTypeConfigSchemaName.CollectionSchema]: CollectionSchema,
    [CriteriaTypeConfigSchemaName.ChartSchema]: ChartSchema,
    [CriteriaTypeConfigSchemaName.GroupingSchema]: GroupingSchema,
}

export const CRITERIA_DEFAULT_REPOSITORY: {
    [key in CriteriaType]:
        | MultipleChoiceType
        | NumberType
        | TextResponseType
        | RankingType
        | CollectionType
        | DateType
        | GroupingType
        | ChartType
} = {
    [CriteriaType.MultipleChoice]: MultipleChoiceDefault,
    [CriteriaType.TextResponse]: TextResponseDefault,
    [CriteriaType.Number]: NumberDefault,
    [CriteriaType.Ranking]: RankingDefault,
    [CriteriaType.Date]: DateDefault,
    [CriteriaType.Collection]: CollectionDefault,
    [CriteriaType.Grouping]: GroupingDefault,
    [CriteriaType.Chart]: ChartDefault,
}
export type ConfigurationType =
    | NumberType
    | MultipleChoiceType
    | RankingType
    | TextResponseType
    | RankingType
    | CollectionType
    | DateType
    | ChartType

export interface ChartInterfaceProps {
    inputId: string
    scoredConceptId: string
    score: CriteriaScore
    config: ChartType
}
export interface CollectionInterfaceProps {
    score: CriteriaScore
    config: CollectionType
    inputId: string
    editingConfig: boolean
}
export interface DateInterfaceProps {
    score: CriteriaScore
    config: DateType
    multiInterface?: boolean
}
export interface GroupingInterfaceProps {
    score: CriteriaScore
    config: GroupingType
    inputId: string
    conceptsToScore: Concept[]
}

export type MultipleChoiceInterfaceProps = {
    inputId: string
    scoredConceptId: string
    score: CriteriaScore
    config: MultipleChoiceType
}
export interface NumberInterfaceProps {
    inputId: string
    scoredConceptId: string
    score: CriteriaScore
    config: NumberType
    multiInterface?: boolean
}
export interface RankingInterfaceProps {
    score: CriteriaScore
    conceptsToScore: Concept[]
}

export interface TextResponseInterfaceProps {
    score: CriteriaScore
    isDialog?: boolean
    config: TextResponseType
    multiInterface?: boolean
}

export default function useCriteriaTypes(criteriaType?: CriteriaType) {
    const criteriaSchema = CRITERIA_SCHEMA_REPOSITORY[
        `${criteriaType}Schema`
    ] as any
    const defaultCriteriaConfig = CRITERIA_DEFAULT_REPOSITORY[criteriaType]
    const getDefaultCriteriaConfig = (criteriaType: CriteriaType) => {
        return CRITERIA_DEFAULT_REPOSITORY[criteriaType]
    }
    return {
        criteriaSchema,
        defaultCriteriaConfig,
        getDefaultCriteriaConfig,
    }
}
