import { Avatar, Box } from "@material-ui/core"
import { useTheme } from "@material-ui/core/styles"
import { Team } from "../../__generated__/types"

const TeamAvatar = (props: {
    team: Team
    size?: "small" | "medium" | "large"
    variant: "circle" | "rounded"
}) => {
    const { team, size, variant } = props
    const theme = useTheme()

    let avatarStyles = {}

    if (size === "small") {
        avatarStyles = {
            width: theme.spacing(3),
            height: theme.spacing(3),
        }
    } else if (size === "medium") {
        avatarStyles = {
            width: theme.spacing(4),
            height: theme.spacing(4),
        }
    }
    return (
        <Avatar
            style={{
                color: theme.palette.text.secondary,
                ...avatarStyles,
            }}
            variant={variant || "rounded"}
            src={team.imageUrl}
        >
            <Box>{team.name?.[0]}</Box>
        </Avatar>
    )
}

export default TeamAvatar
