import React, { useState } from "react"
import RichTextEditor, {
    BASE_INITIAL_TEXT,
} from "../text-editor/RichTextEditor"
import Box from "@material-ui/core/Box"
import Button from "@material-ui/core/Button"
import { makeStyles, useTheme } from "@material-ui/core/styles"
import Avatar from "@material-ui/core/Avatar"
import { useMutation, useQuery } from "@apollo/client"
import SingularComment from "./Comment"
import useAwaitTranslation from "../../i18n/useAwaitTranslation"
import { COMMENTS_BY_CONCEPT, CREATE_COMMENT } from "./graphql"
import WarningIcon from "@material-ui/icons/Warning"
import {
    CreateCommentMutation,
    CreateCommentMutationVariables,
    CommentQuery,
    CommentQueryVariables,
} from "./__generated__/graphql"
import { useAuth } from "../../providers/AuthProvider"
import { Comment } from "../../__generated__/types"
import CircularProgress from "@material-ui/core/CircularProgress"
import { Divider, IconButton, Typography } from "@material-ui/core"
import Clear from "@material-ui/icons/Clear"

const useStyles = makeStyles((theme) => ({
    root: {
        //padding: theme.spacing(1),
        overflow: "hidden",
        display: "flex",
        flexDirection: "column",
        //@ts-ignore
        backgroundColor: theme.palette.background.level2,
        flexGrow: 1,
    },
    inputContainer: {
        marginTop: theme.spacing(3),
        padding: theme.spacing(1),
    },
    commentsContainer: {
        overflow: "auto",
        borderRadius: theme.shape.borderRadius,
        padding: 0,
    },
}))

interface CommentsContainerProps {
    conceptId: string
    onClose?: () => void
}

const PAGE_SIZE = 5

export default function CommentsContainer(props: CommentsContainerProps) {
    const classes = useStyles()
    const { conceptId } = props
    const { currentUser } = useAuth()
    const theme = useTheme()
    const [fetching, setFetching] = useState(false)
    const [limit, setLimit] = useState(PAGE_SIZE)
    const [saving, setSaving] = useState(false)
    const { t } = useAwaitTranslation("comments")
    const { data, fetchMore } = useQuery<CommentQuery, CommentQueryVariables>(
        COMMENTS_BY_CONCEPT,
        {
            variables: {
                id: conceptId,
                first: limit,
                offset: 0,
            },
            fetchPolicy: "network-only",
            nextFetchPolicy: "cache-first",
        }
    )

    const [createComment] = useMutation<
        CreateCommentMutation,
        CreateCommentMutationVariables
    >(CREATE_COMMENT, {
        refetchQueries: [
            {
                query: COMMENTS_BY_CONCEPT,
                variables: {
                    id: conceptId,
                    first: data ? data.Comment.length + 1 : 10,
                    offset: 0,
                },
            },
        ],
    })

    const [textValue, setTextValue] = useState(
        JSON.stringify(BASE_INITIAL_TEXT)
    )

    const comments = data?.Comment ?? null
    let displayCount = 0
    let displayButton = true
    let commentCount =
        comments?.length > 0 ? comments?.[0]?.concept?.commentCount : 0
    let difference = commentCount > 0 ? commentCount - comments.length : 0
    if (difference >= PAGE_SIZE) {
        displayCount = PAGE_SIZE
    } else if (difference < PAGE_SIZE && difference !== 0) {
        displayCount = difference
    } else {
        displayButton = false
    }
    const submit = async () => {
        setSaving(true)
        await createComment({
            variables: {
                body: textValue,
                userId: currentUser.userId,
                conceptId: conceptId,
            },
        })
        setTextValue(JSON.stringify(BASE_INITIAL_TEXT))
        setSaving(false)
    }
    const handleFetch = async () => {
        if (fetchMore) {
            setFetching(true)
            const result = await fetchMore({
                variables: {
                    offset: comments?.length,
                },
            })

            setLimit(comments?.length + result?.data?.Comment?.length)
            setFetching(false)
        }
    }
    let changed = JSON.stringify(BASE_INITIAL_TEXT) !== textValue
    const jsonValue = JSON.parse(textValue ?? "[]")
    if (
        jsonValue.length === 1 &&
        jsonValue[0]?.type === "p" &&
        jsonValue[0]?.children?.[0]?.text?.length === 0
    ) {
        changed = false
    }
    return (
        <Box className={classes.root} id="comments">
            <Box
                display="flex"
                justifyContent={"space-between"}
                alignItems="center"
                p={1}
            >
                <Typography variant="caption" color="textSecondary">
                    {t("comments", "Comments")} ({commentCount})
                </Typography>
                {!!props.onClose && (
                    <IconButton size="small" onClick={props.onClose}>
                        <Clear fontSize="small" />
                    </IconButton>
                )}
            </Box>

            {comments && comments?.length > 0 && (
                <>
                    <Divider />
                    <Box className={classes.commentsContainer}>
                        {comments.map((comment: Comment, idx: number) => {
                            return (
                                <Box key={comment.commentId}>
                                    <SingularComment
                                        liked={
                                            comment.likedBy.filter(
                                                (e) =>
                                                    e.userId ===
                                                    currentUser.userId
                                            ).length > 0
                                        }
                                        comment={comment}
                                        conceptId={conceptId}
                                    />
                                </Box>
                            )
                        })}
                        {displayButton && (
                            <Box display="flex" justifyContent="center">
                                <Button
                                    onClick={handleFetch}
                                    disabled={fetching}
                                >
                                    {fetching ? (
                                        <CircularProgress
                                            size={25}
                                            disableShrink
                                        />
                                    ) : (
                                        <>
                                            {t("view", "View")} {displayCount}{" "}
                                            {t(
                                                "moreComments",
                                                "more comments..."
                                            )}
                                        </>
                                    )}
                                </Button>
                            </Box>
                        )}
                    </Box>
                    <Divider />
                </>
            )}

            <Box className={classes.inputContainer}>
                <Box display="flex" maxHeight={300} overflow="hidden">
                    <Box mr={1}>
                        <Avatar src={currentUser?.imageUrl} />
                    </Box>
                    <RichTextEditor
                        editorId={`New_Comment_${conceptId}_${
                            data ? data.Comment.length + 1 : 1
                        }`}
                        placeholderText={t(
                            "writeAComment",
                            "Write a comment..."
                        )}
                        editable={!saving}
                        onChange={setTextValue}
                    />
                </Box>

                <Box
                    display="flex"
                    alignItems={"center"}
                    marginTop={1}
                    mb={10}
                    justifyContent="space-between"
                >
                    <Box p={1} pr={1.5}>
                        <Typography
                            variant="caption"
                            style={{
                                color: theme.palette.text.hint,
                            }}
                        >
                            <WarningIcon
                                fontSize="small"
                                style={{
                                    color: theme.palette.text.hint,
                                    position: "relative",
                                    top: "5px",
                                    marginRight: 4,
                                }}
                            />
                            Comments viewable by all users
                        </Typography>
                    </Box>
                    <Button
                        color="primary"
                        variant="contained"
                        size="small"
                        onClick={submit}
                        disabled={saving || !textValue || !changed}
                    >
                        {saving ? (
                            <CircularProgress size={20} disableShrink />
                        ) : (
                            t("submit", "Submit")
                        )}
                    </Button>
                </Box>
            </Box>
        </Box>
    )
}
