import { Box } from "@material-ui/core"
import React, { useRef, useState, useCallback } from "react"
import ForceGraph2D from "react-force-graph-2d"
import ForceGraph3D from "react-force-graph-3d"
import { SizeMe } from "react-sizeme"
import { Concept } from "../__generated__/types"
import { alpha, lighten, Theme, useTheme } from "@material-ui/core/styles"
import { VIEW_OPTIONS } from "../providers/GlobalState"
import { useHistory } from "react-router-dom"

export const getConceptTooltip = (
    title: string,
    author: string,
    categoryName: string,
    summary: string,
    theme: Theme
) => {
    return `<div style="max-width: 400px;">
                <div style="padding: 4px; font-weight: bold; font-size: ${
                    theme.typography.body1.fontSize
                };">${title} (${categoryName})</div>
                <div style="padding: 4px; font-size: ${
                    theme.typography.body2.fontSize
                };">${summary || ""}</div>
                <div style="padding: 4px; font-size: ${
                    theme.typography.body2.fontSize
                };"> - ${author} </div>
            </div>`
}

function GraphView(props: {
    concepts?: Concept[]
    formattedData?: {
        nodes: any[]
        links: {
            source: string
            target: string
            particleCount?: number
        }[]
    }
    nodeAutoColorBy?: string
    viewMode: VIEW_OPTIONS
    onNodeClick?: (node: any) => void
    enableFocus?: boolean
}) {
    const {
        formattedData,
        nodeAutoColorBy,
        viewMode,
        onNodeClick,

        enableFocus,
    } = props
    const history = useHistory()
    //only used when expanding connections
    const [focusedData, setFocusedData] = useState<{
        id: string
    }>(null)
    const theme = useTheme()
    const fgRef = useRef(null)

    const handleNodeClick = useCallback(
        (node) => {
            if (!!node && node.__typename === "Concept") {
                if (!!onNodeClick) {
                    if (!!enableFocus) {
                        setFocusedData({ id: node.id })
                    }

                    onNodeClick(node)
                } else {
                    history.push(`/concept/${node.id}/home`)
                }
            } else {
                setFocusedData(null)
            }
        },
        [onNodeClick, history, enableFocus]
    )

    return (
        <Box height="100%" width="100%" overflow="hidden">
            <SizeMe monitorHeight monitorWidth>
                {({ size }) => (
                    <Box
                        width={"100%"}
                        height="100%"
                        overflow="hidden"
                        display="flex"
                        onClick={() => setFocusedData(null)}
                    >
                        {viewMode === VIEW_OPTIONS.GRAPH_2D ? (
                            <ForceGraph2D
                                ref={fgRef}
                                width={size.width || 100}
                                height={size.height || 100}
                                graphData={formattedData}
                                cooldownTicks={100}
                                onEngineStop={() => {
                                    if (!focusedData) {
                                        fgRef.current &&
                                            formattedData?.links?.length > 0 &&
                                            fgRef.current.zoomToFit(100)
                                    }
                                }}
                                linkDirectionalParticles={"particleCount"}
                                linkDirectionalParticleWidth={2}
                                linkWidth={1.5}
                                nodeAutoColorBy={nodeAutoColorBy || "category"}
                                linkColor={(_) =>
                                    alpha(theme.palette.text.primary, 0.2)
                                }
                                nodeLabel={"tooltip"}
                                onNodeClick={handleNodeClick}
                                nodeCanvasObject={(
                                    node: any,
                                    ctx,
                                    globalScale
                                ) => {
                                    if (
                                        node.__typename === "Tag" ||
                                        node.__typename === "Category" ||
                                        node.__typename === "Input"
                                    ) {
                                        const label = node.title || node.id
                                        const fontSize =
                                            (12 / globalScale) * 1.5
                                        ctx.font = `${fontSize}px Inter`
                                        const textWidth =
                                            ctx.measureText(label).width

                                        const bckgDimensions = [
                                            textWidth + 1,
                                            fontSize,
                                        ].map((n) => n + fontSize * 0.2)

                                        ctx.fillStyle =
                                            //@ts-ignore
                                            theme.palette.background.level2
                                        ctx.strokeStyle = lighten(
                                            node.color || "#fff",
                                            0.5
                                        )
                                        ctx.fillRect(
                                            node.x - bckgDimensions[0] / 2,
                                            node.y - bckgDimensions[1] / 2,
                                            bckgDimensions[0],
                                            bckgDimensions[1]
                                        )

                                        roundRect(
                                            ctx,
                                            node.x - bckgDimensions[0] / 2,
                                            node.y - bckgDimensions[1] / 2,
                                            bckgDimensions[0],
                                            bckgDimensions[1],
                                            1
                                        )

                                        ctx.textAlign = "center"
                                        ctx.textBaseline = "middle"
                                        ctx.fillStyle =
                                            theme.palette.text.primary
                                        ctx.fillText(label, node.x, node.y)

                                        node.__bckgDimensions = bckgDimensions
                                    } else if (node.__typename === "Concept") {
                                        const fontSize = node.fontSize || 12
                                        node.__bckgDimensions = [
                                            fontSize,
                                            fontSize,
                                        ]
                                        ctx.fillStyle =
                                            focusedData &&
                                            node.id !== focusedData.id
                                                ? alpha(
                                                      theme.palette.background
                                                          .paper,
                                                      0.75
                                                  )
                                                : lighten(
                                                      node.color || "#fff",
                                                      0.5
                                                  )

                                        ctx.strokeStyle =
                                            focusedData &&
                                            node.id !== focusedData.id
                                                ? alpha(
                                                      node.color ??
                                                          theme.palette
                                                              .background.paper,
                                                      0.9
                                                  )
                                                : lighten(
                                                      node.color || "#fff",
                                                      0.5
                                                  )

                                        if (!!node.imageUrl) {
                                            const img = new Image()
                                            img.src = node.imageUrl

                                            ctx.drawImage(
                                                img,
                                                node.x - fontSize / 2,
                                                node.y - fontSize / 2,
                                                fontSize,
                                                fontSize
                                            )
                                            roundRect(
                                                ctx,
                                                node.x - fontSize / 2,
                                                node.y - fontSize / 2,
                                                fontSize,
                                                fontSize,
                                                1,
                                                false,
                                                true
                                            )
                                        } else {
                                            roundRect(
                                                ctx,
                                                node.x - fontSize / 2,
                                                node.y - fontSize / 2,
                                                fontSize,
                                                fontSize,
                                                1,
                                                true,
                                                true
                                            )
                                        }
                                        if (
                                            focusedData &&
                                            node.id !== focusedData.id
                                        ) {
                                            ctx.fill()
                                        }
                                    }
                                }}
                                nodePointerAreaPaint={(
                                    node: any,
                                    color,
                                    ctx
                                ) => {
                                    ctx.fillStyle = color
                                    const bckgDimensions = node.__bckgDimensions
                                    bckgDimensions &&
                                        ctx.fillRect(
                                            node.x - bckgDimensions[0] / 2,
                                            node.y - bckgDimensions[1] / 2,
                                            bckgDimensions[0],
                                            bckgDimensions[1]
                                        )
                                }}
                            />
                        ) : (
                            <ForceGraph3D
                                ref={fgRef}
                                width={size.width || 100}
                                height={size.height || 100}
                                graphData={formattedData}
                                cooldownTicks={100}
                                onEngineStop={() =>
                                    fgRef.current &&
                                    fgRef.current.zoomToFit(400)
                                }
                                nodeAutoColorBy={nodeAutoColorBy || "category"}
                                nodeLabel={"title"}
                                onNodeClick={(node: any, event) => {
                                    if (node.__typename === "Tag") {
                                        // loadMoreArticles({ variables: { tag: node.id } })
                                    } else if (node.__typename === "Concept") {
                                        // window.open(node.url, "_blank")
                                    }
                                }}
                                enablePointerInteraction={true}
                            />
                        )}
                    </Box>
                )}
            </SizeMe>
        </Box>
    )
}

function roundRect(
    ctx,
    x,
    y,
    width,
    height,
    radius = 5,
    fill = false,
    stroke = true
) {
    const adjustedRadius = { tl: radius, tr: radius, br: radius, bl: radius }

    ctx.beginPath()
    ctx.moveTo(x + adjustedRadius.tl, y)
    ctx.lineTo(x + width - adjustedRadius.tr, y)
    ctx.quadraticCurveTo(x + width, y, x + width, y + adjustedRadius.tr)
    ctx.lineTo(x + width, y + height - adjustedRadius.br)
    ctx.quadraticCurveTo(
        x + width,
        y + height,
        x + width - adjustedRadius.br,
        y + height
    )
    ctx.lineTo(x + adjustedRadius.bl, y + height)
    ctx.quadraticCurveTo(x, y + height, x, y + height - adjustedRadius.bl)
    ctx.lineTo(x, y + adjustedRadius.tl)
    ctx.quadraticCurveTo(x, y, x + adjustedRadius.tl, y)

    ctx.closePath()
    if (fill) {
        ctx.fill()
    }
    if (stroke) {
        ctx.stroke()
    }
}

export default React.memo(GraphView)
