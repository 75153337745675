import { User } from "../../__generated__/types"
import { Box, IconButton, Link, Tooltip } from "@material-ui/core"
import TableCell from "@material-ui/core/TableCell"
import TableRow from "@material-ui/core/TableRow"
import { Link as LinkRouter } from "react-router-dom"
import dayjs from "dayjs"
import UserAvatar from "./UserAvatar"
import { useState } from "react"
import DeleteForever from "@material-ui/icons/DeleteForever"
import useUserManagementTools from "./useUserManagementTools"
import ConfirmUserDeletion from "../dialogs/ConfirmUserDeletion"

export default function UserManagerLineItem(props: {
    user: User
    onFocus: () => void
}) {
    const { user, onFocus } = props
    const [hovered, setHovered] = useState(false)
    const { onDeleteUser, deleting, getSystemRoleLabel } =
        useUserManagementTools()
    const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false)
    const handleDeletion = async () => {
        await onDeleteUser(user)
        setConfirmDeleteOpen(false)
    }

    const lastLoginWasToday = dayjs(user.lastLogin?.formatted)
        .fromNow()
        ?.includes("hours")

    return (
        <>
            <TableRow
                onMouseOver={() => {
                    if (!hovered) {
                        setHovered(true)
                    }
                }}
                onMouseLeave={() => {
                    if (!!hovered) {
                        setHovered(false)
                    }
                }}
                hover
                style={{ cursor: "pointer" }}
                onClick={() => onFocus()}
            >
                <TableCell size="small">
                    <UserAvatar user={user} size="large" variant="circle" />
                </TableCell>
                <TableCell size="small">
                    <Link
                        component={LinkRouter}
                        to={`/user/${user?.username}/home`}
                    >
                        {`${user.lastName}, ${user.firstName}`}
                    </Link>
                </TableCell>

                <TableCell>{user.email}</TableCell>
                <TableCell size="small">
                    {getSystemRoleLabel(user.role)}
                </TableCell>
                <TableCell size="small">
                    <Box display="flex" width="100%" alignItems="center">
                        <Box flexGrow={1}>
                            {!!user.lastLogin.formatted
                                ? !!lastLoginWasToday
                                    ? "Today"
                                    : dayjs(user.lastLogin?.formatted).fromNow()
                                : "Yet to login"}
                        </Box>
                        <Box
                            width={25}
                            display="flex"
                            justifyContent={"center"}
                        >
                            {!!hovered && (
                                <Tooltip
                                    title={
                                        !!user.lastLogin.formatted
                                            ? "You are only able to delete users that have not logged in yet"
                                            : "Delete user permanently"
                                    }
                                >
                                    <span>
                                        <IconButton
                                            disabled={
                                                !!user.lastLogin.formatted
                                            }
                                            onClick={(e) => {
                                                e.stopPropagation()
                                                setConfirmDeleteOpen(true)
                                            }}
                                        >
                                            <DeleteForever
                                                color={
                                                    !!user.lastLogin.formatted
                                                        ? "disabled"
                                                        : "error"
                                                }
                                            />
                                        </IconButton>
                                    </span>
                                </Tooltip>
                            )}
                        </Box>
                    </Box>
                </TableCell>
            </TableRow>
            {!!confirmDeleteOpen && (
                <ConfirmUserDeletion
                    onDeletion={() => {
                        handleDeletion()
                    }}
                    deleting={deleting}
                    user={user}
                    onClose={() => setConfirmDeleteOpen(false)}
                />
            )}
        </>
    )
}
