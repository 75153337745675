import React, { useState } from "react"
import { makeStyles } from "@material-ui/core/styles"
import Button from "@material-ui/core/Button"
import Box from "@material-ui/core/Box"
import { TextField, InputAdornment } from "@material-ui/core"
import { Hidden } from "@material-ui/core"
import Table from "@material-ui/core/Table"
import TableBody from "@material-ui/core/TableBody"
import TableCell from "@material-ui/core/TableCell"
import TableContainer from "@material-ui/core/TableContainer"
import TableHead from "@material-ui/core/TableHead"
import TableRow from "@material-ui/core/TableRow"
import AddIcon from "@material-ui/icons/Add"
import UserManagerLineItem from "./UserManagerLineItem"
import { FULL_USER_QUERY } from "./graphql"
import {
    FullUsersQueryVariables,
    FullUsersQuery,
} from "./__generated__/graphql"
import { useQuery } from "@apollo/client"
import IconButton from "@material-ui/core/IconButton"
import { Loading } from "../Loading"
import useAwaitTranslation from "../../i18n/useAwaitTranslation"
import { User } from "../../__generated__/types"
import UserModal from "./UserModal"
import SearchIcon from "@material-ui/icons/Search"
import GuidanceModal, { SettingsSection } from "../settings/GuidanceModal"

import { useParams, useHistory } from "react-router-dom"
import UserForm from "./UserForm"
const useStyles = makeStyles((theme) => ({
    root: {
        padding: theme.spacing(1, 3),
        [theme.breakpoints.down("sm")]: {
            padding: 0,
        },
        height: "100%",
        overflow: "hidden",
        marginBottom: theme.spacing(12),
    },
    teamList: {
        flexGrow: 1,
    },
    avatar: {
        width: theme.spacing(4),
        height: theme.spacing(4),
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.primary.main,
        marginRight: theme.spacing(1),
    },
    flexCell: {
        display: "flex",
        alignItems: "center",
        height: "100%",
    },
    tableContainer: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
        borderRadius: theme.shape.borderRadius,
        border: `1px solid ${theme.palette.divider}`,
    },
    tableHeader: {
        //@ts-ignore
        backgroundColor: `${theme.palette.background.level2} !important`,
    },
    paper: {
        display: "flex",
        flexDirection: "column",
        height: "100%",
    },
    paperUserForm: {
        marginTop: theme.spacing(8),
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
    },
    avatarUserForm: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.primary.main,
    },
    form: {
        width: "100%", // Fix IE 11 issue.
    },

    progress: {
        position: "absolute",
    },
    errorMessage: {
        color: theme.palette.error.main,
    },
    field: {
        padding: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
}))

export default function UserManager() {
    const classes = useStyles()
    const history = useHistory()
    const { data, loading, refetch } = useQuery<
        FullUsersQuery,
        FullUsersQueryVariables
    >(FULL_USER_QUERY)
    const { focusedId } = useParams()

    const [searchString, setSearchString] = useState("")
    const [userFormOpen, setUserFormOpen] = useState(false)

    const users =
        data?.User?.filter((item) =>
            searchString.length > 0
                ? (item.firstName + item.lastName)
                      .toLowerCase()
                      .includes(searchString.toLowerCase())
                : item
        ) ?? []
    const { t } = useAwaitTranslation("login")

    if (loading) {
        return <Loading size={25} hideQuote={true} />
    }

    return (
        <Box className={classes.root}>
            <Box className={classes.paper}>
                <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    width="100%"
                    py={2}
                >
                    <TextField
                        label={t("search", "Search")}
                        placeholder={t("searchUsers", "Search users")}
                        value={searchString}
                        onChange={(e) => {
                            setSearchString(e.target.value)
                        }}
                        variant="outlined"
                        size="small"
                        fullWidth
                        style={{ marginRight: "1rem" }}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchIcon />
                                </InputAdornment>
                            ),
                        }}
                    />
                    <Hidden xsDown>
                        <Button
                            style={{ flexShrink: 0 }}
                            variant="contained"
                            color="primary"
                            onClick={() => setUserFormOpen(true)}
                        >
                            {t("createUser", "Create User")}
                        </Button>
                    </Hidden>
                    <Hidden smUp>
                        <IconButton>
                            <AddIcon />
                        </IconButton>
                    </Hidden>

                    <GuidanceModal section={SettingsSection.Users} />
                </Box>

                <TableContainer className={classes.tableContainer}>
                    <Table stickyHeader>
                        <TableHead>
                            <TableRow>
                                <TableCell
                                    id="avatar"
                                    size="small"
                                    className={classes.tableHeader}
                                    style={{ width: "50px" }}
                                />

                                <TableCell
                                    className={classes.tableHeader}
                                    size="small"
                                ></TableCell>

                                <TableCell
                                    className={classes.tableHeader}
                                ></TableCell>
                                <TableCell
                                    className={classes.tableHeader}
                                    size="small"
                                >
                                    {t("role", "Role")}
                                </TableCell>
                                <TableCell
                                    className={classes.tableHeader}
                                    size="small"
                                >
                                    {t("lastLogin", "Last Login")}
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {users.map((user) => (
                                <UserManagerLineItem
                                    key={user.userId}
                                    user={user}
                                    onFocus={() => {
                                        history.replace(
                                            `/settings/users/${user.username}`
                                        )
                                    }}
                                />
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>
            {!!userFormOpen && (
                <UserForm
                    onCreation={(newUser: User) => {
                        refetch()

                        history.push(`/settings/users/${newUser.username}`)
                    }}
                    onClose={() => setUserFormOpen(false)}
                />
            )}
            {!!focusedId && (
                <UserModal
                    onClose={() => history.replace("/settings/users")}
                    username={focusedId}
                    open={!!focusedId}
                />
            )}
        </Box>
    )
}
