import {
    Box,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
    TableSortLabel,
    Icon,
} from "@material-ui/core"
import WidgetHeader from "./common/WidgetHeader"
import useWidget from "../useWidget"
import { WidgetTypeComponent } from "../useWidgetTypes"
import dayjs from "dayjs"
import { Loading } from "../../Loading"
import ArrowRightAltIcon from "@material-ui/icons/ArrowRightAlt"
import { useState } from "react"
import { CollectionCriteria } from "../CollectionCriteriaField"
import InputWrapper from "../../inputs/InputWrapper"
import { useResultsVisTools } from "../../inputs/useResultsVisTools"
import {
    FeedbackField,
    myCurrentInputResponseLabelVar,
} from "../../../providers/GlobalState"
import ConceptListItem from "../../ConceptListItem"
import { CriteriaType } from "../../criteria/types"
import { Concept, CriteriaScore, Input } from "../../../__generated__/types"
import RichTextEditor from "../../text-editor/RichTextEditor"
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles"
import { useReactiveVar } from "@apollo/client"
import { GroupingResponse } from "../../inputs/grouping/types"
import { ScoreSelectorDefaultValues } from "../../inputs/types"
import { getComparator, stableSort } from "../../table/Sorting"
import useTableTools from "../../table/useTableTools"

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        visuallyHidden: {
            border: 0,
            clip: "rect(0 0 0 0)",
            height: 1,
            margin: -1,
            overflow: "hidden",
            padding: 0,
            position: "absolute",
            top: 20,
            width: 1,
        },
    })
)
type WidgetConfig = {
    label: string
    collectionCriteria?: CollectionCriteria
}

const TableWidget: WidgetTypeComponent = (props) => {
    //hooks

    const { config, isInitialized } = useWidget<WidgetConfig>(props.widget.id)
    const [hovered, setHovered] = useState(false)
    const {
        subCriteriaFields: fields,
        subCriteriaScores: scores,
        orderedCollectionConcepts,
        responseStatusMessageInterface,
    } = useResultsVisTools(
        props.widget.type,
        config?.collectionCriteria || null
    )

    const tableData = orderedCollectionConcepts?.map((concept) => {
        let object = {
            concept,
            conceptWithValues: { ...concept },
            criteriaScores: [],
        }
        fields.forEach((field) => {
            const config = JSON.parse(field.input?.inputConfig ?? "{}")
            const score =
                field.input?.criteria?.criteriaType === CriteriaType.Grouping
                    ? field.scores.find((score) =>
                          score.concepts.find(
                              (scoreConcept) => scoreConcept.id === concept.id
                          )
                      )
                    : field.scores.find(
                          (score) => score.scoredConcept?.id === concept.id
                      )
            if (!!score) {
                object.criteriaScores.push(score)
            }
            const response = score?.response
            object = {
                ...object,
                conceptWithValues: {
                    ...object.conceptWithValues,
                    [JSON.parse(field.input?.inputConfig ?? "{}")?.name]:
                        !response
                            ? ""
                            : field.input?.criteria?.criteriaType ===
                              CriteriaType.MultipleChoice
                            ? config.options.find((op) => op.id === response)
                                  ?.value ?? ""
                            : field.input?.criteria?.criteriaType ===
                              CriteriaType.Number
                            ? Number(response) ?? ""
                            : field.input?.criteria?.criteriaType ===
                              CriteriaType.Grouping
                            ? JSON.parse(response ?? "[]")?.find(
                                  (item: GroupingResponse) =>
                                      item.conceptIds?.includes(concept.id)
                              )?.group?.value ?? ""
                            : response ?? "",
                },
            }
        })

        return object
    })

    return (
        <Box
            component={!props.isDialog ? Paper : "div"}
            display="flex"
            height="100%"
            width="100%"
            flexDirection="column"
            key={props.widget.id}
            onMouseOver={() => {
                if (!props.isDialog && !hovered) {
                    setHovered(true)
                }
            }}
            onMouseLeave={() => {
                if (!props.isDialog && !!hovered) {
                    setHovered(false)
                }
            }}
        >
            <WidgetHeader
                label={config?.label}
                interactionTools={[]}
                configurationTools={[]}
                hovered={hovered || !!props.isDialog}
                widget={props.widget}
                onWidgetDeletion={props.onWidgetDeletion}
                editing={props.editing}
            />
            <InputWrapper
                loading={false}
                message={responseStatusMessageInterface}
            >
                {!!scores && isInitialized ? (
                    <Box key={props.widget.id} overflow="hidden" height="100%">
                        {!config.collectionCriteria ||
                        config.collectionCriteria?.subCriteriaIds?.length ===
                            0 ? (
                            <Box
                                p={1}
                                display="flex"
                                alignItems={"center"}
                                width="100%"
                                justifyContent={"center"}
                            >
                                No criteria selected...
                            </Box>
                        ) : tableData.length > 0 ? (
                            <Box
                                display={"flex"}
                                height="100%"
                                overflow="hidden"
                            >
                                <ResultsTable
                                    data={tableData}
                                    fields={fields}
                                />
                            </Box>
                        ) : (
                            <Box
                                p={1}
                                width="100%"
                                height="100%"
                                textAlign="center"
                            >
                                <Typography color="textSecondary">
                                    No Responses to Display
                                </Typography>
                            </Box>
                        )}
                    </Box>
                ) : (
                    <Loading size={25} hideQuote={true} />
                )}
            </InputWrapper>
        </Box>
    )
}

const ResultsTable = (props: {
    data: {
        concept: Concept
        conceptWithValues: any
        criteriaScores: CriteriaScore[]
    }[]
    fields: FeedbackField[]
}) => {
    const { data, fields } = props
    const classes = useStyles()
    const currentLabel = useReactiveVar(myCurrentInputResponseLabelVar)
    const viewingAllResponses =
        currentLabel?.label === ScoreSelectorDefaultValues.allResponses &&
        !currentLabel.user
    const { order, orderBy, handleRequestSort } = useTableTools()
    const rowItems = data?.map((item) => {
        return {
            ...item.conceptWithValues,
        }
    })

    if (!!viewingAllResponses) {
        return null
    }
    return (
        <TableContainer>
            <Table size="small" stickyHeader>
                <TableHead>
                    <TableRow>
                        <TableCell
                            key={"concept"}
                            sortDirection={orderBy === "title" ? order : false}
                        >
                            <TableSortLabel
                                active={orderBy === "title"}
                                direction={orderBy === "title" ? order : "asc"}
                                onClick={() => handleRequestSort("title")}
                            >
                                Title
                                {orderBy === "title" && (
                                    <span className={classes.visuallyHidden}>
                                        {order === "desc"
                                            ? "sorted descending"
                                            : "sorted ascending"}
                                    </span>
                                )}
                            </TableSortLabel>
                        </TableCell>

                        {fields.map((field) => {
                            const config = JSON.parse(
                                field.input?.inputConfig ?? "{}"
                            )
                            const name =
                                config?.name || field.input?.criteria?.name

                            if (
                                field.input?.criteria?.criteriaType !==
                                CriteriaType.TextResponse
                            ) {
                                return (
                                    <TableCell
                                        key={name}
                                        sortDirection={
                                            orderBy === name ? order : false
                                        }
                                    >
                                        <TableSortLabel
                                            active={orderBy === name}
                                            direction={
                                                orderBy === name ? order : "asc"
                                            }
                                            onClick={() =>
                                                handleRequestSort(name)
                                            }
                                        >
                                            {name}{" "}
                                            {!!config.suffix
                                                ? `(${config.suffix})`
                                                : ""}
                                            {orderBy === name && (
                                                <span
                                                    className={
                                                        classes.visuallyHidden
                                                    }
                                                >
                                                    {order === "desc"
                                                        ? "sorted descending"
                                                        : "sorted ascending"}
                                                </span>
                                            )}
                                        </TableSortLabel>
                                    </TableCell>
                                )
                            } else {
                                return <TableCell key={name}>{name} </TableCell>
                            }
                        })}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {stableSort(rowItems, getComparator(order, orderBy)).map(
                        (item, index) => {
                            const tableItem =
                                data?.find(
                                    (dataItem) =>
                                        dataItem.concept.id === item.id
                                ) ?? null

                            return (
                                <TableRow key={item.concept?.id || index}>
                                    <TableCell>
                                        <ConceptListItem
                                            hideCategory={true}
                                            hideDivider={true}
                                            item={tableItem?.concept}
                                        />
                                    </TableCell>
                                    {fields.map((field) => {
                                        const name =
                                            JSON.parse(
                                                field.input?.inputConfig ?? "{}"
                                            )?.name ||
                                            field.input?.criteria?.name

                                        return (
                                            <TableCell key={name}>
                                                <FormattedField
                                                    value={item[name]}
                                                    score={tableItem?.criteriaScores?.find(
                                                        (score) =>
                                                            score?.input?.id ===
                                                            field.input?.id
                                                    )}
                                                    input={field.input}
                                                />
                                            </TableCell>
                                        )
                                    })}
                                </TableRow>
                            )
                        }
                    )}
                </TableBody>
            </Table>
        </TableContainer>
    )
}

const FormattedField = (props: {
    input: Input
    value?: string
    score?: CriteriaScore
}) => {
    const { input, value, score } = props
    const config = JSON.parse(input.inputConfig ?? "{}")
    if (!!value) {
        switch (input.criteria?.criteriaType) {
            case CriteriaType.Number:
                if (!!value) {
                    return (
                        <Typography variant="body2" noWrap>
                            {!!config.prefix ? `${config.prefix}` : ""}
                            {value.toLocaleString()}
                            {!!config.maximum ? `/${config.maximum}` : ""}
                        </Typography>
                    )
                }
                break
            case CriteriaType.TextResponse:
                return (
                    <Box key={score?.id}>
                        <RichTextEditor
                            editorId={score?.id}
                            editable={false}
                            initialValue={value}
                        />
                    </Box>
                )

            case CriteriaType.MultipleChoice:
                const optionSelected = config?.options?.find(
                    (op) => op.id === score?.response
                )
                return (
                    <Box display="flex" alignItems="center">
                        {!!optionSelected?.icon && (
                            <Icon style={{ marginRight: 8 }}>
                                {optionSelected.icon.name}
                            </Icon>
                        )}

                        <Typography variant="body2" key={score?.id} noWrap>
                            {value}
                        </Typography>
                    </Box>
                )
            case CriteriaType.Grouping:
                return (
                    <Typography variant="body2" key={score?.id} noWrap>
                        {value}
                    </Typography>
                )
            case CriteriaType.Date:
                const start = new Date(JSON.parse(value ?? "[]")?.[0])
                let end = null
                if (!!config.range) {
                    end = JSON.parse(value ?? "[]")?.[1]
                }
                return (
                    <Box key={score?.id} display="flex" alignItems="center">
                        <Typography variant="body2">
                            {dayjs(start).format("MM/DD/YY")}
                        </Typography>
                        {!!config.range && (
                            <>
                                <Box
                                    mr={1}
                                    ml={1}
                                    display="flex"
                                    alignItems="center"
                                >
                                    <ArrowRightAltIcon fontSize="small" />
                                </Box>
                                <Typography variant="body2">
                                    {dayjs(end).format("MM/DD/YY")}
                                </Typography>
                            </>
                        )}
                    </Box>
                )
            default:
                return <Box />
        }
    }
    return <Typography variant="caption" color="textSecondary" noWrap />
}

export default TableWidget
