import React, { ReactElement, useEffect, useState, useCallback } from "react"
import { useAuth } from "../../providers/AuthProvider"
import { useMutation, useQuery } from "@apollo/client"
import {
    Theme,
    Box,
    useMediaQuery,
    IconButton,
    Avatar,
    Divider,
    InputAdornment,
    Grow,
    TextField,
    Typography,
} from "@material-ui/core"
import { useHistory } from "react-router-dom"
import { makeStyles, useTheme } from "@material-ui/core/styles"
import SortableRecursiveList from "../SortableRecursiveList"
import { Autocomplete } from "@material-ui/lab"
import {
    ADD_CONCEPT_TO_LIST,
    CREATE_USER_WORKSPACE,
    GET_CONCEPT_LIST,
} from "../../graphql/conceptListQueries"
import {
    CreateUserWorkspaceMutation,
    CreateUserWorkspaceMutationVariables,
    AddConceptInListsMutation,
    AddConceptInListsMutationVariables,
    GetConceptListCompleteQuery,
    GetConceptListCompleteQueryVariables,
} from "../../graphql/__generated__/conceptListQueries"
import FuzzyConceptSearch from "../FuzzyConceptSearch"
import useAwaitTranslation from "../../i18n/useAwaitTranslation"
import { ConceptList } from "../../__generated__/types"
import { WORKSPACE_QUERY } from "./graphql"
import {
    UserWorkspacesQuery,
    UserWorkspacesQueryVariables,
} from "./__generated__/graphql"
import AddBoxIcon from "@material-ui/icons/AddBox"
import SearchIcon from "@material-ui/icons/Search"
import EditIcon from "@material-ui/icons/Edit"
import useFilters from "../filters/util/useFilters"
const useStyles = makeStyles((theme: Theme) => ({
    root: {
        display: "flex",
        flexDirection: "column",
        overflow: "hidden",
        width: "100%",
    },
    listContainer: {
        overflowX: "hidden",
        overflowY: "auto",
        transition: "background-color 0.2s ease",
        flexGrow: 1,
        minHeight: 100,
        paddingLeft: theme.spacing(0.5),
    },
    actionHover: {
        "&:hover": {
            backgroundColor: theme.palette.action.hover + " !important",
        },
        borderRadius: theme.shape.borderRadius,
        margin: theme.spacing(1),
        padding: theme.spacing(0.5),
        width: "100%",
        paddingBottom: 0,
    },
    avatar: {
        width: theme.spacing(4),
        height: theme.spacing(4),
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.primary.main,
        cursor: "pointer",
    },
    popper: {
        zIndex: theme.zIndex.drawer + 1,
        width: "25em",
    },
    mobilePopper: {
        width: "100%",
        zIndex: theme.zIndex.modal + 1,
    },
    header: {
        color: theme.palette.text.hint,
    },
}))

interface WorkspaceProps {
    closeDrawer?: () => void
}
type Option = {
    type: null | string
    title: string
    fallback: string
    id: string
    imageUrl: null | string
    route: string
    editable: boolean
}
export default function Workspace(props: WorkspaceProps): ReactElement {
    const classes = useStyles()
    const history = useHistory()

    const { t } = useAwaitTranslation("workspace")
    const { getPermissionsFilter } = useFilters()
    const [searchString, setSearchString] = useState("")
    const [listId, setListId] = useState(null)
    const { currentUser } = useAuth()
    const [add, setAdd] = useState(false)
    const [edit, setEdit] = useState(false)
    const [showSearch, setShowSearch] = useState(false)
    const theme = useTheme()

    const mobile = useMediaQuery(theme.breakpoints.down("sm"))
    let filterObject = {
        ...getPermissionsFilter(),
    }
    if (searchString.length > 0) {
        filterObject["title_contains"] = searchString
    }
    const [createWorkspace] = useMutation<
        CreateUserWorkspaceMutation,
        CreateUserWorkspaceMutationVariables
    >(CREATE_USER_WORKSPACE)
    const [addConcept] = useMutation<
        AddConceptInListsMutation,
        AddConceptInListsMutationVariables
    >(ADD_CONCEPT_TO_LIST)
    const { data: listData } = useQuery<
        GetConceptListCompleteQuery,
        GetConceptListCompleteQueryVariables
    >(GET_CONCEPT_LIST, {
        variables: {
            id: listId,
            conceptsFilter: filterObject,
        },
        skip: !listId,
        fetchPolicy: "cache-and-network",
    })
    const { data: userData, loading: loadingUserData } = useQuery<
        UserWorkspacesQuery,
        UserWorkspacesQueryVariables
    >(WORKSPACE_QUERY, {
        variables: {
            userId: currentUser?.userId,
        },
        skip: !currentUser,
    })
    const user = userData?.User[0]

    const createNewWorkspace = useCallback(async () => {
        let result = await createWorkspace({
            variables: {
                userId: currentUser?.userId,
            },
        })
        setListId(result.data.CreateUserWorkspace.id)
    }, [createWorkspace, currentUser])

    useEffect(() => {
        if (user) {
            const workspaceList = user.conceptLists?.[0]
            if (!listId && !workspaceList) {
                createNewWorkspace()
            } else if (!listId) {
                setListId(workspaceList.id)
            }
        }
    }, [user, createWorkspace, createNewWorkspace, listId])

    const handleConceptAddition = (concept) => {
        addConcept({
            variables: {
                from: { id: concept.id },
                to: { id: conceptList.id },
            },
        })
        setAdd(false)
    }

    if (!!!currentUser) {
        return null
    }
    const conceptList: ConceptList = listData?.ConceptList[0]
    let options: Option[] = [
        {
            type: t("personal", "Personal"),
            fallback: user?.firstName?.[0] + user?.lastName?.[0],
            title: t("myFavorites", "My Favorites"),
            id: user?.conceptLists?.[0]?.id,
            imageUrl: user?.imageUrl,
            route: `/user/${user?.username}/home`,
            editable: true,
        },
    ]
    if (!!user && !!user.teams) {
        options = [
            ...options,
            ...user?.teams?.map((team) => {
                return {
                    type: t("teamFavorites", "Team Favorites"),
                    fallback: team.name?.[0],
                    title: team.name,
                    id: team.conceptLists?.[0]?.id,
                    imageUrl: team.imageUrl,
                    route: `/team/${team.teamId}/home`,
                    editable: !!team.admin.find(
                        (user) => user.userId === currentUser?.userId
                    ),
                }
            }),
        ]
    }

    const value = options.filter((option) => option.id === listId)?.[0]

    return (
        !loadingUserData &&
        !!listId && (
            <div className={classes.root}>
                <Box p={1} pb={0.5} display="flex" alignItems="center">
                    <Avatar
                        variant="rounded"
                        src={value?.imageUrl}
                        className={classes.avatar}
                        onClick={() => {
                            history.push(value.route)
                            if (mobile) {
                                props.closeDrawer()
                            }
                        }}
                    >
                        {value?.fallback}
                    </Avatar>

                    <Box className={classes.actionHover}>
                        <Autocomplete
                            options={options}
                            size="small"
                            fullWidth
                            openOnFocus
                            value={value ?? null}
                            onChange={(
                                _: React.ChangeEvent<{}>,
                                newValue: Option
                            ) => {
                                setListId(newValue.id)
                                setEdit(false)
                            }}
                            disableClearable
                            groupBy={(option: Option) => option.type}
                            getOptionLabel={(option: Option) => option.title}
                            renderOption={(option, { selected }) => (
                                <Box
                                    display={"flex"}
                                    alignItems="center"
                                    justifyContent="center"
                                    overflow="hidden"
                                >
                                    <Avatar
                                        style={{
                                            width: 25,
                                            height: 25,
                                            marginRight: 16,
                                        }}
                                        src={option.imageUrl}
                                    />

                                    <Typography variant="body2" noWrap>
                                        {option.title}
                                    </Typography>
                                </Box>
                            )}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    variant="standard"
                                    InputProps={{
                                        ...params.InputProps,
                                        disableUnderline: true,
                                    }}
                                />
                            )}
                        />
                    </Box>
                    {!!value.editable && (
                        <IconButton
                            size="small"
                            onClick={() => {
                                if (edit) {
                                    setEdit(false)
                                    setShowSearch(false)
                                    setAdd(false)
                                } else {
                                    setEdit(true)
                                }
                            }}
                        >
                            <EditIcon
                                fontSize="small"
                                style={{
                                    color: edit
                                        ? theme.palette.primary.main
                                        : "",
                                }}
                            />
                        </IconButton>
                    )}
                </Box>
                <Grow in={edit && !!value.editable} mountOnEnter unmountOnExit>
                    <Box>
                        <Divider />
                        <Box
                            display="flex"
                            justifyContent="flex-end"
                            alignItems="center"
                        >
                            <IconButton
                                onClick={() => {
                                    setShowSearch(!showSearch)
                                    if (add) {
                                        setAdd(false)
                                    }
                                }}
                            >
                                <SearchIcon
                                    color={showSearch ? "secondary" : "inherit"}
                                />
                            </IconButton>

                            <IconButton
                                onClick={() => {
                                    setAdd(!add)
                                    if (showSearch) {
                                        setShowSearch(false)
                                        setSearchString("")
                                    }
                                }}
                            >
                                <AddBoxIcon
                                    color={add ? "secondary" : "inherit"}
                                />
                            </IconButton>
                        </Box>
                    </Box>
                </Grow>
                <Grow in={add || showSearch} mountOnEnter unmountOnExit>
                    <Box width="100%">
                        <Divider />
                        <Box p={2}>
                            {add ? (
                                <FuzzyConceptSearch
                                    onSelection={handleConceptAddition}
                                    disabledOptionIds={
                                        conceptList?.concepts?.map(
                                            (item) => item.id
                                        ) ?? []
                                    }
                                    placeholder={t(
                                        "typeToAddConcepts",
                                        "Type to add concepts..."
                                    )}
                                    inputAdornment={
                                        <AddBoxIcon fontSize="small" />
                                    }
                                    autoFocus={true}
                                />
                            ) : (
                                <TextField
                                    value={searchString}
                                    onChange={(e) => {
                                        setSearchString(e.target.value)
                                    }}
                                    autoFocus
                                    fullWidth
                                    placeholder={t(
                                        "typeToSearchFavorites",
                                        "Type to search favorites..."
                                    )}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <SearchIcon />
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            )}
                        </Box>
                    </Box>
                </Grow>

                <Divider />
                {conceptList && (
                    <Box className={classes.listContainer}>
                        <SortableRecursiveList
                            closeDrawer={props.closeDrawer}
                            list={conceptList}
                            editable={edit && !!value?.editable}
                            conceptFilter={filterObject}
                        />
                    </Box>
                )}
            </div>
        )
    )
}
