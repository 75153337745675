import React from "react"
import { Box } from "@material-ui/core"
import { Concept, CriteriaScore } from "../../../__generated__/types"

import { ChartType, DefaultChart } from "../../criteria/useCriteriaTypes"
import HypeChartInterface from "../../charts/HypeChartInterface"
import ProductChartInterface from "../../charts/ProductChartInterface"
import BubbleChartInterface from "../../charts/BubbleChartInterface"
import RadarChartInterface from "../../charts/RadarChartInterface"
interface ChartResponseViewerProps {
    config: ChartType
    scores: CriteriaScore[]
    conceptsScored: Concept[]
}

const ChartResponseViewer = (props: ChartResponseViewerProps) => {
    const { scores, config, conceptsScored } = props

    const currentScores =
        conceptsScored?.length > 0
            ? scores.filter((score) =>
                  conceptsScored?.find(
                      (concept) => concept.id === score.scoredConcept?.id
                  )
              )
            : scores
    const isSubfield = config?.source?.criteriaIds?.length > 0
    return (
        <Box height="100%" width="100%">
            {config.chart === DefaultChart.hypeLifeCycle && (
                <HypeChartInterface scores={currentScores} />
            )}
            {config.chart === DefaultChart.productLifeCycle && (
                <ProductChartInterface scores={currentScores} />
            )}
            {config.chart === DefaultChart.bubble && (
                <BubbleChartInterface
                    labelType={isSubfield ? "concept" : "user"}
                    scores={currentScores}
                    axisConfiguration={config.bubbleAxisConfiguration}
                />
            )}
            {config.chart === DefaultChart.radar && (
                <RadarChartInterface
                    labelType={isSubfield ? "concept" : "user"}
                    scores={currentScores}
                    stages={config.radarStages || []}
                />
            )}
        </Box>
    )
}

export default ChartResponseViewer
