import React, { useState, useCallback } from "react"
import { CONCEPT_FEED } from "../graphql/queries"
import { useQuery } from "@apollo/client"
import { Box } from "@material-ui/core"
import {
    ConceptFeedQuery,
    ConceptFeedQueryVariables,
} from "../graphql/__generated__/queries"
import ConceptCard from "./ConceptCard"
import VirtualInfiniteList from "./VirtualInfiniteList"
import { _ConceptOrdering } from "../__generated__/types"
const PAGE_SIZE = 10
const ITEMS_PER_ROW = 1
export default function ConceptVirtualList(props) {
    const [limit, setLimit] = useState(PAGE_SIZE)

    const { data, fetchMore } = useQuery<
        ConceptFeedQuery,
        ConceptFeedQueryVariables
    >(CONCEPT_FEED, {
        variables: {
            filter: props.filter,
            offset: 0,
            first: limit,
            sort: _ConceptOrdering.CREATEDAT_DESC,
        },
    })
    const listData = data?.Concept ?? []

    const size = Math.ceil(listData.length / ITEMS_PER_ROW)
    const onFetchMore = useCallback(
        async (offset) => {
            setLimit(offset)
            if (!fetchMore) return
            const result: { data: ConceptFeedQuery } = await fetchMore({
                variables: {
                    offset,
                },
            })

            return result.data.Concept.length >= PAGE_SIZE
        },
        [fetchMore]
    )

    return (
        <Box key={JSON.stringify(props.filter)}>
            <Box pt={1} width="100%">
                <VirtualInfiniteList
                    key={JSON.stringify(props.filter)}
                    onFetchMore={onFetchMore}
                    startOffset={limit}
                    overscan={Math.ceil(10 / ITEMS_PER_ROW)}
                    pageSize={PAGE_SIZE}
                    size={size}
                >
                    {(itemIndex) => (
                        <Box
                            display="grid"
                            gridTemplateColumns={`repeat(${ITEMS_PER_ROW}, 1fr)`}
                            gridGap="15px"
                        >
                            {new Array(ITEMS_PER_ROW)
                                .fill(1)
                                .map((_, index) => {
                                    const item =
                                        listData[
                                            itemIndex * ITEMS_PER_ROW + index
                                        ]
                                    return item ? (
                                        <ConceptCard
                                            key={item.id}
                                            item={item}
                                            nonGrid={ITEMS_PER_ROW === 1}
                                        />
                                    ) : null
                                })}
                        </Box>
                    )}
                </VirtualInfiniteList>
            </Box>
        </Box>
    )
}
