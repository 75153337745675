import React from "react"
import { FormatAlignCenter } from "@styled-icons/material/FormatAlignCenter"
import { FormatAlignJustify } from "@styled-icons/material/FormatAlignJustify"
import { FormatAlignLeft } from "@styled-icons/material/FormatAlignLeft"
import { FormatAlignRight } from "@styled-icons/material/FormatAlignRight"
import { AlignToolbarButton } from "@udecode/plate"
import { Tooltip } from "@material-ui/core"

export const AlignToolbarButtons = () => {
    return (
        <>
            <AlignToolbarButton
                value="left"
                icon={
                    <Tooltip title={"Align left"}>
                        <FormatAlignLeft />
                    </Tooltip>
                }
            />
            <AlignToolbarButton
                value="center"
                icon={
                    <Tooltip title="Align center">
                        <FormatAlignCenter />
                    </Tooltip>
                }
            />
            <AlignToolbarButton
                value="right"
                icon={
                    <Tooltip title="Align right">
                        <FormatAlignRight />
                    </Tooltip>
                }
            />
            <AlignToolbarButton
                value="justify"
                icon={
                    <Tooltip title={"Justify center"}>
                        <FormatAlignJustify />
                    </Tooltip>
                }
            />
        </>
    )
}
