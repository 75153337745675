import { CriteriaType } from "../components/criteria/types"
import {
    MultipleChoiceType,
    NumberType,
    TextResponseType,
} from "../components/criteria/useCriteriaTypes"
import {
    getCleanedFieldConfig,
    getCollectionConcepts,
    getSelectedResponseScores,
} from "../components/inputs/util"
import { BASE_INITIAL_TEXT } from "../components/text-editor/RichTextEditor"
import { FeedbackField, InputResponseLabel } from "../providers/GlobalState"
import { Concept, CriteriaScore } from "../__generated__/types"

export const BASE_PROMPT =
    "You are an expert assistant for Amble Ideation, a knowledge management application."

export const getFieldPrompt = ({
    pageConcept,
    conceptToEvaluate,
    config,
    // only needed when evalutating using prompt fields
    workspaceFields = [],
    currentLabel,
    // only needed if evaluating collections
    connections = [],
    initialWorkspaceLoadTime,
}: {
    pageConcept: Concept
    conceptToEvaluate?: Concept
    config: TextResponseType
    workspaceFields?: FeedbackField[]
    currentLabel?: InputResponseLabel
    connections?: Concept[]
    initialWorkspaceLoadTime?: number
}) => {
    const { name: fieldName, prompt, promptFields, source } = config

    const isSubfield = !!source?.criteriaIds?.[0]?.id
    const contextFields =
        workspaceFields
            .filter((field) => {
                const fieldConfig = getCleanedFieldConfig(field)
                const fieldCriteriaId = field?.input?.criteria?.id
                const sourceCriteriaId =
                    fieldConfig?.source?.criteriaIds?.[0]?.id

                return (
                    promptFields?.some((item) => item.id === fieldCriteriaId) &&
                    (!!isSubfield
                        ? config?.source?.criteriaIds?.[0]?.id ===
                          sourceCriteriaId
                        : !sourceCriteriaId)
                )
            })
            .map((field) => {
                const fieldCriteriaId = field.input?.criteria?.id
                return {
                    field,
                    subfields:
                        promptFields
                            ?.find((item) => item.id === fieldCriteriaId)
                            ?.subfieldIds?.map((subfield) =>
                                workspaceFields.find(
                                    (subfieldItem) =>
                                        subfield.id ===
                                            subfieldItem?.input?.criteria?.id &&
                                        getCleanedFieldConfig(subfieldItem)
                                            ?.source?.criteriaIds?.[0]?.id ===
                                            fieldCriteriaId
                                )
                            )
                            ?.filter((subfield) => !!subfield) ?? [],
                }
            }) ?? []
    let basePrompt = BASE_PROMPT
    // If you are evaluating the page concept with the description accessible
    if (!conceptToEvaluate || pageConcept?.id === conceptToEvaluate?.id) {
        basePrompt += `We're populating the section called "${fieldName}" for a ${
            pageConcept?.category?.name
        } with the following info: 
        Title: "${pageConcept?.title || ""}"\n
        Summary: "${pageConcept?.summary || ""}"\n
        `
        const pageConceptDescription = document.getElementById(
            pageConcept?.id + "_description"
        )?.innerText
        if (!!pageConceptDescription && pageConceptDescription.length > 0) {
            basePrompt += `Description: "${pageConceptDescription}"\n\n`
        }
    } else {
        basePrompt += `We're populating the section called "${fieldName}" for a ${
            conceptToEvaluate?.category?.name
        } with the following info: 
        Title: "${conceptToEvaluate?.title || ""}"\n
        Summary: "${conceptToEvaluate?.summary || ""}"\n
        `
    }
    if (contextFields.length > 0) {
        basePrompt = getContextStrings(
            basePrompt,
            contextFields,
            currentLabel,
            conceptToEvaluate || pageConcept,
            connections,
            initialWorkspaceLoadTime
        )
    }

    const firstMessage = prompt || `Suggest a ${fieldName}`
    return { basePrompt, firstMessage }
}

const getContextStrings = (
    currentPrompt: string,
    contextFields: {
        field: FeedbackField
        subfields: FeedbackField[]
    }[],
    currentLabel: InputResponseLabel,
    conceptToEvaluate: Concept,
    connections: Concept[],
    initialWorkspaceLoadTime: number
): string => {
    contextFields?.map((item) => {
        const { field, subfields } = item
        const {
            input: {
                inputConfig,
                criteria: { criteriaType, name: criteriaName } = {},
            },
        } = field

        const score =
            getSelectedResponseScores(field, currentLabel)?.find(
                (x) => x.scoredConcept?.id === conceptToEvaluate?.id
            ) || null
        const config = JSON.parse(inputConfig ?? "{}")
        const fieldName = config.name || criteriaName
        currentPrompt += `\t${fieldName}: `
        switch (criteriaType as CriteriaType) {
            case CriteriaType.TextResponse:
                currentPrompt += getTextString(score)
                break
            case CriteriaType.Number:
                currentPrompt += getNumberString(score, config)
                break
            case CriteriaType.MultipleChoice:
                currentPrompt += getMultiplerChoiceString(score, config)
                break
            case CriteriaType.Collection:
                const items =
                    getCollectionConcepts(
                        score,
                        config,
                        connections,
                        currentLabel,
                        initialWorkspaceLoadTime
                    )?.map((concept) => ({
                        concept,
                        subfields: subfields.map((sub) => ({
                            ...sub,
                            scores:
                                getSelectedResponseScores(
                                    sub,
                                    currentLabel
                                )?.filter(
                                    (score) =>
                                        score.scoredConcept?.id === concept?.id
                                ) ?? [],
                        })),
                    })) ?? []

                currentPrompt += getCollectionString(items)

                break
            default:
                break
        }
        currentPrompt += `\n\n`
        return currentPrompt
    })
    return currentPrompt
}

const getTextString = (score: CriteriaScore): string => {
    if (
        !!score?.response &&
        score?.response !== JSON.stringify(BASE_INITIAL_TEXT)
    ) {
        const text = document.getElementById(score?.id)?.innerText?.trim()
        if (!!text) {
            return `"${text}"`
        }
        return ``
    }
    return ``
}

const getNumberString = (score: CriteriaScore, config: NumberType): string => {
    if (!!score?.response && !isNaN(Number(score?.response))) {
        const { prefix, suffix, maximum, slider } = config
        if (!!maximum && !!slider) {
            return `"${prefix || ""}${score?.response}${
                suffix || ""
            } out of ${maximum}"`
        } else {
            return `"${prefix || ""}${score?.response}${suffix || ""}"`
        }
    }
    return ``
}

const getMultiplerChoiceString = (
    score: CriteriaScore,
    config: MultipleChoiceType
): string => {
    const { options } = config
    if (!!score?.response && options?.find((op) => op.id === score?.response)) {
        const option = options.find((op) => op.id === score?.response)
        return `"${option?.value}"`
    }
    return ``
}

const getCollectionString = (
    items: {
        concept: Concept
        subfields: FeedbackField[]
    }[] = []
): string => {
    if (!!items && items.length > 0) {
        const headers = [
            "Title",
            ...items?.[0]?.subfields?.map(
                (x) =>
                    getCleanedFieldConfig(x)?.name || x?.input?.criteria?.name
            ),
        ]
        const header = `| ${headers.join(" | ")} |\n|${" --- |".repeat(
            headers.length
        )}`

        const rows = items.map((item) => {
            const { concept, subfields } = item
            const values = [
                concept?.title,
                ...subfields.map((sub) => {
                    const { input } = sub
                    const { inputConfig, criteria } = input
                    const config = JSON.parse(inputConfig ?? "{}")
                    const { criteriaType } = criteria
                    const score = sub?.scores?.[0]

                    switch (criteriaType as CriteriaType) {
                        case CriteriaType.TextResponse:
                            return getTextString(score)
                        case CriteriaType.Number:
                            return getNumberString(score, config)
                        case CriteriaType.MultipleChoice:
                            return getMultiplerChoiceString(score, config)
                        default:
                            return ""
                    }
                }),
            ]
            return `| ${values.join(` | `)} |`
        })
        return `${header}\n${rows.join("\n")}`
    }
    return ""
}
