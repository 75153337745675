import React, { useState } from "react"
import { useQuery } from "@apollo/client"
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles"
import Box from "@material-ui/core/Box"
import Button from "@material-ui/core/Button"
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import ListItemText from "@material-ui/core/ListItemText"
import TextField from "@material-ui/core/TextField"
import SearchIcon from "@material-ui/icons/Search"
import Divider from "@material-ui/core/Divider"
import { InputAdornment } from "@material-ui/core"
import { Loading } from "../Loading"
import { ALL_INDUSTRY_QUERY } from "./graphql"
import { IndustryQuery, IndustryQueryVariables } from "./__generated__/graphql"
import useAwaitTranslation from "../../i18n/useAwaitTranslation"
import IndustryForm from "./IndustryForm"
import GuidanceModal, { SettingsSection } from "../settings/GuidanceModal"
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: "flex",
            flexDirection: "column",
            overflow: "hidden",
            padding: theme.spacing(1, 3),
        },
        list: {
            width: "100%",
            overflowX: "hidden",
            overflowY: "auto",
            backgroundColor: theme.palette.background.paper,
            borderRadius: theme.shape.borderRadius,
            border: `1px solid ${theme.palette.divider}`,
        },
    })
)
export default function IndustryManager() {
    const classes = useStyles()

    const [searchTerm, setSearchTerm] = useState("")
    const [focusedIndustry, setFocusedIndustry] = useState(null)
    const { t } = useAwaitTranslation("industry")
    const { data, loading, error } = useQuery<
        IndustryQuery,
        IndustryQueryVariables
    >(ALL_INDUSTRY_QUERY)
    const [open, setOpen] = useState(false)
    if (error)
        return <Box>{t("thereHasBeenAnError", "There has been an error")}</Box>
    if (loading) return <Loading size={25} hideQuote={true} />
    const handleSearch = (event) => {
        let term = event.target.value.toLowerCase()
        setSearchTerm(term)
    }
    return (
        <Box className={classes.root}>
            <Box py={2} display="flex" alignItems="center">
                <TextField
                    style={{ flexGrow: 1, marginRight: "1rem" }}
                    label={t("search", "Search")}
                    variant="outlined"
                    size="small"
                    onChange={handleSearch}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <SearchIcon />
                            </InputAdornment>
                        ),
                    }}
                />
                <Button
                    onClick={() => {
                        setOpen(true)
                    }}
                    color="primary"
                    variant="contained"
                    style={{ whiteSpace: "nowrap" }}
                >
                    {t("addIndustry", "Add Industry")}
                </Button>
                <GuidanceModal section={SettingsSection.Industries} />
            </Box>
            <List className={classes.list}>
                {data?.Industry?.filter((i) =>
                    i.name.toLowerCase().includes(searchTerm)
                ).map((item, idx) => {
                    return (
                        <Box key={item.industryId}>
                            <ListItem
                                button
                                onClick={() => {
                                    setFocusedIndustry(item)
                                }}
                            >
                                <ListItemText primary={item.name} />
                            </ListItem>
                            {idx + 1 !== data?.Industry?.length && <Divider />}
                        </Box>
                    )
                })}
            </List>
            {(open || !!focusedIndustry) && (
                <IndustryForm
                    onClose={() => {
                        setFocusedIndustry(null)
                        setOpen(false)
                    }}
                    industry={focusedIndustry}
                />
            )}
        </Box>
    )
}
