import { gql } from "@apollo/client"

const CONCEPT_PERMISSIONS = gql`
    query ConceptPermissions($id: ID!) {
        Concept(id: $id) {
            id
            addedUsers {
                type
                updatedAt {
                    formatted
                }
                User {
                    userId
                    firstName
                    lastName
                    imageUrl
                    username
                    email
                    role
                }
            }
            addedTeams {
                type
                updatedAt {
                    formatted
                }
                Team {
                    teamId
                    imageUrl
                    name
                    members(orderBy: lastName_asc) {
                        userId
                        firstName
                        lastName
                        email
                    }
                }
            }
        }
    }
`

const GRANT_USER_ACCESS = gql`
    mutation GrantUserAccess(
        $conceptId: ID!
        $userId: ID!
        $accessType: AccessType!
    ) {
        GrantUserAccess(
            conceptId: $conceptId
            userId: $userId
            accessType: $accessType
        ) {
            id
            title
            summary
            addedUsers {
                User {
                    userId
                    username
                    firstName
                    lastName
                    imageUrl
                }
                type
                updatedAt {
                    formatted
                }
            }
        }
    }
`

const REMOVE_USER_ACCESS = gql`
    mutation RemoveUserAccess($conceptId: ID!, $userId: ID!) {
        RemoveUserAccess(conceptId: $conceptId, userId: $userId) {
            id
            title
            summary
            addedUsers {
                User {
                    userId
                    username
                }
                type
                updatedAt {
                    formatted
                }
            }
        }
    }
`

const GRANT_TEAM_ACCESS = gql`
    mutation GrantTeamAccess(
        $conceptId: ID!
        $teamId: ID!
        $accessType: AccessType!
    ) {
        GrantTeamAccess(
            conceptId: $conceptId
            teamId: $teamId
            accessType: $accessType
        ) {
            id
            addedTeams {
                Team {
                    teamId
                    name
                }
                type
                updatedAt {
                    formatted
                }
            }
        }
    }
`

const REMOVE_TEAM_ACCESS = gql`
    mutation RemoveTeamAccess($conceptId: ID!, $teamId: ID!) {
        RemoveTeamAccess(conceptId: $conceptId, teamId: $teamId) {
            id
            title
            summary
            addedTeams {
                Team {
                    teamId
                    name
                }
                type
                updatedAt {
                    formatted
                }
            }
        }
    }
`

export {
    CONCEPT_PERMISSIONS,
    GRANT_USER_ACCESS,
    REMOVE_USER_ACCESS,
    GRANT_TEAM_ACCESS,
    REMOVE_TEAM_ACCESS,
}
