import React, { useCallback, useRef, useState } from "react"
import {
    Box,
    Divider,
    IconButton,
    LinearProgress,
    List,
    Typography,
    TextField,
    ListSubheader,
    InputAdornment,
    Badge,
    Select,
    MenuItem,
} from "@material-ui/core"
import { useQuery, useReactiveVar } from "@apollo/client"
import {
    useTheme,
    withStyles,
    createStyles,
    Theme,
    alpha,
} from "@material-ui/core/styles"

import InfiniteScroll from "react-infinite-scroller"
import CircularProgress from "@material-ui/core/CircularProgress"
import { ReactComponent as GlobalFilterIcon } from "../../../styles/FilterIcon.svg"
import {
    COLLECTION_OPTIONS_PAGINATED,
    CONCEPT_WITH_CONNECTIONS_FILTERED,
} from "../graphql"
import {
    CollectionOptionsPaginatedQuery,
    CollectionOptionsPaginatedQueryVariables,
    ConceptWithConnectionsFilteredQuery,
    ConceptWithConnectionsFilteredQueryVariables,
} from "../__generated__/graphql"
import useAwaitTranslation from "../../../i18n/useAwaitTranslation"
import {
    Concept,
    CriteriaScore,
    _ConceptFilter,
    _ConceptOrdering,
} from "../../../__generated__/types"
import CollectionItem from "./CollectionItem"

import {
    CollectionType,
    InputSourceResponseType,
} from "../../criteria/useCriteriaTypes"
import { useAuth } from "../../../providers/AuthProvider"
import { useParams } from "react-router-dom"
import {
    FeedVariables,
    myCurrentInputResponseLabelVar,
    myCurrentInputResponseVar,
} from "../../../providers/GlobalState"
import useFilters from "../../filters/util/useFilters"
import ClickableRichTooltip from "../../Popper/ClickableRichTooltip"
import Filters from "../../filters/Filters"
import { ScoreSelectorDefaultValues } from "../types"
import { COLLECTION_FUZZY_SEARCH } from "../../../graphql/queries"

import {
    FuzzySearchByCategoryForCollectionsQuery,
    FuzzySearchByCategoryForCollectionsQueryVariables,
} from "../../../graphql/__generated__/queries"
import Clear from "@material-ui/icons/Clear"
import { ResponseConnectionPopper } from "./BrowserResultPoppers"
import { CriteriaType } from "../../criteria/types"
import useCategoryTools from "../../categories/useCategoryTools"
import CategoryIcon from "../../categories/CategoryIcon"
import { getSelectedResponseScores, getSourceFilters } from "../util"
import ResponsePreviewer from "../ResponsePreviewer"

const PAGE_SIZE = 10

interface CollectionBrowserProps {
    inputId: string
    currentSelectedIds: string[]
    config: CollectionType
    onCreation: (title: string, categoryId: string) => Promise<void>
    onConceptToggle: (concept: Concept, selected: boolean) => Promise<void>
    disabledMessage?: string
}

const CollectionBrowser: React.FunctionComponent<CollectionBrowserProps> = ({
    inputId,
    config,
    onConceptToggle,
    currentSelectedIds,
    onCreation,
    disabledMessage,
}) => {
    const { t } = useAwaitTranslation("feedback")
    const { environmentCategories } = useCategoryTools()
    const categoryOptions = environmentCategories.filter((cat) =>
        config?.categories?.find((item) => item.id === cat.id)
    )

    const { currentResponseData } = useReactiveVar(myCurrentInputResponseVar)
    const currentLabel = useReactiveVar(myCurrentInputResponseLabelVar)
    //checking for matching criteria and that is is NOT a subfield
    const sourceFields = currentResponseData.filter(
        (item) =>
            !(
                JSON.parse(item.input?.inputConfig ?? "{}")?.source?.criteriaIds
                    ?.length > 0
            ) &&
            config?.suggestionCriteriaIds?.find(
                (x) => x.id === item.input.criteria?.id
            )
    )

    const viewingSuggestedConnections = sourceFields?.length > 0

    //this logic needs to be added to useInputTools, or in the getSourceFilter functions
    const sourceScores: CriteriaScore[] = sourceFields.flatMap((field) =>
        config?.source?.inputSourceResponseType ===
        InputSourceResponseType.primaryResponseOnly
            ? field.scores?.filter((score) => !!score.conceptDefault)
            : getSelectedResponseScores(field, currentLabel)
    )

    let sourceConceptIds: string[] = sourceScores
        .flatMap((score) => score.concepts)
        .map((concept) => concept.id)

    const topLevelConnectionsFilter: _ConceptFilter = {
        id_in: sourceConceptIds.filter((id, index) => {
            return sourceConceptIds.indexOf(id) === index
        }),
    }

    const inputRef = useRef(null)
    const viewingPrimaryResponse =
        !currentLabel?.user &&
        currentLabel?.label === ScoreSelectorDefaultValues.primaryResponse
    const theme = useTheme()
    const {
        currentFeedVariables,
        setCurrentFeedVariables,
        getPermissionsFilter,
    } = useFilters({
        filters: config.source.filters,
        categoryIds: config.categories?.map((item) => item.id),
    })

    const [inputValue, setInputValue] = useState("")
    const [creating, setCreating] = useState(false)
    const [categoryToCreate, setCategoryToCreate] = useState<string>(null)
    const { currentUser } = useAuth()
    const { conceptId } = useParams()
    const sourceFilter: _ConceptFilter = !!config.source.manuallySelect
        ? {
              id_in:
                  config.source.manuallySelectedConceptIds
                      ?.map((item) => item.id)
                      ?.filter((x) => !!x) ?? [],
          }
        : {
              ...getSourceFilters({
                  inputConfig: config,
                  userId: currentUser.userId,
                  conceptId,
                  currentLabel,
                  filters: currentFeedVariables.filters,
                  criteriaType: CriteriaType.Collection,
              }),
              ...getPermissionsFilter(),
          }

    const [loadMore, setLoadMore] = useState(true)
    const [limit, setLimit] = useState(PAGE_SIZE)

    const {
        data: suggestedConnectionData,
        loading: loadingSuggestedConnectionData,
    } = useQuery<
        ConceptWithConnectionsFilteredQuery,
        ConceptWithConnectionsFilteredQueryVariables
    >(CONCEPT_WITH_CONNECTIONS_FILTERED, {
        variables: {
            filter: topLevelConnectionsFilter,
            connectionsFilter: {
                category: {
                    id_in: config?.categories?.map((item) => item.id),
                },
            },
        },
        skip: !viewingSuggestedConnections || inputValue.length > 0,
    })

    const { loading, data, fetchMore, refetch } = useQuery<
        CollectionOptionsPaginatedQuery,
        CollectionOptionsPaginatedQueryVariables
    >(COLLECTION_OPTIONS_PAGINATED, {
        variables: {
            offset: 0,
            first: limit,
            filter: sourceFilter,
            orderBy: _ConceptOrdering.CREATEDAT_DESC,
        },
        skip: inputValue.length > 0,
    })

    const {
        loading: loadingFuzzy,
        data: fuzzyData,
        fetchMore: fetchMoreFuzzy,
    } = useQuery<
        FuzzySearchByCategoryForCollectionsQuery,
        FuzzySearchByCategoryForCollectionsQueryVariables
    >(COLLECTION_FUZZY_SEARCH, {
        variables: {
            offset: 0,
            first: limit,
            searchString: inputValue,
            categoryIds: config?.categories?.map((item) => item.id),
            inputId: inputId,
            userId: currentUser.userId,
        },
        skip: inputValue.length === 0,
    })

    //base list data logic
    let listData =
        (inputValue.length > 0
            ? fuzzyData?.fuzzyConceptSearchByCategory
            : data?.Concept) ?? []

    //results data logic
    const submittedNonPrimaryInputScores = currentResponseData
        .find((item) => item.input.id === inputId)
        ?.scores.filter((score) => !score.conceptDefault)

    let conceptObject = {}
    submittedNonPrimaryInputScores
        .flatMap((score) => score.concepts)
        .map((concept) => (conceptObject[concept.id] = concept))
    const resultsData =
        Object.keys(conceptObject)
            .map((id) => conceptObject[id])
            .map((concept) => {
                return {
                    concept: concept,
                    selections: submittedNonPrimaryInputScores.filter(
                        (score) =>
                            !!score.concepts.some(
                                (item) => item.id === concept.id
                            )
                    ),
                }
            })
            .filter((item) => item.selections.length > 0)
            ?.sort((a, b) => {
                return b.selections.length - a.selections.length
            }) ?? []

    /// suggestin data logic
    let suggestedConnectionObject = {}

    suggestedConnectionData?.Concept?.map((concept) => {
        concept.connections.map((connection) => {
            if (!suggestedConnectionObject[connection.id]) {
                suggestedConnectionObject[connection.id] = {
                    concept: connection,
                    suggestionCount: [concept],
                }
            } else {
                suggestedConnectionObject[connection.id].suggestionCount.push(
                    concept
                )
            }
            return suggestedConnectionObject
        })
        return suggestedConnectionObject
    })

    const suggestedConnectionArray: {
        concept: Concept
        suggestionCount: Concept[]
    }[] =
        Object.keys(suggestedConnectionObject)
            .map((field) => {
                return suggestedConnectionObject[field]
            })
            ?.sort((a, b) => {
                return b.suggestionCount.length - a.suggestionCount.length
            }) ?? []

    /// functions
    const handleCreation = async () => {
        setCreating(true)
        await onCreation(
            inputValue,
            categoryToCreate || categoryOptions?.[0]?.id
        )
        setLoadMore(true)
        await refetch({
            offset: 0,
            first: PAGE_SIZE,
            filter: { ...sourceFilter },
        })
        setInputValue("")
        setCreating(false)
        inputRef?.current?.focus()
    }

    //callbacks
    const onLoadMore = useCallback(
        async (page) => {
            if (!creating) {
                if (inputValue.length === 0) {
                    const result = await fetchMore({
                        variables: {
                            offset: page * PAGE_SIZE,
                        },
                    })

                    setLimit(page * PAGE_SIZE + PAGE_SIZE)
                    setLoadMore(
                        !(result.data.Concept.length < page * PAGE_SIZE)
                    )
                } else {
                    const result = await fetchMoreFuzzy({
                        variables: {
                            offset: listData.length,
                            searchString: inputValue,
                            first: listData.length + PAGE_SIZE,
                        },
                    })

                    setLimit(page * PAGE_SIZE + PAGE_SIZE)
                    setLoadMore(
                        !(
                            result.data.fuzzyConceptSearchByCategory.length <
                            page * PAGE_SIZE
                        )
                    )
                }
            }
        },
        [fetchMore, fetchMoreFuzzy, inputValue, listData.length, creating]
    )

    let cleanedAllOptionsData = listData
    if (inputValue.length === 0) {
        cleanedAllOptionsData = listData.filter(
            (item) =>
                !suggestedConnectionArray.find(
                    (suggestion) => suggestion.concept.id === item.id
                ) &&
                (!viewingPrimaryResponse ||
                    !resultsData.find(
                        (result) => result.concept.id === item.id
                    ))
        )
    }
    return (
        <Box display="flex" width="100%" overflow="hidden" height="100%">
            <Box
                display="flex"
                height="100%"
                width="100%"
                overflow="hidden"
                flexDirection={"column"}
            >
                <Box display="flex" justifyContent={"space-between"} pr={1}>
                    <Box flexGrow={1} p={1}>
                        <TextField
                            inputRef={inputRef}
                            disabled={!!creating}
                            variant="outlined"
                            fullWidth
                            label={t("searchOptions", "Search Options")}
                            size="small"
                            value={inputValue}
                            onKeyDown={(e) => {
                                if (
                                    e.key === "Enter" &&
                                    inputValue.length > 0 &&
                                    !!config.allowConceptCreation
                                ) {
                                    handleCreation()
                                }
                            }}
                            InputProps={{
                                endAdornment: !!creating ? (
                                    <InputAdornment position="end">
                                        <CircularProgress
                                            size={20}
                                            disableShrink
                                        />
                                    </InputAdornment>
                                ) : inputValue.length > 0 ? (
                                    <InputAdornment position="end">
                                        <IconButton
                                            onClick={() => setInputValue("")}
                                            size="small"
                                        >
                                            <Clear fontSize="small" />
                                        </IconButton>
                                    </InputAdornment>
                                ) : null,
                            }}
                            onChange={(e) => {
                                setLoadMore(true)
                                setLimit(PAGE_SIZE)
                                setInputValue(e.target.value)
                            }}
                        />
                        {!!config.allowConceptCreation && (
                            <Box display="flex" alignItems="center" pl={0.5}>
                                <Typography
                                    variant="caption"
                                    color="textSecondary"
                                >
                                    {t(
                                        "enterToCreateNew",
                                        "Enter to create new"
                                    )}
                                </Typography>
                                {categoryOptions?.length > 1 && (
                                    <Select
                                        style={{ marginLeft: 10 }}
                                        variant="standard"
                                        value={
                                            categoryToCreate ||
                                            categoryOptions?.[0]?.id
                                        }
                                        disableUnderline
                                        onChange={(e) => {
                                            setCategoryToCreate(
                                                e.target.value as string
                                            )
                                        }}
                                    >
                                        {categoryOptions?.map((category) => {
                                            return (
                                                <MenuItem
                                                    key={category.id}
                                                    value={category.id}
                                                >
                                                    <Box
                                                        display="flex"
                                                        alignItems="center"
                                                        color={
                                                            theme.palette.text
                                                                .secondary
                                                        }
                                                    >
                                                        <CategoryIcon
                                                            category={category}
                                                            fontSize="small"
                                                        />
                                                        <Typography
                                                            style={{
                                                                marginLeft:
                                                                    theme.spacing(
                                                                        1
                                                                    ),
                                                            }}
                                                            variant="body2"
                                                        >
                                                            {category.name}
                                                        </Typography>
                                                    </Box>
                                                </MenuItem>
                                            )
                                        })}
                                    </Select>
                                )}
                            </Box>
                        )}
                    </Box>

                    <ClickableRichTooltip
                        arrow={true}
                        content={
                            <Box width="30em" zIndex={15000}>
                                <Filters
                                    excludeCategory={true}
                                    onChangeFeedVariables={(
                                        value: FeedVariables
                                    ) => {
                                        setCurrentFeedVariables({
                                            ...currentFeedVariables,
                                            ...value,
                                        })
                                    }}
                                    feedVariables={currentFeedVariables}
                                />
                            </Box>
                        }
                    >
                        <Badge
                            showZero={false}
                            overlap="circle"
                            style={{ marginTop: theme.spacing(1) }}
                            color="primary"
                            badgeContent={
                                inputValue.length > 0
                                    ? 0
                                    : currentFeedVariables.filters.filter(
                                          (filter) => filter.value.length > 0
                                      )?.length
                            }
                        >
                            <IconButton disabled={inputValue.length > 0}>
                                <GlobalFilterIcon
                                    style={{
                                        width: "1em",
                                        height: "1em",
                                        fill: "currentColor",
                                    }}
                                />
                            </IconButton>
                        </Badge>
                    </ClickableRichTooltip>
                </Box>
                <Divider />
                <Box
                    display="flex"
                    flexDirection="column"
                    overflow="hidden"
                    width="100%"
                    height="100%"
                >
                    {!!loading ||
                    !!loadingFuzzy ||
                    loadingSuggestedConnectionData ? null : !loading &&
                      !loadingFuzzy &&
                      !loadingSuggestedConnectionData &&
                      listData.length === 0 &&
                      resultsData.length === 0 ? (
                        <Box
                            p={1}
                            textAlign="center"
                            width="100%"
                            height="100%"
                        >
                            <Typography variant="body2" color="textSecondary">
                                {t(
                                    "noConceptsToDisplay",
                                    "No concepts to display"
                                )}
                            </Typography>
                        </Box>
                    ) : (
                        <List
                            disablePadding
                            style={{ overflow: "auto", height: "100%" }}
                        >
                            <InfiniteScroll
                                key={JSON.stringify(!!creating)}
                                pageStart={0}
                                loadMore={onLoadMore}
                                hasMore={loadMore}
                                loader={
                                    <div
                                        key={0}
                                        style={{ textAlign: "center" }}
                                    >
                                        <CircularProgress
                                            disableShrink
                                            size={25}
                                        />
                                    </div>
                                }
                                useWindow={false}
                            >
                                {/* RESULTS DATA */}
                                {viewingPrimaryResponse &&
                                    resultsData.length > 0 && (
                                        <>
                                            <ListSubheader
                                                style={{
                                                    backgroundColor:
                                                        theme.palette.background
                                                            .paper,
                                                    zIndex: 1,
                                                }}
                                            >
                                                {t(
                                                    "responseResults",
                                                    "Response Results"
                                                )}
                                            </ListSubheader>
                                            {resultsData.map((item, idx) => {
                                                const selected =
                                                    currentSelectedIds.includes(
                                                        item.concept.id
                                                    )

                                                const percentFull = Math.round(
                                                    (item.selections.length /
                                                        resultsData[0]
                                                            ?.selections
                                                            .length) *
                                                        100
                                                )

                                                return (
                                                    <Box
                                                        key={
                                                            item.concept.id +
                                                            idx
                                                        }
                                                        position="relative"
                                                    >
                                                        <Box
                                                            position="absolute"
                                                            right={theme.spacing(
                                                                1
                                                            )}
                                                            bottom={0}
                                                            top={0}
                                                            left={theme.spacing(
                                                                !!config?.singleSelection
                                                                    ? 1
                                                                    : 5
                                                            )}
                                                            display="flex"
                                                            alignItems={
                                                                "center"
                                                            }
                                                        >
                                                            <BorderLinearProgress
                                                                value={
                                                                    percentFull
                                                                }
                                                                variant="determinate"
                                                            />
                                                        </Box>

                                                        <CollectionItem
                                                            item={item.concept}
                                                            singleSelection={
                                                                config.singleSelection
                                                            }
                                                            editable={true}
                                                            selected={selected}
                                                            onAction={
                                                                onConceptToggle
                                                            }
                                                            action="toggle"
                                                            listItemSecondaryAction={
                                                                item.selections
                                                                    .length >
                                                                0 ? (
                                                                    <ResponsePreviewer
                                                                        inputId={
                                                                            inputId
                                                                        }
                                                                        scoredConceptId={
                                                                            item
                                                                                .concept
                                                                                ?.id
                                                                        }
                                                                        hideValue={
                                                                            true
                                                                        }
                                                                        popper={
                                                                            true
                                                                        }
                                                                    />
                                                                ) : null
                                                            }
                                                            disabledMessage={
                                                                disabledMessage
                                                            }
                                                            searchString={
                                                                inputValue
                                                            }
                                                        />
                                                    </Box>
                                                )
                                            })}
                                        </>
                                    )}

                                {/* SUGGESTED CONNECTIONS DATA */}
                                {!!viewingSuggestedConnections &&
                                    suggestedConnectionArray.length > 0 && (
                                        <>
                                            <ListSubheader
                                                style={{
                                                    backgroundColor:
                                                        theme.palette.background
                                                            .paper,
                                                    zIndex: 2,
                                                }}
                                            >
                                                {t("suggested", "Suggested")}
                                            </ListSubheader>
                                            {suggestedConnectionArray.map(
                                                (item, idx) => {
                                                    const selected =
                                                        currentSelectedIds.includes(
                                                            item.concept.id
                                                        )

                                                    const percentFull =
                                                        Math.round(
                                                            (item
                                                                .suggestionCount
                                                                .length /
                                                                suggestedConnectionArray[0]
                                                                    ?.suggestionCount
                                                                    .length) *
                                                                100
                                                        )

                                                    return (
                                                        <Box
                                                            key={
                                                                item.concept
                                                                    .id + idx
                                                            }
                                                            position="relative"
                                                        >
                                                            <Box
                                                                position="absolute"
                                                                right={theme.spacing(
                                                                    1
                                                                )}
                                                                bottom={0}
                                                                top={0}
                                                                left={theme.spacing(
                                                                    !!config?.singleSelection
                                                                        ? 1
                                                                        : 5
                                                                )}
                                                                display="flex"
                                                                alignItems={
                                                                    "center"
                                                                }
                                                            >
                                                                <BorderLinearProgress
                                                                    value={
                                                                        percentFull
                                                                    }
                                                                    variant="determinate"
                                                                />
                                                            </Box>

                                                            <CollectionItem
                                                                item={
                                                                    item.concept
                                                                }
                                                                singleSelection={
                                                                    config.singleSelection
                                                                }
                                                                editable={true}
                                                                selected={
                                                                    selected
                                                                }
                                                                onAction={
                                                                    onConceptToggle
                                                                }
                                                                action="toggle"
                                                                searchString={
                                                                    inputValue
                                                                }
                                                                listItemSecondaryAction={
                                                                    <ResponseConnectionPopper
                                                                        concepts={
                                                                            item.suggestionCount
                                                                        }
                                                                    />
                                                                }
                                                                disabledMessage={
                                                                    disabledMessage
                                                                }
                                                            />
                                                        </Box>
                                                    )
                                                }
                                            )}
                                        </>
                                    )}
                                {/* REGULAR OPTIONS DATA */}
                                <ListSubheader
                                    style={{
                                        backgroundColor:
                                            theme.palette.background.paper,
                                        zIndex: 2,
                                    }}
                                >
                                    {t("allOptions", "All Options")}
                                </ListSubheader>

                                {cleanedAllOptionsData.map((item, idx) => {
                                    const selected =
                                        currentSelectedIds.includes(item.id)

                                    return (
                                        <Box
                                            key={
                                                item.id + idx + "-non-response"
                                            }
                                        >
                                            <CollectionItem
                                                item={item}
                                                singleSelection={
                                                    config.singleSelection
                                                }
                                                editable={true}
                                                selected={selected}
                                                onAction={onConceptToggle}
                                                searchString={inputValue}
                                                action="toggle"
                                                disabledMessage={
                                                    disabledMessage
                                                }
                                            />
                                        </Box>
                                    )
                                })}
                            </InfiniteScroll>
                        </List>
                    )}
                </Box>
            </Box>
        </Box>
    )
}

const BorderLinearProgress = withStyles((theme: Theme) =>
    createStyles({
        root: {
            height: "85%",
            flexGrow: 1,
            borderRadius: theme.shape.borderRadius,
        },
        colorPrimary: {
            backgroundColor: "transparent",
        },
        bar: {
            borderRadius: theme.shape.borderRadius,
            backgroundColor: alpha(theme.palette.primary.main, 0.15),
        },
    })
)(LinearProgress)

export default React.memo(CollectionBrowser)
