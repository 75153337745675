import React, { useEffect, useMemo } from "react"
import { Combobox } from "@udecode/plate-ui-combobox"
import {
    comboboxStore,
    getPluginOptions,
    usePlateEditorRef,
} from "@udecode/plate"
import match from "autosuggest-highlight/match"
import parse from "autosuggest-highlight/parse"
import { ELEMENT_MENTION, getMentionOnSelectItem } from "@udecode/plate-mention"
import { Avatar, Box, Typography } from "@material-ui/core"
import { useLazyQuery } from "@apollo/client"

import { User } from "../../../__generated__/types"
import useMountedState from "../../../util/useMountedState"
import { USER_FILTERED } from "../../users/graphql"
import {
    UserFilteredQuery,
    UserFilteredQueryVariables,
} from "../../users/__generated__/graphql"
import { toCapitalizedWords } from "../../../util/fns"

export const UserMention = () => {
    const isMounted = useMountedState()
    const loadingKey = "loading_users"
    const loadingItem = [{ key: loadingKey, text: "Loading Users" }]
    const [loadOptions, { data, loading }] = useLazyQuery<
        UserFilteredQuery,
        UserFilteredQueryVariables
    >(USER_FILTERED, {
        fetchPolicy: "no-cache",
    })
    const formatItems = (fetchedData: User[]) => {
        return (
            fetchedData?.map((user, index) => {
                return {
                    key: user.username,
                    text: `${user.firstName} ${user.lastName}`,
                    data: user,
                }
            }) || []
        )
    }

    //editor and get trigger based on editor
    const editor = usePlateEditorRef()

    //focused editorId and id of open combobox

    const activeId = comboboxStore.use.activeId()
    const { trigger } = getPluginOptions(editor, ELEMENT_MENTION) as any

    //store value identifying if combobox is open
    const open = comboboxStore.use.isOpen()
    const text = comboboxStore.use.text()

    const isOpen = useMemo(() => {
        if (!open || activeId !== ELEMENT_MENTION) {
            return null
        }
        return true
    }, [open, activeId])

    useEffect(() => {
        if (isOpen && !!isMounted()) {
            const queryText = text || "*"
            loadOptions({
                variables: {
                    filter: {
                        OR: [
                            {
                                firstName_contains: queryText,
                            },
                            {
                                firstName_contains:
                                    toCapitalizedWords(queryText),
                            },
                            {
                                lastName_contains: queryText,
                            },
                            {
                                lastName_contains:
                                    toCapitalizedWords(queryText),
                            },
                        ],
                    },
                    first: 10,
                },
            })
        }
    }, [isOpen, text, isMounted, loadOptions])

    const users = data?.User ?? null
    return (
        <Combobox
            id={ELEMENT_MENTION}
            controlled
            trigger={trigger}
            items={!!loading ? loadingItem : !data ? [] : formatItems(users)}
            onRenderItem={(props) => {
                const titleMatches = match(props.item.text, text || "")
                const partsTitle = parse(props.item.text, titleMatches)
                return (
                    <Box
                        key={props.item.key}
                        display="flex"
                        alignItems={"center"}
                    >
                        {props.item.key !== loadingKey && (
                            <Avatar
                                //@ts-ignore
                                src={props.item.data?.imageUrl}
                                style={{ width: 25, height: 25 }}
                            />
                        )}
                        <Box ml={props.item.key === loadingKey ? 0 : 1}>
                            <Typography variant="body2">
                                {partsTitle.map((part, index) => (
                                    <span
                                        key={index}
                                        style={{
                                            fontWeight: part.highlight
                                                ? 800
                                                : 400,
                                        }}
                                    >
                                        {part.text}
                                    </span>
                                ))}
                            </Typography>
                        </Box>
                    </Box>
                )
            }}
            onSelectItem={getMentionOnSelectItem({ key: ELEMENT_MENTION })}
        />
    )
}
