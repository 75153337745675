import React, { useState } from "react"
import { makeStyles } from "@material-ui/core/styles"
import { useQuery, useMutation } from "@apollo/client"
import {
    Typography,
    Avatar,
    Box,
    Divider,
    IconButton,
    useMediaQuery,
    useTheme,
    Tab,
    Tabs,
} from "@material-ui/core"
import { USER_PROFILE, ADD_USER_HOME_CONCEPT } from "./graphql"
import {
    UserProfileQuery,
    UserProfileQueryVariables,
    AddUserHomeConceptMutation,
    AddUserHomeConceptMutationVariables,
} from "./__generated__/graphql"
import { useAuth } from "../../providers/AuthProvider"
import SettingsIcon from "@material-ui/icons/Settings"
import { useHistory } from "react-router-dom"
import { Loading } from "../Loading"
import { useParams } from "react-router-dom"
import { SizeMe } from "react-sizeme"
import { CREATE_CONCEPT } from "../../graphql/mutations"
import {
    CreateConceptCompleteMutation,
    CreateConceptCompleteMutationVariables,
} from "../../graphql/__generated__/mutations"
import { AccessType } from "../../__generated__/types"
import ConceptVirtualList from "../ConceptVirtualList"
import useAwaitTranslation from "../../i18n/useAwaitTranslation"
const drawerWidth = 260
const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        overflow: "hidden",
        width: "100%",
    },
    drawerPaper: {
        width: drawerWidth,
        backgroundColor: theme.palette.background.default,
    },

    headerContainer: {
        flexGrow: 1,
        overflow: "hidden",
        width: "100%",
        position: "fixed",
        zIndex: theme.zIndex.appBar - 1,
        //@ts-ignore
        backgroundColor: theme.palette.background.level2,
        marginTop: theme.spacing(6) - 1,
        borderTop: `1px solid ${theme.palette.divider}`,
        [theme.breakpoints.down("sm")]: {
            right: 0,
            left: 0,
        },
    },
    drawer: {
        zIndex: theme.zIndex.appBar - 1,
        width: drawerWidth,
        flexShrink: 0,
    },
    closedDrawer: {
        zIndex: theme.zIndex.appBar - 1,
        width: 0,
        flexShrink: 0,
    },
    header: {
        display: "flex",
        flexDirection: "column",
        zIndex: theme.zIndex.appBar - 1,
    },
    avatar: {
        height: 100,
        width: 100,
        [theme.breakpoints.down("sm")]: {
            height: 75,
            width: 75,
        },
        margin: theme.spacing(0, 1),
    },
    imageFallback: {
        height: 100,
        width: 100,
        fontSize: "3rem",
        margin: theme.spacing(0, 1),

        backgroundColor: theme.palette.primary.main,
        color: theme.palette.text.primary,
    },
    listContainer: {
        marginTop: 225,
        [theme.breakpoints.down("sm")]: {
            marginTop: 200,
        },
        padding: theme.spacing(1),
    },
    toolbar: {
        //@ts-ignore
        backgroundColor: theme.palette.background.level2,
        display: "flex",
    },
    toolbarContainer: {
        position: "fixed",
        width: "100%",
        overflow: "hidden",
        zIndex: theme.zIndex.appBar - 1,
        height: theme.spacing(6),
        borderBottom: `1px solid ${theme.palette.divider}`,
        [theme.breakpoints.down("sm")]: {
            right: 0,
            left: 0,
        },
        display: "flex",
    },
}))

export default function UserPublicProfile(props) {
    const classes = useStyles()
    const { username } = useParams()

    const theme = useTheme()
    const mobile = useMediaQuery(theme.breakpoints.down("sm"))
    const history = useHistory()
    const [tab, setTab] = useState(0)
    const { currentUser } = useAuth()
    const [createPersonalConcept] = useMutation<
        CreateConceptCompleteMutation,
        CreateConceptCompleteMutationVariables
    >(CREATE_CONCEPT)
    const [addHomeConcept] = useMutation<
        AddUserHomeConceptMutation,
        AddUserHomeConceptMutationVariables
    >(ADD_USER_HOME_CONCEPT)
    const { data, loading, error, refetch } = useQuery<
        UserProfileQuery,
        UserProfileQueryVariables
    >(USER_PROFILE, {
        variables: {
            username: username,
        },
    })
    const { t } = useAwaitTranslation("user")

    const user = data?.user[0] ?? null

    if (loading || !user) {
        return <Loading size={25} hideQuote={true} />
    }
    if (error) {
        return <div>Could not retrieve user...</div>
    }

    const handleSettings = () => {
        history.push(`/myaccount`)
    }

    const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        setTab(newValue)
    }
    const ownAccount = currentUser.userId === data.user?.[0].userId
    const imageFallback: string =
        (user?.firstName &&
            user?.lastName &&
            user?.firstName[0] + user?.lastName[0]) ??
        ""
    return (
        <Box display="flex" style={{ overflowX: "hidden" }}>
            <SizeMe monitorWidth monitorHeight>
                {({ size }) => (
                    <>
                        <Box className={classes.root}>
                            <Box className={classes.toolbarContainer}>
                                <Box
                                    className={classes.toolbar}
                                    width={!!mobile ? "100vw" : size.width}
                                    justifyContent={
                                        ownAccount
                                            ? "space-between"
                                            : "flex-end"
                                    }
                                >
                                    {ownAccount && (
                                        <IconButton onClick={handleSettings}>
                                            <SettingsIcon />
                                        </IconButton>
                                    )}
                                </Box>
                                <Divider />
                            </Box>

                            <Box className={classes.headerContainer}>
                                <Box
                                    className={classes.header}
                                    width={!!mobile ? "100vw" : size.width}
                                >
                                    <Box display="flex" flexGrow={1} p={1}>
                                        <Box p={1}>
                                            <Avatar
                                                className={classes.avatar}
                                                src={user?.imageUrl}
                                            >
                                                {imageFallback}
                                            </Avatar>
                                        </Box>

                                        <Box p={1} flexGrow={1}>
                                            <Typography
                                                variant={mobile ? "h6" : "h4"}
                                            >
                                                {user?.firstName}{" "}
                                                {user?.lastName}
                                            </Typography>

                                            <Typography
                                                variant="body2"
                                                color="textSecondary"
                                            >
                                                {user?.bio}
                                            </Typography>
                                        </Box>
                                    </Box>
                                    <Divider />
                                    <Box key={size.width}>
                                        <Tabs
                                            value={tab}
                                            onChange={handleChange}
                                            indicatorColor="primary"
                                            textColor="primary"
                                            centered
                                        >
                                            <Tab
                                                label={t(
                                                    "submissions",
                                                    "Submissions"
                                                )}
                                            />
                                            <Tab label={t("likes", "Likes")} />
                                        </Tabs>
                                    </Box>
                                    <Divider />
                                </Box>
                            </Box>
                            <Box
                                className={classes.listContainer}
                                key={tab}
                                width={size.width}
                            >
                                {tab === 0 && (
                                    <ConceptVirtualList
                                        filter={{
                                            createdBy: {
                                                username: username,
                                            },
                                            isPublicAccessType_not:
                                                AccessType.NONE,
                                        }}
                                        key={
                                            JSON.stringify(tab) +
                                            JSON.stringify({
                                                createdBy: {
                                                    username: username,
                                                },
                                                isPublicAccessType_not:
                                                    AccessType.NONE,
                                            })
                                        }
                                    />
                                )}
                                {tab === 1 && (
                                    <ConceptVirtualList
                                        filter={{
                                            likedBy_some: {
                                                username: username,
                                            },
                                            isPublicAccessType_not:
                                                AccessType.NONE,
                                        }}
                                        key={
                                            JSON.stringify(tab) +
                                            JSON.stringify({
                                                likedBy_some: {
                                                    username: username,
                                                },
                                                isPublicAccessType_not:
                                                    AccessType.NONE,
                                            })
                                        }
                                    />
                                )}
                            </Box>
                        </Box>
                    </>
                )}
            </SizeMe>
        </Box>
    )
}
