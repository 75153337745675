import {
    ELEMENT_BLOCKQUOTE,
    ELEMENT_CODE_BLOCK,
    ELEMENT_H1,
    ELEMENT_H2,
    ELEMENT_H3,
    ELEMENT_H4,
    ELEMENT_H5,
    ELEMENT_H6,
    ELEMENT_PARAGRAPH,
    ELEMENT_TODO_LI,
    IndentPlugin,
} from "@udecode/plate"
import { MyPlatePlugin } from "../typescript/plateTypes"

export const indentPlugin: Partial<MyPlatePlugin<IndentPlugin>> = {
    inject: {
        props: {
            validTypes: [
                ELEMENT_PARAGRAPH,
                ELEMENT_H1,
                ELEMENT_H2,
                ELEMENT_H3,
                ELEMENT_H4,
                ELEMENT_H5,
                ELEMENT_H6,
                ELEMENT_BLOCKQUOTE,
                ELEMENT_CODE_BLOCK,
                ELEMENT_TODO_LI,
            ],
        },
    },
}
