import { Box, Divider } from "@material-ui/core"
import { useReactiveVar } from "@apollo/client"

import RichTextEditor, {
    BASE_INITIAL_TEXT,
} from "../../text-editor/RichTextEditor"
import { CriteriaScore, Concept } from "../../../__generated__/types"
import { myCurrentInputResponseLabelVar } from "../../../providers/GlobalState"
import { TextResponseType } from "../../criteria/useCriteriaTypes"
import ConceptListItem from "../../ConceptListItem"
import { ScoreSelectorDefaultValues } from "../types"
import ResponseListItem from "../ResponseListItem"

interface TextResponseReviewerProps {
    config: TextResponseType
    scores?: CriteriaScore[]
    conceptsScored?: Concept[]
}

const TextResponseReviewer: React.FunctionComponent<
    TextResponseReviewerProps
> = ({ conceptsScored = [], scores, config }) => {
    const isSubfield = config?.source?.criteriaIds?.length > 0
    return (
        <Box
            display="flex"
            flexDirection={"column"}
            height="100%"
            flexGrow="1"
            overflow="hidden"
        >
            {!!isSubfield ? (
                <Box display="flex" flexDirection={"column"} overflow="auto">
                    {conceptsScored.map((concept, index) => {
                        const conceptScores =
                            scores.filter(
                                (score) =>
                                    score.scoredConcept?.id === concept.id
                            ) ?? []
                        return (
                            <IndividualResponse
                                key={concept.id}
                                concept={concept}
                                scores={conceptScores}
                            />
                        )
                    })}
                </Box>
            ) : (
                <Box
                    display="flex"
                    flexDirection="column"
                    style={{ overflowY: "auto" }}
                >
                    {scores.map((score) => (
                        <Box key={score.id + (score?.response?.length || "")}>
                            <Box display="flex">
                                {scores?.length > 1 && (
                                    <Box width={"12rem"} flexShrink={0} p={1.5}>
                                        <ResponseListItem
                                            item={{
                                                label: score.label,
                                                user: score.user,
                                            }}
                                            isConceptDefault={
                                                score.conceptDefault
                                            }
                                        />
                                    </Box>
                                )}

                                <Box
                                    display={"flex"}
                                    id={score?.id}
                                    flexGrow={1}
                                >
                                    <RichTextEditor
                                        initialValue={
                                            score.response ||
                                            JSON.stringify(BASE_INITIAL_TEXT)
                                        }
                                        editable={false}
                                        editorId={score.id}
                                    />
                                </Box>
                            </Box>
                            {scores?.length > 1 && <Divider />}
                        </Box>
                    ))}
                </Box>
            )}
        </Box>
    )
}

const IndividualResponse = (props: {
    concept?: Concept
    scores?: CriteriaScore[]
}) => {
    const { concept, scores } = props
    const currentLabel = useReactiveVar(myCurrentInputResponseLabelVar)
    const viewingAllResponses =
        currentLabel?.label === ScoreSelectorDefaultValues.allResponses &&
        !currentLabel?.user

    return (
        <Box>
            <Box display="flex" overflow={"hidden"}>
                {!!concept && (
                    <Box width={"12rem"} flexShrink={0}>
                        <ConceptListItem
                            item={concept}
                            hideCategory={true}
                            hideDivider={true}
                            showSummary={false}
                        />
                    </Box>
                )}
                {!!concept && <Divider orientation="vertical" flexItem />}
                <Box style={{ overflowX: "auto" }} display="flex">
                    {scores?.map((score, index) => {
                        return (
                            <Box
                                key={score.id + (score?.response?.length || "")}
                                minWidth={200}
                                width="100%"
                            >
                                {!!viewingAllResponses && (
                                    <Box
                                        width="100%"
                                        p={1}
                                        height={75}
                                        display="flex"
                                        flexDirection="column"
                                        justifyContent="center"
                                    >
                                        <ResponseListItem
                                            item={{
                                                label: score.label,
                                                user: score.user,
                                            }}
                                            isConceptDefault={
                                                !!score.conceptDefault
                                            }
                                        />
                                    </Box>
                                )}
                                <Box display={"flex"} id={score?.id}>
                                    <RichTextEditor
                                        initialValue={
                                            score.response ||
                                            JSON.stringify(BASE_INITIAL_TEXT)
                                        }
                                        editable={false}
                                        editorId={score.id}
                                    />
                                </Box>
                            </Box>
                        )
                    })}
                </Box>
            </Box>
            {!!concept && <Divider />}
        </Box>
    )
}

export default TextResponseReviewer
