import { gql } from "@apollo/client"

export const CREATE_CONCEPT_STATUS = gql`
    mutation CreateConceptStatus(
        $justification: String
        $justificationNotes: String
        $isActive: Boolean
        $createdAt: _Neo4jDateTimeInput
    ) {
        CreateConceptStatus(
            justification: $justification
            justificationNotes: $justificationNotes
            isActive: $isActive
            createdAt: $createdAt
        ) {
            id
            justification
            justificationNotes
            isActive
            createdAt {
                formatted
            }
            concept {
                id
            }
        }
    }
`
export const UPDATE_CONCEPT_STATUS = gql`
    mutation UpdateConceptStatus(
        $id: ID!
        $justification: String
        $justificationNotes: String
        $isActive: Boolean
        $createdAt: _Neo4jDateTimeInput
    ) {
        UpdateConceptStatus(
            id: $id
            justification: $justification
            justificationNotes: $justificationNotes
            isActive: $isActive
            createdAt: $createdAt
        ) {
            id
            justification
            justificationNotes
            isActive
            createdAt {
                formatted
            }
            concept {
                id
            }
        }
    }
`
export const ADD_CONCEPT_STATUS = gql`
    mutation AddConceptStatus($conceptStatusId: ID!, $conceptId: ID!) {
        AddConceptStatus(
            from: { id: $conceptId }
            to: { id: $conceptStatusId }
        ) {
            from {
                id
                status(filter: { isActive: true }) {
                    id
                    justificationNotes
                    justification
                    isActive
                    createdAt {
                        formatted
                    }
                    concept {
                        id
                    }
                }
            }
            to {
                id
                justificationNotes
                justification
                isActive
                createdAt {
                    formatted
                }
                concept {
                    id
                }
            }
        }
    }
`
export const REMOVE_CONCEPT_STATUS = gql`
    mutation RemoveConceptStatus($conceptStatusId: ID!, $conceptId: ID!) {
        RemoveConceptStatus(
            from: { id: $conceptId }
            to: { id: $conceptStatusId }
        ) {
            from {
                id
            }
            to {
                id
            }
        }
    }
`

export const CONCEPT_STATUS_HISTORY = gql`
    query ConceptStatusHistory(
        $offset: Int
        $first: Int
        $filter: _ConceptStatusFilter
        $orderBy: [_ConceptStatusOrdering]
    ) {
        ConceptStatus(
            filter: $filter
            offset: $offset
            first: $first
            orderBy: $orderBy
        ) {
            id
            justification
            justificationNotes
            isActive
            createdAt {
                formatted
            }
            stage {
                id
                title
                justificationRequired
                justificationOptions
                permissionRequired
                icon
            }
        }
    }
`

export const CREATE_WORKFLOW = gql`
    mutation CreateWorkflow(
        $title: String
        $description: String
        $initialAction: String
    ) {
        CreateWorkflow(
            title: $title
            description: $description
            initialAction: $initialAction
        ) {
            id
            title
            description
            initialAction
            stages {
                id
                title
                isFirstStage
                justificationRequired
                justificationOptions
                permissionRequired
                icon
                nextOptions {
                    id
                    title
                    isFirstStage
                    justificationRequired
                    justificationOptions
                    permissionRequired
                    icon
                }
            }
        }
    }
`

export const CREATE_WORKFLOW_STAGE = gql`
    mutation CreateWorkflowStage($title: String, $isFirstStage: Boolean) {
        CreateWorkflowStage(title: $title, isFirstStage: $isFirstStage) {
            id
            title
            isFirstStage
        }
    }
`
export const ADD_NEXT_STAGE_OPTION = gql`
    mutation AddNextStageOption($firstStageId: ID!, $nextStageId: ID!) {
        AddWorkflowStageNextOptions(
            from: { id: $firstStageId }
            to: { id: $nextStageId }
        ) {
            from {
                id
                nextOptions {
                    id
                }
            }
            to {
                id
                nextOptions {
                    id
                }
            }
        }
    }
`
export const REMOVE_NEXT_STAGE_OPTION = gql`
    mutation RemoveNextStageOption($firstStageId: ID!, $nextStageId: ID!) {
        RemoveWorkflowStageNextOptions(
            from: { id: $firstStageId }
            to: { id: $nextStageId }
        ) {
            from {
                id
                nextOptions {
                    id
                }
            }
            to {
                id
                nextOptions {
                    id
                }
            }
        }
    }
`

export const DELETE_WORKFLOW_STAGE = gql`
    mutation DeleteWorkflowStage($id: ID!) {
        DeleteWorkflowStage(id: $id) {
            id
        }
    }
`
export const ADD_WORKFLOW_STAGE = gql`
    mutation AddWorkflowStage($workflowId: ID!, $stageId: ID!) {
        AddWorkflowStages(from: { id: $workflowId }, to: { id: $stageId }) {
            from {
                id
            }
            to {
                id
            }
        }
    }
`

export const UPDATE_WORKFLOW_STAGE = gql`
    mutation UpdateWorkflowStage(
        $id: ID!
        $title: String
        $justificationRequired: Boolean
        $justificationOptions: String
        $isFirstStage: Boolean
        $permissionRequired: AccessType
        $icon: String
    ) {
        UpdateWorkflowStage(
            id: $id
            title: $title
            justificationRequired: $justificationRequired
            justificationOptions: $justificationOptions
            isFirstStage: $isFirstStage
            permissionRequired: $permissionRequired
            icon: $icon
        ) {
            id
            title
            justificationRequired
            justificationOptions
            isFirstStage
            permissionRequired
            icon
        }
    }
`
export const UPDATE_WORKFLOW = gql`
    mutation UpdateWorkflow(
        $id: ID!
        $title: String
        $description: String
        $initialAction: String
    ) {
        UpdateWorkflow(
            id: $id
            title: $title
            description: $description
            initialAction: $initialAction
        ) {
            id
            title
            description
            initialAction
        }
    }
`

export const DELETE_WORKFLOW = gql`
    mutation DeleteWorkflow($id: ID!) {
        DeleteWorkflow(id: $id) {
            id
        }
    }
`
export const WORKFLOW = gql`
    query Workflow($filter: _WorkflowFilter) {
        Workflow(filter: $filter) {
            id
            title
            isDefault
            description
            initialAction
            stages {
                id
                workflow {
                    id
                    title
                }
                title
                isFirstStage
                justificationRequired
                justificationOptions
                permissionRequired
                icon
                nextOptions {
                    id
                    workflow {
                        id
                        title
                    }
                    title
                    isFirstStage
                    justificationRequired
                    justificationOptions
                    permissionRequired
                    icon
                }
            }
        }
    }
`
export const ADD_STATUS_STAGE = gql`
    mutation AddWorkflowStageStatus($stageId: ID!, $statusId: ID!) {
        AddWorkflowStageStatuses(
            from: { id: $statusId }
            to: { id: $stageId }
        ) {
            from {
                id
                stage {
                    id
                    title
                }
            }
            to {
                id
            }
        }
    }
`
