import React from "react"
import {
    BlockToolbarButton,
    ELEMENT_BLOCKQUOTE,
    ELEMENT_H1,
    ELEMENT_H2,
    ELEMENT_H3,
    ELEMENT_H4,
    ELEMENT_H5,
    ELEMENT_H6,
    getPluginType,
    useEventPlateId,
} from "@udecode/plate"
import { useMyPlateEditorRef } from "../typescript/plateTypes"
import { getElementIcon } from "./getElementIcon"

export const BasicElementToolbarButtons = () => {
    const editor = useMyPlateEditorRef(useEventPlateId())

    return (
        <>
            <BlockToolbarButton
                type={getPluginType(editor, ELEMENT_H1)}
                icon={getElementIcon(ELEMENT_H1)}
            />

            <BlockToolbarButton
                type={getPluginType(editor, ELEMENT_H2)}
                icon={getElementIcon(ELEMENT_H2)}
            />
            <BlockToolbarButton
                type={getPluginType(editor, ELEMENT_H3)}
                icon={getElementIcon(ELEMENT_H3)}
            />
            <BlockToolbarButton
                type={getPluginType(editor, ELEMENT_H4)}
                icon={getElementIcon(ELEMENT_H4)}
            />
            <BlockToolbarButton
                type={getPluginType(editor, ELEMENT_H5)}
                icon={getElementIcon(ELEMENT_H5)}
            />
            <BlockToolbarButton
                type={getPluginType(editor, ELEMENT_H6)}
                icon={getElementIcon(ELEMENT_H6)}
            />
            <BlockToolbarButton
                type={getPluginType(editor, ELEMENT_BLOCKQUOTE)}
                icon={getElementIcon(ELEMENT_BLOCKQUOTE)}
            />
        </>
    )
}
