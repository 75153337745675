export enum CriteriaType {
    Number = "Number",
    TextResponse = "TextResponse",
    MultipleChoice = "MultipleChoice",
    Ranking = "Ranking",
    Grouping = "Grouping",
    Collection = "Collection",
    Date = "Date",
    Chart = "Chart",
}
