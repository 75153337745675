import React, { useState } from "react"
import Box from "@material-ui/core/Box"
import Tooltip from "@material-ui/core/Tooltip"
import SyncAltIcon from "@material-ui/icons/SyncAlt"
import { useHistory } from "react-router-dom"
import DetailedConnectionPreviewDialog from "./DetailedConnectionPreviewDialog"
import LikeToggle from "./liking/LikeToggle"
import { useGlobalStyles } from "../styles/globalStyles"
import IconButton from "@material-ui/core/IconButton"
import CommentIcon from "@material-ui/icons/Comment"
import useAwaitTranslation from "../i18n/useAwaitTranslation"
import { Concept } from "../__generated__/types"
import ConceptCardBaseBody from "./ConceptCardBaseBody"

interface ConceptCardProps {
    item: Concept
    condensed?: boolean
    nonGrid?: boolean
    viewComments?: () => void
}

// Leaving any for item since we are more than likely changing the type interface anyway, and it is throwing a type error for 'product'

const ConceptCard = (props: ConceptCardProps) => {
    const [connectionPreviewOpen, setConnectionPreviewOpen] = useState(false)
    const history = useHistory()

    const iconStyles = useGlobalStyles()

    const handlePreviewClick = (event) => {
        event.stopPropagation()
        setConnectionPreviewOpen(true)
    }

    const { t } = useAwaitTranslation("concepts")

    const bottomActions = (
        <Box
            display="flex"
            marginLeft="auto !important"
            justifyContent="flex-end"
        >
            {!!props.viewComments && (
                <Box mr={1}>
                    <IconButton
                        onClick={(e) => {
                            e.stopPropagation()
                            props.viewComments()
                        }}
                        size="small"
                    >
                        <CommentIcon
                            className={iconStyles.smallLabelStylesIcon}
                        />
                        <span className={iconStyles.smallCount}>
                            {props.item.commentCount ?? 0}
                        </span>
                    </IconButton>
                </Box>
            )}
            <Box mr={1}>
                <Tooltip
                    title={
                        props.item.connections?.length !== 1
                            ? t("preview", "Preview") +
                              ` ${props.item.connections?.length} ` +
                              t("connections", "Connections")
                            : t("preview", "Preview") +
                              ` ${props.item.connections?.length} ` +
                              t("connection", "Connection")
                    }
                >
                    <IconButton
                        onClick={handlePreviewClick}
                        disableRipple
                        size="small"
                    >
                        <SyncAltIcon
                            className={iconStyles.smallLabelStylesIcon}
                        />
                        <span className={iconStyles.smallCount}>
                            {props.item.connections?.length}
                        </span>
                    </IconButton>
                </Tooltip>
            </Box>

            <Box>
                <LikeToggle
                    item={props.item}
                    disableRipple={true}
                    size="small"
                    location="Concept Card"
                />
            </Box>
        </Box>
    )
    return (
        <>
            <ConceptCardBaseBody
                item={props.item}
                bottomActions={bottomActions}
                onClick={() => {
                    history.push(`/concept/${props.item.id}/home`)
                }}
                nonGrid={props.nonGrid}
                condensed={props.condensed}
            />
            {connectionPreviewOpen && (
                <DetailedConnectionPreviewDialog
                    itemId={props.item.id}
                    onClose={() => setConnectionPreviewOpen(false)}
                />
            )}
        </>
    )
}
export default React.memo(ConceptCard)
