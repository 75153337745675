import {
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
} from "@material-ui/core"
import { Alert } from "@material-ui/lab"
import useAwaitTranslation from "../../i18n/useAwaitTranslation"
import { User } from "../../__generated__/types"
import UserListItem from "../users/UserListItem"

export default function ConfirmUserDeletion(props: {
    user: User
    onDeletion: () => void
    deleting: boolean
    onClose: () => void
}) {
    const { user, onDeletion, onClose, deleting } = props

    const { t } = useAwaitTranslation("user")

    return (
        <Dialog open={true} fullWidth onBackdropClick={() => onClose()}>
            <DialogTitle>
                {t(
                    "confirmUserDeletion",
                    "Are you sure you want to delete this user?"
                )}
            </DialogTitle>
            <DialogContent>
                <UserListItem user={user} />
                <Alert severity="warning">
                    {t(
                        "youWillNotBeAbleToRecoverTheAccount",
                        "You will not be able to recover the account"
                    )}
                </Alert>
            </DialogContent>
            <DialogActions>
                <Button disabled={deleting} onClick={() => onClose()}>
                    {t("no", "No")}
                </Button>
                <Button disabled={deleting} onClick={onDeletion}>
                    {!!deleting ? (
                        <CircularProgress size={25} />
                    ) : (
                        t("yes", "Yes")
                    )}
                </Button>
            </DialogActions>
        </Dialog>
    )
}
