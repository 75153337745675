export const defaultTheme = {
    LIGHT: {
        primary: process.env.REACT_APP_PRIMARY_COLOR_LIGHT || "#417505",
        secondary: process.env.REACT_APP_SECONDARY_COLOR_LIGHT || "#F5A623",
        primaryShade: 4,
        secondaryShade: 11,
        primaryHue: "blue",
        secondaryHue: null,
        darkMode: false,
        background: {
            default: "#e8eaed",
            paper: "#fff",
            level1: "#e8eaed",
            level2: "#f5f5f5",
        },
        text: {
            primary: "rgba(0, 0, 0, 0.87)",
            secondary: "rgba(0, 0, 0, 0.54)",
        },
        divider: "rgba(0, 0, 0, 0.12)",
    },
    DARK: {
        primary: process.env.REACT_APP_PRIMARY_COLOR_DARK || "#3072F9",
        secondary: process.env.REACT_APP_SECONDARY_COLOR_DARK || "#ffd180",
        primaryShade: 4,
        secondaryShade: 11,
        primaryHue: "blue",
        secondaryHue: null,
        darkMode: true,
        background: {
            default: "#303030",
            paper: "#424242",
            level1: "#212121",
            level2: "#303030",
        },
        text: {
            primary: "#fff",
            secondary: "rgba(255, 255, 255, 0.7)",
        },
        divider: "rgba(255, 255, 255, 0.12)",
    },
}
