import { useQuery } from "@apollo/client"
import { useEffect, useState } from "react"
import { GET_WIDGET_TYPES } from "./graphql"
import { Widget } from "./useWidgets"
import ConceptListWidget from "./widgets/ConceptListWidget"
import UnknownWidget from "./widgets/UnknownWidget"
import HypeChartWidget from "./widgets/HypeChartWidget"
import RichTextEditorWidget from "./widgets/RichTextEditorWidget"
import HeadingWidget from "./widgets/HeadingWidget"
import { JSONSchema4Type } from "json-schema"
import ProductChartWidget from "./widgets/ProductChartWidget"
import ConceptFeedWidget from "./widgets/ConceptFeedWidget"
import ConceptWidget from "./widgets/ConceptWidget"
import BubbleChartWidget from "./widgets/BubbleChartWidget"
import TableChartIcon from "@material-ui/icons/TableChart"
import CriteriaRadarWidget from "./widgets/CriteriaRadarWidget"

import {
    GetAllWidgetTypesQuery,
    GetAllWidgetTypesQueryVariables,
} from "./__generated__/graphql"
import {
    ConceptFeedSchema,
    UnknownSchema,
    ChartSchema,
    RichTextEditorSchema,
    HeadingSchema,
    ConceptListSchema,
    ConceptSchema,
    CriteriaRadarSchema,
    BubbleChartSchema,
    VideoSchema,
    InputResultsSchema,
    CriteriaSchema,
    GraphSchema,
} from "./widgets/common/WidgetSchemas"
import DnsIcon from "@material-ui/icons/Dns"
import TimelineIcon from "@material-ui/icons/Timeline"
import ListIcon from "@material-ui/icons/List"
import WidgetsIcon from "@material-ui/icons/Widgets"
import TextFieldsIcon from "@material-ui/icons/TextFields"
import { LooksOne } from "@styled-icons/material/LooksOne"
import ViewDayIcon from "@material-ui/icons/ViewDay"
import { Concept } from "../../__generated__/types"
import TrackChangesIcon from "@material-ui/icons/TrackChanges"
import BubbleChartIcon from "@material-ui/icons/BubbleChart"
import VideoLibraryIcon from "@material-ui/icons/VideoLibrary"
import VideoWidget from "./widgets/VideoWidget"
import RecordVoiceOverIcon from "@material-ui/icons/RecordVoiceOver"
import ScoringResultsBubbleChartWidget from "./widgets/ScoringResultsBubbleChartWidget"
import CriteriaWidget from "./widgets/CriteriaWidget"
import TableWidget from "./widgets/TableWidget"
import GraphWidget from "./widgets/GraphWidget"
type WidgetBaseProps = {
    widget: Widget
    editing: boolean
    isDialog?: boolean
    concept?: Concept
    onWidgetDeletion: (props: { widgetId: string; inputId?: string }) => void
}

export type WidgetTypeComponent = {
    (props: WidgetBaseProps): JSX.Element
    defaultLayout?: {
        /**
         * Width in grid units.
         */
        w?: number

        /**
         * Height in grid units.
         */
        h?: number

        /**
         * Minimum width in grid units.
         */
        minW?: number

        /**
         * Maximum width in grid units.
         */
        maxW?: number

        /**
         * Minimum height in grid units.
         */
        minH?: number

        /**
         * Maximum height in grid units.
         */
        maxH?: number
    }
}

export enum WidgetTypeName {
    ConceptsFeed = "ConceptsFeed",
    HypeChart = "HypeChart",
    ProductChart = "ProductChart",
    BubbleChart = "BubbleChart",
    ConceptList = "ConceptList",
    Concept = "Concept",
    Heading = "Heading",
    BlankNotes = "BlankNotes",
    CriteriaRadar = "CriteriaRadar",
    Video = "Video",
    Criteria = "Criteria",
    ScoringResultsBubbleChart = "ScoringResultsBubbleChart",
    Table = "Table",
    Graph = "Graph",
    Unknown = "Unknown",
}

export type WidgetType = {
    id: string
    name: WidgetTypeName
    schema?: WidgetTypeSchemaName
    defaultValue: string
    defaultConfig: string
    component: WidgetTypeComponentName
}

export enum WidgetTypeComponentName {
    ConceptListWidget = "ConceptListWidget",
    HypeChartWidget = "HypeChartWidget",
    ProductChartWidget = "ProductChartWidget",
    BubbleChartWidget = "BubbleChartWidget",
    ScoringResultsBubbleChartWidget = "ScoringResultsBubbleChartWidget",
    HeadingWidget = "HeadingWidget",
    ConceptFeedWidget = "ConceptFeedWidget",
    RichTextEditorWidget = "RichTextEditorWidget",
    ConceptWidget = "ConceptWidget",
    CriteriaRadarWidget = "CriteriaRadarWidget",
    VideoWidget = "VideoWidget",
    CriteriaWidget = "CriteriaWidget",
    TableWidget = "TableWidget",
    GraphWidget = "GraphWidget",
    Unknown = "Unknown",
}

export const WIDGETS_REPOSITORY: {
    [key in WidgetTypeComponentName]: WidgetTypeComponent
} = {
    [WidgetTypeComponentName.ConceptListWidget]: ConceptListWidget,
    [WidgetTypeComponentName.HypeChartWidget]: HypeChartWidget,
    [WidgetTypeComponentName.ProductChartWidget]: ProductChartWidget,
    [WidgetTypeComponentName.BubbleChartWidget]: BubbleChartWidget,
    [WidgetTypeComponentName.ScoringResultsBubbleChartWidget]: ScoringResultsBubbleChartWidget,
    [WidgetTypeComponentName.ConceptFeedWidget]: ConceptFeedWidget,
    [WidgetTypeComponentName.HeadingWidget]: HeadingWidget,
    [WidgetTypeComponentName.RichTextEditorWidget]: RichTextEditorWidget,
    [WidgetTypeComponentName.ConceptWidget]: ConceptWidget,
    [WidgetTypeComponentName.CriteriaRadarWidget]: CriteriaRadarWidget,
    [WidgetTypeComponentName.VideoWidget]: VideoWidget,
    [WidgetTypeComponentName.CriteriaWidget]: CriteriaWidget,
    [WidgetTypeComponentName.TableWidget]: TableWidget,
    [WidgetTypeComponentName.GraphWidget]: GraphWidget,
    [WidgetTypeComponentName.Unknown]: UnknownWidget,
}
export enum WidgetTypeSchemaName {
    ConceptFeedSchema = "ConceptFeedSchema",
    ChartSchema = "ChartSchema",
    BubbleChartSchema = "BubbleChartSchema",
    RichTextEditorSchema = "RichTextEditorSchema",
    ConceptListSchema = "ConceptListSchema",
    HeadingSchema = "HeadingSchema",
    ConceptSchema = "ConceptSchema",
    CriteriaRadarSchema = "CriteriaRadarSchema",
    VideoSchema = "VideoSchema",
    CriteriaSchema = "CriteriaSchema",
    InputResultsSchema = "InputResultsSchema",
    GraphSchema = "GraphSchema",
    UnknownSchema = "UnknownSchema",
}
export const SCHEMA_REPOSITORY: {
    [key in WidgetTypeSchemaName]: JSONSchema4Type
} = {
    [WidgetTypeSchemaName.ConceptFeedSchema]: ConceptFeedSchema,
    [WidgetTypeSchemaName.ChartSchema]: ChartSchema,
    [WidgetTypeSchemaName.BubbleChartSchema]: BubbleChartSchema,
    [WidgetTypeSchemaName.ConceptListSchema]: ConceptListSchema,
    [WidgetTypeSchemaName.RichTextEditorSchema]: RichTextEditorSchema,
    [WidgetTypeSchemaName.HeadingSchema]: HeadingSchema,
    [WidgetTypeSchemaName.ConceptSchema]: ConceptSchema,
    [WidgetTypeSchemaName.CriteriaRadarSchema]: CriteriaRadarSchema,
    [WidgetTypeSchemaName.VideoSchema]: VideoSchema,
    [WidgetTypeSchemaName.CriteriaSchema]: CriteriaSchema,
    [WidgetTypeSchemaName.InputResultsSchema]: InputResultsSchema,
    [WidgetTypeSchemaName.GraphSchema]: GraphSchema,
    [WidgetTypeSchemaName.UnknownSchema]: UnknownSchema,
}
export const ICONS_REPOSITORY: {
    [key in WidgetTypeName]: React.ReactNode
} = {
    [WidgetTypeName.ConceptsFeed]: <DnsIcon fontSize="small" />,
    [WidgetTypeName.HypeChart]: <TimelineIcon fontSize="small" />,
    [WidgetTypeName.ProductChart]: <TimelineIcon fontSize="small" />,
    [WidgetTypeName.BubbleChart]: <BubbleChartIcon fontSize="small" />,
    [WidgetTypeName.ConceptList]: <ListIcon fontSize="small" />,

    [WidgetTypeName.BlankNotes]: <TextFieldsIcon fontSize="small" />,
    [WidgetTypeName.Heading]: (
        <LooksOne style={{ width: "1.5em", height: "1.5em" }} />
    ), // not sure of best way to size this
    [WidgetTypeName.Concept]: <ViewDayIcon fontSize="small" />,
    [WidgetTypeName.CriteriaRadar]: <TrackChangesIcon fontSize="small" />,
    [WidgetTypeName.Video]: <VideoLibraryIcon fontSize="small" />,
    [WidgetTypeName.Criteria]: <RecordVoiceOverIcon fontSize="small" />,

    [WidgetTypeName.ScoringResultsBubbleChart]: (
        <BubbleChartIcon fontSize="small" />
    ),
    [WidgetTypeName.Table]: <TableChartIcon fontSize="small" />,
    [WidgetTypeName.Graph]: <TimelineIcon fontSize="small" />,
    [WidgetTypeName.Unknown]: <WidgetsIcon fontSize="small" />,
}
export function useWidgetTypes() {
    const { data: widgetTypesData } = useQuery<
        GetAllWidgetTypesQuery,
        GetAllWidgetTypesQueryVariables
    >(GET_WIDGET_TYPES)
    const [widgetTypes, setWidgetTypes] = useState([])
    const [isInitialized, setIsInitialized] = useState(false)
    const widgetTypesList = widgetTypesData?.WidgetType ?? null
    useEffect(() => {
        if (widgetTypesList) {
            const validWidgets = [...widgetTypesList]
                .filter((widget) => {
                    if (
                        WIDGETS_REPOSITORY[
                            widget.component as WidgetTypeComponentName
                        ]
                    )
                        return true
                    if (
                        SCHEMA_REPOSITORY[widget.schema as WidgetTypeSchemaName]
                    )
                        return true
                    if (process.env.NODE_ENV !== "production")
                        console.warn("Widget not found", widget.name)
                    return false
                })
                .map((widget) => ({
                    id: widget.id,
                    name: widget.name as WidgetTypeName,
                    schema:
                        SCHEMA_REPOSITORY[
                            widget.schema as WidgetTypeSchemaName
                        ],
                    component:
                        WIDGETS_REPOSITORY[
                            widget.component as WidgetTypeComponentName
                        ],
                    defaultValue: widget.defaultValue,
                    defaultConfig: widget.defaultConfig,
                }))
            setWidgetTypes(validWidgets)
            setIsInitialized(true)
        }
    }, [widgetTypesList])

    return {
        isInitialized,
        widgetTypes,
    }
}
