import { Box, Typography } from "@material-ui/core"
import useCategoryTools from "./categories/useCategoryTools"

export default function CategoryLabel(props: {
    conceptCategoryId: string
    size: "small" | "medium"
}) {
    const { conceptCategoryId, size } = props
    const { getCategoryArray } = useCategoryTools()
    const categoryArray = getCategoryArray(conceptCategoryId)
    let string = ""

    categoryArray.map((cat, index) => {
        if (categoryArray.length - 1 === index) {
            string += cat.name
        } else {
            string += `${cat.name} / `
        }
        return string
    })
    return (
        <Box>
            <Typography
                color="textSecondary"
                variant="body2"
                style={{ fontSize: size === "small" ? 12 : "inherit" }}
            >
                {string}
            </Typography>
        </Box>
    )
}
