import {
    Box,
    ClickAwayListener,
    Fade,
    IconButton,
    TableCell,
    Tooltip,
} from "@material-ui/core"
import { Concept } from "../../../__generated__/types"
import { FeedbackField } from "../../../providers/GlobalState"
import { CriteriaType } from "../../criteria/types"
import NumberCell from "./TableCells/Number"
import { memo, useState } from "react"

import MultipleChoiceCell from "./TableCells/MultipleChoice"
import TextResponseCell from "./TableCells/TextResponse"
import { StyledTableRow } from "./TableCells/StyledTableElements"
import { decomposeColor, makeStyles, useTheme } from "@material-ui/core/styles"
import DateCell from "./TableCells/Date"
import Clear from "@material-ui/icons/Clear"
import TitleCell from "./TableCells/Title"
import DragIndicator from "@material-ui/icons/DragIndicator"
import {
    DraggableProvidedDraggableProps,
    DraggableProvidedDragHandleProps,
} from "react-beautiful-dnd"
import { getUserConceptPermission } from "../../../util/GetPermissions"
import { useAuth } from "../../../providers/AuthProvider"
import DeleteForever from "@material-ui/icons/DeleteForever"
import useConceptTools from "../../../util/useConceptTools"
import useAwaitTranslation from "../../../i18n/useAwaitTranslation"
import ConfirmConceptDeletion from "../../dialogs/ConfirmConceptDeletion"
import { useParams } from "react-router-dom"
import { getCleanedFieldConfig, getMinCellWidth } from "../util"
const useStyles = makeStyles((theme) => ({
    draggingRow: {
        border: `1px solid ${theme.palette.divider}`,
        borderRadius: theme.shape.borderRadius,
    },
    draggingCell: {
        border: 0,
    },
    editingCell: {
        border: `1px solid ${theme.palette.primary.light}`,
        borderRadius: theme.shape.borderRadius,
    },
    editableCell: {
        "&:hover": {
            backgroundColor: theme.palette.action.hover,
        },
    },
    sticky: {
        position: "sticky",
        right: 0,
        zIndex: 1,
    },
}))
interface TableViewRowProps {
    columns: FeedbackField[]
    concept: Concept
    selectedId: string
    setSelectedId: (value: string | null) => void
    draggableRef: any
    draggableProps: DraggableProvidedDraggableProps
    dragHandleProps: DraggableProvidedDragHandleProps
    isDragging?: boolean
    onRemoval?: (concept: Concept, selected: boolean) => Promise<void>
    editable: boolean
}

const TableViewRow: React.FunctionComponent<TableViewRowProps> = ({
    columns,
    concept,
    selectedId,
    setSelectedId,
    draggableRef,
    draggableProps,
    dragHandleProps,
    isDragging,
    onRemoval,
    editable,
}) => {
    const { conceptId: pageConceptId } = useParams()
    const [hovered, setHovered] = useState(false)
    const [confirmDelete, setConfirmDelete] = useState(false)
    const { t } = useAwaitTranslation("feedback")
    const { deleteConcept, deleting } = useConceptTools(concept)
    const classes = useStyles()
    const theme = useTheme()
    const { currentUser } = useAuth()
    const editingTitle = concept?.id === selectedId
    const permissionToDelete =
        !!getUserConceptPermission(concept, currentUser)?.owningPermission &&
        pageConceptId === concept.parent?.id
    const { editingPermission: permissionToEditTitle } =
        getUserConceptPermission(concept, currentUser)
    const handleDeletion = async () => {
        await deleteConcept({
            variables: {
                conceptId: concept.id,
            },
        })
        onRemoval(concept, true)
    }

    return (
        <StyledTableRow
            {...draggableProps}
            ref={draggableRef}
            key={concept?.id}
            onMouseOver={() => {
                if (!hovered && !isDragging) {
                    setHovered(true)
                }
            }}
            onMouseLeave={() => {
                if (!!hovered && !isDragging) {
                    setHovered(false)
                }
            }}
            className={isDragging ? classes.draggingRow : ""}
        >
            {!!editable && (
                <Tooltip title={t("dragToOrder", "Drag to order")}>
                    <TableCell
                        {...dragHandleProps}
                        className={
                            !!isDragging
                                ? classes.draggingCell
                                : !!editable
                                ? classes.editableCell
                                : ""
                        }
                    >
                        <Fade in={!!hovered}>
                            <DragIndicator fontSize="small" color="action" />
                        </Fade>
                    </TableCell>
                </Tooltip>
            )}

            <ClickAwayListener
                onClickAway={() => {
                    if (!!editingTitle) {
                        setSelectedId(null)
                    }
                }}
                key={concept?.id}
            >
                <Tooltip
                    title={
                        !permissionToEditTitle
                            ? t(
                                  "youDoNotHaveRequiredPermissionsToEdit",
                                  "You do not have required permissions to edit this title"
                              )
                            : ""
                    }
                >
                    <TableCell
                        onDoubleClick={() => {
                            if (!!editable && !!permissionToEditTitle) {
                                setSelectedId(concept?.id)
                            }
                        }}
                        className={
                            !!editingTitle && !!editable
                                ? classes.editingCell
                                : !!isDragging
                                ? classes.draggingCell
                                : !!editable
                                ? classes.editableCell
                                : ""
                        }
                        style={{ minWidth: 300 }}
                    >
                        <TitleCell
                            onBlur={() => setSelectedId(null)}
                            concept={concept}
                            editing={!!editingTitle && !!editable}
                        />
                    </TableCell>
                </Tooltip>
            </ClickAwayListener>

            {columns.map((col) => {
                let cellHtml = null
                const cleanedConfig = getCleanedFieldConfig(col)
                const id = col.input?.criteria?.id + concept?.id

                const editingCell = id === selectedId && !!editable

                switch (col.input?.criteria?.criteriaType) {
                    case CriteriaType.Number:
                        cellHtml = (
                            <NumberCell
                                fieldId={col.input?.criteria?.id}
                                editing={editingCell}
                                conceptId={concept?.id}
                                config={cleanedConfig}
                            />
                        )
                        break
                    case CriteriaType.MultipleChoice:
                        cellHtml = (
                            <MultipleChoiceCell
                                fieldId={col.input?.criteria?.id}
                                editing={editingCell}
                                conceptId={concept?.id}
                                config={cleanedConfig}
                            />
                        )
                        break
                    case CriteriaType.TextResponse:
                        cellHtml = (
                            <TextResponseCell
                                fieldId={col.input?.criteria?.id}
                                editing={editingCell}
                                conceptId={concept?.id}
                                config={cleanedConfig}
                            />
                        )
                        break
                    case CriteriaType.Date:
                        cellHtml = (
                            <DateCell
                                fieldId={col.input?.criteria?.id}
                                editing={editingCell}
                                conceptId={concept?.id}
                                config={cleanedConfig}
                            />
                        )
                        break
                    default:
                        cellHtml = <Box />
                }
                const cellWidth = getMinCellWidth(
                    col.input?.criteria?.criteriaType as CriteriaType,
                    cleanedConfig
                )
                return (
                    <ClickAwayListener
                        onClickAway={() => {
                            if (!!editingCell) {
                                setSelectedId(null)
                            }
                        }}
                        key={id}
                    >
                        <TableCell
                            style={{ minWidth: cellWidth }}
                            className={
                                !!editingCell && !!editable
                                    ? classes.editingCell
                                    : !!isDragging
                                    ? classes.draggingCell
                                    : !!editable
                                    ? classes.editableCell
                                    : " "
                            }
                            onDoubleClick={() => {
                                if (!!editable) {
                                    setSelectedId(id)
                                }
                            }}
                        >
                            {cellHtml}
                        </TableCell>
                    </ClickAwayListener>
                )
            })}
            {!!editable && (
                <TableCell
                    className={classes.sticky}
                    style={{
                        position: "sticky",
                        right: 0,
                        backgroundColor: !!hovered
                            ? `rgba(${
                                  decomposeColor(
                                      theme.palette.background.default
                                  ).values
                              }, .75)`
                            : "transparent",
                    }}
                >
                    <Fade in={!!hovered}>
                        <Box display="flex" justifyContent={"flex-end"}>
                            {!!permissionToDelete && (
                                <Tooltip
                                    title={t(
                                        "deletePermanently",
                                        "Delete permanently"
                                    )}
                                >
                                    <IconButton
                                        onClick={() => setConfirmDelete(true)}
                                        size="small"
                                        style={{ marginRight: 4 }}
                                    >
                                        <DeleteForever
                                            color={
                                                !permissionToDelete
                                                    ? "disabled"
                                                    : "error"
                                            }
                                        />
                                    </IconButton>
                                </Tooltip>
                            )}
                            <Tooltip
                                title={t(
                                    "removeFromCollection",
                                    "Remove from collection"
                                )}
                            >
                                <IconButton
                                    size="small"
                                    onClick={() => onRemoval(concept, true)}
                                >
                                    <Clear />
                                </IconButton>
                            </Tooltip>
                        </Box>
                    </Fade>
                </TableCell>
            )}
            {!!confirmDelete && (
                <ConfirmConceptDeletion
                    concept={concept}
                    onDeletion={handleDeletion}
                    deleting={deleting}
                    onClose={() => setConfirmDelete(false)}
                />
            )}
        </StyledTableRow>
    )
}

export default memo(TableViewRow)
