import React, { useCallback, useState } from "react"
import { NetworkStatus, useMutation, useQuery } from "@apollo/client"
import {
    createStyles,
    Theme,
    makeStyles,
    useTheme,
    alpha,
} from "@material-ui/core/styles"
import Box from "@material-ui/core/Box"
import FieldAutocomplete from "./FieldAutocomplete"
import { Loading } from "../Loading"
import {
    IconButton,
    Collapse,
    Typography,
    Tooltip,
    TextField,
    InputAdornment,
    Table,
    TableBody,
    TableHead,
    Paper,
    TableCell,
    TableRow,
    TableContainer,
    Divider,
    Chip,
    Button,
    Slide,
    TableSortLabel,
} from "@material-ui/core"
import {
    ADD_FIELD_SUBFIELD,
    FIELD_MANAGER,
    REMOVE_FIELD_SUBFIELD,
} from "./graphql"
import {
    AddFieldSubfieldMutation,
    AddFieldSubfieldMutationVariables,
    RemoveFieldSubfieldMutation,
    RemoveFieldSubfieldMutationVariables,
    FieldManagerQuery,
    FieldManagerQueryVariables,
} from "./__generated__/graphql"
import CriteriaForm from "./CriteriaForm"
import { Category, Criteria } from "../../__generated__/types"
import useAwaitTranslation from "../../i18n/useAwaitTranslation"
import Clear from "@material-ui/icons/Clear"
import KeyboardArrowUp from "@material-ui/icons/KeyboardArrowUp"
import KeyboardArrowDown from "@material-ui/icons/KeyboardArrowDown"
import { CriteriaType } from "./types"
import {
    CRITERIA_TYPE_ICONS,
    TOP_LEVEL_CRITERIA_OPTIONS,
} from "./useCriteriaTypes"

import CollectionCategorySubList from "./CollectionCategorySubList"
import useCategoryTools from "../categories/useCategoryTools"
import Search from "@material-ui/icons/Search"
import FieldTypeSelect from "./FieldTypeSelect"
import { Pagination } from "@material-ui/lab"
import CategoryIcon from "../categories/CategoryIcon"
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            height: "100%",
            overflow: "hidden",
            display: "flex",
            margin: theme.spacing(1, 3),
            marginBottom: theme.spacing(12),
        },
        row: {
            "& > *": {
                borderBottom: "unset",
            },
        },
        tableHeader: {
            //@ts-ignore
            backgroundColor: `${theme.palette.background.level2} !important`,
        },
        visuallyHidden: {
            border: 0,
            clip: "rect(0 0 0 0)",
            height: 1,
            margin: -1,
            overflow: "hidden",
            padding: 0,
            position: "absolute",
            top: 20,
            width: 1,
        },
    })
)

const FieldsManager = () => {
    const classes = useStyles()
    const rowsPerPage = 25
    const theme = useTheme()
    const [focusedFieldData, setFocusedFieldData] = useState<{
        field?: Criteria
        parentField?: Criteria
    }>(null)
    const [page, setPage] = useState(1)
    const [order, setOrder] = useState<Order>("asc")
    const [orderBy, setOrderBy] = useState<"name" | "numberUsed">("name")
    const [searchString, setSearchString] = useState("")
    const [selectedCriteriaType, setSelectedCriteriaType] = useState<
        CriteriaType | "All"
    >("All")

    const { data, networkStatus, refetch } = useQuery<
        FieldManagerQuery,
        FieldManagerQueryVariables
    >(FIELD_MANAGER, {
        fetchPolicy: "network-only",
    })

    const [addFieldSubfield] = useMutation<
        AddFieldSubfieldMutation,
        AddFieldSubfieldMutationVariables
    >(ADD_FIELD_SUBFIELD)
    const [removeFieldSubfield] = useMutation<
        RemoveFieldSubfieldMutation,
        RemoveFieldSubfieldMutationVariables
    >(REMOVE_FIELD_SUBFIELD)

    const fields = !!data?.criteria
        ? data.criteria.filter(
              (value, index, self) =>
                  index === self.findIndex((field) => field.id === value.id) &&
                  !value.isArchived
          )
        : []

    const { t } = useAwaitTranslation("criteria")

    const onAddSubfield = useCallback(
        async (field: Criteria, subfield: Criteria) => {
            await addFieldSubfield({
                variables: {
                    parentFieldId: field.id,
                    childFieldId: subfield.id,
                },
            })
            setFocusedFieldData(null)
            return
        },
        [addFieldSubfield]
    )

    const onRemoveSubfield = useCallback(
        async (field: Criteria, parentField: Criteria) => {
            await removeFieldSubfield({
                variables: {
                    parentFieldId: parentField.id,
                    childFieldId: field.id,
                },
            })
        },
        [removeFieldSubfield]
    )
    const handleRequestSort = (property) => {
        setPage(1)
        const isAsc = orderBy === property && order === "asc"
        setOrder(isAsc ? "desc" : "asc")
        setOrderBy(property)
    }
    const handlePageChange = (
        event: React.ChangeEvent<unknown>,
        value: number
    ) => {
        setPage(value)
    }

    if (networkStatus === NetworkStatus.loading) {
        return <Loading size={25} hideQuote={true} />
    }

    const availableTopLevelCategoryFields: {
        [x: string]: string | number
    }[] =
        stableSort(
            fields
                ?.filter(
                    (c) =>
                        c.name
                            ?.toLowerCase()
                            .indexOf(searchString?.toLowerCase()) !== -1 &&
                        (selectedCriteriaType === "All" ||
                            c.criteriaType === selectedCriteriaType)
                )
                ?.map((item) => {
                    return {
                        id: item.id,
                        name: item.name,
                        numberUsed: item.inputs.length,
                    }
                }) || [],
            getComparator(order, orderBy)
        ) || []

    const currentPageFields = availableTopLevelCategoryFields.filter(
        (item, index) =>
            index + 1 > rowsPerPage * (page - 1) &&
            index + 1 <= rowsPerPage * page
    )

    const categoriesWithWorkspaces = data?.categories ?? []
    return (
        <Box className={classes.root}>
            <Box
                height="100%"
                width="100%"
                overflow="hidden"
                display="flex"
                flexDirection="column"
            >
                <Box p={2} display="flex" alignItems="center">
                    <Box mr={2}>
                        <FieldTypeSelect
                            typeOptions={TOP_LEVEL_CRITERIA_OPTIONS}
                            value={selectedCriteriaType}
                            onChange={(value: CriteriaType | "All") => {
                                setPage(1)
                                setSelectedCriteriaType(value)
                            }}
                            variant="outlined"
                            size="small"
                            includeAll={true}
                        />
                    </Box>

                    <TextField
                        variant="outlined"
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <Search />
                                </InputAdornment>
                            ),
                        }}
                        size="small"
                        autoFocus
                        fullWidth
                        value={searchString}
                        onChange={(e) => {
                            setPage(1)
                            setSearchString(e.target.value)
                        }}
                        label={`${t("search", "Search")} ${t(
                            "fields",
                            "Fields"
                        )}`}
                    />
                    {!focusedFieldData && (
                        <Box ml={2} flexShrink={0}>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={() =>
                                    setFocusedFieldData({
                                        field: null,
                                        parentField: null,
                                    })
                                }
                            >
                                Create new
                            </Button>
                        </Box>
                    )}
                </Box>
                <Box
                    overflow="hidden"
                    width="100%"
                    display="flex"
                    flexDirection={"column"}
                    component={Paper}
                    height="100%"
                >
                    <TableContainer key={page} style={{ height: "100%" }}>
                        <Table stickyHeader>
                            <TableHead>
                                <TableRow>
                                    <TableCell
                                        size="small"
                                        className={classes.tableHeader}
                                        padding="checkbox"
                                    />
                                    <TableCell
                                        style={{ whiteSpace: "nowrap" }}
                                        size="small"
                                        className={classes.tableHeader}
                                        sortDirection={
                                            orderBy === "name" ? order : false
                                        }
                                    >
                                        <TableSortLabel
                                            onClick={() =>
                                                handleRequestSort("name")
                                            }
                                            active={orderBy === "name"}
                                            direction={
                                                orderBy === "name"
                                                    ? order
                                                    : "asc"
                                            }
                                        >
                                            Name
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell
                                        size="small"
                                        className={classes.tableHeader}
                                    >
                                        Default Workspaces
                                    </TableCell>
                                    <TableCell
                                        style={{ whiteSpace: "nowrap" }}
                                        size="small"
                                        className={classes.tableHeader}
                                        sortDirection={
                                            orderBy === "numberUsed"
                                                ? order
                                                : false
                                        }
                                    >
                                        <TableSortLabel
                                            onClick={() =>
                                                handleRequestSort("numberUsed")
                                            }
                                            active={orderBy === "numberUsed"}
                                            direction={
                                                orderBy === "numberUsed"
                                                    ? order
                                                    : "asc"
                                            }
                                        >
                                            {" "}
                                            # Used
                                        </TableSortLabel>
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {availableTopLevelCategoryFields?.length > 0 &&
                                    currentPageFields.map((item, index) => {
                                        const categoryWorkspacesUsedFor =
                                            categoriesWithWorkspaces.filter(
                                                (cat) => {
                                                    return !!cat.defaultDashboard?.widgets.find(
                                                        (widget) =>
                                                            JSON.parse(
                                                                widget.config ??
                                                                    "{}"
                                                            )?.criteriaId ===
                                                            item.id
                                                    )
                                                }
                                            )
                                        const fullField = fields.find(
                                            (o) => o.id === item.id
                                        )
                                        return (
                                            <FieldRow
                                                key={item.id}
                                                fieldWithSubfields={{
                                                    field: fullField,
                                                    subfields: fields.filter(
                                                        (field) =>
                                                            !!fullField.subfields.find(
                                                                (sub) =>
                                                                    sub.id ===
                                                                    field.id
                                                            )
                                                    ),
                                                }}
                                                focusedFieldId={
                                                    focusedFieldData?.field
                                                        ?.id || null
                                                }
                                                onFocus={(
                                                    field: Criteria,
                                                    child?: boolean
                                                ) => {
                                                    setFocusedFieldData({
                                                        field,
                                                        parentField: !!child
                                                            ? item
                                                            : null,
                                                    })
                                                }}
                                                categoryWorkspacesUsedFor={
                                                    categoryWorkspacesUsedFor
                                                }
                                                onCreateNewSubfield={() => {
                                                    setFocusedFieldData({
                                                        field: null,
                                                        parentField: item,
                                                    })
                                                }}
                                                onRemoveSubfield={
                                                    onRemoveSubfield
                                                }
                                                onAddExistingSubfield={async (
                                                    subfield: Criteria
                                                ) => {
                                                    await onAddSubfield(
                                                        item,
                                                        subfield
                                                    )
                                                }}
                                            />
                                        )
                                    })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <Divider />
                    <Box p={2} display="flex">
                        <Pagination
                            style={{ width: "100%" }}
                            onChange={handlePageChange}
                            count={Math.round(
                                availableTopLevelCategoryFields.length /
                                    rowsPerPage
                            )}
                        />
                        <Box display="flex" mr={3}>
                            <Typography noWrap>Rows Per Page:</Typography>
                            <Typography
                                noWrap
                                style={{ fontWeight: "bold", marginLeft: 8 }}
                            >
                                {rowsPerPage}
                            </Typography>
                        </Box>
                        <Box display="flex">
                            <Typography noWrap>Total Rows:</Typography>
                            <Typography
                                noWrap
                                style={{ fontWeight: "bold", marginLeft: 8 }}
                            >
                                {availableTopLevelCategoryFields.length}
                            </Typography>
                        </Box>
                    </Box>
                </Box>
            </Box>
            {!!focusedFieldData && (
                <Slide
                    direction="left"
                    mountOnEnter
                    unmountOnExit
                    in={!!focusedFieldData}
                >
                    <Paper
                        style={{
                            height: "100",
                            width: "100%",
                            marginLeft: theme.spacing(3),
                            marginTop: theme.spacing(2),
                            padding: theme.spacing(2),
                            overflow: "auto",
                        }}
                        variant="outlined"
                        elevation={0}
                    >
                        <Box p={2}>
                            <Typography variant="h6">
                                {!!focusedFieldData.field ||
                                focusedFieldData.parentField
                                    ? t("fieldEditor", "Field Editor")
                                    : t("newField", "New Field")}
                            </Typography>
                        </Box>
                        <CriteriaForm
                            onClose={() => {
                                setFocusedFieldData(null)
                            }}
                            onSave={async (criteria?: Criteria) => {
                                refetch()
                                return
                            }}
                            hideHeader={true}
                            parent={focusedFieldData?.parentField}
                            criteria={focusedFieldData?.field}
                        />
                    </Paper>
                </Slide>
            )}
        </Box>
    )
}

const FieldRow = (props: {
    fieldWithSubfields: {
        field: Criteria
        subfields: Criteria[]
    }
    categoryWorkspacesUsedFor?: Category[]
    onRemoveSubfield: (field: Criteria, parentField: Criteria) => Promise<void>
    onFocus?: (field: Criteria, sub?: boolean) => void
    onAddExistingSubfield?: (field: Criteria) => Promise<void>
    onCreateNewSubfield?: () => void
    focusedFieldId: string | null
}) => {
    const {
        fieldWithSubfields,
        onRemoveSubfield,
        onFocus,
        onAddExistingSubfield,
        focusedFieldId,
        onCreateNewSubfield,
        categoryWorkspacesUsedFor,
    } = props
    const { field, subfields } = fieldWithSubfields

    const { t } = useAwaitTranslation("criteria")
    const theme = useTheme()
    const classes = useStyles()
    const { getCategoryArray } = useCategoryTools()

    const [expanded, setExpanded] = useState(false)

    const onRemoval = async (subField: Criteria) => {
        await onRemoveSubfield(subField, field)
    }

    const icon = CRITERIA_TYPE_ICONS[field.criteriaType]
    let categories = []
    const collectionCategories =
        JSON.parse(field?.criteriaOptions ?? "{}")?.categories || []
    collectionCategories?.map((item) => {
        categories = [...categories, ...(getCategoryArray(item.id) ?? [])]
        return categories
    })

    return (
        <>
            <TableRow
                className={classes.row}
                key={field.id}
                hover
                onClick={() => {
                    if (!!onFocus) {
                        onFocus(field)
                    }
                }}
                selected={focusedFieldId === field.id}
                style={{
                    backgroundColor: !!expanded
                        ? alpha(theme.palette.primary.light, 0.05)
                        : "",
                }}
            >
                <TableCell padding="checkbox">
                    {field.criteriaType === CriteriaType.Collection && (
                        <IconButton
                            onClick={(e) => {
                                e.stopPropagation()
                                setExpanded(!expanded)
                            }}
                        >
                            {!!expanded ? (
                                <KeyboardArrowUp />
                            ) : (
                                <KeyboardArrowDown />
                            )}
                        </IconButton>
                    )}
                </TableCell>

                <TableCell>
                    <span
                        style={{
                            display: "flex",
                            alignItems: "center",
                        }}
                    >
                        <span
                            style={{
                                display: "flex",
                                alignItems: "center",
                                color: theme.palette.action.active,
                                marginRight: 10,
                            }}
                        >
                            {icon && icon}
                        </span>
                        <Typography variant="body1" style={{ marginRight: 8 }}>
                            {field.name}
                        </Typography>
                        {field.criteriaType === CriteriaType.Collection &&
                            !!collectionCategories && (
                                <CollectionCategorySubList
                                    categoryIds={collectionCategories?.map(
                                        (item) => item.id
                                    )}
                                />
                            )}
                    </span>
                </TableCell>
                <TableCell>
                    <Box display="flex">
                        {categoryWorkspacesUsedFor.map((cat) => {
                            return (
                                <Box key={cat.id} p={0.25}>
                                    <Chip
                                        size="small"
                                        icon={
                                            <span
                                                style={{
                                                    color: theme.palette.action
                                                        .active,
                                                    display: "flex",
                                                    alignItems: "center",
                                                }}
                                            >
                                                <CategoryIcon
                                                    fontSize="small"
                                                    category={cat}
                                                />
                                            </span>
                                        }
                                        label={cat.name}
                                    />
                                </Box>
                            )
                        })}
                    </Box>
                </TableCell>
                <TableCell>{field.inputs.length}</TableCell>
            </TableRow>
            <TableRow
                key={field.id + "-children"}
                style={{
                    backgroundColor: alpha(theme.palette.primary.light, 0.05),
                }}
            >
                <TableCell
                    colSpan={4}
                    style={{ paddingBottom: 0, paddingTop: 0 }}
                >
                    <Collapse in={expanded} unmountOnExit>
                        <Box width="100%" height="100%" p={2}>
                            {subfields.length > 0 && (
                                <TableContainer
                                    component={Paper}
                                    elevation={0}
                                    variant="outlined"
                                >
                                    <Table size="small">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell colSpan={4}>
                                                    Subfields
                                                </TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {subfields
                                                .filter(
                                                    (subfield) =>
                                                        !subfield.isArchived
                                                )
                                                .map((subfield, index) => {
                                                    const icon =
                                                        CRITERIA_TYPE_ICONS[
                                                            subfield
                                                                .criteriaType
                                                        ]
                                                    const workspacesUsedFor =
                                                        categoryWorkspacesUsedFor.filter(
                                                            (cat) => {
                                                                return !!cat.defaultDashboard?.widgets.find(
                                                                    (
                                                                        widget
                                                                    ) => {
                                                                        const config =
                                                                            JSON.parse(
                                                                                widget.config ??
                                                                                    "{}"
                                                                            )
                                                                        return (
                                                                            config?.criteriaId ===
                                                                                subfield.id &&
                                                                            config
                                                                                ?.inputConfig
                                                                                ?.source
                                                                                ?.criteriaIds
                                                                                ?.length >
                                                                                0
                                                                        )
                                                                    }
                                                                )
                                                            }
                                                        )

                                                    const numberOfTimesUsed =
                                                        subfield.inputs.filter(
                                                            (x) =>
                                                                JSON.parse(
                                                                    x.inputConfig ??
                                                                        "{}"
                                                                )?.source?.criteriaIds?.find(
                                                                    (o) =>
                                                                        o.id ===
                                                                        field.id
                                                                )
                                                        )?.length || 0

                                                    const cellBorder =
                                                        index !==
                                                        subfields.length - 1
                                                            ? `1px solid ${theme.palette.divider}`
                                                            : "none"

                                                    return (
                                                        <TableRow
                                                            onClick={(e) => {
                                                                e.stopPropagation()
                                                                if (!!onFocus) {
                                                                    onFocus(
                                                                        subfield,
                                                                        true
                                                                    )
                                                                }
                                                            }}
                                                            selected={
                                                                focusedFieldId ===
                                                                subfield.id
                                                            }
                                                            hover
                                                            key={subfield.id}
                                                        >
                                                            <TableCell
                                                                style={{
                                                                    borderBottom:
                                                                        cellBorder,
                                                                }}
                                                            >
                                                                <span
                                                                    style={{
                                                                        display:
                                                                            "flex",
                                                                        alignItems:
                                                                            "center",
                                                                    }}
                                                                >
                                                                    <span
                                                                        style={{
                                                                            display:
                                                                                "flex",
                                                                            alignItems:
                                                                                "center",
                                                                            color: theme
                                                                                .palette
                                                                                .action
                                                                                .active,
                                                                            marginRight: 10,
                                                                        }}
                                                                    >
                                                                        {icon &&
                                                                            icon}
                                                                    </span>
                                                                    <Typography
                                                                        variant="body2"
                                                                        style={{
                                                                            marginRight: 8,
                                                                        }}
                                                                    >
                                                                        {
                                                                            subfield.name
                                                                        }
                                                                    </Typography>
                                                                </span>
                                                            </TableCell>
                                                            <TableCell
                                                                style={{
                                                                    borderBottom:
                                                                        cellBorder,
                                                                }}
                                                            >
                                                                <Box>
                                                                    {workspacesUsedFor.map(
                                                                        (
                                                                            cat
                                                                        ) => {
                                                                            return (
                                                                                <Box
                                                                                    key={
                                                                                        cat.id
                                                                                    }
                                                                                    p={
                                                                                        0.25
                                                                                    }
                                                                                >
                                                                                    <Chip
                                                                                        size="small"
                                                                                        icon={
                                                                                            <span
                                                                                                style={{
                                                                                                    color: theme
                                                                                                        .palette
                                                                                                        .action
                                                                                                        .active,
                                                                                                    display:
                                                                                                        "flex",
                                                                                                    alignItems:
                                                                                                        "center",
                                                                                                }}
                                                                                            >
                                                                                                <CategoryIcon
                                                                                                    fontSize="small"
                                                                                                    category={
                                                                                                        cat
                                                                                                    }
                                                                                                />
                                                                                            </span>
                                                                                        }
                                                                                        label={
                                                                                            cat.name
                                                                                        }
                                                                                    />
                                                                                </Box>
                                                                            )
                                                                        }
                                                                    )}
                                                                </Box>
                                                            </TableCell>
                                                            <TableCell
                                                                style={{
                                                                    borderBottom:
                                                                        cellBorder,
                                                                }}
                                                            >
                                                                {
                                                                    numberOfTimesUsed
                                                                }
                                                            </TableCell>
                                                            <TableCell
                                                                style={{
                                                                    borderBottom:
                                                                        cellBorder,
                                                                }}
                                                                padding="checkbox"
                                                            >
                                                                <Tooltip
                                                                    title={t(
                                                                        "removeSubfield",
                                                                        "Remove subfield"
                                                                    )}
                                                                >
                                                                    <IconButton
                                                                        size="small"
                                                                        onClick={(
                                                                            e
                                                                        ) => {
                                                                            e.stopPropagation()
                                                                            onRemoval(
                                                                                subfield
                                                                            )
                                                                        }}
                                                                    >
                                                                        <Clear fontSize="small" />
                                                                    </IconButton>
                                                                </Tooltip>
                                                            </TableCell>
                                                        </TableRow>
                                                    )
                                                })}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            )}
                            <Box display="flex" p={1}>
                                <FieldAutocomplete
                                    optionsAllowed="Sub"
                                    onSelect={onAddExistingSubfield}
                                    currentFieldIds={subfields.map(
                                        (field) => field.id
                                    )}
                                    categoriesToGroupBy={categories}
                                    onCreateNewField={onCreateNewSubfield}
                                />
                            </Box>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </>
    )
}

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
    if (b[orderBy] < a[orderBy]) {
        return -1
    }
    if (b[orderBy] > a[orderBy]) {
        return 1
    }
    return 0
}

type Order = "asc" | "desc"

function getComparator<Key extends keyof any>(
    order: Order,
    orderBy: Key
): (
    a: { [key in Key]: number | string },
    b: { [key in Key]: number | string }
) => number {
    return order === "desc"
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy)
}

function stableSort<T>(array: T[], comparator: (a: T, b: T) => number) {
    const stabilizedThis = array.map((el, index) => [el, index] as [T, number])
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0])
        if (order !== 0) return order
        return a[1] - b[1]
    })
    return stabilizedThis.map((el) => el[0])
}

export default FieldsManager
