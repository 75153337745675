import Container from "@material-ui/core/Container"
import { makeStyles } from "@material-ui/core/styles"
import { useEffect } from "react"
import { Box, Button } from "@material-ui/core"
import { ReactComponent as AmbleLogo } from "../logo.svg"
import { useAuth } from "../providers/AuthProvider"
import { useHistory, useLocation } from "react-router-dom"
import useAwaitTranslation from "../i18n/useAwaitTranslation"
import BeenhereIcon from "@material-ui/icons/Beenhere"
import AddBoxIcon from "@material-ui/icons/AddBox"
const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
    },
    root: {
        position: "absolute",
        left: 0,
        top: 0,
        right: 0,
        bottom: 0,
        backgroundColor: theme.palette.background.paper,
    },
}))

export default function LoginOptions() {
    const classes = useStyles()
    const location = useLocation()
    const queryParams = new URLSearchParams(location.search)
    const continueUrl = queryParams.get("continueUrl")

    const { isSignedIn } = useAuth()
    const history = useHistory()
    const { t } = useAwaitTranslation("login")
    useEffect(() => {
        if (isSignedIn) {
            history.push(continueUrl || "/")
        }
    }, [isSignedIn, history, continueUrl])
    return (
        <Box className={classes.root}>
            <Container component="main" maxWidth="md">
                <div className={classes.paper}>
                    <Box
                        display="flex"
                        width="50%"
                        mb={2}
                        justifyContent="center"
                    >
                        <AmbleLogo style={{ height: "70px" }} />
                    </Box>

                    <Box
                        mt={3}
                        display="flex"
                        width="100%"
                        justifyContent="center"
                    >
                        <Button
                            onClick={() =>
                                history.push(
                                    continueUrl
                                        ? `/login/base?continueUrl=${continueUrl}`
                                        : "/login/base"
                                )
                            }
                            style={{ width: "17.5rem", marginRight: 20 }}
                            variant="contained"
                            startIcon={<BeenhereIcon />}
                        >
                            {t(
                                "existingAccountLogin",
                                "Existing Account Login"
                            )}
                        </Button>

                        <Button
                            onClick={() =>
                                history.push(
                                    continueUrl
                                        ? `/signup-options?continueUrl=${continueUrl}`
                                        : "/signup-options"
                                )
                            }
                            style={{ width: "17.5rem", marginLeft: 20 }}
                            variant="contained"
                            startIcon={<AddBoxIcon />}
                        >
                            {t("createNewAccount", "Create New Account")}
                        </Button>
                    </Box>
                </div>
            </Container>
        </Box>
    )
}
