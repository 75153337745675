import React, { useEffect, useState } from "react"
import {
    Box,
    Fade,
    Typography,
    Button,
    Divider,
    CircularProgress,
} from "@material-ui/core"
import Container from "@material-ui/core/Container"
import CssBaseline from "@material-ui/core/CssBaseline"
import { ReactComponent as AmbleLogo } from "../../logo.svg"
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles"
import { useAuth } from "../../providers/AuthProvider"
import { useHistory } from "react-router-dom"
import { useApolloClient, useMutation } from "@apollo/client"
import { ADMIN_EMAILS } from "./graphql"
import {
    AdminEmailsQuery,
    AdminEmailsQueryVariables,
} from "./__generated__/graphql"
import { useTheme } from "@material-ui/core/styles"
import { SEND_EMAIL } from "../../graphql/mutations"
import {
    SendEmailMutation,
    SendEmailMutationVariables,
} from "../../graphql/__generated__/mutations"
import Send from "@material-ui/icons/Send"
import { Alert } from "@material-ui/lab"
import { useParams } from "react-router-dom"
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        page: {
            width: "100vw",
            height: "100vh",
        },
        paper: {
            marginTop: theme.spacing(8),
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
        },
        box: {
            position: "absolute",
            left: 0,
            top: 0,
            right: 0,
            bottom: 0,
            backgroundColor: theme.palette.background.paper,
            overflow: "hidden",
        },
        border: {
            border: `1px solid ${theme.palette.divider}`,
            borderRadius: theme.shape.borderRadius,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            padding: theme.spacing(2),
        },
    })
)

const RequestAccess = () => {
    const classes = useStyles()
    const { requestStatus } = useParams()
    const { logout, hasAccess, currentUser } = useAuth()
    const history = useHistory()
    const client = useApolloClient()
    const theme = useTheme()
    const [sending, setSending] = useState(false)

    const [sendEmail] = useMutation<
        SendEmailMutation,
        SendEmailMutationVariables
    >(SEND_EMAIL)
    const handleLogout = async () => {
        try {
            await logout()
        } catch (e) {
            console.log("Failed to logout")
        }
        history.push("/login")
    }
    useEffect(() => {
        if (!!currentUser && !!hasAccess) {
            history.push("/feed")
        }
    }, [currentUser, hasAccess, history])
    const handleAccessRequest = async () => {
        setSending(true)
        const {
            data: { User: admins },
        } = await client.query<AdminEmailsQuery, AdminEmailsQueryVariables>({
            query: ADMIN_EMAILS,
            fetchPolicy: "no-cache",
        })

        const emails = admins?.map((item) => item.email)

        if (emails.length > 0) {
            await sendEmail({
                variables: {
                    sendTo: emails,
                    subject: `Amble Access Request`,
                    text: "<i></i>",
                    separateEmails: false,
                    html: `<div>
                                <h2>
                                ${currentUser.lastName}, ${currentUser.firstName} has requested access to Amble Ideation
                                </h2>
                                <p style="color: ${theme.palette.text.secondary};">
                                First Name 
                                </p>
                                <p>
                                    ${currentUser.firstName}
                                </p>
                                <p style="color: ${theme.palette.text.secondary};">
                                Last Name 
                                </p>
                                <p>
                                    ${currentUser.lastName}
                                </p>
                                <p style="color: ${theme.palette.text.secondary};">
                                    Email   
                                </p>
                                <p>
                                    ${currentUser.email}
                                </p>
                                <p style="color: ${theme.palette.text.secondary};">
                                Username  
                                </p>
                                <p>
                                    ${currentUser.username}
                                </p>
                                <p style="color: ${theme.palette.text.secondary};">
                                Company 
                                </p>
                                <p>
                                    ${currentUser.company}
                                </p>
                                <h3>
                                <a href="${window.location.origin}/settings/users/${currentUser.username}/confirmAccess">Click to view</a>
                                </h3>  
                                <p>
                                <i>This is a system-generated notification email from Amble Ideation. If you have any questions about the authenticity of this email, please contact your Amble system administrator.</i>
                                </p>
                            </div>`,
                },
            })
        }
        setSending(false)
        history.replace("/request-access/sent")
    }
    return (
        <Box className={classes.box}>
            <Container component="main" maxWidth="xs" className={classes.page}>
                <CssBaseline />
                <Fade in={true}>
                    <div className={classes.paper}>
                        <Box display="flex" width="50%" mb={2}>
                            <AmbleLogo />
                        </Box>
                        <Box mt={1} className={classes.border}>
                            <Box mt={2}>
                                <Typography variant="h6" align="center">
                                    You do not currently have access.
                                </Typography>
                            </Box>
                            <Box mt={4}>
                                {requestStatus === "sent" ? (
                                    <Alert severity="success">
                                        Access Requested
                                    </Alert>
                                ) : (
                                    <Button
                                        onClick={handleAccessRequest}
                                        startIcon={<Send />}
                                        variant={"contained"}
                                        disabled={!!sending}
                                        style={{ position: "relative" }}
                                    >
                                        {!!sending && (
                                            <CircularProgress
                                                size={20}
                                                style={{
                                                    position: "absolute",
                                                }}
                                            />
                                        )}
                                        Request Access
                                    </Button>
                                )}
                            </Box>
                            <Box
                                display="flex"
                                width="100%"
                                alignItems="center"
                                py={2}
                            >
                                <Divider style={{ flexGrow: 1 }} />
                                <Box m={1}>OR</Box>
                                <Divider style={{ flexGrow: 1 }} />
                            </Box>

                            <Button onClick={handleLogout} disabled={!!sending}>
                                Logout
                            </Button>
                        </Box>
                    </div>
                </Fade>
            </Container>
        </Box>
    )
}

export default RequestAccess
