import { CircularProgress } from "@material-ui/core"
import React from "react"
import { MemoQuote } from "./Quote"
import Box from "@material-ui/core/Box"

interface Props {
    size?: string | number
    hideQuote?: boolean
    padding?: number
    message?: string
    zIndex?: number
}

const LoadingIndicator = (props: Props) => {
    const size = props.size || 50
    return (
        <Box
            display="flex"
            flexDirection="column"
            width="100%"
            justifyContent="center"
            alignItems="center"
            height="100%"
            padding={props.padding ?? 0}
            zIndex={props.zIndex ?? 0}
        >
            <CircularProgress disableShrink size={size} />
            {props.message ?? (!props.hideQuote ? <MemoQuote /> : null)}
        </Box>
    )
}

export const Loading = React.memo(LoadingIndicator)
