import React, { useState } from "react"
import { makeStyles } from "@material-ui/core"
import Avatar from "@material-ui/core/Avatar"
import Button from "@material-ui/core/Button"
import CircularProgress from "@material-ui/core/CircularProgress"
import Container from "@material-ui/core/Container"
import Dialog from "@material-ui/core/Dialog"
import TextField from "@material-ui/core/TextField"
import Typography from "@material-ui/core/Typography"
import LockOutlinedIcon from "@material-ui/icons/LockOutlined"
import DoneIcon from "@material-ui/icons/Done"
import useAwaitTranslation from "../../i18n/useAwaitTranslation"
import { useAuth } from "../../providers/AuthProvider"

const useStyles = makeStyles((theme) => ({
    page: {
        width: "100vw",
        height: "100vh",
    },
    paper: {
        marginTop: theme.spacing(8),
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.primary.main,
    },
    form: {
        width: "100%",
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    customError: {
        color: "red",
        fontSize: "0.8rem",
        marginTop: 10,
    },
    progress: {
        position: "absolute",
    },
}))
const defaultErrorObj = {
    email: "",
    general: "",
}

interface ForgotPasswordProps {
    error?: string
}
export default function ForgotPassword(props: ForgotPasswordProps) {
    const classes = useStyles()
    const { error } = props
    const [email, setEmail] = useState("")
    const [emailSent, setEmailSent] = useState(false)
    const [errors, setErrors] = useState({
        ...defaultErrorObj,
        ...{ general: error },
    })
    const [loading, setLoading] = useState(false)
    const { t } = useAwaitTranslation("login")
    const { resetPassword } = useAuth()
    const handleForgotPassword = (e) => {
        e.preventDefault()
        setErrors(defaultErrorObj)
        setLoading(true)
        resetPassword(email)
            .then((e) => {
                setEmailSent(true)
            })
            .catch((e) => {
                const message = { general: e.message }
                setErrors({ ...errors, ...message })
                setLoading(false)
            })
    }
    return (
        <Dialog fullScreen open={true} style={{ zIndex: 99999 }}>
            <Container component="main" maxWidth="xs" className={classes.page}>
                <div className={classes.paper}>
                    {emailSent ? (
                        <>
                            <Avatar className={classes.avatar}>
                                <DoneIcon />
                            </Avatar>
                            <Typography
                                component="div"
                                variant="h6"
                                style={{ textAlign: "center" }}
                            >
                                {t(
                                    "emailSent",
                                    "The password reset instructions have been sent to your email!"
                                )}
                            </Typography>
                        </>
                    ) : (
                        <>
                            <Avatar className={classes.avatar}>
                                <LockOutlinedIcon />
                            </Avatar>
                            <Typography component="h1" variant="h5">
                                {t("resetPassword", "Reset Password")}
                            </Typography>
                            <form className={classes.form} noValidate>
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="email"
                                    label={t("email", "Email")}
                                    name="email"
                                    autoComplete="email"
                                    autoFocus
                                    helperText={errors.email}
                                    error={errors.email ? true : false}
                                    disabled={loading}
                                    onChange={(e) => setEmail(e.target.value)}
                                />
                                <Button
                                    type="submit"
                                    fullWidth
                                    variant="contained"
                                    color="primary"
                                    className={classes.submit}
                                    onClick={handleForgotPassword}
                                    disabled={loading || !email}
                                >
                                    {t("submit", "Submit")}
                                    {loading && (
                                        <CircularProgress
                                            disableShrink
                                            size={30}
                                            className={classes.progress}
                                        />
                                    )}
                                </Button>
                                {errors.general && (
                                    <Typography
                                        variant="body2"
                                        className={classes.customError}
                                    >
                                        {errors.general}
                                    </Typography>
                                )}
                            </form>
                        </>
                    )}
                </div>
            </Container>
        </Dialog>
    )
}
