import { useState } from "react"
import {
    Dialog,
    DialogContent,
    DialogTitle,
    IconButton,
    DialogContentText,
    DialogActions,
    Button,
} from "@material-ui/core"
import { toCapitalizedWords } from "../../util/fns"
import InfoIcon from "@material-ui/icons/Info"
import useAwaitTranslation from "../../i18n/useAwaitTranslation"

export enum SettingsSection {
    Users = "Users",
    Criteria = "Criteria",
    Themes = "Themes",
    Industries = "Industries",
    Categories = "Categories",
}
export const SETTINGS_TRANSLATION_KEYS: {
    [key in SettingsSection]: string
} = {
    [SettingsSection.Users]: "usersGuidance",
    [SettingsSection.Criteria]: "criteriaGuidance",
    [SettingsSection.Themes]: "themesGuidance",
    [SettingsSection.Industries]: "industriesGuidance",
    [SettingsSection.Categories]: "categoriesGuidance",
}

export const SETTINGS_GUIDANCES: {
    [key in SettingsSection]: string
} = {
    [SettingsSection.Users]:
        "Manage the applications users by adding, deleting or editing their rolesand information.",
    [SettingsSection.Criteria]:
        "Manage all existing criteria that concepts can be evaluated on.",
    [SettingsSection.Themes]:
        "Personalize your experience through managing a fully configurable list of themes. Love a specific color pattern? Set it as your default light or dark mode theme.",
    [SettingsSection.Industries]:
        "Manage all existing industries that concepts can be assigned.",
    [SettingsSection.Categories]:
        "Add, edit, or nest submission categories that concepts can be assigned as.",
}

interface GuidanceModalProps {
    section: SettingsSection
}
export default function GuidanceModal(props: GuidanceModalProps) {
    const { section } = props
    const [viewGuidance, setViewGuidance] = useState(false)
    const guidance = SETTINGS_GUIDANCES[section]
    const translationKey = SETTINGS_TRANSLATION_KEYS[section]
    const { t } = useAwaitTranslation("global")
    return (
        <>
            <IconButton
                style={{
                    marginLeft: 10,
                }}
                onClick={() => {
                    setViewGuidance(true)
                }}
            >
                <InfoIcon />
            </IconButton>
            <Dialog
                open={viewGuidance}
                onBackdropClick={() => {
                    setViewGuidance(false)
                }}
            >
                <DialogTitle>
                    {t(section.toLowerCase(), toCapitalizedWords(section))}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {t(translationKey, guidance)}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => {
                            setViewGuidance(false)
                        }}
                    >
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}
