import { FilterItem } from "./types"

export const getNewState = (
    item: FilterItem,
    newValue,
    filters: FilterItem[],
    id: string
) => {
    let filter = { ...item }
    filter["value"] = []
    const newState = [...filters]
    let index = -1

    newState.forEach((x, idx) => {
        if (
            ((!x.secondLevelFilter && !filter.secondLevelFilter) ||
                x.secondLevelFilter === filter.secondLevelFilter) &&
            ((!x.firstLevelFilter && !filter.firstLevelFilter) ||
                x.firstLevelFilter === filter.firstLevelFilter) &&
            (!!x.field ? x.field === filter.field : x.type === filter.type)
        ) {
            index = idx
        }
    })

    newValue.forEach((item, idx) => {
        if (id) {
            filter["value"].push(item[id])
        } else {
            filter["value"].push(item)
        }
    })
    if (index !== -1) {
        newState[index] = filter
    } else {
        newState.push(filter)
    }

    return newState
}
