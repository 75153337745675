import { useState, useEffect, useCallback } from "react"
import { useQuery, useMutation, useApolloClient } from "@apollo/client"
import { USER_MANAGEMENT_LINE_ITEM } from "./graphql"
import {
    DeleteUserMutation,
    DeleteUserMutationVariables,
    UserManagementLineItemQuery,
    UserManagementLineItemQueryVariables,
} from "./__generated__/graphql"
import {
    ADD_USER_HOME_CONCEPT,
    CREATE_USER,
    UPDATE_USER,
    DELETE_USER,
} from "../../components/users/graphql"
import {
    AddUserHomeConceptMutation,
    AddUserHomeConceptMutationVariables,
    CreateUserMutationVariables,
    CreateUserMutation,
    UpdateUserMutation,
    UpdateUserMutationVariables,
} from "../../components/users/__generated__/graphql"
import { CREATE_USER_WORKSPACE } from "../../graphql/conceptListQueries"
import {
    CreateUserWorkspaceMutation,
    CreateUserWorkspaceMutationVariables,
} from "../../graphql/__generated__/conceptListQueries"
import { CREATE_CONCEPT, SEND_EMAIL } from "../../graphql/mutations"
import {
    CreateConceptCompleteMutation,
    CreateConceptCompleteMutationVariables,
    SendEmailMutation,
    SendEmailMutationVariables,
} from "../../graphql/__generated__/mutations"
import {
    AccessType,
    LanguageString,
    SystemRole,
    User,
} from "../../__generated__/types"
import { USER_FILTERED } from "./graphql"
import { ADD_TEAM_MEMBER } from "../teams/graphql"
import {
    AddTeamMemberMutation,
    AddTeamMemberMutationVariables,
} from "../teams/__generated__/graphql"
import { useAuth } from "../../providers/AuthProvider"
import useAwaitTranslation from "../../i18n/useAwaitTranslation"
export default function useUserManagementTools(username?: string) {
    const { data, refetch } = useQuery<
        UserManagementLineItemQuery,
        UserManagementLineItemQueryVariables
    >(USER_MANAGEMENT_LINE_ITEM, {
        variables: {
            username: username,
        },
        skip: !username,
    })
    const { t } = useAwaitTranslation("user")
    const client = useApolloClient()
    const { currentUser } = useAuth()
    const [createPersonalConcept] = useMutation<
        CreateConceptCompleteMutation,
        CreateConceptCompleteMutationVariables
    >(CREATE_CONCEPT)
    const [addHomeConcept] = useMutation<
        AddUserHomeConceptMutation,
        AddUserHomeConceptMutationVariables
    >(ADD_USER_HOME_CONCEPT)
    const [createWorkspace] = useMutation<
        CreateUserWorkspaceMutation,
        CreateUserWorkspaceMutationVariables
    >(CREATE_USER_WORKSPACE)
    const [createUser] = useMutation<
        CreateUserMutation,
        CreateUserMutationVariables
    >(CREATE_USER)
    const [deleteUser, { loading: deleting }] = useMutation<
        DeleteUserMutation,
        DeleteUserMutationVariables
    >(DELETE_USER)
    const [updateUser, { loading: updating }] = useMutation<
        UpdateUserMutation,
        UpdateUserMutationVariables
    >(UPDATE_USER)
    const [sendEmail, { loading: sending }] = useMutation<
        SendEmailMutation,
        SendEmailMutationVariables
    >(SEND_EMAIL)
    const [addMember] = useMutation<
        AddTeamMemberMutation,
        AddTeamMemberMutationVariables
    >(ADD_TEAM_MEMBER)
    const [initialized, setInitialized] = useState(false)
    const [errors, setErrors] = useState([])
    const userData = data?.User?.[0] ?? null
    const [user, setUser] = useState(null)
    useEffect(() => {
        if (userData) {
            setUser(userData)
            setInitialized(true)
        }
    }, [userData, data])

    const onUpdateUser = useCallback(
        async (userData) => {
            await updateUser({
                variables: {
                    ...userData,
                },
            })
        },
        [updateUser]
    )

    const getSystemRoleLabel = (systemRole: SystemRole) => {
        switch (systemRole) {
            case SystemRole.NONE:
                return t("none", "None")
            case SystemRole.GUEST:
                return t("guest", "Guest")
            case SystemRole.MEMBER:
                return t("member", "Member")
            case SystemRole.MANAGER:
                return t("contentManager", "Content Manager")
            case SystemRole.ADMIN:
                return t("admin", "Admin")
            case null:
                return t("none", "None")
        }
    }

    const onDeleteUser = async (userToDelete: User) => {
        await deleteUser({
            variables: {
                userId: userToDelete?.userId,
            },
        })

        client.cache.evict({
            id: client.cache.identify(userToDelete),
        })
    }
    const onAddTeams = useCallback(
        async (teamIds: string[], userId: string) => {
            await Promise.all([
                ...teamIds.map((teamId) =>
                    addMember({
                        variables: {
                            fromId: userId,
                            toId: teamId,
                        },
                    })
                ),
            ])
            await refetch()
        },
        [addMember, refetch]
    )
    const onCreateUser = async (userData) => {
        const {
            data: { User: UserCheck },
        } = await client.query({
            query: USER_FILTERED,
            variables: {
                filter: {
                    email: userData?.email,
                },
            },
        })
        if (!!UserCheck) {
            if (UserCheck.length > 0) {
                setErrors([
                    "There has already been an account created for this email",
                ])
                return null
            } else {
                const {
                    data: { CreateUser: User },
                } = await createUser({
                    variables: {
                        ...userData,
                        userId: userData.email,
                        language: LanguageString.EN,
                        username: userData.email,
                    },
                })

                await createWorkspace({
                    variables: {
                        userId: User.userId,
                    },
                })
                const {
                    data: { CreateConcept: NewConcept },
                } = await createPersonalConcept({
                    variables: {
                        concept: {
                            title: `${User.username}'s Personal Concept`,
                            isPublic: false,
                            isPublicAccessType: AccessType.NONE,
                            type: "User Concept",
                        },
                        userId: User.userId,
                        tags: [],
                    },
                })
                await addHomeConcept({
                    variables: {
                        conceptId: NewConcept.id,
                        userId: User.userId,
                    },
                })
                return User
            }
        } else {
            setErrors([
                ...errors,
                "We are having issues validating the email address.",
            ])
            return
        }
    }

    const onGrantSystemAccess = async (
        userId: string,
        email: string,
        role: SystemRole,
        priorRole: SystemRole | null,
        alertUser?: boolean
    ) => {
        await onUpdateUser({
            userId: userId,
            role: role,
        })

        if (!!alertUser) {
            await sendEmail({
                variables: {
                    sendTo: [email],
                    subject: `Amble Ideation Access`,
                    text: "<i>No Invitation Notes</i>",
                    separateEmails: false,
                    html: getEmailBody(priorRole, role, currentUser),
                },
            })
        }
    }

    const getEmailBody = (
        priorRole: SystemRole,
        newRole: SystemRole,
        currentUser: User
    ) => {
        if (priorRole === SystemRole.NONE || !priorRole) {
            return `<div>
            <h2>
                You have been provided access to Amble Ideation
             </h2>
                <h3>
                    <a href="${
                        window.location.origin
                    }/login">Click here</a> to sign in with the credentials you created
                </h3> 
                <h3>Access granted:
                <span style="font-weight: normal;">
                    ${getSystemRoleLabel(newRole)}
                </span>
            </h3>
                <h3>Access granted by:
                    <span style="font-weight: normal;">
                        ${currentUser.firstName} ${currentUser.lastName}
                    </span>
                </h3>
                <br>
                <p>
                <i>This is a system-generated notification email from Amble Ideation. If you have any questions about the authenticity of this email, please contact your Amble system administrator.</i>
                </p>
            </div>`
        } else {
            return `<div>
            <h2>
                Your access to Amble Ideation has been updated
             </h2>
               ${
                   newRole !== SystemRole.NONE
                       ? `<h3>
                <a href="${window.location.origin}/login">Click here</a> to sign in
            </h3>`
                       : ``
               }
                <h3>Access change:
                <span style="font-weight: normal;">${getSystemRoleLabel(
                    priorRole
                )} > ${getSystemRoleLabel(newRole)}</span>
                </h3>  
                <h3>Access changed by:
                    <span style="font-weight: normal;">
                        ${currentUser.firstName} ${currentUser.lastName}
                    </span>
                </h3>
                <br>
                <p>
                <i>This is a system-generated notification email from Amble Ideation. If you have any questions about the authenticity of this email, please contact your Amble system administrator.</i>
                </p>
            </div>`
        }
    }

    return {
        initialized,
        user,
        onCreateUser,
        onUpdateUser,
        onAddTeams,
        deleting,
        errors,
        refetch,
        sending,
        updating,
        onDeleteUser,
        onGrantSystemAccess,
        getSystemRoleLabel,
    }
}
