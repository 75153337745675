import { _ConceptOrdering } from "../__generated__/types"
export const ConceptFieldSettings = {
    Title: {
        maxLength: 150,
    },
    Summary: {
        maxLength: 300,
    },
}

export const ExerciseFieldSettings = {
    title: {
        maxLength: 75,
    },
    description: {
        maxLength: 300,
    },
}
export const ExerciseTypeFieldSettings = {
    title: {
        maxLength: 75,
    },
    description: {
        maxLength: 300,
    },
    instructions: {
        maxLength: 300,
    },
}

export const sortOptions = [
    {
        label: "Newest First",
        translationKey: "newestFirst",
        value: _ConceptOrdering.CREATEDAT_DESC,
    },
    {
        label: "Oldest First",
        translationKey: "oldestFirst",
        value: _ConceptOrdering.CREATEDAT_ASC,
    },
    {
        label: "Title (A -> Z)",
        translationKey: "titleAsc",
        value: _ConceptOrdering.TITLE_ASC,
    },
    {
        label: "Title (Z -> A)",
        translationKey: "titleDesc",
        value: _ConceptOrdering.TITLE_DESC,
    },
]
