import React, { useState } from "react"
import { Chip, Typography } from "@material-ui/core"
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles"

import { Concept } from "../__generated__/types"
import { useHistory } from "react-router-dom"
import ConceptAvatar from "./ConceptAvatar"
import ConceptPreviewPopper from "./ConceptPreviewPopper"
import { useAuth } from "../providers/AuthProvider"
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        chip: {
            height: "auto !important",
            flexGrow: 1,
            maxWidth: "100%",
            borderRadius: "100em",
        },
        label: {
            flexGrow: 1,
            paddingLeft: 5,
        },
    })
)

const ConceptChip = (props: {
    item: Concept
    disablePopper?: boolean
    disableClick?: boolean
}) => {
    const classes = useStyles()
    const history = useHistory()
    const [anchorEl, setAnchorEl] = useState(null)
    const { isNonMember } = useAuth()
    const handleMouseEnter = (event: React.MouseEvent<HTMLElement>) => {
        if (!props.disablePopper) {
            setAnchorEl(anchorEl ? null : event.currentTarget)
        }
    }
    const handleMouseExit = (event: React.MouseEvent<HTMLElement>) => {
        if (!props.disablePopper) {
            setAnchorEl(null)
        }
    }
    let chipProps = {}
    if (!props.disableClick && !isNonMember) {
        chipProps["onClick"] = () => {
            history.push(`/concept/${props.item.id}/home`)
        }
    }
    return (
        <Chip
            {...chipProps}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseExit}
            avatar={
                <ConceptAvatar
                    concept={props.item}
                    size="medium"
                    variant="circle"
                />
            }
            className={classes.chip}
            style={{
                cursor: !props.disableClick ? "pointer" : "inherit",

                overflow: "hidden",
                display: "flex",
            }}
            classes={{
                label: classes.label,
            }}
            label={
                !props.disablePopper ? (
                    <ConceptPreviewPopper conceptId={props.item.id}>
                        <Typography variant="subtitle2" noWrap>
                            {props.item.title}
                        </Typography>
                    </ConceptPreviewPopper>
                ) : (
                    <Typography variant="subtitle2" noWrap>
                        {props.item.title}
                    </Typography>
                )
            }
        />
    )
}

export default ConceptChip
