import React, { useRef } from "react"
import {
    LineChart,
    Line,
    XAxis,
    YAxis,
    Tooltip,
    Legend,
    ReferenceLine,
    ReferenceDot,
    ResponsiveContainer,
} from "recharts"
import { makeStyles, useTheme } from "@material-ui/core/styles"
import Divider from "@material-ui/core/Divider"
import Box from "@material-ui/core/Box"
import { Paper } from "@material-ui/core"
import useAwaitTranslation from "../../i18n/useAwaitTranslation"
import { CurveData } from "./line-data/ProductChartData"
import { CriteriaScore } from "../../__generated__/types"
import ConceptAvatar from "../ConceptAvatar"
import ChevronRight from "@material-ui/icons/ChevronRight"
import useEnvironmentSettingTools from "../settings/useEnvironmentSettingTools"
import ResponseListItem from "../inputs/ResponseListItem"

const useStyles = makeStyles((theme) => ({
    container: {
        height: "100%",
        display: "flex",
        flexDirection: "column",
    },
    flex: {
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-around",
        width: "100%",

        overflow: "hidden",
    },
    yAxis: {
        writingMode: "vertical-lr",
        textOrientation: "sideways",
        transform: "rotate(180deg)",
        display: "flex",
        justifyContent: "center",
        width: "20px",
        fontSize: 12,
        color: theme.palette.text.secondary,
    },
    clickable: {
        cursor: "crosshair !important",
    },
    timeContainer: {
        fontSize: 12,
        color: theme.palette.text.secondary,
        textAlign: "center",
        marginBottom: "5px",
    },
}))

interface ProductChartInterfaceProps {
    scores: CriteriaScore[]
    onEditResponse?: (value: number) => void
}
type PositionObject = {
    x: number
    y: number
    id: string
    primary: boolean
}

function ProductChartInterface(props: ProductChartInterfaceProps) {
    const { scores, onEditResponse } = props
    //hooks
    const theme = useTheme()
    const classes = useStyles()
    const { t } = useAwaitTranslation("widgets")

    //
    const sizeRef = useRef(null)

    const multipleConceptsEvaluated =
        scores
            ?.map((score) => score?.scoredConcept?.id)
            .filter((value, index, self) => self.indexOf(value) === index)
            ?.length > 1

    //functions
    const handleClick = (data: { activeLabel: number }) => {
        if (
            !!data?.activeLabel &&
            !!onEditResponse &&
            !isNaN(data.activeLabel)
        ) {
            onEditResponse(data.activeLabel)
        }
    }

    let salesArray: PositionObject[] = []
    let profitArray: PositionObject[] = []
    let data = [...CurveData].map((item) => ({ ...item, dataPoints: {} }))

    scores
        ?.filter((score) => !!score?.response)
        .forEach((score) => {
            let matchingPoint = data.find(
                (item) => item.x === Number(score?.response)
            )

            if (!!matchingPoint) {
                matchingPoint.dataPoints[score?.id] = {
                    score: score,
                    concept: !!multipleConceptsEvaluated
                        ? score?.scoredConcept
                        : null,
                    position: matchingPoint.x,
                }
                salesArray.push({
                    id: score?.id,
                    x: Number(score?.response),
                    y: matchingPoint?.Sales ?? 0,
                    primary: !!score.conceptDefault,
                })
                profitArray.push({
                    id: score?.id,
                    x: Number(score?.response),
                    y: matchingPoint?.Profit ?? 0,
                    primary: !!score.conceptDefault,
                })
            }
        })

    return (
        <div className={classes.flex}>
            <Box display="flex" height="100%" overflow="hidden">
                <div
                    style={{
                        width: "100%",
                        height: "100%",
                        flexGrow: 1,
                        overflow: "hidden",
                    }}
                    ref={sizeRef}
                >
                    <ResponsiveContainer height="100%" width="100%">
                        <LineChart
                            style={{
                                cursor: !!onEditResponse
                                    ? "crosshair"
                                    : "default",
                            }}
                            data={data}
                            // @ts-ignore
                            onClick={handleClick}
                            margin={{
                                top: theme.spacing(1),
                                right: theme.spacing(2),
                                left: theme.spacing(3),
                                bottom: theme.spacing(5),
                            }}
                        >
                            <XAxis
                                tick={
                                    <XaxisTick
                                        width={sizeRef?.current?.clientWidth}
                                    />
                                }
                                ticks={[
                                    0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100,
                                ]}
                                type="number"
                                dataKey="x"
                                interval="preserveEnd"
                                label={{
                                    value: t("time", "Time"),
                                    position: "insideBottom",
                                    offset: -theme.spacing(3),
                                    fill: theme.palette.text.primary,
                                }}
                            />
                            <YAxis
                                tick={
                                    <YaxisTick
                                        width={sizeRef?.current?.clientWidth}
                                    />
                                }
                                ticks={[-980, 0, 3500]}
                                type="number"
                                label={{
                                    value: `${t("money", "Money")} ($)`,
                                    angle: -90,
                                    position: "insideLeft",
                                    fill: theme.palette.text.primary,
                                }}
                            />
                            <Tooltip
                                content={
                                    <CustomTooltip palette={theme.palette} />
                                }
                            />
                            <Legend verticalAlign="top" height={15} />
                            <ReferenceLine stroke="rgb(102, 102, 102)" y={0} />
                            <Line
                                dot={false}
                                type="monotone"
                                dataKey="Profit"
                                stroke={theme.palette.primary.dark}
                                fill={theme.palette.primary.dark}
                            />
                            <Line
                                dot={false}
                                type="monotone"
                                dataKey="Sales"
                                stroke={theme.palette.secondary.main}
                                fill={theme.palette.secondary.main}
                            />
                            {salesArray?.map((item, idx) => {
                                const key = idx.toString() + "sales"
                                return (
                                    <ReferenceDot
                                        key={key}
                                        fill={
                                            !!item.primary
                                                ? theme.palette.primary.main
                                                : theme.palette.secondary.main
                                        }
                                        style={{
                                            cursor: !!onEditResponse
                                                ? "pointer"
                                                : "default",
                                        }}
                                        isFront={!!item.primary}
                                        r={5}
                                        xAxisId={0}
                                        x={item.x}
                                        y={item.y}
                                    />
                                )
                            })}
                            {profitArray?.map((item, idx) => {
                                const key = idx.toString() + "profit"
                                return (
                                    <ReferenceDot
                                        key={key}
                                        style={{
                                            cursor: !!onEditResponse
                                                ? "pointer"
                                                : "default",
                                        }}
                                        fill={
                                            !!item.primary
                                                ? theme.palette.primary.main
                                                : theme.palette.secondary.main
                                        }
                                        r={5}
                                        isFront={!!item.primary}
                                        xAxisId={0}
                                        x={item.x}
                                        y={item.y}
                                    />
                                )
                            })}
                        </LineChart>
                    </ResponsiveContainer>
                </div>
            </Box>
        </div>
    )
}

const CustomTooltip = (props: any) => {
    const { active, payload } = props
    const { t } = useAwaitTranslation("widgets")
    const { primaryResponseText } = useEnvironmentSettingTools()
    const data = payload[0]?.payload?.dataPoints
    if (active && payload) {
        if (data && Object.keys(data).length > 0) {
            let topLabel = ""
            const xValue = payload[0].payload.x
            if (xValue < 20) {
                topLabel = t("productDevelopment", "Product Development")
            } else if (xValue >= 20 && xValue <= 40) {
                topLabel = t("introduction", "Introduction")
            } else if (xValue >= 40 && xValue <= 60) {
                topLabel = t("growth", "Growth")
            } else if (xValue >= 60 && xValue <= 80) {
                topLabel = t("maturity", "Maturity")
            } else {
                topLabel = t("decline", "Decline")
            }
            return (
                <Paper elevation={12} style={{ minWidth: "12em" }}>
                    {Object.keys(data).map((dataPoint, idx) => {
                        const point = data[dataPoint]
                        return (
                            <Box
                                p={0.5}
                                key={idx}
                                display="flex"
                                alignItems="center"
                            >
                                <Box
                                    display="flex"
                                    alignItems="center"
                                    padding={0.5}
                                >
                                    <ResponseListItem
                                        item={{
                                            label: point.score.label,
                                            user: point.score.user,
                                        }}
                                        isConceptDefault={
                                            point.score.conceptDefault
                                        }
                                    />
                                </Box>

                                {!!point?.concept && (
                                    <>
                                        <ChevronRight
                                            fontSize="medium"
                                            color="action"
                                        />
                                        <Box
                                            display="flex"
                                            alignItems="center"
                                            padding={0.5}
                                        >
                                            <ConceptAvatar
                                                concept={point.concept}
                                                size="large"
                                                variant="rounded"
                                            />
                                            <Box ml={0.5}>
                                                {point.concept.title}
                                            </Box>
                                        </Box>
                                    </>
                                )}
                            </Box>
                        )
                    })}
                    <Divider />
                    <Box padding={1}>{topLabel}</Box>
                </Paper>
            )
        } else {
            return null
        }
    }

    return null
}

const XaxisTick = (props) => {
    const { t } = useAwaitTranslation("widgets")
    const { x, y, width, payload } = props

    const tickObject = {
        0: "",
        10: t("productDevelopment", "Product Development"),
        20: "",
        30: t("introduction", "Introduction"),
        40: "",
        50: t("growth", "Growth"),
        60: "",
        70: t("maturity", "Maturity"),
        80: "",
        90: t("decline", "Decline"),
        100: "",
    }
    const hideableTicks = [
        t("introduction", "Introduction"),
        t("maturity", "Maturity"),
    ]
    let content
    if (
        width < 750 &&
        hideableTicks.indexOf(tickObject[payload.value]) !== -1
    ) {
        content = ""
    } else {
        content = tickObject[payload.value]
    }
    return (
        <foreignObject x={x - 20} y={y - 5} width="85" height="150">
            <div style={{ fontSize: width < 650 ? "8px" : "10px" }}>
                {content}
            </div>
        </foreignObject>
    )
}

const YaxisTick = (props) => {
    const { t } = useAwaitTranslation("widgets")
    const { x, y, payload, width } = props

    return (
        <foreignObject x={x - 50} y={y - 5} width="85" height="150">
            <div style={{ fontSize: width < 650 ? "8px" : "10px" }}>
                {payload.value === -980 ? (
                    <>
                        <p>{t("losses", "Losses")}/</p>
                        <p>{t("investment", "Investment")}</p>
                    </>
                ) : payload.value === 0 ? (
                    <div style={{ textAlign: "center" }}>0</div>
                ) : payload.value === 3500 ? (
                    t("salesProfit", "Sales / Profit")
                ) : (
                    ""
                )}
            </div>
        </foreignObject>
    )
}

export default React.memo(ProductChartInterface)
