import React, { ReactElement } from "react"
import { TextField } from "@material-ui/core"
import useAwaitTranslation from "../i18n/useAwaitTranslation"
import { useMutation } from "@apollo/client"
import { Link } from "../__generated__/types"
import { CREATE_LINK } from "../graphql/mutations"
import {
    CreateLinkMutation,
    CreateLinkMutationVariables,
} from "../graphql/__generated__/mutations"
interface Props {
    handleLink: (link: Link) => void
    setLoading: (loading: boolean) => void
    autoFocus?: boolean
}

export default function FetchLink({
    handleLink,
    setLoading,

    autoFocus,
}: Props): ReactElement {
    const [addLink] = useMutation<
        CreateLinkMutation,
        CreateLinkMutationVariables
    >(CREATE_LINK)
    const handleInput = async (ev) => {
        if (ev.target.value.length > 0) {
            setLoading(true)
            const url = ev.target.value
            addLink({ variables: { url } })
                .then((res) => {
                    const linkItem = res.data?.addLink

                    if (linkItem) {
                        handleLink(linkItem)
                    }
                })
                .catch((e) => console.log(e))
        } else {
            setLoading(false)
        }
    }
    const { t } = useAwaitTranslation("fetchLink")
    return (
        <TextField
            variant="outlined"
            fullWidth
            id="link"
            autoFocus={!!autoFocus ?? false}
            name="link"
            label={t("webLink", "Web Link (URL)")}
            onChange={handleInput}
        />
    )
}
