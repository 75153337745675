import React from "react"
import { Check } from "@styled-icons/material/Check"
import { Image } from "@styled-icons/material/Image"
import { LineWeight } from "@styled-icons/material/LineWeight"
import { Link } from "@styled-icons/material/Link"
import { OndemandVideo } from "@styled-icons/material/OndemandVideo"
import {
    ColorPickerToolbarDropdown,
    ImageToolbarButton,
    LineHeightToolbarDropdown,
    LinkToolbarButton,
    MARK_BG_COLOR,
    MARK_COLOR,
    MediaEmbedToolbarButton,
} from "@udecode/plate"
import { AlignToolbarButtons } from "../align/AlignToolbarButtons"
import { BasicElementToolbarButtons } from "./BasicElementToolbarButtons"
import { BasicMarkToolbarButtons } from "./BasicMarkToolbarButtons"
import { IndentToolbarButtons } from "../indent/IndentToolbarButtons"
import { ListToolbarButtons } from "./ListToolbarButtons"
import { TableToolbarButtons } from "./TableToolbarButtons"
import { getElementIcon } from "./getElementIcon"
import { Tooltip } from "@material-ui/core"
export const ToolbarButtons = () => {
    return (
        <>
            <BasicElementToolbarButtons />
            <ListToolbarButtons />
            <IndentToolbarButtons />
            <BasicMarkToolbarButtons />
            <ColorPickerToolbarDropdown
                pluginKey={MARK_COLOR}
                icon={getElementIcon(MARK_COLOR)}
                selectedIcon={<Check />}
            />
            <ColorPickerToolbarDropdown
                pluginKey={MARK_BG_COLOR}
                icon={getElementIcon(MARK_BG_COLOR)}
                selectedIcon={<Check />}
            />
            <LineHeightToolbarDropdown
                icon={
                    <Tooltip title="Line height">
                        <LineWeight />
                    </Tooltip>
                }
            />
            <AlignToolbarButtons />
            <LinkToolbarButton
                icon={
                    <Tooltip title="Insert link">
                        <Link />
                    </Tooltip>
                }
            />
            <ImageToolbarButton
                icon={
                    <Tooltip title="Insert image">
                        <Image />
                    </Tooltip>
                }
            />
            <MediaEmbedToolbarButton
                icon={
                    <Tooltip title="Insert video">
                        <OndemandVideo />
                    </Tooltip>
                }
            />
            <TableToolbarButtons />
        </>
    )
}
