import { useRef, useEffect, useCallback } from "react"
import {
    LineChart,
    Line,
    XAxis,
    YAxis,
    Tooltip,
    Legend,
    ReferenceLine,
    ReferenceDot,
    ResponsiveContainer,
} from "recharts"
import { useQuery, useMutation, useApolloClient, gql } from "@apollo/client"
import { makeStyles, useTheme } from "@material-ui/core/styles"
import Divider from "@material-ui/core/Divider"
import Card from "@material-ui/core/Card"
import Box from "@material-ui/core/Box"
import StarIcon from "@material-ui/icons/Star"
import BeenhereIcon from "@material-ui/icons/Beenhere"
import { useHistory } from "react-router-dom"
import { Avatar } from "@material-ui/core"
import useAwaitTranslation from "../../i18n/useAwaitTranslation"
import { CurveData } from "./line-data/ProductChartData"
import {
    CREATE_CRITERIA_SCORE,
    ADD_CRITERIA_SCORE_CRITERIA,
    UPDATE_CRITERIA_SCORE,
    ADD_CRITERIA_SCORE_SCORED_CONCEPT,
    CRITERIA_SCORE_FILTERED,
    CRITERIA_FILTERED,
} from "../criteria/graphql"
import {
    CreateCriteriaScoreMutation,
    UpdateCriteriaScoreMutation,
    UpdateCriteriaScoreMutationVariables,
    CreateCriteriaScoreMutationVariables,
    AddCriteriaScoreCriteriaMutation,
    AddCriteriaScoreCriteriaMutationVariables,
    AddCriteriaScoreScoredConceptMutation,
    AddCriteriaScoreScoredConceptMutationVariables,
    CriteriaScoreFilteredQuery,
    CriteriaScoreFilteredQueryVariables,
    CriteriaQuery,
    CriteriaQueryVariables,
} from "../criteria/__generated__/graphql"
import {
    Concept,
    _ConceptFilter,
    _CriteriaScoreFilter,
} from "../../__generated__/types"
import { DotProps } from "recharts"
import { Loading } from "../Loading"
import ErrorHandler from "../ErrorHandler"

const useStyles = makeStyles((theme) => ({
    container: {
        height: "100%",
        display: "flex",
        flexDirection: "column",
    },
    flex: {
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-around",
        width: "100%",

        overflow: "hidden",
    },
    yAxis: {
        writingMode: "vertical-lr",
        textOrientation: "sideways",
        transform: "rotate(180deg)",
        display: "flex",
        justifyContent: "center",
        width: "20px",
        fontSize: 12,
        color: theme.palette.text.secondary,
    },
    clickable: {
        cursor: "crosshair !important",
    },
    timeContainer: {
        fontSize: 12,
        color: theme.palette.text.secondary,
        textAlign: "center",
        marginBottom: "5px",
    },
}))

interface ProductChartProps {
    filter?: _ConceptFilter
    concepts?: Concept[]
    locked?: boolean
    pageConcept?: Concept
}
type PositionObject = {
    x: number
    y: number
    id: string
}
type TooltipObject = {
    title: string
    primary: boolean
    position: number
    imageUrl: string
}
export default function ProductChart(props: ProductChartProps) {
    //hooks
    const client = useApolloClient()
    const theme = useTheme()
    const history = useHistory()
    const classes = useStyles()
    const { t } = useAwaitTranslation("widgets")

    //
    const sizeRef = useRef(null)

    //mutations
    const [createScore] = useMutation<
        CreateCriteriaScoreMutation,
        CreateCriteriaScoreMutationVariables
    >(CREATE_CRITERIA_SCORE)
    const [addScoreToCriteria] = useMutation<
        AddCriteriaScoreCriteriaMutation,
        AddCriteriaScoreCriteriaMutationVariables
    >(ADD_CRITERIA_SCORE_CRITERIA)
    const [updateScore] = useMutation<
        UpdateCriteriaScoreMutation,
        UpdateCriteriaScoreMutationVariables
    >(UPDATE_CRITERIA_SCORE)
    const [addScoreToConcept] = useMutation<
        AddCriteriaScoreScoredConceptMutation,
        AddCriteriaScoreScoredConceptMutationVariables
    >(ADD_CRITERIA_SCORE_SCORED_CONCEPT)

    //queries and query filters
    let scoreFilters: _CriteriaScoreFilter[] = [
        {
            criteria: {
                name: "Product Chart Position",
            },
        },
        { conceptDefault: true },
    ]
    if (Object.keys(props.filter).length > 0) {
        scoreFilters.push({
            scoredConcept: {
                ...props.filter,
            },
        })
    }
    let filterObject: _CriteriaScoreFilter = {}
    if (!!props.pageConcept) {
        filterObject = {
            OR: [
                {
                    AND: [...scoreFilters],
                },
                {
                    AND: [
                        {
                            scoredConcept: {
                                id: props.pageConcept?.id,
                            },
                        },
                        {
                            criteria: {
                                name: "Product Chart Position",
                            },
                        },
                        { conceptDefault: true },
                    ],
                },
            ],
        }
    } else {
        filterObject = {
            AND: [...scoreFilters],
        }
    }
    const { data: scoreData, error, loading, refetch } = useQuery<
        CriteriaScoreFilteredQuery,
        CriteriaScoreFilteredQueryVariables
    >(CRITERIA_SCORE_FILTERED, {
        variables: {
            filter: {
                ...filterObject,
            },
        },
        fetchPolicy: "cache-and-network",
        nextFetchPolicy: "cache-first",
    })
    const { data: criteriaData } = useQuery<
        CriteriaQuery,
        CriteriaQueryVariables
    >(CRITERIA_FILTERED, {
        variables: {
            filter: {
                AND: [{ name: "Product Chart Position" }, { isDefault: true }],
            },
        },
    })

    //data variables
    const scores = scoreData?.CriteriaScore ?? null
    const criteria = criteriaData?.Criteria?.[0] ?? null
    const pageConceptScore =
        scores?.find(
            (score) => score.scoredConcept?.id === props.pageConcept?.id
        ) ?? null
    //refs
    const pageConceptRef = useRef(null)
    pageConceptRef.current = props.pageConcept

    const criteriaRef = useRef(null)
    criteriaRef.current = criteria

    //callbacks
    const createPageScore = useCallback(async () => {
        const {
            data: { CreateCriteriaScore: NewScore },
        } = await createScore({
            variables: {
                response: null,
                conceptDefault: true,
            },
        })
        await Promise.all([
            addScoreToConcept({
                variables: {
                    conceptId: pageConceptRef?.current?.id,
                    criteriaScoreId: NewScore.id,
                },
            }),
            addScoreToCriteria({
                variables: {
                    criteriaId: criteriaRef?.current?.id,
                    criteriaScoreId: NewScore.id,
                },
            }),
        ])
        await refetch()
    }, [addScoreToConcept, addScoreToCriteria, createScore, refetch])

    //functions
    const handleClick = (data: { activeLabel: number }) => {
        if (!!props.pageConcept && !!data?.activeLabel && !props.locked) {
            client.writeFragment({
                id: client.cache.identify(pageConceptScore),
                fragment: gql`
                    fragment NewResponse on CriteriaScore {
                        response
                    }
                `,
                data: {
                    response: JSON.stringify(data.activeLabel ?? 0),
                },
            })
            updateScore({
                variables: {
                    id: pageConceptScore.id,
                    response: JSON.stringify(data.activeLabel),
                },
            })
        }
    }
    const needsScore =
        !!props.pageConcept &&
        !!scoreData &&
        !!criteriaData &&
        !pageConceptScore

    //useEffects
    useEffect(() => {
        if (!!needsScore) {
            createPageScore()
        }
    }, [needsScore, createPageScore])
    if (error) {
        return <ErrorHandler message={error.message} showMessage={true} />
    }
    if (loading) {
        return <Loading size={25} hideQuote={true} />
    }
    let salesObject: PositionObject | null
    let profitObject: PositionObject | null
    let salesArray: PositionObject[] = []
    let profitArray: PositionObject[] = []
    if (!!props.pageConcept && !!pageConceptScore?.response) {
        salesObject = {
            x: Number(pageConceptScore.response),
            y: 0,
            id: props.pageConcept.id,
        }
        profitObject = {
            x: Number(pageConceptScore.response),
            y: 0,
            id: props.pageConcept.id,
        }
        CurveData.forEach((chartPoint) => {
            chartPoint.dataPoints = {}
            if (chartPoint.x === salesObject.x) {
                salesObject.y = chartPoint.Sales
                profitObject.y = chartPoint.Profit
                let toolTipObject: TooltipObject = {
                    title: props.pageConcept.title,
                    primary: true,
                    position: chartPoint.x,
                    imageUrl: props.pageConcept.imageUrl,
                }
                chartPoint.dataPoints[salesObject.id] = toolTipObject
            }
        })
    }
    scores?.forEach((score) => {
        if (!!score?.response) {
            let salesObject: PositionObject = {
                x: Number(score.response),
                y: 0,
                id: score.scoredConcept?.id,
            }
            let profitObject: PositionObject = {
                x: Number(score.response),
                y: 0,
                id: score.scoredConcept?.id,
            }
            CurveData.forEach((chartPoint) => {
                if (Number(score.response) === chartPoint.x) {
                    salesObject.y = chartPoint.Sales
                    profitObject.y = chartPoint.Profit
                    let toolTipObject: TooltipObject = {
                        title: score.scoredConcept?.title,
                        primary: false,
                        position: chartPoint.x,
                        imageUrl: score.scoredConcept?.imageUrl,
                    }

                    chartPoint.dataPoints[salesObject.id] = toolTipObject
                }
            })
            salesArray.push(salesObject)
            profitArray.push(profitObject)
        }
    })

    return (
        <div className={classes.flex}>
            <Box display="flex" height="100%" overflow="hidden">
                <div
                    style={{
                        width: "100%",
                        height: "100%",
                        flexGrow: 1,
                        overflow: "hidden",
                    }}
                    ref={sizeRef}
                >
                    <ResponsiveContainer height="100%" width="100%">
                        <LineChart
                            className={
                                !!props.pageConcept && !props.locked
                                    ? classes.clickable
                                    : ""
                            }
                            data={CurveData}
                            // @ts-ignore
                            onClick={handleClick}
                            margin={{
                                top: theme.spacing(1),
                                right: theme.spacing(2),
                                left: theme.spacing(3),
                                bottom: theme.spacing(5),
                            }}
                        >
                            <XAxis
                                tick={
                                    <XaxisTick
                                        width={sizeRef?.current?.clientWidth}
                                    />
                                }
                                ticks={[
                                    0,
                                    10,
                                    20,
                                    30,
                                    40,
                                    50,
                                    60,
                                    70,
                                    80,
                                    90,
                                    100,
                                ]}
                                type="number"
                                // dot={false}
                                dataKey="x"
                                interval="preserveEnd"
                                label={{
                                    value: t("time", "Time"),
                                    position: "insideBottom",
                                    offset: -theme.spacing(3),
                                    fill: theme.palette.text.primary,
                                }}
                            />
                            <YAxis
                                tick={
                                    <YaxisTick
                                        width={sizeRef?.current?.clientWidth}
                                    />
                                }
                                ticks={[-980, 0, 3500]}
                                type="number"
                                label={{
                                    value: `${t("money", "Money")} ($)`,
                                    angle: -90,
                                    position: "insideLeft",
                                    fill: theme.palette.text.primary,
                                }}
                            />
                            <Tooltip
                                content={
                                    <CustomTooltip palette={theme.palette} />
                                }
                            />
                            <Legend verticalAlign="top" height={15} />
                            <ReferenceLine stroke="rgb(102, 102, 102)" y={0} />
                            <Line
                                dot={false}
                                type="monotone"
                                dataKey="Profit"
                                stroke={theme.palette.primary.dark}
                                fill={theme.palette.primary.dark}
                            />
                            <Line
                                dot={false}
                                type="monotone"
                                dataKey="Sales"
                                stroke={theme.palette.secondary.main}
                                fill={theme.palette.secondary.main}
                            />
                            {salesArray?.map((item, idx) => {
                                const key = idx.toString() + "sales"
                                return (
                                    <ReferenceDot
                                        key={key}
                                        fill={theme.palette.secondary.main}
                                        style={{
                                            cursor: props.locked
                                                ? "pointer"
                                                : "default",
                                        }}
                                        onClick={(e: DotProps) => {
                                            if (props.locked) {
                                                history.push(
                                                    `/concept/${item.id}/home`
                                                )
                                            }
                                        }}
                                        r={5}
                                        xAxisId={0}
                                        x={item.x}
                                        y={item.y}
                                    />
                                )
                            })}
                            {profitArray?.map((item, idx) => {
                                const key = idx.toString() + "profit"
                                return (
                                    <ReferenceDot
                                        key={key}
                                        fill={theme.palette.secondary.main}
                                        style={{
                                            cursor: props.locked
                                                ? "pointer"
                                                : "default",
                                        }}
                                        onClick={(e: DotProps) => {
                                            if (props.locked) {
                                                history.push(
                                                    `/concept/${item.id}/home`
                                                )
                                            }
                                        }}
                                        r={5}
                                        xAxisId={0}
                                        x={item.x}
                                        y={item.y}
                                    />
                                )
                            })}
                            {!!salesObject && (
                                <ReferenceDot
                                    r={5}
                                    isFront={true}
                                    xAxisId={0}
                                    x={salesObject.x}
                                    y={salesObject.y}
                                    fill={theme.palette.primary.main}
                                />
                            )}
                            {!!profitObject && (
                                <ReferenceDot
                                    r={5}
                                    isFront={true}
                                    xAxisId={0}
                                    x={profitObject.x}
                                    y={profitObject.y}
                                    fill={theme.palette.primary.main}
                                />
                            )}
                        </LineChart>
                    </ResponsiveContainer>
                </div>
            </Box>
        </div>
    )
}

const CustomTooltip = (props: any) => {
    const { active, payload } = props
    const { t } = useAwaitTranslation("widgets")
    const data = payload[0]?.payload?.dataPoints
    if (active && payload) {
        if (data && Object.keys(data).length > 0) {
            let topLabel = ""
            const xValue = payload[0].payload.x
            if (xValue < 20) {
                topLabel = t("productDevelopment", "Product Development")
            } else if (xValue >= 20 && xValue <= 40) {
                topLabel = t("introduction", "Introduction")
            } else if (xValue >= 40 && xValue <= 60) {
                topLabel = t("growth", "Growth")
            } else if (xValue >= 60 && xValue <= 80) {
                topLabel = t("maturity", "Maturity")
            } else {
                topLabel = t("decline", "Decline")
            }
            return (
                <Card style={{ padding: "12px", maxWidth: "350px" }}>
                    <Box padding={0.5}>{topLabel}</Box>
                    <Box padding={0.5}>
                        <Divider />
                    </Box>

                    {Object.keys(data).map((dataPoint, idx) => {
                        return (
                            <Box
                                alignItems="center"
                                key={idx}
                                display="flex"
                                margin="5px"
                                marginRight="0px"
                                padding="8px"
                                paddingLeft="0px"
                            >
                                <Avatar
                                    variant="rounded"
                                    src={data[dataPoint].imageUrl}
                                    style={{ marginRight: "5px" }}
                                >
                                    {data[dataPoint].primary ? (
                                        <StarIcon />
                                    ) : (
                                        <BeenhereIcon />
                                    )}
                                </Avatar>
                                <Box>{data[dataPoint].title}</Box>
                            </Box>
                        )
                    })}
                </Card>
            )
        } else {
            return null
        }
    }

    return null
}

const XaxisTick = (props) => {
    const { t } = useAwaitTranslation("widgets")
    const { x, y, width, payload } = props

    const tickObject = {
        0: "",
        10: t("productDevelopment", "Product Development"),
        20: "",
        30: t("introduction", "Introduction"),
        40: "",
        50: t("growth", "Growth"),
        60: "",
        70: t("maturity", "Maturity"),
        80: "",
        90: t("decline", "Decline"),
        100: "",
    }
    const hideableTicks = [
        t("introduction", "Introduction"),
        t("maturity", "Maturity"),
    ]
    let content
    if (
        width < 750 &&
        hideableTicks.indexOf(tickObject[payload.value]) !== -1
    ) {
        content = ""
    } else {
        content = tickObject[payload.value]
    }
    return (
        <foreignObject x={x - 20} y={y - 5} width="85" height="150">
            <div style={{ fontSize: width < 650 ? "8px" : "10px" }}>
                {content}
            </div>
        </foreignObject>
    )
}

const YaxisTick = (props) => {
    const { t } = useAwaitTranslation("widgets")
    const { x, y, payload, width } = props

    return (
        <foreignObject x={x - 50} y={y - 5} width="85" height="150">
            <div style={{ fontSize: width < 650 ? "8px" : "10px" }}>
                {payload.value === -980 ? (
                    <>
                        <p>{t("losses", "Losses")}/</p>
                        <p>{t("investment", "Investment")}</p>
                    </>
                ) : payload.value === 0 ? (
                    <div style={{ textAlign: "center" }}>0</div>
                ) : payload.value === 3500 ? (
                    t("salesProfit", "Sales / Profit")
                ) : (
                    ""
                )}
            </div>
        </foreignObject>
    )
}
