import { useQuery } from "@apollo/client"
import { Box, List } from "@material-ui/core"
import { useParams } from "react-router-dom"
import { CONNECTION_WIDGET_QUERY } from "../../../graphql/queries"
import {
    ConnectionWidgetQueryQuery,
    ConnectionWidgetQueryQueryVariables,
} from "../../../graphql/__generated__/queries"
import { ConceptFeedWidgetConfig } from "../../dashboard/widgets/ConceptFeedWidget"

import useFilters from "../../filters/util/useFilters"

import SectionHeader from "../SectionHeader"
import ListItemDeserializer from "./ListItemDeserializer"

const ConceptFeedDeserializer = (props: {
    widgetConfig: ConceptFeedWidgetConfig
}) => {
    const { conceptId } = useParams()
    const {
        widgetConfig: {
            label,
            categoryId,
            orderBy,
            filters,
            source,
            conceptId: widgetConceptId,
        },
    } = props

    const conceptIdTofetch = widgetConceptId || conceptId
    const { filterObject } = useFilters({
        viewPrivate: true,
        filters: filters,
        connectionsConceptId:
            source === "connections" ? conceptIdTofetch : null,

        subConnectionCategoryId:
            source === "connections" && categoryId ? categoryId : null,
    })

    const { data } = useQuery<
        ConnectionWidgetQueryQuery,
        ConnectionWidgetQueryQueryVariables
    >(CONNECTION_WIDGET_QUERY, {
        variables: {
            connectionsFilter: filterObject,
            orderBy: [orderBy],
            offset: 0,
            first: 100,
        },
        fetchPolicy: "cache-and-network",
    })
    const feedConcepts = data?.Concept ?? []

    return (
        <Box>
            <SectionHeader
                headerName={label}
                conceptCount={feedConcepts.length}
            />
            <List dense>
                {feedConcepts.map((item) => {
                    return <ListItemDeserializer concept={item} key={item.id} />
                })}
            </List>
        </Box>
    )
}

export default ConceptFeedDeserializer
