import { _Neo4jDateTimeInput } from "../../__generated__/types"

export type CriteriaScoreInputVariables = {
    id?: string
    response?: string
    label?: string
    notes?: string
    chatHistory?: string
    conceptDefault?: boolean
    lastUpdated?: _Neo4jDateTimeInput
    lastUpdatedByUserId?: string
    isArchived?: boolean
}

export enum ScoreSelectorDefaultValues {
    primaryResponse = "Primary Response",
    allResponses = "All Responses",
}
