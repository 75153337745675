import React, { useCallback, useState } from "react"
import { Box, List } from "@material-ui/core"
import { useQuery } from "@apollo/client"

import { useEffect } from "react"

import InfiniteScroll from "react-infinite-scroller"
import CircularProgress from "@material-ui/core/CircularProgress"
import {
    _ConceptFilter,
    _ConceptOrdering,
    Concept,
} from "../__generated__/types"
import { CONCEPT_PAGINATION } from "../graphql/queries"
import {
    ConceptPaginationQuery,
    ConceptPaginationQueryVariables,
} from "../graphql/__generated__/queries"
import SelectionItem from "./inputs/selection/SelectionItem"
import ErrorHandler from "./ErrorHandler"

const PAGE_SIZE = 10
interface SelectableListProps {
    filter: _ConceptFilter
    orderBy: _ConceptOrdering
    currentSelectionIds?: string[]
    maxSelections?: number
    onSelection?: (concept: Concept) => void
    infiniteScroll?: boolean
}

const SelectableList = (props: SelectableListProps) => {
    //state

    const [loadMore, setLoadMore] = useState(true)
    const [limit, setLimit] = useState(PAGE_SIZE)
    const [filter, setFilter] = useState<_ConceptFilter>({})
    const selectable = !!props.onSelection
    //queries
    const { loading, error, data, fetchMore } = useQuery<
        ConceptPaginationQuery,
        ConceptPaginationQueryVariables
    >(CONCEPT_PAGINATION, {
        variables: {
            offset: 0,
            first: limit,
            filter: filter,
            orderBy: [props.orderBy],
        },
        fetchPolicy: !props.infiniteScroll
            ? "cache-and-network"
            : "cache-first",
    })

    //useEffects
    useEffect(() => {
        if (JSON.stringify(props.filter) !== JSON.stringify(filter)) {
            setFilter(props.filter)
            if (!!props.infiniteScroll) {
                setLimit(PAGE_SIZE)
                setLoadMore(true)
            }
        }
    }, [props.filter, filter, props.infiniteScroll])

    //callbacks
    const onLoadMore = useCallback(
        async (page) => {
            if (!fetchMore) return
            const result = await fetchMore({
                variables: {
                    offset: page * PAGE_SIZE,
                },
            })
            setLimit(page * PAGE_SIZE + PAGE_SIZE)
            setLoadMore(!(result.data.Concept.length < page * PAGE_SIZE))
        },
        [fetchMore]
    )

    //query handling
    if (loading) {
        return null
    } else if (error) {
        return <ErrorHandler message={error.message} showMessage={true} />
    }

    const lineItems =
        data?.Concept?.map((item, idx) => {
            return (
                <Box key={item.id}>
                    <SelectionItem
                        item={item}
                        singleSelection={props.maxSelections === 1}
                        editable={selectable}
                        selected={
                            selectable &&
                            props.currentSelectionIds?.includes(item.id)
                        }
                        onAction={() => {
                            props.onSelection(item)
                        }}
                        action={!!selectable ? "toggle" : null}
                    />
                </Box>
            )
        }) ?? []
    return (
        <Box display="flex" height="100%" overflow="hidden" width="100%">
            <Box
                display="flex"
                flexDirection="column"
                overflow="hidden"
                width="100%"
            >
                <List
                    disablePadding
                    style={{
                        overflow: "auto",
                        height: "100%",
                    }}
                >
                    {!!props.infiniteScroll ? (
                        <InfiniteScroll
                            pageStart={0}
                            loadMore={onLoadMore}
                            hasMore={loadMore}
                            loader={
                                <div key={0} style={{ textAlign: "center" }}>
                                    <CircularProgress disableShrink size={25} />
                                </div>
                            }
                            useWindow={false}
                        >
                            {lineItems}
                        </InfiniteScroll>
                    ) : (
                        lineItems
                    )}
                </List>
            </Box>
        </Box>
    )
}

export default SelectableList
