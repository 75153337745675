import React from "react"
import Dialog from "@material-ui/core/Dialog"
import { useLocation } from "react-router-dom"
import { useHistory } from "react-router-dom"
import { useAuth } from "../../providers/AuthProvider"
import { Loading } from "../Loading"
import { parse } from "qs"
import ResetPassword from "./ResetPassword"
import ForgotPassword from "./ForgotPassword"

export default function ManageUserAccount() {
    let location = useLocation()
    const {
        mode,
        oobCode: actionCode,
        continueUrl,
        lang,
    } = parse(location?.search, { ignoreQueryPrefix: true })
    const history = useHistory()
    const { auth } = useAuth()

    // Handle the user management action.
    switch (mode) {
        case undefined:
            return <ForgotPassword />
        case "resetPassword":
            return (
                <ResetPassword
                    actionCode={actionCode}
                    continueUrl={continueUrl}
                />
            )
        case "recoverEmail":
            // Display email recovery handler and UI.
            // handleRecoverEmail(actionCode, lang)
            break
        case "verifyEmail":
            // Display email verification handler and UI.
            handleVerifyEmail(actionCode, continueUrl, lang)
            break
        default:
            // Error: invalid mode.
            console.log("INVALID MODE", mode)
    }

    function handleVerifyEmail(actionCode, continueUrl, lang) {
        auth.applyActionCode(actionCode)
            .then((resp) => {
                // Email address has been verified.
                if (!!continueUrl) {
                    history.push(continueUrl)
                } else {
                }
                // TODO: Display a confirmation message to the user.
                // You could also provide the user with a link back to the app.

                // TODO: If a continue URL is available, display a button which on
                // click redirects the user back to the app via continueUrl with
                // additional state determined from that URL's parameters.
            })
            .catch((error) => {
                // Code is invalid or expired. Ask the user to verify their email address
                // again.
            })
    }

    return (
        <Dialog fullScreen open={true} style={{ zIndex: 99999 }}>
            <Loading />
        </Dialog>
    )
}
