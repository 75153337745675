import React from "react"
import {
    ListItemAvatar,
    ListItem,
    ListItemText,
    ListItemSecondaryAction,
    Checkbox,
    IconButton,
} from "@material-ui/core"
import ClearIcon from "@material-ui/icons/Clear"

import { Concept } from "../../../__generated__/types"

import ConceptAvatar from "../../ConceptAvatar"

interface SelectionItemInterface {
    item: Concept
    editable: boolean
    action?: "toggle" | "remove"
    singleSelection?: boolean
    selected?: boolean
    onAction?: () => void
    hideDivider?: boolean
    selectionCount?: number
}
const SelectionItem = (props: SelectionItemInterface) => {
    const {
        item,
        editable,
        selected,
        onAction,
        action,
        singleSelection,
        selectionCount,
    } = props

    return (
        <ListItem
            button={editable ? true : undefined}
            selected={!!singleSelection && !!selected}
            onClick={() => {
                if (!!editable) {
                    onAction()
                }
            }}
        >
            <ListItemAvatar style={{ display: "flex" }}>
                <ConceptAvatar concept={item} variant="rounded" size="large" />
            </ListItemAvatar>
            <ListItemText
                primary={item.title}
                secondary={selectionCount ?? ""}
            />

            {!!action && !!onAction && !!editable && !singleSelection && (
                <ListItemSecondaryAction>
                    {action === "toggle" && (
                        <Checkbox
                            checked={selected}
                            onChange={(e) => {
                                onAction()
                            }}
                        />
                    )}

                    {action === "remove" && (
                        <IconButton
                            size="small"
                            onClick={() => {
                                onAction()
                            }}
                        >
                            <ClearIcon fontSize="small" />
                        </IconButton>
                    )}
                </ListItemSecondaryAction>
            )}
        </ListItem>
    )
}

export default React.memo(SelectionItem)
