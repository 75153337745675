import { gql } from "@apollo/client"

export const CREATE_CONCEPT = gql`
    mutation createConceptComplete(
        $concept: ConceptInput
        $userId: ID!
        $tags: [String]
    ) {
        CreateConcept(concept: $concept, userId: $userId, tags: $tags) {
            id
            __typename
            title
            type
            summary
            description
            isPublic
            isPublicAccessType
            imageUrl
            createdAt {
                formatted
            }
            likedBy {
                userId
                username
            }
            createdBy {
                userId
                username
                firstName
                lastName
                imageUrl
            }
            category {
                id
                name
                isRoot
            }
            connections {
                id
                title
                type
            }

            tags(orderBy: name_asc) {
                name
            }
        }
    }
`

export const UPDATE_CONCEPT = gql`
    mutation updateConcept($concept: ConceptUpdateInput, $userId: ID!) {
        UpdateConcept(concept: $concept, userId: $userId)
    }
`
export const DELETE_CONCEPT = gql`
    mutation DeleteConcept($conceptId: ID!) {
        DeleteConcept(id: $conceptId) {
            id
        }
    }
`
/// CONNECT CONCEPTS
export const CONNECT_CONCEPTS = gql`
    mutation AddConceptConnections($fromId: ID!, $toId: ID!) {
        AddConceptConnections(from: { id: $fromId }, to: { id: $toId }) {
            from {
                id
                title
                summary
                type
                imageUrl
                connections {
                    id
                }
            }
            to {
                id
                title
                category {
                    id
                    name
                    isRoot
                    icon
                }
                summary
                description
                connections {
                    id
                }
            }
        }
    }
`

export const DISCONNECT_CONCEPTS = gql`
    mutation RemoveConceptConnections($fromId: ID!, $toId: ID!) {
        RemoveConceptConnections(from: { id: $fromId }, to: { id: $toId }) {
            from {
                id
                connections {
                    id
                }
            }
            to {
                id
                connections {
                    id
                }
            }
        }
    }
`

export const ADD_LIST_PARENT_CONCEPT = gql`
    mutation AddConceptListParentConcept($fromId: ID!, $toId: ID!) {
        AddConceptListParentConcept(from: { id: $fromId }, to: { id: $toId }) {
            from {
                id
            }
            to {
                id
                parentConcept {
                    id
                }
            }
        }
    }
`
export const CREATE_LINK = gql`
    mutation CreateLink($url: String) {
        addLink(url: $url) {
            id
            url
            title
            description
            image
            icon
            keywords
            language
            type
            provider
        }
    }
`
export const ADD_CONCEPT_WEB_LINK = gql`
    mutation addConceptWebLink($conceptId: ID!, $linkId: ID!) {
        AddConceptWebLink(from: { id: $conceptId }, to: { id: $linkId }) {
            from {
                id
                title
                webLink {
                    id
                }
            }
            to {
                id
                url
            }
        }
    }
`

export const REMOVE_CONCEPT_WEB_LINK = gql`
    mutation removeConceptWebLink($conceptId: ID!, $linkId: ID!) {
        RemoveConceptWebLink(from: { id: $conceptId }, to: { id: $linkId }) {
            from {
                id
                title
                webLink {
                    id
                    title
                    provider
                    description
                }
            }
            to {
                id
                url
            }
        }
    }
`
export const CREATE_CONCEPT_TRANSLATION = gql`
    mutation createConceptTranslation(
        $languageString: LanguageString
        $title: String
        $summary: String
        $description: String
    ) {
        CreateTranslation(
            languageString: $languageString
            title: $title
            summary: $summary
            description: $description
        ) {
            id
            languageString
            title
            summary
            description
            concept {
                id
            }
        }
    }
`

export const ADD_CONCEPT_TRANSLATION = gql`
    mutation addConceptTranslation($conceptId: ID!, $translationId: ID!) {
        AddConceptTranslations(
            from: { id: $conceptId }
            to: { id: $translationId }
        ) {
            from {
                id
                translations {
                    id
                    languageString
                    title
                    summary
                    description
                }
            }
            to {
                id
            }
        }
    }
`

export const UPDATE_CONCEPT_TRANSLATION = gql`
    mutation updateConceptTranslation(
        $translationId: ID!
        $languageString: LanguageString
        $title: String
        $summary: String
        $description: String
    ) {
        UpdateTranslation(
            id: $translationId
            languageString: $languageString
            title: $title
            summary: $summary
            description: $description
        ) {
            id
            languageString
            title
            summary
            description
            concept {
                id
            }
        }
    }
`
export const SEND_EMAIL = gql`
    mutation SendEmail(
        $sendTo: [String]!
        $subject: String!
        $text: String!
        $html: String
        $separateEmails: Boolean
    ) {
        sendMail(
            sendTo: $sendTo
            subject: $subject
            text: $text
            html: $html
            separateEmails: $separateEmails
        )
    }
`
export const GENERATE_AI = gql`
    mutation GenerateAI($prompt: String!, $maxLength: Int!) {
        generateAI(prompt: $prompt, maxLength: $maxLength)
    }
`

export const GENERATE_CHAT_AI = gql`
    mutation GenerateChatAI(
        $systemPrompt: String!
        $messages: [ChatMessage]
        $maxLength: Int!
    ) {
        generateChatAI(
            systemPrompt: $systemPrompt
            messages: $messages
            maxLength: $maxLength
        )
    }
`
