import { Box, List, Button, ButtonProps, Tooltip } from "@material-ui/core"

import { useRef, useState } from "react"
import {
    makeStyles,
    Theme,
    createStyles,
    useTheme,
} from "@material-ui/core/styles"
import TreeView from "@material-ui/lab/TreeView"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import ChevronRightIcon from "@material-ui/icons/ChevronRight"
import { Category, Concept, SystemRole } from "../../__generated__/types"
import { useAuth } from "../../providers/AuthProvider"
import ClickableRichTooltip from "../Popper/ClickableRichTooltip"
import LoadingOverlayWrapper from "../LoadingOverlayWrapper"
import useConceptTools from "../../util/useConceptTools"
import useCategoryTools from "./useCategoryTools"
import CategoryIcon from "./CategoryIcon"
import CategoryItem from "./CategoryItem"
import { userConceptPermissionsVar } from "../../providers/GlobalState"
import { useReactiveVar } from "@apollo/client"
import useAwaitTranslation from "../../i18n/useAwaitTranslation"

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: "100%",
            fontSize: 13,
            position: "relative",
        },

        listContainer: {
            maxHeight: 400,
            width: 400,
            overflowY: "auto",
        },
        disabledButton: {
            textTransform: "none",
            cursor: "default",
            "&:hover": {
                backgroundColor: "transparent !important",
            },
        },
    })
)
const disabledButtonProps: ButtonProps = {
    disableElevation: true,
    disableRipple: true,
    disableFocusRipple: true,
}
interface ConceptCategoryProps {
    concept: Concept
    editable: boolean
}
export default function ConceptCategory(props: ConceptCategoryProps) {
    const { concept, editable } = props
    const valueRef = useRef(null)
    const theme = useTheme()
    const { environmentCategories, getCategoryArray } = useCategoryTools()
    const { onChangeConceptCategory } = useConceptTools()
    const { owningPermission } = useReactiveVar(userConceptPermissionsVar)
    const { t } = useAwaitTranslation("concepts")
    const { currentUser } = useAuth()
    const [saving, setSaving] = useState(false)
    let options = environmentCategories?.filter((cat) => !!cat.isRoot) ?? []

    const handleCategorySelection = async (category: Category) => {
        setSaving(true)
        await onChangeConceptCategory({ concept, category })
        setSaving(false)
    }

    const classes = useStyles()

    if (!!concept.category) {
        valueRef.current = concept.category
    }

    let selectedCategoryName = ""
    if (!valueRef.current) {
        selectedCategoryName = "No Category Selected"
    } else {
        const catArray = getCategoryArray(valueRef.current.id)
        catArray.map((cat, index) => {
            if (catArray.length - 1 === index) {
                selectedCategoryName += cat.name
            } else {
                selectedCategoryName += `${cat.name} / `
            }
            return selectedCategoryName
        })
    }

    return (
        <div className={classes.root}>
            {!!editable && !!owningPermission ? (
                <ClickableRichTooltip
                    placement="bottom-start"
                    arrow={false}
                    zIndex={theme.zIndex.appBar - 2}
                    content={
                        <Box height="100%" width="100%">
                            <LoadingOverlayWrapper loading={saving}>
                                <List className={classes.listContainer}>
                                    {options.map((option) => (
                                        <TreeView
                                            key={option.id}
                                            defaultCollapseIcon={
                                                <ExpandMoreIcon />
                                            }
                                            defaultExpandIcon={
                                                <ChevronRightIcon />
                                            }
                                            defaultExpanded={
                                                options
                                                    ?.map((item) =>
                                                        item.isRoot
                                                            ? item.id
                                                            : null
                                                    )
                                                    ?.filter((x) => !!x) ?? []
                                            }
                                        >
                                            <CategoryItem
                                                node={option}
                                                setValue={(value: Category) => {
                                                    handleCategorySelection(
                                                        value
                                                    )
                                                }}
                                                hiddenOptions={
                                                    environmentCategories
                                                        ?.filter(
                                                            (o) =>
                                                                !!o.isManaged &&
                                                                currentUser?.role !==
                                                                    SystemRole.ADMIN
                                                        )
                                                        ?.map((x) => x.id) || []
                                                }
                                                value={concept.category}
                                            />
                                        </TreeView>
                                    ))}
                                </List>
                            </LoadingOverlayWrapper>
                        </Box>
                    }
                >
                    <Button
                        startIcon={
                            <CategoryIcon
                                fontSize="small"
                                category={valueRef?.current}
                            />
                        }
                        style={{
                            textTransform: "none",
                        }}
                    >
                        {selectedCategoryName}
                    </Button>
                </ClickableRichTooltip>
            ) : (
                <Tooltip
                    title={
                        !owningPermission && !!editable
                            ? t(
                                  "youMustHaveOwnerPermissionsToChangeCategories",
                                  "You must have owner permissions to change categories"
                              )
                            : ""
                    }
                >
                    <Button
                        {...disabledButtonProps}
                        className={classes.disabledButton}
                        startIcon={
                            <CategoryIcon
                                fontSize="small"
                                category={valueRef?.current}
                            />
                        }
                    >
                        {selectedCategoryName}
                    </Button>
                </Tooltip>
            )}
        </div>
    )
}
