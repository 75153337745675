import { useState, useEffect, useCallback, useRef } from "react"
import { useQuery } from "@apollo/client"
import {
    Box,
    CircularProgress,
    Table,
    TableContainer,
    TableRow,
    TableCell,
    TableHead,
} from "@material-ui/core"
import { _ConceptStatusOrdering } from "../../__generated__/types"
import { Loading } from "../Loading"
import { CONCEPT_STATUS_HISTORY } from "./graphql"
import {
    ConceptStatusHistoryQuery,
    ConceptStatusHistoryQueryVariables,
} from "./__generated__/graphql"
import dayjs from "dayjs"
import InfiniteScroll from "react-infinite-scroller"
import ErrorHandler from "../ErrorHandler"
import { useTheme } from "@material-ui/core/styles"
export const CONCEPT_STATUS_PAGE_SIZE = 10
export default function ConceptStatusHistory(props: { conceptId: string }) {
    const { conceptId } = props
    const tableRef = useRef(null)
    const theme = useTheme()
    const [loadMore, setLoadMore] = useState(true)
    const [limit, setLimit] = useState(CONCEPT_STATUS_PAGE_SIZE)
    const { data, loading, fetchMore, error } = useQuery<
        ConceptStatusHistoryQuery,
        ConceptStatusHistoryQueryVariables
    >(CONCEPT_STATUS_HISTORY, {
        variables: {
            first: limit,
            offset: 0,
            filter: {
                concept: {
                    id: conceptId,
                },
            },
            orderBy: _ConceptStatusOrdering.CREATEDAT_DESC,
        },
    })

    const statuses = data?.ConceptStatus ?? null

    useEffect(() => {
        if (!!data && statuses?.length === 0) {
            setLoadMore(false)
        }
    }, [statuses?.length, data])

    const onLoadMore = useCallback(
        async (page) => {
            if (!fetchMore) return
            const result = await fetchMore({
                variables: {
                    offset: page * CONCEPT_STATUS_PAGE_SIZE,
                },
            })
            setLimit(page * CONCEPT_STATUS_PAGE_SIZE + CONCEPT_STATUS_PAGE_SIZE)
            setLoadMore(
                !(
                    result.data.ConceptStatus.length <
                    page * CONCEPT_STATUS_PAGE_SIZE
                )
            )
        },
        [fetchMore]
    )

    if (error) {
        return <ErrorHandler message={error.message} />
    }
    return (
        <Box
            display="flex"
            flexDirection={"column"}
            width="100%"
            height="100%"
            overflow="hidden"
        >
            {loading ? (
                <Loading size={25} hideQuote={true} />
            ) : (
                <TableContainer
                    ref={tableRef}
                    style={{
                        borderRadius: theme.shape.borderRadius,
                        border: `1px solid ${theme.palette.divider}`,
                        height: "100%",
                    }}
                >
                    <Table stickyHeader size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell>Status</TableCell>
                                <TableCell>Time of Update</TableCell>
                                <TableCell>Reason</TableCell>
                                <TableCell colSpan={2}>Notes</TableCell>
                            </TableRow>
                        </TableHead>

                        <InfiniteScroll
                            pageStart={0}
                            element="tbody"
                            loadMore={onLoadMore}
                            hasMore={loadMore}
                            getScrollParent={() => tableRef?.current}
                            loader={
                                <TableRow key={0}>
                                    <TableCell
                                        colSpan={3}
                                        style={{ textAlign: "center" }}
                                    >
                                        <CircularProgress
                                            disableShrink
                                            size={25}
                                        />
                                    </TableCell>
                                </TableRow>
                            }
                            useWindow={false}
                        >
                            {statuses?.map((item, idx) => {
                                return (
                                    <TableRow key={item.id}>
                                        <TableCell>
                                            {item.stage?.title}
                                        </TableCell>
                                        <TableCell>
                                            {dayjs(
                                                item.createdAt.formatted
                                            ).format("MM-DD-YY")}
                                        </TableCell>
                                        <TableCell>
                                            {item.justification}
                                        </TableCell>
                                        <TableCell colSpan={2}>
                                            {item.justificationNotes}
                                        </TableCell>
                                    </TableRow>
                                )
                            })}
                        </InfiniteScroll>
                    </Table>
                </TableContainer>
            )}
        </Box>
    )
}
