import React, { useState, useEffect, useCallback, useLayoutEffect } from "react"
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles"
import { Box, Grid, GridSize, IconButton } from "@material-ui/core"
import { useQuery } from "@apollo/client"
import { CONNECTION_WIDGET_QUERY } from "../graphql/queries"
import {
    ConnectionWidgetQueryQuery,
    ConnectionWidgetQueryQueryVariables,
} from "../graphql/__generated__/queries"
import { Loading } from "./Loading"
import { _ConceptFilter, _ConceptOrdering } from "../__generated__/types"
import ConceptCard from "./ConceptCard"
import ChevronRightIcon from "@material-ui/icons/ChevronRight"
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft"
import Fade from "@material-ui/core/Fade"
import useResizeObserver from "@react-hook/resize-observer"
import ErrorHandler from "./ErrorHandler"

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: "100%",
            backgroundColor: theme.palette.background.paper,
            overflow: "auto",
        },
        inline: {
            display: "inline",
        },
        container: {
            display: "flex",
            flexDirection: "column",
            width: "100%",
            height: "100%",
            overflow: "hidden",
        },
        searchContainer: {
            marginTop: theme.spacing(1),
            fontSize: "12px",
        },
        linkCount: {
            display: "flex",
            alignItems: "center",
            color: theme.palette.text.secondary,
        },
        toolIcon: {
            height: "0.8em",
            width: "0.8em",
        },
        loaderContainer: {
            textAlign: "center",
        },
        greyed: {
            fontSize: 12,
            color: theme.palette.text.hint,
        },
        conceptCard: {
            border: `1px solid ${theme.palette.divider}`,
            borderRadius: theme.shape.borderRadius,
            height: "100%",
        },
        conceptCardContainer: {
            padding: theme.spacing(0, 1),

            height: "100%",
            width: "100%",
            overflowY: "auto",
        },
    })
)

const useSize = (target) => {
    const [size, setSize] = useState(null)

    useLayoutEffect(() => {
        if (!!target?.current) {
            setSize(target.current.getBoundingClientRect())
        }
    }, [target])

    // Where the magic happens
    useResizeObserver(target, (entry) => setSize(entry.contentRect))
    return size
}

interface ConceptGalleryProps {
    filter: _ConceptFilter
    orderBy: _ConceptOrdering
}
const INITIAL_PAGE_SIZE = 3
const BASE_CARD_WIDTH = 300
export default function ConceptGallery(props: ConceptGalleryProps) {
    //imported hooks, refs, etc.
    const classes = useStyles()

    //state
    const [target, setTarget] = useState(null)
    const size = useSize(target)
    const [filter, setFilter] = useState<_ConceptFilter>({})
    const [limit, setLimit] = useState(INITIAL_PAGE_SIZE)
    const [pageSize, setPageSize] = useState(INITIAL_PAGE_SIZE)
    const [offset, setOffset] = useState(0)
    const [overflowed, setOverflowed] = useState(false)

    //queries
    const { loading, error, data, fetchMore } = useQuery<
        ConnectionWidgetQueryQuery,
        ConnectionWidgetQueryQueryVariables
    >(CONNECTION_WIDGET_QUERY, {
        variables: {
            offset: 0,
            first: limit,
            connectionsFilter: filter,
            orderBy: [props.orderBy],
        },
    })

    //variables
    const listData = data?.Concept ?? []
    const containsImage =
        listData.filter(
            (item, index) =>
                index >= offset && index < offset + pageSize && !!item.imageUrl
        )?.length > 0
    let cutoff = containsImage ? 450 : 300

    //use effects
    useEffect(() => {
        if (size?.height < cutoff) {
            setOverflowed(true)
        } else {
            setOverflowed(false)
        }
    }, [size?.height, cutoff])

    useEffect(() => {
        if (JSON.stringify(props.filter) !== JSON.stringify(filter)) {
            setFilter(props.filter)
            setOffset(0)
        }
    }, [props.filter, filter])

    useEffect(() => {
        if (!!size?.width) {
            const newPageSize = Math.round(size.width / BASE_CARD_WIDTH)
            setPageSize(newPageSize > 3 ? 3 : newPageSize)
        }
    }, [size?.width])

    //functions
    const handleNext = async () => {
        setOffset((prev) => prev + pageSize)

        await onLoadMore()
    }
    const handleBack = () => {
        setOffset((prev) => prev - pageSize)
    }
    const onLoadMore = useCallback(async () => {
        if (!fetchMore) return
        await fetchMore({
            variables: {
                offset: listData.length,
            },
        })
        setLimit(listData.length + pageSize)
    }, [fetchMore, listData.length, pageSize])

    if (loading) {
        return <Loading size={25} hideQuote={true} />
    } else if (error) {
        return <ErrorHandler message={error.message} showMessage={true} />
    }
    return (
        <div className={classes.container}>
            <Box height="100%" display="flex" flexDirection={"column"}>
                <Grid
                    container
                    className={classes.conceptCardContainer}
                    ref={setTarget}
                >
                    {listData.map((item, index) => {
                        if (index >= offset && index < offset + pageSize) {
                            return (
                                <Fade in={true} key={item.id + "_fade"}>
                                    <Grid
                                        item
                                        xs={
                                            Math.round(
                                                12 / pageSize
                                            ) as GridSize
                                        }
                                        key={item.id}
                                        style={{ padding: 12 }}
                                    >
                                        <Box className={classes.conceptCard}>
                                            <ConceptCard
                                                item={item}
                                                condensed={overflowed}
                                            />
                                        </Box>
                                    </Grid>
                                </Fade>
                            )
                        } else {
                            return null
                        }
                    })}
                </Grid>

                <Box display="flex" justifyContent="center" alignItems="center">
                    <IconButton onClick={handleBack} disabled={offset === 0}>
                        <ChevronLeftIcon />
                    </IconButton>
                    <IconButton
                        onClick={handleNext}
                        disabled={listData.length < offset + pageSize}
                    >
                        <ChevronRightIcon />
                    </IconButton>
                </Box>
            </Box>
        </div>
    )
}
