import React, { useState } from "react"
import {
    Box,
    CircularProgress,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Tooltip,
    Typography,
} from "@material-ui/core"
import IconButton from "@material-ui/core/IconButton"

import { ChatRole, Concept } from "../../__generated__/types"
import { useMutation, useReactiveVar } from "@apollo/client"
import {
    myCurrentInputResponseLabelVar,
    myCurrentInputResponseVar,
} from "../../providers/GlobalState"
import Assistant from "@material-ui/icons/Assistant"
import { useInputTools } from "./useInputTools"
import { CRITERIA_TYPE_ICONS } from "../criteria/useCriteriaTypes"
import { CriteriaType } from "../criteria/types"
import { getFieldPrompt } from "../../util/PromptFunctions"
import { GENERATE_CHAT_AI } from "../../graphql/mutations"
import {
    GenerateChatAiMutation,
    GenerateChatAiMutationVariables,
} from "../../graphql/__generated__/mutations"
import useMountedState from "../../util/useMountedState"
import { ScoreSelectorDefaultValues } from "./types"
import PopperTooltip from "../PopperTooltip"
import { getCleanedFieldConfig, getSelectedResponseScores } from "./util"
import useAwaitTranslation from "../../i18n/useAwaitTranslation"

interface FieldsGeneratorProps {
    concept: Concept
}
export const FieldsGenerator: React.FunctionComponent<FieldsGeneratorProps> = ({
    concept,
}) => {
    const { onCreateNewResponse } = useInputTools({})
    const { t } = useAwaitTranslation("feedback")
    const { currentResponseData } = useReactiveVar(myCurrentInputResponseVar)
    const isMounted = useMountedState()
    const [generating, setGenerating] = useState(false)
    const { label, user } = useReactiveVar(myCurrentInputResponseLabelVar)
    const viewingPrimaryResponse =
        label === ScoreSelectorDefaultValues.primaryResponse && !user
    const [generateChatAI] = useMutation<
        GenerateChatAiMutation,
        GenerateChatAiMutationVariables
    >(GENERATE_CHAT_AI)

    const fieldsToGenerate = currentResponseData.filter((field) => {
        const fieldConfig = getCleanedFieldConfig(field)
        const isSubField = fieldConfig?.source?.criteriaIds?.length > 0
        const scores = getSelectedResponseScores(field, { label, user })
        return (
            field.input?.criteria?.criteriaType === CriteriaType.TextResponse &&
            !isSubField &&
            !scores?.length &&
            (!!viewingPrimaryResponse || !!fieldConfig.allowMultipleResponses)
        )
    })

    const onGenerate = async () => {
        setGenerating(true)

        const chatPromises = fieldsToGenerate.map((field) => {
            const fieldConfig = getCleanedFieldConfig(field)
            const prompt = getFieldPrompt({
                pageConcept: concept,
                config: fieldConfig,
                currentLabel: {
                    label,
                    user,
                },
            })
            return generateChatAI({
                variables: {
                    systemPrompt: prompt.basePrompt,
                    messages: [
                        {
                            content: prompt.firstMessage,
                            role: ChatRole.USER,
                        },
                    ],
                    maxLength: 300,
                },
            })
        })

        const chatData = await Promise.all(chatPromises)
        const chats = chatData?.map(
            (chatPromise) => chatPromise.data.generateChatAI
        )
        if (chats?.length > 0) {
            const responseToGenerate = chats.map((chat, index) => {
                return onCreateNewResponse(
                    {
                        response: JSON.stringify([
                            {
                                children: [{ text: chat || "" }],
                                type: "p",
                            },
                        ]),
                        label: label,
                    },
                    concept.id,
                    fieldsToGenerate[index]
                )
            })
            await Promise.all(responseToGenerate)
        }

        if (!!isMounted()) {
            setGenerating(false)
        }
    }

    return (
        <PopperTooltip
            position="bottom-start"
            disablePopper={fieldsToGenerate.length === 0}
            child={
                <Tooltip
                    title={
                        fieldsToGenerate.length === 0
                            ? t("noFieldsToGenerate", "No fields to generate")
                            : ""
                    }
                >
                    <span>
                        {!!generating ? (
                            <Box p={1.5}>
                                <CircularProgress size={20} />{" "}
                            </Box>
                        ) : (
                            <IconButton
                                disabled={fieldsToGenerate.length === 0}
                                onClick={() => onGenerate()}
                            >
                                <Assistant />
                            </IconButton>
                        )}
                    </span>
                </Tooltip>
            }
            content={
                <Box p={1.5} width={300}>
                    <Typography variant="body2" color="textSecondary">
                        {!!generating
                            ? `${t("generating", "Generating")}...`
                            : `${t(
                                  "clickToGenerateTheFollowingFields",
                                  "Click to generate the following fields"
                              )}:`}
                    </Typography>
                    <List dense>
                        {fieldsToGenerate.map((field) => {
                            const icon =
                                CRITERIA_TYPE_ICONS[
                                    field.input?.criteria?.criteriaType
                                ]
                            const config = getCleanedFieldConfig(field)
                            const label =
                                config?.name || field.input?.criteria?.name
                            return (
                                <ListItem key={field.input.id}>
                                    <ListItemIcon style={{ minWidth: 40 }}>
                                        {!!icon && icon}
                                    </ListItemIcon>
                                    <ListItemText
                                        primaryTypographyProps={{
                                            noWrap: true,
                                        }}
                                        primary={label}
                                    />
                                </ListItem>
                            )
                        })}
                    </List>
                </Box>
            }
        />
    )
}

export default FieldsGenerator
