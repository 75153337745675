import React from "react"
import { CriteriaType } from "./types"
import { toCapitalizedWords } from "../../util/fns"
import CategoryTreeSelector from "../categories/CategoryTreeSelector"
import {
    TextField,
    Switch,
    Box,
    InputAdornment,
    FormControl,
    Select,
    InputLabel,
    MenuItem,
    ListItemText,
    ListItemSecondaryAction,
    IconButton,
    Button,
} from "@material-ui/core"
import { Category, Criteria } from "../../__generated__/types"
import useCriteriaTypes, {
    AddedField,
    ChartType,
    ConfigurationType,
    NumberType,
} from "./useCriteriaTypes"
import ClearIcon from "@material-ui/icons/Clear"
import BubbleAxisConfiguration from "./BubbleAxisConfiguration"
import useAwaitTranslation from "../../i18n/useAwaitTranslation"
import CriteriaSelector from "./CriteriaSelector"
import MultiCategoryTreeSelector from "../categories/MultiCategoryTreeSelector"
import useCategoryTools from "../categories/useCategoryTools"
import UniqueOptions from "./form-fields/UniqueOptions"
import PrefixSuffix from "./form-fields/PrefixSuffix"
import MinimumMaximum from "./form-fields/MinimumMaximum"

interface CriteriaSchemaFormParserProps {
    criteriaId?: string
    configuration: ConfigurationType
    criteriaType: CriteriaType
    onChange: (configuration: ConfigurationType) => void
    categoryId: string
    isTableField?: boolean
}
const CriteriaSchemaFormParser = (props: CriteriaSchemaFormParserProps) => {
    const {
        criteriaId,
        configuration,
        criteriaType,
        onChange,
        categoryId,
        isTableField,
    } = props
    const { criteriaSchema, defaultCriteriaConfig } =
        useCriteriaTypes(criteriaType)
    const { getCategoryArray } = useCategoryTools()
    const { t } = useAwaitTranslation("criteria")
    const schema = criteriaSchema?.properties ?? {}
    const currentConfig = {
        ...defaultCriteriaConfig,
        ...configuration,
    }

    const handleChange = (field, value) => {
        let configuration = { ...currentConfig }
        configuration[field] = value
        let cleanedConfigObject = {}
        Object.keys(configuration).map((field) => {
            if (Object.keys(defaultCriteriaConfig).includes(field)) {
                cleanedConfigObject[field] = configuration[field]
            }
            return cleanedConfigObject
        })

        onChange(cleanedConfigObject as ConfigurationType)
    }
    return (
        <Box>
            {!!schema &&
                Object.keys(schema).map((field: string, index) => {
                    const fieldObject = schema[field]

                    const label = fieldObject.options?.label
                        ? t(field, fieldObject.options.label)
                        : t(field, toCapitalizedWords(field))
                    const isSubfield =
                        currentConfig?.source?.criteriaIds?.length > 0

                    if (
                        !!fieldObject.options?.hide ||
                        (fieldObject.options?.requiredForDisplaying?.length >
                            0 &&
                            !!fieldObject.options?.requiredForDisplaying.some(
                                (x) => {
                                    if (x.requiredValues !== undefined) {
                                        return !x.requiredValues.includes(
                                            currentConfig[x.field]
                                        )
                                    } else {
                                        return !currentConfig?.[x.field]
                                    }
                                }
                            )) ||
                        // hate this logic but its temporary and not saved anywhere in the database
                        (!!fieldObject.options?.hideIfTableField &&
                            !!isTableField)
                    ) {
                        return null
                    }

                    switch (fieldObject.type) {
                        case "integer":
                            return (
                                <Box p={2} key={index + criteriaType}>
                                    <TextField
                                        fullWidth
                                        variant="outlined"
                                        type="number"
                                        size="small"
                                        value={Number(
                                            currentConfig?.[field] ?? 0
                                        )}
                                        onChange={(e) => {
                                            handleChange(
                                                field,
                                                Number(e.target.value)
                                            )
                                        }}
                                        InputProps={{
                                            endAdornment:
                                                !criteriaSchema.required.includes(
                                                    field
                                                ) && (
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            size="small"
                                                            onClick={() => {
                                                                handleChange(
                                                                    field,
                                                                    null
                                                                )
                                                            }}
                                                        >
                                                            <ClearIcon fontSize="small" />
                                                        </IconButton>
                                                    </InputAdornment>
                                                ),
                                        }}
                                        label={label}
                                    />
                                </Box>
                            )

                        case "categorySingle":
                            return (
                                <Box p={2} key={index + criteriaType}>
                                    <CategoryTreeSelector
                                        value={currentConfig?.[field] ?? 0}
                                        label={label}
                                        setValue={(category?: Category) => {
                                            handleChange(
                                                field,
                                                category?.id ?? undefined
                                            )
                                        }}
                                        allowAll={
                                            !criteriaSchema?.required.includes(
                                                field
                                            )
                                        }
                                        size="small"
                                    />
                                </Box>
                            )
                        case "categoryMultiple":
                            return (
                                <Box p={2} key={index + criteriaType}>
                                    <MultiCategoryTreeSelector
                                        value={
                                            currentConfig?.[field]?.map(
                                                (item) => item.id
                                            ) ?? 0
                                        }
                                        setValue={(categories?: Category[]) => {
                                            handleChange(
                                                field,
                                                categories?.map((cat) => ({
                                                    id: cat.id,
                                                })) || []
                                            )
                                        }}
                                        label={"Collection Categories"}
                                    />
                                </Box>
                            )

                        case "uniqueOptions":
                            return (
                                <Box p={2} key={index + criteriaType}>
                                    <UniqueOptions
                                        values={
                                            currentConfig?.[field]?.filter(
                                                (item) => !!item.value
                                            ) ?? []
                                        }
                                        label={
                                            fieldObject.options?.label || null
                                        }
                                        allowIcons={
                                            !!fieldObject.options?.allowIcons
                                        }
                                        onChange={(
                                            value: {
                                                value: string
                                                id: string
                                            }[]
                                        ) => {
                                            handleChange(field, value)
                                        }}
                                    />
                                </Box>
                            )
                        case "bubbleAxisConfiguration":
                            return (
                                <Box p={2} key={index + criteriaType}>
                                    <BubbleAxisConfiguration
                                        value={currentConfig as ChartType}
                                        onChange={handleChange}
                                    />
                                </Box>
                            )
                        case "prefixSuffix":
                            return (
                                <Box p={2} key={index + criteriaType}>
                                    <PrefixSuffix
                                        config={currentConfig as NumberType}
                                        onChange={handleChange}
                                    />
                                </Box>
                            )
                        case "fieldMultiple":
                            return (
                                <Box p={2} key={index + criteriaType}>
                                    <CriteriaSelector
                                        onChange={(
                                            value: {
                                                field: Criteria
                                                subfields: Criteria[]
                                            }[]
                                        ) => {
                                            const adjustedValue: AddedField[] =
                                                value?.map((x) => ({
                                                    id: x.field.id,
                                                    subfieldIds:
                                                        x.subfields.map(
                                                            (o) => ({
                                                                id: o.id,
                                                            })
                                                        ),
                                                })) ?? []
                                            handleChange(field, adjustedValue)
                                        }}
                                        allowSubfields={
                                            !!fieldObject.options
                                                ?.allowSubfields
                                        }
                                        value={currentConfig?.[field] ?? []}
                                        label={fieldObject.label || null}
                                        note={fieldObject.note || null}
                                        criteriaFilter={
                                            !!isSubfield
                                                ? {
                                                      criteriaType_in:
                                                          fieldObject.options
                                                              .criteriaTypes ??
                                                          [],
                                                      id_not: criteriaId,
                                                      parentFields_some: {
                                                          id: currentConfig
                                                              ?.source
                                                              ?.criteriaIds?.[0]
                                                              ?.id,
                                                      },
                                                  }
                                                : {
                                                      criteriaType_in:
                                                          fieldObject.options
                                                              .criteriaTypes ??
                                                          [],
                                                      id_not: criteriaId,
                                                      categories_some: {
                                                          id_in: getCategoryArray(
                                                              categoryId
                                                          ).map(
                                                              (item) => item.id
                                                          ),
                                                      },
                                                  }
                                        }
                                    />
                                </Box>
                            )
                        case "subfieldMultiple":
                            return (
                                <Box p={2} key={index + criteriaType}>
                                    <CriteriaSelector
                                        isDraggable={true}
                                        label={fieldObject.label || null}
                                        onChange={(
                                            value: {
                                                field: Criteria
                                                subfields?: Criteria[]
                                            }[]
                                        ) => {
                                            handleChange(
                                                field,
                                                value.map((item) => {
                                                    const subConfig =
                                                        JSON.parse(
                                                            item.field
                                                                .criteriaOptions ??
                                                                "{}"
                                                        )
                                                    return {
                                                        id: item.field.id,
                                                        inputConfig: {
                                                            name: item.field
                                                                .name,
                                                            ...subConfig,
                                                            source: {
                                                                ...subConfig.source,
                                                                criteriaIds: [
                                                                    {
                                                                        id: criteriaId,
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                    }
                                                })
                                            )
                                        }}
                                        value={currentConfig?.[field] ?? []}
                                        criteriaFilter={{
                                            criteriaType_in:
                                                fieldObject.options
                                                    .criteriaTypes ?? [],
                                            id_not: criteriaId,
                                            parentFields_some: {
                                                id: criteriaId,
                                            },
                                        }}
                                    />
                                </Box>
                            )
                        case "minimumMaximum":
                            return (
                                <Box p={2} key={index + criteriaType}>
                                    <MinimumMaximum
                                        config={currentConfig}
                                        onChange={handleChange}
                                    />
                                </Box>
                            )
                        case "string":
                            if (!!fieldObject.enum) {
                                return (
                                    <Box p={2} key={index + criteriaType}>
                                        <FormControl
                                            fullWidth
                                            variant="outlined"
                                            size="small"
                                        >
                                            <InputLabel id={`${field}-label`}>
                                                {label}
                                            </InputLabel>
                                            <Select
                                                labelId={`${field}-label`}
                                                id={`${field}-select`}
                                                value={
                                                    currentConfig?.[field] ?? []
                                                }
                                                label={label}
                                                name={field}
                                                onChange={(e) => {
                                                    handleChange(
                                                        field,
                                                        e.target.value
                                                    )
                                                }}
                                            >
                                                {fieldObject.enum?.map(
                                                    (value) => (
                                                        <MenuItem
                                                            value={value}
                                                            key={value}
                                                        >
                                                            {toCapitalizedWords(
                                                                value
                                                            )}
                                                        </MenuItem>
                                                    )
                                                )}
                                            </Select>
                                        </FormControl>
                                    </Box>
                                )
                            } else {
                                return (
                                    <Box p={2} key={index + criteriaType}>
                                        {currentConfig?.[field] === null &&
                                        !criteriaSchema.required.includes(
                                            field
                                        ) ? (
                                            <Button
                                                variant="outlined"
                                                style={{ width: "100%" }}
                                                onClick={() => {
                                                    handleChange(field, "")
                                                }}
                                            >
                                                Click to add {label}
                                            </Button>
                                        ) : (
                                            <TextField
                                                id={`${field}-text-field`}
                                                label={label}
                                                variant="outlined"
                                                value={
                                                    currentConfig?.[field] ?? []
                                                }
                                                onChange={(e) => {
                                                    handleChange(
                                                        field,
                                                        e.currentTarget.value
                                                    )
                                                }}
                                                multiline={
                                                    !!fieldObject.options
                                                        ?.multiline
                                                }
                                                minRows={
                                                    !!fieldObject.options
                                                        ?.multiline
                                                        ? 5
                                                        : 1
                                                }
                                                size="small"
                                                type="text"
                                                fullWidth
                                                InputProps={{
                                                    endAdornment:
                                                        !criteriaSchema.required.includes(
                                                            field
                                                        ) && (
                                                            <InputAdornment position="end">
                                                                <IconButton
                                                                    size="small"
                                                                    onClick={() => {
                                                                        handleChange(
                                                                            field,
                                                                            null
                                                                        )
                                                                    }}
                                                                >
                                                                    <ClearIcon fontSize="small" />
                                                                </IconButton>
                                                            </InputAdornment>
                                                        ),
                                                }}
                                            />
                                        )}
                                    </Box>
                                )
                            }
                        case "boolean":
                            return (
                                <Box pl={2} pr={2} key={index + criteriaType}>
                                    <MenuItem
                                        ContainerComponent={"div"}
                                        button={true}
                                        onClick={() => {
                                            handleChange(
                                                field,
                                                !currentConfig?.[field]
                                            )
                                        }}
                                    >
                                        <ListItemText primary={label} />
                                        <ListItemSecondaryAction>
                                            <Switch
                                                color="primary"
                                                checked={
                                                    currentConfig?.[field] ??
                                                    false
                                                }
                                                onChange={(e, checked) => {
                                                    handleChange(field, checked)
                                                }}
                                                name={field}
                                            />
                                        </ListItemSecondaryAction>
                                    </MenuItem>
                                </Box>
                            )
                    }
                    return null
                })}
        </Box>
    )
}

export default CriteriaSchemaFormParser
