import React, { useState } from "react"
import IconButton from "@material-ui/core/IconButton"
import {
    ListItemText,
    ListItemIcon,
    Popper,
    Paper,
    Fade,
    List,
    Checkbox,
    CircularProgress,
    ListItemSecondaryAction,
    ListItem,
    Dialog,
    DialogContent,
    DialogActions,
    Button,
    ListSubheader,
    Box,
    ClickAwayListener,
    Divider,
    DialogContentText,
} from "@material-ui/core"
import DashboardIcon from "@material-ui/icons/Dashboard"
import useAwaitTranslation from "../i18n/useAwaitTranslation"
import { useGlobalStyles } from "../styles/globalStyles"
import { Concept, ConceptUpdateInput, SystemRole } from "../__generated__/types"
import { CONCEPT_BY_ID } from "../graphql/queries"
import { useApolloClient, useMutation } from "@apollo/client"

import { makeStyles } from "@material-ui/core/styles"
import { useAuth } from "../providers/AuthProvider"
import MoreVertIcon from "@material-ui/icons/MoreVert"
import {
    CREATE_DASHBOARD,
    ADD_CONCEPT_HOME_DASHBOARD,
    CONNECT_DASHBOARD_TO_CONCEPT,
    REMOVE_CONCEPT_HOME_DASHBOARD,
    DELETE_DASHBOARD,
    ADD_CATEGORY_DEFAULT_DASHBOARD,
    REMOVE_CATEGORY_DEFAULT_DASHBOARD,
    GET_DASHBOARD_WIDGETS_BY_ID,
} from "./dashboard/graphql"
import {
    AddConceptHomeDashboardMutation,
    AddConceptHomeDashboardMutationVariables,
    CreateDashboardMutation,
    CreateDashboardMutationVariables,
    ConnectDashboardToConceptMutation,
    ConnectDashboardToConceptMutationVariables,
    RemoveConceptHomeDashboardMutation,
    RemoveConceptHomeDashboardMutationVariables,
    DeleteDashboardViewMutation,
    DeleteDashboardViewMutationVariables,
    AddCategoryDefaultDashboardMutation,
    AddCategoryDefaultDashboardMutationVariables,
    RemoveCategoryDefaultDashboardMutation,
    RemoveCategoryDefaultDashboardMutationVariables,
} from "./dashboard/__generated__/graphql"
import { useDashboardTemplates } from "./dashboard/useDashboardTemplates"
import useCategoryTools from "./categories/useCategoryTools"

const useStyles = makeStyles((theme) => ({
    popper: {
        zIndex: theme.zIndex.drawer + 1,
    },
    paper: {
        width: "250px",
        height: "fit-content",
    },
    listItem: {
        "&:hover": {
            backgroundColor: theme.palette.action.hover,
        },
    },
}))
interface ConceptDetailsHeaderOptionsProps {
    item: Concept
}

export default function ConceptDetailsHeaderOptions(
    props: ConceptDetailsHeaderOptionsProps
) {
    //hooks
    const { currentUser } = useAuth()
    const classes = useStyles()
    const { t } = useAwaitTranslation("widgets")

    //state
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)

    //functions
    const handleButtonClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(anchorEl ? null : event.currentTarget)
    }

    //variables
    const open = Boolean(anchorEl)

    return (
        <>
            <IconButton onClick={handleButtonClick}>
                <MoreVertIcon />
            </IconButton>

            <Popper
                open={open}
                anchorEl={anchorEl}
                className={classes.popper}
                placement="bottom-end"
                transition
            >
                {({ TransitionProps }) => (
                    <Fade {...TransitionProps} timeout={350}>
                        <Box>
                            <ClickAwayListener
                                onClickAway={() => setAnchorEl(null)}
                            >
                                <Paper className={classes.paper} elevation={7}>
                                    <List disablePadding>
                                        <ListSubheader disableSticky={true}>
                                            {t(
                                                "homeDashboardOptions",
                                                "Home Dashboard Options"
                                            )}
                                        </ListSubheader>
                                        <Divider />

                                        <DashboardOption item={props.item} />

                                        {currentUser?.role ===
                                            SystemRole.ADMIN && (
                                            <CategoryDefaultOption
                                                item={props.item}
                                            />
                                        )}
                                    </List>
                                </Paper>
                            </ClickAwayListener>
                        </Box>
                    </Fade>
                )}
            </Popper>
        </>
    )
}
interface OptionProps {
    item: Concept
    onUpdate?: (data: ConceptUpdateInput) => void
}

const CategoryDefaultOption = (props: OptionProps) => {
    const classes = useStyles()
    const globalClasses = useGlobalStyles()

    const [confirmRemoval, setConfirmRemoval] = useState(false)
    const [confirmAddition, setConfirmAddition] = useState(false)
    const category = props.item?.category
    const catDefaultId = category?.defaultDashboard?.id
    const homeDashboardId = props.item?.homeDashboard?.id
    const checked = !!catDefaultId && catDefaultId === homeDashboardId
    const { t } = useAwaitTranslation("dashboard")
    const [setAsCategoryDefault, { loading: addingDefault }] = useMutation<
        AddCategoryDefaultDashboardMutation,
        AddCategoryDefaultDashboardMutationVariables
    >(ADD_CATEGORY_DEFAULT_DASHBOARD)
    const [removeCategoryDefault, { loading: removingDefault }] = useMutation<
        RemoveCategoryDefaultDashboardMutation,
        RemoveCategoryDefaultDashboardMutationVariables
    >(REMOVE_CATEGORY_DEFAULT_DASHBOARD)

    const handleSetDefault = async () => {
        if (!!category?.defaultDashboard) {
            await removeCategoryDefault({
                variables: {
                    categoryId: category?.id,
                    dashboardId: category?.defaultDashboard?.id,
                },
            })
        }
        await setAsCategoryDefault({
            variables: {
                categoryId: category?.id,
                dashboardId: homeDashboardId,
            },
        })
        setConfirmAddition(false)
    }
    const handleRemoveDefault = async () => {
        await removeCategoryDefault({
            variables: {
                categoryId: props.item?.category?.id,
                dashboardId: homeDashboardId,
            },
        })
        setConfirmRemoval(false)
    }
    if (!homeDashboardId) return null
    return (
        <>
            <ListItem className={classes.listItem}>
                <ListItemIcon className={globalClasses.smallMenuIcon}>
                    <DashboardIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText
                    primaryTypographyProps={{
                        className: globalClasses.smallMenuListItemText,
                    }}
                    primary={t(
                        "customAsCategoryDefault",
                        "Custom as Category Default"
                    )}
                />

                <ListItemSecondaryAction>
                    <Checkbox
                        onChange={(e) => {
                            if (!!e.target.checked) {
                                setConfirmAddition(true)
                            } else {
                                setConfirmRemoval(true)
                            }
                        }}
                        edge="end"
                        size="small"
                        checked={checked}
                    />
                </ListItemSecondaryAction>
            </ListItem>
            {/* removal dialog */}
            <Dialog
                open={confirmRemoval}
                onBackdropClick={() => setConfirmRemoval(false)}
            >
                <DialogContent>
                    <DialogContentText>
                        Are you sure you want to remove this as the default
                        dashboard for all concepts in this category?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        disabled={!!removingDefault}
                        onClick={() => setConfirmRemoval(false)}
                    >
                        No
                    </Button>
                    <Button
                        disabled={!!removingDefault}
                        onClick={handleRemoveDefault}
                    >
                        {!!removingDefault ? (
                            <CircularProgress size={20} disableShrink />
                        ) : (
                            "Yes"
                        )}
                    </Button>
                </DialogActions>
            </Dialog>
            {/* addition dialog */}
            <Dialog
                open={confirmAddition}
                onBackdropClick={() => setConfirmAddition(false)}
            >
                <DialogContent>
                    <DialogContentText>
                        Are you sure you want to make this dashboard the default
                        for all concepts in this category?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        disabled={!!removingDefault || !!addingDefault}
                        onClick={() => setConfirmAddition(false)}
                    >
                        No
                    </Button>
                    <Button
                        disabled={!!removingDefault || !!addingDefault}
                        onClick={handleSetDefault}
                    >
                        {!!removingDefault || !!addingDefault ? (
                            <CircularProgress size={20} disableShrink />
                        ) : (
                            "Yes"
                        )}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

const DashboardOption = (props: OptionProps) => {
    const classes = useStyles()
    const globalClasses = useGlobalStyles()
    const client = useApolloClient()
    const { onApplyDashboardTemplate } = useDashboardTemplates()

    const [createDashboard, { loading: creating }] = useMutation<
        CreateDashboardMutation,
        CreateDashboardMutationVariables
    >(CREATE_DASHBOARD)
    const [removeHomeDashboard, { loading: removing }] = useMutation<
        RemoveConceptHomeDashboardMutation,
        RemoveConceptHomeDashboardMutationVariables
    >(REMOVE_CONCEPT_HOME_DASHBOARD)
    const [connectDashboardToConcept, { loading: connecting }] = useMutation<
        ConnectDashboardToConceptMutation,
        ConnectDashboardToConceptMutationVariables
    >(CONNECT_DASHBOARD_TO_CONCEPT, {
        refetchQueries: [
            {
                query: CONCEPT_BY_ID,
                variables: { id: props.item?.id },
            },
        ],
        awaitRefetchQueries: true,
    })
    const [deleteDashboard, { loading: deleting }] = useMutation<
        DeleteDashboardViewMutation,
        DeleteDashboardViewMutationVariables
    >(DELETE_DASHBOARD, {
        update(cache) {
            cache.evict({
                id: cache.identify(props.item?.homeDashboard),
            })
        },
    })
    const [addHomeDashboard, { loading: addingHomeDashboard }] = useMutation<
        AddConceptHomeDashboardMutation,
        AddConceptHomeDashboardMutationVariables
    >(ADD_CONCEPT_HOME_DASHBOARD)
    const [creatingCustomDashboard, setCreatingCustomDashboard] = useState(
        false
    )
    const [confirmOpen, setConfirmOpen] = useState(false)
    const checked = !!props.item?.homeDashboard
    const { t } = useAwaitTranslation("dashboard")
    const { getCategoryArray } = useCategoryTools()
    const categoryArray = getCategoryArray(props.item.category?.id)
    const handleAddition = async () => {
        setCreatingCustomDashboard(true)
        const {
            data: { CreateDashboardView: NewDashboard },
        } = await createDashboard({
            variables: {
                name: `${props.item?.title} Home Dashboard`,
                description: "",
            },
        })
        if (!!NewDashboard) {
            await addHomeDashboard({
                variables: {
                    conceptId: props.item?.id,
                    dashboardId: NewDashboard?.id,
                },
            })
        }
        const categoryDefault =
            categoryArray?.[categoryArray?.length - 1]?.defaultDashboard ?? null
        if (!!categoryDefault) {
            await onApplyDashboardTemplate(categoryDefault, NewDashboard.id)
        }

        await client.refetchQueries({
            include: [
                {
                    // @ts-ignore
                    query: CONCEPT_BY_ID,
                    variables: {
                        id: props.item.id,
                    },
                },
                {
                    // @ts-ignore
                    query: GET_DASHBOARD_WIDGETS_BY_ID,
                    variables: {
                        dashboardId: NewDashboard.id,
                    },
                },
            ],
        })
        setCreatingCustomDashboard(false)
    }

    const handleNestedAddition = async () => {
        await Promise.all([
            removeHomeDashboard({
                variables: {
                    dashboardId: props.item?.homeDashboard?.id,
                    conceptId: props.item?.id,
                },
            }),
            connectDashboardToConcept({
                variables: {
                    dashboardId: props.item?.homeDashboard?.id,
                    conceptId: props.item?.id,
                },
            }),
        ])

        setConfirmOpen(false)
    }

    const handleDeletion = async () => {
        await deleteDashboard({
            variables: {
                id: props.item?.homeDashboard?.id,
            },
        })

        setConfirmOpen(false)
    }

    return (
        <>
            <ListItem className={classes.listItem}>
                <ListItemIcon className={globalClasses.smallMenuIcon}>
                    <DashboardIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText
                    primaryTypographyProps={{
                        className: globalClasses.smallMenuListItemText,
                    }}
                    primary={t("custom", "Custom")}
                />

                <ListItemSecondaryAction>
                    {!!creatingCustomDashboard ? (
                        <CircularProgress size={20} disableShrink={true} />
                    ) : (
                        <Checkbox
                            disabled={creating || addingHomeDashboard}
                            onChange={(e) => {
                                if (!!e.target.checked) {
                                    handleAddition()
                                } else {
                                    setConfirmOpen(true)
                                }
                            }}
                            edge="end"
                            size="small"
                            checked={checked}
                        />
                    )}
                </ListItemSecondaryAction>
            </ListItem>
            <Dialog
                open={confirmOpen}
                onBackdropClick={() => setConfirmOpen(false)}
            >
                <DialogContent>
                    <DialogContentText>
                        Do you want to delete the current home dashboard or add
                        it as a nested dashboard?
                    </DialogContentText>
                </DialogContent>
                <DialogActions style={{ justifyContent: "center" }}>
                    <Button
                        onClick={handleDeletion}
                        disabled={deleting || removing || connecting}
                        style={{ position: "relative" }}
                    >
                        {deleting ? (
                            <CircularProgress size={20} disableShrink />
                        ) : (
                            "Delete"
                        )}
                    </Button>
                    <Button
                        onClick={handleNestedAddition}
                        disabled={deleting || removing || connecting}
                    >
                        {removing || connecting ? (
                            <CircularProgress size={20} />
                        ) : (
                            "Nest"
                        )}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}
