import React from "react"
import { VIEW_OPTIONS } from "../providers/GlobalState"
import { Concept } from "../__generated__/types"

import { FormControl, InputLabel, MenuItem, Select } from "@material-ui/core"

import { Pagination } from "@material-ui/lab"
import { DEFAULT_CONCEPTS_PER_GRAPH } from "./ConceptFeed"

export const FeedViewTypeSelect = (props: {
    value: VIEW_OPTIONS
    onChange: (value: VIEW_OPTIONS) => void
}) => {
    const { value, onChange } = props

    return (
        <FormControl style={{ width: "7em" }} size="small" fullWidth>
            <InputLabel>View Type</InputLabel>

            <Select
                label="View Type"
                disableUnderline
                value={value}
                onChange={(e) => onChange(e.target.value as VIEW_OPTIONS)}
            >
                <MenuItem value={VIEW_OPTIONS.NORMAL}>Normal</MenuItem>
                <MenuItem value={VIEW_OPTIONS.GRAPH_2D}>Graph 2D</MenuItem>
                <MenuItem value={VIEW_OPTIONS.GRAPH_3D}>Graph 3D</MenuItem>
            </Select>
        </FormControl>
    )
}

export const FeedPagination = (props: {
    currentTotal: Concept[]
    currentPage: number
    onChange: (pageNumber: number) => void
}) => {
    const { currentTotal, onChange, currentPage } = props
    const totalPages = Math.round(
        currentTotal?.length / DEFAULT_CONCEPTS_PER_GRAPH
    )
    const currentFeedPage = currentPage || 1

    return (
        <Pagination
            color="primary"
            siblingCount={1}
            onChange={(event: React.ChangeEvent<unknown>, value: number) => {
                onChange(value)
            }}
            count={totalPages}
            page={currentFeedPage}
        />
    )
}
