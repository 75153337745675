import React, { useState, useCallback } from "react"
import {
    Dialog,
    DialogActions,
    Button,
    Divider,
    List,
    CircularProgress,
    Box,
    DialogTitle,
    Popper,
    ClickAwayListener,
    Paper,
    IconButton,
    Badge,
    useMediaQuery,
} from "@material-ui/core"
import { ALL_USER_NOTIFICATIONS_QUERY } from "./graphql"
import { ReactComponent as GlobalFilterIcon } from "../../styles/FilterIcon.svg"
import { useQuery } from "@apollo/client"
import { Loading } from "../Loading"
import {
    createStyles,
    Theme,
    makeStyles,
    useTheme,
} from "@material-ui/core/styles"
import InfiniteScroll from "react-infinite-scroller"
import NotificationItem from "./NotificationItem"

import Filters from "../filters/Filters"
import { FilterItem, FilterType } from "../filters/util/types"
import { getFilterObject } from "../filters/util/getFilterObject"
import { _NotificationFilter } from "../../__generated__/types"
import useAwaitTranslation from "../../i18n/useAwaitTranslation"
import { FeedVariables } from "../../providers/GlobalState"
import {
    AllUserNotificationsQuery,
    AllUserNotificationsQueryVariables,
} from "./__generated__/graphql"
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: "100%",
            backgroundColor: theme.palette.background.paper,
            overflow: "auto",
            flexGrow: 1,
        },

        label: {
            fontSize: 12,
            color: theme.palette.text.secondary,
            paddingBottom: theme.spacing(0.5),
        },

        popper: {
            zIndex: 1500,
        },
        mobilePopper: {
            width: "100%",
            right: 0,
            left: 0,
            zIndex: 1500,
        },
        paper: {
            height: "100vh",
        },
    })
)
interface NotificationsModalProps {
    onClose: () => void
    userId: string
}

const PAGE_SIZE = 15
export default function NotificationsModal(props: NotificationsModalProps) {
    const classes = useStyles()
    const theme = useTheme()
    const mobile = useMediaQuery(theme.breakpoints.down("sm"))
    const [filters, setFilters] = useState<FilterItem[]>([
        {
            firstLevelFilter: "type_in",
            value: [],
            type: FilterType.NotificationType,
        },
        {
            firstLevelFilter: "actionBy_in",
            secondLevelFilter: "userId_in",
            value: [],
            type: FilterType.User,
        },
        {
            firstLevelFilter: "concept_in",
            secondLevelFilter: "id_in",
            value: [],
            optionsFilter: {
                watchedBy_some: {
                    userId: props.userId,
                },
                type_not_in: ["Feature", "Requirement", "UserInterview"],
            },
            type: FilterType.Concept,
        },
    ])
    const [limit, setLimit] = useState(PAGE_SIZE)
    const [loadMore, setLoadMore] = useState(true)
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)

    const open = Boolean(anchorEl)
    //@ts-ignore
    let filterObject: _NotificationFilter = {
        users_some: {
            userId: props.userId,
        },
        actionBy_not: {
            userId: props.userId,
        },
        concept_not: {
            id: null,
        },
        ...getFilterObject(filters),
    }
    const { data, loading, fetchMore } = useQuery<
        AllUserNotificationsQuery,
        AllUserNotificationsQueryVariables
    >(ALL_USER_NOTIFICATIONS_QUERY, {
        variables: {
            first: limit,
            offset: 0,
            filter: filterObject,
        },
    })
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(anchorEl ? null : event.currentTarget)
    }
    const notifications = data?.Notification ?? []
    const onLoadMore = useCallback(
        async (page) => {
            if (!fetchMore) return
            const result = await fetchMore({
                variables: {
                    offset: page * PAGE_SIZE,
                },
            })
            setLimit(page * PAGE_SIZE + PAGE_SIZE)
            setLoadMore(!(result.data?.Notification?.length < page * PAGE_SIZE))
        },
        [fetchMore]
    )

    const numFiltersEnabled = () =>
        filters.filter((x) => {
            return x.value && x.value.length > 0
        }).length
    const { t } = useAwaitTranslation("user")
    return (
        <Dialog
            open={true}
            fullWidth
            maxWidth="md"
            classes={{
                paper: classes.paper,
            }}
            onBackdropClick={() => props.onClose()}
        >
            <Box
                display="flex"
                flexDirection="column"
                width="100%"
                overflow="hidden"
                height="100%"
            >
                <DialogTitle style={{ paddingBottom: 0 }}>
                    <Box
                        display="flex"
                        width="100%"
                        justifyContent="space-between"
                        alignItems="center"
                    >
                        <Box>{t("allNotifications", "All Notifications")}</Box>
                        <IconButton onClick={handleClick}>
                            <Badge
                                badgeContent={numFiltersEnabled()}
                                color="primary"
                                overlap="rectangle"
                            >
                                <GlobalFilterIcon
                                    style={{
                                        width: "1em",
                                        height: "1em",
                                        fill: "currentcolor",
                                    }}
                                />
                            </Badge>
                        </IconButton>
                        <Popper
                            className={
                                mobile ? classes.mobilePopper : classes.popper
                            }
                            open={open}
                            anchorEl={anchorEl}
                            disablePortal
                        >
                            {({ TransitionProps }) => (
                                <ClickAwayListener
                                    onClickAway={() => setAnchorEl(null)}
                                >
                                    <Paper
                                        elevation={6}
                                        style={{ width: "20em" }}
                                    >
                                        <Filters
                                            onChangeFeedVariables={(
                                                value: FeedVariables
                                            ) => {
                                                setFilters(value.filters)
                                                setLoadMore(true)
                                            }}
                                            feedVariables={{
                                                filters: filters,
                                            }}
                                        />
                                    </Paper>
                                </ClickAwayListener>
                            )}
                        </Popper>
                    </Box>
                </DialogTitle>

                <Divider />
                {loading ? (
                    <Loading size={25} hideQuote={true} />
                ) : (
                    <>
                        <List className={classes.root}>
                            <InfiniteScroll
                                pageStart={0}
                                loadMore={onLoadMore}
                                hasMore={loadMore}
                                loader={
                                    <div
                                        key={0}
                                        style={{
                                            textAlign: "center",
                                            marginTop: "4px",
                                        }}
                                    >
                                        <CircularProgress
                                            disableShrink
                                            size={40}
                                        />
                                    </div>
                                }
                                useWindow={false}
                            >
                                {notifications?.length > 0 ? (
                                    notifications.map((notification, index) => {
                                        return (
                                            <Box key={notification.id}>
                                                <NotificationItem
                                                    onClose={() =>
                                                        props.onClose()
                                                    }
                                                    notification={notification}
                                                    viewed={true}
                                                />
                                                <Divider />
                                            </Box>
                                        )
                                    })
                                ) : (
                                    <Box
                                        p={1}
                                        display="flex"
                                        justifyContent="center"
                                    >
                                        {t(
                                            "noNotificationsToShow",
                                            "No notifications to show..."
                                        )}
                                    </Box>
                                )}
                            </InfiniteScroll>
                        </List>
                    </>
                )}
                <Divider />
                <DialogActions>
                    <Button onClick={() => props.onClose()}>
                        {t("close", "Close")}
                    </Button>
                </DialogActions>
            </Box>
        </Dialog>
    )
}
