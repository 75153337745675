import React from "react"
import { Box, List, Typography, Chip } from "@material-ui/core"
import SyncAlt from "@material-ui/icons/SyncAlt"
import { Concept } from "../../../__generated__/types"

import ClickableRichTooltip from "../../Popper/ClickableRichTooltip"

import ConceptListItem from "../../ConceptListItem"

export const ResponseConnectionPopper = (props: { concepts: Concept[] }) => {
    const { concepts } = props
    return (
        <ClickableRichTooltip
            arrow={true}
            placement="right"
            content={
                <Box width="30em" maxHeight={300} overflow="auto">
                    <List dense>
                        {concepts.map((concept, index) => {
                            return (
                                <ConceptListItem
                                    disablePreview={true}
                                    key={concept.id}
                                    hideDivider={true}
                                    item={concept}
                                />
                            )
                        })}
                    </List>
                </Box>
            }
        >
            <Chip
                size="small"
                avatar={<SyncAlt fontSize="small" color="action" />}
                label={
                    <Typography variant="body2" color="textSecondary">
                        {concepts.length}
                    </Typography>
                }
            />
        </ClickableRichTooltip>
    )
}
