import React, { useState, useRef } from "react"

import ReactCrop, {
    centerCrop,
    makeAspectCrop,
    Crop,
    PixelCrop,
} from "react-image-crop"
import { canvasPreview } from "./canvasPreview"
import { useDebounceEffect } from "./useDebounceEffect"
import ZoomInIcon from "@material-ui/icons/ZoomIn"
import ZoomOutIcon from "@material-ui/icons/ZoomOut"
import "react-image-crop/dist/ReactCrop.css"
import { makeStyles, Theme } from "@material-ui/core/styles"
import RotateRightIcon from "@material-ui/icons/RotateRight"
import RotateLeftIcon from "@material-ui/icons/RotateLeft"
import ArrowBackIcon from "@material-ui/icons/ArrowBack"
import {
    Button,
    Box,
    Input,
    Typography,
    CircularProgress,
    IconButton,
    InputAdornment,
    Divider,
} from "@material-ui/core"
import PhotoSearch from "./PhotoSearch"
import useAwaitTranslation from "../../i18n/useAwaitTranslation"
import { Loading } from "../Loading"

const useStyles = makeStyles((theme: Theme) => ({
    dialogContent: {
        overflow: "hidden",
        display: "flex",
        flexDirection: "column",
        [theme.breakpoints.up("sm")]: {
            minHeight: "70vh",
        },
        height: "80vh",
        padding: `0 !important`,
    },
    image: {
        width: "100%",
        height: "100%",
        objectFit: "contain",
        borderRadius: theme.shape.borderRadius,
    },
    imageContainer: {
        [theme.breakpoints.up("sm")]: {
            height: "70vh",
        },
        padding: theme.spacing(2, 0),
    },
    loadingContainer: {
        position: "absolute",
        right: 0,
        left: 0,
        top: 0,
        bottom: 0,
        zIndex: theme.zIndex.modal + 2,
    },

    input: {
        "&[type=number]": {
            "-moz-appearance": "textfield",
        },
        "&::-webkit-outer-spin-button": {
            "-webkit-appearance": "none",
            margin: 0,
        },
        "&::-webkit-inner-spin-button": {
            "-webkit-appearance": "none",
            margin: 0,
        },
        textAlign: "center",
    },
    crop: {
        height: "100%",
        width: "100%",
        "& > *": {
            height: "100%",
        },
        borderRadius: theme.shape.borderRadius,
    },
}))

// This is to demonstate how to make and center a % aspect crop
// which is a bit trickier so we use some helper functions.

export default function ImageCropper(props: {
    onCancel: () => void
    onDone?: (file, fileName) => Promise<void>
    imageId: string
    currentImageUrl: string
}) {
    const { onCancel, onDone, currentImageUrl } = props
    const { t } = useAwaitTranslation("images")
    const classes = useStyles()

    const [imgSrc, setImgSrc] = useState("")
    const [submitting, setSubmitting] = useState(false)
    const previewCanvasRef = useRef<HTMLCanvasElement>(null)
    const imgRef = useRef<HTMLImageElement>(null)
    const [crop, setCrop] = useState<Crop>()

    const [completedCrop, setCompletedCrop] = useState<PixelCrop>(null)
    const [scale, setScale] = useState(1)
    const [rotate, setRotate] = useState(0)
    const aspect = 1 / 1

    const onSelectFile = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files && e.target.files.length > 0) {
            setCrop(undefined) // Makes crop preview update between images.
            const reader = new FileReader()
            reader.addEventListener("load", () =>
                setImgSrc(reader.result.toString() || "")
            )
            reader.readAsDataURL(e.target.files[0])
        }
    }
    function onImageLoad(e) {
        const { width, height } = e.currentTarget
        const min = Math.min(width, height)
        const crop = centerCrop(
            makeAspectCrop(
                {
                    // You don't need to pass a complete crop into
                    // makeAspectCrop or centerCrop.
                    unit: "px",
                    width: min,
                    height: min,
                },
                aspect,
                width,
                height
            ),
            min,
            min
        )

        setCrop(crop)
    }

    useDebounceEffect(
        async () => {
            if (
                completedCrop?.width &&
                completedCrop?.height &&
                imgRef.current &&
                previewCanvasRef.current
            ) {
                // We use canvasPreview as it's much faster than imgPreview.
                canvasPreview(
                    imgRef.current,
                    previewCanvasRef.current,
                    completedCrop,
                    scale,
                    rotate
                )
            }
        },
        100,
        [completedCrop, scale, rotate]
    )

    const onComplete = async () => {
        setSubmitting(true)
        let fileName = props.imageId + JSON.stringify(new Date().getTime())

        const blob = await getCroppedImg(
            imgRef.current,
            completedCrop,
            fileName,
            scale,
            rotate
        )

        if (!!blob && !!fileName) {
            onDone(blob, fileName)
        }
    }
    const handlePixabayUpload = async (imageUrl, imageId) => {
        setImgSrc(imageUrl)
    }
    return (
        <Box
            height="100%"
            display="flex"
            width="100%"
            alignItems="center"
            flexDirection={"column"}
            position="relative"
        >
            {!!submitting ? (
                <Box className={classes.loadingContainer}>
                    <Loading
                        size={25}
                        hideQuote={true}
                        message="Uploading Image..."
                    />
                </Box>
            ) : !imgSrc ? (
                <Box
                    width="100%"
                    height="100%"
                    display="flex"
                    flexDirection={"column"}
                    overflow="hidden"
                >
                    <Box
                        display="flex"
                        justifyContent={
                            !!currentImageUrl ? "space-between" : "flex-end"
                        }
                        p={2}
                        width="100%"
                        alignItems={"center"}
                    >
                        {!!currentImageUrl && (
                            <Button
                                onClick={() => {
                                    onCancel()
                                }}
                            >
                                {t("cancel", "Cancel")}
                            </Button>
                        )}
                        <Button
                            size="small"
                            variant="contained"
                            color="primary"
                            component="label"
                        >
                            <input
                                accept="image/*"
                                type="file"
                                hidden
                                onChange={onSelectFile}
                            />
                            <span>
                                {t("uploadNewImage", "Upload New Image")}
                            </span>
                        </Button>
                    </Box>

                    <Box
                        p={2}
                        display="flex"
                        overflow="hidden"
                        flexDirection="column"
                    >
                        <PhotoSearch handleImageClick={handlePixabayUpload} />
                    </Box>
                </Box>
            ) : (
                <Box
                    display="flex"
                    flexDirection={"column"}
                    height="100%"
                    width="100%"
                    overflow="hidden"
                >
                    <Box p={1} display="flex" alignItems={"center"}>
                        <Box width="100%">
                            <Button
                                onClick={() => {
                                    setImgSrc(null)
                                }}
                                startIcon={<ArrowBackIcon />}
                            >
                                Go Back
                            </Button>
                        </Box>
                        <Box width={"100%"} textAlign="right">
                            <Button
                                color="primary"
                                onClick={onComplete}
                                disabled={
                                    !completedCrop ||
                                    !!submitting ||
                                    completedCrop.height === 0 ||
                                    completedCrop.width === 0
                                }
                            >
                                {!!submitting ? (
                                    <CircularProgress size={20} disableShrink />
                                ) : (
                                    "Done"
                                )}
                            </Button>
                        </Box>
                    </Box>
                    <Divider />
                    <Box
                        p={2}
                        display="flex"
                        justifyContent={"space-between"}
                        width="100%"
                        pb={0}
                        pt={0}
                        alignItems="center"
                    >
                        <Box width="100%">
                            <Typography variant={"h6"}>Crop Image</Typography>
                        </Box>
                        <Box display="flex" alignItems="center">
                            <Box
                                m={1}
                                mr={2}
                                ml={2}
                                display="flex"
                                alignItems={"center"}
                            >
                                <IconButton
                                    onClick={() => {
                                        setScale(scale - 0.1)
                                    }}
                                >
                                    <ZoomOutIcon />
                                </IconButton>
                                <Box m={1}>
                                    <Input
                                        inputProps={{
                                            type: "number",
                                            className: classes.input,
                                        }}
                                        value={Number(scale.toFixed(1))}
                                        style={{ width: "3em" }}
                                        onChange={(e) =>
                                            setScale(Number(e.target.value))
                                        }
                                        endAdornment={
                                            <InputAdornment position="end">
                                                x
                                            </InputAdornment>
                                        }
                                    />
                                </Box>
                                <IconButton
                                    onClick={() => {
                                        setScale(scale + 0.1)
                                    }}
                                >
                                    <ZoomInIcon />
                                </IconButton>
                            </Box>
                            <Box
                                m={1}
                                display="flex"
                                alignItems={"center"}
                                mr={2}
                                ml={2}
                            >
                                <IconButton
                                    onClick={() => {
                                        setRotate(rotate - 5)
                                    }}
                                >
                                    <RotateLeftIcon />
                                </IconButton>
                                <Box m={1}>
                                    <Input
                                        inputProps={{
                                            type: "number",
                                            className: classes.input,
                                        }}
                                        endAdornment={
                                            <InputAdornment position="end">
                                                {`\u00B0`}
                                            </InputAdornment>
                                        }
                                        value={rotate}
                                        style={{ width: "3em" }}
                                        onChange={(e) =>
                                            setRotate(
                                                Math.min(
                                                    180,
                                                    Math.max(
                                                        -180,
                                                        Number(e.target.value)
                                                    )
                                                )
                                            )
                                        }
                                    />
                                </Box>

                                <IconButton
                                    onClick={() => {
                                        setRotate(rotate + 5)
                                    }}
                                >
                                    <RotateRightIcon />
                                </IconButton>
                            </Box>
                        </Box>
                    </Box>

                    {Boolean(imgSrc) && (
                        <Box
                            width="100%"
                            // height="100%"
                            display="flex"
                            justifyContent={"center"}
                            alignItems="center"
                            pt={1}
                            p={3}
                            overflow="hidden"
                        >
                            <ReactCrop
                                // ruleOfThirds={true}
                                minHeight={50}
                                minWidth={50}
                                crop={crop}
                                onChange={(pixelCrop, percentageCrop) =>
                                    setCrop(pixelCrop)
                                }
                                onComplete={(c) => setCompletedCrop(c)}
                                aspect={aspect}
                                className={classes.crop}
                            >
                                <img
                                    crossOrigin="anonymous"
                                    ref={imgRef}
                                    alt="Crop me"
                                    src={imgSrc}
                                    style={{
                                        transform: `scale(${scale}) rotate(${rotate}deg)`,
                                        height: "inherit",
                                        width: "inherit",
                                        objectFit: "scale-down",
                                    }}
                                    onLoad={onImageLoad}
                                />
                            </ReactCrop>
                        </Box>
                    )}
                </Box>
            )}
        </Box>
    )
}

/**
 * @param {File} image - Image File Object
 * @param {Object} pixelCrop - pixelCrop Object provided by react-image-crop
 * @param {String} fileName - Name of the returned file in Promise
 * @param {Int} scale - zoom value
 * @param {Int} rotate - rotation value
 */
function getCroppedImg(
    image: HTMLImageElement,
    pixelCrop,
    fileName,
    scale,
    rotate
) {
    const canvas = document.createElement("canvas")

    canvasPreview(image, canvas, pixelCrop, scale, rotate)

    return new Promise((resolve, reject) => {
        canvas.toBlob((file) => {
            //@ts-ignore
            file.name = fileName
            resolve(file)
        }, "image/png")
    }).catch((e) => {
        console.log(e)
    })
}
