import { useMutation } from "@apollo/client"
import { useCallback } from "react"
import { UPLOAD_CONCEPT_IMAGE } from "./graphql"
import {
    UploadConceptImageMutation,
    UploadConceptImageMutationVariables,
} from "./__generated__/graphql"

export default function useImageTools() {
    const [uploadPhoto] = useMutation<
        UploadConceptImageMutation,
        UploadConceptImageMutationVariables
    >(UPLOAD_CONCEPT_IMAGE)

    const onUploadPhoto = useCallback(
        async (file, imageName: string) => {
            const photoResponse = await uploadPhoto({
                variables: {
                    file: file,
                    imageName: imageName,
                },
            }).catch((e) => {
                console.log(e)
            })
            if (!!photoResponse) {
                return photoResponse.data.uploadConceptImage.fileUrl
            }
            return ""
        },
        [uploadPhoto]
    )

    return {
        onUploadPhoto,
    }
}
