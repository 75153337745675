import React from "react"
import { Box, Button, Grow, Typography } from "@material-ui/core"
import useAwaitTranslation from "../../../i18n/useAwaitTranslation"

import Grid from "@material-ui/core/Grid"
import ButtonGroup from "@material-ui/core/ButtonGroup"
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown"
import ClickAwayListener from "@material-ui/core/ClickAwayListener"
import Paper from "@material-ui/core/Paper"
import Popper from "@material-ui/core/Popper"
import MenuItem from "@material-ui/core/MenuItem"
import MenuList from "@material-ui/core/MenuList"
import { CollectionType } from "../../criteria/useCriteriaTypes"
import useCategoryTools from "../../categories/useCategoryTools"
import { Category } from "../../../__generated__/types"
import CategoryIcon from "../../categories/CategoryIcon"
import { useTheme } from "@material-ui/core/styles"
import Add from "@material-ui/icons/Add"
import PageviewIcon from "@material-ui/icons/Pageview"
const CollectionToolbar = (props: {
    setAdding: (value: boolean) => void
    onBrowse: () => void
    onSetCategoryToAdd: (item: Category) => void
    categoryToAdd?: string
    config: CollectionType
}) => {
    const {
        setAdding,
        onBrowse,

        config,
        onSetCategoryToAdd,
        categoryToAdd,
    } = props

    const { t } = useAwaitTranslation()

    return (
        <Box display="flex" justifyContent={"flex-start"} p={2}>
            <AddItemButton
                config={config}
                onChange={onSetCategoryToAdd}
                categoryValue={categoryToAdd}
                onClick={() => {
                    setAdding(true)
                }}
            />

            <Button
                style={{ marginLeft: 10 }}
                onClick={() => onBrowse()}
                endIcon={<PageviewIcon />}
                variant="outlined"
                size="small"
            >
                {t("browseAll", "Browse All")}
            </Button>
        </Box>
    )
}

const AddItemButton = (props: {
    config: CollectionType
    onChange: (value: Category) => void
    categoryValue: string
    onClick: () => void
}) => {
    const { config, categoryValue, onChange, onClick } = props
    const [open, setOpen] = React.useState(false)
    const anchorRef = React.useRef<HTMLDivElement>(null)
    const theme = useTheme()
    const { environmentCategories } = useCategoryTools()
    const options = environmentCategories.filter((cat) =>
        config?.categories?.find((item) => item.id === cat.id)
    )

    const handleMenuItemClick = (
        event: React.MouseEvent<HTMLLIElement, MouseEvent>,
        option: Category
    ) => {
        setOpen(false)
        onChange(option)
    }

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen)
    }

    const handleClose = (event: React.MouseEvent<Document, MouseEvent>) => {
        if (
            anchorRef.current &&
            anchorRef.current.contains(event.target as HTMLElement)
        ) {
            return
        }

        setOpen(false)
    }
    const selectedCategory = options.find((op) => op.id === categoryValue)
    if (
        config.categories?.length <= 1 ||
        !config.categories ||
        !selectedCategory
    ) {
        return (
            <Button
                size="small"
                variant="contained"
                color="primary"
                onClick={() => onClick()}
                startIcon={<Add />}
            >
                {selectedCategory?.name || "No Category Selected"}
            </Button>
        )
    }
    return (
        <Grid
            container
            direction="column"
            alignItems="center"
            style={{ width: "auto" }}
        >
            <Grid item xs={12}>
                <ButtonGroup
                    variant="contained"
                    color="primary"
                    ref={anchorRef}
                    size="small"
                >
                    <Button
                        onClick={() => onClick()}
                        startIcon={<Add />}
                        size="small"
                    >
                        {selectedCategory?.name}
                    </Button>
                    <Button color="primary" size="small" onClick={handleToggle}>
                        <ArrowDropDownIcon />
                    </Button>
                </ButtonGroup>
                <Popper
                    open={open}
                    anchorEl={anchorRef.current}
                    role={undefined}
                    transition
                    disablePortal
                    //has to be above 3 for it is under the table header
                    style={{ zIndex: 5 }}
                >
                    {({ TransitionProps, placement }) => (
                        <Grow
                            {...TransitionProps}
                            style={{
                                transformOrigin:
                                    placement === "bottom"
                                        ? "center top"
                                        : "center bottom",
                            }}
                        >
                            <Paper>
                                <ClickAwayListener onClickAway={handleClose}>
                                    <MenuList id="split-button-menu">
                                        {options?.map((option) => {
                                            return (
                                                <MenuItem
                                                    key={option.id}
                                                    value={option.id}
                                                    selected={
                                                        option.id ===
                                                        categoryValue
                                                    }
                                                    onClick={(event) =>
                                                        handleMenuItemClick(
                                                            event,
                                                            option
                                                        )
                                                    }
                                                >
                                                    <Box
                                                        display="flex"
                                                        alignItems="center"
                                                        color={
                                                            theme.palette.text
                                                                .secondary
                                                        }
                                                    >
                                                        <CategoryIcon
                                                            category={option}
                                                            fontSize="small"
                                                        />
                                                        <Typography
                                                            style={{
                                                                marginLeft:
                                                                    theme.spacing(
                                                                        1
                                                                    ),
                                                            }}
                                                            variant="body2"
                                                        >
                                                            {option.name}
                                                        </Typography>
                                                    </Box>
                                                </MenuItem>
                                            )
                                        })}
                                    </MenuList>
                                </ClickAwayListener>
                            </Paper>
                        </Grow>
                    )}
                </Popper>
            </Grid>
        </Grid>
    )
}

export default CollectionToolbar
