import React, { useState } from "react"
import Box from "@material-ui/core/Box"
import { makeStyles, Theme } from "@material-ui/core/styles"
import { useMutation } from "@apollo/client"
import Button from "@material-ui/core/Button"
import TextField from "@material-ui/core/TextField"
import Dialog from "@material-ui/core/Dialog"
import DialogTitle from "@material-ui/core/DialogTitle"
import DialogContent from "@material-ui/core/DialogContent"
import DialogActions from "@material-ui/core/DialogActions"
import Switch from "@material-ui/core/Switch"
import useAwaitTranslation from "../../i18n/useAwaitTranslation"
import CircularProgress from "@material-ui/core/CircularProgress"
import {
    CREATE_THEME,
    ADD_DESIGN_THEME_CREATED_BY,
    ADD_USER_ACTIVE_DARK_THEME,
    ADD_USER_ACTIVE_LIGHT_THEME,
    REMOVE_USER_ACTIVE_DARK_THEME,
    REMOVE_USER_ACTIVE_LIGHT_THEME,
    USER_THEMES_QUERY,
    DELETE_DESIGN_THEME,
    UPDATE_DESIGN_THEME,
} from "./graphql"
import {
    CreateThemeMutation,
    CreateThemeMutationVariables,
    AddThemeCreatedByMutation,
    AddThemeCreatedByMutationVariables,
    AddActiveDarkThemeMutation,
    AddActiveDarkThemeMutationVariables,
    AddActiveLightThemeMutation,
    AddActiveLightThemeMutationVariables,
    RemoveActiveDarkThemeMutation,
    RemoveActiveDarkThemeMutationVariables,
    RemoveActiveLightThemeMutation,
    RemoveActiveLightThemeMutationVariables,
    DeleteDesignThemeMutation,
    DeleteDesignThemeMutationVariables,
    UpdateDesignThemeMutation,
    UpdateDesignThemeMutationVariables,
} from "./__generated__/graphql"
import { SketchPicker } from "react-color"
import Radio from "@material-ui/core/Radio"
import RadioGroup from "@material-ui/core/RadioGroup"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import { DesignTheme, User } from "../../__generated__/types"
import DeleteForever from "@material-ui/icons/DeleteForever"
const useStyles = makeStyles((theme: Theme) => ({
    color: {
        width: "100%",
        height: "2em",
    },
    root: {
        display: "flex",
        flexDirection: "column",
        padding: theme.spacing(1),
        [theme.breakpoints.up("sm")]: {
            height: "100%",
            overflow: "hidden",
        },
    },
    section: {
        [theme.breakpoints.down("sm")]: {
            flexDirection: "column",
        },
        height: "100%",
        [theme.breakpoints.up("sm")]: {
            flexDirection: "row",
            overflow: "hidden",
        },
    },
    swatch: {
        padding: "1px",
        background: "#fff",
        borderRadius: theme.shape.borderRadius,
        boxShadow: "0 0 0 1px rgba(0,0,0,.1)",
        display: "inline-block",
        cursor: "pointer",
        width: "100%",
    },
    popover: {
        position: "absolute",
        zIndex: 2,
    },
    cover: {
        position: "fixed",
        top: "0px",
        right: "0px",
        bottom: "0px",
        left: "0px",
    },
    label: {
        margin: theme.spacing(1, 0),
        color: "currentColor",
    },
    list: {
        width: "100%",
        overflowX: "hidden",
        overflowY: "auto",
        borderRadius: theme.shape.borderRadius,
    },
    fullWidth: {
        width: "100%",
    },
    listPaper: {
        margin: theme.spacing(1.5),
        marginTop: 0,
    },
    container: {
        height: "100%",
        borderRadius: theme.shape.borderRadius,
        border: "1px solid",
        display: "flex",
        flexDirection: "column",
        [theme.breakpoints.down("sm")]: {
            height: "600px",
        },
    },
    colorPreview: {
        width: "2em",
        height: "2em",
        borderRadius: theme.shape.borderRadius,
    },
    radioGroup: {
        padding: theme.spacing(2),
        flexDirection: "row",
    },
}))

interface ThemeFormProps {
    item?: DesignTheme
    mode: string
    user: User
    onClose: () => void
}
export default function ThemeForm(props: ThemeFormProps) {
    const classes = useStyles()
    const [confirm, setConfirm] = useState(false)
    const { t } = useAwaitTranslation("theme")
    const [data, setData] = useState({
        title: "",
        description: "",
        primary: "#fff",
        secondary: "#fff",
        darkMode: false,
    })
    const mode = props.mode.charAt(0)?.toUpperCase() + props.mode.slice(1)
    const currentUserActiveTheme =
        mode === "Light"
            ? props.user.activeLightTheme
            : props.user.activeDarkTheme
    React.useEffect(() => {
        if (props.item) {
            setData({
                title: props.item.title && props.item.title,
                description: props.item.description && props.item.description,
                primary: props.item.primary && props.item.primary,
                secondary: props.item.secondary && props.item.secondary,
                darkMode: props.item.darkMode ? true : false,
            })
        }
    }, [props.item])
    const [active, setActive] = useState(false)
    const [saving, setSaving] = useState(false)
    const [createTheme] = useMutation<
        CreateThemeMutation,
        CreateThemeMutationVariables
    >(CREATE_THEME, {
        refetchQueries: [
            {
                query: USER_THEMES_QUERY,
            },
        ],
    })
    const [addAuthor] = useMutation<
        AddThemeCreatedByMutation,
        AddThemeCreatedByMutationVariables
    >(ADD_DESIGN_THEME_CREATED_BY)
    const [deleteDesignTheme] = useMutation<
        DeleteDesignThemeMutation,
        DeleteDesignThemeMutationVariables
    >(DELETE_DESIGN_THEME, {
        update(cache) {
            cache.evict({
                id: cache.identify(props.item),
            })
        },
    })
    const [updateDesignTheme] = useMutation<
        UpdateDesignThemeMutation,
        UpdateDesignThemeMutationVariables
    >(UPDATE_DESIGN_THEME)
    const [addActiveDarkTheme] = useMutation<
        AddActiveDarkThemeMutation,
        AddActiveDarkThemeMutationVariables
    >(ADD_USER_ACTIVE_DARK_THEME)
    const [addActiveLightTheme] = useMutation<
        AddActiveLightThemeMutation,
        AddActiveLightThemeMutationVariables
    >(ADD_USER_ACTIVE_LIGHT_THEME)
    const [removeActiveDarkTheme] = useMutation<
        RemoveActiveDarkThemeMutation,
        RemoveActiveDarkThemeMutationVariables
    >(REMOVE_USER_ACTIVE_DARK_THEME)
    const [removeActiveLightTheme] = useMutation<
        RemoveActiveLightThemeMutation,
        RemoveActiveLightThemeMutationVariables
    >(REMOVE_USER_ACTIVE_LIGHT_THEME)

    const updateTheme = async () => {
        setSaving(true)
        await updateDesignTheme({
            variables: {
                ...data,
                themeId: props.item.themeId,
                darkMode: data.darkMode,
            },
        })

        props.onClose()
    }
    const deleteTheme = async () => {
        setSaving(true)
        await deleteDesignTheme({
            variables: { themeId: props.item.themeId },
        })

        props.onClose()
    }
    const createNewTheme = async () => {
        setSaving(true)
        const {
            data: { CreateDesignTheme: NewTheme },
        } = await createTheme({
            variables: {
                ...data,
                darkMode: data.darkMode,
            },
        })

        if (NewTheme) {
            addAuthor({
                variables: {
                    fromId: props.user.userId,
                    toId: NewTheme.themeId,
                },
            })
            if (!active) {
                props.onClose()
            } else if (active) {
                if (data.darkMode) {
                    if (currentUserActiveTheme) {
                        removeActiveDarkTheme({
                            variables: {
                                fromId: props.user.userId,
                                toId: currentUserActiveTheme.themeId,
                            },
                        })
                    }
                    await addActiveDarkTheme({
                        variables: {
                            fromId: props.user.userId,
                            toId: NewTheme.themeId,
                        },
                    })
                    props.onClose()
                } else {
                    if (currentUserActiveTheme) {
                        removeActiveLightTheme({
                            variables: {
                                fromId: props.user.userId,
                                toId: currentUserActiveTheme.themeId,
                            },
                        })
                    }
                    await addActiveLightTheme({
                        variables: {
                            fromId: props.user.userId,
                            toId: NewTheme.themeId,
                        },
                    })
                    props.onClose()
                }
            }
        }
    }
    const modeSelection = data.darkMode ? "Dark" : "Light"
    return (
        <>
            <Dialog open={true} fullWidth={!confirm}>
                {confirm ? (
                    <>
                        <DialogContent>
                            {t(
                                "confirmThemeDeletion",
                                "Are you sure you want to delete this theme"
                            )}
                            ?
                        </DialogContent>
                        <DialogActions>
                            <Button
                                disabled={saving}
                                onClick={() => {
                                    setConfirm(false)
                                }}
                            >
                                {t("no", "No")}
                            </Button>
                            <Button
                                disabled={saving}
                                variant="contained"
                                color="primary"
                                onClick={deleteTheme}
                            >
                                {saving ? (
                                    <CircularProgress size={25} disableShrink />
                                ) : (
                                    t("yes", "Yes")
                                )}
                            </Button>
                        </DialogActions>
                    </>
                ) : (
                    <>
                        <DialogTitle>
                            {props.item
                                ? t("editTheme", "Edit Theme")
                                : t("newThemeForm", "New Theme Form")}
                        </DialogTitle>
                        <DialogContent>
                            <Box padding={2}>
                                <TextField
                                    fullWidth
                                    onChange={(e) => {
                                        setData({
                                            ...data,
                                            title: e.target.value,
                                        })
                                    }}
                                    value={data.title}
                                    label={t("themeTitle", "Theme Title")}
                                    required
                                />
                            </Box>
                            <Box padding={2}>
                                <TextField
                                    fullWidth
                                    multiline
                                    value={data.description}
                                    label={t(
                                        "themeDescription",
                                        "Theme Description"
                                    )}
                                    onChange={(e) => {
                                        setData({
                                            ...data,
                                            description: e.target.value,
                                        })
                                    }}
                                />
                            </Box>
                            <Box padding={2}>
                                <Box className={classes.label}>
                                    {" "}
                                    {t("primary", "Primary")}
                                </Box>
                                <Box>
                                    <ColorPopper
                                        color={data.primary}
                                        handleChange={setData}
                                        type="primary"
                                        data={data}
                                    />
                                </Box>
                            </Box>
                            <Box padding={2}>
                                <Box className={classes.label}>
                                    {t("secondary", "Secondary")}
                                </Box>
                                <ColorPopper
                                    color={data.secondary}
                                    handleChange={setData}
                                    type="secondary"
                                    data={data}
                                />
                            </Box>
                            {!props.item && (
                                <RadioGroup
                                    className={classes.radioGroup}
                                    name="darkMode"
                                    value={data.darkMode ? "true" : "false"}
                                    onChange={(
                                        event: React.ChangeEvent<HTMLInputElement>
                                    ) => {
                                        setData({
                                            ...data,
                                            darkMode:
                                                event.target.value === "true"
                                                    ? true
                                                    : false,
                                        })
                                    }}
                                >
                                    <FormControlLabel
                                        value={"false"}
                                        control={<Radio />}
                                        label={t("light", "Light")}
                                    />
                                    <FormControlLabel
                                        value={"true"}
                                        control={<Radio />}
                                        label={t("dark", "Dark")}
                                    />
                                </RadioGroup>
                            )}
                            {!props.item && (
                                <Box padding={2}>
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                checked={active}
                                                onChange={(e) => {
                                                    setActive(e.target.checked)
                                                }}
                                            />
                                        }
                                        label={
                                            t("setAsActive", "Set as Active ") +
                                            modeSelection +
                                            t("theme", " Theme")
                                        }
                                    />
                                </Box>
                            )}
                        </DialogContent>
                        <DialogActions>
                            <Box
                                display="flex"
                                width="100%"
                                justifyContent={
                                    props.item ? "space-between" : "flex-end"
                                }
                            >
                                {props.item && (
                                    <Button
                                        onClick={() => {
                                            setConfirm(true)
                                        }}
                                        startIcon={
                                            <DeleteForever color="error" />
                                        }
                                        disabled={saving}
                                    >
                                        {t("deleteTheme", "Delete Theme")}
                                    </Button>
                                )}
                                <Box>
                                    <Button
                                        onClick={() => {
                                            props.onClose()
                                        }}
                                        disabled={saving}
                                    >
                                        {t("discard", "Discard")}
                                    </Button>
                                    <Button
                                        disabled={
                                            data.title.length === 0 || saving
                                        }
                                        onClick={() => {
                                            props.item
                                                ? updateTheme()
                                                : createNewTheme()
                                        }}
                                        color="primary"
                                    >
                                        {saving ? (
                                            <CircularProgress
                                                size={25}
                                                disableShrink
                                            />
                                        ) : (
                                            t("save", "Save")
                                        )}
                                    </Button>
                                </Box>
                            </Box>
                        </DialogActions>
                    </>
                )}
            </Dialog>
        </>
    )
}

interface ColorPopperProps {
    color: string
    handleChange: Function
    type: string
    data: any
}

const ColorPopper = (props: ColorPopperProps) => {
    const classes = useStyles()
    const [display, setDisplay] = useState(false)
    const handleClick = () => {
        setDisplay(!display)
    }
    const handleClose = () => {
        setDisplay(false)
    }

    return (
        <Box width="100%">
            <Box className={classes.swatch} onClick={handleClick}>
                <Box
                    className={classes.color}
                    style={{ background: props.color }}
                />
            </Box>
            {display ? (
                <Box className={classes.popover}>
                    <Box className={classes.cover} onClick={handleClose} />
                    <SketchPicker
                        color={props.color}
                        onChangeComplete={(color) => {
                            if (props.type === "primary") {
                                props.handleChange({
                                    ...props.data,
                                    primary: color.hex,
                                })
                            } else if (props.type === "secondary") {
                                props.handleChange({
                                    ...props.data,
                                    secondary: color.hex,
                                })
                            }
                        }}
                    />
                </Box>
            ) : null}
        </Box>
    )
}
