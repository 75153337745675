import React, { useState, useEffect, useRef } from "react"
import Box from "@material-ui/core/Box"
import { useMutation } from "@apollo/client"
import { makeStyles, useTheme, Theme } from "@material-ui/core/styles"
import { useHistory } from "react-router-dom"
import { useAuth } from "../../providers/AuthProvider"
import Divider from "@material-ui/core/Divider"
import Menu from "@material-ui/core/Menu"
import MenuItem from "@material-ui/core/MenuItem"
import Checkbox from "@material-ui/core/Checkbox"
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward"
import DeleteIcon from "@material-ui/icons/Delete"
import useMediaQuery from "@material-ui/core/useMediaQuery"
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord"
import { useGlobalStyles } from "../../styles/globalStyles"
import {
    DELETE_CHECKLIST_ITEM,
    UPDATE_CHECKLIST_ITEM,
} from "../../graphql/Checklist"
import {
    DeleteChecklistItemMutation,
    DeleteChecklistItemMutationVariables,
    UpdateChecklistItemMutation,
    UpdateChecklistItemMutationVariables,
} from "../../graphql/__generated__/Checklist"
import Typography from "@material-ui/core/Typography"
import { ChecklistItem, ItemPrefixType } from "../../__generated__/types"
import useAwaitTranslation from "../../i18n/useAwaitTranslation"
import {
    Grow,
    IconButton,
    ListItemIcon,
    ListItemText,
    ListItem,
    TextField,
    Tooltip,
    ListItemAvatar,
    ClickAwayListener,
} from "@material-ui/core"
import NotesIcon from "@material-ui/icons/Notes"
import MoreVertIcon from "@material-ui/icons/MoreVert"
import { DraggableProvidedDragHandleProps } from "react-beautiful-dnd"
import DragIndicatorIcon from "@material-ui/icons/DragIndicator"
import ExitToAppIcon from "@material-ui/icons/ExitToApp"
import ClearIcon from "@material-ui/icons/Clear"
import CategoryLabel from "../CategoryLabel"
import ConceptAvatar from "../ConceptAvatar"
const useStyles = makeStyles((theme: Theme) => ({
    root: {
        [theme.breakpoints.down("sm")]: {
            alignItems: "center",
            flexDirection: "column-reverse",
        },
        padding: 0,
        "&:hover": {
            backgroundColor: theme.palette.action.hover,
        },
    },
    summary: {
        fontSize: theme.typography.body2.fontSize,
        color: theme.palette.text.secondary,
    },
    avatar: {
        minWidth: "60px",
        minHeight: "60px",
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.text.primary,
    },

    typeContainer: {
        color: theme.palette.text.secondary,
    },

    clickableNotesContainer: {
        "&:hover": {
            backgroundColor: theme.palette.action.hover,
        },
        cursor: "pointer",
    },
    label: {
        fontSize: 12,
        color: theme.palette.text.secondary,
    },
    textField: {
        fontSize: theme.typography.body2.fontSize,
    },
}))
interface ChecklistConceptItemProps {
    item: ChecklistItem
    index: number
    editPermission: boolean
    dragHandleProps?: DraggableProvidedDragHandleProps
    clone?: boolean
    move?: Function
    removeConcept?: Function
    showComplete?: boolean
    prefix: ItemPrefixType | null
    innerRef?: any
    onNextInput?: Function
    draggable?: boolean
}
const initialState = {
    mouseX: null,
    mouseY: null,
}
export const ChecklistConceptItem = (props: ChecklistConceptItemProps) => {
    const classes = useStyles()
    const globalClasses = useGlobalStyles()
    const theme = useTheme()
    const inputRef = useRef(null)
    const mobile = useMediaQuery(theme.breakpoints.down("sm"))
    const history = useHistory()
    const { currentUser } = useAuth()

    const [editing, setEditing] = useState(false)
    const [hovered, setHovered] = useState(false)
    const { t } = useAwaitTranslation("checklist")
    const [saveLine] = useMutation<
        UpdateChecklistItemMutation,
        UpdateChecklistItemMutationVariables
    >(UPDATE_CHECKLIST_ITEM)
    const [deleteLine] = useMutation<
        DeleteChecklistItemMutation,
        DeleteChecklistItemMutationVariables
    >(DELETE_CHECKLIST_ITEM, {
        update(cache) {
            cache.evict({
                id: cache.identify(props.item),
            })
        },
    })
    const [checked, setChecked] = useState(true)

    const [value, setValue] = useState(null)
    const [anchorEl, setAnchorEl] = React.useState<{
        mouseX: null | number
        mouseY: null | number
    }>(initialState)
    const handleClick = (event) => {
        event.preventDefault()
        event.stopPropagation()
        setAnchorEl({
            mouseX: event.clientX,
            mouseY: event.clientY,
        })
    }
    const handleClose = () => {
        setAnchorEl(initialState)
    }
    useEffect(() => {
        if (!!props.item) {
            setValue(props.item.title)
            setChecked(props.item.checked)
        }
    }, [props.item])
    useEffect(() => {
        if (inputRef.current && editing) {
            inputRef.current.focus()
        }
    }, [editing])
    const handleSave = () => {
        if (value !== props.item.title) {
            saveLine({
                variables: {
                    userId: currentUser.userId,
                    checklistItem: {
                        id: props.item.id,
                        title: value,
                    },
                },
            })
        }
        setEditing(false)
    }
    const handleClear = async (e) => {
        await saveLine({
            variables: {
                userId: currentUser.userId,
                checklistItem: {
                    id: props.item.id,
                    title: "",
                },
            },
        })
        setHovered(false)
        setEditing(false)
    }
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setChecked(event.target.checked)
        saveLine({
            variables: {
                userId: currentUser.userId,
                checklistItem: {
                    id: props.item.id,
                    checked: event.target.checked,
                },
            },
        })
    }
    const handleDeletion = () => {
        deleteLine({ variables: { id: props.item.id } })
    }

    if (props.showComplete === false && props.item.checked) {
        return null
    }

    return (
        <>
            <ListItem
                style={{
                    backgroundColor: props.clone
                        ? theme.palette.divider
                        : "default",
                }}
                disableGutters
                className={classes.root}
                onMouseEnter={() => setHovered(true)}
                onMouseLeave={() => setHovered(false)}
                key={props.item.id}
            >
                <Box display="flex" flexGrow={1} alignItems="stretch">
                    {props.prefix === "BULLET" ? (
                        <Box
                            height="100%"
                            display="flex"
                            alignItems="flex-start"
                            pt={3.5}
                            pr={1}
                            pl={1}
                        >
                            <FiberManualRecordIcon
                                style={{ width: "0.35em", height: "0.35em" }}
                            />
                        </Box>
                    ) : props.prefix === "NUMBER" ? (
                        <Box
                            minHeight="100%"
                            style={{ backdropFilter: "contrast(0.9)" }}
                        >
                            <Box p={1} display="flex" alignItems="center">
                                {props.index + 1}.
                            </Box>
                        </Box>
                    ) : props.prefix === "CHECKBOX" ? (
                        <Box
                            display="flex"
                            minHeight="100%"
                            style={{ backdropFilter: "contrast(0.9)" }}
                        >
                            <Checkbox
                                checked={checked}
                                color="primary"
                                disabled={!props.editPermission}
                                onChange={handleChange}
                                inputProps={{
                                    "aria-label": "primary checkbox",
                                }}
                                style={{
                                    alignItems: "flex-start",
                                    height: "fit-content",
                                    padding: theme.spacing(0.75),
                                }}
                            />
                        </Box>
                    ) : null}
                    <Box display="flex" flexDirection="column" width="100%">
                        <Box display="flex" width="100%">
                            <Box
                                padding={1}
                                pl={1.5}
                                display="flex"
                                alignItems="center"
                            >
                                <ListItemAvatar>
                                    <ConceptAvatar
                                        concept={props.item?.concept}
                                        variant="rounded"
                                    />
                                </ListItemAvatar>
                            </Box>

                            <Box width="100%" p={0.5}>
                                {props.item?.concept && (
                                    <Box p={0.5}>
                                        <CategoryLabel
                                            conceptCategoryId={
                                                props.item.concept?.category?.id
                                            }
                                            size="small"
                                        />
                                        <Box display="flex">
                                            <Box>
                                                <Typography variant="body1">
                                                    {props.item.concept.title}
                                                </Typography>
                                            </Box>
                                        </Box>
                                        {props.item.concept.summary && (
                                            <Box className={classes.summary}>
                                                {props.item.concept.summary}
                                            </Box>
                                        )}
                                    </Box>
                                )}
                            </Box>
                            <ListItemIcon
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                }}
                            >
                                {props.draggable ? (
                                    <Box {...props.dragHandleProps}>
                                        <DragIndicatorIcon color="action" />
                                    </Box>
                                ) : (
                                    <Grow in={hovered || !!mobile}>
                                        <IconButton
                                            onClick={handleClick}
                                            size="small"
                                        >
                                            <MoreVertIcon />
                                        </IconButton>
                                    </Grow>
                                )}
                            </ListItemIcon>
                        </Box>
                        {(editing || value?.length > 0) &&
                            (editing && !!props.editPermission ? (
                                <Box p={1.5} minHeight={theme.spacing(8)}>
                                    <ClickAwayListener
                                        onClickAway={() => {
                                            handleSave()
                                        }}
                                    >
                                        <TextField
                                            value={value}
                                            label={t("notes", "Notes")}
                                            inputRef={inputRef}
                                            fullWidth
                                            onFocus={() => {
                                                if (
                                                    inputRef &&
                                                    inputRef.current &&
                                                    editing
                                                ) {
                                                    inputRef.current.setSelectionRange(
                                                        inputRef.current.value
                                                            .length,
                                                        inputRef.current.value
                                                            .length
                                                    )
                                                }
                                            }}
                                            multiline
                                            onKeyDown={(e) => {
                                                if (e.key === "Enter") {
                                                    handleSave()
                                                }
                                            }}
                                            name="Notes"
                                            onChange={(e) => {
                                                setValue(e.currentTarget.value)
                                            }}
                                            InputProps={{
                                                classes: {
                                                    input: classes.textField,
                                                },
                                                endAdornment: (
                                                    <React.Fragment>
                                                        <Tooltip
                                                            title={t(
                                                                "removeNotes",
                                                                "Remove notes"
                                                            )}
                                                        >
                                                            <IconButton
                                                                size="small"
                                                                onClick={
                                                                    handleClear
                                                                }
                                                            >
                                                                <ClearIcon fontSize="small" />
                                                            </IconButton>
                                                        </Tooltip>
                                                    </React.Fragment>
                                                ),
                                            }}
                                        />
                                    </ClickAwayListener>
                                </Box>
                            ) : (
                                <Box
                                    className={
                                        props.editPermission
                                            ? classes.clickableNotesContainer
                                            : ""
                                    }
                                    onClick={() => {
                                        if (!!props.editPermission) {
                                            setEditing(true)
                                        }
                                    }}
                                    p={1.5}
                                    minHeight={theme.spacing(8)}
                                >
                                    <Box className={classes.label}>Notes</Box>
                                    <Box>{value}</Box>
                                </Box>
                            ))}
                    </Box>
                </Box>

                <Menu
                    keepMounted
                    open={anchorEl.mouseY !== null}
                    onClose={handleClose}
                    anchorReference="anchorPosition"
                    anchorPosition={
                        anchorEl.mouseY !== null && anchorEl.mouseX !== null
                            ? {
                                  top: anchorEl.mouseY,
                                  left: anchorEl.mouseX,
                              }
                            : undefined
                    }
                >
                    {!value && (
                        <MenuItem
                            component="li"
                            onClick={(event) => {
                                setEditing(true)
                                handleClose()
                            }}
                            key={props.item + "notes"}
                        >
                            <ListItemIcon
                                className={globalClasses.smallMenuIcon}
                            >
                                <NotesIcon fontSize="small" />
                            </ListItemIcon>
                            <ListItemText
                                primaryTypographyProps={{
                                    className:
                                        globalClasses.smallMenuListItemText,
                                }}
                                primary="Add Notes"
                            />
                        </MenuItem>
                    )}
                    <MenuItem
                        component="li"
                        key={props.item + "exit"}
                        onClick={(event) => {
                            history.push(
                                `/concept/${props.item.concept?.id}/home`
                            )
                        }}
                    >
                        <ListItemIcon className={globalClasses.smallMenuIcon}>
                            <ExitToAppIcon fontSize="small" />
                        </ListItemIcon>
                        <ListItemText
                            primaryTypographyProps={{
                                className: globalClasses.smallMenuListItemText,
                            }}
                            primary="View"
                        />
                    </MenuItem>
                    {props.editPermission && (
                        <Box>
                            <MenuItem
                                component="li"
                                key={props.item + "move"}
                                onClick={() => {
                                    props.move(props.index, 0)
                                    handleClose()
                                }}
                            >
                                <ListItemIcon
                                    className={globalClasses.smallMenuIcon}
                                >
                                    <ArrowUpwardIcon fontSize="small" />{" "}
                                </ListItemIcon>
                                <ListItemText
                                    primaryTypographyProps={{
                                        className:
                                            globalClasses.smallMenuListItemText,
                                    }}
                                    primary={t("moveToTop", "Move to top")}
                                />
                            </MenuItem>
                            <MenuItem
                                key={props.item + "delete"}
                                onClick={() => {
                                    handleDeletion()
                                    handleClose()
                                }}
                            >
                                <ListItemIcon
                                    className={globalClasses.smallMenuIcon}
                                >
                                    <DeleteIcon fontSize="small" />
                                </ListItemIcon>
                                <ListItemText
                                    primaryTypographyProps={{
                                        className:
                                            globalClasses.smallMenuListItemText,
                                    }}
                                    primary="Remove"
                                />
                            </MenuItem>
                        </Box>
                    )}
                </Menu>
            </ListItem>

            <Divider />
        </>
    )
}
