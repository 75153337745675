import { makeStyles, Theme } from "@material-ui/core/styles"
import { Box } from "@material-ui/core"
import { Concept } from "../../../__generated__/types"

import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd"

import RankingItem from "./RankingItem"
import { useEffect, useState } from "react"
import { useMutation } from "@apollo/client"
import { UPDATE_CRITERIA_SCORE } from "../../criteria/graphql"
import {
    UpdateCriteriaScoreMutation,
    UpdateCriteriaScoreMutationVariables,
} from "../../criteria/__generated__/graphql"

import { reorderArray } from "../../../util/fns"
import { RankingInterfaceProps } from "../../criteria/useCriteriaTypes"

function shuffle(sourceArray) {
    for (var i = 0; i < sourceArray.length - 1; i++) {
        var j = i + Math.floor(Math.random() * (sourceArray.length - i))
        var temp = sourceArray[j]
        sourceArray[j] = sourceArray[i]
        sourceArray[i] = temp
    }
    return sourceArray
}

const useStyles = makeStyles((theme: Theme) => ({
    label: {
        color: theme.palette.text.hint,
        fontSize: 12,
    },
    root: {
        overflow: "hidden",
        display: "flex",
        flexDirection: "column",
        flexGrow: 1,
        height: "100%",
    },
    list: {
        overflowY: "auto",
        border: `1px solid ${theme.palette.divider}`,
        borderRadius: theme.shape.borderRadius,
        padding: 0,
        paddingLeft: theme.spacing(1),
    },
    exerciseContainer: {
        display: "flex",
        height: "100%",
        [theme.breakpoints.down("sm")]: {
            flexDirection: "column-reverse",
        },
        [theme.breakpoints.up("sm")]: {
            flexDirection: "row",
        },
        overflow: "hidden",
    },
    section: {
        height: "100%",
    },
    unrankedConceptsContainer: {
        [theme.breakpoints.down("sm")]: {
            overflowY: "hidden",
            overflowX: "auto",
        },
        [theme.breakpoints.up("sm")]: {
            overflowX: "hidden",
            overflowY: "auto",
            flexWrap: "wrap",
        },
        display: "flex",
    },
    clone: {
        zIndex: 100,
        backgroundColor: theme.palette.background.paper,
        border: `1px solid ${theme.palette.divider}`,
        borderRadius: theme.shape.borderRadius,
    },
    rankedListContainer: {
        [theme.breakpoints.down("sm")]: {
            overflow: "hidden",
        },
        display: "flex",
        flexDirection: "column",
        flexGrow: 1,
    },
    previewAvatar: {
        [theme.breakpoints.down("sm")]: {
            minWidth: "150px",
            minHeight: "150px",
        },
        minWidth: "200px",
        minHeight: "200px",
        margin: theme.spacing(1),
    },
    sortableContainer: {
        overflowY: "auto",
    },
    toolbar: {
        //@ts-ignore
        backgroundColor: theme.palette.background.level2,
    },
}))

type RankedList = {
    concepts: Concept[]
    conceptIds: string[]
}

const RankingInterface: React.FunctionComponent<RankingInterfaceProps> = ({
    score,
    conceptsToScore: conceptsToRank,
}) => {
    const [updateScore] = useMutation<
        UpdateCriteriaScoreMutation,
        UpdateCriteriaScoreMutationVariables
    >(UPDATE_CRITERIA_SCORE)
    const classes = useStyles()

    const onUpdateScore = (list: RankedList) => {
        let data = {
            id: score.id,
            response: JSON.stringify(list.conceptIds),
        }
        updateScore({
            variables: {
                ...data,
            },
        })
    }

    //state
    const [conceptList, setConceptList] = useState<RankedList | null>(null)
    //useEffects
    useEffect(() => {
        if (conceptsToRank) {
            setConceptList({
                conceptIds: score?.response
                    ? JSON.parse(score?.response)
                    : [...conceptsToRank?.map((item) => item.id)],
                concepts: !score?.response
                    ? shuffle([...conceptsToRank])
                    : conceptsToRank,
            })
        }
    }, [score, conceptsToRank])

    //variables
    let orderedConcepts = []
    let unorderedConcepts = []
    let conceptsById = {}
    const ids =
        conceptList?.conceptIds || conceptList?.concepts.map((c) => c.id) || []
    conceptList?.concepts.forEach((c) => {
        conceptsById[c.id] = c
        let conceptIdx = ids.indexOf(c.id)
        if (conceptIdx === -1) {
            unorderedConcepts.push(c)
        } else {
            orderedConcepts[ids.indexOf(c.id)] = c
        }
    })

    let allConcepts = [...orderedConcepts, ...unorderedConcepts].filter(
        (c) => !!c
    )

    //functions

    const setListData = (ConceptList) => {
        let orderedConcepts = []
        let unorderedConcepts = []
        let conceptsById = {}
        const { concepts, conceptIds } = ConceptList
        const ids = conceptIds || concepts.map((c) => c.id)
        concepts.forEach((c) => {
            conceptsById[c.id] = c
            let conceptIdx = ids.indexOf(c.id)
            if (conceptIdx === -1) {
                unorderedConcepts.push(c)
            } else {
                orderedConcepts[ids.indexOf(c.id)] = c
            }
        })
        let allConcepts = [...orderedConcepts, ...unorderedConcepts].filter(
            (c) => !!c
        )
        onUpdateScore({
            ...ConceptList,
            concepts: allConcepts,
        })
        setConceptList({
            ...ConceptList,
            concepts: allConcepts,
        })
    }
    const removeConcept = (sourceIndex, draggableId) => {
        let position = sourceIndex
        let newArray = Array.from(allConcepts)
        newArray.splice(position, 1)
        const newList = {
            conceptIds: newArray.map((c) => c.id),
            concepts: newArray,
        }
        setListData({
            ...conceptList,
            ...newList,
        })
    }

    const onDragEnd = async (result) => {
        if (!result.destination) {
            return removeConcept(result.source.index, result.draggableId)
        }
        const conceptArray = reorderArray(
            allConcepts,
            result.source.index,
            result.destination.index
        )

        setListData({
            ...conceptList,
            conceptIds: conceptArray.map((c: Concept) => c.id),
        })
    }
    if (!conceptList) {
        return null
    }
    return (
        <Box
            display="flex"
            overflow="hidden"
            flexDirection="column"
            height="100%"
        >
            <Box flexGrow={1} overflow="auto">
                <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable
                        droppableId={score?.id ?? "no_score_droppable"}
                        renderClone={(provided, snapshot, rubric) => (
                            <div
                                className={classes.clone}
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                            >
                                <RankingItem
                                    concept={allConcepts[rubric.source.index]}
                                    showPosition={true}
                                    position={rubric.source.index + 1}
                                    draggable={true}
                                />
                            </div>
                        )}
                    >
                        {(provided, snapshot) => (
                            <div
                                ref={provided.innerRef}
                                {...provided.droppableProps}
                            >
                                {allConcepts.map((concept, index) => (
                                    <Draggable
                                        key={concept.id}
                                        draggableId={concept.id}
                                        index={index}
                                    >
                                        {(provided, snapshot) => (
                                            <div
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                                key={concept.id + index}
                                            >
                                                <RankingItem
                                                    concept={concept}
                                                    showPosition={true}
                                                    position={index + 1}
                                                    draggable={true}
                                                />
                                            </div>
                                        )}
                                    </Draggable>
                                ))}
                                {provided.placeholder}
                            </div>
                        )}
                    </Droppable>
                </DragDropContext>
            </Box>
        </Box>
    )
}

export default RankingInterface
