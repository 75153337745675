import React, { useRef, useState } from "react"
import { Box, Paper, Typography } from "@material-ui/core"
import WidgetHeader from "./common/WidgetHeader"
import useWidget from "../useWidget"
import { WidgetTypeComponent } from "../useWidgetTypes"

import { useInputTools } from "../../inputs/useInputTools"
import InputWrapper from "../../inputs/InputWrapper"
import { CriteriaType } from "../../criteria/types"
import BaseInputInterface from "../../inputs/BaseInputInterface"
import BaseInputResponseViewer from "../../inputs/BaseInputResponseViewer"
import { useReactiveVar } from "@apollo/client"
import { expandWidgetVar } from "../../../providers/GlobalState"
import { useParams } from "react-router-dom"
type WidgetConfig = {
    criteriaId: string
    inputConfig: any
}

const CriteriaWidget: WidgetTypeComponent = (props) => {
    const { conceptId } = useParams()
    //hooks
    const { config, isInitialized } = useWidget<WidgetConfig>(props.widget.id)

    const expandedWidgetId =
        useReactiveVar(expandWidgetVar)?.[conceptId] ?? null
    const [hovered, setHovered] = useState(false)
    //refs
    const currentConfig = useRef(config)
    currentConfig.current = config

    const {
        input,
        responseStatusMessageInterface,
        showInterface,
        showLoading,
        cleanedInputConfig,
        interactionTools,
        conceptsToScore,
        conceptsScored,
        onCreateNewResponse,
        criteria,
        scores,
        fieldCompleted,
        sourceField,
        collectionCount,
    } = useInputTools({
        criteriaId: currentConfig.current?.criteriaId,
        inputConfig: currentConfig?.current?.inputConfig,
        conceptCategoryId: props.concept?.category?.id,
        isDialog: props.isDialog,
    })
    if (expandedWidgetId === props.widget.id && !props.isDialog) {
        return null
    }
    return (
        <Box
            component={!props.isDialog ? Paper : "div"}
            display="flex"
            height="100%"
            width="100%"
            flexDirection="column"
            key={props.widget.id}
            position="relative"
            id={input?.id}
            onMouseOver={() => {
                if (!props.isDialog && !hovered && !!input) {
                    setHovered(true)
                }
            }}
            onMouseLeave={() => {
                if (!props.isDialog && !!hovered && !!input) {
                    setHovered(false)
                }
            }}
        >
            <WidgetHeader
                hovered={hovered || !!props.isDialog}
                widget={props.widget}
                editing={props.editing}
                inputConfig={cleanedInputConfig}
                input={input}
                fieldCompleted={fieldCompleted}
                inputWidget={true}
                sourceField={sourceField}
                interactionTools={interactionTools}
                collectionCount={collectionCount}
                onWidgetDeletion={props.onWidgetDeletion}
            />
            <InputWrapper
                message={responseStatusMessageInterface}
                loading={!isInitialized || !!showLoading}
            >
                {!!criteria ? (
                    <Box
                        display="flex"
                        height="100%"
                        width="100%"
                        flexDirection="column"
                        overflow="hidden"
                    >
                        {!!showInterface && (
                            <BaseInputInterface
                                key={JSON.stringify(
                                    !!responseStatusMessageInterface
                                )}
                                //only if sub criteria
                                conceptsToScore={conceptsToScore}
                                //should be one score if not sub criteria
                                scores={scores}
                                onCreateNewResponse={onCreateNewResponse}
                                inputId={input?.id}
                                criteriaType={
                                    criteria?.criteriaType as CriteriaType
                                }
                                config={cleanedInputConfig}
                                isDialog={props.isDialog}
                                editingConfig={props.editing}
                            />
                        )}
                        {!!input &&
                            !showInterface &&
                            !responseStatusMessageInterface && (
                                <BaseInputResponseViewer
                                    fieldId={criteria?.id}
                                    inputId={input?.id}
                                    criteriaType={
                                        criteria?.criteriaType as CriteriaType
                                    }
                                    config={cleanedInputConfig}
                                    scores={scores || []}
                                    onCreateNewResponse={onCreateNewResponse}
                                    conceptsScored={conceptsScored}
                                    editing={props.editing}
                                />
                            )}
                    </Box>
                ) : (
                    <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        p={1}
                    >
                        <Typography variant="body2" color="textSecondary">
                            This criteria has been deleted...
                        </Typography>
                    </Box>
                )}
            </InputWrapper>
        </Box>
    )
}

export default CriteriaWidget
