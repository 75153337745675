import React, { useState } from "react"
import { Box, Divider } from "@material-ui/core"
import Button from "@material-ui/core/Button"
import CircularProgress from "@material-ui/core/CircularProgress"
import Link from "@material-ui/core/Link"
import TextField from "@material-ui/core/TextField"
import Typography from "@material-ui/core/Typography"
import { ReactComponent as GoogleIcon } from "../../../styles/icons/google-logo.svg"
import { ReactComponent as MicrosoftIcon } from "../../../styles/icons/microsoft-logo.svg"
import { useHistory, useLocation } from "react-router-dom"
import useAwaitTranslation from "../../../i18n/useAwaitTranslation"
import { useAuth } from "../../../providers/AuthProvider"
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles"
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        form: {
            width: "100%",
            marginTop: theme.spacing(1),
        },
        submit: {
            margin: theme.spacing(3, 0, 2),
        },
        providerLogin: {
            backgroundColor: theme.palette.background.paper,
        },
        customError: {
            color: "red",
            fontSize: "0.8rem",
            marginTop: 10,
        },
        progress: {
            position: "absolute",
        },
        divider: {
            flexGrow: 1,
        },
    })
)
type ErrorObject = {
    general?: string
}

const BaseLogin: React.FC<{
    hideSignUpOption?: boolean
}> = ({ hideSignUpOption }) => {
    const classes = useStyles()
    const location = useLocation()
    const queryParams = new URLSearchParams(location.search)
    const continueUrl = queryParams.get("continueUrl")
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const [errors, setErrors] = useState<ErrorObject>({})
    const [loading, setLoading] = useState(false)
    const history = useHistory()
    const { t } = useAwaitTranslation("login")
    const { login, loginGoogle, loginMicrosoft } = useAuth()
    const handleSubmit = async (e) => {
        e.preventDefault()
        setErrors({})
        setLoading(true)
        await login(email, password).catch((e) => {
            setErrors({ general: e.message })
            setLoading(false)
        })
    }
    return (
        <form className={classes.form} noValidate>
            <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="email"
                label={t("email", "Email")}
                name="email"
                autoComplete="email"
                autoFocus
                disabled={loading}
                onChange={(e) => setEmail(e.target.value)}
            />
            <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="password"
                label={t("password", "Password")}
                type="password"
                id="password"
                autoComplete="current-password"
                disabled={loading}
                onChange={(e) => setPassword(e.target.value)}
            />
            <Box textAlign="end">
                <Link
                    href="manage-account"
                    onClick={(e) => {
                        e.preventDefault()
                        history.push("/manage-account")
                    }}
                    variant="body2"
                >
                    {t("forgotPassword", "Forgot Password?")}
                </Link>
            </Box>
            <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
                onClick={handleSubmit}
                disabled={loading || !email || !password}
            >
                {t("signIn", "Sign In")}
                {loading && (
                    <CircularProgress
                        disableShrink
                        size={30}
                        className={classes.progress}
                    />
                )}
            </Button>
            <Box display="flex" width="100%" alignItems="center" py={2}>
                <Divider className={classes.divider} />
                <Box m={1}>OR</Box>
                <Divider className={classes.divider} />
            </Box>

            <Box display="flex" justifyContent="center" m={1}>
                <Button
                    className={classes.providerLogin}
                    onClick={loginGoogle}
                    disabled={loading}
                    startIcon={<GoogleIcon />}
                >
                    {t("signIn", "Sign In")} with Google
                </Button>
            </Box>
            <Box display="flex" justifyContent="center" m={1}>
                <Button
                    className={classes.providerLogin}
                    onClick={loginMicrosoft}
                    disabled={loading}
                    startIcon={<MicrosoftIcon />}
                >
                    {t("signIn", "Sign In")} with Microsoft
                </Button>
            </Box>
            {!hideSignUpOption && (
                <Box display="flex" flexDirection="column" py={2}>
                    <Box my={1} textAlign="center">
                        <Link
                            onClick={() =>
                                history.push(
                                    continueUrl
                                        ? `/signup-options?continueUrl=${continueUrl}`
                                        : "/signup-options"
                                )
                            }
                            variant="body2"
                            component="button"
                            disabled={!!loading}
                        >
                            {t(
                                "signUpMessage",
                                "Don't have an account? Sign Up"
                            )}
                        </Link>
                    </Box>
                </Box>
            )}
            {errors.general && (
                <Typography variant="body2" className={classes.customError}>
                    {errors.general}
                </Typography>
            )}
        </form>
    )
}

export default BaseLogin
