import { Avatar } from "@material-ui/core"
import { useTheme } from "@material-ui/core/styles"
import { Concept } from "../__generated__/types"
import CategoryIcon from "./categories/CategoryIcon"

const ConceptAvatar = (props: {
    concept: Concept
    size?: "small" | "medium" | "large" | "extra-large"
    variant: "circle" | "rounded"
}) => {
    const { concept, size, variant } = props
    const theme = useTheme()

    let avatarStyles = {}
    let iconStyles = {}
    if (size === "small") {
        avatarStyles = {
            width: theme.spacing(3),
            height: theme.spacing(3),
        }
        iconStyles = {
            fontSize: "0.9em",
        }
    } else if (size === "medium") {
        avatarStyles = {
            width: theme.spacing(4),
            height: theme.spacing(4),
        }
        iconStyles = {
            fontSize: "1em",
        }
    } else if (size === "extra-large") {
        avatarStyles = {
            width: theme.spacing(8),
            height: theme.spacing(8),
        }
        iconStyles = {
            fontSize: "2em",
        }
    }
    if (!concept) {
        return null
    }
    return (
        <Avatar
            style={{
                color: theme.palette.text.secondary,
                ...avatarStyles,
            }}
            variant={variant || "rounded"}
            src={concept.imageUrl}
        >
            <CategoryIcon
                styles={{ ...iconStyles }}
                category={concept.category}
            />
        </Avatar>
    )
}

export default ConceptAvatar
