import {
    Box,
    List,
    ListItem,
    ListItemText,
    IconButton,
    Collapse,
    ListItemIcon,
    Typography,
    Tooltip,
} from "@material-ui/core"
import { useTheme, alpha } from "@material-ui/core/styles"
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown"
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp"

import { CriteriaType } from "../criteria/types"
import React, { useState } from "react"
import { useReactiveVar } from "@apollo/client"

import { Criteria } from "../../__generated__/types"
import { useParams } from "react-router-dom"

import {
    currentWorkspaceWidgetsVar,
    myCurrentInputResponseVar,
} from "../../providers/GlobalState"
import {
    CollectionType,
    CRITERIA_TYPE_ICONS,
} from "../criteria/useCriteriaTypes"
import InputConfigForm from "../inputs/InputConfigForm"
import useCategoryTools from "../categories/useCategoryTools"
import Check from "@material-ui/icons/Check"
import CollectionCategorySubList from "../criteria/CollectionCategorySubList"
import FieldAutocomplete from "../criteria/FieldAutocomplete"
import { getInputId } from "../inputs/util"

const FieldRowOption = (props: {
    criteria: Criteria
    onAddExistingSubfield: (field: Criteria) => Promise<void>
    onCreateNewSubfield: () => void
    onAddFieldToWorkspace: (
        criteria: Criteria,
        parent?: Criteria,
        config?: CollectionType
    ) => void
}) => {
    const {
        criteria,
        onAddFieldToWorkspace,
        onAddExistingSubfield,
        onCreateNewSubfield,
    } = props
    const { conceptId } = useParams()
    const currentResponsesVar = useReactiveVar(myCurrentInputResponseVar)
    const currentWorkspaceWidgets = useReactiveVar(currentWorkspaceWidgetsVar)

    //only top level criteria here
    const topLevelFieldAdded = currentResponsesVar?.currentResponseData.find(
        (item) =>
            item.input.criteria?.id === criteria?.id &&
            !(
                JSON.parse(item.input?.inputConfig ?? "{}")?.source?.criteriaIds
                    ?.length > 0
            ) &&
            currentWorkspaceWidgets.conceptId === conceptId &&
            !!currentWorkspaceWidgets.orderedWidgets.find(
                (widgetItem) =>
                    widgetItem.fieldData?.input?.id === item.input.id
            )
    )

    const { getCategoryArray } = useCategoryTools()
    const theme = useTheme()
    let categories = []
    const configCategories: { id: string }[] =
        JSON.parse(
            topLevelFieldAdded?.input?.inputConfig ??
                criteria?.criteriaOptions ??
                "{}"
        )?.categories ?? []

    configCategories?.map((item) => {
        categories = [...categories, ...(getCategoryArray(item.id) ?? [])]
        return categories
    })

    const [configurationOpen, setConfigurationOpen] = useState(false)
    const [expanded, setExpanded] = useState(false)

    const icon = CRITERIA_TYPE_ICONS[criteria.criteriaType]

    return (
        <Box
            style={{
                backgroundColor: !!expanded
                    ? alpha(theme.palette.primary.light, 0.05)
                    : "inherit",
            }}
        >
            <Box display="flex" alignItems={"center"} width="100%">
                <Box
                    width={theme.spacing(4)}
                    height={theme.spacing(4)}
                    display="flex"
                    justifyContent={"flex-start"}
                    alignItems="center"
                >
                    {criteria.criteriaType === CriteriaType.Collection && (
                        <IconButton
                            aria-label="expand row"
                            size="small"
                            onClick={(e) => {
                                e.stopPropagation()

                                setExpanded(!expanded)
                            }}
                        >
                            {expanded ? (
                                <KeyboardArrowUpIcon />
                            ) : (
                                <KeyboardArrowDownIcon />
                            )}
                        </IconButton>
                    )}
                </Box>
                <ListItem
                    button={!!topLevelFieldAdded ? undefined : true}
                    onClick={() => {
                        if (!topLevelFieldAdded) {
                            if (
                                criteria.criteriaType !==
                                CriteriaType.Collection
                            ) {
                                onAddFieldToWorkspace(criteria)
                            } else {
                                setConfigurationOpen(true)
                            }
                        }
                    }}
                >
                    <Box display="flex" flexDirection="column" width="100%">
                        <Box
                            display="flex"
                            alignItems="center"
                            justifyContent="flex-start"
                        >
                            <ListItemText
                                style={{ width: "100%" }}
                                primary={
                                    <Box display="flex" alignItems={"center"}>
                                        <span
                                            style={{
                                                display: "flex",
                                                alignItems: "center",
                                                color: theme.palette.action
                                                    .active,
                                                marginRight: 10,
                                            }}
                                        >
                                            {icon && icon}
                                        </span>
                                        <Typography
                                            variant="body1"
                                            style={{ marginRight: 8 }}
                                        >
                                            {criteria.name}
                                        </Typography>
                                        {criteria.criteriaType ===
                                            CriteriaType.Collection && (
                                            <CollectionCategorySubList
                                                categoryIds={configCategories.map(
                                                    (item) => item.id
                                                )}
                                            />
                                        )}
                                    </Box>
                                }
                            />

                            {!!topLevelFieldAdded && <Check color="primary" />}
                        </Box>
                    </Box>
                </ListItem>
                {!!configurationOpen && (
                    <InputConfigForm
                        onClose={() => {
                            setConfigurationOpen(false)
                        }}
                        criteria={criteria}
                        onSave={(config: CollectionType) => {
                            onAddFieldToWorkspace(criteria, null, config)
                        }}
                    />
                )}
            </Box>
            <Collapse in={expanded} unmountOnExit>
                <Box
                    display="flex"
                    flexDirection="column"
                    width="100%"
                    pl={6}
                    pr={1}
                >
                    <List disablePadding dense style={{ width: "100%" }}>
                        {criteria?.subfields
                            ?.filter((subfield) => !subfield.isArchived)
                            .map((subfield, index) => {
                                const added =
                                    currentResponsesVar?.currentResponseData.some(
                                        (item) =>
                                            getInputId(
                                                item.input.criteria?.id,
                                                conceptId,
                                                JSON.parse(
                                                    item.input?.inputConfig ??
                                                        "{}"
                                                )
                                            ) ===
                                                subfield.id +
                                                    conceptId +
                                                    criteria.id &&
                                            currentWorkspaceWidgets.conceptId ===
                                                conceptId &&
                                            currentWorkspaceWidgets.orderedWidgets.find(
                                                (widgetItem) =>
                                                    widgetItem.fieldData?.input
                                                        ?.id ===
                                                    subfield.id +
                                                        conceptId +
                                                        criteria.id
                                            )
                                    )

                                const icon =
                                    CRITERIA_TYPE_ICONS[subfield.criteriaType]
                                return (
                                    <Tooltip
                                        key={subfield.id + "_row" + index}
                                        title={
                                            !topLevelFieldAdded
                                                ? `You must add the field ${
                                                      criteria?.name || ""
                                                  } prior to adding this subfield`
                                                : !!added
                                                ? "This subfield has already been added"
                                                : ""
                                        }
                                    >
                                        <ListItem
                                            dense
                                            button={
                                                !!added || !topLevelFieldAdded
                                                    ? undefined
                                                    : true
                                            }
                                            onClick={() => {
                                                if (
                                                    !added &&
                                                    !!topLevelFieldAdded
                                                ) {
                                                    onAddFieldToWorkspace(
                                                        subfield,
                                                        criteria
                                                    )
                                                }
                                            }}
                                        >
                                            <ListItemText
                                                primary={
                                                    <Box
                                                        display="flex"
                                                        alignItems={"center"}
                                                    >
                                                        <span
                                                            style={{
                                                                display: "flex",
                                                                alignItems:
                                                                    "center",
                                                                color: theme
                                                                    .palette
                                                                    .action
                                                                    .active,
                                                                marginRight: 10,
                                                            }}
                                                        >
                                                            {icon && icon}
                                                        </span>
                                                        <Typography
                                                            variant="body2"
                                                            style={{
                                                                marginRight: 10,
                                                            }}
                                                        >
                                                            {subfield.name}
                                                        </Typography>
                                                    </Box>
                                                }
                                            />
                                            {!!added && (
                                                <ListItemIcon>
                                                    <Check
                                                        fontSize="small"
                                                        color="primary"
                                                    />
                                                </ListItemIcon>
                                            )}
                                        </ListItem>
                                    </Tooltip>
                                )
                            })}
                    </List>
                    <Box display="flex" justifyContent="flex-end" p={1}>
                        <FieldAutocomplete
                            onSelect={onAddExistingSubfield}
                            categoriesToGroupBy={categories}
                            optionsAllowed={"Sub"}
                            currentFieldIds={criteria.subfields?.map(
                                (item) => item.id
                            )}
                            onCreateNewField={onCreateNewSubfield}
                        />
                    </Box>
                </Box>
            </Collapse>
        </Box>
    )
}

export default FieldRowOption
