import { Box } from "@material-ui/core"

import RichTextEditor from "../../text-editor/RichTextEditor"
import SectionHeader from "../SectionHeader"

const RichTextDeserializer = (props: {
    editorId: string
    value: string
    headerName?: string
}) => {
    const { editorId, value, headerName } = props
    return (
        <Box>
            {!!headerName && <SectionHeader headerName={headerName} />}
            <RichTextEditor
                editable={false}
                initialValue={value}
                editorId={editorId}
            />
        </Box>
    )
}

export default RichTextDeserializer
