import { useState } from "react"

import {
    Box,
    TextField,
    InputAdornment,
    IconButton,
    ListItem,
    ListItemSecondaryAction,
    ListItemText,
    List,
    Typography,
} from "@material-ui/core"
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles"
import SendIcon from "@material-ui/icons/Send"
import ClearIcon from "@material-ui/icons/Clear"
import AddBoxIcon from "@material-ui/icons/AddBox"

import { useAuth } from "../../providers/AuthProvider"
import useAwaitTranslation from "../../i18n/useAwaitTranslation"

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        primary: {
            fontSize: theme.typography.body2.fontSize,
        },
        container: {
            height: "100%",
            display: "flex",
            flexDirection: "column",
            overflow: "hidden",
        },
        justificationOptionList: {
            overflowY: "auto",
            height: "100%",
            marginTop: theme.spacing(2),
        },
        listItem: {
            "&:hover": {
                backgroundColor: theme.palette.action.hover,
            },
        },
    })
)

interface JustificationOptionsEditorProps {
    onChange: (value: string[]) => void
    value: string[]
}

const JustificationOptionsEditor = (props: JustificationOptionsEditorProps) => {
    const { onChange, value } = props

    const classes = useStyles()

    const { currentUser } = useAuth()

    const [inputValue, setInputValue] = useState("")

    const { t } = useAwaitTranslation("workflows")
    return (
        <Box className={classes.container}>
            <Box mb={1}>
                <Typography variant="body1" color="textSecondary">
                    {t("justificationOptions", "Justification Options")}
                </Typography>
            </Box>
            <Box width="100%">
                <TextField
                    fullWidth
                    variant="outlined"
                    size="small"
                    value={inputValue}
                    onChange={(e) => {
                        setInputValue(e.target.value)
                    }}
                    onKeyDown={(e) => {
                        if (e.key === "Enter") {
                            onChange([...value, inputValue])
                            setInputValue("")
                        }
                    }}
                    placeholder={t(
                        "typeToAddANewJustificationOption",
                        "Type to add a new justification option"
                    )}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <AddBoxIcon fontSize="small" />
                            </InputAdornment>
                        ),
                        endAdornment: inputValue?.length > 0 && (
                            <InputAdornment position="end">
                                <Box>
                                    <IconButton
                                        size="small"
                                        onClick={() => setInputValue("")}
                                    >
                                        <ClearIcon fontSize="small" />
                                    </IconButton>
                                    <IconButton
                                        size="small"
                                        color="primary"
                                        onClick={() => {
                                            onChange([...value, inputValue])
                                            setInputValue("")
                                        }}
                                    >
                                        <SendIcon fontSize="small" />
                                    </IconButton>
                                </Box>
                            </InputAdornment>
                        ),
                    }}
                />
            </Box>

            <List className={classes.justificationOptionList} disablePadding>
                {value.map((item, index) => {
                    return (
                        <ListItem key={index} className={classes.listItem}>
                            <ListItemText primary={item} />
                            <ListItemSecondaryAction
                                onClick={() => {
                                    onChange(
                                        value.filter(
                                            (selection) => selection !== item
                                        )
                                    )
                                }}
                            >
                                <IconButton size="small">
                                    <ClearIcon fontSize="small" />
                                </IconButton>
                            </ListItemSecondaryAction>
                        </ListItem>
                    )
                })}
            </List>
        </Box>
    )
}

export default JustificationOptionsEditor
