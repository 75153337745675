import { ReactElement, useEffect, useState, useRef } from "react"
import { makeStyles } from "@material-ui/core/styles"
import { Box, Theme } from "@material-ui/core"
import { useAuth } from "../providers/AuthProvider"
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd"
import { useMutation } from "@apollo/client"
import { UPDATE_CHECKLIST } from "../graphql/Checklist"

import { ChecklistConceptItem } from "./concept-list/ChecklistConceptItem"
import { Checklist } from "../__generated__/types"
import {
    UpdateChecklistMutation,
    UpdateChecklistMutationVariables,
} from "../graphql/__generated__/Checklist"
import { reorderArray } from "../util/fns"

const useStyles = makeStyles((theme: Theme) => ({
    clone: {
        zIndex: 100,
        backgroundColor: theme.palette.background.paper,
    },
}))

interface Props {
    list: Checklist
    editPermission: boolean
    draggable: boolean
}

export default function SortableChecklist(props: Props): ReactElement {
    const { list, editPermission } = props
    const { currentUser } = useAuth()
    const classes = useStyles()
    const inputRefs = useRef({})
    const [checklist, setChecklist] = useState(null)
    const [items, setChecklistItems] = useState(null)
    const [listUpdate] = useMutation<
        UpdateChecklistMutation,
        UpdateChecklistMutationVariables
    >(UPDATE_CHECKLIST)
    const setListData = (list) => {
        let orderedItems = []
        let unorderedItems = []
        let itemsById = {}
        const { items, itemIds } = list
        if (itemIds) {
            const ids = itemIds
            items.forEach((c) => {
                itemsById[c.id] = c
                let itemIdx = ids.indexOf(c.id)
                if (itemIdx === -1) {
                    unorderedItems.push(c)
                } else {
                    orderedItems[ids.indexOf(c.id)] = c
                }
            })
        } else {
            unorderedItems = [...items]
        }

        unorderedItems.sort((a, b) => {
            const aTime = new Date(a.createdAt.formatted) as any
            const bTime = new Date(b.createdAt.formatted) as any
            return aTime - bTime
        })
        let allItems = [...orderedItems, ...unorderedItems].filter((c) => !!c)
        setChecklistItems(allItems)
        setChecklist({
            ...list,
            items: allItems,
        })
    }

    useEffect(() => {
        if (list) {
            setListData(list)
        }
    }, [list])
    const move = async (source, destination) => {
        if (
            source !== destination &&
            Math.sign(destination) !== -1 &&
            checklist.items.length - destination >= 1
        ) {
            const itemArray = reorderArray(checklist.items, source, destination)
            const newList = {
                id: checklist.id,
                itemIds: itemArray.map((c: any) => c.id),
            }
            try {
                setListData({
                    ...checklist,
                    ...newList,
                })
                await listUpdate({
                    variables: {
                        userId: currentUser.userId,
                        checklist: newList,
                    },
                })
                return
            } catch (e) {
                console.warn(e)
            }
        }
    }
    const onDragEnd = async (result) => {
        const itemArray = reorderArray(
            checklist.items,
            result?.source?.index,
            result?.destination?.index
        )
        const newList = {
            id: checklist.id,
            itemIds: itemArray.map((c: any) => c.id),
        }
        try {
            setListData({
                ...checklist,
                ...newList,
            })
            await listUpdate({
                variables: {
                    userId: currentUser.userId,
                    checklist: newList,
                },
            })
            return
        } catch (e) {
            console.warn(e)
        }
    }

    return (
        <Box key={checklist?.id}>
            {checklist && (
                <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable
                        droppableId={checklist.id}
                        renderClone={(provided, snapshot, rubric) => (
                            <div
                                className={classes.clone}
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                            >
                                <ChecklistConceptItem
                                    index={rubric.source.index}
                                    item={items[rubric.source.index]}
                                    clone={true}
                                    draggable={props.draggable}
                                    dragHandleProps={provided.dragHandleProps}
                                    innerRef={(element) =>
                                        (inputRefs.current[
                                            rubric.source.index
                                        ] = element)
                                    }
                                    onNextInput={() => {
                                        inputRefs.current[
                                            rubric.source.index + 1
                                        ]?.click()
                                    }}
                                    editPermission={editPermission}
                                    prefix={props.list.itemPrefixType}
                                />
                            </div>
                        )}
                    >
                        {(provided) => (
                            <div
                                id={"sortable-checklist-" + checklist?.id}
                                ref={provided.innerRef}
                                {...provided.droppableProps}
                            >
                                {items?.map((item, index) => (
                                    <Draggable
                                        isDragDisabled={!props.draggable}
                                        key={item.id + item.index}
                                        draggableId={item.id}
                                        index={index}
                                    >
                                        {(provided, snapshot) => (
                                            <div
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                key={
                                                    item.id + item.index + "div"
                                                }
                                            >
                                                <ChecklistConceptItem
                                                    item={item}
                                                    index={index}
                                                    draggable={props.draggable}
                                                    dragHandleProps={
                                                        provided.dragHandleProps
                                                    }
                                                    editPermission={
                                                        editPermission
                                                    }
                                                    innerRef={(element) =>
                                                        (inputRefs.current[
                                                            index
                                                        ] = element)
                                                    }
                                                    onNextInput={() => {
                                                        inputRefs.current[
                                                            index + 1
                                                        ]?.click()
                                                    }}
                                                    move={move}
                                                    prefix={
                                                        props.list
                                                            .itemPrefixType
                                                    }
                                                />
                                            </div>
                                        )}
                                    </Draggable>
                                ))}
                                {provided.placeholder}
                            </div>
                        )}
                    </Droppable>
                </DragDropContext>
            )}
        </Box>
    )
}
