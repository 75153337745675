import React, { useCallback } from "react"
import Box from "@material-ui/core/Box"
import { IconButton, CircularProgress } from "@material-ui/core"
import { useHistory } from "react-router-dom"
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank"
import CheckBoxIcon from "@material-ui/icons/CheckBox"
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle"
import { Concept } from "../__generated__/types"

import ConceptCardBaseBody from "./ConceptCardBaseBody"
import DoubleArrowIcon from "@material-ui/icons/DoubleArrow"
import useConceptTools from "../util/useConceptTools"
interface ConnectionOptionProps {
    item: Concept
    connectableConceptId: string
    editing?: boolean
    action: "Toggle" | "Remove" | "None"
    directionToRemove?: "from" | "to"
    onClick?: (concept: Concept) => void
}

const ConnectionOption = (props: ConnectionOptionProps) => {
    const {
        onConceptConnection,
        onConceptDisconnection,
        connecting,
        disconnecting,
    } = useConceptTools()
    //hooks
    const history = useHistory()

    //variables
    const connected = props.item?.connections.some(
        (item) => item.id === props.connectableConceptId
    )

    //callbacks
    const handleConceptToggle = useCallback(async () => {
        if (!connecting || !disconnecting) {
            if (!!connected) {
                //temporary logic for disconnection bug
                await onConceptDisconnection(
                    props.directionToRemove === "from"
                        ? props.item.id
                        : props.connectableConceptId,

                    props.directionToRemove === "to"
                        ? props.item.id
                        : props.connectableConceptId,
                    props.directionToRemove
                )
            } else {
                await onConceptConnection(
                    props.connectableConceptId,
                    props.item.id,
                    "from"
                )
            }
        }
    }, [
        onConceptConnection,
        onConceptDisconnection,
        props.item.id,
        props.connectableConceptId,
        connecting,
        connected,
        disconnecting,
        props.directionToRemove,
    ])

    //functions
    const handleConceptRemoval = async (e) => {
        e.stopPropagation()
        //temporary logic for disconnection bug
        await onConceptDisconnection(
            props.directionToRemove === "from"
                ? props.item.id
                : props.connectableConceptId,

            props.directionToRemove === "to"
                ? props.item.id
                : props.connectableConceptId,
            props.directionToRemove
        )
    }

    if (props.action === "Remove" && !connected) {
        return null
    }

    const topActions = (
        <Box display="flex" alignItems={"center"} ml={0.5}>
            {props.action === "Toggle" && props.editing && (
                <IconButton
                    onClick={handleConceptToggle}
                    size="small"
                    disabled={!!connecting || !!disconnecting}
                >
                    {!!connecting || !!disconnecting ? (
                        <CircularProgress size={20} color="secondary" />
                    ) : connected ? (
                        <CheckBoxIcon fontSize="small" color="primary" />
                    ) : (
                        <CheckBoxOutlineBlankIcon fontSize="small" />
                    )}
                </IconButton>
            )}
            {props.action === "Remove" && props.editing && (
                <IconButton
                    onClick={handleConceptRemoval}
                    disabled={disconnecting}
                    size="small"
                >
                    {disconnecting ? (
                        <CircularProgress size={20} color="secondary" />
                    ) : (
                        <RemoveCircleIcon fontSize="small" />
                    )}
                </IconButton>
            )}
        </Box>
    )
    const bottomActions = (
        <Box>
            <IconButton
                onClick={() => {
                    history.push(`/concept/${props.item.id}/home`)
                }}
                size="small"
            >
                <DoubleArrowIcon />
            </IconButton>
        </Box>
    )
    return (
        <ConceptCardBaseBody
            item={props.item}
            topActions={topActions}
            bottomActions={bottomActions}
            onClick={props.onClick}
        />
    )
}
export default React.memo(ConnectionOption)
