import {
    IconButton,
    Dialog,
    DialogActions,
    Button,
    DialogContentText,
    DialogContent,
    Slide,
    CircularProgress,
    Tooltip,
    DialogTitle,
} from "@material-ui/core"
import React, { useState } from "react"
import DeleteForeverIcon from "@material-ui/icons/DeleteForever"
import SettingsIcon from "@material-ui/icons/Settings"
import { Widget } from "../../useWidgets"
import WidgetConfigModal from "./WidgetConfigModal"
import { TransitionProps } from "@material-ui/core/transitions"
import useAwaitTranslation from "../../../../i18n/useAwaitTranslation"
import InputConfigForm from "../../../inputs/InputConfigForm"
import { Input } from "../../../../__generated__/types"

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & { children?: React.ReactElement<any, any> },
    ref: React.Ref<unknown>
) {
    return <Slide direction="up" ref={ref} {...props} />
})
type Props = {
    widget: Widget
    input?: Input
    onWidgetDeletion: (props: { widgetId: string; inputId?: string }) => void
}

export default function WidgetEditToolbar(props: Props) {
    const [confirmOpen, setConfirmOpen] = useState(false)
    const [configOpen, setConfigOpen] = useState(false)
    const [deleting, setDeleting] = useState(false)

    const { t } = useAwaitTranslation("widgets")

    return (
        <>
            {!deleting && (
                <Tooltip
                    title={
                        !!props.input && !props.input?.criteria
                            ? "This is no longer configurable due to the criteria being deleted"
                            : ""
                    }
                >
                    <span>
                        <IconButton
                            aria-label="settings widget"
                            onClick={() => setConfigOpen(true)}
                            disabled={!!props.input && !props.input?.criteria}
                        >
                            <SettingsIcon />
                        </IconButton>
                    </span>
                </Tooltip>
            )}
            {!!props.input && !!configOpen && (
                <InputConfigForm
                    widgetId={props.widget.id}
                    input={props.input}
                    onClose={() => {
                        setConfigOpen(false)
                    }}
                />
            )}
            {!props.input && !!configOpen && (
                <WidgetConfigModal
                    widget={props.widget}
                    open={configOpen}
                    onClose={() => setConfigOpen(false)}
                />
            )}
            <IconButton
                aria-label="delete widget"
                onClick={() => setConfirmOpen(true)}
            >
                <DeleteForeverIcon color="error" />
            </IconButton>
            <Dialog
                open={confirmOpen}
                TransitionComponent={Transition}
                fullWidth
            >
                <DialogTitle>
                    {t(
                        "confirmDeleteWidget",
                        "Are you sure you want to delete this widget?"
                    )}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {t(
                            "youWillNotBeAbleToUndoThisAction",
                            "You will not be able to undo this action."
                        )}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        disabled={!!deleting}
                        variant="text"
                        onClick={() => setConfirmOpen(false)}
                    >
                        {t("no", "No")}
                    </Button>
                    <Button
                        disabled={!!deleting}
                        variant="text"
                        onClick={() => {
                            setDeleting(true)
                            props.onWidgetDeletion({
                                widgetId: props.widget.id,
                                inputId: props.input?.id ?? null,
                            })
                        }}
                    >
                        {!!deleting ? (
                            <CircularProgress size={25} />
                        ) : (
                            t("yes", "Yes")
                        )}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}
