import React from "react"
import {
    Box,
    Divider,
    Button,
    alpha,
    LinearProgress,
    Chip,
    Icon,
} from "@material-ui/core"
import { useReactiveVar } from "@apollo/client"

import { CriteriaScore, Concept } from "../../../__generated__/types"
import { MultipleChoiceType } from "../../criteria/useCriteriaTypes"
import ConceptListItem from "../../ConceptListItem"
import { myCurrentInputResponseLabelVar } from "../../../providers/GlobalState"
import {
    Theme,
    createStyles,
    withStyles,
    useTheme,
} from "@material-ui/core/styles"
import Check from "@material-ui/icons/Check"
import { IconValue } from "../../icons/IconPicker"
import { ScoreSelectorDefaultValues } from "../types"
interface MultipleChoiceResponseViewerProps {
    inputId: string
    config: MultipleChoiceType
    scores?: CriteriaScore[]
    conceptsScored?: Concept[]
}
const MultipleChoiceResponseViewer: React.FunctionComponent<
    MultipleChoiceResponseViewerProps
> = ({ scores, config, conceptsScored = [] }) => {
    const { options } = config
    const isSubfield = config?.source?.criteriaIds?.length > 0
    return (
        <Box
            display="flex"
            flexDirection={"column"}
            height="100%"
            flexGrow="1"
            overflow="hidden"
        >
            {!!isSubfield ? (
                <Box
                    display="flex"
                    flexDirection={"column"}
                    height="100%"
                    overflow="auto"
                >
                    {conceptsScored.map((concept, index) => {
                        return (
                            <IndividualResponse
                                key={concept.id}
                                concept={concept}
                                scores={
                                    scores.filter(
                                        (score) =>
                                            score.scoredConcept?.id ===
                                            concept.id
                                    ) ?? []
                                }
                                options={options}
                            />
                        )
                    })}
                </Box>
            ) : (
                <IndividualResponse scores={scores} options={options} />
            )}
        </Box>
    )
}

const IndividualResponse = (props: {
    concept?: Concept
    scores?: CriteriaScore[]
    options: { value: string; id: string; icon?: IconValue }[]
}) => {
    const { concept, scores, options } = props
    const theme = useTheme()
    const currentLabel = useReactiveVar(myCurrentInputResponseLabelVar)
    const viewingAllResponses =
        !currentLabel?.user &&
        currentLabel?.label === ScoreSelectorDefaultValues.allResponses

    return (
        <Box>
            <Box display="flex" height="100%">
                {!!concept && (
                    <Box width="25%" minWidth="15em">
                        <ConceptListItem
                            item={concept}
                            showSummary={false}
                            hideDivider={true}
                            hideCategory={true}
                        />
                    </Box>
                )}
                <Divider orientation="vertical" flexItem />
                <Box
                    width="100%"
                    p={1}
                    pb={0}
                    display="flex"
                    flexDirection="column"
                    height="100%"
                    style={{ overflowY: "auto" }}
                >
                    {options.map((option, index) => {
                        const percent = Math.round(
                            ((scores.filter(
                                (item) => item.response === option.id
                            )?.length ?? 0) /
                                scores.length) *
                                100 ?? 0
                        )

                        const selected = option.id === scores?.[0]?.response

                        return (
                            <Box
                                p={0.5}
                                key={option.id || index}
                                width="100%"
                                height="100%"
                                maxHeight={100}
                            >
                                <Button
                                    color={!!selected ? "primary" : "default"}
                                    variant={"text"}
                                    style={{
                                        position: "relative",
                                        display: "flex",
                                        width: "100%",
                                        height: "100%",
                                        color: !!viewingAllResponses
                                            ? theme.palette.text.primary
                                            : !!selected
                                            ? theme.palette.primary.main
                                            : theme.palette.action.disabled,
                                    }}
                                    disabled={true}
                                >
                                    <Box flexGrow={1} mr={2} />
                                    <Box flexGrow={1} display="flex">
                                        {!!option.icon && (
                                            <Icon style={{ marginRight: 8 }}>
                                                {option.icon.name}
                                            </Icon>
                                        )}
                                        {option.value}
                                    </Box>
                                    <Box
                                        ml={2}
                                        display="flex"
                                        alignItems="center"
                                    >
                                        {!!viewingAllResponses ? (
                                            <Chip
                                                variant="outlined"
                                                label={`${
                                                    isNaN(percent) ? 0 : percent
                                                }%`}
                                            />
                                        ) : (
                                            !!selected && (
                                                <Check
                                                    fontSize="small"
                                                    color="primary"
                                                />
                                            )
                                        )}
                                    </Box>
                                    <Box
                                        position="absolute"
                                        bottom={0}
                                        top={0}
                                        right={0}
                                        left={0}
                                        display="flex"
                                        alignItems={"center"}
                                    >
                                        <BorderLinearProgress
                                            value={isNaN(percent) ? 0 : percent}
                                            variant="determinate"
                                        />
                                    </Box>
                                </Button>
                            </Box>
                        )
                    })}
                </Box>
            </Box>
            {!!concept && <Divider />}
        </Box>
    )
}

const BorderLinearProgress = withStyles((theme: Theme) =>
    createStyles({
        root: {
            height: "100%",
            flexGrow: 1,
            borderRadius: theme.shape.borderRadius,
        },
        colorPrimary: {
            backgroundColor: "transparent",
        },
        bar: {
            borderRadius: theme.shape.borderRadius,
            backgroundColor: alpha(theme.palette.primary.main, 0.15),
        },
    })
)(LinearProgress)

export default MultipleChoiceResponseViewer
