import { Typography, Box } from "@material-ui/core"

import { CriteriaScore } from "../../../__generated__/types"
import { MultipleChoiceType } from "../../criteria/useCriteriaTypes"
import SectionHeader from "../SectionHeader"

const MultipleChoiceDeserializer = (props: {
    score: CriteriaScore
    config: MultipleChoiceType
    hideHeader?: boolean
}) => {
    const { config, score, hideHeader } = props

    const selectionOptionValue =
        config?.options?.find((op) => op.id === score?.response)?.value || ""
    return (
        <Box>
            {!hideHeader && <SectionHeader headerName={config.name} />}
            <Typography variant="body1" gutterBottom>
                {selectionOptionValue}
            </Typography>
        </Box>
    )
}

export default MultipleChoiceDeserializer
