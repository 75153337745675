import { JSONSchema4Object } from "json-schema"

export const MultipleChoiceSchema: JSONSchema4Object = {
    required: ["name", "options"],
    properties: {
        name: {
            type: "string",
        },
        instructions: {
            type: "string",
            options: {
                multiline: true,
            },
        },
        allowMultipleResponses: {
            type: "boolean",
            options: {
                hideIfTableField: true,
            },
        },
        options: {
            type: "uniqueOptions",
            options: {
                allowIcons: true,
                label: "Options",
            },
        },
    },
}

export const NumberSchema: JSONSchema4Object = {
    required: ["name", "minimum"],
    properties: {
        name: {
            type: "string",
        },
        instructions: {
            type: "string",
            options: {
                multiline: true,
            },
        },
        allowMultipleResponses: {
            type: "boolean",
            options: {
                hideIfTableField: true,
            },
        },
        prefixSuffix: {
            type: "prefixSuffix",
        },
        minimumMaximum: {
            type: "minimumMaximum",
        },
        slider: {
            type: "boolean",
            options: {
                requiredForDisplaying: [
                    {
                        field: "maximum",
                    },
                ],
            },
        },
    },
}

export const TextResponseSchema: JSONSchema4Object = {
    required: ["name", "prompt"],
    properties: {
        name: {
            type: "string",
        },
        instructions: {
            type: "string",
            options: {
                multiline: true,
            },
        },
        prompt: {
            type: "string",
            options: {
                multiline: true,
            },
        },
        promptFields: {
            type: "fieldMultiple",
            label: "Prompt Context Fields",
            options: {
                criteriaTypes: [
                    "TextResponse",
                    "MultipleChoice",
                    "Number",
                    "Collection",
                ],
                allowSubfields: true,
            },
            note: "Only collection, text, number, and multiple choice fields are allowed at this time",
        },
        allowMultipleResponses: {
            type: "boolean",
            options: {
                hideIfTableField: true,
            },
        },
    },
}

export const RankingSchema: JSONSchema4Object = {
    required: ["name"],
    properties: {
        name: {
            type: "string",
        },
        instructions: {
            type: "string",
            options: {
                multiline: true,
            },
        },
        allowMultipleResponses: {
            type: "boolean",
            options: {
                hideIfTableField: true,
            },
        },
    },
}

export const CollectionSchema: JSONSchema4Object = {
    required: ["name", "categoryId", "singleSelection"],
    properties: {
        name: {
            type: "string",
        },
        instructions: {
            type: "string",
            options: {
                multiline: true,
            },
        },
        allowMultipleResponses: {
            type: "boolean",
            options: {
                hideIfTableField: true,
            },
        },
        requirePrimaryResponse: {
            type: "boolean",
            options: {
                requiredForDisplaying: [
                    {
                        field: "singleSelection",
                        requiredValues: [false],
                    },
                ],
            },
        },
        categories: {
            type: "categoryMultiple",
            options: {
                label: "Collection Categories",
            },
        },
        singleSelection: {
            type: "boolean",
        },
        view: {
            type: "string",
            enum: ["list", "chip", "gallery", "table"],
            options: {
                requiredForDisplaying: [
                    {
                        field: "singleSelection",
                        requiredValues: [false],
                    },
                ],
            },
        },
        subfields: {
            type: "subfieldMultiple",
            label: "Table Column Fields",
            options: {
                requiredForDisplaying: [
                    {
                        field: "view",
                        requiredValues: ["table"],
                    },
                    {
                        field: "singleSelection",
                        requiredValues: [false],
                    },
                ],
                criteriaTypes: [
                    "Number",
                    "TextResponse",
                    "MultipleChoice",
                    "Date",
                ],
            },
        },
        minimumMaximum: {
            type: "minimumMaximum",
            options: {
                requiredForDisplaying: [
                    {
                        field: "singleSelection",
                        requiredValues: [false],
                    },
                ],
            },
        },
        allowConceptCreation: {
            type: "boolean",
        },
        allowFiltering: {
            type: "boolean",
            options: {
                hide: true,
            },
        },
        allowRanking: {
            type: "boolean",
            options: {
                hide: true,
            },
        },
        enableSuggestions: {
            type: "boolean",
        },
        suggestionCriteriaIds: {
            type: "fieldMultiple",
            label: "Suggestion Source Fields",
            options: {
                requiredForDisplaying: [
                    {
                        field: "enableSuggestions",
                        requiredValues: [true],
                    },
                ],
                criteriaTypes: ["Collection"],
            },
        },
    },
}

export const DateSchema: JSONSchema4Object = {
    required: ["name", "singleSelection"],
    properties: {
        name: {
            type: "string",
        },
        instructions: {
            type: "string",
            options: {
                multiline: true,
            },
        },
        allowMultipleResponses: {
            type: "boolean",
            options: {
                hideIfTableField: true,
            },
        },
        range: {
            type: "boolean",
        },
    },
}

export const ChartSchema: JSONSchema4Object = {
    required: ["name"],
    properties: {
        name: {
            type: "string",
        },
        instructions: {
            type: "string",
            options: {
                multiline: true,
            },
        },
        allowMultipleResponses: {
            type: "boolean",
            options: {
                hideIfTableField: true,
            },
        },
        chart: {
            type: "string",
            enum: ["hypeLifeCycle", "productLifeCycle", "bubble", "radar"],
        },
        bubbleAxisConfiguration: {
            type: "bubbleAxisConfiguration",
            options: {
                requiredForDisplaying: [
                    {
                        field: "chart",
                        requiredValues: ["bubble"],
                    },
                ],
            },
        },
        radarStages: {
            type: "uniqueOptions",

            options: {
                requiredForDisplaying: [
                    {
                        field: "chart",
                        requiredValues: ["radar"],
                    },
                ],
                label: "Radar Stages",
            },
        },
    },
}

export const GroupingSchema: JSONSchema4Object = {
    required: ["name"],
    properties: {
        name: {
            type: "string",
        },
        instructions: {
            type: "string",
            options: {
                multiline: true,
            },
        },
        allowMultipleResponses: {
            type: "boolean",
            options: {
                hideIfTableField: true,
            },
        },
        groups: {
            type: "uniqueOptions",
            options: {
                label: "Groups",
            },
        },
    },
}
