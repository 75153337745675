import {
    Box,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Typography,
} from "@material-ui/core"
import parse from "autosuggest-highlight/parse"
import match from "autosuggest-highlight/match"
import React from "react"
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles"

import { Concept } from "../__generated__/types"
import ConceptAvatar from "./ConceptAvatar"
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        avatar: {
            color: theme.palette.text.primary,
            backgroundColor: theme.palette.primary.main,
        },

        title: {
            fontSize: theme.typography.body1.fontSize,
        },
    })
)

interface SearchListItemProps {
    concept: Concept
    inputValue: string
    onSelect?: () => void
}
const SearchListItem = (props: SearchListItemProps) => {
    const { concept, inputValue, onSelect } = props
    const classes = useStyles()

    const titleMatches = match(concept.title, inputValue)
    const partsTitle = parse(concept.title, titleMatches)
    const summary = concept.summary || ""
    const summaryMatches = match(summary, inputValue)
    const partsSummary = parse(summary, summaryMatches)

    const isButton: boolean = !!onSelect

    return (
        <ListItem
            component="div"
            style={{ padding: !isButton && 0 }}
            divider={isButton}
            button={isButton as any}
            disableGutters={!isButton}
            dense={!isButton}
            onClick={isButton ? () => onSelect() : null}
        >
            <ListItemAvatar>
                <ConceptAvatar
                    concept={concept}
                    variant="rounded"
                    size="large"
                />
            </ListItemAvatar>
            <ListItemText
                primary={
                    <Box display="flex" alignItems="center">
                        <Box flexGrow={1} className={classes.title}>
                            {partsTitle.map((part, index) => (
                                <span
                                    key={index}
                                    style={{
                                        fontWeight: part.highlight ? 800 : 400,
                                    }}
                                >
                                    {part.text}
                                </span>
                            ))}
                        </Box>
                        <Typography variant="body2" color="textSecondary">
                            {concept?.category?.name}
                        </Typography>
                    </Box>
                }
                secondary={
                    <>
                        {partsSummary.map((part, index) => (
                            <span
                                key={index}
                                style={{
                                    fontWeight: part.highlight ? 800 : 400,
                                }}
                            >
                                {part.text}
                            </span>
                        ))}
                    </>
                }
            />
        </ListItem>
    )
}

export default SearchListItem
