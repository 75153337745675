import { gql } from "@apollo/client"

export const BASE_CRITERIA_FRAGMENT = gql`
    fragment Criteria on Criteria {
        id
        name
        isDefault
        criteriaType
        criteriaOptions
        isArchived
        subfields(orderBy: name_asc) {
            id
            name
            isDefault
            criteriaType
            criteriaOptions
            isArchived
        }
    }
`

export const BASE_CRITERIA_SCORE_FRAGMENT = gql`
    fragment BaseCriteriaScore on CriteriaScore {
        id
        value
        label
        response
        chatHistory
        notes
        response
        status
        value
        isArchived
        conceptDefault
    }
`
export const CRITERIA_SCORE_FRAGMENT = gql`
    fragment CriteriaScore on CriteriaScore {
        id
        value
        label
        response
        notes
        chatHistory
        response
        status
        value
        isArchived
        conceptDefault
        criteria {
            id
            name
            isDefault
            criteriaType
            criteriaOptions
            categories {
                id
                name
            }
        }
        user {
            userId
            firstName
            lastName
            username
            imageUrl
        }
        scoredConcept {
            id
            title
            summary
            imageUrl
            category {
                id
                name
                isRoot
            }
            translations {
                id
                languageString
                title
            }
        }
        input {
            id
        }
        concepts {
            id
            title
            summary
            imageUrl
            category {
                id
                name
                isRoot
            }
            translations {
                id
                languageString
                title
            }
        }
    }
`

export const CRITERIA_FILTERED = gql`
    query Criteria($filter: _CriteriaFilter) {
        Criteria(filter: $filter, orderBy: name_asc) {
            ...Criteria
        }
    }
    ${BASE_CRITERIA_FRAGMENT}
`
export const FIELD_MANAGER = gql`
    query FieldManager {
        criteria: Criteria(orderBy: name_asc, isArchived: null) {
            ...Criteria
            inputs {
                id
                inputConfig
            }
        }
        categories: Category {
            id
            name
            defaultDashboard {
                id
                widgets {
                    id
                    config
                }
            }
        }
    }
    ${BASE_CRITERIA_FRAGMENT}
`

/// MUTATIONS
export const CREATE_CRITERIA = gql`
    mutation CreateCriteria(
        $name: String
        $isDefault: Boolean
        $criteriaType: String
        $criteriaOptions: String
    ) {
        CreateCriteria(
            name: $name
            isDefault: $isDefault
            criteriaType: $criteriaType
            criteriaOptions: $criteriaOptions
        ) {
            ...Criteria
        }
    }
    ${BASE_CRITERIA_FRAGMENT}
`
export const UPDATE_CRITERIA = gql`
    mutation UpdateCriteria(
        $id: ID!
        $name: String
        $isDefault: Boolean
        $criteriaType: String
        $criteriaOptions: String
        $isArchived: Boolean
    ) {
        UpdateCriteria(
            id: $id
            name: $name
            isDefault: $isDefault
            criteriaType: $criteriaType
            criteriaOptions: $criteriaOptions
            isArchived: $isArchived
        ) {
            ...Criteria
        }
    }
    ${BASE_CRITERIA_FRAGMENT}
`

export const ADD_CATEGORY_CRITERIA = gql`
    mutation AddCategoryCriteria($criteriaId: ID!, $categoryId: ID!) {
        AddCriteriaCategories(
            from: { id: $categoryId }
            to: { id: $criteriaId }
        ) {
            from {
                id
                criteria {
                    ...Criteria
                }
            }
            to {
                ...Criteria
            }
        }
    }
    ${BASE_CRITERIA_FRAGMENT}
`

export const REMOVE_CATEGORY_CRITERIA = gql`
    mutation RemoveCategoryCriteria($criteriaId: ID!, $categoryId: ID!) {
        RemoveCriteriaCategories(
            from: { id: $categoryId }
            to: { id: $criteriaId }
        ) {
            from {
                id
                criteria {
                    ...Criteria
                }
            }
            to {
                ...Criteria
            }
        }
    }
    ${BASE_CRITERIA_FRAGMENT}
`

export const DELETE_CRITERIA = gql`
    mutation DeleteCriteria($id: ID!) {
        DeleteCriteria(id: $id) {
            id
        }
    }
`

export const CREATE_CRITERIA_SCORE = gql`
    mutation CreateCriteriaScore(
        $notes: String
        $response: String
        $value: Float
        $conceptDefault: Boolean
        $label: String
        $lastUpdated: _Neo4jDateTimeInput
        $lastUpdatedByUserId: String
    ) {
        CreateCriteriaScore(
            notes: $notes
            response: $response
            value: $value
            conceptDefault: $conceptDefault
            label: $label
            lastUpdated: $lastUpdated
            lastUpdatedByUserId: $lastUpdatedByUserId
        ) {
            ...CriteriaScore
        }
    }
    ${CRITERIA_SCORE_FRAGMENT}
`

export const UPDATE_CRITERIA_SCORE = gql`
    mutation UpdateCriteriaScore(
        $id: ID!
        $notes: String
        $label: String
        $value: Float
        $response: String
        $conceptDefault: Boolean
        $lastUpdated: _Neo4jDateTimeInput
        $lastUpdatedByUserId: String
        $isArchived: Boolean
        $chatHistory: String
    ) {
        UpdateCriteriaScore(
            id: $id
            notes: $notes
            label: $label
            response: $response
            value: $value
            conceptDefault: $conceptDefault
            lastUpdated: $lastUpdated
            lastUpdatedByUserId: $lastUpdatedByUserId
            isArchived: $isArchived
            chatHistory: $chatHistory
        ) {
            ...BaseCriteriaScore
        }
    }
    ${BASE_CRITERIA_SCORE_FRAGMENT}
`

export const UPDATE_CRITERIA_SCORE_NO_RETURN = gql`
    mutation UpdateCriteriaScoreNoReturn(
        $id: ID!
        $notes: String
        $label: String
        $value: Float
        $response: String
        $conceptDefault: Boolean
        $lastUpdated: _Neo4jDateTimeInput
        $lastUpdatedByUserId: String
        $isArchived: Boolean
        $chatHistory: String
    ) {
        UpdateCriteriaScore(
            id: $id
            notes: $notes
            label: $label
            response: $response
            value: $value
            conceptDefault: $conceptDefault
            lastUpdated: $lastUpdated
            lastUpdatedByUserId: $lastUpdatedByUserId
            isArchived: $isArchived
            chatHistory: $chatHistory
        ) {
            id
        }
    }
`

export const DELETE_CRITERIA_SCORE = gql`
    mutation DeleteCriteriaScore($id: ID!) {
        DeleteCriteriaScore(id: $id) {
            id
        }
    }
`

export const ADD_CRITERIA_SCORE_USER = gql`
    mutation AddCriteriaScoreUser($criteriaScoreId: ID!, $userId: ID!) {
        AddCriteriaScoreUser(
            from: { id: $criteriaScoreId }
            to: { userId: $userId }
        ) {
            from {
                id
            }
            to {
                userId
                firstName
                lastName
                username
            }
        }
    }
`
export const ADD_CRITERIA_SCORE_CRITERIA = gql`
    mutation AddCriteriaScoreCriteria($criteriaScoreId: ID!, $criteriaId: ID!) {
        AddCriteriaScoreCriteria(
            from: { id: $criteriaScoreId }
            to: { id: $criteriaId }
        ) {
            from {
                id
            }
            to {
                id
            }
        }
    }
`
export const ADD_CRITERIA_SCORE_SCORED_CONCEPT = gql`
    mutation AddCriteriaScoreScoredConcept(
        $conceptId: ID!
        $criteriaScoreId: ID!
    ) {
        AddCriteriaScoreScoredConcept(
            from: { id: $criteriaScoreId }
            to: { id: $conceptId }
        ) {
            from {
                id
            }
            to {
                id
            }
        }
    }
`

export const ADD_CRITERIA_SCORE_CONCEPT = gql`
    mutation AddCriteriaScoreConcepts($conceptId: ID!, $criteriaScoreId: ID!) {
        AddCriteriaScoreConcepts(
            from: { id: $criteriaScoreId }
            to: { id: $conceptId }
        ) {
            from {
                id
                concepts {
                    id
                }
            }
            to {
                id
            }
        }
    }
`

export const REMOVE_CRITERIA_SCORE_CONCEPT = gql`
    mutation RemoveCriteriaScoreConcepts(
        $conceptId: ID!
        $criteriaScoreId: ID!
    ) {
        RemoveCriteriaScoreConcepts(
            from: { id: $criteriaScoreId }
            to: { id: $conceptId }
        ) {
            from {
                id
                concepts {
                    id
                }
            }
            to {
                id
            }
        }
    }
`

export const CRITERIA_SCORE_FILTERED = gql`
    query CriteriaScoreFiltered(
        $filter: _CriteriaScoreFilter
        $orderBy: [_CriteriaScoreOrdering]
    ) {
        CriteriaScore(filter: $filter, orderBy: $orderBy) {
            ...CriteriaScore
        }
    }
    ${CRITERIA_SCORE_FRAGMENT}
`

export const CONCEPT_WITH_CRITERIA = gql`
    query ConceptWithCriteria(
        $conceptFilter: _ConceptFilter
        $criteriaScoreFilter: _CriteriaScoreFilter
        $criteriaFilter: _CriteriaFilter
    ) {
        Concept(filter: $conceptFilter) {
            id
            title
            summary
            imageUrl
            category {
                id
                name
                isRoot
                icon
            }
            translations {
                id
                languageString
                title
            }
            addedUsers {
                type
                User {
                    userId
                    firstName
                    lastName
                    imageUrl
                }
            }
            addedTeams {
                type
                Team {
                    teamId
                    name
                    imageUrl
                    members {
                        userId
                    }
                }
            }
            criteriaScores(filter: $criteriaScoreFilter) {
                id
                value
                response
                notes
                conceptDefault
                user {
                    userId
                }
                criteria {
                    id
                    name
                    isDefault
                    category {
                        id
                        name
                    }
                    criteriaType
                }
            }
        }
        Criteria(filter: $criteriaFilter) {
            id
            name
            isDefault
            category {
                id
                name
            }
            criteriaType
        }
    }
`

export const ADD_FIELD_SUBFIELD = gql`
    mutation AddFieldSubfield($parentFieldId: ID!, $childFieldId: ID!) {
        AddCriteriaSubfields(
            from: { id: $childFieldId }
            to: { id: $parentFieldId }
        ) {
            from {
                ...Criteria
            }
            to {
                ...Criteria
            }
        }
    }
    ${BASE_CRITERIA_FRAGMENT}
`
export const REMOVE_FIELD_SUBFIELD = gql`
    mutation RemoveFieldSubfield($parentFieldId: ID!, $childFieldId: ID!) {
        RemoveCriteriaSubfields(
            from: { id: $childFieldId }
            to: { id: $parentFieldId }
        ) {
            from {
                ...Criteria
            }
            to {
                ...Criteria
            }
        }
    }
    ${BASE_CRITERIA_FRAGMENT}
`
