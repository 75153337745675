import React, { useEffect, useState } from "react"
import NotificationsActiveIcon from "@material-ui/icons/NotificationsActive"
import NotificationsOffIcon from "@material-ui/icons/NotificationsOff"
import { useMutation, useQuery } from "@apollo/client"
import { useTheme } from "@material-ui/core/styles"
import {
    ADD_USER_WATCHED_CONCEPT,
    REMOVE_USER_WATCHED_CONCEPT,
    USER_WATCHED_CONCEPT_CHECK,
} from "./graphql"
import {
    AddUserWatchedConceptsMutation,
    AddUserWatchedConceptsMutationVariables,
    RemoveUserWatchedConceptsMutation,
    RemoveUserWatchedConceptsMutationVariables,
    UserWatchConceptCheckQuery,
    UserWatchConceptCheckQueryVariables,
} from "./__generated__/graphql"
import { useAuth } from "../../providers/AuthProvider"
import useAwaitTranslation from "../../i18n/useAwaitTranslation"
import { Concept } from "../../__generated__/types"
import { ListItemIcon, ListItemText, MenuItem } from "@material-ui/core"
import { useGlobalStyles } from "../../styles/globalStyles"

interface AlertSubscriptionToggleProps {
    item: Concept
    size?: "small" | "medium"
}
const AlertSubscriptionToggle: React.FC<AlertSubscriptionToggleProps> = ({
    item,
}) => {
    const globalClasses = useGlobalStyles()
    const theme = useTheme()
    const { currentUser } = useAuth()
    const [watched, setWatched] = useState(false)
    const [addSubscription] = useMutation<
        AddUserWatchedConceptsMutation,
        AddUserWatchedConceptsMutationVariables
    >(ADD_USER_WATCHED_CONCEPT)
    const [removeSubscription] = useMutation<
        RemoveUserWatchedConceptsMutation,
        RemoveUserWatchedConceptsMutationVariables
    >(REMOVE_USER_WATCHED_CONCEPT)
    const { data, refetch } = useQuery<
        UserWatchConceptCheckQuery,
        UserWatchConceptCheckQueryVariables
    >(USER_WATCHED_CONCEPT_CHECK, {
        variables: {
            id: item.id,
            userId: currentUser.userId,
        },
    })
    // for some reason, watchedConcepts is not being cached by the user entity
    const watchedCheck =
        data?.User?.[0]?.watchedConcepts?.filter((x) => x.id === item.id)
            ?.length > 0
    useEffect(() => {
        if (data && watchedCheck) {
            setWatched(watchedCheck)
        }
    }, [data, watchedCheck])

    const handleSubscriptionAdd = async (ev) => {
        if (watched) {
            setWatched(false)
            await removeSubscription({
                variables: {
                    fromId: currentUser.userId,
                    toId: item.id,
                },
            })
        } else {
            setWatched(true)
            await addSubscription({
                variables: {
                    fromId: currentUser.userId,
                    toId: item.id,
                },
            })
        }
        refetch()
    }
    const { t } = useAwaitTranslation("widgets")

    return (
        <MenuItem onClick={handleSubscriptionAdd}>
            <ListItemIcon className={globalClasses.smallMenuIcon}>
                {watched ? (
                    <NotificationsActiveIcon
                        style={{ color: theme.palette.primary.main }}
                    />
                ) : (
                    <NotificationsOffIcon />
                )}
            </ListItemIcon>

            <ListItemText
                primaryTypographyProps={{
                    className: globalClasses.smallMenuListItemText,
                }}
                secondaryTypographyProps={{
                    className: globalClasses.smallMenuListItemSecondaryText,
                }}
                primary={
                    watched
                        ? t("unsubscribe", "Unsubscribe")
                        : t("subscribe", "Subscribe")
                }
                secondary={
                    watched
                        ? t(
                              "stopReceivingUpdateNotifications",
                              "Stop receiving update notifications"
                          )
                        : t(
                              "receiveUpdateNotifications",
                              "Receive update notifications"
                          )
                }
            />
        </MenuItem>
    )
}

export default AlertSubscriptionToggle
