import React, { useCallback, useEffect, useRef, useState } from "react"
import {
    Box,
    IconButton,
    ListItem,
    Typography,
    List,
    ListItemText,
    useMediaQuery,
    Slide,
    Divider,
    ListItemSecondaryAction,
    Paper,
} from "@material-ui/core"
import { useTheme, makeStyles } from "@material-ui/core/styles"
import { Concept, CriteriaScore } from "../../__generated__/types"
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward"
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward"
import useAwaitTranslation from "../../i18n/useAwaitTranslation"
import DoubleArrowIcon from "@material-ui/icons/DoubleArrow"
import {
    CriteriaScoreInputVariables,
    ScoreSelectorDefaultValues,
} from "./types"
import ConceptAvatar from "../ConceptAvatar"
import { CriteriaType } from "../criteria/types"
import {
    INTERFACE_REPOSITORY,
    VARIABLE_SIZE_CRITERIA_OPTIONS,
} from "../criteria/useCriteriaTypes"
import { checkFieldCompletion } from "./util"
import { useReactiveVar } from "@apollo/client"
import { myCurrentInputResponseLabelVar } from "../../providers/GlobalState"
import ResponsePreviewer from "./ResponsePreviewer"
import { useParams } from "react-router-dom"
import CheckIcon from "@material-ui/icons/Check"
import useMountedState from "../../util/useMountedState"
const useStyles = makeStyles((theme) => ({
    flexContainer: {
        display: "flex",
        flexDirection: "column",
        overflow: "hidden",
        border: `1px solid ${theme.palette.divider}`,
        borderRadius: theme.shape.borderRadius,
        margin: theme.spacing(4, 2),
        backgroundColor: theme.palette.background.paper,
    },
}))

interface BaseInputInterfaceProps {
    inputId: string
    //scores provided based off response selected
    scores: CriteriaScore[]
    //concepts provided to score if its a sub criteria
    conceptsToScore?: Concept[]
    //method to create a new criteriaScore
    onCreateNewResponse: (
        data: CriteriaScoreInputVariables,
        scoredConceptId?: string
    ) => Promise<void>

    //criteriaType of input
    criteriaType: CriteriaType
    //configuration of input based on criteriaType
    config: any
    //dialog prop for text boxes to reset rich text editor (sub-optimal)
    isDialog: boolean
    editingConfig: boolean
}

const BaseInputInterface: React.FunctionComponent<BaseInputInterfaceProps> = ({
    inputId,
    conceptsToScore,
    scores,
    onCreateNewResponse,
    criteriaType,
    config,
    isDialog,
    editingConfig,
}) => {
    const { conceptId } = useParams()
    const isMounted = useMountedState()
    const classes = useStyles()
    const theme = useTheme()
    const [focusedIndex, setFocusedIndex] = useState(null)
    const mobile = useMediaQuery(theme.breakpoints.down("sm"))
    const slideDirection = useRef<"up" | "down">(null)
    const { i18n } = useAwaitTranslation("feedback")
    const selectedLangString = i18n?.language
    const currentLabel = useReactiveVar(myCurrentInputResponseLabelVar)
    const viewingPrimaryResponse =
        currentLabel.label === ScoreSelectorDefaultValues.primaryResponse &&
        !currentLabel.user
    const onCheckConceptScoreExistence = useCallback(
        async (index) => {
            if (
                !!conceptsToScore[index] &&
                !scores?.find(
                    (score) =>
                        score.scoredConcept?.id === conceptsToScore[index].id
                )
            ) {
                await onCreateNewResponse({}, conceptsToScore[index]?.id)
            }
            if (!!isMounted()) {
                setFocusedIndex(index)
            }
        },
        [conceptsToScore, scores, onCreateNewResponse, isMounted]
    )

    const handleNext = async () => {
        slideDirection.current = "up"
        await onCheckConceptScoreExistence(focusedIndex + 1)
    }

    const handleBack = async () => {
        slideDirection.current = "down"
        await onCheckConceptScoreExistence(focusedIndex - 1)
    }
    const currentConcept = conceptsToScore?.[focusedIndex]

    const currentConceptTranslatedConcept = currentConcept?.translations.filter(
        (t) => t.languageString === selectedLangString
    )?.[0]
    const Interface = INTERFACE_REPOSITORY[criteriaType]

    // if scoring a collection, take the current step score, if not then there should only be one score anyway
    const currentScore = !!currentConcept
        ? scores?.find(
              (score) => score.scoredConcept?.id === currentConcept?.id
          )
        : scores?.[0] ?? null

    const isSubField =
        config?.source?.criteriaIds?.length > 0 &&
        criteriaType !== CriteriaType.Collection

    const multiInterface =
        !!isSubField &&
        ![CriteriaType.Ranking, CriteriaType.Grouping].includes(criteriaType)

    useEffect(() => {
        if (
            focusedIndex === null &&
            conceptsToScore?.length > 0 &&
            !!multiInterface
        ) {
            onCheckConceptScoreExistence(0)
        }
    }, [
        focusedIndex,
        conceptsToScore?.length,
        onCheckConceptScoreExistence,
        multiInterface,
    ])
    if (!Interface) {
        return null
    }

    const showAllResponsesViewer =
        isDialog &&
        !!viewingPrimaryResponse &&
        config.allowMultipleResponses &&
        !mobile

    return (
        <Box
            display="flex"
            flexDirection={"column"}
            height="100%"
            overflow="hidden"
        >
            {/* Ranking and grouping does not require a different interface to evaluate each concept individually */}
            {!!multiInterface ? (
                <Box display="flex" height="100%" overflow="hidden">
                    {!!isDialog && !mobile && (
                        <List
                            style={{ overflow: "auto", width: "50%" }}
                            disablePadding
                        >
                            {(conceptsToScore ?? [])?.map((concept, index) => {
                                const translatedContent =
                                    concept?.translations.filter(
                                        (t) =>
                                            t.languageString ===
                                            selectedLangString
                                    )[0]
                                const score = scores?.find(
                                    (score) =>
                                        score.scoredConcept?.id === concept.id
                                )
                                const fieldCompleted = checkFieldCompletion({
                                    score,
                                    criteriaType: criteriaType as CriteriaType,
                                    config,
                                    currentLabel,
                                })
                                return (
                                    <ListItem
                                        key={concept.id}
                                        selected={focusedIndex === index}
                                        button
                                        onClick={async () => {
                                            if (focusedIndex !== index) {
                                                if (focusedIndex > index) {
                                                    slideDirection.current =
                                                        "down"
                                                } else {
                                                    slideDirection.current =
                                                        "up"
                                                }
                                                await onCheckConceptScoreExistence(
                                                    index
                                                )
                                            }
                                        }}
                                        style={{
                                            paddingRight: theme.spacing(8),
                                        }}
                                    >
                                        <Box display="flex" alignItems="center">
                                            <Box minWidth="3rem" display="flex">
                                                {fieldCompleted && (
                                                    <CheckIcon fontSize="small" />
                                                )}
                                            </Box>
                                            <Box minWidth="2rem">
                                                <Typography
                                                    variant="body2"
                                                    color="textSecondary"
                                                >
                                                    {index + 1}
                                                </Typography>
                                            </Box>
                                            <Box pr={2}>
                                                <ConceptAvatar
                                                    variant="rounded"
                                                    size="medium"
                                                    concept={concept}
                                                />
                                            </Box>
                                        </Box>
                                        <ListItemText
                                            primary={
                                                translatedContent?.title ||
                                                concept.title
                                            }
                                            primaryTypographyProps={{
                                                noWrap: true,
                                            }}
                                        />
                                        {!!viewingPrimaryResponse && (
                                            <ListItemSecondaryAction>
                                                <ResponsePreviewer
                                                    inputId={inputId}
                                                    scoredConceptId={concept.id}
                                                    popper={true}
                                                />
                                            </ListItemSecondaryAction>
                                        )}
                                    </ListItem>
                                )
                            })}
                        </List>
                    )}
                    <Divider orientation="vertical" />
                    <Box
                        display="flex"
                        flexDirection="column"
                        height="100%"
                        alignItems="center"
                        justifyContent="center"
                        width={"100%"}
                        overflow="hidden"
                        position="relative"
                        style={{
                            backgroundColor: theme.palette.background.default,
                        }}
                    >
                        <IconButton
                            disabled={focusedIndex === 0}
                            style={{
                                padding: theme.spacing(2),
                                width: "100%",
                                zIndex: 1,
                                backgroundColor: "transparent",
                                position: "absolute",
                                top: 0,
                                height: 75,
                            }}
                            onClick={handleBack}
                        >
                            <ArrowUpwardIcon
                                fontSize={
                                    !!mobile || !isDialog ? "medium" : "large"
                                }
                            />
                        </IconButton>
                        <Box
                            position="absolute"
                            style={{ zIndex: 2 }}
                            left={0}
                            top={0}
                            p={1.5}
                        >
                            <Typography
                                variant={mobile ? "subtitle2" : "subtitle1"}
                            >
                                {(focusedIndex || 0) + 1} /{" "}
                                {conceptsToScore.length}
                            </Typography>
                        </Box>
                        <Slide
                            in={!!currentConcept}
                            key={currentConcept?.id || "no-concept"}
                            direction={slideDirection.current}
                            timeout={!slideDirection.current ? 0 : 250}
                        >
                            <Box
                                flexGrow={
                                    VARIABLE_SIZE_CRITERIA_OPTIONS.includes(
                                        criteriaType
                                    )
                                        ? 0
                                        : 1
                                }
                                width={"80%"}
                                display="flex"
                                flexDirection={"column"}
                                justifyContent={"center"}
                                overflow="hidden"
                                component={Paper}
                                my={10}
                            >
                                {!!currentConcept && (
                                    <Box
                                        display="flex"
                                        px={2.5}
                                        pt={1.5}
                                        pb={0}
                                        alignItems="center"
                                    >
                                        <ConceptAvatar
                                            variant="rounded"
                                            concept={currentConcept}
                                            size={
                                                mobile ? "large" : "extra-large"
                                            }
                                        />
                                        <Box flexGrow={1} p={1} ml={1.5}>
                                            <Typography
                                                variant={
                                                    mobile ? "body1" : "h6"
                                                }
                                            >
                                                {currentConceptTranslatedConcept?.title ||
                                                    currentConcept?.title}
                                            </Typography>

                                            {!mobile && (
                                                <Typography
                                                    variant={
                                                        !isDialog
                                                            ? "caption"
                                                            : "subtitle1"
                                                    }
                                                    color="textSecondary"
                                                >
                                                    {currentConceptTranslatedConcept?.summary ||
                                                        currentConcept?.summary}
                                                </Typography>
                                            )}
                                        </Box>
                                    </Box>
                                )}

                                {!!config?.instructions && (
                                    <FieldInstructions
                                        instructions={config.instructions}
                                        isDialog={isDialog}
                                    />
                                )}
                                <Box
                                    height={
                                        VARIABLE_SIZE_CRITERIA_OPTIONS.includes(
                                            criteriaType
                                        )
                                            ? "auto"
                                            : "100%"
                                    }
                                    pb={
                                        VARIABLE_SIZE_CRITERIA_OPTIONS.includes(
                                            criteriaType
                                        )
                                            ? 3
                                            : 0
                                    }
                                    display="flex"
                                    width="100%"
                                    overflow="hidden"
                                    flexDirection={"column"}
                                    key={currentScore?.id}
                                >
                                    {
                                        <Interface
                                            score={currentScore}
                                            config={config}
                                            multiInterface={multiInterface}
                                            inputId={inputId}
                                            isDialog={isDialog}
                                            editing={editingConfig}
                                            scoredConceptId={currentConcept?.id}
                                        />
                                    }
                                </Box>
                            </Box>
                        </Slide>
                        <IconButton
                            style={{
                                padding: theme.spacing(2),
                                width: "100%",
                                zIndex: 1,
                                backgroundColor: "transparent",
                                position: "absolute",
                                bottom: 0,
                                height: 75,
                            }}
                            disabled={
                                focusedIndex === conceptsToScore.length - 1
                            }
                            onClick={handleNext}
                        >
                            <ArrowDownwardIcon
                                fontSize={
                                    !!mobile || !isDialog ? "medium" : "large"
                                }
                            />
                        </IconButton>
                    </Box>
                </Box>
            ) : showAllResponsesViewer ? (
                <Box
                    display="flex"
                    height="100%"
                    overflow="hidden"
                    width="100%"
                    style={{
                        backgroundColor: theme.palette.background.default,
                    }}
                >
                    <Box
                        className={classes.flexContainer}
                        width="100%"
                        component={Paper}
                    >
                        <Box
                            display="flex"
                            flexDirection="column"
                            overflow="hidden"
                            height="100%"
                            width="100%"
                            style={
                                VARIABLE_SIZE_CRITERIA_OPTIONS.includes(
                                    criteriaType
                                )
                                    ? {
                                          padding: theme.spacing(3),
                                          alignItems: "center",
                                          justifyContent: "center",
                                      }
                                    : {}
                            }
                        >
                            {!!config?.instructions && (
                                <FieldInstructions
                                    instructions={config.instructions}
                                    isDialog={isDialog}
                                />
                            )}
                            <Box
                                width="100%"
                                overflow="hidden"
                                height={
                                    VARIABLE_SIZE_CRITERIA_OPTIONS.includes(
                                        criteriaType
                                    )
                                        ? "auto"
                                        : "100%"
                                }
                                display="flex"
                                flexDirection={"column"}
                            >
                                <Interface
                                    multiInterface={multiInterface}
                                    score={scores?.[0]}
                                    config={config}
                                    inputId={inputId}
                                    isDialog={isDialog}
                                    editing={editingConfig}
                                    conceptsToScore={conceptsToScore}
                                    scoredConceptId={conceptId}
                                />
                            </Box>
                        </Box>
                    </Box>
                    <Box
                        className={classes.flexContainer}
                        width={
                            [
                                CriteriaType.Chart,
                                CriteriaType.Grouping,
                                CriteriaType.Ranking,
                            ].includes(criteriaType)
                                ? "30%"
                                : "100%"
                        }
                        component={Paper}
                    >
                        <ResponsePreviewer
                            inputId={inputId}
                            popper={false}
                            scoredConceptId={
                                [
                                    CriteriaType.Grouping,
                                    CriteriaType.Collection,
                                ].includes(criteriaType)
                                    ? null
                                    : conceptId
                            }
                        />
                    </Box>
                </Box>
            ) : !!isDialog ? (
                <Box
                    style={{
                        backgroundColor: theme.palette.background.default,
                    }}
                    display="flex"
                    height="100%"
                    width="100%"
                    flexDirection="column"
                    alignItems="center"
                    justifyContent="center"
                >
                    <Box
                        className={classes.flexContainer}
                        component={Paper}
                        style={
                            VARIABLE_SIZE_CRITERIA_OPTIONS.includes(
                                criteriaType
                            )
                                ? {
                                      padding: theme.spacing(3),
                                  }
                                : mobile
                                ? {
                                      height: "100%",
                                      width: "90%",
                                  }
                                : {
                                      width: "90%",
                                      height: "90%",
                                  }
                        }
                    >
                        {!!config?.instructions && (
                            <FieldInstructions
                                instructions={config.instructions}
                                isDialog={isDialog}
                            />
                        )}
                        <Interface
                            multiInterface={multiInterface}
                            score={scores?.[0]}
                            config={config}
                            inputId={inputId}
                            isDialog={isDialog}
                            editing={editingConfig}
                            conceptsToScore={conceptsToScore}
                            scoredConceptId={conceptId}
                        />
                    </Box>
                </Box>
            ) : (
                <Box
                    display="flex"
                    flexDirection="column"
                    height="100%"
                    width="100%"
                >
                    {!!config?.instructions && (
                        <FieldInstructions
                            instructions={config.instructions}
                            isDialog={isDialog}
                        />
                    )}
                    <Interface
                        multiInterface={multiInterface}
                        score={scores?.[0]}
                        config={config}
                        inputId={inputId}
                        isDialog={isDialog}
                        editing={editingConfig}
                        conceptsToScore={conceptsToScore}
                        scoredConceptId={conceptId}
                    />
                </Box>
            )}
        </Box>
    )
}

const FieldInstructions = ({ instructions, isDialog }) => {
    const theme = useTheme()
    const mobile = useMediaQuery(theme.breakpoints.down("sm"))
    return (
        <Box p={2} display="flex" pb={mobile || !isDialog ? 0 : 2}>
            <DoubleArrowIcon
                style={{
                    marginRight: 5,
                    marginLeft: 5,
                    marginTop: "0.25rem",
                }}
                color="primary"
                fontSize={mobile || !isDialog ? "small" : "default"}
            />
            <Typography variant={mobile || !isDialog ? "subtitle1" : "h6"}>
                {instructions}
            </Typography>
        </Box>
    )
}

export default BaseInputInterface
