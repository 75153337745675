import { Typography, Box } from "@material-ui/core"

import { CriteriaScore } from "../../../__generated__/types"
import { NumberType } from "../../criteria/useCriteriaTypes"
import SectionHeader from "../SectionHeader"

const NumberDeserializer = (props: {
    score: CriteriaScore
    config: NumberType
    hideHeader?: boolean
}) => {
    const { config, score, hideHeader } = props
    return (
        <Box>
            {!hideHeader && <SectionHeader headerName={config.name} />}
            {!!score && (
                <Typography variant="body1" gutterBottom>
                    {!!config.prefix ? `${config.prefix}` : ""}
                    {score?.response?.toLocaleString()}
                    {!!config.maximum ? `/${config.maximum}` : ""}
                    {!!config.suffix
                        ? config.suffix === "%"
                            ? `${config.suffix}`
                            : ` ${config.suffix}`
                        : ""}
                </Typography>
            )}
        </Box>
    )
}

export default NumberDeserializer
