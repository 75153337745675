import { makeVar } from "@apollo/client"
import {
    _ConceptOrdering,
    Input,
    User,
    CriteriaScore,
    Category,
    Concept,
} from "../__generated__/types"
import { FilterItem } from "../components/filters/util/types"
import { UserConceptPermission } from "../util/GetPermissions"
import { ScoreSelectorDefaultValues } from "../components/inputs/types"
import { Widget } from "../components/dashboard/useWidgets"

// width of navigation bar that contains workspace
export const navWidth: number = 300

export enum VIEW_OPTIONS {
    NORMAL,
    GRAPH_2D,
    GRAPH_3D,
}
export type FeedVariables = {
    filters: FilterItem[]
    viewPrivate?: boolean
    categoryId?: string | null
    categoryIds?: string[] | null
    connectionsConceptId?: string
    subConnectionCategoryId?: string
    sortBy?: _ConceptOrdering
    columnCount?: number
    pinFilters?: boolean
    editing?: boolean
    displayType?: VIEW_OPTIONS
    page?: number
}

const initialFeedVariables: FeedVariables = {
    filters: null,
    viewPrivate: true,
    sortBy: _ConceptOrdering.CREATEDAT_DESC,
    categoryId: null,
    columnCount: 3,
    pinFilters: false,
    displayType: VIEW_OPTIONS.NORMAL,
    page: 1,
}
export const feedVariablesVar = makeVar<FeedVariables>(initialFeedVariables)

export const expandWidgetVar = makeVar({})

export const INITIAL_PERMISSIONS_OBJECT = {
    viewingPermission: false,
    contributingPermission: false,
    editingPermission: false,
    owningPermission: false,
    conceptId: null,
}

const userConceptPermissions: UserConceptPermission = INITIAL_PERMISSIONS_OBJECT

export const userConceptPermissionsVar = makeVar(userConceptPermissions)

export type FeedbackField = {
    input: Input
    scores: CriteriaScore[]
}

export type WorkspaceFieldData = {
    currentResponseData: FeedbackField[]
    conceptId: string
}

const myCurrentInputResponse: WorkspaceFieldData = {
    currentResponseData: [],
    conceptId: null,
}

const allPageConceptConnections: {
    conceptId: string
    connections: Concept[]
} = {
    conceptId: null,
    connections: [],
}
export const allPageConceptConnectionsVar = makeVar(allPageConceptConnections)

export const myCurrentInputResponseVar = makeVar(myCurrentInputResponse)

export type InputResponseLabel = {
    label: string
    content?: string
    user?: User
    conceptId?: string | null
}

export type WorkspaceWidgetItem = Widget & {
    fieldData: FeedbackField
}

const currentWorkspaceWidgets: {
    conceptId: string
    orderedWidgets: WorkspaceWidgetItem[]
} = {
    conceptId: null,
    orderedWidgets: [],
}
export const currentWorkspaceWidgetsVar = makeVar(currentWorkspaceWidgets)
const myCurrentInputResponseLabel: InputResponseLabel = {
    label: ScoreSelectorDefaultValues.primaryResponse,
    user: null,
    conceptId: null,
}
export const myCurrentInputResponseLabelVar = makeVar(
    myCurrentInputResponseLabel
)
const defaultFilters: FilterItem[] = []
export const defaultFiltersVar = makeVar<FilterItem[]>(defaultFilters)

export const envCategoriesVar = makeVar<Category[]>([])

const initialWorkspaceLoadTime: number = 0
export const workspaceLoadTimeVar = makeVar<number>(initialWorkspaceLoadTime)
