import { Box, Fade } from "@material-ui/core"
import { makeStyles, Theme } from "@material-ui/core"
import { Loading } from "./Loading"

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        position: "relative",
        height: "100%",
        width: "100%",
        overflow: "hidden",
    },
    blur: {
        position: "absolute",
        display: "flex",
        height: "100%",
        width: "100%",
        justifyContent: "center",
        alignItems: "center",
        zIndex: theme.zIndex.modal,
        transition: theme.transitions.easing.easeInOut,
        transitionDuration: `${theme.transitions.duration.standard}ms`,
    },
    buttonContainer: {
        position: "absolute",
        left: 0,
        right: 0,
        bottom: 0,
        top: 0,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        zIndex: 1,
    },
    loaderContainer: {
        position: "absolute",
        display: "flex",
        height: "100%",
        width: "100%",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        zIndex: theme.zIndex.modal + 1,
    },
}))
const LoadingOverlayWrapper = (props: {
    loading: boolean
    children: React.ReactElement
    message?: string
}) => {
    const { loading, children, message } = props
    const classes = useStyles()
    return (
        <Box position="relative" height="100%" width="100%">
            <Fade in={!!loading} mountOnEnter unmountOnExit>
                <Box className={classes.loaderContainer}>
                    <Loading
                        message={message || null}
                        size={25}
                        hideQuote={true}
                    />
                </Box>
            </Fade>

            <Box
                onClick={(e) => {
                    e.stopPropagation()
                }}
                style={{
                    filter: !!loading ? "blur(5px)" : "none",
                    pointerEvents: !!loading ? "none" : "auto",
                }}
                height="100%"
            >
                {children}
            </Box>
        </Box>
    )
}

export default LoadingOverlayWrapper
