import {
    Popper,
    Divider,
    Box,
    ClickAwayListener,
    Typography,
    TextField,
    useTheme,
    InputAdornment,
} from "@material-ui/core"
import LibraryBooksIcon from "@material-ui/icons/LibraryBooks"

import React from "react"
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles"
import TreeView from "@material-ui/lab/TreeView"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import ChevronRightIcon from "@material-ui/icons/ChevronRight"
import { Category } from "../../__generated__/types"
import useAwaitTranslation from "../../i18n/useAwaitTranslation"
import useCategoryTools from "./useCategoryTools"
import CategoryIcon from "./CategoryIcon"
import CategoryItem from "./CategoryItem"

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: "100%",
            fontSize: 13,
            position: "relative",
        },

        allContent: {
            "&:hover": {
                backgroundColor: theme.palette.action.hover + " !important",
            },
            cursor: "pointer",
        },

        popper: {
            boxShadow: theme.shadows[10],
            borderRadius: 3,
            zIndex: 1800,
            fontSize: 13,
            backgroundColor: theme.palette.background.paper,
        },

        listContainer: {
            maxHeight: 400,
            overflowY: "auto",
        },
    })
)

interface CategoryTreeSelectorProps {
    value?: string | null
    setValue: (category?: Category) => void
    size?: "small" | "medium"
    saving?: boolean
    allowAll?: boolean
    allowAllText?: string
    zIndex?: number
    label?: string
    variant?: "filled" | "standard" | "outlined"
    hiddenOptions?: string[]
}
export default function CategoryTreeSelector(props: CategoryTreeSelectorProps) {
    const { value, setValue } = props
    const { environmentCategories, getCategoryArray } = useCategoryTools()
    const ref = React.useRef(null)
    const categoryArray = getCategoryArray(value)
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
    const { t: categoryTranslation } = useAwaitTranslation("categories")
    let options = environmentCategories?.filter((cat) => !!cat.isRoot)

    const theme = useTheme()
    const classes = useStyles()
    let selectedCategory: Category = null
    if (!!value) {
        selectedCategory = categoryArray?.[categoryArray?.length - 1]
    }

    React.useEffect(() => {
        if (!!environmentCategories && !selectedCategory && !props.allowAll) {
            setValue(
                environmentCategories.filter((op) => op.name === "Idea")?.[0]
            )
        }
    }, [environmentCategories, setValue, selectedCategory, props.allowAll])

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        if (!props.saving) {
            setAnchorEl(event.currentTarget)
        }
    }

    const handleClose = (event: React.ChangeEvent<{}>) => {
        setAnchorEl(null)
    }

    const open = Boolean(anchorEl)
    const id = "category-selector"

    const position = ref?.current?.getBoundingClientRect() ?? null
    const { t } = useAwaitTranslation("concepts")

    return (
        <div className={classes.root} ref={ref}>
            <TextField
                aria-describedby={id}
                onClick={handleClick}
                value={
                    selectedCategory?.name ??
                    props.allowAllText ??
                    categoryTranslation("allContent", "All Content")
                }
                variant={props.variant || "outlined"}
                size={props.size ?? "medium"}
                label={
                    <Box display="flex" alignItems="center">
                        <Box>{props.label || t("category", "Category")}</Box>
                    </Box>
                }
                disabled={!!props.saving}
                fullWidth
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            {!!value ? (
                                <CategoryIcon
                                    fontSize="small"
                                    category={categoryArray?.[0]}
                                />
                            ) : (
                                <LibraryBooksIcon fontSize="small" />
                            )}
                        </InputAdornment>
                    ),
                }}
                inputProps={{
                    readOnly: true,
                    style: { cursor: "pointer" },
                }}
            />

            <Popper
                id={id}
                open={open}
                anchorEl={anchorEl}
                placement="bottom-start"
                className={classes.popper}
                style={{
                    top: position?.top ?? 0,
                    right: position?.right ?? 0,
                    left: position?.left ?? 0,
                    width: position?.width ?? 0,
                    zIndex: props.zIndex ?? theme.zIndex.modal + 1,
                }}
            >
                <ClickAwayListener onClickAway={handleClose}>
                    <Box className={classes.listContainer}>
                        {!!props.allowAll && (
                            <>
                                <Box
                                    p={1}
                                    className={classes.allContent}
                                    onClick={() => {
                                        props.setValue(null)
                                        setAnchorEl(null)
                                    }}
                                    style={{
                                        backgroundColor: !props.value
                                            ? theme.palette.action.selected
                                            : "inherit",
                                    }}
                                >
                                    <Typography variant="body1">
                                        {props.allowAllText ??
                                            categoryTranslation(
                                                "allContent",
                                                "All Content"
                                            )}
                                    </Typography>
                                </Box>
                                <Divider />
                            </>
                        )}
                        {options.map((option, index) => {
                            return (
                                <TreeView
                                    key={option.id}
                                    defaultCollapseIcon={<ExpandMoreIcon />}
                                    defaultExpandIcon={<ChevronRightIcon />}
                                    defaultExpanded={
                                        options
                                            ?.map((item) =>
                                                item.isRoot ? item.id : null
                                            )
                                            ?.filter((x) => !!x) ?? []
                                    }
                                >
                                    <CategoryItem
                                        node={option}
                                        setValue={(value: Category) => {
                                            setValue(value)
                                            setAnchorEl(null)
                                        }}
                                        hiddenOptions={
                                            props.hiddenOptions || []
                                        }
                                        value={selectedCategory}
                                    />
                                </TreeView>
                            )
                        })}
                    </Box>
                </ClickAwayListener>
            </Popper>
        </div>
    )
}
