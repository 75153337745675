import React, { useState } from "react"
import IconButton from "@material-ui/core/IconButton"
import {
    Dialog,
    DialogContent,
    Menu,
    MenuItem,
    Box,
    Tooltip,
    ListItemText,
    ListItemIcon,
    Divider,
} from "@material-ui/core"
import LanguageIcon from "@material-ui/icons/Translate"
import MoreVertIcon from "@material-ui/icons/MoreVert"
import TextFormatIcon from "@material-ui/icons/TextFormat"
import useAwaitTranslation from "../i18n/useAwaitTranslation"
import { useHistory } from "react-router-dom"
import { QRToggle } from "./QRToggle"
import { useGlobalStyles } from "../styles/globalStyles"
import CropFreeIcon from "@material-ui/icons/CropFree"
import { Concept } from "../__generated__/types"
import LibraryAddIcon from "@material-ui/icons/LibraryAdd"
import LibraryAddCheckIcon from "@material-ui/icons/LibraryAddCheck"
import ChangeHistoryIcon from "@material-ui/icons/ChangeHistory"
import ConceptStatusHistory from "./workflows/ConceptStatusHistory"
import AlertSubscriptionToggle from "./alert-subscriptions/AlertSubscriptionToggle"
import useConceptTools from "../util/useConceptTools"
import DeleteForever from "@material-ui/icons/DeleteForever"
import ConfirmConceptDeletion from "./dialogs/ConfirmConceptDeletion"
import Header from "./dialogs/Header"
import Clear from "@material-ui/icons/Clear"
import ConceptToText from "./exporting/ConceptToText"
import {
    myCurrentInputResponseLabelVar,
    userConceptPermissionsVar,
} from "../providers/GlobalState"
import { useReactiveVar } from "@apollo/client"
import { ScoreSelectorDefaultValues } from "./inputs/types"
import useWorkspaceTools from "./workspace/useWorkspaceTools"
import { useTheme } from "@material-ui/core/styles"
interface ConceptOptionsProps {
    item: Concept
    setOpenTranslationsModal: (boolean) => void
}

const ConceptOptions: React.FC<ConceptOptionsProps> = ({
    item,
    setOpenTranslationsModal,
}) => {
    //hooks
    const globalClasses = useGlobalStyles()
    const theme = useTheme()
    const history = useHistory()
    const { editingPermission, owningPermission } = useReactiveVar(
        userConceptPermissionsVar
    )
    const { inUserWorkspace, onToggle } = useWorkspaceTools(item)
    const { t } = useAwaitTranslation("widgets")
    const currentLabel = useReactiveVar(myCurrentInputResponseLabelVar)
    const viewingAllResponses =
        currentLabel?.label === ScoreSelectorDefaultValues.allResponses &&
        !currentLabel?.user
    const { i18n } = useAwaitTranslation()
    const selectedLangString = i18n?.language
    const title =
        item.translations.find((t) => t.languageString === selectedLangString)
            ?.title || item.title

    //state
    const [openConfirm, setOpenConfirm] = useState(false)
    const [openStatusHistory, setOpenStatusHistory] = useState(false)
    const [openQR, setOpenQR] = useState(false)
    const [openTextFormat, setOpenTextFormat] = useState(false)
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
    const { deleteConcept, deleting } = useConceptTools(item)
    //mutations

    //functions
    const handleButtonClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(anchorEl ? null : event.currentTarget)
    }
    const handleDelete = async () => {
        await deleteConcept({
            variables: {
                conceptId: item.id,
            },
        })
        history.push(`/feed`)
    }

    //variables
    const open = Boolean(anchorEl)
    return (
        <>
            <Tooltip title={t("conceptOptions", "Concept options")}>
                <IconButton onClick={handleButtonClick}>
                    <MoreVertIcon />
                </IconButton>
            </Tooltip>

            {open && (
                <Menu
                    disableScrollLock
                    open={open}
                    anchorEl={anchorEl}
                    onClose={() => {
                        setAnchorEl(null)
                    }}
                    PaperProps={{
                        style: {
                            width: 300,
                        },
                    }}
                    anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
                    transformOrigin={{ vertical: "top", horizontal: "left" }}
                    getContentAnchorEl={null}
                >
                    <MenuItem onClick={onToggle}>
                        <ListItemIcon className={globalClasses.smallMenuIcon}>
                            {inUserWorkspace ? (
                                <LibraryAddCheckIcon
                                    fontSize="small"
                                    style={{
                                        color: theme.palette.primary.main,
                                    }}
                                />
                            ) : (
                                <LibraryAddIcon fontSize="small" />
                            )}
                        </ListItemIcon>
                        <ListItemText
                            primaryTypographyProps={{
                                className: globalClasses.smallMenuListItemText,
                            }}
                            primary={
                                !!inUserWorkspace
                                    ? t(
                                          "removeFromFavorites",
                                          "Remove from Favorites"
                                      )
                                    : t("addToFavorites", "Add to Favorites")
                            }
                        />
                    </MenuItem>
                    <AlertSubscriptionToggle item={item} />
                    <MenuItem
                        component="li"
                        onClick={() => {
                            setAnchorEl(null)
                            setOpenTextFormat(true)
                        }}
                        disabled={!!viewingAllResponses}
                    >
                        <ListItemIcon className={globalClasses.smallMenuIcon}>
                            <TextFormatIcon fontSize="small" />
                        </ListItemIcon>
                        <ListItemText
                            primaryTypographyProps={{
                                className: globalClasses.smallMenuListItemText,
                            }}
                            primary={t("textOnlyFormat", "Text Only Format")}
                        />
                    </MenuItem>
                    <MenuItem
                        component="li"
                        onClick={() => {
                            setAnchorEl(null)
                            setOpenStatusHistory(true)
                        }}
                    >
                        <ListItemIcon className={globalClasses.smallMenuIcon}>
                            <ChangeHistoryIcon fontSize="small" />
                        </ListItemIcon>
                        <ListItemText
                            primaryTypographyProps={{
                                className: globalClasses.smallMenuListItemText,
                            }}
                            primary={t("statusHistory", "Status History")}
                        />
                    </MenuItem>
                    <MenuItem
                        component="li"
                        onClick={() => {
                            setAnchorEl(null)
                            setOpenQR(true)
                        }}
                    >
                        <ListItemIcon className={globalClasses.smallMenuIcon}>
                            <CropFreeIcon fontSize="small" />
                        </ListItemIcon>
                        <ListItemText
                            primaryTypographyProps={{
                                className: globalClasses.smallMenuListItemText,
                            }}
                            primary={t("viewQRCode", "View QR Code")}
                        />
                    </MenuItem>

                    {editingPermission && (
                        <Box>
                            <MenuItem
                                component="li"
                                onClick={() => {
                                    setOpenTranslationsModal(true)
                                    setAnchorEl(null)
                                }}
                            >
                                <ListItemIcon
                                    className={globalClasses.smallMenuIcon}
                                >
                                    <LanguageIcon fontSize="small" />
                                </ListItemIcon>
                                <ListItemText
                                    primaryTypographyProps={{
                                        className:
                                            globalClasses.smallMenuListItemText,
                                    }}
                                    primary={t(
                                        "editTranslations",
                                        "Edit Translations"
                                    )}
                                />
                            </MenuItem>
                        </Box>
                    )}

                    {owningPermission && (
                        <Box>
                            <Divider />
                            <MenuItem
                                component="li"
                                onClick={() => {
                                    setOpenConfirm(true)
                                    setAnchorEl(null)
                                }}
                            >
                                <ListItemIcon
                                    className={globalClasses.smallMenuIcon}
                                >
                                    <DeleteForever
                                        color="error"
                                        fontSize="small"
                                    />
                                </ListItemIcon>
                                <ListItemText
                                    primaryTypographyProps={{
                                        className:
                                            globalClasses.smallMenuListItemText,
                                    }}
                                    primary={t(
                                        "deleteConcept",
                                        "Delete Concept"
                                    )}
                                />
                            </MenuItem>
                        </Box>
                    )}
                </Menu>
            )}
            {!!openConfirm && (
                <ConfirmConceptDeletion
                    concept={item}
                    onClose={() => setOpenConfirm(false)}
                    onDeletion={handleDelete}
                    deleting={deleting}
                />
            )}

            {!!openQR && <QRToggle onClose={() => setOpenQR(false)} />}
            {!!openTextFormat && (
                <ConceptToText
                    concept={item}
                    onClose={() => setOpenTextFormat(false)}
                />
            )}
            {!!openStatusHistory && (
                <Dialog
                    maxWidth="md"
                    fullWidth
                    open={true}
                    PaperProps={{
                        style: {
                            height: 700,
                        },
                    }}
                    onBackdropClick={() => setOpenStatusHistory(false)}
                >
                    <Header
                        title={title}
                        subtitle={t("statusHistory", "Status History")}
                        actions={
                            <IconButton
                                onClick={() => setOpenStatusHistory(false)}
                            >
                                <Clear />
                            </IconButton>
                        }
                    />

                    <DialogContent>
                        <ConceptStatusHistory conceptId={item.id} />
                    </DialogContent>
                </Dialog>
            )}
        </>
    )
}

export default ConceptOptions
