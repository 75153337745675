import React, { useState, useEffect } from "react"
import { useMutation } from "@apollo/client"
import Box from "@material-ui/core/Box"
import Button from "@material-ui/core/Button"
import { CriteriaType } from "./types"
import { toCapitalizedWords } from "../../util/fns"
import {
    DialogContent,
    Dialog,
    FormControl,
    Select,
    InputLabel,
    MenuItem,
    CircularProgress,
    DialogActions,
    DialogContentText,
    Typography,
    ListItemText,
    ListItemIcon,
} from "@material-ui/core"
import DeleteIcon from "@material-ui/icons/Delete"
import { Criteria } from "../../__generated__/types"
import useCriteriaTypes, {
    CRITERIA_TYPE_ICONS,
    SUB_LEVEL_CRITERIA_OPTIONS,
    TOP_LEVEL_CRITERIA_OPTIONS,
} from "./useCriteriaTypes"
import {
    CREATE_CRITERIA,
    UPDATE_CRITERIA,
    ADD_CATEGORY_CRITERIA,
    ADD_FIELD_SUBFIELD,
} from "./graphql"
import {
    CreateCriteriaMutation,
    CreateCriteriaMutationVariables,
    UpdateCriteriaMutation,
    UpdateCriteriaMutationVariables,
    AddCategoryCriteriaMutation,
    AddCategoryCriteriaMutationVariables,
    AddFieldSubfieldMutation,
    AddFieldSubfieldMutationVariables,
} from "./__generated__/graphql"
import CriteriaSchemaFormParser from "./CriteriaSchemaFormParser"
import useAwaitTranslation from "../../i18n/useAwaitTranslation"
interface CriteriaFormProps {
    criteria?: Criteria
    parent?: Criteria
    categoryId?: string
    onClose: () => void
    onSave: (criteria?: Criteria) => Promise<void>
    hideHeader?: boolean
}
const CriteriaForm = (props: CriteriaFormProps) => {
    const { t } = useAwaitTranslation("criteria")
    const [createCriteria] = useMutation<
        CreateCriteriaMutation,
        CreateCriteriaMutationVariables
    >(CREATE_CRITERIA)
    const [updateCriteria] = useMutation<
        UpdateCriteriaMutation,
        UpdateCriteriaMutationVariables
    >(UPDATE_CRITERIA)

    const [addFieldSubfield] = useMutation<
        AddFieldSubfieldMutation,
        AddFieldSubfieldMutationVariables
    >(ADD_FIELD_SUBFIELD)
    const [addCategoryCriteria] = useMutation<
        AddCategoryCriteriaMutation,
        AddCategoryCriteriaMutationVariables
    >(ADD_CATEGORY_CRITERIA)

    const [selectedCriteriaType, setSelectedCriteriaType] = useState(
        CriteriaType.TextResponse
    )
    const { defaultCriteriaConfig } = useCriteriaTypes(selectedCriteriaType)
    const [saving, setSaving] = useState(false)
    const [confirm, setConfirm] = useState(false)
    const [currentCriteriaConfig, setCurrentCriteriaConfig] = useState(null)

    useEffect(() => {
        if (!!props.criteria) {
            if (!!props.criteria.criteriaOptions) {
                const criteriaConfig = JSON.parse(
                    props.criteria?.criteriaOptions ?? "{}"
                )
                setCurrentCriteriaConfig(
                    {
                        ...defaultCriteriaConfig,
                        ...criteriaConfig,
                        name: criteriaConfig?.name ?? props.criteria.name,
                    } ?? null
                )
            }
            setSelectedCriteriaType(props.criteria.criteriaType as CriteriaType)
        } else {
            setCurrentCriteriaConfig(defaultCriteriaConfig)
        }
    }, [props.criteria, defaultCriteriaConfig])
    const handleSave = async () => {
        setSaving(true)
        if (!!props.criteria) {
            await updateCriteria({
                variables: {
                    id: props.criteria.id,
                    name: currentCriteriaConfig.name ?? props.criteria.name,
                    criteriaType: selectedCriteriaType,
                    criteriaOptions: currentCriteriaConfig
                        ? JSON.stringify(currentCriteriaConfig)
                        : JSON.stringify(defaultCriteriaConfig),
                },
            })
            await props.onSave()
        } else {
            const {
                data: { CreateCriteria: NewCriteria },
            } = await createCriteria({
                variables: {
                    name: currentCriteriaConfig.name,
                    criteriaType: selectedCriteriaType,
                    criteriaOptions: currentCriteriaConfig
                        ? JSON.stringify(currentCriteriaConfig)
                        : JSON.stringify(defaultCriteriaConfig),
                },
            })
            if (!props.parent && !!props.categoryId) {
                await addCategoryCriteria({
                    variables: {
                        criteriaId: NewCriteria.id,
                        categoryId: props.categoryId,
                    },
                })
            } else if (!!props.parent) {
                await addFieldSubfield({
                    variables: {
                        parentFieldId: props.parent.id,
                        childFieldId: NewCriteria.id,
                    },
                })
            }
            await props.onSave(NewCriteria)
        }
        props.onClose()
    }

    const handleDeletion = async () => {
        await updateCriteria({
            variables: {
                id: props.criteria?.id,
                isArchived: true,
            },
        })
        props.onClose()
    }
    const criteriaTypeOptions = !!props.parent
        ? SUB_LEVEL_CRITERIA_OPTIONS
        : TOP_LEVEL_CRITERIA_OPTIONS

    return (
        <>
            <Box>
                {!props.hideHeader && (
                    <Typography variant="h6">
                        {!!props.criteria
                            ? t("fieldEditor", "Field Editor")
                            : t("newField", "New Field")}
                    </Typography>
                )}
                <Box display="flex" flexDirection={"column"} height="100%">
                    {!props.criteria && (
                        <Box p={2}>
                            <FormControl
                                required
                                variant="outlined"
                                fullWidth
                                disabled={!!props.criteria?.criteriaType}
                                size="small"
                            >
                                <InputLabel>
                                    {t("fieldType", "Field Type")}
                                </InputLabel>
                                <Select
                                    value={selectedCriteriaType}
                                    onChange={(e) => {
                                        setSelectedCriteriaType(
                                            e.target.value as CriteriaType
                                        )
                                        setCurrentCriteriaConfig(null)
                                    }}
                                    renderValue={(value: CriteriaType) => {
                                        const icon = CRITERIA_TYPE_ICONS[value]
                                        return (
                                            <Box
                                                display="flex"
                                                alignItems="center"
                                            >
                                                {icon && icon}

                                                <Typography
                                                    style={{ marginLeft: 10 }}
                                                    variant="body1"
                                                >
                                                    {t(
                                                        value,
                                                        toCapitalizedWords(
                                                            value
                                                        )
                                                    )}
                                                </Typography>
                                            </Box>
                                        )
                                    }}
                                    label={t("fieldType", "Field Type")}
                                >
                                    {criteriaTypeOptions
                                        .sort()
                                        .map((item, index) => {
                                            const icon =
                                                CRITERIA_TYPE_ICONS[item]
                                            return (
                                                <MenuItem
                                                    value={item}
                                                    key={index}
                                                >
                                                    <ListItemIcon>
                                                        {icon && icon}
                                                    </ListItemIcon>
                                                    <ListItemText
                                                        primary={t(
                                                            item,
                                                            toCapitalizedWords(
                                                                item
                                                            )
                                                        )}
                                                    />
                                                </MenuItem>
                                            )
                                        })}
                                </Select>
                            </FormControl>
                        </Box>
                    )}
                    <CriteriaSchemaFormParser
                        criteriaId={props.criteria?.id}
                        criteriaType={selectedCriteriaType}
                        configuration={currentCriteriaConfig}
                        onChange={(config) => {
                            setCurrentCriteriaConfig(config)
                        }}
                        categoryId={props.categoryId}
                    />
                </Box>

                <Box
                    display="flex"
                    justifyContent={
                        props.criteria ? "space-between" : "flex-end"
                    }
                    alignItems="center"
                    flexGrow={1}
                    mt={2}
                >
                    {!!props.criteria && (
                        <Button
                            size="small"
                            startIcon={<DeleteIcon color="error" />}
                            disabled={!!saving}
                            onClick={() => setConfirm(true)}
                        >
                            {t("delete", "Delete")}
                        </Button>
                    )}
                    <Box
                        display="flex"
                        justifyContent="flex-end"
                        flexShrink={0}
                    >
                        <Button
                            size="small"
                            disabled={!!saving}
                            onClick={() => props.onClose()}
                        >
                            {t("cancel", "Cancel")}
                        </Button>
                        <Button
                            size="small"
                            color="primary"
                            onClick={handleSave}
                            disabled={
                                !!saving ||
                                (props.criteria?.criteriaType ===
                                    selectedCriteriaType &&
                                    JSON.stringify(currentCriteriaConfig) ===
                                        props.criteria?.criteriaOptions)
                            }
                        >
                            {!!saving ? (
                                <CircularProgress disableShrink size={25} />
                            ) : (
                                t("save", "Save")
                            )}
                        </Button>
                    </Box>
                </Box>
            </Box>
            {confirm && (
                <Dialog open={confirm}>
                    <DialogContent>
                        <DialogContentText>
                            {t(
                                "areYouSureYouWantToDeleteThisFieldForAllCategories",
                                "Are you sure you want to delete this field for all categories?"
                            )}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setConfirm(false)}>
                            {t("no", "No")}
                        </Button>
                        <Button onClick={handleDeletion}>
                            {t("yes", "Yes")}
                        </Button>
                    </DialogActions>
                </Dialog>
            )}
        </>
    )
}

export default CriteriaForm
