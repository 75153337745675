import React, { useState, useEffect } from "react"
import { makeStyles, useTheme, Theme } from "@material-ui/core/styles"
import { useQuery, useReactiveVar } from "@apollo/client"
import {
    useMediaQuery,
    Typography,
    Avatar,
    Box,
    Tooltip,
    IconButton,
    Link,
    Paper,
} from "@material-ui/core"
import SettingsIcon from "@material-ui/icons/Settings"
import { useHistory } from "react-router-dom"
import ImageIcon from "@material-ui/icons/Image"
import TeamSettings from "./TeamSettings"
import { TEAM_PROFILE_QUERY } from "./graphql"
import useAwaitTranslation from "../../i18n/useAwaitTranslation"

import { Loading } from "../Loading"
import { Team } from "../../__generated__/types"
import {
    TeamProfileQuery,
    TeamProfileQueryVariables,
} from "./__generated__/graphql"
import { useAuth } from "../../providers/AuthProvider"
import { useParams } from "react-router-dom"
import TeamMemberTable from "./TeamMemberTable"
import { userConceptPermissionsVar } from "../../providers/GlobalState"

const useStyles = makeStyles((theme) => ({
    avatar: {
        height: 100,
        width: 100,
        margin: "auto",
    },

    buttonIcon: {
        [theme.breakpoints.up("sm")]: {
            marginRight: theme.spacing(1),
        },
    },
    root: {
        overflow: "hidden",
        display: "flex",
        flexDirection: "column",
        height: "calc(100vh - 64px)",
        padding: theme.spacing(2),
    },
    userAvatar: {
        height: theme.spacing(3),
        width: theme.spacing(3),
        margin: theme.spacing(0, 0.75),
    },

    headerContainer: {},

    header: {
        display: "flex",
        flexDirection: "column",
        zIndex: theme.zIndex.appBar - 1,
    },
    toolbar: {
        //@ts-ignore
        backgroundColor: theme.palette.background.level2,
        display: "flex",
    },
}))

export default function TeamProfile(props) {
    const classes = useStyles()
    const theme: Theme = useTheme()
    const { currentUser } = useAuth()
    const { t } = useAwaitTranslation("teams")

    const mobile = useMediaQuery(theme.breakpoints.down("sm"))
    const { teamId } = useParams()
    const history = useHistory()

    const { data, loading, error, refetch } = useQuery<
        TeamProfileQuery,
        TeamProfileQueryVariables
    >(TEAM_PROFILE_QUERY, {
        variables: { teamId: teamId },
    })

    const [settings, showSettings] = useState(false)
    const team: Team = data?.Team[0] ?? null

    const permissions = useReactiveVar(userConceptPermissionsVar)
        .editingPermission

    useEffect(() => {
        if (!!team) {
            const isAdmin =
                team?.admin?.filter(
                    (user) => user.userId === currentUser?.userId
                )?.length > 0 || team.leader?.userId === currentUser?.userId
            userConceptPermissionsVar({
                viewingPermission: true,
                editingPermission: !!isAdmin,
                contributingPermission: !!isAdmin,
                owningPermission: !!isAdmin,
            })
        }
    }, [team, currentUser?.userId])
    if (loading || !team) {
        return <Loading size={25} hideQuote={true} />
    } else if (error) {
        return <Box>Could not retrieve team...</Box>
    }

    return (
        <Box className={classes.root}>
            <Box p={2} pb={1}>
                <Paper>
                    <Box display="flex" flexGrow={1} p={1}>
                        <Box p={1}>
                            <Avatar
                                className={classes.avatar}
                                alt={team?.name}
                                src={team?.imageUrl}
                            >
                                <ImageIcon />
                            </Avatar>
                        </Box>
                        <Box p={1} flexGrow={1} alignItems="center">
                            <Box marginTop={mobile ? 2 : 0}>
                                <Box flexGrow={1}>
                                    <Typography
                                        gutterBottom
                                        variant={mobile ? "h5" : "h4"}
                                    >
                                        {team?.name}
                                    </Typography>
                                </Box>
                            </Box>
                            {team?.description && (
                                <Box>
                                    <Typography variant="body1">
                                        {team.description}
                                    </Typography>
                                </Box>
                            )}
                            <Box
                                display="flex"
                                marginTop={2}
                                alignItems="center"
                            >
                                {t("teamLead", "Team Lead")}
                                :
                                <Avatar
                                    className={classes.userAvatar}
                                    alt={team?.leader?.firstName}
                                    src={team?.leader?.imageUrl}
                                />
                                <Link
                                    style={{
                                        cursor: "pointer",
                                    }}
                                    onClick={() =>
                                        history.push(
                                            `/user/${team.leader?.username}/home`
                                        )
                                    }
                                >
                                    {team?.leader?.firstName}{" "}
                                    {team?.leader?.lastName}
                                </Link>
                            </Box>
                        </Box>
                        {permissions && (
                            <Box>
                                <Tooltip
                                    title={t("teamSettings", "Team Settings")}
                                >
                                    <IconButton
                                        onClick={() => {
                                            showSettings(true)
                                        }}
                                    >
                                        <SettingsIcon />
                                    </IconButton>
                                </Tooltip>
                            </Box>
                        )}
                    </Box>
                </Paper>
            </Box>
            <TeamMemberTable team={team} />

            {settings && (
                <TeamSettings
                    team={team}
                    onClose={() => {
                        showSettings(false)
                    }}
                    onSave={async () => {
                        await refetch()
                    }}
                />
            )}
        </Box>
    )
}
