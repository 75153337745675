import { gql } from "@apollo/client"

export const SEARCH_CONCEPTS = gql`
    query SearchConcepts($searchString: String, $userId: String) {
        fuzzyConceptSearch(searchString: $searchString, userId: $userId) {
            id
            title
            translations {
                id
                languageString
                title
                summary
                description
            }
            isPublic

            category {
                id
                name
                isRoot
            }
            createdBy {
                userId
            }
            imageUrl
            summary
            description
            childConceptLists {
                id
                title
            }
        }
    }
`
export const ADVANCED_SEARCH_CONCEPTS = gql`
    query AdvancedSearchConcepts(
        $searchString: String
        $first: Int
        $offset: Int
        $userId: String
    ) {
        fuzzyConceptSearch(
            searchString: $searchString
            first: $first
            offset: $offset
            userId: $userId
        ) {
            id
            title
            category {
                id
                name
                isRoot
            }
            summary
            description
            isPublic
            imageUrl
            commentCount
            createdAt {
                formatted
            }
            usedByScore {
                id
            }
            createdBy {
                userId
                username
                firstName
                lastName
                imageUrl
            }
            tags(orderBy: name_asc) {
                name
            }
        }
    }
`
export const ADVANCED_SEARCH_CONCEPTS_BY_CATEGORY = gql`
    query AdvancedSearchConceptsByCategory(
        $searchString: String
        $first: Int
        $offset: Int
        $categoryIds: [ID]
        $userId: String
    ) {
        fuzzyConceptSearchByCategory(
            searchString: $searchString
            first: $first
            offset: $offset
            categoryIds: $categoryIds
            userId: $userId
        ) {
            id
            title
            isPublic
            rootCategory {
                id
                name
                isRoot
            }
            category {
                id
                name
                isRoot
            }
            createdBy {
                userId
            }
            imageUrl
            summary
            description
        }
    }
`
const CREATE_CONCEPT_LIST = gql`
    mutation CreateConceptList(
        $title: String
        $type: ConceptListType
        $isOrdered: Boolean
    ) {
        CreateConceptList(title: $title, type: $type, isOrdered: $isOrdered) {
            id
            title
            type
            isOrdered
            conceptIds
            user {
                userId
                firstName
                lastName
            }
            team {
                teamId
                name
            }
            concepts {
                id
                title
                translations {
                    id
                    languageString
                    title
                    summary
                    description
                }
                category {
                    id
                    name
                    isRoot
                    icon
                }
                summary
                description
                imageUrl
                likedBy {
                    userId
                }
                createdAt {
                    formatted
                }
                createdBy {
                    userId
                    firstName
                    lastName
                    imageUrl
                }
                connections {
                    id
                    category {
                        id
                        name
                        isRoot
                    }
                    title
                    imageUrl
                }

                tags(orderBy: name_asc) {
                    name
                }
            }
        }
    }
`

const CREATE_USER_WORKSPACE = gql`
    mutation CreateUserWorkspace($userId: ID) {
        CreateUserWorkspace(userId: $userId) {
            id
            title
            type
            isOrdered
            conceptIds
            concepts {
                id
                title
                translations {
                    id
                    languageString
                    title
                }
                category {
                    id
                    name
                    isRoot
                    icon
                }
                imageUrl
            }
            user {
                userId
                conceptLists {
                    id
                    title
                    type
                    conceptIds
                    concepts {
                        id
                        title
                        category {
                            id
                            name
                            isRoot
                        }
                        imageUrl
                    }
                }
            }
        }
    }
`

const CREATE_TEAM_WORKSPACE = gql`
    mutation CreateTeamWorkspace($teamId: ID) {
        CreateTeamWorkspace(teamId: $teamId) {
            id
            title
            type
            conceptIds
            concepts {
                id
                title
                translations {
                    id
                    languageString
                    title
                }
                category {
                    id
                    name
                    isRoot
                    icon
                }
                imageUrl
            }
            team {
                teamId
                conceptLists {
                    id
                    title
                    type
                    conceptIds
                    concepts {
                        id
                        title
                        translations {
                            id
                            languageString
                            title
                        }
                        category {
                            id
                            name
                            isRoot
                        }
                        imageUrl
                    }
                }
            }
        }
    }
`

const GET_CONCEPT_LIST = gql`
    query GetConceptListComplete($id: ID, $conceptsFilter: _ConceptFilter) {
        ConceptList(id: $id) {
            id
            title
            type
            isOrdered
            conceptIds
            user {
                userId
                firstName
                lastName
            }
            team {
                teamId
                name
            }
            concepts(filter: $conceptsFilter) {
                id
                title
                translations {
                    id
                    languageString
                    title
                    summary
                    description
                }
                category {
                    id
                    name
                    isRoot
                    icon
                }
                summary
                description
                imageUrl
                likedBy {
                    userId
                }
                createdAt {
                    formatted
                }
                createdBy {
                    userId
                    firstName
                    lastName
                    imageUrl
                }
                connections {
                    id
                    category {
                        id
                        name
                        isRoot
                    }
                    title
                    imageUrl
                }
                children(filter: $conceptsFilter) {
                    id
                    category {
                        id
                        name
                        isRoot
                    }
                    title
                    imageUrl
                }
                parent {
                    id
                    category {
                        id
                        name
                        isRoot
                    }
                    title
                    imageUrl
                }
                tags(orderBy: name_asc) {
                    name
                }
            }
        }
    }
`

const CONCEPT_LIST_UPDATE = gql`
    mutation UpdateConceptList($id: ID!, $title: String, $conceptIds: [ID]) {
        UpdateConceptList(id: $id, title: $title, conceptIds: $conceptIds) {
            id
            title
            type
            isOrdered
            conceptIds
            user {
                userId
                firstName
                lastName
            }
            team {
                teamId
                name
            }
            concepts {
                id
                title
                translations {
                    id
                    languageString
                    title
                    summary
                    description
                }
                category {
                    id
                    name
                    isRoot
                    icon
                }
                summary
                description
                imageUrl
                likedBy {
                    userId
                }
                createdAt {
                    formatted
                }
                createdBy {
                    userId
                    firstName
                    lastName
                    imageUrl
                }
                connections {
                    id
                    category {
                        id
                        name
                        isRoot
                    }
                    title
                }
            }
        }
    }
`

const ADD_CONCEPT_TO_LIST = gql`
    mutation AddConceptInLists($from: _ConceptInput!, $to: _ConceptListInput!) {
        AddConceptInLists(from: $from, to: $to) {
            from {
                id
            }
            to {
                id
                title
                type
                isOrdered
                conceptIds
                concepts {
                    id
                }
            }
        }
    }
`

const REMOVE_CONCEPT_FROM_LIST = gql`
    mutation RemoveConceptInLists(
        $from: _ConceptInput!
        $to: _ConceptListInput!
    ) {
        RemoveConceptInLists(from: $from, to: $to) {
            from {
                id
            }
            to {
                id
                title
                type
                isOrdered
                conceptIds
                concepts {
                    id
                }
            }
        }
    }
`
const DELETE_CONCEPT_LIST = gql`
    mutation DeleteConceptList($id: ID!) {
        DeleteConceptList(id: $id) {
            id
        }
    }
`
const USER_LISTS_QUERY = gql`
    query USER_CONCEPT_LIST($userId: ID!) {
        User(userId: $userId) {
            userId
            conceptLists {
                id
                title
                type
            }
            teams {
                teamId
                name
                conceptLists {
                    id
                    title
                    type
                }
            }
        }
    }
`
const TREND_CONCEPT_LIST_QUERY = gql`
    query GetConceptList($id: ID) {
        ConceptList(id: $id) {
            id
            title
            type
            isOrdered
            conceptIds
            user {
                userId
                firstName
                lastName
            }
            team {
                teamId
                name
            }
            concepts {
                id
                title
                translations {
                    id
                    languageString
                    title
                    summary
                }
                category {
                    id
                    name
                    isRoot
                    icon
                }
                summary
                description
                imageUrl
            }
        }
    }
`

export {
    CREATE_CONCEPT_LIST,
    CREATE_USER_WORKSPACE,
    CREATE_TEAM_WORKSPACE,
    GET_CONCEPT_LIST,
    ADD_CONCEPT_TO_LIST,
    REMOVE_CONCEPT_FROM_LIST,
    CONCEPT_LIST_UPDATE,
    DELETE_CONCEPT_LIST,
    USER_LISTS_QUERY,
    TREND_CONCEPT_LIST_QUERY,
}
