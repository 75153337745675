import React, { useEffect, useMemo, useState } from "react"
import { useQuery, useReactiveVar } from "@apollo/client"
import {
    Box,
    CircularProgress,
    Typography,
    IconButton,
    Grow,
    List,
    ListItem,
    ListItemText,
    TextField,
    ListItemIcon,
    Divider,
    Button,
    Fade,
    Avatar,
    InputAdornment,
    useMediaQuery,
    Popper,
    Paper,
    ClickAwayListener,
    Tooltip,
} from "@material-ui/core"
import PersonAddIcon from "@material-ui/icons/PersonAdd"
import AssignmentIndIcon from "@material-ui/icons/AssignmentInd"
import GroupIcon from "@material-ui/icons/Group"
import { Autocomplete } from "@material-ui/lab"
import useAwaitTranslation from "../../i18n/useAwaitTranslation"
import { useAuth } from "../../providers/AuthProvider"
import { CONCEPT_INPUTS, RESPONSE_POLLING } from "./graphql"
import {
    ConceptInputsQuery,
    ConceptInputsQueryVariables,
    ResponsePollingQuery,
    ResponsePollingQueryVariables,
} from "./__generated__/graphql"
import { useParams } from "react-router-dom"
import { useTheme } from "@material-ui/core/styles"
import {
    Concept,
    CriteriaScore,
    _CriteriaScoreFilter,
} from "../../__generated__/types"
import CheckIcon from "@material-ui/icons/Check"
import {
    allPageConceptConnectionsVar,
    currentWorkspaceWidgetsVar,
    expandWidgetVar,
    InputResponseLabel,
    myCurrentInputResponseLabelVar,
    myCurrentInputResponseVar,
    userConceptPermissionsVar,
    workspaceLoadTimeVar,
} from "../../providers/GlobalState"
import { useScoreLabelTools } from "./useScoreLabelTools"
import DonutLarge from "@material-ui/icons/DonutLarge"
import CheckCircle from "@material-ui/icons/CheckCircle"
import ClickableRichTooltip from "../Popper/ClickableRichTooltip"
import ResponseListItem from "./ResponseListItem"
import { ScoreSelectorDefaultValues } from "./types"
import useEnvironmentSettingTools from "../settings/useEnvironmentSettingTools"
import { WidgetTypeName } from "../dashboard/useWidgetTypes"
import FieldsGenerator from "./FieldsGenerator"
import {
    getCleanedFieldConfig,
    getLabelOptions,
    getWorkspaceCompletionData,
} from "./util"
import { getUserConceptPermission } from "../../util/GetPermissions"

export const POLLING_INTERVAL_SECONDS = 5
interface InputResponseTrackerProps {
    concept: Concept
}

const InputResponseTracker: React.FunctionComponent<
    InputResponseTrackerProps
> = ({ concept }) => {
    const { conceptId: pageConceptId } = useParams()
    const theme = useTheme()
    const mobile = useMediaQuery(theme.breakpoints.down("xs"))
    const { currentUser, isNonMember } = useAuth()

    const { t } = useAwaitTranslation("feedback")

    const { primaryResponseText } = useEnvironmentSettingTools()
    const { contributingPermission, editingPermission } = useReactiveVar(
        userConceptPermissionsVar
    )

    const currentLabel = useReactiveVar(myCurrentInputResponseLabelVar)
    const currentWorkspaceWidgets = useReactiveVar(currentWorkspaceWidgetsVar)
    const firstWidgetId = currentWorkspaceWidgets?.orderedWidgets?.[0]?.id
    const initialWorkspaceLoadTime = useReactiveVar(workspaceLoadTimeVar)
    const viewingPrimaryResponse =
        !currentLabel?.user &&
        currentLabel?.label === ScoreSelectorDefaultValues.primaryResponse
    const viewingAllResponses =
        currentLabel?.label === ScoreSelectorDefaultValues.allResponses &&
        !currentLabel.user
    const { currentResponseData, conceptId: fieldDataConceptId } =
        useReactiveVar(myCurrentInputResponseVar)

    const { connections } = useReactiveVar(allPageConceptConnectionsVar)
    const criteriaScoreFilter = useMemo(() => {
        let base: _CriteriaScoreFilter = {
            input: {
                parentConcept: {
                    id: pageConceptId,
                },
            },
            user_not: null,
        }
        if (fieldDataConceptId === pageConceptId) {
            // poll for all responses that have been updated in last 30 seconds
            const date = new Date(Date.now() - 30 * 1000)

            base = {
                ...base,
                lastUpdated_gt: {
                    year: date.getUTCFullYear(),
                    month: date.getUTCMonth() + 1,
                    day: date.getUTCDate(),
                    hour: date.getUTCHours(),
                    minute: date.getUTCMinutes(),
                    second: 0,
                },
                lastUpdatedByUserId_not: currentUser.userId,
            }
        } else {
            base = {
                ...base,
                isArchived: null,
            }
        }
        return base
    }, [pageConceptId, fieldDataConceptId, currentUser.userId])

    const {
        data: { CriteriaScore: scores } = {},
        stopPolling,
        startPolling,
    } = useQuery<ResponsePollingQuery, ResponsePollingQueryVariables>(
        RESPONSE_POLLING,
        {
            variables: {
                filter: criteriaScoreFilter,
            },
            fetchPolicy: "cache-and-network",
            nextFetchPolicy: "cache-first",
            returnPartialData: true,
        }
    )
    const { data: { Input: inputs } = {} } = useQuery<
        ConceptInputsQuery,
        ConceptInputsQueryVariables
    >(CONCEPT_INPUTS, {
        variables: {
            filter: {
                parentConcept: {
                    id: pageConceptId,
                },
            },
        },
        fetchPolicy: "cache-and-network",
        nextFetchPolicy: "cache-first",
    })

    useEffect(() => {
        if (!!scores && currentLabel.conceptId !== pageConceptId) {
            const usersResponse =
                scores.find(
                    (score) =>
                        score.user?.userId === currentUser?.userId &&
                        !score.label &&
                        !score.conceptDefault
                ) ||
                scores.find(
                    (score) =>
                        score.user?.userId === currentUser?.userId &&
                        !score.conceptDefault
                )
            const {
                editingPermission,
                owningPermission,
                contributingPermission,
            } = getUserConceptPermission(concept, currentUser)
            const contributorOnly =
                !editingPermission &&
                !owningPermission &&
                !!contributingPermission
            if (!!usersResponse || !!contributorOnly) {
                myCurrentInputResponseLabelVar({
                    label: usersResponse?.label || null,
                    user: currentUser,
                    conceptId: pageConceptId,
                })
            } else {
                myCurrentInputResponseLabelVar({
                    label: ScoreSelectorDefaultValues.primaryResponse,
                    content: primaryResponseText,
                    user: null,
                    conceptId: pageConceptId,
                })
            }
        }
    }, [
        pageConceptId,
        currentLabel,
        currentUser,
        scores,
        primaryResponseText,
        concept,
    ])
    useEffect(() => {
        if (!!inputs && !!scores) {
            const responseData = inputs.map((input) => {
                return {
                    input: input,
                    scores: scores.filter(
                        (score) => score.input?.id === input.id
                    ),
                }
            })

            myCurrentInputResponseVar({
                currentResponseData: responseData,
                conceptId: pageConceptId,
            })

            if (responseData.length > 0) {
                startPolling(POLLING_INTERVAL_SECONDS * 1000)
            } else {
                stopPolling()
            }
        }
    }, [inputs, pageConceptId, scores, startPolling, stopPolling])

    useEffect(() => {
        if (isNonMember) {
            expandWidgetVar({
                [pageConceptId]: firstWidgetId,
            })
        }
    }, [isNonMember, firstWidgetId, pageConceptId])

    if (
        currentResponseData.length === 0 ||
        fieldDataConceptId !== pageConceptId ||
        currentLabel.conceptId !== pageConceptId ||
        currentWorkspaceWidgets.conceptId !== pageConceptId ||
        !currentWorkspaceWidgets.orderedWidgets.find(
            (widgetItem) =>
                widgetItem.type === WidgetTypeName.Criteria &&
                !!widgetItem.fieldData
        )
    ) {
        return <Box width="100%" />
    }

    const hasOtherResponses = scores?.find((score) => !score.conceptDefault)

    const sortedLabelOptions: InputResponseLabel[] = getLabelOptions(
        scores,
        currentLabel,
        primaryResponseText,
        pageConceptId
    )

    const {
        percentComplete,
        totalFields,
        totalFieldsCompleted,
        orderedFields,
    } = getWorkspaceCompletionData(
        currentWorkspaceWidgets.orderedWidgets
            ?.filter((x) => !!x.fieldData)
            ?.map((o) => o.fieldData) ?? [],
        currentLabel,
        connections,
        initialWorkspaceLoadTime
    )
    const fieldsAllowingMultipleResponses =
        currentResponseData.filter((item) => {
            const { allowMultipleResponses } = getCleanedFieldConfig(item)
            return allowMultipleResponses !== false
        }) || []

    return (
        <Fade
            mountOnEnter
            unmountOnExit
            in={
                currentResponseData.length > 0 &&
                fieldDataConceptId === pageConceptId
            }
        >
            <Box
                display="flex"
                width={"100%"}
                justifyContent="flex-start"
                zIndex={theme.zIndex.appBar - 2}
            >
                <Box
                    flexGrow={1}
                    display="flex"
                    width="100%"
                    alignItems={"center"}
                    justifyContent="flex-start"
                >
                    <ClickableRichTooltip
                        arrow={true}
                        hide={viewingAllResponses}
                        placement={"bottom-start"}
                        content={
                            <Box minWidth={"30em"}>
                                <Box display={"flex"} flexDirection="column">
                                    <Box
                                        display="flex"
                                        alignItems="center"
                                        p={1}
                                    >
                                        <Box flexGrow={1}>
                                            <Box p={0.25}>
                                                <Typography
                                                    variant="body1"
                                                    noWrap
                                                >
                                                    {currentLabel?.label ??
                                                        `${currentLabel?.user?.firstName} ${currentLabel?.user?.lastName}`}
                                                </Typography>
                                                <Typography
                                                    color="textSecondary"
                                                    variant="body2"
                                                >
                                                    Current Fields{" "}
                                                    {`(${totalFieldsCompleted}/${totalFields})`}
                                                </Typography>
                                            </Box>
                                        </Box>
                                    </Box>
                                    <Divider />
                                    <Box
                                        display={"flex"}
                                        width="100%"
                                        overflow="hidden"
                                        alignItems="center"
                                    >
                                        <List
                                            dense
                                            disablePadding
                                            style={{
                                                width: "100%",
                                                maxHeight: 400,
                                                overflowY: "auto",
                                            }}
                                        >
                                            {orderedFields.map((field) => {
                                                const {
                                                    name: fieldName,
                                                    source,
                                                } = getCleanedFieldConfig(field)
                                                const sourceField =
                                                    currentResponseData.find(
                                                        (item) =>
                                                            !!source?.criteriaIds?.find(
                                                                (x) =>
                                                                    x.id ===
                                                                    item.input
                                                                        .criteria
                                                                        ?.id
                                                            )
                                                    )
                                                const {
                                                    name: sourceFieldName,
                                                } =
                                                    getCleanedFieldConfig(
                                                        sourceField
                                                    )
                                                return (
                                                    <ListItem
                                                        key={field.input.id}
                                                        button
                                                        onClick={() => {
                                                            document
                                                                .getElementById(
                                                                    field.input
                                                                        .id
                                                                )
                                                                ?.scrollIntoView(
                                                                    {
                                                                        behavior:
                                                                            "smooth",
                                                                        block: "nearest",
                                                                    }
                                                                )
                                                        }}
                                                    >
                                                        <ListItemIcon>
                                                            {!!field.isCompleted ? (
                                                                <CheckCircle
                                                                    fontSize="small"
                                                                    color="primary"
                                                                />
                                                            ) : (
                                                                <DonutLarge
                                                                    fontSize="small"
                                                                    color="secondary"
                                                                />
                                                            )}
                                                        </ListItemIcon>
                                                        <ListItemText>
                                                            <Typography
                                                                variant="body2"
                                                                color="textSecondary"
                                                            >
                                                                {sourceFieldName ??
                                                                    ""}
                                                            </Typography>
                                                            <Typography variant="body1">
                                                                {!!field.input
                                                                    ?.criteria
                                                                    ? fieldName ??
                                                                      ""
                                                                    : t(
                                                                          "criteriaDeleted",
                                                                          "This criteria has been deleted"
                                                                      )}
                                                            </Typography>
                                                        </ListItemText>
                                                    </ListItem>
                                                )
                                            })}
                                        </List>
                                    </Box>
                                </Box>
                            </Box>
                        }
                    >
                        <Box
                            display="flex"
                            height={0}
                            justifyContent="flex-end"
                        >
                            <Box display="flex" alignItems="center" ml={1.5}>
                                <Box display="flex">
                                    <Box
                                        position="relative"
                                        display="inline-flex"
                                        alignItems={"center"}
                                    >
                                        <IconButton
                                            style={{
                                                zIndex: theme.zIndex.appBar - 2,
                                            }}
                                            color={
                                                percentComplete === 100
                                                    ? "primary"
                                                    : "default"
                                            }
                                            disabled={viewingAllResponses}
                                        >
                                            <CircularProgress
                                                size={40}
                                                value={
                                                    viewingAllResponses
                                                        ? 0
                                                        : percentComplete
                                                }
                                                variant="determinate"
                                            />
                                            <CircularProgress
                                                size={40}
                                                style={{
                                                    position: "absolute",
                                                    color: theme.palette
                                                        .divider,
                                                }}
                                                value={100}
                                                variant="determinate"
                                            />
                                        </IconButton>

                                        <Box
                                            style={{
                                                top: 0,
                                                left: 0,
                                                bottom: 0,
                                                right: 0,
                                                position: "absolute",
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "center",
                                            }}
                                        >
                                            <Grow
                                                mountOnEnter
                                                unmountOnExit
                                                in={true}
                                            >
                                                {viewingAllResponses ? (
                                                    <Box>-</Box>
                                                ) : percentComplete === 100 ? (
                                                    <CheckIcon fontSize="small" />
                                                ) : (
                                                    <Typography
                                                        variant="caption"
                                                        component="div"
                                                        color="textSecondary"
                                                    >{`${Math.round(
                                                        isNaN(percentComplete)
                                                            ? 0
                                                            : percentComplete
                                                    )}%`}</Typography>
                                                )}
                                            </Grow>
                                        </Box>
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                    </ClickableRichTooltip>
                    {(!!hasOtherResponses ||
                        !viewingPrimaryResponse ||
                        !!contributingPermission) && (
                        <Box
                            width="100%"
                            mr={!!mobile ? 2.5 : 1}
                            ml={1}
                            display="flex"
                            alignItems={"center"}
                        >
                            {(!!hasOtherResponses ||
                                !viewingPrimaryResponse) && (
                                <Autocomplete
                                    options={sortedLabelOptions}
                                    size="small"
                                    fullWidth
                                    openOnFocus
                                    value={currentLabel}
                                    onChange={(
                                        _: React.ChangeEvent<{}>,
                                        newValue: InputResponseLabel
                                    ) => {
                                        myCurrentInputResponseLabelVar(newValue)
                                    }}
                                    disableClearable
                                    renderOption={(
                                        option: InputResponseLabel,
                                        index
                                    ) => {
                                        return (
                                            <ResponseListItem
                                                key={JSON.stringify(index)}
                                                item={option}
                                                isConceptDefault={
                                                    option.label ===
                                                        ScoreSelectorDefaultValues.primaryResponse &&
                                                    !option.user
                                                }
                                            />
                                        )
                                    }}
                                    getOptionLabel={(
                                        option: InputResponseLabel
                                    ) =>
                                        !!option.content
                                            ? option.content
                                            : !!option.label
                                            ? option.label
                                            : `${option.user.firstName} ${option.user.lastName}`
                                    }
                                    getOptionSelected={(
                                        option: InputResponseLabel
                                    ) =>
                                        !currentLabel?.user
                                            ? currentLabel.label ===
                                              option.label
                                            : !currentLabel.label
                                            ? !option.label &&
                                              option.user?.userId ===
                                                  currentLabel.user?.userId
                                            : currentLabel.label ===
                                                  option.label &&
                                              currentLabel.user?.userId ===
                                                  option.user?.userId
                                    }
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            InputProps={{
                                                ...params.InputProps,
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <Avatar
                                                            style={{
                                                                width: 30,
                                                                height: 30,
                                                                backgroundColor:
                                                                    viewingPrimaryResponse
                                                                        ? theme
                                                                              .palette
                                                                              .secondary
                                                                              .main
                                                                        : theme
                                                                              .palette
                                                                              .primary
                                                                              .main,
                                                            }}
                                                            src={
                                                                !!viewingPrimaryResponse ||
                                                                !!currentLabel.label
                                                                    ? null
                                                                    : currentLabel
                                                                          .user
                                                                          ?.imageUrl
                                                            }
                                                        >
                                                            {!!viewingPrimaryResponse ? (
                                                                <AssignmentIndIcon fontSize="small" />
                                                            ) : !!viewingAllResponses ? (
                                                                <GroupIcon fontSize="small" />
                                                            ) : currentLabel.label ? (
                                                                currentLabel
                                                                    .label[0]
                                                            ) : (
                                                                currentLabel
                                                                    .user
                                                                    ?.firstName[0]
                                                            )}
                                                        </Avatar>
                                                    </InputAdornment>
                                                ),
                                            }}
                                            variant="outlined"
                                            size="small"
                                        />
                                    )}
                                />
                            )}
                            {!isNonMember &&
                                ((!!viewingPrimaryResponse &&
                                    !!editingPermission) ||
                                    (currentUser?.userId ===
                                        currentLabel.user?.userId &&
                                        !!contributingPermission)) && (
                                    <FieldsGenerator concept={concept} />
                                )}
                            {!isNonMember &&
                                contributingPermission &&
                                fieldsAllowingMultipleResponses.length > 0 && (
                                    <LabelPopper scores={scores} />
                                )}
                        </Box>
                    )}
                </Box>
            </Box>
        </Fade>
    )
}

const LabelPopper = (props: { scores: CriteriaScore[] }) => {
    const { scores } = props
    const { conceptId } = useParams()
    const { currentUser } = useAuth()
    const theme = useTheme()
    const { t } = useAwaitTranslation("feedback")

    const { label, setLabel, errorMessage, userHasSubmittedOwnResponse } =
        useScoreLabelTools(scores)

    //state
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
    //functions
    const handleButtonClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(anchorEl ? null : event.currentTarget)
    }
    const handleCreation = () => {
        myCurrentInputResponseLabelVar({
            label: label?.length > 0 ? label : null,
            user: currentUser,
            conceptId: conceptId,
        })
        setAnchorEl(null)
        setLabel("")
    }
    //variables
    const open = Boolean(anchorEl)

    return (
        <>
            <Tooltip title={t("provideResponse", "Provide Response")}>
                <IconButton onClick={handleButtonClick}>
                    <PersonAddIcon />
                </IconButton>
            </Tooltip>
            <Popper
                style={{ zIndex: theme.zIndex.appBar - 1 }}
                open={open}
                anchorEl={anchorEl}
                placement="bottom-start"
            >
                <ClickAwayListener onClickAway={() => setAnchorEl(null)}>
                    <Paper>
                        <Box
                            display="flex"
                            flexDirection={"column"}
                            width={"20rem"}
                        >
                            <Box
                                p={2}
                                pb={0}
                                display="flex"
                                alignItems="center"
                            >
                                <Typography
                                    style={{
                                        flexGrow: 1,
                                    }}
                                    variant="subtitle1"
                                    color="textSecondary"
                                >
                                    {t("newResponse", "New Response")}
                                </Typography>
                            </Box>

                            <Box p={2}>
                                <TextField
                                    required={!!userHasSubmittedOwnResponse}
                                    color="secondary"
                                    autoFocus={true}
                                    variant="outlined"
                                    fullWidth
                                    label={t("label", "Label")}
                                    value={label ?? ""}
                                    onChange={(e) => {
                                        setLabel(e.target.value)
                                    }}
                                    onKeyDown={(e) => {
                                        if (
                                            e.key === "Enter" &&
                                            !errorMessage &&
                                            label.length !== 0
                                        ) {
                                            handleCreation()
                                        }
                                    }}
                                    size="small"
                                    error={!!errorMessage}
                                    helperText={
                                        errorMessage
                                            ? errorMessage
                                            : !userHasSubmittedOwnResponse
                                            ? "Leave blank to show your name as the label"
                                            : ""
                                    }
                                />
                            </Box>
                            <Box
                                p={1}
                                pt={0}
                                mr={1}
                                display="flex"
                                justifyContent="flex-end"
                            >
                                <Button
                                    disabled={
                                        !!errorMessage ||
                                        (!!userHasSubmittedOwnResponse &&
                                            label.length === 0)
                                    }
                                    color="primary"
                                    size="small"
                                    onClick={handleCreation}
                                >
                                    {t("create", "Create")}
                                </Button>
                            </Box>
                        </Box>
                    </Paper>
                </ClickAwayListener>
            </Popper>
        </>
    )
}

export default React.memo(InputResponseTracker)
