import {
    ELEMENT_HR,
    ELEMENT_IMAGE,
    ELEMENT_MEDIA_EMBED,
    SelectOnBackspacePlugin,
} from "@udecode/plate"
import { MyPlatePlugin } from "../typescript/plateTypes"

export const selectOnBackspacePlugin: Partial<
    MyPlatePlugin<SelectOnBackspacePlugin>
> = {
    options: {
        query: {
            allow: [ELEMENT_IMAGE, ELEMENT_HR, ELEMENT_MEDIA_EMBED],
        },
    },
}
