import { useState, useEffect } from "react"
import { makeStyles } from "@material-ui/core/styles"
import {
    Toolbar,
    useTheme,
    Divider,
    Hidden,
    Box,
    SwipeableDrawer,
    IconButton,
    alpha,
} from "@material-ui/core"
import Workspace from "./workspace/Workspace"
import useMediaQuery from "@material-ui/core/useMediaQuery"
import { useHistory } from "react-router-dom"
import Logo from "./Logo"
import { Resizable } from "react-resizable"
import { useAuth } from "../providers/AuthProvider"
import MenuIcon from "@material-ui/icons/Menu"

const useStyles = makeStyles((theme) => ({
    drawer: {
        maxWidth: "100vw",
        flexShrink: 0,
        zIndex: theme.zIndex.appBar - 1,
        overflow: "hidden",
        //@ts-ignore
        backgroundColor: theme.palette.background.level2,
    },
    closedDrawer: {
        zIndex: theme.zIndex.appBar - 1,
        width: 0,
        flexShrink: 0,
    },
    selected: {
        borderRight: "solid 5px",
        color: theme.palette.primary.main,
    },
    selectedColor: {
        color: theme.palette.primary.main,
    },
    logoToolbar: {
        minHeight: theme.spacing(8),
        backgroundColor: alpha(theme.palette.primary.main, 0.15),
        display: "flex",
        justifyContent: "space-between",
    },
    handle: {
        position: "absolute",
        right: 0,
        top: 0,
        height: "100vh",
        cursor: "ew-resize",
        width: "1em",
    },
}))
const DRAWER_WIDTH = 300
interface NavDrawerProps {
    drawerOpen: boolean
    toggleDrawer: (state: boolean) => void
}

const NavDrawer = (props: NavDrawerProps) => {
    const { currentUser, hasAccess, isNonMember } = useAuth()
    const classes = useStyles()
    const theme = useTheme()
    const mobile = useMediaQuery(theme.breakpoints.down("sm"))
    const [width, setWidth] = useState(DRAWER_WIDTH)

    const history = useHistory()

    const onResize = (event, { element, size, handle }) => {
        if (!mobile) {
            setWidth(size.width)
        }
    }

    useEffect(() => {
        if (mobile) {
            setWidth(DRAWER_WIDTH + 100)
        }
    }, [mobile])

    if (!!currentUser && !!hasAccess && !isNonMember) {
        return (
            <div>
                <SwipeableDrawer
                    anchor="left"
                    className={
                        props.drawerOpen ? classes.drawer : classes.closedDrawer
                    }
                    onOpen={() => props.toggleDrawer(true)}
                    onClose={() => {
                        props.toggleDrawer(false)
                    }}
                    style={{
                        width: props.drawerOpen ? width : 0,
                    }}
                    variant={mobile ? "temporary" : "persistent"}
                    open={props.drawerOpen}
                    classes={{
                        paper: classes.drawer,
                    }}
                    PaperProps={{
                        style: {
                            width: props.drawerOpen ? width : 0,
                        },
                    }}
                >
                    <Resizable
                        height={Infinity}
                        width={width}
                        handle={<Box className={classes.handle} />}
                        onResize={onResize}
                        minConstraints={[DRAWER_WIDTH - 50, 100]}
                        maxConstraints={[650, Infinity]}
                    >
                        <>
                            {!mobile && <Toolbar />}
                            <Hidden mdUp>
                                <Toolbar className={classes.logoToolbar}>
                                    <IconButton
                                        onClick={() => {
                                            props.toggleDrawer(false)
                                        }}
                                    >
                                        <MenuIcon />
                                    </IconButton>

                                    <Box
                                        onClick={() => {
                                            history.push(`/feed`)
                                            props.toggleDrawer(false)
                                        }}
                                    >
                                        <Logo />
                                    </Box>
                                </Toolbar>
                                <Divider />
                            </Hidden>
                            {!!currentUser?.userId && (
                                <Workspace
                                    closeDrawer={() =>
                                        props.toggleDrawer(false)
                                    }
                                />
                            )}
                        </>
                    </Resizable>
                </SwipeableDrawer>
            </div>
        )
    } else {
        return null
    }
}

export default NavDrawer
