import { Box, Button } from "@material-ui/core"
import { FeedbackField } from "../../providers/GlobalState"
import DonutLarge from "@material-ui/icons/DonutLarge"
import CheckCircle from "@material-ui/icons/CheckCircle"

interface FieldMessageProps {
    field: FeedbackField
    fieldsWaitingOn: FeedbackField[]
}

const FieldMessage: React.FunctionComponent<FieldMessageProps> = ({
    field,
    fieldsWaitingOn,
}) => {
    const config = JSON.parse(field.input.inputConfig ?? "{}")
    const stillWaiting = fieldsWaitingOn.find(
        (x) => x.input.id === field.input.id
    )

    return (
        <Box key={field.input?.id} display="flex" alignItems="center">
            <Button
                onClick={() => {
                    document.getElementById(field.input.id)?.scrollIntoView({
                        behavior: "smooth",
                        block: "center",
                    })
                }}
                startIcon={
                    !!stillWaiting ? (
                        <DonutLarge fontSize="small" color="secondary" />
                    ) : (
                        <CheckCircle fontSize="small" color="primary" />
                    )
                }
            >
                {config?.name ?? field.input.criteria?.name}
            </Button>
        </Box>
    )
}

export default FieldMessage
