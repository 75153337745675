import { Box, ListItemText, ListItemAvatar, Avatar } from "@material-ui/core"
import AssignmentIndIcon from "@material-ui/icons/AssignmentInd"
import GroupIcon from "@material-ui/icons/Group"

import { useTheme } from "@material-ui/core/styles"

import { InputResponseLabel } from "../../providers/GlobalState"
import { ScoreSelectorDefaultValues } from "./types"
import UserAvatar from "../users/UserAvatar"
import useEnvironmentSettingTools from "../settings/useEnvironmentSettingTools"

export default function ResponseListItem(props: {
    item: InputResponseLabel
    isConceptDefault?: boolean
}) {
    const { primaryResponseText } = useEnvironmentSettingTools()
    const { item, isConceptDefault } = props
    const theme = useTheme()
    return (
        <Box display="flex" alignItems="center">
            <ListItemAvatar>
                {!!item.user && !item.label && !isConceptDefault ? (
                    <UserAvatar user={item.user} variant="circle" />
                ) : (
                    <Avatar
                        style={{
                            backgroundColor: !!isConceptDefault
                                ? theme.palette.secondary.main
                                : theme.palette.primary.main,
                        }}
                    >
                        {isConceptDefault ? (
                            <AssignmentIndIcon fontSize="small" />
                        ) : item.label ===
                          ScoreSelectorDefaultValues.allResponses ? (
                            <GroupIcon fontSize="small" />
                        ) : (
                            item.label && item.label[0]
                        )}
                    </Avatar>
                )}
            </ListItemAvatar>
            <ListItemText
                primary={
                    isConceptDefault
                        ? primaryResponseText
                        : item.content ??
                          item.label ??
                          `${item.user?.firstName} ${item.user.lastName}`
                }
                secondary={
                    !!isConceptDefault
                        ? null
                        : !!item.user && !!item.label
                        ? `${item.user.firstName} ${item.user.lastName}`
                        : null
                }
                secondaryTypographyProps={{
                    variant: "caption",
                }}
            />
        </Box>
    )
}
