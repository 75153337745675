import { useReactiveVar, useQuery } from "@apollo/client"
import Box from "@material-ui/core/Box"
import IconButton from "@material-ui/core/IconButton"
import React, { useRef } from "react"
import {
    FeedVariables,
    feedVariablesVar,
    VIEW_OPTIONS,
} from "../providers/GlobalState"
import { Concept, SystemRole, _ConceptFilter } from "../__generated__/types"
import CategoryMenuSelector from "./categories/CategoryMenuSelector"
import { ReactComponent as GlobalFilterIcon } from "../styles/FilterIcon.svg"
import ClearAllIcon from "@material-ui/icons/ClearAll"
import { useTheme } from "@material-ui/core/styles"
import { FEED_TOTAL_COUNTER } from "../graphql/queries"
import { Badge, Tooltip, Typography, useMediaQuery } from "@material-ui/core"
import {
    FeedTotalCounterQuery,
    FeedTotalCounterQueryVariables,
} from "../graphql/__generated__/queries"
import ClickableRichTooltip from "./Popper/ClickableRichTooltip"
import Filters from "./filters/Filters"
import { FeedPagination } from "./FeedTools"
import useAwaitTranslation from "../i18n/useAwaitTranslation"
import ConceptsToCsv from "./exporting/ConceptsToCsv"
import { useAuth } from "../providers/AuthProvider"

export default function ConceptFeedHeader(props: {
    onToggleSidebar: () => void
    filterState: _ConceptFilter
}) {
    //hooks
    const theme = useTheme()
    const mobile = useMediaQuery(theme.breakpoints.down("sm"))
    const { t } = useAwaitTranslation("concepts")
    const { currentUser, isNonMember } = useAuth()
    //refs
    const counterDataRef = useRef<Concept[]>(null)

    //global state
    const currentFeedVariables = useReactiveVar(feedVariablesVar)

    //queries
    const { data: totalData } = useQuery<
        FeedTotalCounterQuery,
        FeedTotalCounterQueryVariables
    >(FEED_TOTAL_COUNTER, {
        variables: {
            filter: {
                ...props.filterState,
                category: undefined,
            },
        },
        fetchPolicy: "cache-and-network",
        skip: !!isNonMember,
    })

    //variables

    const filtersApplied = Object.keys(props.filterState).filter(
        (name) =>
            // OR is only used for permissions currently, will eventually change
            ["OR", "category", "isPublicAccessType_not"].indexOf(name) === -1
    )

    if (!!totalData) {
        counterDataRef.current = totalData.Concept ?? []
    }

    let iconButton = (
        <Badge
            badgeContent={filtersApplied.length}
            overlap="circular"
            color="primary"
        >
            <IconButton
                onClick={() => {
                    if (!!mobile) {
                        props.onToggleSidebar()
                    }
                }}
                disableTouchRipple
                disableFocusRipple
            >
                <GlobalFilterIcon
                    style={{
                        width: "1em",
                        height: "1em",
                        fill: "currentColor",
                    }}
                />
            </IconButton>
        </Badge>
    )

    return (
        <Box
            display="flex"
            width="100%"
            height="100%"
            alignItems="center"
            flexWrap="wrap"
            p={1}
        >
            <Box flexGrow={2}>
                {!!isNonMember ? (
                    <Box p={1}>
                        <Typography variant="h6" component="h1">
                            Welcome, {currentUser?.firstName}
                        </Typography>
                    </Box>
                ) : (
                    <CategoryMenuSelector
                        value={currentFeedVariables?.categoryId}
                        setValue={(value: string | null) => {
                            feedVariablesVar({
                                ...currentFeedVariables,
                                categoryId: value,
                                page: 1,
                            })
                        }}
                        counterData={counterDataRef.current}
                    />
                )}
            </Box>

            {currentFeedVariables.displayType !== VIEW_OPTIONS.NORMAL && (
                <FeedPagination
                    onChange={(page: number) => {
                        feedVariablesVar({
                            ...currentFeedVariables,
                            page,
                        })
                    }}
                    currentPage={currentFeedVariables.page}
                    currentTotal={
                        !currentFeedVariables.categoryId
                            ? counterDataRef.current
                            : counterDataRef.current?.filter(
                                  (concept) =>
                                      concept.category?.id ===
                                      currentFeedVariables.categoryId
                              )
                    }
                />
            )}

            {!isNonMember && filtersApplied.length > 0 && (
                <Tooltip title={t("clearAllFilters", "Clear all filters")}>
                    <IconButton
                        onClick={() =>
                            feedVariablesVar({
                                ...currentFeedVariables,
                                filters: [],
                            })
                        }
                    >
                        <ClearAllIcon />
                    </IconButton>
                </Tooltip>
            )}
            {!isNonMember &&
                (!!mobile
                    ? iconButton
                    : !currentFeedVariables.pinFilters && (
                          <ClickableRichTooltip
                              zIndex={theme.zIndex.appBar - 1}
                              placement="top-end"
                              content={
                                  <Box flexGrow={"1"} width={500}>
                                      <Filters
                                          feedVariables={currentFeedVariables}
                                          onChangeFeedVariables={(
                                              value: FeedVariables
                                          ) =>
                                              feedVariablesVar({
                                                  ...value,
                                                  page: 1,
                                              })
                                          }
                                          onSidePin={() =>
                                              props.onToggleSidebar()
                                          }
                                          excludeCategory={true}
                                      />
                                  </Box>
                              }
                          >
                              <Tooltip
                                  title={t("filterTheFeed", "Filter the feed")}
                              >
                                  {iconButton}
                              </Tooltip>
                          </ClickableRichTooltip>
                      ))}
            {currentUser.role === SystemRole.ADMIN && (
                <Box mx={1}>
                    <ConceptsToCsv filter={props.filterState} />
                </Box>
            )}
        </Box>
    )
}
