import {
    ListItem,
    ListItemIcon,
    ListItemText,
    Box,
    useTheme,
    Typography,
    Chip,
    ButtonBase,
    Popper,
    ClickAwayListener,
    Paper,
} from "@material-ui/core"
import React from "react"
import {
    makeStyles,
    Theme,
    createStyles,
    alpha,
} from "@material-ui/core/styles"
import TreeView from "@material-ui/lab/TreeView"
import LibraryBooksIcon from "@material-ui/icons/LibraryBooks"
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import ChevronRightIcon from "@material-ui/icons/ChevronRight"
import TreeItem from "@material-ui/lab/TreeItem"
import { Category, Concept } from "../../__generated__/types"
import useAwaitTranslation from "../../i18n/useAwaitTranslation"
import useCategoryTools from "./useCategoryTools"
import CategoryIcon from "./CategoryIcon"
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            fontSize: 13,
            position: "relative",
            width: "100%",
        },

        treeRoot: {
            display: "flex",
            flexDirection: "column",
            overflow: "hidden",
            padding: theme.spacing(1, 3),
        },
        treeContainer: {
            flexGrow: 1,
            overflowY: "auto",
            padding: theme.spacing(0, 1),
        },
        treeItem: {
            backgroundColor: "transparent !important",
            padding: 0,
        },
        iconContainer: {
            "&:hover": {
                backgroundColor: theme.palette.action.hover + " !important",
            },

            borderRadius: theme.shape.borderRadius,
            display: "flex",
            alignItems: "center",
            margin: 0,
            width: "25px",
        },
        inactiveIconContainer: {
            cursor: "default",
            margin: 0,
            width: "25px",
        },
        content: {
            alignItems: "stretch",
        },
        listContainer: {
            maxHeight: 400,
            overflowY: "auto",
        },
        allContent: {
            "&:hover": {
                backgroundColor: theme.palette.action.hover + " !important",
            },
            cursor: "pointer",
            display: "flex",
        },
        clickable: {
            "&:hover": {
                backgroundColor: theme.palette.action.hover,
                borderRadius: "4px",
            },

            padding: theme.spacing(1),
            paddingLeft: theme.spacing(2),
            display: "flex",
            justifyContent: "flex-start",
        },
        popper: {
            zIndex: theme.zIndex.modal + 1,
        },
    })
)

interface CategoryMenuSelectorProps {
    value: string | null
    setValue: (category: string | null) => void
    counterData?: Concept[]
    loading?: boolean
    textVariant?: "body1" | "h6"
    allText?: string
    preOptionText?: string
    includeAllOption?: boolean
}
export default function CategoryMenuSelector(props: CategoryMenuSelectorProps) {
    //hooks
    const { t } = useAwaitTranslation("categories")
    const theme = useTheme()
    const classes = useStyles()

    //refs
    const ref = React.useRef(null)

    //state
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget)
    }

    const { getCategoryArray, environmentCategories } = useCategoryTools()

    let categoryValue: Category = null
    const categoryArray = getCategoryArray(props.value)
    if (!!props.value) {
        categoryValue = categoryArray?.[categoryArray?.length - 1]
    }

    const selectedCount = !categoryValue
        ? props.counterData?.length
        : props.counterData?.filter(
              (item) => item.category?.id === categoryValue?.id
          )?.length
    const open = Boolean(anchorEl)
    //variables
    let options = environmentCategories?.filter((x) => !!x.isRoot) ?? []

    return (
        <Box width={"100%"} height="100%" display="flex">
            <div className={classes.root} ref={ref}>
                <Box
                    className={classes.clickable}
                    height="100%"
                    display="flex"
                    onClick={handleClick}
                    width="100%"
                    alignItems="center"
                    pl={0.5}
                    component={ButtonBase}
                >
                    {categoryValue?.id ? (
                        <Box display={"flex"} alignItems="center">
                            <CategoryIcon category={categoryArray?.[0]} />
                            <Typography
                                variant={props.textVariant ?? "h6"}
                                style={{
                                    marginLeft: "0.5em",
                                    display: "flex",
                                    alignItems: "center",
                                }}
                            >
                                {`${props.preOptionText || ""} ${
                                    categoryValue?.name || ""
                                }`}
                                {!!props.counterData && `  (${selectedCount}) `}
                            </Typography>
                        </Box>
                    ) : (
                        <Box display={"flex"} alignItems="center">
                            <LibraryBooksIcon />
                            <Typography
                                variant={props.textVariant ?? "h6"}
                                style={{
                                    marginLeft: "0.5em",
                                    display: "flex",
                                    alignItems: "center",
                                }}
                            >
                                {props.allText ??
                                    t("allContent", "All Content")}
                            </Typography>
                            {!!props.counterData && (
                                <Typography
                                    variant={props.textVariant ?? "h6"}
                                    style={{
                                        marginLeft: theme.spacing(0.5),
                                    }}
                                >
                                    {`(${selectedCount})`}
                                </Typography>
                            )}
                        </Box>
                    )}
                    <ArrowDropDownIcon
                        style={{
                            transform: !!anchorEl ? "rotate(180deg)" : "none",
                            marginLeft: "0.25em",
                        }}
                    />
                </Box>
                <Popper
                    open={open}
                    anchorEl={anchorEl}
                    placement="bottom-start"
                    className={classes.popper}
                >
                    <ClickAwayListener onClickAway={() => setAnchorEl(null)}>
                        <Paper
                            elevation={11}
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                overflow: "hidden",
                                width: "30em",
                            }}
                        >
                            <Box className={classes.listContainer} flexGrow={1}>
                                <ListItem
                                    className={classes.allContent}
                                    onClick={() => {
                                        props.setValue(null)
                                        setAnchorEl(null)
                                    }}
                                    selected={!props.value}
                                >
                                    <ListItemText
                                        primary={
                                            props.allText ??
                                            t("allContent", "All Content")
                                        }
                                    />

                                    {!!props.counterData && (
                                        <ListItemIcon
                                            style={{ justifyContent: "center" }}
                                        >
                                            <Chip
                                                size="small"
                                                label={props.counterData.length}
                                            />
                                        </ListItemIcon>
                                    )}
                                </ListItem>

                                {options.map((option, index) => {
                                    return (
                                        <TreeView
                                            key={option.id}
                                            defaultCollapseIcon={
                                                <ExpandMoreIcon />
                                            }
                                            defaultExpandIcon={
                                                <ChevronRightIcon />
                                            }
                                            defaultExpanded={
                                                options
                                                    ?.map((item) =>
                                                        item.isRoot
                                                            ? item.id
                                                            : null
                                                    )
                                                    ?.filter((x) => !!x) ?? []
                                            }
                                        >
                                            <TreeItemContainer
                                                node={option}
                                                setValue={(value: Category) => {
                                                    props.setValue(value?.id)
                                                    setAnchorEl(null)
                                                }}
                                                counterData={
                                                    props.counterData ?? null
                                                }
                                                value={categoryValue}
                                            />
                                        </TreeView>
                                    )
                                })}
                            </Box>
                        </Paper>
                    </ClickAwayListener>
                </Popper>
            </div>
        </Box>
    )
}

interface TreeItemContainerProps {
    node: Category
    setValue: (value: Category) => void
    value: Category | null
    counterData?: Concept[]
}
const TreeItemContainer = (props: TreeItemContainerProps) => {
    const { environmentCategories } = useCategoryTools()
    const { node, setValue, value, counterData } = props
    const classes = useStyles()
    const theme = useTheme()
    const count =
        !!counterData &&
        counterData.filter((concept) => concept.category?.id === node.id)
            ?.length

    let fadePercentage = (count / counterData?.length) * 5
    if (fadePercentage > 1) {
        fadePercentage = 1
    }
    const children =
        environmentCategories?.filter(
            (category) => category.parent?.id === node.id
        ) ?? null
    return (
        <Box key={node.id}>
            <TreeItem
                classes={{
                    label: classes.treeItem,
                    iconContainer:
                        children?.length > 0
                            ? classes.iconContainer
                            : classes.inactiveIconContainer,
                    content: classes.content,
                }}
                key={node.id}
                nodeId={node.id}
                onLabelClick={(e) => {
                    setValue(node)
                    e.preventDefault()
                }}
                label={
                    <ListItem dense button selected={node.id === value?.id}>
                        <ListItemText primary={node.name} />
                        {!!counterData && (
                            <ListItemIcon style={{ justifyContent: "center" }}>
                                <Chip
                                    style={{
                                        backgroundColor: alpha(
                                            theme.palette.primary.main,
                                            fadePercentage
                                        ),
                                    }}
                                    size="small"
                                    label={count}
                                />
                            </ListItemIcon>
                        )}
                    </ListItem>
                }
            >
                {Array.isArray(children)
                    ? children?.map((node) => (
                          <TreeItemContainer
                              key={node.id}
                              node={node}
                              setValue={setValue}
                              value={value}
                              counterData={props.counterData}
                          />
                      ))
                    : null}
            </TreeItem>
        </Box>
    )
}
