import React, { useCallback, useEffect, useRef, useState } from "react"

import {
    makeStyles,
    createStyles,
    Theme,
    withStyles,
} from "@material-ui/core/styles"

import { useMutation } from "@apollo/client"
import { GENERATE_CHAT_AI } from "../graphql/mutations"

import {
    Box,
    IconButton,
    Grow,
    TextField,
    InputAdornment,
    alpha,
    Avatar,
    Divider,
} from "@material-ui/core"
import AssistantIcon from "@material-ui/icons/Assistant"
import useMountedState from "../util/useMountedState"
import CheckCircle from "@material-ui/icons/CheckCircle"
import FileCopy from "@material-ui/icons/FileCopy"
import Send from "@material-ui/icons/Send"
import UserAvatar from "./users/UserAvatar"
import { ChatMessage, ChatRole } from "../__generated__/types"
import DotsLoading from "./DotsLoading"

const useStyles = makeStyles((theme) => ({
    textContainer: {
        height: "100%",
        overflowY: "auto",
    },
    message: {
        "&:nth-of-type(odd)": {
            backgroundColor: alpha(theme.palette.primary.main, 0.05),
        },
        padding: theme.spacing(1),
    },

    root: {
        border: `1px solid ${theme.palette.divider}`,
        borderRadius: theme.shape.borderRadius,
    },
    messageContent: {
        marginTop: theme.spacing(1),
    },
}))

export default function AIHelper(props: {
    systemPrompt: string
    currentChatMessages?: ChatMessage[]
    firstMessage?: string
    maxLength?: number
    onGenerate?: (value: ChatMessage[]) => void
}) {
    const {
        systemPrompt,
        firstMessage,
        maxLength,
        onGenerate,
        currentChatMessages,
    } = props
    const isMounted = useMountedState()
    const scrollRef = useRef(null)
    const inputRef = useRef(null)
    const [messages, setMessages] = useState<ChatMessage[]>([])
    const [inputValue, setInputValue] = useState("")
    const classes = useStyles()

    const [generateChatAI, { loading: generating }] =
        useMutation(GENERATE_CHAT_AI)
    const handleGenerate = useCallback(async () => {
        setInputValue("")
        try {
            let initialMessage = [
                {
                    content: firstMessage || "What do you suggest?",
                    role: ChatRole.USER,
                },
            ]
            let messagesToSend = messages
            if (messages?.length === 0) {
                messagesToSend = [...initialMessage]
            }
            if (!!inputValue) {
                messagesToSend = [
                    ...messagesToSend,
                    { content: inputValue, role: ChatRole.USER },
                ]
                setMessages(messagesToSend)
                scrollRef?.current?.scrollIntoView({
                    behavior: "smooth",
                    block: "nearest",
                })
            } else {
                setMessages(messagesToSend)
            }
            const { data } = await generateChatAI({
                variables: {
                    systemPrompt: systemPrompt,
                    messages: messagesToSend,
                    maxLength: maxLength || 300,
                },
            })

            if (data?.generateChatAI && !!isMounted()) {
                const newMessages = [
                    ...messagesToSend,
                    { content: data.generateChatAI, role: ChatRole.ASSISTANT },
                ]

                setMessages(newMessages)
                if (!!onGenerate) {
                    onGenerate(newMessages)
                }

                scrollRef?.current?.scrollIntoView({
                    behavior: "smooth",
                    block: "nearest",
                })
                inputRef?.current?.focus()
            }
        } catch (error) {
            console.log(error, "ERROR")
        }
    }, [
        generateChatAI,
        inputValue,
        isMounted,
        maxLength,
        messages,
        systemPrompt,
        firstMessage,
        onGenerate,
    ])
    useEffect(() => {
        if (messages.length === 0) {
            if (!currentChatMessages || currentChatMessages?.length === 0) {
                handleGenerate()
            } else {
                setMessages(currentChatMessages)
            }
        }
    }, [handleGenerate, messages.length, currentChatMessages])

    return (
        <Box
            width="100%"
            display="flex"
            flexDirection="column"
            height="100%"
            overflow="hidden"
            className={classes.root}
        >
            <Box className={classes.textContainer}>
                {messages.map((message, index) => {
                    return <Message message={message} key={index} />
                })}
                {!!generating && <DotsLoading />}
                <div ref={scrollRef} />
            </Box>
            <Divider />
            <Box p={1}>
                <TextField
                    variant="outlined"
                    fullWidth
                    onKeyDown={(e) => {
                        if (
                            e.key === "Enter" &&
                            inputValue.length > 0 &&
                            !generating
                        ) {
                            handleGenerate()
                        }
                    }}
                    autoFocus
                    inputRef={inputRef}
                    value={inputValue}
                    onChange={(e) => setInputValue(e.target.value)}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <UserAvatar variant="circle" size="small" />
                            </InputAdornment>
                        ),
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton
                                    disabled={inputValue.length === 0}
                                    onClick={() => {
                                        if (
                                            inputValue.length > 0 &&
                                            !generating
                                        ) {
                                            handleGenerate()
                                        }
                                    }}
                                >
                                    <Send />
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                />
            </Box>
        </Box>
    )
}

const Message = (props: { message: ChatMessage }) => {
    const { message } = props
    const classes = useStyles()
    const isMounted = useMountedState()
    const textRef = useRef(null)
    const [hovered, setHovered] = useState(false)
    const [copied, setCopied] = useState(false)
    const onCopy = () => {
        setCopied(true)
        try {
            const content = textRef.current?.innerHTML
            const blobInput = new Blob([content], { type: "text/html" })
            const clipboardItemInput = new ClipboardItem({
                "text/html": blobInput,
            })
            navigator.clipboard.write([clipboardItemInput])
        } catch (e) {
            console.log(e)
        }
        setTimeout(() => {
            if (!!isMounted()) {
                setCopied(false)
            }
        }, 3000)
    }

    return (
        <Box
            position="relative"
            onMouseOver={() => {
                setHovered(true)
            }}
            onMouseLeave={() => {
                setHovered(false)
            }}
            p={0.5}
            className={classes.message}
            display="flex"
        >
            {!!hovered && (
                <IconButton
                    size="small"
                    style={{
                        position: "absolute",
                        right: 10,
                        top: 10,
                        backdropFilter: "blur(5px)",
                    }}
                    color={!!copied ? "primary" : "default"}
                    onClick={onCopy}
                >
                    {!!copied ? (
                        <Grow in={true}>
                            <CheckCircle fontSize="small" />
                        </Grow>
                    ) : (
                        <FileCopy fontSize="small" />
                    )}
                </IconButton>
            )}
            <Box p={1} pr={2}>
                {message.role === ChatRole.USER ? (
                    <UserAvatar variant="circle" size="small" />
                ) : (
                    <StyledAvatar variant="circle">
                        <AssistantIcon
                            style={{ color: "white" }}
                            fontSize="small"
                        />
                    </StyledAvatar>
                )}
            </Box>
            <span className={classes.messageContent} ref={textRef}>
                {message.content}
            </span>
        </Box>
    )
}

export const StyledAvatar = withStyles((theme: Theme) =>
    createStyles({
        root: {
            width: theme.spacing(3),
            height: theme.spacing(3),
            backgroundColor: theme.palette.primary.main,
        },
    })
)(Avatar)
