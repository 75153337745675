import { useReactiveVar } from "@apollo/client"
import { Box, TextField } from "@material-ui/core"
import { Autocomplete } from "@material-ui/lab"
import { myCurrentInputResponseVar } from "../../providers/GlobalState"
import { Criteria } from "../../__generated__/types"
import { CriteriaType } from "../criteria/types"
import { WidgetTypeName } from "./useWidgetTypes"

export type CollectionCriteria = {
    collectionCriteriaId: string
    subCriteriaIds: string[]
}

export default function CollectionCriteriaField(props: {
    widgetTypeName: WidgetTypeName
    value: CollectionCriteria
    onChange: (value: CollectionCriteria) => void
}) {
    const AvailableFields = {
        [WidgetTypeName.ScoringResultsBubbleChart]: [CriteriaType.Number],
        [WidgetTypeName.Table]: [
            CriteriaType.Number,
            CriteriaType.TextResponse,
            CriteriaType.Date,
            CriteriaType.MultipleChoice,
            CriteriaType.Grouping,
        ],
    }
    const { widgetTypeName, value, onChange } = props
    const currentResponsesVar = useReactiveVar(myCurrentInputResponseVar)

    const collectionOptions =
        currentResponsesVar?.currentResponseData
            .filter(
                (item) =>
                    item.input.criteria.criteriaType === CriteriaType.Collection
            )
            .map((item) => item.input.criteria) ?? []
    const subCriteriaOptions =
        currentResponsesVar?.currentResponseData
            .filter((item) =>
                JSON.parse(
                    item.input?.inputConfig ?? "{}"
                )?.source?.criteriaIds?.find(
                    (item) => item.id === value.collectionCriteriaId
                )
            )
            .map((item) => item.input.criteria)
            .filter((criteria) =>
                AvailableFields[widgetTypeName].includes(criteria.criteriaType)
            ) ?? []
    const currentCollection =
        collectionOptions.find(
            (criteria) => criteria.id === value.collectionCriteriaId
        ) ?? null
    let orderedSubCriteria = value.subCriteriaIds
        ?.map((id) => subCriteriaOptions.find((criteria) => criteria.id === id))
        ?.filter((x) => !!x)

    return (
        <Box>
            <Box width="100%">
                <Autocomplete
                    options={collectionOptions}
                    getOptionLabel={(criteria: Criteria) => criteria.name || ""}
                    onChange={(event, newValue: Criteria) => {
                        if (newValue.id !== value.collectionCriteriaId) {
                            onChange({
                                ...value,
                                collectionCriteriaId: newValue.id,
                                subCriteriaIds: [],
                            })
                        }
                    }}
                    value={currentCollection}
                    renderInput={(params) => (
                        <TextField
                            variant="outlined"
                            label="Collection"
                            {...params}
                            fullWidth
                        />
                    )}
                />
            </Box>
            <Box width="100%" mt={3}>
                <Autocomplete
                    options={subCriteriaOptions}
                    multiple
                    disableCloseOnSelect
                    getOptionLabel={(criteria: Criteria) => criteria.name || ""}
                    onChange={(event, newValue: Criteria[]) => {
                        let sortedValue = [
                            ...newValue.filter(
                                (val) =>
                                    !!orderedSubCriteria.find(
                                        (sub) => sub.id === val.id
                                    )
                            ),
                            ...newValue.filter(
                                (val) =>
                                    !orderedSubCriteria.find(
                                        (sub) => sub.id === val.id
                                    )
                            ),
                        ]

                        onChange({
                            ...value,

                            subCriteriaIds: sortedValue.map(
                                (criteria) => criteria.id
                            ),
                        })
                    }}
                    value={orderedSubCriteria}
                    renderInput={(params) => (
                        <TextField
                            label="Collection Criteria"
                            {...params}
                            fullWidth
                            variant="outlined"
                        />
                    )}
                />
            </Box>
        </Box>
    )
}
