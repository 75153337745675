import React, { useState } from "react"
import Typography from "@material-ui/core/Typography"
import {
    Card,
    CardActions,
    CardContent,
    Divider,
    Button,
    Grid,
    Avatar,
    Box,
    CircularProgress,
} from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import { useAuth } from "../../providers/AuthProvider"
import { useMutation, useQuery } from "@apollo/client"
import { Form, Field } from "react-final-form"
import { TextField } from "mui-rff"
import { UPLOAD_PROFILE_IMAGE } from "../images/graphql"
import {
    UploadProfileImageMutation,
    UploadProfileImageMutationVariables,
} from "../images/__generated__/graphql"
import dayjs from "dayjs"
import { Loading } from "../Loading"
import useAwaitTranslation from "../../i18n/useAwaitTranslation"
import { USER_QUERY, UPDATE_USER } from "./graphql"
import {
    MyAccountQuery,
    MyAccountQueryVariables,
    UpdateUserMutation,
    UpdateUserMutationVariables,
} from "./__generated__/graphql"
import { User, _UserInput } from "../../__generated__/types"
import { getGermanTimeAgo } from "../../util/fns"
const useStyles = makeStyles((theme) => ({
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
    },
    uiProgress: {
        position: "fixed",
        height: "31px",
        width: "31px",
        left: "50%",
        top: "35%",
    },
    toolbar: theme.mixins.toolbar,
    progress: {
        position: "absolute",
    },
    uploadButton: {
        marginLeft: "8px",
        margin: theme.spacing(1),
    },
    customError: {
        color: "red",
        fontSize: "0.8rem",
        marginTop: 10,
    },
    actionButton: {
        marginTop: "10px",
    },
    locationText: {
        paddingLeft: "15px",
    },
    details: {
        display: "flex",
    },
    dialog: {
        padding: theme.spacing(3),
        width: "400px",
    },
    dialogField: {
        margin: "1em 0em",
    },
    avatarButton: {
        position: "relative",
        borderRadius: "100%",
    },
    avatarUploadText: {
        opacity: 0,
        textAlign: "center",
        position: "absolute",
        height: "100%",
        width: "100%",
        zIndex: 1000,
        display: "flex",
        alignItems: "center",
        borderRadius: "100%",
        "&:hover": {
            opacity: 1,
            cursor: "pointer",
            backdropFilter: "blur(5px)",
        },
    },
    avatar: {
        width: theme.spacing(10),
        height: theme.spacing(10),
        "&:hover": {
            filter: "opacity(0.5)",
            cursor: "pointer",
        },
    },
}))

export default function Account() {
    const classes = useStyles()
    const { currentUser } = useAuth()
    const [editing, setEditing] = useState(false)
    const { loading, error, data } = useQuery<
        MyAccountQuery,
        MyAccountQueryVariables
    >(USER_QUERY, {
        variables: { userId: currentUser.userId },
    })
    const [updateUser] = useMutation<
        UpdateUserMutation,
        UpdateUserMutationVariables
    >(UPDATE_USER)
    const [uploadPhoto] = useMutation<
        UploadProfileImageMutation,
        UploadProfileImageMutationVariables
    >(UPLOAD_PROFILE_IMAGE)
    const [imageUploaded, setImageUploaded] = useState(null)
    const [saving, setSaving] = useState(false)

    const { t } = useAwaitTranslation("account")

    const user: User = data?.User?.[0] ?? null
    const saveUser = async (user: _UserInput) => {
        setSaving(true)
        await updateUser({ variables: user })
        setEditing(false)
        setSaving(false)
    }
    const handleEdit = () => {
        setEditing(true)
    }
    const handleProfileUpload = (event) => {
        uploadProfileImage({
            file: event.target.files[0],
            imageName: user.username,
        })
    }
    const uploadProfileImage = async (imageProps: any) => {
        const imageUploadProps = (({ file, imageName }) => ({
            file,
            imageName,
        }))(imageProps)
        try {
            let photoResponse = await uploadPhoto({
                variables: imageUploadProps,
            })
            if (photoResponse && photoResponse.data) {
                setImageUploaded(photoResponse.data.uploadProfileImage.fileUrl)
                const data = {
                    userId: user.userId,
                    imageUrl: photoResponse.data.uploadProfileImage.fileUrl,
                }
                saveUser(data)
            }
        } catch (e) {
            console.log(e)
        }
    }
    if (loading) {
        return <Loading size={150} />
    } else if (error) {
        console.log(error)
    }

    return (
        <main className={classes.content}>
            <Form
                initialValues={{
                    firstName: user?.firstName,
                    lastName: user?.lastName,
                    email: user?.email,
                    bio: user?.bio,
                    username: user?.username,
                }}
                onSubmit={(values) => {
                    saveUser({ ...values, userId: user?.userId })
                }}
                validate={(values) => {
                    const errors = {} as any
                    if (!values.firstName) {
                        errors.firstName = t("required", "Required")
                    } else if (!values.lastName) {
                        errors.lastName = t("required", "Required")
                    } else if (!values.email) {
                        errors.email = t("required", "Required")
                    }
                    return errors
                }}
                render={({ handleSubmit, values }) => (
                    <form onSubmit={handleSubmit}>
                        <Card>
                            <CardContent>
                                <div className={classes.details}>
                                    <div>
                                        <Box display="flex" alignItems="center">
                                            <Button
                                                className={classes.avatarButton}
                                                component="label"
                                            >
                                                <input
                                                    type="file"
                                                    hidden
                                                    onChange={
                                                        handleProfileUpload
                                                    }
                                                />
                                                <span
                                                    className={
                                                        classes.avatarUploadText
                                                    }
                                                >
                                                    Upload Image
                                                </span>
                                                <Avatar
                                                    className={classes.avatar}
                                                    src={
                                                        imageUploaded !== null
                                                            ? imageUploaded
                                                            : user.imageUrl
                                                    }
                                                ></Avatar>
                                            </Button>
                                            <div>
                                                <Typography
                                                    className={
                                                        classes.locationText
                                                    }
                                                    variant="h4"
                                                >
                                                    {values.firstName}{" "}
                                                    {values.lastName}
                                                </Typography>
                                                <Box
                                                    display="flex"
                                                    flexDirection="column"
                                                    className={
                                                        classes.locationText
                                                    }
                                                    mt={1}
                                                >
                                                    <span
                                                        style={{
                                                            float: "right",
                                                            whiteSpace:
                                                                "nowrap",
                                                        }}
                                                    >
                                                        {t(
                                                            "memberSince",
                                                            "Member Since"
                                                        )}
                                                        :{" "}
                                                        {t(
                                                            "language",
                                                            "language"
                                                        ) === "language"
                                                            ? dayjs(
                                                                  user
                                                                      ?.createdDate
                                                                      .formatted
                                                              ).fromNow()
                                                            : getGermanTimeAgo(
                                                                  dayjs(
                                                                      user
                                                                          ?.createdDate
                                                                          .formatted
                                                                  ).fromNow(),
                                                                  false
                                                              )}
                                                        {/* Last Login: {dayjs(values.lastLogin.formatted).fromNow()} */}
                                                    </span>
                                                </Box>
                                            </div>
                                        </Box>
                                    </div>
                                </div>
                                <div className={classes.progress} />
                            </CardContent>
                            <Divider />
                        </Card>
                        <br />
                        <Card>
                            <CardContent>
                                <Grid container spacing={3}>
                                    <Grid item md={6} xs={12}>
                                        <Field name="firstName">
                                            {({ input, meta }) => (
                                                <div>
                                                    <TextField
                                                        required
                                                        fullWidth
                                                        label={t(
                                                            "firstName",
                                                            "First"
                                                        )}
                                                        margin="dense"
                                                        name="firstName"
                                                        variant="outlined"
                                                        disabled={!editing}
                                                        value={values.firstName}
                                                    />
                                                </div>
                                            )}
                                        </Field>
                                    </Grid>
                                    <Grid item md={6} xs={12}>
                                        <Field name="lastName">
                                            {({ input, meta }) => (
                                                <div>
                                                    <TextField
                                                        fullWidth
                                                        required
                                                        label={t(
                                                            "lastName",
                                                            "Last Name"
                                                        )}
                                                        margin="dense"
                                                        name="lastName"
                                                        variant="outlined"
                                                        disabled={!editing}
                                                        value={values.lastName}
                                                    />
                                                </div>
                                            )}
                                        </Field>
                                    </Grid>
                                    <Grid item md={6} xs={12}>
                                        <Field name="email">
                                            {({ input, meta }) => (
                                                <div>
                                                    <TextField
                                                        fullWidth
                                                        required
                                                        label={t(
                                                            "email",
                                                            "Email"
                                                        )}
                                                        margin="dense"
                                                        name="email"
                                                        variant="outlined"
                                                        disabled={true}
                                                        value={values.email}
                                                    />
                                                </div>
                                            )}
                                        </Field>
                                    </Grid>

                                    <Grid item md={6} xs={12}>
                                        <Field name="username">
                                            {({ input, meta }) => (
                                                <div>
                                                    <TextField
                                                        fullWidth
                                                        label={t(
                                                            "username",
                                                            "Username"
                                                        )}
                                                        margin="dense"
                                                        name="username"
                                                        variant="outlined"
                                                        disabled={true}
                                                        value={values.username}
                                                    />
                                                </div>
                                            )}
                                        </Field>
                                    </Grid>

                                    <Grid item xs={12}>
                                        <Field name="bio">
                                            {({ input, meta }) => (
                                                <div>
                                                    <TextField
                                                        fullWidth
                                                        multiline
                                                        label={t("bio", "Bio")}
                                                        margin="dense"
                                                        name="bio"
                                                        variant="outlined"
                                                        disabled={!editing}
                                                        value={values.bio}
                                                    />
                                                </div>
                                            )}
                                        </Field>
                                    </Grid>
                                </Grid>
                            </CardContent>
                            <CardActions style={{ justifyContent: "flex-end" }}>
                                {editing ? (
                                    <Button
                                        color="primary"
                                        variant="contained"
                                        className={classes.actionButton}
                                        onClick={handleSubmit}
                                        disabled={saving}
                                    >
                                        {saving ? (
                                            <CircularProgress
                                                size={25}
                                                disableShrink
                                            />
                                        ) : (
                                            t("saveDetails", "Save Details")
                                        )}
                                    </Button>
                                ) : (
                                    <Button
                                        color="primary"
                                        variant="contained"
                                        className={classes.actionButton}
                                        onClick={handleEdit}
                                    >
                                        {t("editDetails", "Edit Details")}
                                    </Button>
                                )}
                            </CardActions>
                        </Card>
                    </form>
                )}
            />
        </main>
    )
}
