import React, { useCallback, useState } from "react"
import { makeStyles } from "@material-ui/core/styles"
import { useAuth } from "../../providers/AuthProvider"
import Box from "@material-ui/core/Box"
import { Divider } from "@material-ui/core"
import { Paper, Toolbar } from "@material-ui/core"
import Table from "@material-ui/core/Table"
import TableBody from "@material-ui/core/TableBody"
import TableCell from "@material-ui/core/TableCell"
import TableContainer from "@material-ui/core/TableContainer"
import TableHead from "@material-ui/core/TableHead"
import TableRow from "@material-ui/core/TableRow"
import TableSortLabel from "@material-ui/core/TableSortLabel"
import { Team, User } from "../../__generated__/types"
import TeamMemberLineItem from "./TeamMemberLineItem"
import UserAutocomplete from "../users/UserAutocomplete"
import { useMutation } from "@apollo/client"
import { ADD_TEAM_MEMBER } from "./graphql"
import {
    AddTeamMemberMutation,
    AddTeamMemberMutationVariables,
} from "./__generated__/graphql"
const useStyles = makeStyles((theme) => ({
    teamList: {
        flexGrow: 1,
    },
    avatar: {
        width: theme.spacing(4),
        height: theme.spacing(4),
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.primary.main,
        marginRight: theme.spacing(1),
    },
    flexCell: {
        display: "flex",
        alignItems: "center",
        height: "100%",
    },
    tableContainer: {
        flexGrow: 1,
    },
    tableHeader: {
        //@ts-ignore
        backgroundColor: `${theme.palette.background.level2} !important`,
    },
    paper: {
        display: "flex",
        flexDirection: "column",
        height: "100%",
        margin: theme.spacing(2),
        overflow: "hidden",
    },
}))

type Order = "asc" | "desc"

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
    if (b[orderBy] < a[orderBy]) {
        return -1
    }
    if (b[orderBy] > a[orderBy]) {
        return 1
    }
    return 0
}

function getComparator<Key extends keyof any>(
    order: Order,
    orderBy: Key
): (
    a: { [key in Key]: number | string },
    b: { [key in Key]: number | string }
) => number {
    return order === "desc"
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy)
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort<T>(
    array: readonly T[],
    comparator: (a: T, b: T) => number
) {
    const stabilizedThis = array.map((el, index) => [el, index] as [T, number])
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0])
        if (order !== 0) {
            return order
        }
        return a[1] - b[1]
    })
    return stabilizedThis.map((el) => el[0])
}

interface TeamMemberTableProps {
    team: Team
}

export default function TeamMemberTable(props: TeamMemberTableProps) {
    const classes = useStyles()
    const { currentUser } = useAuth()
    const [addMember, { loading: addingMember }] = useMutation<
        AddTeamMemberMutation,
        AddTeamMemberMutationVariables
    >(ADD_TEAM_MEMBER)
    const editable =
        props.team.admin.filter((x) => {
            return x.userId === currentUser?.userId
        }).length > 0 || props.team.leader?.userId === currentUser?.userId
    const [order, setOrder] = useState<Order>("asc")
    const [orderBy, setOrderBy] = useState("lastName")

    const handleRequestSort = (property) => {
        const isAsc = orderBy === property && order === "asc"
        setOrder(isAsc ? "desc" : "asc")
        setOrderBy(property)
    }

    const sortableMembers = props.team?.members
        ? [...props.team.members]?.map((item) => {
              let object = {}
              Object.keys(item).filter((field) => {
                  if (
                      (typeof field === "string" ||
                          typeof field === "number") &&
                      field !== "__typename"
                  ) {
                      object[field] = item[field]
                  }
                  return object
              })
              return {
                  ...object,

                  role:
                      props.team.admin.filter((x) => {
                          return x.userId === item.userId
                      }).length > 0
                          ? "Admin"
                          : "Editor",
              }
          })
        : []

    const onMemberAddition = useCallback(
        async (user: User) => {
            await addMember({
                variables: {
                    fromId: user.userId,
                    toId: props.team.teamId,
                },
            })
        },
        [props.team.teamId, addMember]
    )
    return (
        <Paper className={classes.paper}>
            {!!editable && (
                <>
                    <Toolbar>
                        <Box
                            display="flex"
                            justifyContent="flex-end"
                            alignItems="center"
                            width="100%"
                        >
                            <UserAutocomplete
                                loading={!!addingMember}
                                onSelection={onMemberAddition}
                                disabledOptionUserIds={props.team.members?.map(
                                    (op) => op.userId
                                )}
                                size="small"
                            />
                        </Box>
                    </Toolbar>
                    <Divider />
                </>
            )}

            <TableContainer className={classes.tableContainer}>
                <Table stickyHeader>
                    <TableHead>
                        <TableRow>
                            <TableCell
                                id="avatar"
                                size="small"
                                className={classes.tableHeader}
                                style={{ width: "50px" }}
                            />

                            <TableCell
                                className={classes.tableHeader}
                                sortDirection={
                                    orderBy === "lastName" ? order : false
                                }
                                size="small"
                            >
                                <TableSortLabel
                                    active={orderBy === "lastName"}
                                    direction={
                                        orderBy === "lastName" ? order : "asc"
                                    }
                                    onClick={() =>
                                        handleRequestSort("lastName")
                                    }
                                >
                                    Name
                                </TableSortLabel>
                            </TableCell>

                            <TableCell
                                className={classes.tableHeader}
                                sortDirection={
                                    orderBy === "email" ? order : false
                                }
                            >
                                <TableSortLabel
                                    active={orderBy === "email"}
                                    direction={
                                        orderBy === "email" ? order : "asc"
                                    }
                                    onClick={() => handleRequestSort("email")}
                                >
                                    Email
                                </TableSortLabel>
                            </TableCell>
                            <TableCell
                                className={classes.tableHeader}
                                sortDirection={
                                    orderBy === "role" ? order : false
                                }
                            >
                                <TableSortLabel
                                    active={orderBy === "role"}
                                    direction={
                                        orderBy === "role" ? order : "asc"
                                    }
                                    onClick={() => handleRequestSort("role")}
                                >
                                    Role
                                </TableSortLabel>
                            </TableCell>
                            {!!editable && (
                                <TableCell
                                    className={classes.tableHeader}
                                    size="small"
                                />
                            )}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {!!sortableMembers &&
                            stableSort(
                                sortableMembers,
                                getComparator(order, orderBy)
                            )?.map((member, idx) => {
                                const user = props.team.members.filter(
                                    (x) => x.userId === member.userId
                                )?.[0]
                                if (!!user) {
                                    return (
                                        <TeamMemberLineItem
                                            key={member.userId}
                                            user={user}
                                            team={props.team}
                                        />
                                    )
                                } else return null
                            })}
                    </TableBody>
                </Table>
            </TableContainer>
        </Paper>
    )
}
