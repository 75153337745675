import { Avatar, Divider, Typography } from "@material-ui/core"
import Box from "@material-ui/core/Box"
import { makeStyles, useTheme } from "@material-ui/core/styles"
import React from "react"

import {
    ReferenceLine,
    ResponsiveContainer,
    Scatter,
    ScatterChart,
    XAxis,
    YAxis,
} from "recharts"
import { CriteriaScore } from "../../__generated__/types"
import AssignmentIndIcon from "@material-ui/icons/AssignmentInd"
import ClickableRichTooltip from "../Popper/ClickableRichTooltip"
import { BubbleAxisConfigurationType } from "../criteria/useCriteriaTypes"
import { SizeMe } from "react-sizeme"
import ConceptListItem from "../ConceptListItem"
import ResponseListItem from "../inputs/ResponseListItem"
import { ScoreSelectorDefaultValues } from "../inputs/types"
const useStyles = makeStyles(() => ({
    container: {
        height: "100%",
        display: "flex",
        flexDirection: "column",
        width: "100%",
        overflow: "hidden",
    },
}))

interface BubbleChartInterfaceProps {
    axisConfiguration: BubbleAxisConfigurationType
    labelType: "concept" | "user"
    scores: CriteriaScore[]
    onEditResponse?: (value: number[]) => void
}

const BubbleChartInterface: React.FC<BubbleChartInterfaceProps> = ({
    axisConfiguration,
    labelType,
    scores,
    onEditResponse,
}) => {
    // imported hooked, etc.
    const theme = useTheme()
    const classes = useStyles()

    //maniuplation of props data
    let chartData = scores
        ?.filter((x) => !!x?.response)
        .map((x) => {
            return {
                label: x.label,
                user: !!x.user ? { ...x.user } : null,
                scoredConcept: {
                    ...x.scoredConcept,
                },
                conceptDefault: !!x.conceptDefault,
                xValue: JSON.parse(x.response ?? "[]")?.[0] ?? 0,
                yValue: JSON.parse(x.response ?? "[]")?.[1] ?? 0,
            }
        })

    const handleClick = (data: { xValue: number; yValue: number }) => {
        if (!!data?.xValue && !!data?.yValue && !!onEditResponse) {
            onEditResponse([data.xValue, data.yValue])
        }
    }

    return (
        <Box
            display="flex"
            height="100%"
            width="100%"
            p={!!onEditResponse ? 1 : 0}
        >
            <SizeMe monitorHeight monitorWidth>
                {({ size }) => (
                    <div className={classes.container}>
                        <Box flexGrow={1} overflow="hidden" width="100%">
                            <ResponsiveContainer width="100%" height="100%">
                                <ScatterChart
                                    onClick={handleClick}
                                    style={{
                                        cursor: !!onEditResponse
                                            ? "crosshair"
                                            : "default",
                                    }}
                                >
                                    <XAxis
                                        type="number"
                                        dataKey={"xValue"}
                                        name={"x-axis"}
                                        domain={[-100, 100]}
                                        xAxisId={"x-axis"}
                                        hide={true}
                                    />
                                    <YAxis
                                        type="number"
                                        dataKey={"yValue"}
                                        name={"y-axis"}
                                        domain={[-100, 100]}
                                        yAxisId={"y-axis"}
                                        hide={true}
                                    />
                                    <ReferenceLine
                                        xAxisId={"x-axis"}
                                        yAxisId={"y-axis"}
                                        x={0}
                                    />
                                    <ReferenceLine
                                        xAxisId={"x-axis"}
                                        yAxisId={"y-axis"}
                                        y={0}
                                    />

                                    <ReferenceLine
                                        xAxisId={"x-axis"}
                                        yAxisId={"y-axis"}
                                        segment={[
                                            {
                                                x: 0,
                                                y: 100,
                                            },
                                            {
                                                x: 0,
                                                y: 100,
                                            },
                                        ]}
                                        label={
                                            <CustomLabel
                                                backgroundColor={
                                                    axisConfiguration.quandrantOneBackgroundColor ??
                                                    "transparent"
                                                }
                                                quadrant={1}
                                                width={size.width}
                                                height={size.height}
                                                label={
                                                    axisConfiguration.quandrantOneLabel
                                                }
                                            />
                                        }
                                    />

                                    <ReferenceLine
                                        xAxisId={"x-axis"}
                                        yAxisId={"y-axis"}
                                        segment={[
                                            {
                                                x: -100,
                                                y: 100,
                                            },
                                            {
                                                x: -100,
                                                y: 100,
                                            },
                                        ]}
                                        label={
                                            <CustomLabel
                                                backgroundColor={
                                                    axisConfiguration.quandrantTwoBackgroundColor ||
                                                    "transparent"
                                                }
                                                quadrant={2}
                                                width={size.width}
                                                height={size.height}
                                                label={
                                                    axisConfiguration.quandrantTwoLabel
                                                }
                                            />
                                        }
                                    />

                                    <ReferenceLine
                                        xAxisId={"x-axis"}
                                        yAxisId={"y-axis"}
                                        segment={[
                                            {
                                                x: -100,
                                                y: 0,
                                            },
                                            {
                                                x: -100,
                                                y: 0,
                                            },
                                        ]}
                                        label={
                                            <CustomLabel
                                                backgroundColor={
                                                    axisConfiguration.quandrantThreeBackgroundColor ||
                                                    "transparent"
                                                }
                                                quadrant={3}
                                                width={size.width}
                                                height={size.height}
                                                label={
                                                    axisConfiguration.quandrantThreeLabel
                                                }
                                            />
                                        }
                                    />

                                    <ReferenceLine
                                        xAxisId={"x-axis"}
                                        yAxisId={"y-axis"}
                                        segment={[
                                            {
                                                x: 0,
                                                y: 0,
                                            },
                                            {
                                                x: 0,
                                                y: 0,
                                            },
                                        ]}
                                        label={
                                            <CustomLabel
                                                backgroundColor={
                                                    axisConfiguration.quandrantFourBackgroundColor ||
                                                    "transparent"
                                                }
                                                quadrant={4}
                                                width={size.width}
                                                height={size.height}
                                                label={
                                                    axisConfiguration.quandrantFourLabel
                                                }
                                            />
                                        }
                                    />

                                    {axisConfiguration.positiveXAxisLabel && (
                                        <ReferenceLine
                                            xAxisId={"x-axis"}
                                            yAxisId={"y-axis"}
                                            segment={[
                                                {
                                                    x: 0,
                                                    y: 0,
                                                },
                                                {
                                                    x: 0,
                                                    y: 0,
                                                },
                                            ]}
                                            label={
                                                <CustomAxisLabel
                                                    label={
                                                        axisConfiguration.positiveXAxisLabel
                                                    }
                                                    width={size.width / 2}
                                                    justifyContent="flex-end"
                                                    axis={"positiveXAxis"}
                                                />
                                            }
                                        />
                                    )}
                                    {axisConfiguration.positiveYAxisLabel && (
                                        <ReferenceLine
                                            xAxisId={"x-axis"}
                                            yAxisId={"y-axis"}
                                            segment={[
                                                {
                                                    x: -100,
                                                    y: 100,
                                                },
                                                {
                                                    x: -100,
                                                    y: 100,
                                                },
                                            ]}
                                            label={
                                                <CustomAxisLabel
                                                    label={
                                                        axisConfiguration.positiveYAxisLabel
                                                    }
                                                    width={size.width}
                                                    justifyContent="center"
                                                    axis={"positiveYAxis"}
                                                />
                                            }
                                        />
                                    )}
                                    <ReferenceLine
                                        xAxisId={"x-axis"}
                                        yAxisId={"y-axis"}
                                        segment={[
                                            {
                                                x: -100,
                                                y: 0,
                                            },
                                            {
                                                x: -100,
                                                y: 0,
                                            },
                                        ]}
                                        label={
                                            <CustomAxisLabel
                                                label={
                                                    axisConfiguration.negativeXAxisLabel
                                                }
                                                width={size.width / 2}
                                                justifyContent="flex-start"
                                                axis={"negativeXAxis"}
                                            />
                                        }
                                    />
                                    <ReferenceLine
                                        xAxisId={"x-axis"}
                                        yAxisId={"y-axis"}
                                        segment={[
                                            {
                                                x: -100,
                                                y: -90,
                                            },
                                            {
                                                x: -100,
                                                y: -90,
                                            },
                                        ]}
                                        label={
                                            <CustomAxisLabel
                                                label={
                                                    axisConfiguration.negativeYAxisLabel
                                                }
                                                axis={"negativeYAxis"}
                                                width={size.width}
                                                justifyContent="center"
                                            />
                                        }
                                    />

                                    <Scatter
                                        style={{ cursor: "pointer" }}
                                        data={chartData}
                                        xAxisId={"x-axis"}
                                        yAxisId={"y-axis"}
                                        shape={
                                            <CustomDot
                                                labelType={labelType}
                                                theme={theme}
                                            />
                                        }
                                        fill={theme.palette.primary.main}
                                    />
                                </ScatterChart>
                            </ResponsiveContainer>
                        </Box>
                    </div>
                )}
            </SizeMe>
        </Box>
    )
}

const CustomLabel = (props) => {
    let adjustedX = 0
    let adjustedY = 0
    switch (props.quadrant) {
        case 1:
            adjustedX = props.viewBox.x
            adjustedY = props.viewBox.y - props.offset
            break
        case 2:
            adjustedX = props.viewBox.x - props.offset
            adjustedY = props.viewBox.y - props.offset
            break
        case 3:
            adjustedX = props.viewBox.x - props.offset
            adjustedY = props.viewBox.y
            break
        case 4:
            adjustedX = props.viewBox.x
            adjustedY = props.viewBox.y
            break
    }

    const width = props.width || 100
    const height = props.height || 100
    return (
        <foreignObject
            width={width / 2}
            height={height / 2}
            x={adjustedX}
            y={adjustedY}
        >
            <Box
                width="100%"
                height="100%"
                display="flex"
                alignItems="center"
                justifyContent="center"
                style={{ backgroundColor: props.backgroundColor }}
            >
                {!!props.label && (
                    <Box
                        display="flex"
                        alignItems={"center"}
                        justifyContent="center"
                        width={"75%"}
                        height="75%"
                    >
                        <Typography>{props.label}</Typography>
                    </Box>
                )}
            </Box>
        </foreignObject>
    )
}

const CustomAxisLabel = (props) => {
    const { label, width, justifyContent, offset } = props
    let adjustedX = 0
    let adjustedY = 0
    switch (props.axis) {
        case "positiveXAxis":
            adjustedX = props.viewBox.x
            adjustedY = props.viewBox.y
            break
        case "negativeXAxis":
            adjustedX = props.viewBox.x - offset
            adjustedY = props.viewBox.y
            break
        case "positiveYAxis":
            adjustedX = props.viewBox.x - offset
            adjustedY = props.viewBox.y
            break
        case "negativeYAxis":
            adjustedX = props.viewBox.x - offset
            adjustedY = props.viewBox.y - offset * 2
            break
    }
    if (!label) {
        return null
    }

    return (
        <foreignObject
            width={width || 100}
            height={"3em"}
            x={adjustedX}
            y={adjustedY}
        >
            <Box
                width="100%"
                height="100%"
                display="flex"
                justifyContent={justifyContent}
                p={0.5}
            >
                <Typography>{label}</Typography>
            </Box>
        </foreignObject>
    )
}

const CustomDot = (props) => {
    const { cx, cy, payload, theme, labelType } = props
    const dotWidthAndHeight = 40
    const dotContent = (
        <Avatar
            style={{
                width: dotWidthAndHeight,
                height: dotWidthAndHeight,
                backgroundColor: !!payload.conceptDefault
                    ? theme.palette.secondary.main
                    : theme.palette.primary.main,
            }}
            src={
                labelType === "concept"
                    ? payload.scoredConcept?.imageUrl
                    : payload.label || payload.conceptDefault
                    ? undefined
                    : payload.user?.imageUrl
            }
        >
            <Box display="flex" alignItems="center" justifyContent="center">
                {labelType === "concept" ? (
                    payload.scoredConcept?.title?.[0]
                ) : payload.conceptDefault ? (
                    <AssignmentIndIcon />
                ) : payload.label ? (
                    payload.label?.[0]
                ) : (
                    payload.user?.firstName?.[0] + payload.user?.lastName?.[0]
                )}
            </Box>
        </Avatar>
    )

    return (
        <foreignObject
            x={cx - dotWidthAndHeight / 2}
            y={cy - dotWidthAndHeight / 2}
            width={dotWidthAndHeight}
            height={dotWidthAndHeight}
            key={payload.user?.userId + payload.scoredConcept?.id}
            onClick={(e) => e.stopPropagation()}
            style={{
                cursor: "pointer",
            }}
        >
            <ClickableRichTooltip
                placement="left"
                content={
                    <Box
                        key={
                            payload.user?.userId +
                            payload.scoredConcept?.id +
                            "box"
                        }
                        minWidth={300}
                        maxWidth={400}
                        overflow="hidden"
                    >
                        <Box p={1}>
                            <ResponseListItem
                                item={{
                                    label: payload.conceptDefault
                                        ? ScoreSelectorDefaultValues.primaryResponse
                                        : payload.label,
                                    user: payload.conceptDefault
                                        ? null
                                        : payload.user,
                                }}
                                isConceptDefault={!!payload.conceptDefault}
                            />
                        </Box>
                        <Divider />
                        <ConceptListItem
                            hideDivider={true}
                            showSummary={true}
                            item={payload.scoredConcept}
                        />
                    </Box>
                }
            >
                {dotContent}
            </ClickableRichTooltip>
        </foreignObject>
    )
}

export default React.memo(BubbleChartInterface)
