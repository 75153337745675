import React, {
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
    memo,
} from "react"
import { DndProvider } from "react-dnd"
import { HTML5Backend } from "react-dnd-html5-backend"
import { useHistory } from "react-router-dom"
import {
    AutoformatPlugin,
    CodeBlockElement,
    createAlignPlugin,
    createAutoformatPlugin,
    createBlockquotePlugin,
    createBoldPlugin,
    createCodeBlockPlugin,
    createCodePlugin,
    createComboboxPlugin,
    createDeserializeCsvPlugin,
    createDeserializeDocxPlugin,
    createDeserializeMdPlugin,
    createDndPlugin,
    createExitBreakPlugin,
    createFontBackgroundColorPlugin,
    createFontColorPlugin,
    createFontSizePlugin,
    createHeadingPlugin,
    createHighlightPlugin,
    createHistoryPlugin,
    createHorizontalRulePlugin,
    createImagePlugin,
    createIndentPlugin,
    createItalicPlugin,
    createKbdPlugin,
    createLineHeightPlugin,
    createLinkPlugin,
    createListPlugin,
    createMediaEmbedPlugin,
    createMentionPlugin,
    createNodeIdPlugin,
    ELEMENT_TABLE,
    createParagraphPlugin,
    createPlateUI,
    createReactPlugin,
    createResetNodePlugin,
    createSelectOnBackspacePlugin,
    createSoftBreakPlugin,
    createStrikethroughPlugin,
    createSubscriptPlugin,
    createSuperscriptPlugin,
    createTablePlugin,
    createTodoListPlugin,
    createTrailingBlockPlugin,
    createUnderlinePlugin,
    ELEMENT_CODE_BLOCK,
    ELEMENT_MENTION,
    ELEMENT_PARAGRAPH,
    ELEMENT_TD,
    ELEMENT_TH,
    MentionElement,
    Plate,
    StyledElement,
    TableCellElement,
    TEditableProps,
    TNode,
    withProps,
    TableElement,
    MentionPlugin,
} from "@udecode/plate"
import { createJuicePlugin } from "@udecode/plate-juice"

import { alignPlugin } from "./align/alignPlugin"
import { MarkBalloonToolbar } from "./toolbar/MarkBalloonToolbar"
import { withStyledDraggables } from "./dnd/withStyledDraggables"
import { exitBreakPlugin } from "./exit-break/exitBreakPlugin"
import { indentPlugin } from "./indent/indentPlugin"
import { linkPlugin } from "./link/linkPlugin"
import { resetBlockTypePlugin } from "./reset-node/resetBlockTypePlugin"
import { selectOnBackspacePlugin } from "./select-on-backspace/selectOnBackspacePlugin"
import { softBreakPlugin } from "./soft-break/softBreakPlugin"
import { Toolbar } from "./toolbar/Toolbar"
import { trailingBlockPlugin } from "./trailing-block/trailingBlockPlugin"
import {
    createMyPlugins,
    MyEditor,
    MyPlatePlugin,
    MyValue,
} from "./typescript/plateTypes"
import { ToolbarButtons } from "./toolbar/ToolbarButtons"
import { convertValues, SlateValue } from "./utils"

import { Box, Grow, IconButton, makeStyles, Slide } from "@material-ui/core"
import { autoformatPlugin } from "./autoformat/autoformatPlugin"
import { withStyledPlaceHolders } from "./placeholder/withStyledPlaceHolders"
import TextFields from "@material-ui/icons/TextFields"

import { lineHeightPlugin } from "./line-height/lineHeightPlugin"
import { ConceptMention } from "./mentionables/ConceptMention"
import ConceptPreviewPopper from "../ConceptPreviewPopper"
import { UserMention } from "./mentionables/UserMention"
import useImageTools from "../images/useImageTools"
import { useAuth } from "../../providers/AuthProvider"
import { dataURLtoFile } from "../../util/fns"
import AIHelper from "../AIHelper"
import Assistant from "@material-ui/icons/Assistant"
import { ChatMessage } from "../../__generated__/types"
import { useTheme } from "@material-ui/core/styles"

export const BASE_INITIAL_TEXT = [{ children: [{ text: "" }], type: "p" }]

export const IsPlateStringEmpty = (value: string) => {
    try {
        const parsedText = JSON.parse(value)
        //checking to see if it is an empty slate text
        if (
            parsedText?.length === 1 &&
            parsedText?.[0]?.type === BASE_INITIAL_TEXT[0].type &&
            parsedText?.[0]?.children?.length === 1 &&
            parsedText?.[0]?.children?.[0]?.text === ""
        ) {
            return true
        } else {
            return false
        }
    } catch (e) {
        return true
    }
}

const useStyles = makeStyles((theme) => ({
    root: {
        height: "100%",
        display: "flex",
        width: "100%",
        overflow: "hidden",
    },
    readOnly: {
        flexGrow: 1,
        overflow: "auto",
        margin: 0,
        height: "inherit",
        minHeight: "inherit",
    },
    focused: {
        borderRadius: theme.shape.borderRadius,
        border: "2px solid",
        borderColor: theme.palette.primary.main,
    },
    editable: {
        border: `1px solid ${theme.palette.divider}`,
        "&:hover": {
            border: `1px solid ${theme.palette.text.primary}`,
        },
        borderRadius: theme.shape.borderRadius,
    },
    editableNoEffects: {},
}))

let components = createPlateUI({
    [ELEMENT_CODE_BLOCK]: CodeBlockElement,
    [ELEMENT_PARAGRAPH]: withProps(StyledElement, {
        // as: 'p',
        styles: {
            root: {
                margin: 0,
                padding: "4px 0",
            },
        },
        prefixClassNames: "p",
    }),
    [ELEMENT_TD]: withProps(TableCellElement, {
        styles: {
            root: {
                border: "1px solid black",
                backgroundColor: "inherit",
            },
        },
    }),
    [ELEMENT_TABLE]: withProps(TableElement, {
        styles: {
            root: {
                "&& button": {
                    color: "red",
                },
            },
        },
    }),
    [ELEMENT_TH]: withProps(TableCellElement, {
        styles: {
            root: {
                backgroundColor: "inherit",
                border: "1px solid black",
            },
        },
    }),
    // customize your components by plugin key
})
components = withStyledPlaceHolders(components)

interface RichTextEditorProps {
    editorId: string
    editable: boolean
    updateWhileEditing?: boolean
    placeholderText?: string
    onChange?: (value: string) => void
    onChatMessage?: (value: ChatMessage[]) => void
    currentChatMessages?: ChatMessage[]
    initialValue?: string
    users?: any[]
    disableEffects?: boolean
    keepToolbar?: boolean
    autoFocus?: boolean
    aiPrompt?: { basePrompt: string; firstMessage: string }
}
const RichTextEditor = (props: RichTextEditorProps) => {
    // RichTextEditor is an uncontrolled component that only uses the first initialValue and ignore the others for perfomance reason.
    // If you are trying to control the value of the editor, past the initial value, it won't work.
    // This is on-purpose, as the alternative is to handle every change and forcing Slate to reevaluate every config on change.
    let originalInitialValue = useRef(props.initialValue)
    let initialTransformedValue = useMemo(
        () => convertValues(originalInitialValue.current),
        []
    )

    const originalOnChange = props.onChange
    const onChange = useCallback(
        (data) => {
            if (!!originalOnChange) {
                originalOnChange(JSON.stringify(data))
            }
        },
        [originalOnChange]
    )

    return (
        <PlateEditor
            {...props}
            onChange={onChange}
            editorId={props.editorId}
            initialValue={initialTransformedValue}
        />
    )
}
export const ELEMENT_CONCEPT = "concept"
const PlateEditor = (
    props: Omit<RichTextEditorProps, "initialValue" | "onChange"> & {
        initialValue?: SlateValue
        onChange: (value: TNode[]) => void
        editorId: string
        updateWhileEditing?: boolean
    }
) => {
    const classes = useStyles()
    const history = useHistory()
    const { currentUser } = useAuth()
    const { onUploadPhoto } = useImageTools()
    const [editorClass, setEditorClass] = useState("")
    const [showHelper, setShowHelper] = useState(false)
    const [editorValue, setEditorValue] = useState(props.initialValue)
    const [showToolbar, setShowToolbar] = useState(!!props.keepToolbar)
    useEffect(() => {
        if (!!props.editable) {
            if (!!props.disableEffects) {
                setEditorClass(classes.editableNoEffects)
            } else {
                setEditorClass(classes.editable)
            }
        } else {
            setEditorClass(classes.readOnly)
        }
    }, [
        props.editable,
        classes.editable,
        classes.readOnly,
        classes.editableNoEffects,
        props.disableEffects,
    ])

    const theme = useTheme()
    const readOnlyEditableProps: TEditableProps = {
        readOnly: true,
        style: {
            padding: 8,
            overflow: "auto",
        },
    }

    const editableProps: TEditableProps = {
        placeholder: props.placeholderText ?? "",
        spellCheck: true,
        readOnly: false,
        autoFocus: !!props.autoFocus,
        style: {
            paddingTop: 8,
            paddingRight: 8,
            paddingBottom: 8,
            paddingLeft: 32,
            overflow: "auto",
            height: "100%",
        },
    }

    const plugins = useMemo(
        () =>
            createMyPlugins(
                [
                    createReactPlugin(),
                    createHistoryPlugin(),
                    createResetNodePlugin(resetBlockTypePlugin),

                    createParagraphPlugin(),
                    createBlockquotePlugin(),
                    createTodoListPlugin(),
                    createHeadingPlugin(),
                    createImagePlugin({
                        options: {
                            uploadImage: async (dataUrl) => {
                                const file = dataURLtoFile(
                                    dataUrl,
                                    currentUser.username +
                                        JSON.stringify(Date.now())
                                )
                                return await onUploadPhoto(
                                    file,
                                    currentUser.username +
                                        JSON.stringify(Date.now())
                                )
                            },
                        },
                    }),
                    createHorizontalRulePlugin(),
                    createLinkPlugin(linkPlugin),
                    createListPlugin(),

                    createTablePlugin(),
                    createMediaEmbedPlugin(),
                    createCodeBlockPlugin(),
                    createAlignPlugin(alignPlugin),
                    //Marks
                    createBoldPlugin(),
                    createCodePlugin(),
                    createItalicPlugin(),
                    createHighlightPlugin(),
                    createUnderlinePlugin(),
                    createStrikethroughPlugin(),
                    createSubscriptPlugin(),
                    createSuperscriptPlugin(),
                    createFontColorPlugin(),
                    createFontBackgroundColorPlugin(),
                    createFontSizePlugin(),
                    createKbdPlugin(),
                    createAutoformatPlugin<
                        AutoformatPlugin<MyValue, MyEditor>,
                        MyValue
                    >(autoformatPlugin),
                    // createBlockSelectionPlugin(),
                    createNodeIdPlugin({
                        options: { filterText: false },
                    }),
                    createDndPlugin({ options: { enableScroller: true } }),
                    createLineHeightPlugin(lineHeightPlugin),
                    createIndentPlugin(indentPlugin),
                    createSoftBreakPlugin(softBreakPlugin),
                    createExitBreakPlugin(exitBreakPlugin),

                    // createNormalizeTypesPlugin(forcedLayoutPlugin),
                    createTrailingBlockPlugin(trailingBlockPlugin),
                    createSelectOnBackspacePlugin(selectOnBackspacePlugin),
                    createComboboxPlugin(),

                    createMentionPlugin<MentionPlugin, MyValue>({
                        key: ELEMENT_CONCEPT,
                        component: MentionElement,
                        props: (editor) => {
                            const onMentionClicked = () => {
                                const item: { id?: string } =
                                    editor.element?.data
                                if (!!item?.id) {
                                    history.push(`/concept/${item.id}/home`)
                                }
                            }

                            const renderLabel = (element) => {
                                return (
                                    <ConceptPreviewPopper
                                        conceptId={element.data?.id}
                                        key={element.data?.id}
                                    >
                                        <span
                                            style={{
                                                display: "flex",
                                                cursor: "pointer",
                                                fontSize:
                                                    theme.typography.body2
                                                        .fontSize,
                                            }}
                                        >
                                            {element.value}
                                        </span>
                                    </ConceptPreviewPopper>
                                )
                            }

                            return {
                                onClick: onMentionClicked,
                                renderLabel: renderLabel,
                            }
                        },
                        options: {
                            trigger: "#",
                            createMentionNode: (item) => {
                                return {
                                    id: item.key,
                                    value: item.text,
                                    data: {
                                        id: item.key,
                                    },
                                }
                            },
                            insertSpaceAfterMention: true,
                        },
                    }),
                    createMentionPlugin<MentionPlugin, MyValue>({
                        key: ELEMENT_MENTION,
                        component: MentionElement,
                        props: (editor) => {
                            const onMentionClicked = () => {
                                const item: { id?: string } =
                                    editor.element?.data

                                if (!!item?.id) {
                                    history.push(`/user/${item.id}/home`)
                                }
                            }

                            const renderLabel = (element) => {
                                return (
                                    <div
                                        title={element.value}
                                        style={{
                                            display: "flex",
                                            cursor: "pointer",
                                            fontSize:
                                                theme.typography.body2.fontSize,
                                        }}
                                    >
                                        {element.value}
                                    </div>
                                )
                            }

                            return {
                                onClick: onMentionClicked,
                                renderLabel: renderLabel,
                            }
                        },
                        options: {
                            trigger: "@",
                            createMentionNode: (item) => {
                                return {
                                    id: item.key,
                                    value: item.text,
                                    data: {
                                        id: item.key,
                                    },
                                }
                            },

                            insertSpaceAfterMention: true,
                        },
                    }),
                    createDeserializeMdPlugin(),
                    createDeserializeCsvPlugin(),
                    createDeserializeDocxPlugin(),
                    createJuicePlugin() as MyPlatePlugin,
                ],
                {
                    components: withStyledDraggables(components, theme),
                }
            ),
        [theme, history, currentUser.username, onUploadPhoto]
    )

    const handleChange = (e) => {
        setEditorValue(e)
        if (!!props.updateWhileEditing) {
            props.onChange(e)
        }
    }
    const saveChanges = () => {
        if (!!editorValue) {
            props.onChange(editorValue)
        }
    }

    return (
        <Box className={classes.root}>
            <Box
                display="flex"
                flexGrow={1}
                width="100%"
                className={editorClass}
                flexDirection="column"
                overflow="hidden"
                position="relative"
                key={props.editorId}
                onClick={() => {
                    if (!!props.editable && !props.disableEffects) {
                        setEditorClass(classes.focused)
                    }
                }}
                onBlur={() => {
                    saveChanges()
                    if (
                        !!props.editable &&
                        !props.disableEffects &&
                        editorClass !== classes.editable
                    ) {
                        setEditorClass(classes.editable)
                    }
                }}
                style={{
                    borderTopLeftRadius: !!props.editable
                        ? theme.shape.borderRadius
                        : 0,
                    borderTopRightRadius: !!props.editable
                        ? theme.shape.borderRadius
                        : 0,
                }}
            >
                <DndProvider backend={HTML5Backend}>
                    <Plate
                        id={props.editorId}
                        plugins={plugins as any}
                        initialValue={props.initialValue}
                        editableProps={
                            !props.editable
                                ? readOnlyEditableProps
                                : editableProps
                        }
                        onChange={handleChange}
                        firstChildren={
                            !!showToolbar && !!props.editable ? (
                                <Grow
                                    in={showToolbar && !!props.editable}
                                    mountOnEnter
                                    unmountOnExit
                                >
                                    <div>
                                        <Toolbar
                                            style={{
                                                margin: 0,
                                                padding: "4px",
                                                paddingRight: "8px",
                                                color: "inherit",
                                            }}
                                        >
                                            <ToolbarButtons />
                                        </Toolbar>
                                    </div>
                                </Grow>
                            ) : null
                        }
                    >
                        <MarkBalloonToolbar />
                        <ConceptMention />
                        <UserMention />
                    </Plate>
                </DndProvider>
                {!!props.editable && (
                    <Box display={"flex"} position="absolute" top={0} right={0}>
                        <IconButton
                            onClick={(ev) => {
                                ev.stopPropagation()
                                setShowToolbar((v) => !v)
                            }}
                        >
                            <TextFields
                                fontSize="small"
                                color={!showToolbar ? "action" : "disabled"}
                            />
                        </IconButton>
                        {!!props.aiPrompt && (
                            <IconButton
                                onClick={(ev) => {
                                    ev.stopPropagation()
                                    setShowHelper((prev) => !prev)
                                }}
                            >
                                <Assistant />
                            </IconButton>
                        )}
                    </Box>
                )}
            </Box>
            <Slide
                direction="left"
                in={!!showHelper && !!props.aiPrompt && !!props.editable}
                mountOnEnter
                unmountOnExit
            >
                <Box ml={1} width={"100%"} height="100%" display="flex">
                    <AIHelper
                        systemPrompt={props.aiPrompt?.basePrompt}
                        firstMessage={props.aiPrompt?.firstMessage}
                        onGenerate={props.onChatMessage}
                        currentChatMessages={props.currentChatMessages}
                    />
                </Box>
            </Slide>
        </Box>
    )
}

export default memo(RichTextEditor)
