import React, { useState, useEffect } from "react"
import { useMutation } from "@apollo/client"

import Box from "@material-ui/core/Box"
import Dialog from "@material-ui/core/Dialog"
import DialogContent from "@material-ui/core/DialogContent"
import DialogTitle from "@material-ui/core/DialogTitle"
import DialogActions from "@material-ui/core/DialogActions"
import Button from "@material-ui/core/Button"
import TextField from "@material-ui/core/TextField"
import CircularProgress from "@material-ui/core/CircularProgress"
import {
    ALL_INDUSTRY_QUERY,
    CREATE_INDUSTRY,
    UPDATE_INDUSTRY,
    DELETE_INDUSTRY,
} from "./graphql"
import {
    CreateIndustryMutation,
    CreateIndustryMutationVariables,
    UpdateIndustryMutation,
    UpdateIndustryMutationVariables,
    DeleteIndustryMutation,
    DeleteIndustryMutationVariables,
} from "./__generated__/graphql"
import useAwaitTranslation from "../../i18n/useAwaitTranslation"
import { Industry } from "../../__generated__/types"
import DeleteForever from "@material-ui/icons/DeleteForever"

interface IndustryFormProps {
    onClose: () => void
    industry?: Industry
}
const IndustryForm = (props: IndustryFormProps) => {
    const [value, setValue] = useState("")
    const [confirm, setConfirm] = useState(false)
    const { t } = useAwaitTranslation("industry")
    const [createIndustry] = useMutation<
        CreateIndustryMutation,
        CreateIndustryMutationVariables
    >(CREATE_INDUSTRY, {
        refetchQueries: [
            {
                query: ALL_INDUSTRY_QUERY,
            },
        ],
    })
    const [updateIndustry] = useMutation<
        UpdateIndustryMutation,
        UpdateIndustryMutationVariables
    >(UPDATE_INDUSTRY)
    const [deleteIndustry] = useMutation<
        DeleteIndustryMutation,
        DeleteIndustryMutationVariables
    >(DELETE_INDUSTRY, {
        update(cache) {
            cache.evict({
                id: cache.identify(props.industry),
            })
        },
    })
    useEffect(() => {
        if (!!props.industry) {
            setValue(props.industry.name)
        }
    }, [props.industry])
    const [saving, setSaving] = useState(false)
    const handleSave = async () => {
        setSaving(true)
        if (!!props.industry) {
            await updateIndustry({
                variables: {
                    industryId: props.industry?.industryId,
                    name: value,
                },
            })
        } else {
            await createIndustry({
                variables: { name: value },
            })
        }
        props.onClose()
    }
    const handleDeletion = () => {
        deleteIndustry({
            variables: { industryId: props.industry.industryId },
        })
        props.onClose()
    }
    return (
        <Dialog open={true} fullWidth>
            {confirm ? (
                <>
                    <DialogContent>
                        {t(
                            "confirmDeleteIndustry",
                            "Are you sure you want to delete"
                        )}{" "}
                        {props.industry?.name}?
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={() => {
                                setConfirm(false)
                            }}
                        >
                            {t("no", "No")}
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleDeletion}
                        >
                            {t("yes", "Yes")}
                        </Button>
                    </DialogActions>
                </>
            ) : (
                <>
                    <DialogTitle>
                        <Box>
                            {!!props.industry
                                ? t("industryEditor", "Industry Editor")
                                : t("industryForm", "Industry Form")}
                        </Box>
                    </DialogTitle>
                    <DialogContent>
                        <Box p={2}>
                            <TextField
                                required
                                disabled={saving}
                                style={{ width: "100%" }}
                                value={value}
                                label={t("name", "Name")}
                                onChange={(event) => {
                                    setValue(event.target.value)
                                }}
                            />
                        </Box>
                    </DialogContent>
                    <DialogActions>
                        <Box
                            display="flex"
                            width="100%"
                            justifyContent={
                                !!props.industry ? "space-between" : "flex-end"
                            }
                        >
                            {!!props.industry && (
                                <Button
                                    startIcon={<DeleteForever color="error" />}
                                    onClick={() => {
                                        setConfirm(true)
                                    }}
                                    disabled={saving}
                                >
                                    {t("delete", "Delete")}
                                </Button>
                            )}
                            <Box>
                                <Button
                                    onClick={() => {
                                        props.onClose()
                                    }}
                                    disabled={saving}
                                >
                                    {t("discard", "Discard")}
                                </Button>
                                <Button
                                    disabled={
                                        saving ||
                                        props.industry?.name === value ||
                                        value.length === 0
                                    }
                                    color="primary"
                                    onClick={handleSave}
                                >
                                    {saving ? (
                                        <CircularProgress
                                            size={20}
                                            disableShrink
                                        />
                                    ) : (
                                        t("save", "Save")
                                    )}
                                </Button>
                            </Box>
                        </Box>
                    </DialogActions>
                </>
            )}
        </Dialog>
    )
}

export default IndustryForm
