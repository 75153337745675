import React from "react"
import { Box, Button, Icon } from "@material-ui/core"

import { useInputTools } from "../useInputTools"
import { MultipleChoiceInterfaceProps } from "../../criteria/useCriteriaTypes"

const MultipleChoiceInterface: React.FC<MultipleChoiceInterfaceProps> = ({
    score,
    config,
}) => {
    const { options } = config
    const { onEditResponse } = useInputTools({})
    return (
        <Box
            display="flex"
            height="100%"
            width="100%"
            flexDirection="column"
            style={{ overflowY: "auto" }}
            justifyContent="center"
        >
            <Box
                display="flex"
                flexDirection="column"
                p={2}
                height="100%"
                justifyContent="center"
                alignItems="center"
            >
                {options?.map((option, idx) => {
                    return (
                        <Button
                            key={option.id || idx}
                            variant={
                                !!score?.response &&
                                score?.response === option.id
                                    ? "contained"
                                    : "outlined"
                            }
                            startIcon={
                                option.icon ? (
                                    <Icon>{option.icon.name}</Icon>
                                ) : null
                            }
                            style={{
                                margin: "4px",
                                flexGrow: 1,
                                maxHeight: 75,
                                width: "50%",
                            }}
                            color="primary"
                            size="small"
                            onClick={(ev) => {
                                let updateData = {
                                    response: option.id,
                                }

                                onEditResponse(
                                    {
                                        ...updateData,
                                    },
                                    score
                                )
                            }}
                            disabled={!score}
                        >
                            {option.value}
                        </Button>
                    )
                })}
            </Box>
        </Box>
    )
}

export default MultipleChoiceInterface
