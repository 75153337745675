import { JSONSchema4Type } from "json-schema"
import { ItemPrefixType } from "../../../../__generated__/types"
import { CriteriaType } from "../../../criteria/types"

//conceptOnly if true, it can only be added to non personal and non team concepts.
// It is for situation such as adding a criteria radar to a personal concept page - this stops that possibility

export const ConceptFeedSchema: JSONSchema4Type = {
    options: {
        template: true,
        conceptOnly: false,
    },
    required: [],
    properties: {
        label: {
            type: "string",
        },
        orderBy: {
            type: "string",
            enum: [
                "createdAt_desc",
                "createdAt_asc",
                "title_asc",
                "title_desc",
            ],
        },
        view: {
            type: "string",
            enum: ["list", "gallery", "chip"],
        },
        source: {
            type: "source",
            enum: ["allConcepts", "connections"],
        },
        conceptId: {
            type: "conceptSingle",
            options: {
                requiredForDisplaying: [
                    {
                        field: "source",
                        requiredValues: ["connections"],
                    },
                ],
            },
        },
        categoryId: {
            type: "categorySingle",
            options: {
                requiredForDisplaying: [
                    {
                        field: "source",
                        requiredValues: ["connections"],
                    },
                ],
            },
        },
        filters: {
            type: "filters",
        },
    },
}
export const HeadingSchema: JSONSchema4Type = {
    required: [],
    options: {
        template: true,
        conceptOnly: false,
    },
    properties: {
        label: {
            type: "string",
        },
        size: {
            type: "string",
            enum: ["small", "medium", "large"],
        },
        showDivider: {
            type: "boolean",
        },
    },
}
export const ConceptListSchema: JSONSchema4Type = {
    options: {
        template: false,
        conceptOnly: true,
    },
    required: ["conceptListId"],
    properties: {
        label: {
            type: "string",
        },
        conceptListId: {
            type: "conceptListSingle",
            options: {
                hide: true,
            },
        },
        description: {
            type: "string",
        },
        itemPrefixType: {
            type: "string",
            enum: [
                ItemPrefixType.CHECKBOX.toLowerCase(),
                ItemPrefixType.NUMBER.toLowerCase(),
                ItemPrefixType.NONE.toLowerCase(),
            ],
        },
    },
}
export const ConceptSchema: JSONSchema4Type = {
    options: {
        template: true,
        conceptOnly: false,
    },
    required: ["conceptId"],
    properties: {
        conceptId: {
            type: "conceptSingle",
        },
        label: {
            type: "string",
        },
        view: {
            type: "string",
            enum: ["Card", "Full Preview"],
        },
    },
}
export const ChartSchema: JSONSchema4Type = {
    options: {
        template: true,
        conceptOnly: false,
    },
    required: [],
    properties: {
        label: {
            type: "string",
        },
        source: {
            type: "source",
            enum: ["allConcepts", "connections"],
        },
        filters: {
            type: "filters",
        },
    },
}

export const BubbleChartSchema: JSONSchema4Type = {
    options: {
        template: true,
        conceptOnly: false,
    },
    required: ["criteriaIds"],
    properties: {
        label: {
            type: "string",
        },
        source: {
            type: "source",
            enum: ["allConcepts", "connections"],
        },
        filters: {
            type: "filters",
        },
        criteriaIds: {
            type: "criteriaMultiple",
            options: {
                criteriaType: CriteriaType.Number,
            },
        },
        conceptIds: {
            type: "conceptMultiple",
            options: {
                hide: true,
            },
        },
    },
}

export const RichTextEditorSchema: JSONSchema4Type = {
    options: {
        template: true,
        conceptOnly: false,
    },
    required: [],
    properties: {
        label: {
            type: "string",
        },
    },
}

export const CriteriaRadarSchema: JSONSchema4Type = {
    options: {
        template: false,
        conceptOnly: true,
    },
    required: ["criteriaIds"],
    properties: {
        label: {
            type: "string",
        },
        criteriaIds: {
            type: "criteriaMultiple",
            options: {
                criteriaType: CriteriaType.Number,
            },
        },
    },
}

export const CriteriaSchema: JSONSchema4Type = {
    required: ["criteriaId"],
    properties: {
        criteriaId: {
            type: "criteriaSingle",
            options: {
                hide: true,
            },
        },
        inputConfig: {
            type: "inputConfig",
            options: {
                hide: true,
            },
        },
    },
}

export const InputSchema: JSONSchema4Type = {
    required: ["criteriaId"],
    properties: {
        criteriaId: {
            type: "criteriaSingle",
            options: {
                hide: true,
            },
        },
        inputConfig: {
            type: "inputConfig",
            options: {
                hide: true,
            },
        },
    },
}

export const InputResultsSchema: JSONSchema4Type = {
    required: [],
    properties: {
        label: {
            type: "string",
        },
        collectionCriteria: {
            type: "collectionCriteria",
        },
    },
}

export const GraphSchema: JSONSchema4Type = {
    required: ["criteriaMultiple"],
    properties: {
        label: {
            type: "string",
        },
        criteriaIds: {
            type: "criteriaMultiple",
            options: {
                criteriaType: CriteriaType.Collection,
            },
        },
    },
}

export const VideoSchema: JSONSchema4Type = {
    options: {
        template: false,
        conceptOnly: false,
    },
    required: ["url"],
    properties: {
        label: {
            type: "string",
        },
        url: {
            type: "string",
        },
    },
}

export const UnknownSchema: JSONSchema4Type = {
    required: [],
    properties: {},
}
