import React, { useState, useEffect, useRef } from "react"
import {
    createStyles,
    Theme,
    makeStyles,
    useTheme,
} from "@material-ui/core/styles"
import { useMutation, useQuery, gql } from "@apollo/client"
import ConnectionViewer from "./ConnectionViewer"
import Divider from "@material-ui/core/Divider"
import useAwaitTranslation from "../i18n/useAwaitTranslation"
import CategoryMenuSelector from "./categories/CategoryMenuSelector"
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft"
import ChevronRightIcon from "@material-ui/icons/ChevronRight"
import EditIcon from "@material-ui/icons/Edit"
import { useAuth } from "../providers/AuthProvider"
import { useParams } from "react-router-dom"
import { Loading } from "./Loading"
import { getUserConceptPermission } from "../util/GetPermissions"
import {
    Box,
    Dialog,
    IconButton,
    Tooltip,
    Slide,
    useMediaQuery,
    Popper,
    Button,
    Paper,
    ClickAwayListener,
    DialogTitle,
    Fade,
} from "@material-ui/core"
import Filters from "./filters/Filters"
import { FilterItem } from "./filters/util/types"
import { Concept, _ConceptOrdering } from "../__generated__/types"
import {
    CONNECTION_MODAL,
    CONNECTION_VIEWER,
    FULL_CONCEPT_FILTERED,
} from "../graphql/queries"
import Breadcrumb from "./Breadcrumb"
import { ReactComponent as GlobalFilterIcon } from "../styles/FilterIcon.svg"
import Badge from "@material-ui/core/Badge"
import useFilters from "./filters/util/useFilters"
import CloseIcon from "@material-ui/icons/Close"
import FuzzyConceptSearch from "./FuzzyConceptSearch"
import AddBoxIcon from "@material-ui/icons/AddBox"
import ConceptListItem from "./ConceptListItem"
import {
    AddConceptConnectionsMutation,
    AddConceptConnectionsMutationVariables,
} from "../graphql/__generated__/mutations"
import { CONNECT_CONCEPTS } from "../graphql/mutations"
import {
    ConnectionModalQuery,
    ConnectionModalQueryVariables,
} from "../graphql/__generated__/queries"
import ErrorHandler from "./ErrorHandler"

import { FeedVariables, VIEW_OPTIONS } from "../providers/GlobalState"
import useEnvironmentSettingTools from "./settings/useEnvironmentSettingTools"
import { FeedViewTypeSelect } from "./FeedTools"
import ConnectionsGraphView from "./ConnectionsGraphView"
import Pageview from "@material-ui/icons/Pageview"

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        popper: {
            zIndex: theme.zIndex.modal + 1,
            width: "25em",
        },
        mobilePopper: {
            width: "100%",
            right: 0,
            left: 0,
            zIndex: theme.zIndex.modal + 1,
        },
        breadcrumbsContainer: {
            backgroundColor: theme.palette.background.default,
        },
        tools: {
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
        },
        flexEndOnMobile: {
            display: "flex",

            justifyContent: "flex-end",
        },
    })
)

interface DetailedConnectionPreviewDialogProps {
    itemId: string
    onClose: () => void
}

type BreadcrumbObject = {
    concept: Concept
    filters: FilterItem[]
    viewPrivate: boolean
    categoryId: string
}
function DetailedConnectionPreviewDialog(
    props: DetailedConnectionPreviewDialogProps
) {
    //hooks
    const theme = useTheme()
    const { conceptId } = useParams()
    const mobile = useMediaQuery(theme.breakpoints.down("sm"))
    const classes = useStyles()
    const { currentUser } = useAuth()

    const { t } = useAwaitTranslation("connectionPreview")

    //refs
    const itemRef = useRef<Concept>(null)
    const editPermission = useRef<Boolean>(false)
    const breadcrumbsContainerRef = useRef<HTMLElement[]>([])
    const { ableToConnect } = useEnvironmentSettingTools()
    const [displayType, setDisplayType] = useState<VIEW_OPTIONS>(
        VIEW_OPTIONS.GRAPH_2D
    )
    //state
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
    const [navigatedConceptId, setNavigatedConceptId] = useState<string>(null)
    const [breadcrumbObjects, setBreadcrumbObjects] = useState<
        BreadcrumbObject[]
    >([])

    const currentConceptId = navigatedConceptId ?? props.itemId

    const [browse, setBrowse] = useState(false)

    const {
        currentFeedVariables: connectionFeedVariables,
        setCurrentFeedVariables: setConnectionFeedVariables,
        filterObject: connectionsFilterObject,
        defaultFilters,
    } = useFilters({
        filters: null,
        categoryId: null,
        viewPrivate: true,
        connectionsConceptId: currentConceptId,
    })

    const {
        currentFeedVariables: browserFeedVariables,
        setCurrentFeedVariables: setBrowserFeedVariables,
        filterObject: browserFilterObject,
    } = useFilters({ filters: null, categoryId: null, viewPrivate: true })

    //mutations
    const [connectConcepts] = useMutation<
        AddConceptConnectionsMutation,
        AddConceptConnectionsMutationVariables
    >(CONNECT_CONCEPTS, {
        update(cache, { data: { AddConceptConnections } }) {
            cache.modify({
                id: cache.identify(AddConceptConnections.from),
                fields: {
                    connections(existingConnections = [], { readField }) {
                        const newConnectionRef = cache.writeFragment({
                            data: AddConceptConnections.to,
                            fragment: gql`
                                fragment NewConnection on Concept {
                                    id
                                }
                            `,
                        })
                        if (
                            existingConnections.some(
                                (ref) =>
                                    readField("id", ref) ===
                                    AddConceptConnections.to?.id
                            )
                        ) {
                            return existingConnections
                        }
                        return [...existingConnections, newConnectionRef]
                    },
                },
            })
        },
        refetchQueries: [
            {
                query: CONNECTION_VIEWER,
                variables: {
                    id: currentConceptId,
                    connectionsFilter: connectionsFilterObject,
                    orderBy: [_ConceptOrdering.CREATEDAT_DESC],
                },
            },
            {
                query: FULL_CONCEPT_FILTERED,
                variables: {
                    filter: connectionsFilterObject,
                },
            },
        ],
    })

    //to get fresh permissions, since we dont want to fetch permissions in the feed or elsewhere
    const { data, error } = useQuery<
        ConnectionModalQuery,
        ConnectionModalQueryVariables
    >(CONNECTION_MODAL, {
        variables: {
            id: currentConceptId,
            connectionsFilter: {
                ...connectionsFilterObject,
                category: undefined,
            },
        },
        fetchPolicy: "network-only",
        nextFetchPolicy: "cache-first",
    })

    //variables

    const item = data?.Concept?.[0] ?? null
    const open = Boolean(anchorEl)

    if (!!item) {
        itemRef.current = item
        editPermission.current =
            !!ableToConnect &&
            getUserConceptPermission(item, currentUser).editingPermission
    }

    useEffect(() => {
        if (breadcrumbsContainerRef?.current[breadcrumbObjects.length - 1]) {
            breadcrumbsContainerRef.current[
                breadcrumbObjects.length - 1
            ].scrollIntoView({ behavior: "smooth" })
        }
    }, [breadcrumbObjects.length])

    //functions
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(anchorEl ? null : event.currentTarget)
    }

    return (
        <Dialog
            key={props.itemId}
            open={true}
            fullWidth={!mobile}
            fullScreen={mobile}
            maxWidth="lg"
            disableEnforceFocus={true}
            PaperProps={{
                style: {
                    height: !mobile && 900,
                    maxHeight: "100vh",
                    overflow: "hidden",
                },
            }}
            onBackdropClick={() => props.onClose()}
        >
            <DialogTitle>
                {t("connectionViewer", "Connection Viewer")}
                <IconButton
                    style={{ position: "absolute", right: 0, top: 0 }}
                    onClick={() => props.onClose()}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>

            {error ? (
                <ErrorHandler message={error?.message} showMessage={true} />
            ) : (
                <Box
                    overflow="hidden"
                    display="flex"
                    flexDirection="column"
                    height="100%"
                    style={{
                        backgroundColor: theme.palette.background.default,
                    }}
                >
                    {breadcrumbObjects.length > 0 && (
                        <div
                            className={classes.breadcrumbsContainer}
                            style={{
                                paddingRight: theme.spacing(1),
                                paddingLeft: theme.spacing(1),
                                display: "flex",
                                alignItems: "center",
                                overflowX: "auto",
                                overflowY: "hidden",
                            }}
                        >
                            {breadcrumbObjects.map((object, index) => {
                                return (
                                    <div
                                        key={
                                            object.concept.id +
                                            JSON.stringify(index)
                                        }
                                        style={{
                                            display: "flex",
                                            alignItems: "center",
                                        }}
                                        ref={(element) =>
                                            (breadcrumbsContainerRef.current[
                                                index
                                            ] = element)
                                        }
                                    >
                                        <Breadcrumb
                                            breadcrumb={object.concept}
                                            onClick={() => {
                                                setConnectionFeedVariables({
                                                    filters:
                                                        breadcrumbObjects[index]
                                                            .filters,
                                                    viewPrivate:
                                                        breadcrumbObjects[index]
                                                            .viewPrivate,
                                                    categoryId:
                                                        breadcrumbObjects[index]
                                                            .categoryId ?? null,
                                                    connectionsConceptId:
                                                        breadcrumbObjects[index]
                                                            .concept?.id,
                                                })
                                                itemRef.current = null
                                                editPermission.current = false
                                                setNavigatedConceptId(
                                                    breadcrumbObjects[index]
                                                        .concept.id
                                                )
                                                setBreadcrumbObjects(
                                                    breadcrumbObjects.filter(
                                                        (item, idx) =>
                                                            index > idx
                                                    )
                                                )
                                            }}
                                        />
                                        {index !==
                                            breadcrumbObjects.length - 1 && (
                                            <Box
                                                p={0.5}
                                                display="flex"
                                                alignItems={"center"}
                                            >
                                                <ChevronRightIcon fontSize="small" />
                                            </Box>
                                        )}
                                    </div>
                                )
                            })}
                        </div>
                    )}

                    {!itemRef?.current ? (
                        <Loading size={25} hideQuote={true} />
                    ) : (
                        <Box
                            display="flex"
                            flexDirection="column"
                            overflow="hidden"
                            height="100%"
                        >
                            <Box
                                width="100%"
                                style={{
                                    backgroundColor:
                                        theme.palette.background.paper,
                                }}
                            >
                                <ConceptListItem
                                    item={itemRef.current}
                                    showSummary={!mobile}
                                    disableClick={
                                        conceptId === currentConceptId
                                    }
                                />
                            </Box>

                            <Slide
                                in={!browse}
                                appear={false}
                                direction="right"
                                unmountOnExit
                                mountOnEnter
                            >
                                <Box
                                    display="flex"
                                    overflow="hidden"
                                    flexGrow={1}
                                    flexDirection="column"
                                >
                                    <Fade in={true}>
                                        <Box
                                            display="flex"
                                            flexDirection="column"
                                            width="100%"
                                            style={{
                                                backgroundColor:
                                                    theme.palette.background
                                                        .paper,
                                            }}
                                        >
                                            {!!editPermission.current && (
                                                <>
                                                    <Box
                                                        p={2}
                                                        display="flex"
                                                        alignItems={"center"}
                                                        flexGrow={1}
                                                    >
                                                        <FuzzyConceptSearch
                                                            onSelection={(
                                                                concept: Concept
                                                            ) => {
                                                                connectConcepts(
                                                                    {
                                                                        variables:
                                                                            {
                                                                                fromId: currentConceptId,
                                                                                toId: concept?.id,
                                                                            },
                                                                    }
                                                                )
                                                            }}
                                                            disabledOptionIds={[
                                                                ...itemRef.current.connections.map(
                                                                    (item) =>
                                                                        item.id
                                                                ),
                                                                currentConceptId,
                                                            ]}
                                                            disableAdvancedSearch={
                                                                true
                                                            }
                                                            autoFocus={true}
                                                            inputAdornment={
                                                                <AddBoxIcon />
                                                            }
                                                            endAdornment={
                                                                <Box display="flex">
                                                                    <Divider
                                                                        style={{
                                                                            margin: "2px",
                                                                        }}
                                                                        flexItem
                                                                        orientation="vertical"
                                                                    />
                                                                    <Box ml={1}>
                                                                        <Button
                                                                            onClick={() =>
                                                                                setBrowse(
                                                                                    true
                                                                                )
                                                                            }
                                                                            endIcon={
                                                                                <Pageview />
                                                                            }
                                                                        >
                                                                            {t(
                                                                                "browse",
                                                                                "Browse"
                                                                            )}
                                                                        </Button>
                                                                    </Box>
                                                                </Box>
                                                            }
                                                            placeholder={t(
                                                                "typeToLinkConcept",
                                                                "Type to connect a new concept"
                                                            )}
                                                            variant="outlined"
                                                        />
                                                    </Box>
                                                </>
                                            )}

                                            <Box className={classes.tools}>
                                                <Box
                                                    flexGrow={1}
                                                    mr={2}
                                                    width="100%"
                                                >
                                                    <CategoryMenuSelector
                                                        textVariant="body1"
                                                        includeAllOption={true}
                                                        value={
                                                            connectionFeedVariables.categoryId
                                                        }
                                                        setValue={(
                                                            categoryId: string
                                                        ) => {
                                                            setConnectionFeedVariables(
                                                                {
                                                                    ...connectionFeedVariables,
                                                                    categoryId,
                                                                }
                                                            )
                                                        }}
                                                        allText={t(
                                                            "allConnections",
                                                            "All Connections"
                                                        )}
                                                        preOptionText={t(
                                                            "connected",
                                                            "Connected"
                                                        )}
                                                        counterData={
                                                            itemRef.current
                                                                ?.connections ??
                                                            []
                                                        }
                                                    />
                                                </Box>
                                                <Box
                                                    className={
                                                        classes.flexEndOnMobile
                                                    }
                                                >
                                                    {!!editPermission.current && (
                                                        <Box mr={2}>
                                                            <Tooltip
                                                                title={t(
                                                                    "editConnections",
                                                                    "Edit Connections"
                                                                )}
                                                            >
                                                                <IconButton
                                                                    onClick={() => {
                                                                        if (
                                                                            displayType !==
                                                                            VIEW_OPTIONS.NORMAL
                                                                        ) {
                                                                            setDisplayType(
                                                                                VIEW_OPTIONS.NORMAL
                                                                            )
                                                                        }
                                                                        setConnectionFeedVariables(
                                                                            {
                                                                                ...connectionFeedVariables,
                                                                                editing:
                                                                                    !connectionFeedVariables.editing,
                                                                            }
                                                                        )
                                                                    }}
                                                                >
                                                                    <EditIcon
                                                                        color={
                                                                            connectionFeedVariables.editing
                                                                                ? "primary"
                                                                                : "inherit"
                                                                        }
                                                                    />
                                                                </IconButton>
                                                            </Tooltip>
                                                        </Box>
                                                    )}

                                                    <FeedViewTypeSelect
                                                        value={displayType}
                                                        onChange={
                                                            setDisplayType
                                                        }
                                                    />
                                                    <Tooltip
                                                        title={t(
                                                            "filterConnections",
                                                            "Filter connections"
                                                        )}
                                                    >
                                                        <IconButton
                                                            onClick={
                                                                handleClick
                                                            }
                                                            style={{
                                                                marginRight: 10,
                                                            }}
                                                        >
                                                            <Badge
                                                                badgeContent={
                                                                    connectionFeedVariables.filters.filter(
                                                                        (x) => {
                                                                            return (
                                                                                x.value &&
                                                                                x
                                                                                    .value
                                                                                    .length >
                                                                                    0
                                                                            )
                                                                        }
                                                                    ).length
                                                                }
                                                                color="secondary"
                                                                overlap="rectangle"
                                                            >
                                                                <GlobalFilterIcon
                                                                    style={{
                                                                        width: "1em",
                                                                        height: "1em",
                                                                        fill: "currentColor",
                                                                    }}
                                                                />
                                                            </Badge>
                                                        </IconButton>
                                                    </Tooltip>
                                                </Box>
                                            </Box>
                                        </Box>
                                    </Fade>
                                    <Divider />
                                    {displayType === VIEW_OPTIONS.NORMAL ? (
                                        <ConnectionViewer
                                            filter={connectionsFilterObject}
                                            orderBy={
                                                _ConceptOrdering.CREATEDAT_DESC
                                            }
                                            editing={
                                                connectionFeedVariables.editing
                                            }
                                            connectionProperties={{
                                                connectableConceptId:
                                                    itemRef.current.id,
                                                action: connectionFeedVariables.editing
                                                    ? "Remove"
                                                    : "None",
                                            }}
                                            onClick={(concept: Concept) => {
                                                setBreadcrumbObjects([
                                                    ...breadcrumbObjects,
                                                    {
                                                        concept:
                                                            itemRef.current,
                                                        filters:
                                                            connectionFeedVariables.filters,
                                                        viewPrivate:
                                                            connectionFeedVariables.viewPrivate,
                                                        categoryId:
                                                            connectionFeedVariables.categoryId,
                                                    },
                                                ])
                                                setConnectionFeedVariables({
                                                    ...connectionFeedVariables,
                                                    filters: defaultFilters,
                                                    categoryId: null,
                                                    connectionsConceptId:
                                                        concept.id,
                                                })
                                                setBrowserFeedVariables({
                                                    ...browserFeedVariables,
                                                    filters: defaultFilters,
                                                })
                                                setNavigatedConceptId(
                                                    concept.id
                                                )
                                                itemRef.current = null
                                                editPermission.current = false
                                            }}
                                        />
                                    ) : (
                                        <ConnectionsGraphView
                                            filter={connectionsFilterObject}
                                            displayType={displayType}
                                            concept={itemRef.current}
                                            onConceptClick={(
                                                concept: Concept
                                            ) => {
                                                setBreadcrumbObjects([
                                                    ...breadcrumbObjects,
                                                    {
                                                        concept:
                                                            itemRef.current,
                                                        filters:
                                                            connectionFeedVariables.filters,
                                                        viewPrivate:
                                                            connectionFeedVariables.viewPrivate,
                                                        categoryId:
                                                            connectionFeedVariables.categoryId,
                                                    },
                                                ])
                                                setConnectionFeedVariables({
                                                    ...connectionFeedVariables,
                                                    filters: defaultFilters,
                                                    categoryId: null,
                                                    connectionsConceptId:
                                                        concept.id,
                                                })
                                                setBrowserFeedVariables({
                                                    ...browserFeedVariables,
                                                    filters: defaultFilters,
                                                })
                                                setNavigatedConceptId(
                                                    concept.id
                                                )
                                            }}
                                        />
                                    )}
                                </Box>
                            </Slide>
                            <Slide
                                in={browse}
                                direction="left"
                                mountOnEnter
                                unmountOnExit
                            >
                                <Box
                                    display="flex"
                                    overflow="hidden"
                                    flexGrow={1}
                                    flexDirection="column"
                                >
                                    <Box
                                        display="flex"
                                        flexDirection="column"
                                        width="100%"
                                        style={{
                                            backgroundColor:
                                                theme.palette.background.paper,
                                        }}
                                    >
                                        <Box>
                                            <Button
                                                onClick={() => setBrowse(false)}
                                            >
                                                <ChevronLeftIcon />{" "}
                                                {t("goBack", "Go Back")}
                                            </Button>
                                        </Box>

                                        <Box
                                            display="flex"
                                            justifyContent="space-between"
                                            alignItems="center"
                                            mt={1}
                                            pr={2}
                                        >
                                            <Box flexGrow={1} width="100%">
                                                <CategoryMenuSelector
                                                    textVariant="body1"
                                                    includeAllOption={true}
                                                    value={
                                                        browserFeedVariables.categoryId
                                                    }
                                                    setValue={(
                                                        categoryId: string
                                                    ) => {
                                                        setBrowserFeedVariables(
                                                            {
                                                                ...browserFeedVariables,
                                                                categoryId,
                                                            }
                                                        )
                                                    }}
                                                    allText={t(
                                                        "allConnectableContent",
                                                        "All Connectable Content"
                                                    )}
                                                    preOptionText={t(
                                                        "connectable",
                                                        "Connectable"
                                                    )}
                                                />
                                            </Box>
                                            <Box
                                                className={
                                                    classes.flexEndOnMobile
                                                }
                                            >
                                                <Tooltip
                                                    title={t(
                                                        "filterContent",
                                                        "FilterContent"
                                                    )}
                                                >
                                                    <IconButton
                                                        onClick={handleClick}
                                                    >
                                                        <Badge
                                                            badgeContent={
                                                                browserFeedVariables.filters.filter(
                                                                    (x) => {
                                                                        return (
                                                                            x.value &&
                                                                            x
                                                                                .value
                                                                                .length >
                                                                                0
                                                                        )
                                                                    }
                                                                ).length
                                                            }
                                                            color="secondary"
                                                            overlap="rectangle"
                                                        >
                                                            <GlobalFilterIcon
                                                                style={{
                                                                    width: "1em",
                                                                    height: "1em",
                                                                    fill: "currentColor",
                                                                }}
                                                            />
                                                        </Badge>
                                                    </IconButton>
                                                </Tooltip>
                                            </Box>
                                        </Box>
                                    </Box>
                                    <Divider />
                                    <ConnectionViewer
                                        filter={{
                                            ...browserFilterObject,
                                            id_not: item?.id,
                                        }}
                                        orderBy={
                                            _ConceptOrdering.CREATEDAT_DESC
                                        }
                                        editing={true}
                                        connectionProperties={{
                                            connectableConceptId: item?.id,
                                            action: "Toggle",
                                        }}
                                    />
                                </Box>
                            </Slide>
                        </Box>
                    )}
                </Box>
            )}
            <Popper
                className={mobile ? classes.mobilePopper : classes.popper}
                open={open}
                anchorEl={anchorEl}
                placement="bottom-end"
            >
                {({ TransitionProps }) => (
                    <ClickAwayListener onClickAway={() => setAnchorEl(null)}>
                        <Paper elevation={6}>
                            <Filters
                                excludeCategory={true}
                                onChangeFeedVariables={(
                                    value: FeedVariables
                                ) => {
                                    if (browse) {
                                        setBrowserFeedVariables({
                                            ...browserFeedVariables,
                                            ...value,
                                        })
                                    } else {
                                        setConnectionFeedVariables({
                                            ...connectionFeedVariables,
                                            ...value,
                                        })
                                    }
                                }}
                                feedVariables={
                                    browse
                                        ? browserFeedVariables
                                        : connectionFeedVariables
                                }
                            />
                        </Paper>
                    </ClickAwayListener>
                )}
            </Popper>
        </Dialog>
    )
}

export default DetailedConnectionPreviewDialog
