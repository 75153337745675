import React from "react"
import {
    ELEMENT_OL,
    ELEMENT_UL,
    getPluginType,
    ListToolbarButton,
} from "@udecode/plate"
import { useMyPlateEditorRef } from "../typescript/plateTypes"
import { getElementIcon } from "./getElementIcon"

export const ListToolbarButtons = () => {
    const editor = useMyPlateEditorRef()

    return (
        <>
            <ListToolbarButton
                type={getPluginType(editor, ELEMENT_UL)}
                icon={getElementIcon(ELEMENT_UL)}
            />
            <ListToolbarButton
                type={getPluginType(editor, ELEMENT_OL)}
                icon={getElementIcon(ELEMENT_OL)}
            />
        </>
    )
}
