import { Route, Redirect } from "react-router-dom"
import { useAuth } from "../providers/AuthProvider"
export default function PrivateRoute({ component: Component, ...rest }) {
    const { isSignedIn, hasAccess } = useAuth()

    return (
        <Route
            {...rest}
            render={(props) => {
                return !!isSignedIn && !!hasAccess ? (
                    <Component {...props} />
                ) : !!isSignedIn && !hasAccess ? (
                    <Redirect to="/request-access" />
                ) : (
                    <Redirect
                        to={"/login?continueUrl=" + props.location.pathname}
                    />
                )
            }}
        />
    )
}
