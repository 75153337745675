import { useState, useEffect, useCallback } from "react"
import { useMutation, useQuery } from "@apollo/client"
import {
    ADD_NEXT_STAGE_OPTION,
    ADD_WORKFLOW_STAGE,
    CREATE_WORKFLOW,
    CREATE_WORKFLOW_STAGE,
    DELETE_WORKFLOW,
    DELETE_WORKFLOW_STAGE,
    REMOVE_NEXT_STAGE_OPTION,
    UPDATE_WORKFLOW,
    UPDATE_WORKFLOW_STAGE,
    WORKFLOW,
} from "./graphql"
import {
    CreateWorkflowStageMutation,
    CreateWorkflowStageMutationVariables,
    WorkflowQuery,
    WorkflowQueryVariables,
    AddWorkflowStageMutation,
    AddWorkflowStageMutationVariables,
    AddNextStageOptionMutation,
    AddNextStageOptionMutationVariables,
    CreateWorkflowMutation,
    CreateWorkflowMutationVariables,
    RemoveNextStageOptionMutation,
    RemoveNextStageOptionMutationVariables,
    DeleteWorkflowStageMutation,
    DeleteWorkflowStageMutationVariables,
    UpdateWorkflowMutation,
    UpdateWorkflowMutationVariables,
    DeleteWorkflowMutation,
    DeleteWorkflowMutationVariables,
    UpdateWorkflowStageMutation,
    UpdateWorkflowStageMutationVariables,
} from "./__generated__/graphql"
import { WorkflowStage } from "../../__generated__/types"

export default function useWorkflowTools(props: { fetch: boolean }) {
    const { fetch } = props
    const { data, refetch } = useQuery<WorkflowQuery, WorkflowQueryVariables>(
        WORKFLOW,
        {
            fetchPolicy: "cache-first",
            skip: !fetch,
        }
    )

    const [isInitialized, setIsInitialized] = useState(false)
    const [allStages, setAllStages] = useState([])
    const [createWorkflow, { loading: creatingWorkflow }] = useMutation<
        CreateWorkflowMutation,
        CreateWorkflowMutationVariables
    >(CREATE_WORKFLOW)
    const [updateWorkflow, { loading: updatingWorkflow }] = useMutation<
        UpdateWorkflowMutation,
        UpdateWorkflowMutationVariables
    >(UPDATE_WORKFLOW)
    const [
        updateWorkflowStage,
        { loading: updatingWorkflowStage },
    ] = useMutation<
        UpdateWorkflowStageMutation,
        UpdateWorkflowStageMutationVariables
    >(UPDATE_WORKFLOW_STAGE)
    const [createWorkflowStage] = useMutation<
        CreateWorkflowStageMutation,
        CreateWorkflowStageMutationVariables
    >(CREATE_WORKFLOW_STAGE)
    const [
        addNextStageOption,
        { loading: addingNextStageOption },
    ] = useMutation<
        AddNextStageOptionMutation,
        AddNextStageOptionMutationVariables
    >(ADD_NEXT_STAGE_OPTION)
    const [
        removeNextStageOption,
        { loading: removingNextStageOption },
    ] = useMutation<
        RemoveNextStageOptionMutation,
        RemoveNextStageOptionMutationVariables
    >(REMOVE_NEXT_STAGE_OPTION)
    const [addWorkflowStage] = useMutation<
        AddWorkflowStageMutation,
        AddWorkflowStageMutationVariables
    >(ADD_WORKFLOW_STAGE)
    const [deleteWorkflow, { loading: deletingWorkflow }] = useMutation<
        DeleteWorkflowMutation,
        DeleteWorkflowMutationVariables
    >(DELETE_WORKFLOW, {
        update(cache, { data: { DeleteWorkflow } }) {
            cache.evict({
                id: cache.identify(DeleteWorkflow),
            })
        },
    })
    const [
        deleteWorkflowStage,
        { loading: deletingWorkflowStage },
    ] = useMutation<
        DeleteWorkflowStageMutation,
        DeleteWorkflowStageMutationVariables
    >(DELETE_WORKFLOW_STAGE, {
        update(cache, { data: { DeleteWorkflowStage } }) {
            cache.evict({
                id: cache.identify(DeleteWorkflowStage),
            })
        },
    })

    const environmentWorkflow = data?.Workflow?.find(
        (workflow) => !!workflow.isDefault
    )
    const stages = environmentWorkflow?.stages
    const allSubWorkflows =
        data?.Workflow?.filter((workflow) => !workflow.isDefault) ?? null

    const onCreateWorkflow = async (
        workflowData: CreateWorkflowMutationVariables
    ) => {
        await createWorkflow({
            variables: {
                ...workflowData,
            },
        })
    }
    const onUpdateWorkflow = async (
        workflowData: UpdateWorkflowMutationVariables
    ) => {
        await updateWorkflow({
            variables: {
                ...workflowData,
            },
        })
    }
    const getOrderedStages = useCallback((stages: WorkflowStage[]) => {
        let stagesInOrder = []

        const firstStage = stages?.find((stage) => !!stage.isFirstStage)
        if (!!firstStage) {
            stagesInOrder = [
                firstStage,
                ...stages.filter(
                    (stage) =>
                        !!firstStage.nextOptions.some(
                            (op) => op.id === stage.id
                        )
                ),
            ]
        }

        stages?.forEach((stage, index) => {
            if (!stagesInOrder.find((i) => i.id === stage.id)) {
                stagesInOrder = [...stagesInOrder, stage]
            }
        })

        return stagesInOrder
    }, [])

    useEffect(() => {
        if (!!stages && !isInitialized) {
            let allStages = [...getOrderedStages(environmentWorkflow?.stages)]
            allSubWorkflows?.map((workflow) => {
                allStages = [...allStages, ...getOrderedStages(workflow.stages)]
                return allStages
            })
            setAllStages(allStages)
            setIsInitialized(true)
        }
    }, [
        stages,
        allSubWorkflows,
        environmentWorkflow,
        getOrderedStages,
        isInitialized,
    ])

    return {
        environmentWorkflow,
        isInitialized,
        allSubWorkflows,
        refetch,
        onCreateWorkflow,
        getOrderedStages,
        createWorkflowStage,
        addNextStageOption,
        addingNextStageOption,
        removeNextStageOption,
        removingNextStageOption,

        deleteWorkflowStage,
        deletingWorkflowStage,
        deleteWorkflow,
        addWorkflowStage,
        onUpdateWorkflow,
        updateWorkflowStage,
        updatingWorkflowStage,
        deletingWorkflow,

        updatingWorkflow,
        creatingWorkflow,
        allStages,
    }
}
