import React, { useState } from "react"
import { useMutation } from "@apollo/client"
import Box from "@material-ui/core/Box"
import Dialog from "@material-ui/core/Dialog"
import DialogContent from "@material-ui/core/DialogContent"
import DialogTitle from "@material-ui/core/DialogTitle"
import DialogActions from "@material-ui/core/DialogActions"
import Button from "@material-ui/core/Button"
import {
    TextField,
    MenuList,
    MenuItem,
    ListItemIcon,
    ListItemText,
    ListItemSecondaryAction,
    Switch,
} from "@material-ui/core"
import { CircularProgress } from "@material-ui/core"
import { CREATE_CATEGORY, ADD_CATEGORY_PARENT } from "./graphql"
import {
    AddCategoryParentMutation,
    AddCategoryParentMutationVariables,
    CreateCategoryMutation,
    CreateCategoryMutationVariables,
} from "./__generated__/graphql"
import { Category } from "../../__generated__/types"
import useAwaitTranslation from "../../i18n/useAwaitTranslation"
import CheckIcon from "@material-ui/icons/Check"
import { ReactComponent as GlobalFilterIcon } from "../../styles/FilterIcon.svg"
import { useTheme } from "@material-ui/core/styles"
import Save from "@material-ui/icons/Save"
import Clear from "@material-ui/icons/Clear"
import SupervisedUserCircleIcon from "@material-ui/icons/SupervisedUserCircle"
interface NewCategoryFormProps {
    onClose: () => void
    parent?: Category
}
const NewCategoryForm = (props: NewCategoryFormProps) => {
    const theme = useTheme()
    const [name, setName] = useState("")
    const [description, setDescription] = useState("")
    const [isManaged, setIsManaged] = useState(false)
    const [saving, setSaving] = useState(false)
    const [isFilterOption, setIsFilterOption] = useState(false)
    const [addParent] = useMutation<
        AddCategoryParentMutation,
        AddCategoryParentMutationVariables
    >(ADD_CATEGORY_PARENT)
    const { t } = useAwaitTranslation("categories")
    const [createCategory] = useMutation<
        CreateCategoryMutation,
        CreateCategoryMutationVariables
    >(CREATE_CATEGORY, {
        refetchQueries: [
            "CategoriesFiltered",
            "EnvironmentCategoriesAndFields",
        ],
        awaitRefetchQueries: true,
    })

    const handleSave = async () => {
        setSaving(true)
        const {
            data: { CreateCategory: NewCategory },
        } = await createCategory({
            variables: {
                name: name,
                description: description,
                isManaged,
                isFilterOption,
                isRoot: !props.parent,
            },
        })
        if (!!props.parent && !!NewCategory) {
            await addParent({
                variables: {
                    parentId: props.parent.id,
                    childId: NewCategory?.id,
                },
            })
        }
        props.onClose()
    }

    return (
        <Dialog open={true} fullWidth>
            <DialogTitle>{t("categoryForm", "Category Form")}</DialogTitle>
            <DialogContent>
                {props.parent && (
                    <Box p={2}>
                        <TextField
                            fullWidth
                            variant="outlined"
                            value={props.parent.name}
                            label={t("parent", "Parent")}
                            disabled
                            InputProps={{
                                endAdornment: (
                                    <React.Fragment>
                                        <CheckIcon />
                                    </React.Fragment>
                                ),
                            }}
                        />
                    </Box>
                )}
                <Box p={2}>
                    <TextField
                        required
                        fullWidth
                        variant="outlined"
                        autoFocus
                        value={name}
                        label={t("name", "Name")}
                        onChange={(event) => setName(event.target?.value)}
                    />
                </Box>
                <Box p={2}>
                    <TextField
                        fullWidth
                        variant="outlined"
                        value={description}
                        label={t("description", "Description")}
                        onChange={(event) =>
                            setDescription(event.target?.value)
                        }
                    />
                </Box>
                <Box p={2} pt={1}>
                    <MenuList>
                        <MenuItem onClick={() => setIsManaged((prev) => !prev)}>
                            <ListItemIcon>
                                <SupervisedUserCircleIcon />
                            </ListItemIcon>
                            <ListItemText>
                                {t(
                                    "adminManagedContent",
                                    "Admin Managed Content"
                                )}
                            </ListItemText>
                            <ListItemSecondaryAction>
                                <Switch
                                    disabled={!!saving}
                                    checked={isManaged}
                                    color="primary"
                                    onChange={(e) => {
                                        setIsManaged(e.target.checked)
                                    }}
                                />
                            </ListItemSecondaryAction>
                        </MenuItem>
                        <MenuItem
                            onClick={() => setIsFilterOption((prev) => !prev)}
                        >
                            <ListItemIcon>
                                <GlobalFilterIcon
                                    style={{
                                        fill: theme.palette.action.active,

                                        width: "1.25em",
                                        height: "1.25em",
                                    }}
                                />
                            </ListItemIcon>
                            <ListItemText>
                                {t("isFilterOption", "Filter Option")}
                            </ListItemText>
                            <ListItemSecondaryAction>
                                <Switch
                                    disabled={!!saving}
                                    checked={isFilterOption}
                                    color="primary"
                                    onChange={(e) => {
                                        setIsFilterOption(e.target.checked)
                                    }}
                                />
                            </ListItemSecondaryAction>
                        </MenuItem>
                    </MenuList>
                </Box>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={() => {
                        props.onClose()
                    }}
                    disabled={saving}
                    startIcon={<Clear />}
                >
                    {t("discard", "Discard")}
                </Button>
                <Button
                    color="primary"
                    disabled={saving || !(name && name.length > 0)}
                    onClick={handleSave}
                    style={{ position: "relative" }}
                    startIcon={<Save />}
                >
                    {!!saving && (
                        <CircularProgress
                            size={20}
                            style={{ position: "absolute" }}
                        />
                    )}
                    {t("save", "Save")}
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default NewCategoryForm
