import { FeedbackField, InputResponseLabel } from "../../providers/GlobalState"
import {
    Concept,
    CriteriaScore,
    _ConceptFilter,
    _CriteriaScoreFilter,
} from "../../__generated__/types"
import { CriteriaType } from "../criteria/types"
import {
    CollectionType,
    DateType,
    InputSourceResponseType,
    InputSourceValue,
    MultipleChoiceType,
} from "../criteria/useCriteriaTypes"
import { getFilterObject } from "../filters/util/getFilterObject"
import { FilterItem } from "../filters/util/types"
import { ScoreSelectorDefaultValues } from "./types"
import { CRITERIA_DEFAULT_REPOSITORY } from "../criteria/useCriteriaTypes"
import { IsPlateStringEmpty } from "../text-editor/RichTextEditor"
import { CompletionData } from "../../util/types"
export const checkFieldCompletion = ({
    score,
    criteriaType,
    config,
    conceptsToRankOrGroup,
    collectionSpecificData,
    currentLabel,
}: {
    // only NOT sent when the collection concepts being evaluated are ALL inherited connections
    score?: CriteriaScore
    criteriaType: CriteriaType
    config: any
    // only sent when it is ranking or grouping
    conceptsToRankOrGroup?: Concept[]
    //only sent when primary response is being evaluated and if it a collection that inherits connections
    collectionSpecificData?: {
        connections: Concept[]
        initialWorkspaceLoadTime?: number
    }
    currentLabel: InputResponseLabel
}): boolean => {
    let completed = false

    switch (criteriaType) {
        case CriteriaType.Number:
            completed = !!score?.response && !isNaN(Number(score?.response))
            break
        case CriteriaType.TextResponse:
            completed =
                !!score?.response && !IsPlateStringEmpty(score?.response)

            break
        case CriteriaType.Date:
            const { range }: DateType = config
            const dateResponse = JSON.parse(score?.response ?? "[]")
            completed = !!dateResponse?.[0]
            if (!!range) {
                completed = completed && !!dateResponse?.[1]
            }
            break
        case CriteriaType.MultipleChoice:
            const { options }: MultipleChoiceType = config
            const multipleChoiceResponse = score?.response ?? ""
            completed = !!options?.find(
                (op) => op.id === multipleChoiceResponse
            )
            break
        case CriteriaType.Collection:
            const { minimum }: CollectionType = config

            const currentCount =
                getCollectionConcepts(
                    score,
                    config,
                    collectionSpecificData.connections,
                    currentLabel,
                    collectionSpecificData.initialWorkspaceLoadTime
                )?.length ?? 0
            completed = currentCount >= minimum && minimum > 0

            break
        case CriteriaType.Grouping:
            const conceptsGrouped =
                conceptsToRankOrGroup?.filter((concept) =>
                    JSON.parse(score?.response ?? "[]")
                        .filter((grouping) => !!grouping.group)
                        .flatMap((grouping) => grouping.conceptIds)
                        .includes(concept.id)
                ) ?? []
            completed =
                conceptsGrouped.length > 0 &&
                conceptsGrouped.length === conceptsToRankOrGroup.length
            break
        case CriteriaType.Ranking:
            const conceptsRanked = conceptsToRankOrGroup?.filter((concept) =>
                JSON.parse(score?.response ?? "[]").includes(concept.id)
            )
            completed =
                conceptsRanked.length > 0 &&
                conceptsRanked.length === conceptsToRankOrGroup.length
            break
        default:
            completed = !!score?.response
    }

    return completed
}

// CAN AND WILL BE SIMPLIFIED IN FUTURE
export const getSourceFilters = (props: {
    inputConfig: any
    userId: string
    conceptId: string
    currentLabel: InputResponseLabel
    filters?: FilterItem[]
    criteriaType?: CriteriaType
}) => {
    const {
        inputConfig,
        userId,
        conceptId,
        filters,
        currentLabel,
        criteriaType,
    } = props
    let sourceFilter: _ConceptFilter = {
        id_not: conceptId,

        ...getFilterObject(filters ?? inputConfig?.source?.filters ?? []),
    }

    const sourceResponseType = !!inputConfig?.source?.inputSourceResponseType
        ? inputConfig?.source?.inputSourceResponseType
        : InputSourceResponseType.primaryResponseOnly

    if (
        inputConfig?.source?.criteriaIds?.length > 0 &&
        criteriaType !== CriteriaType.Collection
    ) {
        let baseFilters: _CriteriaScoreFilter[] = [
            {
                criteria: {
                    id_in: inputConfig.source.criteriaIds?.map(
                        (item) => item.id
                    ),
                },
            },
            {
                input: {
                    parentConcept: {
                        id: conceptId,
                    },
                },
            },
            {
                isArchived: null,
            },
        ]

        if (
            sourceResponseType !== InputSourceResponseType.allResponses &&
            currentLabel?.label !== ScoreSelectorDefaultValues.allResponses
        ) {
            if (
                (currentLabel?.label ===
                    ScoreSelectorDefaultValues.primaryResponse &&
                    !currentLabel?.user) ||
                sourceResponseType ===
                    InputSourceResponseType.primaryResponseOnly
            ) {
                baseFilters = [
                    ...baseFilters,
                    {
                        conceptDefault: true,
                    },
                ]
            } else {
                baseFilters = [
                    {
                        user: {
                            userId: userId,
                        },
                    },
                    {
                        conceptDefault_not: true,
                    },
                    {
                        label: currentLabel.label,
                    },
                    ...baseFilters,
                ]
            }
        }

        sourceFilter = {
            ...sourceFilter,
            OR: [
                {
                    usedByScore_some: {
                        AND: [...baseFilters],
                    },
                },
                {
                    criteriaScores_some: {
                        AND: [...baseFilters],
                    },
                },
            ],
        }
    } else if (inputConfig?.source?.type === InputSourceValue.connections) {
        if (!!sourceFilter.connections_some) {
            sourceFilter["AND"] = [
                { connections_some: { id: conceptId } },
                { connections_some: { ...sourceFilter.connections_some } },
            ]
        } else {
            sourceFilter = {
                ...sourceFilter,
                connections_some: {
                    id: conceptId,
                },
            }
        }
    }
    if (!!inputConfig?.categories) {
        sourceFilter = {
            ...sourceFilter,
            category: {
                id_in: inputConfig.categories.map((item) => item.id),
            },
        }
    }

    return sourceFilter
}

export const getInputId = (criteriaId, conceptId, inputConfig) => {
    let inputId = criteriaId?.concat(conceptId) || ""
    const sourceCriteriaIds = inputConfig?.source?.criteriaIds || null
    if (!!sourceCriteriaIds) {
        sourceCriteriaIds.forEach((item) => {
            inputId = inputId.concat("", item.id || "")
        })
    }

    return inputId
}

export const getLabelOptions = (
    scores: CriteriaScore[] = [],
    currentLabel: InputResponseLabel | null = null,
    primaryResponseText: string = "Primary Response",
    pageConceptId: string
): InputResponseLabel[] => {
    const baseLabels = [
        {
            label: ScoreSelectorDefaultValues.primaryResponse,
            content: primaryResponseText,
            conceptId: pageConceptId,
        },
    ]

    if (scores?.some((score) => !score.conceptDefault)) {
        baseLabels.push({
            label: ScoreSelectorDefaultValues.allResponses,
            content: "All Responses",
            conceptId: pageConceptId,
        })
    }

    const uniqueNonPrimaryLabels = Array.from(
        scores?.filter((score) => !score.label && !score.conceptDefault) ?? []
    ).reduce((labels, score) => {
        if (
            !labels.some((label) => label.user?.userId === score.user?.userId)
        ) {
            labels.push({
                label: null,
                user: score.user,
                conceptId: pageConceptId,
            })
        }
        return labels
    }, [])

    scores
        ?.filter((score) => !!score.label && !score.conceptDefault)
        ?.forEach((score) => {
            if (
                !uniqueNonPrimaryLabels.some(
                    (label) =>
                        label.label === score.label &&
                        label.user?.userId === score.user?.userId
                )
            ) {
                uniqueNonPrimaryLabels.push({
                    label: score.label,
                    user: score.user,
                    conceptId: pageConceptId,
                })
            }
        })

    if (
        currentLabel &&
        currentLabel.label !== ScoreSelectorDefaultValues.allResponses &&
        currentLabel.user &&
        !uniqueNonPrimaryLabels.some(
            (label) =>
                label.label === currentLabel.label &&
                label.user?.userId === currentLabel.user?.userId
        )
    ) {
        uniqueNonPrimaryLabels.push(Object.assign({}, currentLabel))
    }

    return [
        ...baseLabels,
        ...uniqueNonPrimaryLabels.sort(function (a, b) {
            var textA = (
                a.label || `${a.user?.firstName} ${a.user?.lastName}`
            )?.toUpperCase()
            var textB = (
                b.label || `${b.user?.firstName} ${b.user?.lastName}`
            )?.toUpperCase()
            return textA < textB ? -1 : textA > textB ? 1 : 0
        }),
    ]
}

export const scoreUpdatedSinceInitialLoad = (
    scoreLastUpdatedAt: string | null,
    initialWorkspaceLoadtime?: number
): boolean => {
    if (!scoreLastUpdatedAt || !initialWorkspaceLoadtime) return false
    const scoreLastUpdated = new Date(scoreLastUpdatedAt).getTime()
    return scoreLastUpdated > initialWorkspaceLoadtime
}

export const getCollectionConcepts = (
    score: CriteriaScore,
    config: CollectionType,
    connections: Concept[],
    currentLabel: InputResponseLabel,
    initialWorkspaceLoadTime?: number
): Concept[] => {
    const viewingPrimaryResponse =
        currentLabel?.label === ScoreSelectorDefaultValues.primaryResponse &&
        !currentLabel?.user
    let collection: Concept[] = []
    if (!config.requirePrimaryResponse && viewingPrimaryResponse) {
        if (
            !!scoreUpdatedSinceInitialLoad(
                score?.lastUpdated?.formatted,
                initialWorkspaceLoadTime
            )
        ) {
            const responseIds = JSON.parse(score.response)
            collection = [
                ...(score?.concepts?.filter((c) =>
                    responseIds.includes(c.id)
                ) ?? []),
                ...connections.filter(
                    (connection) =>
                        !score?.concepts?.find((o) => o.id === connection.id) &&
                        responseIds.includes(connection.id) &&
                        !!config.categories?.find(
                            (category) =>
                                category.id === connection.category?.id
                        )
                ),
            ]
        } else {
            collection =
                [
                    ...(score?.concepts ?? []),
                    ...connections.filter(
                        (connection) =>
                            !score?.concepts?.find(
                                (c) => c.id === connection.id
                            ) &&
                            !!config.categories?.find(
                                (o) => o.id === connection.category?.id
                            )
                    ),
                ] || []
        }
    } else {
        collection = score?.concepts || []
    }

    return collection
}

export const getSelectedResponseScores = (
    field: FeedbackField,
    currentLabel: InputResponseLabel
) => {
    const { label, user } = currentLabel
    const scores = field?.scores || []
    const { allowMultipleResponses } = getCleanedFieldConfig(field)
    const viewingAllResponses =
        label === ScoreSelectorDefaultValues.allResponses && !user
    const viewingPrimaryResponse =
        label === ScoreSelectorDefaultValues.primaryResponse && !user
    return !!viewingAllResponses
        ? scores
        : !!viewingPrimaryResponse || !allowMultipleResponses
        ? scores?.filter((score) => !!score.conceptDefault)
        : !!currentLabel?.user && !currentLabel?.label
        ? scores?.filter(
              (score) =>
                  score.user?.userId === currentLabel?.user?.userId &&
                  !score.label &&
                  !score.conceptDefault
          )
        : !!currentLabel?.user && !!currentLabel?.label
        ? scores?.filter(
              (score) =>
                  score.label === currentLabel?.label &&
                  score.user?.userId === currentLabel?.user?.userId &&
                  !score.conceptDefault
          )
        : []
}

export const getCleanedFieldConfig = (field?: FeedbackField) => {
    let config: any = {}
    if (!!field?.input?.criteria?.criteriaType) {
        config = {
            ...(CRITERIA_DEFAULT_REPOSITORY[
                field?.input?.criteria?.criteriaType
            ] ?? {}),
        }
    }
    config = {
        ...config,
        ...JSON.parse(field?.input?.criteria?.criteriaOptions ?? "{}"),
        ...JSON.parse(field?.input?.inputConfig ?? "{}"),
    }
    return config
}

export const getConceptsToScore = (
    sourceField: FeedbackField,
    subfield: FeedbackField,
    connections: Concept[],
    currentLabel: InputResponseLabel,
    initialWorkspaceLoadTime?: number
) => {
    const { user, label } = currentLabel
    const viewingPrimaryResponse =
        label === ScoreSelectorDefaultValues.primaryResponse && !user

    if (!!sourceField) {
        const subfieldConfig = getCleanedFieldConfig(subfield)
        const usingPrimaryResponse =
            !!viewingPrimaryResponse ||
            subfieldConfig.source?.inputSourceResponseType ===
                InputSourceResponseType.primaryResponseOnly
        const sourceScore = sourceField?.scores?.find((score) =>
            !!usingPrimaryResponse
                ? !!score.conceptDefault
                : subfieldConfig?.source?.inputSourceResponseType ===
                  InputSourceResponseType.usersResponseOnly
                ? user?.userId === score.user?.userId &&
                  (!label ? !score.label : label === score.label)
                : true
        )

        const collectionConfig: CollectionType =
            getCleanedFieldConfig(sourceField)
        let conceptsToOrder = sourceScore?.concepts || []

        if (
            // means include connections that are not in the source score
            // this is duplicate logic in useCollectionTools, need to find way to make uniform
            !!usingPrimaryResponse &&
            !collectionConfig.requirePrimaryResponse
        ) {
            conceptsToOrder = [
                ...conceptsToOrder,
                ...connections?.filter(
                    (connection) =>
                        !conceptsToOrder.find((x) => x.id === connection.id) &&
                        !!collectionConfig.categories?.find(
                            (item) => item.id === connection.category?.id
                        )
                ),
            ]
        }

        const responseArray = JSON.parse(sourceScore?.response ?? "[]")
        let orderedConcepts = []

        if (
            subfieldConfig?.source?.inputSourceResponseType !==
                InputSourceResponseType.allResponses &&
            responseArray.length > 0
        ) {
            conceptsToOrder
                .filter((concept) => responseArray.includes(concept.id))
                .map(
                    (concept) =>
                        (orderedConcepts[responseArray.indexOf(concept.id)] =
                            concept)
                )
        } else {
            orderedConcepts = [...(conceptsToOrder || [])]
        }
        // concepts restricted to ones that are in response array
        orderedConcepts = orderedConcepts.filter((x) => !!x)
        // if the score was updated after the initial load time, then we take the response array as only source of truth

        if (
            !scoreUpdatedSinceInitialLoad(
                sourceScore?.lastUpdated?.formatted,
                initialWorkspaceLoadTime
            )
        ) {
            return [
                ...orderedConcepts,
                ...(conceptsToOrder.filter(
                    (concept) =>
                        !!sourceScore?.response &&
                        !responseArray.includes(concept.id)
                ) || []),
            ]
        } else {
            return orderedConcepts
        }
    }
    return []
}

interface WorkspaceCompletionData {
    percentComplete: number
    totalFields: number
    totalFieldsCompleted: number
    orderedFields: CompletionData[]
}

export const getWorkspaceCompletionData = (
    fields: FeedbackField[] = [],
    currentLabel: InputResponseLabel,
    connections: Concept[],
    initialWorkspaceLoadTime?: number
): WorkspaceCompletionData => {
    const { user, label } = currentLabel
    const viewingPrimaryResponse =
        label === ScoreSelectorDefaultValues.primaryResponse && !user
    const viewingAllResponses =
        label === ScoreSelectorDefaultValues.allResponses && !user
    const orderedFields: FeedbackField[] = fields
        .filter(
            (field) =>
                viewingPrimaryResponse ||
                viewingAllResponses ||
                getCleanedFieldConfig(field)?.allowMultipleResponses !== false
        )
        .filter(Boolean)
    const fieldsWithCompletionStatus: CompletionData[] = orderedFields.map(
        (item) => {
            const config = getCleanedFieldConfig(item)
            const isSubfield = (config?.source?.criteriaIds?.length ?? 0) > 0

            let fieldsCompleted = false
            if (isSubfield) {
                const sourceField = orderedFields.find((field) =>
                    config?.source?.criteriaIds?.some(
                        (o) => o.id === field.input?.criteria?.id
                    )
                )
                const conceptsToScore = getConceptsToScore(
                    sourceField,
                    item,
                    connections,
                    currentLabel,
                    initialWorkspaceLoadTime
                )
                fieldsCompleted = [
                    CriteriaType.Grouping,
                    CriteriaType.Ranking,
                ].includes(item.input?.criteria?.criteriaType as CriteriaType)
                    ? checkFieldCompletion({
                          score: getSelectedResponseScores(
                              item,
                              currentLabel
                          )?.[0],
                          criteriaType: item.input?.criteria
                              ?.criteriaType as CriteriaType,
                          config,
                          conceptsToRankOrGroup: conceptsToScore,
                          currentLabel,
                      })
                    : getSelectedResponseScores(item, currentLabel)?.filter(
                          (score) =>
                              conceptsToScore?.some(
                                  (c) => c.id === score.scoredConcept?.id
                              ) &&
                              checkFieldCompletion({
                                  score,
                                  criteriaType: item.input?.criteria
                                      ?.criteriaType as CriteriaType,
                                  config,
                                  currentLabel,
                              })
                      )?.length === conceptsToScore?.length &&
                      conceptsToScore?.length > 0
            } else {
                fieldsCompleted = checkFieldCompletion({
                    score: getSelectedResponseScores(item, currentLabel)?.[0],
                    criteriaType: item.input?.criteria
                        ?.criteriaType as CriteriaType,
                    config,
                    collectionSpecificData: {
                        connections,
                        initialWorkspaceLoadTime,
                    },
                    currentLabel,
                })
            }

            return { ...item, isCompleted: fieldsCompleted }
        }
    )

    const totalFieldsCompleted =
        fieldsWithCompletionStatus?.filter((item) => !!item.isCompleted)
            ?.length ?? 0
    return {
        percentComplete:
            Math.round(
                (totalFieldsCompleted / (orderedFields?.length ?? 1)) * 100
            ) || 0,
        totalFields: orderedFields?.length ?? 0,
        totalFieldsCompleted,
        orderedFields: fieldsWithCompletionStatus,
    }
}

export const getMinCellWidth = (criteriaType: CriteriaType, config): number => {
    switch (criteriaType) {
        case CriteriaType.TextResponse:
            return 400
        case CriteriaType.Number:
            if (!!config.slider) {
                return 275
            } else {
                return 100
            }
        default:
            return 200
    }
}
