import React, { useState } from "react"
import Box from "@material-ui/core/Box"
import { makeStyles, Theme, useTheme } from "@material-ui/core/styles"
import { useMutation, useQuery, useLazyQuery } from "@apollo/client"
import CircularProgress from "@material-ui/core/CircularProgress"
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import Divider from "@material-ui/core/Divider"
import Avatar from "@material-ui/core/Avatar"
import useMediaQuery from "@material-ui/core/useMediaQuery"
import ListItemText from "@material-ui/core/ListItemText"
import ListItemAvatar from "@material-ui/core/ListItemAvatar"
import GroupIcon from "@material-ui/icons/Group"
import RecordVoiceOverIcon from "@material-ui/icons/RecordVoiceOver"
import VpnLockIcon from "@material-ui/icons/VpnLock"
import SendIcon from "@material-ui/icons/Send"
import PeopleIcon from "@material-ui/icons/People"
import PersonIcon from "@material-ui/icons/Person"
import SupervisedUserCircleIcon from "@material-ui/icons/SupervisedUserCircle"
import {
    CONCEPT_PERMISSIONS,
    GRANT_USER_ACCESS,
    GRANT_TEAM_ACCESS,
    REMOVE_USER_ACCESS,
    REMOVE_TEAM_ACCESS,
} from "./graphql"
import {
    ConceptPermissionsQuery,
    ConceptPermissionsQueryVariables,
    GrantTeamAccessMutation,
    GrantTeamAccessMutationVariables,
    GrantUserAccessMutation,
    GrantUserAccessMutationVariables,
    RemoveTeamAccessMutation,
    RemoveTeamAccessMutationVariables,
    RemoveUserAccessMutation,
    RemoveUserAccessMutationVariables,
} from "./__generated__/graphql"
import { CONCEPT_BY_ID } from "../../graphql/queries"
import { ALL_USER_QUERY } from "../users/graphql"
import { ALL_TEAM_QUERY } from "../teams/graphql"
import {
    AllUsersQuery,
    AllUsersQueryVariables,
} from "../users/__generated__/graphql"
import {
    AllTeamQuery,
    AllTeamQueryVariables,
} from "../teams/__generated__/graphql"
import Tab from "@material-ui/core/Tab"
import Select from "@material-ui/core/Select"
import MenuItem from "@material-ui/core/MenuItem"
import Tabs from "@material-ui/core/Tabs"
import { useAuth } from "../../providers/AuthProvider"
import Tooltip from "@material-ui/core/Tooltip"
import Autocomplete, {
    createFilterOptions,
} from "@material-ui/lab/Autocomplete"
import TextField from "@material-ui/core/TextField"
import { UPDATE_CONCEPT } from "../../graphql/mutations"
import {
    UpdateConceptMutation,
    UpdateConceptMutationVariables,
} from "../../graphql/__generated__/mutations"
import {
    AccessType,
    _ConceptAddedTeams,
    _ConceptAddedUsers,
    User,
    Team,
    Concept,
    SystemRole,
} from "../../__generated__/types"
import { IconButton, InputAdornment, Link, Typography } from "@material-ui/core"
import Chip from "@material-ui/core/Chip"
import BlockIcon from "@material-ui/icons/Block"
import VisibilityIcon from "@material-ui/icons/Visibility"
import EditIcon from "@material-ui/icons/Edit"
import { Link as LinkRouter } from "react-router-dom"
import useAwaitTranslation from "../../i18n/useAwaitTranslation"
import useEnvironmentSettingTools from "../settings/useEnvironmentSettingTools"
import Public from "@material-ui/icons/Public"
import ClickableRichTooltip from "../Popper/ClickableRichTooltip"
import { Loading } from "../Loading"
import UserAvatar from "../users/UserAvatar"
import PersonAdd from "@material-ui/icons/PersonAdd"
import UserForm from "../users/UserForm"
import TeamAvatar from "../teams/TeamAvatar"
import {
    INITIAL_PERMISSIONS_OBJECT,
    userConceptPermissionsVar,
} from "../../providers/GlobalState"
import useMountedState from "../../util/useMountedState"

const useStyles = makeStyles((theme: Theme) => ({
    tooltipListItem: {
        fontSize: theme.typography.body2.fontSize,
    },

    additionForm: {
        display: "flex",
        alignItems: "center",
        [theme.breakpoints.down("sm")]: {
            flexDirection: "column",
        },
        width: "100%",
    },

    listItemBody: {
        display: "flex",
        flexGrow: 1,
        overflow: "hidden",
        alignItems: "center",
    },
    ellipsis: {
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
    },
    listItemActionContainer: {
        flexBasis: "30%",
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
    },
    autoComplete: {
        minWidth: "8em",
    },
    sendIconContainer: {
        display: "flex",
        alignItems: "center",
        minWidth: "12em",
        [theme.breakpoints.down("sm")]: {
            width: "100%",
            paddingLeft: theme.spacing(1),
        },
    },

    publicMenuIcon: {
        height: "0.75em",
        width: "0.75em",
    },
    listbox: {
        overflow: "auto",
    },
    popper: {
        zIndex: theme.zIndex.modal + 4,
    },
    input: {
        zIndex: theme.zIndex.modal + 3,
    },
    permButton: {
        padding: theme.spacing(1.25),
        borderRadius: theme.shape.borderRadius,
        "&:hover": {
            backgroundColor: theme.palette.action.hover,
            cursor: "pointer",
        },
    },
}))

export default function ConceptPermissions(props: { item: Concept }) {
    //hooks
    const classes = useStyles()
    const theme = useTheme()
    const mobile = useMediaQuery(theme.breakpoints.down("xs"))
    const { currentUser } = useAuth()
    const { t } = useAwaitTranslation("permissions")

    //queries
    const { loading, data } = useQuery<
        ConceptPermissionsQuery,
        ConceptPermissionsQueryVariables
    >(CONCEPT_PERMISSIONS, {
        variables: { id: props.item.id },
        fetchPolicy: "network-only",
    })

    //state
    const [tab, setTab] = useState(0)

    //variables
    const users = (data?.Concept[0]?.addedUsers ?? [])
        .slice()
        .sort((a, b) =>
            a.User.lastName
                ?.toUpperCase()
                ?.localeCompare(b.User.lastName?.toUpperCase())
        )
    const teams = (data?.Concept[0].addedTeams ?? [])
        .slice()
        .sort((a, b) =>
            a.Team.name
                ?.toUpperCase()
                ?.localeCompare(b.Team.name?.toUpperCase())
        )
    //public status editing is restricted to only users and teams that have been explicitly added as owners
    const publicStatusEditingAccess =
        (users?.some(
            (item) =>
                item.User.userId === currentUser.userId &&
                item.type === AccessType.OWNER
        ) ||
            teams?.some((item) =>
                item.Team.members.some(
                    (member) =>
                        member.userId === currentUser.userId &&
                        item.type === AccessType.OWNER
                )
            )) ??
        false
    // ability to add teams and users is restricted to explicitly added owners and teams
    // if the concept is not private, all system admins can add users and teams
    let permissionToAddTeamsAndUsers = publicStatusEditingAccess
        ? true
        : !!(
              currentUser?.role === SystemRole.ADMIN &&
              props.item.isPublicAccessType !== AccessType.NONE
          )
        ? true
        : false

    /// If nobody has owner permissions at all, then allow an admin to edit regardless of public status
    if (!permissionToAddTeamsAndUsers) {
        if (
            users?.filter((x) => x.type === AccessType.OWNER)?.length === 0 &&
            teams?.filter((x) => x.type === AccessType.OWNER)?.length === 0 &&
            currentUser?.role === SystemRole.ADMIN
        ) {
            permissionToAddTeamsAndUsers = true
        }
    }

    const publicOptions = [
        {
            value: AccessType.NONE,
            html: (
                <Box display="flex" alignItems="center">
                    <BlockIcon className={classes.publicMenuIcon} />
                    <Box ml={1}>{t("noPublicAccess", "No public access")}</Box>
                </Box>
            ),
        },

        {
            value: AccessType.VIEWER,
            html: (
                <Box display="flex" alignItems="center">
                    <VisibilityIcon className={classes.publicMenuIcon} />
                    <Box ml={1}>
                        {t("everyoneCanView", "Everyone can view")}
                    </Box>
                </Box>
            ),
        },
        {
            value: AccessType.CONTRIBUTOR,
            html: (
                <Box display="flex" alignItems="center">
                    <RecordVoiceOverIcon className={classes.publicMenuIcon} />
                    <Box ml={1}>
                        {t("everyoneCanContribute", "Everyone can contribute")}
                    </Box>
                </Box>
            ),
        },
        {
            value: AccessType.EDITOR,
            html: (
                <Box display="flex" alignItems="center">
                    <EditIcon className={classes.publicMenuIcon} />
                    <Box ml={1}>
                        {t("everyoneCanEdit", "Everyone can edit")}
                    </Box>
                </Box>
            ),
        },
    ]

    const content = (
        <Box
            width={!!mobile ? 350 : 600}
            height={600}
            display="flex"
            flexDirection={"column"}
            overflow="hidden"
        >
            <Box
                p={2}
                display="flex"
                justifyContent="space-between"
                alignItems={!!mobile ? "flex-start" : "center"}
                flexDirection={!!mobile ? "column" : "row"}
            >
                <Typography color="textSecondary">
                    {t("publicAccess", "Public Access")}
                </Typography>
                <PublicAccessEditor
                    concept={props.item}
                    permission={publicStatusEditingAccess}
                    publicOptions={publicOptions}
                />
            </Box>

            <Divider />
            <Tabs
                variant="fullWidth"
                value={tab}
                onChange={(_: React.ChangeEvent<{}>, newValue: number) => {
                    setTab(newValue)
                }}
                indicatorColor="primary"
            >
                <Tab label={`${t("teams", "Teams")} (${teams?.length ?? 0})`} />
                <Tab label={`${t("users", "Users")} (${users?.length ?? 0})`} />
            </Tabs>
            {tab === 0 && permissionToAddTeamsAndUsers && (
                <>
                    <Box display="flex" justifyContent="flex-end" p={1}>
                        <AddTeamAccess concept={props.item} />
                    </Box>

                    <Divider />
                </>
            )}
            {tab === 1 && permissionToAddTeamsAndUsers && (
                <>
                    <Box display="flex" justifyContent="flex-end" padding={1}>
                        <AddUserAccess concept={props.item} />
                    </Box>

                    <Divider />
                </>
            )}

            <Box overflow="auto">
                {tab === 0 && (
                    <List>
                        {teams?.map((item) => (
                            <Box key={item.Team.teamId}>
                                <TeamItem
                                    item={item}
                                    concept={props.item}
                                    permission={permissionToAddTeamsAndUsers}
                                />
                            </Box>
                        ))}
                    </List>
                )}
                {tab === 1 && (
                    <List>
                        {users?.map((item) => (
                            <Box key={item.User.userId}>
                                <UserItem
                                    item={item}
                                    concept={props.item}
                                    permission={permissionToAddTeamsAndUsers}
                                />
                            </Box>
                        ))}
                    </List>
                )}
            </Box>
        </Box>
    )
    return (
        <>
            <ClickableRichTooltip
                zIndex={theme.zIndex.appBar - 1}
                content={
                    !!loading ? (
                        <Box width="100%">
                            <Loading size={25} hideQuote={true} />
                        </Box>
                    ) : (
                        content
                    )
                }
                onMouseUp={true}
                arrow={false}
            >
                <Box
                    display="flex"
                    alignItems="center"
                    className={classes.permButton}
                >
                    <Tooltip
                        title={
                            publicOptions.filter(
                                (o) => o.value === props.item.isPublicAccessType
                            )[0].html
                        }
                    >
                        <Box
                            mr={0.5}
                            ml={0.5}
                            display="flex"
                            alignItems="center"
                        >
                            {props.item.isPublicAccessType ===
                            AccessType.NONE ? (
                                <VpnLockIcon
                                    fontSize="small"
                                    color="disabled"
                                />
                            ) : (
                                <Public color="action" fontSize="small" />
                            )}
                        </Box>
                    </Tooltip>
                    <Tooltip
                        title={
                            users?.length > 0
                                ? users.map((u) => (
                                      <Box key={u.User.userId}>
                                          {u.User.firstName +
                                              " " +
                                              u.User.lastName}
                                      </Box>
                                  ))
                                : "No user permissions"
                        }
                    >
                        <Box
                            display="flex"
                            mr={0.5}
                            ml={0.5}
                            alignItems="center"
                        >
                            <PersonIcon
                                color={
                                    users?.length > 0 ? "action" : "disabled"
                                }
                                style={{
                                    marginRight: 2,
                                }}
                                fontSize="small"
                            />
                            <Typography color="textSecondary" variant="body2">
                                {users?.length > 0 && users?.length}
                            </Typography>
                        </Box>
                    </Tooltip>
                    <Tooltip
                        title={
                            teams?.length > 0
                                ? teams.map((t) => (
                                      <Box key={t.Team.teamId}>
                                          {t.Team.name +
                                              ` (${t.Team.members?.length})`}
                                      </Box>
                                  ))
                                : "No team permissions"
                        }
                    >
                        <Box
                            display="flex"
                            mr={0.5}
                            ml={0.5}
                            alignItems="center"
                        >
                            <PeopleIcon
                                color={
                                    teams?.length > 0 ? "action" : "disabled"
                                }
                                style={{
                                    marginRight: 2,
                                }}
                                fontSize="small"
                            />
                            <Typography color="textSecondary" variant="body2">
                                {teams?.length > 0 && teams?.length}
                            </Typography>
                        </Box>
                    </Tooltip>
                </Box>
            </ClickableRichTooltip>
        </>
    )
}
const filterOptions = createFilterOptions({
    limit: 15,
})
const AddUserAccess = (props: { concept: Concept }) => {
    const classes = useStyles()
    const isMounted = useMountedState()
    const { currentUser } = useAuth()
    const [creatingNewUser, setCreatingNewUser] = useState(false)
    const { concept } = props
    const currentUserIds = concept?.addedUsers.map((item, idx) => {
        return item.User?.userId
    })
    const [users, setUsers] = useState<User[]>([])
    const [saving, setSaving] = useState(false)
    const [accessType, setAccessType] = useState<AccessType>(AccessType.VIEWER)
    const [grantUserAccess] = useMutation<
        GrantUserAccessMutation,
        GrantUserAccessMutationVariables
    >(GRANT_USER_ACCESS)
    const [loadUsers, { data, loading, called }] = useLazyQuery<
        AllUsersQuery,
        AllUsersQueryVariables
    >(ALL_USER_QUERY)
    const { t } = useAwaitTranslation("permissions")
    const handleSave = async () => {
        setSaving(true)
        await Promise.all([
            users.map((user) => {
                return grantUserAccess({
                    variables: {
                        conceptId: concept.id,
                        userId: user.userId,
                        accessType: accessType,
                    },
                })
            }),
        ])
        if (!!isMounted()) {
            userConceptPermissionsVar(INITIAL_PERMISSIONS_OBJECT)
            setUsers([])
            setSaving(false)
        }
    }

    return (
        <Box className={classes.additionForm}>
            <Box p={1} flexGrow={1}>
                <Autocomplete
                    options={
                        data?.users
                            ? data?.users.filter((x) => {
                                  return currentUserIds.indexOf(x.userId) === -1
                              })
                            : []
                    }
                    filterOptions={filterOptions}
                    classes={{
                        popper: classes.popper,
                        listbox: classes.listbox,
                        inputRoot: classes.input,
                    }}
                    fullWidth
                    value={users}
                    onFocus={() => {
                        if (!called) {
                            loadUsers()
                        }
                    }}
                    disableClearable
                    renderTags={(tagValue, getTagProps) => {
                        return tagValue.map((option: User, index) => (
                            <Chip
                                size="small"
                                key={option.userId}
                                {...getTagProps({ index })}
                                avatar={
                                    option.imageUrl ? (
                                        <Avatar src={option.imageUrl} />
                                    ) : (
                                        <Avatar>
                                            {option.firstName[0] +
                                                option.lastName[0]}
                                        </Avatar>
                                    )
                                }
                                label={option.firstName + " " + option.lastName}
                            />
                        ))
                    }}
                    size="small"
                    multiple
                    disabled={saving}
                    loading={loading}
                    onChange={(event, newValue: User[]) => {
                        setUsers(newValue)
                    }}
                    getOptionLabel={(option: User) => {
                        return option.lastName + ", " + option.firstName
                    }}
                    renderOption={(option: User) => {
                        return (
                            <ListItem
                                key={option.userId}
                                disableGutters
                                component={"div"}
                            >
                                <ListItemAvatar>
                                    <UserAvatar
                                        user={option}
                                        variant="circle"
                                        size="large"
                                    />
                                </ListItemAvatar>
                                <ListItemText
                                    primary={
                                        <Box display="flex" alignItems="center">
                                            {option.lastName +
                                                ", " +
                                                option.firstName}

                                            {option.role ===
                                                SystemRole.ADMIN && (
                                                <Tooltip
                                                    title={t("admin", "Admin")}
                                                >
                                                    <Box
                                                        component="span"
                                                        display="flex"
                                                        alignItems="center"
                                                        ml={1}
                                                    >
                                                        <SupervisedUserCircleIcon fontSize="small" />
                                                    </Box>
                                                </Tooltip>
                                            )}
                                        </Box>
                                    }
                                    secondary={option.email}
                                />
                            </ListItem>
                        )
                    }}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            variant="outlined"
                            label={t("user", "User")}
                            placeholder={t(
                                "addExistingUser",
                                "Add existing user"
                            )}
                            InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                    <>
                                        {!!currentUser.role &&
                                        currentUser.role !==
                                            SystemRole.GUEST ? (
                                            <InputAdornment position="end">
                                                <Tooltip title="Create new user">
                                                    <IconButton
                                                        size="small"
                                                        onClick={() =>
                                                            setCreatingNewUser(
                                                                true
                                                            )
                                                        }
                                                    >
                                                        <PersonAdd />
                                                    </IconButton>
                                                </Tooltip>
                                            </InputAdornment>
                                        ) : null}
                                        {params.InputProps.endAdornment}
                                    </>
                                ),
                            }}
                        />
                    )}
                />
            </Box>

            <Box className={classes.sendIconContainer}>
                <Autocomplete
                    options={[
                        AccessType.VIEWER,
                        AccessType.CONTRIBUTOR,
                        AccessType.EDITOR,
                        AccessType.OWNER,
                    ]}
                    classes={{
                        popper: classes.popper,
                        listbox: classes.listbox,
                        inputRoot: classes.input,
                    }}
                    size="small"
                    fullWidth
                    getOptionLabel={(option) => getEnumString(option)}
                    value={accessType}
                    className={classes.autoComplete}
                    disableClearable
                    openOnFocus
                    disabled={saving}
                    onChange={(event, value: AccessType) => {
                        setAccessType(value)
                    }}
                    renderInput={(params) => (
                        <TextField variant="outlined" {...params} />
                    )}
                />

                <IconButton
                    color="primary"
                    disabled={users.length === 0}
                    onClick={handleSave}
                >
                    {saving ? <CircularProgress size={25} /> : <SendIcon />}
                </IconButton>
            </Box>
            {!!creatingNewUser && (
                <UserForm
                    onCreation={(newUser: User) => {
                        setUsers([...users, newUser])
                    }}
                    onClose={() => setCreatingNewUser(false)}
                />
            )}
        </Box>
    )
}

const AddTeamAccess = (props: { concept: Concept }) => {
    const { concept } = props
    const isMounted = useMountedState()
    const classes = useStyles()
    const currentTeamIds = concept?.addedTeams.map((item, idx) => {
        return item.Team?.teamId
    })
    const [teams, setTeams] = useState<Team[]>([])
    const [saving, setSaving] = useState(false)
    const [accessType, setAccessType] = useState<AccessType>(AccessType.EDITOR)
    const [grantTeamAccess] = useMutation<
        GrantTeamAccessMutation,
        GrantTeamAccessMutationVariables
    >(GRANT_TEAM_ACCESS)
    const [loadTeams, { data, loading, called }] = useLazyQuery<
        AllTeamQuery,
        AllTeamQueryVariables
    >(ALL_TEAM_QUERY)
    const { t } = useAwaitTranslation("permissions")
    const handleSave = async () => {
        setSaving(true)
        await Promise.all([
            teams.map((team) => {
                return grantTeamAccess({
                    variables: {
                        conceptId: concept.id,
                        teamId: team.teamId,
                        accessType: accessType,
                    },
                })
            }),
        ])
        if (!!isMounted()) {
            userConceptPermissionsVar(INITIAL_PERMISSIONS_OBJECT)
            setTeams([])
            setSaving(false)
        }
    }
    return (
        <Box className={classes.additionForm}>
            <Box p={1} flexGrow={1}>
                <Autocomplete
                    options={
                        data?.teams
                            ? data?.teams.filter((x) => {
                                  return currentTeamIds.indexOf(x.teamId) === -1
                              })
                            : []
                    }
                    classes={{
                        popper: classes.popper,
                        listbox: classes.listbox,
                        inputRoot: classes.input,
                    }}
                    value={teams}
                    multiple
                    onFocus={() => {
                        if (!called) {
                            loadTeams()
                        }
                    }}
                    renderTags={(tagValue, getTagProps) => {
                        return tagValue.map((option: Team, index) => (
                            <Chip
                                {...getTagProps({ index })}
                                key={option.teamId}
                                size="small"
                                avatar={
                                    option.imageUrl ? (
                                        <Avatar src={option.imageUrl} />
                                    ) : (
                                        <Avatar>{option.name[0]}</Avatar>
                                    )
                                }
                                label={option.name}
                            />
                        ))
                    }}
                    filterOptions={filterOptions}
                    disabled={saving}
                    loading={loading}
                    size="small"
                    onChange={(event, newValue: Team[]) => {
                        setTeams(newValue)
                    }}
                    disableClearable
                    getOptionLabel={(option: Team) => option.name}
                    renderOption={(option: Team) => {
                        return (
                            <ListItem
                                component={"div"}
                                key={option.teamId}
                                disableGutters
                            >
                                <ListItemAvatar>
                                    <TeamAvatar
                                        team={option}
                                        variant="circle"
                                        size="medium"
                                    />
                                </ListItemAvatar>
                                <ListItemText primary={option.name} />
                            </ListItem>
                        )
                    }}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            variant="outlined"
                            label={t("team", "Team")}
                            placeholder={t("selectTeam", "Select Team")}
                        />
                    )}
                />
            </Box>
            <Box className={classes.sendIconContainer}>
                <Autocomplete
                    options={[
                        AccessType.VIEWER,
                        AccessType.CONTRIBUTOR,
                        AccessType.EDITOR,
                        AccessType.OWNER,
                    ]}
                    classes={{
                        popper: classes.popper,
                        listbox: classes.listbox,
                        inputRoot: classes.input,
                    }}
                    fullWidth
                    value={accessType}
                    size="small"
                    className={classes.autoComplete}
                    disableClearable
                    openOnFocus
                    disabled={saving}
                    getOptionLabel={(option) => getEnumString(option)}
                    onChange={(event, value: AccessType) => {
                        setAccessType(value)
                    }}
                    renderInput={(params) => (
                        <TextField variant="outlined" {...params} />
                    )}
                />

                <IconButton
                    color="primary"
                    disabled={teams.length === 0}
                    onClick={handleSave}
                >
                    {saving ? <CircularProgress size={25} /> : <SendIcon />}
                </IconButton>
            </Box>
        </Box>
    )
}

const PublicAccessEditor = (props: {
    concept: Concept
    permission: boolean
    publicOptions: {
        value: AccessType
        html: React.ReactElement
    }[]
}) => {
    const { concept, permission, publicOptions } = props
    const isMounted = useMountedState()
    const { currentUser } = useAuth()

    const { ableToPublish } = useEnvironmentSettingTools()
    const [saving, setSaving] = useState(false)

    const { t } = useAwaitTranslation("permissions")
    const [updateConcept] = useMutation<
        UpdateConceptMutation,
        UpdateConceptMutationVariables
    >(UPDATE_CONCEPT, {
        refetchQueries: [
            {
                query: CONCEPT_BY_ID,
                variables: { id: props.concept.id },
            },
        ],
    })
    const handleEdit = async (newValue) => {
        setSaving(true)
        await updateConcept({
            variables: {
                userId: currentUser.userId,
                concept: {
                    id: concept.id,
                    isPublicAccessType: newValue,
                    isPublic: newValue !== AccessType.NONE ?? false,
                },
            },
        })
        if (!!isMounted()) {
            userConceptPermissionsVar(INITIAL_PERMISSIONS_OBJECT)
            setSaving(false)
        }
    }

    const options = publicOptions
    const value = concept.isPublicAccessType || AccessType.VIEWER
    return (
        <Tooltip
            title={
                !ableToPublish
                    ? t(
                          "youMustBeASystemAdminToMakeConceptsPublic",
                          "You must be a system admin to make concepts public"
                      )
                    : !permission
                    ? t(
                          "youMustHaveOwnerPermissionsToEditPublicStatus",
                          "You must have owner permissions to edit public status"
                      )
                    : ""
            }
        >
            <Box display="flex" alignItems="center">
                <Select
                    value={value}
                    onChange={(
                        event: React.ChangeEvent<{ value: AccessType }>
                    ) => {
                        handleEdit(event.target.value)
                    }}
                    disabled={saving || !permission || !ableToPublish}
                    disableUnderline
                >
                    {options.map((option, idx) => {
                        return (
                            <MenuItem
                                key={option.value + "_public"}
                                value={option.value}
                            >
                                {option.html}
                            </MenuItem>
                        )
                    })}
                </Select>
            </Box>
        </Tooltip>
    )
}

const TeamItem = (props: {
    item: _ConceptAddedTeams
    concept: Concept
    permission: boolean
}) => {
    const { item, concept, permission } = props
    const isMounted = useMountedState()
    const classes = useStyles()
    const [saving, setSaving] = useState(false)

    const [grantTeamAccess] = useMutation<
        GrantTeamAccessMutation,
        GrantTeamAccessMutationVariables
    >(GRANT_TEAM_ACCESS)
    const [removeTeamAccess] = useMutation<
        RemoveTeamAccessMutation,
        RemoveTeamAccessMutationVariables
    >(REMOVE_TEAM_ACCESS)
    const handleEdit = async (newValue) => {
        setSaving(true)
        if (newValue === AccessType.NONE) {
            await removeTeamAccess({
                variables: {
                    conceptId: concept.id,
                    teamId: item.Team.teamId,
                },
            })
        } else {
            await grantTeamAccess({
                variables: {
                    conceptId: concept.id,
                    teamId: item.Team.teamId,
                    accessType: newValue,
                },
            })
        }
        if (!!isMounted()) {
            userConceptPermissionsVar(INITIAL_PERMISSIONS_OBJECT)
            setSaving(false)
        }
    }
    const value = item.type || AccessType.VIEWER

    const options = [
        AccessType.NONE,
        AccessType.VIEWER,
        AccessType.CONTRIBUTOR,
        AccessType.EDITOR,
        AccessType.OWNER,
    ]
    return (
        <>
            <ListItem key={item.Team.teamId} dense>
                <Box className={classes.listItemBody}>
                    <ListItemAvatar>
                        {item.Team && item.Team.imageUrl ? (
                            <Avatar src={item.Team.imageUrl} />
                        ) : (
                            <Avatar>
                                <GroupIcon />
                            </Avatar>
                        )}
                    </ListItemAvatar>
                    <ListItemText
                        primary={
                            <Link
                                className={classes.ellipsis}
                                color="inherit"
                                to={`/team/${item.Team.teamId}/home`}
                                component={LinkRouter}
                            >
                                {item.Team.name}
                            </Link>
                        }
                        secondary={
                            <Tooltip
                                title={
                                    <Box component="span">
                                        {item.Team.members.map((x, idx) => {
                                            return (
                                                <Box
                                                    key={
                                                        x.userId +
                                                        item.Team.teamId
                                                    }
                                                    className={
                                                        classes.tooltipListItem
                                                    }
                                                    padding={0.5}
                                                >
                                                    {x.lastName +
                                                        ", " +
                                                        x.firstName}
                                                </Box>
                                            )
                                        })}
                                    </Box>
                                }
                            >
                                <Box
                                    component="span"
                                    className={classes.ellipsis}
                                >
                                    {item.Team.members.length + " members"}
                                </Box>
                            </Tooltip>
                        }
                    />
                </Box>

                <Box className={classes.listItemActionContainer}>
                    <Select
                        value={value}
                        onChange={(
                            event: React.ChangeEvent<{ value: AccessType }>
                        ) => {
                            handleEdit(event.target.value)
                        }}
                        disabled={saving || !permission}
                        disableUnderline
                    >
                        {options.map((item, idx) => {
                            return (
                                <MenuItem key={"TEAM" + item} value={item}>
                                    <Typography
                                        color="textSecondary"
                                        variant="body2"
                                    >
                                        {getEnumString(item)}
                                    </Typography>
                                </MenuItem>
                            )
                        })}
                    </Select>
                </Box>

                <Divider />
            </ListItem>
        </>
    )
}

const UserItem = (props: {
    item: _ConceptAddedUsers
    concept: Concept
    permission: boolean
}) => {
    const { t } = useAwaitTranslation("permissions")
    const { item, concept, permission } = props
    const isMounted = useMountedState()
    const classes = useStyles()
    const [saving, setSaving] = useState(false)
    const { currentUser } = useAuth()
    const isAdmin = currentUser.role === SystemRole.ADMIN
    const [grantUserAccess] = useMutation<
        GrantUserAccessMutation,
        GrantUserAccessMutationVariables
    >(GRANT_USER_ACCESS)
    const [removeUserAccess] = useMutation<
        RemoveUserAccessMutation,
        RemoveUserAccessMutationVariables
    >(REMOVE_USER_ACCESS)
    const handleEdit = async (newValue) => {
        setSaving(true)
        if (newValue === AccessType.NONE) {
            await removeUserAccess({
                variables: {
                    conceptId: concept.id,
                    userId: item.User.userId,
                },
            })
        } else {
            await grantUserAccess({
                variables: {
                    conceptId: concept.id,
                    userId: item.User.userId,
                    accessType: newValue,
                },
            })
        }
        if (!!isMounted()) {
            userConceptPermissionsVar(INITIAL_PERMISSIONS_OBJECT)
            setSaving(false)
        }
    }

    const options = [
        AccessType.NONE,
        AccessType.VIEWER,
        AccessType.CONTRIBUTOR,
        AccessType.EDITOR,
        AccessType.OWNER,
    ]
    const value = item.type || AccessType.VIEWER
    const disabledDueToAdmin = !isAdmin && item.User?.role === SystemRole.ADMIN
    return (
        <>
            <ListItem key={item.User.userId}>
                <Box className={classes.listItemBody}>
                    <Box textAlign="center">
                        <ListItemAvatar>
                            <UserAvatar
                                size="large"
                                variant="circle"
                                user={item.User}
                            />
                        </ListItemAvatar>
                    </Box>

                    <ListItemText
                        primary={
                            <Box display="flex" alignItems="center">
                                <Link
                                    className={classes.ellipsis}
                                    color="inherit"
                                    component={LinkRouter}
                                    to={`/user/${item.User.username}/home`}
                                >
                                    {item.User.lastName +
                                        ", " +
                                        item.User.firstName}
                                </Link>
                                {item.User.role === SystemRole.ADMIN && (
                                    <Tooltip title={t("admin", "Admin")}>
                                        <Box
                                            component="span"
                                            display="flex"
                                            alignItems="center"
                                            ml={1}
                                        >
                                            <SupervisedUserCircleIcon fontSize="small" />
                                        </Box>
                                    </Tooltip>
                                )}
                            </Box>
                        }
                        secondary={
                            <Box className={classes.ellipsis} component="span">
                                {item.User.email}
                            </Box>
                        }
                    />
                </Box>

                <Tooltip
                    title={
                        !!permission && !!disabledDueToAdmin
                            ? "You must be an admin to change admin access"
                            : ""
                    }
                >
                    <Box className={classes.listItemActionContainer}>
                        <Select
                            value={value}
                            onChange={(
                                event: React.ChangeEvent<{ value: AccessType }>
                            ) => {
                                handleEdit(event.target.value)
                            }}
                            disabled={
                                saving || !permission || !!disabledDueToAdmin
                            }
                            disableUnderline
                        >
                            {options.map((option, idx) => {
                                return (
                                    <MenuItem
                                        key={item.User?.userId + option}
                                        value={option}
                                    >
                                        <Typography
                                            color="textSecondary"
                                            variant="body2"
                                        >
                                            {getEnumString(option)}
                                        </Typography>
                                    </MenuItem>
                                )
                            })}
                        </Select>
                    </Box>
                </Tooltip>
            </ListItem>
        </>
    )
}

function getEnumString(string: AccessType) {
    let lowercase = string.toLocaleLowerCase()
    return lowercase.charAt(0).toUpperCase() + lowercase.slice(1)
}
