import React, { useState } from "react"
import {
    ListItemAvatar,
    ListItem,
    ListItemText,
    Box,
    IconButton,
    ListItemSecondaryAction,
} from "@material-ui/core"
import ClearIcon from "@material-ui/icons/Clear"

import { Concept } from "../../../__generated__/types"
import parse from "autosuggest-highlight/parse"
import match from "autosuggest-highlight/match"
import ConceptAvatar from "../../ConceptAvatar"
import CheckBox from "@material-ui/icons/CheckBox"
import CheckBoxOutlineBlank from "@material-ui/icons/CheckBoxOutlineBlank"
import { useHistory } from "react-router-dom"
import { useTheme } from "@material-ui/core/styles"
import { makeStyles, Theme } from "@material-ui/core"
import ConceptPreviewPopper from "../../ConceptPreviewPopper"
import useAwaitTranslation from "../../../i18n/useAwaitTranslation"
import useMountedState from "../../../util/useMountedState"
import { useAuth } from "../../../providers/AuthProvider"

interface CollectionItemInterface {
    item: Concept
    editable: boolean
    action?: "toggle" | "remove"
    singleSelection?: boolean
    selected?: boolean
    onAction?: (concept: Concept, selected: boolean) => Promise<void>
    hideDivider?: boolean
    listItemSecondaryAction?: React.ReactElement
    showPosition?: boolean
    position?: number
    draggable?: boolean
    searchString?: string
    disabledMessage?: string
}

const useStyles = makeStyles((theme: Theme) => ({
    blur: {
        transition: theme.transitions.easing.easeInOut,
        transitionDuration: `${theme.transitions.duration.standard}ms`,
    },
}))

const CollectionItem: React.FunctionComponent<CollectionItemInterface> = ({
    item,
    editable,
    selected,
    onAction,
    action,
    singleSelection,
    listItemSecondaryAction,
    draggable,
    searchString,
    disabledMessage,
}) => {
    const classes = useStyles()

    const history = useHistory()
    const { isNonMember } = useAuth()
    const isMounted = useMountedState()
    const [mutating, setMutating] = useState(false)
    const { i18n } = useAwaitTranslation("feedback")
    const selectedLangString = i18n?.language
    let translatedContent = item?.translations.filter(
        (t) => t.languageString === selectedLangString
    )[0]
    const title = translatedContent?.title || item.title || ""
    const titleMatches = match(title, searchString || "")
    const partsTitle = parse(title, titleMatches)
    const summary = translatedContent?.summary || item.summary || ""
    const summaryMatches = match(summary, searchString || "")
    const partsSummary = parse(summary, summaryMatches)
    const theme = useTheme()
    const handleToggle = async () => {
        if (!mutating) {
            setMutating(true)
            await onAction(item, selected)
            if (!!isMounted()) {
                setMutating(false)
            }
        }
    }

    return (
        <ListItem
            className={classes.blur}
            button={editable || !isNonMember ? true : undefined}
            disabled={!selected && !!disabledMessage}
            ContainerComponent="div"
            selected={!!singleSelection && !!selected}
            onClick={() => {
                if (!editable && !isNonMember) {
                    history.push(`/concept/${item.id}/home`)
                } else if (!!editable && action === "toggle") {
                    handleToggle()
                }
            }}
            style={{
                cursor: !!draggable ? "grab" : "pointer",
                paddingRight: !!listItemSecondaryAction
                    ? theme.spacing(10)
                    : theme.spacing(2),
            }}
            disableGutters={!singleSelection && action === "toggle"}
        >
            {action === "toggle" && !!onAction && !singleSelection && (
                <Box
                    mr={0.5}
                    ml={0.5}
                    p={1}
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                >
                    {!!selected ? (
                        <CheckBox color="primary" fontSize="small" />
                    ) : (
                        <CheckBoxOutlineBlank
                            color="primary"
                            fontSize="small"
                        />
                    )}
                </Box>
            )}
            <Box
                display="flex"
                width="100%"
                alignItems={"center"}
                style={{
                    filter: !!mutating ? "blur(1px)" : "none",
                }}
            >
                <ListItemAvatar style={{ display: "flex" }}>
                    <ConceptAvatar
                        concept={item}
                        variant="rounded"
                        size="large"
                    />
                </ListItemAvatar>
                <ListItemText
                    primary={
                        <ConceptPreviewPopper conceptId={item.id}>
                            <Box width="fit-content">
                                {partsTitle.map((part, index) => (
                                    <span
                                        key={index}
                                        style={{
                                            fontWeight: part.highlight
                                                ? 800
                                                : 400,
                                        }}
                                    >
                                        {part.text}
                                    </span>
                                ))}
                            </Box>
                        </ConceptPreviewPopper>
                    }
                    primaryTypographyProps={{
                        variant: "body2",
                        style: {
                            width: "fit-content",
                        },
                    }}
                    secondary={
                        <>
                            {partsSummary.map((part, index) => (
                                <span
                                    key={index}
                                    style={{
                                        fontWeight: part.highlight ? 800 : 400,
                                    }}
                                >
                                    {part.text}
                                </span>
                            ))}
                        </>
                    }
                />
            </Box>
            {(!!listItemSecondaryAction || !!editable) && (
                <ListItemSecondaryAction>
                    {!!listItemSecondaryAction && listItemSecondaryAction}
                    {!listItemSecondaryAction && action === "remove" && (
                        <IconButton
                            size="small"
                            onClick={(e) => {
                                handleToggle()
                            }}
                        >
                            <ClearIcon fontSize="small" />
                        </IconButton>
                    )}
                </ListItemSecondaryAction>
            )}
        </ListItem>
    )
}

export default React.memo(CollectionItem)
