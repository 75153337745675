import {
    Box,
    IconButton,
    TableCell,
    TableSortLabel,
    Tooltip,
} from "@material-ui/core"
import {
    FeedbackField,
    myCurrentInputResponseLabelVar,
} from "../../../../providers/GlobalState"
import { CriteriaType } from "../../../criteria/types"
import useTableTools from "../../../table/useTableTools"
import Settings from "@material-ui/icons/Settings"
import { getCleanedFieldConfig, getSelectedResponseScores } from "../../util"
import { Concept, CriteriaScore } from "../../../../__generated__/types"
import { useReactiveVar } from "@apollo/client"
import Assistant from "@material-ui/icons/Assistant"
import { IsPlateStringEmpty } from "../../../text-editor/RichTextEditor"
// Using a table as the portal so that we do not get react
// warnings when mounting a tr element

interface HeaderCellProps {
    field: FeedbackField
    order: "asc" | "desc"
    orderBy: string
    onSortBy: (value: string) => void
    setEditingField: (value: FeedbackField) => void
    editingConfig: boolean
    editable: boolean
    concepts: Concept[]
    onGenerateValues: (
        field: FeedbackField,
        items: {
            score: CriteriaScore
            concept: Concept
        }[]
    ) => Promise<void>
}

const HeaderCell: React.FunctionComponent<HeaderCellProps> = ({
    field,
    orderBy,
    order,
    onSortBy,
    setEditingField,
    editingConfig,
    editable,
    concepts,
    onGenerateValues,
}) => {
    const { classes: tableClasses } = useTableTools()

    const currentLabel = useReactiveVar(myCurrentInputResponseLabelVar)
    const settingsButton = (
        <IconButton
            style={{ marginLeft: 2 }}
            size="small"
            onClick={() => setEditingField(field)}
        >
            <Settings fontSize="small" />
        </IconButton>
    )
    const cleanedConfig = getCleanedFieldConfig(field)
    const headerString = cleanedConfig.suffix ? (
        <Box whiteSpace={"nowrap"}>
            {`${cleanedConfig.name} (${cleanedConfig.suffix})`}{" "}
        </Box>
    ) : (
        cleanedConfig.name
    )
    const rowsToGenerate =
        concepts
            ?.map((concept) => {
                const score =
                    getSelectedResponseScores(field, currentLabel)?.find(
                        (score) => score.scoredConcept?.id === concept.id
                    ) || null
                if (!score || !!IsPlateStringEmpty(score?.response)) {
                    return {
                        score,
                        concept,
                    }
                }
                return null
            })
            ?.filter((x) => !!x) ?? []

    if (field.input?.criteria?.criteriaType === CriteriaType.TextResponse) {
        return (
            <TableCell key={field.input?.id}>
                <Box display="flex" alignItems="center">
                    {cleanedConfig.name}
                    {!!editingConfig
                        ? settingsButton
                        : !!editable && (
                              <Tooltip
                                  title={
                                      rowsToGenerate.length === 0
                                          ? "All rows currently have values"
                                          : "Generate values for all rows without a value"
                                  }
                              >
                                  <span>
                                      <IconButton
                                          disabled={rowsToGenerate.length === 0}
                                          style={{ marginLeft: 2 }}
                                          size="small"
                                          onClick={() =>
                                              onGenerateValues(
                                                  field,
                                                  rowsToGenerate
                                              )
                                          }
                                      >
                                          <Assistant fontSize="small" />
                                      </IconButton>
                                  </span>
                              </Tooltip>
                          )}
                </Box>
            </TableCell>
        )
    } else {
        return (
            <TableCell
                key={field.input?.id}
                sortDirection={orderBy === cleanedConfig.name ? order : false}
            >
                {!editingConfig ? (
                    <TableSortLabel
                        active={orderBy === cleanedConfig.name}
                        style={{
                            whiteSpace: "nowrap",
                        }}
                        direction={
                            orderBy === cleanedConfig.name ? order : "asc"
                        }
                        onClick={() => onSortBy(cleanedConfig.name)}
                    >
                        {headerString}{" "}
                        {orderBy === cleanedConfig.name && (
                            <span className={tableClasses.visuallyHidden}>
                                {order === "desc"
                                    ? "sorted descending"
                                    : "sorted ascending"}
                            </span>
                        )}
                    </TableSortLabel>
                ) : (
                    <Box
                        display="flex"
                        alignItems={"center"}
                        style={{
                            whiteSpace: "nowrap",
                        }}
                    >
                        {headerString}
                        {settingsButton}
                    </Box>
                )}
            </TableCell>
        )
    }
}

export default HeaderCell
