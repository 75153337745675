import React from "react"
import { Button, Box, useMediaQuery, Typography } from "@material-ui/core"
import { ReactComponent as AmbleLogo } from "../../logo.svg"
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight"
import KeyboardArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft"
import useAwaitTranslation from "../../i18n/useAwaitTranslation"
import { useTheme } from "@material-ui/core/styles"

interface FooterProps {
    onNext: () => void
    onBack: () => void
    onClose: () => void
    currentIndex: number
    total: number
}
const Footer: React.FC<FooterProps> = ({
    onNext,
    onBack,
    onClose,
    currentIndex,
    total,
}) => {
    const theme = useTheme()
    const mobile = useMediaQuery(theme.breakpoints.down("sm"))

    const { t } = useAwaitTranslation("feedback")
    const isLast = currentIndex + 1 === total
    return (
        <Box
            width="100%"
            display="flex"
            justifyContent="space-between"
            overflow="hidden"
        >
            <Button
                style={{
                    height: "100%",
                    paddingLeft: 25,
                    paddingRight: 25,
                    flexShrink: 0,
                }}
                onClick={() => onBack()}
                startIcon={<KeyboardArrowLeftIcon />}
                disabled={currentIndex === 0}
            >
                {t("back", "Back")}
            </Button>
            <Box my={1.5} textAlign="center">
                <Box
                    flex={1}
                    display="flex"
                    justifyContent="center"
                    height={mobile ? "100%" : 50}
                >
                    <Box
                        display="flex"
                        flexDirection="column"
                        alignItems="center"
                        justifyContent="center"
                    >
                        <Typography color="textSecondary" variant="body1">{`${
                            currentIndex + 1
                        } / ${total}`}</Typography>

                        {mobile && (
                            <Box mt={2}>
                                <AmbleLogo style={{ height: "20px" }} />
                            </Box>
                        )}
                    </Box>
                </Box>
            </Box>
            <Button
                style={{
                    height: "100%",
                    paddingLeft: 25,
                    paddingRight: 25,
                    flexShrink: 0,
                }}
                onClick={() => {
                    if (isLast) {
                        onClose()
                    } else {
                        onNext()
                    }
                }}
                color={isLast ? "primary" : "default"}
                endIcon={<KeyboardArrowRightIcon />}
            >
                {isLast ? t("done", "Done") : t("next", "Next")}
            </Button>
        </Box>
    )
}

export default Footer
