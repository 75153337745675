import { useCallback, useReducer, useEffect, useState } from "react"
import { useQuery } from "@apollo/client"
import { FeedVariables } from "../../../providers/GlobalState"

import { AccessType, _ConceptFilter } from "../../../__generated__/types"
import { useAuth } from "../../../providers/AuthProvider"
import { FilterItem, FilterType } from "./types"
import { CATEGORIES_FILTERED } from "../../categories/graphql"
import {
    CategoriesFilteredQuery,
    CategoriesFilteredQueryVariables,
} from "../../categories/__generated__/graphql"

function FeedVariablesReducer(state, action) {
    let newState = { ...state }
    Object.keys(action.value).map((field) => {
        newState[field] = action.value[field]
        return newState
    })
    return newState
}

export default function useFilters(initialFeedVariables?: FeedVariables) {
    const { currentUser } = useAuth()
    const { data } = useQuery<
        CategoriesFilteredQuery,
        CategoriesFilteredQueryVariables
    >(CATEGORIES_FILTERED, {
        variables: {
            filter: {
                isFilterOption: true,
            },
        },
    })
    const [state, dispatch] = useReducer(FeedVariablesReducer, {
        viewPrivate: true,
        filters: [],
    } as FeedVariables)

    const [defaultFilters, setDefaultFilters] = useState([])

    const getCleanedFilterItems = useCallback(
        (value: FilterItem[], disableCurrent?: boolean) => {
            if (!!defaultFilters) {
                const cleanedFilters = [
                    ...defaultFilters.map((filter, index) => {
                        const currentFilter = value?.find(
                            (initFilter) =>
                                initFilter?.value?.length > 0 &&
                                (filter.type !== FilterType.Concept
                                    ? initFilter.type === filter.type
                                    : initFilter.type === filter.type &&
                                      initFilter.field === filter.field)
                        )
                        if (!!currentFilter) {
                            return {
                                ...filter,
                                ...currentFilter,
                                disabled: !!disableCurrent,
                            }
                        } else {
                            return filter
                        }
                    }),
                ]

                return cleanedFilters as FilterItem[]
            } else {
                return value
            }
        },
        [defaultFilters]
    )

    const getPermissionsFilter: () => _ConceptFilter = useCallback(() => {
        return {
            OR: [
                {
                    addedTeams_some: {
                        Team: {
                            teamId_in:
                                currentUser.teams
                                    ?.map((team) => team.teamId)
                                    ?.filter((x) => !!x) ?? [],
                        },
                        type_not: AccessType.NONE,
                    },
                },
                {
                    addedUsers_some: {
                        User: {
                            userId: currentUser.userId,
                        },
                        type_not: AccessType.NONE,
                    },
                },
                {
                    isPublicAccessType_not: AccessType.NONE,
                },
            ],
        }
    }, [currentUser.userId, currentUser.teams])

    const getFilterObject = (feedVariables: FeedVariables) => {
        let filterObject: _ConceptFilter = {}
        if (!!feedVariables.filters && feedVariables.filters?.length !== 0) {
            feedVariables.filters.filter((filter) => {
                if (filter.value && filter.value.length !== 0) {
                    if (
                        !!filter.firstLevelFilter &&
                        !filter.secondLevelFilter &&
                        !filter.secondLevelFilterFormatter
                    ) {
                        filterObject[filter.firstLevelFilter] = filter.value
                    } else {
                        filterObject[filter.firstLevelFilter] = {}
                        if (!!filter.secondLevelFilter) {
                            filterObject[filter.firstLevelFilter][
                                filter.secondLevelFilter
                            ] = filter.value
                        } else if (!!filter.secondLevelFilterFormatter) {
                            filterObject[filter.firstLevelFilter] = {
                                ...filterObject[filter.firstLevelFilter],
                                ...filter.secondLevelFilterFormatter(
                                    filter.value
                                ),
                            }
                        }
                    }
                }

                return filterObject
            })
        }

        if (!!feedVariables.connectionsConceptId) {
            let connectionsFilterObject = {}
            if (!!feedVariables.subConnectionCategoryId) {
                connectionsFilterObject = {
                    connections_some: {
                        AND: [
                            {
                                connections_some: {
                                    id: feedVariables.connectionsConceptId,
                                },
                            },
                            {
                                category: {
                                    id: feedVariables.subConnectionCategoryId,
                                },
                            },
                        ],
                    },
                }
            } else {
                connectionsFilterObject = {
                    connections_some: {
                        id: feedVariables.connectionsConceptId,
                    },
                }
            }

            if (!!filterObject.connections_some) {
                filterObject["AND"] = [
                    {
                        connections_some: {
                            ...filterObject.connections_some,
                        },
                    },
                    { ...connectionsFilterObject },
                ]
                delete filterObject.connections_some
            } else {
                filterObject = {
                    ...filterObject,
                    ...connectionsFilterObject,
                }
            }
        }

        if (!feedVariables.viewPrivate) {
            filterObject = {
                ...filterObject,
                isPublicAccessType_not: AccessType.NONE,
            }
        } else {
            filterObject = {
                ...filterObject,
                ...getPermissionsFilter(),
            }
        }
        if (!!feedVariables.categoryId) {
            filterObject = {
                ...filterObject,
                category: {
                    id: feedVariables.categoryId,
                },
            }
        } else if (!!feedVariables.categoryIds) {
            filterObject = {
                ...filterObject,
                category: {
                    id_in: feedVariables.categoryIds,
                },
            }
        }

        return filterObject
    }

    useEffect(() => {
        if (!!initialFeedVariables?.filters && defaultFilters.length > 0) {
            dispatch({
                value: {
                    filters: getCleanedFilterItems(
                        initialFeedVariables.filters,
                        true
                    ),
                },
            })
        }
    }, [initialFeedVariables?.filters, getCleanedFilterItems, defaultFilters])
    useEffect(() => {
        if (!!data && defaultFilters.length === 0) {
            setDefaultFilters([
                ...[
                    {
                        firstLevelFilter: "category",
                        secondLevelFilter: "id_in",
                        value: [],
                        type: FilterType.Category,
                    },
                    {
                        firstLevelFilter: "status_some",
                        secondLevelFilterFormatter: (value) => {
                            return {
                                AND: [
                                    {
                                        isActive: true,
                                    },
                                    {
                                        stage: {
                                            id_in: value,
                                        },
                                    },
                                ],
                            }
                        },
                        value: [],
                        type: FilterType.Status,
                    },
                    {
                        firstLevelFilter: "createdBy",
                        secondLevelFilter: "userId_in",
                        value: [],
                        type: FilterType.User,
                    },
                    {
                        firstLevelFilter: "industries_some",
                        secondLevelFilter: "industryId_in",
                        value: [],
                        type: FilterType.Industry,
                    },
                    {
                        firstLevelFilter: "tags_some",
                        secondLevelFilter: "name_in",
                        value: [],
                        type: FilterType.Tags,
                    },
                ],
                ...(data.Category.map((item) => {
                    return {
                        field: item.name,
                        firstLevelFilter: "connections_some",
                        secondLevelFilter: "id_in",
                        value: [],
                        optionsFilter: {
                            category: {
                                id: item.id,
                            },
                        },
                        type: FilterType.Concept,
                    }
                }) ?? []),
            ])
        }
    }, [data, defaultFilters.length])

    const currentFeedVariables = {
        ...initialFeedVariables,
        ...state,
    }
    const filterObject = getFilterObject(currentFeedVariables)
    return {
        filterObject,
        defaultFilters,
        currentFeedVariables,
        setCurrentFeedVariables: (value: FeedVariables) => {
            dispatch({ value })
        },
        getCleanedFilterItems,
        getFilterObject,
        getPermissionsFilter,
    }
}
