import React from "react"
import {
    Radar,
    RadarChart,
    PolarGrid,
    PolarAngleAxis,
    PolarRadiusAxis,
    Legend,
    ResponsiveContainer,
} from "recharts"
import { useTheme, makeStyles } from "@material-ui/core/styles"
import { Box, Chip, Tooltip } from "@material-ui/core"
import { Typography } from "@material-ui/core"

type RadarDataValue = {
    id: string
    label: string
    value: number
}
const useStyles = makeStyles((theme) => ({
    radarChart: {
        "& svg": {
            overflow: "overlay !important",
        },
    },
}))

export type RadarDataObject = {
    criteriaName: string
    values: RadarDataValue[]
}

interface CriteriaRadarProps {
    data: RadarDataObject[]
    hideLegend?: boolean
}
export default function CriteriaRadarChart(props: CriteriaRadarProps) {
    //hooks
    const theme = useTheme()
    const classes = useStyles()

    //variables
    const formattedData = props.data?.map((item) => {
        let object = {
            fullMark: 10,
            subject: item.criteriaName,
        }
        item.values?.map((val) => {
            object[val.id] = val.value > 10 ? 10 : val.value
            return object
        })
        return object
    })

    const radarObjects = props.data?.[0]?.values

    return (
        <Box height="100%" width="100%">
            <Box
                display="flex"
                width="100%"
                height="100%"
                overflow="hidden"
                alignItems="center"
            >
                <Box width="100%" overflow="hidden" height="100%">
                    <ResponsiveContainer width="100%" height="100%">
                        <RadarChart
                            outerRadius={"50%"}
                            cx={"50%"}
                            cy={"50%"}
                            data={formattedData}
                            className={classes.radarChart}
                        >
                            <PolarGrid gridType="circle" />
                            <PolarAngleAxis
                                dataKey="subject"
                                tick={(props) => {
                                    const {
                                        x,
                                        y,
                                        cy,
                                        cx,

                                        orientation,
                                        width,
                                        height,
                                        ...rest
                                    } = props

                                    return (
                                        <g>
                                            <foreignObject
                                                {...rest}
                                                textAnchor="middle"
                                                width={100}
                                                height={25}
                                                //equations made up
                                                y={y - (cy - y) / 5 - 10}
                                                x={x - (cx - x) / 2 - 50}
                                                overflow="visible"
                                            >
                                                <Tooltip
                                                    title={props.payload.value}
                                                >
                                                    <Chip
                                                        size="small"
                                                        style={{
                                                            overflow: "hidden",
                                                            width: "100%",
                                                            textAlign: "center",
                                                        }}
                                                        label={
                                                            <Typography
                                                                noWrap
                                                                variant="caption"
                                                            >
                                                                {
                                                                    props
                                                                        .payload
                                                                        .value
                                                                }
                                                            </Typography>
                                                        }
                                                    />
                                                </Tooltip>
                                            </foreignObject>
                                        </g>
                                    )
                                }}
                            />
                            <PolarRadiusAxis domain={[0, 10]} />
                            {radarObjects?.map((item, index) => {
                                return (
                                    <Radar
                                        key={item.id}
                                        style={{
                                            margin: "auto",
                                        }}
                                        name={item.label}
                                        dataKey={item.id}
                                        stroke={
                                            index === 0
                                                ? theme.palette.primary.main
                                                : theme.palette.secondary.main
                                        }
                                        fill={
                                            index === 0
                                                ? theme.palette.primary.main
                                                : theme.palette.secondary.main
                                        }
                                        fillOpacity={0.6}
                                    />
                                )
                            })}
                            {!props.hideLegend && <Legend />}
                        </RadarChart>
                    </ResponsiveContainer>
                </Box>
            </Box>
        </Box>
    )
}
