import {
    Box,
    Button,
    TextField,
    InputAdornment,
    IconButton,
    Tooltip,
    useTheme,
    Divider,
} from "@material-ui/core"
import { Concept } from "../../../__generated__/types"

import GridLayout, { Layout } from "react-grid-layout"
import { GroupingType } from "../../criteria/useCriteriaTypes"
import "react-grid-layout/css/styles.css"
import "react-resizable/css/styles.css"
import { useInputTools } from "../useInputTools"
import { Droppable } from "react-beautiful-dnd"
import { useState } from "react"
import { SizeMe } from "react-sizeme"
import ConceptChip from "../../ConceptChip"
import Add from "@material-ui/icons/Add"
import Clear from "@material-ui/icons/Clear"
import { GroupingResponseWithConcepts } from "./types"
import IndividualGroup from "./IndividualGroup"
import PlaylistAddCheckIcon from "@material-ui/icons/PlaylistAddCheck"
import DashboardIcon from "@material-ui/icons/Dashboard"
import useAwaitTranslation from "../../../i18n/useAwaitTranslation"
type GridLayoutItem = Layout
const gridCompactOptions = ["vertical", "horizontal", null]

const DroppableGroups = (props: {
    responseWithConcepts: GroupingResponseWithConcepts[]
    layout: GridLayoutItem[]
    setLayout: (layout: GridLayoutItem[]) => void
    config: GroupingType
    inputId: string
    viewingResults: boolean
    inputConfigurationIsEditable: boolean
    onGroupNameChange?: (groupId, newName) => void
    onGroupRemoval?: (groupIdToRemove: string) => void
    onGroupAddition?: (groupName: string) => void
}) => {
    const {
        config,
        inputId,
        responseWithConcepts,
        setLayout,
        layout,
        inputConfigurationIsEditable,
        viewingResults,
        onGroupNameChange,
        onGroupRemoval,
        onGroupAddition,
    } = props
    const theme = useTheme()

    const [addingNew, setAddingNew] = useState(false)
    const [newGroupTitle, setNewGroupTitle] = useState("")
    const [gridCompactType, setGridCompactType] = useState(2)
    const { t } = useAwaitTranslation("feedback")
    const { onUpdateInput } = useInputTools({})
    const onMakeStandard = async () => {
        if (!!inputConfigurationIsEditable) {
            await onUpdateInput({
                id: inputId,
                inputConfig: JSON.stringify({
                    ...config,
                    groups: responseWithConcepts.map(
                        (grouping) => grouping.group
                    ),
                    groupLayout: layout,
                }),
            })
        }
    }

    if (
        !layout ||
        !!responseWithConcepts.some((grouping) => !grouping.group.id)
    ) {
        return null
    }

    return (
        <Box
            display="flex"
            flexDirection={"column"}
            overflow="hidden"
            height="100%"
            width="100%"
        >
            <Box
                width="100%"
                height="100%"
                overflow="auto"
                display="flex"
                flexDirection="column"
                justifyContent={"center"}
            >
                <SizeMe monitorHeight monitorWidth>
                    {({ size }) => (
                        <GridLayout
                            style={{ minHeight: "100%" }}
                            width={size.width ?? 500}
                            compactType={
                                gridCompactOptions[gridCompactType] as
                                    | "vertical"
                                    | "horizontal"
                            }
                            preventCollision={gridCompactType === 2}
                            autoSize={true}
                            useCSSTransforms={false}
                            draggableHandle={`.input-drag-handle-${inputId}`}
                            isDraggable={!!inputConfigurationIsEditable}
                            isResizable={!!inputConfigurationIsEditable}
                            layout={layout}
                            rowHeight={30}
                            cols={12}
                            onLayoutChange={(newLayout: Layout[]) => {
                                setLayout(newLayout)
                            }}
                        >
                            {responseWithConcepts.map((grouping, index) => {
                                const groupConcepts: Concept[] =
                                    responseWithConcepts[index]?.concepts ?? []

                                return (
                                    <Box
                                        data-grid={{
                                            w: 4,
                                            h: 4,
                                            x: 0,
                                            y: 0,
                                        }}
                                        key={grouping.group.id}
                                        boxShadow={theme.shadows[1]}
                                        border={
                                            "solid 1px " + theme.palette.divider
                                        }
                                        borderRadius={theme.shape.borderRadius}
                                    >
                                        <Box
                                            width="100%"
                                            height={"100%"}
                                            display="flex"
                                            overflow="hidden"
                                            borderRadius={
                                                theme.shape.borderRadius
                                            }
                                        >
                                            <Droppable
                                                droppableId={grouping.group.id}
                                                type="CONCEPT"
                                                renderClone={(
                                                    provided,
                                                    snapshot,
                                                    rubric
                                                ) => (
                                                    <div
                                                        ref={provided.innerRef}
                                                        {...provided.draggableProps}
                                                    >
                                                        <ConceptChip
                                                            item={
                                                                groupConcepts[
                                                                    rubric
                                                                        .source
                                                                        .index
                                                                ]
                                                            }
                                                            disablePopper={true}
                                                            disableClick={true}
                                                        />
                                                    </div>
                                                )}
                                            >
                                                {(provided, snapshot) => {
                                                    return (
                                                        <IndividualGroup
                                                            viewingResults={
                                                                viewingResults
                                                            }
                                                            inputConfigurationIsEditable={
                                                                inputConfigurationIsEditable
                                                            }
                                                            key={
                                                                grouping.group
                                                                    .id
                                                            }
                                                            group={
                                                                grouping.group
                                                            }
                                                            onEditGroupTitle={(
                                                                value: string
                                                            ) => {
                                                                onGroupNameChange(
                                                                    grouping
                                                                        .group
                                                                        .id,
                                                                    value
                                                                )
                                                            }}
                                                            onGroupRemoval={
                                                                onGroupRemoval
                                                            }
                                                            provided={provided}
                                                            snapshot={snapshot}
                                                            inputId={inputId}
                                                            groupConcepts={
                                                                groupConcepts
                                                            }
                                                        />
                                                    )
                                                }}
                                            </Droppable>
                                        </Box>
                                    </Box>
                                )
                            })}
                        </GridLayout>
                    )}
                </SizeMe>
            </Box>

            {!!inputConfigurationIsEditable && (
                <>
                    <Divider />
                    <Box display="flex" justifyContent={"flex-end"} p={0.5}>
                        {!!addingNew ? (
                            <Box p={0.5} width="100%">
                                <TextField
                                    variant="outlined"
                                    onKeyDown={(e) => {
                                        if (
                                            e.key === "Enter" &&
                                            newGroupTitle.length > 0
                                        ) {
                                            onGroupAddition(newGroupTitle)
                                            setNewGroupTitle("")
                                            setAddingNew(false)
                                        }
                                    }}
                                    onBlur={(e) => {
                                        if (e.target.value.length === 0) {
                                            setAddingNew(false)
                                        }
                                    }}
                                    fullWidth
                                    autoFocus={true}
                                    size="small"
                                    value={newGroupTitle}
                                    onChange={(e) =>
                                        setNewGroupTitle(e.target.value)
                                    }
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    onClick={() => {
                                                        setNewGroupTitle("")
                                                        setAddingNew(false)
                                                    }}
                                                    size="small"
                                                >
                                                    <Clear fontSize="small" />
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </Box>
                        ) : (
                            <Button
                                onClick={() => setAddingNew(true)}
                                style={{
                                    width: "100%",
                                }}
                                startIcon={<Add />}
                            >
                                Add Group
                            </Button>
                        )}
                        <Tooltip
                            title={
                                gridCompactType === 0
                                    ? t(
                                          "compactGroupingVertically",
                                          "Compacting Vertical: Click to change to Horizontal"
                                      )
                                    : gridCompactType === 1
                                    ? t(
                                          "compactGroupingHorizontally",
                                          "Compacting Horizontal: Click to change to Freeform"
                                      )
                                    : t(
                                          "compactGroupingFreeform",
                                          "Freeform: Click to compact vertically"
                                      )
                            }
                        >
                            <IconButton
                                onClick={() =>
                                    setGridCompactType((i) =>
                                        i === 2 ? 0 : i + 1
                                    )
                                }
                            >
                                <DashboardIcon
                                    style={{
                                        borderTop:
                                            gridCompactType === 0 &&
                                            "solid 1px",
                                        borderLeft:
                                            gridCompactType === 1 &&
                                            "solid 1px",
                                    }}
                                />
                            </IconButton>
                        </Tooltip>
                        <Tooltip
                            title={t(
                                "applyLayoutToAllNewResponses",
                                "Apply layout to all new responses"
                            )}
                        >
                            <IconButton onClick={onMakeStandard}>
                                <PlaylistAddCheckIcon />
                            </IconButton>
                        </Tooltip>
                    </Box>
                </>
            )}
        </Box>
    )
}

export default DroppableGroups
