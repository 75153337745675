import React, { useEffect, useState } from "react"
import { makeStyles, Theme } from "@material-ui/core/styles"
import { Box, IconButton } from "@material-ui/core"
import { useMutation, useReactiveVar } from "@apollo/client"
import Dialog from "@material-ui/core/Dialog"
import DialogContent from "@material-ui/core/DialogContent"
import CloseIcon from "@material-ui/icons/Close"
import { UPDATE_CONCEPT } from "../../graphql/mutations"
import EditIcon from "@material-ui/icons/Edit"
import DeleteForeverIcon from "@material-ui/icons/DeleteForever"

import { useAuth } from "../../providers/AuthProvider"
import ImageCropper from "./ImageCropper"
import {
    UpdateConceptMutation,
    UpdateConceptMutationVariables,
} from "../../graphql/__generated__/mutations"
import { CONCEPT_BY_ID } from "../../graphql/queries"
import { Concept } from "../../__generated__/types"
import { userConceptPermissionsVar } from "../../providers/GlobalState"
import useImageTools from "./useImageTools"

const useStyles = makeStyles((theme: Theme) => ({
    dialogContent: {
        overflow: "hidden",
        display: "flex",
        flexDirection: "column",
        [theme.breakpoints.up("sm")]: {
            minHeight: "70vh",
        },
        height: "80vh",
        width: "100%",
        padding: `0 !important`,
    },
    image: {
        objectFit: "contain",
        borderRadius: theme.shape.borderRadius,
        border: `1px solid ${theme.palette.divider}`,
        height: "100%",
    },
    imageContainer: {
        textAlign: "center",
        [theme.breakpoints.up("sm")]: {
            height: "70vh",
        },
        padding: theme.spacing(2),
    },
    loadingFallback: {
        position: "absolute",
        left: "calc(50%)",
        top: "calc(50%)",
    },
}))

interface ImageUploadProps {
    item?: Concept
    onClose: () => void

    onImageUploaded?: (imageUrl: string) => void
}
export default function ImageUploadDialog(props: ImageUploadProps) {
    const classes = useStyles()
    const { currentUser } = useAuth()
    const { onUploadPhoto } = useImageTools()
    const [editing, setEditing] = useState(false)
    const [imageUrl, setImageUrl] = useState(null)
    const editPermission = useReactiveVar(
        userConceptPermissionsVar
    ).editingPermission
    const [updateConcept] = useMutation<
        UpdateConceptMutation,
        UpdateConceptMutationVariables
    >(UPDATE_CONCEPT, {
        refetchQueries: [
            {
                query: CONCEPT_BY_ID,
                variables: {
                    id: props.item?.id,
                },
            },
        ],
        awaitRefetchQueries: true,
    })
    useEffect(() => {
        if (props.item?.imageUrl) {
            setImageUrl(props.item?.imageUrl)
        } else if (!!editPermission) {
            setEditing(true)
        }
    }, [props.item?.imageUrl, editPermission])

    const uploadImage = async (file, imageName) => {
        const imageUrl = await onUploadPhoto(file, imageName)

        if (imageUrl) {
            if (!!props.item) {
                await updateConcept({
                    variables: {
                        userId: currentUser.userId,
                        concept: {
                            id: props.item?.id,
                            imageUrl,
                        },
                    },
                })

                setImageUrl(imageUrl)
            } else if (!!props.onImageUploaded) {
                props.onImageUploaded(imageUrl)
            }
        }

        setEditing(false)
    }
    const removeImage = async () => {
        if (!!props.item) {
            await updateConcept({
                variables: {
                    userId: currentUser.userId,
                    concept: {
                        id: props.item?.id,
                        imageUrl: null,
                    },
                },
            })
            await setImageUrl(null)
            props.onClose()
        }
    }

    return (
        <Dialog
            open={true}
            onBackdropClick={() => {
                props.onClose()
            }}
            fullWidth
            maxWidth="md"
        >
            <DialogContent
                className={classes.dialogContent}
                style={{
                    justifyContent: "flex-start",
                }}
            >
                {!editing ? (
                    <Box>
                        <Box
                            p={1}
                            alignItems="center"
                            display="flex"
                            justifyContent="flex-end"
                        >
                            {!!editPermission && (
                                <>
                                    <IconButton onClick={() => removeImage()}>
                                        <DeleteForeverIcon />
                                    </IconButton>

                                    <IconButton
                                        onClick={() => setEditing(true)}
                                    >
                                        <EditIcon />
                                    </IconButton>
                                </>
                            )}
                            <IconButton onClick={() => props.onClose()}>
                                <CloseIcon />
                            </IconButton>
                        </Box>

                        <Box className={classes.imageContainer}>
                            <img
                                className={classes.image}
                                src={imageUrl}
                                alt={""}
                            />
                        </Box>
                    </Box>
                ) : (
                    <ImageCropper
                        onDone={uploadImage}
                        onCancel={() => {
                            setEditing(false)
                        }}
                        imageId={props.item?.id ?? currentUser?.userId}
                        currentImageUrl={props.item?.imageUrl ?? null}
                    />
                )}
            </DialogContent>
        </Dialog>
    )
}
