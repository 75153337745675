import { NetworkStatus, useQuery } from "@apollo/client"
import { Box } from "@material-ui/core"
import { FULL_CONCEPT_FILTERED } from "../graphql/queries"
import { Concept, _ConceptFilter } from "../__generated__/types"
import {
    FullConceptFilteredQuery,
    FullConceptFilteredQueryVariables,
} from "../graphql/__generated__/queries"
import { useMemo } from "react"
import GraphView, { getConceptTooltip } from "./GraphView"
import { VIEW_OPTIONS } from "../providers/GlobalState"
import { useTheme } from "@material-ui/core/styles"
import LoadingOverlayWrapper from "./LoadingOverlayWrapper"
import useAwaitTranslation from "../i18n/useAwaitTranslation"

export default function ConnectionsGraphView(props: {
    concept: Concept
    filter: _ConceptFilter
    displayType: VIEW_OPTIONS
    onConceptClick: (concept: Concept) => void
}) {
    const { concept, filter, displayType, onConceptClick } = props
    const { data, networkStatus } = useQuery<
        FullConceptFilteredQuery,
        FullConceptFilteredQueryVariables
    >(FULL_CONCEPT_FILTERED, {
        variables: {
            filter,
        },
        fetchPolicy: "network-only",
    })
    const connections: Concept[] = data?.Concept ?? null
    const { i18n } = useAwaitTranslation("connectionPreview")
    const selectedLangString = i18n?.language
    const theme = useTheme()

    const formattedData = useMemo(() => {
        const nodes: {
            id: string
            title: string
            category: string
            imageUrl: string | null
            __typename: "Concept" | "Category"
            fontSize?: number
            tooltip?: string
        }[] = [
            {
                id: concept.id,
                title:
                    (concept?.translations.filter(
                        (t) => t.languageString === selectedLangString
                    )[0]?.title || concept.title) +
                    ` (${concept.category?.name})`,
                category: concept.category?.name,
                imageUrl: concept.imageUrl,
                __typename: concept.__typename,
                fontSize: 30,
                tooltip: getConceptTooltip(
                    concept?.translations.filter(
                        (t) => t.languageString === selectedLangString
                    )[0]?.title || concept.title,
                    `${concept.createdBy?.firstName} ${concept.createdBy?.lastName}`,
                    concept.category?.name,
                    concept?.translations.filter(
                        (t) => t.languageString === selectedLangString
                    )[0]?.summary || concept.summary,
                    theme
                ),
            },
        ]
        const links = []
        if (!connections) {
            return { nodes, links }
        }

        connections.forEach((connection) => {
            nodes.push({
                id: connection.id,
                title:
                    (connection?.translations.filter(
                        (t) => t.languageString === selectedLangString
                    )[0]?.title || connection.title) +
                    ` (${connection.category?.name})`,
                category: connection.category?.name,
                imageUrl: connection.imageUrl,
                __typename: connection.__typename,
                tooltip: getConceptTooltip(
                    connection?.translations.filter(
                        (t) => t.languageString === selectedLangString
                    )[0]?.title || connection.title,
                    `${connection.createdBy?.firstName} ${connection.createdBy?.lastName}`,
                    connection.category?.name,
                    connection?.translations.filter(
                        (t) => t.languageString === selectedLangString
                    )[0]?.summary || connection.summary,
                    theme
                ),
            })
            if (!!connection?.category) {
                if (
                    !nodes.find(
                        (node) =>
                            node.id === connection.category?.id &&
                            node.__typename === "Category"
                    )
                ) {
                    nodes.push({
                        id: connection.category.id,
                        title: connection.category.name,
                        category: connection.category.name,
                        imageUrl: null,
                        __typename: connection.category.__typename,
                    })
                    links.push({
                        target: concept.id,
                        source: connection.category.id,
                        particleCount:
                            connections.filter(
                                (c) =>
                                    c.category?.id === connection.category?.id
                            )?.length || 1,
                    })
                }
                links.push({
                    target: connection.category.id,
                    source: connection.id,
                    particleCount: 1,
                })
            }
        })

        const nodeIds = []
        return {
            nodes: nodes.filter((n) =>
                nodeIds.indexOf(n.id) === -1 ? nodeIds.push(n.id) : false
            ),
            links,
        }
    }, [
        connections,
        //deconstructing dependencies so graph doesnt double render when a new connection is made
        concept.id,
        concept.title,
        concept.summary,
        concept.__typename,
        concept.category?.name,
        concept.imageUrl,
        theme,
        concept.createdBy?.firstName,
        concept.createdBy?.lastName,
        concept.translations,
        selectedLangString,
    ])

    return (
        <Box height="100%" width="100%" overflow="hidden" display={"flex"}>
            <LoadingOverlayWrapper
                loading={
                    !connections || networkStatus === NetworkStatus.setVariables
                }
            >
                <GraphView
                    onNodeClick={(node) => {
                        const connection = connections?.find(
                            (concept) => concept.id === node?.id
                        )

                        if (node.id !== concept.id && !!connection) {
                            onConceptClick(connection)
                        }
                    }}
                    formattedData={formattedData}
                    viewMode={displayType}
                />
            </LoadingOverlayWrapper>
        </Box>
    )
}
