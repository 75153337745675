import * as React from "react"
import { useState } from "react"
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles"
import Popper, { PopperPlacementType } from "@material-ui/core/Popper"
import Paper from "@material-ui/core/Paper"

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        popper: {
            zIndex: 1,
        },
    })
)

interface PopperProps {
    child: React.ReactNode
    content: React.ReactNode
    position?: PopperPlacementType
    transition?: boolean
    disablePopper?: boolean
}

const PopperTooltip: React.FunctionComponent<PopperProps> = ({
    child,
    content,
    position = "top",
    transition = true,
    disablePopper = false,
}) => {
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)
    const [open, setOpen] = useState<boolean>(false)

    const classes = useStyles()

    const handleMouseEnter = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget)
        setOpen(true)
    }

    const handleMouseLeave = () => {
        setAnchorEl(null)
        setOpen(false)
    }

    return (
        <>
            <div
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
            >
                {child}
            </div>
            <Popper
                open={open && !disablePopper}
                anchorEl={anchorEl}
                className={classes.popper}
                placement={position}
                transition={transition}
                disablePortal
            >
                <Paper>{content}</Paper>
            </Popper>
        </>
    )
}

export default PopperTooltip
