import {
    Box,
    Button,
    Divider,
    List,
    ListItem,
    ListItemText,
    Paper,
    Slide,
    TextField,
    ListItemSecondaryAction,
    CircularProgress,
    IconButton,
    InputAdornment,
    Collapse,
    ListItemIcon,
    Icon,
} from "@material-ui/core"
import { useState } from "react"
import WorkflowForm from "./WorkflowForm"
import { Workflow, WorkflowStage } from "../../__generated__/types"
import { Loading } from "../Loading"
import { useTheme } from "@material-ui/core/styles"

import useWorkflowTools from "./useWorkflowTools"
import Clear from "@material-ui/icons/Clear"
import Check from "@material-ui/icons/Check"
import Add from "@material-ui/icons/Add"
import ExpandMore from "@material-ui/icons/ExpandMore"
import WorkflowStageForm from "./WorkflowStageForm"
export default function WorkflowsManager() {
    const [focusedStage, setFocusedStage] = useState<WorkflowStage>(null)
    const {
        isInitialized,
        allSubWorkflows,
        refetch: refetchWorkflows,
        environmentWorkflow,
    } = useWorkflowTools({ fetch: true })

    const [openForm, setOpenForm] = useState(false)

    if (!isInitialized) {
        return <Loading size={25} hideQuote={true} />
    }

    return (
        <Box
            height="100%"
            width="100%"
            overflow="hidden"
            p={2}
            flexDirection="column"
            display="flex"
        >
            <Box display="flex" pt={2} pb={2} justifyContent="flex-end">
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => setOpenForm(true)}
                >
                    Add Workflow
                </Button>
            </Box>
            <Paper
                style={{
                    width: "100%",
                    height: "100%",
                    overflow: "hidden",
                    display: "flex",
                }}
            >
                <Box
                    height="100%"
                    style={{ overflowY: "auto" }}
                    flexShrink={0}
                    width={!!focusedStage ? "50%" : "100%"}
                >
                    <List>
                        <IndividualWorkflow
                            focusedStage={focusedStage}
                            workflow={environmentWorkflow}
                            onStageFocus={setFocusedStage}
                        />
                        {allSubWorkflows.map((workflow, index) => {
                            return (
                                <IndividualWorkflow
                                    key={workflow.id}
                                    focusedStage={focusedStage}
                                    workflow={workflow}
                                    onStageFocus={setFocusedStage}
                                    onAddStage={async () => {
                                        await refetchWorkflows()
                                    }}
                                />
                            )
                        })}
                    </List>
                </Box>

                {!!focusedStage && (
                    <Slide
                        in={!!focusedStage}
                        direction="left"
                        mountOnEnter
                        unmountOnExit
                    >
                        <Box
                            width="50%"
                            display="flex"
                            key={focusedStage.id}
                            overflow="hidden"
                        >
                            <Divider orientation="vertical" />
                            <WorkflowStageForm
                                stage={focusedStage}
                                onClose={() => {
                                    setFocusedStage(null)
                                }}
                                onDeletion={() => {
                                    setFocusedStage(null)
                                }}
                            />
                        </Box>
                    </Slide>
                )}
            </Paper>
            {!!openForm && (
                <WorkflowForm
                    onSave={async () => {
                        await refetchWorkflows()
                    }}
                    onClose={() => setOpenForm(false)}
                />
            )}
        </Box>
    )
}

const IndividualWorkflow = (props: {
    workflow: Workflow
    onStageFocus: (stage: WorkflowStage) => void
    focusedStage: WorkflowStage
    onAddStage?: () => Promise<void>
}) => {
    const [addingStage, setAddingStage] = useState(false)
    const [creatingNewStage, setCreatingNewStage] = useState(false)
    const [newStageTitle, setNewStageTitle] = useState("")
    const theme = useTheme()
    const [formOpen, setFormOpen] = useState(false)
    const [expanded, setExpanded] = useState(false)
    const {
        getOrderedStages,
        createWorkflowStage,
        addNextStageOption,
        addWorkflowStage,
    } = useWorkflowTools({ fetch: false })
    const { workflow, onStageFocus, focusedStage, onAddStage } = props
    const orderedStages = getOrderedStages(workflow.stages)
    const handleNewStageCreation = async () => {
        setCreatingNewStage(true)
        const {
            data: { CreateWorkflowStage: NewWorkflowStage },
        } = await createWorkflowStage({
            variables: {
                title: newStageTitle,
                isFirstStage: workflow.stages.length === 0,
            },
        })
        await addWorkflowStage({
            variables: {
                workflowId: workflow.id,
                stageId: NewWorkflowStage.id,
            },
        })
        if (workflow.stages.length > 0) {
            await addNextStageOption({
                variables: {
                    firstStageId:
                        workflow.stages[workflow.stages.length - 1]?.id,
                    nextStageId: NewWorkflowStage.id,
                },
            })
        }
        await onAddStage()
        setAddingStage(false)
        setNewStageTitle("")
        setCreatingNewStage(false)
    }

    return (
        <Box display="flex" flexDirection={"column"} width="100%">
            <ListItem button onClick={() => setFormOpen(true)}>
                <ListItemText
                    primary={workflow.title}
                    secondary={workflow.description}
                />
                <ListItemSecondaryAction>
                    <IconButton
                        size="small"
                        onClick={() => setExpanded((prev) => !prev)}
                    >
                        <ExpandMore />
                    </IconButton>
                </ListItemSecondaryAction>
            </ListItem>
            <Collapse in={expanded}>
                <Box
                    m={2}
                    border={`1px solid ${theme.palette.divider}`}
                    borderRadius={theme.shape.borderRadius}
                >
                    <List dense style={{ width: "100%" }} disablePadding>
                        {orderedStages.map((stage, index) => {
                            const icon = JSON.parse(stage?.icon ?? "{}")
                            return (
                                <ListItem
                                    key={stage.id}
                                    button
                                    onClick={() => {
                                        onStageFocus(stage)
                                    }}
                                    selected={focusedStage?.id === stage.id}
                                >
                                    {!!icon?.name && (
                                        <ListItemIcon
                                            style={{
                                                minWidth: theme.spacing(4),
                                            }}
                                        >
                                            <Icon fontSize="small">
                                                {icon.name}
                                            </Icon>
                                        </ListItemIcon>
                                    )}
                                    <ListItemText
                                        primary={stage.title}
                                        secondary={stage.description}
                                    />
                                </ListItem>
                            )
                        })}
                        {!!creatingNewStage ? (
                            <Box
                                display="flex"
                                width="100%"
                                justifyContent={"center"}
                                p={1}
                            >
                                <CircularProgress
                                    size={20}
                                    disableShrink={true}
                                />
                            </Box>
                        ) : (
                            !!onAddStage && (
                                <Box width="100%" display={"flex"} p={2}>
                                    {!!addingStage ? (
                                        <Box display="flex" flexGrow={1}>
                                            <TextField
                                                value={newStageTitle}
                                                label={"New Stage Title"}
                                                onChange={(e) =>
                                                    setNewStageTitle(
                                                        e.target.value
                                                    )
                                                }
                                                size="small"
                                                fullWidth
                                                autoFocus
                                                variant="outlined"
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            <Box display="flex">
                                                                <IconButton
                                                                    size="small"
                                                                    onClick={() => {
                                                                        setAddingStage(
                                                                            false
                                                                        )
                                                                        setNewStageTitle(
                                                                            ""
                                                                        )
                                                                    }}
                                                                >
                                                                    <Clear />
                                                                </IconButton>
                                                                <IconButton
                                                                    size="small"
                                                                    disabled={
                                                                        newStageTitle.length ===
                                                                        0
                                                                    }
                                                                    onClick={
                                                                        handleNewStageCreation
                                                                    }
                                                                >
                                                                    <Check />
                                                                </IconButton>
                                                            </Box>
                                                        </InputAdornment>
                                                    ),
                                                }}
                                            />
                                        </Box>
                                    ) : (
                                        <Button
                                            startIcon={<Add />}
                                            size="small"
                                            style={{ width: "100%" }}
                                            onClick={() => setAddingStage(true)}
                                        >
                                            Add Stage
                                        </Button>
                                    )}
                                </Box>
                            )
                        )}
                    </List>
                </Box>
            </Collapse>
            {!!formOpen && (
                <WorkflowForm
                    workflow={workflow}
                    onClose={() => setFormOpen(false)}
                />
            )}
        </Box>
    )
}
