import React, { useState, useCallback, useEffect } from "react"
import List from "@material-ui/core/List"
import { Box } from "@material-ui/core"
import { NetworkStatus, useQuery } from "@apollo/client"
import InfiniteScroll from "react-infinite-scroller"
import CircularProgress from "@material-ui/core/CircularProgress"
import { CONNECTION_WIDGET_QUERY } from "../../../graphql/queries"
import {
    ConnectionWidgetQueryQuery,
    ConnectionWidgetQueryQueryVariables,
} from "../../../graphql/__generated__/queries"
import { Loading } from "../../Loading"
import {
    Concept,
    _ConceptFilter,
    _ConceptOrdering,
} from "../../../__generated__/types"
import ConceptListItem from "../../ConceptListItem"
import ErrorHandler from "../../ErrorHandler"
import useMountedState from "../../../util/useMountedState"

const PAGE_SIZE = 10
export default function ListView(props: {
    filter: _ConceptFilter
    orderBy: _ConceptOrdering
    fixedItems?: Concept[]
    skipQuery?: boolean
}) {
    const isMounted = useMountedState()
    const [loadMore, setLoadMore] = useState(true)
    const [limit, setLimit] = useState(PAGE_SIZE)
    const [filter, setFilter] = useState<_ConceptFilter>({})
    const { error, data, fetchMore, networkStatus } = useQuery<
        ConnectionWidgetQueryQuery,
        ConnectionWidgetQueryQueryVariables
    >(CONNECTION_WIDGET_QUERY, {
        variables: {
            offset: 0,
            first: limit,
            connectionsFilter: filter,
            orderBy: [props.orderBy],
        },
        fetchPolicy: "cache-and-network",
        nextFetchPolicy: "cache-first",
        skip: !!props.skipQuery,
    })
    const listData = data?.Concept ?? null
    useEffect(() => {
        if (JSON.stringify(props.filter) !== JSON.stringify(filter)) {
            setFilter(props.filter)
            setLimit(PAGE_SIZE)
            setLoadMore(true)
        }
    }, [props.filter, filter])
    useEffect(() => {
        if ((!!data && listData?.length === 0) || !!props.skipQuery) {
            setLoadMore(false)
        }
    }, [listData?.length, data, props.skipQuery])

    const onLoadMore = useCallback(
        async (page) => {
            if (!fetchMore) return
            const result = await fetchMore({
                variables: {
                    offset: page * PAGE_SIZE,
                },
            })
            if (isMounted()) {
                setLimit(page * PAGE_SIZE + PAGE_SIZE)
                setLoadMore(!(result.data.Concept.length < page * PAGE_SIZE))
            }
        },
        [fetchMore, isMounted]
    )
    if (error) {
        return <ErrorHandler message={error.message} showMessage={false} />
    }

    return (
        <Box
            display="flex"
            flexDirection="column"
            height="100%"
            overflow="hidden"
            width="100%"
        >
            {networkStatus === NetworkStatus.loading ? (
                <Loading size={25} hideQuote={true} />
            ) : (
                <List style={{ overflowY: "auto" }} disablePadding>
                    <InfiniteScroll
                        pageStart={0}
                        loadMore={onLoadMore}
                        hasMore={loadMore}
                        loader={
                            <div
                                key={0}
                                style={{ padding: 4, textAlign: "center" }}
                            >
                                <CircularProgress disableShrink size={25} />
                            </div>
                        }
                        useWindow={false}
                    >
                        {!!props.fixedItems &&
                            props.fixedItems.map((item, idx) => {
                                return (
                                    <Box key={item.id}>
                                        <ConceptListItem
                                            item={item}
                                            showSummary={true}
                                            hideCategory={true}
                                            hideDivider={true}
                                        />
                                    </Box>
                                )
                            })}
                        {listData?.map((item, idx) => {
                            return (
                                <Box key={item.id}>
                                    <ConceptListItem
                                        item={item}
                                        showSummary={true}
                                        hideCategory={true}
                                        hideDivider={true}
                                    />
                                </Box>
                            )
                        })}
                    </InfiniteScroll>
                </List>
            )}
        </Box>
    )
}
