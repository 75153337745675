import { useLazyQuery } from "@apollo/client"
import {
    Dialog,
    IconButton,
    DialogContent,
    Grow,
    Tooltip,
} from "@material-ui/core"
import { _ConceptFilter } from "../../__generated__/types"
import { CONCEPTS_TO_CSV } from "./graphql"
import GetAppIcon from "@material-ui/icons/GetApp"
import { Loading } from "../Loading"
import useMountedState from "../../util/useMountedState"
import { useEffect, useState } from "react"
import DoneOutlineIcon from "@material-ui/icons/DoneOutline"
import useAwaitTranslation from "../../i18n/useAwaitTranslation"
import {
    ConceptsToCsvQuery,
    ConceptsToCsvQueryVariables,
} from "./__generated__/graphql"
import { formatCsvData } from "./util"

interface ConceptsToCsvProps {
    filter: _ConceptFilter
}

const ConceptsToCsv: React.FunctionComponent<ConceptsToCsvProps> = ({
    filter,
}) => {
    const isMounted = useMountedState()
    const { t } = useAwaitTranslation("concepts")
    const [fetchConcepts, { loading }] = useLazyQuery<
        ConceptsToCsvQuery,
        ConceptsToCsvQueryVariables
    >(CONCEPTS_TO_CSV)
    const { i18n } = useAwaitTranslation()
    const [recentlyExported, setRecentlyExported] = useState(false)
    const [langs, setLangs] = useState<{
        [key in string]: { name: string }
    }>({ en: { name: "English" } })

    useEffect(() => {
        i18n.services.backendConnector.backend.getLanguages(
            (_: any, ret: typeof langs) => {
                if (ret) {
                    setLangs(ret)
                }
            }
        )
    }, [i18n])

    const handleClick = async () => {
        const {
            data: { concepts, categories },
        } = await fetchConcepts({
            variables: {
                filter: {
                    ...filter,
                },
            },
            fetchPolicy: "network-only",
        })
        const items = concepts.map((concept) => {
            return {
                concept,
                dashboard:
                    concept.homeDashboard ||
                    categories?.find((o) => o.id === concept.category?.id)
                        ?.defaultDashboard ||
                    null,
            }
        })

        const json = formatCsvData(items, categories, langs)
        let fields = Object.keys(json[0])
        let replacer = function (_, value) {
            return value === null ? "" : value
        }

        let csv = json.map(function (row) {
            return fields
                .map(function (fieldName) {
                    return JSON.stringify(row[fieldName], replacer)
                })
                .join(",")
        })

        csv.unshift(fields.join(","))

        const parsedCsv = "data:text/csv;charset=utf-8," + csv.join("\r\n")

        const encodedUri = encodeURI(parsedCsv)

        const link = document.createElement("a")
        link.setAttribute("href", encodedUri)
        link.setAttribute("download", "amble_export.csv")
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
        if (!!isMounted()) {
            setRecentlyExported(true)
        }

        setTimeout(() => {
            if (!!isMounted()) {
                setRecentlyExported(false)
            }
        }, 5000)
    }

    return (
        <>
            <Tooltip
                title={
                    !!recentlyExported
                        ? t("exportSuccessful", "Export successful")
                        : t("exportFeedDataToCSV", "Export feed data to CSV")
                }
            >
                <IconButton onClick={handleClick}>
                    {!!recentlyExported ? (
                        <Grow in={true} mountOnEnter unmountOnExit>
                            <DoneOutlineIcon color="primary" />
                        </Grow>
                    ) : (
                        <GetAppIcon />
                    )}
                </IconButton>
            </Tooltip>
            <Dialog open={!!loading} fullWidth>
                <DialogContent>
                    <Loading
                        message={"Exporting to CSV..."}
                        size={25}
                        hideQuote={true}
                    />
                </DialogContent>
            </Dialog>
        </>
    )
}

export default ConceptsToCsv
