import { useMutation } from "@apollo/client"
import {
    AppBar,
    Box,
    Hidden,
    IconButton,
    makeStyles,
    Toolbar,
    useTheme,
    alpha,
    Typography,
    Chip,
} from "@material-ui/core"
import useMediaQuery from "@material-ui/core/useMediaQuery"
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer"
import MenuIcon from "@material-ui/icons/Menu"
import PostAddIcon from "@material-ui/icons/PostAdd"
import SearchIcon from "@material-ui/icons/Search"
import React, { useContext, useEffect, useState } from "react"
import { useHistory } from "react-router-dom"
import { useAuth } from "../providers/AuthProvider"
import HeaderAvatarMenu from "./HeaderAvatarMenu"
import HeaderNotifications from "./notifications/HeaderNotifications"
import LanguagePicker from "./LanguagePicker"
import { UPDATE_USER } from "./users/graphql"
import WarningIcon from "@material-ui/icons/Warning"
import Logo from "./Logo"
import QuickSearch from "./QuickSearch"
import { DispatchContext } from "./themes/ThemeContext"
import {
    UpdateUserMutation,
    UpdateUserMutationVariables,
} from "./users/__generated__/graphql"
import { ThemeType } from "../__generated__/types"
import useEnvironmentSettingTools from "./settings/useEnvironmentSettingTools"

const useStyles = makeStyles((theme) => ({
    appBar: {
        // zIndex: theme.zIndex.SwipeableDrawer + 101,
        background: theme.palette.background.paper,
        "@media print": {
            display: "none",
        },
    },
    toolbar: {
        width: "100vw",
        padding: "0 12px",
        display: "flex",
        justifyContent: "space-between",
        flexWrap: "nowrap",
        backgroundColor: alpha(theme.palette.primary.main, 0.15),
        height: theme.spacing(8),
    },
    settings: {
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        width: "100%",
    },
    logo: {
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
    },
    swipeableSearch: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        padding: theme.spacing(1),
        height: theme.spacing(8),
    },
}))

interface AppHeaderProps {
    toggleMenu: () => void
    showFormDrawer: () => void
    toggleWorkspaceDrawer: () => void
}
export default function AppHeader(props: AppHeaderProps) {
    const classes = useStyles()
    const theme = useTheme()
    const mobile = useMediaQuery(theme.breakpoints.down("sm"))
    const { currentUser, logout, hasAccess, isNonMember } = useAuth()
    const history = useHistory()

    const [updateThemeType] = useMutation<
        UpdateUserMutation,
        UpdateUserMutationVariables
    >(UPDATE_USER)
    const dispatch = useContext(DispatchContext)
    const [mobileSearch, showMobileSearch] = useState(false)

    //TODO: Possibly refactor to const isLanguagePickerVisible = (currentUser?.language ?? null) === null to remove the state & effect
    const [languagePickerVisible, setLanguagePickerVisible] =
        React.useState(false)

    const { ableToCreate } = useEnvironmentSettingTools()

    const darkModeToggle = (makeDarkMode) => {
        const flipTheme = (themeType) =>
            themeType === "dark" ? "light" : "dark"
        const type =
            makeDarkMode === undefined
                ? flipTheme(theme.palette.type)
                : makeDarkMode
                ? "dark"
                : "light"
        updateThemeType({
            variables: {
                userId: currentUser.userId,
                activeThemeType: type?.toUpperCase() as ThemeType,
            },
        }).then((result) => {
            //@ts-ignore
            dispatch({
                type: "CHANGE",
                payload: { paletteType: type },
            })
        })
    }

    const handleDarkModeToggle = (event) => {
        event.target.value = !!event.target.checked
        darkModeToggle(event.target.checked)
    }
    const handleLogout = async () => {
        try {
            await logout()
            history.push("/login")
        } catch {
            console.log("Failed to log out")
        }
    }

    useEffect(() => {
        if (currentUser?.language) {
            setLanguagePickerVisible(false) //Don't show if user has chosen language
        } else if (currentUser?.language === null) {
            setLanguagePickerVisible(true) //Show if user language unknown
        }
    }, [currentUser?.language])

    if (!!currentUser && !!hasAccess) {
        return (
            <AppBar position="fixed" className={classes.appBar}>
                <Toolbar className={classes.toolbar}>
                    <Box className={classes.logo}>
                        {!isNonMember && (
                            <IconButton
                                style={{ minWidth: "fit-content" }}
                                onClick={props.toggleWorkspaceDrawer}
                            >
                                <MenuIcon />
                            </IconButton>
                        )}

                        <Hidden smDown>
                            <Logo />
                        </Hidden>
                        {!!process.env.REACT_APP_NON_PROD_INSTANCE_NAME && (
                            <Chip
                                icon={
                                    <WarningIcon
                                        style={{
                                            color: theme.palette.error
                                                .contrastText,
                                        }}
                                        fontSize="small"
                                    />
                                }
                                style={{
                                    marginLeft: 10,
                                    backgroundColor: theme.palette.error.main,
                                    color: theme.palette.error.contrastText,
                                }}
                                label={
                                    <Typography>
                                        {
                                            process.env
                                                .REACT_APP_NON_PROD_INSTANCE_NAME
                                        }
                                    </Typography>
                                }
                            />
                        )}
                    </Box>

                    <Box className={classes.settings}>
                        {!isNonMember &&
                            (mobile ? (
                                <IconButton
                                    onClick={() => {
                                        showMobileSearch(true)
                                    }}
                                >
                                    <SearchIcon />
                                </IconButton>
                            ) : (
                                <QuickSearch />
                            ))}
                        {mobile && mobileSearch && (
                            <SwipeableDrawer
                                anchor={"top"}
                                open={mobileSearch}
                                onClose={() => showMobileSearch(false)}
                                onOpen={() => showMobileSearch(true)}
                                classes={{
                                    paper: classes.swipeableSearch,
                                }}
                            >
                                <QuickSearch autoOpen={true} />
                            </SwipeableDrawer>
                        )}
                        {languagePickerVisible && (
                            <LanguagePicker
                                mobile={mobile}
                                user={currentUser}
                            />
                        )}

                        {!!ableToCreate && !isNonMember && (
                            <IconButton onClick={props.showFormDrawer}>
                                <PostAddIcon />
                            </IconButton>
                        )}
                        {!isNonMember && <HeaderNotifications />}
                        <HeaderAvatarMenu
                            theme={theme}
                            handleDarkModeToggle={handleDarkModeToggle}
                            handleLogout={handleLogout}
                            history={history}
                            currentUser={currentUser}
                            languagePickerVisible={languagePickerVisible}
                            setLanguagePickerVisible={setLanguagePickerVisible}
                        />
                    </Box>
                </Toolbar>
            </AppBar>
        )
    }
    return null
}
