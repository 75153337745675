import { makeStyles, withStyles, styled } from "@material-ui/core/styles"

const useStyles = makeStyles((theme) => ({
    bounce: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: theme.spacing(2),
        "&& div:nth-child(2)": {
            animationDelay: "0.5s",
        },
        "&& div:nth-child(3)": {
            animationDelay: "1s",
        },
    },
}))

export default function DotsLoading() {
    const classes = useStyles()
    return (
        <div className={classes.bounce}>
            <PulsatingCircle />
            <PulsatingCircle />
            <PulsatingCircle />
        </div>
    )
}

const PulsatingCircleComponent = styled("div")(({ theme }) => ({
    background: theme.palette.primary.main,
    borderRadius: "100%",
    animation: "pulsate 1.5s infinite ease",
    width: theme.spacing(1),
    height: theme.spacing(1),
    margin: "3px 6px",
    backgroundColor: theme.palette.grey[500],
}))

const PulsatingCircle = withStyles({
    "@global @keyframes pulsate": {
        from: {
            opacity: 1,
            transform: "scale(1)",
        },
        to: {
            opacity: 0,
            transform: "scale(2)",
        },
    },
})(() => {
    return <PulsatingCircleComponent />
})
