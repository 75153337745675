import { Box, Paper } from "@material-ui/core"
import {
    _ConceptOrdering,
    _CriteriaScoreFilter,
} from "../../../__generated__/types"
import WidgetHeader from "./common/WidgetHeader"
import useWidget from "../useWidget"
import { WidgetTypeComponent } from "../useWidgetTypes"
import { FilterItem } from "../../filters/util/types"
import WidgetFilterToggle from "./common/WidgetFilterToggle"
import useFilters from "../../filters/util/useFilters"
import { useQuery } from "@apollo/client"
import { CONCEPT_WITH_CRITERIA } from "../../criteria/graphql"
import {
    ConceptWithCriteriaQuery,
    ConceptWithCriteriaQueryVariables,
} from "../../criteria/__generated__/graphql"
import { useParams } from "react-router-dom"
import { Loading } from "../../Loading"
import BubbleChart from "../../charts/BubbleChart"
import { useState } from "react"
type WidgetConfig = {
    conceptId: string
    label: string
    orderBy: _ConceptOrdering
    filters?: FilterItem[]
    source: string
    criteriaIds: string[]
}

const BubbleChartWidget: WidgetTypeComponent = (props) => {
    //hooks

    const { config, isInitialized } = useWidget<WidgetConfig>(props.widget.id)
    const { conceptId } = useParams()
    const [hovered, setHovered] = useState(false)
    const { currentFeedVariables, setCurrentFeedVariables, filterObject } =
        useFilters({
            viewPrivate: true,
            filters: config?.filters,
            connectionsConceptId:
                config?.source === "connections" ? conceptId : null,
        })

    let criteriaScoreFilter: _CriteriaScoreFilter = {
        criteria: {
            id_in: config?.criteriaIds ?? [],
        },
        conceptDefault: true,
    }
    //throws apollo error if nested concept filter is given empty filter variable so needing to check
    if (Object.keys(filterObject).length > 0) {
        criteriaScoreFilter = {
            ...criteriaScoreFilter,
            scoredConcept: filterObject,
        }
    }

    ///queries
    const { data, error } = useQuery<
        ConceptWithCriteriaQuery,
        ConceptWithCriteriaQueryVariables
    >(CONCEPT_WITH_CRITERIA, {
        variables: {
            conceptFilter: filterObject,
            criteriaFilter: {
                id_in: config?.criteriaIds ?? [],
            },
            criteriaScoreFilter: criteriaScoreFilter,
        },
        skip: !config,
    })

    // variables for data
    const criteria = data?.Criteria ?? []
    const concepts = data?.Concept ?? []

    let bubbleChartData = concepts
        .map((item) => {
            return {
                item: item,
                values:
                    criteria.map((criteria) => {
                        const responseValue = Number(
                            item?.criteriaScores?.filter(
                                (x) => x.criteria?.name === criteria.name
                            )?.[0]?.response ?? "0"
                        )
                        return {
                            criteriaName: criteria.name,
                            value: responseValue > 10 ? 10 : responseValue,
                        }
                    }) ?? [],
            }
        })
        ?.filter((x) => x.values.filter((o) => o.value !== 0)?.length > 0)

    if (error) {
        return <Box>{error.message}</Box>
    }
    let axisOptions: string[] = criteria?.map((item) => item.name)

    return (
        <Box
            component={!props.isDialog ? Paper : "div"}
            display="flex"
            height="100%"
            width="100%"
            flexDirection="column"
            key={props.widget.id}
            onMouseOver={() => {
                if (!props.isDialog && !hovered) {
                    setHovered(true)
                }
            }}
            onMouseLeave={() => {
                if (!props.isDialog && !!hovered) {
                    setHovered(false)
                }
            }}
        >
            <WidgetHeader
                label={config?.label}
                hovered={hovered || !!props.isDialog}
                onWidgetDeletion={props.onWidgetDeletion}
                interactionTools={[
                    <WidgetFilterToggle
                        key={`${props.widget.id}_filter`}
                        feedVariables={currentFeedVariables}
                        size="medium"
                        isDialog={props.isDialog}
                        setFeedVariables={setCurrentFeedVariables}
                    />,
                ]}
                widget={props.widget}
                editing={props.editing}
            />

            {!!data && isInitialized ? (
                <Box
                    key={props.widget.id + props.editing}
                    overflow="hidden"
                    height="100%"
                >
                    {axisOptions?.length > 0 ? (
                        <BubbleChart
                            data={bubbleChartData}
                            axisOptions={
                                criteria.map((item) => item.name)?.sort() ?? []
                            }
                        />
                    ) : (
                        <Box
                            p={1}
                            display="flex"
                            alignItems={"center"}
                            width="100%"
                            justifyContent={"center"}
                        >
                            No criteria selected...
                        </Box>
                    )}
                </Box>
            ) : (
                <Loading size={25} hideQuote={true} />
            )}
        </Box>
    )
}

export default BubbleChartWidget
