import React from "react"
import { Box, Typography, LinearProgress, alpha } from "@material-ui/core"
import { NumberType } from "../../criteria/useCriteriaTypes"
import { Theme, createStyles, withStyles } from "@material-ui/core/styles"

const BorderLinearProgress = withStyles((theme: Theme) =>
    createStyles({
        root: {
            height: "100%",
            flexGrow: 1,
            borderRadius: theme.shape.borderRadius,
        },
        colorPrimary: {
            backgroundColor: "transparent",
        },
        bar: {
            borderRadius: theme.shape.borderRadius,
            backgroundColor: alpha(theme.palette.primary.main, 0.15),
        },
    })
)(LinearProgress)

interface IndividualResponseProps {
    value: number
    config: NumberType
}
const IndividualResponse: React.FC<IndividualResponseProps> = ({
    value,
    config,
}) => {
    if (!!config.maximum) {
        return (
            <Box p={1} display="flex" height="100%">
                <Box width={150} display="flex">
                    <BorderLinearProgress
                        value={(value / config.maximum) * 100}
                        variant="determinate"
                    />
                </Box>
                <Box ml={1}>
                    <Typography variant="h6">
                        {value}/
                        <span
                            style={{
                                fontSize: "75%",
                            }}
                        >
                            {config.maximum}
                            {config?.suffix ?? ""}
                        </span>
                    </Typography>
                </Box>
            </Box>
        )
    } else {
        return (
            <Box p={1} height="100%" display={"flex"} alignItems="center">
                <Typography variant="body1">
                    {config.prefix}
                    {value.toLocaleString()} {config.suffix ?? ""}
                </Typography>
            </Box>
        )
    }
}

export default IndividualResponse
