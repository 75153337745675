import { useCallback, useEffect, useRef } from "react"
import { useQuery, useMutation, useApolloClient, gql } from "@apollo/client"
import { Avatar, Theme } from "@material-ui/core"
import Box from "@material-ui/core/Box"
import Card from "@material-ui/core/Card"
import Divider from "@material-ui/core/Divider"
import { makeStyles, useTheme } from "@material-ui/core/styles"
import { Palette } from "@material-ui/core/styles/createPalette"
import { Typography } from "@material-ui/core/styles/createTypography"
import ArrowRightAltIcon from "@material-ui/icons/ArrowRightAlt"
import StarIcon from "@material-ui/icons/Star"
import TrendingUpIcon from "@material-ui/icons/TrendingUp"
import { useHistory } from "react-router-dom"
import {
    Area,
    AreaChart,
    Line,
    LineChart,
    ReferenceDot,
    ReferenceLine,
    ResponsiveContainer,
    Tooltip,
    XAxis,
    YAxis,
} from "recharts"
import { CurveData, AdoptionData } from "./line-data/HypeChartData"
import useAwaitTranslation from "../../i18n/useAwaitTranslation"
import {
    Concept,
    _ConceptFilter,
    _CriteriaScoreFilter,
} from "../../__generated__/types"
import { Loading } from "../Loading"
import {
    CREATE_CRITERIA_SCORE,
    ADD_CRITERIA_SCORE_CRITERIA,
    UPDATE_CRITERIA_SCORE,
    ADD_CRITERIA_SCORE_SCORED_CONCEPT,
    CRITERIA_SCORE_FILTERED,
    CRITERIA_FILTERED,
} from "../criteria/graphql"
import {
    CreateCriteriaScoreMutation,
    UpdateCriteriaScoreMutation,
    UpdateCriteriaScoreMutationVariables,
    CreateCriteriaScoreMutationVariables,
    AddCriteriaScoreCriteriaMutation,
    AddCriteriaScoreCriteriaMutationVariables,
    AddCriteriaScoreScoredConceptMutation,
    AddCriteriaScoreScoredConceptMutationVariables,
    CriteriaScoreFilteredQuery,
    CriteriaScoreFilteredQueryVariables,
    CriteriaQuery,
    CriteriaQueryVariables,
} from "../criteria/__generated__/graphql"
import { DotProps } from "recharts"
import ErrorHandler from "../ErrorHandler"

const useStyles = makeStyles((theme: Theme) => ({
    flex: {
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-around",
        width: "100%",
    },
    yAxis: {
        writingMode: "vertical-lr",
        textOrientation: "sideways",
        transform: "rotate(180deg)",
        display: "flex",
        justifyContent: "center",
        width: "20px",
        fontSize: 12,
        color: theme.palette.text.hint,
    },
    timeContainer: {
        fontSize: 12,
        color: theme.palette.text.hint,
        textAlign: "center",
        marginBottom: "5px",
    },
    clickable: {
        cursor: "crosshair !important",
    },
}))

interface HypeChartProps {
    filter?: _ConceptFilter
    concepts?: Concept[]
    locked?: boolean
    pageConcept?: Concept
}
type PositionObject = {
    x: number
    y: number
    id: string
}
type TooltipObject = {
    title: string
    primary: boolean
    position: number
    imageUrl: string
}
export default function HypeChart(props: HypeChartProps) {
    //hooks
    const client = useApolloClient()
    const theme = useTheme()
    const history = useHistory()
    const classes = useStyles()
    const { t } = useAwaitTranslation("trends")

    const sizeRef = useRef(null)

    //mutations
    const [createScore] = useMutation<
        CreateCriteriaScoreMutation,
        CreateCriteriaScoreMutationVariables
    >(CREATE_CRITERIA_SCORE)
    const [addScoreToCriteria] = useMutation<
        AddCriteriaScoreCriteriaMutation,
        AddCriteriaScoreCriteriaMutationVariables
    >(ADD_CRITERIA_SCORE_CRITERIA)
    const [updateScore] = useMutation<
        UpdateCriteriaScoreMutation,
        UpdateCriteriaScoreMutationVariables
    >(UPDATE_CRITERIA_SCORE)
    const [addScoreToConcept] = useMutation<
        AddCriteriaScoreScoredConceptMutation,
        AddCriteriaScoreScoredConceptMutationVariables
    >(ADD_CRITERIA_SCORE_SCORED_CONCEPT)

    //queries and query filters
    let scoreFilters: _CriteriaScoreFilter[] = [
        {
            criteria: {
                name: "Hype Chart Position",
            },
        },
        { conceptDefault: true },
    ]
    if (Object.keys(props.filter).length > 0) {
        scoreFilters.push({
            scoredConcept: {
                ...props.filter,
            },
        })
    }
    let filterObject: _CriteriaScoreFilter = {}
    if (!!props.pageConcept) {
        filterObject = {
            OR: [
                {
                    AND: [...scoreFilters],
                },
                {
                    AND: [
                        {
                            scoredConcept: {
                                id: props.pageConcept?.id,
                            },
                        },
                        {
                            criteria: {
                                name: "Hype Chart Position",
                            },
                        },
                    ],
                },
            ],
        }
    } else {
        filterObject = {
            AND: [...scoreFilters],
        }
    }
    const { data: scoreData, error, loading, refetch } = useQuery<
        CriteriaScoreFilteredQuery,
        CriteriaScoreFilteredQueryVariables
    >(CRITERIA_SCORE_FILTERED, {
        variables: {
            filter: {
                ...filterObject,
            },
        },
        fetchPolicy: "cache-and-network",
        nextFetchPolicy: "cache-first",
    })
    const { data: criteriaData } = useQuery<
        CriteriaQuery,
        CriteriaQueryVariables
    >(CRITERIA_FILTERED, {
        variables: {
            filter: {
                AND: [{ name: "Hype Chart Position" }, { isDefault: true }],
            },
        },
    })

    //data variables
    const scores = scoreData?.CriteriaScore ?? null
    const criteria = criteriaData?.Criteria?.[0] ?? null
    const pageConceptScore =
        scores?.find(
            (score) => score.scoredConcept?.id === props.pageConcept?.id
        ) ?? null

    //refs
    const pageConceptRef = useRef(null)
    pageConceptRef.current = props.pageConcept

    const criteriaRef = useRef(null)
    criteriaRef.current = criteria

    //callbacks
    const createPageScore = useCallback(async () => {
        const {
            data: { CreateCriteriaScore: NewScore },
        } = await createScore({
            variables: {
                response: null,
                conceptDefault: true,
            },
        })
        await Promise.all([
            addScoreToConcept({
                variables: {
                    conceptId: pageConceptRef?.current?.id,
                    criteriaScoreId: NewScore.id,
                },
            }),
            addScoreToCriteria({
                variables: {
                    criteriaId: criteriaRef?.current?.id,
                    criteriaScoreId: NewScore.id,
                },
            }),
        ])

        await refetch()
    }, [addScoreToConcept, addScoreToCriteria, createScore, refetch])

    //functions
    const handleClick = (data: { activeLabel: number }) => {
        if (!!props.pageConcept && !!data?.activeLabel && !props.locked) {
            client.writeFragment({
                id: client.cache.identify(pageConceptScore),
                fragment: gql`
                    fragment NewResponse on CriteriaScore {
                        response
                    }
                `,
                data: {
                    response: JSON.stringify(data.activeLabel ?? 0),
                },
            })
            updateScore({
                variables: {
                    id: pageConceptScore.id,
                    response: JSON.stringify(data.activeLabel),
                },
            })
        }
    }
    const needsScore =
        !!props.pageConcept &&
        !!scoreData &&
        !!criteriaData &&
        !pageConceptScore

    //useEffects
    useEffect(() => {
        if (!!needsScore) {
            createPageScore()
        }
    }, [needsScore, createPageScore])

    //query handling
    if (error) {
        return <ErrorHandler message={error.message} showMessage={true} />
    }
    if (loading || (!!props.pageConcept && !pageConceptScore)) {
        return <Loading size={25} hideQuote={true} />
    }
    //chart data
    let expectationsObject: PositionObject | null
    let adoptionObject: PositionObject | null
    let expectationsArray: PositionObject[] = []
    let adoptionArray: PositionObject[] = []
    if (!!props.pageConcept && !!pageConceptScore?.response) {
        expectationsObject = {
            id: props.pageConcept.id,
            x: Number(pageConceptScore.response),
            y: 0,
        }
        adoptionObject = {
            id: props.pageConcept.id,
            x: Number(pageConceptScore.response),
            y: 0,
        }
        CurveData.forEach((chartPoint) => {
            chartPoint.dataPoints = {}
            if (chartPoint.number === expectationsObject?.x) {
                expectationsObject.y = chartPoint.hypeLine
                let toolTipObject: TooltipObject = {
                    title: props.pageConcept.title,
                    imageUrl: props.pageConcept.imageUrl,
                    primary: true,
                    position: chartPoint.number,
                }
                chartPoint.dataPoints[expectationsObject.id] = toolTipObject
            }
        })
        AdoptionData.forEach((chartPoint) => {
            if (chartPoint.number === adoptionObject.x) {
                adoptionObject.y = chartPoint.hypeLine
            }
        })
    }
    scores?.forEach((score) => {
        if (!!score?.response) {
            let expectationsObject: PositionObject = {
                x: Number(score.response),
                y: 0,
                id: score.scoredConcept?.id,
            }
            let adoptionObject: PositionObject = {
                x: Number(score.response),
                y: 0,
                id: score.scoredConcept?.id,
            }
            CurveData.forEach((chartPoint) => {
                if (
                    !!score.response &&
                    Number(score.response) === chartPoint.number
                ) {
                    expectationsObject.y = chartPoint.hypeLine
                    let toolTipObject: TooltipObject = {
                        title: score.scoredConcept?.title,
                        imageUrl: score.scoredConcept?.imageUrl,
                        primary: false,
                        position: chartPoint.number,
                    }
                    chartPoint.dataPoints[expectationsObject.id] = toolTipObject
                }
            })
            expectationsArray.push(expectationsObject)
            AdoptionData.forEach((chartPoint) => {
                if (
                    !!score.response &&
                    Number(score.response) === chartPoint.number
                ) {
                    adoptionObject.y = chartPoint.hypeLine
                }
            })
            adoptionArray.push(adoptionObject)
        }
    })
    return (
        <div className={classes.flex}>
            <div
                style={{
                    width: "100%",
                    height: "100%",
                    overflow: "hidden",
                }}
                ref={sizeRef}
            >
                <ResponsiveContainer height={"100%"} width="100%">
                    <LineChart
                        // @ts-ignore
                        onClick={handleClick}
                        className={
                            !!props.pageConcept && !props.locked
                                ? classes.clickable
                                : ""
                        }
                        data={CurveData}
                        syncId="hype"
                        margin={{
                            right: theme.spacing(2),
                            left: theme.spacing(3),
                            top: theme.spacing(2),
                        }}
                    >
                        <XAxis
                            hide={true}
                            orientation="top"
                            xAxisId={0}
                            type="number"
                            dataKey="number"
                            interval="preserveEnd"
                            domain={[0, 100]}
                        />
                        <XAxis
                            orientation="bottom"
                            xAxisId={1}
                            type="number"
                            dataKey="number"
                            ticks={[5, 20, 30, 45, 75]}
                            tick={
                                <ExpectationsTick
                                    width={sizeRef?.current?.clientWidth}
                                />
                            }
                            interval="preserveEnd"
                            domain={[0, 100]}
                        />
                        <YAxis
                            width={theme.spacing(4)}
                            tick={false}
                            dataKey="hypeLine"
                            domain={[0, 2000]}
                            label={{
                                value: t(
                                    "hypeChart.expectations",
                                    "Expectations"
                                ),
                                position: "insideLeft",
                                angle: -90,
                                //offset: -theme.spacing(3),
                                fill: theme.palette.text.primary,
                            }}
                        />
                        <Tooltip
                            wrapperStyle={{
                                fill: theme.palette.background.paper,
                                zIndex: theme.zIndex.tooltip,
                            }}
                            content={
                                <CustomTooltip
                                    typography={theme.typography}
                                    palette={theme.palette}
                                />
                            }
                        />
                        <Line
                            type="basis"
                            dot={false}
                            dataKey="hypeLine"
                            stroke={theme.palette.primary.main}
                            fill={theme.palette.primary.main}
                        />
                        {expectationsArray?.map((item, idx) => {
                            const key = idx.toString() + "expectations"
                            return (
                                <ReferenceDot
                                    key={key}
                                    fill={theme.palette.secondary.main}
                                    style={{
                                        cursor: props.locked
                                            ? "pointer"
                                            : "default",
                                    }}
                                    onClick={(e: DotProps) => {
                                        if (props.locked) {
                                            history.push(
                                                `/concept/${item.id}/home`
                                            )
                                        }
                                    }}
                                    r={5}
                                    xAxisId={0}
                                    x={item.x}
                                    y={item.y}
                                />
                            )
                        })}
                        {!!expectationsObject && (
                            <ReferenceDot
                                r={5}
                                isFront={true}
                                xAxisId={0}
                                x={expectationsObject.x}
                                y={expectationsObject.y}
                                fill={theme.palette.primary.main}
                            />
                        )}
                    </LineChart>
                </ResponsiveContainer>
            </div>
            <div
                style={{
                    width: "100%",
                    height: "100%",
                    overflow: "hidden",
                }}
            >
                <ResponsiveContainer height={"100%"} width="100%">
                    <AreaChart
                        data={AdoptionData}
                        syncId="hype"
                        className={
                            !!props.pageConcept && !props.locked
                                ? classes.clickable
                                : ""
                        }
                        margin={{
                            top: theme.spacing(1),
                            right: theme.spacing(2),
                            left: theme.spacing(3),
                            bottom: theme.spacing(5),
                        }}
                    >
                        <YAxis
                            tick={false}
                            dataKey="hypeLine"
                            domain={[0, 4]}
                            width={theme.spacing(4)}
                            label={{
                                value: t(
                                    "hypeChart.adoptionRate",
                                    "Adoption Rate"
                                ),
                                position: "insideLeft",
                                angle: -90,

                                fill: theme.palette.text.primary,
                            }}
                        />
                        <Tooltip
                            wrapperStyle={{
                                fill: theme.palette.background.paper,
                                zIndex: theme.zIndex.tooltip,
                            }}
                            content={
                                <CustomTooltip
                                    typography={theme.typography}
                                    palette={theme.palette}
                                />
                            }
                        />
                        <XAxis
                            dataKey="number"
                            ticks={[10, 30, 50, 70, 90]}
                            domain={[0, 100]}
                            tick={
                                <AdoptionTick
                                    width={sizeRef?.current?.clientWidth}
                                />
                            }
                            label={{
                                value: t("hypeChart.time", "Time"),
                                position: "insideBottom",
                                offset: -theme.spacing(3),
                                fill: theme.palette.text.primary,
                            }}
                        />
                        <Area
                            type="monotone"
                            dataKey="hypeLine"
                            stroke={theme.palette.primary.main}
                            fill={theme.palette.primary.main}
                        />

                        {adoptionArray?.map((item, idx) => {
                            const key = idx.toString() + "adoption"
                            return (
                                <ReferenceDot
                                    key={key}
                                    style={{
                                        cursor: props.locked
                                            ? "pointer"
                                            : "default",
                                    }}
                                    onClick={(e: DotProps) => {
                                        if (props.locked) {
                                            history.push(
                                                `/concept/${item.id}/home`
                                            )
                                        }
                                    }}
                                    fill={theme.palette.secondary.main}
                                    r={5}
                                    xAxisId={0}
                                    x={item.x}
                                    y={item.y}
                                />
                            )
                        })}
                        <ReferenceLine
                            strokeOpacity={0.5}
                            stroke={theme.palette.text.hint}
                            segment={[
                                { x: 20, y: 0 },
                                {
                                    x: 20,
                                    y: AdoptionData[20].hypeLine,
                                },
                            ]}
                        />
                        <ReferenceLine
                            strokeOpacity={0.5}
                            stroke={theme.palette.text.hint}
                            segment={[
                                { x: 40, y: 0 },
                                {
                                    x: 40,
                                    y: AdoptionData[40].hypeLine,
                                },
                            ]}
                        />
                        <ReferenceLine
                            strokeOpacity={0.5}
                            stroke={theme.palette.text.hint}
                            segment={[
                                { x: 60, y: 0 },
                                {
                                    x: 60,
                                    y: AdoptionData[60].hypeLine,
                                },
                            ]}
                        />
                        <ReferenceLine
                            strokeOpacity={0.5}
                            stroke={theme.palette.text.hint}
                            segment={[
                                { x: 80, y: 0 },
                                {
                                    x: 80,
                                    y: AdoptionData[80].hypeLine,
                                },
                            ]}
                        />
                        <ReferenceLine
                            strokeOpacity={0.5}
                            stroke={theme.palette.text.hint}
                            segment={[
                                { x: 100, y: 0 },
                                {
                                    x: 100,
                                    y: AdoptionData[100].hypeLine,
                                },
                            ]}
                        />
                        {!!adoptionObject && (
                            <ReferenceDot
                                r={5}
                                isFront={true}
                                xAxisId={0}
                                x={adoptionObject.x}
                                y={adoptionObject.y}
                                fill={theme.palette.primary.main}
                            />
                        )}
                    </AreaChart>
                </ResponsiveContainer>
            </div>
        </div>
    )
}
const CustomTooltip = (props: {
    payload?: any
    typography?: Typography
    palette?: Palette
    active?: boolean
}) => {
    const { active, payload, typography, palette } = props
    const { t } = useAwaitTranslation("trends")
    const data = payload[0]?.payload?.dataPoints
    if (active && payload) {
        if (data && Object.keys(data).length > 0) {
            let topLabel = ""
            const xValue = payload[0].payload.number
            if (xValue < 17) {
                topLabel = t(
                    "hypeChart.innovationTrigger",
                    "Innovation Trigger"
                )
            } else if (xValue >= 17 && xValue <= 23) {
                topLabel = t(
                    "hypeChart.peakOfInflatedExpectations",
                    "Peak of Inflated Expectations"
                )
            } else if (xValue >= 24 && xValue <= 35) {
                topLabel = t(
                    "hypeChart.troughOfDisillusionment",
                    "Trough of Disillusionment"
                )
            } else if (xValue >= 36 && xValue <= 65) {
                topLabel = t(
                    "hypeChart.slopeOfEnlightment",
                    "Slope of Enlightenment"
                )
            } else {
                topLabel = t(
                    "hypeChart.plateauOfProductivity",
                    "Plateau of Productivity"
                )
            }

            let bottomLabel = ""
            if (xValue <= 20) {
                bottomLabel = t("hypeChart.innovators", "Innovators")
            } else if ((xValue) => 20 && xValue < 40) {
                bottomLabel = t("hypeChart.earlyAdopters", "Early Adopters")
            } else if ((xValue) => 40 && xValue < 60) {
                bottomLabel = t("hypeChart.earlyMajority", "Early Majority")
            } else if ((xValue) => 60 && xValue < 80) {
                bottomLabel = t("hypeChart.lateMajority", "Late Majority")
            } else {
                bottomLabel = t("hypeChart.laggards", "Laggards")
            }

            return (
                <Card
                    style={{
                        padding: "12px",
                        maxWidth: "350px",
                    }}
                >
                    {Object.keys(data).map((dataPoint, idx) => (
                        <Box
                            key={idx}
                            display="flex"
                            alignItems="center"
                            fontSize={typography.body1.fontSize}
                            padding={0.5}
                        >
                            <Avatar
                                variant="rounded"
                                src={data[dataPoint].imageUrl}
                                style={{ marginRight: "5px" }}
                            >
                                {data[dataPoint].primary ? (
                                    <StarIcon />
                                ) : (
                                    <TrendingUpIcon />
                                )}
                            </Avatar>
                            {data[dataPoint].title}
                        </Box>
                    ))}
                    <Box padding={0.5}>
                        <Divider />
                    </Box>
                    <Box fontSize={typography.body2.fontSize}>
                        <Box padding={0.5} display="flex" alignItems="center">
                            {t("hypeChart.expectations", "Expectations")}{" "}
                            <ArrowRightAltIcon /> {topLabel}
                        </Box>
                        <Box padding={0.5} display="flex" alignItems="center">
                            {t("hypeChart.adoptionRate", "Adoption Rate")}{" "}
                            <ArrowRightAltIcon /> {bottomLabel}
                        </Box>
                    </Box>
                </Card>
            )
        } else {
            return null
        }
    } else {
        return null
    }
}

const ExpectationsTick = (props) => {
    const { x, y, payload, width } = props
    const { t } = useAwaitTranslation("trends")

    const tickObject = {
        5: t("hypeChart.innovationTrigger", "Innovation Trigger"),
        20: t(
            "hypeChart.peakOfInflatedExpectations",
            "Peak of Inflated Expectations"
        ),
        30: t("hypeChart.troughOfDisillusionment", "Trough of Disillusionment"),
        45: t("hypeChart.slopeOfEnlightment", "Slope of Enlightenment"),
        75: t("hypeChart.plateauOfProductivity", "Plateau of Productivity"),
    }
    const hideableTicks = [
        t(
            "hypeChart.peakOfInflatedExpectations",
            "Peak of Inflated Expectations"
        ),
        t("hypeChart.slopeOfEnlightment", "Slope of Enlightenment"),
    ]
    let content = ""

    if (
        width < 750 &&
        hideableTicks.indexOf(tickObject[payload.value]) !== -1
    ) {
        content = ""
    } else {
        content = tickObject[payload.value]
    }

    return (
        <foreignObject x={x - 15} y={y - 5} width="85" height="150">
            <div style={{ fontSize: width < 650 ? "8px" : "10px" }}>
                {content}
            </div>
        </foreignObject>
    )
}
const AdoptionTick = (props) => {
    const { x, y, payload, width } = props

    const { t } = useAwaitTranslation("trends")
    const tickObject = {
        0: "",
        10: t("hypeChart.innovators", "Innovators"),
        20: "",
        30: t("hypeChart.earlyAdopters", "Early Adopters"),
        40: "",
        50: t("hypeChart.earlyMajority", "Early Majority"),
        60: "",
        70: t("hypeChart.lateMajority", "Late Majority"),
        80: "",
        90: t("hypeChart.laggards", "Laggards"),
        100: "",
    }
    const hideableTicks = [
        t("hypeChart.earlyAdopters", "Early Adopters"),
        t("hypeChart.lateMajority", "Late Majority"),
    ]
    let content: string = ""
    if (
        width < 750 &&
        hideableTicks.indexOf(tickObject[payload.value]) !== -1
    ) {
        content = ""
    } else {
        content = tickObject[payload.value]
    }
    return (
        <foreignObject x={x - 20} y={y - 5} width="85" height="150">
            <div style={{ fontSize: width < 650 ? "8px" : "10px" }}>
                {content}
            </div>
        </foreignObject>
    )
}
