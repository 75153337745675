import React from "react"

import { Box, Typography } from "@material-ui/core"

import useCategoryTools from "../categories/useCategoryTools"
import { useTheme } from "@material-ui/core/styles"
import CategoryIcon from "../categories/CategoryIcon"

const CollectionCategorySubList = (props: { categoryIds: string[] }) => {
    const { categoryIds } = props
    const { getCategoriesByIds } = useCategoryTools()
    const theme = useTheme()
    const categories = getCategoriesByIds(categoryIds)
    if (!categories || categories?.length === 0) {
        return null
    }
    return (
        <Box
            display="flex"
            alignItems="center"
            color={theme.palette.text.secondary}
            fontSize={theme.typography.caption.fontSize}
            maxWidth={400}
            flexWrap="wrap"
        >
            (&nbsp;
            {categories.map((category, index) => {
                return (
                    <Box
                        key={category?.id + index}
                        color={theme.palette.text.secondary}
                        display="flex"
                        mr={index !== categories.length - 1 ? 0.75 : 0}
                    >
                        <span
                            style={{
                                display: "flex",
                                alignItems: "center",
                                marginRight: 3,
                            }}
                        >
                            <CategoryIcon
                                category={category}
                                fontSize={"small"}
                                styles={{
                                    fontSize: "1.4em",
                                }}
                            />
                        </span>
                        <Typography variant="caption">
                            {category?.name}
                        </Typography>
                        {index !== categories.length - 1 && ","}
                    </Box>
                )
            })}
            &nbsp;)
        </Box>
    )
}

export default CollectionCategorySubList
