import React from "react"
import Box from "@material-ui/core/Box"
import {
    Card,
    CardContent,
    CardMedia,
    CardActions,
    Avatar,
    Link,
    Typography,
    CardActionArea,
    Tooltip,
} from "@material-ui/core"
import { useHistory } from "react-router-dom"
import useAwaitTranslation from "../i18n/useAwaitTranslation"
import dayjs from "dayjs"
import { makeStyles } from "@material-ui/core/styles"
import { AccessType, Concept } from "../__generated__/types"
import TagList from "./tags/TagList"
import { getGermanTimeAgo } from "../util/fns"
import CategoryLabel from "./CategoryLabel"
import AccountTreeRounded from "@material-ui/icons/AccountTreeRounded"
import VpnLockIcon from "@material-ui/icons/VpnLock"
const useStyles = makeStyles(
    ({ shape, spacing, palette, breakpoints, typography }) => ({
        card: {
            borderRadius: shape.borderRadius,
            height: "100%",
            display: "flex",
            flexDirection: "column",

            overflow: "auto",
        },
        cardActions: {
            justifyContent: "space-between",

            width: "100%",
            flexWrap: "wrap-reverse",
            fontSize: 12,
            color: palette.text.secondary,
        },
        media: {
            height: "150px",
            width: "150px",

            flexShrink: 0,
            borderRadius: shape.borderRadius,
            margin: "auto",
        },
        content: {
            flexGrow: 1,
            padding: 0,
            width: "100%",
            display: "flex",
            flexDirection: "column",
        },
        heading: {
            fontSize: typography.h6.fontSize,
            fontWeight: typography.fontWeightMedium as any,
            letterSpacing: typography.h6.letterSpacing,
            marginTop: spacing(0.5),
            display: "flex",
            alignItems: "center",
            [breakpoints.down("sm")]: {
                fontSize: typography.body1.fontSize,
                fontWeight: typography.fontWeightMedium,
                letterSpacing: typography.body1.letterSpacing,
            },
        },
        body: {
            display: "flex",
            flexWrap: "wrap",
            height: "100%",
        },

        summary: {
            fontSize: typography.body2.fontSize,
            color: palette.text.secondary,
            flexGrow: 1,
            margin: spacing(0.5, 0),
        },
        categoryContainer: {
            color: palette.text.secondary,
        },
        avatar: {
            height: spacing(3),
            width: spacing(3),
            margin: spacing(0, 0.75),
            marginLeft: 0,
        },
        flexContainer: {
            display: "flex",
            flexGrow: 1,
            flexDirection: "column",
        },
        cardActionArea: {
            display: "flex",
            flexDirection: "column",
            height: "100%",
        },
        parentIcon: {
            display: "flex",
            flexDirection: "column",
            height: "100%",
        },
    })
)

interface ConceptCardBaseBodyProps {
    item: Concept
    topActions?: React.ReactElement
    bottomActions?: React.ReactElement
    onClick?: (concept: Concept) => void
    nonGrid?: boolean
    condensed?: boolean
    hideAuthor?: boolean
}

const ConceptCardBaseBody = (props: ConceptCardBaseBodyProps) => {
    //hooks

    const { t, i18n } = useAwaitTranslation("concepts")
    const history = useHistory()

    const classes = useStyles()

    const handleUserClick = (event) => {
        event.stopPropagation()
        history.push(`/user/${props.item.createdBy?.username}/home`)
    }

    const selectedLangString = i18n?.language
    let translatedContent = props.item.translations.filter(
        (t) => t.languageString === selectedLangString
    )[0]

    const baseContent = (
        <>
            <CardContent className={classes.content}>
                <Box display="flex" height="100%" width="100%" p={1}>
                    <Box
                        flexGrow={1}
                        height="100%"
                        display="flex"
                        flexDirection={"column"}
                        overflow="hidden"
                    >
                        <Box
                            display="flex"
                            flexDirection="column"
                            height="100%"
                        >
                            <Box
                                display="flex"
                                alignItems="center"
                                justifyContent="space-between"
                                position="relative"
                            >
                                <Box flexGrow={1} display="flex">
                                    {props.item.parent?.title && (
                                        <Tooltip
                                            title={props.item.parent?.title}
                                        >
                                            <AccountTreeRounded
                                                fontSize="small"
                                                color="disabled"
                                                style={{ marginRight: 4 }}
                                            />
                                        </Tooltip>
                                    )}
                                    <CategoryLabel
                                        conceptCategoryId={
                                            props.item.category?.id
                                        }
                                        size="medium"
                                    />
                                </Box>

                                <Box
                                    display="flex"
                                    alignItems={"center"}
                                    justifyContent="flex-end"
                                >
                                    <Typography
                                        color="textSecondary"
                                        variant="body2"
                                    >
                                        {props.item.status?.[0]?.stage?.title}
                                    </Typography>

                                    {props.item.isPublicAccessType ===
                                        AccessType.NONE && (
                                        <VpnLockIcon
                                            style={{
                                                marginRight: !!props.topActions
                                                    ? 8
                                                    : 0,
                                                marginLeft: 8,
                                            }}
                                            color="disabled"
                                            fontSize="small"
                                        />
                                    )}
                                    {!!props.topActions
                                        ? props.topActions
                                        : null}
                                </Box>
                            </Box>
                            <Box display="flex" flexGrow={1}>
                                <Box
                                    display="flex"
                                    flexDirection={"column"}
                                    flexGrow={1}
                                >
                                    <Box className={classes.heading}>
                                        {translatedContent?.title ||
                                            props.item.title}
                                    </Box>

                                    <Box className={classes.body}>
                                        {!props.condensed &&
                                            props.item.summary && (
                                                <Box
                                                    pt={1}
                                                    width="100%"
                                                    flex="1 1 200px"
                                                >
                                                    <Typography
                                                        className={
                                                            classes.summary
                                                        }
                                                    >
                                                        {translatedContent?.summary ||
                                                            props.item.summary}
                                                    </Typography>
                                                </Box>
                                            )}
                                        {!!props.item.imageUrl &&
                                            !props.nonGrid && (
                                                <Box
                                                    mr={2}
                                                    ml={2}
                                                    pt={1}
                                                    pb={1}
                                                    mt={"auto"}
                                                    mb={"auto"}
                                                    flex={"1 1 0px"}
                                                >
                                                    <CardMedia
                                                        className={
                                                            classes.media
                                                        }
                                                        image={
                                                            props.item.imageUrl
                                                        }
                                                    />
                                                </Box>
                                            )}
                                    </Box>
                                </Box>
                                {!!props.item.imageUrl && !!props.nonGrid && (
                                    <Box mr={2} ml={2} pt={1} pb={1}>
                                        <CardMedia
                                            className={classes.media}
                                            image={props.item.imageUrl}
                                        />
                                    </Box>
                                )}
                            </Box>
                        </Box>
                        {!props.condensed && props.item?.tags?.length > 0 && (
                            <Box
                                mt={0.5}
                                style={{
                                    filter: "opacity(0.8)",
                                }}
                            >
                                <TagList concept={props.item} />
                            </Box>
                        )}
                    </Box>
                </Box>
            </CardContent>
            {!props.hideAuthor && (
                <CardActions className={classes.cardActions}>
                    <Box
                        display="flex"
                        flexWrap="wrap"
                        alignItems="center"
                        flexGrow={1}
                    >
                        <Avatar
                            className={classes.avatar}
                            alt={props.item.createdBy?.firstName}
                            src={props.item.createdBy?.imageUrl}
                        />
                        <Box display="flex" flexDirection={"column"}>
                            <Link
                                style={{
                                    marginRight: "5px",
                                }}
                                onClick={handleUserClick}
                            >
                                {props.item.createdBy?.firstName}{" "}
                                {props.item.createdBy?.lastName}
                            </Link>
                            <span
                                style={{
                                    fontSize: "0.8em",
                                }}
                            >
                                {t("language", "language") === "language"
                                    ? dayjs(
                                          props.item.createdAt?.formatted
                                      ).fromNow()
                                    : getGermanTimeAgo(
                                          dayjs(
                                              props.item.createdAt?.formatted
                                          ).fromNow(),
                                          false
                                      )}
                            </span>
                        </Box>
                    </Box>
                    {!!props.bottomActions ? props.bottomActions : null}
                </CardActions>
            )}
        </>
    )
    return (
        <Card className={classes.card} elevation={0}>
            {!!props.onClick ? (
                <CardActionArea
                    component={"div"}
                    className={classes.cardActionArea}
                    onClick={() => {
                        props.onClick(props.item)
                    }}
                >
                    {baseContent}
                </CardActionArea>
            ) : (
                baseContent
            )}
        </Card>
    )
}
export default React.memo(ConceptCardBaseBody)
