import { Box, FormControlLabel, Switch } from "@material-ui/core"
import {
    ChartInterfaceProps,
    DefaultChart,
} from "../../criteria/useCriteriaTypes"
import HypeChartInterface from "../../charts/HypeChartInterface"
import ProductChartInterface from "../../charts/ProductChartInterface"
import BubbleChartInterface from "../../charts/BubbleChartInterface"
import { useInputTools } from "../useInputTools"
import RadarChartInterface, {
    RadarChartResponse,
} from "../../charts/RadarChartInterface"
import { useMemo, useState } from "react"
import {
    myCurrentInputResponseLabelVar,
    myCurrentInputResponseVar,
} from "../../../providers/GlobalState"
import { useReactiveVar } from "@apollo/client"
import { ScoreSelectorDefaultValues } from "../types"

const ChartInterface: React.FC<ChartInterfaceProps> = ({
    inputId,
    scoredConceptId,
    score,
    config,
}) => {
    //hooks
    const { onEditResponse } = useInputTools({})
    const [showOtherResponses, setShowOtherResponses] = useState(false)
    const { currentResponseData } = useReactiveVar(myCurrentInputResponseVar)
    const currentLabel = useReactiveVar(myCurrentInputResponseLabelVar)
    const viewingPrimaryResponse =
        currentLabel.label === ScoreSelectorDefaultValues.primaryResponse &&
        !currentLabel.user

    const scoreArray = useMemo(() => {
        const allNonPrimaryScores = !!viewingPrimaryResponse
            ? currentResponseData
                  ?.find((field) => field.input.id === inputId)
                  ?.scores?.filter(
                      (score) =>
                          !score.conceptDefault &&
                          score.scoredConcept?.id === scoredConceptId
                  )
            : []
        if (!!showOtherResponses) {
            return [...allNonPrimaryScores, score]
        } else {
            return [score]
        }
    }, [
        showOtherResponses,
        score,
        currentResponseData,
        inputId,
        scoredConceptId,
        viewingPrimaryResponse,
    ])

    return (
        <Box position="relative" flexGrow={1} overflow="hidden" key={score?.id}>
            {!!viewingPrimaryResponse && (
                <Box
                    position="absolute"
                    right={0}
                    top={-15}
                    p={2}
                    pb={1}
                    display="flex"
                    justifyContent="flex-end"
                    style={{
                        zIndex: 1,
                    }}
                >
                    <FormControlLabel
                        control={
                            <Switch
                                checked={showOtherResponses}
                                onChange={() =>
                                    setShowOtherResponses(!showOtherResponses)
                                }
                                size="small"
                            />
                        }
                        label="Show Other Responses"
                    />
                </Box>
            )}
            {config?.chart === DefaultChart.hypeLifeCycle && (
                <HypeChartInterface
                    scores={scoreArray}
                    onEditResponse={(value: number) =>
                        onEditResponse(
                            {
                                response: JSON.stringify(value),
                            },
                            score
                        )
                    }
                />
            )}
            {config?.chart === DefaultChart.productLifeCycle && (
                <ProductChartInterface
                    scores={scoreArray}
                    onEditResponse={(value: number) =>
                        onEditResponse(
                            {
                                response: JSON.stringify(value),
                            },
                            score
                        )
                    }
                />
            )}
            {config?.chart === DefaultChart.bubble && (
                <BubbleChartInterface
                    scores={scoreArray}
                    onEditResponse={(value: number[]) =>
                        onEditResponse(
                            {
                                response: JSON.stringify(value),
                            },
                            score
                        )
                    }
                    labelType="user"
                    axisConfiguration={config?.bubbleAxisConfiguration}
                />
            )}
            {config?.chart === DefaultChart.radar && (
                <RadarChartInterface
                    scores={scoreArray}
                    onEditResponse={(value: RadarChartResponse) =>
                        onEditResponse(
                            {
                                response: JSON.stringify(value),
                            },
                            score
                        )
                    }
                    labelType="user"
                    stages={config?.radarStages || []}
                />
            )}
        </Box>
    )
}

export default ChartInterface
