import React, { useState, useEffect, useCallback } from "react"
import { Box, Button } from "@material-ui/core"
import { CONNECTION_WIDGET_QUERY } from "../graphql/queries"
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles"
import {
    ConnectionWidgetQueryQuery,
    ConnectionWidgetQueryQueryVariables,
} from "../graphql/__generated__/queries"
import { useQuery } from "@apollo/client"
import { _ConceptFilter, _ConceptOrdering } from "../__generated__/types"
import ErrorHandler from "./ErrorHandler"
import { Loading } from "./Loading"
import ConceptChip from "./ConceptChip"
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: "flex",
            flexDirection: "column",
            width: "100%",
            height: "100%",
            overflow: "hidden",
        },
        listContainer: {
            overflowY: "auto",
            padding: theme.spacing(0, 1),
            overflowX: "hidden",
        },
    })
)
interface ConceptChipArrayProps {
    filter: _ConceptFilter
    orderBy: _ConceptOrdering
}
const PAGE_SIZE = 30
export default function ConceptChipArray(props: ConceptChipArrayProps) {
    //hooks
    const classes = useStyles()

    //state
    const [filter, setFilter] = useState<_ConceptFilter>({})
    const [limit, setLimit] = useState(PAGE_SIZE)
    const [offset, setOffset] = useState(0)

    //queries
    const { loading, data, fetchMore, error } = useQuery<
        ConnectionWidgetQueryQuery,
        ConnectionWidgetQueryQueryVariables
    >(CONNECTION_WIDGET_QUERY, {
        variables: {
            offset: 0,
            first: limit,
            connectionsFilter: filter,
            orderBy: [props.orderBy],
        },
    })

    //useEffects
    useEffect(() => {
        if (JSON.stringify(props.filter) !== JSON.stringify(filter)) {
            setFilter(props.filter)
            setOffset(0)
        }
    }, [props.filter, filter])

    //variables
    const listData = data?.Concept ?? []

    //functions
    const handleNext = async () => {
        setOffset((prev) => prev + PAGE_SIZE)

        await onLoadMore()
    }

    //callbacks
    const onLoadMore = useCallback(async () => {
        if (!fetchMore) return
        await fetchMore({
            variables: {
                offset: listData.length,
            },
        })

        setLimit(listData.length + PAGE_SIZE)
    }, [fetchMore, listData.length])
    if (loading) {
        return <Loading size={25} hideQuote={true} />
    } else if (error) {
        return <ErrorHandler showMessage={true} message={error.message} />
    }

    return (
        <Box className={classes.root}>
            <Box
                display="flex"
                flexWrap="wrap"
                position="relative"
                pb={4}
                className={classes.listContainer}
            >
                {listData?.map((item, index) => {
                    return (
                        <Box key={index + item.id} p={0.5}>
                            <ConceptChip item={item} />
                        </Box>
                    )
                })}
                {(!(listData.length < offset + PAGE_SIZE) || loading) && (
                    <Button
                        disabled={loading}
                        style={{ width: "100%" }}
                        onClick={handleNext}
                    >
                        View More
                    </Button>
                )}
            </Box>
        </Box>
    )
}
