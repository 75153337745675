import { useReactiveVar } from "@apollo/client"
import { Box, Button, Typography } from "@material-ui/core"
import {
    myCurrentInputResponseLabelVar,
    myCurrentInputResponseVar,
    userConceptPermissionsVar,
} from "../../providers/GlobalState"
import { useTheme } from "@material-ui/core/styles"
import { CollectionCriteria } from "../dashboard/CollectionCriteriaField"
import { ScoreSelectorDefaultValues } from "./types"
import DonutLarge from "@material-ui/icons/DonutLarge"
import CheckCircle from "@material-ui/icons/CheckCircle"
import { useAuth } from "../../providers/AuthProvider"
import {
    InputSourceResponseType,
    CollectionView,
} from "../criteria/useCriteriaTypes"
import { WidgetTypeName } from "../dashboard/useWidgetTypes"
import { CollectionType } from "../criteria/useCriteriaTypes"
import { getCleanedFieldConfig, getSelectedResponseScores } from "./util"
export function useResultsVisTools(
    widgetTypeName: WidgetTypeName,
    config?: CollectionCriteria
) {
    const currentResponsesVar = useReactiveVar(myCurrentInputResponseVar)
    const currentLabel = useReactiveVar(myCurrentInputResponseLabelVar)
    const { currentUser } = useAuth()

    const { editingPermission, contributingPermission } = useReactiveVar(
        userConceptPermissionsVar
    )
    const theme = useTheme()
    const viewingPrimaryResponse =
        !currentLabel?.user &&
        currentLabel?.label === ScoreSelectorDefaultValues.primaryResponse
    const viewingAllResponses =
        !currentLabel?.user &&
        currentLabel?.label === ScoreSelectorDefaultValues.allResponses

    const collectionField = currentResponsesVar.currentResponseData.find(
        (field) => field.input.criteria?.id === config?.collectionCriteriaId
    )

    const collectionFieldConfig: CollectionType =
        getCleanedFieldConfig(collectionField)

    const subCriteriaFields =
        config?.subCriteriaIds
            ?.map((id) => {
                let field = currentResponsesVar.currentResponseData.find(
                    (field) =>
                        id === field.input.criteria?.id &&
                        JSON.parse(
                            field.input.inputConfig ?? "{}"
                        )?.source?.criteriaIds?.find(
                            (item) =>
                                item.id === collectionField?.input?.criteria?.id
                        )
                )

                if (!!field) {
                    return {
                        ...field,
                        scores: getSelectedResponseScores(field, currentLabel),
                    }
                } else return null
            })
            ?.filter((x) => !!x) ?? []
    let primaryResponseOnly = true
    subCriteriaFields.forEach((item) => {
        if (
            JSON.parse(item.input.inputConfig ?? "{}")?.source
                ?.inputSourceResponseType !==
            InputSourceResponseType.primaryResponseOnly
        ) {
            primaryResponseOnly = false
        }
    })

    // logic will change once tables can hadnle primary response only values
    const collectionScore =
        !!primaryResponseOnly &&
        collectionFieldConfig.view !== CollectionView.table
            ? collectionField?.scores.find((score) => !!score.conceptDefault)
            : getSelectedResponseScores(collectionField, currentLabel)?.[0]

    let orderedCollectionConcepts = []
    let unorderedCollectionConcepts = []
    collectionScore?.concepts?.forEach((concept) => {
        const index = JSON.parse(collectionScore?.response)?.indexOf(concept.id)

        if (typeof index !== "undefined" && index !== -1) {
            orderedCollectionConcepts[index] = concept
        } else {
            unorderedCollectionConcepts.push(concept)
        }
    })
    orderedCollectionConcepts = [
        ...orderedCollectionConcepts,
        ...unorderedCollectionConcepts,
    ]?.filter((x) => !!x)

    //filtering for submitted scores and scores with a concept that is still in the parent collection score
    const subCriteriaScores = subCriteriaFields
        .flatMap((field) => getSelectedResponseScores(field, currentLabel))
        .filter((score) =>
            !!viewingAllResponses
                ? getSelectedResponseScores(collectionField, currentLabel)
                      ?.flatMap((score) => score.concepts)
                      ?.find(
                          (concept) => concept.id === score?.scoredConcept?.id
                      )
                : collectionScore?.concepts.find(
                      (concept) => concept.id === score.scoredConcept?.id
                  )
        )

    const messageIntefaceStyles = {
        display: "flex",
        zIndex: theme.zIndex.modal - 1,
        alignItems: "center",
        justifyContent: "center",
        height: "100%",
        width: "100%",
        padding: 5,
    }
    const messageTextInterfaceStyles = {
        border: `1px solid ${theme.palette.divider}`,
        borderRadius: theme.shape.borderRadius,
        backgroundColor: theme.palette.background.paper,
        margin: theme.spacing(2),
        textAlign: "center" as const,
        padding: theme.spacing(1),
        boxShadow: theme.shadows[12],
    }

    const userCanEditResponse =
        (!!contributingPermission &&
            currentLabel?.user?.userId === currentUser.userId) ||
        (!!viewingPrimaryResponse && !!editingPermission)

    let responseStatusMessageInterface = null
    if (!!viewingAllResponses && widgetTypeName === WidgetTypeName.Table) {
        responseStatusMessageInterface = (
            <Box style={{ ...messageIntefaceStyles }}>
                <Box
                    display="flex"
                    flexDirection="column"
                    alignItems={"center"}
                    style={messageTextInterfaceStyles}
                >
                    <Typography>
                        All responses view is not currently available
                    </Typography>
                </Box>
            </Box>
        )
    } else if (!!config && !viewingAllResponses && !!userCanEditResponse) {
        if (!collectionScore) {
            responseStatusMessageInterface = (
                <Box style={{ ...messageIntefaceStyles }}>
                    <Box
                        display="flex"
                        flexDirection="column"
                        alignItems={"center"}
                        style={messageTextInterfaceStyles}
                    >
                        <Typography>
                            {!!viewingPrimaryResponse
                                ? "A primary response must be provided for the following:"
                                : "You must respond to the following first:"}
                        </Typography>
                        <Box textAlign="center" mt={1}>
                            <Box display="flex" alignItems="center">
                                <Button
                                    startIcon={
                                        !collectionScore ? (
                                            <DonutLarge
                                                fontSize="small"
                                                color="secondary"
                                            />
                                        ) : (
                                            <CheckCircle
                                                fontSize="small"
                                                color="primary"
                                            />
                                        )
                                    }
                                    onClick={() => {
                                        document
                                            .getElementById(
                                                collectionField?.input.id
                                            )
                                            ?.scrollIntoView({
                                                behavior: "smooth",
                                                block: "center",
                                            })
                                    }}
                                >
                                    {collectionField?.input?.criteria?.name}
                                </Button>
                            </Box>
                            {subCriteriaFields.map((field) => {
                                const currentScores = getSelectedResponseScores(
                                    field,
                                    currentLabel
                                )?.filter(
                                    (score) =>
                                        !!collectionScore?.concepts.find(
                                            (concept) =>
                                                concept.id ===
                                                score.scoredConcept?.id
                                        )
                                )
                                const needsResponse =
                                    currentScores.length === 0 ||
                                    currentScores?.length !==
                                        collectionScore?.concepts.length

                                return (
                                    <Box
                                        key={field.input.id}
                                        display="flex"
                                        alignItems="center"
                                    >
                                        <Button
                                            startIcon={
                                                !!needsResponse ? (
                                                    <DonutLarge
                                                        fontSize="small"
                                                        color="secondary"
                                                    />
                                                ) : (
                                                    <CheckCircle
                                                        fontSize="small"
                                                        color="primary"
                                                    />
                                                )
                                            }
                                            onClick={() => {
                                                document
                                                    .getElementById(
                                                        field.input.id
                                                    )
                                                    ?.scrollIntoView({
                                                        behavior: "smooth",
                                                        block: "center",
                                                    })
                                            }}
                                        >
                                            {field.input.criteria?.name}
                                        </Button>
                                    </Box>
                                )
                            })}
                        </Box>
                    </Box>
                </Box>
            )
        }
    }

    return {
        subCriteriaFields,
        subCriteriaScores,
        responseStatusMessageInterface,
        orderedCollectionConcepts,
    }
}
