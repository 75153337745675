import React, { useState, useEffect } from "react"
import { makeStyles } from "@material-ui/core/styles"
import { Box, ClickAwayListener, IconButton } from "@material-ui/core"
import { useHotkeys } from "react-hotkeys-hook"
import SearchIcon from "@material-ui/icons/Search"
import { Concept } from "../__generated__/types"
import { useHistory } from "react-router-dom"
import clsx from "clsx"
import FuzzyConceptSearch from "./FuzzyConceptSearch"
const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        flexGrow: 1,
        justifyContent: "flex-end",
        alignItems: "center",
        [theme.breakpoints.up("xs")]: {
            marginLeft: theme.spacing(1),
        },
    },
    searchBar: {
        width: 0,
        overflow: "hidden",
        // marginRight: 5,
        flexGrow: 0,
    },
    autoFocusedSearchBar: {
        flexGrow: 1,
        width: "100%",
    },
    searchBarFocused: {
        flexGrow: 1,
    },
    popper: {
        zIndex: 1500,
    },
    listbox: {
        overflow: "auto",
    },
    avatar: {
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.primary.main,
    },
    open: {
        width: "100%",
        transition: theme.transitions.create("width", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    close: {
        width: 0,
        transition: theme.transitions.create("width", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
}))

interface QuickSearchProps {
    autoOpen?: boolean
}
export default function QuickSearch(props: QuickSearchProps) {
    const classes = useStyles()
    const history = useHistory()
    useHotkeys("s,u", (ev) => {
        ev.preventDefault()
        setOpen(true)
    })
    useHotkeys(
        "ctrl+s,command+s,ctrl+u,command+u",
        (ev) => {
            ev.preventDefault()
            setOpen(true)
        },
        {
            enableOnTags: ["INPUT", "SELECT", "TEXTAREA"],
        }
    )
    const [open, setOpen] = useState(false)
    const [advancedSearchOpen, setAdvancedSearchOpen] = useState(false)
    useEffect(() => {
        if (!!props.autoOpen) {
            setOpen(true)
        }
    }, [props.autoOpen])

    return (
        <ClickAwayListener
            onClickAway={() => !advancedSearchOpen && setOpen(false)}
        >
            <Box className={classes.root}>
                {(!!advancedSearchOpen || !open) && (
                    <IconButton onClick={(ev) => setOpen(true)}>
                        <SearchIcon />
                    </IconButton>
                )}
                <Box
                    className={clsx({
                        [classes.close]: !!advancedSearchOpen || !open,
                        [classes.open]: !advancedSearchOpen && !!open,
                    })}
                >
                    {open && (
                        <FuzzyConceptSearch
                            onSelection={(concept: Concept) => {
                                history.push(`/concept/${concept.id}/home`)
                                setOpen(false)
                            }}
                            onAdvancedSearchOpen={() => {
                                setAdvancedSearchOpen(true)
                            }}
                            onAdvancedSearchClose={() => {
                                setOpen(false)
                                setAdvancedSearchOpen(false)
                            }}
                            autoFocus={true}
                            variant="outlined"
                        />
                    )}
                </Box>
            </Box>
        </ClickAwayListener>
    )
}
