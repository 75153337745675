import { ListItem, ListItemAvatar, ListItemText } from "@material-ui/core"
import { User } from "../../__generated__/types"
import UserAvatar from "./UserAvatar"

export default function UserListItem(props: { user: User }) {
    const { user } = props
    return (
        <ListItem component="div">
            <ListItemAvatar>
                <UserAvatar user={user} variant="circle" size="large" />
            </ListItemAvatar>
            <ListItemText
                primary={`${user.firstName} ${user.lastName}`}
                secondary={user.email}
            />
        </ListItem>
    )
}
