import { useCallback, useEffect, useState } from "react"
import {
    Box,
    TableContainer,
    Typography,
    TableCell,
    Table,
    TableRow,
    LinearProgress,
    TableBody,
    TableHead,
    ClickAwayListener,
    Input,
} from "@material-ui/core"
import { Loading } from "../Loading"
import {
    useTheme,
    makeStyles,
    Theme,
    withStyles,
    createStyles,
} from "@material-ui/core/styles"
import { useApolloClient, useMutation, gql } from "@apollo/client"
import {
    CREATE_CRITERIA_SCORE,
    ADD_CRITERIA_SCORE_CRITERIA,
    UPDATE_CRITERIA_SCORE,
    ADD_CRITERIA_SCORE_SCORED_CONCEPT,
} from "../criteria/graphql"
import {
    CreateCriteriaScoreMutation,
    UpdateCriteriaScoreMutation,
    UpdateCriteriaScoreMutationVariables,
    CreateCriteriaScoreMutationVariables,
    AddCriteriaScoreCriteriaMutation,
    AddCriteriaScoreCriteriaMutationVariables,
    AddCriteriaScoreScoredConceptMutation,
    AddCriteriaScoreScoredConceptMutationVariables,
} from "../criteria/__generated__/graphql"
import { Concept, Criteria, CriteriaScore } from "../../__generated__/types"

const useStyles = makeStyles((theme) => ({
    clickable: {
        cursor: "pointer",
        "&:hover": {
            backgroundColor: theme.palette.action.hover,
            borderRadius: "4px",
        },
    },
}))

const BorderLinearProgress = withStyles((theme: Theme) =>
    createStyles({
        root: {
            borderRadius: theme.shape.borderRadius,
            height: "1em",
        },
    })
)(LinearProgress)

interface DefaultScoringCriteriaEditorProps {
    criteria: Criteria[]
    concept: Concept
    onRefetch: () => Promise<void>
}
const DefaultScoringCriteriaEditor = (
    props: DefaultScoringCriteriaEditorProps
) => {
    const { criteria, concept, onRefetch } = props

    //mutations
    const [createScore] = useMutation<
        CreateCriteriaScoreMutation,
        CreateCriteriaScoreMutationVariables
    >(CREATE_CRITERIA_SCORE)
    const [addScoreToCriteria] = useMutation<
        AddCriteriaScoreCriteriaMutation,
        AddCriteriaScoreCriteriaMutationVariables
    >(ADD_CRITERIA_SCORE_CRITERIA)
    const [addScoreToConcept] = useMutation<
        AddCriteriaScoreScoredConceptMutation,
        AddCriteriaScoreScoredConceptMutationVariables
    >(ADD_CRITERIA_SCORE_SCORED_CONCEPT)

    //callbacks
    const handleScoresCreation = useCallback(
        async (criteria: Criteria[]) => {
            await Promise.all([
                ...criteria?.map(async (criteria) => {
                    const {
                        data: { CreateCriteriaScore: NewScore },
                    } = await createScore({
                        variables: {
                            response: null,
                            conceptDefault: true,
                        },
                    })
                    await Promise.all([
                        addScoreToConcept({
                            variables: {
                                conceptId: concept?.id,
                                criteriaScoreId: NewScore.id,
                            },
                        }),
                        addScoreToCriteria({
                            variables: {
                                criteriaId: criteria?.id,
                                criteriaScoreId: NewScore.id,
                            },
                        }),
                    ])
                    return
                }),
            ])
            await onRefetch()
        },
        [addScoreToConcept, addScoreToCriteria, createScore, concept, onRefetch]
    )

    //useEffects
    useEffect(() => {
        const scoresNeedingCreated = criteria
            ?.map((item) => {
                if (
                    !concept.criteriaScores?.find(
                        (score) => score.criteria?.name === item.name
                    )
                ) {
                    return item
                } else return null
            })
            ?.filter((x) => !!x)

        if (scoresNeedingCreated.length > 0) {
            handleScoresCreation(scoresNeedingCreated)
        }
    }, [criteria, concept, handleScoresCreation])

    //variables
    const scores = concept.criteriaScores ?? []
    const scoresNeedingCreated = criteria
        ?.map((item) => {
            if (
                !concept.criteriaScores?.find(
                    (score) => score.criteria?.name === item.name
                )
            ) {
                return item
            } else return null
        })
        ?.filter((x) => !!x)
    if (scoresNeedingCreated.length > 0) {
        return <Loading size={25} hideQuote={true} />
    }

    return (
        <TableContainer
            style={{
                height: "100%",
            }}
        >
            <Table size="small" stickyHeader>
                <TableHead>
                    <TableRow>
                        <TableCell size="small">Name</TableCell>
                        <TableCell size="small">Score</TableCell>
                        <TableCell size="medium"></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {scores?.map((score) => {
                        return (
                            <TableRow key={score.id}>
                                <TableCell
                                    size="small"
                                    style={{ flexShrink: 0 }}
                                >
                                    <Typography noWrap variant="body2">
                                        {score.criteria?.name}
                                    </Typography>
                                </TableCell>

                                <NumberCells score={score} />
                            </TableRow>
                        )
                    })}
                </TableBody>
            </Table>
        </TableContainer>
    )
}

interface NumberCellsProps {
    score: CriteriaScore
}
const NumberCells = (props: NumberCellsProps) => {
    const { score } = props
    const client = useApolloClient()
    const classes = useStyles()
    const theme = useTheme()
    const [updateScore] = useMutation<
        UpdateCriteriaScoreMutation,
        UpdateCriteriaScoreMutationVariables
    >(UPDATE_CRITERIA_SCORE)
    const [value, setValue] = useState(null)
    const [editing, setEditing] = useState(false)
    const handleScoreUpdate = async () => {
        const response = value
        client.writeFragment({
            id: client.cache.identify(score),
            fragment: gql`
                fragment NewDefaultScoringResponse on CriteriaScore {
                    response
                }
            `,
            data: {
                response: response,
            },
        })
        updateScore({
            variables: {
                id: score.id,
                response: response,
            },
        })
    }
    useEffect(() => {
        if (!!score?.response && !value) {
            setValue(score?.response)
        }
    }, [score?.response, value])
    return (
        <>
            <ClickAwayListener
                onClickAway={() => {
                    setEditing(false)
                }}
            >
                <TableCell size="small" className={classes.clickable}>
                    {editing ? (
                        <Input
                            onChange={(e) => {
                                if (Number(e.target.value) > 10) {
                                    setValue("10")
                                } else {
                                    setValue(e.target.value)
                                }
                            }}
                            margin="dense"
                            inputProps={{
                                style: {
                                    fontSize: theme.typography.body2.fontSize,
                                },
                                step: 0.1,
                                min: 0,
                                max: 10,
                                type: "number",
                            }}
                            onKeyDown={(e) => {
                                if (e.key === "Enter") {
                                    handleScoreUpdate()
                                    setEditing(false)
                                }
                            }}
                            type="number"
                            value={value ?? "0"}
                            autoFocus
                            onBlur={(e) => {
                                handleScoreUpdate()
                            }}
                        />
                    ) : (
                        <Box
                            width="100%"
                            height="100%"
                            onClick={() => {
                                setEditing(true)
                            }}
                        >
                            <Typography variant="body2">
                                {value ?? "0"}
                            </Typography>
                        </Box>
                    )}
                </TableCell>
            </ClickAwayListener>
            <TableCell
                size="medium"
                style={{
                    width: "100%",
                    padding: theme.spacing(1),
                }}
            >
                <Box width="100%">
                    <BorderLinearProgress
                        variant="determinate"
                        value={Number(value * 10 ?? "0")}
                        color="primary"
                    />
                </Box>
            </TableCell>
        </>
    )
}

export default DefaultScoringCriteriaEditor
