import React, { useState, useEffect } from "react"
import { makeStyles, Theme } from "@material-ui/core/styles"
import Box from "@material-ui/core/Box"
import TextField from "@material-ui/core/TextField"
import Autocomplete from "@material-ui/lab/Autocomplete"
import { gql, useApolloClient, useLazyQuery, useMutation } from "@apollo/client"
import CircularProgress from "@material-ui/core/CircularProgress"
import InputAdornment from "@material-ui/core/InputAdornment"
import Chip from "@material-ui/core/Chip"
import {
    ADD_CONCEPT_INDUSTRY,
    REMOVE_CONCEPT_INDUSTRY,
    ALL_INDUSTRY_QUERY,
    ADD_CONCEPT_INDUSTRY_NO_RESPONSE,
    REMOVE_CONCEPT_INDUSTRY_NO_RESPONSE,
} from "./graphql"
import {
    IndustryQuery,
    IndustryQueryVariables,
    AddConceptIndustriesMutation,
    AddConceptIndustriesMutationVariables,
    RemoveConceptIndustriesMutation,
    RemoveConceptIndustriesMutationVariables,
    AddConceptIndustriesNoResponseMutation,
    AddConceptIndustriesNoResponseMutationVariables,
    RemoveConceptIndustriesNoResponseMutation,
    RemoveConceptIndustriesNoResponseMutationVariables,
} from "./__generated__/graphql"
import { Concept, Industry } from "../../__generated__/types"
import useAwaitTranslation from "../../i18n/useAwaitTranslation"
import Tooltip from "@material-ui/core/Tooltip"
import { Checkbox } from "@material-ui/core"
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank"
import CheckBoxIcon from "@material-ui/icons/CheckBox"
import { Loading } from "../Loading"
import ErrorHandler from "../ErrorHandler"

const uncheckedIcon = <CheckBoxOutlineBlankIcon fontSize="small" />
const checkedIcon = <CheckBoxIcon fontSize="small" />
const useStyles = makeStyles((theme: Theme) => ({
    root: {
        display: "flex",
        flexDirection: "column",
        padding: theme.spacing(1),
        height: "100%",
    },
    listbox: {
        overflow: "auto",
    },
    label: {
        fontSize: 12,
        color: theme.palette.text.secondary,
        paddingBottom: theme.spacing(0.5),
    },
    clickable: {
        borderRadius: theme.shape.borderRadius,
        "&:hover": {
            backgroundColor: theme.palette.action.hover,
        },
        cursor: "pointer",
        padding: theme.spacing(1),
        width: "100%",
    },
    locked: {
        padding: theme.spacing(1),
        height: "100%",
        width: "100%",
    },
    input: {
        padding: theme.spacing(1),
    },
    showMore: {
        display: "inline-flex",
        color: theme.palette.secondary.main,
    },
}))

interface IndustryListProps {
    concept: Concept
    editable: boolean
}

const GetSortedValue = (industries: Industry[]) => {
    return [...industries]?.sort(function (a, b) {
        var textA = a.name?.toUpperCase()
        var textB = b.name?.toUpperCase()
        return textA < textB ? -1 : textA > textB ? 1 : 0
    })
}

export default function IndustryList(props: IndustryListProps) {
    //hooks
    const classes = useStyles()
    const client = useApolloClient()
    const { t } = useAwaitTranslation("industry")

    //queries
    const [loadIndustries, { data, error, loading, called }] = useLazyQuery<
        IndustryQuery,
        IndustryQueryVariables
    >(ALL_INDUSTRY_QUERY)

    //state
    const [editing, setEditing] = useState(false)
    const [value, setValue] = useState<Industry[]>([])
    const [updatesLoading, setUpdatesLoading] = useState(false)
    const [options, setOptions] = useState<Industry[] | null>(null)

    //mutations
    const [addIndustry] = useMutation<
        AddConceptIndustriesMutation,
        AddConceptIndustriesMutationVariables
    >(ADD_CONCEPT_INDUSTRY)
    const [removeIndustry] = useMutation<
        RemoveConceptIndustriesMutation,
        RemoveConceptIndustriesMutationVariables
    >(REMOVE_CONCEPT_INDUSTRY)
    const [addIndustryButIgnoreResults] = useMutation<
        AddConceptIndustriesNoResponseMutation,
        AddConceptIndustriesNoResponseMutationVariables
    >(ADD_CONCEPT_INDUSTRY_NO_RESPONSE)
    const [removeIndustryButIgnoreResults] = useMutation<
        RemoveConceptIndustriesNoResponseMutation,
        RemoveConceptIndustriesNoResponseMutationVariables
    >(REMOVE_CONCEPT_INDUSTRY_NO_RESPONSE)

    //useEffects
    useEffect(() => {
        if (!updatesLoading) {
            setValue(GetSortedValue(props.concept.industries))
        }
    }, [props.concept.industries, updatesLoading])
    useEffect(() => {
        if (data) {
            setOptions(data.Industry)
        }
    }, [data, setOptions, value])

    //variables
    const allSelected = value?.length >= options?.length

    //functions
    const handleSelectAll = async (isSelected) => {
        setUpdatesLoading(true)
        if (isSelected) {
            client.cache.modify({
                id: client.cache.identify(props.concept),
                fields: {
                    industries(existingIndustries = [], { readField }) {
                        const refArray = options.map((option) => {
                            return client.cache.writeFragment({
                                data: option,
                                fragment: gql`
                                    fragment UpdatedIndustry on Industry {
                                        industryId
                                    }
                                `,
                            })
                        })

                        return [...refArray]
                    },
                },
            })
            const optionsToAdd = options.filter(
                (op) =>
                    !props.concept.industries.find(
                        (i) => i.industryId === op.industryId
                    )
            )
            await Promise.all([
                ...optionsToAdd.map((op) => {
                    return addIndustryButIgnoreResults({
                        variables: {
                            id: props.concept.id,
                            industryId: op.industryId,
                        },
                    }).catch((e) => {
                        client.cache.modify({
                            id: client.cache.identify(props.concept),
                            fields: {
                                industries(
                                    existingIndustries = [],
                                    { readField }
                                ) {
                                    return [
                                        ...existingIndustries.filter(
                                            (ref) =>
                                                readField("industryId", ref) !==
                                                op.industryId
                                        ),
                                    ]
                                },
                            },
                        })
                        setValue(GetSortedValue(props.concept.industries))
                    })
                }),
            ])

            setValue(options)
        } else {
            client.cache.modify({
                id: client.cache.identify(props.concept),
                fields: {
                    industries(existingIndustries = [], { readField }) {
                        return []
                    },
                },
            })
            await Promise.all([
                ...props.concept.industries.map((industry) => {
                    return removeIndustryButIgnoreResults({
                        variables: {
                            id: props.concept.id,
                            industryId: industry.industryId,
                        },
                    }).catch((e) => {
                        client.cache.modify({
                            id: client.cache.identify(props.concept),
                            fields: {
                                industries(
                                    existingIndustries = [],
                                    { readField }
                                ) {
                                    const industryRef = client.cache.writeFragment(
                                        {
                                            data: industry,
                                            fragment: gql`
                                                fragment AddedIndustry on Industry {
                                                    industryId
                                                }
                                            `,
                                        }
                                    )
                                    if (
                                        existingIndustries.some(
                                            (ref) =>
                                                readField("industryId", ref) ===
                                                industry.industryId
                                        )
                                    ) {
                                        return existingIndustries
                                    }
                                    return [...existingIndustries, industryRef]
                                },
                            },
                        })
                        setValue(GetSortedValue(props.concept.industries))
                    })
                }),
            ])

            setValue([])
        }
        setUpdatesLoading(false)
    }
    const handleToggleSelectAll = () => {
        handleSelectAll && handleSelectAll(!allSelected)
    }

    //query handling
    if (error) {
        return <ErrorHandler showMessage={false} />
    }
    if (updatesLoading) {
        return <Loading hideQuote={true} />
    }
    return editing ? (
        <Autocomplete
            options={options ?? []}
            multiple
            fullWidth
            className={classes.input}
            onFocus={() => {
                if (!called) {
                    loadIndustries()
                }
            }}
            onBlur={() => setEditing(false)}
            classes={{
                listbox: classes.listbox,
            }}
            getOptionLabel={(option) => option.name}
            getOptionSelected={(option, value) =>
                value.industryId === option.industryId
            }
            filterOptions={(options, params) => {
                const filtered = options
                const selectAll = {
                    industryId: "SELECT_ALL",
                    name: "Select All Industries",
                }
                filtered.unshift(selectAll)
                return filtered
            }}
            value={value}
            ChipProps={{
                color: "secondary",
                size: "small",
            }}
            size="small"
            openOnFocus
            disableCloseOnSelect
            renderOption={(option, { selected }) => {
                const selectAllProps =
                    option.industryId === "SELECT_ALL"
                        ? { checked: allSelected }
                        : {}
                return (
                    <Box key={option.industryId}>
                        <Checkbox
                            icon={uncheckedIcon}
                            checkedIcon={checkedIcon}
                            style={{ marginRight: 8 }}
                            checked={selected}
                            {...selectAllProps}
                        />
                        {option.name}
                    </Box>
                )
            }}
            loading={loading}
            loadingText={t("loadingIndustries", "Loading industries...")}
            noOptionsText={t(
                "noIndustriesMatch",
                "No industries that match..."
            )}
            disableClearable
            onChange={(event: any, newValue: Industry[], reason: string) => {
                if (newValue.find((i) => i.industryId === "SELECT_ALL")) {
                    handleToggleSelectAll()
                    return
                }
                setValue(GetSortedValue(newValue))
                if (reason === "select-option") {
                    newValue.forEach((item) => {
                        if (props.concept.industries.indexOf(item) === -1) {
                            addIndustry({
                                variables: {
                                    id: props.concept.id,
                                    industryId: item.industryId,
                                },
                            }).catch((e) => {
                                setValue(
                                    GetSortedValue(props.concept.industries)
                                )
                            })
                        }
                    })
                } else if (reason === "remove-option") {
                    props.concept.industries.forEach((item) => {
                        if (newValue.indexOf(item) === -1) {
                            removeIndustry({
                                variables: {
                                    id: props.concept.id,
                                    industryId: item.industryId,
                                },
                            }).catch((e) => {
                                setValue(
                                    GetSortedValue(props.concept.industries)
                                )
                            })
                        }
                    })
                }
            }}
            renderInput={(params) => (
                <TextField
                    {...params}
                    autoFocus
                    multiline
                    variant="standard"
                    label={t("industries", "Industries")}
                    placeholder={t("searchIndustries", "Search Industries")}
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: loading && (
                            <>
                                <InputAdornment position="end">
                                    <CircularProgress disableShrink size={25} />
                                </InputAdornment>
                                {params.InputProps.startAdornment}
                            </>
                        ),
                    }}
                />
            )}
        />
    ) : (
        <Box
            className={props.editable ? classes.clickable : classes.locked}
            onClick={() => {
                if (props.editable) {
                    setEditing(true)
                }
            }}
        >
            <Box className={classes.label}>{t("industries", "Industries")}</Box>
            <Box display="flex" flexWrap="wrap" overflow="hidden">
                {value &&
                    value.length > 0 &&
                    value.map((item, idx) => {
                        return (
                            <Tooltip title={item.name} key={item.industryId}>
                                <Chip
                                    key={item.industryId + "-chip"}
                                    color="secondary"
                                    label={
                                        <span
                                            style={{
                                                whiteSpace: "nowrap",
                                                overflow: "hidden",
                                                textOverflow: "ellipsis",
                                            }}
                                        >
                                            {item.name}
                                        </span>
                                    }
                                    style={{
                                        margin: "2px",
                                        cursor: props.editable
                                            ? "pointer"
                                            : "default",
                                        display: "flex",
                                        justifyContent: "flex-start",
                                        overflow: "hidden",
                                    }}
                                    size="small"
                                />
                            </Tooltip>
                        )
                    })}
            </Box>
        </Box>
    )
}
