import { Box, Divider, Typography } from "@material-ui/core"
import { CriteriaScore } from "../../../__generated__/types"

import { GroupingType } from "../../criteria/useCriteriaTypes"

import { useInputTools } from "../useInputTools"
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd"
import { useCallback, useEffect, useReducer, useState } from "react"
import { useTheme } from "@material-ui/core/styles"
import ConceptChip from "../../ConceptChip"
import DroppableGroups from "./DroppableGroups"
import { produce } from "immer"
import { Layout } from "react-grid-layout"
import { useReactiveVar } from "@apollo/client"
import { myCurrentInputResponseLabelVar } from "../../../providers/GlobalState"
import { ScoreSelectorDefaultValues } from "../types"
import { GroupingResponse, GroupingResponseWithConcepts } from "./types"
/// ONLY TEMPORARY DUPLICATE LOGIC
/// NEEDS TO MATCH LOGIC OF REDUCER
const getMovedState = (state, action) => {
    //deep copy since structuredClone isnt available in our current typescript version
    let currentState = JSON.parse(JSON.stringify(state))
    const fromGroup = currentState.find((obj) => obj.group.id === action.from)
    fromGroup.concepts = fromGroup.concepts || []
    const toGroup = currentState.find((obj) => obj.group.id === action.to)
    toGroup.concepts = toGroup.concepts || []
    const [removed] = fromGroup.concepts.splice(action.fromIndex, 1)
    toGroup.concepts.splice(action.toIndex, 0, removed)
    return currentState
}
const getGroupNameChangedState = (state, action) => {
    let currentState = JSON.parse(JSON.stringify(state))
    const groupToRename = currentState.find(
        (obj) => obj.group.id === action.groupId
    )
    groupToRename.group.value = action.newName
    return currentState
}

const getGroupRemovedState = (state, action) => {
    let currentState = JSON.parse(JSON.stringify(state))
    const groupToRemove = currentState.find(
        (obj) => obj.group.id === action.groupIdToRemove
    )
    const groupToAddConceptsTo = currentState.find(
        (obj) => obj.group.id === action.groupIdToAddConceptsTo
    )
    groupToAddConceptsTo.concepts = [
        ...groupToAddConceptsTo.concepts,
        ...groupToRemove.concepts,
    ]
    currentState = currentState.filter(
        (obj) => obj.group.id !== action.groupIdToRemove
    )
    return currentState
}
const getGroupAddedState = (state, action) => {
    let currentState = JSON.parse(JSON.stringify(state))
    currentState.push({
        group: { value: action.groupToAdd, id: JSON.stringify(Date.now()) },
        layoutConfiguration: null,
        concepts: [],
    })
    return currentState
}
const dragReducer = produce((draft, action) => {
    switch (action.type) {
        case "MOVE": {
            const fromGroup = draft.find((obj) => obj.group.id === action.from)
            fromGroup.concepts = fromGroup.concepts || []
            const toGroup = draft.find((obj) => obj.group.id === action.to)
            toGroup.concepts = toGroup.concepts || []
            const [removed] = fromGroup.concepts.splice(action.fromIndex, 1)
            toGroup.concepts.splice(action.toIndex, 0, removed)
            break
        }

        case "RENAME GROUP": {
            const groupToRename = draft.find(
                (obj) => obj.group.id === action.groupId
            )
            groupToRename.group.value = action.newName
            break
        }

        case "REMOVE GROUP": {
            const groupToRemove = draft.find(
                (obj) => obj.group.id === action.groupIdToRemove
            )
            const groupToAddConceptsTo = draft.find(
                (obj) => obj.group.id === action.groupIdToAddConceptsTo
            )

            groupToAddConceptsTo.concepts = [
                ...groupToAddConceptsTo.concepts,
                ...groupToRemove.concepts,
            ]
            const indexToRemove = draft.findIndex(
                (item) => item.group.id === action.groupIdToRemove
            )
            if (indexToRemove !== -1) draft.splice(indexToRemove, 1)
            break
        }

        case "ADD GROUP": {
            draft.push({
                group: {
                    value: action.groupToAdd,
                    id: JSON.stringify(Date.now()),
                },
                layoutConfiguration: null,
                concepts: [],
            })
            break
        }
    }
})
const DroppableContainer = (props: {
    score?: CriteriaScore
    groups?: GroupingResponseWithConcepts[]
    config: GroupingType
    inputId: string
    viewingResults: boolean
}) => {
    const { score, groups, config, inputId, viewingResults } = props

    const currentLabel = useReactiveVar(myCurrentInputResponseLabelVar)
    const viewingPrimaryResponse =
        currentLabel.label === ScoreSelectorDefaultValues.primaryResponse &&
        !currentLabel.user
    const viewingAllResponses =
        currentLabel.label === ScoreSelectorDefaultValues.allResponses &&
        !currentLabel.user
    const theme = useTheme()
    const { onEditResponse } = useInputTools({})

    const [state, dispatch] = useReducer(dragReducer, groups)
    const [layout, setLayout] = useState<Layout[]>(null)

    const onCleanAndEditScore = useCallback(
        (newState: any, newLayout?: Layout[]) => {
            if (!!score) {
                const layoutToUpdateWith = newLayout || layout

                const updatedResponse: GroupingResponse = newState.map(
                    (item) => {
                        return {
                            group:
                                item.group.id !== inputId ? item.group : null,
                            layoutConfiguration:
                                layoutToUpdateWith.find(
                                    (layoutItem) =>
                                        layoutItem.i === item.group.id
                                ) || null,
                            conceptIds: item.concepts?.map(
                                (concept) => concept.id
                            ),
                        }
                    }
                )

                onEditResponse(
                    {
                        response: JSON.stringify(updatedResponse),
                    },
                    score
                )
            }
        },
        [score, onEditResponse, layout, inputId]
    )

    const onGroupNameChange = useCallback(
        (groupId: string, newName: string) => {
            const newState = getGroupNameChangedState(state, {
                groupId,
                newName,
            })
            onCleanAndEditScore(newState)
            dispatch({ type: "RENAME GROUP", groupId, newName })
        },
        [onCleanAndEditScore, state]
    )
    const onGroupRemoval = useCallback(
        (groupIdToRemove: string) => {
            const newState = getGroupRemovedState(state, {
                groupIdToRemove: groupIdToRemove,
                groupIdToAddConceptsTo: inputId,
            })

            onCleanAndEditScore(newState)
            dispatch({
                type: "REMOVE GROUP",
                groupIdToRemove,
                groupIdToAddConceptsTo: inputId,
            })
        },
        [onCleanAndEditScore, inputId, state]
    )
    const onGroupAddition = useCallback(
        (groupToAdd: string) => {
            const newState = getGroupAddedState(state, {
                type: "ADD GROUP",
                groupToAdd,
            })
            onCleanAndEditScore(newState)
            dispatch({
                type: "ADD GROUP",
                groupToAdd,
            })
        },
        [state, onCleanAndEditScore]
    )
    const onLayoutChange = useCallback(
        (newLayout: Layout[]) => {
            setLayout(newLayout)

            onCleanAndEditScore(state, newLayout)
        },
        [onCleanAndEditScore, state]
    )
    const onDragEnd = useCallback(
        (result) => {
            if (result.reason === "DROP") {
                if (!result.destination) {
                    return
                }

                const newState = getMovedState(state, {
                    type: "MOVE",
                    from: result.source.droppableId,
                    to: result.destination.droppableId,
                    fromIndex: result.source.index,
                    toIndex: result.destination.index,
                })
                onCleanAndEditScore(newState)
                dispatch({
                    type: "MOVE",
                    from: result.source.droppableId,
                    to: result.destination.droppableId,
                    fromIndex: result.source.index,
                    toIndex: result.destination.index,
                })
            }
        },
        [onCleanAndEditScore, state]
    )

    useEffect(() => {
        if (!layout) {
            if (
                groups.filter((group) => !group.layoutConfiguration).length ===
                    groups.length ||
                viewingAllResponses
            ) {
                setLayout(config.groupLayout)
            } else {
                setLayout(
                    groups
                        .map((item) => item.layoutConfiguration)
                        ?.filter((x) => !!x)
                )
            }
        }
    }, [layout, groups, config.groupLayout, viewingAllResponses])
    const conceptsWithNoGroup =
        state.find((item) => item.group.id === inputId)?.concepts ?? []
    return (
        <DragDropContext onDragEnd={onDragEnd}>
            <Box display="flex" width="100%" height="100%" overflow="hidden">
                {!viewingResults && (
                    <>
                        <Box
                            width={250}
                            height="100%"
                            overflow="hidden"
                            display="flex"
                            flexDirection={"column"}
                        >
                            <Box p={0.5}>
                                <Typography
                                    variant="body2"
                                    color="textSecondary"
                                >
                                    Ungrouped Concepts
                                </Typography>
                            </Box>
                            <Box style={{ overflowY: "auto" }} flexGrow={1}>
                                <Droppable
                                    droppableId={inputId}
                                    type="CONCEPT"
                                    renderClone={(
                                        provided,
                                        snapshot,
                                        rubric
                                    ) => (
                                        <div
                                            style={{
                                                width: "100%",
                                                height: "100%",
                                            }}
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                        >
                                            <ConceptChip
                                                item={
                                                    conceptsWithNoGroup[
                                                        rubric.source.index
                                                    ]
                                                }
                                                disableClick={true}
                                            />
                                        </div>
                                    )}
                                >
                                    {(provided, snapshot) => (
                                        <div
                                            ref={provided.innerRef}
                                            {...provided.droppableProps}
                                            style={{
                                                width: "100%",
                                                height: "100%",
                                                backgroundColor:
                                                    snapshot.isDraggingOver
                                                        ? theme.palette.action
                                                              .hover
                                                        : "transparent",
                                                padding: theme.spacing(0.5),
                                            }}
                                        >
                                            {conceptsWithNoGroup.map(
                                                (concept, index) => {
                                                    return (
                                                        <Draggable
                                                            key={concept.id}
                                                            draggableId={
                                                                concept.id
                                                            }
                                                            index={index}
                                                        >
                                                            {(
                                                                provided,
                                                                snapshot
                                                            ) => (
                                                                <div
                                                                    ref={
                                                                        provided.innerRef
                                                                    }
                                                                    key={
                                                                        concept.id
                                                                    }
                                                                    {...provided.draggableProps}
                                                                    {...provided.dragHandleProps}
                                                                >
                                                                    <Box
                                                                        p={0.5}
                                                                    >
                                                                        <ConceptChip
                                                                            item={
                                                                                concept
                                                                            }
                                                                            disableClick={
                                                                                true
                                                                            }
                                                                        />
                                                                    </Box>
                                                                </div>
                                                            )}
                                                        </Draggable>
                                                    )
                                                }
                                            )}
                                        </div>
                                    )}
                                </Droppable>
                            </Box>
                        </Box>
                        <Divider orientation="vertical" flexItem />
                    </>
                )}
                <DroppableGroups
                    responseWithConcepts={state.filter(
                        (item) => item.group.id !== inputId
                    )}
                    inputId={inputId}
                    config={config}
                    viewingResults={viewingResults}
                    inputConfigurationIsEditable={
                        !viewingResults && !!viewingPrimaryResponse
                    }
                    onGroupNameChange={onGroupNameChange}
                    layout={layout}
                    setLayout={onLayoutChange}
                    onGroupRemoval={onGroupRemoval}
                    onGroupAddition={onGroupAddition}
                />
            </Box>
        </DragDropContext>
    )
}

export default DroppableContainer
