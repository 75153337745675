import { ListItem, ListItemText } from "@material-ui/core"
import useAwaitTranslation from "../../../i18n/useAwaitTranslation"

import { Concept } from "../../../__generated__/types"

const ListItemDeserializer = (props: { concept: Concept }) => {
    const { concept } = props
    const { i18n } = useAwaitTranslation()
    const selectedLangString = i18n?.language
    let translatedContent =
        concept?.translations?.find(
            (t) => t.languageString === selectedLangString
        ) || null
    const title = translatedContent?.title || concept.title
    const summary = translatedContent?.summary || concept.summary || ""

    return (
        <ListItem key={concept.id}>
            <ListItemText
                style={{
                    display: "list-item",
                    listStyleType: "disc",
                }}
                primary={`${title} ${!!summary ? "||" : ""}  ${summary}`}
            />
        </ListItem>
    )
}

export default ListItemDeserializer
