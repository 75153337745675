import { FormControl, Select, InputLabel, MenuItem } from "@material-ui/core"
import useAwaitTranslation from "../../i18n/useAwaitTranslation"
import { toCapitalizedWords } from "../../util/fns"
import { InputSourceResponseType } from "../criteria/useCriteriaTypes"

const ResponseTypeSelector = (props: {
    value: InputSourceResponseType
    onChange: (value: InputSourceResponseType) => void
}) => {
    const { value, onChange } = props
    const { t } = useAwaitTranslation("criteria")
    return (
        <FormControl fullWidth variant="outlined" size="small">
            <InputLabel>
                {t("responseEvaluated", "Response Evaluated")}
            </InputLabel>
            <Select
                value={value}
                label={t("responseEvaluated", "Response Evaluated")}
                onChange={(event) => {
                    onChange(event.target.value as InputSourceResponseType)
                }}
            >
                {Object.keys(InputSourceResponseType).map((value) => (
                    <MenuItem value={value} key={value}>
                        {t(value, toCapitalizedWords(value))}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    )
}

export default ResponseTypeSelector
