import React, { useState } from "react"
import { makeStyles, useTheme } from "@material-ui/core/styles"
import {
    Box,
    Dialog,
    DialogContent,
    Button,
    DialogActions,
    IconButton,
    TextField,
    Tooltip,
    ListItem,
    Chip,
    Link,
    Checkbox,
    List,
    DialogTitle,
    CircularProgress,
    Typography,
    InputAdornment,
    Grow,
} from "@material-ui/core"
import { toCapitalizedWords } from "../util/fns"
import {
    UPDATE_CONCEPT,
    ADD_CONCEPT_WEB_LINK,
    CREATE_LINK,
    REMOVE_CONCEPT_WEB_LINK,
} from "../graphql/mutations"
import LinkIcon from "@material-ui/icons/Link"
import {
    RemoveConceptWebLinkMutation,
    RemoveConceptWebLinkMutationVariables,
    UpdateConceptMutation,
    UpdateConceptMutationVariables,
} from "../graphql/__generated__/mutations"
import { CONCEPT_BY_ID } from "../graphql/queries"
import { useMutation } from "@apollo/client"
import { Concept, Link as LinkType } from "../__generated__/types"
import { useAuth } from "../providers/AuthProvider"
import {
    CreateLinkMutation,
    CreateLinkMutationVariables,
} from "../graphql/__generated__/mutations"
import {
    AddConceptWebLinkMutation,
    AddConceptWebLinkMutationVariables,
} from "../graphql/__generated__/mutations"
import SendIcon from "@material-ui/icons/Send"
import ClearIcon from "@material-ui/icons/Clear"
import DeleteIcon from "@material-ui/icons/Delete"
import { isValidHttpUrl } from "../util/fns"
import useAwaitTranslation from "../i18n/useAwaitTranslation"
const useStyles = makeStyles((theme) => ({
    editable: {
        "&:hover": {
            backgroundColor: theme.palette.action.hover,
            borderRadius: "4px",
            cursor: "pointer",
        },
    },
    label: {
        fontSize: 12,
        color: theme.palette.text.secondary,
        margin: theme.spacing(0.5, 0),
    },
    list: {
        border: `1px solid ${theme.palette.divider}`,
        borderRadius: theme.shape.borderRadius,
        margin: theme.spacing(1),
    },
    clickable: {
        "&:hover": {
            backgroundColor: theme.palette.action.hover,
            borderRadius: "4px",
            cursor: "pointer",
        },
    },
}))

interface WebLinkEditorProps {
    editing: boolean
    item: Concept
}

export default function WebLinkEditor(props: WebLinkEditorProps) {
    const { editing, item } = props
    //hooks
    const classes = useStyles()
    const { currentUser } = useAuth()
    const theme = useTheme()
    const { t } = useAwaitTranslation("concepts")
    //state
    const [retrievedLink, setRetrievedLink] = useState<LinkType | null>(null)
    const [searchedUrl, setSearchedUrl] = useState("")
    const [error, setError] = useState(false)
    const [saving, setSaving] = useState(false)
    const [open, setOpen] = useState(false)
    const [removingLink, setRemovingLink] = useState(false)
    const [usedData, setUsedData] = useState({
        title: false,
        summary: false,
    })

    //mutations
    const [updateConcept] = useMutation<
        UpdateConceptMutation,
        UpdateConceptMutationVariables
    >(UPDATE_CONCEPT, {
        refetchQueries: [
            {
                query: CONCEPT_BY_ID,
                variables: { id: item.id },
            },
        ],
    })
    const [addConceptWebLink] = useMutation<
        AddConceptWebLinkMutation,
        AddConceptWebLinkMutationVariables
    >(ADD_CONCEPT_WEB_LINK)
    const [removeConceptWebLink] = useMutation<
        RemoveConceptWebLinkMutation,
        RemoveConceptWebLinkMutationVariables
    >(REMOVE_CONCEPT_WEB_LINK)
    const [addLink, { loading: addingLink }] = useMutation<
        CreateLinkMutation,
        CreateLinkMutationVariables
    >(CREATE_LINK)

    //functions
    const handleSubmission = async (e) => {
        setSaving(true)
        let dataObject = {
            id: item.id,
        }
        Object.keys(usedData).map((field) => {
            if (!!usedData[field]) {
                if (field === "summary") {
                    dataObject[field] =
                        retrievedLink.type && retrievedLink.provider
                            ? `${toCapitalizedWords(
                                  retrievedLink.type
                              )} from ${toCapitalizedWords(
                                  retrievedLink.provider
                              )}`
                            : ""
                } else {
                    dataObject[field] = retrievedLink[field]
                }
            }
            return dataObject
        })
        let promiseArray = [
            addConceptWebLink({
                variables: {
                    conceptId: item?.id,
                    linkId: retrievedLink?.id,
                },
            }),
        ]

        if (Object.keys(dataObject).length > 1) {
            promiseArray.push(
                updateConcept({
                    variables: {
                        userId: currentUser?.userId,
                        concept: {
                            ...dataObject,
                        },
                    },
                })
            )
        }
        await Promise.all(promiseArray)
        handleClose()
        setSaving(false)
    }
    const handleScrape = (ev) => {
        if (searchedUrl.length > 0) {
            // not using await due to it throwing typing error on the
            // response data - i believe it is due to it being a custom
            // cypher query
            addLink({ variables: { url: searchedUrl } })
                .then((res) => {
                    const newLink = res.data?.addLink
                    if (!!newLink) {
                        setRetrievedLink(newLink)
                    }
                })
                .catch((e) => console.log(e))
        }
    }
    const handleRemoval = async () => {
        setRemovingLink(true)
        await removeConceptWebLink({
            variables: {
                conceptId: item.id,
                linkId: item.webLink?.id,
            },
        })
        setRemovingLink(false)
    }
    const handleClear = () => {
        setRetrievedLink(null)
        setSearchedUrl("")
    }
    const handleClose = () => {
        setRetrievedLink(null)
        setSearchedUrl("")
        setOpen(false)
    }

    return (
        <>
            <Box
                p={1}
                width="100%"
                display="flex"
                flexDirection={"column"}
                overflow="hidden"
                position={"relative"}
                className={!!editing && !item.webLink ? classes.clickable : ""}
                onClick={(e) => {
                    if (!!editing && !item.webLink) {
                        setOpen(true)
                    }
                }}
            >
                {!!item.webLink && !!editing && (
                    <IconButton
                        onClick={handleRemoval}
                        size="small"
                        disabled={removingLink}
                        style={{ position: "absolute", right: 2, top: 2 }}
                    >
                        {!!removingLink ? (
                            <CircularProgress size={20} disableShrink />
                        ) : (
                            <DeleteIcon fontSize="small" />
                        )}
                    </IconButton>
                )}
                <Box
                    fontSize={12}
                    color={theme.palette.text.secondary}
                    paddingBottom={0.5}
                >
                    {t("linkToSouce", "Link to Source")}
                </Box>
                <Box overflow="hidden" display="flex">
                    <Grow in={!!item.webLink} mountOnEnter unmountOnExit>
                        <Chip
                            size="small"
                            style={{
                                margin: theme.spacing(0.25),
                                display: "flex",
                                justifyContent: "flex-start",
                                overflow: "hidden",
                            }}
                            label={
                                <Link
                                    href={item.webLink?.url}
                                    target="_blank"
                                    rel="noopener"
                                    style={{
                                        display: "flex",
                                        alignItems: "center",
                                    }}
                                    onClick={(e) => {
                                        e.stopPropagation()
                                    }}
                                >
                                    <LinkIcon
                                        style={{
                                            marginRight: ".5em",
                                        }}
                                    />
                                    <Typography noWrap variant="body2">
                                        {item.webLink?.url}
                                    </Typography>
                                </Link>
                            }
                        />
                    </Grow>
                </Box>
            </Box>
            <Dialog
                open={open}
                onBackdropClick={() => {
                    if (!saving) {
                        handleClose()
                    }
                }}
                onClose={handleClose}
                maxWidth="sm"
                fullWidth
            >
                <DialogTitle>
                    {t("webLinkEditor", "Web Link Editor")}
                </DialogTitle>
                <DialogContent>
                    <Box p={1}>
                        <TextField
                            variant="outlined"
                            fullWidth
                            id="link"
                            name="link"
                            label={t("newWebLinkUrl", "New Web Link URL")}
                            onChange={(e) => {
                                const isValidUrl = isValidHttpUrl(
                                    e.target.value
                                )
                                if (
                                    e.target.value.length > 0 &&
                                    !isValidUrl &&
                                    !error
                                ) {
                                    setError(true)
                                } else if (
                                    !!error &&
                                    (!!isValidHttpUrl ||
                                        e.target.value.length === 0)
                                ) {
                                    setError(false)
                                }
                                setSearchedUrl(e.target.value)
                            }}
                            error={!!error}
                            helperText={
                                !!error
                                    ? t(
                                          "entryNotValidUrl",
                                          "Entry is not a valid URL"
                                      )
                                    : ""
                            }
                            value={searchedUrl}
                            disabled={!!retrievedLink}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        {!!retrievedLink ? (
                                            <Tooltip
                                                title={t(
                                                    "clearWebLink",
                                                    "Clear Web Link"
                                                )}
                                            >
                                                <IconButton
                                                    size="small"
                                                    onClick={handleClear}
                                                >
                                                    <ClearIcon fontSize="small" />
                                                </IconButton>
                                            </Tooltip>
                                        ) : !!addingLink ? (
                                            <CircularProgress
                                                size={25}
                                                disableShrink
                                            />
                                        ) : (
                                            <Tooltip
                                                title={t(
                                                    "retrieveUrlMetadata",
                                                    "Retrieve URL Metadata"
                                                )}
                                            >
                                                <span>
                                                    <IconButton
                                                        size="small"
                                                        onClick={handleScrape}
                                                        disabled={
                                                            searchedUrl.length ===
                                                                0 || !!error
                                                        }
                                                    >
                                                        <SendIcon fontSize="small" />
                                                    </IconButton>
                                                </span>
                                            </Tooltip>
                                        )}
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Box>
                    {!!retrievedLink && (
                        <>
                            <Box ml={1.5} mt={3}>
                                <Typography variant="subtitle2">
                                    {t(
                                        "retrievedUrlMetadata",
                                        "Retrieved URL Metadata"
                                    )}
                                </Typography>
                            </Box>
                            <List className={classes.list}>
                                <ListItem divider>
                                    <Box flexGrow={1}>
                                        <Box className={classes.label}>
                                            {t("title", "Title")}
                                        </Box>
                                        <Box>{retrievedLink.title}</Box>
                                    </Box>

                                    <Checkbox
                                        checked={usedData.title}
                                        onChange={(e) => {
                                            setUsedData({
                                                ...usedData,
                                                title: e.target.checked,
                                            })
                                        }}
                                        disabled={!!saving}
                                    />
                                </ListItem>
                                <ListItem divider>
                                    <Box flexGrow={1}>
                                        <Box className={classes.label}>
                                            {t("summary", "Summary")}
                                        </Box>
                                        <Box>
                                            {retrievedLink.type &&
                                            retrievedLink.provider
                                                ? `${toCapitalizedWords(
                                                      retrievedLink.type
                                                  )} from ${toCapitalizedWords(
                                                      retrievedLink.provider
                                                  )}`
                                                : null}
                                        </Box>
                                    </Box>

                                    <Checkbox
                                        checked={usedData.summary}
                                        onChange={(e) => {
                                            setUsedData({
                                                ...usedData,
                                                summary: e.target.checked,
                                            })
                                        }}
                                        disabled={!!saving}
                                    />
                                </ListItem>
                            </List>
                        </>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>
                        {t("discard", "Discard")}
                    </Button>
                    <Button
                        disabled={!retrievedLink}
                        variant="contained"
                        color="primary"
                        onClick={handleSubmission}
                    >
                        {saving ? (
                            <CircularProgress size={25} disableShrink />
                        ) : (
                            t("save", "Save")
                        )}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}
