import React, { useCallback, useMemo } from "react"
import {
    Radar,
    RadarChart,
    PolarGrid,
    PolarAngleAxis,
    PolarRadiusAxis,
    ResponsiveContainer,
} from "recharts"
import { makeStyles } from "@material-ui/core/styles"
import { Box, Avatar, Typography, Divider } from "@material-ui/core"
import { CriteriaScore } from "../../__generated__/types"
import ClickableRichTooltip from "../Popper/ClickableRichTooltip"
import ConceptListItem from "../ConceptListItem"
import AssignmentIndIcon from "@material-ui/icons/AssignmentInd"
import { useTheme } from "@material-ui/core/styles"
import ResponseListItem from "../inputs/ResponseListItem"
const useStyles = makeStyles((theme) => ({
    radarChart: {
        "& svg": {
            overflow: "overlay !important",
        },
    },
}))

function areEqual(prevProps, nextProps) {
    if (prevProps.stages.length !== nextProps.stages.length) {
        return false
    }
    if (JSON.stringify(prevProps.scores) !== JSON.stringify(nextProps.scores)) {
        return false
    }
    return true
}

export type RadarChartResponse = {
    radialDistance: number
    radialIndex: number
}

interface RadarChartProps {
    labelType: "concept" | "user"
    stages: { id: string; value: string }[]
    scores: CriteriaScore[]
    onEditResponse?: (value: RadarChartResponse) => void
}
const RadarChartInterface: React.FunctionComponent<RadarChartProps> = ({
    labelType,
    stages,
    scores,
    onEditResponse,
}) => {
    //hooks
    const classes = useStyles()
    const theme = useTheme()
    //variables

    const data = useMemo(() => {
        const scoresWithResponses = scores?.filter((score) => !!score?.response)
        const controlData = Array.from({ length: 100 }, (_, i) => {
            const radialIndex = i + 1
            const fullMark = 100
            const obj = { radialIndex, fullMark }
            scoresWithResponses.forEach((score) => {
                const response: RadarChartResponse = JSON.parse(score?.response)
                if (obj.radialIndex === response.radialIndex) {
                    obj[score.id] = response.radialDistance
                }
            })
            return obj
        })
        return controlData
    }, [scores])

    const handleClick = useCallback(
        (data) => {
            if (!!data?.activeCoordinate && !!onEditResponse) {
                const radialDistance = Math.round(
                    (Math.hypot(
                        data.activeCoordinate.cx - data.chartX,
                        data.activeCoordinate.cy - data.chartY
                    ) /
                        data.activeCoordinate.outerRadius) *
                        100
                )
                onEditResponse({
                    radialIndex: data.activeLabel,
                    radialDistance,
                })
            }
        },
        [onEditResponse]
    )

    return (
        <Box height="100%" width="100%">
            <ResponsiveContainer width="100%" height="100%">
                <RadarChart
                    onClick={handleClick}
                    outerRadius={"95%"}
                    cx={"50%"}
                    cy={"50%"}
                    margin={{
                        top: 15,
                        bottom: 75,
                    }}
                    data={data}
                    className={classes.radarChart}
                    style={{
                        cursor: !!onEditResponse ? "crosshair" : "default",
                    }}
                >
                    <PolarGrid gridType="circle" radialLines={false} />

                    <PolarAngleAxis
                        dataKey="radialIndex"
                        domain={["auto", "auto"]}
                        tick={false}
                        type="number"
                        tickLine={false}
                    />

                    <PolarRadiusAxis
                        tickLine={true}
                        axisLine={false}
                        angle={-90}
                        tick={(props) => {
                            if (!!stages[props.index]) {
                                return (
                                    <foreignObject
                                        key={props.index}
                                        x={props.cx - 100 / 2}
                                        y={
                                            props.y +
                                            props.radius / stages.length / 4
                                        }
                                        width={100}
                                        height={"5em"}
                                    >
                                        <Box
                                            textAlign={"center"}
                                            overflow="hidden"
                                            display="flex"
                                            justifyContent={"center"}
                                        >
                                            <Typography color="textSecondary">
                                                {stages[props.index].value ||
                                                    ""}
                                            </Typography>
                                        </Box>
                                    </foreignObject>
                                )
                            } else {
                                return null
                            }
                        }}
                        tickCount={stages.length}
                        domain={[0, 100]}
                    />
                    {scores
                        ?.filter((score) => !!score)
                        .map((score) => {
                            return (
                                <Radar
                                    key={score.id}
                                    name={score.id}
                                    dataKey={score.id}
                                    dot={
                                        <CustomDot
                                            score={score}
                                            theme={theme}
                                            labelType={labelType}
                                        />
                                    }
                                />
                            )
                        })}
                </RadarChart>
            </ResponsiveContainer>
        </Box>
    )
}

const CustomDot = (props) => {
    const { cx, cy, score, index, labelType, theme } = props
    const dotWidthAndHeight = 40
    const dotContent = (
        <Avatar
            style={{
                width: dotWidthAndHeight,
                height: dotWidthAndHeight,
                backgroundColor: !!score.conceptDefault
                    ? theme.palette.secondary.main
                    : theme.palette.primary.main,
            }}
            src={
                labelType === "concept"
                    ? score.scoredConcept?.imageUrl
                    : score.label || score.conceptDefault
                    ? undefined
                    : score.user?.imageUrl
            }
        >
            <Box display="flex" alignItems="center" justifyContent="center">
                {labelType === "concept" ? (
                    score.scoredConcept?.title?.[0]
                ) : score.conceptDefault ? (
                    <AssignmentIndIcon />
                ) : score.label ? (
                    score.label?.[0]
                ) : (
                    score.user?.firstName?.[0] + score.user?.lastName?.[0]
                )}
            </Box>
        </Avatar>
    )
    if (!isNaN(cx) && !isNaN(cy)) {
        return (
            <foreignObject
                x={cx - dotWidthAndHeight / 2}
                y={cy - dotWidthAndHeight / 2}
                width={dotWidthAndHeight}
                height={dotWidthAndHeight}
                key={index}
                onClick={(e) => e.stopPropagation()}
                style={{
                    cursor: "pointer",
                }}
            >
                <ClickableRichTooltip
                    content={
                        <Box
                            key={
                                score.user?.userId +
                                score.scoredConcept?.id +
                                "box"
                            }
                            minWidth={300}
                            maxWidth={400}
                            overflow="hidden"
                        >
                            <Box>
                                <Box width="100%" p={1}>
                                    <ResponseListItem
                                        item={{
                                            label: score.label,
                                            user: score.user,
                                        }}
                                        isConceptDefault={score.conceptDefault}
                                    />
                                </Box>
                                <Divider />
                                <ConceptListItem
                                    hideDivider={true}
                                    item={score.scoredConcept}
                                    showSummary={true}
                                />
                            </Box>
                        </Box>
                    }
                >
                    {dotContent}
                </ClickableRichTooltip>
            </foreignObject>
        )
    } else {
        return null
    }
}

export default React.memo(RadarChartInterface, areEqual)
