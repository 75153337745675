import React from "react"
import { useQuery } from "@apollo/client"
import ClickAwayListener from "@material-ui/core/ClickAwayListener"
import Grow from "@material-ui/core/Grow"
import Paper from "@material-ui/core/Paper"
import Popper from "@material-ui/core/Popper"
import MenuItem from "@material-ui/core/MenuItem"
import MenuList from "@material-ui/core/MenuList"
import ExitToAppIcon from "@material-ui/icons/ExitToApp"
import FaceIcon from "@material-ui/icons/Face"
import AccountBoxIcon from "@material-ui/icons/AccountBox"
import LanguageIcon from "@material-ui/icons/Translate"
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles"
import FeedbackIcon from "@material-ui/icons/Feedback"
import {
    Avatar,
    Box,
    IconButton,
    ListItemIcon,
    ListItemText,
    Switch,
    CircularProgress,
} from "@material-ui/core"
import { getDarkModeIcon } from "../util/DarkModeIcon"
import { useTranslation } from "react-i18next"
import SettingsIcon from "@material-ui/icons/Settings"
import GroupIcon from "@material-ui/icons/Group"
import { SystemRole } from "../__generated__/types"
import { USER_CONCEPT_DAILY_PROGRESS } from "../graphql/queries"
import {
    UserConceptDailyProgressQuery,
    UserConceptDailyProgressQueryVariables,
} from "../graphql/__generated__/queries"
import { useAuth } from "../providers/AuthProvider"

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: "flex",
            zIndex: theme.zIndex.appBar + 1,
        },
        paper: {
            marginRight: theme.spacing(2),
        },

        darkModeToggle: {
            display: "flex",
            justifyContent: "flex-start",
        },
        ellipsis: {
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            overflow: "hidden",
        },
        imageFallback: {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.text.primary,
        },
    })
)

const getMidnightToday = () => {
    const d = new Date()
    d.setHours(0, 0, 0, 0)
    return d
}
const today = getMidnightToday()

export default function HeaderAvatarMenu({
    history,
    currentUser,
    handleLogout,
    handleDarkModeToggle,
    languagePickerVisible,
    setLanguagePickerVisible,
    theme,
}) {
    const { isNonMember } = useAuth()
    const classes = useStyles()
    const { data } = useQuery<
        UserConceptDailyProgressQuery,
        UserConceptDailyProgressQueryVariables
    >(USER_CONCEPT_DAILY_PROGRESS, {
        variables: {
            first: 10,
            filter: {
                AND: [
                    {
                        createdBy: {
                            userId: currentUser.userId,
                        },
                    },
                    {
                        OR: [
                            {
                                createdAt_gte: {
                                    month: today.getUTCMonth() + 1,
                                    day: today.getUTCDate(),
                                    year: today.getUTCFullYear(),
                                },
                            },
                            {
                                createdAt_gte: {
                                    month: today.getUTCMonth() + 1,
                                    day: today.getUTCDate() + 1,
                                    year: today.getUTCFullYear(),
                                },
                            },
                        ],
                    },
                ],
            },
        },
    })

    const [open, setOpen] = React.useState(false)
    const anchorRef = React.useRef<HTMLButtonElement>(null)

    const { t } = useTranslation("settings")
    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen)
    }

    const handleClose = (event: React.MouseEvent<EventTarget>) => {
        if (
            anchorRef.current &&
            anchorRef.current.contains(event.target as HTMLElement)
        ) {
            return
        }
        setOpen(false)
    }

    const handleNavigation = (path: string) => {
        setOpen(false)
        history.push(path)
    }
    const openUsersnap = () => {
        try {
            if (window["Usersnap"]) {
                window["Usersnap"].open()
            } else {
                console.log("Usersnap not available")
            }
        } catch {
            console.log("Usersnap not available")
        }
    }
    const DarkModeIcon = getDarkModeIcon(theme.palette.type)
    const imageFallback: string =
        (currentUser?.firstName &&
            currentUser?.lastName &&
            currentUser.firstName[0] + currentUser.lastName[0]) ??
        ""
    const conceptsSubmittedToday =
        data?.Concept?.filter(
            (c) => new Date(c.createdAt?.formatted) > today
        ) ?? []

    return (
        <div className={classes.root}>
            <IconButton
                ref={anchorRef}
                aria-controls={open ? "menu-list-grow" : undefined}
                aria-haspopup="true"
                onClick={handleToggle}
            >
                <Box style={{ position: "relative", display: "inline-flex" }}>
                    <CircularProgress
                        variant="determinate"
                        value={
                            conceptsSubmittedToday.length > 10
                                ? 100
                                : (conceptsSubmittedToday.length / 10) * 100
                        }
                        size={50}
                    />
                    <Box
                        style={{
                            top: 0,
                            left: 0,
                            bottom: 0,
                            right: 0,
                            position: "absolute",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                        }}
                    >
                        <Avatar
                            variant="circular"
                            alt={currentUser?.username}
                            src={currentUser?.imageUrl}
                            className={classes.imageFallback}
                        >
                            {imageFallback}
                        </Avatar>
                    </Box>
                </Box>
            </IconButton>
            {open && (
                <Popper
                    open={open}
                    anchorEl={anchorRef.current}
                    transition
                    disablePortal
                >
                    {({ TransitionProps, placement }) => (
                        <Grow
                            {...TransitionProps}
                            style={{
                                transformOrigin:
                                    placement === "bottom"
                                        ? "center top"
                                        : "center bottom",
                            }}
                        >
                            <Paper elevation={12} style={{ maxWidth: "350px" }}>
                                <ClickAwayListener onClickAway={handleClose}>
                                    <MenuList id="avatar-menu-list">
                                        {!isNonMember && (
                                            <MenuItem
                                                onClick={() =>
                                                    handleNavigation(
                                                        `/user/${currentUser.username}/home`
                                                    )
                                                }
                                            >
                                                <ListItemIcon>
                                                    <FaceIcon />
                                                </ListItemIcon>
                                                <ListItemText>
                                                    {t("profile", "Profile")}
                                                </ListItemText>
                                            </MenuItem>
                                        )}
                                        {!isNonMember && (
                                            <MenuItem
                                                onClick={() =>
                                                    handleNavigation(`/teams`)
                                                }
                                            >
                                                <ListItemIcon>
                                                    <GroupIcon />
                                                </ListItemIcon>
                                                <ListItemText>
                                                    {t("myTeams", "My Teams")}
                                                </ListItemText>
                                            </MenuItem>
                                        )}
                                        {!isNonMember && (
                                            <MenuItem
                                                onClick={() =>
                                                    handleNavigation(
                                                        `/myaccount`
                                                    )
                                                }
                                            >
                                                <ListItemIcon>
                                                    <AccountBoxIcon />
                                                </ListItemIcon>
                                                <ListItemText>
                                                    {t(
                                                        "myAccount",
                                                        "My Account"
                                                    )}
                                                </ListItemText>
                                            </MenuItem>
                                        )}
                                        <MenuItem
                                            className={classes.darkModeToggle}
                                            onClick={handleDarkModeToggle}
                                        >
                                            <ListItemIcon>
                                                {DarkModeIcon}
                                            </ListItemIcon>
                                            <ListItemText
                                                primary={t(
                                                    "darkMode",
                                                    "Dark Mode"
                                                )}
                                            />
                                            <Box ml={3}>
                                                <Switch
                                                    checked={
                                                        theme.palette.type ===
                                                        "dark"
                                                    }
                                                    value={
                                                        theme.palette.type ===
                                                        "dark"
                                                    }
                                                    onChange={
                                                        handleDarkModeToggle
                                                    }
                                                    name="prefersDarkMode"
                                                    inputProps={{
                                                        "aria-label":
                                                            "Dark Mode?",
                                                    }}
                                                />
                                            </Box>
                                        </MenuItem>
                                        <MenuItem
                                            className={classes.darkModeToggle}
                                            onClick={() =>
                                                setLanguagePickerVisible(
                                                    (isVisible) => {
                                                        return !isVisible
                                                    }
                                                )
                                            }
                                        >
                                            <ListItemIcon>
                                                {<LanguageIcon />}
                                            </ListItemIcon>
                                            <ListItemText
                                                primary={t(
                                                    "showLanguage",
                                                    "Show Language Picker"
                                                )}
                                            />
                                            <Box ml={3}>
                                                <Switch
                                                    checked={
                                                        languagePickerVisible
                                                    }
                                                    value={
                                                        languagePickerVisible
                                                    }
                                                    name="languagePickerVisible"
                                                    inputProps={{
                                                        "aria-label": t(
                                                            "showLanguagePicker",
                                                            "Show Language Picker?"
                                                        ),
                                                    }}
                                                />
                                            </Box>
                                        </MenuItem>

                                        {!isNonMember && (
                                            <MenuItem
                                                key="feedback"
                                                onClick={() => {
                                                    setOpen(false)
                                                    openUsersnap()
                                                }}
                                            >
                                                <ListItemIcon>
                                                    <FeedbackIcon />
                                                </ListItemIcon>
                                                <ListItemText
                                                    primary={t(
                                                        "shareFeedback",
                                                        "Share Feedback"
                                                    )}
                                                />
                                            </MenuItem>
                                        )}
                                        {currentUser?.role ===
                                            SystemRole.ADMIN && (
                                            <MenuItem
                                                onClick={() =>
                                                    handleNavigation(
                                                        `/settings/general`
                                                    )
                                                }
                                            >
                                                <ListItemIcon>
                                                    <SettingsIcon />
                                                </ListItemIcon>
                                                <ListItemText
                                                    primary={t(
                                                        "settings",
                                                        "Settings"
                                                    )}
                                                />
                                            </MenuItem>
                                        )}
                                        <MenuItem
                                            onClick={() => handleLogout()}
                                        >
                                            <ListItemIcon>
                                                <ExitToAppIcon
                                                    style={{
                                                        transform:
                                                            "rotate(180deg)",
                                                    }}
                                                />
                                            </ListItemIcon>
                                            <ListItemText
                                                primary={t("logout", "Logout")}
                                            />
                                        </MenuItem>
                                    </MenuList>
                                </ClickAwayListener>
                            </Paper>
                        </Grow>
                    )}
                </Popper>
            )}
        </div>
    )
}
