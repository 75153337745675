import { useQuery, useMutation } from "@apollo/client"
import { useEffect, useState, useCallback } from "react"
import debounce from "p-debounce"
import {
    GET_DASHBOARD_WIDGET_BY_ID,
    UPDATE_DASHBOARD_WIDGET,
    DELETE_DASHBOARD_WIDGET,
} from "./graphql"
import {
    DeleteDashboardWidgetMutation,
    DeleteDashboardWidgetMutationVariables,
    GetDashboardWidgetByIdQuery,
    GetDashboardWidgetByIdQueryVariables,
    UpdateDashboardWidgetMutation,
    UpdateDashboardWidgetMutationVariables,
} from "./__generated__/graphql"

export default function useWidget<T = {}>(widgetId: string | null) {
    const { data: widgetData, refetch } = useQuery<
        GetDashboardWidgetByIdQuery,
        GetDashboardWidgetByIdQueryVariables
    >(GET_DASHBOARD_WIDGET_BY_ID, {
        variables: {
            id: widgetId,
        },
        skip: !widgetId,
    })
    const widget = widgetData?.Widget[0] ?? null
    const [config, setConfig] = useState<T | null>(null)
    const [value, setValue] = useState<T | null>(null)
    const [isInitialized, setIsInitialized] = useState(false)

    useEffect(() => {
        if (!widgetId) {
            setIsInitialized(true)
        } else if (!!widget) {
            setValue(JSON.parse(widget.value || "{}"))
            setConfig(JSON.parse(widget.config || "{}"))
            setIsInitialized(true)
        }
    }, [widget, widgetData, widgetId])

    // API CALLS FOR METHODS
    const [updateWidget] = useMutation<
        UpdateDashboardWidgetMutation,
        UpdateDashboardWidgetMutationVariables
    >(UPDATE_DASHBOARD_WIDGET)
    const [deleteWidget] = useMutation<
        DeleteDashboardWidgetMutation,
        DeleteDashboardWidgetMutationVariables
    >(DELETE_DASHBOARD_WIDGET)

    // METHODS
    const onEditConfig = debounce(
        useCallback(
            async (config: T) => {
                const stringifiedConfig = JSON.stringify(config)
                await updateWidget({
                    variables: {
                        id: widgetId,
                        config: stringifiedConfig,
                    },
                })
                await refetch()
            },
            [updateWidget, refetch, widgetId]
        ),
        500
    )
    const onEditValue = debounce(
        useCallback(
            async (value: T) => {
                const stringifiedValue = JSON.stringify(value)

                await updateWidget({
                    variables: {
                        id: widgetId,
                        value: stringifiedValue,
                    },
                })
                await refetch()
            },
            [updateWidget, refetch, widgetId]
        ),
        500
    )

    const onDeleteWidget = useCallback(
        async (id: string) => {
            await deleteWidget({
                variables: {
                    id: id,
                },
                update: (cache, { data }) => {
                    cache.evict({
                        id: cache.identify(data.DeleteWidget),
                    })
                    cache.gc()
                },
            })
        },
        [deleteWidget]
    )

    return {
        widget,
        isInitialized,
        config,
        value,
        // METHODS
        onEditConfig,
        onDeleteWidget,
        onEditValue,
    }
}
