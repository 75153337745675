import { useMutation, useQuery } from "@apollo/client"
import {
    Box,
    Button,
    CircularProgress,
    InputAdornment,
    ListItemIcon,
    ListItemSecondaryAction,
    ListItemText,
    MenuItem,
    MenuList,
    Paper,
    Switch,
    TextField,
    Typography,
    useTheme,
} from "@material-ui/core"
import { ALL_ENVIRONMENT_SETTINGS, UPDATE_ENVIRONMENT_SETTING } from "./graphql"
import { BooleanValues, EnvironmentSettingName } from "./types"
import {
    AllEnvironmentSettingsQuery,
    AllEnvironmentSettingsQueryVariables,
    UpdateEnvironmentSettingMutation,
    UpdateEnvironmentSettingMutationVariables,
} from "./__generated__/graphql"
import PublishIcon from "@material-ui/icons/Publish"
import SyncAltIcon from "@material-ui/icons/SyncAlt"
import useAwaitTranslation from "../../i18n/useAwaitTranslation"
import { useEffect, useState } from "react"
import { myCurrentInputResponseLabelVar } from "../../providers/GlobalState"
import { ScoreSelectorDefaultValues } from "../inputs/types"
import AssignmentInd from "@material-ui/icons/AssignmentInd"

type GeneralSettingsValue = {
    restrictPublishingToAdminsValue: BooleanValues
    restrictConnectionsToAdminsValue: BooleanValues
    primaryResponseTextValue: string
}

export default function GeneralSettings() {
    const theme = useTheme()
    const [localSettings, setLocalSettings] =
        useState<GeneralSettingsValue>(null)
    const [saving, setSaving] = useState(false)
    const { data } = useQuery<
        AllEnvironmentSettingsQuery,
        AllEnvironmentSettingsQueryVariables
    >(ALL_ENVIRONMENT_SETTINGS)
    const [updateSetting] = useMutation<
        UpdateEnvironmentSettingMutation,
        UpdateEnvironmentSettingMutationVariables
    >(UPDATE_ENVIRONMENT_SETTING)

    const { t } = useAwaitTranslation("global")

    const restrictPublishingToAdmins =
        data?.EnvironmentSetting?.find(
            (setting) =>
                setting.name ===
                EnvironmentSettingName.RestrictPublishingToAdmins
        ) ?? null

    const restrictConnectionsToAdmins =
        data?.EnvironmentSetting?.find(
            (setting) =>
                setting.name ===
                EnvironmentSettingName.RestrictConnectionsToAdmins
        ) ?? null

    const primaryResponseText =
        data?.EnvironmentSetting?.find(
            (setting) =>
                setting.name === EnvironmentSettingName.PrimaryResponseText
        ) ?? null

    const restrictPublishingToAdminsValue =
        restrictPublishingToAdmins?.value as BooleanValues
    const restrictConnectionsToAdminsValue =
        restrictConnectionsToAdmins?.value as BooleanValues
    const primaryResponseTextValue = primaryResponseText?.value || ""

    const currentSettings: GeneralSettingsValue = {
        restrictPublishingToAdminsValue,
        restrictConnectionsToAdminsValue,
        primaryResponseTextValue,
    }
    const handleSave = async () => {
        setSaving(true)
        let promises = []
        if (
            localSettings.restrictPublishingToAdminsValue !==
            currentSettings.restrictPublishingToAdminsValue
        ) {
            promises.push(
                updateSetting({
                    variables: {
                        id: restrictPublishingToAdmins.id,
                        value: localSettings.restrictPublishingToAdminsValue,
                    },
                })
            )
        }
        if (
            localSettings.restrictConnectionsToAdminsValue !==
            currentSettings.restrictConnectionsToAdminsValue
        ) {
            promises.push(
                updateSetting({
                    variables: {
                        id: restrictConnectionsToAdmins.id,
                        value: localSettings.restrictConnectionsToAdminsValue,
                    },
                })
            )
        }
        if (
            localSettings.primaryResponseTextValue !==
            currentSettings.primaryResponseTextValue
        ) {
            promises.push(
                updateSetting({
                    variables: {
                        id: primaryResponseText.id,
                        value: localSettings.primaryResponseTextValue,
                    },
                })
            )
            myCurrentInputResponseLabelVar({
                label: ScoreSelectorDefaultValues.primaryResponse,
                content: localSettings.primaryResponseTextValue,
                user: null,
                conceptId: null,
            })
        }
        if (promises.length > 0) {
            await Promise.all(promises)
        }

        setSaving(false)
    }
    useEffect(() => {
        if (!localSettings && !!data) {
            setLocalSettings({
                restrictPublishingToAdminsValue,
                restrictConnectionsToAdminsValue,
                primaryResponseTextValue,
            })
        }
    }, [
        localSettings,
        restrictConnectionsToAdminsValue,
        restrictPublishingToAdminsValue,
        primaryResponseTextValue,
        data,
    ])

    if (!localSettings) {
        return null
    }
    const changed =
        JSON.stringify(currentSettings) !== JSON.stringify(localSettings)

    return (
        <Box
            height="100%"
            overflow="hidden"
            display="flex"
            px={3}
            py={1}
            flexDirection={"column"}
        >
            <Box
                pt={2}
                pb={2}
                display="flex"
                width="100%"
                justifyContent={"flex-end"}
            >
                {!!changed && (
                    <Button
                        disabled={!!saving}
                        onClick={() => setLocalSettings(currentSettings)}
                    >
                        Cancel
                    </Button>
                )}
                <Button
                    onClick={handleSave}
                    color="primary"
                    variant="contained"
                    style={{ position: "relative" }}
                    disabled={!changed || saving}
                >
                    {!!saving && (
                        <CircularProgress
                            size={20}
                            style={{
                                position: "absolute",
                            }}
                        />
                    )}
                    Save Changes
                </Button>
            </Box>
            <Paper
                style={{
                    padding: theme.spacing(3, 3),
                    height: "100%",
                    overflow: "hidden",
                }}
                variant="outlined"
            >
                {!!primaryResponseText && (
                    <>
                        <TextField
                            fullWidth
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <AssignmentInd color="action" />
                                    </InputAdornment>
                                ),
                            }}
                            value={localSettings.primaryResponseTextValue}
                            onChange={(e) =>
                                setLocalSettings({
                                    ...localSettings,
                                    primaryResponseTextValue:
                                        e.currentTarget.value,
                                })
                            }
                            label={t(
                                "primaryResponseLabel",
                                "Primary Response Label"
                            )}
                            variant="outlined"
                        />
                        <Typography color="textSecondary" variant="caption">
                            {t(
                                "labelDisplayedForPrimaryResponse",
                                "Label displayed for the primary response in a concept workspace"
                            )}
                        </Typography>
                    </>
                )}
                <MenuList>
                    <MenuItem
                        button={true}
                        onClick={() => {
                            setLocalSettings({
                                ...localSettings,
                                restrictPublishingToAdminsValue:
                                    localSettings.restrictPublishingToAdminsValue ===
                                    BooleanValues.TRUE
                                        ? BooleanValues.FALSE
                                        : BooleanValues.TRUE,
                            })
                        }}
                    >
                        <ListItemIcon>
                            <PublishIcon />
                        </ListItemIcon>
                        <ListItemText
                            primary={`${t(
                                "restrictPublishingToAdmins",
                                "Restrict Publishing to Admins"
                            )}?`}
                        />
                        <ListItemSecondaryAction>
                            <Switch
                                color="primary"
                                checked={
                                    localSettings.restrictPublishingToAdminsValue ===
                                    BooleanValues.TRUE
                                }
                                disabled={!!saving}
                                onChange={() => {
                                    setLocalSettings({
                                        ...localSettings,
                                        restrictPublishingToAdminsValue:
                                            localSettings.restrictPublishingToAdminsValue ===
                                            BooleanValues.TRUE
                                                ? BooleanValues.FALSE
                                                : BooleanValues.TRUE,
                                    })
                                }}
                            />
                        </ListItemSecondaryAction>
                    </MenuItem>
                    <MenuItem
                        button={true}
                        onClick={() => {
                            setLocalSettings({
                                ...localSettings,
                                restrictConnectionsToAdminsValue:
                                    localSettings.restrictConnectionsToAdminsValue ===
                                    BooleanValues.TRUE
                                        ? BooleanValues.FALSE
                                        : BooleanValues.TRUE,
                            })
                        }}
                    >
                        <ListItemIcon>
                            <SyncAltIcon />
                        </ListItemIcon>
                        <ListItemText
                            primary={`${t(
                                "restrictConnectionsToAdmins",
                                "Restrict Connections to Admins"
                            )}?`}
                        />
                        <ListItemSecondaryAction>
                            <Switch
                                disabled={!!saving}
                                color="primary"
                                checked={
                                    localSettings.restrictConnectionsToAdminsValue ===
                                    BooleanValues.TRUE
                                }
                                onChange={() => {
                                    setLocalSettings({
                                        ...localSettings,
                                        restrictConnectionsToAdminsValue:
                                            localSettings.restrictConnectionsToAdminsValue ===
                                            BooleanValues.TRUE
                                                ? BooleanValues.FALSE
                                                : BooleanValues.TRUE,
                                    })
                                }}
                            />
                        </ListItemSecondaryAction>
                    </MenuItem>
                </MenuList>
            </Paper>
        </Box>
    )
}
