import { useReactiveVar } from "@apollo/client"
import {
    Box,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
} from "@material-ui/core"
import { useTheme } from "@material-ui/core/styles"
import useAwaitTranslation from "../../../i18n/useAwaitTranslation"
import {
    FeedbackField,
    myCurrentInputResponseLabelVar,
} from "../../../providers/GlobalState"
import { Concept } from "../../../__generated__/types"
import {
    getCleanedFieldConfig,
    getSelectedResponseScores,
} from "../../inputs/util"
import { getSerializer } from "../util"

const TableDeserializer = (props: {
    orderedConcepts: Concept[]
    columnFields: FeedbackField[]
}) => {
    const { orderedConcepts, columnFields } = props
    const theme = useTheme()
    return (
        <Box my={2} flex={1}>
            <TableContainer
                style={{
                    border: `1px solid ${theme.palette.divider}`,
                    borderBottom: 0,
                }}
            >
                <Table size="small" style={{ width: "auto", minWidth: "100%" }}>
                    <TableHead
                        style={{
                            // @ts-ignore
                            backgroundColor: theme.palette.background.level2,
                        }}
                    >
                        <TableRow>
                            <TableCell />
                            {columnFields.map((col) => {
                                const columnConfig = getCleanedFieldConfig(col)
                                return (
                                    <TableCell key={col.input.id}>
                                        {columnConfig.name || ""}
                                    </TableCell>
                                )
                            })}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {orderedConcepts.map((concept) => {
                            return (
                                <RowItem
                                    concept={concept}
                                    columnFields={columnFields}
                                    key={concept.id}
                                />
                            )
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    )
}

const RowItem = (props: {
    concept: Concept
    columnFields: FeedbackField[]
}) => {
    const { concept, columnFields } = props
    const { i18n } = useAwaitTranslation()
    const currentLabel = useReactiveVar(myCurrentInputResponseLabelVar)
    const selectedLangString = i18n?.language
    let translatedContent =
        concept?.translations?.find(
            (t) => t.languageString === selectedLangString
        ) || null
    const title = translatedContent?.title || concept.title
    return (
        <TableRow key={concept.id}>
            <TableCell key={concept.id + "_title"}>{title}</TableCell>
            {columnFields.map((col) => {
                const columnConfig = getCleanedFieldConfig(col)
                const score = getSelectedResponseScores(
                    col,
                    currentLabel
                )?.find((score) => score.scoredConcept?.id === concept.id)
                let html = getSerializer(
                    col.input.criteria?.criteriaType,
                    score,
                    columnConfig,
                    true
                )

                return (
                    <TableCell key={score?.id + col.input.id}>{html}</TableCell>
                )
            })}
        </TableRow>
    )
}
export default TableDeserializer
