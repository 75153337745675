import { Box, ButtonBase, Typography, debounce } from "@material-ui/core"
import NumericInputSlider from "../NumericInputSlider"
import {
    NumberInterfaceProps,
    NumberType,
} from "../../criteria/useCriteriaTypes"
import { useInputTools } from "../useInputTools"
import React, { useCallback } from "react"
import { useReactiveVar } from "@apollo/client"
import {
    myCurrentInputResponseLabelVar,
    myCurrentInputResponseVar,
} from "../../../providers/GlobalState"
import { ScoreSelectorDefaultValues } from "../types"
import { getAverage, getMedian } from "../../../util/fns"
import { useTheme, makeStyles } from "@material-ui/core/styles"
const useStyles = makeStyles((theme) => ({
    facilitatorsInterface: {
        flexGrow: 1,
        [theme.breakpoints.up("sm")]: {
            width: "75%",
            margin: "auto",
        },
        paddingTop: theme.spacing(3),
    },
}))

const NumberInterface: React.FC<NumberInterfaceProps> = ({
    score,
    config,
    inputId,
    scoredConceptId,
}) => {
    const classes = useStyles()
    const { label, user } = useReactiveVar(myCurrentInputResponseLabelVar)
    const viewingPrimaryResponse =
        label === ScoreSelectorDefaultValues.primaryResponse && !user
    const { onEditResponse } = useInputTools({})

    const onResponseChange = debounce(
        useCallback(
            (value: number) => {
                if (!!score) {
                    const updateData = {
                        response: JSON.stringify(value),
                    }

                    onEditResponse(
                        {
                            ...updateData,
                        },
                        score
                    )
                }
            },
            [score, onEditResponse]
        ),
        1000
    )
    const currentValue = score?.response ? +score?.response : 0
    return (
        <Box
            display={"flex"}
            flexDirection="column"
            flexGrow={1}
            style={{ overflowY: "auto" }}
            key={score?.id}
        >
            <Box
                flexGrow={1}
                display="flex"
                key={score?.id}
                pr={0.5}
                pl={0.5}
                pt={1.5}
                flexDirection="column"
                justifyContent={"center"}
                alignItems="center"
            >
                <Box
                    height={"fit-content"}
                    display="flex"
                    flexDirection={"column"}
                    justifyContent="center"
                    flexGrow={1}
                    px={2.5}
                    width="100%"
                >
                    <NumericInputSlider
                        editing={true}
                        value={currentValue}
                        handleChange={onResponseChange}
                        config={config}
                        disabled={!score}
                    />
                </Box>
                {/* {!!viewingPrimaryResponse && (
                    <Box className={classes.facilitatorsInterface}>
                        <FacilitatorsInterface
                            inputId={inputId}
                            scoredConceptId={scoredConceptId}
                            onChange={onResponseChange}
                            config={config}
                        />
                    </Box>
                )} */}
            </Box>
        </Box>
    )
}

interface FacilitatorsInterfaceProps {
    inputId: string
    onChange: (value: number) => void
    scoredConceptId: string | undefined
    config: NumberType
}

const FacilitatorsInterface: React.FC<FacilitatorsInterfaceProps> = ({
    inputId,
    scoredConceptId,
    onChange,
    config,
}) => {
    const theme = useTheme()
    const { currentResponseData } = useReactiveVar(myCurrentInputResponseVar)
    const allNonPrimaryScores =
        currentResponseData
            ?.find((field) => field.input.id === inputId)
            ?.scores?.filter(
                (score) =>
                    !score.conceptDefault &&
                    score.scoredConcept?.id === scoredConceptId
            ) ?? []
    const sortedValues =
        allNonPrimaryScores?.map((score) => +score?.response) ?? []

    const average = getAverage(sortedValues)
    const median = getMedian(sortedValues)
    const max = Math.max(...sortedValues)
    const min = sortedValues?.length === 0 ? 0 : Math.min(...sortedValues)
    const getValueString = (value: number) => {
        if (!!isNaN(value)) {
            return "-"
        }
        return `${config.prefix ?? ""}${value?.toLocaleString() || 0} ${
            config.suffix ?? ""
        }`
    }
    return (
        <Box>
            <Box mb={0.5}>
                <Typography>
                    Response Stats ({allNonPrimaryScores?.length})
                </Typography>
            </Box>
            <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                border={`1px solid ${theme.palette.divider}`}
                borderRadius={theme.shape.borderRadius}
            >
                <Box
                    p={1}
                    width="100%"
                    component={ButtonBase}
                    onClick={() => onChange(average)}
                >
                    <Box p={1} textAlign="left">
                        <Typography variant="body2" color="textSecondary">
                            Average
                        </Typography>
                        <Typography>{getValueString(average)}</Typography>
                    </Box>
                </Box>
                <Box
                    p={1}
                    width="100%"
                    component={ButtonBase}
                    onClick={() => onChange(median)}
                >
                    <Box p={1} textAlign="left">
                        <Typography variant="body2" color="textSecondary">
                            Median
                        </Typography>
                        <Typography>{getValueString(median)}</Typography>
                    </Box>
                </Box>
                <Box
                    p={1}
                    width="100%"
                    component={ButtonBase}
                    onClick={() => onChange(min)}
                >
                    <Box p={1} textAlign="left">
                        <Typography variant="body2" color="textSecondary">
                            Min
                        </Typography>
                        <Typography> {getValueString(min)}</Typography>
                    </Box>
                </Box>
                <Box
                    p={1}
                    width="100%"
                    component={ButtonBase}
                    onClick={() => onChange(max)}
                >
                    <Box p={1} textAlign="left">
                        <Typography variant="body2" color="textSecondary">
                            Max
                        </Typography>
                        <Typography>{getValueString(max)}</Typography>
                    </Box>
                </Box>
            </Box>
        </Box>
    )
}

export default NumberInterface
