import { useReactiveVar } from "@apollo/client"
import { Box } from "@material-ui/core"
import { myCurrentInputResponseLabelVar } from "../../../providers/GlobalState"
import { Concept, CriteriaScore } from "../../../__generated__/types"
import { GroupingType } from "../../criteria/useCriteriaTypes"
import { ScoreSelectorDefaultValues } from "../types"

import DroppableContainer from "./DroppableContainer"
import { GroupingResponse, GroupingResponseWithConcepts } from "./types"

const GroupingResponseViewer = (props: {
    scores: CriteriaScore[]
    config: GroupingType
    inputId: string
    conceptsScored: Concept[]
}) => {
    const { scores, config, inputId, conceptsScored } = props
    const currentLabel = useReactiveVar(myCurrentInputResponseLabelVar)
    const viewingAllResponses =
        currentLabel.label === ScoreSelectorDefaultValues.allResponses &&
        !currentLabel.user
    if (!conceptsScored) {
        //have to await for concepts scored or else the initial state of droppable container will include no concepts
        return null
    }
    if (!!viewingAllResponses) {
        const groups = config?.groups.map((group) => {
            return {
                group,
                layoutConfiguration: null,
                concepts: scores.flatMap((score) =>
                    score.concepts.filter((concept) =>
                        JSON.parse(score?.response ?? "[]")
                            .find((item) => item.group?.id === group.id)
                            ?.conceptIds.includes(concept.id)
                    )
                ),
            }
        })

        return (
            <Box width="100%" height={"100%"}>
                <DroppableContainer
                    groups={groups}
                    config={config}
                    inputId={inputId}
                    viewingResults={true}
                />
            </Box>
        )
    } else {
        const score = scores?.[0]

        const response: GroupingResponse[] = JSON.parse(score?.response ?? "[]")

        const groups: GroupingResponseWithConcepts[] = response.map((item) => {
            return {
                group: item.group || { value: inputId, id: inputId },
                layoutConfiguration: item.layoutConfiguration,
                concepts:
                    item.conceptIds
                        ?.map((id) =>
                            conceptsScored?.find((concept) => concept.id === id)
                        )
                        ?.filter((x) => !!x) ?? [],
            }
        })

        return (
            <Box width="100%" height={"100%"} key={score?.id}>
                <DroppableContainer
                    score={score}
                    groups={groups}
                    config={config}
                    inputId={inputId}
                    viewingResults={true}
                />
            </Box>
        )
    }
}

export default GroupingResponseViewer
