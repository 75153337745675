import { Box, ClickAwayListener } from "@material-ui/core"
import { Concept } from "../../../__generated__/types"

import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd"

import { CollectionType } from "../../criteria/useCriteriaTypes"
import CollectionQuickAdd from "./CollectionQuickAdd"
import { useState } from "react"
import useAwaitTranslation from "../../../i18n/useAwaitTranslation"
import CollectionListItem from "./CollectionListItem"
import { reorderArray } from "../../../util/fns"

const DraggableCollection = (props: {
    inputId: string
    config: CollectionType
    concepts: Concept[]
    editable: boolean
    onChangeRowOrder?: (concepts: Concept[], updateScore: boolean) => void
    onConceptToggle?: (concept: Concept, selected: boolean) => Promise<void>
    onConceptCreation?: (title: string, categoryId: string) => Promise<void>
    addingNewLineItem?: boolean
    onCancelNewLineItem?: () => void
    categoryToAdd?: string
}) => {
    const {
        config,
        concepts,
        onChangeRowOrder,
        inputId,
        onConceptToggle,
        editable,
        addingNewLineItem,
        onConceptCreation,
        onCancelNewLineItem,
        categoryToAdd,
    } = props
    //hooks
    const { t } = useAwaitTranslation("feedback")
    const [newLineItemValue, setNewLineItemValue] = useState("")
    //functions

    const onDragEnd = (result) => {
        if (!result.destination) {
            return
        }
        const conceptArray: Concept[] = reorderArray(
            concepts,
            result.source.index,
            result.destination.index
        )
        onChangeRowOrder(conceptArray, true)
    }
    if (!concepts) {
        return null
    }
    return (
        <Box
            display="flex"
            overflow="hidden"
            flexDirection="column"
            height="100%"
        >
            {!!addingNewLineItem && (
                <ClickAwayListener
                    onClickAway={() => {
                        if (newLineItemValue.length === 0) {
                            onCancelNewLineItem()
                        }
                    }}
                >
                    <Box p={1}>
                        <CollectionQuickAdd
                            setSearchString={setNewLineItemValue}
                            searchString={newLineItemValue}
                            inputId={inputId}
                            onSelection={onConceptToggle}
                            onCreation={onConceptCreation}
                            variant="standard"
                            disabledOptionIds={concepts.map((item) => item.id)}
                            placeholder={`${t(
                                "typeToAdd",
                                "Type to add to"
                            )} '${config.name}'`}
                            categoryToAdd={categoryToAdd}
                            hint={
                                !!config.allowConceptCreation
                                    ? t(
                                          "enterToCreateNew",
                                          "Enter to create new"
                                      )
                                    : ""
                            }
                            disableCreation={!config.allowConceptCreation}
                        />
                    </Box>
                </ClickAwayListener>
            )}
            <Box flexGrow={1} overflow="auto">
                <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable
                        droppableId={inputId ?? "no_score_droppable"}
                        renderClone={(provided, snapshot, rubric) => (
                            <CollectionListItem
                                isDragging={snapshot.isDragging}
                                item={concepts[rubric.source.index]}
                                editable={!!editable}
                                draggableRef={provided.innerRef}
                                draggableProps={provided.draggableProps}
                                dragHandleProps={provided.dragHandleProps}
                                onRemoval={onConceptToggle}
                            />
                        )}
                    >
                        {(provided, snapshot) => (
                            <div
                                ref={provided.innerRef}
                                {...provided.droppableProps}
                            >
                                {concepts.map((concept, index) => (
                                    <Draggable
                                        key={concept.id}
                                        draggableId={concept.id}
                                        index={index}
                                        isDragDisabled={!editable}
                                    >
                                        {(provided, snapshot) => (
                                            <CollectionListItem
                                                key={concept.id}
                                                isDragging={snapshot.isDragging}
                                                draggableProps={
                                                    provided.draggableProps
                                                }
                                                dragHandleProps={
                                                    provided.dragHandleProps
                                                }
                                                draggableRef={provided.innerRef}
                                                editable={!!editable}
                                                item={concept}
                                                onRemoval={onConceptToggle}
                                            />
                                        )}
                                    </Draggable>
                                ))}
                                {provided.placeholder}
                            </div>
                        )}
                    </Droppable>
                </DragDropContext>
            </Box>
        </Box>
    )
}

export default DraggableCollection
