import { Box, Paper } from "@material-ui/core"
import WidgetHeader from "./common/WidgetHeader"
import useWidget from "../useWidget"
import { WidgetTypeComponent } from "../useWidgetTypes"
import { useQuery } from "@apollo/client"
import { CONCEPT_BY_ID } from "../../../graphql/queries"
import ConceptCard from "../../ConceptCard"
import ConceptDetails from "../../ConceptDetails"
import { Loading } from "../../Loading"
import {
    ConceptQuery,
    ConceptQueryVariables,
} from "../../../graphql/__generated__/queries"
import ErrorHandler from "../../ErrorHandler"
import { useState } from "react"
type WidgetConfig = {
    label: string
    conceptId: string
    view: string
}

const ConceptWidget: WidgetTypeComponent = (props) => {
    //hooks
    const { config, isInitialized } = useWidget<WidgetConfig>(props.widget.id)
    const [hovered, setHovered] = useState(false)
    //queries
    const { data, loading, error } = useQuery<
        ConceptQuery,
        ConceptQueryVariables
    >(CONCEPT_BY_ID, {
        variables: {
            id: config?.conceptId,
        },
        skip: !isInitialized || !config?.conceptId,
    })
    //query handling
    if (loading) {
        return <Loading size={25} hideQuote={true} />
    }
    if (error) {
        return <ErrorHandler showMessage={true} message={error.message} />
    }

    //variables
    const concept = data?.Concept?.[0]
    return (
        <Box
            component={!props.isDialog ? Paper : "div"}
            display="flex"
            height="100%"
            width="100%"
            flexDirection="column"
            key={props.widget.id}
            overflow={"auto"}
            onMouseOver={() => {
                if (!props.isDialog && !hovered) {
                    setHovered(true)
                }
            }}
            onMouseLeave={() => {
                if (!props.isDialog && !!hovered) {
                    setHovered(false)
                }
            }}
        >
            <WidgetHeader
                label={config?.label}
                hovered={hovered || !!props.isDialog}
                widget={props.widget}
                editing={props.editing}
                onWidgetDeletion={props.onWidgetDeletion}
            />
            {!!concept ? (
                config.view === "Card" ? (
                    <ConceptCard item={concept} />
                ) : (
                    <ConceptDetails concept={concept} />
                )
            ) : (
                <Loading size={25} hideQuote={true} />
            )}
        </Box>
    )
}

export default ConceptWidget
