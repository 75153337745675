import React, { useEffect, useRef, useState } from "react"
import { useLazyQuery } from "@apollo/client"

import {
    TextField,
    InputAdornment,
    Box,
    Typography,
    CircularProgress,
    Button,
    IconButton,
} from "@material-ui/core"
import { CRITERIA_FILTERED } from "./graphql"

import { CriteriaQuery, CriteriaQueryVariables } from "./__generated__/graphql"
import { Category, Criteria } from "../../__generated__/types"
import useAwaitTranslation from "../../i18n/useAwaitTranslation"
import SearchIcon from "@material-ui/icons/Search"
import { Autocomplete, createFilterOptions } from "@material-ui/lab"
import FieldTypeSelect from "./FieldTypeSelect"
import { CriteriaType } from "./types"
import useCategoryTools from "../categories/useCategoryTools"
import { alpha, useTheme } from "@material-ui/core/styles"
import {
    ALL_CRITERIA_TYPE_OPTIONS,
    SUB_LEVEL_CRITERIA_OPTIONS,
    TOP_LEVEL_CRITERIA_OPTIONS,
} from "./useCriteriaTypes"
import Add from "@material-ui/icons/Add"
import Clear from "@material-ui/icons/Clear"
import CategoryIcon from "../categories/CategoryIcon"
import FieldListItem from "./FieldListItem"
const FieldAutocomplete = (props: {
    onSelect: (field: Criteria) => Promise<void>
    currentFieldIds: string[]
    categoriesToGroupBy?: Category[]
    hideLabel?: boolean
    optionsAllowed: "All" | "Top" | "Sub"
    onCreateNewField?: () => void
    colorBackground?: boolean
}) => {
    const {
        onSelect,
        currentFieldIds,
        hideLabel,
        optionsAllowed,
        categoriesToGroupBy,
        onCreateNewField,
        colorBackground,
    } = props
    const mounted = useRef(false)
    const theme = useTheme()
    const autocompleteRef = useRef(null)
    const { t } = useAwaitTranslation("criteria")
    const [showButton, setShowButton] = useState(true)
    const [inputValue, setInputValue] = useState("")
    const { environmentCategories } = useCategoryTools()
    const [adding, setAdding] = useState(false)
    const options =
        optionsAllowed === "All"
            ? ALL_CRITERIA_TYPE_OPTIONS
            : optionsAllowed === "Top"
            ? TOP_LEVEL_CRITERIA_OPTIONS
            : SUB_LEVEL_CRITERIA_OPTIONS
    const [selectedCriteriaType, setSelectedCriteriaType] = useState<
        CriteriaType | "All"
    >("All")
    const [fetchAllFields, { data, loading, called }] = useLazyQuery<
        CriteriaQuery,
        CriteriaQueryVariables
    >(CRITERIA_FILTERED, {
        variables: {
            filter: {
                id_not_in: currentFieldIds,
                criteriaType_in: options,
                isArchived: null,
            },
        },
    })
    const fields = data?.Criteria ?? []
    const onChange = async (field: Criteria) => {
        setAdding(true)
        await onSelect(field)
        if (!!mounted.current) {
            setInputValue("")
            setAdding(false)
        }
    }

    useEffect(() => {
        mounted.current = true
        return () => {
            mounted.current = false
        }
    }, [])

    const fieldsWithCategoryGroupings = fields.map((field) => {
        return {
            ...field,
            mostSpecificCategoryId: !!categoriesToGroupBy
                ? categoriesToGroupBy.find(
                      (cat) => !!cat.criteria.find((c) => c.id === field.id)
                  )?.id || null
                : null,
        }
    })

    const filteredFields: (Criteria & {
        mostSpecificCategoryId?: string
    })[] = fieldsWithCategoryGroupings
        .filter(
            (field) =>
                (selectedCriteriaType === "All"
                    ? true
                    : selectedCriteriaType === field.criteriaType) &&
                !!field.name
        )
        .sort(function (a, b) {
            return a.mostSpecificCategoryId?.localeCompare(
                b.mostSpecificCategoryId
            )
        })

    return !!showButton ? (
        <Button
            onClick={() => setShowButton(false)}
            size="small"
            color="secondary"
            style={{ width: "100%" }}
            startIcon={<Add fontSize="small" />}
        >
            {optionsAllowed === "Sub"
                ? t("addSubfield", "Add Subfield")
                : t("addField", "Add Field")}
        </Button>
    ) : (
        <>
            <Box
                width="100%"
                display="flex"
                alignItems="center"
                border={`1px solid ${theme.palette.divider}`}
                borderRadius={theme.shape.borderRadius}
                p={0.5}
                pb={0}
                style={{
                    backgroundColor: !!colorBackground
                        ? alpha(theme.palette.primary.light, 0.05)
                        : theme.palette.background.paper,
                }}
            >
                <Box mr={1} width={"12em"}>
                    <FieldTypeSelect
                        typeOptions={options}
                        value={selectedCriteriaType}
                        onChange={(value: CriteriaType | "All") => {
                            setSelectedCriteriaType(value)
                            setTimeout(() => {
                                autocompleteRef.current?.focus()
                            }, 100)
                        }}
                        variant="standard"
                        size="small"
                        includeAll={true}
                    />
                </Box>

                <Autocomplete
                    fullWidth
                    onChange={(
                        event,
                        newValue: Criteria & { mostSpecificCategoryId: string },
                        reason
                    ) => {
                        if (reason === "select-option") {
                            setInputValue("")
                            onChange(
                                fields.find((field) => field.id === newValue.id)
                            )
                        }
                    }}
                    filterOptions={createFilterOptions({
                        limit: 25,
                    })}
                    size="small"
                    renderGroup={(params) => {
                        const category = categoriesToGroupBy?.find(
                            (cat) => cat.id === params.group
                        )
                        return (
                            <li key={params.group}>
                                {!!category ? (
                                    <Box
                                        p={1}
                                        display="flex"
                                        color={theme.palette.text.secondary}
                                    >
                                        <Typography
                                            style={{
                                                flexGrow: 1,
                                            }}
                                            variant="subtitle2"
                                            color="textSecondary"
                                        >
                                            {category?.name}{" "}
                                            {t("fields", "Fields")}
                                        </Typography>
                                        <CategoryIcon
                                            fontSize="small"
                                            category={category}
                                        />
                                    </Box>
                                ) : (
                                    <Typography
                                        style={{
                                            flexGrow: 1,

                                            padding: theme.spacing(1),
                                        }}
                                        variant="subtitle2"
                                        color="textSecondary"
                                    >
                                        {params.group}
                                    </Typography>
                                )}

                                <ul style={{ paddingLeft: 0 }}>
                                    {params.children}
                                </ul>
                            </li>
                        )
                    }}
                    groupBy={(value) =>
                        !!categoriesToGroupBy &&
                        !!fieldsWithCategoryGroupings.find(
                            (item) => !!item.mostSpecificCategoryId
                        )
                            ? value.mostSpecificCategoryId || "All Other Fields"
                            : "All Available Fields"
                    }
                    loading={loading}
                    selectOnFocus
                    clearOnBlur
                    onFocus={() => {
                        if (!called) {
                            fetchAllFields()
                        }
                    }}
                    openOnFocus
                    blurOnSelect
                    value={null}
                    disableClearable
                    disabled={!!adding}
                    getOptionLabel={(option) => option.name}
                    renderOption={(option) => {
                        return <FieldListItem key={option.id} field={option} />
                    }}
                    options={filteredFields}
                    onInputChange={(event, newInputValue) => {
                        setInputValue(newInputValue)
                    }}
                    forcePopupIcon={false}
                    inputValue={inputValue}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label={
                                !hideLabel
                                    ? t(
                                          "addExistingField",
                                          "Add Existing Field"
                                      )
                                    : ""
                            }
                            inputRef={autocompleteRef}
                            variant="standard"
                            autoFocus={true}
                            value={inputValue}
                            InputProps={{
                                ...params.InputProps,
                                disableUnderline: true,
                                startAdornment: (
                                    <>
                                        <InputAdornment position="start">
                                            {!!adding ? (
                                                <CircularProgress
                                                    size={20}
                                                    disableShrink
                                                />
                                            ) : (
                                                <SearchIcon fontSize="small" />
                                            )}
                                        </InputAdornment>
                                        {params.InputProps.startAdornment}
                                    </>
                                ),
                            }}
                        />
                    )}
                />

                {!!onCreateNewField && (
                    <Box pb={0.5}>
                        <Button
                            onClick={onCreateNewField}
                            size="small"
                            color="secondary"
                            startIcon={<Add fontSize="small" />}
                        >
                            {t("new", "New")}
                        </Button>
                    </Box>
                )}
            </Box>
            <Box p={0.5} flexShrink={0} display="flex" alignItems="center">
                <IconButton onClick={() => setShowButton(true)}>
                    <Clear />
                </IconButton>
            </Box>
        </>
    )
}

export default FieldAutocomplete
