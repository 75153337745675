import { gql } from "@apollo/client"

export const ALL_TEAM_QUERY = gql`
    query AllTeam {
        teams: Team(orderBy: name_asc) {
            teamId
            name
            imageUrl
        }
    }
`

export const REMOVE_TEAM_MEMBER = gql`
    mutation RemoveTeamMember($fromId: ID!, $toId: ID!) {
        RemoveTeamMembers(from: { userId: $fromId }, to: { teamId: $toId }) {
            from {
                userId
                firstName
                lastName
            }
            to {
                teamId
                name
                members(orderBy: lastName_asc) {
                    userId
                    firstName
                    lastName
                    username
                    imageUrl
                    conceptsCreated {
                        id
                        title
                        summary
                        type
                        createdBy {
                            userId
                            username
                            firstName
                            lastName
                        }
                    }
                    numConceptsCreated
                    bio
                }
            }
        }
    }
`
export const ADD_TEAM_MEMBER = gql`
    mutation AddTeamMember($fromId: ID!, $toId: ID!) {
        AddTeamMembers(from: { userId: $fromId }, to: { teamId: $toId }) {
            from {
                userId
                firstName
                lastName
            }
            to {
                teamId
                name
                members(orderBy: lastName_asc) {
                    userId
                    firstName
                    lastName
                    username
                    imageUrl
                    conceptsCreated {
                        id
                        title
                        summary
                        type
                        createdBy {
                            userId
                            username
                            firstName
                            lastName
                        }
                    }
                    numConceptsCreated
                    bio
                }
            }
        }
    }
`

export const ADD_TEAM_ADMIN = gql`
    mutation AddTeamAdmin($fromId: ID!, $toId: ID!) {
        AddTeamAdmin(from: { userId: $fromId }, to: { teamId: $toId }) {
            from {
                userId
                firstName
                lastName
            }
            to {
                teamId
                name
                admin {
                    userId
                    firstName
                    lastName
                }
            }
        }
    }
`

export const REMOVE_TEAM_ADMIN = gql`
    mutation RemoveTeamAdmin($fromId: ID!, $toId: ID!) {
        RemoveTeamAdmin(from: { userId: $fromId }, to: { teamId: $toId }) {
            from {
                userId
                firstName
                lastName
            }
            to {
                teamId
                name
                admin {
                    userId
                    firstName
                    lastName
                }
            }
        }
    }
`

export const ADD_TEAM_CONCEPT = gql`
    mutation AddTeamConcepts($fromId: ID!, $toId: ID!) {
        AddTeamConcepts(from: { teamId: $fromId }, to: { id: $toId }) {
            from {
                teamId
                name
            }
            to {
                id
                title
            }
        }
    }
`

export const REMOVE_TEAM_CONCEPT = gql`
    mutation RemoveTeamConcepts($fromId: ID!, $toId: ID!) {
        RemoveTeamConcepts(from: { teamId: $fromId }, to: { id: $toId }) {
            from {
                teamId
                name
            }
            to {
                id
                title
            }
        }
    }
`

export const TEAM_PROFILE_QUERY = gql`
    query TeamProfile($teamId: ID!) {
        Team(teamId: $teamId) {
            teamId
            name
            description
            imageUrl
            leader {
                userId
                firstName
                lastName
                username
                imageUrl
                email
            }
            admin {
                userId
                firstName
                lastName
                email
            }
            members(orderBy: lastName_asc) {
                userId
                firstName
                lastName
                username
                imageUrl
                email
                numConceptsCreated
                bio
            }
        }
    }
`

export const TEAMS_QUERY = gql`
    query Team($userFilter: _UserFilter) {
        Team(filter: { members_single: $userFilter }, orderBy: name_asc) {
            name
            teamId
            imageUrl
            description
            leader {
                userId
                firstName
                lastName
                username
                imageUrl
            }
            members(orderBy: lastName_asc) {
                userId
                firstName
                lastName
                username
                email
            }
            admin {
                userId
                firstName
                lastName
                email
            }
            conceptLists(filter: { type: TEAM_WORKSPACE }) {
                id
                concepts {
                    id
                }
            }
        }
    }
`

export const UPDATE_TEAM = gql`
    mutation UpdateTeam(
        $teamId: ID!
        $name: String
        $description: String
        $imageUrl: String
    ) {
        UpdateTeam(
            teamId: $teamId
            name: $name
            description: $description
            imageUrl: $imageUrl
        ) {
            teamId
            name
            description
            imageUrl
        }
    }
`

export const CREATE_TEAM_WITH_USERS = gql`
    mutation createTeamWithUsers($team: TeamInput) {
        CreateTeamWithUsers(team: $team) {
            teamId
            name
            description
            leader {
                userId
                firstName
                lastName
            }
            members(orderBy: lastName_asc) {
                userId
                firstName
                lastName
            }
            admin {
                userId
                firstName
                lastName
            }
        }
    }
`

export const ADD_TEAM_LEADER = gql`
    mutation AddTeamLeader($fromId: ID!, $toId: ID!) {
        AddTeamLeader(from: { userId: $fromId }, to: { teamId: $toId }) {
            from {
                userId
                firstName
                lastName
            }
            to {
                teamId
                name
                leader {
                    userId
                    firstName
                    lastName
                }
            }
        }
    }
`
export const REMOVE_TEAM_LEADER = gql`
    mutation RemoveTeamLeader($fromId: ID!, $toId: ID!) {
        RemoveTeamLeader(from: { userId: $fromId }, to: { teamId: $toId }) {
            from {
                userId
                firstName
                lastName
            }
            to {
                teamId
                name
                leader {
                    userId
                    firstName
                    lastName
                }
            }
        }
    }
`
export const DELETE_TEAM = gql`
    mutation DeleteTeam($teamId: ID!) {
        DeleteTeam(teamId: $teamId) {
            teamId
            name
            description
            leader {
                userId
                firstName
            }
            members(orderBy: lastName_asc) {
                userId
                firstName
                lastName
            }
        }
    }
`
export const ADD_TEAM_HOME_CONCEPT = gql`
    mutation AddTeamHomeConcept($teamId: ID!, $conceptId: ID!) {
        AddTeamHomeConcept(from: { teamId: $teamId }, to: { id: $conceptId }) {
            from {
                teamId
            }
            to {
                id
            }
        }
    }
`
