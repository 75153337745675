import { ListItem, ListItemIcon, ListItemText, Box } from "@material-ui/core"

import { makeStyles, Theme, createStyles } from "@material-ui/core/styles"
import TreeItem from "@material-ui/lab/TreeItem"
import { Category } from "../../__generated__/types"
import useCategoryTools from "./useCategoryTools"
import CategoryIcon from "./CategoryIcon"

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        treeItem: {
            backgroundColor: "transparent !important",
            padding: 0,
        },
        iconContainer: {
            "&:hover": {
                backgroundColor: theme.palette.action.hover + " !important",
            },

            borderRadius: theme.shape.borderRadius,
            display: "flex",
            alignItems: "center",
            margin: 0,
            width: "30px",
        },
        inactiveIconContainer: {
            cursor: "default",
            margin: 0,
            width: "30px",
        },
        content: {
            alignItems: "stretch",
        },
    })
)

const CategoryItem = (props: {
    node: Category
    setValue: (value: Category) => void
    value: Category | null
    hiddenOptions?: string[]
}) => {
    const { node, setValue, value, hiddenOptions } = props
    const classes = useStyles()
    const { environmentCategories } = useCategoryTools()
    const children = environmentCategories.filter(
        (cat) => cat.parent?.id === node.id
    )
    const disableSelection = hiddenOptions?.includes(node.id)
    if (!!disableSelection) {
        return <Box key={node.id} />
    }
    return (
        <Box key={node.id}>
            <TreeItem
                classes={{
                    label: classes.treeItem,
                    iconContainer:
                        children?.length > 0
                            ? classes.iconContainer
                            : classes.inactiveIconContainer,
                    content: classes.content,
                }}
                key={node.id}
                nodeId={node.id}
                onLabelClick={(e) => {
                    if (!disableSelection) {
                        setValue(node)
                    }

                    e.preventDefault()
                }}
                label={
                    <ListItem
                        disabled={disableSelection}
                        dense
                        button
                        selected={node.id === value?.id}
                    >
                        <ListItemText primary={node.name} />
                        {node.isRoot && (
                            <ListItemIcon>
                                <CategoryIcon
                                    category={node}
                                    fontSize="small"
                                />
                            </ListItemIcon>
                        )}
                    </ListItem>
                }
            >
                {Array.isArray(children)
                    ? children?.map((node) => (
                          <CategoryItem
                              key={node.id}
                              node={node}
                              setValue={setValue}
                              value={value}
                              hiddenOptions={hiddenOptions}
                          />
                      ))
                    : null}
            </TreeItem>
        </Box>
    )
}

export default CategoryItem
