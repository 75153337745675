import { Box, Paper } from "@material-ui/core"
import { _ConceptOrdering } from "../../../__generated__/types"
import WidgetHeader from "./common/WidgetHeader"
import useWidget from "../useWidget"
import { WidgetTypeComponent } from "../useWidgetTypes"
import { useParams } from "react-router-dom"
import { FilterItem } from "../../filters/util/types"
import HypeChart from "../../charts/HypeChart"
import WidgetFilterToggle from "./common/WidgetFilterToggle"

import useFilters from "../../filters/util/useFilters"
import useCategoryTools from "../../categories/useCategoryTools"
import { useState } from "react"

type WidgetConfig = {
    conceptId?: string
    label: string
    orderBy: _ConceptOrdering
    filters?: FilterItem[]
    source: string
}

const HypeChartWidget: WidgetTypeComponent = (props) => {
    //hooks
    const { conceptId } = useParams()
    const [hovered, setHovered] = useState(false)
    const { getCategoryArray } = useCategoryTools()
    const categoryArray = getCategoryArray(props.concept.category?.id)
    const { config, isInitialized } = useWidget<WidgetConfig>(props.widget.id)
    const { currentFeedVariables, setCurrentFeedVariables, filterObject } =
        useFilters({
            viewPrivate: true,
            filters: config?.filters,
            connectionsConceptId:
                config?.source === "connections" ? conceptId : null,
        })

    return (
        <Box
            component={!props.isDialog ? Paper : "div"}
            display="flex"
            height="100%"
            width="100%"
            flexDirection="column"
            key={props.widget.id}
            onMouseOver={() => {
                if (!props.isDialog && !hovered) {
                    setHovered(true)
                }
            }}
            onMouseLeave={() => {
                if (!props.isDialog && !!hovered) {
                    setHovered(false)
                }
            }}
        >
            <WidgetHeader
                hovered={hovered || !!props.isDialog}
                onWidgetDeletion={props.onWidgetDeletion}
                label={config?.label}
                interactionTools={[
                    <WidgetFilterToggle
                        key={`${props.widget.id}_filter`}
                        feedVariables={currentFeedVariables}
                        size="medium"
                        isDialog={props.isDialog}
                        setFeedVariables={setCurrentFeedVariables}
                    />,
                ]}
                widget={props.widget}
                editing={props.editing}
            />
            {isInitialized && (
                <Box key={props.widget.id} overflow="hidden" flexGrow={1}>
                    <HypeChart
                        filter={filterObject}
                        locked={!props.editing}
                        pageConcept={
                            categoryArray?.[0]?.name === "Trend"
                                ? props.concept
                                : null
                        }
                    />
                </Box>
            )}
        </Box>
    )
}

export default HypeChartWidget
