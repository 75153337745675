import { makeVar, useQuery, useReactiveVar } from "@apollo/client"
import {
    Typography,
    useMediaQuery,
    Toolbar,
    Drawer,
    Button,
    Divider,
} from "@material-ui/core"
import Box from "@material-ui/core/Box"
import CircularProgress from "@material-ui/core/CircularProgress"
import { makeStyles, useTheme, decomposeColor } from "@material-ui/core/styles"
import React, { useCallback, useEffect, useRef, useState } from "react"
import useAwaitTranslation from "../i18n/useAwaitTranslation"
import { CONCEPT_FEED } from "../graphql/queries"
import {
    ConceptFeedQuery,
    ConceptFeedQueryVariables,
} from "../graphql/__generated__/queries"
import {
    feedVariablesVar,
    FeedVariables,
    VIEW_OPTIONS,
} from "../providers/GlobalState"
import ConceptCard from "./ConceptCard"
import { Loading } from "./Loading"
import VirtualInfiniteList from "./VirtualInfiniteList"
import FeedCommentsContainer from "./commenting/FeedCommentsContainer"
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer"
import Filters from "./filters/Filters"
import clsx from "clsx"
import { AccessType, _ConceptOrdering } from "../__generated__/types"
import ConceptFeedHeader from "./ConceptFeedHeader"
import ErrorHandler from "./ErrorHandler"
import ChevronLeft from "@material-ui/icons/ChevronLeft"
import useFilters from "./filters/util/useFilters"
import ConceptFeedGraphView from "./ConceptFeedGraphView"
import { useAuth } from "../providers/AuthProvider"

const drawerWidth = 350
const closedDrawerWidth = 0
const useStyles = makeStyles((theme) => ({
    rootBox: {
        alignItems: "flex-start",
        [theme.breakpoints.up("sm")]: {
            display: "flex",
            flexDirection: "column",
        },
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
        zIndex: theme.zIndex.appBar - 2,
    },
    drawerPaper: {
        width: drawerWidth,

        //@ts-ignore
        backgroundColor: theme.palette.background.level2,
    },
    sidebarContent: {
        display: "flex",
        flexDirection: "column",
        height: "100%",
        overflow: "hidden",
        flexGrow: 1,
    },
    sidebarHeader: {
        marginBottom: theme.spacing(2),
        marginTop: theme.spacing(1),
        padding: theme.spacing(1),
        display: "flex",
    },
    grey: {
        color: theme.palette.text.secondary,
        fontSize: "12px",
    },
    loading: {
        width: "100%",
        textAlign: "center",
        height: "100%",
    },
    toggleButton: {
        backgroundColor: theme.palette.background.paper,
        border: "1px solid",
        borderColor: theme.palette.divider,
        zIndex: theme.zIndex.appBar,
        position: "fixed",
    },
    toggleContainer: {
        position: "sticky",
        top: "64px",
        display: "flex",
        justifyContent: "center",
        zIndex: theme.zIndex.appBar - 1,
    },
    divider: {
        position: "fixed",
        top: 0,
        bottom: 0,
    },
    toggleFilterContainer: {
        top: theme.spacing(9.5),
        position: "absolute",
        height: "100%",
    },
    swipeableDrawerClosed: {
        width: 0,
        zIndex: theme.zIndex.appBar - 2,
    },
    drawerOpen: {
        width: drawerWidth,
        transition: theme.transitions.create("width", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
        //@ts-ignore
        backgroundColor: theme.palette.background.level2,
    },
    swipeableDrawerOpen: {
        width: drawerWidth,
    },
    drawerClose: {
        overflowX: "hidden",

        width: closedDrawerWidth,

        //@ts-ignore
        backgroundColor: theme.palette.background.level2,
    },
    toolbar: {
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
    },
    toggleAreaContainer: {
        minWidth: closedDrawerWidth,
        borderRight: `1px solid ${theme.palette.divider}`,
        "&:hover": {
            backgroundColor: theme.palette.action.hover,
        },
        cursor: "pointer",
    },
    selectorContainer: {
        zIndex: theme.zIndex.appBar - 5,
        backdropFilter: "blur(12px)",
        width: "100%",
        backgroundColor: `rgba(${
            decomposeColor(theme.palette.background.default).values
        }, .25)`,
    },
}))

export const DEFAULT_PAGE_SIZE = 12
export const DEFAULT_CONCEPTS_PER_GRAPH = 50
const LIMIT_VAR = makeVar(DEFAULT_PAGE_SIZE)

export default function ConceptFeed() {
    //hooks
    const classes = useStyles()
    const theme = useTheme()
    const mobile = useMediaQuery(theme.breakpoints.down("sm"))
    const { t } = useAwaitTranslation("ideas")
    const { isNonMember, currentUser } = useAuth()
    //refs
    let isFetchingMore = useRef(false)

    //global state

    const currentFeedVariables = useReactiveVar(feedVariablesVar)
    const limit = useReactiveVar(LIMIT_VAR)
    const currentColumnCount = !!mobile ? 1 : currentFeedVariables.columnCount
    const { getCleanedFilterItems, getFilterObject } = useFilters()
    //local state
    const [localFilterState, setLocalFilterState] = React.useState({})
    const [commentConceptId, setCommentConceptId] = useState(null)
    let [showPullToRefreshIndicator, setShowPullToRefreshIndicator] =
        useState(false)
    //filter parsing

    const filters = getFilterObject({
        ...currentFeedVariables,
        filters: getCleanedFilterItems(currentFeedVariables.filters, false),
    })

    //queries
    const { loading, error, data, fetchMore, refetch } = useQuery<
        ConceptFeedQuery,
        ConceptFeedQueryVariables
    >(CONCEPT_FEED, {
        variables: {
            filter: !isNonMember
                ? {
                      ...filters,
                  }
                : {
                      OR: [
                          {
                              addedTeams_some: {
                                  Team: {
                                      teamId_in:
                                          currentUser.teams
                                              ?.map((team) => team.teamId)
                                              ?.filter((x) => !!x) ?? [],
                                  },
                                  type_not: AccessType.NONE,
                              },
                          },
                          {
                              addedUsers_some: {
                                  User: {
                                      userId: currentUser.userId,
                                  },
                                  type_not: AccessType.NONE,
                              },
                          },
                      ],
                  },
            offset:
                currentFeedVariables.displayType === VIEW_OPTIONS.NORMAL
                    ? 0
                    : currentFeedVariables.page * DEFAULT_CONCEPTS_PER_GRAPH -
                      DEFAULT_CONCEPTS_PER_GRAPH,
            first:
                currentFeedVariables.displayType === VIEW_OPTIONS.NORMAL
                    ? limit + DEFAULT_PAGE_SIZE
                    : DEFAULT_CONCEPTS_PER_GRAPH,
            sort: [
                currentFeedVariables?.sortBy ?? _ConceptOrdering.CREATEDAT_DESC,
            ],
        },
        fetchPolicy:
            currentFeedVariables.displayType === VIEW_OPTIONS.NORMAL
                ? "cache-first"
                : "network-only",
    })
    //callbacks
    const onFetchMore = useCallback(
        async (offset) => {
            LIMIT_VAR(offset)
            if (!fetchMore) return
            const result: { data: ConceptFeedQuery } = await fetchMore({
                variables: {
                    offset,
                },
            })

            return result.data.Concept.length >= DEFAULT_PAGE_SIZE
        },
        [fetchMore]
    )

    //variables
    const feedData = data?.Concept ?? []
    const size = Math.ceil(feedData.length / currentColumnCount)

    const displayType = currentFeedVariables?.displayType
    const showSidebar = currentFeedVariables.pinFilters || !!commentConceptId
    const currentWidthToSubtract = showSidebar ? drawerWidth : closedDrawerWidth

    useEffect(() => {
        let _startY = 0
        const onTouchStart = (e: WindowEventMap["touchstart"]) => {
            _startY = e.touches[0].pageY
        }
        const onTouchMove = (e: WindowEventMap["touchmove"]) => {
            const y = e.touches[0].pageY
            // Activate pull-to-refresh when at the top of the container
            // and user is scrolling up.
            if (
                document.scrollingElement.scrollTop <= 0 &&
                // add 100 offset
                y > _startY + 100 &&
                !isFetchingMore.current
            ) {
                setShowPullToRefreshIndicator(true)
                isFetchingMore.current = true
                refetch({
                    offset: 0,
                }).then(() => {
                    setShowPullToRefreshIndicator(false)
                    isFetchingMore.current = false
                })
            }
        }
        window.addEventListener("touchmove", onTouchMove, { passive: true })
        window.addEventListener("touchstart", onTouchStart, { passive: true })

        return () => {
            window.removeEventListener("touchmove", onTouchMove)
            window.removeEventListener("touchstart", onTouchMove)
        }
    }, [refetch])

    useEffect(() => {
        if (JSON.stringify(localFilterState) !== JSON.stringify(filters)) {
            setCommentConceptId(null)
            LIMIT_VAR(DEFAULT_PAGE_SIZE)
            setLocalFilterState(filters)
        }
    }, [limit, refetch, data, filters, localFilterState])

    if (error)
        return <ErrorHandler message={error.message} showMessage={false} />
    const sidebarContents = (
        <Box className={classes.sidebarContent}>
            {commentConceptId ? (
                <FeedCommentsContainer
                    concept={
                        feedData.filter((x) => x.id === commentConceptId)[0]
                    }
                    closeComments={() => {
                        setCommentConceptId(null)
                    }}
                />
            ) : (
                <Box flexGrow={"1"}>
                    {!mobile && (
                        <>
                            <Box display="flex" justifyContent="flex-start">
                                <Button
                                    size="small"
                                    onClick={() => {
                                        feedVariablesVar({
                                            ...currentFeedVariables,
                                            pinFilters: false,
                                        })
                                    }}
                                    startIcon={<ChevronLeft fontSize="small" />}
                                >
                                    Unpin
                                </Button>
                            </Box>
                            <Divider />
                        </>
                    )}
                    <Filters
                        feedVariables={currentFeedVariables}
                        onChangeFeedVariables={(value: FeedVariables) =>
                            feedVariablesVar({
                                ...value,
                                page: 1,
                            })
                        }
                        excludeCategory={true}
                    />
                </Box>
            )}
        </Box>
    )

    return (
        <Box
            className={classes.rootBox}
            position="relative"
            height={!!loading ? "100%" : "auto"}
        >
            <Box
                className={classes.selectorContainer}
                position={
                    displayType === VIEW_OPTIONS.NORMAL ? "sticky" : "absolute"
                }
                top={displayType === VIEW_OPTIONS.NORMAL ? theme.spacing(8) : 0}
                style={{
                    width: mobile
                        ? "100vw"
                        : "calc(100% - " + currentWidthToSubtract + "px)",
                }}
            >
                <ConceptFeedHeader
                    onToggleSidebar={() => {
                        setCommentConceptId(null)
                        feedVariablesVar({
                            ...currentFeedVariables,
                            pinFilters: !currentFeedVariables.pinFilters,
                        })
                    }}
                    filterState={filters}
                />
            </Box>
            <Box
                pl={displayType === VIEW_OPTIONS.NORMAL ? 1.5 : 0}
                pr={displayType === VIEW_OPTIONS.NORMAL ? 1.5 : 0}
                style={{
                    width: mobile
                        ? "100%"
                        : "calc(100% - " + currentWidthToSubtract + "px)",
                }}
                height="100%"
            >
                {!!loading ? (
                    <div className={classes.loading}>
                        <Loading size={25} hideQuote={true} />
                    </div>
                ) : feedData.length > 0 ? (
                    <Box
                        width="100%"
                        height={
                            currentFeedVariables.displayType ===
                            VIEW_OPTIONS.NORMAL
                                ? "100%"
                                : "calc(100vh - 64px)"
                        }
                    >
                        {showPullToRefreshIndicator && (
                            <Box display="flex" justifyContent="center">
                                <CircularProgress color="secondary" />
                            </Box>
                        )}
                        {/* TODO add toggle between these views in top bar next to pGafilter popper */}
                        {displayType !== VIEW_OPTIONS.NORMAL ? (
                            <ConceptFeedGraphView feedData={feedData} />
                        ) : (
                            <VirtualInfiniteList
                                key={JSON.stringify(localFilterState)}
                                onFetchMore={onFetchMore}
                                startOffset={limit}
                                overscan={Math.ceil(10 / currentColumnCount)}
                                pageSize={DEFAULT_PAGE_SIZE}
                                size={size}
                            >
                                {(itemIndex) => (
                                    <Box
                                        display="grid"
                                        gridTemplateColumns={`repeat(${currentColumnCount}, 1fr)`}
                                        gridGap="15px"
                                    >
                                        {new Array(currentColumnCount)
                                            .fill(1)
                                            .map((_, index) => {
                                                const item =
                                                    feedData[
                                                        itemIndex *
                                                            currentColumnCount +
                                                            index
                                                    ]
                                                return item ? (
                                                    <ConceptCard
                                                        key={item.id}
                                                        item={item}
                                                        nonGrid={
                                                            currentColumnCount ===
                                                                1 && !mobile
                                                        }
                                                        viewComments={() => {
                                                            setCommentConceptId(
                                                                item.id
                                                            )
                                                        }}
                                                    />
                                                ) : null
                                            })}
                                    </Box>
                                )}
                            </VirtualInfiniteList>
                        )}
                    </Box>
                ) : (
                    <Box
                        padding={4}
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        width="100%"
                        mt={`${theme.spacing(5)}px`}
                    >
                        <Typography variant="h6">
                            {t("noConcepts", "No Concepts")}
                        </Typography>
                    </Box>
                )}
            </Box>
            {mobile ? (
                <SwipeableDrawer
                    open={showSidebar}
                    variant="temporary"
                    anchor="right"
                    onOpen={() =>
                        feedVariablesVar({
                            ...currentFeedVariables,
                            pinFilters: true,
                        })
                    }
                    onClose={() => {
                        if (!!commentConceptId) {
                            setCommentConceptId(null)
                        }

                        feedVariablesVar({
                            ...currentFeedVariables,
                            pinFilters: false,
                        })
                    }}
                    className={
                        showSidebar
                            ? classes.swipeableDrawerOpen
                            : classes.swipeableDrawerClosed
                    }
                    classes={{
                        paper: showSidebar ? classes.drawerPaper : "",
                    }}
                >
                    {sidebarContents}
                </SwipeableDrawer>
            ) : (
                <Drawer
                    anchor="right"
                    variant="persistent"
                    open={!mobile}
                    className={clsx(classes.drawer, {
                        [classes.drawerOpen]: showSidebar,
                        [classes.drawerClose]: !showSidebar,
                    })}
                    classes={{
                        paper: clsx({
                            [classes.drawerOpen]: showSidebar,
                            [classes.drawerClose]: !showSidebar,
                        }),
                    }}
                >
                    <Toolbar className={classes.toolbar} />

                    <Box display="flex" height="100%" overflow="hidden">
                        {showSidebar && sidebarContents}
                    </Box>
                </Drawer>
            )}
        </Box>
    )
}
