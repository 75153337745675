import Button from "@material-ui/core/Button"
import CircularProgress from "@material-ui/core/CircularProgress"
import Container from "@material-ui/core/Container"
import CssBaseline from "@material-ui/core/CssBaseline"
import Grid from "@material-ui/core/Grid"
import Link from "@material-ui/core/Link"
import { makeStyles, useTheme } from "@material-ui/core/styles"
import { TextField, Select } from "mui-rff"
import React, { useState, useEffect } from "react"
import { Form } from "react-final-form"
import { useHistory, useLocation } from "react-router-dom"
import Box from "@material-ui/core/Box"
import { useAuth } from "../providers/AuthProvider"
import { InputAdornment, MenuItem, Typography } from "@material-ui/core"
import useAwaitTranslation from "../i18n/useAwaitTranslation"
import { ReactComponent as AmbleLogo } from "../logo.svg"
import CheckIcon from "@material-ui/icons/Check"
import { FORM_ERROR } from "final-form"
import { parseDomainFromEmail } from "../util/fns"
import { SystemRole } from "../__generated__/types"

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
    },

    form: {
        width: "100%", // Fix IE 11 issue.
        marginTop: theme.spacing(3),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    progress: {
        position: "absolute",
    },
    box: {
        position: "absolute",
        left: 0,
        top: 0,
        right: 0,
        bottom: 0,
        backgroundColor: theme.palette.background.paper,
    },
}))

type ErrorsObject = {
    username?: string
    firstName?: string
    lastName?: string
    email?: string
    password?: string
    confirmPassword?: string
    language?: string
}
export default function SignupPage() {
    const classes = useStyles()
    const location = useLocation()
    const queryParams = new URLSearchParams(location.search)
    const continueUrl = queryParams.get("continueUrl")
    const theme = useTheme()
    const [langs, setLangs] = useState<{
        [key in string]: { nativeName: string }
    }>({ en: { nativeName: "English" } })

    const { signup, currentUser, logout } = useAuth()
    const history = useHistory()

    const onSubmitData = async (values) => {
        const email = values.email || currentUser.email
        const emailDomain = parseDomainFromEmail(email)
        const allowGuests =
            !!process.env.REACT_APP_ALLOW_UNINVITED_GUESTS &&
            process.env.REACT_APP_ALLOW_UNINVITED_GUESTS?.toLowerCase() ===
                "true"
        const role =
            (process.env.REACT_APP_COMPANY_EMAIL_DOMAIN === emailDomain &&
                !!currentUser?.emailVerified) ||
            !!allowGuests
                ? SystemRole.MEMBER
                : SystemRole.NONE

        let submissionError = null
        await signup({
            email: email,
            password: values.password || currentUser.refreshToken,
            userData: {
                ...values,
                email: email,
                imageUrl: currentUser?.photoURL ?? null,
                role,
            },
        }).catch((error) => {
            submissionError = {
                [FORM_ERROR]: error.message,
            }
        })
        return submissionError
    }

    const { i18n, t } = useAwaitTranslation("login")
    const needEmailPassword = !Boolean(currentUser?.displayName)

    useEffect(() => {
        i18n.services.backendConnector.backend.getLanguages(
            (_: any, ret: typeof langs) => {
                if (ret) {
                    setLangs(ret)
                }
            }
        )
    }, [i18n])

    const initialValues = currentUser?.displayName
        ? {
              firstName: currentUser?.displayName?.split(" ")[0],
              lastName: currentUser?.displayName?.split(" ")[1],
          }
        : {}

    return (
        <Box className={classes.box}>
            <Container component="main" maxWidth="xs">
                <CssBaseline />

                <div className={classes.paper}>
                    <Box
                        display="flex"
                        width="50%"
                        mb={2}
                        justifyContent="center"
                    >
                        <AmbleLogo style={{ height: "70px" }} />
                    </Box>

                    <Form
                        initialValues={initialValues}
                        onSubmit={onSubmitData}
                        validate={(values) => {
                            const errors: ErrorsObject = {}
                            if (!values.username) {
                                errors.username = t("required", "Required")
                            }
                            if (!values.firstName) {
                                errors.firstName = t("required", "Required")
                            }
                            if (!values.lastName) {
                                errors.lastName = t("required", "Required")
                            }
                            if (!values.language) {
                                errors.language = t("required", "Required")
                            }
                            if (needEmailPassword) {
                                if (!values.password) {
                                    errors.password = t("required", "Required")
                                }
                                if (!values.email) {
                                    errors.email = t("required", "Required")
                                }
                                if (!values.confirmPassword) {
                                    errors.confirmPassword = t(
                                        "required",
                                        "Required"
                                    )
                                } else if (
                                    values.confirmPassword !== values.password
                                ) {
                                    errors.confirmPassword = t(
                                        "pwnotmatch",
                                        "Must match"
                                    )
                                }
                            }

                            return errors
                        }}
                        render={({
                            handleSubmit,
                            submitting,
                            errors,
                            submitError,
                        }) => (
                            <form
                                className={classes.form}
                                onSubmit={handleSubmit}
                            >
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            variant="outlined"
                                            required
                                            fullWidth
                                            id="firstName"
                                            label={t("firstName", "First Name")}
                                            name="firstName"
                                            autoComplete="firstName"
                                            disabled={!!submitting}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            variant="outlined"
                                            required
                                            fullWidth
                                            id="lastName"
                                            label={t("lastName", "Last Name")}
                                            name="lastName"
                                            autoComplete="lastName"
                                            disabled={!!submitting}
                                        />
                                    </Grid>

                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            variant="outlined"
                                            required
                                            fullWidth
                                            id="username"
                                            label={t("userName", "User Name")}
                                            name="username"
                                            autoComplete="username"
                                            disabled={!!submitting}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            variant="outlined"
                                            fullWidth
                                            id="company"
                                            label={t("company", "Company")}
                                            name="company"
                                            autoComplete="company"
                                            disabled={!!submitting}
                                        />
                                    </Grid>

                                    <Grid item xs={12}>
                                        <TextField
                                            variant="outlined"
                                            required
                                            fullWidth
                                            id="email"
                                            label={t("email", "Email")}
                                            value={
                                                currentUser
                                                    ? currentUser.email
                                                    : undefined
                                            }
                                            disabled={
                                                !needEmailPassword ||
                                                !!submitting
                                            }
                                            InputProps={{
                                                endAdornment:
                                                    !needEmailPassword && (
                                                        <InputAdornment position="end">
                                                            <CheckIcon
                                                                style={{
                                                                    color: theme
                                                                        .palette
                                                                        .success
                                                                        .main,
                                                                }}
                                                            />
                                                        </InputAdornment>
                                                    ),
                                            }}
                                            name="email"
                                            autoComplete="email"
                                        />
                                    </Grid>
                                    {needEmailPassword && (
                                        <>
                                            <Grid item xs={12}>
                                                <TextField
                                                    variant="outlined"
                                                    required
                                                    fullWidth
                                                    name="password"
                                                    label={t(
                                                        "password",
                                                        "Password"
                                                    )}
                                                    type="password"
                                                    id="password"
                                                    autoComplete="current-password"
                                                    disabled={!!submitting}
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <TextField
                                                    variant="outlined"
                                                    required
                                                    fullWidth
                                                    name="confirmPassword"
                                                    label={t(
                                                        "password_confirm",
                                                        "Confirm Password"
                                                    )}
                                                    type="password"
                                                    id="confirmPassword"
                                                    autoComplete="current-password"
                                                    disabled={!!submitting}
                                                />
                                            </Grid>
                                        </>
                                    )}
                                    <Grid item xs={12}>
                                        <Select
                                            label={t("language", "Language")}
                                            variant="outlined"
                                            name="language"
                                            required
                                            disabled={!!submitting}
                                        >
                                            {Object.keys(langs).map(
                                                (languageString) => {
                                                    return (
                                                        <MenuItem
                                                            key={languageString}
                                                            value={
                                                                languageString
                                                            }
                                                        >
                                                            {
                                                                langs[
                                                                    languageString
                                                                ].nativeName
                                                            }
                                                        </MenuItem>
                                                    )
                                                }
                                            )}
                                        </Select>
                                    </Grid>
                                </Grid>
                                {!!submitError && (
                                    <Box mx={1} mt={2}>
                                        <Typography
                                            variant="caption"
                                            color="error"
                                        >
                                            {submitError}
                                        </Typography>
                                    </Box>
                                )}

                                <Button
                                    type="submit"
                                    fullWidth
                                    variant="contained"
                                    color="primary"
                                    className={classes.submit}
                                    disabled={
                                        submitting ||
                                        Object.keys(errors).length > 0
                                    }
                                >
                                    {t("createAccount", "Create Account")}
                                    {submitting && (
                                        <CircularProgress
                                            disableShrink
                                            size={30}
                                            className={classes.progress}
                                        />
                                    )}
                                </Button>
                                <Grid container justify="flex-end">
                                    {needEmailPassword ? (
                                        <Grid item>
                                            <Link
                                                onClick={() =>
                                                    history.push(
                                                        continueUrl
                                                            ? `/login?continueUrl=${continueUrl}`
                                                            : "/login"
                                                    )
                                                }
                                                variant="body2"
                                                disabled={!!submitting}
                                                component="button"
                                            >
                                                {t(
                                                    "signInMessage",
                                                    "Already have an account? Sign in"
                                                )}
                                            </Link>
                                        </Grid>
                                    ) : (
                                        <Grid item>
                                            <Button
                                                onClick={() =>
                                                    logout() &&
                                                    history.push("/login")
                                                }
                                            >
                                                Logout
                                            </Button>
                                        </Grid>
                                    )}
                                </Grid>
                            </form>
                        )}
                    />
                </div>
            </Container>
        </Box>
    )
}
