import { Icon } from "@material-ui/core"
import { Category } from "../../__generated__/types"
import { DEFAULT_ICON_NAMESPACE } from "../icons/IconPicker"
import useCategoryTools from "./useCategoryTools"

export default function CategoryIcon(props: {
    category?: Category
    styles?: React.CSSProperties
    fontSize?: "inherit" | "default" | "large" | "small"
}) {
    const { category, styles, fontSize } = props
    const { getCategoryArray } = useCategoryTools()
    const rootCategory = getCategoryArray(category?.id)?.[0]
    const iconName =
        JSON.parse(rootCategory?.icon ?? "{}")?.name || DEFAULT_ICON_NAMESPACE

    return (
        <Icon style={{ ...(styles ?? {}) }} fontSize={fontSize || "medium"}>
            {iconName}
        </Icon>
    )
}
