import React, { useEffect, useState } from "react"
import { getConceptLikedIcon } from "../../util/ConceptLikedStyles"
import { useMutation } from "@apollo/client"
import IconButton from "@material-ui/core/IconButton"
import Box from "@material-ui/core/Box"
import Tooltip from "@material-ui/core/Tooltip"
import { ADD_USER_LIKE, REMOVE_USER_LIKE } from "./graphql"
import {
    Add_User_LikeMutation,
    Add_User_LikeMutationVariables,
    Remove_User_LikeMutation,
    Remove_User_LikeMutationVariables,
} from "./__generated__/graphql"
import { useAuth } from "../../providers/AuthProvider"
import { useGlobalStyles } from "../../styles/globalStyles"
import useAwaitTranslation from "../../i18n/useAwaitTranslation"

interface LikeToggleProps {
    item: any
    disableRipple?: boolean
    size: "medium" | "small"
    location?: string
}
export default function LikeToggle(props: LikeToggleProps) {
    const { currentUser } = useAuth()
    const iconStyles = useGlobalStyles()
    const [like, setLike] = useState(null)
    const [likeLength, setLikeLength] = useState(null)
    const [addLike] = useMutation<
        Add_User_LikeMutation,
        Add_User_LikeMutationVariables
    >(ADD_USER_LIKE)
    const [removeLike] = useMutation<
        Remove_User_LikeMutation,
        Remove_User_LikeMutationVariables
    >(REMOVE_USER_LIKE)
    const { t } = useAwaitTranslation("translation")
    useEffect(() => {
        setLike(
            props.item.likedBy.filter((e) => e.userId === currentUser.userId)
                .length > 0
        )
        setLikeLength(props.item.likedBy.length)
    }, [props.item.likedBy, props.item.id, currentUser.userId])
    const handleLiked = (ev) => {
        const LikeObject = {
            userId: currentUser.userId as string,
            id: props.item.id as string,
        }
        ev.stopPropagation()
        if (like === false) {
            setLike(true)
            setLikeLength(likeLength + 1)
            addLike({ variables: { ...LikeObject } })
        } else {
            setLike(false)
            setLikeLength(likeLength - 1)
            removeLike({ variables: { ...LikeObject } })
        }
    }
    const LikeIcon = getConceptLikedIcon(like)
    return (
        <Tooltip title={like ? t("removeLike", "Remove like") : t("addLike", "Add like")} key={props.item.id}>
            <IconButton
                onClick={handleLiked}
                disableRipple={props.disableRipple ? true : false}
                size={props.size}
            >
                {LikeIcon && (
                    <LikeIcon
                        className={
                            props.location === "Concept Card"
                                ? iconStyles.smallLabelStylesIcon
                                : props.size !== "small"
                                ? iconStyles.labelStylesIcon
                                : ""
                        }
                    />
                )}
                <Box
                    component="span"
                    className={
                        props.location === "Concept Card"
                            ? iconStyles.smallCount
                            : iconStyles.count
                    }
                >
                    {likeLength}
                </Box>
            </IconButton>
        </Tooltip>
    )
}
