import React from "react"
import { Box, Typography, LinearProgress, Chip, alpha } from "@material-ui/core"
import { Concept, CriteriaScore } from "../../../__generated__/types"

import { NumberType } from "../../criteria/useCriteriaTypes"
import {
    Theme,
    createStyles,
    withStyles,
    useTheme,
} from "@material-ui/core/styles"
import ConceptListItem from "../../ConceptListItem"
import { getAverage } from "../../../util/fns"
interface NumberResponseViewerProps {
    config: NumberType
    scores?: CriteriaScore[]
    conceptsScored?: Concept[]
}

const NumberResponseViewer: React.FunctionComponent<
    NumberResponseViewerProps
> = ({ scores, config, conceptsScored = [] }) => {
    const theme = useTheme()

    const isSubfield = config?.source?.criteriaIds?.length > 0
    if (!!isSubfield) {
        const dataObjects: {
            concept: Concept
            scores: CriteriaScore[]
            label: string
            value: number
        }[] = conceptsScored
            ?.map((concept) => {
                let conceptScores =
                    scores?.filter(
                        (score) => score.scoredConcept?.id === concept.id
                    ) ?? []

                let base = 0

                conceptScores.map((conceptScore) => {
                    base += Number(conceptScore.response)
                    return base
                })

                return {
                    concept: concept,
                    scores: conceptScores,
                    label: concept.title,
                    value: Math.round((base / conceptScores.length) * 10) / 10,
                }
            })
            ?.sort((a, b) => b.value - a.value)
        const max = config.maximum ?? dataObjects?.[0]?.value
        return (
            <Box
                display="flex"
                flexDirection="column"
                style={{ overflowY: "auto" }}
            >
                {dataObjects.map((object, index) => {
                    return (
                        <Box
                            key={index}
                            display="flex"
                            flexDirection={"column"}
                        >
                            <Box position="relative" height="100%">
                                <Box
                                    position="absolute"
                                    right={theme.spacing(1)}
                                    bottom={0}
                                    top={0}
                                    left={theme.spacing(1)}
                                    display="flex"
                                    alignItems={"center"}
                                >
                                    <BorderLinearProgress
                                        value={(object.value / max) * 100}
                                        variant="determinate"
                                    />
                                </Box>

                                <Box display="flex" width="100%">
                                    <Box width="100%">
                                        <ConceptListItem
                                            item={object.concept}
                                            showSummary={false}
                                            hideDivider={true}
                                            hideCategory={true}
                                            secondaryIcon={
                                                <Chip
                                                    style={{
                                                        flexGrow: 1,
                                                        margin: 0.5,
                                                        marginRight: 8,
                                                        cursor: "pointer",
                                                    }}
                                                    color="secondary"
                                                    label={
                                                        <Box
                                                            display="flex"
                                                            alignItems="center"
                                                            justifyContent={
                                                                "flex-start"
                                                            }
                                                        >
                                                            {!!config.maximum ? (
                                                                <Typography variant="h6">
                                                                    {
                                                                        object.value
                                                                    }
                                                                    /
                                                                    <span
                                                                        style={{
                                                                            fontSize:
                                                                                "75%",
                                                                        }}
                                                                    >
                                                                        {
                                                                            config.maximum
                                                                        }
                                                                        {config?.suffix ??
                                                                            ""}
                                                                    </span>
                                                                </Typography>
                                                            ) : (
                                                                <Typography
                                                                    variant="body1"
                                                                    style={{
                                                                        flexGrow: 1,
                                                                    }}
                                                                >
                                                                    {config.prefix ??
                                                                        ""}
                                                                    {object.value.toLocaleString()}{" "}
                                                                    {config?.suffix ??
                                                                        ""}
                                                                </Typography>
                                                            )}
                                                        </Box>
                                                    }
                                                />
                                            }
                                        />
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                    )
                })}
            </Box>
        )
    } else {
        return <TopLevel scores={scores} config={config} />
    }
}

const BorderLinearProgress = withStyles((theme: Theme) =>
    createStyles({
        root: {
            height: "85%",
            flexGrow: 1,
            borderRadius: theme.shape.borderRadius,
        },
        colorPrimary: {
            backgroundColor: "transparent",
        },
        bar: {
            borderRadius: theme.shape.borderRadius,
            backgroundColor: alpha(theme.palette.primary.main, 0.15),
        },
    })
)(LinearProgress)

const TopLevel = (props: { scores: CriteriaScore[]; config: NumberType }) => {
    const { scores, config } = props
    let average = 0
    if (scores?.length > 0) {
        average = getAverage(
            scores
                .filter((score) => !!score.response)
                .map((score) => Number(score.response))
        )
    }

    if (!!config.maximum) {
        return (
            <Box
                width="100%"
                p={1}
                display="flex"
                height="100%"
                alignItems="center"
            >
                <Box p={1} width="100%" height="100%">
                    <BorderLinearProgress
                        value={(average / config.maximum) * 100}
                        variant="determinate"
                    />
                </Box>
                <Box ml={1}>
                    <Typography variant="h6">
                        {average}/
                        <span style={{ fontSize: "75%" }}>
                            {config.maximum}
                        </span>
                    </Typography>
                </Box>
            </Box>
        )
    } else {
        return (
            <Box p={1} height="100%" display={"flex"} alignItems="center">
                <Typography variant="h5">
                    {config.prefix}
                    {average.toLocaleString()} {config.suffix ?? ""}
                </Typography>
            </Box>
        )
    }
}

export default NumberResponseViewer
