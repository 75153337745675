import { Box, Paper } from "@material-ui/core"
import RichTextEditor from "../../text-editor/RichTextEditor"
import useWidget from "../useWidget"
import { WidgetTypeComponent } from "../useWidgetTypes"
import WidgetHeader from "./common/WidgetHeader"
import { toCapitalizedWords } from "../../../util/fns"
import { Loading } from "../../Loading"
import { useState } from "react"
type WidgetConfig = {
    label?: string
    text?: string
}

const RichTextEditorWidget: WidgetTypeComponent = (props) => {
    const [hovered, setHovered] = useState(false)
    //hooks
    const { value, config, onEditValue, isInitialized } =
        useWidget<WidgetConfig>(props.widget.id)

    //variables
    const text = value?.text || '[{"children":[{"text":""}]'

    return (
        <Box
            component={!props.isDialog ? Paper : "div"}
            display="flex"
            height="100%"
            width="100%"
            flexDirection="column"
            onMouseOver={() => {
                if (!props.isDialog && !hovered) {
                    setHovered(true)
                }
            }}
            onMouseLeave={() => {
                if (!props.isDialog && !!hovered) {
                    setHovered(false)
                }
            }}
        >
            <WidgetHeader
                hovered={hovered || !!props.isDialog}
                label={config?.label ?? toCapitalizedWords(props.widget?.type)}
                widget={props.widget}
                editing={props.editing}
                onWidgetDeletion={props.onWidgetDeletion}
            />

            {!!isInitialized ? (
                <RichTextEditor
                    editorId={
                        props.isDialog
                            ? "dialog-" + props.widget.id
                            : props.widget.id
                    }
                    editable={!!props.editing}
                    onChange={(text) => {
                        onEditValue({
                            ...value,
                            text,
                        })
                    }}
                    initialValue={text}
                />
            ) : (
                <Loading size={25} hideQuote={true} />
            )}
        </Box>
    )
}

export default RichTextEditorWidget
