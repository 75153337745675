import React from "react"
import {
    Box,
    Typography,
    Chip,
    Divider,
    Icon,
    alpha,
    useMediaQuery,
} from "@material-ui/core"
import ClickableRichTooltip from "../Popper/ClickableRichTooltip"
import RecordVoiceOver from "@material-ui/icons/RecordVoiceOver"
import ResponseListItem from "./ResponseListItem"

import { CriteriaType } from "../criteria/types"
import RichTextEditor, {
    BASE_INITIAL_TEXT,
} from "../text-editor/RichTextEditor"
import { useReactiveVar } from "@apollo/client"
import { myCurrentInputResponseVar } from "../../providers/GlobalState"
import DateIndividualResponse from "./date/IndividualResponse"
import NumberIndividualResponse from "./number/IndividualResponse"
import { getCleanedFieldConfig } from "./util"
import ConceptChip from "../ConceptChip"
import { useTheme } from "@material-ui/core/styles"

interface ResponsePreviewerProps {
    inputId: string
    hideValue?: boolean
    scoredConceptId?: string
    popper: boolean
}
const ResponsePreviewer: React.FC<ResponsePreviewerProps> = ({
    inputId,
    hideValue,
    scoredConceptId,
    popper = true,
}) => {
    const theme = useTheme()
    const mobile = useMediaQuery(theme.breakpoints.down("sm"))
    const { currentResponseData } = useReactiveVar(myCurrentInputResponseVar)
    const currentField = currentResponseData.find(
        (field) => field.input.id === inputId
    )
    const config = getCleanedFieldConfig(currentField)
    const scores =
        currentField?.scores?.filter(
            (score) =>
                !score.conceptDefault &&
                (!scoredConceptId ||
                    (currentField.input.criteria?.criteriaType ===
                    CriteriaType.Collection
                        ? score.concepts.find((o) => o.id === scoredConceptId)
                        : score.scoredConcept?.id === scoredConceptId))
        ) ?? []

    const criteriaType = currentField?.input?.criteria?.criteriaType
    const sortedScores = scores.sort(function (a, b) {
        var textA = (
            a.label || `${a.user?.firstName} ${a.user?.lastName}`
        )?.toUpperCase()
        var textB = (
            b.label || `${b.user?.firstName} ${b.user?.lastName}`
        )?.toUpperCase()
        return textA < textB ? -1 : textA > textB ? 1 : 0
    })

    const body = (
        <Box
            display="flex"
            flexDirection="column"
            overflow="hidden"
            height="100%"
            width="100%"
        >
            <Box
                p={1.5}
                style={{
                    backgroundColor: alpha(theme.palette.primary.main, 0.05),
                }}
                display="flex"
                justifyContent="space-between"
            >
                <Typography>All Responses </Typography>
                <Box display="flex">
                    <RecordVoiceOver fontSize="small" color="action" />
                    <Typography
                        style={{
                            marginLeft: theme.spacing(0.5),
                            fontWeight: 600,
                        }}
                        color="textSecondary"
                        variant="body2"
                    >
                        {scores.length}
                    </Typography>
                </Box>
            </Box>
            <Divider />
            <Box flexGrow={1} style={{ overflowY: "auto" }}>
                {sortedScores.map((score, index) => {
                    let responseViewer = null
                    switch (criteriaType) {
                        case CriteriaType.TextResponse:
                            responseViewer = (
                                <RichTextEditor
                                    initialValue={
                                        score.response ||
                                        JSON.stringify(BASE_INITIAL_TEXT)
                                    }
                                    editable={false}
                                    editorId={score.id}
                                />
                            )
                            break
                        case CriteriaType.Number:
                            responseViewer = (
                                <NumberIndividualResponse
                                    value={Number(score.response ?? "0")}
                                    config={config}
                                />
                            )
                            break
                        case CriteriaType.MultipleChoice:
                            const optionSelected = config?.options?.find(
                                (op) => op.id === score?.response
                            )

                            responseViewer = (
                                <Box display="flex" p={1}>
                                    {!!optionSelected.icon && (
                                        <Box component={"span"} mr={1}>
                                            <Icon>
                                                {optionSelected.icon.name}
                                            </Icon>
                                        </Box>
                                    )}
                                    <Typography>
                                        {optionSelected?.value}
                                    </Typography>
                                </Box>
                            )
                            break
                        case CriteriaType.Date:
                            responseViewer = (
                                <DateIndividualResponse
                                    score={score}
                                    config={config}
                                />
                            )
                            break
                        case CriteriaType.Collection:
                            responseViewer = (
                                <Box
                                    display="flex"
                                    p={1}
                                    width="100%"
                                    flexWrap="wrap"
                                    overflow="hidden"
                                >
                                    {score?.concepts?.map((concept) => (
                                        <Box
                                            key={concept.id}
                                            p={0.5}
                                            overflow="hidden"
                                            maxWidth="12rem"
                                        >
                                            <ConceptChip
                                                item={concept}
                                                disableClick
                                            />
                                        </Box>
                                    ))}
                                </Box>
                            )
                            break
                        default:
                    }
                    const isLast = index === scores.length - 1
                    return (
                        <Box key={score?.id}>
                            <Box
                                display="flex"
                                alignItems={
                                    criteriaType === CriteriaType.TextResponse
                                        ? "flex-start"
                                        : "center"
                                }
                            >
                                <Box
                                    width={!!responseViewer ? "15em" : "100%"}
                                    flexShrink={0}
                                    p={1.5}
                                >
                                    <ResponseListItem
                                        item={{
                                            label: score.label,
                                            user: score.user,
                                        }}
                                        isConceptDefault={
                                            !!score.conceptDefault
                                        }
                                    />
                                </Box>
                                {!!responseViewer && !hideValue && (
                                    <Box
                                        flexGrow={1}
                                        minWidth={"10em"}
                                        display="flex"
                                        justifyContent="center"
                                        width="100%"
                                    >
                                        {responseViewer}
                                    </Box>
                                )}
                            </Box>
                            {!isLast && <Divider />}
                        </Box>
                    )
                })}
            </Box>
        </Box>
    )
    if (!!popper) {
        return (
            <ClickableRichTooltip
                arrow={true}
                placement={"bottom-start"}
                content={
                    <Box
                        maxWidth={!!mobile ? 350 : 750}
                        maxHeight={450}
                        overflow="hidden"
                        display="flex"
                        flexDirection="column"
                    >
                        {body}
                    </Box>
                }
            >
                <Chip
                    size="small"
                    color="default"
                    disabled={scores.length === 0}
                    icon={<RecordVoiceOver color="action" fontSize="small" />}
                    label={
                        <Typography variant="body2">{scores.length}</Typography>
                    }
                />
            </ClickableRichTooltip>
        )
    } else {
        return body
    }
}

export default ResponsePreviewer
