export function toCapitalizedWords(name) {
    var words = name.match(/[A-Za-z][a-z]*/g) || []

    return words.map(capitalize).join(" ")
}

export function capitalize(word) {
    return word.charAt(0)?.toUpperCase() + word.substring(1)
}

export function reorderArray(
    list: Array<any>,
    startIndex: number,
    endIndex: number
) {
    const result = Array.from(list)
    const [removed] = result.splice(startIndex, 1)
    result.splice(endIndex, 0, removed)

    return result
}

export function isValidDate(d) {
    return d instanceof Date && !isNaN(d.getTime())
}
export function isJsonString(str) {
    try {
        JSON.parse(str)
    } catch (e) {
        return false
    }
    return true
}
export function getGermanTimeAgo(englishTime: string, shared: Boolean) {
    if (englishTime === "a day ago")
        return "vor einem Tag" + (shared ? " geteilt" : "")
    if (englishTime === "a few seconds ago")
        return "vor ein paar Sekunden" + (shared ? " geteilt" : "")
    if (englishTime === "an hour ago")
        return "vor einer Stunde" + (shared ? " geteilt" : "")

    if (shared) {
        switch (englishTime.split(" ")[1]) {
            case "minutes":
                return `geteilt vor ${englishTime.split(" ")[0]} Minuten`
            case "hours":
                return `geteilt vor ${englishTime.split(" ")[0]} Stunden`
            case "days":
                return `geteilt vor ${englishTime.split(" ")[0]} Tagen`
            case "months":
                return `geteilt vor ${englishTime.split(" ")[0]} Monaten`
            default:
                return "shared " + englishTime
        }
    } else {
        switch (englishTime.split(" ")[1]) {
            case "minutes":
                return `vor ${englishTime.split(" ")[0]} Minuten`
            case "hours":
                return `vor ${englishTime.split(" ")[0]} Stunden`
            case "days":
                return `vor ${englishTime.split(" ")[0]} Tagen`
            case "months":
                return `vor ${englishTime.split(" ")[0]} Monaten`
            default:
                return englishTime
        }
    }
}

export function getTranslatedWidgetName(
    englishWidgetName: string,
    t: (
        key: string | TemplateStringsArray | (string | TemplateStringsArray)[],
        df: string
    ) => string
) {
    switch (englishWidgetName) {
        case "Blank Notes":
            return t("blankNotes", "Blank Notes")
        case "Bubble Chart":
            return t("bubbleChart", "Bubble Chart")
        case "Concept":
            return t("concept", "Concept")
        case "List":
            return t("list", "List")
        case "Concepts Feed":
            return t("conceptsFeed", "Concepts Feed")
        case "Criteria Field":
            return t("criteriaField", "Criteria Field")
        case "Criteria Radar":
            return t("criteriaRadar", "Criteria Radar")
        case "Heading":
            return t("heading", "Heading")
        case "Hype-Chart":
            return t("hypeChart", "Hype Chart")
        case "Product Chart":
            return t("productChart", "Product Chart")
        case "Ranking Exercise":
            return t("rankingExercise", "Ranking Exercise")
        case "Roadmap":
            return t("roadmap", "Roadmap")
        case "Scoring Exercise":
            return t("scoringExercise", "Scoring Exercise")
        default:
            return englishWidgetName
    }
}

export function toTitleCase(text: string) {
    if (typeof text === "string") {
        return text.replace(/\w\S*/g, function (txt) {
            return txt.charAt(0)?.toUpperCase() + txt.substr(1)?.toLowerCase()
        })
    } else {
        return text
    }
}

export function isValidHttpUrl(text: string) {
    let url
    try {
        url = new URL(text)
    } catch (_) {
        return false
    }
    return url.protocol === "http:" || url.protocol === "https:"
}

export const getAverage = (arr: number[]): number => {
    if (arr.length === 0) return 0
    let reducer = (total, currentValue) => total + currentValue
    let sum = arr.reduce(reducer)
    return Math.round(sum / arr.length)
}

export const getMedian = (arr: number[]): number => {
    // First sort the arr in ascending order
    arr.sort((a, b) => a - b)

    // Calculate the middle index of the array
    const middleIndex = Math.floor(arr.length / 2)

    // If the array length is odd, return the middle item
    if (arr.length % 2) {
        return arr[middleIndex]
    } else {
        // If the array length is even, return the average of the two middle items
        return (arr[middleIndex - 1] + arr[middleIndex]) / 2.0
    }
}

export enum ActionValue {
    add = "add",
    remove = "remove",
    set = "set",
    updateProperties = "updateProperties",
}
export function OrderedStateReducer(
    state: any[],
    action: {
        value: ActionValue
        items: any[]
    }
) {
    let newState = [...(state ?? [])]
    if (action.value === ActionValue.remove) {
        newState = [
            ...newState.filter(
                (item) => !action.items.find((x) => x.id === item.id)
            ),
        ]
    } else if (action.value === ActionValue.set) {
        newState = [...action.items]
    } else if (action.value === ActionValue.add) {
        newState = [
            ...action.items.filter(
                (concept) => !newState.find((item) => item.id === concept.id)
            ),
            ...newState,
        ]
    } else if (action.value === ActionValue.updateProperties) {
        if (!state) {
            newState = [...action.items]
        } else {
            newState = newState.map((item, index) => ({
                ...item,
                ...(action.items.find((o) => o.id === item.id) ?? {}),
            }))
        }
    }
    return newState
}

export function dataURLtoFile(dataurl, filename) {
    var arr = dataurl.split(","),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n)

    while (n--) {
        u8arr[n] = bstr.charCodeAt(n)
    }

    return new File([u8arr], filename, { type: mime })
}

export function parseDomainFromEmail(email: string): string | null {
    // Regular expression pattern to extract domain name from email address
    const domainPattern: RegExp = /@([a-zA-Z0-9.-]+\.[a-zA-Z]{2,})$/

    // Match the domain pattern in the provided email string
    const matches: RegExpMatchArray | null = email.match(domainPattern)

    if (matches && matches.length > 1) {
        // The first captured group contains the domain name
        const domain: string = matches[1]
        return domain
    }

    // Return null if no domain is found
    return null
}
