import { gql } from "@apollo/client"
import { CRITERIA_SCORE_FRAGMENT } from "../components/criteria/graphql"
import { INPUT_SOURCE_CONCEPT_FRAGMENT } from "../components/inputs/graphql"

export const TRANSLATION_FRAGMENT = gql`
    fragment Translation on Translation {
        id
        languageString
        title
        summary
        description
    }
`

export const CONCEPT_FEED = gql`
    query ConceptFeed(
        $offset: Int
        $first: Int
        $filter: _ConceptFilter
        $sort: [_ConceptOrdering]
    ) {
        Concept(
            orderBy: $sort
            first: $first
            offset: $offset
            filter: $filter
        ) {
            id
            title
            translations {
                ...Translation
            }
            status(filter: { isActive: true }) {
                id
                createdAt {
                    formatted
                }
                stage {
                    id
                    title
                }
            }
            category {
                id
                name
                isRoot
                icon
            }
            summary
            description
            isPublic
            isPublicAccessType
            imageUrl
            commentCount
            parent {
                id
                title
            }
            createdAt {
                formatted
            }
            likedBy {
                userId
                username
            }
            createdBy {
                userId
                username
                firstName
                lastName
                imageUrl
            }
            connections {
                id
            }
            tags(orderBy: name_asc) {
                name
            }
        }
    }
    ${TRANSLATION_FRAGMENT}
`

export const CONCEPT_BY_ID = gql`
    query Concept($id: ID) {
        Concept(id: $id) {
            id
            title
            translations {
                ...Translation
            }
            summary
            type
            description
            isPublic
            isPublicAccessType
            imageUrl
            commentCount
            status(filter: { isActive: true }) {
                id
                isActive
                justification
                createdAt {
                    formatted
                }
                stage {
                    id
                    title
                    justificationRequired
                    justificationOptions
                }
            }
            category {
                id
                name
                isRoot
                icon
                isManaged
                defaultDashboard {
                    id
                }
            }
            parent {
                id
                title
            }
            homeDashboard {
                id
                layout
            }
            likedBy {
                userId
                username
            }
            tags(orderBy: name_asc) {
                name
            }
            createdAt {
                formatted
            }
            createdBy {
                userId
                username
                firstName
                lastName
                imageUrl
            }
            webLink {
                id
                url
                title
                icon
                provider
                description
                type
            }
            criteriaScores(filter: { conceptDefault: true }) {
                ...CriteriaScore
            }
            connections {
                id
            }
            industries {
                industryId
                name
            }
            addedUsers {
                type
                User {
                    userId
                    firstName
                    lastName
                    imageUrl
                }
            }
            addedTeams {
                type
                Team {
                    teamId
                    name
                    imageUrl
                    members {
                        userId
                    }
                }
            }
        }
    }
    ${CRITERIA_SCORE_FRAGMENT}
    ${TRANSLATION_FRAGMENT}
`

export const CONCEPT_PREVIEW = gql`
    query ConceptPreview($id: ID) {
        Concept(id: $id) {
            id
            title
            translations {
                ...Translation
            }
            summary
            type
            description
            isPublic
            isPublicAccessType
            imageUrl
            status(filter: { isActive: true }) {
                id
                isActive
                justification
                createdAt {
                    formatted
                }
                stage {
                    id
                    title
                    justificationRequired
                    justificationOptions
                }
            }
            category {
                id
                name
                isRoot
                defaultDashboard {
                    id
                }
            }
            parent {
                id
                title
            }

            tags(orderBy: name_asc) {
                name
            }
            createdAt {
                formatted
            }
            createdBy {
                userId
                username
                firstName
                lastName
                imageUrl
            }
            webLink {
                id
                url
                title
                icon
                provider
                description
                type
            }
            industries {
                industryId
                name
            }
        }
    }
    ${TRANSLATION_FRAGMENT}
`

export const CONCEPT_DESCRIPTION = gql`
    query ConceptDescription($id: ID) {
        Concept(id: $id) {
            id
            title
            translations {
                ...Translation
            }
            description
            category {
                id
                name
                icon
                isRoot
            }

            summary
            imageUrl
        }
    }
    ${TRANSLATION_FRAGMENT}
`

export const CONCEPT_CHILDREN_BY_IDS = gql`
    query ConceptChildrenByIds($conceptFilter: _ConceptFilter) {
        Concept(filter: $conceptFilter) {
            id
            title
            translations {
                ...Translation
            }
            summary
            description
            isPublic
            imageUrl
            createdAt {
                formatted
            }
            startDate {
                formatted
            }
            endDate {
                formatted
            }
            imageUrl
            category {
                id
                name
                icon
                isRoot
            }

            createdBy {
                userId
                firstName
                lastName
                imageUrl
                username
            }
            likedBy {
                userId
            }
            connections {
                id
            }
            children {
                id
                title
                summary
                imageUrl
                category {
                    id
                    name
                    isRoot
                    icon
                }
            }
            connections {
                id
            }
            parent {
                id
                title

                summary
                imageUrl
                category {
                    id
                    name
                    isRoot
                    icon
                }
            }
        }
    }
    ${TRANSLATION_FRAGMENT}
`

export const CONNECTION_WIDGET_QUERY = gql`
    query ConnectionWidgetQuery(
        $orderBy: [_ConceptOrdering]
        $offset: Int
        $first: Int
        $connectionsFilter: _ConceptFilter
    ) {
        Concept(
            orderBy: $orderBy
            filter: $connectionsFilter
            first: $first
            offset: $offset
        ) {
            id
            title
            translations {
                ...Translation
            }
            status(filter: { isActive: true }) {
                id
                createdAt {
                    formatted
                }
                stage {
                    id
                    title
                }
            }
            category {
                id
                name
                icon
                isRoot
            }

            summary
            description
            isPublic
            isPublicAccessType
            imageUrl
            commentCount
            usesDashboard {
                id
                name
                layout
            }
            createdAt {
                formatted
            }
            likedBy {
                userId
                username
            }
            createdBy {
                userId
                username
                firstName
                lastName
                imageUrl
            }
            connections {
                id
                title
                category {
                    id
                    name
                    isRoot
                    icon
                }

                imageUrl
            }
            tags(orderBy: name_asc) {
                name
            }
        }
    }
    ${TRANSLATION_FRAGMENT}
`
export const CONCEPT_PAGINATION = gql`
    query ConceptPagination(
        $orderBy: [_ConceptOrdering]
        $offset: Int
        $first: Int
        $filter: _ConceptFilter
    ) {
        Concept(
            orderBy: $orderBy
            filter: $filter
            first: $first
            offset: $offset
        ) {
            id
            title
            translations {
                ...Translation
            }
            category {
                id
                name
                icon
                isRoot
            }
            summary
            description
            isPublic
            imageUrl
            commentCount
            createdAt {
                formatted
            }
            usedByScore {
                id
            }
            createdBy {
                userId
                username
                firstName
                lastName
                imageUrl
            }
            tags(orderBy: name_asc) {
                name
            }
        }
    }
    ${TRANSLATION_FRAGMENT}
`
export const BASE_CONCEPT_FILTERED = gql`
    query BaseConceptFiltered(
        $orderBy: [_ConceptOrdering]
        $filter: _ConceptFilter
    ) {
        Concept(orderBy: $orderBy, filter: $filter) {
            id
            title
            translations {
                ...Translation
            }
            summary
            imageUrl
            type
            category {
                id
                name
                icon
                isRoot
            }

            createdAt {
                formatted
            }
        }
    }
    ${TRANSLATION_FRAGMENT}
`
export const FULL_CONCEPT_FILTERED = gql`
    query FullConceptFiltered(
        $orderBy: [_ConceptOrdering]
        $filter: _ConceptFilter
    ) {
        Concept(orderBy: $orderBy, filter: $filter) {
            id
            title
            category {
                id
                name
                isRoot
            }
            translations {
                ...Translation
            }
            summary
            description
            isPublic
            isPublicAccessType
            imageUrl
            createdAt {
                formatted
            }
            createdBy {
                userId
                username
                firstName
                lastName
                imageUrl
            }

            tags(orderBy: name_asc) {
                name
            }
        }
    }
    ${TRANSLATION_FRAGMENT}
`

export const USER_CONCEPT_DAILY_PROGRESS = gql`
    query UserConceptDailyProgress(
        $orderBy: [_ConceptOrdering]
        $filter: _ConceptFilter
        $first: Int
    ) {
        Concept(orderBy: $orderBy, filter: $filter, first: $first) {
            id
            title
            translations {
                ...Translation
            }
            summary
            imageUrl
            type
            category {
                id
                name
                icon
                isRoot
            }
            createdAt {
                formatted
            }
        }
    }
    ${TRANSLATION_FRAGMENT}
`

export const CONNECTION_VIEWER = gql`
    query ConnectionViewer(
        $orderBy: [_ConceptOrdering]
        $offset: Int
        $first: Int
        $connectionsFilter: _ConceptFilter
        $id: ID!
    ) {
        Concept(
            orderBy: $orderBy
            filter: $connectionsFilter
            first: $first
            offset: $offset
        ) {
            id
            title
            translations {
                ...Translation
            }
            category {
                id
                name
                icon
                isRoot
            }
            status(filter: { isActive: true }) {
                id
                createdAt {
                    formatted
                }
                stage {
                    id
                    title
                }
            }
            summary
            description
            isPublic
            isPublicAccessType
            imageUrl
            parent {
                id
                title
            }
            createdAt {
                formatted
            }
            createdBy {
                userId
                username
                firstName
                lastName
                imageUrl
            }
            connections {
                id
            }
            detailedConnections {
                from(filter: { Concept: { id: $id } }) {
                    Concept {
                        id
                    }
                }
                to(filter: { Concept: { id: $id } }) {
                    Concept {
                        id
                    }
                }
            }
        }
    }
    ${TRANSLATION_FRAGMENT}
`
export const CONNECTION_MODAL = gql`
    query ConnectionModal($id: ID!, $connectionsFilter: _ConceptFilter) {
        Concept(id: $id) {
            id
            title
            translations {
                ...Translation
            }
            category {
                id
                name
                icon
                isRoot
            }

            summary
            description
            isPublic
            isPublicAccessType
            imageUrl
            parent {
                id
                title
            }
            createdAt {
                formatted
            }
            createdBy {
                userId
                username
                firstName
                lastName
                imageUrl
            }
            connections(filter: $connectionsFilter) {
                id
                title
                category {
                    id
                    name
                    isRoot
                    icon
                }
                imageUrl
            }
            tags(orderBy: name_asc) {
                name
            }
            addedUsers {
                type
                updatedAt {
                    formatted
                }
                User {
                    userId
                    firstName
                    lastName
                    imageUrl
                    username
                    email
                }
            }
            addedTeams {
                type
                updatedAt {
                    formatted
                }
                Team {
                    teamId
                    imageUrl
                    name
                    members(orderBy: lastName_asc) {
                        userId
                        firstName
                        lastName
                    }
                }
            }
        }
    }
    ${TRANSLATION_FRAGMENT}
`
export const FEED_TOTAL_COUNTER = gql`
    query FeedTotalCounter($filter: _ConceptFilter) {
        Concept(filter: $filter) {
            id
            title
            category {
                id
                name
            }
        }
    }
`

export const FUZZY_SEARCH_BY_CATEGORY = gql`
    query FuzzySearchByCategory(
        $searchString: String
        $first: Int
        $offset: Int
        $categoryIds: [ID]
        $userId: String
    ) {
        fuzzyConceptSearchByCategory(
            searchString: $searchString
            first: $first
            offset: $offset
            categoryIds: $categoryIds
            userId: $userId
        ) {
            id
            title
            category {
                id
                name
                icon
                isRoot
            }
            summary
            description
            isPublic
            imageUrl
            commentCount
            createdAt {
                formatted
            }
            usedByScore {
                id
            }

            createdBy {
                userId
                username
                firstName
                lastName
                imageUrl
            }
            tags(orderBy: name_asc) {
                name
            }
        }
    }
`

export const COLLECTION_FUZZY_SEARCH = gql`
    query FuzzySearchByCategoryForCollections(
        $searchString: String
        $first: Int
        $offset: Int
        $categoryIds: [ID]
        $inputId: ID
        $userId: String
    ) {
        fuzzyConceptSearchByCategory(
            searchString: $searchString
            first: $first
            offset: $offset
            categoryIds: $categoryIds
            userId: $userId
        ) {
            ...InputSourceConcept
            usedByScore(filter: { input: { id: $inputId } }) {
                id
            }
        }
    }
    ${INPUT_SOURCE_CONCEPT_FRAGMENT}
`
