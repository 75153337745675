import React, { useState, useEffect, useCallback } from "react"
import { Box, Button, CircularProgress } from "@material-ui/core"
import { CONNECTION_WIDGET_QUERY } from "../../../graphql/queries"
import {
    ConnectionWidgetQueryQuery,
    ConnectionWidgetQueryQueryVariables,
} from "../../../graphql/__generated__/queries"
import { useQuery } from "@apollo/client"
import {
    Concept,
    _ConceptFilter,
    _ConceptOrdering,
} from "../../../__generated__/types"
import ErrorHandler from "../../ErrorHandler"
import ConceptChip from "../../ConceptChip"

const PAGE_SIZE = 10
export default function ChipView(props: {
    filter: _ConceptFilter
    orderBy: _ConceptOrdering
    fixedItems?: Concept[]
    skipQuery?: boolean
}) {
    //hooks

    //state
    const [filter, setFilter] = useState<_ConceptFilter>({})
    const [limit, setLimit] = useState(PAGE_SIZE)
    const [offset, setOffset] = useState(0)

    //queries
    const { loading, data, fetchMore, error } = useQuery<
        ConnectionWidgetQueryQuery,
        ConnectionWidgetQueryQueryVariables
    >(CONNECTION_WIDGET_QUERY, {
        variables: {
            offset: 0,
            first: limit,
            connectionsFilter: filter,
            orderBy: [props.orderBy],
        },
        fetchPolicy: "cache-and-network",

        skip: !!props.skipQuery,
    })

    //useEffects
    useEffect(() => {
        if (JSON.stringify(props.filter) !== JSON.stringify(filter)) {
            setFilter(props.filter)
            setOffset(0)
        }
    }, [props.filter, filter])

    //variables
    const listData = data?.Concept ?? []

    //functions
    const handleNext = async () => {
        await onLoadMore()
        setOffset((prev) => prev + PAGE_SIZE)
    }

    //callbacks
    const onLoadMore = useCallback(async () => {
        if (!fetchMore) return
        await fetchMore({
            variables: {
                offset: listData.length,
            },
        })

        setLimit(listData.length + PAGE_SIZE)
    }, [fetchMore, listData.length])
    if (error) {
        return <ErrorHandler showMessage={true} message={error.message} />
    }

    return (
        <Box
            display="flex"
            flexWrap="wrap"
            position="relative"
            style={{ overflowY: "auto" }}
        >
            {!!props.fixedItems &&
                props.fixedItems.map((item, index) => {
                    return (
                        <Box key={index + item.id} p={0.5} overflow="hidden">
                            <ConceptChip item={item} />
                        </Box>
                    )
                })}
            {listData?.map((item, index) => {
                return (
                    <Box key={index + item.id} p={0.5} overflow="hidden">
                        <ConceptChip item={item} />
                    </Box>
                )
            })}
            {(!(listData.length < offset + PAGE_SIZE) || loading) && (
                <Button
                    disabled={loading}
                    style={{ width: "100%" }}
                    onClick={handleNext}
                >
                    {!!loading ? <CircularProgress size={25} /> : "View More"}
                </Button>
            )}
        </Box>
    )
}
