import React from "react"
import Popper from "@material-ui/core/Popper"
import {
    IconButton,
    Paper,
    Box,
    Fade,
    ClickAwayListener,
} from "@material-ui/core"
import { ReactComponent as GlobalFilterIcon } from "../../../../styles/FilterIcon.svg"
import { makeStyles, useTheme } from "@material-ui/core/styles"
import Filters from "../../../filters/Filters"
import { getNumFiltersEnabled } from "../../../filters/util/getNumFiltersEnabled"
import { FeedVariables } from "../../../../providers/GlobalState"
const useStyles = makeStyles((theme) => ({
    popper: {
        minWidth: "30em",
        zIndex: theme.zIndex.modal + 1,
    },
}))

export default function WidgetFilterToggle(props: {
    feedVariables: FeedVariables
    setFeedVariables: (feedVariables: FeedVariables) => void
    isDialog?: boolean
    size?: "small" | "medium"
}) {
    const { feedVariables, setFeedVariables } = props
    const classes = useStyles()
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(anchorEl ? null : event.currentTarget)
    }

    const theme = useTheme()
    const open = Boolean(anchorEl)
    const filtersEnabled =
        getNumFiltersEnabled(props.feedVariables.filters) ?? 0

    return (
        <>
            <IconButton size={props.size ?? "medium"} onClick={handleClick}>
                <GlobalFilterIcon
                    style={{
                        fill:
                            filtersEnabled > 0
                                ? theme.palette.primary.main
                                : "currentColor",
                        width: props.size === "medium" ? "0.9em" : "0.8em",
                        height: props.size === "medium" ? "0.9em" : "0.8em",
                    }}
                />
            </IconButton>
            <Popper
                open={open}
                anchorEl={anchorEl}
                transition
                placement="top"
                className={classes.popper}
            >
                {({ TransitionProps }) => (
                    <ClickAwayListener onClickAway={() => setAnchorEl(null)}>
                        <Fade {...TransitionProps} timeout={350}>
                            <Paper elevation={7}>
                                <Box width={"30em"}>
                                    <Filters
                                        onChangeFeedVariables={setFeedVariables}
                                        feedVariables={feedVariables}
                                    />
                                </Box>
                            </Paper>
                        </Fade>
                    </ClickAwayListener>
                )}
            </Popper>
        </>
    )
}
