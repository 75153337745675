import {
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
} from "@material-ui/core"
import { Alert } from "@material-ui/lab"
import useAwaitTranslation from "../../i18n/useAwaitTranslation"
import { Concept } from "../../__generated__/types"
import ConceptListItem from "../ConceptListItem"
import { useParams } from "react-router-dom"

export default function ConfirmConceptDeletion(props: {
    concept: Concept
    onDeletion: () => void
    deleting: boolean
    onClose: () => void
}) {
    const { concept, onDeletion, onClose, deleting } = props
    const { conceptId } = useParams()
    const { t } = useAwaitTranslation("concept")

    return (
        <Dialog open={true} fullWidth onBackdropClick={() => onClose()}>
            <DialogTitle>
                {t(
                    "confirmConceptDeletion",
                    "Are you sure you want to delete?"
                )}
            </DialogTitle>
            <DialogContent>
                <ConceptListItem
                    disableClick={conceptId === concept?.id}
                    item={concept}
                    showSummary={true}
                    hideDivider={true}
                />
                <Alert severity="warning">
                    {t(
                        "youWillNotBeAbleToRecoverIt",
                        "You will not be able to recover it"
                    )}
                </Alert>
            </DialogContent>
            <DialogActions>
                <Button disabled={deleting} onClick={() => onClose()}>
                    {t("no", "No")}
                </Button>
                <Button disabled={deleting} onClick={onDeletion}>
                    {!!deleting ? (
                        <CircularProgress size={25} />
                    ) : (
                        t("yes", "Yes")
                    )}
                </Button>
            </DialogActions>
        </Dialog>
    )
}
