import React from "react"
import {
    getPluginType,
    MARK_BOLD,
    MARK_CODE,
    MARK_ITALIC,
    MARK_STRIKETHROUGH,
    MARK_SUBSCRIPT,
    MARK_SUPERSCRIPT,
    MARK_UNDERLINE,
    MarkToolbarButton,
    MARK_KBD,
    CodeBlockToolbarButton,
    ELEMENT_CODE_BLOCK,
} from "@udecode/plate"
import { useMyPlateEditorRef } from "../typescript/plateTypes"
import { getElementIcon } from "./getElementIcon"

export const BasicMarkToolbarButtons = () => {
    const editor = useMyPlateEditorRef()

    return (
        <>
            <MarkToolbarButton
                type={getPluginType(editor, MARK_BOLD)}
                icon={getElementIcon(MARK_BOLD)}
            />
            <MarkToolbarButton
                type={getPluginType(editor, MARK_ITALIC)}
                icon={getElementIcon(MARK_ITALIC)}
            />
            <MarkToolbarButton
                type={getPluginType(editor, MARK_UNDERLINE)}
                icon={getElementIcon(MARK_UNDERLINE)}
            />
            <MarkToolbarButton
                type={getPluginType(editor, MARK_STRIKETHROUGH)}
                icon={getElementIcon(MARK_STRIKETHROUGH)}
            />
            <CodeBlockToolbarButton
                type={getPluginType(editor, ELEMENT_CODE_BLOCK)}
                icon={getElementIcon(ELEMENT_CODE_BLOCK)}
            />
            <MarkToolbarButton
                type={getPluginType(editor, MARK_CODE)}
                icon={getElementIcon(MARK_CODE)}
            />
            <MarkToolbarButton
                type={getPluginType(editor, MARK_KBD)}
                icon={getElementIcon(MARK_KBD)}
            />
            <MarkToolbarButton
                type={getPluginType(editor, MARK_SUPERSCRIPT)}
                clear={getPluginType(editor, MARK_SUBSCRIPT)}
                icon={getElementIcon(MARK_SUPERSCRIPT)}
            />
            <MarkToolbarButton
                type={getPluginType(editor, MARK_SUBSCRIPT)}
                clear={getPluginType(editor, MARK_SUPERSCRIPT)}
                icon={getElementIcon(MARK_SUBSCRIPT)}
            />
        </>
    )
}
