import { gql } from "@apollo/client"

export const CONCEPTS_TO_CSV = gql`
    query ConceptsToCsv($filter: _ConceptFilter) {
        concepts: Concept(filter: $filter, orderBy: createdAt_desc) {
            id
            title
            summary
            category {
                id
                name
            }
            imageUrl
            createdBy {
                userId
                firstName
                lastName
            }
            translations {
                id
                languageString
                title
                summary
                description
            }
            createdAt {
                formatted
            }
            webLink {
                id
                url
            }
            isPublic
            isPublicAccessType
            tags {
                name
            }
            industries {
                industryId
            }
            connections {
                id
                title
                category {
                    id
                    name
                }
            }
            homeDashboard {
                id
                widgets(filter: { type: { name: "Criteria" } }) {
                    id
                    name
                    config
                }
            }
            inputs {
                id
                inputConfig
                criteria {
                    id
                    criteriaOptions
                    criteriaType
                }
                criteriaScores {
                    id
                    response
                    conceptDefault
                    scoredConcept {
                        id
                    }
                    concepts {
                        id
                    }
                }
            }
            status(filter: { isActive: true }) {
                id
                createdAt {
                    formatted
                }
                stage {
                    id
                    title
                }
            }
        }
        categories: Category {
            id
            name
            parent {
                id
            }
            defaultDashboard {
                id
                widgets(filter: { type: { name: "Criteria" } }) {
                    id
                    name
                    config
                }
            }
            children {
                id
            }
        }
    }
`
