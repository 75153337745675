import { gql } from "@apollo/client"

export const ENVIRONMENT_SETTING_BY_NAME = gql`
    query EnvironmentSettingByName($name: String) {
        EnvironmentSetting(name: $name) {
            id
            name
            value
        }
    }
`

export const ALL_ENVIRONMENT_SETTINGS = gql`
    query AllEnvironmentSettings {
        EnvironmentSetting {
            id
            name
            value
        }
    }
`

export const UPDATE_ENVIRONMENT_SETTING = gql`
    mutation UpdateEnvironmentSetting($id: ID!, $value: String) {
        UpdateEnvironmentSetting(id: $id, value: $value) {
            id
            value
        }
    }
`

export const CREATE_ENVIRONMENT_SETTING = gql`
    mutation CreateEnvironmentSetting($name: String, $value: String) {
        CreateEnvironmentSetting(name: $name, value: $value) {
            id
            name
            value
        }
    }
`
