import {
    Box,
    Divider,
    Icon,
    IconButton,
    TextField,
    Typography,
} from "@material-ui/core"
import Clear from "@material-ui/icons/Clear"
import { useMemo, useState } from "react"
import { MATERIAL_ICON_NAMES } from "../../styles/icons/materialIconOptions"

export const DEFAULT_ICON_NAMESPACE = "emoji_objects"

export type IconValue = {
    name: string
}

export default function IconPicker(props: {
    value?: IconValue
    onChange: (value: IconValue) => void
}) {
    const { value, onChange } = props
    const [searchString, setSearchString] = useState("")

    const icons = useMemo(() => {
        return MATERIAL_ICON_NAMES.filter((name) =>
            searchString.length > 0
                ? name.toLowerCase().includes(searchString.toLowerCase())
                : true
        ).map((name) => {
            return (
                <IconButton
                    key={name}
                    color={value?.name === name ? "primary" : "default"}
                    onClick={() => onChange({ name })}
                    style={{ flexGrow: 1 }}
                >
                    <Icon>{name}</Icon>
                </IconButton>
            )
        })
    }, [searchString, onChange, value])
    return (
        <Box
            display="flex"
            flexDirection={"column"}
            overflow="hidden"
            height={400}
            width={300}
        >
            <Box p={1}>
                <TextField
                    fullWidth
                    variant="outlined"
                    size="small"
                    autoFocus
                    value={searchString}
                    onChange={(e) => setSearchString(e.target.value)}
                    label={"Search Icons"}
                />
            </Box>

            <Divider />
            <Box height="100%" overflow="auto">
                <Box
                    justifyContent="space-around"
                    display="flex"
                    alignItems="flex-start"
                    flexWrap="wrap"
                >
                    {icons}
                </Box>
            </Box>
            <Divider />
            <Box p={1} display="flex" alignItems="center">
                <Box width="100%">
                    <Typography variant="caption" color="textSecondary">
                        Current Selection
                    </Typography>
                    <Box>{!!value ? <Icon>{value.name}</Icon> : "None"}</Box>
                </Box>
                {!!value && (
                    <IconButton
                        onClick={() => {
                            onChange(null)
                        }}
                        size="small"
                    >
                        <Clear fontSize="small" />
                    </IconButton>
                )}
            </Box>
        </Box>
    )
}
