import React, { useState, useEffect } from "react"
import { makeStyles, Theme, useTheme } from "@material-ui/core/styles"
import Autocomplete from "@material-ui/lab/Autocomplete"
import TextField from "@material-ui/core/TextField"
import Chip from "@material-ui/core/Chip"
import Typography from "@material-ui/core/Typography"
import useAwaitTranslation from "../../i18n/useAwaitTranslation"

import { WorkflowStage } from "../../__generated__/types"
import { Box, Icon } from "@material-ui/core"
import HighlightOffIcon from "@material-ui/icons/HighlightOff"
import useWorkflowTools from "../workflows/useWorkflowTools"
import { getNewState } from "./util/getNewState"
import { FilterProps } from "./util/types"
const useStyles = makeStyles((theme: Theme) => ({
    listbox: {
        overflow: "auto",
    },
    popper: {
        zIndex: theme.zIndex.modal + 4,
    },
    input: {
        zIndex: theme.zIndex.modal + 3,
    },
    optionAvatar: {
        width: theme.spacing(3),
        height: theme.spacing(3),
        marginRight: theme.spacing(1),
    },
}))

export default function StatusFilter(props: FilterProps) {
    const { isInitialized: statusesAreLoaded, allStages } = useWorkflowTools({
        fetch: true,
    })

    const [value, setValue] = useState<WorkflowStage[]>([])
    const classes = useStyles()
    const { t } = useAwaitTranslation("global")
    const theme = useTheme()
    useEffect(() => {
        if (!!statusesAreLoaded) {
            setValue([
                ...allStages.filter(
                    (x: WorkflowStage) => props.item.value.indexOf(x.id) !== -1
                ),
            ])
        }
    }, [statusesAreLoaded, allStages, props.item.value])

    const valueNames = value?.map((stage) => stage.title)
    const options = allStages.sort(function (a, b) {
        return b.workflow.title.localeCompare(a.workflow.title)
    })
    return !!props.item?.disabled ? (
        <Chip
            label={
                <Box>
                    <Box component="span" fontWeight="bold" mr={0.5}>
                        {t("statuses", "Statuses")}:
                    </Box>
                    {valueNames?.join("; ")}
                </Box>
            }
        />
    ) : (
        <Autocomplete
            options={options}
            groupBy={(value) => value.workflow?.title}
            multiple
            classes={{
                popper: classes.popper,
                listbox: classes.listbox,
                inputRoot: classes.input,
            }}
            getOptionLabel={(option: WorkflowStage) => option.title}
            value={value ?? []}
            renderOption={(option) => {
                const icon = JSON.parse(option?.icon ?? "{}")?.name || null
                return (
                    <Box display="flex" key={option.id} alignItems="center">
                        {!!icon && (
                            <Box
                                style={{ minWidth: theme.spacing(4) }}
                                display="flex"
                                alignItems="center"
                            >
                                <Icon color="action" fontSize="small">
                                    {icon}
                                </Icon>
                            </Box>
                        )}
                        <Typography variant="body2">{option.title}</Typography>
                    </Box>
                )
            }}
            loading={!statusesAreLoaded}
            size="small"
            noOptionsText={t("noStatusesMatch", "No statuses that match...")}
            onChange={(
                event: any,
                newValue: WorkflowStage[],
                reason: string
            ) => {
                setValue(newValue)
                const newState = getNewState(
                    props.item,
                    newValue,
                    props.filters,
                    "id"
                )

                if (newState) {
                    props.setFilters(newState)
                }
            }}
            renderTags={(value, getTagProps) =>
                value.map((option, index) => {
                    const icon = !!option.icon
                        ? JSON.parse(option.icon ?? "{}")?.name
                        : null
                    return (
                        <Chip
                            key={index}
                            icon={
                                !!icon ? (
                                    <Icon fontSize="small">{icon}</Icon>
                                ) : null
                            }
                            deleteIcon={<HighlightOffIcon />}
                            label={
                                <Typography
                                    variant="body2"
                                    style={{ whiteSpace: "normal" }}
                                >
                                    {option.title}
                                </Typography>
                            }
                            {...getTagProps({ index })}
                            style={{ height: "100%" }}
                        />
                    )
                })
            }
            renderInput={(params) => (
                <TextField
                    {...params}
                    variant="outlined"
                    label={t("statuses", "Statuses")}
                    placeholder={t("searchStatuses", "Search Statuses")}
                />
            )}
        />
    )
}
