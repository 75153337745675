import {
    Box,
    Dialog,
    DialogTitle,
    Button,
    TextField,
    Slide,
    Divider,
    CircularProgress,
    InputAdornment,
    useMediaQuery,
    IconButton,
    Typography,
    Grow,
} from "@material-ui/core"
import React, { useState, useCallback, useEffect } from "react"
import {
    createStyles,
    Theme,
    makeStyles,
    useTheme,
} from "@material-ui/core/styles"
import ClearIcon from "@material-ui/icons/Clear"
import { useQuery } from "@apollo/client"
import useAwaitTranslation from "../i18n/useAwaitTranslation"
import {
    ADVANCED_SEARCH_CONCEPTS,
    ADVANCED_SEARCH_CONCEPTS_BY_CATEGORY,
} from "../graphql/conceptListQueries"
import {
    AdvancedSearchConceptsQuery,
    AdvancedSearchConceptsQueryVariables,
    AdvancedSearchConceptsByCategoryQuery,
    AdvancedSearchConceptsByCategoryQueryVariables,
} from "../graphql/__generated__/conceptListQueries"
import { Concept } from "../__generated__/types"
import { TransitionProps } from "@material-ui/core/transitions"
import SearchIcon from "@material-ui/icons/Search"
import Clear from "@material-ui/icons/Clear"
import SearchListItem from "./SearchListItem"
import MultiCategoryTreeSelector from "./categories/MultiCategoryTreeSelector"
import { useAuth } from "../providers/AuthProvider"

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: "flex",
            flexGrow: 1,
            justifyContent: "flex-end",
            alignItems: "center",
            [theme.breakpoints.up("xs")]: {
                marginLeft: theme.spacing(1),
            },
        },
        searchBar: {
            width: 0,
            overflow: "hidden",
            // marginRight: 5,
            flexGrow: 0,
        },
        autoFocusedSearchBar: {
            flexGrow: 1,
            width: "100%",
        },
        searchBarFocused: {
            flexGrow: 1,
        },
        popper: {
            zIndex: 1500,
        },
        listbox: {
            overflow: "auto",
        },
        avatar: {
            color: theme.palette.text.primary,
            backgroundColor: theme.palette.primary.main,
        },
        listContainer: {
            border: `1px solid ${theme.palette.divider}`,
            borderRadius: theme.shape.borderRadius,
        },
        title: {
            fontSize: theme.typography.body1.fontSize,
        },
    })
)
const Transition = React.forwardRef(function Transition(
    props: TransitionProps & { children?: React.ReactElement<any, any> },
    ref: React.Ref<unknown>
) {
    return <Slide direction="up" ref={ref} {...props} />
})
interface SearchModalProps {
    onClose: () => void
    onSelect: (concept: Concept) => void
    open: boolean
    currentString?: string | null
}

const PAGE_SIZE = 30
export default function SearchModal(props: SearchModalProps) {
    const classes = useStyles()
    const [searchString, setSearchString] = useState(null)
    const [categories, setCategories] = useState([])
    const categoryIds = categories.map((c) => c.id)
    const theme = useTheme()
    const { currentUser } = useAuth()
    const mobile = useMediaQuery(theme.breakpoints.down("sm"))
    const [filterVariables, setFilterVariables] = useState({
        searchString: "",
        first: PAGE_SIZE,
    })
    const { t } = useAwaitTranslation("concepts")
    const { data, loading: dataLoading, fetchMore, error } = useQuery<
        AdvancedSearchConceptsQuery,
        AdvancedSearchConceptsQueryVariables
    >(ADVANCED_SEARCH_CONCEPTS, {
        variables: {
            offset: 0,
            first: filterVariables.first,
            searchString:
                filterVariables.searchString?.length > 0
                    ? filterVariables.searchString
                    : "*",
            userId: currentUser.userId,
        },
    })
    const {
        data: categoryData,
        loading: categoryDataLoading,
        fetchMore: categoryDataFetchMore,
    } = useQuery<
        AdvancedSearchConceptsByCategoryQuery,
        AdvancedSearchConceptsByCategoryQueryVariables
    >(ADVANCED_SEARCH_CONCEPTS_BY_CATEGORY, {
        variables: {
            categoryIds: categoryIds,
            offset: 0,
            first: filterVariables.first,
            searchString:
                filterVariables.searchString?.length > 0
                    ? filterVariables.searchString
                    : "*",
            userId: currentUser.userId,
        },
    })
    const loading = dataLoading || categoryDataLoading
    const listData =
        categoryIds?.length > 0
            ? categoryData?.fuzzyConceptSearchByCategory ?? []
            : data?.fuzzyConceptSearch ?? []
    const handleNext = async () => {
        await onLoadMore()
    }
    const onLoadMore = useCallback(async () => {
        if (!fetchMore || !categoryDataFetchMore) return
        if (categoryIds?.length > 0) {
            await categoryDataFetchMore({
                variables: {
                    categoryIds: categoryIds,
                    offset: listData.length,
                    searchString: searchString?.length > 0 ? searchString : "*",
                    first: listData.length + PAGE_SIZE,
                },
            })
        } else {
            await fetchMore({
                variables: {
                    offset: listData.length,
                    searchString: searchString?.length > 0 ? searchString : "*",
                    first: listData.length + PAGE_SIZE,
                },
            })
        }
        setFilterVariables({
            searchString: filterVariables.searchString,
            first: listData.length + PAGE_SIZE,
        })
    }, [
        fetchMore,
        categoryDataFetchMore,
        categoryIds,
        listData.length,
        filterVariables.searchString,
        searchString,
    ])

    useEffect(() => {
        if (!!props.currentString && !!props.open) {
            setSearchString(props.currentString)
            setFilterVariables({
                first: PAGE_SIZE,
                searchString: props.currentString,
            })
        }
    }, [props.currentString, props.open])
    if (error) {
        return <div>{error.message}</div>
    }

    const searchTermUpdated =
        !!searchString && searchString !== filterVariables?.searchString
    return (
        <Dialog
            open={props.open}
            onBackdropClick={() => props.onClose()}
            TransitionComponent={Transition}
            PaperProps={{
                style: {
                    height: mobile ? "100%" : 700,
                    display: "flex",
                    flexDirection: "column",
                },
            }}
            fullWidth={!mobile}
            fullScreen={mobile}
            maxWidth="lg"
        >
            <DialogTitle>
                {t("advancedSearch", "Advanced Search")}
                <IconButton
                    onClick={() => {
                        props.onClose()
                    }}
                    style={{ position: "absolute", right: 0, top: 0 }}
                >
                    <Clear />
                </IconButton>
            </DialogTitle>
            <Box p={3} pt={1} pb={1} display="flex">
                <TextField
                    variant="outlined"
                    label={"Search"}
                    helperText={
                        <Box
                            component="span"
                            style={{
                                display:
                                    !!searchString &&
                                    searchString !==
                                        filterVariables?.searchString
                                        ? "block"
                                        : "none",
                                color: theme.palette.warning.main,
                            }}
                        >
                            {t(
                                "enterToUpdateResults",
                                "Press enter to update results"
                            )}
                        </Box>
                    }
                    size="small"
                    value={searchString ?? ""}
                    onChange={(e) => {
                        setSearchString(e.target.value)
                    }}
                    onKeyDown={(e) => {
                        if (e.key === "Enter") {
                            setFilterVariables({
                                searchString: searchString,
                                first: PAGE_SIZE,
                            })
                        }
                    }}
                    fullWidth
                    autoFocus={true}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <SearchIcon />
                            </InputAdornment>
                        ),
                        endAdornment: (
                            <InputAdornment position="end">
                                <Box display="flex" alignItems="center">
                                    <Grow in={loading}>
                                        <CircularProgress
                                            size={20}
                                            disableShrink
                                        />
                                    </Grow>

                                    <Grow in={searchString?.length > 0}>
                                        <IconButton
                                            style={{
                                                marginRight: "5px",
                                                marginLeft: "5px",
                                            }}
                                            onClick={() => {
                                                setSearchString("")
                                                setFilterVariables({
                                                    first: PAGE_SIZE,
                                                    searchString: "",
                                                })
                                            }}
                                            size="small"
                                        >
                                            <ClearIcon />
                                        </IconButton>
                                    </Grow>
                                    <Divider flexItem orientation="vertical" />
                                    <IconButton
                                        style={{
                                            marginLeft: "5px",
                                        }}
                                        size="small"
                                        onClick={() => {
                                            setFilterVariables({
                                                searchString: searchString,
                                                first: PAGE_SIZE,
                                            })
                                        }}
                                    >
                                        <SearchIcon />
                                    </IconButton>
                                </Box>
                            </InputAdornment>
                        ),
                    }}
                />
            </Box>
            <Box
                display="flex"
                alignItems={"center"}
                justifyContent="space-between"
            >
                <Box ml={3} mr={3} minWidth={"50%"}>
                    {filterVariables.searchString?.length > 0 && (
                        <Typography variant="body2" color="textSecondary">
                            {t("resultsFor", "Results for")}: "
                            {filterVariables.searchString}"
                        </Typography>
                    )}
                </Box>

                <Box mx={3} flexGrow={1} display="flex" alignItems="center">
                    <Typography
                        variant="body2"
                        color="textSecondary"
                        style={{ marginRight: "8px" }}
                    >
                        Filter:
                    </Typography>
                    <MultiCategoryTreeSelector
                        setValue={setCategories}
                        value={categoryIds}
                    />
                </Box>
            </Box>
            <Box
                overflow="auto"
                m={3}
                mt={0.5}
                flexGrow={1}
                className={classes.listContainer}
                style={{
                    background: searchTermUpdated
                        ? theme.palette.background.default
                        : "",
                    filter: searchTermUpdated ? "opacity(50%)" : "",
                }}
            >
                {listData.length > 0 || !!loading ? (
                    <>
                        {listData.map((item, index) => {
                            return (
                                <SearchListItem
                                    concept={item}
                                    key={item.id}
                                    inputValue={
                                        filterVariables?.searchString?.length >
                                        0
                                            ? filterVariables?.searchString
                                            : "*"
                                    }
                                    onSelect={() => {
                                        props.onSelect(item)
                                        props.onClose()
                                    }}
                                />
                            )
                        })}
                        {filterVariables.first > listData?.length &&
                        !loading ? (
                            <Box p={1} textAlign="center">
                                That's all!
                            </Box>
                        ) : (
                            <Box display="flex">
                                <Button
                                    style={{ flexGrow: 1 }}
                                    onClick={handleNext}
                                    disabled={loading}
                                >
                                    {loading ? (
                                        <CircularProgress
                                            size={25}
                                            disableShrink={true}
                                        />
                                    ) : (
                                        "View More Results"
                                    )}
                                </Button>
                            </Box>
                        )}
                    </>
                ) : (
                    <Box textAlign="center" p={2}>
                        <Typography color="textSecondary">
                            No results...
                        </Typography>
                    </Box>
                )}
            </Box>
        </Dialog>
    )
}
