import {
    ELEMENT_PARAGRAPH,
    isBlockAboveEmpty,
    isSelectionAtBlockStart,
    ResetNodePlugin,
} from "@udecode/plate"
import { MyPlatePlugin } from "../typescript/plateTypes"

const resetBlockTypesCommonRule = {
    types: [],
    defaultType: ELEMENT_PARAGRAPH,
}

export const resetBlockTypePlugin: Partial<MyPlatePlugin<ResetNodePlugin>> = {
    options: {
        rules: [
            {
                ...resetBlockTypesCommonRule,
                hotkey: "Enter",
                predicate: isBlockAboveEmpty,
            },
            {
                ...resetBlockTypesCommonRule,
                hotkey: "Backspace",
                predicate: isSelectionAtBlockStart,
            },
        ],
    },
}
