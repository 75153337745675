import React from "react"
import Box from "@material-ui/core/Box"
import { CriteriaType } from "./types"
import { toCapitalizedWords } from "../../util/fns"
import {
    FormControl,
    Select,
    InputLabel,
    MenuItem,
    Typography,
    ListItemText,
    ListItemIcon,
} from "@material-ui/core"
import { useTheme } from "@material-ui/core/styles"
import { CRITERIA_TYPE_ICONS } from "./useCriteriaTypes"

import useAwaitTranslation from "../../i18n/useAwaitTranslation"

const FieldTypeSelect = (props: {
    value: CriteriaType | "All"
    onChange: (value: CriteriaType | "All") => void
    typeOptions: CriteriaType[]
    includeAll?: boolean
    size?: "small" | "default"
    variant?: "filled" | "outlined" | "standard"
}) => {
    const { value, onChange, includeAll, size, typeOptions, variant } = props
    const { t } = useAwaitTranslation("criteria")
    const theme = useTheme()

    let options = [
        ...typeOptions.sort().map((item, index) => {
            const icon = CRITERIA_TYPE_ICONS[item]
            return (
                <MenuItem value={item} key={item}>
                    <ListItemIcon>{icon && icon}</ListItemIcon>
                    <ListItemText primary={t(item, toCapitalizedWords(item))} />
                </MenuItem>
            )
        }),
    ]
    if (!!includeAll) {
        options.unshift(
            <MenuItem
                style={{ borderBottom: `1px solid ${theme.palette.divider}` }}
                value={"All"}
                key={"all"}
            >
                <ListItemText primary={t("all", "All")} />
            </MenuItem>
        )
    }
    return (
        <FormControl variant={variant || "outlined"} fullWidth size="small">
            <InputLabel>{t("fieldType", "Field Type")}</InputLabel>
            <Select
                disableUnderline={!variant || variant === "standard"}
                value={value}
                onChange={(e) => {
                    onChange(e.target.value as CriteriaType | "All")
                }}
                renderValue={(value: CriteriaType | "All") => {
                    let icon = null
                    if (!!value) {
                        icon = CRITERIA_TYPE_ICONS[value]
                    }

                    return (
                        <Box display="flex" alignItems="center">
                            {!!icon && (
                                <span
                                    style={{
                                        fontSize:
                                            size === "small"
                                                ? "0.75em"
                                                : "inherit",
                                        display: "flex",
                                        alignItems: "center",
                                    }}
                                >
                                    {icon}
                                </span>
                            )}
                            <Typography
                                style={{ marginLeft: 10 }}
                                variant="body1"
                                noWrap
                            >
                                {!!value
                                    ? t(value, toCapitalizedWords(value))
                                    : t("all", "All")}
                            </Typography>
                        </Box>
                    )
                }}
                label={t("fieldType", "Field Type")}
            >
                {options}
            </Select>
        </FormControl>
    )
}

export default FieldTypeSelect
