import {
    ELEMENT_BLOCKQUOTE,
    ELEMENT_CODE_BLOCK,
    ELEMENT_H1,
    ELEMENT_H2,
    ELEMENT_H3,
    ELEMENT_H4,
    ELEMENT_H5,
    ELEMENT_H6,
    ELEMENT_OL,
    ELEMENT_UL,
    MARK_BG_COLOR,
    MARK_BOLD,
    MARK_CODE,
    MARK_COLOR,
    MARK_ITALIC,
    MARK_KBD,
    MARK_STRIKETHROUGH,
    MARK_SUBSCRIPT,
    MARK_SUPERSCRIPT,
    MARK_UNDERLINE,
} from "@udecode/plate"
import { FormatBold } from "@styled-icons/material/FormatBold"
import { FormatItalic } from "@styled-icons/material/FormatItalic"
import { FormatUnderlined } from "@styled-icons/material/FormatUnderlined"
import { Tooltip } from "@material-ui/core"
import { FormatQuote } from "@styled-icons/material/FormatQuote"
import { Looks3 } from "@styled-icons/material/Looks3"
import { Looks4 } from "@styled-icons/material/Looks4"
import { Looks5 } from "@styled-icons/material/Looks5"
import { Looks6 } from "@styled-icons/material/Looks6"
import { LooksOne } from "@styled-icons/material/LooksOne"
import { LooksTwo } from "@styled-icons/material/LooksTwo"
import { FormatListBulleted } from "@styled-icons/material/FormatListBulleted"
import { FormatListNumbered } from "@styled-icons/material/FormatListNumbered"
import { FormatStrikethrough } from "@styled-icons/material/FormatStrikethrough"
import { CodeBlock } from "@styled-icons/boxicons-regular/CodeBlock"
import { CodeAlt } from "@styled-icons/boxicons-regular/CodeAlt"
import { Keyboard } from "@styled-icons/material/Keyboard"
import { Superscript } from "@styled-icons/foundation/Superscript"
import { Subscript } from "@styled-icons/foundation/Subscript"
import { FormatColorText } from "@styled-icons/material/FormatColorText"
import { FontDownload } from "@styled-icons/material/FontDownload"

export const getElementIcon = (type) => {
    switch (type) {
        case ELEMENT_BLOCKQUOTE:
            return (
                <Tooltip title={"Format as blockquote"}>
                    <FormatQuote />
                </Tooltip>
            )

        case ELEMENT_UL:
            return (
                <Tooltip title={"Format as bulleted list"}>
                    <FormatListBulleted />
                </Tooltip>
            )
        case ELEMENT_OL:
            return (
                <Tooltip title={"Format as ordered list"}>
                    <FormatListNumbered />
                </Tooltip>
            )
        case ELEMENT_H1:
            return (
                <Tooltip title={"H1"}>
                    <LooksOne />
                </Tooltip>
            )
        case ELEMENT_H2:
            return (
                <Tooltip title={"H2"}>
                    <LooksTwo />
                </Tooltip>
            )
        case ELEMENT_H3:
            return (
                <Tooltip title={"H3"}>
                    <Looks3 />
                </Tooltip>
            )
        case ELEMENT_H4:
            return (
                <Tooltip title={"H4"}>
                    <Looks4 />
                </Tooltip>
            )
        case ELEMENT_H5:
            return (
                <Tooltip title={"H5"}>
                    <Looks5 />
                </Tooltip>
            )
        case ELEMENT_H6:
            return (
                <Tooltip title={"H6"}>
                    <Looks6 />
                </Tooltip>
            )
        case MARK_BOLD:
            return (
                <Tooltip title={"Bold (⌘B)"}>
                    <FormatBold />
                </Tooltip>
            )
        case MARK_ITALIC:
            return (
                <Tooltip title={"Italic (⌘I)"}>
                    <FormatItalic />
                </Tooltip>
            )
        case MARK_UNDERLINE:
            return (
                <Tooltip title={"Underline (⌘U)"}>
                    <FormatUnderlined />
                </Tooltip>
            )
        case MARK_STRIKETHROUGH:
            return (
                <Tooltip title={"Strikethrough"}>
                    <FormatStrikethrough />
                </Tooltip>
            )
        case ELEMENT_CODE_BLOCK:
            return (
                <Tooltip title={"Insert code block"}>
                    <CodeBlock />
                </Tooltip>
            )
        case MARK_CODE:
            return (
                <Tooltip title={"Format as code"}>
                    <CodeAlt />
                </Tooltip>
            )
        case MARK_KBD:
            return (
                <Tooltip title={"Format as keyboard element"}>
                    <Keyboard />
                </Tooltip>
            )
        case MARK_SUPERSCRIPT:
            return (
                <Tooltip title={"Superscript"}>
                    <Superscript />
                </Tooltip>
            )
        case MARK_SUBSCRIPT:
            return (
                <Tooltip title={"Subscript"}>
                    <Subscript />
                </Tooltip>
            )
        case MARK_COLOR:
            return (
                <Tooltip title={"Font color"}>
                    <FormatColorText />
                </Tooltip>
            )
        case MARK_BG_COLOR:
            return (
                <Tooltip title={"Background color"}>
                    <FontDownload />
                </Tooltip>
            )

        default:
            return null
    }
}
