import { useState, useEffect } from "react"
import { useLazyQuery } from "@apollo/client"
import TextField from "@material-ui/core/TextField"
import Autocomplete from "@material-ui/lab/Autocomplete"

import { ALL_USER_QUERY } from "../users/graphql"
import { SystemRole, User } from "../../__generated__/types"
import useAwaitTranslation from "../../i18n/useAwaitTranslation"
import {
    AllUsersQuery,
    AllUsersQueryVariables,
} from "../users/__generated__/graphql"
import UserListItem from "./UserListItem"
import useMountedState from "../../util/useMountedState"
import { useAuth } from "../../providers/AuthProvider"
import {
    CircularProgress,
    IconButton,
    InputAdornment,
    Tooltip,
} from "@material-ui/core"
import PersonAdd from "@material-ui/icons/PersonAdd"
import UserForm from "./UserForm"

export default function UserAutocomplete(props: {
    onSelection: (user: User) => Promise<void>
    loading?: boolean
    disabledOptionUserIds?: string[]
    size?: "small" | "medium"
}) {
    const isMounted = useMountedState()
    const {
        onSelection,
        loading: mutating,
        disabledOptionUserIds,
        size,
    } = props
    const [loadUsers, { data, loading, called }] = useLazyQuery<
        AllUsersQuery,
        AllUsersQueryVariables
    >(ALL_USER_QUERY)
    const [creatingNewUser, setCreatingNewUser] = useState(false)
    const [inputValue, setInputValue] = useState("")
    const [options, setOptions] = useState(null)
    const { currentUser } = useAuth()
    const { t } = useAwaitTranslation("teams")

    useEffect(() => {
        if (data?.users && !!isMounted) {
            setOptions(data.users)
        }
    }, [data?.users, setOptions, isMounted])

    const handleSelection = async (event, newValue) => {
        await onSelection(newValue)
        if (!!isMounted()) {
            setInputValue("")
        }
    }
    return (
        <>
            <Autocomplete
                options={options ?? []}
                value={null}
                onFocus={() => {
                    if (!called) {
                        loadUsers()
                    }
                }}
                getOptionDisabled={(option) =>
                    disabledOptionUserIds?.includes(option.userId)
                }
                size={size || "medium"}
                fullWidth
                disabled={!!mutating}
                loading={loading}
                onChange={handleSelection}
                getOptionLabel={(option) => {
                    return option.lastName + ", " + option.firstName
                }}
                renderOption={(option) => (
                    <UserListItem user={option} key={option.userId} />
                )}
                inputValue={inputValue}
                disableClearable
                renderInput={(params) => (
                    <TextField
                        {...params}
                        onChange={(e) => setInputValue(e.target.value)}
                        value={inputValue}
                        variant="outlined"
                        placeholder={t("typeToAddUser", "Type to add user")}
                        InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                                <>
                                    {!!currentUser.role &&
                                    currentUser.role !== SystemRole.GUEST ? (
                                        <InputAdornment position="end">
                                            <Tooltip title="Create new user">
                                                <IconButton
                                                    size="small"
                                                    onClick={() =>
                                                        setCreatingNewUser(true)
                                                    }
                                                >
                                                    <PersonAdd />
                                                </IconButton>
                                            </Tooltip>
                                        </InputAdornment>
                                    ) : null}
                                    {params.InputProps.endAdornment}
                                </>
                            ),
                            startAdornment: !!mutating ? (
                                <InputAdornment position="start">
                                    <CircularProgress size={25} />
                                </InputAdornment>
                            ) : null,
                        }}
                    />
                )}
            />
            {!!creatingNewUser && (
                <UserForm
                    onCreation={onSelection}
                    onClose={() => setCreatingNewUser(false)}
                />
            )}
        </>
    )
}
