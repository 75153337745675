import { Box, Paper } from "@material-ui/core"
import WidgetHeader from "./common/WidgetHeader"
import useWidget from "../useWidget"
import { WidgetTypeComponent } from "../useWidgetTypes"
import ReactPlayer from "react-player"
import { SizeMe } from "react-sizeme"
import { Loading } from "../../Loading"
import { useState } from "react"

type WidgetConfig = {
    label: string
    url: string
}

const VideoWidget: WidgetTypeComponent = (props) => {
    //hooks
    const { config, isInitialized } = useWidget<WidgetConfig>(props.widget.id)
    const [hovered, setHovered] = useState(false)
    const isImage = (url) => {
        return /\.(jpg|jpeg|png|webp|avif|gif|svg)$/.test(url)
    }

    return (
        <Box
            component={!props.isDialog ? Paper : "div"}
            display="flex"
            height="100%"
            width="100%"
            flexDirection="column"
            overflow="hidden"
            key={props.widget.id}
            onMouseOver={() => {
                if (!props.isDialog && !hovered) {
                    setHovered(true)
                }
            }}
            onMouseLeave={() => {
                if (!props.isDialog && !!hovered) {
                    setHovered(false)
                }
            }}
        >
            <WidgetHeader
                hovered={hovered || !!props.isDialog}
                label={config?.label}
                widget={props.widget}
                editing={props.editing}
                onWidgetDeletion={props.onWidgetDeletion}
            />
            {isInitialized && config?.url ? (
                <SizeMe monitorWidth monitorHeight>
                    {({ size }) => (
                        <Box
                            key={props.widget.id}
                            overflow="hidden"
                            flexGrow={1}
                        >
                            {isImage(config.url) ? (
                                <img
                                    height={"auto"}
                                    style={{
                                        width: "100%",
                                        objectFit: "contain",
                                    }}
                                    src={config.url}
                                    alt={config.label}
                                />
                            ) : (
                                <ReactPlayer
                                    light={true}
                                    url={config?.url}
                                    width={size.width}
                                    height={size.height}
                                    config={{
                                        youtube: {
                                            playerVars: {
                                                modestbranding: 1,
                                            },
                                        },
                                    }}
                                />
                            )}
                        </Box>
                    )}
                </SizeMe>
            ) : (
                <Loading size={25} hideQuote={true} />
            )}
        </Box>
    )
}

export default VideoWidget
