import React, { useEffect, useState } from "react"
import { makeStyles, Theme } from "@material-ui/core/styles"
import Autocomplete from "@material-ui/lab/Autocomplete"
import TextField from "@material-ui/core/TextField"
import { useQuery } from "@apollo/client"
import Chip from "@material-ui/core/Chip"
import { ALL_USER_QUERY } from "../users/graphql"
import Typography from "@material-ui/core/Typography"
import useAwaitTranslation from "../../i18n/useAwaitTranslation"
import {
    AllUsersQuery,
    AllUsersQueryVariables,
} from "../users/__generated__/graphql"
import { User } from "../../__generated__/types"
import { FilterProps } from "./util/types"
import { getNewState } from "./util/getNewState"
import { Box, Avatar } from "@material-ui/core"
import HighlightOffIcon from "@material-ui/icons/HighlightOff"

const useStyles = makeStyles((theme: Theme) => ({
    listbox: {
        overflow: "auto",
    },
    popper: {
        zIndex: theme.zIndex.modal + 4,
    },
    input: {
        zIndex: theme.zIndex.modal + 3,
    },
    optionAvatar: {
        width: theme.spacing(3),
        height: theme.spacing(3),
        marginRight: theme.spacing(1),
    },
}))

export default function UserFilter(props: FilterProps) {
    const classes = useStyles()
    const [value, setValue] = useState<User[]>([])
    const { data, loading } = useQuery<AllUsersQuery, AllUsersQueryVariables>(
        ALL_USER_QUERY
    )
    const { t } = useAwaitTranslation("global")

    useEffect(() => {
        if (data?.users && props.item) {
            setValue(
                data?.users?.filter(
                    (x: User) => props.item.value.indexOf(x.userId) !== -1
                )
            )
        }
    }, [data, props.item])
    const valNames =
        value?.map((item) => {
            return `${item.lastName}, ${item.firstName}`
        }) ?? []
    return !!props.item?.disabled ? (
        <Chip
            label={
                <Box>
                    <Box component="span" fontWeight="bold" mr={0.5}>
                        {props.item?.type}s:
                    </Box>
                    {valNames.join("; ")}
                </Box>
            }
        />
    ) : (
        <Autocomplete
            options={data?.users ?? []}
            multiple
            classes={{
                popper: classes.popper,
                listbox: classes.listbox,
                inputRoot: classes.input,
            }}
            getOptionLabel={(option) =>
                option.lastName + ", " + option.firstName
            }
            value={value}
            size="small"
            loading={loading}
            loadingText={t("loadingUsers", "Loading users...")}
            noOptionsText={t("noUsersMatch", "No users that match...")}
            onChange={(event: any, newValue: User[], reason: string) => {
                setValue(newValue)
                const newState = getNewState(
                    props.item,
                    newValue,
                    props.filters,
                    "userId"
                )
                if (newState) {
                    props.setFilters(newState)
                }
            }}
            renderOption={(option) => {
                return (
                    <Box alignItems="center" display="flex" key={option.userId}>
                        <Avatar
                            src={option.imageUrl}
                            className={classes.optionAvatar}
                        >
                            <Typography variant="body2">
                                {option.firstName?.[0]}
                            </Typography>
                        </Avatar>

                        <Box>
                            <Typography variant="body1">
                                {`${option.lastName}, ${option.firstName}`}
                            </Typography>
                        </Box>
                    </Box>
                )
            }}
            renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                    <Chip
                        deleteIcon={<HighlightOffIcon />}
                        avatar={
                            <Avatar src={option.imageUrl}>
                                <Typography variant="body2">
                                    {option.firstName?.[0]}
                                </Typography>
                            </Avatar>
                        }
                        label={
                            <Typography
                                variant="body2"
                                style={{ whiteSpace: "normal" }}
                            >
                                {option.lastName + ", " + option.firstName}
                            </Typography>
                        }
                        {...getTagProps({ index })}
                        style={{ height: "100%" }}
                    />
                ))
            }
            renderInput={(params) => (
                <TextField
                    {...params}
                    variant="outlined"
                    label={t("users", "Users ")}
                    placeholder={t("searchUsers", "Search Users")}
                />
            )}
        />
    )
}
