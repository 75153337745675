import { Box } from "@material-ui/core"
import { Concept, CriteriaScore } from "../../../__generated__/types"
import { GroupingType } from "../../criteria/useCriteriaTypes"
import DroppableContainer from "./DroppableContainer"
import { GroupingResponse, GroupingResponseWithConcepts } from "./types"

interface GroupingInterfaceProps {
    score: CriteriaScore
    config: GroupingType
    inputId: string
    conceptsToScore: Concept[]
}

const GroupingInterface: React.FunctionComponent<GroupingInterfaceProps> = ({
    score,
    config,
    inputId,
    conceptsToScore,
}) => {
    if (!conceptsToScore) return null

    let groups: GroupingResponseWithConcepts[] = []
    if (!score?.response) {
        groups = config?.groups
            ?.filter((item) => !!item?.value && !!item?.id)
            ?.map((group) => {
                return {
                    group: group,
                    layoutConfiguration: null,
                    concepts: [],
                }
            })
        groups.push({
            group: { value: inputId, id: inputId },
            layoutConfiguration: null,
            concepts: conceptsToScore,
        })
    } else {
        const response: GroupingResponse[] = JSON.parse(score?.response ?? "[]")
        groups = response
            .map((item) => {
                return {
                    group: item.group || { value: inputId, id: inputId },
                    layoutConfiguration: item.layoutConfiguration,
                    concepts: item.conceptIds
                        .map((id) =>
                            conceptsToScore.find((concept) => concept.id === id)
                        )
                        ?.filter((x) => !!x),
                }
            })
            ?.filter((item) => !!item.group?.value && !!item.group?.id)

        const conceptsWithNoGroup = conceptsToScore.filter(
            (concept) =>
                !groups
                    .flatMap((group) => group.concepts)
                    .find((groupConcept) => groupConcept.id === concept.id)
        )
        if (conceptsWithNoGroup.length > 0) {
            groups.forEach((item) => {
                if (item.group.id === inputId) {
                    item.concepts = [...item.concepts, ...conceptsWithNoGroup]
                }
            })
        }
    }

    return (
        <Box
            width="100%"
            height="100%"
            overflow="hidden"
            key={score?.id + conceptsToScore?.length}
        >
            <DroppableContainer
                score={score}
                groups={groups}
                config={config}
                viewingResults={false}
                inputId={inputId}
            />
        </Box>
    )
}

export default GroupingInterface
