import React from "react"
import { Category } from "../../__generated__/types"
import { FilterProps } from "./util/types"
import { getNewState } from "./util/getNewState"
import MultiCategoryTreeSelector from "../categories/MultiCategoryTreeSelector"

export default function CategoryFilter(props: FilterProps) {
    return (
        <MultiCategoryTreeSelector
            value={props.item.value ?? []}
            disabled={!!props.item.disabled}
            setValue={(value: Category[]) => {
                const newState = getNewState(
                    props.item,
                    value,
                    props.filters,
                    "id"
                )

                if (newState) {
                    props.setFilters(newState)
                }
            }}
        />
    )
}
