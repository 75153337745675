import React from "react"
import { Box, Typography } from "@material-ui/core"
import { CriteriaScore } from "../../../__generated__/types"
import dayjs from "dayjs"
import { DateType } from "../../criteria/useCriteriaTypes"
import ArrowRightAlt from "@material-ui/icons/ArrowRightAlt"
interface DateResponseViewerProps {
    config: DateType
    score?: CriteriaScore
}

const DateResponseViewer: React.FunctionComponent<DateResponseViewerProps> = ({
    score,
    config,
}) => {
    const dateArray = JSON.parse(score?.response ?? "[]")
    const startDate = dateArray?.[0]
    let endDate = null
    if (!!config.range) {
        endDate = dateArray?.[1]
    }

    return (
        <Box display="flex" alignItems="center" height="100%">
            <Box p={1}>
                <Typography variant="body1">
                    {dayjs(startDate).format("MM/DD/YY")}
                </Typography>
            </Box>
            {!!config.range && (
                <>
                    <ArrowRightAlt />
                    <Box p={1}>
                        <Typography variant="body1">
                            {dayjs(endDate).format("MM/DD/YY")}
                        </Typography>
                    </Box>
                </>
            )}
        </Box>
    )
}

export default DateResponseViewer
