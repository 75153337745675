import React from "react"
import { Box, Typography } from "@material-ui/core"
import { Concept, CriteriaScore } from "../../../__generated__/types"
import dayjs from "dayjs"
import { DateType } from "../../criteria/useCriteriaTypes"
import Roadmap from "../../charts/Roadmap"
import ArrowRightAlt from "@material-ui/icons/ArrowRightAlt"
interface DateResponseViewerProps {
    inputId: string
    config: DateType
    scores?: CriteriaScore[]
    conceptsScored?: Concept[]
}

const DateResponseViewer: React.FunctionComponent<DateResponseViewerProps> = ({
    scores,
    config,
    conceptsScored = [],
    inputId,
}) => {
    const isSubfield = config?.source?.criteriaIds?.length > 0

    if (!!isSubfield) {
        return (
            <Roadmap
                config={config}
                inputId={inputId}
                scores={scores}
                conceptsScored={conceptsScored}
            />
        )
    } else {
        const currentScore = scores?.[0] ?? null
        const dateArray = JSON.parse(currentScore?.response ?? "[]")
        const startDate = dateArray?.[0]
        let endDate = null
        if (!!config.range) {
            endDate = dateArray?.[1]
        }

        return (
            <Box display="flex" alignItems="center" height="100%">
                <Box p={1}>
                    <Typography variant="h6">
                        {dayjs(startDate).format("MM/DD/YY")}
                    </Typography>
                </Box>
                {!!config.range && (
                    <>
                        <ArrowRightAlt />
                        <Box p={1}>
                            <Typography variant="h6">
                                {dayjs(endDate).format("MM/DD/YY")}
                            </Typography>
                        </Box>
                    </>
                )}
            </Box>
        )
    }
}

export default DateResponseViewer
