import { Box } from "@material-ui/core"
import RichTextEditor, {
    BASE_INITIAL_TEXT,
} from "../../../text-editor/RichTextEditor"

import { useInputTools } from "../../useInputTools"
import { TextResponseType } from "../../../criteria/useCriteriaTypes"

const TextResponseCell = (props: {
    fieldId: string
    conceptId: string
    editing: boolean
    config: TextResponseType
}) => {
    const { editing, conceptId, fieldId, config } = props

    const { onEditResponse, onCreateNewResponse, scores } = useInputTools({
        criteriaId: fieldId,
        inputConfig: config,
    })
    const score =
        scores?.find((score) => score.scoredConcept?.id === conceptId) || null
    const value = score?.response
    const initialText = JSON.stringify(BASE_INITIAL_TEXT)

    return (
        <Box
            display="flex"
            flexDirection={"column"}
            height="100%"
            id={score?.id || fieldId + conceptId}
        >
            <RichTextEditor
                autoFocus={true}
                editable={!!editing}
                editorId={fieldId + conceptId}
                initialValue={value || initialText}
                disableEffects={true}
                onChange={(value: string) => {
                    let updateData = {
                        response: value,
                    }

                    if (!!score) {
                        onEditResponse(
                            {
                                ...updateData,
                            },
                            score
                        )
                    } else {
                        onCreateNewResponse({ ...updateData }, conceptId)
                    }
                }}
            />
        </Box>
    )
}

export default TextResponseCell
