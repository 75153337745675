import { useReactiveVar } from "@apollo/client"
import { useState } from "react"
import { useAuth } from "../../providers/AuthProvider"
import { CriteriaScore } from "../../__generated__/types"

import useAwaitTranslation from "../../i18n/useAwaitTranslation"
import { myCurrentInputResponseLabelVar } from "../../providers/GlobalState"
export function useScoreLabelTools(scores: CriteriaScore[]) {
    const { currentUser } = useAuth()

    const [label, setLabel] = useState("")
    const { t } = useAwaitTranslation("feedback")
    const currentLabel = useReactiveVar(myCurrentInputResponseLabelVar)

    const userHasSubmittedOwnResponse =
        scores?.find(
            (score) =>
                !score.conceptDefault &&
                !score.label &&
                score.user?.userId === currentUser?.userId
        ) ||
        (currentLabel?.user?.userId === currentUser?.userId &&
            !currentLabel?.label)

    let errorMessage = null
    if (!!scores) {
        if (!!userHasSubmittedOwnResponse && !label) {
            errorMessage = t(
                "youMustProvideAResponseLabel",
                "You must provide a response label"
            )
        } else if (
            scores.some(
                (item) =>
                    label === item.label &&
                    item.user?.userId === currentUser?.userId
            ) ||
            (currentLabel.label === label &&
                currentLabel.user?.userId === currentUser?.userId)
        ) {
            errorMessage = t(
                "youHaveAlreadyUsedThisResponseLabel",
                "You have already used this response label"
            )
        }
    }

    return {
        label,
        setLabel,

        errorMessage,

        userHasSubmittedOwnResponse,
    }
}
