import React from "react"
import { Box } from "@material-ui/core"
import { useReactiveVar } from "@apollo/client"

import { Concept, CriteriaScore } from "../../../__generated__/types"
import RankingResultsBarChart from "../../charts/RankingResultsBarChart"
import { myCurrentInputResponseLabelVar } from "../../../providers/GlobalState"

import { RankingType } from "../../criteria/useCriteriaTypes"
import RankingItem from "./RankingItem"
import { ScoreSelectorDefaultValues } from "../types"
interface RankingResponseViewerProps {
    inputId: string
    config: RankingType
    scores?: CriteriaScore[]
    conceptsScored?: Concept[]
}

const RankingResponseViewer = (props: RankingResponseViewerProps) => {
    const { scores, conceptsScored } = props
    const currentLabel = useReactiveVar(myCurrentInputResponseLabelVar)
    const viewingAllResponses =
        currentLabel.label === ScoreSelectorDefaultValues.allResponses &&
        !currentLabel.user

    const rangeArray = getRangeArray(conceptsScored ?? [])
    if (!conceptsScored) {
        return null
    }
    const orderedData =
        conceptsScored
            ?.map((item) => {
                let val = 0
                let valObject = {}
                let totalScores =
                    scores.filter((score) => {
                        const response = JSON.parse(score.response ?? "[]")
                        if (response.includes(item.id)) {
                            val += response.indexOf(item.id) + 1
                        }
                        return response.includes(item.id)
                    }) ?? []
                rangeArray.map((range, index) => {
                    valObject[range.label] =
                        (scores.filter((score) => {
                            const placement =
                                JSON.parse(score.response)?.indexOf(item.id) + 1

                            if (index + 1 !== rangeArray.length) {
                                return (
                                    placement > range.min &&
                                    placement <= range.max
                                )
                            } else {
                                return placement > range.min
                            }
                        })?.length /
                            totalScores.length) *
                        100

                    return valObject
                })

                return {
                    concept: item,
                    averageRank: val / totalScores.length,
                    scores: totalScores,
                    name: item.title,
                    ...valObject,
                }
            })
            ?.sort((a, b) => {
                return a.averageRank - b.averageRank
            }) ?? []

    if (!!viewingAllResponses) {
        return (
            <Box
                display="flex"
                flexDirection="column"
                overflow="hidden"
                height="100%"
            >
                <RankingResultsBarChart
                    rangeArray={rangeArray}
                    data={orderedData}
                />
            </Box>
        )
    } else {
        return (
            <Box
                display="flex"
                flexDirection={"column"}
                style={{ overflowY: "auto" }}
                height="100%"
            >
                {orderedData.map((item, index) => {
                    return (
                        <Box key={item.concept.id}>
                            <RankingItem
                                concept={item.concept}
                                position={index + 1}
                                showPosition={true}
                                draggable={false}
                            />
                        </Box>
                    )
                })}
            </Box>
        )
    }
}

function getRangeArray(concepts: Concept[]) {
    const stringArray = [
        "",
        "1st",
        "2nd",
        "3rd",
        "4th",
        "5th",
        "6th",
        "7th",
        "8th",
        "9th",
        "10th",
        "11th",
        "12th",
        "13th",
        "14th",
        "15th",
    ]
    let rangeArray = [
        {
            label: "Ranked 1st",
            max: 1,
            min: 0,
        },
    ]
    const rangeSplit =
        Math.round((concepts.length - 1) / 3) < 1
            ? 1
            : Math.round((concepts.length - 1) / 3)

    const totalSplits = rangeSplit === 1 ? 4 : 3
    for (let i = 1; i <= totalSplits; i++) {
        const min = rangeArray[i - 1]?.max
        const max = min + rangeSplit
        if (i < concepts.length) {
            rangeArray.push({
                label:
                    rangeSplit === 1
                        ? `Ranked ${stringArray[i + 1]}`
                        : i === totalSplits
                        ? `Ranked ${stringArray[min + 1]} or above`
                        : `Ranked ${stringArray[min + 1]} - ${
                              stringArray[max]
                          }`,
                max: max,
                min: min,
            })
        }
    }
    return rangeArray
}

export default RankingResponseViewer
