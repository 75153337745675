import {
    Box,
    Button,
    FormControl,
    InputLabel,
    ListItemText,
    MenuItem,
    Select,
    Switch,
    TextField,
    Typography,
    ListItemSecondaryAction,
    MenuList,
    CircularProgress,
    Icon,
    IconButton,
} from "@material-ui/core"
import { useEffect, useState } from "react"
import { AccessType, WorkflowStage } from "../../__generated__/types"
import useAwaitTranslation from "../../i18n/useAwaitTranslation"

import JustificationOptionsEditor from "./JustificationOptionsEditor"
import useWorkflowTools from "./useWorkflowTools"
import { Autocomplete } from "@material-ui/lab"
import DeleteForever from "@material-ui/icons/DeleteForever"
import ClickableRichTooltip from "../Popper/ClickableRichTooltip"
import Add from "@material-ui/icons/Add"
import IconPicker, { IconValue } from "../icons/IconPicker"

const WorkflowStageForm = (props: {
    stage: WorkflowStage
    onClose: () => void
    onDeletion: () => void
}) => {
    const { stage, onClose, onDeletion } = props
    const { t } = useAwaitTranslation("workflows")

    const [stageData, setStageData] = useState(null)
    const [nextStageOptions, setNextStageOptions] = useState<WorkflowStage[]>(
        null
    )
    const {
        addNextStageOption,
        addingNextStageOption,
        removeNextStageOption,
        removingNextStageOption,
        updateWorkflowStage,
        updatingWorkflowStage,
        deleteWorkflowStage,
        deletingWorkflowStage,
    } = useWorkflowTools({
        fetch: false,
    })
    const handleDeletion = async () => {
        await deleteWorkflowStage({
            variables: {
                id: stage.id,
            },
        })
        onDeletion()
    }
    const handleSaving = async () => {
        let cleanedStageData = {
            ...stageData,
            icon: !!stageData.icon ? JSON.stringify(stageData.icon) : null,
        }

        if (!cleanedStageData.justificationRequired) {
            cleanedStageData["justificationOptions"] = null
        }
        let promises = [
            ...currentNextStageOptions
                .filter((op) => !stage.nextOptions.find((x) => op.id === x.id))
                .map((op) => {
                    return addNextStageOption({
                        variables: {
                            firstStageId: stage.id,
                            nextStageId: op.id,
                        },
                    })
                }),
            ...stage.nextOptions
                .filter(
                    (op) => !currentNextStageOptions.find((x) => op.id === x.id)
                )
                .map((op) => {
                    return removeNextStageOption({
                        variables: {
                            firstStageId: stage.id,
                            nextStageId: op.id,
                        },
                    })
                }),
        ]

        await Promise.all([
            ...promises,
            updateWorkflowStage({
                variables: {
                    id: stage.id,
                    ...cleanedStageData,
                },
            }),
        ])

        onClose()
    }

    let initialData = {
        title: stage.title,
        justificationRequired: stage.justificationRequired,
        justificationOptions: stage.justificationOptions,
        permissionRequired: stage.permissionRequired ?? AccessType.NONE,
        icon: !!stage.icon ? JSON.parse(stage.icon ?? "{}") : null,
    }
    useEffect(() => {
        if (!!stage) {
            setStageData({
                title: stage.title,
                justificationRequired: stage.justificationRequired,
                justificationOptions: stage.justificationOptions,
                permissionRequired: stage.permissionRequired ?? AccessType.NONE,
                icon: !!stage.icon ? JSON.parse(stage.icon ?? "{}") : null,
            })
        }
    }, [stage])

    const currentJustificationOptions = JSON.parse(
        stageData?.justificationOptions ?? "[]"
    )

    const currentNextStageOptions = !!nextStageOptions
        ? nextStageOptions
        : stage?.nextOptions

    const nextOptionsChanged =
        !!nextStageOptions?.some(
            (op) => !stage?.nextOptions?.some((x) => x.id === op.id)
        ) ||
        !!stage.nextOptions?.some(
            (op) => !nextStageOptions?.some((x) => x.id === op.id)
        )

    return (
        !!stageData && (
            <Box
                key={stage.id}
                width="100%"
                height="100%"
                p={2}
                display="flex"
                flexDirection={"column"}
                style={{ overflowY: "auto" }}
            >
                <Box display="flex" width="100%" alignItems={"baseline"} p={1}>
                    <Box p={1} flexGrow={1}>
                        <Typography variant="subtitle1" color="textSecondary">
                            {stage.workflow?.title}
                        </Typography>
                        <Typography variant="h6">Stage Editor</Typography>
                    </Box>
                </Box>

                <Box p={1.5} display="flex" alignItems="center">
                    <ClickableRichTooltip
                        content={
                            <IconPicker
                                value={stageData?.icon}
                                onChange={(value: IconValue) =>
                                    setStageData({
                                        ...stageData,
                                        icon: value,
                                    })
                                }
                            />
                        }
                    >
                        {!!stageData?.icon?.name ? (
                            <IconButton>
                                <Icon>{stageData.icon.name}</Icon>
                            </IconButton>
                        ) : (
                            <Button
                                size="small"
                                startIcon={<Add fontSize="small" />}
                            >
                                Icon
                            </Button>
                        )}
                    </ClickableRichTooltip>
                    <TextField
                        fullWidth
                        size="small"
                        label={t("title", "Title")}
                        variant="outlined"
                        onChange={(e) => {
                            setStageData({
                                ...stageData,
                                title: e.currentTarget.value,
                            })
                        }}
                        value={stageData.title}
                    />
                </Box>
                <Box p={1.5}>
                    <FormControl fullWidth variant="outlined" size="small">
                        <InputLabel>
                            {t(
                                "permissionRequiredToChange",
                                "Permission Required to Change"
                            )}
                        </InputLabel>
                        <Select
                            label={t(
                                "permissionRequiredToChange",
                                "Permission Required to Change"
                            )}
                            fullWidth
                            value={stageData.permissionRequired}
                            onChange={(e) =>
                                setStageData({
                                    ...stageData,
                                    permissionRequired: e.target.value,
                                })
                            }
                        >
                            {Object.keys(AccessType).map(
                                (accessType, index) => (
                                    <MenuItem
                                        value={AccessType[accessType]}
                                        key={index}
                                    >
                                        {accessType === AccessType.NONE
                                            ? "ADMIN ONLY"
                                            : AccessType[accessType]}
                                    </MenuItem>
                                )
                            )}
                        </Select>
                    </FormControl>
                </Box>
                <Box p={1.5}>
                    <MenuList dense>
                        <MenuItem
                            onClick={() =>
                                setStageData({
                                    ...stageData,
                                    justificationRequired: !stageData.justificationRequired,
                                })
                            }
                        >
                            <ListItemText
                                primary={`${t(
                                    "requireJustificationReason",
                                    "Require Justification Reason"
                                )}?`}
                                primaryTypographyProps={{
                                    color: "textSecondary",
                                }}
                            />
                            <ListItemSecondaryAction>
                                <Switch
                                    checked={!!stageData.justificationRequired}
                                    onChange={(e) =>
                                        setStageData({
                                            ...stageData,
                                            justificationRequired: !stageData.justificationRequired,
                                        })
                                    }
                                />
                            </ListItemSecondaryAction>
                        </MenuItem>
                    </MenuList>
                </Box>
                {!!stageData.justificationRequired && (
                    <Box p={1.5}>
                        <JustificationOptionsEditor
                            value={currentJustificationOptions}
                            onChange={(value: string[]) => {
                                setStageData({
                                    ...stageData,
                                    justificationOptions: JSON.stringify(value),
                                })
                            }}
                        />
                    </Box>
                )}
                <NextStageOptionsEditor
                    onChange={setNextStageOptions}
                    value={currentNextStageOptions}
                    stage={stage}
                />
                <Box display="flex" justifyContent={"space-between"} p={1}>
                    <Button
                        onClick={handleDeletion}
                        disabled={
                            !!deletingWorkflowStage || !!updatingWorkflowStage
                        }
                        startIcon={
                            !!deletingWorkflowStage ? null : (
                                <DeleteForever color="error" />
                            )
                        }
                    >
                        {!!deletingWorkflowStage ? (
                            <CircularProgress size={20} disableShrink />
                        ) : (
                            "Delete"
                        )}
                    </Button>
                    <Box display="flex">
                        <Button
                            onClick={() => {
                                onClose()
                            }}
                            disabled={
                                !!updatingWorkflowStage ||
                                !!addingNextStageOption ||
                                !!removingNextStageOption ||
                                !!deletingWorkflowStage
                            }
                        >
                            Close
                        </Button>
                        <Button
                            color="primary"
                            disabled={
                                (JSON.stringify(stageData) ===
                                    JSON.stringify(initialData) &&
                                    !nextOptionsChanged) ||
                                !!updatingWorkflowStage ||
                                (!!stageData.justificationRequired &&
                                    JSON.parse(
                                        stageData?.justificationOptions ?? "[]"
                                    )?.length === 0)
                            }
                            onClick={handleSaving}
                        >
                            {!!updatingWorkflowStage ||
                            !!addingNextStageOption ||
                            !!removingNextStageOption ? (
                                <CircularProgress size={20} disableShrink />
                            ) : (
                                "Save"
                            )}
                        </Button>
                    </Box>
                </Box>
            </Box>
        )
    )
}

const NextStageOptionsEditor = (props: {
    value: WorkflowStage[]
    onChange: (value: WorkflowStage[]) => void
    stage: WorkflowStage
}) => {
    const { value, onChange, stage } = props
    const { allStages } = useWorkflowTools({ fetch: true })
    const options = allStages?.filter(
        (item) => stage.workflow.id === item.workflow.id && item.id !== stage.id
    )
    return (
        <Box p={1.5}>
            <Autocomplete
                multiple
                value={value}
                getOptionSelected={(option) =>
                    !!value.find((x) => x.id === option.id)
                }
                onChange={(event, newValue) => {
                    onChange([...newValue])
                }}
                getOptionLabel={(option) => option.title}
                options={options}
                renderInput={(params) => (
                    <TextField
                        label="Next Stage Options"
                        variant="outlined"
                        {...params}
                        size="small"
                    />
                )}
            />
        </Box>
    )
}

export default WorkflowStageForm
