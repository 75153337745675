import React, { useState, useEffect } from "react"
import Box from "@material-ui/core/Box"
import Button from "@material-ui/core/Button"
import { useApolloClient, useMutation } from "@apollo/client"
import {
    DialogContent,
    Dialog,
    CircularProgress,
    DialogTitle,
    DialogActions,
    Typography,
} from "@material-ui/core"
import { CriteriaType } from "../criteria/types"
import { Criteria, Input } from "../../__generated__/types"
import { UPDATE_INPUT } from "./graphql"
import CriteriaSchemaFormParser from "../criteria/CriteriaSchemaFormParser"
import {
    UpdateInputMutation,
    UpdateInputMutationVariables,
} from "./__generated__/graphql"
import SourceOptions from "./SourceOptions"
import useCriteriaTypes, {
    DefaultInputSource,
    InputSourceResponseType,
} from "../criteria/useCriteriaTypes"
import useWidget from "../dashboard/useWidget"
import ResponseTypeSelector from "./ResponseTypeSelector"
import useAwaitTranslation from "../../i18n/useAwaitTranslation"
import { CONCEPT_BY_ID } from "../../graphql/queries"
import { useParams } from "react-router-dom"
import {
    ConceptQuery,
    ConceptQueryVariables,
} from "../../graphql/__generated__/queries"
interface InputConfigFormProps {
    input?: Input | null
    widgetId?: string
    criteria?: Criteria
    onSave?: (config: any) => void
    onClose: () => void
    isTableField?: boolean
}
const InputConfigForm = (props: InputConfigFormProps) => {
    const { conceptId } = useParams()
    const currentCriteriaType = (props.input?.criteria?.criteriaType ??
        props.criteria.criteriaType) as CriteriaType
    const initialInputConfigurationOptions = JSON.parse(
        props.input?.inputConfig ?? "{}"
    )
    const client = useApolloClient()
    const concept = client.readQuery<ConceptQuery, ConceptQueryVariables>({
        query: CONCEPT_BY_ID,
        variables: {
            id: conceptId,
        },
    })?.Concept?.[0]

    const { onEditConfig, config } = useWidget(props.widgetId)
    const { defaultCriteriaConfig } = useCriteriaTypes(currentCriteriaType)
    const { t } = useAwaitTranslation("criteria")
    const [updateInput, { loading: updating }] = useMutation<
        UpdateInputMutation,
        UpdateInputMutationVariables
    >(UPDATE_INPUT)

    const [
        currentInputConfigurationOptions,
        setCurrentInputConfigurationOptions,
    ] = useState(null)

    const handleSave = async () => {
        let cleanedConfigObject: any = {}
        Object.keys(currentInputConfigurationOptions).map((field) => {
            if (Object.keys(defaultCriteriaConfig).includes(field)) {
                cleanedConfigObject[field] =
                    currentInputConfigurationOptions[field]
            }
            return cleanedConfigObject
        })
        if (!!cleanedConfigObject.source) {
            Object.keys(currentInputConfigurationOptions.source).map(
                (field) => {
                    if (Object.keys(DefaultInputSource).includes(field)) {
                        cleanedConfigObject.source[field] =
                            currentInputConfigurationOptions.source[field]
                    }
                    return cleanedConfigObject
                }
            )
            cleanedConfigObject.source = {
                ...DefaultInputSource,
                ...cleanedConfigObject.source,
            }
        }

        if (
            !!cleanedConfigObject.source &&
            !cleanedConfigObject.source.manuallySelect
        ) {
            cleanedConfigObject = {
                ...cleanedConfigObject,
                source: {
                    ...cleanedConfigObject.source,
                    manuallySelectedConceptIds: null,
                },
            }
        }
        let promises = []
        if (!!props.input) {
            promises.push(
                updateInput({
                    variables: {
                        id: props.input.id,
                        inputConfig: JSON.stringify(cleanedConfigObject),
                    },
                })
            )
        }
        if (!!props.widgetId) {
            promises.push(
                onEditConfig({
                    ...config,
                    inputConfig: cleanedConfigObject,
                })
            )
        }
        if (promises.length > 0) {
            await Promise.all(promises)
        } else {
            props.onSave(cleanedConfigObject)
        }

        props.onClose()
    }

    useEffect(() => {
        if (!currentInputConfigurationOptions) {
            const baseCriteriaConfig = JSON.parse(
                props.input?.criteria?.criteriaOptions ??
                    props.criteria?.criteriaOptions ??
                    "{}"
            )
            let baseConfig = {
                ...defaultCriteriaConfig,
                ...baseCriteriaConfig,
            }
            if (!!props.input) {
                baseConfig = {
                    ...baseConfig,
                    ...initialInputConfigurationOptions,
                }
            }
            setCurrentInputConfigurationOptions({
                ...baseConfig,
            })
        }
    }, [
        initialInputConfigurationOptions,
        props.input,
        currentInputConfigurationOptions,
        defaultCriteriaConfig,
        props.criteria,
    ])
    const isChild =
        currentInputConfigurationOptions?.source?.criteriaIds?.length > 0

    return (
        !!currentInputConfigurationOptions && (
            <Dialog
                maxWidth={
                    !isChild && !!currentInputConfigurationOptions?.source
                        ? "md"
                        : "sm"
                }
                open={true}
                fullWidth
                disableEnforceFocus={true}
            >
                <DialogTitle>
                    <Box
                        display="flex"
                        alignItems="center"
                        width="100%"
                        justifyContent={"space-between"}
                    >
                        <Typography variant="h6">
                            {t("fieldConfiguration", "Field Configuration")}
                        </Typography>
                    </Box>
                </DialogTitle>
                <DialogContent>
                    <Box display="flex" width="100%" height="100%">
                        <Box width="100%" height="100%">
                            <Box
                                display="flex"
                                flexDirection={"column"}
                                height="100%"
                                mb={
                                    !!currentInputConfigurationOptions?.source
                                        ? 3
                                        : 0
                                }
                            >
                                <CriteriaSchemaFormParser
                                    criteriaType={currentCriteriaType}
                                    configuration={
                                        currentInputConfigurationOptions
                                    }
                                    onChange={(config) => {
                                        setCurrentInputConfigurationOptions(
                                            config
                                        )
                                    }}
                                    criteriaId={
                                        props.input?.criteria?.id ??
                                        props.criteria?.id ??
                                        null
                                    }
                                    categoryId={concept?.category?.id}
                                    isTableField={props.isTableField}
                                />
                            </Box>
                            {!props.isTableField &&
                                !!currentInputConfigurationOptions?.source &&
                                (!isChild ? (
                                    <SourceOptions
                                        currentConfig={
                                            currentInputConfigurationOptions
                                        }
                                        onChange={
                                            setCurrentInputConfigurationOptions
                                        }
                                    />
                                ) : (
                                    <Box p={2} pt={0}>
                                        <ResponseTypeSelector
                                            value={
                                                currentInputConfigurationOptions
                                                    ?.source
                                                    ?.inputSourceResponseType ??
                                                InputSourceResponseType.primaryResponseOnly
                                            }
                                            onChange={(
                                                value: InputSourceResponseType
                                            ) => {
                                                setCurrentInputConfigurationOptions(
                                                    {
                                                        ...currentInputConfigurationOptions,
                                                        source: {
                                                            ...currentInputConfigurationOptions.source,
                                                            inputSourceResponseType: value,
                                                        },
                                                    }
                                                )
                                            }}
                                        />
                                    </Box>
                                ))}
                        </Box>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button size="small" onClick={() => props.onClose()}>
                        {t("cancel", "Cancel")}
                    </Button>
                    <Button
                        size="small"
                        variant="contained"
                        color="primary"
                        onClick={handleSave}
                        disabled={
                            JSON.stringify(currentInputConfigurationOptions) ===
                                props.input?.inputConfig ||
                            updating ||
                            currentInputConfigurationOptions?.name?.length ===
                                0 ||
                            (!!currentInputConfigurationOptions?.source
                                ?.manuallySelect &&
                                currentInputConfigurationOptions?.source
                                    ?.manuallySelectedConceptIds?.length === 0)
                        }
                    >
                        {updating ? (
                            <CircularProgress disableShrink size={25} />
                        ) : (
                            t("save", "Save")
                        )}
                    </Button>
                </DialogActions>
            </Dialog>
        )
    )
}

export default InputConfigForm
