import {
    Box,
    IconButton,
    DialogTitle,
    Dialog,
    Button,
    DialogActions,
    DialogContent,
    useMediaQuery,
    Typography,
} from "@material-ui/core"
import { makeStyles, useTheme } from "@material-ui/core/styles"
import CloseIcon from "@material-ui/icons/Close"
import React, { useState } from "react"
import { Loading } from "./Loading"
import { Concept, LanguageString } from "../__generated__/types"
import useAwaitTranslation from "../i18n/useAwaitTranslation"
import { useMutation } from "@apollo/client"
import {
    ADD_CONCEPT_TRANSLATION,
    CREATE_CONCEPT_TRANSLATION,
    UPDATE_CONCEPT_TRANSLATION,
} from "../graphql/mutations"
import {
    AddConceptTranslationMutation,
    AddConceptTranslationMutationVariables,
    CreateConceptTranslationMutation,
    CreateConceptTranslationMutationVariables,
    UpdateConceptTranslationMutation,
    UpdateConceptTranslationMutationVariables,
} from "../graphql/__generated__/mutations"
import { LanguageDataObject, LanguageTitle } from "../util/types"
import TranslatableContentForm from "./forms/TranslatableContentForm"
import SaveIcon from "@material-ui/icons/Save"
const useStyles = makeStyles((theme) => ({
    formContainer: {
        padding: 0,
        minWidth: "300px",
        width: "100%",
        maxWidth: "100vw",
        height: "100%",
        overflow: "hidden",
        display: "flex",
        [theme.breakpoints.down("sm")]: {
            overflow: "auto",
            flexDirection: "column",
        },
    },
}))

interface ConceptTranslationsModalProps {
    concept: Concept
    onClose: () => void
}
const ConceptTranslationsModal: React.FunctionComponent<
    ConceptTranslationsModalProps
> = ({ concept, onClose }) => {
    const classes = useStyles()
    const theme = useTheme()
    const mobile = useMediaQuery(theme.breakpoints.down("sm"))
    const { t, i18n } = useAwaitTranslation("concepts")
    const langString = i18n?.language as LanguageString

    const [createTranslation] = useMutation<
        CreateConceptTranslationMutation,
        CreateConceptTranslationMutationVariables
    >(CREATE_CONCEPT_TRANSLATION)
    const [addTranslation] = useMutation<
        AddConceptTranslationMutation,
        AddConceptTranslationMutationVariables
    >(ADD_CONCEPT_TRANSLATION)
    const [updateTranslation] = useMutation<
        UpdateConceptTranslationMutation,
        UpdateConceptTranslationMutationVariables
    >(UPDATE_CONCEPT_TRANSLATION)
    const [submitting, setSubmitting] = useState(false)
    const [english, setEnglish] = useState<LanguageDataObject>({
        title: concept?.title,
        summary: concept?.summary,
        description: concept?.description,
    })
    const currentTranslation = concept.translations.filter(
        (t) => t.languageString === langString
    )[0]
    const [translatedContent, setTranslatedContent] =
        useState<LanguageDataObject>({
            title: currentTranslation?.title || "",
            summary: currentTranslation?.summary || "",
            description: currentTranslation?.description || "",
        })

    const handleSubmission = async () => {
        setSubmitting(true)
        if (!currentTranslation) {
            const {
                data: { CreateTranslation: NewTranslation },
            } = await createTranslation({
                variables: {
                    ...translatedContent,
                    languageString: langString,
                },
            })

            await addTranslation({
                variables: {
                    conceptId: concept.id,
                    translationId: NewTranslation.id,
                },
            })
        } else {
            await updateTranslation({
                variables: {
                    translationId: currentTranslation.id,
                    ...translatedContent,
                },
            })
        }
        onClose()
    }
    return (
        <Dialog open={true} fullScreen>
            {submitting ? (
                <Loading size={25} hideQuote={true} />
            ) : (
                <>
                    <DialogTitle>
                        <Box
                            display="flex"
                            justifyContent="space-between"
                            alignItems="center"
                        >
                            <Typography variant="h5">
                                {t("manageTranslations", "Manage Translations")}
                            </Typography>
                            <IconButton
                                onClick={() => {
                                    onClose()
                                }}
                            >
                                <CloseIcon />
                            </IconButton>
                        </Box>
                    </DialogTitle>
                    <DialogContent
                        dividers
                        style={{ overflow: mobile ? "auto" : "hidden" }}
                    >
                        <Box className={classes.formContainer}>
                            <TranslatableContentForm
                                categoryName={concept.category.name}
                                setItem={setEnglish}
                                item={english}
                                language={LanguageTitle.en}
                                disabled={true}
                            />
                            <TranslatableContentForm
                                categoryName={concept.category.name}
                                setItem={setTranslatedContent}
                                item={translatedContent}
                                language={LanguageTitle[langString]}
                                disabled={false}
                            />
                        </Box>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            disabled={translatedContent.title?.length === 0}
                            variant="contained"
                            onClick={handleSubmission}
                            startIcon={<SaveIcon />}
                            color="primary"
                        >
                            Save
                        </Button>
                    </DialogActions>
                </>
            )}
        </Dialog>
    )
}

export default ConceptTranslationsModal
