import { useQuery, useReactiveVar } from "@apollo/client"
import { Box, List, ListItem, ListItemText } from "@material-ui/core"
import { useParams } from "react-router-dom"
import {
    myCurrentInputResponseLabelVar,
    myCurrentInputResponseVar,
} from "../../../providers/GlobalState"
import { CriteriaScore } from "../../../__generated__/types"
import { CollectionType, CollectionView } from "../../criteria/useCriteriaTypes"
import useCollectionTools from "../../inputs/collection/useCollectionTools"
import { INHERITED_CONNECTIONS } from "../../inputs/graphql"
import { ScoreSelectorDefaultValues } from "../../inputs/types"
import { getInputId } from "../../inputs/util"
import {
    InheritedConnectionsQuery,
    InheritedConnectionsQueryVariables,
} from "../../inputs/__generated__/graphql"
import SectionHeader from "../SectionHeader"
import ListItemDeserializer from "./ListItemDeserializer"
import TableDeserializer from "./TableDeserializer"

const CollectionDeserializer = (props: {
    score: CriteriaScore
    config: CollectionType
}) => {
    const { conceptId } = useParams()
    const { score, config } = props
    const currentFields = useReactiveVar(myCurrentInputResponseVar)
    const { state } = useCollectionTools(score, config)
    const orderedConcepts = state?.concepts ?? []

    const currentLabel = useReactiveVar(myCurrentInputResponseLabelVar)
    const viewingPrimaryResponse =
        currentLabel?.label === ScoreSelectorDefaultValues.primaryResponse &&
        !currentLabel.user
    const { data } = useQuery<
        InheritedConnectionsQuery,
        InheritedConnectionsQueryVariables
    >(INHERITED_CONNECTIONS, {
        variables: {
            filter: {
                AND: [
                    {
                        connections_some: {
                            id: conceptId,
                        },
                    },
                    {
                        id_not_in:
                            orderedConcepts?.map((concept) => concept.id) ?? [],
                    },
                    {
                        category: {
                            id_in:
                                config?.categories?.map((item) => item.id) ??
                                [],
                        },
                    },
                ],
            },
        },
        fetchPolicy: "cache-and-network",
        skip: !!config.requirePrimaryResponse || !viewingPrimaryResponse,
    })
    const inheritedConnections = data?.Concept ?? []
    const conceptsToShow = [...orderedConcepts, ...inheritedConnections]
    return (
        <Box>
            <SectionHeader
                headerName={config?.name}
                conceptCount={conceptsToShow.length}
            />
            {config.view !== CollectionView.table ? (
                <List dense>
                    {!!config?.singleSelection ? (
                        <ListItem>
                            <ListItemText
                                primary={score?.concepts?.[0]?.title || ""}
                                secondary={score?.concepts?.[0]?.summary || ""}
                            />
                        </ListItem>
                    ) : (
                        conceptsToShow.map((item) => {
                            return (
                                <ListItemDeserializer
                                    concept={item}
                                    key={item.id}
                                />
                            )
                        })
                    )}
                </List>
            ) : (
                <TableDeserializer
                    orderedConcepts={conceptsToShow}
                    columnFields={config.subfields
                        ?.map((sub) =>
                            currentFields?.currentResponseData?.find(
                                (item) =>
                                    getInputId(
                                        sub.id,
                                        conceptId,
                                        sub.inputConfig
                                    ) ===
                                    getInputId(
                                        item.input.criteria?.id,
                                        conceptId,
                                        JSON.parse(item.input.inputConfig)
                                    )
                            )
                        )
                        ?.filter((x) => !!x)}
                />
            )}
        </Box>
    )
}

export default CollectionDeserializer
