import React from "react"
import Box from "@material-ui/core/Box"
import ListItem from "@material-ui/core/ListItem"
import ListItemAvatar from "@material-ui/core/ListItemAvatar"
import ListItemText from "@material-ui/core/ListItemText"
import { makeStyles } from "@material-ui/core/styles"
import Typography from "@material-ui/core/Typography"
import { useHistory } from "react-router-dom"
import { Concept } from "../__generated__/types"
import useAwaitTranslation from "../i18n/useAwaitTranslation"
import ConceptAvatar from "./ConceptAvatar"
import CategoryLabel from "./CategoryLabel"
import { ListItemIcon } from "@material-ui/core"
import ConceptPreviewPopper from "./ConceptPreviewPopper"
import { useAuth } from "../providers/AuthProvider"
const useStyles = makeStyles((theme) => ({
    summary: {
        fontSize: 12,
        color: theme.palette.text.secondary,
    },
    subIcon: {
        height: "0.65em",
        width: "0.65em",
    },
    greyedText: {
        color: theme.palette.text.secondary,
        fontSize: 12,
    },
    noWrapText: {
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
    },
}))
interface ConceptListItemProps {
    item: Concept
    showSummary?: boolean
    disableClick?: boolean
    disableTitleWrap?: boolean
    onClick?: () => void
    hideDivider?: boolean
    hideCategory?: boolean
    selected?: boolean
    secondaryIcon?: React.ReactElement
    disablePreview?: boolean
}

export default function ConceptListItem(props: ConceptListItemProps) {
    const {
        item,
        showSummary,
        disableClick,
        selected,
        disableTitleWrap,
        hideCategory,
        secondaryIcon,
        disablePreview,
    } = props
    const classes = useStyles()

    const history = useHistory()
    const { isNonMember } = useAuth()
    const { i18n } = useAwaitTranslation("concepts")
    const selectedLangString = i18n?.language
    let translatedContent =
        item?.translations?.filter(
            (t) => t.languageString === selectedLangString
        )?.[0] || null
    let titleProps = {}
    if (!!disableTitleWrap) {
        titleProps = {
            noWrap: true,
            style: {
                overflow: "hidden",
                textOverflow: "ellipsis",
            },
        }
    }

    return (
        <Box key={item.id}>
            <ListItem
                button={!disableClick && !isNonMember ? true : undefined}
                onClick={(e) => {
                    e.stopPropagation()
                    if (props.onClick) {
                        props.onClick()
                    } else if (!disableClick && !isNonMember) {
                        history.push(`/concept/${item.id}/home`)
                    }
                }}
                style={{ display: "block" }}
                divider={!props.hideDivider}
                selected={!!selected}
            >
                <Box
                    display="flex"
                    alignItems="center"
                    overflow="hidden"
                    width="100%"
                >
                    <ListItemAvatar style={{ display: "flex" }}>
                        <ConceptAvatar
                            concept={props.item}
                            variant="rounded"
                            size="large"
                        />
                    </ListItemAvatar>
                    <ListItemText>
                        {!hideCategory && (
                            <CategoryLabel
                                conceptCategoryId={item.category?.id}
                                size="small"
                            />
                        )}
                        <Box width="fit-content">
                            {!disablePreview ? (
                                <ConceptPreviewPopper conceptId={item.id}>
                                    <Typography variant="body2" {...titleProps}>
                                        {translatedContent?.title || item.title}
                                    </Typography>
                                </ConceptPreviewPopper>
                            ) : (
                                <Typography variant="body2" {...titleProps}>
                                    {translatedContent?.title || item.title}
                                </Typography>
                            )}
                        </Box>
                        {!!hideCategory && showSummary && (
                            <Box className={classes.greyedText}>
                                {translatedContent?.summary || item.summary}
                            </Box>
                        )}
                    </ListItemText>
                    {!!secondaryIcon && (
                        <ListItemIcon>{secondaryIcon}</ListItemIcon>
                    )}
                </Box>
                {!hideCategory && !!showSummary && (
                    <Box className={classes.greyedText} mt={0.5}>
                        {translatedContent?.summary || item.summary}
                    </Box>
                )}
            </ListItem>
        </Box>
    )
}
