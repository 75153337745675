import React, { useState } from "react"
import Box from "@material-ui/core/Box"
import { makeStyles, Theme, useTheme } from "@material-ui/core/styles"
import { useMutation, useQuery } from "@apollo/client"
import Paper from "@material-ui/core/Paper"
import Button from "@material-ui/core/Button"
import Typography from "@material-ui/core/Typography"
import useAwaitTranslation from "../../i18n/useAwaitTranslation"
import {
    ADD_USER_ACTIVE_DARK_THEME,
    ADD_USER_ACTIVE_LIGHT_THEME,
    REMOVE_USER_ACTIVE_DARK_THEME,
    REMOVE_USER_ACTIVE_LIGHT_THEME,
    USER_THEMES_QUERY,
} from "./graphql"
import {
    AddActiveDarkThemeMutation,
    AddActiveDarkThemeMutationVariables,
    AddActiveLightThemeMutation,
    AddActiveLightThemeMutationVariables,
    RemoveActiveDarkThemeMutation,
    RemoveActiveDarkThemeMutationVariables,
    RemoveActiveLightThemeMutation,
    RemoveActiveLightThemeMutationVariables,
    UserThemesQueryQuery,
    UserThemesQueryQueryVariables,
} from "./__generated__/graphql"
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import ListItemText from "@material-ui/core/ListItemText"
import Divider from "@material-ui/core/Divider"
import IconButton from "@material-ui/core/IconButton"
import EditIcon from "@material-ui/icons/Edit"
import Tooltip from "@material-ui/core/Tooltip"
import { defaultTheme } from "../../styles/defaultTheme"
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank"
import CheckBoxIcon from "@material-ui/icons/CheckBox"
import { useAuth } from "../../providers/AuthProvider"
import { DesignTheme, User } from "../../__generated__/types"
import { Loading } from "../Loading"
import ThemeForm from "./ThemeForm"
import GuidanceModal, { SettingsSection } from "../settings/GuidanceModal"
const useStyles = makeStyles((theme: Theme) => ({
    color: {
        width: "100%",
        height: "2em",
    },
    root: {
        display: "flex",
        flexDirection: "column",
        padding: theme.spacing(1),
        [theme.breakpoints.up("sm")]: {
            height: "100%",
            overflow: "hidden",
        },
    },
    section: {
        [theme.breakpoints.down("sm")]: {
            flexDirection: "column",
        },
        height: "100%",
        [theme.breakpoints.up("sm")]: {
            flexDirection: "row",
            overflow: "hidden",
        },
    },
    swatch: {
        padding: "1px",
        background: "#fff",
        borderRadius: theme.shape.borderRadius,
        boxShadow: "0 0 0 1px rgba(0,0,0,.1)",
        display: "inline-block",
        cursor: "pointer",
        width: "100%",
    },
    popover: {
        position: "absolute",
        zIndex: 2,
    },
    cover: {
        position: "fixed",
        top: "0px",
        right: "0px",
        bottom: "0px",
        left: "0px",
    },
    label: {
        margin: theme.spacing(1, 0),
        color: "currentColor",
    },
    list: {
        width: "100%",
        overflowX: "hidden",
        overflowY: "auto",
        borderRadius: theme.shape.borderRadius,
    },
    fullWidth: {
        width: "100%",
    },
    listPaper: {
        margin: theme.spacing(1.5),
        marginTop: 0,
    },
    container: {
        height: "100%",
        borderRadius: theme.shape.borderRadius,
        border: "1px solid",
        display: "flex",
        flexDirection: "column",
        [theme.breakpoints.down("sm")]: {
            height: "600px",
        },
    },
    colorPreview: {
        width: "2em",
        height: "2em",
        borderRadius: theme.shape.borderRadius,
    },
    radioGroup: {
        padding: theme.spacing(2),
        flexDirection: "row",
    },
}))

export default function ThemeManager() {
    const { currentUser } = useAuth()
    const classes = useStyles()
    const [open, setOpen] = useState(false)
    const theme = useTheme()
    const { t } = useAwaitTranslation("theme")
    const { data, loading, error } = useQuery<
        UserThemesQueryQuery,
        UserThemesQueryQueryVariables
    >(USER_THEMES_QUERY, {
        variables: {
            userId: currentUser.userId,
        },
    })
    const [addActiveLightTheme] = useMutation<
        AddActiveLightThemeMutation,
        AddActiveLightThemeMutationVariables
    >(ADD_USER_ACTIVE_LIGHT_THEME)
    const [addActiveDarkTheme] = useMutation<
        AddActiveDarkThemeMutation,
        AddActiveDarkThemeMutationVariables
    >(ADD_USER_ACTIVE_DARK_THEME)
    const [removeActiveLightTheme] = useMutation<
        RemoveActiveLightThemeMutation,
        RemoveActiveLightThemeMutationVariables
    >(REMOVE_USER_ACTIVE_LIGHT_THEME)
    const [removeActiveDarkTheme] = useMutation<
        RemoveActiveDarkThemeMutation,
        RemoveActiveDarkThemeMutationVariables
    >(REMOVE_USER_ACTIVE_DARK_THEME)
    if (loading) {
        return <Loading size={25} hideQuote={true} />
    }
    if (error) {
        return <Box>{t("themeErrorGeneral", "There has been an error")}</Box>
    }
    const User = data.User[0] as User
    const lightThemes = data.User[0]?.createdThemes.filter(
        (x) => x.darkMode === false
    )
    const darkThemes = data.User[0].createdThemes.filter(
        (x) => x.darkMode === true
    )
    const handleThemeActivation = (themeItem) => {
        if (themeItem.darkMode) {
            if (User.activeDarkTheme) {
                removeActiveDarkTheme({
                    variables: {
                        fromId: User.userId,
                        toId: User.activeDarkTheme.themeId,
                    },
                })
            }
            addActiveDarkTheme({
                variables: {
                    fromId: User.userId,
                    toId: themeItem.themeId,
                },
            })
        } else {
            if (User.activeLightTheme) {
                removeActiveLightTheme({
                    variables: {
                        fromId: User.userId,
                        toId: User.activeLightTheme.themeId,
                    },
                })
            }
            addActiveLightTheme({
                variables: {
                    fromId: User.userId,
                    toId: themeItem.themeId,
                },
            })
        }
    }
    return (
        <Box
            className={classes.root}
            padding={1}
            display="flex"
            flexDirection="column"
            height="100%"
        >
            <Box
                display="flex"
                justifyContent="flex-end"
                padding={1.5}
                paddingTop={2}
            >
                <Button
                    variant="contained"
                    onClick={() => {
                        setOpen(true)
                    }}
                >
                    {t("createNew", "Create New")}
                </Button>
                <GuidanceModal section={SettingsSection.Themes} />
            </Box>
            <Box display="flex" width="100%" className={classes.section}>
                <Box padding={1.5} width="100%">
                    <Box
                        width="100%"
                        className={classes.container}
                        style={{
                            backgroundColor:
                                defaultTheme.LIGHT.background.default,
                            borderColor: defaultTheme.LIGHT.divider,
                        }}
                    >
                        <Box
                            style={{ color: defaultTheme.LIGHT.text.primary }}
                            padding={1.5}
                        >
                            {t("activatedLightTheme", "Activated Light Theme")}
                        </Box>
                        <List style={{ padding: 0 }}>
                            {User.activeLightTheme ? (
                                <ThemeListItem
                                    handleThemeActivation={
                                        handleThemeActivation
                                    }
                                    item={User.activeLightTheme}
                                    user={User}
                                />
                            ) : (
                                <Box
                                    p={2}
                                    textAlign="center"
                                    style={{
                                        color: defaultTheme.LIGHT.text.primary,
                                    }}
                                >
                                    {t(
                                        "noLightThemeSelected",
                                        "No Active Light Theme Selected"
                                    )}
                                </Box>
                            )}
                        </List>
                        <Box padding={1}>
                            <Divider
                                style={{
                                    backgroundColor: defaultTheme.LIGHT.divider,
                                }}
                            />
                        </Box>
                        <Box
                            style={{ color: defaultTheme.LIGHT.text.primary }}
                            padding={1.5}
                        >
                            {t(
                                "allCreatedLightThemes",
                                "All Created Light Themes"
                            )}
                        </Box>
                        <List className={classes.list}>
                            {lightThemes &&
                                lightThemes.map((item: DesignTheme) => {
                                    if (
                                        item.themeId !==
                                        User.activeLightTheme?.themeId
                                    ) {
                                        return (
                                            <Box key={item.themeId}>
                                                <ThemeListItem
                                                    handleThemeActivation={
                                                        handleThemeActivation
                                                    }
                                                    item={item}
                                                    user={User}
                                                />
                                            </Box>
                                        )
                                    }
                                })}
                        </List>
                    </Box>
                </Box>
                <Box padding={1.5} width="100%">
                    <Box
                        className={classes.container}
                        style={{
                            backgroundColor:
                                defaultTheme.DARK.background.default,
                            borderColor: defaultTheme.DARK.divider,
                        }}
                    >
                        <Box
                            style={{ color: defaultTheme.DARK.text.primary }}
                            padding={1.5}
                        >
                            {t("activatedDarkTheme", "Acitvated Dark Theme")}
                        </Box>
                        <List style={{ padding: 0 }}>
                            {User.activeDarkTheme ? (
                                <ThemeListItem
                                    handleThemeActivation={
                                        handleThemeActivation
                                    }
                                    item={User.activeDarkTheme}
                                    user={User}
                                />
                            ) : (
                                <Box
                                    p={2}
                                    textAlign="center"
                                    style={{
                                        color: defaultTheme.DARK.text.primary,
                                    }}
                                >
                                    {t(
                                        "noActivateDarkThemeSelcted",
                                        "No Acitvate Dark Theme Selected"
                                    )}
                                </Box>
                            )}
                        </List>
                        <Box padding={1}>
                            <Divider
                                style={{
                                    backgroundColor: defaultTheme.DARK.divider,
                                }}
                            />
                        </Box>
                        <Box
                            style={{ color: defaultTheme.DARK.text.primary }}
                            padding={1.5}
                        >
                            {t("allDarkThemes", "All Created Dark Themes")}
                        </Box>
                        <List className={classes.list}>
                            {darkThemes &&
                                darkThemes.map((item: DesignTheme) => {
                                    if (
                                        item.themeId !==
                                        User.activeDarkTheme?.themeId
                                    ) {
                                        return (
                                            <Box key={item.themeId}>
                                                <ThemeListItem
                                                    handleThemeActivation={
                                                        handleThemeActivation
                                                    }
                                                    item={item}
                                                    user={User}
                                                />
                                            </Box>
                                        )
                                    } else {
                                        return null
                                    }
                                })}
                        </List>
                    </Box>
                </Box>
            </Box>
            {open && (
                <ThemeForm
                    onClose={() => {
                        setOpen(false)
                    }}
                    mode={theme.palette.type}
                    user={User}
                />
            )}
        </Box>
    )
}

interface ThemeListItemProps {
    handleThemeActivation: Function
    item: DesignTheme
    user: User
}
const ThemeListItem = (props: ThemeListItemProps) => {
    const [open, setOpen] = useState(false)
    const [active, setActive] = useState(false)
    const classes = useStyles()
    const theme = useTheme()
    const { t } = useAwaitTranslation("theme")
    React.useEffect(() => {
        if (props.item.darkMode) {
            setActive(
                props.user.activeDarkTheme?.themeId === props.item.themeId
            )
        } else {
            setActive(
                props.user.activeLightTheme?.themeId === props.item.themeId
            )
        }
    }, [props.item, props.user])

    return (
        <Paper
            className={classes.listPaper}
            style={{
                backgroundColor: props.item.darkMode
                    ? defaultTheme.DARK.background.paper
                    : defaultTheme.LIGHT.background.paper,
                color: props.item.darkMode
                    ? defaultTheme.DARK.text.primary
                    : defaultTheme.LIGHT.text.primary,
            }}
        >
            <Box display="flex">
                <ListItem
                    classes={{
                        container: classes.fullWidth,
                    }}
                >
                    {active ? (
                        <Box
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                            marginRight={2}
                        >
                            <CheckBoxIcon
                                style={{
                                    color: props.item.darkMode
                                        ? defaultTheme.DARK.text.primary
                                        : defaultTheme.LIGHT.text.primary,
                                }}
                            />
                        </Box>
                    ) : (
                        <Box
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                            marginRight={2}
                        >
                            <IconButton
                                style={{
                                    padding: 0,
                                    color: props.item.darkMode
                                        ? defaultTheme.DARK.text.primary
                                        : defaultTheme.LIGHT.text.primary,
                                }}
                                onClick={() => {
                                    props.handleThemeActivation(props.item)
                                }}
                            >
                                <CheckBoxOutlineBlankIcon />
                            </IconButton>
                        </Box>
                    )}
                    <ListItemText
                        primary={
                            <Typography
                                style={{
                                    color: props.item.darkMode
                                        ? defaultTheme.DARK.text.primary
                                        : defaultTheme.LIGHT.text.primary,
                                }}
                            >
                                {props.item.title}
                            </Typography>
                        }
                        secondary={
                            <Typography
                                style={{
                                    color: props.item.darkMode
                                        ? defaultTheme.DARK.text.secondary
                                        : defaultTheme.LIGHT.text.secondary,
                                    fontSize: theme.typography.body2.fontSize,
                                }}
                            >
                                {props.item.title}
                            </Typography>
                        }
                    />

                    <Box display="flex">
                        <Box padding={0.5}>
                            <Box
                                className={classes.colorPreview}
                                style={{ backgroundColor: props.item.primary }}
                            />
                        </Box>
                        <Box padding={0.5}>
                            <Box
                                className={classes.colorPreview}
                                style={{
                                    backgroundColor: props.item.secondary,
                                }}
                            />
                        </Box>
                    </Box>
                    <Tooltip title={t("editTheme", "Edit theme")}>
                        <IconButton
                            style={{
                                color: props.item.darkMode
                                    ? defaultTheme.DARK.text.secondary
                                    : defaultTheme.LIGHT.text.secondary,
                            }}
                            onClick={() => {
                                setOpen(true)
                            }}
                        >
                            <EditIcon />
                        </IconButton>
                    </Tooltip>
                </ListItem>
                {open && (
                    <ThemeForm
                        item={props.item}
                        mode={props.item.darkMode ? "dark" : "light"}
                        user={props.user}
                        onClose={() => {
                            setOpen(false)
                        }}
                    />
                )}
            </Box>
        </Paper>
    )
}
