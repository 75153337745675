import React, { useCallback, useState } from "react"
import { NetworkStatus, useMutation } from "@apollo/client"
import {
    createStyles,
    Theme,
    makeStyles,
    useTheme,
    alpha,
} from "@material-ui/core/styles"
import Box from "@material-ui/core/Box"
import FieldAutocomplete from "./FieldAutocomplete"
import { Loading } from "../Loading"
import {
    Slide,
    ListItem,
    ListItemText,
    CircularProgress,
    IconButton,
    Collapse,
    ListItemIcon,
    List,
    ListSubheader,
    Typography,
    ListItemSecondaryAction,
    Divider,
    Tooltip,
    TextField,
    InputAdornment,
} from "@material-ui/core"
import {
    ADD_CATEGORY_CRITERIA,
    ADD_FIELD_SUBFIELD,
    REMOVE_CATEGORY_CRITERIA,
    REMOVE_FIELD_SUBFIELD,
} from "./graphql"
import {
    AddCategoryCriteriaMutation,
    AddCategoryCriteriaMutationVariables,
    RemoveCategoryCriteriaMutation,
    RemoveCategoryCriteriaMutationVariables,
    AddFieldSubfieldMutation,
    AddFieldSubfieldMutationVariables,
    RemoveFieldSubfieldMutation,
    RemoveFieldSubfieldMutationVariables,
} from "./__generated__/graphql"
import CriteriaForm from "./CriteriaForm"
import { Category, Criteria, Widget } from "../../__generated__/types"
import useAwaitTranslation from "../../i18n/useAwaitTranslation"
import Clear from "@material-ui/icons/Clear"
import KeyboardArrowUp from "@material-ui/icons/KeyboardArrowUp"
import KeyboardArrowDown from "@material-ui/icons/KeyboardArrowDown"
import { CriteriaType } from "./types"
import {
    CRITERIA_TYPE_ICONS,
    TOP_LEVEL_CRITERIA_OPTIONS,
} from "./useCriteriaTypes"
import CollectionCategorySubList from "./CollectionCategorySubList"
import useCategoryTools from "../categories/useCategoryTools"
import Search from "@material-ui/icons/Search"
import FieldTypeSelect from "./FieldTypeSelect"
import { useQuery } from "@apollo/client"
import { GET_DASHBOARD_WIDGETS_BY_ID } from "../dashboard/graphql"
import {
    GetDashboardWidgetsQuery,
    GetDashboardWidgetsQueryVariables,
} from "../dashboard/__generated__/graphql"
import ConceptListItem from "../ConceptListItem"
import Dashboard from "@material-ui/icons/Dashboard"
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            [theme.breakpoints.down("sm")]: {
                padding: 0,
            },
            height: "100%",
            overflow: "hidden",
            display: "flex",
        },
        row: {
            "& > *": {
                borderBottom: "unset",
            },
        },
    })
)

const CategoryCriteriaManager = (props: { category: Category }) => {
    const { category } = props
    const classes = useStyles()
    const theme = useTheme()
    const { getCategoryArray, onRefetchCategories, networkStatus } =
        useCategoryTools({ fetchPolicy: "network-only" })
    const { data: widgetsData, networkStatus: widgetsStatus } = useQuery<
        GetDashboardWidgetsQuery,
        GetDashboardWidgetsQueryVariables
    >(GET_DASHBOARD_WIDGETS_BY_ID, {
        variables: {
            dashboardId: category.defaultDashboard?.id,
        },
        skip: !category.defaultDashboard?.id,
        fetchPolicy: "network-only",
    })
    const [focusedField, setFocusedField] = useState(null)
    const [focusedParentField, setFocusedParentField] = useState(null)
    const [showForm, setShowForm] = useState(false)
    const [searchString, setSearchString] = useState("")
    const [selectedCriteriaType, setSelectedCriteriaType] = useState<
        CriteriaType | "All"
    >("All")
    const [addCriteriaToCategory] = useMutation<
        AddCategoryCriteriaMutation,
        AddCategoryCriteriaMutationVariables
    >(ADD_CATEGORY_CRITERIA)
    const [removeCriteriaFromCategory] = useMutation<
        RemoveCategoryCriteriaMutation,
        RemoveCategoryCriteriaMutationVariables
    >(REMOVE_CATEGORY_CRITERIA)
    const [addFieldSubfield] = useMutation<
        AddFieldSubfieldMutation,
        AddFieldSubfieldMutationVariables
    >(ADD_FIELD_SUBFIELD)
    const [removeFieldSubfield] = useMutation<
        RemoveFieldSubfieldMutation,
        RemoveFieldSubfieldMutationVariables
    >(REMOVE_FIELD_SUBFIELD)
    const categoryArray = getCategoryArray(category?.id)

    const fields = categoryArray
        .flatMap((cat) => cat.criteria)
        .filter(
            (value, index, self) =>
                index === self.findIndex((field) => field.id === value.id) &&
                !value.isArchived &&
                !!value.name
        )
        .sort(function (a, b) {
            var textA = a.name.toUpperCase()
            var textB = b.name.toUpperCase()
            return textA < textB ? -1 : textA > textB ? 1 : 0
        })

    const availableTopLevelCategoryFields =
        fields?.filter(
            (c) =>
                c.name?.toLowerCase().indexOf(searchString?.toLowerCase()) !==
                    -1 &&
                (selectedCriteriaType === "All" ||
                    c.criteriaType === selectedCriteriaType)
        ) ?? []

    const categoryFields = availableTopLevelCategoryFields?.filter((criteria) =>
        categoryArray
            ?.find((cat) => category?.id === cat.id)
            ?.criteria?.find((field) => field.id === criteria.id)
    )
    const inheritedFields = availableTopLevelCategoryFields?.filter(
        (criteria) => !categoryFields?.find((field) => field.id === criteria.id)
    )
    const { t } = useAwaitTranslation("criteria")

    const onAddNewField = useCallback(
        async (field: Criteria, subfield?: Criteria) => {
            if (!subfield) {
                await addCriteriaToCategory({
                    variables: {
                        categoryId: category.id,
                        criteriaId: field.id,
                    },
                })
            } else {
                await addFieldSubfield({
                    variables: {
                        parentFieldId: field.id,
                        childFieldId: subfield.id,
                    },
                })
            }
            setFocusedField(null)
            setFocusedParentField(null)
            setShowForm(false)
            return
        },
        [addCriteriaToCategory, category.id, addFieldSubfield]
    )

    const onRemoveField = useCallback(
        async (field: Criteria, fieldToRemoveFrom?: Criteria) => {
            if (!!fieldToRemoveFrom) {
                await removeFieldSubfield({
                    variables: {
                        parentFieldId: fieldToRemoveFrom.id,
                        childFieldId: field.id,
                    },
                })
            } else {
                await removeCriteriaFromCategory({
                    variables: {
                        categoryId: category.id,
                        criteriaId: field.id,
                    },
                })
            }
        },
        [removeCriteriaFromCategory, category.id, removeFieldSubfield]
    )

    if (
        networkStatus === NetworkStatus.loading ||
        widgetsStatus === NetworkStatus.loading
    ) {
        return <Loading size={25} hideQuote={true} />
    }
    const defaultDashboard = category.defaultDashboard

    const widgets = widgetsData?.Widget || []
    return (
        <Box className={classes.root}>
            <Box
                overflow="hidden"
                width="100%"
                display="flex"
                flexDirection={"column"}
            >
                <Box pl={2} mt={3}>
                    <Typography color="textSecondary" variant="body2">
                        Default Workspace
                    </Typography>
                </Box>
                {defaultDashboard?.conceptHome?.[0] ? (
                    <ConceptListItem
                        showSummary={true}
                        hideCategory={true}
                        hideDivider={true}
                        item={defaultDashboard?.conceptHome?.[0]}
                    />
                ) : (
                    <Box p={2}>
                        <Clear />
                    </Box>
                )}

                <Box p={2} pb={1} display="flex" alignItems="center">
                    <Box mr={2}>
                        <FieldTypeSelect
                            typeOptions={TOP_LEVEL_CRITERIA_OPTIONS}
                            value={selectedCriteriaType}
                            onChange={(value: CriteriaType | "All") => {
                                setSelectedCriteriaType(value)
                            }}
                            variant="outlined"
                            size="small"
                            includeAll={true}
                        />
                    </Box>

                    <TextField
                        variant="outlined"
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <Search />
                                </InputAdornment>
                            ),
                        }}
                        size="small"
                        autoFocus
                        fullWidth
                        value={searchString}
                        onChange={(e) => setSearchString(e.target.value)}
                        label={`${t("search", "Search")} ${
                            category?.name ?? ""
                        } ${t("fields", "Fields")}`}
                    />
                </Box>
                <Box
                    display="flex"
                    alignItems="center"
                    justifyContent={"flex-end"}
                    pr={3}
                >
                    <Dashboard color="primary" fontSize="small" />

                    <Typography noWrap variant="caption">
                        = Included in Category Default Workspace
                    </Typography>
                </Box>
                <List disablePadding style={{ overflowY: "auto" }}>
                    <ListSubheader
                        style={{
                            backgroundColor: alpha(
                                theme.palette.background.paper,
                                0.95
                            ),
                        }}
                    >
                        {category?.name} {t("fields", "Fields")}
                    </ListSubheader>
                    {categoryFields?.length > 0 &&
                        categoryFields?.map((item, index) => {
                            return (
                                <FieldRow
                                    workspaceWidgets={widgets}
                                    key={item.id}
                                    field={item}
                                    onFocus={(
                                        field: Criteria,
                                        child?: boolean
                                    ) => {
                                        if (!!child) {
                                            setFocusedParentField(item)
                                        }
                                        setFocusedField(field)
                                    }}
                                    onCreateNewSubfield={() => {
                                        setFocusedParentField(item)
                                        setShowForm(true)
                                    }}
                                    onFieldRemoval={onRemoveField}
                                    onAddExistingSubfield={async (
                                        subfield: Criteria
                                    ) => {
                                        await onAddNewField(item, subfield)
                                    }}
                                    focusedFieldId={focusedField?.id}
                                />
                            )
                        })}
                    <Box display="flex" p={1} mr={2} ml={2} mt={1}>
                        <FieldAutocomplete
                            optionsAllowed={"Top"}
                            onSelect={onAddNewField}
                            currentFieldIds={categoryFields?.map(
                                (item) => item.id
                            )}
                            onCreateNewField={() => {
                                setShowForm(true)
                            }}
                            colorBackground={true}
                        />
                    </Box>
                    {!props.category?.isRoot && (
                        <>
                            <ListSubheader
                                style={{
                                    zIndex: 1,

                                    backgroundColor:
                                        theme.palette.background.paper,
                                }}
                            >
                                {t("inheritedFields", "Inherited Fields")}
                            </ListSubheader>
                            {inheritedFields?.map((item, index) => {
                                return (
                                    <FieldRow
                                        workspaceWidgets={widgets}
                                        key={item.id}
                                        field={item}
                                    />
                                )
                            })}
                        </>
                    )}
                </List>
            </Box>
            {(!!showForm || !!focusedField || !!focusedParentField) && (
                <Divider orientation="vertical" flexItem />
            )}
            <Slide
                in={!!showForm || !!focusedField || !!focusedParentField}
                mountOnEnter
                unmountOnExit
                direction="left"
            >
                <Box width="100%" height="100%" overflow="auto" p={3}>
                    {!focusedField && (
                        <Typography variant="h6">
                            {!!showForm && !focusedParentField
                                ? `${t("new", "New")} ${category?.name} ${t(
                                      "field",
                                      "Field"
                                  )}`
                                : `${t("new", "New")} ${
                                      focusedParentField?.name
                                  } ${t("subfield", "Subfield")}`}
                        </Typography>
                    )}

                    <CriteriaForm
                        onClose={() => {
                            setShowForm(false)
                            setFocusedField(null)
                            setFocusedParentField(null)
                        }}
                        onSave={async (criteria?: Criteria) => {
                            await onRefetchCategories()
                        }}
                        hideHeader={!focusedField}
                        parent={focusedParentField}
                        categoryId={category.id}
                        criteria={focusedField}
                    />
                </Box>
            </Slide>
        </Box>
    )
}

const FieldRow = (props: {
    field: Criteria
    onFieldRemoval?: (
        field: Criteria,
        fieldToRemoveFrom?: Criteria
    ) => Promise<void>
    onFocus?: (field: Criteria, sub?: boolean) => void
    onAddExistingSubfield?: (field: Criteria) => Promise<void>
    onCreateNewSubfield?: () => void
    focusedFieldId?: string
    workspaceWidgets: Widget[]
}) => {
    const {
        field,
        onFieldRemoval,
        onFocus,
        onAddExistingSubfield,
        focusedFieldId,
        onCreateNewSubfield,
        workspaceWidgets,
    } = props
    const { t } = useAwaitTranslation("criteria")
    const theme = useTheme()
    const classes = useStyles()
    const { getCategoryArray } = useCategoryTools()
    const [hovered, setHovered] = useState(false)
    const [removing, setRemoving] = useState(false)
    const [expanded, setExpanded] = useState(false)

    const onRemoval = async (subField?: Criteria) => {
        if (!!subField) {
            await onFieldRemoval(subField, field)
        } else {
            setRemoving(true)
            await onFieldRemoval(field)
        }
    }

    const icon = CRITERIA_TYPE_ICONS[field.criteriaType]
    let categories = []
    const collectionCategories = JSON.parse(
        field?.criteriaOptions ?? "{}"
    )?.categories
    collectionCategories?.map((item) => {
        categories = [...categories, ...(getCategoryArray(item.id) ?? [])]
        return categories
    })
    const fieldInDefaultWorkspace = !!workspaceWidgets.find(
        (widget) => JSON.parse(widget.config ?? "{}")?.criteriaId === field.id
    )

    return (
        <Box
            key={field.id}
            width="100%"
            style={{
                backgroundColor: !!expanded
                    ? alpha(theme.palette.primary.light, 0.05)
                    : "inherit",
            }}
        >
            <ListItem
                onMouseOver={() => {
                    if (!!onFieldRemoval && !removing) {
                        setHovered(true)
                    }
                }}
                onMouseLeave={() => {
                    if (!!onFieldRemoval && !removing) {
                        setHovered(false)
                    }
                }}
                onClick={() => {
                    if (!!onFocus && !removing) {
                        onFocus(field)
                    }
                }}
                disabled={!!removing}
                button={!!onFocus && !removing ? true : undefined}
                className={classes.row}
                selected={focusedFieldId === field.id}
            >
                <ListItemIcon>
                    {field.criteriaType === CriteriaType.Collection && (
                        <IconButton
                            onClick={(e) => {
                                e.stopPropagation()
                                setExpanded(!expanded)
                            }}
                        >
                            {!!expanded ? (
                                <KeyboardArrowUp />
                            ) : (
                                <KeyboardArrowDown />
                            )}
                        </IconButton>
                    )}
                </ListItemIcon>
                <ListItemText>
                    <span
                        style={{
                            display: "flex",
                            alignItems: "center",
                        }}
                    >
                        <span
                            style={{
                                display: "flex",
                                alignItems: "center",
                                color: theme.palette.action.active,
                                marginRight: 10,
                            }}
                        >
                            {icon && icon}
                        </span>
                        <Typography variant="body1" style={{ marginRight: 8 }}>
                            {field.name}
                        </Typography>
                        {field.criteriaType === CriteriaType.Collection &&
                            !!collectionCategories && (
                                <CollectionCategorySubList
                                    categoryIds={collectionCategories?.map(
                                        (item) => item.id
                                    )}
                                />
                            )}
                    </span>
                </ListItemText>

                {!!fieldInDefaultWorkspace ? (
                    <Tooltip
                        title={t(
                            "fieldsIncludedInTheDefaultWorkspaceCannotBeRemoved",
                            "Fields included in the default workspace cannot be removed"
                        )}
                    >
                        <Dashboard color="primary" fontSize="small" />
                    </Tooltip>
                ) : !!removing ? (
                    <Box p={1.5}>
                        <CircularProgress size={20} disableShrink />
                    </Box>
                ) : (
                    hovered &&
                    !!onFieldRemoval && (
                        <Tooltip
                            title={t(
                                "removeFieldFromCategory",
                                "Remove field from category"
                            )}
                        >
                            <IconButton
                                onClick={(e) => {
                                    e.stopPropagation()
                                    onRemoval()
                                }}
                                size="small"
                            >
                                <Clear />
                            </IconButton>
                        </Tooltip>
                    )
                )}
            </ListItem>
            <Box key={field.id + "-children"} pl={8} pr={5}>
                <Collapse in={expanded} unmountOnExit>
                    <Box width="100%" height="100%">
                        <List dense>
                            {props.field.subfields
                                .filter((subfield) => !subfield.isArchived)
                                .map((subfield, index) => {
                                    const icon =
                                        CRITERIA_TYPE_ICONS[
                                            subfield.criteriaType
                                        ]
                                    const subfieldInDefaultWorkspace =
                                        !!workspaceWidgets.find((widget) => {
                                            const config = JSON.parse(
                                                widget.config ?? "{}"
                                            )
                                            return (
                                                config?.criteriaId ===
                                                    subfield.id &&
                                                config?.inputConfig?.source
                                                    ?.criteriaIds?.length > 0
                                            )
                                        })
                                    return (
                                        <ListItem
                                            selected={
                                                focusedFieldId === subfield.id
                                            }
                                            button={
                                                !!onFocus ? true : undefined
                                            }
                                            onClick={(e) => {
                                                e.stopPropagation()
                                                if (!!onFocus) {
                                                    onFocus(subfield, true)
                                                }
                                            }}
                                            key={subfield.id}
                                        >
                                            <ListItemIcon
                                                style={{
                                                    minWidth: theme.spacing(4),
                                                }}
                                            >
                                                {icon && icon}
                                            </ListItemIcon>
                                            <ListItemText
                                                primary={subfield.name}
                                            />
                                            {!!subfieldInDefaultWorkspace ? (
                                                <Tooltip
                                                    title={t(
                                                        "fieldsIncludedInTheDefaultWorkspaceCannotBeRemoved",
                                                        "Fields included in the default workspace cannot be removed"
                                                    )}
                                                >
                                                    <Dashboard
                                                        color="primary"
                                                        fontSize="small"
                                                    />
                                                </Tooltip>
                                            ) : (
                                                !!onFieldRemoval && (
                                                    <ListItemSecondaryAction>
                                                        <Tooltip
                                                            title={t(
                                                                "removeSubfield",
                                                                "Remove subfield"
                                                            )}
                                                        >
                                                            <IconButton
                                                                size="small"
                                                                onClick={(
                                                                    e
                                                                ) => {
                                                                    e.stopPropagation()
                                                                    onRemoval(
                                                                        subfield
                                                                    )
                                                                }}
                                                            >
                                                                <Clear fontSize="small" />
                                                            </IconButton>
                                                        </Tooltip>
                                                    </ListItemSecondaryAction>
                                                )
                                            )}
                                        </ListItem>
                                    )
                                })}
                        </List>
                        <Box display="flex" p={1}>
                            <FieldAutocomplete
                                optionsAllowed="Sub"
                                onSelect={onAddExistingSubfield}
                                currentFieldIds={props.field.subfields.map(
                                    (field) => field.id
                                )}
                                categoriesToGroupBy={categories}
                                onCreateNewField={onCreateNewSubfield}
                            />
                        </Box>
                    </Box>
                </Collapse>
            </Box>
        </Box>
    )
}

export default CategoryCriteriaManager
