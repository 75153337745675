import { SystemRole, User } from "../../__generated__/types"
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Typography,
    Box,
    CircularProgress,
} from "@material-ui/core"
import useUserManagementTools from "../users/useUserManagementTools"
import { Loading } from "../Loading"
import { useHistory } from "react-router-dom"
const ConfirmUserAccess = (props: { userToConfirm: User }) => {
    const { userToConfirm } = props
    const { onGrantSystemAccess, sending, updating } = useUserManagementTools()
    const history = useHistory()

    const handleDecision = async (approve?: boolean) => {
        if (!approve) {
            history.replace("/settings/users")
        } else {
            await onGrantSystemAccess(
                userToConfirm.userId,
                userToConfirm.email,
                SystemRole.GUEST,
                SystemRole.NONE,
                true
            )
        }
    }

    return (
        <Dialog open={true} fullWidth>
            {!!userToConfirm ? (
                <>
                    <DialogTitle>
                        Access has been requested by the following user:
                    </DialogTitle>
                    <DialogContent>
                        <Box>
                            <Typography color="textSecondary">
                                First Name
                            </Typography>
                            <Typography>{userToConfirm.firstName}</Typography>
                        </Box>
                        <Box mt={2}>
                            <Typography color="textSecondary">
                                Last Name
                            </Typography>
                            <Typography>{userToConfirm.lastName}</Typography>
                        </Box>
                        <Box mt={2}>
                            <Typography color="textSecondary">Email</Typography>
                            <Typography>{userToConfirm.email}</Typography>
                        </Box>
                        <Box mt={2}>
                            <Typography color="textSecondary">
                                Username
                            </Typography>
                            <Typography>{userToConfirm.username}</Typography>
                        </Box>
                        <Box mt={2}>
                            <Typography color="textSecondary">
                                Company
                            </Typography>
                            <Typography>{userToConfirm.company}</Typography>
                        </Box>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={() => handleDecision(false)}
                            disabled={!!updating || !!sending}
                        >
                            Deny
                        </Button>
                        <Button
                            onClick={() => handleDecision(true)}
                            disabled={!!updating}
                            style={{ position: "relative" }}
                        >
                            {(!!updating || !!sending) && (
                                <CircularProgress
                                    size={20}
                                    style={{ position: "absolute" }}
                                />
                            )}
                            Accept
                        </Button>
                    </DialogActions>
                </>
            ) : (
                <Loading size={25} hideQuote={true} padding={3} />
            )}
        </Dialog>
    )
}

export default ConfirmUserAccess
