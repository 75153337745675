import { _ConceptFilter, _ConceptOrdering } from "../../../__generated__/types"

export type FilterItem = {
    type: FilterType
    value: string[]
    firstLevelFilter: string
    secondLevelFilter?: string
    secondLevelFilterFormatter?: (value: string[]) => any // could be any type filter
    field?: string
    optionsFilter?: _ConceptFilter
    disabled?: boolean
    hide?: boolean
}

export type FilterProps = {
    item: FilterItem
    setFilters: (filters: FilterItem[]) => void
    filters: FilterItem[]
}

export enum FilterType {
    Category = "Category",
    User = "User",
    Industry = "Industry",
    Tags = "Tags",
    Concept = "Concept",
    NotificationType = "NotificationType",
    Status = "Status",
}

export type SortOption = {
    label: string
    translationKey: string
    value: _ConceptOrdering
}
export const sortOptions: SortOption[] = [
    {
        label: "Newest First",
        translationKey: "newestFirst",
        value: _ConceptOrdering.CREATEDAT_DESC,
    },
    {
        label: "Oldest First",
        translationKey: "oldestFirst",
        value: _ConceptOrdering.CREATEDAT_ASC,
    },
    {
        label: "Title (A -> Z)",
        translationKey: "titleAsc",
        value: _ConceptOrdering.TITLE_ASC,
    },
    {
        label: "Title (Z -> A)",
        translationKey: "titleDesc",
        value: _ConceptOrdering.TITLE_DESC,
    },
]
