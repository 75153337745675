import React, { useEffect, useRef, useState } from "react"
import { InputAdornment, TextField } from "@material-ui/core"
import { useAuth } from "../../../../providers/AuthProvider"
import useConceptTools from "../../../../util/useConceptTools"
import { useApolloClient } from "@apollo/client"
import { Concept } from "../../../../__generated__/types"
import { INPUT_SOURCE_CONCEPT_FRAGMENT } from "../../graphql"
import GenerateAI, { getTitlePrompt } from "../../../GenerateAI"
import { ConceptFieldSettings } from "../../../../util/SystemSettings"
import { Box } from "@material-ui/core"
import ConceptPreviewPopper from "../../../ConceptPreviewPopper"
import { useHistory } from "react-router-dom"
const TitleCell = (props: {
    concept: Concept
    editing: boolean
    onBlur: () => void
}) => {
    const { editing, concept, onBlur } = props
    const [value, setValue] = useState(null)
    const inputRef = useRef(null)
    const { currentUser, isNonMember } = useAuth()
    const history = useHistory()
    const client = useApolloClient()
    const { onUpdateConcept } = useConceptTools()
    useEffect(() => {
        if (value === null) {
            setValue(concept?.title)
        }
    }, [concept?.title, value])

    const handleSave = (generatedVal?: string) => {
        if (!!editing && value?.length > 0) {
            client.cache.writeFragment({
                id: client.cache.identify(concept),
                fragment: INPUT_SOURCE_CONCEPT_FRAGMENT,
                data: {
                    ...concept,
                    title: generatedVal || value,
                },
            })
            onUpdateConcept({
                userId: currentUser.userId,
                concept: {
                    id: concept?.id,
                    title: generatedVal || value,
                },
            })
        } else {
            setValue(concept?.title)
        }
    }
    useEffect(() => {
        if (!!editing) {
            inputRef?.current?.focus()
        }
    }, [editing])
    if (!editing) {
        return (
            <ConceptPreviewPopper conceptId={concept.id}>
                <Box
                    onClick={() => {
                        if (!isNonMember) {
                            history.push(`/concept/${concept.id}/home`)
                        }
                    }}
                    component="span"
                >
                    {value || ""}
                </Box>
            </ConceptPreviewPopper>
        )
    }
    return (
        <TextField
            onChange={(e) => setValue(e.target.value)}
            onBlur={() => handleSave()}
            fullWidth
            value={value || ""}
            multiline
            onKeyDown={(e) => {
                if (e.key === "Enter") {
                    handleSave()
                    onBlur()
                }
            }}
            variant="standard"
            inputRef={inputRef}
            InputProps={{
                style: {
                    fontSize: 14,
                },

                endAdornment: (
                    <InputAdornment
                        position="end"
                        style={{
                            marginBottom: "auto",
                            width: "16px",
                        }}
                    >
                        <GenerateAI
                            prompt={getTitlePrompt(
                                value,
                                concept.category?.name
                            )}
                            maxLength={ConceptFieldSettings.Title.maxLength}
                            onGenerate={(res) => {
                                handleSave(res)
                                setValue(res)
                            }}
                            onGenerateError={(e) => console.log(e)}
                            size="small"
                        />
                    </InputAdornment>
                ),
            }}
        />
    )
}

export default TitleCell
