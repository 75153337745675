import React, { useState, useEffect } from "react"
import InputAdornment from "@material-ui/core/InputAdornment"
import Button from "@material-ui/core/Button"
import Dialog from "@material-ui/core/Dialog"
import DialogActions from "@material-ui/core/DialogActions"
import Box from "@material-ui/core/Box"
import DialogContent from "@material-ui/core/DialogContent"
import DialogTitle from "@material-ui/core/DialogTitle"
import TextField from "@material-ui/core/TextField"
import { useHistory } from "react-router-dom"
import { useLazyQuery, useMutation } from "@apollo/client"
import CircularProgress from "@material-ui/core/CircularProgress"
import Autocomplete from "@material-ui/lab/Autocomplete"
import { CREATE_CONCEPT } from "../../graphql/mutations"
import {
    CreateConceptCompleteMutation,
    CreateConceptCompleteMutationVariables,
} from "../../graphql/__generated__/mutations"
import {
    CREATE_TEAM_WITH_USERS,
    ADD_TEAM_HOME_CONCEPT,
    ADD_TEAM_ADMIN,
} from "./graphql"
import {
    CreateTeamWithUsersMutation,
    CreateTeamWithUsersMutationVariables,
    AddTeamHomeConceptMutation,
    AddTeamHomeConceptMutationVariables,
    AddTeamAdminMutation,
    AddTeamAdminMutationVariables,
} from "./__generated__/graphql"
import { CREATE_TEAM_WORKSPACE } from "../../graphql/conceptListQueries"
import useAwaitTranslation from "../../i18n/useAwaitTranslation"

import {
    CreateTeamWorkspaceMutation,
    CreateTeamWorkspaceMutationVariables,
} from "../../graphql/__generated__/conceptListQueries"
import { ALL_USER_QUERY } from "../users/graphql"
import {
    AllUsersQuery,
    AllUsersQueryVariables,
} from "../users/__generated__/graphql"
import SearchIcon from "@material-ui/icons/Search"
import { Loading } from "../Loading"
import { User, AccessType } from "../../__generated__/types"
import { useAuth } from "../../providers/AuthProvider"
interface TeamFormProps {
    onClose: () => void
}
export default function TeamForm(props: TeamFormProps) {
    const [loadUsers, { data, loading, error, called }] = useLazyQuery<
        AllUsersQuery,
        AllUsersQueryVariables
    >(ALL_USER_QUERY)
    const { currentUser } = useAuth()
    const { t } = useAwaitTranslation("teams")
    const [name, setName] = useState("")
    const [members, setMembers] = useState([])
    const [leader, setLeader] = useState(null)
    const [admins, setAdmins] = useState([])
    const history = useHistory()
    const [createConcept] = useMutation<
        CreateConceptCompleteMutation,
        CreateConceptCompleteMutationVariables
    >(CREATE_CONCEPT)
    const [addTeamHomeConcept] = useMutation<
        AddTeamHomeConceptMutation,
        AddTeamHomeConceptMutationVariables
    >(ADD_TEAM_HOME_CONCEPT)
    const [createTeam] = useMutation<
        CreateTeamWithUsersMutation,
        CreateTeamWithUsersMutationVariables
    >(CREATE_TEAM_WITH_USERS)
    const [addAdmin] = useMutation<
        AddTeamAdminMutation,
        AddTeamAdminMutationVariables
    >(ADD_TEAM_ADMIN)
    const [createTeamWorkspace] = useMutation<
        CreateTeamWorkspaceMutation,
        CreateTeamWorkspaceMutationVariables
    >(CREATE_TEAM_WORKSPACE)

    const [saving, setSaving] = useState(false)
    const [options, setOptions] = useState(null)

    useEffect(() => {
        if (data) {
            setOptions(data.users)
        }
    }, [data, setOptions])
    if (error) {
        return <div>There has been an error loading users...</div>
    }
    const handleSubmit = async () => {
        setSaving(true)
        const {
            data: { CreateTeamWithUsers: NewTeam },
        } = await createTeam({
            variables: {
                team: {
                    name: name,
                    memberIds: members.map((x) => x.userId),
                    leaderId: leader?.userId,
                },
            },
        })

        await Promise.all([
            ...admins.map((item) =>
                addAdmin({
                    variables: {
                        fromId: item.userId,
                        toId: NewTeam.teamId,
                    },
                })
            ),
            createTeamWorkspace({
                variables: {
                    teamId: NewTeam.teamId,
                },
            }),
        ])
        const {
            data: { CreateConcept: NewConcept },
        } = await createConcept({
            variables: {
                concept: {
                    title: `${name} Team Concept`,
                    isPublic: false,
                    isPublicAccessType: AccessType.NONE,
                    type: "Team Concept",
                },
                userId: currentUser.userId,
                tags: [],
            },
        })
        await addTeamHomeConcept({
            variables: {
                teamId: NewTeam.teamId,
                conceptId: NewConcept.id,
            },
        })
        history.push(`/team/${NewTeam.teamId}/home`)
    }
    return (
        <Dialog open={true} fullWidth>
            {saving ? (
                <Loading size={25} hideQuote={true} padding={3} />
            ) : (
                <>
                    <DialogTitle>
                        <Box
                            display="flex"
                            alignItems="center"
                            justifyContent="space-between"
                        >
                            <Box>Create New Team</Box>
                        </Box>
                    </DialogTitle>
                    <DialogContent>
                        <Box p={2}>
                            <TextField
                                variant="outlined"
                                autoFocus
                                value={name}
                                onChange={(e) => {
                                    setName(e.target.value)
                                }}
                                required
                                margin="dense"
                                label={t("teamName", "Team Name")}
                                fullWidth
                            />
                        </Box>
                        <Box p={2}>
                            <Autocomplete
                                onFocus={() => {
                                    if (!called) {
                                        loadUsers()
                                    }
                                }}
                                loading={loading}
                                options={options ?? []}
                                getOptionLabel={(option: User) => {
                                    return (
                                        option.lastName +
                                        ", " +
                                        option.firstName
                                    )
                                }}
                                onChange={(
                                    event: React.ChangeEvent<HTMLInputElement>,
                                    newValue: User[]
                                ) => {
                                    if (
                                        leader &&
                                        newValue.filter((x) => {
                                            return x.userId === leader.userId
                                        }).length === 0
                                    ) {
                                        setLeader(null)
                                    }
                                    const adminsCheck = admins
                                        .map((user) => {
                                            const check =
                                                newValue.filter(
                                                    (item) =>
                                                        item.userId ===
                                                        user.userId
                                                )?.length > 0
                                            if (check) {
                                                return user
                                            } else {
                                                return null
                                            }
                                        })
                                        ?.filter((x) => !!x)

                                    setMembers(newValue)
                                    setAdmins(adminsCheck)
                                }}
                                multiple
                                disableCloseOnSelect
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        variant="outlined"
                                        label={t("members", "Members")}
                                        required
                                        placeholder={t(
                                            "searchUsers",
                                            "Search Users"
                                        )}
                                        InputProps={{
                                            ...params.InputProps,
                                            startAdornment: loading ? (
                                                <>
                                                    <InputAdornment position="start">
                                                        <CircularProgress
                                                            disableShrink
                                                            size={25}
                                                        />
                                                    </InputAdornment>
                                                    {
                                                        params.InputProps
                                                            .startAdornment
                                                    }
                                                </>
                                            ) : (
                                                <>
                                                    <InputAdornment position="start">
                                                        <SearchIcon />
                                                    </InputAdornment>
                                                    {
                                                        params.InputProps
                                                            .startAdornment
                                                    }
                                                </>
                                            ),
                                        }}
                                    />
                                )}
                            />
                        </Box>
                        <Box p={2}>
                            <Autocomplete
                                value={admins}
                                loading={loading}
                                options={members}
                                getOptionLabel={(option: User) => {
                                    return (
                                        option.lastName +
                                        ", " +
                                        option.firstName
                                    )
                                }}
                                onChange={(
                                    event: React.ChangeEvent<HTMLInputElement>,
                                    newValue: User[]
                                ) => {
                                    setAdmins(newValue)
                                }}
                                multiple
                                disableCloseOnSelect
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        variant="outlined"
                                        label={t("admins", "Admins")}
                                    />
                                )}
                            />
                        </Box>
                        <Box p={2}>
                            <Autocomplete
                                value={leader}
                                options={members}
                                onChange={(
                                    event: React.ChangeEvent<HTMLInputElement>,
                                    newValue: User
                                ) => {
                                    setLeader(newValue)
                                }}
                                getOptionLabel={(option: User) => {
                                    return (
                                        option.lastName +
                                        ", " +
                                        option.firstName
                                    )
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        required
                                        {...params}
                                        variant="outlined"
                                        label={t("teamLead", "Team Lead")}
                                        placeholder={t(
                                            "selectTeamLead",
                                            "Select Team Lead"
                                        )}
                                    />
                                )}
                            />
                        </Box>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            disabled={saving}
                            onClick={() => {
                                props.onClose()
                            }}
                        >
                            {t("discard", "Discard")}
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleSubmit}
                            disabled={
                                name.length === 0 ||
                                members.length === 0 ||
                                !leader ||
                                saving
                            }
                        >
                            {saving ? (
                                <CircularProgress size={25} disableShrink />
                            ) : (
                                t("save", "Save")
                            )}
                        </Button>
                    </DialogActions>
                </>
            )}
        </Dialog>
    )
}
