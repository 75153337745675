import { useQuery } from "@apollo/client"
import { useAuth } from "../../providers/AuthProvider"
import { SystemRole } from "../../__generated__/types"
import { USER_FILTERED } from "../users/graphql"
import {
    UserFilteredQuery,
    UserFilteredQueryVariables,
} from "../users/__generated__/graphql"
import { ALL_ENVIRONMENT_SETTINGS } from "./graphql"
import { BooleanValues, EnvironmentSettingName } from "./types"
import {
    AllEnvironmentSettingsQuery,
    AllEnvironmentSettingsQueryVariables,
} from "./__generated__/graphql"

export default function useEnvironmentSettingTools(props?: {
    fetchAdmins?: boolean
}) {
    const { currentUser, isNonMember } = useAuth()
    const { data, loading: loadingSettings } = useQuery<
        AllEnvironmentSettingsQuery,
        AllEnvironmentSettingsQueryVariables
    >(ALL_ENVIRONMENT_SETTINGS, {
        skip: !currentUser,
    })
    const { data: adminData, loading: loadingAdmins } = useQuery<
        UserFilteredQuery,
        UserFilteredQueryVariables
    >(USER_FILTERED, {
        variables: {
            filter: {
                role: SystemRole.ADMIN,
            },
        },
        skip: !currentUser || !props?.fetchAdmins,
    })
    const settings = data?.EnvironmentSetting ?? null
    const admins = adminData?.User ?? null
    const adminIsRequiredToPublish =
        settings?.find(
            (setting) =>
                setting.name ===
                EnvironmentSettingName.RestrictPublishingToAdmins
        )?.value === BooleanValues.TRUE
    const adminIsRequiredToConnect =
        settings?.find(
            (setting) =>
                setting.name ===
                EnvironmentSettingName.RestrictConnectionsToAdmins
        )?.value === BooleanValues.TRUE
    const primaryResponseText =
        settings?.find(
            (setting) =>
                setting.name === EnvironmentSettingName.PrimaryResponseText
        )?.value || "Primary Response"
    let ableToPublish = false
    let ableToConnect = false
    if (currentUser?.role === SystemRole.ADMIN || !adminIsRequiredToPublish) {
        ableToPublish = true
    }
    if (currentUser?.role === SystemRole.ADMIN || !adminIsRequiredToConnect) {
        ableToConnect = true
    }
    const ableToCreate = !isNonMember

    return {
        settings,
        admins,
        loadingAdmins,
        loadingSettings,
        ableToPublish,
        ableToConnect,
        ableToCreate,
        primaryResponseText,
    }
}
