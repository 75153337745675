import React from "react"
import { Box, LinearProgress } from "@material-ui/core"
import { useReactiveVar } from "@apollo/client"

import {
    Concept,
    CriteriaScore,
    _ConceptFilter,
    _ConceptOrdering,
} from "../../../__generated__/types"
import {
    useTheme,
    withStyles,
    createStyles,
    Theme,
    alpha,
} from "@material-ui/core/styles"
import { myCurrentInputResponseLabelVar } from "../../../providers/GlobalState"
import { CollectionType, CollectionView } from "../../criteria/useCriteriaTypes"
import ConceptCardBaseBody from "../../ConceptCardBaseBody"
import { useHistory } from "react-router-dom"
import CollectionItem from "./CollectionItem"

import {
    CriteriaScoreInputVariables,
    ScoreSelectorDefaultValues,
} from "../types"
import ListView from "./ListView"
import ChipView from "./ChipView"
import { useParams } from "react-router-dom"
import GalleryView from "./GalleryView"
import TableView from "./TableView"
import useCollectionTools from "./useCollectionTools"
import { useAuth } from "../../../providers/AuthProvider"
import ResponsePreviewer from "../ResponsePreviewer"

interface CollectionResponseViewerProps {
    inputId: string
    config: CollectionType
    scores?: CriteriaScore[]
    conceptsScored?: Concept[]
    onCreateNewResponse: (
        data: CriteriaScoreInputVariables,
        scoredConceptId?: string
    ) => Promise<void>
    editing: boolean
}
const CollectionResponseViewer = (props: CollectionResponseViewerProps) => {
    const { isNonMember } = useAuth()
    const { scores, config, onCreateNewResponse, inputId, editing } = props
    const history = useHistory()
    const currentLabel = useReactiveVar(myCurrentInputResponseLabelVar)
    const viewingAllResponses =
        currentLabel.label === ScoreSelectorDefaultValues.allResponses &&
        !currentLabel.user

    if (!!viewingAllResponses) {
        return (
            <AllResponsesView
                inputId={inputId}
                scores={scores}
                config={config}
            />
        )
    } else {
        if (!!config.singleSelection) {
            const concept = scores?.[0]?.concepts?.[0]
            if (!!concept) {
                return (
                    <ConceptCardBaseBody
                        item={concept}
                        nonGrid={true}
                        hideAuthor={true}
                        onClick={
                            !isNonMember
                                ? (concept: Concept) =>
                                      history.push(
                                          `/concept/${concept.id}/home`
                                      )
                                : undefined
                        }
                    />
                )
            } else {
                return null
            }
        } else {
            const score = scores?.[0]
            return (
                <SingleResponseView
                    key={score?.lastUpdated?.formatted || score?.id}
                    score={score}
                    onCreateNewResponse={onCreateNewResponse}
                    inputId={inputId}
                    editingConfig={editing}
                    config={config}
                />
            )
        }
    }
}

const SingleResponseView = (props: {
    score: CriteriaScore
    onCreateNewResponse: (
        data: CriteriaScoreInputVariables,
        scoredConceptId?: string
    ) => Promise<void>
    config: CollectionType
    inputId: string
    editingConfig: boolean
}) => {
    const { score, config, inputId, editingConfig } = props

    const { conceptId } = useParams()

    const { state, onUpdateConceptOrder } = useCollectionTools(score, config)
    const currentLabel = useReactiveVar(myCurrentInputResponseLabelVar)

    const viewingPrimaryResponse =
        currentLabel.label === ScoreSelectorDefaultValues.primaryResponse &&
        !currentLabel.user

    const connectionFilter: _ConceptFilter = {
        AND: [
            {
                connections_some: {
                    id: conceptId,
                },
            },
            {
                id_not_in: state?.concepts?.map((concept) => concept.id) ?? [],
            },
            {
                category: {
                    id_in: config?.categories?.map((item) => item.id) ?? [],
                },
            },
        ],
    }

    return (
        <Box
            display="flex"
            flexDirection={"column"}
            overflow="hidden"
            height="100%"
        >
            {config.view === CollectionView.chip ? (
                <ChipView
                    fixedItems={state?.concepts}
                    skipQuery={
                        !!config.requirePrimaryResponse ||
                        (!viewingPrimaryResponse &&
                            !!config.allowMultipleResponses)
                    }
                    filter={connectionFilter}
                    orderBy={_ConceptOrdering.CREATEDAT_DESC}
                />
            ) : config.view === CollectionView.gallery ? (
                <GalleryView
                    fixedItems={state?.concepts}
                    skipQuery={
                        !!config.requirePrimaryResponse ||
                        (!viewingPrimaryResponse &&
                            !!config.allowMultipleResponses)
                    }
                    filter={connectionFilter}
                    orderBy={_ConceptOrdering.CREATEDAT_DESC}
                />
            ) : config.view === CollectionView.table ? (
                <TableView
                    inputId={inputId}
                    editingConfig={editingConfig}
                    editable={false}
                    config={config}
                    concepts={state?.concepts}
                    onChangeRowOrder={onUpdateConceptOrder}
                />
            ) : (
                <ListView
                    fixedItems={state?.concepts}
                    skipQuery={true}
                    filter={connectionFilter}
                    orderBy={_ConceptOrdering.CREATEDAT_DESC}
                />
            )}
        </Box>
    )
}

const AllResponsesView = (props: {
    scores: CriteriaScore[]
    config: CollectionType
    inputId: string
}) => {
    const { scores, config, inputId } = props
    const theme = useTheme()
    let unique = {}
    scores.map((score) => {
        score.concepts.map((concept) => {
            unique[concept.id] = {
                name: concept.title,
                concept: concept,
            }
            return unique
        })
        return unique
    })
    const cleanedDataObjects = Object.keys(unique)
        .map((key) => {
            return {
                ...unique[key],
                selections: scores?.filter(
                    (score) =>
                        !!score.concepts.find(
                            (concept) => concept.id === unique[key]?.concept?.id
                        )
                ),
            }
        })
        .sort((a, b) => {
            return b.selections.length - a.selections.length
        })

    return (
        <Box height="100%" width="100%" style={{ overflowY: "auto" }}>
            {cleanedDataObjects.map((item, index) => {
                return (
                    <Box key={item.concept.id} position="relative">
                        <Box
                            position="absolute"
                            right={theme.spacing(1)}
                            left={theme.spacing(1)}
                            bottom={0}
                            top={0}
                            display="flex"
                            alignItems={"center"}
                        >
                            <BorderLinearProgress
                                value={
                                    (item.selections.length /
                                        cleanedDataObjects[0]?.selections
                                            .length) *
                                    100
                                }
                                variant="determinate"
                            />
                        </Box>

                        <CollectionItem
                            item={item.concept}
                            singleSelection={config.singleSelection}
                            editable={false}
                            listItemSecondaryAction={
                                <ResponsePreviewer
                                    popper={true}
                                    inputId={inputId}
                                    scoredConceptId={item.concept.id}
                                />
                            }
                        />
                    </Box>
                )
            })}
        </Box>
    )
}

const BorderLinearProgress = withStyles((theme: Theme) =>
    createStyles({
        root: {
            height: "85%",
            flexGrow: 1,
            borderRadius: theme.shape.borderRadius,
        },
        colorPrimary: {
            backgroundColor: "transparent",
        },
        bar: {
            borderRadius: theme.shape.borderRadius,
            backgroundColor: alpha(theme.palette.primary.main, 0.15),
        },
    })
)(LinearProgress)

export default CollectionResponseViewer
