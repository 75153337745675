import React, { useRef } from "react"
import {
    Dialog,
    makeStyles,
    Divider,
    DialogContent,
    IconButton,
    Typography,
    Box,
    Slide,
    Tabs,
    Tab,
    CircularProgress,
    Grow,
    alpha,
} from "@material-ui/core"
import { ReactComponent as AmbleLogo } from "../../logo.svg"
import { useReactiveVar } from "@apollo/client"
import CheckCircleIcon from "@material-ui/icons/CheckCircle"
import DonutLargeIcon from "@material-ui/icons/DonutLarge"
import {
    expandWidgetVar,
    currentWorkspaceWidgetsVar,
    myCurrentInputResponseLabelVar,
    allPageConceptConnectionsVar,
    workspaceLoadTimeVar,
} from "../../providers/GlobalState"
import CheckIcon from "@material-ui/icons/Check"
import { useSwipeable } from "react-swipeable"
import { Concept } from "../../__generated__/types"
import { WidgetTypeName, useWidgetTypes } from "../dashboard/useWidgetTypes"
import ClearIcon from "@material-ui/icons/Clear"
import {
    getCleanedFieldConfig,
    getWorkspaceCompletionData,
} from "../inputs/util"
import Footer from "./Footer"
import useTheme from "@material-ui/core/styles/useTheme"
import useMediaQuery from "@material-ui/core/useMediaQuery"
import ResponseListItem from "../inputs/ResponseListItem"
import { ScoreSelectorDefaultValues } from "../inputs/types"
import { useAuth } from "../../providers/AuthProvider"
const useStyles = makeStyles((theme) => ({
    root: {
        overflowX: "hidden",
    },
    dialogContent: {
        padding: 0,
        "&:first-child": {
            paddingTop: 0,
        },
    },
    mini: {
        border: `1px solid ${theme.palette.divider}`,
        borderRadius: theme.shape.borderRadius,
        backgroundColor: theme.palette.background.paper,
    },
    chip: {
        borderRadius: theme.shape.borderRadius * 1,
        width: "12rem",
    },
    title: {
        width: "calc(100vw - 300px)",
    },
}))

interface PresentationModeProps {
    concept: Concept
}

const PresentationMode: React.FC<PresentationModeProps> = ({ concept }) => {
    const classes = useStyles()
    const theme = useTheme()
    const mobile = useMediaQuery(theme.breakpoints.down("sm"))
    const { widgetTypes } = useWidgetTypes()
    const slideDirection = useRef<"left" | "right">(null)
    const { isNonMember } = useAuth()
    const expandedWidgetObject = useReactiveVar(expandWidgetVar)
    const expandedWidgetId = expandedWidgetObject?.[concept.id] ?? null
    const { orderedWidgets: allOrderedWidgets } = useReactiveVar(
        currentWorkspaceWidgetsVar
    )
    const currentLabel = useReactiveVar(myCurrentInputResponseLabelVar)
    const viewingPrimaryResponse =
        currentLabel.label === ScoreSelectorDefaultValues.primaryResponse &&
        !currentLabel.user
    const viewingAllResponses =
        currentLabel.label === ScoreSelectorDefaultValues.allResponses &&
        !currentLabel.user
    const { connections } = useReactiveVar(allPageConceptConnectionsVar)
    const initialWorkspaceLoadTime = useReactiveVar(workspaceLoadTimeVar)

    const currentDialogWidget = allOrderedWidgets.find(
        (item) => item.id === expandedWidgetId
    )
    const currentField = currentDialogWidget?.fieldData
    const CurrentComponent = widgetTypes?.find(
        (type) => type.name === currentDialogWidget?.type
    )?.component
    const orderedPresentationWidgets = allOrderedWidgets.filter(
        (item) => item.type !== WidgetTypeName.Heading
    )

    const currentIndex = orderedPresentationWidgets?.findIndex(
        (item) => item.id === expandedWidgetId
    )

    const handleNextWidget = (widgetId?: string) => {
        slideDirection.current = "left"
        const end = currentIndex + 1 === orderedPresentationWidgets?.length
        if (end) {
            expandWidgetVar({
                ...expandedWidgetObject,
                [concept.id]: widgetId || orderedPresentationWidgets[0].id,
            })
        } else {
            expandWidgetVar({
                ...expandedWidgetObject,
                [concept.id]:
                    widgetId || orderedPresentationWidgets[currentIndex + 1].id,
            })
        }
    }
    const handleBackWidget = (widgetId?: string) => {
        slideDirection.current = "right"
        const end = currentIndex === 0
        if (end) {
            expandWidgetVar({
                ...expandedWidgetObject,
                [concept.id]:
                    widgetId ||
                    orderedPresentationWidgets[
                        orderedPresentationWidgets.length - 1
                    ]?.id,
            })
        } else {
            expandWidgetVar({
                ...expandedWidgetObject,
                [concept.id]:
                    widgetId ||
                    orderedPresentationWidgets[currentIndex - 1]?.id,
            })
        }
    }
    const handleClose = () => {
        expandWidgetVar({
            ...expandedWidgetObject,
            [concept.id]: "",
        })
    }
    const handlers = useSwipeable({
        onSwipedLeft: () => handleNextWidget(),
        onSwipedRight: () => handleBackWidget(),
        swipeDuration: 250,
        preventScrollOnSwipe: false,
    })
    const { percentComplete, orderedFields } = getWorkspaceCompletionData(
        allOrderedWidgets
            ?.filter((x) => !!x.fieldData)
            ?.map((o) => o.fieldData),
        currentLabel,
        connections,
        initialWorkspaceLoadTime
    )
    let scoreObject = {}
    currentField?.scores?.map(
        (score) =>
            (scoreObject[
                !!score?.conceptDefault
                    ? JSON.stringify(score.conceptDefault)
                    : score.label + score.user?.userId
            ] = score)
    )
    React.useEffect(() => {
        if (!currentDialogWidget?.id) {
            slideDirection.current = null
        }
    }, [currentDialogWidget?.id])
    if (!currentDialogWidget) {
        return null
    }
    return (
        <Dialog
            fullScreen={true}
            disableEnforceFocus={true}
            onClose={() =>
                expandWidgetVar({
                    ...expandedWidgetObject,
                    [concept.id]: "",
                })
            }
            open={!!currentDialogWidget}
            PaperProps={{
                classes: {
                    root: classes.root,
                },
            }}
            {...handlers}
        >
            <Box
                display="flex"
                alignItems={"center"}
                p={0.75}
                pb={0.5}
                flexWrap={"wrap"}
            >
                <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    width="100%"
                    p={1}
                    textAlign="center"
                    position="relative"
                >
                    {!mobile && (
                        <Box
                            textAlign={"center"}
                            position="absolute"
                            left={theme.spacing(2.5)}
                            top={theme.spacing(2.5)}
                        >
                            <AmbleLogo style={{ height: "30px" }} />
                        </Box>
                    )}
                    {mobile ? (
                        <Box
                            flexGrow={1}
                            textAlign="center"
                            p={0}
                            maxWidth={600}
                        >
                            <Typography
                                variant={"subtitle1"}
                                color="textSecondary"
                            >
                                {concept.title}
                            </Typography>
                        </Box>
                    ) : (
                        <Box
                            textAlign="center"
                            p={1.5}
                            className={classes.title}
                        >
                            <Typography variant={"h6"} color="textSecondary">
                                {concept.title}
                            </Typography>
                        </Box>
                    )}
                    {!mobile && (
                        <Box
                            position="absolute"
                            right={theme.spacing(4)}
                            top={theme.spacing(2)}
                        >
                            <ResponseListItem
                                item={currentLabel}
                                isConceptDefault={!!viewingPrimaryResponse}
                            />
                        </Box>
                    )}
                </Box>
                {!isNonMember && (
                    <IconButton
                        style={{
                            position: "absolute",
                            right: 0,
                            top: 0,
                        }}
                        onClick={() =>
                            expandWidgetVar({
                                ...expandedWidgetObject,
                                [concept.id]: "",
                            })
                        }
                        size={mobile ? "small" : "medium"}
                    >
                        <ClearIcon />
                    </IconButton>
                )}
            </Box>

            <Box display="flex" alignItems="center">
                {mobile ? (
                    <Box ml={2} flexGrow={1} py={1}>
                        <ResponseListItem
                            item={currentLabel}
                            isConceptDefault={!!viewingPrimaryResponse}
                        />
                    </Box>
                ) : (
                    <Box
                        flexGrow={1}
                        overflow="hidden"
                        borderTop={`1px solid ${theme.palette.divider}`}
                    >
                        <Tabs
                            value={expandedWidgetId}
                            scrollButtons="auto"
                            variant="scrollable"
                        >
                            {orderedPresentationWidgets?.map((item, index) => {
                                let label
                                let complete = true
                                if (item.type === WidgetTypeName.Criteria) {
                                    const config = getCleanedFieldConfig(
                                        item.fieldData
                                    )
                                    const isSubfield =
                                        config?.source?.criteriaIds?.length > 0
                                    complete = orderedFields.find(
                                        (field) =>
                                            field.input.id ===
                                            item.fieldData?.input.id
                                    )?.isCompleted
                                    const statusIcon =
                                        !viewingPrimaryResponse &&
                                        !config.allowMultipleResponses ? null : (
                                            <Box
                                                display="flex"
                                                alignItems="center"
                                                mr={1}
                                            >
                                                {!!complete ? (
                                                    <CheckCircleIcon
                                                        fontSize={"small"}
                                                        color="primary"
                                                    />
                                                ) : (
                                                    <DonutLargeIcon
                                                        fontSize={"small"}
                                                        color="secondary"
                                                    />
                                                )}
                                            </Box>
                                        )
                                    if (!!isSubfield) {
                                        const parentField = orderedFields.find(
                                            (field) =>
                                                !!config?.source?.criteriaIds?.find(
                                                    (item) =>
                                                        item.id ===
                                                        field.input?.criteria
                                                            ?.id
                                                )
                                        )
                                        if (!!parentField) {
                                            const cleanedParentConfig =
                                                getCleanedFieldConfig(
                                                    parentField
                                                )

                                            label = (
                                                <Box
                                                    display="flex"
                                                    alignItems="center"
                                                >
                                                    {!viewingAllResponses &&
                                                        statusIcon}
                                                    <Box>
                                                        <Typography
                                                            variant="caption"
                                                            color="textSecondary"
                                                            style={{
                                                                textTransform:
                                                                    "none",
                                                            }}
                                                        >
                                                            {
                                                                cleanedParentConfig?.name
                                                            }
                                                        </Typography>
                                                        <Typography
                                                            variant="body2"
                                                            style={{
                                                                textTransform:
                                                                    "none",
                                                            }}
                                                        >
                                                            {config?.name || ""}
                                                        </Typography>
                                                    </Box>
                                                </Box>
                                            )
                                        }
                                    } else {
                                        label = (
                                            <Box
                                                display="flex"
                                                alignItems="center"
                                            >
                                                {!viewingAllResponses &&
                                                    statusIcon}

                                                <Typography
                                                    variant="body2"
                                                    style={{
                                                        textTransform: "none",
                                                    }}
                                                >
                                                    {config?.name || ""}
                                                </Typography>
                                            </Box>
                                        )
                                    }
                                } else {
                                    label = (
                                        <Typography
                                            variant="body2"
                                            style={{
                                                textTransform: "none",
                                            }}
                                        >
                                            {JSON.parse(item.config)?.label}
                                        </Typography>
                                    )
                                }

                                return (
                                    <Tab
                                        onClick={() => {
                                            if (index > currentIndex) {
                                                handleNextWidget(item.id)
                                            } else {
                                                handleBackWidget(item.id)
                                            }
                                        }}
                                        label={label}
                                        value={item.id}
                                        key={item.id}
                                        style={{
                                            backgroundColor: alpha(
                                                theme.palette.primary.main,
                                                0.1
                                            ),

                                            borderRight:
                                                index ===
                                                orderedPresentationWidgets?.length
                                                    ? "none"
                                                    : `1px solid ${theme.palette.divider}`,
                                        }}
                                    />
                                )
                            })}
                        </Tabs>
                    </Box>
                )}
                {!viewingAllResponses && (
                    <Box
                        display="flex"
                        height="100%"
                        borderTop={
                            mobile
                                ? "none"
                                : `1px solid ${theme.palette.divider}`
                        }
                        px={2}
                    >
                        <Box
                            position="relative"
                            display="inline-flex"
                            alignItems={"center"}
                        >
                            <Box display="flex">
                                <CircularProgress
                                    size={40}
                                    value={percentComplete}
                                    variant="determinate"
                                />
                                <CircularProgress
                                    size={40}
                                    style={{
                                        position: "absolute",
                                        color: theme.palette.divider,
                                    }}
                                    value={100}
                                    variant="determinate"
                                />
                            </Box>

                            <Box
                                style={{
                                    top: 0,
                                    left: 0,
                                    bottom: 0,
                                    right: 0,
                                    position: "absolute",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                }}
                            >
                                <Grow mountOnEnter unmountOnExit in={true}>
                                    {percentComplete === 100 ? (
                                        <CheckIcon fontSize="small" />
                                    ) : (
                                        <Typography
                                            variant="caption"
                                            component="div"
                                            color="textSecondary"
                                        >{`${Math.round(
                                            isNaN(percentComplete)
                                                ? 0
                                                : percentComplete
                                        )}%`}</Typography>
                                    )}
                                </Grow>
                            </Box>
                        </Box>
                    </Box>
                )}
            </Box>
            <Divider />
            <Slide
                in={true}
                direction={slideDirection.current ?? "left"}
                timeout={!slideDirection.current ? 0 : 250}
                key={expandedWidgetId}
            >
                <DialogContent className={classes.dialogContent}>
                    {!!CurrentComponent && (
                        <CurrentComponent
                            widget={currentDialogWidget}
                            editing={false}
                            isDialog={true}
                            concept={concept}
                            index={currentIndex}
                        />
                    )}
                </DialogContent>
            </Slide>
            <Divider />
            <Footer
                currentIndex={currentIndex}
                onNext={handleNextWidget}
                onClose={handleClose}
                onBack={handleBackWidget}
                total={orderedPresentationWidgets?.length || 0}
            />
        </Dialog>
    )
}

export default PresentationMode
