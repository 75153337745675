import { Avatar, Box } from "@material-ui/core"
import { useTheme } from "@material-ui/core/styles"
import Person from "@material-ui/icons/Person"
import { User } from "../../__generated__/types"

const UserAvatar = (props: {
    user?: User
    size?: "small" | "medium" | "large"
    variant: "circle" | "rounded"
}) => {
    const { user, size, variant } = props
    const theme = useTheme()

    let avatarStyles = {}

    if (!!size) {
        if (size === "small") {
            avatarStyles = {
                width: theme.spacing(3),
                height: theme.spacing(3),
            }
        } else if (size === "medium") {
            avatarStyles = {
                width: theme.spacing(4),
                height: theme.spacing(4),
            }
        }
    }
    return (
        <Avatar
            style={{
                color: theme.palette.text.secondary,
                ...avatarStyles,
            }}
            variant={variant || "rounded"}
            src={user?.imageUrl}
        >
            <Box>
                {!!user ? (
                    `${user.firstName?.[0] + user.lastName?.[0]}`
                ) : (
                    <Person />
                )}
            </Box>
        </Avatar>
    )
}

export default UserAvatar
