import React, { useRef } from "react"
import { Divider, Paper, Theme } from "@material-ui/core"
import Box from "@material-ui/core/Box"
import { makeStyles, useTheme } from "@material-ui/core/styles"
import { Palette } from "@material-ui/core/styles/createPalette"

import ArrowRightAltIcon from "@material-ui/icons/ArrowRightAlt"
import {
    Area,
    AreaChart,
    Line,
    LineChart,
    ReferenceDot,
    ReferenceLine,
    ResponsiveContainer,
    Tooltip,
    XAxis,
    YAxis,
} from "recharts"
import { CurveData, AdoptionData } from "./line-data/HypeChartData"
import useAwaitTranslation from "../../i18n/useAwaitTranslation"
import { CriteriaScore } from "../../__generated__/types"
import ConceptAvatar from "../ConceptAvatar"
import ChevronRight from "@material-ui/icons/ChevronRight"
import useEnvironmentSettingTools from "../settings/useEnvironmentSettingTools"
import ResponseListItem from "../inputs/ResponseListItem"
const useStyles = makeStyles((theme: Theme) => ({
    flex: {
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-around",
        width: "100%",
    },
    yAxis: {
        writingMode: "vertical-lr",
        textOrientation: "sideways",
        transform: "rotate(180deg)",
        display: "flex",
        justifyContent: "center",
        width: "20px",
        fontSize: 12,
        color: theme.palette.text.hint,
    },
    timeContainer: {
        fontSize: 12,
        color: theme.palette.text.hint,
        textAlign: "center",
        marginBottom: "5px",
    },
    clickable: {
        cursor: "crosshair !important",
    },
}))

interface HypeChartProps {
    scores: CriteriaScore[]
    onEditResponse?: (value: number) => void
}
type PositionObject = {
    x: number
    y: number
    id: string
    primary: boolean
}

function HypeChartInterface(props: HypeChartProps) {
    const { scores, onEditResponse } = props
    const theme = useTheme()
    const classes = useStyles()
    const { t } = useAwaitTranslation("trends")

    const sizeRef = useRef(null)
    const multipleConceptsEvaluated =
        scores
            ?.map((score) => score?.scoredConcept?.id)
            .filter((value, index, self) => self.indexOf(value) === index)
            ?.length > 1

    //functions
    const handleClick = (data: { activeLabel: number }) => {
        if (
            !!data?.activeLabel &&
            !!onEditResponse &&
            !isNaN(data.activeLabel)
        ) {
            onEditResponse(data.activeLabel)
        }
    }
    let curveData = [...CurveData].map((item) => ({ ...item, dataPoints: {} }))
    let adoptionData = [...AdoptionData].map((item) => ({
        ...item,
        dataPoints: {},
    }))
    let expectationsArray: PositionObject[] = []
    let adoptionArray: PositionObject[] = []

    scores
        ?.filter((score) => !!score?.response)
        .forEach((score) => {
            let matchingExpectationPoint = curveData.find(
                (item) => item.number === Number(score?.response)
            )
            let matchingAdoptionPoint = adoptionData.find(
                (item) => item.number === Number(score?.response)
            )
            if (!!matchingAdoptionPoint && !!matchingExpectationPoint) {
                matchingExpectationPoint.dataPoints[score?.id] = {
                    score: score,
                    concept: !!multipleConceptsEvaluated
                        ? score.scoredConcept
                        : null,
                    position: matchingExpectationPoint.number,
                }
                expectationsArray.push({
                    id: score?.id,
                    x: Number(score?.response),
                    y: matchingExpectationPoint?.hypeLine ?? 0,
                    primary: !!score.conceptDefault,
                })
                adoptionArray.push({
                    id: score?.id,
                    x: Number(score?.response),
                    y: matchingAdoptionPoint?.hypeLine ?? 0,
                    primary: !!score.conceptDefault,
                })
            }
        })

    return (
        <div className={classes.flex}>
            <div
                style={{
                    width: "100%",
                    height: "100%",
                    overflow: "hidden",
                }}
                ref={sizeRef}
            >
                <ResponsiveContainer height={"100%"} width="100%">
                    <LineChart
                        // @ts-ignore
                        onClick={handleClick}
                        data={curveData}
                        syncId="hype-interface"
                        style={{
                            cursor: !!onEditResponse ? "crosshair" : "default",
                        }}
                        margin={{
                            right: theme.spacing(2),
                            left: theme.spacing(3),
                            top: theme.spacing(2),
                        }}
                    >
                        <XAxis
                            hide={true}
                            orientation="top"
                            xAxisId={0}
                            type="number"
                            dataKey="number"
                            interval="preserveEnd"
                            domain={[0, 100]}
                        />
                        <XAxis
                            orientation="bottom"
                            xAxisId={1}
                            type="number"
                            dataKey="number"
                            ticks={[5, 20, 30, 45, 75]}
                            tick={
                                <ExpectationsTick
                                    width={sizeRef?.current?.clientWidth}
                                />
                            }
                            interval="preserveEnd"
                            domain={[0, 100]}
                        />
                        <YAxis
                            width={theme.spacing(4)}
                            tick={false}
                            dataKey="hypeLine"
                            domain={[0, 2000]}
                            label={{
                                value: t(
                                    "hypeChart.expectations",
                                    "Expectations"
                                ),
                                position: "outsideLeft",
                                angle: -90,
                                //offset: -theme.spacing(3),
                                fill: theme.palette.text.primary,
                            }}
                        />
                        <Tooltip
                            wrapperStyle={{
                                fill: theme.palette.background.paper,
                                zIndex: theme.zIndex.tooltip,
                            }}
                            content={<CustomTooltip palette={theme.palette} />}
                        />
                        <Line
                            type="basis"
                            dot={false}
                            dataKey="hypeLine"
                            stroke={theme.palette.primary.main}
                            fill={theme.palette.primary.main}
                        />

                        {expectationsArray.map((item, idx) => {
                            const key = idx.toString() + "expectations"

                            return (
                                <ReferenceDot
                                    key={key}
                                    fill={
                                        !!item.primary
                                            ? theme.palette.primary.main
                                            : theme.palette.secondary.main
                                    }
                                    style={{
                                        cursor: !!onEditResponse
                                            ? "pointer"
                                            : "default",
                                    }}
                                    isFront={!!item.primary}
                                    r={5}
                                    xAxisId={0}
                                    x={item.x}
                                    y={item.y}
                                />
                            )
                        })}
                    </LineChart>
                </ResponsiveContainer>
            </div>
            <div
                style={{
                    width: "100%",
                    height: "100%",
                    overflow: "hidden",
                }}
            >
                <ResponsiveContainer height={"100%"} width="100%">
                    <AreaChart
                        data={adoptionData}
                        syncId="hype-interface"
                        style={{
                            cursor: !!onEditResponse ? "crosshair" : "default",
                        }}
                        margin={{
                            top: theme.spacing(1),
                            right: theme.spacing(2),
                            left: theme.spacing(3),
                            bottom: theme.spacing(5),
                        }}
                        // @ts-ignore
                        onClick={handleClick}
                    >
                        <YAxis
                            tick={false}
                            dataKey="hypeLine"
                            domain={[0, 4]}
                            width={theme.spacing(4)}
                            label={{
                                value: t(
                                    "hypeChart.adoptionRate",
                                    "Adoption Rate"
                                ),
                                position: "outsideLeft",
                                angle: -90,

                                fill: theme.palette.text.primary,
                            }}
                        />
                        <Tooltip
                            wrapperStyle={{
                                fill: theme.palette.background.paper,
                                zIndex: theme.zIndex.tooltip,
                            }}
                            content={<CustomTooltip palette={theme.palette} />}
                        />
                        <XAxis
                            dataKey="number"
                            ticks={[10, 30, 50, 70, 90]}
                            domain={[0, 100]}
                            tick={
                                <AdoptionTick
                                    width={sizeRef?.current?.clientWidth}
                                />
                            }
                            label={{
                                value: t("hypeChart.time", "Time"),
                                position: "insideBottom",
                                offset: -theme.spacing(3),
                                fill: theme.palette.text.primary,
                            }}
                        />
                        <Area
                            type="monotone"
                            dataKey="hypeLine"
                            stroke={theme.palette.primary.main}
                            fill={theme.palette.primary.main}
                        />
                        {adoptionArray?.map((item, idx) => {
                            const key = idx.toString() + "adoption"
                            return (
                                <ReferenceDot
                                    key={key}
                                    style={{
                                        cursor: !!onEditResponse
                                            ? "pointer"
                                            : "default",
                                    }}
                                    fill={
                                        !!item.primary
                                            ? theme.palette.primary.main
                                            : theme.palette.secondary.main
                                    }
                                    r={5}
                                    isFront={!!item.primary}
                                    xAxisId={0}
                                    x={item.x}
                                    y={item.y}
                                />
                            )
                        })}
                        <ReferenceLine
                            strokeOpacity={0.5}
                            stroke={theme.palette.text.hint}
                            segment={[
                                { x: 20, y: 0 },
                                {
                                    x: 20,
                                    y: adoptionData[20].hypeLine,
                                },
                            ]}
                        />
                        <ReferenceLine
                            strokeOpacity={0.5}
                            stroke={theme.palette.text.hint}
                            segment={[
                                { x: 40, y: 0 },
                                {
                                    x: 40,
                                    y: adoptionData[40].hypeLine,
                                },
                            ]}
                        />
                        <ReferenceLine
                            strokeOpacity={0.5}
                            stroke={theme.palette.text.hint}
                            segment={[
                                { x: 60, y: 0 },
                                {
                                    x: 60,
                                    y: adoptionData[60].hypeLine,
                                },
                            ]}
                        />
                        <ReferenceLine
                            strokeOpacity={0.5}
                            stroke={theme.palette.text.hint}
                            segment={[
                                { x: 80, y: 0 },
                                {
                                    x: 80,
                                    y: adoptionData[80].hypeLine,
                                },
                            ]}
                        />
                        <ReferenceLine
                            strokeOpacity={0.5}
                            stroke={theme.palette.text.hint}
                            segment={[
                                { x: 100, y: 0 },
                                {
                                    x: 100,
                                    y: adoptionData[100].hypeLine,
                                },
                            ]}
                        />
                    </AreaChart>
                </ResponsiveContainer>
            </div>
        </div>
    )
}
const CustomTooltip = (props: {
    payload?: any
    palette?: Palette
    active?: boolean
}) => {
    const { active, payload } = props
    const { t } = useAwaitTranslation("trends")
    const { primaryResponseText } = useEnvironmentSettingTools()
    const data = payload[0]?.payload?.dataPoints

    if (active && payload) {
        if (data && Object.keys(data).length > 0) {
            let topLabel = ""
            const xValue = payload[0].payload.number
            if (xValue < 17) {
                topLabel = t(
                    "hypeChart.innovationTrigger",
                    "Innovation Trigger"
                )
            } else if (xValue >= 17 && xValue <= 23) {
                topLabel = t(
                    "hypeChart.peakOfInflatedExpectations",
                    "Peak of Inflated Expectations"
                )
            } else if (xValue >= 24 && xValue <= 35) {
                topLabel = t(
                    "hypeChart.troughOfDisillusionment",
                    "Trough of Disillusionment"
                )
            } else if (xValue >= 36 && xValue <= 65) {
                topLabel = t(
                    "hypeChart.slopeOfEnlightment",
                    "Slope of Enlightenment"
                )
            } else {
                topLabel = t(
                    "hypeChart.plateauOfProductivity",
                    "Plateau of Productivity"
                )
            }

            let bottomLabel = ""
            if (xValue <= 20) {
                bottomLabel = t("hypeChart.innovators", "Innovators")
            } else if ((xValue) => 20 && xValue < 40) {
                bottomLabel = t("hypeChart.earlyAdopters", "Early Adopters")
            } else if ((xValue) => 40 && xValue < 60) {
                bottomLabel = t("hypeChart.earlyMajority", "Early Majority")
            } else if ((xValue) => 60 && xValue < 80) {
                bottomLabel = t("hypeChart.lateMajority", "Late Majority")
            } else {
                bottomLabel = t("hypeChart.laggards", "Laggards")
            }

            return (
                <Paper elevation={12}>
                    {Object.keys(data).map((dataPoint, idx) => {
                        const point = data[dataPoint]

                        return (
                            <Box
                                key={idx}
                                p={0.5}
                                display="flex"
                                alignItems="center"
                            >
                                <Box
                                    display="flex"
                                    alignItems="center"
                                    padding={0.5}
                                >
                                    <ResponseListItem
                                        item={{
                                            label: point.score.label,
                                            user: point.score.user,
                                        }}
                                        isConceptDefault={
                                            point.score.conceptDefault
                                        }
                                    />
                                </Box>

                                {!!point?.concept && (
                                    <>
                                        <ChevronRight
                                            fontSize="medium"
                                            color="action"
                                        />
                                        <Box
                                            display="flex"
                                            alignItems="center"
                                            padding={0.5}
                                        >
                                            <ConceptAvatar
                                                concept={point.concept}
                                                size="large"
                                                variant="rounded"
                                            />
                                            <Box ml={0.5}>
                                                {point.concept.title}
                                            </Box>
                                        </Box>
                                    </>
                                )}
                            </Box>
                        )
                    })}
                    <Divider />
                    <Box p={0.5}>
                        <Box padding={0.5} display="flex" alignItems="center">
                            {t("hypeChart.expectations", "Expectations")}{" "}
                            <ArrowRightAltIcon /> {topLabel}
                        </Box>
                        <Box padding={0.5} display="flex" alignItems="center">
                            {t("hypeChart.adoptionRate", "Adoption Rate")}{" "}
                            <ArrowRightAltIcon /> {bottomLabel}
                        </Box>
                    </Box>
                </Paper>
            )
        } else {
            return null
        }
    } else {
        return null
    }
}

const ExpectationsTick = (props) => {
    const { x, y, payload, width } = props
    const { t } = useAwaitTranslation("trends")

    const tickObject = {
        5: t("hypeChart.innovationTrigger", "Innovation Trigger"),
        20: t(
            "hypeChart.peakOfInflatedExpectations",
            "Peak of Inflated Expectations"
        ),
        30: t("hypeChart.troughOfDisillusionment", "Trough of Disillusionment"),
        45: t("hypeChart.slopeOfEnlightment", "Slope of Enlightenment"),
        75: t("hypeChart.plateauOfProductivity", "Plateau of Productivity"),
    }
    const hideableTicks = [
        t(
            "hypeChart.peakOfInflatedExpectations",
            "Peak of Inflated Expectations"
        ),
        t("hypeChart.slopeOfEnlightment", "Slope of Enlightenment"),
    ]
    let content = ""

    if (
        width < 750 &&
        hideableTicks.indexOf(tickObject[payload.value]) !== -1
    ) {
        content = ""
    } else {
        content = tickObject[payload.value]
    }

    return (
        <foreignObject x={x - 15} y={y - 5} width="85" height="150">
            <div style={{ fontSize: width < 650 ? "8px" : "10px" }}>
                {content}
            </div>
        </foreignObject>
    )
}
const AdoptionTick = (props) => {
    const { x, y, payload, width } = props

    const { t } = useAwaitTranslation("trends")
    const tickObject = {
        0: "",
        10: t("hypeChart.innovators", "Innovators"),
        20: "",
        30: t("hypeChart.earlyAdopters", "Early Adopters"),
        40: "",
        50: t("hypeChart.earlyMajority", "Early Majority"),
        60: "",
        70: t("hypeChart.lateMajority", "Late Majority"),
        80: "",
        90: t("hypeChart.laggards", "Laggards"),
        100: "",
    }
    const hideableTicks = [
        t("hypeChart.earlyAdopters", "Early Adopters"),
        t("hypeChart.lateMajority", "Late Majority"),
    ]
    let content: string = ""
    if (
        width < 750 &&
        hideableTicks.indexOf(tickObject[payload.value]) !== -1
    ) {
        content = ""
    } else {
        content = tickObject[payload.value]
    }
    return (
        <foreignObject x={x - 20} y={y - 5} width="85" height="150">
            <div style={{ fontSize: width < 650 ? "8px" : "10px" }}>
                {content}
            </div>
        </foreignObject>
    )
}

export default React.memo(HypeChartInterface)
