import { TableRow } from "@material-ui/core"

import {
    withStyles,
    Theme,
    createStyles,
    alpha,
} from "@material-ui/core/styles"

export const StyledTableRow = withStyles((theme: Theme) =>
    createStyles({
        root: {
            "&:nth-of-type(odd)": {
                backgroundColor: alpha(theme.palette.primary.main, 0.05),
            },
        },
    })
)(TableRow)
