import React, { useState } from "react"
import { makeStyles, useTheme } from "@material-ui/core/styles"
import {
    Avatar,
    Box,
    Divider,
    Link,
    Hidden,
    Typography,
    Tooltip,
    IconButton,
} from "@material-ui/core"
import Card from "@material-ui/core/Card"
import CardContent from "@material-ui/core/CardContent"
import CardMedia from "@material-ui/core/CardMedia"
import DetailsField from "./DetailsField"
import ImageUploadDialog from "./images/ImageUploadDialog"
import IndustryList from "./industries/IndustryList"
import { ConceptFieldSettings } from "../util/SystemSettings"
import useAwaitTranslation from "../i18n/useAwaitTranslation"
import { UPDATE_CONCEPT } from "../graphql/mutations"
import InsertPhotoIcon from "@material-ui/icons/InsertPhoto"
import {
    UpdateConceptMutation,
    UpdateConceptMutationVariables,
} from "../graphql/__generated__/mutations"
import dayjs from "dayjs"
import { getGermanTimeAgo } from "../util/fns"
import { CONCEPT_BY_ID } from "../graphql/queries"
import { useMutation, useReactiveVar } from "@apollo/client"
import { Concept } from "../__generated__/types"
import { useAuth } from "../providers/AuthProvider"
import TagToggle from "./tags/TagToggle"
import { userConceptPermissionsVar } from "../providers/GlobalState"
import ConceptDescription from "./ConceptDescription"
import ConceptDetailsHeaderOptions from "./ConceptDetailsHeaderOptions"
import ConceptWebLinkEditor from "./ConceptWebLinkEditor"
import { useHistory } from "react-router-dom"
import ConceptStatus from "./workflows/ConceptStatus"
import ConceptCategory from "./categories/ConceptCategory"
import AccountTreeRounded from "@material-ui/icons/AccountTreeRounded"
import useConceptTools from "../util/useConceptTools"
import FuzzyConceptSearch from "./FuzzyConceptSearch"
import Close from "@material-ui/icons/Close"
import { getSummaryPrompt, getTitlePrompt } from "./GenerateAI"
const useStyles = makeStyles((theme) => ({
    card: {
        display: "flex",
        flexDirection: "row",
        [theme.breakpoints.down("xs")]: {
            flexDirection: "column",
        },
    },
    authorContainer: {
        display: "flex",
        alignItems: "center",
        color: theme.palette.text.secondary,
        height: "100%",
        marginTop: theme.spacing(1),
        fontSize: "12px",
    },
    titleContainer: {
        [theme.breakpoints.down("md")]: {
            margin: "auto",
            marginTop: "1em",
        },
    },

    smallMedia: {
        minWidth: "125px",
        maxWidth: "75%",
        minHeight: "125px",
        height: "125px",
        margin: theme.spacing(3),
        marginTop: theme.spacing(1),
        borderRadius: theme.shape.borderRadius,
        marginRight: theme.spacing(1),
        [theme.breakpoints.down("xs")]: {
            margin: "auto",
            marginTop: theme.spacing(2),
        },
    },
    cardContent: {
        width: "100%",
        [theme.breakpoints.down("sm")]: {
            paddingTop: 0,
        },
    },
    avatar: {
        height: theme.spacing(4),
        width: theme.spacing(4),
        marginRight: "7.5px",
    },
    bottomRow: {
        display: "flex",
        [theme.breakpoints.down("xs")]: {
            flexDirection: "column",
        },
        borderTop: `1px solid ${theme.palette.divider}`,
    },
    topBar: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        width: "100%",
        padding: theme.spacing(1),
        paddingBottom: 0,
        alignItems: "center",
    },
    conceptDates: {
        display: "flex",
        alignItems: "center",
        [theme.breakpoints.down("xs")]: {
            margin: theme.spacing(1, 0),
        },
        flexShrink: 0,
    },

    subtitle: {
        fontSize: 12,
        color: theme.palette.text.secondary,
        display: "flex",
        alignItems: "center",
        whiteSpace: "nowrap",
    },
    stickyHeader: {
        position: "fixed",
        width: "100%",
        top: theme.spacing(12),
        zIndex: theme.zIndex.appBar - 1,
        //@ts-ignore
        backgroundColor: theme.palette.background.level2,
    },
}))

interface ConceptHeaderProps {
    item: Concept
    editing: boolean
    preview?: boolean
    setOpenTranslationsModal?: (boolean) => void
    openTranslationsModal?: boolean
}

function ConceptDetailsHeader(props: ConceptHeaderProps) {
    //hooks
    const classes = useStyles()
    const { currentUser } = useAuth()
    const { addConceptParent, removeConceptParent } = useConceptTools()

    const { t, i18n } = useAwaitTranslation("concepts")
    const history = useHistory()
    const theme = useTheme()
    //mutations
    const [updateConcept] = useMutation<
        UpdateConceptMutation,
        UpdateConceptMutationVariables
    >(UPDATE_CONCEPT, {
        refetchQueries: [
            {
                query: CONCEPT_BY_ID,
                variables: { id: props.item.id },
            },
        ],
    })

    //globalState
    const ownerPermission = useReactiveVar(userConceptPermissionsVar)
        .owningPermission

    //state
    const [open, setOpen] = useState(false)
    const [editingParent, setEditingParent] = useState(false)

    const parentEditModeActive = props.editing && editingParent

    //functions
    const handleEdit = (field: string, value: string) => {
        let valObject = {
            userId: currentUser.userId,
            concept: {
                id: props.item.id,
            },
        }
        valObject.concept[field] = value
        if (props.item[field] !== value) {
            updateConcept({
                variables: {
                    ...valObject,
                },
            })
        }
    }

    const handleUserClick = (event) => {
        event.stopPropagation()
        history.push(`/user/${props.item.createdBy?.username}/home`)
    }

    const handleParentClick = (event) => {
        event.stopPropagation()
        props.editing
            ? setEditingParent(!editingParent)
            : history.push(`/concept/${props.item.parent?.id}/home`)
    }
    const removeParent = async () => {
        if (props.item?.parent?.id) {
            await removeConceptParent({
                variables: {
                    childId: props.item?.id,
                    parentId: props.item?.parent?.id,
                },
            })
        }
    }

    const handleParentSelection = async (concept) => {
        if (concept) {
            addConceptParent({
                variables: {
                    childId: props.item?.id,
                    parentId: concept.id,
                },
            })
        }
    }
    const selectedLangString = i18n?.language
    const translatedContent = props.item.translations.filter(
        (t) => t.languageString === selectedLangString
    )[0]

    return (
        <>
            <Card
                elevation={!!props.preview ? 0 : 1}
                style={{
                    overflow: "hidden",
                }}
            >
                <Box
                    display="flex"
                    width="100%"
                    flexWrap={parentEditModeActive ? "wrap" : "nowrap"}
                >
                    {parentEditModeActive && (
                        <Box width={"100%"}>
                            {props.item.parent?.title ? (
                                <Box
                                    display="flex"
                                    alignItems="center"
                                    px={2}
                                    mb={-1}
                                >
                                    <Typography
                                        color="textSecondary"
                                        variant="body2"
                                    >
                                        {props.item.parent?.title}
                                    </Typography>
                                    <Tooltip title="Remove Parent">
                                        <IconButton onClick={removeParent}>
                                            <Close
                                                fontSize="small"
                                                color="inherit"
                                            />
                                        </IconButton>
                                    </Tooltip>
                                </Box>
                            ) : (
                                <FuzzyConceptSearch
                                    onSelection={handleParentSelection}
                                    placeholder="Search to add parent"
                                />
                            )}
                        </Box>
                    )}
                    <Box key={props.item.id} className={classes.topBar}>
                        {(props.item.parent?.title || props.editing) && (
                            <Tooltip
                                title={
                                    props.editing
                                        ? t("editParent", "Edit Parent")
                                        : props.item.parent?.title
                                }
                            >
                                <IconButton
                                    onClick={handleParentClick}
                                    style={{
                                        backgroundColor:
                                            parentEditModeActive &&
                                            theme.palette.action.hover,
                                    }}
                                >
                                    <AccountTreeRounded
                                        fontSize="small"
                                        color="inherit"
                                    />
                                </IconButton>
                            </Tooltip>
                        )}
                        <ConceptCategory
                            editable={props.editing}
                            concept={props.item}
                        />

                        <ConceptStatus
                            concept={props.item}
                            editing={props.editing}
                        />
                        {props.editing && !!ownerPermission && (
                            <ConceptDetailsHeaderOptions item={props.item} />
                        )}
                    </Box>
                </Box>

                <Box
                    display="flex"
                    flexDirection={"column"}
                    className={classes.card}
                    alignItems={"center"}
                >
                    {(!!props.editing || !!props.item?.imageUrl) && (
                        <CardMedia
                            className={classes.smallMedia}
                            style={{
                                cursor: "pointer",
                                backgroundColor: !props.item?.imageUrl
                                    ? theme.palette.type === "dark"
                                        ? theme.palette.grey["A700"]
                                        : theme.palette.grey["300"]
                                    : "inherit",
                            }}
                            onClick={() => setOpen(true)}
                            image={props.item.imageUrl}
                        >
                            {!props.item.imageUrl && (
                                <Box
                                    display="flex"
                                    flexDirection={"column"}
                                    alignItems="center"
                                    justifyContent={"center"}
                                    height="100%"
                                    width="100%"
                                >
                                    <InsertPhotoIcon
                                        fontSize="large"
                                        color="disabled"
                                    />
                                    <Box>
                                        <Typography
                                            variant="caption"
                                            color="textSecondary"
                                        >
                                            {t("addImage", "Add Image")}
                                        </Typography>
                                    </Box>
                                </Box>
                            )}
                        </CardMedia>
                    )}
                    <CardContent
                        style={{
                            paddingTop: !!props.preview ? 0 : theme.spacing(3),
                        }}
                        className={classes.cardContent}
                    >
                        <Box
                            display="flex"
                            justifyContent="space-between"
                            height="100%"
                            flexDirection="column"
                        >
                            <Box pl={0.5}>
                                <Box className={classes.titleContainer}>
                                    <DetailsField
                                        editable={props.editing}
                                        placeholder={t("title", "Title")}
                                        className={
                                            !!props.preview ? "body1" : "h6"
                                        }
                                        handleEdit={(value) => {
                                            handleEdit("title", value)
                                        }}
                                        value={
                                            translatedContent?.title ||
                                            props.item.title
                                        }
                                        maxLength={
                                            ConceptFieldSettings.Title.maxLength
                                        }
                                        multiline={true}
                                        setOpenTranslationsModal={
                                            props.setOpenTranslationsModal
                                        }
                                        aiPrompt={getTitlePrompt(
                                            props.item.title,
                                            props.item.category?.name
                                        )}
                                    />
                                </Box>
                                {(!!props.editing ||
                                    translatedContent?.summary ||
                                    props.item.summary) && (
                                    <Box mt={1}>
                                        <DetailsField
                                            editable={props.editing}
                                            placeholder={t(
                                                "summary",
                                                "Summary"
                                            )}
                                            multiline={true}
                                            className={
                                                !!props.preview
                                                    ? "body2"
                                                    : "body1"
                                            }
                                            handleEdit={(value) => {
                                                handleEdit("summary", value)
                                            }}
                                            value={
                                                translatedContent?.summary ||
                                                props.item.summary ||
                                                ""
                                            }
                                            maxLength={
                                                ConceptFieldSettings.Summary
                                                    .maxLength
                                            }
                                            setOpenTranslationsModal={
                                                props.setOpenTranslationsModal
                                            }
                                            aiPrompt={getSummaryPrompt(
                                                props.item.title,
                                                props.item.category?.name
                                            )}
                                        />
                                    </Box>
                                )}
                                <Box className={classes.subtitle} mt={1}>
                                    <Box
                                        display="flex"
                                        flexWrap="wrap"
                                        alignItems="center"
                                        onClick={handleUserClick}
                                    >
                                        <Avatar
                                            className={classes.avatar}
                                            alt={
                                                props.item.createdBy?.firstName
                                            }
                                            src={props.item.createdBy?.imageUrl}
                                        />
                                        <Box
                                            display="flex"
                                            flexDirection={"row"}
                                        >
                                            <Link
                                                style={{
                                                    cursor: "pointer",
                                                    marginRight: "5px",
                                                }}
                                            >
                                                {
                                                    props.item.createdBy
                                                        ?.firstName
                                                }{" "}
                                                {props.item.createdBy?.lastName}
                                            </Link>
                                            <span>
                                                {t("language", "language") ===
                                                "language"
                                                    ? "shared " +
                                                      dayjs(
                                                          props.item.createdAt
                                                              ?.formatted
                                                      ).fromNow()
                                                    : getGermanTimeAgo(
                                                          dayjs(
                                                              props.item
                                                                  .createdAt
                                                                  ?.formatted
                                                          ).fromNow(),
                                                          true
                                                      )}
                                            </span>
                                        </Box>
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                    </CardContent>
                </Box>

                {(!!props.editing ||
                    props.item.industries.length > 0 ||
                    props.item.tags.length > 0 ||
                    !!props.item.webLink) && (
                    <Box className={classes.bottomRow}>
                        {(!!props.editing || !!props.item.webLink) && (
                            <Box
                                minHeight={theme.spacing(8)}
                                width="100%"
                                display="flex"
                                overflow="hidden"
                            >
                                <ConceptWebLinkEditor
                                    editing={props.editing}
                                    item={props.item}
                                />
                                <Hidden xsDown>
                                    <Divider orientation="vertical" flexItem />
                                </Hidden>
                            </Box>
                        )}
                        {(!!props.editing || props.item.tags?.length > 0) && (
                            <Box
                                minHeight={theme.spacing(8)}
                                width="100%"
                                display="flex"
                                overflow="hidden"
                            >
                                <TagToggle
                                    concept={props.item}
                                    editable={props.editing}
                                />
                                <Hidden xsDown>
                                    <Divider orientation="vertical" flexItem />
                                </Hidden>
                            </Box>
                        )}

                        {(!!props.editing ||
                            props.item.industries?.length > 0) && (
                            <Box
                                minHeight={theme.spacing(8)}
                                width="100%"
                                display="flex"
                                overflow="hidden"
                            >
                                <IndustryList
                                    concept={props.item}
                                    editable={props.editing}
                                />
                            </Box>
                        )}
                    </Box>
                )}
                <ConceptDescription
                    item={props.item}
                    editable={props.editing}
                    preview={props.preview}
                />
            </Card>

            {open && (
                <ImageUploadDialog
                    item={props.item}
                    onClose={() => {
                        setOpen(false)
                    }}
                />
            )}
        </>
    )
}

export default React.memo(ConceptDetailsHeader)
