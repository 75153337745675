import React, { useState } from "react"
import {
    createStyles,
    Theme,
    makeStyles,
    useTheme,
} from "@material-ui/core/styles"
import useMediaQuery from "@material-ui/core/useMediaQuery"
import Box from "@material-ui/core/Box"
import Button from "@material-ui/core/Button"
import {
    Paper,
    IconButton,
    ListItem,
    ListItemText,
    ListItemIcon,
    Tooltip,
} from "@material-ui/core"

import { Category } from "../../__generated__/types"
import TreeView from "@material-ui/lab/TreeView"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import ChevronRightIcon from "@material-ui/icons/ChevronRight"
import TreeItem from "@material-ui/lab/TreeItem"
import useAwaitTranslation from "../../i18n/useAwaitTranslation"
import NewCategoryForm from "./NewCategoryForm"
import CategoryManagerModal from "./CategoryManagerModal"
import AddIcon from "@material-ui/icons/Add"
import GuidanceModal, { SettingsSection } from "../settings/GuidanceModal"
import useCategoryTools from "./useCategoryTools"
import CategoryIcon from "./CategoryIcon"
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: "flex",
            flexDirection: "column",
            overflow: "hidden",
            padding: theme.spacing(1, 3),
            height: "100%",
        },
        treeContainer: {
            flexGrow: 1,
            overflowY: "auto",
            padding: theme.spacing(0, 1),
            width: "100%",
            margin: theme.spacing(1),
        },
        treeItem: {
            backgroundColor: "transparent !important",
            padding: 0,
        },
        iconContainer: {
            "&:hover": {
                backgroundColor: theme.palette.action.hover + " !important",
            },

            borderRadius: theme.shape.borderRadius,
            display: "flex",
            alignItems: "center",
            margin: 0,
            width: "18px",
        },
        inactiveIconContainer: {
            cursor: "default",
            margin: 0,
            width: "18px",
        },
        content: {
            alignItems: "stretch",
        },

        paper: {
            display: "flex",
            overflow: " hidden",
            height: "100%",
        },
    })
)

export default function CategoryManager() {
    const classes = useStyles()
    const { t } = useAwaitTranslation("categories")
    const { environmentCategories } = useCategoryTools()
    const [categoryFormOpen, setCategoryFormOpen] = useState(false)
    const [focusedCategoryId, setFocusedCategoryId] = useState(null)
    const roots = environmentCategories?.filter((cat) => !!cat.isRoot)
    const focusedCategory = environmentCategories?.find(
        (cat) => cat.id === focusedCategoryId
    )
    return (
        <Box className={classes.root}>
            <Box
                py={2}
                display="flex"
                alignItems="center"
                justifyContent="flex-end"
            >
                <Button
                    key="add-cat"
                    onClick={() => setCategoryFormOpen(true)}
                    variant="contained"
                    color="primary"
                    style={{ whiteSpace: "nowrap" }}
                >
                    {t("addCategory", "Add Root Category")}
                </Button>
                <GuidanceModal section={SettingsSection.Categories} />
            </Box>
            <Paper className={classes.paper} variant="outlined">
                <Box className={classes.treeContainer}>
                    {roots?.map((item, idx) => {
                        return (
                            <TreeView
                                key={idx}
                                defaultCollapseIcon={<ExpandMoreIcon />}
                                defaultExpandIcon={<ChevronRightIcon />}
                            >
                                <TreeItemContainer
                                    node={item}
                                    onFocus={(id: string) => {
                                        setFocusedCategoryId(id)
                                    }}
                                />
                            </TreeView>
                        )
                    })}
                </Box>
            </Paper>
            {!!categoryFormOpen && (
                <NewCategoryForm
                    onClose={() => {
                        setCategoryFormOpen(false)
                    }}
                />
            )}
            {!!focusedCategory && (
                <CategoryManagerModal
                    category={environmentCategories?.find(
                        (cat) => cat.id === focusedCategoryId
                    )}
                    onClose={() => {
                        setFocusedCategoryId(null)
                    }}
                />
            )}
        </Box>
    )
}

interface TreeItemContainerProps {
    node: Category
    onFocus: (id: string) => void
}
const TreeItemContainer = (props: TreeItemContainerProps) => {
    const { node, onFocus } = props
    const classes = useStyles()
    const { environmentCategories } = useCategoryTools()
    const theme = useTheme()
    const mobile = useMediaQuery(theme.breakpoints.down("sm"))
    const [visibility, setVisibility] = useState<"visible" | "hidden">("hidden")
    const [childFormOpen, setChildFormOpen] = useState(false)

    const children = environmentCategories?.filter(
        (cat) => cat.parent?.id === node.id
    )
    return (
        <Box key={node.id}>
            <TreeItem
                classes={{
                    label: classes.treeItem,
                    iconContainer:
                        children?.length > 0
                            ? classes.iconContainer
                            : classes.inactiveIconContainer,
                    content: classes.content,
                }}
                key={node.id}
                nodeId={node.id}
                onLabelClick={(e) => {
                    e.preventDefault()
                    onFocus(node.id)
                }}
                label={
                    <>
                        <ListItem
                            divider
                            button
                            onMouseEnter={() => setVisibility("visible")}
                            onMouseLeave={() => setVisibility("hidden")}
                        >
                            {!!node.isRoot && (
                                <ListItemIcon>
                                    <CategoryIcon category={node} />
                                </ListItemIcon>
                            )}
                            <ListItemText
                                primary={node.name}
                                secondary={node.description}
                            />

                            <Tooltip title="Add nested category">
                                <IconButton
                                    onClick={(e) => {
                                        e.stopPropagation()
                                        setChildFormOpen(true)
                                        setVisibility("hidden")
                                    }}
                                    style={{
                                        visibility: mobile
                                            ? "visible"
                                            : visibility,
                                    }}
                                    size="small"
                                >
                                    <AddIcon />
                                </IconButton>
                            </Tooltip>
                        </ListItem>
                    </>
                }
            >
                {Array.isArray(children)
                    ? children.map((node) => (
                          <TreeItemContainer
                              node={node}
                              onFocus={onFocus}
                              key={node.id}
                          />
                      ))
                    : null}
            </TreeItem>

            {!!childFormOpen && (
                <NewCategoryForm
                    onClose={() => {
                        setChildFormOpen(false)
                    }}
                    parent={node}
                />
            )}
        </Box>
    )
}
