import React, { useState, useRef } from "react"
import Drawer from "@material-ui/core/Drawer"
import Button from "@material-ui/core/Button"
import Snackbar from "@material-ui/core/Snackbar"
import ChevronRightIcon from "@material-ui/icons/ChevronRight"
import { makeStyles } from "@material-ui/core/styles"
import { useHistory } from "react-router-dom"
import ConceptForm from "./ConceptForm"
import { Quote } from "./Quote"
import LongIdeaForm from "./LongConceptForm"
import useAwaitTranslation from "../i18n/useAwaitTranslation"
import QuickEntry from "./QuickEntry"
import { useAuth } from "../providers/AuthProvider"
import useEnvironmentSettingTools from "./settings/useEnvironmentSettingTools"
import { Alert } from "@material-ui/lab"
import { Box, Paper, Typography } from "@material-ui/core"
import { Concept } from "../__generated__/types"
import CategoryLabel from "./CategoryLabel"
const entryFormWidth = 480
const useStyles = makeStyles((theme) => ({
    drawer: {
        width: entryFormWidth,
        maxWidth: "100vw",
        flexShrink: 0,
    },
    snackbar: {
        color: theme.palette.primary.main,
    },
    quote: {
        position: "absolute",
        bottom: 0,
        width: "100%",
        padding: "1rem",
        color: theme.palette.text.hint,
    },
}))

interface AddConceptDrawerProps {
    closeForm: () => void
    formOpen: boolean
}

const AddConceptDrawer = (props: AddConceptDrawerProps) => {
    //hooks
    const history = useHistory()
    const classes = useStyles()

    const { currentUser, hasAccess } = useAuth()
    const { t } = useAwaitTranslation("concepts")
    const { ableToCreate } = useEnvironmentSettingTools()
    //state
    const [snackbar, setSnackbar] = useState(false)
    const [snackbarCategoryId, setSnackbarCategoryId] = useState("")
    const [snackbarConcept, setSnackbarConcept] = useState(null)
    const [longForm, goToLongForm] = useState(false)

    //refs
    const snackbarRef = useRef(null)

    //functions
    const handleClose = () => {
        setSnackbar(false)
        setSnackbarConcept(null)
    }
    const openSnackbar = (concept: Concept, categoryId: string) => {
        setSnackbarConcept(concept)
        setSnackbarCategoryId(categoryId)
        setSnackbar(true)
        if (!!snackbarRef?.current) {
            snackbarRef.current.focus()
        }
    }
    const handleSnackbarClick = () => {
        handleClose()
        history.push(`/concept/${snackbarConcept?.id}/home`)
    }

    if (!!currentUser && !!hasAccess) {
        return (
            <div>
                <Drawer
                    className={classes.drawer}
                    anchor="right"
                    open={props.formOpen}
                    classes={{
                        paper: classes.drawer,
                    }}
                >
                    <ConceptForm
                        closeForm={() => props.closeForm()}
                        openSnackbar={openSnackbar}
                        goToLongForm={() => {
                            goToLongForm(true)
                        }}
                    />
                    <div className={classes.quote}>
                        <Quote />
                    </div>
                </Drawer>
                <Snackbar
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "left",
                    }}
                    open={snackbar}
                    onClose={handleClose}
                    autoHideDuration={1000000}
                >
                    <Paper
                        style={{ width: 400, overflow: "hidden" }}
                        elevation={12}
                    >
                        <Box>
                            <Alert severity="success">
                                {"Creation Successful"}
                            </Alert>
                            <Box p={1.5}>
                                <CategoryLabel
                                    size={"medium"}
                                    conceptCategoryId={snackbarCategoryId}
                                />
                                <Typography noWrap variant="body1">
                                    {snackbarConcept?.title}
                                </Typography>
                                <Typography
                                    noWrap
                                    variant="body2"
                                    color="textSecondary"
                                >
                                    {snackbarConcept?.summary}
                                </Typography>
                            </Box>
                            <Box display="flex" justifyContent="flex-end">
                                <Button
                                    ref={snackbarRef}
                                    onClick={handleSnackbarClick}
                                    className={classes.snackbar}
                                >
                                    {t("continueEditing", "Continue Editing")}{" "}
                                    <ChevronRightIcon />
                                </Button>
                            </Box>
                        </Box>
                    </Paper>
                </Snackbar>

                {longForm && (
                    <LongIdeaForm
                        onClose={() => {
                            goToLongForm(false)
                            props.closeForm()
                        }}
                        openSnackbar={openSnackbar}
                    />
                )}
                {!!ableToCreate && <QuickEntry openSnackbar={openSnackbar} />}
            </div>
        )
    } else {
        return null
    }
}

export default AddConceptDrawer
