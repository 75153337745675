import React from "react"
import { Box } from "@material-ui/core"

import { CriteriaScore } from "../../../__generated__/types"
import { DateType } from "../../criteria/useCriteriaTypes"
import {
    KeyboardDatePicker,
    MuiPickersUtilsProvider,
} from "@material-ui/pickers"
import DateFnsUtils from "@date-io/date-fns"
import { useInputTools } from "../useInputTools"
import { isValidDate } from "../../../util/fns"

const datePattern =
    /\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d\.\d+([+-][0-2]\d:[0-5]\d|Z)/

interface DateInterfaceProps {
    score: CriteriaScore
    config: DateType
    multiInterface?: boolean
}

const DateInterface = (props: DateInterfaceProps) => {
    const { score, config } = props
    const { onEditResponse } = useInputTools({})

    const dateArray = JSON.parse(score?.response ?? "[]")
    const start: Date =
        !!dateArray[0] && datePattern.exec(dateArray[0])
            ? new Date(dateArray[0])
            : new Date()
    let end: Date = !!config.range
        ? !!dateArray[1] && datePattern.exec(dateArray[1])
            ? new Date(dateArray[1])
            : new Date()
        : null

    return (
        <Box
            display={"flex"}
            flexDirection="column"
            flexGrow={1}
            style={{ overflowY: "auto" }}
            key={score?.id}
        >
            <Box
                flexGrow={1}
                display="flex"
                alignItems="center"
                justifyContent="center"
                key={score?.id}
                pr={0.5}
                pl={0.5}
            >
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <Box
                        p={1}
                        display="flex"
                        width="100%"
                        justifyContent="center"
                    >
                        <Box p={1} width="100%" maxWidth="15rem">
                            <KeyboardDatePicker
                                size="small"
                                variant="inline"
                                InputAdornmentProps={{ position: "start" }}
                                fullWidth
                                allowKeyboardControl
                                format="MM/dd/yyyy"
                                value={start.toString()}
                                onChange={(value) => {
                                    if (!!isValidDate(value)) {
                                        let updateData = {
                                            response: JSON.stringify(
                                                !!config.range
                                                    ? [
                                                          value.toISOString(),
                                                          end?.toISOString() ||
                                                              new Date().toISOString(),
                                                      ]
                                                    : [value.toISOString()]
                                            ),
                                        }

                                        onEditResponse(
                                            {
                                                ...updateData,
                                            },
                                            score
                                        )
                                    }
                                }}
                                inputVariant="outlined"
                            />
                        </Box>
                        {!!config.range && (
                            <Box p={1} width="100%" maxWidth="15rem">
                                <KeyboardDatePicker
                                    size="small"
                                    variant="inline"
                                    format="MM/dd/yyyy"
                                    fullWidth
                                    minDate={start}
                                    allowKeyboardControl
                                    value={end.toString()}
                                    InputAdornmentProps={{ position: "start" }}
                                    onChange={(value) => {
                                        if (!!isValidDate(value)) {
                                            let updateData = {
                                                response: JSON.stringify([
                                                    dateArray[0] ||
                                                        new Date().toISOString(),
                                                    value.toISOString(),
                                                ]),
                                            }

                                            onEditResponse(
                                                {
                                                    ...updateData,
                                                },
                                                score
                                            )
                                        }
                                    }}
                                    inputVariant="outlined"
                                />
                            </Box>
                        )}
                    </Box>
                </MuiPickersUtilsProvider>
            </Box>
        </Box>
    )
}

export default DateInterface
