import { Concept, User, AccessType, SystemRole } from "../__generated__/types"

export type UserConceptPermission = {
    viewingPermission: boolean
    contributingPermission: boolean
    editingPermission: boolean
    owningPermission: boolean
    conceptId?: string
}

export const getUserConceptPermission = (
    concept: Concept | null,
    user: User | null
) => {
    /// viewingPermissions
    let viewingPermission = concept?.isPublicAccessType !== AccessType.NONE
    if (!viewingPermission) {
        viewingPermission =
            concept?.addedUsers?.some(
                (item) =>
                    item.User.userId === user.userId &&
                    item.type !== AccessType.NONE
            ) ?? false
    }
    if (!viewingPermission) {
        viewingPermission =
            concept?.addedTeams?.some((item) =>
                item.Team.members.some(
                    (member) =>
                        member.userId === user.userId &&
                        item.type !== AccessType.NONE
                )
            ) ?? false
    }

    /// edit permissions
    let contributingPermission = [
        AccessType.EDITOR,
        AccessType.OWNER,
        AccessType.CONTRIBUTOR,
    ].includes(concept?.isPublicAccessType)
    if (!contributingPermission) {
        contributingPermission =
            concept?.addedUsers?.some(
                (item) =>
                    item.User.userId === user.userId &&
                    (item.type === AccessType.EDITOR ||
                        item.type === AccessType.OWNER ||
                        item.type === AccessType.CONTRIBUTOR)
            ) ?? false
    }
    if (!contributingPermission) {
        contributingPermission =
            concept?.addedTeams?.some((item) =>
                item.Team.members.some(
                    (member) =>
                        member.userId === user.userId &&
                        (item.type === AccessType.EDITOR ||
                            item.type === AccessType.OWNER ||
                            item.type === AccessType.CONTRIBUTOR)
                )
            ) ?? false
    }

    /// edit permissions
    let editingPermission = [AccessType.EDITOR, AccessType.OWNER].includes(
        concept?.isPublicAccessType
    )
    if (!editingPermission) {
        editingPermission =
            concept?.addedUsers?.some(
                (item) =>
                    item.User.userId === user.userId &&
                    (item.type === AccessType.EDITOR ||
                        item.type === AccessType.OWNER)
            ) ?? false
    }
    if (!editingPermission) {
        editingPermission =
            concept?.addedTeams?.some((item) =>
                item.Team.members.some(
                    (member) =>
                        member.userId === user.userId &&
                        (item.type === AccessType.EDITOR ||
                            item.type === AccessType.OWNER)
                )
            ) ?? false
    }

    if (!editingPermission && concept.isPublicAccessType !== AccessType.NONE) {
        if (user.role === SystemRole.ADMIN) {
            editingPermission = true
        } else if (
            user.role === SystemRole.MANAGER &&
            !concept.category?.isManaged
        ) {
            editingPermission = true
        }
    }

    // owner permissions
    let owningPermission = concept?.isPublicAccessType === AccessType.OWNER
    if (!owningPermission) {
        owningPermission =
            concept?.addedUsers?.some(
                (item) =>
                    item.User?.userId === user.userId &&
                    item.type === AccessType.OWNER
            ) ?? false
    }
    if (!owningPermission) {
        owningPermission =
            concept?.addedTeams?.some((item) =>
                item.Team?.members.some(
                    (member) =>
                        member.userId === user.userId &&
                        item.type === AccessType.OWNER
                )
            ) ?? false
    }

    ///

    return {
        viewingPermission,
        contributingPermission:
            contributingPermission || editingPermission || owningPermission,
        editingPermission: editingPermission || owningPermission,
        owningPermission,
        conceptId: concept.id,
    } as UserConceptPermission
}
