import { gql } from "@apollo/client"

export const USER_QUERY = gql`
    query MyAccount($userId: ID) {
        User(filter: { userId: $userId }) {
            userId
            firstName
            lastName
            email
            bio
            role
            imageUrl
            username
            lastLogin {
                formatted
            }
            createdDate {
                formatted
            }
            numConceptsCreated
        }
    }
`
export const USER_FILTERED = gql`
    query UserFiltered($filter: _UserFilter, $first: Int) {
        User(filter: $filter, orderBy: firstName_asc, first: $first) {
            userId
            providerId
            username
            email
            firstName
            lastName
            role
            imageUrl
        }
    }
`
export const FULL_USER_QUERY = gql`
    query FullUsers {
        User(orderBy: lastName_asc) {
            userId
            email
            firstName
            lastName
            username
            role
            email
            imageUrl
            conceptsCreated {
                id
            }
            lastLogin {
                formatted
            }
        }
    }
`
export const USER_MANAGEMENT_LINE_ITEM = gql`
    query UserManagementLineItem($username: String) {
        User(username: $username) {
            userId
            firstName
            lastName
            username
            email
            company
            imageUrl
            bio
            role
            conceptsCreated {
                id
            }
            createdDate {
                formatted
            }
            lastLogin {
                formatted
            }
            teams {
                teamId
                name
                imageUrl
                members {
                    userId
                    firstName
                    lastName
                }
            }
            admin {
                teamId
                name
                imageUrl
            }
            leads {
                teamId
                name
                imageUrl
            }
        }
    }
`
export const ALL_USER_QUERY = gql`
    query AllUsers {
        users: User(orderBy: firstName_asc) {
            userId
            email
            firstName
            lastName
            username
            imageUrl
            role
        }
    }
`

export const ADMIN_EMAILS = gql`
    query AdminEmails {
        User(filter: { role: ADMIN }) {
            userId
            email
        }
    }
`

export const ADD_USER_HOME_CONCEPT = gql`
    mutation AddUserHomeConcept($userId: ID!, $conceptId: ID!) {
        AddUserHomeConcept(from: { userId: $userId }, to: { id: $conceptId }) {
            from {
                userId
            }
            to {
                id
            }
        }
    }
`

export const DELETE_USER = gql`
    mutation DeleteUser($userId: ID!) {
        DeleteUser(userId: $userId) {
            userId
        }
    }
`

export const USER_PROFILE = gql`
    query UserProfile($username: String) {
        user: User(filter: { username: $username }) {
            userId
            firstName
            lastName
            username
            email
            company
            imageUrl
            role
            bio
            createdDate {
                formatted
            }
            lastLogin {
                formatted
            }
        }
    }
`

export const UPDATE_USER = gql`
    mutation updateUser(
        $userId: ID!
        $providerId: String
        $firstName: String
        $lastName: String
        $email: String
        $company: String
        $role: SystemRole
        $username: String
        $language: LanguageString
        $imageUrl: String
        $token: String
        $lastLogin: _Neo4jDateTimeInput
        $createdDate: _Neo4jDateTimeInput
        $activeThemeType: ThemeType
    ) {
        UpdateUser(
            userId: $userId
            providerId: $providerId
            firstName: $firstName
            lastName: $lastName
            email: $email
            company: $company
            username: $username
            role: $role
            language: $language
            imageUrl: $imageUrl
            token: $token
            lastLogin: $lastLogin
            createdDate: $createdDate
            activeThemeType: $activeThemeType
        ) {
            userId
            providerId
            firstName
            lastName
            email
            role
            company
            username
            language
            imageUrl
            createdDate {
                formatted
            }
            lastLogin {
                formatted
            }
            activeThemeType

            teams {
                teamId
            }

            conceptLists {
                id
                title
                type
                conceptIds
                concepts {
                    id
                    title
                    type
                    summary
                    imageUrl
                    category {
                        id
                        name
                    }
                    children {
                        id
                        title
                        type
                        imageUrl
                        category {
                            id
                            name
                        }
                    }
                }
            }
        }
    }
`

export const CREATE_USER = gql`
    mutation CreateUser(
        $userId: ID
        $providerId: String
        $firstName: String
        $lastName: String
        $email: String
        $role: SystemRole
        $username: String
        $language: LanguageString
        $imageUrl: String
        $company: String
        $token: String
        $lastLogin: _Neo4jDateTimeInput
        $createdDate: _Neo4jDateTimeInput
    ) {
        CreateUser(
            userId: $userId
            providerId: $providerId
            firstName: $firstName
            lastName: $lastName
            email: $email
            username: $username
            role: $role
            language: $language
            company: $company
            imageUrl: $imageUrl
            token: $token
            lastLogin: $lastLogin
            createdDate: $createdDate
        ) {
            userId
            providerId
            firstName
            lastName
            email
            role
            username
            language
            company
            imageUrl
            createdDate {
                formatted
            }
            lastLogin {
                formatted
            }

            conceptLists {
                id
                title
                type
                concepts {
                    id
                    title
                    type
                    imageUrl
                }
            }
            teams {
                teamId
            }
        }
    }
`

export const MERGE_USER = gql`
    mutation mergeUser(
        $userId: ID!
        $providerId: String
        $firstName: String
        $lastName: String
        $email: String
        $company: String
        $role: SystemRole
        $username: String
        $language: LanguageString
        $imageUrl: String
        $token: String
        $lastLogin: _Neo4jDateTimeInput
        $createdDate: _Neo4jDateTimeInput
    ) {
        MergeUser(
            userId: $userId
            providerId: $providerId
            firstName: $firstName
            lastName: $lastName
            email: $email
            company: $company
            username: $username
            role: $role
            language: $language
            imageUrl: $imageUrl
            token: $token
            lastLogin: $lastLogin
            createdDate: $createdDate
        ) {
            userId
            providerId
            firstName
            lastName
            email
            company
            role
            username
            language
            imageUrl
            createdDate {
                formatted
            }
            lastLogin {
                formatted
            }
            conceptLists {
                id
                title
                type
                concepts {
                    id
                    title
                    type
                    imageUrl
                }
            }
            teams {
                teamId
            }
        }
    }
`
