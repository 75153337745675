import { PopperPlacementType } from "@material-ui/core"
import React, { ReactElement, useEffect, useState } from "react"
import RichTooltip from "./RichTooltip"

interface Props {
    content: ReactElement | (() => ReactElement)
    children: ReactElement
    closePopper?: boolean
    arrow?: boolean
    placement?: PopperPlacementType
    zIndex?: number
    //true if content contains a portal
    onMouseUp?: boolean
    hide?: boolean
}

const ClickableRichTooltip = ({
    placement,
    arrow,
    content,
    children,
    zIndex,
    closePopper = false,
    onMouseUp = false,
    hide = false,
}: Props) => {
    const [open, setOpen] = useState(false)

    useEffect(() => {
        if (!!closePopper && !!open) {
            setOpen(false)
        }
    }, [closePopper, open])
    const existingOnClick = children.props.onClick
    const newOnClick = (e: MouseEvent) => {
        setOpen(!open)
        existingOnClick && existingOnClick(e)
    }

    const contentNode = typeof content === "function" ? content() : content
    if (!!hide) {
        return children
    }
    return (
        <RichTooltip
            open={open}
            onClose={() => {
                setOpen(false)
            }}
            zIndex={zIndex}
            arrow={arrow}
            placement={placement}
            content={contentNode}
            onMouseUp={onMouseUp}
        >
            {React.cloneElement(children, {
                ...children.props,
                onClick: newOnClick,
            })}
        </RichTooltip>
    )
}

export default ClickableRichTooltip
