import React from "react"
import { Box, Chip, Divider, Typography } from "@material-ui/core"
import { makeStyles, useTheme, Theme, alpha } from "@material-ui/core/styles"
import dayjs from "dayjs"
import useAwaitTranslation from "../../i18n/useAwaitTranslation"
import { getGermanTimeAgo } from "../../util/fns"
import ArrowRightAltIcon from "@material-ui/icons/ArrowRightAlt"
import { DateType } from "../criteria/useCriteriaTypes"
import { Concept, CriteriaScore } from "../../__generated__/types"
import ConceptListItem from "../ConceptListItem"
import { useHistory } from "react-router-dom"
import { useReactiveVar } from "@apollo/client"
import TodayIcon from "@material-ui/icons/Today"
import { myCurrentInputResponseLabelVar } from "../../providers/GlobalState"
import { ScoreSelectorDefaultValues } from "../inputs/types"
const useStyles = makeStyles((theme: Theme) => ({
    bar: {
        borderRadius: theme.shape.borderRadius,
        backgroundColor: alpha(theme.palette.primary.main, 0.15),
    },
    subBar: {
        borderRadius: theme.shape.borderRadius,
        backgroundColor: alpha(theme.palette.primary.main, 0.15),
    },

    axisLabel: {
        fontSize: theme.typography.body2.fontSize,
        textAlign: "center",
        position: "absolute",
        ///half of typical width of date of format mm/dd/yy
        left: -30,
    },
    hover: {
        "&:hover": {
            backgroundColor: theme.palette.action.hover,
        },
    },
}))

type DateObject = {
    concept: Concept
    startDate: Date
    endDate: Date
}

interface RoadmapProps {
    inputId: string
    config: DateType
    scores: CriteriaScore[]
    conceptsScored?: Concept[]
}
export default function Roadmap(props: RoadmapProps) {
    const { config, scores, conceptsScored } = props
    const currentLabel = useReactiveVar(myCurrentInputResponseLabelVar)
    const viewingAllResponses =
        currentLabel?.label === ScoreSelectorDefaultValues.allResponses &&
        !currentLabel.user
    const oneYearAgo = new Date(
        new Date().setFullYear(new Date().getFullYear() - 1)
    )
    const oneYearFromToday = new Date(
        new Date().setFullYear(new Date().getFullYear() + 1)
    )
    const dateData: DateObject[] =
        conceptsScored
            ?.map((concept, index) => {
                if (!!viewingAllResponses) {
                    const conceptScores = scores.filter(
                        (score) => score.scoredConcept?.id === concept.id
                    )
                    let startAverage = 0
                    conceptScores.map((score) => {
                        startAverage += new Date(
                            JSON.parse(score?.response ?? "[]")?.[0]
                        )?.getTime()
                        return startAverage
                    })
                    let endAverage = 0
                    conceptScores.map((score) => {
                        endAverage += new Date(
                            JSON.parse(score?.response ?? "[]")?.[
                                !!config.range ? 1 : 0
                            ]
                        )?.getTime()
                        return endAverage
                    })
                    return {
                        concept: concept,
                        startDate: new Date(
                            startAverage / conceptScores.length
                        ),
                        endDate: new Date(endAverage / conceptScores.length),
                    }
                } else {
                    return {
                        concept: concept,
                        startDate: new Date(
                            JSON.parse(
                                scores?.find(
                                    (score) =>
                                        score.scoredConcept?.id === concept.id
                                )?.response ?? "[]"
                            )?.[0] ?? null
                        ),
                        endDate: new Date(
                            JSON.parse(
                                scores?.find(
                                    (score) =>
                                        score.scoredConcept?.id === concept.id
                                )?.response ?? "[]"
                            )?.[!!config.range ? 1 : 0] ?? null
                        ),
                    }
                }
            })
            ?.sort((a, b) => {
                return a.startDate.getTime() - b.startDate.getTime()
            }) ?? []
    const min = dateData.map((obj) => obj.startDate)?.[0] || oneYearAgo
    const max =
        dateData
            .map((obj) => obj.endDate)
            ?.sort((a, b) => {
                return b.getTime() - a.getTime()
            })?.[0] || oneYearFromToday

    return (
        <Box
            display="flex"
            flexDirection="column"
            overflow="hidden"
            width="100%"
            height="100%"
        >
            <Box
                overflow="auto"
                flexGrow={1}
                height="100%"
                display="flex"
                flexDirection={"column"}
            >
                {dateData.map((x, idx) => {
                    return (
                        <Box
                            key={idx}
                            width="100%"
                            flexGrow={1}
                            minHeight={60}
                            maxHeight={100}
                        >
                            <GanttBar
                                item={x}
                                min={min}
                                max={max}
                                depth={0}
                                config={config}
                            />
                        </Box>
                    )
                })}
            </Box>
            <Divider />
            <BottomAxis minDate={min} maxDate={max} />
        </Box>
    )
}

interface BottomAxisProps {
    minDate: Date
    maxDate: Date
}
const BottomAxis = (props: BottomAxisProps) => {
    const classes = useStyles()
    let integer = 6
    const theme = useTheme()
    const min = props.minDate.getTime()
    const max = props.maxDate.getTime()
    const today = new Date().getTime()
    const todayOffset = ((today - min) / (max - min)) * 100

    const interval = Math.round((max - min) / integer)

    let dates = []
    const { t } = useAwaitTranslation("concepts")

    for (let i = 0; i < integer; i++) {
        let date = new Date(min + interval * i)
        dates.push(date)
    }
    let showToday = true
    if (
        today < dates[1].getTime() ||
        today > dates[dates.length - 1].getTime()
    ) {
        showToday = false
    }
    return (
        <Box display="flex" pb={2} pr={1} pl={1}>
            <Box width="100%">
                <Box width="100%" display="flex" position={"relative"}>
                    {!!showToday && (
                        <Box
                            style={{
                                position: "absolute",
                                left: todayOffset + "%",
                                height: "100%",
                            }}
                        >
                            <Divider orientation="vertical" />
                            <Box
                                display="flex"
                                alignItems={"center"}
                                ml={-1.25}
                                pr={0.5}
                                pl={0.5}
                                mt={6}
                                style={{
                                    backgroundColor:
                                        theme.palette.background.paper,
                                }}
                            >
                                <TodayIcon fontSize="small" color="action" />
                                <Typography
                                    style={{ marginLeft: 4 }}
                                    variant="body2"
                                    color="textSecondary"
                                >
                                    Today
                                </Typography>
                            </Box>
                        </Box>
                    )}
                    {dates.length > 0 &&
                        dates
                            .filter((x, idx) => idx !== 0)
                            .map((x, idx) => {
                                const offset =
                                    ((x.getTime() - min) / (max - min)) * 100
                                return (
                                    <Box
                                        position="absolute"
                                        left={offset + "%"}
                                        key={idx}
                                    >
                                        <Box
                                            width="fit-content"
                                            position="relative"
                                        >
                                            <Box height="0.5em" display="flex">
                                                <Divider orientation="vertical" />
                                            </Box>
                                            <Box className={classes.axisLabel}>
                                                {dayjs(x).format("MM/DD/YY")}
                                            </Box>
                                        </Box>
                                    </Box>
                                )
                            })}
                </Box>
                <Box
                    display="flex"
                    alignItems="center"
                    mt={6}
                    color={theme.palette.text.hint}
                >
                    <Box whiteSpace="nowrap" mr={1}>
                        {t("language", "language") === "language"
                            ? dayjs(min).fromNow()
                            : getGermanTimeAgo(dayjs(min).fromNow(), false)}
                    </Box>
                    <Box width="100%">
                        <Divider />
                    </Box>
                    <Box whiteSpace="nowrap" ml={1}>
                        {t("language", "language") === "language"
                            ? dayjs(max).fromNow()
                            : getGermanTimeAgo(dayjs(max).fromNow(), false)}
                    </Box>
                </Box>
            </Box>
        </Box>
    )
}

interface GanttBarProps {
    item: DateObject
    min: Date
    max: Date
    depth: number

    config: DateType
}
const GanttBar = (props: GanttBarProps) => {
    const classes = useStyles()
    const history = useHistory()

    const duration = props.max?.getTime() - props.min?.getTime()

    const leftWidth =
        ((props.item.startDate.getTime() - props.min.getTime()) / duration) *
        100
    const middleWidth =
        ((props.item.endDate.getTime() - props.item.startDate.getTime()) /
            duration) *
        100

    const rightWidth =
        ((props.max.getTime() - props.item.endDate.getTime()) / duration) * 100

    return (
        <Box
            display="flex"
            height="100%"
            alignItems="center"
            position={"relative"}
            width="100%"
        >
            <Box width="100%" zIndex={1}>
                <ConceptListItem
                    hideCategory={true}
                    hideDivider={true}
                    item={props.item.concept}
                    secondaryIcon={
                        <Box p={1}>
                            <Chip
                                color="secondary"
                                label={
                                    <Box display="flex" alignItems="center">
                                        <Typography variant="body1">
                                            {dayjs(props.item.startDate).format(
                                                "MM/DD/YY"
                                            )}
                                        </Typography>
                                        {!!props.config.range && (
                                            <>
                                                <Box
                                                    mr={1}
                                                    ml={1}
                                                    display="flex"
                                                    alignItems="center"
                                                >
                                                    <ArrowRightAltIcon fontSize="small" />
                                                </Box>
                                                <Typography variant="body1">
                                                    {dayjs(
                                                        props.item.endDate
                                                    ).format("MM/DD/YY")}
                                                </Typography>
                                            </>
                                        )}
                                    </Box>
                                }
                            />
                        </Box>
                    }
                />
            </Box>

            <Box
                height={"100%"}
                display="flex"
                width="100%"
                position="absolute"
            >
                <Box width={leftWidth + "%"} />

                <Box
                    m={0.5}
                    className={props.depth === 0 ? classes.bar : classes.subBar}
                    width={middleWidth !== 0 ? middleWidth + "%" : "25px"}
                />

                <Box width={rightWidth + "%"} />
            </Box>
        </Box>
    )
}
