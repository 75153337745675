import { Typography } from "@material-ui/core"

export default function SectionHeader(props: {
    headerName: string
    conceptCount?: number
}) {
    const { headerName, conceptCount } = props

    return (
        <Typography
            style={{ fontWeight: "bold" }}
            gutterBottom
            variant="subtitle2"
            component="h2"
        >
            {headerName}{" "}
            {!isNaN(conceptCount) &&
                ` (${conceptCount === 100 ? 100 + "+" : conceptCount})`}
        </Typography>
    )
}
