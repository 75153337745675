import React, { useState } from "react"
import { makeStyles } from "@material-ui/core/styles"
import Button from "@material-ui/core/Button"
import { useQuery } from "@apollo/client"
import { useAuth } from "../../providers/AuthProvider"
import TeamForm from "./TeamForm"
import Box from "@material-ui/core/Box"
import Link from "@material-ui/core/Link"
import { TEAMS_QUERY } from "./graphql"
import { TeamQuery, TeamQueryVariables } from "./__generated__/graphql"
import { Divider, Typography } from "@material-ui/core"
import { Loading } from "../Loading"
import useAwaitTranslation from "../../i18n/useAwaitTranslation"
import { Paper, Toolbar, Hidden, IconButton } from "@material-ui/core"
import { Link as LinkRouter } from "react-router-dom"
import Table from "@material-ui/core/Table"
import TableBody from "@material-ui/core/TableBody"
import TableCell from "@material-ui/core/TableCell"
import TableContainer from "@material-ui/core/TableContainer"
import TableHead from "@material-ui/core/TableHead"
import TableRow from "@material-ui/core/TableRow"
import TableSortLabel from "@material-ui/core/TableSortLabel"
import AddIcon from "@material-ui/icons/Add"
import { useHistory } from "react-router-dom"
import TeamAvatar from "./TeamAvatar"
import UserAvatar from "../users/UserAvatar"
const useStyles = makeStyles((theme) => ({
    root: {
        padding: theme.spacing(3),
        height: "100%",
    },
    teamList: {
        flexGrow: 1,
    },
    avatar: {
        width: theme.spacing(4),
        height: theme.spacing(4),
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.primary.main,
        marginRight: theme.spacing(1),
    },
    flexCell: {
        display: "flex",
        alignItems: "center",
        height: "100%",
    },
    tableContainer: {
        flexGrow: 1,
    },
    tableHeader: {
        //@ts-ignore
        backgroundColor: `${theme.palette.background.level2} !important`,
    },
    paper: {
        display: "flex",
        flexDirection: "column",
        height: "100%",
    },
}))

type Order = "asc" | "desc"

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
    if (b[orderBy] < a[orderBy]) {
        return -1
    }
    if (b[orderBy] > a[orderBy]) {
        return 1
    }
    return 0
}

function getComparator<Key extends keyof any>(
    order: Order,
    orderBy: Key
): (
    a: { [key in Key]: number | string },
    b: { [key in Key]: number | string }
) => number {
    return order === "desc"
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy)
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort<T>(
    array: readonly T[],
    comparator: (a: T, b: T) => number
) {
    const stabilizedThis = array.map((el, index) => [el, index] as [T, number])
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0])
        if (order !== 0) {
            return order
        }
        return a[1] - b[1]
    })
    return stabilizedThis.map((el) => el[0])
}

export default function Teams() {
    const classes = useStyles()
    const [teamFormOpen, setTeamFormOpen] = useState(false)
    const { t } = useAwaitTranslation("teams")
    const { currentUser } = useAuth()
    const history = useHistory()
    const [order, setOrder] = useState<Order>("asc")
    const [orderBy, setOrderBy] = useState("name")
    const { data, loading, error } = useQuery<TeamQuery, TeamQueryVariables>(
        TEAMS_QUERY,
        {
            variables: {
                userFilter: {
                    userId: currentUser?.userId,
                },
            },
        }
    )
    if (loading) {
        return <Loading size={25} hideQuote={true} />
    }
    if (error) {
        return <Box>{error.message}</Box>
    }

    const handleRequestSort = (property) => {
        const isAsc = orderBy === property && order === "asc"
        setOrder(isAsc ? "desc" : "asc")
        setOrderBy(property)
    }
    const teams = data?.Team
    const sortableTeams = teams
        ? [
              ...teams.map((item) => {
                  let object = {
                      conceptTotal: item.conceptLists[0]?.concepts?.length,
                      memberTotal: item.members?.length,
                      leaderName: item.leader?.lastName?.[0] ?? "z",
                  }
                  Object.keys(item).filter((field) => {
                      if (
                          (typeof field === "string" ||
                              typeof field === "number") &&
                          field !== "__typename"
                      ) {
                          object[field] = item[field]
                      }
                      return object
                  })
                  return object
              }),
          ]
        : []

    return (
        <Box className={classes.root}>
            <Paper className={classes.paper} variant="outlined">
                <Toolbar>
                    <Typography
                        component="div"
                        variant="h6"
                        style={{ flex: "1 1 100%" }}
                    >
                        {t("myTeams", "My Teams")}
                    </Typography>
                    <Box flexShrink={0}>
                        <Hidden xsDown>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={() => setTeamFormOpen(true)}
                            >
                                {t("createNew", "Create New")}
                            </Button>
                        </Hidden>
                        <Hidden smUp>
                            <IconButton onClick={() => setTeamFormOpen(true)}>
                                <AddIcon />
                            </IconButton>
                        </Hidden>
                    </Box>
                </Toolbar>
                <Divider />
                <TableContainer className={classes.tableContainer}>
                    <Table stickyHeader>
                        <TableHead>
                            <TableRow>
                                <TableCell
                                    className={classes.tableHeader}
                                    sortDirection={
                                        orderBy === "name" ? order : false
                                    }
                                >
                                    <TableSortLabel
                                        active={orderBy === "name"}
                                        direction={
                                            orderBy === "name" ? order : "asc"
                                        }
                                        onClick={() =>
                                            handleRequestSort("name")
                                        }
                                    >
                                        {t("name", "Name")}
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell
                                    className={classes.tableHeader}
                                    sortDirection={
                                        orderBy === "memberTotal"
                                            ? order
                                            : false
                                    }
                                >
                                    <TableSortLabel
                                        active={orderBy === "memberTotal"}
                                        direction={
                                            orderBy === "memberTotal"
                                                ? order
                                                : "asc"
                                        }
                                        onClick={() =>
                                            handleRequestSort("memberTotal")
                                        }
                                    >
                                        {t("members", "Members")}
                                    </TableSortLabel>
                                </TableCell>
                                <Hidden smDown>
                                    <>
                                        <TableCell
                                            className={classes.tableHeader}
                                            sortDirection={
                                                orderBy === "conceptTotal"
                                                    ? order
                                                    : false
                                            }
                                        >
                                            <TableSortLabel
                                                active={
                                                    orderBy === "conceptTotal"
                                                }
                                                direction={
                                                    orderBy === "conceptTotal"
                                                        ? order
                                                        : "asc"
                                                }
                                                onClick={() =>
                                                    handleRequestSort(
                                                        "conceptTotal"
                                                    )
                                                }
                                            >
                                                {t("concepts", "Concepts")}
                                            </TableSortLabel>
                                        </TableCell>
                                        <TableCell
                                            className={classes.tableHeader}
                                            sortDirection={
                                                orderBy === "leaderName"
                                                    ? order
                                                    : false
                                            }
                                        >
                                            <TableSortLabel
                                                active={
                                                    orderBy === "leaderName"
                                                }
                                                direction={
                                                    orderBy === "leaderName"
                                                        ? order
                                                        : "asc"
                                                }
                                                onClick={() =>
                                                    handleRequestSort(
                                                        "leaderName"
                                                    )
                                                }
                                            >
                                                {t("teamLead", "Team Lead")}
                                            </TableSortLabel>
                                        </TableCell>
                                    </>
                                </Hidden>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {sortableTeams?.length > 0 &&
                                stableSort(
                                    sortableTeams,
                                    getComparator(order, orderBy)
                                )?.map((item, idx) => {
                                    const team = teams.filter(
                                        (x) => x.teamId === item.teamId
                                    )?.[0]
                                    if (!!team) {
                                        return (
                                            <TableRow
                                                hover
                                                onClick={(e) => {
                                                    history.push(
                                                        `/team/${team.teamId}/home`
                                                    )
                                                }}
                                                style={{ cursor: "pointer" }}
                                                key={team.teamId}
                                            >
                                                <TableCell>
                                                    <Box
                                                        className={
                                                            classes.flexCell
                                                        }
                                                    >
                                                        <TeamAvatar
                                                            team={team}
                                                            variant="rounded"
                                                            size="large"
                                                        />
                                                        <Box ml={1}>
                                                            {team.name}
                                                        </Box>
                                                    </Box>
                                                </TableCell>

                                                <TableCell align="left">
                                                    {team.members?.length}
                                                </TableCell>
                                                <Hidden smDown>
                                                    <>
                                                        <TableCell align="left">
                                                            {
                                                                team
                                                                    .conceptLists[0]
                                                                    ?.concepts
                                                                    ?.length
                                                            }
                                                        </TableCell>
                                                        <TableCell>
                                                            <Box
                                                                className={
                                                                    classes.flexCell
                                                                }
                                                            >
                                                                <UserAvatar
                                                                    user={
                                                                        team.leader
                                                                    }
                                                                    size="medium"
                                                                    variant="circle"
                                                                />
                                                                <Box ml={1}>
                                                                    <Link
                                                                        component={
                                                                            LinkRouter
                                                                        }
                                                                        onClick={(
                                                                            e
                                                                        ) =>
                                                                            e.stopPropagation()
                                                                        }
                                                                        to={`/user/${team.leader?.username}/home`}
                                                                    >
                                                                        {`${team.leader?.firstName} ${team.leader?.lastName}`}
                                                                    </Link>
                                                                </Box>
                                                            </Box>
                                                        </TableCell>
                                                    </>
                                                </Hidden>
                                            </TableRow>
                                        )
                                    } else return null
                                })}
                        </TableBody>
                    </Table>
                </TableContainer>

                {teamFormOpen && (
                    <TeamForm
                        onClose={() => {
                            setTeamFormOpen(false)
                        }}
                    />
                )}
            </Paper>
        </Box>
    )
}
