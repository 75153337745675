import {
    Box,
    Button,
    CircularProgress,
    Grid,
    Typography,
    Tooltip,
    Divider,
    IconButton,
    InputAdornment,
} from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import CloseIcon from "@material-ui/icons/Close"
import { TextField } from "mui-rff"
import { useState } from "react"
import { Form } from "react-final-form"

import { ConceptFieldSettings } from "../util/SystemSettings"
import FetchLink from "./FetchLink"
import TagInput from "./tags/TagInput"
import useAwaitTranslation from "../i18n/useAwaitTranslation"
import { AccessType, Category, Link, SystemRole } from "../__generated__/types"
import { Loading } from "./Loading"
import { Concept } from "../__generated__/types"
import CategoryTreeSelector from "./categories/CategoryTreeSelector"
import useConceptTools from "../util/useConceptTools"
import useEnvironmentSettingTools from "./settings/useEnvironmentSettingTools"
import GenerateAI, { getSummaryPrompt, getTitlePrompt } from "./GenerateAI"
import Public from "@material-ui/icons/Public"
import Send from "@material-ui/icons/Send"
import VpnLock from "@material-ui/icons/VpnLock"
import useCategoryTools from "./categories/useCategoryTools"
import { useAuth } from "../providers/AuthProvider"

const useStyles = makeStyles((theme) => ({
    formTitle: {
        fontSize: "1.5rem",
        textAlign: "center",
    },
    formContainer: {
        padding: theme.spacing(1.5),
        paddingTop: 0,
        minWidth: "300px",
        width: "100%",
        maxWidth: "100vw",
        marginTop: theme.spacing(3),
    },
    formField: {
        margin: "1rem",
    },
}))

interface ConceptFormProps {
    concept?: Concept
    closeForm: () => void
    openSnackbar?: (concept: Concept, categoryId: string) => void
    creationCallback?: (concept: Concept) => void
    formTitle?: string
    goToLongForm?: () => void
}
export default function ConceptForm(props: ConceptFormProps) {
    const classes = useStyles()
    const { currentUser } = useAuth()

    const [item, setItem] = useState(props.concept)
    const [createFromUrl, setCreateFromUrl] = useState(false)
    const { onConceptCreation, getConceptFromLinkItem } = useConceptTools()
    const { ableToPublish } = useEnvironmentSettingTools()
    const [tags, setTags] = useState<Array<string>>([])
    const [isPublic, setIsPublic] = useState(true)
    const [category, setCategory] = useState<Category>(null)
    const [linkItem, setLinkItem] = useState<Link | null>(null)
    const [linkLoading, setLinkLoading] = useState(false)
    const [submitting, setSubmitting] = useState(false)
    const { environmentCategories } = useCategoryTools()
    const { t } = useAwaitTranslation("ideas")

    const submitConcept = async (data) => {
        const NewConcept = await onConceptCreation({
            data: {
                title: data.title,
                type: category?.isRoot ? category.name : category.parent.name,
                summary: data.summary,
                isPublic: !!isPublic && !!ableToPublish,
                isPublicAccessType:
                    !!isPublic && !!ableToPublish
                        ? AccessType.VIEWER
                        : AccessType.NONE,
            },
            linkItem: linkItem,
            category: category,
            openSnackbar: props.openSnackbar,
            tags: tags,
        })
        if (!!props.creationCallback) props.creationCallback(NewConcept)
        setSubmitting(false)
        props.closeForm()
    }

    const handleLink = (linkData: Link) => {
        setLinkItem(linkData)
        setItem({
            ...item,
            ...getConceptFromLinkItem(linkData),
        })
        setLinkLoading(false)
    }
    if (submitting) {
        return <Loading size={25} hideQuote={true} />
    } else {
        return (
            <Box>
                <Box
                    display="flex"
                    justifyContent="space-between"
                    p={1}
                    alignItems="center"
                >
                    <Box display="flex">
                        <Tooltip
                            title={t("goToDetailedForm", "Go to detailed form")}
                        >
                            <Button
                                onClick={() => {
                                    props.goToLongForm()
                                }}
                            >
                                {t("detailedForm", "Detailed Form")}
                            </Button>
                        </Tooltip>
                        <Divider flexItem orientation="vertical" />
                        <Tooltip
                            title={t(
                                "fillFormMetadata",
                                "Fill form with entered URL metadata"
                            )}
                        >
                            <Button onClick={() => setCreateFromUrl(true)}>
                                {t("createFromURL", "Create from URL")}
                            </Button>
                        </Tooltip>
                    </Box>

                    <Tooltip title={t("closeForm", "Close form")}>
                        <IconButton
                            onClick={() => props.closeForm()}
                            size="small"
                        >
                            <CloseIcon />
                        </IconButton>
                    </Tooltip>
                </Box>
                <Divider />
                <Box className={classes.formContainer}>
                    <Typography className={classes.formTitle} variant="h4">
                        {props.formTitle ||
                            t("createNewConcept", "Create New Concept")}
                    </Typography>
                    <Form
                        onSubmit={(values, form) => {
                            setSubmitting(true)
                            submitConcept(values).catch((error) => {
                                console.log(error)
                                throw error
                            })
                        }}
                        initialValues={item || {}}
                        validate={(values) => {
                            const errors = {} as any
                            if (!values.title) {
                                errors.title = t(
                                    "enterTitleError",
                                    "You must enter a title"
                                )
                            }
                            return errors
                        }}
                        render={({ handleSubmit, values }) => (
                            <form onSubmit={handleSubmit}>
                                <Grid
                                    container
                                    direction="column"
                                    alignContent="stretch"
                                >
                                    {createFromUrl && (
                                        <Grid
                                            item
                                            key="link"
                                            className={classes.formField}
                                        >
                                            <Box
                                                display="flex"
                                                alignItems="center"
                                            >
                                                <FetchLink
                                                    setLoading={setLinkLoading}
                                                    handleLink={(
                                                        link: Link
                                                    ) => {
                                                        handleLink(link)
                                                    }}
                                                    autoFocus={true}
                                                />
                                                <IconButton
                                                    size="small"
                                                    onClick={() => {
                                                        setCreateFromUrl(false)
                                                        setLinkItem(null)
                                                    }}
                                                >
                                                    <CloseIcon fontSize="small" />
                                                </IconButton>
                                            </Box>

                                            {linkLoading && (
                                                <Box
                                                    m={1}
                                                    display="flex"
                                                    justifyContent="center"
                                                >
                                                    <CircularProgress
                                                        disableShrink
                                                        size="1em"
                                                    />{" "}
                                                    {t(
                                                        "fetchingLinkDetails",
                                                        "Fetching Link Details..."
                                                    )}
                                                </Box>
                                            )}
                                        </Grid>
                                    )}
                                    <Grid
                                        item
                                        key="type"
                                        className={classes.formField}
                                    >
                                        <CategoryTreeSelector
                                            value={category?.id ?? null}
                                            setValue={setCategory}
                                            hiddenOptions={
                                                environmentCategories
                                                    ?.filter(
                                                        (x) =>
                                                            currentUser.role !==
                                                                SystemRole.ADMIN &&
                                                            !!x.isManaged
                                                    )
                                                    ?.map((o) => o.id) || []
                                            }
                                        />
                                    </Grid>

                                    <Grid
                                        item
                                        key="title"
                                        className={classes.formField}
                                    >
                                        <TextField
                                            inputProps={{
                                                maxLength:
                                                    ConceptFieldSettings.Title
                                                        .maxLength,
                                                autoFocus: !props.concept,
                                            }}
                                            variant="outlined"
                                            name="title"
                                            onBlur={() => {
                                                setItem({
                                                    ...item,
                                                    title: values.title,
                                                })
                                            }}
                                            multiline={true}
                                            label={t("title", "Title")}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <GenerateAI
                                                            onGenerate={(
                                                                text: string
                                                            ) => {
                                                                setItem({
                                                                    ...item,
                                                                    title: text,
                                                                })
                                                            }}
                                                            maxLength={
                                                                ConceptFieldSettings
                                                                    .Title
                                                                    .maxLength
                                                            }
                                                            onGenerateError={(
                                                                error
                                                            ) =>
                                                                console.log(
                                                                    error
                                                                )
                                                            }
                                                            prompt={getTitlePrompt(
                                                                values.title,
                                                                category?.name
                                                            )}
                                                            disabled={
                                                                values.title
                                                                    ?.length ===
                                                                    0 ||
                                                                !values.title
                                                            }
                                                            tooltip={
                                                                values.title
                                                                    ?.length ===
                                                                    0 ||
                                                                !values.title
                                                                    ? "No text entered"
                                                                    : `Use artifical intelligence to generate a(n) ${
                                                                          category?.name?.toLowerCase() ||
                                                                          "idea"
                                                                      } using the text entered`
                                                            }
                                                        />
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                    </Grid>
                                    <Grid
                                        item
                                        key="summary"
                                        className={classes.formField}
                                    >
                                        <TextField
                                            multiline
                                            rows={4}
                                            inputProps={{
                                                maxLength:
                                                    ConceptFieldSettings.Summary
                                                        .maxLength,
                                            }}
                                            variant="outlined"
                                            name="summary"
                                            label={t(
                                                "summaryTagline",
                                                "Summary (Tagline)"
                                            )}
                                            onBlur={() => {
                                                setItem({
                                                    ...item,
                                                    summary: values.summary,
                                                })
                                            }}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <GenerateAI
                                                            onGenerate={(
                                                                text: string
                                                            ) => {
                                                                setItem({
                                                                    ...item,
                                                                    summary:
                                                                        text,
                                                                })
                                                            }}
                                                            maxLength={
                                                                ConceptFieldSettings
                                                                    .Summary
                                                                    .maxLength
                                                            }
                                                            onGenerateError={(
                                                                error
                                                            ) =>
                                                                console.log(
                                                                    error
                                                                )
                                                            }
                                                            prompt={getSummaryPrompt(
                                                                values.title,
                                                                category?.name
                                                            )}
                                                            disabled={
                                                                values.title
                                                                    ?.length ===
                                                                    0 ||
                                                                !values.title
                                                            }
                                                            tooltip={
                                                                values.title
                                                                    ?.length ===
                                                                    0 ||
                                                                !values.title
                                                                    ? "No text for title entered"
                                                                    : `Use artificial intelligence to generate a summary (tagline) for the ${
                                                                          category?.name?.toLowerCase() ||
                                                                          "idea"
                                                                      } entered in the title field`
                                                            }
                                                        />
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                    </Grid>
                                    <Grid
                                        item
                                        key="tag"
                                        className={classes.formField}
                                    >
                                        <TagInput
                                            setTags={setTags}
                                            disabled={false}
                                            clickable={false}
                                        />
                                    </Grid>
                                </Grid>
                                <Box m="1rem">
                                    {!!ableToPublish && (
                                        <Tooltip
                                            title={
                                                !!isPublic
                                                    ? t(
                                                          "clickToMakePrivate",
                                                          "Click to make PRIVATE"
                                                      )
                                                    : t(
                                                          "clickToMakePublic",
                                                          "Click to make PUBLIC"
                                                      )
                                            }
                                        >
                                            <Button
                                                onClick={() =>
                                                    setIsPublic((prev) => !prev)
                                                }
                                                variant="outlined"
                                                startIcon={
                                                    !!isPublic ? (
                                                        <Public
                                                            color={"primary"}
                                                        />
                                                    ) : (
                                                        <VpnLock color="disabled" />
                                                    )
                                                }
                                            >
                                                {!!isPublic
                                                    ? "Public"
                                                    : "Private"}
                                            </Button>
                                        </Tooltip>
                                    )}
                                    <Button
                                        type="submit"
                                        style={{ float: "right" }}
                                        endIcon={<Send />}
                                    >
                                        {t("submit", "Submit")}
                                    </Button>
                                </Box>
                            </form>
                        )}
                    />
                </Box>
            </Box>
        )
    }
}
