import { CriteriaScore, AccessType } from "../../__generated__/types"
import { useAuth } from "../../providers/AuthProvider"
import { useTheme } from "@material-ui/core/styles"
import {
    ADD_INPUT_CRITERIA,
    ADD_INPUT_PARENT_CONCEPT,
    ADD_INPUT_CRITERIA_SCORES,
    INPUT_SCORE_FRAGMENT,
    CREATE_COLLECTION_CONCEPT,
    UPDATE_INPUT,
    MERGE_INPUT,
    RESPONSE_POLLING,
    CONCEPT_CONNECTIONS,
} from "../inputs/graphql"
import ArrowRightIcon from "@material-ui/icons/ArrowRight"
import AssignmentLateIcon from "@material-ui/icons/AssignmentLate"
import { ADD_CONCEPT_CATEGORY } from "../categories/graphql"

import {
    ConceptConnectionsQuery,
    ConceptConnectionsQueryVariables,
    CreateCollectionConceptMutation,
    CreateCollectionConceptMutationVariables,
    MergeInputMutation,
    MergeInputMutationVariables,
    ResponsePollingQuery,
    ResponsePollingQueryVariables,
    UpdateInputMutation,
    UpdateInputMutationVariables,
} from "../inputs/__generated__/graphql"
import {
    AddConceptCategoryMutation,
    AddConceptCategoryMutationVariables,
} from "../categories/__generated__/graphql"
import { CriteriaType } from "../criteria/types"
import {
    AddInputCriteriaMutation,
    AddInputCriteriaMutationVariables,
    AddInputParentConceptMutation,
    AddInputParentConceptMutationVariables,
    AddInputCriteriaScoresMutation,
    AddInputCriteriaScoresMutationVariables,
} from "../inputs/__generated__/graphql"
import { GRANT_TEAM_ACCESS, GRANT_USER_ACCESS } from "../permissions/graphql"
import {
    GrantTeamAccessMutation,
    GrantTeamAccessMutationVariables,
    GrantUserAccessMutation,
    GrantUserAccessMutationVariables,
} from "../permissions/__generated__/graphql"
import { InputSourceResponseType } from "../criteria/useCriteriaTypes"
import {
    CREATE_CRITERIA_SCORE,
    ADD_CRITERIA_SCORE_CRITERIA,
    ADD_CRITERIA_SCORE_USER,
    UPDATE_CRITERIA_SCORE,
    ADD_CRITERIA_SCORE_SCORED_CONCEPT,
    ADD_CRITERIA_SCORE_CONCEPT,
} from "../criteria/graphql"
import {
    CreateCriteriaScoreMutation,
    CreateCriteriaScoreMutationVariables,
    AddCriteriaScoreCriteriaMutation,
    AddCriteriaScoreCriteriaMutationVariables,
    AddCriteriaScoreUserMutation,
    AddCriteriaScoreUserMutationVariables,
    UpdateCriteriaScoreMutation,
    UpdateCriteriaScoreMutationVariables,
    AddCriteriaScoreScoredConceptMutation,
    AddCriteriaScoreScoredConceptMutationVariables,
    AddCriteriaScoreConceptsMutation,
    AddCriteriaScoreConceptsMutationVariables,
} from "../criteria/__generated__/graphql"
import { ScoreSelectorDefaultValues } from "./types"
import { useMutation, useReactiveVar, useApolloClient } from "@apollo/client"
import AssignmentTurnedInIcon from "@material-ui/icons/AssignmentTurnedIn"
import {
    IconButton,
    Box,
    Typography,
    Button,
    List,
    Divider,
    useMediaQuery,
} from "@material-ui/core"
import { useParams } from "react-router-dom"
import { useState, useCallback, useMemo, useEffect } from "react"
import { CriteriaScoreInputVariables } from "./types"
import {
    allPageConceptConnectionsVar,
    FeedbackField,
    myCurrentInputResponseLabelVar,
    myCurrentInputResponseVar,
    userConceptPermissionsVar,
    workspaceLoadTimeVar,
} from "../../providers/GlobalState"
import { CONCEPT_BY_ID } from "../../graphql/queries"

import ClickableRichTooltip from "../Popper/ClickableRichTooltip"
import { ADD_CONCEPT_CHILD } from "../workspace/graphql"
import {
    AddConceptChildMutation,
    AddConceptChildMutationVariables,
} from "../workspace/__generated__/graphql"
import ConceptListItem from "../ConceptListItem"
import {
    ConceptQuery,
    ConceptQueryVariables,
} from "../../graphql/__generated__/queries"
import useAwaitTranslation from "../../i18n/useAwaitTranslation"
import useCategoryTools from "../categories/useCategoryTools"
import useEnvironmentSettingTools from "../settings/useEnvironmentSettingTools"
import useMountedState from "../../util/useMountedState"
import {
    checkFieldCompletion,
    getCleanedFieldConfig,
    getCollectionConcepts,
    getConceptsToScore,
    getInputId,
    getSelectedResponseScores,
} from "./util"
import Edit from "@material-ui/icons/Edit"
import FieldMessage from "./FieldMessage"
import ResponseResetButton from "./ResponseResetButton"
import { CONNECT_CONCEPTS, DISCONNECT_CONCEPTS } from "../../graphql/mutations"
import {
    AddConceptConnectionsMutation,
    AddConceptConnectionsMutationVariables,
    RemoveConceptConnectionsMutation,
    RemoveConceptConnectionsMutationVariables,
} from "../../graphql/__generated__/mutations"
import useFilters from "../filters/util/useFilters"
import ResponsePreviewer from "./ResponsePreviewer"

export function useInputTools(props: {
    criteriaId?: string
    inputConfig?: any
    conceptCategoryId?: string
    isDialog?: boolean
}) {
    /////// HOOKS ///////////
    const { primaryResponseText } = useEnvironmentSettingTools()
    const { t } = useAwaitTranslation("feedback")
    const { criteriaId, inputConfig } = props

    const { currentUser } = useAuth()
    const { conceptId: pageConceptId } = useParams()
    const client = useApolloClient()
    const theme = useTheme()
    const mobile = useMediaQuery(theme.breakpoints.down("sm"))
    const { getPermissionsFilter } = useFilters()
    const { getCategoryArray, allEnvironmentFieldIds } = useCategoryTools()
    const isMounted = useMountedState()
    ////////////////////////////

    //////// LOCAL AND GLOBAL STATE /////////////
    const [mutating, setMutating] = useState(false)
    const [editing, setEditing] = useState(false)

    const { conceptId: currentFieldDataConceptId, currentResponseData } =
        useReactiveVar(myCurrentInputResponseVar)

    const { connections } = useReactiveVar(allPageConceptConnectionsVar)
    const currentLabel = useReactiveVar(myCurrentInputResponseLabelVar)
    const { contributingPermission } = useReactiveVar(userConceptPermissionsVar)
    const { editingPermission } = useReactiveVar(userConceptPermissionsVar)
    const initialWorkspaceLoadTime = useReactiveVar(workspaceLoadTimeVar)
    ////////////////////////////

    //// MUTATIONS //////////////////////////////
    const [addInputCriteria] = useMutation<
        AddInputCriteriaMutation,
        AddInputCriteriaMutationVariables
    >(ADD_INPUT_CRITERIA)
    const [mergeInput] = useMutation<
        MergeInputMutation,
        MergeInputMutationVariables
    >(MERGE_INPUT)
    const [updateInput] = useMutation<
        UpdateInputMutation,
        UpdateInputMutationVariables
    >(UPDATE_INPUT)
    const [addInputToConcept] = useMutation<
        AddInputParentConceptMutation,
        AddInputParentConceptMutationVariables
    >(ADD_INPUT_PARENT_CONCEPT)
    const [createCriteriaScore] = useMutation<
        CreateCriteriaScoreMutation,
        CreateCriteriaScoreMutationVariables
    >(CREATE_CRITERIA_SCORE)
    const [addUserToScore] = useMutation<
        AddCriteriaScoreUserMutation,
        AddCriteriaScoreUserMutationVariables
    >(ADD_CRITERIA_SCORE_USER)
    const [addScoreToCriteria] = useMutation<
        AddCriteriaScoreCriteriaMutation,
        AddCriteriaScoreCriteriaMutationVariables
    >(ADD_CRITERIA_SCORE_CRITERIA)
    const [addConceptCategory] = useMutation<
        AddConceptCategoryMutation,
        AddConceptCategoryMutationVariables
    >(ADD_CONCEPT_CATEGORY)
    const [createConcept] = useMutation<
        CreateCollectionConceptMutation,
        CreateCollectionConceptMutationVariables
    >(CREATE_COLLECTION_CONCEPT)
    const [disconnectConcepts, { loading: disconnecting }] = useMutation<
        RemoveConceptConnectionsMutation,
        RemoveConceptConnectionsMutationVariables
    >(DISCONNECT_CONCEPTS)
    const [connectConcepts, { loading: connecting }] = useMutation<
        AddConceptConnectionsMutation,
        AddConceptConnectionsMutationVariables
    >(CONNECT_CONCEPTS)
    const [addConceptAsChild] = useMutation<
        AddConceptChildMutation,
        AddConceptChildMutationVariables
    >(ADD_CONCEPT_CHILD)

    //for explicitly scoring a concept - such as impact for a feature
    const [addCriteriaScoreScoredConcept] = useMutation<
        AddCriteriaScoreScoredConceptMutation,
        AddCriteriaScoreScoredConceptMutationVariables
    >(ADD_CRITERIA_SCORE_SCORED_CONCEPT)
    //for adding concepts to a collection score - such as adding a feature to a collection score of criteria Feature List on category Solution
    const [addCriteriaScoreConcept] = useMutation<
        AddCriteriaScoreConceptsMutation,
        AddCriteriaScoreConceptsMutationVariables
    >(ADD_CRITERIA_SCORE_CONCEPT)
    const [updateScore, { loading: updatingScore }] = useMutation<
        UpdateCriteriaScoreMutation,
        UpdateCriteriaScoreMutationVariables
    >(UPDATE_CRITERIA_SCORE)
    const [grantUserAccess] = useMutation<
        GrantUserAccessMutation,
        GrantUserAccessMutationVariables
    >(GRANT_USER_ACCESS)
    const [grantTeamAccess] = useMutation<
        GrantTeamAccessMutation,
        GrantTeamAccessMutationVariables
    >(GRANT_TEAM_ACCESS)

    const [addScoreToInput] = useMutation<
        AddInputCriteriaScoresMutation,
        AddInputCriteriaScoresMutationVariables
    >(ADD_INPUT_CRITERIA_SCORES, {
        update(cache, { data }) {
            const newScore = data?.AddInputCriteriaScores?.to

            const { CriteriaScore: currentScores } = cache.readQuery<
                ResponsePollingQuery,
                ResponsePollingQueryVariables
            >({
                query: RESPONSE_POLLING,
                variables: {
                    filter: {
                        input: {
                            parentConcept: {
                                id: pageConceptId,
                            },
                        },
                        user_not: null,
                    },
                },
                returnPartialData: true,
            })

            if (!!currentScores && !!newScore) {
                cache.writeQuery({
                    query: RESPONSE_POLLING,
                    variables: {
                        filter: {
                            input: {
                                parentConcept: {
                                    id: pageConceptId,
                                },
                            },
                            user_not: null,
                        },
                    },
                    data: {
                        CriteriaScore: [...currentScores, newScore],
                    },
                })
            }
        },
    })

    //////////////// VARIABLES /////////////////
    const currentField = currentResponseData.find(
        (item) =>
            getInputId(
                item.input.criteria?.id,
                pageConceptId,
                JSON.parse(item.input.inputConfig)
            ) === getInputId(criteriaId, pageConceptId, inputConfig)
    )

    const currentInput = currentField?.input ?? null
    const criteria = currentInput?.criteria ?? null

    const cleanedInputConfig = {
        name: criteria?.name,
        ...(inputConfig ?? {}),
        ...getCleanedFieldConfig(currentField),
    }
    const viewingPrimaryResponse =
        !currentLabel?.user &&
        currentLabel?.label === ScoreSelectorDefaultValues.primaryResponse
    const viewingAllResponses =
        !currentLabel?.user &&
        currentLabel?.label === ScoreSelectorDefaultValues.allResponses

    const sourceResponseType = !!cleanedInputConfig?.source
        ?.inputSourceResponseType
        ? cleanedInputConfig?.source?.inputSourceResponseType
        : InputSourceResponseType.primaryResponseOnly

    const isSubfield =
        criteria?.criteriaType !== CriteriaType.Collection &&
        cleanedInputConfig?.source?.criteriaIds?.length > 0

    const fieldIsArchived = !!criteria?.isArchived
    const fieldIsUnavailable = !allEnvironmentFieldIds.includes(criteriaId)

    const scores = getSelectedResponseScores(
        currentField,
        currentLabel
    )?.filter((x) => (!!viewingAllResponses ? !x.conceptDefault : true))

    const currentScore = !!viewingAllResponses ? null : scores?.[0]

    let sourceField = null

    if (!!isSubfield) {
        sourceField = currentResponseData.find(
            (field) =>
                !!cleanedInputConfig?.source?.criteriaIds?.find(
                    (item) => item.id === field.input?.criteria?.id
                )
        )
    }
    const conceptsToScore = useMemo(
        () =>
            !!isSubfield && !!sourceField
                ? getConceptsToScore(
                      sourceField,
                      currentField,
                      connections,
                      currentLabel,
                      initialWorkspaceLoadTime
                  )
                : [],
        [
            sourceField,
            isSubfield,
            currentField,
            connections,
            initialWorkspaceLoadTime,
            currentLabel,
        ]
    )

    const categoryArray = getCategoryArray(props.conceptCategoryId)
    const categoryFields = categoryArray.flatMap(
        (category) => category.criteria
    )
    const fieldHasBeenRemoved = !categoryFields.find(
        (field) =>
            (!isSubfield && field.id === criteriaId) ||
            (!!isSubfield &&
                field.id === sourceField?.input?.criteria?.id &&
                !!field.subfields.find(
                    (subfield) => subfield.id === criteriaId
                ))
    )

    ///// CONCEPTS FROM SOURCE SCORE THAT A SUB CRITERIA NEEDS TO EVALUATE ///

    const conceptsScored =
        !criteria || !isSubfield
            ? []
            : [CriteriaType.Ranking, CriteriaType.Grouping].includes(
                  criteria?.criteriaType as CriteriaType
              )
            ? conceptsToScore?.filter((concept) =>
                  checkFieldCompletion({
                      score: currentScore,
                      criteriaType: criteria.criteriaType as CriteriaType,
                      config: cleanedInputConfig,
                      conceptsToRankOrGroup: [concept],
                      currentLabel,
                  })
              )
            : conceptsToScore?.filter((concept) =>
                  scores
                      ?.filter((score) =>
                          checkFieldCompletion({
                              score,
                              criteriaType:
                                  criteria?.criteriaType as CriteriaType,
                              config: cleanedInputConfig,
                              currentLabel,
                          })
                      )
                      .some((score) => score.scoredConcept?.id === concept.id)
              )

    const userCanEditResponse =
        (!!contributingPermission &&
            currentLabel?.user?.userId === currentUser.userId &&
            !!cleanedInputConfig?.allowMultipleResponses) ||
        (!!viewingPrimaryResponse && !!editingPermission)
    const collectionCount =
        criteria?.criteriaType === CriteriaType.Collection
            ? getCollectionConcepts(
                  currentScore,
                  cleanedInputConfig,
                  connections,
                  currentLabel,
                  initialWorkspaceLoadTime
              )?.length ?? 0
            : 0
    const primaryResponseNeeded =
        criteria?.criteriaType !== CriteriaType.Collection ||
        !!cleanedInputConfig?.requirePrimaryResponse ||
        !!cleanedInputConfig?.singleSelection ||
        (criteria?.criteriaType === CriteriaType.Collection &&
            collectionCount === 0)

    const showInterface =
        !!currentInput &&
        !!userCanEditResponse &&
        !viewingAllResponses &&
        (!!editing ||
            (!currentScore &&
                (!!primaryResponseNeeded || !viewingPrimaryResponse)))

    const showLoading =
        (currentFieldDataConceptId !== pageConceptId ||
            !currentInput ||
            !!mutating ||
            !!updatingScore ||
            !!connecting ||
            !!disconnecting ||
            (!!isSubfield && !conceptsToScore) ||
            (!conceptsToScore && !!isSubfield)) &&
        !fieldIsUnavailable
    const fieldCompleted = !isSubfield
        ? checkFieldCompletion({
              score: currentScore,
              criteriaType: criteria?.criteriaType as CriteriaType,
              config: cleanedInputConfig,
              collectionSpecificData: {
                  connections,
                  initialWorkspaceLoadTime,
              },
              currentLabel,
          })
        : conceptsScored?.length > 0 &&
          conceptsScored?.length === conceptsToScore?.length

    /////////////////////////////

    //////// LOGIC TO SHOW BLOCKER OR NOT SUCH AS WAITING ON A SCORE ////////////////
    let responseStatusMessageInterface = null

    const messageIntefaceStyles = {
        display: "flex",
        zIndex: theme.zIndex.appBar - 2,
        alignItems: "center",
        justifyContent: "center",
        height: "100%",
        width: "100%",
        padding: 5,
    }

    const messageTextInterfaceStyles = {
        border: `1px solid ${theme.palette.divider}`,
        borderRadius: theme.shape.borderRadius,
        backgroundColor: theme.palette.background.paper,
        margin: theme.spacing(2),
        textAlign: "center" as const,
        padding: theme.spacing(1),
        boxShadow: theme.shadows[12],
    }

    // SUB FIELDS ONLY
    let waitingOnAnotherResponse = false
    let responsesDependentOn: FeedbackField[] = null
    let responsesStillWaitingOn: FeedbackField[] = null
    if (!showLoading) {
        if (!!fieldIsUnavailable) {
            responseStatusMessageInterface = (
                <Box style={{ ...messageIntefaceStyles }}>
                    <Box style={messageTextInterfaceStyles}>
                        <Typography>
                            {t(
                                "thisFieldIsUnavailable",
                                "This field is unavailable"
                            )}
                        </Typography>
                    </Box>
                </Box>
            )
        } else if (!!isSubfield) {
            responsesDependentOn = currentResponseData.filter(
                (item) =>
                    cleanedInputConfig.source.criteriaIds.find(
                        (o) => o.id === item.input.criteria?.id
                    ) &&
                    (item.input.criteria?.criteriaType !==
                        CriteriaType.Collection ||
                        currentInput?.criteria?.criteriaType !==
                            CriteriaType.Collection)
            )

            responsesStillWaitingOn =
                responsesDependentOn?.filter((field) =>
                    !!viewingPrimaryResponse ||
                    sourceResponseType ===
                        InputSourceResponseType.primaryResponseOnly
                        ? !checkFieldCompletion({
                              score:
                                  field.scores?.find(
                                      (o) => !!o.conceptDefault
                                  ) || null,
                              criteriaType: field.input.criteria
                                  ?.criteriaType as CriteriaType,
                              config: getCleanedFieldConfig(field),
                              collectionSpecificData: {
                                  connections,
                                  initialWorkspaceLoadTime,
                              },
                              currentLabel,
                          })
                        : !checkFieldCompletion({
                              score: getSelectedResponseScores(
                                  field,
                                  currentLabel
                              )?.[0],
                              criteriaType: field.input.criteria
                                  ?.criteriaType as CriteriaType,
                              config: getCleanedFieldConfig(field),
                              collectionSpecificData: {
                                  connections,
                                  initialWorkspaceLoadTime,
                              },
                              currentLabel,
                          })
                ) ?? []

            waitingOnAnotherResponse = responsesStillWaitingOn.length > 0
        }
    }
    ////////////////////////////////////////////

    if (!!viewingAllResponses) {
        if (scores?.length === 0) {
            responseStatusMessageInterface = (
                <Box style={{ ...messageIntefaceStyles }}>
                    <Box style={messageTextInterfaceStyles}>
                        <Typography>
                            {t("noResponsesYet", "No Responses Yet")}
                        </Typography>
                    </Box>
                </Box>
            )
        }
        // only want to show a blocker if they dont have a response or if it hasnt been submitted
    } else if (
        (!isSubfield && !fieldCompleted) ||
        (!!isSubfield && conceptsScored?.length === 0)
    ) {
        if (!!viewingPrimaryResponse) {
            if (!userCanEditResponse && !!primaryResponseNeeded) {
                responseStatusMessageInterface = (
                    <Box style={{ ...messageIntefaceStyles }}>
                        <Box style={messageTextInterfaceStyles}>
                            <Typography>
                                {`${t("no", "No")} ${primaryResponseText} ${t(
                                    "responseProvided",
                                    "response provided"
                                )}`}
                            </Typography>
                        </Box>
                    </Box>
                )
            } else if (!!userCanEditResponse) {
                if (
                    (scores?.length === 0 || !scores) &&
                    !!primaryResponseNeeded &&
                    !waitingOnAnotherResponse
                ) {
                    responseStatusMessageInterface = (
                        <Button
                            style={{ ...messageIntefaceStyles }}
                            onClick={() => {
                                onCreateNewResponse(
                                    {},
                                    // if there is no parent criteria, then theres no secondary source concept being evaluated
                                    !isSubfield
                                        ? null
                                        : conceptsToScore?.[0]?.id
                                )
                                setEditing(true)
                            }}
                        >
                            {t(
                                "clickToProvideAResponse",
                                "Click to provide a response"
                            )}
                        </Button>
                    )
                } else if (
                    !!primaryResponseNeeded &&
                    !!waitingOnAnotherResponse
                ) {
                    responseStatusMessageInterface = (
                        <Box style={{ ...messageIntefaceStyles }}>
                            <Box
                                display="flex"
                                flexDirection="column"
                                alignItems={"center"}
                                style={messageTextInterfaceStyles}
                            >
                                <Typography>
                                    {`${primaryResponseText} ${t(
                                        "responseRequiredForTheFollowing",
                                        "response required for the following"
                                    )}:`}
                                </Typography>
                                <Box mt={1} textAlign="center">
                                    {responsesDependentOn?.map(
                                        (field, index) => {
                                            return (
                                                <FieldMessage
                                                    key={
                                                        field.input?.id + index
                                                    }
                                                    field={field}
                                                    fieldsWaitingOn={
                                                        responsesStillWaitingOn
                                                    }
                                                />
                                            )
                                        }
                                    )}
                                </Box>
                            </Box>
                        </Box>
                    )
                }
            }
        } else if (!viewingPrimaryResponse) {
            if (!userCanEditResponse) {
                responseStatusMessageInterface = (
                    <Box style={{ ...messageIntefaceStyles }}>
                        <Box style={messageTextInterfaceStyles}>
                            <Typography>
                                {!cleanedInputConfig?.allowMultipleResponses
                                    ? `${primaryResponseText} ${t(
                                          "responseHasNotBeenProvided",
                                          "response has not been provided"
                                      )}`
                                    : `${currentLabel?.user?.firstName} ${
                                          currentLabel?.user?.lastName
                                      } ${t(
                                          "hasNotProvidedAResponse",
                                          "has not provided a response"
                                      )}`}
                            </Typography>
                        </Box>
                    </Box>
                )
            } else if (!!userCanEditResponse) {
                if (!!waitingOnAnotherResponse) {
                    responseStatusMessageInterface = (
                        <Box style={{ ...messageIntefaceStyles }}>
                            <Box
                                display="flex"
                                flexDirection="column"
                                alignItems={"center"}
                                style={messageTextInterfaceStyles}
                            >
                                <Typography>
                                    {cleanedInputConfig?.source
                                        ?.inputSourceResponseType ===
                                    InputSourceResponseType.primaryResponseOnly
                                        ? `${primaryResponseText} ${t(
                                              "responseRequiredForTheFollowing",
                                              "response required for the following"
                                          )}:`
                                        : `${t(
                                              "youMustProvideAResponseForTheFollowing",
                                              "You must provide a response for the following"
                                          )}:`}
                                </Typography>
                                <Box mt={1} textAlign="center">
                                    {responsesDependentOn?.map(
                                        (field, index) => {
                                            return (
                                                <FieldMessage
                                                    key={
                                                        field.input?.id + index
                                                    }
                                                    field={field}
                                                    fieldsWaitingOn={
                                                        responsesStillWaitingOn
                                                    }
                                                />
                                            )
                                        }
                                    )}
                                </Box>
                            </Box>
                        </Box>
                    )
                } else if (scores?.length === 0 || !scores) {
                    responseStatusMessageInterface = (
                        <Button
                            style={{ ...messageIntefaceStyles }}
                            onClick={() => {
                                onCreateNewResponse(
                                    {},
                                    // if there is no parent criteria, then theres no secondary source concept being evaluated
                                    !isSubfield
                                        ? null
                                        : conceptsToScore?.[0]?.id
                                )
                                setEditing(true)
                            }}
                        >
                            {t(
                                "clickToProvideAResponse",
                                "Click to provide a response"
                            )}
                        </Button>
                    )
                }
            }
        }
    }
    /////////////////////////////////////////////

    ///////////// CALLBACK FUNCTIONS ////////////
    const onDisconnectConcept = useCallback(
        (concept) => {
            client.cache.updateQuery<
                ConceptConnectionsQuery,
                ConceptConnectionsQueryVariables
            >(
                {
                    query: CONCEPT_CONNECTIONS,
                    variables: {
                        filter: {
                            connections_some: {
                                id: pageConceptId,
                            },
                            ...getPermissionsFilter(),
                        },
                        pageConceptId,
                    },
                },
                (data) => {
                    return {
                        Concept: [
                            ...data?.Concept?.filter(
                                (c) => c.id !== concept?.id
                            ),
                        ],
                    }
                }
            )
            disconnectConcepts({
                variables: !!concept.detailedConnections?.from?.find(
                    (o) => o.Concept.id === pageConceptId
                )
                    ? {
                          toId: concept.id,
                          fromId: pageConceptId,
                      }
                    : {
                          toId: pageConceptId,
                          fromId: concept.id,
                      },
            })
        },
        [disconnectConcepts, pageConceptId, getPermissionsFilter, client.cache]
    )

    const onConnectConcept = useCallback(
        (concept) => {
            client.cache.updateQuery<
                ConceptConnectionsQuery,
                ConceptConnectionsQueryVariables
            >(
                {
                    query: CONCEPT_CONNECTIONS,
                    variables: {
                        filter: {
                            connections_some: {
                                id: pageConceptId,
                            },
                            ...getPermissionsFilter(),
                        },
                        pageConceptId,
                    },
                },
                (data) => {
                    return {
                        ...data,
                        Concept: [
                            ...data?.Concept,
                            {
                                ...concept,
                                detailedConnections: {
                                    __typename:
                                        "_ConceptDetailedConnectionsDirections",
                                    to: [
                                        {
                                            __typename:
                                                "_ConceptDetailedConnections",
                                            Concept: {
                                                __typename: "Concept",
                                                id: pageConceptId,
                                            },
                                        },
                                    ],
                                    from: [],
                                },
                            },
                        ],
                    }
                }
            )
            connectConcepts({
                variables: {
                    fromId: concept.id,
                    toId: pageConceptId,
                },
            })
        },
        [client.cache, connectConcepts, pageConceptId, getPermissionsFilter]
    )
    const onCreateNewInput = useCallback(
        async (criteriaId: string, inputConfig: string) => {
            const inputId = getInputId(
                criteriaId,
                pageConceptId,
                JSON.parse(inputConfig ?? "{}")
            )

            const {
                data: { MergeInput: NewInput },
            } = await mergeInput({
                variables: {
                    id: inputId,
                    inputConfig,
                },
            })

            await Promise.all([
                addInputToConcept({
                    variables: {
                        inputId: NewInput.id,
                        parentConceptId: pageConceptId,
                    },
                }),
                addInputCriteria({
                    variables: {
                        inputId: NewInput.id,
                        criteriaId: criteriaId,
                    },
                }),
            ])
        },
        [addInputCriteria, addInputToConcept, mergeInput, pageConceptId]
    )
    const onCreateNewCollectionConcept = useCallback(
        async (props: {
            title: string
            categoryId: string
            parentId?: string
        }) => {
            const { title, categoryId, parentId } = props

            const {
                data: { CreateConcept: NewConcept },
            } = await createConcept({
                variables: {
                    userId: currentUser.userId,
                    concept: {
                        title: title,
                        summary: "",
                        isPublic: false,
                        type: "Idea",
                        isPublicAccessType: AccessType.NONE,
                    },
                    tags: [],
                },
            })

            const pageConcept = client.readQuery<
                ConceptQuery,
                ConceptQueryVariables
            >({
                query: CONCEPT_BY_ID,
                variables: { id: pageConceptId },
            })

            const pageConceptUserOwnerIds: string[] =
                pageConcept?.Concept?.[0]?.addedUsers
                    ?.filter(
                        (addedUser) =>
                            addedUser.type === AccessType.OWNER &&
                            addedUser.User.userId !== currentUser.userId
                    )
                    ?.map((addedUser) => addedUser.User.userId) || []
            if (pageConceptUserOwnerIds.length > 0) {
                pageConceptUserOwnerIds.map((id) =>
                    grantUserAccess({
                        variables: {
                            userId: id,
                            conceptId: NewConcept.id,
                            accessType: AccessType.OWNER,
                        },
                    })
                )
            }
            const pageConceptTeamOwnerIds: string[] =
                pageConcept?.Concept?.[0]?.addedTeams
                    ?.filter((addedTeam) => addedTeam.type === AccessType.OWNER)
                    ?.map((addedTeam) => addedTeam.Team.teamId)
            if (pageConceptTeamOwnerIds.length > 0) {
                pageConceptTeamOwnerIds.map((id) =>
                    grantTeamAccess({
                        variables: {
                            teamId: id,
                            conceptId: NewConcept.id,
                            accessType: AccessType.OWNER,
                        },
                    })
                )
            }
            // have to await both due to refetching of collection query
            await Promise.all([
                addConceptCategory({
                    variables: {
                        categoryId: categoryId,
                        id: NewConcept.id,
                    },
                }),
                grantUserAccess({
                    variables: {
                        userId: currentUser.userId,
                        conceptId: NewConcept.id,
                        accessType: AccessType.OWNER,
                    },
                }),
            ])
            if (!!parentId) {
                addConceptAsChild({
                    variables: {
                        childId: NewConcept.id,
                        parentId,
                    },
                })
            }

            return NewConcept
        },
        [
            addConceptCategory,
            createConcept,
            grantUserAccess,
            grantTeamAccess,
            currentUser.userId,
            addConceptAsChild,
            client,
            pageConceptId,
        ]
    )

    const onCreateNewResponse = useCallback(
        async (
            criteriaScoreInput: CriteriaScoreInputVariables,
            scoredConceptId?: string,
            field?: FeedbackField
        ) => {
            if (!mutating) {
                setMutating(true)
                const {
                    data: { CreateCriteriaScore: NewCriteriaScore },
                } = await createCriteriaScore({
                    variables: {
                        ...criteriaScoreInput,
                        label:
                            currentLabel?.label !==
                                ScoreSelectorDefaultValues.primaryResponse &&
                            !!currentLabel?.user
                                ? currentLabel.label
                                : null,
                        conceptDefault:
                            currentLabel?.label ===
                                ScoreSelectorDefaultValues.primaryResponse &&
                            !currentLabel?.user
                                ? true
                                : false,
                    },
                })
                let promises = [
                    addScoreToCriteria({
                        variables: {
                            criteriaId:
                                field?.input?.criteria?.id || criteria.id,
                            criteriaScoreId: NewCriteriaScore.id,
                        },
                    }),
                    addUserToScore({
                        variables: {
                            userId: currentUser.userId,
                            criteriaScoreId: NewCriteriaScore.id,
                        },
                    }),
                    addCriteriaScoreScoredConcept({
                        variables: {
                            criteriaScoreId: NewCriteriaScore.id,
                            conceptId: scoredConceptId || pageConceptId,
                        },
                    }),
                ]
                //adding all concepts being ranked to the score - only required for ranking
                //collections add concepts to the score when concepts are selected
                if (
                    [CriteriaType.Ranking, CriteriaType.Grouping].includes(
                        criteria?.criteriaType as CriteriaType
                    )
                ) {
                    promises = [
                        ...promises,
                        ...conceptsToScore?.map((concept) =>
                            addCriteriaScoreConcept({
                                variables: {
                                    conceptId: concept.id,
                                    criteriaScoreId: NewCriteriaScore.id,
                                },
                            })
                        ),
                    ]
                }
                await Promise.all(promises)

                await addScoreToInput({
                    variables: {
                        inputId: field?.input?.id || currentInput?.id,
                        criteriaScoreId: NewCriteriaScore.id,
                    },
                })

                if (!!isMounted()) {
                    setMutating(false)
                }
            }
        },
        [
            createCriteriaScore,
            addScoreToCriteria,
            addCriteriaScoreScoredConcept,
            addUserToScore,
            currentInput?.id,
            criteria?.id,
            currentLabel,
            currentUser.userId,
            addScoreToInput,
            pageConceptId,
            criteria?.criteriaType,
            conceptsToScore,
            addCriteriaScoreConcept,
            isMounted,
            mutating,
        ]
    )
    const onEditResponse = useCallback(
        (
            criteriaScoreInput: CriteriaScoreInputVariables,
            score: CriteriaScore,
            skipCacheUpdate?: boolean
        ) => {
            if (!!criteriaScoreInput.isArchived) {
                client.cache.evict({
                    id: client.cache.identify(score),
                })
            } else if (!skipCacheUpdate) {
                client.writeFragment({
                    id: client.cache.identify(score),
                    fragment: INPUT_SCORE_FRAGMENT,
                    fragmentName: "InputCriteriaScore",
                    data: {
                        ...score,
                        ...criteriaScoreInput,
                    },
                })
            }

            updateScore({
                variables: {
                    id: score?.id,
                    ...criteriaScoreInput,
                    lastUpdatedByUserId: currentUser.userId,
                    lastUpdated: {
                        year: new Date().getUTCFullYear(),
                        month: new Date().getUTCMonth() + 1,
                        day: new Date().getUTCDate(),
                        hour: new Date().getUTCHours(),
                        minute: new Date().getUTCMinutes(),
                        second: new Date().getUTCSeconds(),
                    },
                },
            })
        },
        [updateScore, client, currentUser.userId]
    )
    const onResetResponse = useCallback(
        (scoresToReset) => {
            if (criteria?.criteriaType === CriteriaType.Collection) {
                const categoryConnections = connections?.filter(
                    (o) =>
                        cleanedInputConfig?.categories?.find(
                            (i) => i.id === o.category?.id
                        )?.id
                )
                if (viewingPrimaryResponse && categoryConnections?.length > 0) {
                    categoryConnections?.map((x) => {
                        return onDisconnectConcept(x)
                    })
                }
            }
            if (!!scoresToReset?.length) {
                scoresToReset.map((o) => {
                    return onEditResponse({ isArchived: true }, o)
                })
            }
        },
        [
            onEditResponse,
            viewingPrimaryResponse,
            connections,
            onDisconnectConcept,
            cleanedInputConfig?.categories,
            criteria?.criteriaType,
        ]
    )
    const onUpdateInput = useCallback(
        async (updatedInput: UpdateInputMutationVariables) => {
            await updateInput({
                variables: {
                    ...updatedInput,
                },
            })
        },
        [updateInput]
    )

    //////////////////////////////////////////////

    ////////// INTERACTION TOOLS //////////////
    let interactionTools = []
    const conceptsNotRespondedTo =
        conceptsToScore?.filter(
            (conceptToEvaluate) =>
                !conceptsScored.find(
                    (conceptScored) => conceptScored.id === conceptToEvaluate.id
                )
        ) || []
    const conceptsMissing =
        !!isSubfield && conceptsNotRespondedTo.length > 0 && scores?.length > 0
    const showWarning =
        conceptsMissing || !!fieldHasBeenRemoved || !!fieldIsArchived

    if (!!showWarning) {
        interactionTools.push(
            <ClickableRichTooltip
                placement="bottom-end"
                content={
                    <Box
                        width={400}
                        maxHeight={300}
                        display="flex"
                        flexDirection={"column"}
                        overflow="hidden"
                    >
                        {(!!fieldHasBeenRemoved || !!fieldIsArchived) && (
                            <>
                                <Box
                                    width="100%"
                                    p={1}
                                    display="flex"
                                    alignItems="center"
                                >
                                    <ArrowRightIcon
                                        fontSize="small"
                                        style={{ marginRight: 2 }}
                                    />
                                    <Typography variant="body2">
                                        {!!fieldIsArchived
                                            ? "This field has been deleted"
                                            : !!isSubfield
                                            ? `This field has been removed as a subfield of ${
                                                  JSON.parse(
                                                      sourceField?.input
                                                          ?.inputConfig ?? "{}"
                                                  )?.name
                                              }`
                                            : "This field has been removed from this category"}
                                    </Typography>
                                </Box>
                                <Divider />
                            </>
                        )}
                        {!!conceptsMissing && (
                            <>
                                <Box
                                    width="100%"
                                    p={1}
                                    display="flex"
                                    alignItems={"center"}
                                >
                                    <ArrowRightIcon
                                        fontSize="small"
                                        style={{ marginRight: 2 }}
                                    />
                                    <Typography variant="body2">
                                        {conceptsNotRespondedTo.length > 1
                                            ? `${
                                                  conceptsNotRespondedTo.length
                                              } ${t(
                                                  "conceptsHaveNotBeenEvaluated",
                                                  "concepts have not been evaluated"
                                              )}`
                                            : `1 ${t(
                                                  "conceptHasNotBeenEvaluated",
                                                  "concept has not been evaluated"
                                              )}`}
                                    </Typography>
                                </Box>
                                <Divider />
                                <List
                                    disablePadding
                                    dense
                                    style={{ overflowY: "auto" }}
                                >
                                    {conceptsNotRespondedTo.map(
                                        (concept, index) => {
                                            return (
                                                <ConceptListItem
                                                    hideDivider={true}
                                                    key={concept.id}
                                                    item={concept}
                                                />
                                            )
                                        }
                                    )}
                                </List>
                            </>
                        )}
                    </Box>
                }
                key={"missing_concepts"}
            >
                <IconButton>
                    <AssignmentLateIcon color="error" />
                </IconButton>
            </ClickableRichTooltip>
        )
    }

    if (!responseStatusMessageInterface) {
        if (
            !!viewingPrimaryResponse &&
            !isSubfield &&
            !!cleanedInputConfig?.allowMultipleResponses &&
            (!props.isDialog || !editing || !!mobile)
        ) {
            interactionTools.push(
                <span
                    key={"other_responses_preview"}
                    style={{
                        display: "flex",
                        alignItems: "center",
                        marginRight: theme.spacing(1),
                    }}
                >
                    <ResponsePreviewer
                        popper={true}
                        inputId={currentInput?.id}
                    />
                </span>
            )
        }
        if (!viewingAllResponses && !!userCanEditResponse) {
            if (
                scores?.length > 0 ||
                (criteria?.criteriaType === CriteriaType.Collection &&
                    !primaryResponseNeeded)
            ) {
                if (!editing && !props.isDialog) {
                    interactionTools.push(
                        <span key={"edit_response"}>
                            <IconButton
                                disabled={!!showLoading}
                                onClick={() => {
                                    setEditing(true)
                                    if (!currentScore) {
                                        onCreateNewResponse({})
                                    }
                                }}
                            >
                                <Edit />
                            </IconButton>
                        </span>
                    )
                } else {
                    interactionTools.push(
                        <span key={"reset_response"}>
                            <ResponseResetButton
                                onReset={() => {
                                    onResetResponse(scores)
                                }}
                                key={"reset"}
                                loading={showLoading}
                            />
                        </span>
                    )
                    if (!props.isDialog) {
                        interactionTools.push(
                            <span key={"save_response"}>
                                <IconButton
                                    key={"save"}
                                    disabled={!!showLoading}
                                    onClick={() => setEditing(false)}
                                >
                                    <AssignmentTurnedInIcon />
                                </IconButton>
                            </span>
                        )
                    }
                }
            }
        }
    }
    //////////////////////////////////////////
    useEffect(() => {
        if (!!props.isDialog && !!userCanEditResponse) {
            setEditing(true)
        }
    }, [props.isDialog, userCanEditResponse])
    return {
        input: currentInput,
        sourceField,
        criteria,
        scores,
        interactionTools: !!showLoading ? [] : interactionTools,
        updatingScore,
        mutating,
        showInterface,
        fieldCompleted,
        showLoading,
        cleanedInputConfig,
        conceptsToScore,
        conceptsScored,
        responseStatusMessageInterface,
        userCanEditResponse,
        collectionCount,
        onUpdateInput,
        onCreateNewInput,
        onCreateNewCollectionConcept,
        onEditResponse,
        onCreateNewResponse,
        onConnectConcept,
        onDisconnectConcept,
    }
}
