import React, { useEffect, useMemo } from "react"
import { Combobox } from "@udecode/plate-ui-combobox"
import {
    comboboxStore,
    getPluginOptions,
    usePlateEditorRef,
} from "@udecode/plate"
import match from "autosuggest-highlight/match"
import parse from "autosuggest-highlight/parse"
import { getMentionOnSelectItem } from "@udecode/plate-mention"
import { Box, Typography } from "@material-ui/core"
import { useLazyQuery } from "@apollo/client"
import { useAuth } from "../../../providers/AuthProvider"
import { SEARCH_CONCEPTS } from "../../../graphql/conceptListQueries"
import {
    SearchConceptsQuery,
    SearchConceptsQueryVariables,
} from "../../../graphql/__generated__/conceptListQueries"
import { Concept } from "../../../__generated__/types"
import useMountedState from "../../../util/useMountedState"
import { ELEMENT_CONCEPT } from "../RichTextEditor"

export const ConceptMention = () => {
    //introduce states for managing API calls
    const { currentUser } = useAuth()
    const isMounted = useMountedState()
    const loadingItem = [{ key: "0", text: "Loading Items" }]
    const [loadOptions, { data, loading }] = useLazyQuery<
        SearchConceptsQuery,
        SearchConceptsQueryVariables
    >(SEARCH_CONCEPTS, {
        fetchPolicy: "no-cache",
    })
    const formatItems = (fetchedData: Concept[]) => {
        return (
            fetchedData?.map((item, index) => {
                return {
                    key: item.id,
                    text: item.title,
                    data: item,
                }
            }) || []
        )
    }

    //editor and get trigger based on editor
    const editor = usePlateEditorRef()

    //focused editorId and id of open combobox

    const activeId = comboboxStore.use.activeId()
    const { trigger } = getPluginOptions(editor, ELEMENT_CONCEPT) as any

    //store value identifying if combobox is open
    const open = comboboxStore.use.isOpen()
    const text = comboboxStore.use.text()

    const isOpen = useMemo(() => {
        if (!open || activeId !== ELEMENT_CONCEPT) {
            return null
        }
        return true
    }, [open, activeId])

    useEffect(() => {
        if (isOpen && !!isMounted()) {
            loadOptions({
                variables: {
                    searchString: text || "*",
                    userId: currentUser.userId,
                },
            })
        }
    }, [isOpen, text, currentUser.userId, isMounted, loadOptions])

    const concepts = data?.fuzzyConceptSearch ?? null

    return (
        <Combobox
            id={ELEMENT_CONCEPT}
            controlled
            trigger={trigger}
            items={!!loading ? loadingItem : !data ? [] : formatItems(concepts)}
            onRenderItem={(props: any) => {
                const titleMatches = match(props.item.text, text || "")
                const partsTitle = parse(props.item.text, titleMatches)
                return (
                    <Box key={props.item.key}>
                        <Typography variant="body2">
                            {partsTitle.map((part, index) => (
                                <span
                                    key={index}
                                    style={{
                                        fontWeight: part.highlight ? 800 : 400,
                                    }}
                                >
                                    {part.text}
                                </span>
                            ))}{" "}
                            {props.item?.data?.category &&
                                `(${props.item?.data?.category?.name})`}
                        </Typography>
                    </Box>
                )
            }}
            onSelectItem={getMentionOnSelectItem({ key: ELEMENT_CONCEPT })}
        />
    )
}
