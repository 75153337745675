import React, { useEffect, useState, useRef } from "react"
import { makeStyles } from "@material-ui/core/styles"
import {
    Box,
    ClickAwayListener,
    InputAdornment,
    TextField,
    useTheme,
} from "@material-ui/core"
import useAwaitTranslation from "../i18n/useAwaitTranslation"
import GenerateAI from "./GenerateAI"

const useStyles = makeStyles((theme) => ({
    body1: {
        fontSize: theme.typography.body1.fontSize,
        lineHeight: 1.5,
        minHeight: "18.5px",
    },
    body2: {
        fontSize: theme.typography.body2.fontSize,
    },
    h6: {
        fontSize: theme.typography.h6.fontSize,
        lineHeight: 1.5,
    },
    subtitle2: {
        fontSize: theme.typography.subtitle2.fontSize,
    },
    clickable: {
        height: "100%",
        width: "100%",
        "&:hover": {
            backgroundColor: theme.palette.action.hover,
            borderRadius: "4px",
            cursor: "pointer",
        },
        display: "flex",
        alignItems: "center",
    },
}))

interface DetailsFieldProps {
    value: string
    editable: boolean
    handleEdit: (newValue: string) => void
    grow?: boolean
    placeholder?: string
    options?: string[]
    className?: string
    multiline?: boolean
    parentWidth?: number
    maxLength?: number
    setOpenTranslationsModal?: (boolean) => void
    aiPrompt?: string
}

export default function DetailsField(props: DetailsFieldProps) {
    const classes = useStyles()
    const theme = useTheme()
    const ref = useRef(null)
    const spanRef = useRef(null)
    const [editing, setEditing] = useState(false)
    const [value, setValue] = useState(props.value)
    const { i18n } = useAwaitTranslation("detailsField")
    const selectedLangString = i18n?.language
    const [width, setWidth] = useState(null)

    useEffect(() => {
        const newWidth = spanRef.current?.offsetWidth + 50
        if (!!props.grow && !!newWidth) {
            if (!!props.parentWidth) {
                if (newWidth < props.parentWidth) {
                    //50 for helper text and a little spacing
                    setWidth(spanRef.current.offsetWidth + 50)
                } else {
                    setWidth(props.parentWidth)
                }
            } else {
                setWidth(spanRef.current.offsetWidth + 50)
            }
        }
    }, [spanRef, props.grow, value, editing, props.parentWidth])
    useEffect(() => {
        setValue(props.value)
    }, [setValue, props.value])
    useEffect(() => {
        if (ref && editing) {
            ref.current.focus()
        }
    }, [ref, editing])
    const handleChange = (newValue) => {
        props.handleEdit(newValue)
        setEditing(false)
    }
    let input = (
        <ClickAwayListener onClickAway={(event) => handleChange(value)}>
            <Box overflow="none">
                <TextField
                    InputProps={{
                        classes: {
                            input: classes[props.className],
                        },
                        style: {
                            marginRight: "10px",
                        },
                        endAdornment: !!props.aiPrompt ? (
                            <InputAdornment position="end">
                                <GenerateAI
                                    maxLength={props.maxLength}
                                    onGenerateError={(error) =>
                                        console.log(error)
                                    }
                                    onGenerate={(text: string) =>
                                        props.handleEdit(text)
                                    }
                                    prompt={props.aiPrompt}
                                />
                            </InputAdornment>
                        ) : null,
                    }}
                    inputProps={{
                        maxLength: props.maxLength,
                    }}
                    value={value}
                    placeholder={props.placeholder ?? ""}
                    multiline={props.multiline}
                    onKeyDown={(e) => {
                        if (e.key === "Enter") {
                            handleChange(value)
                        }
                    }}
                    helperText={`${value?.length ?? 0}/${props.maxLength}`}
                    inputRef={ref}
                    onFocus={() => {
                        if (ref && ref.current && editing) {
                            ref.current.setSelectionRange(
                                ref.current.value.length,
                                ref.current.value.length
                            )
                        }
                    }}
                    fullWidth
                    variant="standard"
                    onChange={(event) => {
                        setValue(event.target.value)
                    }}
                    style={{
                        width: props.grow ? width + "px" : "100%",
                        minWidth: "5em",
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "row",
                    }}
                />
            </Box>
        </ClickAwayListener>
    )

    return (
        <Box
            onClick={(e) => {
                if (props.editable) {
                    if (
                        selectedLangString === "en" ||
                        !props.setOpenTranslationsModal
                    ) {
                        setEditing(true)
                    } else {
                        e.stopPropagation()
                        props.setOpenTranslationsModal(true)
                    }
                }
            }}
            width="100%"
            display="flex"
            alignItems="center"
        >
            {editing ? (
                <Box p={0.5} flexGrow={1} display="flex">
                    <Box flexGrow={1}>{input}</Box>
                </Box>
            ) : (
                <Box
                    p={0.5}
                    className={props.editable ? classes.clickable : ""}
                >
                    <Box
                        className={classes[props.className]}
                        style={{ color: !value && theme.palette.text.hint }}
                    >
                        {value || props.placeholder}
                    </Box>
                </Box>
            )}
        </Box>
    )
}
