import React, { useState } from "react"
import { makeStyles } from "@material-ui/core/styles"
import Button from "@material-ui/core/Button"
import Box from "@material-ui/core/Box"
import {
    Dialog,
    DialogContent,
    DialogTitle,
    CircularProgress,
    TextField,
    DialogActions,
    Switch,
    ListItemText,
    ListItemSecondaryAction,
    Tooltip,
} from "@material-ui/core"
import InputLabel from "@material-ui/core/InputLabel"
import MenuItem from "@material-ui/core/MenuItem"
import FormControl from "@material-ui/core/FormControl"
import Select from "@material-ui/core/Select"
import { useMutation } from "@apollo/client"
import useAwaitTranslation from "../../i18n/useAwaitTranslation"
import useUserManagementTools from "./useUserManagementTools"
import { SystemRole, User } from "../../__generated__/types"
import { SEND_EMAIL } from "../../graphql/mutations"
import {
    SendEmailMutation,
    SendEmailMutationVariables,
} from "../../graphql/__generated__/mutations"
import { useAuth } from "../../providers/AuthProvider"
import { useParams, useLocation } from "react-router-dom"
const useStyles = makeStyles((theme) => ({
    errorMessage: {
        color: theme.palette.error.main,
    },
    field: {
        padding: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
}))

export default function UserForm(props: {
    onClose: () => void
    onCreation: (newUser: User) => void
}) {
    const { conceptId } = useParams()
    const location = useLocation()
    const classes = useStyles()
    const { onCreateUser, errors, getSystemRoleLabel } =
        useUserManagementTools()
    const { currentUser } = useAuth()
    const [sendEmail] = useMutation<
        SendEmailMutation,
        SendEmailMutationVariables
    >(SEND_EMAIL)
    const [loading, setLoading] = useState(false)
    const [sendInvite, setSendInvite] = useState(true)
    const [userData, setUserData] = useState({
        firstName: "",
        lastName: "",
        email: "",
        role: SystemRole.GUEST,
    })

    const handleSubmission = async () => {
        setLoading(true)
        const newUser = await onCreateUser({
            ...userData,
            username: userData.firstName + userData.lastName,
        })
        if (!!sendInvite) {
            const link = !!conceptId
                ? `${window.location.origin}/signup-options?continueUrl=${location.pathname}`
                : `${window.location.origin}/signup-options`
            await sendEmail({
                variables: {
                    sendTo: [userData.email],
                    subject: `Amble Account Creation`,
                    text: "<i>No Notes</i>",
                    separateEmails: false,
                    html: `<div>
                                <h2>
                                   A new account has been created for you in Amble.
                                </h2>
                                <h3>
                                    <a href="${link}">Click to finish setting up your account</a>
                                </h3>  
                                <h3>Account created by:
                                    <span style="font-weight: normal;">
                                        ${currentUser.firstName} ${currentUser.lastName}
                                    </span>
                                </h3>
                                <br>
                                <p>
                                <i>This is a system-generated notification email from Amble Ideation. If you have any questions about the authenticity of this email, please contact your Amble system administrator.</i>
                                </p>
                            </div>`,
                },
            })
        }

        if (!!newUser) {
            props.onCreation(newUser)
        }
        props.onClose()
    }

    const { t } = useAwaitTranslation("login")
    return (
        <Dialog open={true} fullWidth>
            <DialogTitle>{t("newUser", "New User")}</DialogTitle>
            <DialogContent>
                <Box p={1} mb={2}>
                    <Box className={classes.field}>
                        <TextField
                            variant="outlined"
                            required
                            size="small"
                            fullWidth
                            onChange={(e) => {
                                setUserData({
                                    ...userData,
                                    firstName: e.target.value,
                                })
                            }}
                            id="firstName"
                            label={t("firstName", "First Name")}
                            name="firstName"
                            autoComplete="firstName"
                            disabled={loading}
                        />
                    </Box>
                    <Box className={classes.field}>
                        <TextField
                            variant="outlined"
                            required
                            size="small"
                            fullWidth
                            onChange={(e) => {
                                setUserData({
                                    ...userData,
                                    lastName: e.target.value,
                                })
                            }}
                            id="lastName"
                            label={t("lastName", "Last Name")}
                            name="lastName"
                            autoComplete="lastName"
                            disabled={loading}
                        />
                    </Box>

                    <Box className={classes.field}>
                        <TextField
                            variant="outlined"
                            required
                            fullWidth
                            id="email"
                            onChange={(e) => {
                                setUserData({
                                    ...userData,
                                    email: e.target.value,
                                })
                            }}
                            size="small"
                            label={t("email", "Email")}
                            disabled={loading}
                            name="email"
                            autoComplete="email"
                        />
                    </Box>

                    <Box className={classes.field}>
                        <Tooltip
                            title={
                                currentUser.role !== SystemRole.ADMIN
                                    ? "Only system admins can create non-guest users"
                                    : ""
                            }
                        >
                            <FormControl
                                disabled={loading}
                                variant="outlined"
                                fullWidth
                                size="small"
                            >
                                <InputLabel>{t("role", "Role")}</InputLabel>
                                <Select
                                    disabled={
                                        currentUser.role !== SystemRole.ADMIN
                                    }
                                    value={
                                        currentUser.role !== SystemRole.ADMIN
                                            ? SystemRole.GUEST
                                            : userData.role
                                    }
                                    label={t("role", "Role")}
                                    onChange={(e) => {
                                        setUserData({
                                            ...userData,
                                            role: e.target.value as any,
                                        })
                                    }}
                                >
                                    <MenuItem value={SystemRole.GUEST}>
                                        {getSystemRoleLabel(SystemRole.GUEST)}
                                    </MenuItem>
                                    <MenuItem value={SystemRole.MEMBER}>
                                        {getSystemRoleLabel(SystemRole.MEMBER)}
                                    </MenuItem>
                                    <MenuItem value={SystemRole.MANAGER}>
                                        {getSystemRoleLabel(SystemRole.MANAGER)}
                                    </MenuItem>
                                    <MenuItem value={SystemRole.ADMIN}>
                                        {getSystemRoleLabel(SystemRole.ADMIN)}
                                    </MenuItem>
                                </Select>
                            </FormControl>
                        </Tooltip>
                    </Box>
                    <Box className={classes.field}>
                        <MenuItem
                            ContainerComponent={"div"}
                            button={true}
                            onClick={() => {
                                setSendInvite((prev) => !prev)
                            }}
                            disabled={loading}
                        >
                            <ListItemText>Send Invite?</ListItemText>
                            <ListItemSecondaryAction>
                                <Switch checked={!!sendInvite} />
                            </ListItemSecondaryAction>
                        </MenuItem>
                    </Box>

                    <Box display="flex">
                        {errors.length > 0 &&
                            errors.map((error, index) => (
                                <Box
                                    key={index}
                                    className={classes.errorMessage}
                                >
                                    {error}
                                </Box>
                            ))}
                    </Box>
                </Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => props.onClose()}>Discard</Button>
                <Button
                    color="primary"
                    onClick={handleSubmission}
                    disabled={loading}
                    style={{ position: "relative" }}
                >
                    Create
                    {loading && (
                        <CircularProgress
                            disableShrink
                            size={20}
                            style={{ position: "absolute" }}
                        />
                    )}
                </Button>
            </DialogActions>
        </Dialog>
    )
}
