import { useState } from "react"
import {
    Box,
    TextField,
    InputAdornment,
    IconButton,
    List,
    Divider,
    Typography,
} from "@material-ui/core"
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles"
import { useQuery } from "@apollo/client"
import { Criteria } from "../../__generated__/types"
import Autocomplete from "@material-ui/lab/Autocomplete"
import ClearIcon from "@material-ui/icons/Clear"
import AddBoxIcon from "@material-ui/icons/AddBox"
import { CRITERIA_FILTERED } from "./graphql"
import { CriteriaQuery, CriteriaQueryVariables } from "./__generated__/graphql"
import { _CriteriaFilter } from "../../__generated__/types"
import useAwaitTranslation from "../../i18n/useAwaitTranslation"
import FieldListItem from "./FieldListItem"
import { reorderArray } from "../../util/fns"
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd"
import { AddedField } from "./useCriteriaTypes"
import { CriteriaType } from "./types"
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        list: {
            backgroundColor: theme.palette.background.paper,
            border: `1px solid ${theme.palette.divider}`,
            borderRadius: theme.shape.borderRadius,
            margin: theme.spacing(1),
        },
        criteriaSelectorContainer: {
            backgroundColor: theme.palette.background.paper,
            border: `1px solid ${theme.palette.divider}`,
            borderRadius: theme.shape.borderRadius,
            height: 450,
            display: "flex",

            flexDirection: "column",
            overflow: "hidden",
        },
        criteriaList: {
            overflowY: "auto",
        },
        criteriaListItem: {
            "&:hover": {
                backgroundColor: theme.palette.action.hover,
            },
            padding: theme.spacing(1.5),
        },
        header: {
            fontSize: theme.typography.subtitle1.fontSize,
            padding: theme.spacing(1),
        },
    })
)

interface CriteriaSelectorProps {
    onChange: (value: { field: Criteria; subfields: Criteria[] }[]) => void
    value: AddedField[]
    criteriaFilter: _CriteriaFilter
    label?: string
    note?: string
    isDraggable?: boolean
    allowSubfields?: boolean
}

const CriteriaSelector: React.FunctionComponent<CriteriaSelectorProps> = ({
    onChange,
    value,
    criteriaFilter,
    label,
    isDraggable,
    note,
    allowSubfields,
}) => {
    //hooks
    const classes = useStyles()
    const { t } = useAwaitTranslation("criteriaManager")

    //queries
    const { data } = useQuery<CriteriaQuery, CriteriaQueryVariables>(
        CRITERIA_FILTERED,
        {
            variables: {
                filter: criteriaFilter,
            },
            fetchPolicy: "network-only",
        }
    )

    //state
    const [val, setVal] = useState(null)
    const [inputValue, setInputValue] = useState("")

    //variables
    const criteria = data?.Criteria?.filter((x) => !!x.name) ?? []
    const currentlyAddedFields: Criteria[] =
        value
            .map((item) => criteria.find((o) => o.id === item.id))
            .filter(Boolean) ?? []

    const onDragEnd = (result) => {
        if (!result.destination) {
            return
        }
        const fieldArray: Criteria[] = reorderArray(
            currentlyAddedFields,
            result.source.index,
            result.destination.index
        )
        onChange(
            fieldArray.map((item) => {
                const selectedItem = value.find((o) => o.id === item.id)
                return {
                    field: item,
                    subfields: selectedItem
                        ? selectedItem.subfieldIds
                              ?.map((o) =>
                                  item.subfields.find((x) => o.id === x.id)
                              )
                              ?.filter(Boolean) ?? []
                        : [],
                }
            })
        )
    }

    const convertToCriteriaObjects = (
        value: AddedField[]
    ): { field: Criteria; subfields: Criteria[] }[] => {
        return (
            value
                ?.map((addedField) => {
                    const field = criteria.find((c) => c.id === addedField.id)

                    const subfields =
                        addedField.subfieldIds
                            ?.map((subfieldId) =>
                                field?.subfields?.find(
                                    (c) => c.id === subfieldId.id
                                )
                            )
                            .filter(Boolean) ?? []
                    return { field, subfields }
                })
                ?.filter((x) => !!x.field) ?? []
        )
    }

    return (
        <Box className={classes.criteriaSelectorContainer}>
            <Box p={1.5}>
                <Box className={classes.header}>
                    <Typography variant="subtitle1">
                        {label || t("selectedFields", "Selected Fields")}
                    </Typography>
                    {!!note && (
                        <Typography color="textSecondary" variant="caption">
                            {note}
                        </Typography>
                    )}
                </Box>

                <Box width="100%">
                    <Autocomplete
                        fullWidth
                        size="small"
                        onChange={(_, newValue: Criteria, reason) => {
                            if (reason === "select-option") {
                                setInputValue("")
                                setVal(null)
                                onChange([
                                    ...convertToCriteriaObjects(value),
                                    {
                                        field: newValue,
                                        subfields: [],
                                    },
                                ])
                            }
                        }}
                        disableCloseOnSelect
                        selectOnFocus
                        clearOnBlur
                        getOptionDisabled={(option) =>
                            !!value.find((o) => o.id === option.id)
                        }
                        getOptionLabel={(option) => option.name}
                        renderOption={(option) => {
                            return (
                                <FieldListItem key={option.id} field={option} />
                            )
                        }}
                        options={criteria}
                        groupBy={(item) => item.group}
                        onInputChange={(event, newInputValue) => {
                            setInputValue(newInputValue)
                        }}
                        inputValue={inputValue}
                        value={val}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                variant="outlined"
                                placeholder={t(
                                    "typeToAddField",
                                    "Type to add field"
                                )}
                                InputProps={{
                                    ...params.InputProps,
                                    startAdornment: (
                                        <>
                                            <InputAdornment position="start">
                                                <AddBoxIcon fontSize="small" />
                                            </InputAdornment>
                                            {params.InputProps.startAdornment}
                                        </>
                                    ),
                                    endAdornment: inputValue?.length > 0 && (
                                        <InputAdornment position="end">
                                            <IconButton
                                                size="small"
                                                onClick={() =>
                                                    setInputValue("")
                                                }
                                            >
                                                <ClearIcon fontSize="small" />
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        )}
                    />
                </Box>
            </Box>
            <Divider />
            <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId={label || "selected_fields"}>
                    {(provided) => (
                        <List
                            ref={provided.innerRef}
                            {...provided.droppableProps}
                            className={classes.criteriaList}
                            disablePadding
                            style={{ height: "100%" }}
                        >
                            {currentlyAddedFields.map((item, index) => (
                                <Draggable
                                    isDragDisabled={!isDraggable}
                                    key={item.id}
                                    index={index}
                                    draggableId={item.id}
                                >
                                    {(provided) => (
                                        <div
                                            key={item.id}
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                            className={classes.criteriaListItem}
                                        >
                                            <AddedItem
                                                key={item.id}
                                                value={value}
                                                field={item}
                                                onChange={(
                                                    value: AddedField[]
                                                ) =>
                                                    onChange(
                                                        convertToCriteriaObjects(
                                                            value
                                                        )
                                                    )
                                                }
                                                allowSubfields={allowSubfields}
                                            />
                                        </div>
                                    )}
                                </Draggable>
                            ))}
                        </List>
                    )}
                </Droppable>
            </DragDropContext>
        </Box>
    )
}

interface AddedItemProps {
    value: AddedField[]
    field: Criteria
    onChange: (value: AddedField[]) => void
    allowSubfields: boolean
}

const AddedItem: React.FunctionComponent<AddedItemProps> = ({
    value,
    field,
    onChange,
    allowSubfields,
}) => {
    const handleSubfieldToggle = (subfieldId: string) => {
        onChange(
            value.map((item) => {
                if (item.id === field.id) {
                    return {
                        ...item,
                        subfieldIds: item.subfieldIds?.find(
                            (o) => o.id === subfieldId
                        )
                            ? item.subfieldIds?.filter(
                                  (o) => o.id !== subfieldId
                              )
                            : [
                                  ...(item.subfieldIds ?? []),
                                  {
                                      id: subfieldId,
                                  },
                              ],
                    }
                }
                return item
            })
        )
    }
    return (
        <Box key={field.id}>
            <FieldListItem
                key={field.id}
                onRemoval={() =>
                    onChange(value.filter((item) => item.id !== field.id))
                }
                field={field}
            />
            {!!allowSubfields &&
                field.criteriaType === CriteriaType.Collection &&
                (field.subfields?.length > 0 ? (
                    <Box px={2} mt={1.5}>
                        {field.subfields
                            .filter((x) =>
                                [
                                    CriteriaType.TextResponse,
                                    CriteriaType.MultipleChoice,
                                    CriteriaType.Number,
                                ].includes(x.criteriaType as CriteriaType)
                            )
                            .map((subfield) => {
                                const added = !!value.find(
                                    (item) =>
                                        item.id === field.id &&
                                        item.subfieldIds?.find(
                                            (o) => o.id === subfield.id
                                        )
                                )

                                return (
                                    <FieldListItem
                                        key={subfield.id}
                                        field={subfield}
                                        onToggle={() =>
                                            handleSubfieldToggle(subfield.id)
                                        }
                                        added={added}
                                    />
                                )
                            })}
                    </Box>
                ) : (
                    <Typography variant="caption" color="textSecondary">
                        No subfields
                    </Typography>
                ))}
        </Box>
    )
}

export default CriteriaSelector
