import React, { ReactElement, useCallback, useEffect, useState } from "react"
import {
    Box,
    FormControl,
    GridList,
    GridListTile,
    Hidden,
    InputLabel,
    makeStyles,
    MenuItem,
    Select,
    TextField,
    useMediaQuery,
    useTheme,
} from "@material-ui/core"
import { Pagination } from "@material-ui/lab"
import useAwaitTranslation from "../../i18n/useAwaitTranslation"

const useStyles = makeStyles((theme) => ({
    results: {
        overflow: "auto",
    },
    imageResult: {
        cursor: "pointer",
    },
}))
interface Props {
    type?: string
    handleImageClick?: Function
}

var API_KEY = "19955082-d3f4397624ca16bb56aa28d18"

export default function PhotoSearch(props: Props): ReactElement {
    const classes = useStyles()
    const [items, setItems] = useState([])
    const [resultCount, setResultCount] = useState(0)
    const [searchTerm, setSearchTerm] = useState("")
    const [columns, setColumns] = useState(3)
    const [category, setCategory] = useState("business")
    const [page, setPage] = React.useState(1)
    const theme = useTheme()
    const mobile = useMediaQuery(theme.breakpoints.down("sm"))
    const { t } = useAwaitTranslation("images")
    const searchImages = useCallback(
        (searchTerm) => {
            var URL =
                "https://pixabay.com/api/?key=" +
                API_KEY +
                "&q=" +
                encodeURIComponent(searchTerm) +
                (category ? "&category=" + category : "") +
                "&page=" +
                page +
                "&safesearch=true"
            fetch(URL)
                .then((res) => res.json())
                .then((result) => {
                    setResultCount(result.totalHits)
                    setItems(result.hits)
                })
        },
        [category, page]
    )
    const searchVideos = (searchTerm) => {
        var URL =
            "https://pixabay.com/api/videos/?key=" +
            API_KEY +
            "&q=" +
            encodeURIComponent(searchTerm)
        fetch(URL)
            .then((res) => res.json())
            .then((result) => {
                setResultCount(result.totalHits)
                setItems(result.hits)
            })
    }
    useEffect(() => {
        if (props.type === "video") {
            searchVideos(searchTerm ?? " ")
        } else {
            searchImages(searchTerm ?? " ")
        }
    }, [searchTerm, props.type, category, page, searchImages])
    useEffect(() => {
        if (mobile) {
            setColumns(1)
        }
    }, [mobile])
    const handleColumnChange = (ev) => setColumns(ev.target.value)
    const handleCategoryChange = (ev) => setCategory(ev.target.value)
    const handlePageChange = (ev, value) => setPage(value)
    const categories = [
        "backgrounds",
        "fashion",
        "nature",
        "science",
        "education",
        "feelings",
        "health",
        "people",
        "religion",
        "places",
        "animals",
        "industry",
        "computer",
        "food",
        "sports",
        "transportation",
        "travel",
        "buildings",
        "business",
        "music",
    ]
    return (
        <>
            <Box
                display="flex"
                flexWrap="wrap-reverse"
                justifyContent="space-between"
            >
                <TextField
                    style={{ flexGrow: 1 }}
                    id="image-search"
                    autoFocus
                    label={
                        props.type === "video"
                            ? "Search Videos"
                            : "Search Images"
                    }
                    onChange={(ev) => setSearchTerm(ev.target.value)}
                />
                <Hidden smDown>
                    <FormControl style={{ minWidth: "20ch", margin: "0 1em" }}>
                        <InputLabel
                            id="category-selector"
                            style={{ whiteSpace: "nowrap" }}
                        >
                            {t("imageCategory", "Image Category")}
                        </InputLabel>
                        <Select
                            labelId="image-category-label"
                            id="image-category"
                            value={category}
                            onChange={handleCategoryChange}
                        >
                            {categories.map((cat, idx) => (
                                <MenuItem value={cat} key={idx}>
                                    {cat}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <FormControl style={{ minWidth: "10ch", margin: "0 1em" }}>
                        <InputLabel
                            id="column-selector"
                            style={{ whiteSpace: "nowrap" }}
                        >
                            # {t("columns", "Columns")}
                        </InputLabel>
                        <Select
                            // variant="outlined"

                            labelId="simple-select-label"
                            id="simple-select"
                            value={columns}
                            onChange={handleColumnChange}
                        >
                            <MenuItem key={1} value={1}>
                                1
                            </MenuItem>
                            <MenuItem key={2} value={2}>
                                2
                            </MenuItem>
                            <MenuItem key={3} value={3}>
                                3
                            </MenuItem>
                            <MenuItem key={4} value={4}>
                                4
                            </MenuItem>
                        </Select>
                    </FormControl>
                </Hidden>
                {resultCount > 0 && (
                    <h4 style={{ whiteSpace: "nowrap" }}>
                        {t("totalResults", "Total Results")}: {resultCount}
                    </h4>
                )}
            </Box>
            <Box style={{ overflowY: "auto" }}>
                <GridList
                    className={classes.results}
                    cellHeight={200}
                    cols={columns}
                >
                    {items.map((tile, idx) => (
                        <GridListTile
                            key={idx}
                            onClick={() =>
                                props.handleImageClick(
                                    tile.largeImageURL,
                                    tile.id
                                )
                            }
                            rows={1}
                            cols={tile.cols || 1}
                            className={classes.imageResult}
                        >
                            {props.type === "video" ? (
                                <video src={tile.videos.tiny.url}></video>
                            ) : (
                                <img src={tile.previewURL} alt={tile.title} />
                            )}
                        </GridListTile>
                    ))}
                </GridList>
            </Box>
            {resultCount > 0 && (
                <Pagination
                    style={{ justifyContent: "center", margin: "1em" }}
                    count={Math.ceil(resultCount / 20)}
                    page={page}
                    onChange={handlePageChange}
                />
            )}
        </>
    )
}
