import {
    Box,
    FormControl,
    MenuItem,
    Select,
    CircularProgress,
    Dialog,
    DialogTitle,
    DialogActions,
    Button,
    DialogContent,
    TextField,
    InputLabel,
    MenuList,
    ListItemIcon,
    Collapse,
    ListItemText,
    Divider,
    Tooltip,
    Typography,
    Switch,
    ButtonBase,
    Checkbox,
    IconButton,
    ListItem,
    ListItemSecondaryAction,
    List,
    ListSubheader,
    Icon,
} from "@material-ui/core"
import EmailIcon from "@material-ui/icons/Email"
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown"
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp"
import {
    makeStyles,
    Theme,
    createStyles,
    useTheme,
} from "@material-ui/core/styles"
import CheckIcon from "@material-ui/icons/Check"
import {
    AccessType,
    Concept,
    SystemRole,
    Team,
    User,
    WorkflowStage,
} from "../../__generated__/types"
import { useParams } from "react-router-dom"
import useConceptTools from "../../util/useConceptTools"
import React, { useEffect, useRef, useState, useCallback } from "react"
import ClickableRichTooltip from "../Popper/ClickableRichTooltip"
import useWorkflowTools from "./useWorkflowTools"
import { useAuth } from "../../providers/AuthProvider"

import useCategoryTools from "../categories/useCategoryTools"
import { useMutation, useQuery, useReactiveVar } from "@apollo/client"
import { CONCEPT_PERMISSIONS, GRANT_USER_ACCESS } from "../permissions/graphql"
import {
    ConceptPermissionsQuery,
    ConceptPermissionsQueryVariables,
    GrantUserAccessMutation,
    GrantUserAccessMutationVariables,
} from "../permissions/__generated__/graphql"

import { Loading } from "../Loading"
import GroupIcon from "@material-ui/icons/Group"
import ClearIcon from "@material-ui/icons/Clear"
import { SEND_EMAIL } from "../../graphql/mutations"
import {
    SendEmailMutation,
    SendEmailMutationVariables,
} from "../../graphql/__generated__/mutations"
import useEnvironmentSettingTools from "../settings/useEnvironmentSettingTools"
import { userConceptPermissionsVar } from "../../providers/GlobalState"

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        disabledButton: {
            textTransform: "none",
            cursor: "default",
            "&:hover": {
                backgroundColor: "transparent !important",
            },
        },
        root: {
            width: "100%",
            border: `1px solid ${theme.palette.divider}`,
            borderRadius: theme.shape.borderRadius,
            backgroundColor: theme.palette.background.paper,
            position: "relative",
            overflow: "auto",
            maxHeight: 300,
        },
        listSection: {
            backgroundColor: "inherit",
        },
        ul: {
            backgroundColor: "inherit",
            padding: 0,
        },
        selectedList: {
            border: `1px solid ${theme.palette.divider}`,
            borderRadius: theme.shape.borderRadius,
        },
    })
)
export default function ConceptStatus(props: {
    concept: Concept
    editing: boolean
}) {
    const { concept, editing } = props
    const currentStatusData = useRef(null)
    const theme = useTheme()
    const { currentUser } = useAuth()
    const classes = useStyles()
    const { onCreateNewConceptStatus, onUpdateConcept } = useConceptTools(
        concept
    )

    const { getCategoryArray } = useCategoryTools()
    const categoryArray = getCategoryArray(concept.category?.id)
    const fullCategoryWithWorkflow = categoryArray[categoryArray.length - 1]
    const {
        environmentWorkflow,
        isInitialized: workflowsInitialized,
        allSubWorkflows,
        getOrderedStages,
    } = useWorkflowTools({ fetch: true })
    const userConceptPermissions = useReactiveVar(userConceptPermissionsVar)
    const { admins } = useEnvironmentSettingTools({ fetchAdmins: true })
    const [sendEmail] = useMutation<
        SendEmailMutation,
        SendEmailMutationVariables
    >(SEND_EMAIL)
    const [grantUserAccess] = useMutation<
        GrantUserAccessMutation,
        GrantUserAccessMutationVariables
    >(GRANT_USER_ACCESS)

    const [
        selectedStatusStage,
        setSelectedStatusStage,
    ] = useState<WorkflowStage>(null)

    const currentCategoryWorkflow =
        (!!fullCategoryWithWorkflow?.workflow
            ? allSubWorkflows?.find(
                  (item) => item.id === fullCategoryWithWorkflow.workflow.id
              )
            : environmentWorkflow) || environmentWorkflow

    const orderedStages = getOrderedStages(currentCategoryWorkflow?.stages)
    const activeStatus = concept.status.find((status) => !!status.isActive)
    const activeStage = currentCategoryWorkflow?.stages?.find(
        (item) => item.id === activeStatus?.stage.id
    )
    const currentStageIcon = JSON.parse(activeStage?.icon ?? "{}")?.name || null
    if (!!activeStatus && !!activeStage) {
        currentStatusData.current = {
            status: activeStatus,
            stage: activeStage,
        }
    }
    const handleSubmission = useCallback(
        async (
            reason: string,
            notes: string,
            emails?: string[],
            makePublic?: boolean
        ) => {
            await onCreateNewConceptStatus({
                statusStage: selectedStatusStage,
                concept,
                justificationNotes: notes,
                justificationReason: reason,
                refetch: true,
            })

            if (concept.status.length === 0) {
                await Promise.all([
                    ...admins?.map((admin) => {
                        return grantUserAccess({
                            variables: {
                                conceptId: concept.id,
                                userId: admin.userId,
                                accessType: AccessType.OWNER,
                            },
                        })
                    }),
                ])
            }
            if (!!emails) {
                const adjustedNotes = notes || "<i>No change notes provided</i>"
                const adjustedPriorStatus =
                    activeStage?.title || "<i>No prior status</i>"
                const changeString = selectedStatusStage.title
                await sendEmail({
                    variables: {
                        sendTo: emails,
                        subject: `${concept.category.name} Status Updated [${selectedStatusStage.title}] | ${concept.title}`,
                        text: adjustedNotes,
                        separateEmails: false,
                        html: `<div>
                                    <h2>
                                        ${concept.title}
                                        <a href="${
                                            window.location.href
                                        }">(Click to view ${concept.category.name.toLowerCase()})</a>
                                    </h2>
                                    <h3>Status Change:
                                        <span style="font-weight: normal;">${adjustedPriorStatus} > ${changeString}</span>
                                    </h3>
                                    ${
                                        !!reason
                                            ? `<h3>Reason:
                                            <span style="font-weight: normal;">${reason}</span>
                                        </h3>`
                                            : ""
                                    }
                                    <h3>Notes:
                                        <span style="font-weight: normal;">${adjustedNotes}</span>
                                    </h3>
                                    <h3>Updated By:
                                        <span style="font-weight: normal;">
                                            ${currentUser.firstName} ${
                            currentUser.lastName
                        }
                                        </span>
                                    </h3>
                                    <br>
                                    <p>
                                    <i>This is a system-generated notification email from Amble Ideation. If you have any questions about the authenticity of this email, please contact your Amble system administrator.</i>
                                    </p>
                                </div>`,
                    },
                })
            }
            if (!!makePublic) {
                onUpdateConcept(
                    {
                        userId: currentUser.userId,
                        concept: {
                            id: concept.id,
                            isPublic: true,
                            isPublicAccessType: AccessType.VIEWER,
                        },
                    },
                    true
                )
            }
            setSelectedStatusStage(null)
        },
        [
            sendEmail,
            onCreateNewConceptStatus,
            selectedStatusStage,
            concept,
            currentUser.userId,
            onUpdateConcept,
            currentUser.firstName,
            currentUser.lastName,
            activeStage?.title,
            admins,
            grantUserAccess,
        ]
    )

    const fullStatusName = !!currentStatusData?.current?.stage
        ? `${currentStatusData?.current?.stage?.title} ${
              currentStatusData?.current?.status?.justification
                  ? `(${currentStatusData?.current.status.justification})`
                  : ``
          }`
        : null

    let permissionToChange = !currentStatusData?.current?.stage
        ? userConceptPermissions.editingPermission
        : currentUser.role === SystemRole.ADMIN
    if (!permissionToChange) {
        const permissionRequired = !!activeStage
            ? activeStage?.permissionRequired
            : orderedStages.find((stage) => stage.isFirstStage)
                  ?.permissionRequired
        if (permissionRequired === AccessType.OWNER) {
            permissionToChange = userConceptPermissions.owningPermission
        } else if (permissionRequired === AccessType.EDITOR) {
            permissionToChange = userConceptPermissions.editingPermission
        }
    }
    if (
        !workflowsInitialized ||
        !fullCategoryWithWorkflow ||
        (!editing && !currentStatusData?.current?.stage)
    ) {
        return null
    }

    return (
        <Box
            display="flex"
            alignItems="center"
            width="100%"
            justifyContent="flex-end"
        >
            {!!editing ? (
                !permissionToChange ? (
                    <Tooltip
                        title={
                            !!currentStatusData?.current?.stage
                                ? "You do not have permission to change this status"
                                : "You do not hav permission to perform this action"
                        }
                    >
                        <span>
                            <Button
                                className={classes.disabledButton}
                                disableRipple={true}
                                disableFocusRipple={true}
                                disableElevation={true}
                            >
                                {fullStatusName ??
                                    currentCategoryWorkflow?.initialAction ??
                                    "Add Status"}
                            </Button>
                        </span>
                    </Tooltip>
                ) : !currentStatusData?.current?.stage ? (
                    <Button
                        onClick={() =>
                            setSelectedStatusStage(
                                orderedStages.find((item) => item.isFirstStage)
                            )
                        }
                        style={{ position: "relative" }}
                    >
                        {currentCategoryWorkflow?.initialAction ?? "Add Status"}
                    </Button>
                ) : (
                    <ClickableRichTooltip
                        placement="bottom-end"
                        arrow={false}
                        zIndex={theme.zIndex.appBar - 2}
                        closePopper={!!selectedStatusStage}
                        content={
                            <Box width={300} maxHeight={500} height="100%">
                                <MenuList dense disablePadding>
                                    <MenuItem button={false} disabled>
                                        {!!currentStageIcon && (
                                            <ListItemIcon
                                                style={{
                                                    minWidth: theme.spacing(4),
                                                }}
                                            >
                                                <Icon fontSize="small">
                                                    {currentStageIcon}
                                                </Icon>
                                            </ListItemIcon>
                                        )}
                                        <ListItemText
                                            primary={
                                                currentStatusData?.current
                                                    ?.stage.title
                                            }
                                        />
                                        <ListItemIcon>
                                            <CheckIcon />
                                        </ListItemIcon>
                                    </MenuItem>
                                    <Divider />

                                    {currentStatusData?.current?.stage?.nextOptions?.map(
                                        (stage, index) => {
                                            const selected =
                                                stage.id ===
                                                activeStatus?.stage?.id
                                            const icon = JSON.parse(
                                                stage.icon ?? "{}"
                                            )?.name
                                            return (
                                                <MenuItem
                                                    dense
                                                    onClick={() => {
                                                        if (
                                                            stage.id !==
                                                            activeStatus?.stage
                                                                ?.id
                                                        ) {
                                                            setSelectedStatusStage(
                                                                stage
                                                            )
                                                        }
                                                    }}
                                                    selected={selected}
                                                    value={stage.id}
                                                    key={stage.title + index}
                                                >
                                                    {!!icon && (
                                                        <ListItemIcon
                                                            style={{
                                                                minWidth: theme.spacing(
                                                                    4
                                                                ),
                                                            }}
                                                        >
                                                            <Icon fontSize="small">
                                                                {icon}
                                                            </Icon>
                                                        </ListItemIcon>
                                                    )}
                                                    <ListItemText
                                                        primary={stage.title}
                                                    />
                                                    {!!selected && (
                                                        <ListItemIcon
                                                            style={{
                                                                justifyContent:
                                                                    "flex-end",
                                                            }}
                                                        >
                                                            <CheckIcon />
                                                        </ListItemIcon>
                                                    )}
                                                </MenuItem>
                                            )
                                        }
                                    )}
                                </MenuList>
                            </Box>
                        }
                    >
                        <Button
                            startIcon={
                                !!currentStageIcon ? (
                                    <Icon fontSize="small">
                                        {currentStageIcon}
                                    </Icon>
                                ) : null
                            }
                            style={{
                                textTransform: "none",
                                flexShrink: 0,
                                position: "relative",
                            }}
                        >
                            {fullStatusName}
                        </Button>
                    </ClickableRichTooltip>
                )
            ) : (
                !!currentStatusData?.current?.stage && (
                    <Button
                        startIcon={
                            !!currentStageIcon ? (
                                <Icon fontSize="small">{currentStageIcon}</Icon>
                            ) : null
                        }
                        className={classes.disabledButton}
                        disableRipple={true}
                        disableFocusRipple={true}
                        disableElevation={true}
                    >
                        {fullStatusName}
                    </Button>
                )
            )}
            {!!selectedStatusStage && (
                <ExplanationModal
                    concept={concept}
                    onClose={() => setSelectedStatusStage(null)}
                    onSubmission={handleSubmission}
                    stage={selectedStatusStage}
                />
            )}
        </Box>
    )
}

const ExplanationModal = (props: {
    onClose: () => void
    stage: WorkflowStage
    concept: Concept
    onSubmission: (
        justificationReason: string,
        justificationNotes?: string,
        emails?: string[],
        makePublic?: boolean
    ) => Promise<void>
}) => {
    const { onClose, stage, onSubmission, concept } = props
    const { currentUser } = useAuth()
    const [justificationReason, setJustificationReason] = useState(null)
    const [justificationNotes, setJustificationNotes] = useState("")
    const [saving, setSaving] = useState(false)
    const [makePublic, setMakePublic] = useState(false)
    const [sendAlert, setSendAlert] = useState(true)
    const [emails, setEmails] = useState(null)
    const statusJustificationReasonOptions = JSON.parse(
        stage.justificationOptions ?? "[]"
    )

    const reasonRequired = statusJustificationReasonOptions.length > 0

    const alreadyPublic = concept.isPublicAccessType !== AccessType.NONE
    return (
        <Dialog maxWidth="sm" open={true} fullWidth>
            <DialogTitle>{`Status Stage - ${stage.title}`}</DialogTitle>
            <DialogContent>
                {reasonRequired && (
                    <Box p={2}>
                        <FormControl variant="outlined" fullWidth required>
                            <InputLabel>Reason</InputLabel>

                            <Select
                                variant="outlined"
                                fullWidth
                                onChange={(e) => {
                                    setJustificationReason(e.target.value)
                                }}
                                disabled={!!saving}
                                value={justificationReason ?? ""}
                                label={"Reason"}
                            >
                                {statusJustificationReasonOptions.map(
                                    (op, index) => {
                                        return (
                                            <MenuItem key={index} value={op}>
                                                {op}
                                            </MenuItem>
                                        )
                                    }
                                )}
                            </Select>
                        </FormControl>
                    </Box>
                )}
                <Box p={2}>
                    <TextField
                        fullWidth
                        variant="outlined"
                        multiline
                        rows={4}
                        disabled={saving}
                        label="Notes"
                        value={justificationNotes}
                        onChange={(e) => {
                            setJustificationNotes(e.target.value)
                        }}
                    />
                </Box>

                {currentUser.role === SystemRole.ADMIN && (
                    <Tooltip
                        title={
                            !!alreadyPublic
                                ? "This concept is already made public"
                                : ""
                        }
                    >
                        <Box
                            component={!!alreadyPublic ? "div" : ButtonBase}
                            p={3}
                            onClick={(e) => {
                                setMakePublic((prev) => !prev)
                            }}
                            width="100%"
                            display="flex"
                            justifyContent={"space-between"}
                        >
                            <Typography color="textSecondary">
                                Make Public?
                            </Typography>
                            <Switch
                                disabled={!!alreadyPublic}
                                size="small"
                                checked={
                                    concept?.isPublicAccessType !==
                                        AccessType.NONE || makePublic
                                }
                                color="primary"
                            />
                        </Box>
                    </Tooltip>
                )}

                <Box
                    component={ButtonBase}
                    p={3}
                    onClick={(e) => setSendAlert((prev) => !prev)}
                    width="100%"
                    display="flex"
                    justifyContent={"space-between"}
                >
                    <Typography color="textSecondary">
                        Notify Others?
                    </Typography>
                    <Switch size="small" checked={sendAlert} color="primary" />
                </Box>
                {!!sendAlert && (
                    <AlertOptionsList
                        selectedEmails={emails}
                        setSelectedEmails={setEmails}
                    />
                )}
            </DialogContent>

            <DialogActions>
                <Button
                    onClick={() => {
                        onClose()
                    }}
                    disabled={saving}
                >
                    Cancel
                </Button>
                <Button
                    onClick={async () => {
                        setSaving(true)
                        await onSubmission(
                            justificationReason,
                            justificationNotes,
                            !!sendAlert && emails?.length > 0 ? emails : null,
                            !!makePublic
                        )
                    }}
                    disabled={
                        (!!reasonRequired && !justificationReason) || saving
                    }
                    color="primary"
                >
                    {!!saving ? (
                        <CircularProgress size={25} disableShrink />
                    ) : (
                        "Save"
                    )}
                </Button>
            </DialogActions>
        </Dialog>
    )
}

interface AlertOptionsListProps {
    selectedEmails: string[] | null
    setSelectedEmails: (value: string[]) => void
}
const AlertOptionsList = (props: AlertOptionsListProps) => {
    const { selectedEmails, setSelectedEmails } = props
    const { conceptId } = useParams()
    const classes = useStyles()
    const { data: conceptPermissionsData } = useQuery<
        ConceptPermissionsQuery,
        ConceptPermissionsQueryVariables
    >(CONCEPT_PERMISSIONS, {
        variables: {
            id: conceptId,
        },
        fetchPolicy: "cache-and-network",
    })
    const { admins } = useEnvironmentSettingTools({ fetchAdmins: true })

    const conceptWithPermissions = conceptPermissionsData?.Concept?.[0] ?? null
    useEffect(() => {
        if (!!admins && !selectedEmails) {
            setSelectedEmails(admins.map((user) => user.email))
        }
    }, [admins, selectedEmails, setSelectedEmails])

    if (!admins || !conceptWithPermissions || !selectedEmails) {
        return (
            <Box
                display={"flex"}
                height="20em"
                justifyContent={"center"}
                alignItems="center"
                style={{ overflowY: "auto" }}
            >
                <Loading size={25} hideQuote={true} />
            </Box>
        )
    }

    let adminOptions = admins.map((user) => ({ user, team: null, type: null }))
    let ownerOptions = conceptWithPermissions?.addedUsers
        ?.filter((x) => x.type === AccessType.OWNER)
        .map((x) => ({
            user: x.User,
            team: null,
            type: AccessType.OWNER,
        }))

    conceptWithPermissions?.addedTeams
        ?.filter((x) => x.type === AccessType.OWNER)
        .map((team) => {
            ownerOptions.push({
                user: null,
                team: team.Team,
                type: AccessType.OWNER,
            })
            return ownerOptions
        })
    let editorOptions = conceptWithPermissions?.addedUsers
        ?.filter((x) => x.type === AccessType.EDITOR)
        .map((x) => ({
            user: x.User,
            team: null,
            type: AccessType.EDITOR,
        }))

    conceptWithPermissions?.addedTeams
        ?.filter((x) => x.type === AccessType.EDITOR)
        .map((team) => {
            editorOptions.push({
                user: null,
                team: team.Team,
                type: AccessType.EDITOR,
            })
            return editorOptions
        })

    return (
        <Box pr={2} pl={2} height="100%" style={{ overflowY: "hidden" }}>
            <Box p={1}>
                <Typography variant="subtitle1" color="textSecondary">
                    Emails Options
                </Typography>
            </Box>
            <List dense className={classes.root} subheader={<li />}>
                <li key={"owners"} className={classes.listSection}>
                    <ul className={classes.ul}>
                        <ListSubheader>Owners</ListSubheader>
                        {ownerOptions.map((item, index) => {
                            const selected = !!item.user
                                ? selectedEmails?.includes(item.user?.email)
                                : item.team.members.filter((user) =>
                                      selectedEmails?.includes(user?.email)
                                  ).length === item.team.members.length
                            return (
                                <AlertTable
                                    obj={item}
                                    key={index}
                                    selected={selected}
                                    selectedEmails={selectedEmails}
                                    setSelectedEmails={setSelectedEmails}
                                />
                            )
                        })}
                    </ul>
                </li>
                <li key={"editors"} className={classes.listSection}>
                    <ul className={classes.ul}>
                        <ListSubheader>Editors</ListSubheader>
                        {editorOptions.map((item, index) => {
                            const selected = !!item.user
                                ? selectedEmails?.includes(item.user?.email)
                                : item.team.members.filter((user) =>
                                      selectedEmails?.includes(user?.email)
                                  ).length === item.team.members.length

                            return (
                                <AlertTable
                                    obj={item}
                                    key={index}
                                    selected={selected}
                                    selectedEmails={selectedEmails}
                                    setSelectedEmails={setSelectedEmails}
                                />
                            )
                        })}
                    </ul>
                </li>
                <li key={"admins"} className={classes.listSection}>
                    <ul className={classes.ul}>
                        <ListSubheader>Admins</ListSubheader>
                        {adminOptions.map((item, index) => {
                            const selected = selectedEmails?.includes(
                                item.user?.email
                            )

                            return (
                                <AlertTable
                                    obj={item}
                                    key={index}
                                    selected={selected}
                                    selectedEmails={selectedEmails}
                                    setSelectedEmails={setSelectedEmails}
                                />
                            )
                        })}
                    </ul>
                </li>
            </List>
            <Box mt={2}>
                <Box p={1}>
                    <Typography variant="subtitle1" color="textSecondary">
                        Emails Selected ({selectedEmails?.length ?? "0"})
                    </Typography>
                </Box>

                <List dense className={classes.selectedList}>
                    {selectedEmails?.sort().map((email, index) => {
                        return (
                            <ListItem key={email}>
                                <ListItemIcon>
                                    <EmailIcon fontSize="small" />
                                </ListItemIcon>
                                <ListItemText primary={email} />
                                <ListItemSecondaryAction>
                                    <IconButton
                                        size="small"
                                        onClick={() =>
                                            setSelectedEmails(
                                                selectedEmails?.filter(
                                                    (selectedEmail) =>
                                                        selectedEmail !== email
                                                )
                                            )
                                        }
                                    >
                                        <ClearIcon fontSize="small" />
                                    </IconButton>
                                </ListItemSecondaryAction>
                            </ListItem>
                        )
                    })}
                </List>
            </Box>
        </Box>
    )
}

function AlertTable(props: {
    obj: {
        user: User
        team: Team
        type: AccessType
    }
    selected: boolean
    setSelectedEmails: (selectedEmails: string[]) => void
    selectedEmails: string[] | null
}) {
    const { obj, selected, selectedEmails, setSelectedEmails } = props
    const [open, setOpen] = React.useState(false)

    return (
        <>
            <ListItem>
                <ListItemIcon>
                    <Checkbox
                        checked={selected}
                        size="small"
                        onChange={(e) => {
                            let newEmails: string[] = []
                            if (!!selected) {
                                if (!!obj.user) {
                                    newEmails = !!selectedEmails
                                        ? [
                                              ...selectedEmails?.filter(
                                                  (email) =>
                                                      email !== obj.user.email
                                              ),
                                          ]
                                        : []
                                } else {
                                    newEmails = !!selectedEmails
                                        ? [
                                              ...selectedEmails?.filter(
                                                  (email) =>
                                                      !obj.team.members.find(
                                                          (member) =>
                                                              member.email ===
                                                              email
                                                      )
                                              ),
                                          ]
                                        : []
                                }
                            } else {
                                if (!!obj.user) {
                                    newEmails = !!selectedEmails
                                        ? [...selectedEmails, obj.user.email]
                                        : [obj.user.email]
                                } else {
                                    newEmails = !!selectedEmails
                                        ? [
                                              ...selectedEmails,
                                              ...obj.team.members
                                                  .filter(
                                                      (user) =>
                                                          !selectedEmails?.includes(
                                                              user.email
                                                          )
                                                  )
                                                  .map((user) => user.email),
                                          ]
                                        : [
                                              ...obj.team.members
                                                  .filter(
                                                      (user) =>
                                                          !selectedEmails?.includes(
                                                              user.email
                                                          )
                                                  )
                                                  .map((user) => user.email),
                                          ]
                                }
                            }
                            setSelectedEmails(newEmails)
                        }}
                    />
                </ListItemIcon>
                <ListItemText
                    primary={
                        obj.user ? (
                            `${obj.user.firstName} ${obj.user.lastName}`
                        ) : (
                            <Box display="flex" alignItems="center">
                                <Typography variant="body2">
                                    {obj.team.name}
                                </Typography>

                                <GroupIcon
                                    style={{ marginLeft: 8 }}
                                    fontSize="small"
                                />
                            </Box>
                        )
                    }
                    secondary={
                        !!obj.user
                            ? obj.user.email
                            : `${
                                  obj.team.members.filter((user) =>
                                      selectedEmails?.includes(user.email)
                                  ).length
                              }/${obj.team.members.length} members selected`
                    }
                />
                {!!obj.team && (
                    <ListItemSecondaryAction>
                        <IconButton
                            aria-label="expand row"
                            size="small"
                            onClick={() => setOpen(!open)}
                        >
                            {open ? (
                                <KeyboardArrowUpIcon />
                            ) : (
                                <KeyboardArrowDownIcon />
                            )}
                        </IconButton>
                    </ListItemSecondaryAction>
                )}
            </ListItem>
            {!!obj.team && (
                <Box>
                    <Collapse in={open}>
                        <Box ml={2}>
                            {obj.team.members.map((user, index) => {
                                const teamMemberIsSelected = selectedEmails?.includes(
                                    user.email
                                )
                                return (
                                    <ListItem key={index}>
                                        <ListItemIcon>
                                            <Checkbox
                                                size="small"
                                                checked={teamMemberIsSelected}
                                                onChange={(e) => {
                                                    let newEmails: string[] = []
                                                    if (
                                                        !!teamMemberIsSelected
                                                    ) {
                                                        newEmails = !!selectedEmails
                                                            ? [
                                                                  ...selectedEmails.filter(
                                                                      (email) =>
                                                                          email !==
                                                                          user.email
                                                                  ),
                                                              ]
                                                            : []
                                                    } else {
                                                        newEmails = !!selectedEmails
                                                            ? [
                                                                  ...selectedEmails,
                                                                  user.email,
                                                              ]
                                                            : [user.email]
                                                    }
                                                    setSelectedEmails(newEmails)
                                                }}
                                            />
                                        </ListItemIcon>
                                        <ListItemText
                                            primary={`${user?.firstName} ${user?.lastName}`}
                                            secondary={user?.email}
                                        />
                                    </ListItem>
                                )
                            })}
                        </Box>
                    </Collapse>
                </Box>
            )}
        </>
    )
}
