import React from "react"
import Box from "@material-ui/core/Box"

import {
    createStyles,
    Theme,
    makeStyles,
    decomposeColor,
    useTheme,
} from "@material-ui/core/styles"
import WidgetEditToolbar from "./WidgetEditToolbar"
import { Widget } from "../../useWidgets"
import DragHandleIcon from "@material-ui/icons/DragHandle"
import {
    expandWidgetVar,
    myCurrentInputResponseLabelVar,
    FeedbackField,
    currentWorkspaceWidgetsVar,
} from "../../../../providers/GlobalState"
import ZoomInIcon from "@material-ui/icons/ZoomIn"
import ChevronRightIcon from "@material-ui/icons/ChevronRight"
import {
    IconButton,
    Typography,
    alpha,
    Divider,
    Fade,
    useMediaQuery,
    Chip,
} from "@material-ui/core"
import { useReactiveVar } from "@apollo/client"
import DetailsField from "../../../DetailsField"
import useWidget from "../../useWidget"
import DonutLargeIcon from "@material-ui/icons/DonutLarge"
import { SizeMe } from "react-sizeme"
import CheckCircleIcon from "@material-ui/icons/CheckCircle"
import { Input } from "../../../../__generated__/types"
import { ScoreSelectorDefaultValues } from "../../../inputs/types"
import { getCleanedFieldConfig } from "../../../inputs/util"
import { WidgetTypeName } from "../../useWidgetTypes"
import { useParams } from "react-router-dom"
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            display: "flex",
            width: "100%",
            flexDirection: "column",
            position: "relative",
        },
        header: {
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            color: theme.palette.text.primary,
            borderTopLeftRadius: theme.shape.borderRadius,
            borderTopRightRadius: theme.shape.borderRadius,
            width: "100%",
        },
        configurationHeader: {
            display: "flex",
            justifyContent: "space-between",
            color: theme.palette.text.primary,
            background: alpha(theme.palette.secondary.main, 0.15),
            borderTopLeftRadius: theme.shape.borderRadius,
            borderTopRightRadius: theme.shape.borderRadius,
            width: "100%",
            alignItems: "center",
            border: `1px solid ${theme.palette.divider}`,
        },
        small: {
            fontSize: 12,
        },
        actions: {
            marginLeft: "auto",
            color: theme.palette.text.hint,
        },
        interactionTools: {
            [theme.breakpoints.up("sm")]: {
                position: "absolute",
                right: 0,
                top: 0,
                backgroundColor: `rgba(${
                    decomposeColor(theme.palette.background.paper).values
                }, .25)`,
            },
            display: "flex",
        },
    })
)
interface WidgetHeaderProps {
    label?: string
    interactionTools?: React.ReactNode
    configurationTools?: React.ReactNode
    widget: Widget
    editing: boolean
    input?: Input
    fieldCompleted?: boolean
    inputWidget?: boolean
    inputConfig?: any
    hovered?: boolean
    sourceField?: FeedbackField
    onWidgetDeletion: (props: { widgetId: string; inputId?: string }) => void
    collectionCount?: number | undefined
}
const WidgetHeader: React.FunctionComponent<WidgetHeaderProps> = ({
    label: widgetLabel,
    interactionTools,
    configurationTools,
    widget,
    editing,
    input,
    fieldCompleted,
    inputWidget,
    inputConfig,
    hovered,
    sourceField,
    onWidgetDeletion,
    collectionCount,
}) => {
    //hooks
    const { conceptId } = useParams()
    const classes = useStyles()
    const theme = useTheme()
    const mobile = useMediaQuery(theme.breakpoints.down("sm"))
    const tabletOrBelow = useMediaQuery(theme.breakpoints.down("md"))
    const { onEditConfig, isInitialized, config } = useWidget(
        widget?.id ?? null
    )
    const expandedWidgetObject = useReactiveVar(expandWidgetVar)
    const expandedWidgetId = expandedWidgetObject?.[conceptId] ?? null
    const { orderedWidgets } = useReactiveVar(currentWorkspaceWidgetsVar)
    const currentPresentationIndex =
        (orderedWidgets
            ?.filter((item) => item.type !== WidgetTypeName.Heading)
            .findIndex((item) => item.id === widget?.id) ?? 0) + 1
    const isDialog = expandedWidgetId === widget?.id
    const { label, user: responseAuthor } = useReactiveVar(
        myCurrentInputResponseLabelVar
    )
    const viewingPrimaryResponse =
        label === ScoreSelectorDefaultValues.primaryResponse && !responseAuthor
    const viewingAllResponses =
        label === ScoreSelectorDefaultValues.allResponses && !responseAuthor
    //global state

    const handleSave = (val: string) => {
        if (isInitialized && !!onEditConfig) {
            onEditConfig({
                ...config,
                label: val,
            })
        }
    }

    let criteriaPrompt = null
    let statusIcon = null
    if (!!input && !!inputConfig) {
        statusIcon = (
            <Box display="flex" alignItems="center" mr={1}>
                {!!fieldCompleted ? (
                    <CheckCircleIcon
                        fontSize={isDialog && !mobile ? "medium" : "small"}
                        color="primary"
                    />
                ) : (
                    <DonutLargeIcon
                        fontSize={isDialog && !mobile ? "medium" : "small"}
                        color="secondary"
                    />
                )}
            </Box>
        )
        if (
            (!viewingPrimaryResponse && !inputConfig?.allowMultipleResponses) ||
            !!viewingAllResponses
        ) {
            statusIcon = null
        }

        if (!!sourceField) {
            const sourceFieldConfig = getCleanedFieldConfig(sourceField)
            if (!!mobile || !isDialog) {
                criteriaPrompt = (
                    <Box>
                        <Typography variant={"body2"} color="textSecondary">
                            {sourceFieldConfig?.name}
                        </Typography>
                        <Typography variant={"body1"}>
                            {inputConfig.name}
                        </Typography>
                    </Box>
                )
            } else {
                criteriaPrompt = (
                    <Box display="flex" alignItems="center">
                        <Typography variant={!!isDialog ? "h6" : "body1"}>
                            {sourceFieldConfig?.name}
                        </Typography>
                        <ChevronRightIcon
                            fontSize={isDialog ? "medium" : "small"}
                            style={{
                                marginRight: theme.spacing(0.25),
                                marginLeft: theme.spacing(0.25),
                            }}
                        />
                        <Typography variant={!!isDialog ? "h6" : "body1"}>
                            {inputConfig.name}
                        </Typography>
                    </Box>
                )
            }
        } else {
            criteriaPrompt = (
                <Box display="flex" alignItems="center">
                    <Typography
                        variant={!!isDialog && !mobile ? "h6" : "body1"}
                    >
                        {inputConfig?.name}
                    </Typography>
                </Box>
            )
        }
    }

    return (
        <Box display="flex" flexDirection="column">
            {!!editing && (
                <Box className={classes.container}>
                    <Box className={classes.configurationHeader}>
                        {editing && (
                            <Box
                                display="flex"
                                flexGrow={0}
                                className="widget-drag-handle"
                                style={{
                                    cursor: "move",
                                    paddingLeft: "8px",
                                }}
                            >
                                <DragHandleIcon />
                            </Box>
                        )}

                        <Box
                            display="flex"
                            justifyContent="flex-end"
                            className={classes.actions}
                        >
                            {configurationTools && configurationTools}
                            {editing && (
                                <WidgetEditToolbar
                                    widget={widget}
                                    input={input}
                                    onWidgetDeletion={onWidgetDeletion}
                                />
                            )}
                        </Box>
                    </Box>
                </Box>
            )}
            <SizeMe monitorWidth monitorHeight>
                {({ size }) => (
                    <Box className={classes.container}>
                        <Box className={classes.header}>
                            <Box p={!mobile && !!isDialog ? 2 : 1}>
                                <Chip
                                    size={
                                        isDialog && !mobile ? "medium" : "small"
                                    }
                                    label={
                                        <Typography
                                            variant={
                                                isDialog && !mobile
                                                    ? "h5"
                                                    : "body1"
                                            }
                                        >
                                            {currentPresentationIndex}
                                        </Typography>
                                    }
                                    icon={statusIcon}
                                />
                            </Box>

                            {!inputWidget ? (
                                <Box
                                    overflow="hidden"
                                    flexGrow={1}
                                    alignSelf="stretch"
                                    display="flex"
                                    p={0.75}
                                >
                                    <DetailsField
                                        value={widgetLabel ?? ""}
                                        handleEdit={handleSave}
                                        editable={!!editing}
                                        className={
                                            !!isDialog && !mobile
                                                ? "h6"
                                                : "body1"
                                        }
                                        maxLength={50}
                                        parentWidth={size.width - 15}
                                        grow={true}
                                    />
                                </Box>
                            ) : (
                                <Box
                                    display="flex"
                                    flexGrow={1}
                                    p={1}
                                    pr={0.5}
                                    alignItems="center"
                                    overflow="hidden"
                                >
                                    {criteriaPrompt}
                                </Box>
                            )}
                            <Fade
                                in={
                                    hovered === undefined ||
                                    !!hovered ||
                                    !!tabletOrBelow
                                }
                                mountOnEnter
                                unmountOnExit
                            >
                                <Box
                                    display={"flex"}
                                    className={classes.interactionTools}
                                >
                                    {interactionTools &&
                                        (!input || !!input?.criteria) && (
                                            <Box display="flex">
                                                {interactionTools}{" "}
                                            </Box>
                                        )}

                                    {expandedWidgetId !== widget?.id && (
                                        <Box height="100%">
                                            <IconButton
                                                style={{ padding: "8px" }}
                                                onClick={() =>
                                                    expandWidgetVar({
                                                        ...expandedWidgetObject,
                                                        [conceptId]:
                                                            widget?.id ?? null,
                                                    })
                                                }
                                            >
                                                <ZoomInIcon />
                                            </IconButton>
                                        </Box>
                                    )}
                                </Box>
                            </Fade>
                        </Box>
                    </Box>
                )}
            </SizeMe>

            <Divider />
        </Box>
    )
}

export default WidgetHeader
