import { gql } from "@apollo/client"
import { BASE_CRITERIA_FRAGMENT } from "../criteria/graphql"

export const FULL_CATEGORY_FRAGMENT = gql`
    fragment Category on Category {
        id
        name
        description
        icon
        isRoot
        isFilterOption
        isManaged
        criteria {
            ...Criteria
        }
        workflow {
            id
            title
        }
        defaultDashboard {
            id
            name
            description
            layout
            conceptHome {
                id
                title
                imageUrl
                summary
            }
        }
        parent {
            id
            name
            icon
            isRoot
            isFilterOption
            isManaged
        }
        children {
            id
            name
            icon
            isRoot
            isFilterOption
            isManaged
            workflow {
                id
                title
            }
            parent {
                id
                name
                icon
                isRoot
                isFilterOption
                isManaged
            }
        }
    }
    ${BASE_CRITERIA_FRAGMENT}
`

const CREATE_CATEGORY = gql`
    mutation CreateCategory(
        $name: String
        $icon: String
        $description: String
        $conceptType: ConceptType
        $isManaged: Boolean
        $isFilterOption: Boolean
        $isRoot: Boolean
    ) {
        CreateCategory(
            name: $name
            icon: $icon
            description: $description
            conceptType: $conceptType
            isManaged: $isManaged
            isFilterOption: $isFilterOption
            isRoot: $isRoot
        ) {
            id
            name
            icon
            description
            isManaged
            isFilterOption
            conceptType
            isRoot
        }
    }
`
const UPDATE_CATEGORY = gql`
    mutation UpdateCategory(
        $id: ID!
        $name: String
        $description: String
        $isManaged: Boolean
        $isFilterOption: Boolean
        $isRoot: Boolean
        $icon: String
    ) {
        UpdateCategory(
            id: $id
            name: $name
            description: $description
            isManaged: $isManaged
            isFilterOption: $isFilterOption
            isRoot: $isRoot
            icon: $icon
        ) {
            id
            name
            icon
            description
            isManaged
            isFilterOption
            conceptType
            isRoot
        }
    }
`
const DELETE_CATEGORY = gql`
    mutation DeleteCategory($id: ID!) {
        DeleteCategory(id: $id) {
            id
        }
    }
`
const ADD_CATEGORY_PARENT = gql`
    mutation AddCategoryParent($parentId: ID!, $childId: ID!) {
        AddCategoryParent(from: { id: $childId }, to: { id: $parentId }) {
            from {
                id
                name
                description
                conceptType
                parent {
                    id
                    name
                    description
                    conceptType
                }
                children {
                    id
                    name
                    description
                    conceptType
                    parent {
                        id
                        name
                        description
                        conceptType
                    }
                }
            }
            to {
                id
                name
                description
                conceptType
                parent {
                    id
                    name
                    description
                    conceptType
                }
                children {
                    id
                    name
                    description
                    conceptType
                    parent {
                        id
                        name
                        description
                        conceptType
                    }
                }
            }
        }
    }
`
const REMOVE_CATEGORY_PARENT = gql`
    mutation RemoveCategoryParent($parentId: ID!, $childId: ID!) {
        RemoveCategoryParent(from: { id: $childId }, to: { id: $parentId }) {
            from {
                id
                name
                description
                conceptType
                parent {
                    id
                    name
                    description
                    conceptType
                }
                children {
                    id
                    name
                    description
                    conceptType
                    parent {
                        id
                        name
                        description
                        conceptType
                    }
                }
            }
            to {
                id
                name
                description
                conceptType
                parent {
                    id
                    name
                    description
                    conceptType
                }
                children {
                    id
                    name
                    description
                    conceptType
                    parent {
                        id
                        name
                        description
                        conceptType
                    }
                }
            }
        }
    }
`

const CATEGORIES_FILTERED = gql`
    query CategoriesFiltered($filter: _CategoryFilter) {
        Category(filter: $filter, orderBy: name_asc) {
            ...Category
        }
    }
    ${FULL_CATEGORY_FRAGMENT}
`

export const ENVIRONMENT_CATEGORIES_AND_FIELDS = gql`
    query EnvironmentCategoriesAndFields {
        categories: Category(orderBy: name_asc) {
            ...Category
        }
        fields: Criteria {
            id
        }
    }
    ${FULL_CATEGORY_FRAGMENT}
`

const ADD_CONCEPT_CATEGORY = gql`
    mutation AddConceptCategory($id: ID!, $categoryId: ID!) {
        AddConceptCategory(from: { id: $id }, to: { id: $categoryId }) {
            from {
                id
                title
                category {
                    id
                    name
                    isRoot
                    icon
                }
            }
            to {
                id
                name
            }
        }
    }
`
const REMOVE_CONCEPT_CATEGORY = gql`
    mutation RemoveConceptCategory($id: ID!, $categoryId: ID!) {
        RemoveConceptCategory(from: { id: $id }, to: { id: $categoryId }) {
            from {
                id
                title
                category {
                    id
                    name
                }
            }
            to {
                id
                name
            }
        }
    }
`

const ADD_CATEGORY_WORKFLOW = gql`
    mutation AddCategoryWorkflow($categoryId: ID!, $workflowId: ID!) {
        AddCategoryWorkflow(
            from: { id: $workflowId }
            to: { id: $categoryId }
        ) {
            from {
                id
            }
            to {
                id
                workflow {
                    id
                }
            }
        }
    }
`
const REMOVE_CATEGORY_WORKFLOW = gql`
    mutation RemoveCategoryWorkflow($categoryId: ID!, $workflowId: ID!) {
        RemoveCategoryWorkflow(
            from: { id: $workflowId }
            to: { id: $categoryId }
        ) {
            from {
                id
            }
            to {
                id
                workflow {
                    id
                }
            }
        }
    }
`
export {
    CREATE_CATEGORY,
    REMOVE_CONCEPT_CATEGORY,
    ADD_CONCEPT_CATEGORY,
    UPDATE_CATEGORY,
    DELETE_CATEGORY,
    ADD_CATEGORY_PARENT,
    REMOVE_CATEGORY_PARENT,
    CATEGORIES_FILTERED,
    ADD_CATEGORY_WORKFLOW,
    REMOVE_CATEGORY_WORKFLOW,
}
