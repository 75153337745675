export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Upload: any;
};










export enum AccessType {
  NONE = 'NONE',
  VIEWER = 'VIEWER',
  CONTRIBUTOR = 'CONTRIBUTOR',
  EDITOR = 'EDITOR',
  OWNER = 'OWNER'
}

export type Category = {
  __typename?: 'Category';
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  isRoot?: Maybe<Scalars['Boolean']>;
  isManaged?: Maybe<Scalars['Boolean']>;
  isFilterOption?: Maybe<Scalars['Boolean']>;
  isSystemDefault?: Maybe<Scalars['Boolean']>;
  accessRoles?: Maybe<Array<Maybe<SystemRole>>>;
  icon?: Maybe<Scalars['String']>;
  depth?: Maybe<Scalars['Int']>;
  conceptType?: Maybe<ConceptType>;
  parent?: Maybe<Category>;
  children?: Maybe<Array<Maybe<Category>>>;
  concepts?: Maybe<Array<Maybe<Concept>>>;
  defaultCriteria?: Maybe<Array<Maybe<Criteria>>>;
  criteria?: Maybe<Array<Maybe<Criteria>>>;
  defaultDashboard?: Maybe<DashboardView>;
  workflow?: Maybe<Workflow>;
  /** Generated field for querying the Neo4j [system id](https://neo4j.com/docs/cypher-manual/current/functions/scalar/#functions-id) of this node. */
  _id?: Maybe<Scalars['String']>;
};


export type CategoryParentArgs = {
  filter?: Maybe<_CategoryFilter>;
};


export type CategoryChildrenArgs = {
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<Maybe<_CategoryOrdering>>>;
  filter?: Maybe<_CategoryFilter>;
};


export type CategoryConceptsArgs = {
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<Maybe<_ConceptOrdering>>>;
  filter?: Maybe<_ConceptFilter>;
};


export type CategoryDefaultCriteriaArgs = {
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<Maybe<_CriteriaOrdering>>>;
  filter?: Maybe<_CriteriaFilter>;
};


export type CategoryCriteriaArgs = {
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<Maybe<_CriteriaOrdering>>>;
  filter?: Maybe<_CriteriaFilter>;
};


export type CategoryDefaultDashboardArgs = {
  filter?: Maybe<_DashboardViewFilter>;
};


export type CategoryWorkflowArgs = {
  filter?: Maybe<_WorkflowFilter>;
};

export type ChatMessage = {
  role?: Maybe<ChatRole>;
  content?: Maybe<Scalars['String']>;
};

export enum ChatRole {
  ASSISTANT = 'assistant',
  USER = 'user'
}

export type Checklist = {
  __typename?: 'Checklist';
  id?: Maybe<Scalars['ID']>;
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  conceptBased?: Maybe<Scalars['Boolean']>;
  isOrdered?: Maybe<Scalars['Boolean']>;
  itemPrefixType?: Maybe<ItemPrefixType>;
  itemIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  listLengthLimit?: Maybe<Scalars['Int']>;
  lastUpdated?: Maybe<_Neo4jDateTime>;
  updates?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdAt?: Maybe<_Neo4jDateTime>;
  createdBy?: Maybe<User>;
  type?: Maybe<ChecklistType>;
  items?: Maybe<Array<Maybe<ChecklistItem>>>;
  concept?: Maybe<Array<Maybe<Concept>>>;
  exercise?: Maybe<Exercise>;
  /** Generated field for querying the Neo4j [system id](https://neo4j.com/docs/cypher-manual/current/functions/scalar/#functions-id) of this node. */
  _id?: Maybe<Scalars['String']>;
};


export type ChecklistCreatedByArgs = {
  filter?: Maybe<_UserFilter>;
};


export type ChecklistTypeArgs = {
  filter?: Maybe<_ChecklistTypeFilter>;
};


export type ChecklistItemsArgs = {
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<Maybe<_ChecklistItemOrdering>>>;
  filter?: Maybe<_ChecklistItemFilter>;
};


export type ChecklistConceptArgs = {
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<Maybe<_ConceptOrdering>>>;
  filter?: Maybe<_ConceptFilter>;
};


export type ChecklistExerciseArgs = {
  filter?: Maybe<_ExerciseFilter>;
};

export type ChecklistInput = {
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  itemPrefixType?: Maybe<ItemPrefixType>;
  isOrdered?: Maybe<Scalars['Boolean']>;
  conceptBased?: Maybe<Scalars['Boolean']>;
};

export type ChecklistItem = {
  __typename?: 'ChecklistItem';
  id?: Maybe<Scalars['ID']>;
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  checked?: Maybe<Scalars['Boolean']>;
  lastUpdated?: Maybe<_Neo4jDateTime>;
  displayState?: Maybe<ItemDisplayState>;
  listIndex?: Maybe<Scalars['Int']>;
  updates?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdAt?: Maybe<_Neo4jDateTime>;
  createdBy?: Maybe<User>;
  checklist?: Maybe<Checklist>;
  parent?: Maybe<ChecklistItem>;
  children?: Maybe<Array<Maybe<ChecklistItem>>>;
  concept?: Maybe<Concept>;
  /** Generated field for querying the Neo4j [system id](https://neo4j.com/docs/cypher-manual/current/functions/scalar/#functions-id) of this node. */
  _id?: Maybe<Scalars['String']>;
};


export type ChecklistItemCreatedByArgs = {
  filter?: Maybe<_UserFilter>;
};


export type ChecklistItemChecklistArgs = {
  filter?: Maybe<_ChecklistFilter>;
};


export type ChecklistItemParentArgs = {
  filter?: Maybe<_ChecklistItemFilter>;
};


export type ChecklistItemChildrenArgs = {
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<Maybe<_ChecklistItemOrdering>>>;
  filter?: Maybe<_ChecklistItemFilter>;
};


export type ChecklistItemConceptArgs = {
  filter?: Maybe<_ConceptFilter>;
};

export type ChecklistItemUpdate = {
  id: Scalars['ID'];
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  checked?: Maybe<Scalars['Boolean']>;
  displayState?: Maybe<ItemDisplayState>;
  listIndex?: Maybe<Scalars['Int']>;
};

export type ChecklistType = {
  __typename?: 'ChecklistType';
  id?: Maybe<Scalars['ID']>;
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  itemPrefixType?: Maybe<ItemPrefixType>;
  conceptBased?: Maybe<Scalars['Boolean']>;
  checklists?: Maybe<Array<Maybe<Checklist>>>;
  /** Generated field for querying the Neo4j [system id](https://neo4j.com/docs/cypher-manual/current/functions/scalar/#functions-id) of this node. */
  _id?: Maybe<Scalars['String']>;
};


export type ChecklistTypeChecklistsArgs = {
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<Maybe<_ChecklistOrdering>>>;
  filter?: Maybe<_ChecklistFilter>;
};

export type ChecklistUpdateInput = {
  id: Scalars['ID'];
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  itemIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  itemPrefixType?: Maybe<ItemPrefixType>;
  isOrdered?: Maybe<Scalars['Boolean']>;
};

export type Comment = {
  __typename?: 'Comment';
  commentId?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<_Neo4jDateTime>;
  user?: Maybe<User>;
  body?: Maybe<Scalars['String']>;
  concept?: Maybe<Concept>;
  replyTo?: Maybe<Array<Maybe<Comment>>>;
  replies?: Maybe<Array<Maybe<Comment>>>;
  notifications?: Maybe<Array<Maybe<Notification>>>;
  replyCount?: Maybe<Scalars['Int']>;
  likedBy?: Maybe<Array<Maybe<User>>>;
  /** Generated field for querying the Neo4j [system id](https://neo4j.com/docs/cypher-manual/current/functions/scalar/#functions-id) of this node. */
  _id?: Maybe<Scalars['String']>;
};


export type CommentUserArgs = {
  filter?: Maybe<_UserFilter>;
};


export type CommentConceptArgs = {
  filter?: Maybe<_ConceptFilter>;
};


export type CommentReplyToArgs = {
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<Maybe<_CommentOrdering>>>;
  filter?: Maybe<_CommentFilter>;
};


export type CommentRepliesArgs = {
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<Maybe<_CommentOrdering>>>;
  filter?: Maybe<_CommentFilter>;
};


export type CommentNotificationsArgs = {
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<Maybe<_NotificationOrdering>>>;
  filter?: Maybe<_NotificationFilter>;
};


export type CommentLikedByArgs = {
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<Maybe<_UserOrdering>>>;
  filter?: Maybe<_UserFilter>;
};

export type Company = {
  __typename?: 'Company';
  companyId?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  employees?: Maybe<Array<Maybe<User>>>;
  industries?: Maybe<Array<Maybe<Industry>>>;
  teams?: Maybe<Array<Maybe<Team>>>;
  /** Generated field for querying the Neo4j [system id](https://neo4j.com/docs/cypher-manual/current/functions/scalar/#functions-id) of this node. */
  _id?: Maybe<Scalars['String']>;
};


export type CompanyEmployeesArgs = {
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<Maybe<_UserOrdering>>>;
  filter?: Maybe<_UserFilter>;
};


export type CompanyIndustriesArgs = {
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<Maybe<_IndustryOrdering>>>;
  filter?: Maybe<_IndustryFilter>;
};


export type CompanyTeamsArgs = {
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<Maybe<_TeamOrdering>>>;
  filter?: Maybe<_TeamFilter>;
};

export type Concept = {
  __typename?: 'Concept';
  id?: Maybe<Scalars['ID']>;
  slug?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  summary?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  translations?: Maybe<Array<Maybe<Translation>>>;
  username?: Maybe<Scalars['String']>;
  user?: Maybe<User>;
  addedUsers?: Maybe<Array<Maybe<_ConceptAddedUsers>>>;
  addedTeams?: Maybe<Array<Maybe<_ConceptAddedTeams>>>;
  userHomepage?: Maybe<User>;
  teamHomepage?: Maybe<Team>;
  industries?: Maybe<Array<Maybe<Industry>>>;
  recentUpdates?: Maybe<Array<Maybe<_ConceptRecentUpdates>>>;
  createdBy?: Maybe<User>;
  createdAt?: Maybe<_Neo4jDateTime>;
  startDate?: Maybe<_Neo4jDateTime>;
  endDate?: Maybe<_Neo4jDateTime>;
  isArchived?: Maybe<Scalars['Boolean']>;
  isPublic?: Maybe<Scalars['Boolean']>;
  isPublicAccessType?: Maybe<AccessType>;
  source?: Maybe<Scalars['String']>;
  sourceId?: Maybe<Scalars['String']>;
  vis?: Maybe<Visibility>;
  checklistIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  dashboardIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  status?: Maybe<Array<Maybe<ConceptStatus>>>;
  parent?: Maybe<Concept>;
  children?: Maybe<Array<Maybe<Concept>>>;
  category?: Maybe<Category>;
  rootCategory?: Maybe<Category>;
  imageUrl?: Maybe<Scalars['String']>;
  webLink?: Maybe<Link>;
  likedBy?: Maybe<Array<Maybe<User>>>;
  criteria?: Maybe<Array<Maybe<Criteria>>>;
  criteriaScores?: Maybe<Array<Maybe<CriteriaScore>>>;
  likeCount?: Maybe<Scalars['Int']>;
  homeDashboard?: Maybe<DashboardView>;
  usesDashboard?: Maybe<Array<Maybe<DashboardView>>>;
  comments?: Maybe<Array<Maybe<Comment>>>;
  commentCount?: Maybe<Scalars['Int']>;
  watchedBy?: Maybe<Array<Maybe<User>>>;
  notifications?: Maybe<Array<Maybe<Notification>>>;
  tags?: Maybe<Array<Maybe<Tag>>>;
  connections?: Maybe<Array<Maybe<Concept>>>;
  detailedConnections?: Maybe<_ConceptDetailedConnectionsDirections>;
  teams?: Maybe<Array<Maybe<Team>>>;
  inLists?: Maybe<Array<Maybe<ConceptList>>>;
  workspace?: Maybe<ConceptList>;
  childConceptLists?: Maybe<Array<Maybe<ConceptList>>>;
  checklists?: Maybe<Array<Maybe<Checklist>>>;
  checklistItemLink?: Maybe<Array<Maybe<ChecklistItem>>>;
  exercisesCreated?: Maybe<Array<Maybe<Exercise>>>;
  exercisesIncluding?: Maybe<Array<Maybe<Exercise>>>;
  createdForExerciseSubmission?: Maybe<ExerciseSubmission>;
  inputs?: Maybe<Array<Maybe<Input>>>;
  includedForInputs?: Maybe<Array<Maybe<Input>>>;
  createdViaInput?: Maybe<Array<Maybe<Input>>>;
  usedByScore?: Maybe<Array<Maybe<CriteriaScore>>>;
  /** Generated field for querying the Neo4j [system id](https://neo4j.com/docs/cypher-manual/current/functions/scalar/#functions-id) of this node. */
  _id?: Maybe<Scalars['String']>;
};


export type ConceptTranslationsArgs = {
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<Maybe<_TranslationOrdering>>>;
  filter?: Maybe<_TranslationFilter>;
};


export type ConceptUserArgs = {
  filter?: Maybe<_UserFilter>;
};


export type ConceptAddedUsersArgs = {
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<Maybe<_UserAccessOrdering>>>;
  filter?: Maybe<_ConceptUserAccessFilter>;
};


export type ConceptAddedTeamsArgs = {
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<Maybe<_TeamAccessOrdering>>>;
  filter?: Maybe<_ConceptTeamAccessFilter>;
};


export type ConceptUserHomepageArgs = {
  filter?: Maybe<_UserFilter>;
};


export type ConceptTeamHomepageArgs = {
  filter?: Maybe<_TeamFilter>;
};


export type ConceptIndustriesArgs = {
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<Maybe<_IndustryOrdering>>>;
  filter?: Maybe<_IndustryFilter>;
};


export type ConceptRecentUpdatesArgs = {
  limit?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<Maybe<_ConceptUpdateOrdering>>>;
  filter?: Maybe<_ConceptConceptUpdateFilter>;
};


export type ConceptCreatedByArgs = {
  filter?: Maybe<_UserFilter>;
};


export type ConceptStatusArgs = {
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<Maybe<_ConceptStatusOrdering>>>;
  filter?: Maybe<_ConceptStatusFilter>;
};


export type ConceptParentArgs = {
  filter?: Maybe<_ConceptFilter>;
};


export type ConceptChildrenArgs = {
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<Maybe<_ConceptOrdering>>>;
  filter?: Maybe<_ConceptFilter>;
};


export type ConceptCategoryArgs = {
  filter?: Maybe<_CategoryFilter>;
};


export type ConceptWebLinkArgs = {
  filter?: Maybe<_LinkFilter>;
};


export type ConceptLikedByArgs = {
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<Maybe<_UserOrdering>>>;
  filter?: Maybe<_UserFilter>;
};


export type ConceptCriteriaArgs = {
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<Maybe<_CriteriaOrdering>>>;
  filter?: Maybe<_CriteriaFilter>;
};


export type ConceptCriteriaScoresArgs = {
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<Maybe<_CriteriaScoreOrdering>>>;
  filter?: Maybe<_CriteriaScoreFilter>;
};


export type ConceptHomeDashboardArgs = {
  filter?: Maybe<_DashboardViewFilter>;
};


export type ConceptUsesDashboardArgs = {
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<Maybe<_DashboardViewOrdering>>>;
  filter?: Maybe<_DashboardViewFilter>;
};


export type ConceptCommentsArgs = {
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<Maybe<_CommentOrdering>>>;
  filter?: Maybe<_CommentFilter>;
};


export type ConceptWatchedByArgs = {
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<Maybe<_UserOrdering>>>;
  filter?: Maybe<_UserFilter>;
};


export type ConceptNotificationsArgs = {
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<Maybe<_NotificationOrdering>>>;
  filter?: Maybe<_NotificationFilter>;
};


export type ConceptTagsArgs = {
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<Maybe<_TagOrdering>>>;
  filter?: Maybe<_TagFilter>;
};


export type ConceptConnectionsArgs = {
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<Maybe<_ConceptOrdering>>>;
  filter?: Maybe<_ConceptFilter>;
};


export type ConceptTeamsArgs = {
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<Maybe<_TeamOrdering>>>;
  filter?: Maybe<_TeamFilter>;
};


export type ConceptInListsArgs = {
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<Maybe<_ConceptListOrdering>>>;
  filter?: Maybe<_ConceptListFilter>;
};


export type ConceptWorkspaceArgs = {
  filter?: Maybe<_ConceptListFilter>;
};


export type ConceptChildConceptListsArgs = {
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<Maybe<_ConceptListOrdering>>>;
  filter?: Maybe<_ConceptListFilter>;
};


export type ConceptChecklistsArgs = {
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<Maybe<_ChecklistOrdering>>>;
  filter?: Maybe<_ChecklistFilter>;
};


export type ConceptChecklistItemLinkArgs = {
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<Maybe<_ChecklistItemOrdering>>>;
  filter?: Maybe<_ChecklistItemFilter>;
};


export type ConceptExercisesCreatedArgs = {
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<Maybe<_ExerciseOrdering>>>;
  filter?: Maybe<_ExerciseFilter>;
};


export type ConceptExercisesIncludingArgs = {
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<Maybe<_ExerciseOrdering>>>;
  filter?: Maybe<_ExerciseFilter>;
};


export type ConceptCreatedForExerciseSubmissionArgs = {
  filter?: Maybe<_ExerciseSubmissionFilter>;
};


export type ConceptInputsArgs = {
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<Maybe<_InputOrdering>>>;
  filter?: Maybe<_InputFilter>;
};


export type ConceptIncludedForInputsArgs = {
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<Maybe<_InputOrdering>>>;
  filter?: Maybe<_InputFilter>;
};


export type ConceptCreatedViaInputArgs = {
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<Maybe<_InputOrdering>>>;
  filter?: Maybe<_InputFilter>;
};


export type ConceptUsedByScoreArgs = {
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<Maybe<_CriteriaScoreOrdering>>>;
  filter?: Maybe<_CriteriaScoreFilter>;
};

export type ConceptBoard = {
  __typename?: 'ConceptBoard';
  id?: Maybe<Scalars['ID']>;
  title?: Maybe<Scalars['String']>;
  boardType?: Maybe<Scalars['String']>;
  columns?: Maybe<Array<Maybe<ConceptList>>>;
  columnOrder?: Maybe<Array<Maybe<Scalars['ID']>>>;
  user?: Maybe<User>;
  /** Generated field for querying the Neo4j [system id](https://neo4j.com/docs/cypher-manual/current/functions/scalar/#functions-id) of this node. */
  _id?: Maybe<Scalars['String']>;
};


export type ConceptBoardColumnsArgs = {
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<Maybe<_ConceptListOrdering>>>;
  filter?: Maybe<_ConceptListFilter>;
};


export type ConceptBoardUserArgs = {
  filter?: Maybe<_UserFilter>;
};

export type ConceptConnection = {
  __typename?: 'ConceptConnection';
  from?: Maybe<Concept>;
  to?: Maybe<Concept>;
  description?: Maybe<Scalars['String']>;
  type?: Maybe<ConnectionType>;
  username?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<_Neo4jDateTime>;
  updateValue?: Maybe<Scalars['String']>;
};

export type ConceptInput = {
  title?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  summary?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  isPublic?: Maybe<Scalars['Boolean']>;
  isPublicAccessType?: Maybe<AccessType>;
  imageUrl?: Maybe<Scalars['String']>;
};

export type ConceptList = {
  __typename?: 'ConceptList';
  id?: Maybe<Scalars['ID']>;
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  type?: Maybe<ConceptListType>;
  isOrdered?: Maybe<Scalars['Boolean']>;
  user?: Maybe<User>;
  inboxUser?: Maybe<User>;
  team?: Maybe<Team>;
  concepts?: Maybe<Array<Maybe<Concept>>>;
  workspaceConcept?: Maybe<Concept>;
  parentConcept?: Maybe<Concept>;
  conceptIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  conceptBoard?: Maybe<ConceptBoard>;
  persona?: Maybe<Concept>;
  /** Generated field for querying the Neo4j [system id](https://neo4j.com/docs/cypher-manual/current/functions/scalar/#functions-id) of this node. */
  _id?: Maybe<Scalars['String']>;
};


export type ConceptListUserArgs = {
  filter?: Maybe<_UserFilter>;
};


export type ConceptListInboxUserArgs = {
  filter?: Maybe<_UserFilter>;
};


export type ConceptListTeamArgs = {
  filter?: Maybe<_TeamFilter>;
};


export type ConceptListConceptsArgs = {
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<Maybe<_ConceptOrdering>>>;
  filter?: Maybe<_ConceptFilter>;
};


export type ConceptListWorkspaceConceptArgs = {
  filter?: Maybe<_ConceptFilter>;
};


export type ConceptListParentConceptArgs = {
  filter?: Maybe<_ConceptFilter>;
};


export type ConceptListConceptBoardArgs = {
  filter?: Maybe<_ConceptBoardFilter>;
};


export type ConceptListPersonaArgs = {
  filter?: Maybe<_ConceptFilter>;
};

export enum ConceptListType {
  USER_WORKSPACE = 'USER_WORKSPACE',
  TEAM_WORKSPACE = 'TEAM_WORKSPACE',
  FEATURE_BACKLOG = 'FEATURE_BACKLOG',
  PERSONA_REQUIREMENTS = 'PERSONA_REQUIREMENTS',
  EXERCISE_CONCEPTS = 'EXERCISE_CONCEPTS'
}

export type ConceptStatus = {
  __typename?: 'ConceptStatus';
  id?: Maybe<Scalars['ID']>;
  isActive?: Maybe<Scalars['Boolean']>;
  stage?: Maybe<WorkflowStage>;
  justification?: Maybe<Scalars['String']>;
  justificationNotes?: Maybe<Scalars['String']>;
  createdAt?: Maybe<_Neo4jDateTime>;
  concept?: Maybe<Concept>;
  /** Generated field for querying the Neo4j [system id](https://neo4j.com/docs/cypher-manual/current/functions/scalar/#functions-id) of this node. */
  _id?: Maybe<Scalars['String']>;
};


export type ConceptStatusStageArgs = {
  filter?: Maybe<_WorkflowStageFilter>;
};


export type ConceptStatusConceptArgs = {
  filter?: Maybe<_ConceptFilter>;
};

export enum ConceptType {
  IDEA = 'Idea',
  REQUIREMENT = 'Requirement',
  RESOURCE = 'Resource',
  PROBLEM = 'Problem',
  OBSERVATION = 'Observation',
  SOLUTION = 'Solution',
  USERINTERVIEW = 'UserInterview',
  FEATURE = 'Feature',
  TREND = 'Trend',
  PERSONA = 'Persona'
}

export type ConceptUpdate = {
  __typename?: 'ConceptUpdate';
  from?: Maybe<User>;
  to?: Maybe<Concept>;
  updatedAt?: Maybe<_Neo4jDateTime>;
  updateValue?: Maybe<Scalars['String']>;
};

export type ConceptUpdateInput = {
  id: Scalars['ID'];
  title?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  summary?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  isPublic?: Maybe<Scalars['Boolean']>;
  isPublicAccessType?: Maybe<AccessType>;
  imageUrl?: Maybe<Scalars['String']>;
  checklistIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  dashboardIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  startDate?: Maybe<_Neo4jDateTimeInput>;
  endDate?: Maybe<_Neo4jDateTimeInput>;
};

export enum ConnectionType {
  RESOLVES = 'RESOLVES',
  SUPPORTS = 'SUPPORTS',
  RELATES = 'RELATES',
  CHALLENGES = 'CHALLENGES',
  BLOCKS = 'BLOCKS'
}

export type Criteria = {
  __typename?: 'Criteria';
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  isDefault?: Maybe<Scalars['Boolean']>;
  criteriaType?: Maybe<Scalars['String']>;
  criteriaOptions?: Maybe<Scalars['String']>;
  inputConfig?: Maybe<Scalars['String']>;
  isArchived?: Maybe<Scalars['Boolean']>;
  scores?: Maybe<Array<Maybe<CriteriaScore>>>;
  childIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  parent?: Maybe<Criteria>;
  children?: Maybe<Array<Maybe<Criteria>>>;
  parentFields?: Maybe<Array<Maybe<Criteria>>>;
  subfields?: Maybe<Array<Maybe<Criteria>>>;
  concept?: Maybe<Concept>;
  category?: Maybe<Category>;
  categories?: Maybe<Array<Maybe<Category>>>;
  exercise?: Maybe<Exercise>;
  exerciseTypes?: Maybe<Array<Maybe<ExerciseType>>>;
  inputs?: Maybe<Array<Maybe<Input>>>;
  /** Generated field for querying the Neo4j [system id](https://neo4j.com/docs/cypher-manual/current/functions/scalar/#functions-id) of this node. */
  _id?: Maybe<Scalars['String']>;
};


export type CriteriaScoresArgs = {
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<Maybe<_CriteriaScoreOrdering>>>;
  filter?: Maybe<_CriteriaScoreFilter>;
};


export type CriteriaParentArgs = {
  filter?: Maybe<_CriteriaFilter>;
};


export type CriteriaChildrenArgs = {
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<Maybe<_CriteriaOrdering>>>;
  filter?: Maybe<_CriteriaFilter>;
};


export type CriteriaParentFieldsArgs = {
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<Maybe<_CriteriaOrdering>>>;
  filter?: Maybe<_CriteriaFilter>;
};


export type CriteriaSubfieldsArgs = {
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<Maybe<_CriteriaOrdering>>>;
  filter?: Maybe<_CriteriaFilter>;
};


export type CriteriaConceptArgs = {
  filter?: Maybe<_ConceptFilter>;
};


export type CriteriaCategoryArgs = {
  filter?: Maybe<_CategoryFilter>;
};


export type CriteriaCategoriesArgs = {
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<Maybe<_CategoryOrdering>>>;
  filter?: Maybe<_CategoryFilter>;
};


export type CriteriaExerciseArgs = {
  filter?: Maybe<_ExerciseFilter>;
};


export type CriteriaExerciseTypesArgs = {
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<Maybe<_ExerciseTypeOrdering>>>;
  filter?: Maybe<_ExerciseTypeFilter>;
};


export type CriteriaInputsArgs = {
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<Maybe<_InputOrdering>>>;
  filter?: Maybe<_InputFilter>;
};

export type CriteriaScore = {
  __typename?: 'CriteriaScore';
  id?: Maybe<Scalars['ID']>;
  label?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['Float']>;
  response?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  chatHistory?: Maybe<Scalars['String']>;
  status?: Maybe<ScoreStatus>;
  conceptDefault?: Maybe<Scalars['Boolean']>;
  lastUpdated?: Maybe<_Neo4jDateTime>;
  isArchived?: Maybe<Scalars['Boolean']>;
  lastUpdatedByUserId?: Maybe<Scalars['String']>;
  user?: Maybe<User>;
  criteria?: Maybe<Criteria>;
  scoredConcept?: Maybe<Concept>;
  concepts?: Maybe<Array<Maybe<Concept>>>;
  exerciseSubmission?: Maybe<ExerciseSubmission>;
  exercise?: Maybe<Exercise>;
  input?: Maybe<Input>;
  /** Generated field for querying the Neo4j [system id](https://neo4j.com/docs/cypher-manual/current/functions/scalar/#functions-id) of this node. */
  _id?: Maybe<Scalars['String']>;
};


export type CriteriaScoreUserArgs = {
  filter?: Maybe<_UserFilter>;
};


export type CriteriaScoreCriteriaArgs = {
  filter?: Maybe<_CriteriaFilter>;
};


export type CriteriaScoreScoredConceptArgs = {
  filter?: Maybe<_ConceptFilter>;
};


export type CriteriaScoreConceptsArgs = {
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<Maybe<_ConceptOrdering>>>;
  filter?: Maybe<_ConceptFilter>;
};


export type CriteriaScoreExerciseSubmissionArgs = {
  filter?: Maybe<_ExerciseSubmissionFilter>;
};


export type CriteriaScoreExerciseArgs = {
  filter?: Maybe<_ExerciseFilter>;
};


export type CriteriaScoreInputArgs = {
  filter?: Maybe<_InputFilter>;
};

export type DashboardView = {
  __typename?: 'DashboardView';
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  layout?: Maybe<Scalars['String']>;
  isTemplate?: Maybe<Scalars['Boolean']>;
  isPublic?: Maybe<Scalars['Boolean']>;
  activeTheme?: Maybe<DesignTheme>;
  createdBy?: Maybe<User>;
  usedBy?: Maybe<Array<Maybe<User>>>;
  usedByConcepts?: Maybe<Array<Maybe<Concept>>>;
  widgets?: Maybe<Array<Maybe<Widget>>>;
  conceptHome?: Maybe<Array<Maybe<Concept>>>;
  categoryDefault?: Maybe<Category>;
  /** Generated field for querying the Neo4j [system id](https://neo4j.com/docs/cypher-manual/current/functions/scalar/#functions-id) of this node. */
  _id?: Maybe<Scalars['String']>;
};


export type DashboardViewActiveThemeArgs = {
  filter?: Maybe<_DesignThemeFilter>;
};


export type DashboardViewCreatedByArgs = {
  filter?: Maybe<_UserFilter>;
};


export type DashboardViewUsedByArgs = {
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<Maybe<_UserOrdering>>>;
  filter?: Maybe<_UserFilter>;
};


export type DashboardViewUsedByConceptsArgs = {
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<Maybe<_ConceptOrdering>>>;
  filter?: Maybe<_ConceptFilter>;
};


export type DashboardViewWidgetsArgs = {
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<Maybe<_WidgetOrdering>>>;
  filter?: Maybe<_WidgetFilter>;
};


export type DashboardViewConceptHomeArgs = {
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<Maybe<_ConceptOrdering>>>;
  filter?: Maybe<_ConceptFilter>;
};


export type DashboardViewCategoryDefaultArgs = {
  filter?: Maybe<_CategoryFilter>;
};

export type DesignTheme = {
  __typename?: 'DesignTheme';
  themeId?: Maybe<Scalars['ID']>;
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  darkMode?: Maybe<Scalars['Boolean']>;
  primary?: Maybe<Scalars['String']>;
  secondary?: Maybe<Scalars['String']>;
  primaryHue?: Maybe<Scalars['String']>;
  secondaryHue?: Maybe<Scalars['String']>;
  primaryShade?: Maybe<Scalars['Int']>;
  secondaryShade?: Maybe<Scalars['Int']>;
  background?: Maybe<Scalars['String']>;
  usedByDashboards?: Maybe<Array<Maybe<DashboardView>>>;
  createdBy?: Maybe<User>;
  savedBy?: Maybe<Array<Maybe<User>>>;
  usedAsLightBy?: Maybe<Array<Maybe<User>>>;
  usedAsDarkBy?: Maybe<Array<Maybe<User>>>;
  /** Generated field for querying the Neo4j [system id](https://neo4j.com/docs/cypher-manual/current/functions/scalar/#functions-id) of this node. */
  _id?: Maybe<Scalars['String']>;
};


export type DesignThemeUsedByDashboardsArgs = {
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<Maybe<_DashboardViewOrdering>>>;
  filter?: Maybe<_DashboardViewFilter>;
};


export type DesignThemeCreatedByArgs = {
  filter?: Maybe<_UserFilter>;
};


export type DesignThemeSavedByArgs = {
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<Maybe<_UserOrdering>>>;
  filter?: Maybe<_UserFilter>;
};


export type DesignThemeUsedAsLightByArgs = {
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<Maybe<_UserOrdering>>>;
  filter?: Maybe<_UserFilter>;
};


export type DesignThemeUsedAsDarkByArgs = {
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<Maybe<_UserOrdering>>>;
  filter?: Maybe<_UserFilter>;
};

export type EnvironmentSetting = {
  __typename?: 'EnvironmentSetting';
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
  /** Generated field for querying the Neo4j [system id](https://neo4j.com/docs/cypher-manual/current/functions/scalar/#functions-id) of this node. */
  _id?: Maybe<Scalars['String']>;
};

export type Exercise = {
  __typename?: 'Exercise';
  id?: Maybe<Scalars['ID']>;
  url?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  instructions?: Maybe<Scalars['String']>;
  timerType?: Maybe<TimerType>;
  imageUrl?: Maybe<Scalars['String']>;
  duration?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<_Neo4jDateTime>;
  startTime?: Maybe<_Neo4jDateTime>;
  endTime?: Maybe<_Neo4jDateTime>;
  status?: Maybe<ExerciseStatus>;
  isPublic?: Maybe<Scalars['Boolean']>;
  isTemplate?: Maybe<Scalars['Boolean']>;
  isConceptFeedback?: Maybe<Scalars['Boolean']>;
  exerciseConfig?: Maybe<Scalars['String']>;
  criteria?: Maybe<Array<Maybe<Criteria>>>;
  type?: Maybe<ExerciseType>;
  facilitators?: Maybe<Array<Maybe<User>>>;
  invitedTeams?: Maybe<Array<Maybe<Team>>>;
  invitedUsers?: Maybe<Array<Maybe<User>>>;
  participants?: Maybe<Array<Maybe<User>>>;
  createdBy?: Maybe<User>;
  submissions?: Maybe<Array<Maybe<ExerciseSubmission>>>;
  parentConcept?: Maybe<Concept>;
  includedChecklist?: Maybe<Checklist>;
  includedConcepts?: Maybe<Array<Maybe<Concept>>>;
  conceptsCreated?: Maybe<Array<Maybe<Concept>>>;
  criteriaScores?: Maybe<Array<Maybe<CriteriaScore>>>;
  /** Generated field for querying the Neo4j [system id](https://neo4j.com/docs/cypher-manual/current/functions/scalar/#functions-id) of this node. */
  _id?: Maybe<Scalars['String']>;
};


export type ExerciseCriteriaArgs = {
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<Maybe<_CriteriaOrdering>>>;
  filter?: Maybe<_CriteriaFilter>;
};


export type ExerciseTypeArgs = {
  filter?: Maybe<_ExerciseTypeFilter>;
};


export type ExerciseFacilitatorsArgs = {
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<Maybe<_UserOrdering>>>;
  filter?: Maybe<_UserFilter>;
};


export type ExerciseInvitedTeamsArgs = {
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<Maybe<_TeamOrdering>>>;
  filter?: Maybe<_TeamFilter>;
};


export type ExerciseInvitedUsersArgs = {
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<Maybe<_UserOrdering>>>;
  filter?: Maybe<_UserFilter>;
};


export type ExerciseParticipantsArgs = {
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<Maybe<_UserOrdering>>>;
  filter?: Maybe<_UserFilter>;
};


export type ExerciseCreatedByArgs = {
  filter?: Maybe<_UserFilter>;
};


export type ExerciseSubmissionsArgs = {
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<Maybe<_ExerciseSubmissionOrdering>>>;
  filter?: Maybe<_ExerciseSubmissionFilter>;
};


export type ExerciseParentConceptArgs = {
  filter?: Maybe<_ConceptFilter>;
};


export type ExerciseIncludedChecklistArgs = {
  filter?: Maybe<_ChecklistFilter>;
};


export type ExerciseIncludedConceptsArgs = {
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<Maybe<_ConceptOrdering>>>;
  filter?: Maybe<_ConceptFilter>;
};


export type ExerciseConceptsCreatedArgs = {
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<Maybe<_ConceptOrdering>>>;
  filter?: Maybe<_ConceptFilter>;
};


export type ExerciseCriteriaScoresArgs = {
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<Maybe<_CriteriaScoreOrdering>>>;
  filter?: Maybe<_CriteriaScoreFilter>;
};

export enum ExerciseCategory {
  RANKING = 'RANKING',
  VOTING = 'VOTING',
  SCORING = 'SCORING',
  ASSESSMENT = 'ASSESSMENT',
  GENERATING = 'GENERATING',
  ANSWERING = 'ANSWERING'
}

export type ExerciseScore = {
  __typename?: 'ExerciseScore';
  id?: Maybe<Scalars['ID']>;
  value?: Maybe<Scalars['Int']>;
  explanation?: Maybe<Scalars['String']>;
  submission?: Maybe<ExerciseSubmission>;
  user?: Maybe<User>;
  concept?: Maybe<Concept>;
  /** Generated field for querying the Neo4j [system id](https://neo4j.com/docs/cypher-manual/current/functions/scalar/#functions-id) of this node. */
  _id?: Maybe<Scalars['String']>;
};


export type ExerciseScoreSubmissionArgs = {
  filter?: Maybe<_ExerciseSubmissionFilter>;
};


export type ExerciseScoreConceptArgs = {
  filter?: Maybe<_ConceptFilter>;
};

export enum ExerciseStatus {
  NOT_STARTED = 'NOT_STARTED',
  DRAFT = 'DRAFT',
  IN_PROGRESS = 'IN_PROGRESS',
  COMPLETE = 'COMPLETE'
}

export type ExerciseSubmission = {
  __typename?: 'ExerciseSubmission';
  id?: Maybe<Scalars['ID']>;
  notes?: Maybe<Scalars['String']>;
  conceptIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  imageUrl?: Maybe<Scalars['String']>;
  status?: Maybe<ExerciseStatus>;
  exercise?: Maybe<Exercise>;
  user?: Maybe<User>;
  scores?: Maybe<Array<Maybe<ExerciseScore>>>;
  conceptCreated?: Maybe<Array<Maybe<Concept>>>;
  criteriaScores?: Maybe<Array<Maybe<CriteriaScore>>>;
  /** Generated field for querying the Neo4j [system id](https://neo4j.com/docs/cypher-manual/current/functions/scalar/#functions-id) of this node. */
  _id?: Maybe<Scalars['String']>;
};


export type ExerciseSubmissionExerciseArgs = {
  filter?: Maybe<_ExerciseFilter>;
};


export type ExerciseSubmissionUserArgs = {
  filter?: Maybe<_UserFilter>;
};


export type ExerciseSubmissionScoresArgs = {
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<Maybe<_ExerciseScoreOrdering>>>;
  filter?: Maybe<_ExerciseScoreFilter>;
};


export type ExerciseSubmissionConceptCreatedArgs = {
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<Maybe<_ConceptOrdering>>>;
  filter?: Maybe<_ConceptFilter>;
};


export type ExerciseSubmissionCriteriaScoresArgs = {
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<Maybe<_CriteriaScoreOrdering>>>;
  filter?: Maybe<_CriteriaScoreFilter>;
};

export type ExerciseType = {
  __typename?: 'ExerciseType';
  id?: Maybe<Scalars['ID']>;
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  instructions?: Maybe<Scalars['String']>;
  timerType?: Maybe<TimerType>;
  imageUrl?: Maybe<Scalars['String']>;
  category?: Maybe<ExerciseCategory>;
  isConceptFeedback?: Maybe<Scalars['Boolean']>;
  exerciseTypeConfig?: Maybe<Scalars['String']>;
  criteriaIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  criteria?: Maybe<Array<Maybe<Criteria>>>;
  createdBy?: Maybe<User>;
  exercises?: Maybe<Array<Maybe<Exercise>>>;
  /** Generated field for querying the Neo4j [system id](https://neo4j.com/docs/cypher-manual/current/functions/scalar/#functions-id) of this node. */
  _id?: Maybe<Scalars['String']>;
};


export type ExerciseTypeCriteriaArgs = {
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<Maybe<_CriteriaOrdering>>>;
  filter?: Maybe<_CriteriaFilter>;
};


export type ExerciseTypeCreatedByArgs = {
  filter?: Maybe<_UserFilter>;
};


export type ExerciseTypeExercisesArgs = {
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<Maybe<_ExerciseOrdering>>>;
  filter?: Maybe<_ExerciseFilter>;
};

export type File = {
  __typename?: 'File';
  filename?: Maybe<Scalars['String']>;
  mimetype?: Maybe<Scalars['String']>;
  encoding?: Maybe<Scalars['String']>;
  fileUrl?: Maybe<Scalars['String']>;
  /** Generated field for querying the Neo4j [system id](https://neo4j.com/docs/cypher-manual/current/functions/scalar/#functions-id) of this node. */
  _id?: Maybe<Scalars['String']>;
};

export type FileInput = {
  filename: Scalars['String'];
  mimetype: Scalars['String'];
  encoding: Scalars['String'];
};

export type Industry = {
  __typename?: 'Industry';
  industryId?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  marketSize?: Maybe<Scalars['Float']>;
  companies?: Maybe<Array<Maybe<Company>>>;
  concepts?: Maybe<Array<Maybe<Concept>>>;
  /** Generated field for querying the Neo4j [system id](https://neo4j.com/docs/cypher-manual/current/functions/scalar/#functions-id) of this node. */
  _id?: Maybe<Scalars['String']>;
};


export type IndustryCompaniesArgs = {
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<Maybe<_CompanyOrdering>>>;
  filter?: Maybe<_CompanyFilter>;
};


export type IndustryConceptsArgs = {
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<Maybe<_ConceptOrdering>>>;
  filter?: Maybe<_ConceptFilter>;
};

export type Initiative = {
  __typename?: 'Initiative';
  id?: Maybe<Scalars['ID']>;
  title?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  summary?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  imageUrl?: Maybe<Scalars['String']>;
  createdAt?: Maybe<_Neo4jDateTime>;
  startDate?: Maybe<_Neo4jDateTime>;
  endDate?: Maybe<_Neo4jDateTime>;
  duration?: Maybe<_Neo4jDateTime>;
  likedBy?: Maybe<Array<Maybe<User>>>;
  team?: Maybe<Team>;
  user?: Maybe<User>;
  connections?: Maybe<Array<Maybe<Concept>>>;
  tags?: Maybe<Array<Maybe<Tag>>>;
  comments?: Maybe<Array<Maybe<Comment>>>;
  commentCount?: Maybe<Scalars['Int']>;
  /** Generated field for querying the Neo4j [system id](https://neo4j.com/docs/cypher-manual/current/functions/scalar/#functions-id) of this node. */
  _id?: Maybe<Scalars['String']>;
};


export type InitiativeLikedByArgs = {
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<Maybe<_UserOrdering>>>;
  filter?: Maybe<_UserFilter>;
};


export type InitiativeTeamArgs = {
  filter?: Maybe<_TeamFilter>;
};


export type InitiativeUserArgs = {
  filter?: Maybe<_UserFilter>;
};


export type InitiativeConnectionsArgs = {
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<Maybe<_ConceptOrdering>>>;
  filter?: Maybe<_ConceptFilter>;
};


export type InitiativeTagsArgs = {
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<Maybe<_TagOrdering>>>;
  filter?: Maybe<_TagFilter>;
};


export type InitiativeCommentsArgs = {
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<Maybe<_CommentOrdering>>>;
  filter?: Maybe<_CommentFilter>;
};

export type Input = {
  __typename?: 'Input';
  id?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<_Neo4jDateTime>;
  inputConfig?: Maybe<Scalars['String']>;
  criteria?: Maybe<Criteria>;
  parentConcept?: Maybe<Concept>;
  includedConcepts?: Maybe<Array<Maybe<Concept>>>;
  conceptsCreated?: Maybe<Array<Maybe<Concept>>>;
  criteriaScores?: Maybe<Array<Maybe<CriteriaScore>>>;
  /** Generated field for querying the Neo4j [system id](https://neo4j.com/docs/cypher-manual/current/functions/scalar/#functions-id) of this node. */
  _id?: Maybe<Scalars['String']>;
};


export type InputCriteriaArgs = {
  filter?: Maybe<_CriteriaFilter>;
};


export type InputParentConceptArgs = {
  filter?: Maybe<_ConceptFilter>;
};


export type InputIncludedConceptsArgs = {
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<Maybe<_ConceptOrdering>>>;
  filter?: Maybe<_ConceptFilter>;
};


export type InputConceptsCreatedArgs = {
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<Maybe<_ConceptOrdering>>>;
  filter?: Maybe<_ConceptFilter>;
};


export type InputCriteriaScoresArgs = {
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<Maybe<_CriteriaScoreOrdering>>>;
  filter?: Maybe<_CriteriaScoreFilter>;
};

export enum ItemDisplayState {
  COLLAPSED = 'COLLAPSED',
  HIDDEN = 'HIDDEN',
  IMPORTANT = 'IMPORTANT'
}

export enum ItemPrefixType {
  NONE = 'NONE',
  CHECKBOX = 'CHECKBOX',
  BULLET = 'BULLET',
  NUMBER = 'NUMBER'
}

export enum LanguageString {
  EN = 'en',
  DE = 'de',
  ES = 'es',
  PT = 'pt'
}

export type Link = {
  __typename?: 'Link';
  id?: Maybe<Scalars['ID']>;
  url?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  icon?: Maybe<Scalars['String']>;
  keywords?: Maybe<Scalars['String']>;
  language?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  provider?: Maybe<Scalars['String']>;
  addedAt?: Maybe<_Neo4jDateTime>;
  updatedAt?: Maybe<_Neo4jDateTime>;
  /** Generated field for querying the Neo4j [system id](https://neo4j.com/docs/cypher-manual/current/functions/scalar/#functions-id) of this node. */
  _id?: Maybe<Scalars['String']>;
};

export enum MoscowRank {
  MUST = 'MUST',
  COULD = 'COULD',
  SHOULD = 'SHOULD',
  WOULD = 'WOULD'
}

export type Mutation = {
  __typename?: 'Mutation';
  CreateConcept?: Maybe<Concept>;
  UpdateConcept?: Maybe<Scalars['String']>;
  GrantUserAccess?: Maybe<Concept>;
  RemoveUserAccess?: Maybe<Concept>;
  GrantTeamAccess?: Maybe<Concept>;
  RemoveTeamAccess?: Maybe<Concept>;
  CreateTeamWithUsers?: Maybe<Team>;
  CreateComment?: Maybe<Comment>;
  CreateCommentReply?: Maybe<Comment>;
  CreatePersonalBoard?: Maybe<ConceptBoard>;
  CreateUserWorkspace?: Maybe<ConceptList>;
  CreateTeamWorkspace?: Maybe<ConceptList>;
  CreateChecklist?: Maybe<Checklist>;
  UpdateChecklist?: Maybe<Checklist>;
  CreateChecklistItem?: Maybe<ChecklistItem>;
  UpdateChecklistItem?: Maybe<ChecklistItem>;
  login: User;
  signup: User;
  uploadProfileImage: File;
  uploadConceptImage: File;
  addLink?: Maybe<Link>;
  AddUserLiked?: Maybe<User>;
  sendMail?: Maybe<Scalars['Boolean']>;
  generateAI?: Maybe<Scalars['String']>;
  generateChatAI?: Maybe<Scalars['String']>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##add--remove-relationship) for [creating](https://neo4j.com/docs/cypher-manual/4.1/clauses/create/#create-relationships) the UPDATED relationship. */
  AddUserRecentUpdates?: Maybe<_AddUserRecentUpdatesPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##add--remove-relationship) for [deleting](https://neo4j.com/docs/cypher-manual/4.1/clauses/delete/#delete-delete-relationships-only) the UPDATED relationship. */
  RemoveUserRecentUpdates?: Maybe<_RemoveUserRecentUpdatesPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##update-relationship) for [updating](https://neo4j.com/docs/cypher-manual/4.1/clauses/set/#set-update-a-property) the UPDATED relationship. */
  UpdateUserRecentUpdates?: Maybe<_UpdateUserRecentUpdatesPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##merge-relationship) for [merging](https://neo4j.com/docs/cypher-manual/4.1/clauses/merge/#query-merge-relationships) the UPDATED relationship. */
  MergeUserRecentUpdates?: Maybe<_MergeUserRecentUpdatesPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##add--remove-relationship) for [deleting](https://neo4j.com/docs/cypher-manual/4.1/clauses/delete/#delete-delete-relationships-only) the LIKED relationship. */
  RemoveUserLiked?: Maybe<_RemoveUserLikedPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##merge-relationship) for [merging](https://neo4j.com/docs/cypher-manual/4.1/clauses/merge/#query-merge-relationships) the LIKED relationship. */
  MergeUserLiked?: Maybe<_MergeUserLikedPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##add--remove-relationship) for [creating](https://neo4j.com/docs/cypher-manual/4.1/clauses/create/#create-relationships) the HAS_ACCESS relationship. */
  AddUserAddedToConcepts?: Maybe<_AddUserAddedToConceptsPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##add--remove-relationship) for [deleting](https://neo4j.com/docs/cypher-manual/4.1/clauses/delete/#delete-delete-relationships-only) the HAS_ACCESS relationship. */
  RemoveUserAddedToConcepts?: Maybe<_RemoveUserAddedToConceptsPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##update-relationship) for [updating](https://neo4j.com/docs/cypher-manual/4.1/clauses/set/#set-update-a-property) the HAS_ACCESS relationship. */
  UpdateUserAddedToConcepts?: Maybe<_UpdateUserAddedToConceptsPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##merge-relationship) for [merging](https://neo4j.com/docs/cypher-manual/4.1/clauses/merge/#query-merge-relationships) the HAS_ACCESS relationship. */
  MergeUserAddedToConcepts?: Maybe<_MergeUserAddedToConceptsPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##add--remove-relationship) for [creating](https://neo4j.com/docs/cypher-manual/4.1/clauses/create/#create-relationships) the USER_HOME_CONCEPT relationship. */
  AddUserHomeConcept?: Maybe<_AddUserHomeConceptPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##add--remove-relationship) for [deleting](https://neo4j.com/docs/cypher-manual/4.1/clauses/delete/#delete-delete-relationships-only) the USER_HOME_CONCEPT relationship. */
  RemoveUserHomeConcept?: Maybe<_RemoveUserHomeConceptPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##merge-relationship) for [merging](https://neo4j.com/docs/cypher-manual/4.1/clauses/merge/#query-merge-relationships) the USER_HOME_CONCEPT relationship. */
  MergeUserHomeConcept?: Maybe<_MergeUserHomeConceptPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##add--remove-relationship) for [creating](https://neo4j.com/docs/cypher-manual/4.1/clauses/create/#create-relationships) the CREATED relationship. */
  AddUserConceptsCreated?: Maybe<_AddUserConceptsCreatedPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##add--remove-relationship) for [deleting](https://neo4j.com/docs/cypher-manual/4.1/clauses/delete/#delete-delete-relationships-only) the CREATED relationship. */
  RemoveUserConceptsCreated?: Maybe<_RemoveUserConceptsCreatedPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##merge-relationship) for [merging](https://neo4j.com/docs/cypher-manual/4.1/clauses/merge/#query-merge-relationships) the CREATED relationship. */
  MergeUserConceptsCreated?: Maybe<_MergeUserConceptsCreatedPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##add--remove-relationship) for [creating](https://neo4j.com/docs/cypher-manual/4.1/clauses/create/#create-relationships) the ACTIVE_LIGHT_THEME relationship. */
  AddUserActiveLightTheme?: Maybe<_AddUserActiveLightThemePayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##add--remove-relationship) for [deleting](https://neo4j.com/docs/cypher-manual/4.1/clauses/delete/#delete-delete-relationships-only) the ACTIVE_LIGHT_THEME relationship. */
  RemoveUserActiveLightTheme?: Maybe<_RemoveUserActiveLightThemePayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##merge-relationship) for [merging](https://neo4j.com/docs/cypher-manual/4.1/clauses/merge/#query-merge-relationships) the ACTIVE_LIGHT_THEME relationship. */
  MergeUserActiveLightTheme?: Maybe<_MergeUserActiveLightThemePayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##add--remove-relationship) for [creating](https://neo4j.com/docs/cypher-manual/4.1/clauses/create/#create-relationships) the ACTIVE_DARK_THEME relationship. */
  AddUserActiveDarkTheme?: Maybe<_AddUserActiveDarkThemePayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##add--remove-relationship) for [deleting](https://neo4j.com/docs/cypher-manual/4.1/clauses/delete/#delete-delete-relationships-only) the ACTIVE_DARK_THEME relationship. */
  RemoveUserActiveDarkTheme?: Maybe<_RemoveUserActiveDarkThemePayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##merge-relationship) for [merging](https://neo4j.com/docs/cypher-manual/4.1/clauses/merge/#query-merge-relationships) the ACTIVE_DARK_THEME relationship. */
  MergeUserActiveDarkTheme?: Maybe<_MergeUserActiveDarkThemePayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##add--remove-relationship) for [creating](https://neo4j.com/docs/cypher-manual/4.1/clauses/create/#create-relationships) the SAVED_THEME relationship. */
  AddUserSavedThemes?: Maybe<_AddUserSavedThemesPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##add--remove-relationship) for [deleting](https://neo4j.com/docs/cypher-manual/4.1/clauses/delete/#delete-delete-relationships-only) the SAVED_THEME relationship. */
  RemoveUserSavedThemes?: Maybe<_RemoveUserSavedThemesPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##merge-relationship) for [merging](https://neo4j.com/docs/cypher-manual/4.1/clauses/merge/#query-merge-relationships) the SAVED_THEME relationship. */
  MergeUserSavedThemes?: Maybe<_MergeUserSavedThemesPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##add--remove-relationship) for [creating](https://neo4j.com/docs/cypher-manual/4.1/clauses/create/#create-relationships) the CREATED_THEME relationship. */
  AddUserCreatedThemes?: Maybe<_AddUserCreatedThemesPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##add--remove-relationship) for [deleting](https://neo4j.com/docs/cypher-manual/4.1/clauses/delete/#delete-delete-relationships-only) the CREATED_THEME relationship. */
  RemoveUserCreatedThemes?: Maybe<_RemoveUserCreatedThemesPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##merge-relationship) for [merging](https://neo4j.com/docs/cypher-manual/4.1/clauses/merge/#query-merge-relationships) the CREATED_THEME relationship. */
  MergeUserCreatedThemes?: Maybe<_MergeUserCreatedThemesPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##add--remove-relationship) for [creating](https://neo4j.com/docs/cypher-manual/4.1/clauses/create/#create-relationships) the SAVED_DASHBOARD relationship. */
  AddUserSavedDashboards?: Maybe<_AddUserSavedDashboardsPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##add--remove-relationship) for [deleting](https://neo4j.com/docs/cypher-manual/4.1/clauses/delete/#delete-delete-relationships-only) the SAVED_DASHBOARD relationship. */
  RemoveUserSavedDashboards?: Maybe<_RemoveUserSavedDashboardsPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##merge-relationship) for [merging](https://neo4j.com/docs/cypher-manual/4.1/clauses/merge/#query-merge-relationships) the SAVED_DASHBOARD relationship. */
  MergeUserSavedDashboards?: Maybe<_MergeUserSavedDashboardsPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##add--remove-relationship) for [creating](https://neo4j.com/docs/cypher-manual/4.1/clauses/create/#create-relationships) the CREATED_DASHBOARD relationship. */
  AddUserCreatedDashboard?: Maybe<_AddUserCreatedDashboardPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##add--remove-relationship) for [deleting](https://neo4j.com/docs/cypher-manual/4.1/clauses/delete/#delete-delete-relationships-only) the CREATED_DASHBOARD relationship. */
  RemoveUserCreatedDashboard?: Maybe<_RemoveUserCreatedDashboardPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##merge-relationship) for [merging](https://neo4j.com/docs/cypher-manual/4.1/clauses/merge/#query-merge-relationships) the CREATED_DASHBOARD relationship. */
  MergeUserCreatedDashboard?: Maybe<_MergeUserCreatedDashboardPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##add--remove-relationship) for [creating](https://neo4j.com/docs/cypher-manual/4.1/clauses/create/#create-relationships) the TEAM_MEMBER relationship. */
  AddUserTeams?: Maybe<_AddUserTeamsPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##add--remove-relationship) for [deleting](https://neo4j.com/docs/cypher-manual/4.1/clauses/delete/#delete-delete-relationships-only) the TEAM_MEMBER relationship. */
  RemoveUserTeams?: Maybe<_RemoveUserTeamsPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##merge-relationship) for [merging](https://neo4j.com/docs/cypher-manual/4.1/clauses/merge/#query-merge-relationships) the TEAM_MEMBER relationship. */
  MergeUserTeams?: Maybe<_MergeUserTeamsPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##add--remove-relationship) for [creating](https://neo4j.com/docs/cypher-manual/4.1/clauses/create/#create-relationships) the TEAM_ADMIN relationship. */
  AddUserAdmin?: Maybe<_AddUserAdminPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##add--remove-relationship) for [deleting](https://neo4j.com/docs/cypher-manual/4.1/clauses/delete/#delete-delete-relationships-only) the TEAM_ADMIN relationship. */
  RemoveUserAdmin?: Maybe<_RemoveUserAdminPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##merge-relationship) for [merging](https://neo4j.com/docs/cypher-manual/4.1/clauses/merge/#query-merge-relationships) the TEAM_ADMIN relationship. */
  MergeUserAdmin?: Maybe<_MergeUserAdminPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##add--remove-relationship) for [creating](https://neo4j.com/docs/cypher-manual/4.1/clauses/create/#create-relationships) the LEADS_TEAM relationship. */
  AddUserLeads?: Maybe<_AddUserLeadsPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##add--remove-relationship) for [deleting](https://neo4j.com/docs/cypher-manual/4.1/clauses/delete/#delete-delete-relationships-only) the LEADS_TEAM relationship. */
  RemoveUserLeads?: Maybe<_RemoveUserLeadsPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##merge-relationship) for [merging](https://neo4j.com/docs/cypher-manual/4.1/clauses/merge/#query-merge-relationships) the LEADS_TEAM relationship. */
  MergeUserLeads?: Maybe<_MergeUserLeadsPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##add--remove-relationship) for [creating](https://neo4j.com/docs/cypher-manual/4.1/clauses/create/#create-relationships) the COMMENTED relationship. */
  AddUserComments?: Maybe<_AddUserCommentsPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##add--remove-relationship) for [deleting](https://neo4j.com/docs/cypher-manual/4.1/clauses/delete/#delete-delete-relationships-only) the COMMENTED relationship. */
  RemoveUserComments?: Maybe<_RemoveUserCommentsPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##merge-relationship) for [merging](https://neo4j.com/docs/cypher-manual/4.1/clauses/merge/#query-merge-relationships) the COMMENTED relationship. */
  MergeUserComments?: Maybe<_MergeUserCommentsPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##add--remove-relationship) for [creating](https://neo4j.com/docs/cypher-manual/4.1/clauses/create/#create-relationships) the WATCHING relationship. */
  AddUserWatchedConcepts?: Maybe<_AddUserWatchedConceptsPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##add--remove-relationship) for [deleting](https://neo4j.com/docs/cypher-manual/4.1/clauses/delete/#delete-delete-relationships-only) the WATCHING relationship. */
  RemoveUserWatchedConcepts?: Maybe<_RemoveUserWatchedConceptsPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##merge-relationship) for [merging](https://neo4j.com/docs/cypher-manual/4.1/clauses/merge/#query-merge-relationships) the WATCHING relationship. */
  MergeUserWatchedConcepts?: Maybe<_MergeUserWatchedConceptsPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##add--remove-relationship) for [creating](https://neo4j.com/docs/cypher-manual/4.1/clauses/create/#create-relationships) the USER_TRIGGERED_NOTIFICATION relationship. */
  AddUserNotificationsTriggered?: Maybe<_AddUserNotificationsTriggeredPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##add--remove-relationship) for [deleting](https://neo4j.com/docs/cypher-manual/4.1/clauses/delete/#delete-delete-relationships-only) the USER_TRIGGERED_NOTIFICATION relationship. */
  RemoveUserNotificationsTriggered?: Maybe<_RemoveUserNotificationsTriggeredPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##merge-relationship) for [merging](https://neo4j.com/docs/cypher-manual/4.1/clauses/merge/#query-merge-relationships) the USER_TRIGGERED_NOTIFICATION relationship. */
  MergeUserNotificationsTriggered?: Maybe<_MergeUserNotificationsTriggeredPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##add--remove-relationship) for [creating](https://neo4j.com/docs/cypher-manual/4.1/clauses/create/#create-relationships) the NOTIFIED relationship. */
  AddUserNotifications?: Maybe<_AddUserNotificationsPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##add--remove-relationship) for [deleting](https://neo4j.com/docs/cypher-manual/4.1/clauses/delete/#delete-delete-relationships-only) the NOTIFIED relationship. */
  RemoveUserNotifications?: Maybe<_RemoveUserNotificationsPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##merge-relationship) for [merging](https://neo4j.com/docs/cypher-manual/4.1/clauses/merge/#query-merge-relationships) the NOTIFIED relationship. */
  MergeUserNotifications?: Maybe<_MergeUserNotificationsPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##add--remove-relationship) for [creating](https://neo4j.com/docs/cypher-manual/4.1/clauses/create/#create-relationships) the HAS_WORKSPACE_INBOX relationship. */
  AddUserWorkspaceInbox?: Maybe<_AddUserWorkspaceInboxPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##add--remove-relationship) for [deleting](https://neo4j.com/docs/cypher-manual/4.1/clauses/delete/#delete-delete-relationships-only) the HAS_WORKSPACE_INBOX relationship. */
  RemoveUserWorkspaceInbox?: Maybe<_RemoveUserWorkspaceInboxPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##merge-relationship) for [merging](https://neo4j.com/docs/cypher-manual/4.1/clauses/merge/#query-merge-relationships) the HAS_WORKSPACE_INBOX relationship. */
  MergeUserWorkspaceInbox?: Maybe<_MergeUserWorkspaceInboxPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##add--remove-relationship) for [creating](https://neo4j.com/docs/cypher-manual/4.1/clauses/create/#create-relationships) the HAS_CONCEPT_LIST relationship. */
  AddUserConceptLists?: Maybe<_AddUserConceptListsPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##add--remove-relationship) for [deleting](https://neo4j.com/docs/cypher-manual/4.1/clauses/delete/#delete-delete-relationships-only) the HAS_CONCEPT_LIST relationship. */
  RemoveUserConceptLists?: Maybe<_RemoveUserConceptListsPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##merge-relationship) for [merging](https://neo4j.com/docs/cypher-manual/4.1/clauses/merge/#query-merge-relationships) the HAS_CONCEPT_LIST relationship. */
  MergeUserConceptLists?: Maybe<_MergeUserConceptListsPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##add--remove-relationship) for [creating](https://neo4j.com/docs/cypher-manual/4.1/clauses/create/#create-relationships) the HAS_CONCEPT_BOARD relationship. */
  AddUserConceptBoard?: Maybe<_AddUserConceptBoardPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##add--remove-relationship) for [deleting](https://neo4j.com/docs/cypher-manual/4.1/clauses/delete/#delete-delete-relationships-only) the HAS_CONCEPT_BOARD relationship. */
  RemoveUserConceptBoard?: Maybe<_RemoveUserConceptBoardPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##merge-relationship) for [merging](https://neo4j.com/docs/cypher-manual/4.1/clauses/merge/#query-merge-relationships) the HAS_CONCEPT_BOARD relationship. */
  MergeUserConceptBoard?: Maybe<_MergeUserConceptBoardPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##add--remove-relationship) for [creating](https://neo4j.com/docs/cypher-manual/4.1/clauses/create/#create-relationships) the EXERCISE_FACILITATED_BY relationship. */
  AddUserExercisesFacilitated?: Maybe<_AddUserExercisesFacilitatedPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##add--remove-relationship) for [deleting](https://neo4j.com/docs/cypher-manual/4.1/clauses/delete/#delete-delete-relationships-only) the EXERCISE_FACILITATED_BY relationship. */
  RemoveUserExercisesFacilitated?: Maybe<_RemoveUserExercisesFacilitatedPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##merge-relationship) for [merging](https://neo4j.com/docs/cypher-manual/4.1/clauses/merge/#query-merge-relationships) the EXERCISE_FACILITATED_BY relationship. */
  MergeUserExercisesFacilitated?: Maybe<_MergeUserExercisesFacilitatedPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##add--remove-relationship) for [creating](https://neo4j.com/docs/cypher-manual/4.1/clauses/create/#create-relationships) the EXERCISE_INVITE_SENT_TO_USER relationship. */
  AddUserExercisesInvitedTo?: Maybe<_AddUserExercisesInvitedToPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##add--remove-relationship) for [deleting](https://neo4j.com/docs/cypher-manual/4.1/clauses/delete/#delete-delete-relationships-only) the EXERCISE_INVITE_SENT_TO_USER relationship. */
  RemoveUserExercisesInvitedTo?: Maybe<_RemoveUserExercisesInvitedToPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##merge-relationship) for [merging](https://neo4j.com/docs/cypher-manual/4.1/clauses/merge/#query-merge-relationships) the EXERCISE_INVITE_SENT_TO_USER relationship. */
  MergeUserExercisesInvitedTo?: Maybe<_MergeUserExercisesInvitedToPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##add--remove-relationship) for [creating](https://neo4j.com/docs/cypher-manual/4.1/clauses/create/#create-relationships) the EXERCISE_COMPLETED_BY relationship. */
  AddUserExercisesParticipated?: Maybe<_AddUserExercisesParticipatedPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##add--remove-relationship) for [deleting](https://neo4j.com/docs/cypher-manual/4.1/clauses/delete/#delete-delete-relationships-only) the EXERCISE_COMPLETED_BY relationship. */
  RemoveUserExercisesParticipated?: Maybe<_RemoveUserExercisesParticipatedPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##merge-relationship) for [merging](https://neo4j.com/docs/cypher-manual/4.1/clauses/merge/#query-merge-relationships) the EXERCISE_COMPLETED_BY relationship. */
  MergeUserExercisesParticipated?: Maybe<_MergeUserExercisesParticipatedPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##add--remove-relationship) for [creating](https://neo4j.com/docs/cypher-manual/4.1/clauses/create/#create-relationships) the EXERCISE_CREATED_BY relationship. */
  AddUserExercisesCreated?: Maybe<_AddUserExercisesCreatedPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##add--remove-relationship) for [deleting](https://neo4j.com/docs/cypher-manual/4.1/clauses/delete/#delete-delete-relationships-only) the EXERCISE_CREATED_BY relationship. */
  RemoveUserExercisesCreated?: Maybe<_RemoveUserExercisesCreatedPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##merge-relationship) for [merging](https://neo4j.com/docs/cypher-manual/4.1/clauses/merge/#query-merge-relationships) the EXERCISE_CREATED_BY relationship. */
  MergeUserExercisesCreated?: Maybe<_MergeUserExercisesCreatedPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##add--remove-relationship) for [creating](https://neo4j.com/docs/cypher-manual/4.1/clauses/create/#create-relationships) the EXERCISE_TYPE_CREATED_BY relationship. */
  AddUserExerciseTypesCreated?: Maybe<_AddUserExerciseTypesCreatedPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##add--remove-relationship) for [deleting](https://neo4j.com/docs/cypher-manual/4.1/clauses/delete/#delete-delete-relationships-only) the EXERCISE_TYPE_CREATED_BY relationship. */
  RemoveUserExerciseTypesCreated?: Maybe<_RemoveUserExerciseTypesCreatedPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##merge-relationship) for [merging](https://neo4j.com/docs/cypher-manual/4.1/clauses/merge/#query-merge-relationships) the EXERCISE_TYPE_CREATED_BY relationship. */
  MergeUserExerciseTypesCreated?: Maybe<_MergeUserExerciseTypesCreatedPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##add--remove-relationship) for [creating](https://neo4j.com/docs/cypher-manual/4.1/clauses/create/#create-relationships) the EXERCISE_RESULT_SUBMITTED_BY relationship. */
  AddUserExerciseSubmissions?: Maybe<_AddUserExerciseSubmissionsPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##add--remove-relationship) for [deleting](https://neo4j.com/docs/cypher-manual/4.1/clauses/delete/#delete-delete-relationships-only) the EXERCISE_RESULT_SUBMITTED_BY relationship. */
  RemoveUserExerciseSubmissions?: Maybe<_RemoveUserExerciseSubmissionsPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##merge-relationship) for [merging](https://neo4j.com/docs/cypher-manual/4.1/clauses/merge/#query-merge-relationships) the EXERCISE_RESULT_SUBMITTED_BY relationship. */
  MergeUserExerciseSubmissions?: Maybe<_MergeUserExerciseSubmissionsPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##add--remove-relationship) for [creating](https://neo4j.com/docs/cypher-manual/4.1/clauses/create/#create-relationships) the SCORED_BY relationship. */
  AddUserCriteriaScores?: Maybe<_AddUserCriteriaScoresPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##add--remove-relationship) for [deleting](https://neo4j.com/docs/cypher-manual/4.1/clauses/delete/#delete-delete-relationships-only) the SCORED_BY relationship. */
  RemoveUserCriteriaScores?: Maybe<_RemoveUserCriteriaScoresPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##merge-relationship) for [merging](https://neo4j.com/docs/cypher-manual/4.1/clauses/merge/#query-merge-relationships) the SCORED_BY relationship. */
  MergeUserCriteriaScores?: Maybe<_MergeUserCriteriaScoresPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/#create) for [creating](https://neo4j.com/docs/cypher-manual/4.1/clauses/create/#create-nodes) a User node. */
  CreateUser?: Maybe<User>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/#update) for [updating](https://neo4j.com/docs/cypher-manual/4.1/clauses/set/#set-update-a-property) a User node. */
  UpdateUser?: Maybe<User>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/#delete) for [deleting](https://neo4j.com/docs/cypher-manual/4.1/clauses/delete/#delete-delete-single-node) a User node. */
  DeleteUser?: Maybe<User>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/#merge) for [merging](https://neo4j.com/docs/cypher-manual/4.1/clauses/merge/#query-merge-node-derived) a User node. */
  MergeUser?: Maybe<User>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/#create) for [creating](https://neo4j.com/docs/cypher-manual/4.1/clauses/create/#create-nodes) a EnvironmentSetting node. */
  CreateEnvironmentSetting?: Maybe<EnvironmentSetting>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/#update) for [updating](https://neo4j.com/docs/cypher-manual/4.1/clauses/set/#set-update-a-property) a EnvironmentSetting node. */
  UpdateEnvironmentSetting?: Maybe<EnvironmentSetting>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/#delete) for [deleting](https://neo4j.com/docs/cypher-manual/4.1/clauses/delete/#delete-delete-single-node) a EnvironmentSetting node. */
  DeleteEnvironmentSetting?: Maybe<EnvironmentSetting>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/#merge) for [merging](https://neo4j.com/docs/cypher-manual/4.1/clauses/merge/#query-merge-node-derived) a EnvironmentSetting node. */
  MergeEnvironmentSetting?: Maybe<EnvironmentSetting>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##add--remove-relationship) for [creating](https://neo4j.com/docs/cypher-manual/4.1/clauses/create/#create-relationships) the USER_TRIGGERED_NOTIFICATION relationship. */
  AddNotificationActionBy?: Maybe<_AddNotificationActionByPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##add--remove-relationship) for [deleting](https://neo4j.com/docs/cypher-manual/4.1/clauses/delete/#delete-delete-relationships-only) the USER_TRIGGERED_NOTIFICATION relationship. */
  RemoveNotificationActionBy?: Maybe<_RemoveNotificationActionByPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##merge-relationship) for [merging](https://neo4j.com/docs/cypher-manual/4.1/clauses/merge/#query-merge-relationships) the USER_TRIGGERED_NOTIFICATION relationship. */
  MergeNotificationActionBy?: Maybe<_MergeNotificationActionByPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##add--remove-relationship) for [creating](https://neo4j.com/docs/cypher-manual/4.1/clauses/create/#create-relationships) the CONCEPT_TRIGGERED_NOTIFICATION relationship. */
  AddNotificationConcept?: Maybe<_AddNotificationConceptPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##add--remove-relationship) for [deleting](https://neo4j.com/docs/cypher-manual/4.1/clauses/delete/#delete-delete-relationships-only) the CONCEPT_TRIGGERED_NOTIFICATION relationship. */
  RemoveNotificationConcept?: Maybe<_RemoveNotificationConceptPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##merge-relationship) for [merging](https://neo4j.com/docs/cypher-manual/4.1/clauses/merge/#query-merge-relationships) the CONCEPT_TRIGGERED_NOTIFICATION relationship. */
  MergeNotificationConcept?: Maybe<_MergeNotificationConceptPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##add--remove-relationship) for [creating](https://neo4j.com/docs/cypher-manual/4.1/clauses/create/#create-relationships) the COMMENT_TRIGGERED_NOTIFICATION relationship. */
  AddNotificationComment?: Maybe<_AddNotificationCommentPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##add--remove-relationship) for [deleting](https://neo4j.com/docs/cypher-manual/4.1/clauses/delete/#delete-delete-relationships-only) the COMMENT_TRIGGERED_NOTIFICATION relationship. */
  RemoveNotificationComment?: Maybe<_RemoveNotificationCommentPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##merge-relationship) for [merging](https://neo4j.com/docs/cypher-manual/4.1/clauses/merge/#query-merge-relationships) the COMMENT_TRIGGERED_NOTIFICATION relationship. */
  MergeNotificationComment?: Maybe<_MergeNotificationCommentPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##add--remove-relationship) for [creating](https://neo4j.com/docs/cypher-manual/4.1/clauses/create/#create-relationships) the NOTIFIED relationship. */
  AddNotificationUsers?: Maybe<_AddNotificationUsersPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##add--remove-relationship) for [deleting](https://neo4j.com/docs/cypher-manual/4.1/clauses/delete/#delete-delete-relationships-only) the NOTIFIED relationship. */
  RemoveNotificationUsers?: Maybe<_RemoveNotificationUsersPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##merge-relationship) for [merging](https://neo4j.com/docs/cypher-manual/4.1/clauses/merge/#query-merge-relationships) the NOTIFIED relationship. */
  MergeNotificationUsers?: Maybe<_MergeNotificationUsersPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/#create) for [creating](https://neo4j.com/docs/cypher-manual/4.1/clauses/create/#create-nodes) a Notification node. */
  CreateNotification?: Maybe<Notification>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/#update) for [updating](https://neo4j.com/docs/cypher-manual/4.1/clauses/set/#set-update-a-property) a Notification node. */
  UpdateNotification?: Maybe<Notification>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/#delete) for [deleting](https://neo4j.com/docs/cypher-manual/4.1/clauses/delete/#delete-delete-single-node) a Notification node. */
  DeleteNotification?: Maybe<Notification>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/#merge) for [merging](https://neo4j.com/docs/cypher-manual/4.1/clauses/merge/#query-merge-node-derived) a Notification node. */
  MergeNotification?: Maybe<Notification>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##add--remove-relationship) for [creating](https://neo4j.com/docs/cypher-manual/4.1/clauses/create/#create-relationships) the ACTIVE_THEME relationship. */
  AddDashboardViewActiveTheme?: Maybe<_AddDashboardViewActiveThemePayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##add--remove-relationship) for [deleting](https://neo4j.com/docs/cypher-manual/4.1/clauses/delete/#delete-delete-relationships-only) the ACTIVE_THEME relationship. */
  RemoveDashboardViewActiveTheme?: Maybe<_RemoveDashboardViewActiveThemePayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##merge-relationship) for [merging](https://neo4j.com/docs/cypher-manual/4.1/clauses/merge/#query-merge-relationships) the ACTIVE_THEME relationship. */
  MergeDashboardViewActiveTheme?: Maybe<_MergeDashboardViewActiveThemePayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##add--remove-relationship) for [creating](https://neo4j.com/docs/cypher-manual/4.1/clauses/create/#create-relationships) the CREATED_DASHBOARD relationship. */
  AddDashboardViewCreatedBy?: Maybe<_AddDashboardViewCreatedByPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##add--remove-relationship) for [deleting](https://neo4j.com/docs/cypher-manual/4.1/clauses/delete/#delete-delete-relationships-only) the CREATED_DASHBOARD relationship. */
  RemoveDashboardViewCreatedBy?: Maybe<_RemoveDashboardViewCreatedByPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##merge-relationship) for [merging](https://neo4j.com/docs/cypher-manual/4.1/clauses/merge/#query-merge-relationships) the CREATED_DASHBOARD relationship. */
  MergeDashboardViewCreatedBy?: Maybe<_MergeDashboardViewCreatedByPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##add--remove-relationship) for [creating](https://neo4j.com/docs/cypher-manual/4.1/clauses/create/#create-relationships) the SAVED_DASHBOARD relationship. */
  AddDashboardViewUsedBy?: Maybe<_AddDashboardViewUsedByPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##add--remove-relationship) for [deleting](https://neo4j.com/docs/cypher-manual/4.1/clauses/delete/#delete-delete-relationships-only) the SAVED_DASHBOARD relationship. */
  RemoveDashboardViewUsedBy?: Maybe<_RemoveDashboardViewUsedByPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##merge-relationship) for [merging](https://neo4j.com/docs/cypher-manual/4.1/clauses/merge/#query-merge-relationships) the SAVED_DASHBOARD relationship. */
  MergeDashboardViewUsedBy?: Maybe<_MergeDashboardViewUsedByPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##add--remove-relationship) for [creating](https://neo4j.com/docs/cypher-manual/4.1/clauses/create/#create-relationships) the SAVED_DASHBOARD relationship. */
  AddDashboardViewUsedByConcepts?: Maybe<_AddDashboardViewUsedByConceptsPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##add--remove-relationship) for [deleting](https://neo4j.com/docs/cypher-manual/4.1/clauses/delete/#delete-delete-relationships-only) the SAVED_DASHBOARD relationship. */
  RemoveDashboardViewUsedByConcepts?: Maybe<_RemoveDashboardViewUsedByConceptsPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##merge-relationship) for [merging](https://neo4j.com/docs/cypher-manual/4.1/clauses/merge/#query-merge-relationships) the SAVED_DASHBOARD relationship. */
  MergeDashboardViewUsedByConcepts?: Maybe<_MergeDashboardViewUsedByConceptsPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##add--remove-relationship) for [creating](https://neo4j.com/docs/cypher-manual/4.1/clauses/create/#create-relationships) the HAS_WIDGET relationship. */
  AddDashboardViewWidgets?: Maybe<_AddDashboardViewWidgetsPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##add--remove-relationship) for [deleting](https://neo4j.com/docs/cypher-manual/4.1/clauses/delete/#delete-delete-relationships-only) the HAS_WIDGET relationship. */
  RemoveDashboardViewWidgets?: Maybe<_RemoveDashboardViewWidgetsPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##merge-relationship) for [merging](https://neo4j.com/docs/cypher-manual/4.1/clauses/merge/#query-merge-relationships) the HAS_WIDGET relationship. */
  MergeDashboardViewWidgets?: Maybe<_MergeDashboardViewWidgetsPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##add--remove-relationship) for [creating](https://neo4j.com/docs/cypher-manual/4.1/clauses/create/#create-relationships) the HAS_HOME_DASHBOARD relationship. */
  AddDashboardViewConceptHome?: Maybe<_AddDashboardViewConceptHomePayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##add--remove-relationship) for [deleting](https://neo4j.com/docs/cypher-manual/4.1/clauses/delete/#delete-delete-relationships-only) the HAS_HOME_DASHBOARD relationship. */
  RemoveDashboardViewConceptHome?: Maybe<_RemoveDashboardViewConceptHomePayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##merge-relationship) for [merging](https://neo4j.com/docs/cypher-manual/4.1/clauses/merge/#query-merge-relationships) the HAS_HOME_DASHBOARD relationship. */
  MergeDashboardViewConceptHome?: Maybe<_MergeDashboardViewConceptHomePayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##add--remove-relationship) for [creating](https://neo4j.com/docs/cypher-manual/4.1/clauses/create/#create-relationships) the USES_DEFAULT_DASHBOARD relationship. */
  AddDashboardViewCategoryDefault?: Maybe<_AddDashboardViewCategoryDefaultPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##add--remove-relationship) for [deleting](https://neo4j.com/docs/cypher-manual/4.1/clauses/delete/#delete-delete-relationships-only) the USES_DEFAULT_DASHBOARD relationship. */
  RemoveDashboardViewCategoryDefault?: Maybe<_RemoveDashboardViewCategoryDefaultPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##merge-relationship) for [merging](https://neo4j.com/docs/cypher-manual/4.1/clauses/merge/#query-merge-relationships) the USES_DEFAULT_DASHBOARD relationship. */
  MergeDashboardViewCategoryDefault?: Maybe<_MergeDashboardViewCategoryDefaultPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/#create) for [creating](https://neo4j.com/docs/cypher-manual/4.1/clauses/create/#create-nodes) a DashboardView node. */
  CreateDashboardView?: Maybe<DashboardView>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/#update) for [updating](https://neo4j.com/docs/cypher-manual/4.1/clauses/set/#set-update-a-property) a DashboardView node. */
  UpdateDashboardView?: Maybe<DashboardView>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/#delete) for [deleting](https://neo4j.com/docs/cypher-manual/4.1/clauses/delete/#delete-delete-single-node) a DashboardView node. */
  DeleteDashboardView?: Maybe<DashboardView>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/#merge) for [merging](https://neo4j.com/docs/cypher-manual/4.1/clauses/merge/#query-merge-node-derived) a DashboardView node. */
  MergeDashboardView?: Maybe<DashboardView>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##add--remove-relationship) for [creating](https://neo4j.com/docs/cypher-manual/4.1/clauses/create/#create-relationships) the WIDGET_TYPE_OF relationship. */
  AddWidgetType?: Maybe<_AddWidgetTypePayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##add--remove-relationship) for [deleting](https://neo4j.com/docs/cypher-manual/4.1/clauses/delete/#delete-delete-relationships-only) the WIDGET_TYPE_OF relationship. */
  RemoveWidgetType?: Maybe<_RemoveWidgetTypePayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##merge-relationship) for [merging](https://neo4j.com/docs/cypher-manual/4.1/clauses/merge/#query-merge-relationships) the WIDGET_TYPE_OF relationship. */
  MergeWidgetType?: Maybe<_MergeWidgetTypePayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##add--remove-relationship) for [creating](https://neo4j.com/docs/cypher-manual/4.1/clauses/create/#create-relationships) the HAS_WIDGET relationship. */
  AddWidgetDashboards?: Maybe<_AddWidgetDashboardsPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##add--remove-relationship) for [deleting](https://neo4j.com/docs/cypher-manual/4.1/clauses/delete/#delete-delete-relationships-only) the HAS_WIDGET relationship. */
  RemoveWidgetDashboards?: Maybe<_RemoveWidgetDashboardsPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##merge-relationship) for [merging](https://neo4j.com/docs/cypher-manual/4.1/clauses/merge/#query-merge-relationships) the HAS_WIDGET relationship. */
  MergeWidgetDashboards?: Maybe<_MergeWidgetDashboardsPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/#create) for [creating](https://neo4j.com/docs/cypher-manual/4.1/clauses/create/#create-nodes) a Widget node. */
  CreateWidget?: Maybe<Widget>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/#update) for [updating](https://neo4j.com/docs/cypher-manual/4.1/clauses/set/#set-update-a-property) a Widget node. */
  UpdateWidget?: Maybe<Widget>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/#delete) for [deleting](https://neo4j.com/docs/cypher-manual/4.1/clauses/delete/#delete-delete-single-node) a Widget node. */
  DeleteWidget?: Maybe<Widget>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/#merge) for [merging](https://neo4j.com/docs/cypher-manual/4.1/clauses/merge/#query-merge-node-derived) a Widget node. */
  MergeWidget?: Maybe<Widget>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##add--remove-relationship) for [creating](https://neo4j.com/docs/cypher-manual/4.1/clauses/create/#create-relationships) the WIDGET_TYPE_OF relationship. */
  AddWidgetTypeWidgets?: Maybe<_AddWidgetTypeWidgetsPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##add--remove-relationship) for [deleting](https://neo4j.com/docs/cypher-manual/4.1/clauses/delete/#delete-delete-relationships-only) the WIDGET_TYPE_OF relationship. */
  RemoveWidgetTypeWidgets?: Maybe<_RemoveWidgetTypeWidgetsPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##merge-relationship) for [merging](https://neo4j.com/docs/cypher-manual/4.1/clauses/merge/#query-merge-relationships) the WIDGET_TYPE_OF relationship. */
  MergeWidgetTypeWidgets?: Maybe<_MergeWidgetTypeWidgetsPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/#create) for [creating](https://neo4j.com/docs/cypher-manual/4.1/clauses/create/#create-nodes) a WidgetType node. */
  CreateWidgetType?: Maybe<WidgetType>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/#update) for [updating](https://neo4j.com/docs/cypher-manual/4.1/clauses/set/#set-update-a-property) a WidgetType node. */
  UpdateWidgetType?: Maybe<WidgetType>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/#delete) for [deleting](https://neo4j.com/docs/cypher-manual/4.1/clauses/delete/#delete-delete-single-node) a WidgetType node. */
  DeleteWidgetType?: Maybe<WidgetType>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##add--remove-relationship) for [creating](https://neo4j.com/docs/cypher-manual/4.1/clauses/create/#create-relationships) the ACTIVE_THEME relationship. */
  AddDesignThemeUsedByDashboards?: Maybe<_AddDesignThemeUsedByDashboardsPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##add--remove-relationship) for [deleting](https://neo4j.com/docs/cypher-manual/4.1/clauses/delete/#delete-delete-relationships-only) the ACTIVE_THEME relationship. */
  RemoveDesignThemeUsedByDashboards?: Maybe<_RemoveDesignThemeUsedByDashboardsPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##merge-relationship) for [merging](https://neo4j.com/docs/cypher-manual/4.1/clauses/merge/#query-merge-relationships) the ACTIVE_THEME relationship. */
  MergeDesignThemeUsedByDashboards?: Maybe<_MergeDesignThemeUsedByDashboardsPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##add--remove-relationship) for [creating](https://neo4j.com/docs/cypher-manual/4.1/clauses/create/#create-relationships) the CREATED_THEME relationship. */
  AddDesignThemeCreatedBy?: Maybe<_AddDesignThemeCreatedByPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##add--remove-relationship) for [deleting](https://neo4j.com/docs/cypher-manual/4.1/clauses/delete/#delete-delete-relationships-only) the CREATED_THEME relationship. */
  RemoveDesignThemeCreatedBy?: Maybe<_RemoveDesignThemeCreatedByPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##merge-relationship) for [merging](https://neo4j.com/docs/cypher-manual/4.1/clauses/merge/#query-merge-relationships) the CREATED_THEME relationship. */
  MergeDesignThemeCreatedBy?: Maybe<_MergeDesignThemeCreatedByPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##add--remove-relationship) for [creating](https://neo4j.com/docs/cypher-manual/4.1/clauses/create/#create-relationships) the SAVED_THEME relationship. */
  AddDesignThemeSavedBy?: Maybe<_AddDesignThemeSavedByPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##add--remove-relationship) for [deleting](https://neo4j.com/docs/cypher-manual/4.1/clauses/delete/#delete-delete-relationships-only) the SAVED_THEME relationship. */
  RemoveDesignThemeSavedBy?: Maybe<_RemoveDesignThemeSavedByPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##merge-relationship) for [merging](https://neo4j.com/docs/cypher-manual/4.1/clauses/merge/#query-merge-relationships) the SAVED_THEME relationship. */
  MergeDesignThemeSavedBy?: Maybe<_MergeDesignThemeSavedByPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##add--remove-relationship) for [creating](https://neo4j.com/docs/cypher-manual/4.1/clauses/create/#create-relationships) the ACTIVE_LIGHT_THEME relationship. */
  AddDesignThemeUsedAsLightBy?: Maybe<_AddDesignThemeUsedAsLightByPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##add--remove-relationship) for [deleting](https://neo4j.com/docs/cypher-manual/4.1/clauses/delete/#delete-delete-relationships-only) the ACTIVE_LIGHT_THEME relationship. */
  RemoveDesignThemeUsedAsLightBy?: Maybe<_RemoveDesignThemeUsedAsLightByPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##merge-relationship) for [merging](https://neo4j.com/docs/cypher-manual/4.1/clauses/merge/#query-merge-relationships) the ACTIVE_LIGHT_THEME relationship. */
  MergeDesignThemeUsedAsLightBy?: Maybe<_MergeDesignThemeUsedAsLightByPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##add--remove-relationship) for [creating](https://neo4j.com/docs/cypher-manual/4.1/clauses/create/#create-relationships) the ACTIVE_DARK_THEME relationship. */
  AddDesignThemeUsedAsDarkBy?: Maybe<_AddDesignThemeUsedAsDarkByPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##add--remove-relationship) for [deleting](https://neo4j.com/docs/cypher-manual/4.1/clauses/delete/#delete-delete-relationships-only) the ACTIVE_DARK_THEME relationship. */
  RemoveDesignThemeUsedAsDarkBy?: Maybe<_RemoveDesignThemeUsedAsDarkByPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##merge-relationship) for [merging](https://neo4j.com/docs/cypher-manual/4.1/clauses/merge/#query-merge-relationships) the ACTIVE_DARK_THEME relationship. */
  MergeDesignThemeUsedAsDarkBy?: Maybe<_MergeDesignThemeUsedAsDarkByPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/#create) for [creating](https://neo4j.com/docs/cypher-manual/4.1/clauses/create/#create-nodes) a DesignTheme node. */
  CreateDesignTheme?: Maybe<DesignTheme>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/#update) for [updating](https://neo4j.com/docs/cypher-manual/4.1/clauses/set/#set-update-a-property) a DesignTheme node. */
  UpdateDesignTheme?: Maybe<DesignTheme>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/#delete) for [deleting](https://neo4j.com/docs/cypher-manual/4.1/clauses/delete/#delete-delete-single-node) a DesignTheme node. */
  DeleteDesignTheme?: Maybe<DesignTheme>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/#merge) for [merging](https://neo4j.com/docs/cypher-manual/4.1/clauses/merge/#query-merge-node-derived) a DesignTheme node. */
  MergeDesignTheme?: Maybe<DesignTheme>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##add--remove-relationship) for [creating](https://neo4j.com/docs/cypher-manual/4.1/clauses/create/#create-relationships) the WORKS_AT relationship. */
  AddCompanyEmployees?: Maybe<_AddCompanyEmployeesPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##add--remove-relationship) for [deleting](https://neo4j.com/docs/cypher-manual/4.1/clauses/delete/#delete-delete-relationships-only) the WORKS_AT relationship. */
  RemoveCompanyEmployees?: Maybe<_RemoveCompanyEmployeesPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##merge-relationship) for [merging](https://neo4j.com/docs/cypher-manual/4.1/clauses/merge/#query-merge-relationships) the WORKS_AT relationship. */
  MergeCompanyEmployees?: Maybe<_MergeCompanyEmployeesPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##add--remove-relationship) for [creating](https://neo4j.com/docs/cypher-manual/4.1/clauses/create/#create-relationships) the IN_INDUSTRY relationship. */
  AddCompanyIndustries?: Maybe<_AddCompanyIndustriesPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##add--remove-relationship) for [deleting](https://neo4j.com/docs/cypher-manual/4.1/clauses/delete/#delete-delete-relationships-only) the IN_INDUSTRY relationship. */
  RemoveCompanyIndustries?: Maybe<_RemoveCompanyIndustriesPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##merge-relationship) for [merging](https://neo4j.com/docs/cypher-manual/4.1/clauses/merge/#query-merge-relationships) the IN_INDUSTRY relationship. */
  MergeCompanyIndustries?: Maybe<_MergeCompanyIndustriesPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##add--remove-relationship) for [creating](https://neo4j.com/docs/cypher-manual/4.1/clauses/create/#create-relationships) the TEAM_AT relationship. */
  AddCompanyTeams?: Maybe<_AddCompanyTeamsPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##add--remove-relationship) for [deleting](https://neo4j.com/docs/cypher-manual/4.1/clauses/delete/#delete-delete-relationships-only) the TEAM_AT relationship. */
  RemoveCompanyTeams?: Maybe<_RemoveCompanyTeamsPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##merge-relationship) for [merging](https://neo4j.com/docs/cypher-manual/4.1/clauses/merge/#query-merge-relationships) the TEAM_AT relationship. */
  MergeCompanyTeams?: Maybe<_MergeCompanyTeamsPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/#create) for [creating](https://neo4j.com/docs/cypher-manual/4.1/clauses/create/#create-nodes) a Company node. */
  CreateCompany?: Maybe<Company>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/#update) for [updating](https://neo4j.com/docs/cypher-manual/4.1/clauses/set/#set-update-a-property) a Company node. */
  UpdateCompany?: Maybe<Company>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/#delete) for [deleting](https://neo4j.com/docs/cypher-manual/4.1/clauses/delete/#delete-delete-single-node) a Company node. */
  DeleteCompany?: Maybe<Company>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/#merge) for [merging](https://neo4j.com/docs/cypher-manual/4.1/clauses/merge/#query-merge-node-derived) a Company node. */
  MergeCompany?: Maybe<Company>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##add--remove-relationship) for [creating](https://neo4j.com/docs/cypher-manual/4.1/clauses/create/#create-relationships) the IN_INDUSTRY relationship. */
  AddIndustryCompanies?: Maybe<_AddIndustryCompaniesPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##add--remove-relationship) for [deleting](https://neo4j.com/docs/cypher-manual/4.1/clauses/delete/#delete-delete-relationships-only) the IN_INDUSTRY relationship. */
  RemoveIndustryCompanies?: Maybe<_RemoveIndustryCompaniesPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##merge-relationship) for [merging](https://neo4j.com/docs/cypher-manual/4.1/clauses/merge/#query-merge-relationships) the IN_INDUSTRY relationship. */
  MergeIndustryCompanies?: Maybe<_MergeIndustryCompaniesPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##add--remove-relationship) for [creating](https://neo4j.com/docs/cypher-manual/4.1/clauses/create/#create-relationships) the IMPACTS_INDUSTRY relationship. */
  AddIndustryConcepts?: Maybe<_AddIndustryConceptsPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##add--remove-relationship) for [deleting](https://neo4j.com/docs/cypher-manual/4.1/clauses/delete/#delete-delete-relationships-only) the IMPACTS_INDUSTRY relationship. */
  RemoveIndustryConcepts?: Maybe<_RemoveIndustryConceptsPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##merge-relationship) for [merging](https://neo4j.com/docs/cypher-manual/4.1/clauses/merge/#query-merge-relationships) the IMPACTS_INDUSTRY relationship. */
  MergeIndustryConcepts?: Maybe<_MergeIndustryConceptsPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/#create) for [creating](https://neo4j.com/docs/cypher-manual/4.1/clauses/create/#create-nodes) a Industry node. */
  CreateIndustry?: Maybe<Industry>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/#update) for [updating](https://neo4j.com/docs/cypher-manual/4.1/clauses/set/#set-update-a-property) a Industry node. */
  UpdateIndustry?: Maybe<Industry>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/#delete) for [deleting](https://neo4j.com/docs/cypher-manual/4.1/clauses/delete/#delete-delete-single-node) a Industry node. */
  DeleteIndustry?: Maybe<Industry>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/#merge) for [merging](https://neo4j.com/docs/cypher-manual/4.1/clauses/merge/#query-merge-node-derived) a Industry node. */
  MergeIndustry?: Maybe<Industry>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##add--remove-relationship) for [creating](https://neo4j.com/docs/cypher-manual/4.1/clauses/create/#create-relationships) the LEADS_TEAM relationship. */
  AddTeamLeader?: Maybe<_AddTeamLeaderPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##add--remove-relationship) for [deleting](https://neo4j.com/docs/cypher-manual/4.1/clauses/delete/#delete-delete-relationships-only) the LEADS_TEAM relationship. */
  RemoveTeamLeader?: Maybe<_RemoveTeamLeaderPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##merge-relationship) for [merging](https://neo4j.com/docs/cypher-manual/4.1/clauses/merge/#query-merge-relationships) the LEADS_TEAM relationship. */
  MergeTeamLeader?: Maybe<_MergeTeamLeaderPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##add--remove-relationship) for [creating](https://neo4j.com/docs/cypher-manual/4.1/clauses/create/#create-relationships) the TEAM_ADMIN relationship. */
  AddTeamAdmin?: Maybe<_AddTeamAdminPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##add--remove-relationship) for [deleting](https://neo4j.com/docs/cypher-manual/4.1/clauses/delete/#delete-delete-relationships-only) the TEAM_ADMIN relationship. */
  RemoveTeamAdmin?: Maybe<_RemoveTeamAdminPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##merge-relationship) for [merging](https://neo4j.com/docs/cypher-manual/4.1/clauses/merge/#query-merge-relationships) the TEAM_ADMIN relationship. */
  MergeTeamAdmin?: Maybe<_MergeTeamAdminPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##add--remove-relationship) for [creating](https://neo4j.com/docs/cypher-manual/4.1/clauses/create/#create-relationships) the TEAM_MEMBER relationship. */
  AddTeamMembers?: Maybe<_AddTeamMembersPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##add--remove-relationship) for [deleting](https://neo4j.com/docs/cypher-manual/4.1/clauses/delete/#delete-delete-relationships-only) the TEAM_MEMBER relationship. */
  RemoveTeamMembers?: Maybe<_RemoveTeamMembersPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##merge-relationship) for [merging](https://neo4j.com/docs/cypher-manual/4.1/clauses/merge/#query-merge-relationships) the TEAM_MEMBER relationship. */
  MergeTeamMembers?: Maybe<_MergeTeamMembersPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##add--remove-relationship) for [creating](https://neo4j.com/docs/cypher-manual/4.1/clauses/create/#create-relationships) the TEAM_AT relationship. */
  AddTeamCompany?: Maybe<_AddTeamCompanyPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##add--remove-relationship) for [deleting](https://neo4j.com/docs/cypher-manual/4.1/clauses/delete/#delete-delete-relationships-only) the TEAM_AT relationship. */
  RemoveTeamCompany?: Maybe<_RemoveTeamCompanyPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##merge-relationship) for [merging](https://neo4j.com/docs/cypher-manual/4.1/clauses/merge/#query-merge-relationships) the TEAM_AT relationship. */
  MergeTeamCompany?: Maybe<_MergeTeamCompanyPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##add--remove-relationship) for [creating](https://neo4j.com/docs/cypher-manual/4.1/clauses/create/#create-relationships) the TEAM_CONCEPT relationship. */
  AddTeamConcepts?: Maybe<_AddTeamConceptsPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##add--remove-relationship) for [deleting](https://neo4j.com/docs/cypher-manual/4.1/clauses/delete/#delete-delete-relationships-only) the TEAM_CONCEPT relationship. */
  RemoveTeamConcepts?: Maybe<_RemoveTeamConceptsPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##merge-relationship) for [merging](https://neo4j.com/docs/cypher-manual/4.1/clauses/merge/#query-merge-relationships) the TEAM_CONCEPT relationship. */
  MergeTeamConcepts?: Maybe<_MergeTeamConceptsPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##add--remove-relationship) for [creating](https://neo4j.com/docs/cypher-manual/4.1/clauses/create/#create-relationships) the TEAM_HOMEPAGE_CONCEPT relationship. */
  AddTeamHomeConcept?: Maybe<_AddTeamHomeConceptPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##add--remove-relationship) for [deleting](https://neo4j.com/docs/cypher-manual/4.1/clauses/delete/#delete-delete-relationships-only) the TEAM_HOMEPAGE_CONCEPT relationship. */
  RemoveTeamHomeConcept?: Maybe<_RemoveTeamHomeConceptPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##merge-relationship) for [merging](https://neo4j.com/docs/cypher-manual/4.1/clauses/merge/#query-merge-relationships) the TEAM_HOMEPAGE_CONCEPT relationship. */
  MergeTeamHomeConcept?: Maybe<_MergeTeamHomeConceptPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##add--remove-relationship) for [creating](https://neo4j.com/docs/cypher-manual/4.1/clauses/create/#create-relationships) the HAS_ACCESS relationship. */
  AddTeamAddedToConcepts?: Maybe<_AddTeamAddedToConceptsPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##add--remove-relationship) for [deleting](https://neo4j.com/docs/cypher-manual/4.1/clauses/delete/#delete-delete-relationships-only) the HAS_ACCESS relationship. */
  RemoveTeamAddedToConcepts?: Maybe<_RemoveTeamAddedToConceptsPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##update-relationship) for [updating](https://neo4j.com/docs/cypher-manual/4.1/clauses/set/#set-update-a-property) the HAS_ACCESS relationship. */
  UpdateTeamAddedToConcepts?: Maybe<_UpdateTeamAddedToConceptsPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##merge-relationship) for [merging](https://neo4j.com/docs/cypher-manual/4.1/clauses/merge/#query-merge-relationships) the HAS_ACCESS relationship. */
  MergeTeamAddedToConcepts?: Maybe<_MergeTeamAddedToConceptsPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##add--remove-relationship) for [creating](https://neo4j.com/docs/cypher-manual/4.1/clauses/create/#create-relationships) the TEAM_CONCEPT_LIST relationship. */
  AddTeamConceptLists?: Maybe<_AddTeamConceptListsPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##add--remove-relationship) for [deleting](https://neo4j.com/docs/cypher-manual/4.1/clauses/delete/#delete-delete-relationships-only) the TEAM_CONCEPT_LIST relationship. */
  RemoveTeamConceptLists?: Maybe<_RemoveTeamConceptListsPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##merge-relationship) for [merging](https://neo4j.com/docs/cypher-manual/4.1/clauses/merge/#query-merge-relationships) the TEAM_CONCEPT_LIST relationship. */
  MergeTeamConceptLists?: Maybe<_MergeTeamConceptListsPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##add--remove-relationship) for [creating](https://neo4j.com/docs/cypher-manual/4.1/clauses/create/#create-relationships) the EXERCISE_INVITE_SENT_TO_TEAM relationship. */
  AddTeamExercisesInvitedTo?: Maybe<_AddTeamExercisesInvitedToPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##add--remove-relationship) for [deleting](https://neo4j.com/docs/cypher-manual/4.1/clauses/delete/#delete-delete-relationships-only) the EXERCISE_INVITE_SENT_TO_TEAM relationship. */
  RemoveTeamExercisesInvitedTo?: Maybe<_RemoveTeamExercisesInvitedToPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##merge-relationship) for [merging](https://neo4j.com/docs/cypher-manual/4.1/clauses/merge/#query-merge-relationships) the EXERCISE_INVITE_SENT_TO_TEAM relationship. */
  MergeTeamExercisesInvitedTo?: Maybe<_MergeTeamExercisesInvitedToPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/#create) for [creating](https://neo4j.com/docs/cypher-manual/4.1/clauses/create/#create-nodes) a Team node. */
  CreateTeam?: Maybe<Team>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/#update) for [updating](https://neo4j.com/docs/cypher-manual/4.1/clauses/set/#set-update-a-property) a Team node. */
  UpdateTeam?: Maybe<Team>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/#delete) for [deleting](https://neo4j.com/docs/cypher-manual/4.1/clauses/delete/#delete-delete-single-node) a Team node. */
  DeleteTeam?: Maybe<Team>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/#merge) for [merging](https://neo4j.com/docs/cypher-manual/4.1/clauses/merge/#query-merge-node-derived) a Team node. */
  MergeTeam?: Maybe<Team>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##add--remove-relationship) for [creating](https://neo4j.com/docs/cypher-manual/4.1/clauses/create/#create-relationships) the COMMENTED relationship. */
  AddCommentUser?: Maybe<_AddCommentUserPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##add--remove-relationship) for [deleting](https://neo4j.com/docs/cypher-manual/4.1/clauses/delete/#delete-delete-relationships-only) the COMMENTED relationship. */
  RemoveCommentUser?: Maybe<_RemoveCommentUserPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##merge-relationship) for [merging](https://neo4j.com/docs/cypher-manual/4.1/clauses/merge/#query-merge-relationships) the COMMENTED relationship. */
  MergeCommentUser?: Maybe<_MergeCommentUserPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##add--remove-relationship) for [creating](https://neo4j.com/docs/cypher-manual/4.1/clauses/create/#create-relationships) the COMMENT_ON relationship. */
  AddCommentConcept?: Maybe<_AddCommentConceptPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##add--remove-relationship) for [deleting](https://neo4j.com/docs/cypher-manual/4.1/clauses/delete/#delete-delete-relationships-only) the COMMENT_ON relationship. */
  RemoveCommentConcept?: Maybe<_RemoveCommentConceptPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##merge-relationship) for [merging](https://neo4j.com/docs/cypher-manual/4.1/clauses/merge/#query-merge-relationships) the COMMENT_ON relationship. */
  MergeCommentConcept?: Maybe<_MergeCommentConceptPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##add--remove-relationship) for [creating](https://neo4j.com/docs/cypher-manual/4.1/clauses/create/#create-relationships) the REPLIED relationship. */
  AddCommentReplyTo?: Maybe<_AddCommentReplyToPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##add--remove-relationship) for [deleting](https://neo4j.com/docs/cypher-manual/4.1/clauses/delete/#delete-delete-relationships-only) the REPLIED relationship. */
  RemoveCommentReplyTo?: Maybe<_RemoveCommentReplyToPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##merge-relationship) for [merging](https://neo4j.com/docs/cypher-manual/4.1/clauses/merge/#query-merge-relationships) the REPLIED relationship. */
  MergeCommentReplyTo?: Maybe<_MergeCommentReplyToPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##add--remove-relationship) for [creating](https://neo4j.com/docs/cypher-manual/4.1/clauses/create/#create-relationships) the REPLIED relationship. */
  AddCommentReplies?: Maybe<_AddCommentRepliesPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##add--remove-relationship) for [deleting](https://neo4j.com/docs/cypher-manual/4.1/clauses/delete/#delete-delete-relationships-only) the REPLIED relationship. */
  RemoveCommentReplies?: Maybe<_RemoveCommentRepliesPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##merge-relationship) for [merging](https://neo4j.com/docs/cypher-manual/4.1/clauses/merge/#query-merge-relationships) the REPLIED relationship. */
  MergeCommentReplies?: Maybe<_MergeCommentRepliesPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##add--remove-relationship) for [creating](https://neo4j.com/docs/cypher-manual/4.1/clauses/create/#create-relationships) the COMMENT_TRIGGERED_NOTIFICATION relationship. */
  AddCommentNotifications?: Maybe<_AddCommentNotificationsPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##add--remove-relationship) for [deleting](https://neo4j.com/docs/cypher-manual/4.1/clauses/delete/#delete-delete-relationships-only) the COMMENT_TRIGGERED_NOTIFICATION relationship. */
  RemoveCommentNotifications?: Maybe<_RemoveCommentNotificationsPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##merge-relationship) for [merging](https://neo4j.com/docs/cypher-manual/4.1/clauses/merge/#query-merge-relationships) the COMMENT_TRIGGERED_NOTIFICATION relationship. */
  MergeCommentNotifications?: Maybe<_MergeCommentNotificationsPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##add--remove-relationship) for [creating](https://neo4j.com/docs/cypher-manual/4.1/clauses/create/#create-relationships) the LIKED_COMMENT relationship. */
  AddCommentLikedBy?: Maybe<_AddCommentLikedByPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##add--remove-relationship) for [deleting](https://neo4j.com/docs/cypher-manual/4.1/clauses/delete/#delete-delete-relationships-only) the LIKED_COMMENT relationship. */
  RemoveCommentLikedBy?: Maybe<_RemoveCommentLikedByPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##merge-relationship) for [merging](https://neo4j.com/docs/cypher-manual/4.1/clauses/merge/#query-merge-relationships) the LIKED_COMMENT relationship. */
  MergeCommentLikedBy?: Maybe<_MergeCommentLikedByPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/#update) for [updating](https://neo4j.com/docs/cypher-manual/4.1/clauses/set/#set-update-a-property) a Comment node. */
  UpdateComment?: Maybe<Comment>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/#delete) for [deleting](https://neo4j.com/docs/cypher-manual/4.1/clauses/delete/#delete-delete-single-node) a Comment node. */
  DeleteComment?: Maybe<Comment>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/#merge) for [merging](https://neo4j.com/docs/cypher-manual/4.1/clauses/merge/#query-merge-node-derived) a Comment node. */
  MergeComment?: Maybe<Comment>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##add--remove-relationship) for [creating](https://neo4j.com/docs/cypher-manual/4.1/clauses/create/#create-relationships) the TAGGED relationship. */
  AddTagTagged?: Maybe<_AddTagTaggedPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##add--remove-relationship) for [deleting](https://neo4j.com/docs/cypher-manual/4.1/clauses/delete/#delete-delete-relationships-only) the TAGGED relationship. */
  RemoveTagTagged?: Maybe<_RemoveTagTaggedPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##merge-relationship) for [merging](https://neo4j.com/docs/cypher-manual/4.1/clauses/merge/#query-merge-relationships) the TAGGED relationship. */
  MergeTagTagged?: Maybe<_MergeTagTaggedPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/#create) for [creating](https://neo4j.com/docs/cypher-manual/4.1/clauses/create/#create-nodes) a Tag node. */
  CreateTag?: Maybe<Tag>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/#delete) for [deleting](https://neo4j.com/docs/cypher-manual/4.1/clauses/delete/#delete-delete-single-node) a Tag node. */
  DeleteTag?: Maybe<Tag>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/#merge) for [merging](https://neo4j.com/docs/cypher-manual/4.1/clauses/merge/#query-merge-node-derived) a Tag node. */
  MergeTag?: Maybe<Tag>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##add--remove-relationship) for [creating](https://neo4j.com/docs/cypher-manual/4.1/clauses/create/#create-relationships) the CREATED relationship. */
  AddChecklistCreatedBy?: Maybe<_AddChecklistCreatedByPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##add--remove-relationship) for [deleting](https://neo4j.com/docs/cypher-manual/4.1/clauses/delete/#delete-delete-relationships-only) the CREATED relationship. */
  RemoveChecklistCreatedBy?: Maybe<_RemoveChecklistCreatedByPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##merge-relationship) for [merging](https://neo4j.com/docs/cypher-manual/4.1/clauses/merge/#query-merge-relationships) the CREATED relationship. */
  MergeChecklistCreatedBy?: Maybe<_MergeChecklistCreatedByPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##add--remove-relationship) for [creating](https://neo4j.com/docs/cypher-manual/4.1/clauses/create/#create-relationships) the CHECKLIST_TYPE relationship. */
  AddChecklistType?: Maybe<_AddChecklistTypePayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##add--remove-relationship) for [deleting](https://neo4j.com/docs/cypher-manual/4.1/clauses/delete/#delete-delete-relationships-only) the CHECKLIST_TYPE relationship. */
  RemoveChecklistType?: Maybe<_RemoveChecklistTypePayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##merge-relationship) for [merging](https://neo4j.com/docs/cypher-manual/4.1/clauses/merge/#query-merge-relationships) the CHECKLIST_TYPE relationship. */
  MergeChecklistType?: Maybe<_MergeChecklistTypePayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##add--remove-relationship) for [creating](https://neo4j.com/docs/cypher-manual/4.1/clauses/create/#create-relationships) the HAS_LIST_ITEM relationship. */
  AddChecklistItems?: Maybe<_AddChecklistItemsPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##add--remove-relationship) for [deleting](https://neo4j.com/docs/cypher-manual/4.1/clauses/delete/#delete-delete-relationships-only) the HAS_LIST_ITEM relationship. */
  RemoveChecklistItems?: Maybe<_RemoveChecklistItemsPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##merge-relationship) for [merging](https://neo4j.com/docs/cypher-manual/4.1/clauses/merge/#query-merge-relationships) the HAS_LIST_ITEM relationship. */
  MergeChecklistItems?: Maybe<_MergeChecklistItemsPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##add--remove-relationship) for [creating](https://neo4j.com/docs/cypher-manual/4.1/clauses/create/#create-relationships) the CONCEPT_CHECKLIST relationship. */
  AddChecklistConcept?: Maybe<_AddChecklistConceptPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##add--remove-relationship) for [deleting](https://neo4j.com/docs/cypher-manual/4.1/clauses/delete/#delete-delete-relationships-only) the CONCEPT_CHECKLIST relationship. */
  RemoveChecklistConcept?: Maybe<_RemoveChecklistConceptPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##merge-relationship) for [merging](https://neo4j.com/docs/cypher-manual/4.1/clauses/merge/#query-merge-relationships) the CONCEPT_CHECKLIST relationship. */
  MergeChecklistConcept?: Maybe<_MergeChecklistConceptPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##add--remove-relationship) for [creating](https://neo4j.com/docs/cypher-manual/4.1/clauses/create/#create-relationships) the EXERCISE_INCLUDES_CHECKLIST relationship. */
  AddChecklistExercise?: Maybe<_AddChecklistExercisePayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##add--remove-relationship) for [deleting](https://neo4j.com/docs/cypher-manual/4.1/clauses/delete/#delete-delete-relationships-only) the EXERCISE_INCLUDES_CHECKLIST relationship. */
  RemoveChecklistExercise?: Maybe<_RemoveChecklistExercisePayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##merge-relationship) for [merging](https://neo4j.com/docs/cypher-manual/4.1/clauses/merge/#query-merge-relationships) the EXERCISE_INCLUDES_CHECKLIST relationship. */
  MergeChecklistExercise?: Maybe<_MergeChecklistExercisePayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/#delete) for [deleting](https://neo4j.com/docs/cypher-manual/4.1/clauses/delete/#delete-delete-single-node) a Checklist node. */
  DeleteChecklist?: Maybe<Checklist>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/#merge) for [merging](https://neo4j.com/docs/cypher-manual/4.1/clauses/merge/#query-merge-node-derived) a Checklist node. */
  MergeChecklist?: Maybe<Checklist>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##add--remove-relationship) for [creating](https://neo4j.com/docs/cypher-manual/4.1/clauses/create/#create-relationships) the CHECKLIST_TYPE relationship. */
  AddChecklistTypeChecklists?: Maybe<_AddChecklistTypeChecklistsPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##add--remove-relationship) for [deleting](https://neo4j.com/docs/cypher-manual/4.1/clauses/delete/#delete-delete-relationships-only) the CHECKLIST_TYPE relationship. */
  RemoveChecklistTypeChecklists?: Maybe<_RemoveChecklistTypeChecklistsPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##merge-relationship) for [merging](https://neo4j.com/docs/cypher-manual/4.1/clauses/merge/#query-merge-relationships) the CHECKLIST_TYPE relationship. */
  MergeChecklistTypeChecklists?: Maybe<_MergeChecklistTypeChecklistsPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/#create) for [creating](https://neo4j.com/docs/cypher-manual/4.1/clauses/create/#create-nodes) a ChecklistType node. */
  CreateChecklistType?: Maybe<ChecklistType>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/#update) for [updating](https://neo4j.com/docs/cypher-manual/4.1/clauses/set/#set-update-a-property) a ChecklistType node. */
  UpdateChecklistType?: Maybe<ChecklistType>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/#delete) for [deleting](https://neo4j.com/docs/cypher-manual/4.1/clauses/delete/#delete-delete-single-node) a ChecklistType node. */
  DeleteChecklistType?: Maybe<ChecklistType>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##add--remove-relationship) for [creating](https://neo4j.com/docs/cypher-manual/4.1/clauses/create/#create-relationships) the CREATED relationship. */
  AddChecklistItemCreatedBy?: Maybe<_AddChecklistItemCreatedByPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##add--remove-relationship) for [deleting](https://neo4j.com/docs/cypher-manual/4.1/clauses/delete/#delete-delete-relationships-only) the CREATED relationship. */
  RemoveChecklistItemCreatedBy?: Maybe<_RemoveChecklistItemCreatedByPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##merge-relationship) for [merging](https://neo4j.com/docs/cypher-manual/4.1/clauses/merge/#query-merge-relationships) the CREATED relationship. */
  MergeChecklistItemCreatedBy?: Maybe<_MergeChecklistItemCreatedByPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##add--remove-relationship) for [creating](https://neo4j.com/docs/cypher-manual/4.1/clauses/create/#create-relationships) the HAS_LIST_ITEM relationship. */
  AddChecklistItemChecklist?: Maybe<_AddChecklistItemChecklistPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##add--remove-relationship) for [deleting](https://neo4j.com/docs/cypher-manual/4.1/clauses/delete/#delete-delete-relationships-only) the HAS_LIST_ITEM relationship. */
  RemoveChecklistItemChecklist?: Maybe<_RemoveChecklistItemChecklistPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##merge-relationship) for [merging](https://neo4j.com/docs/cypher-manual/4.1/clauses/merge/#query-merge-relationships) the HAS_LIST_ITEM relationship. */
  MergeChecklistItemChecklist?: Maybe<_MergeChecklistItemChecklistPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##add--remove-relationship) for [creating](https://neo4j.com/docs/cypher-manual/4.1/clauses/create/#create-relationships) the CL_ITEM_CHILD_OF relationship. */
  AddChecklistItemParent?: Maybe<_AddChecklistItemParentPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##add--remove-relationship) for [deleting](https://neo4j.com/docs/cypher-manual/4.1/clauses/delete/#delete-delete-relationships-only) the CL_ITEM_CHILD_OF relationship. */
  RemoveChecklistItemParent?: Maybe<_RemoveChecklistItemParentPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##merge-relationship) for [merging](https://neo4j.com/docs/cypher-manual/4.1/clauses/merge/#query-merge-relationships) the CL_ITEM_CHILD_OF relationship. */
  MergeChecklistItemParent?: Maybe<_MergeChecklistItemParentPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##add--remove-relationship) for [creating](https://neo4j.com/docs/cypher-manual/4.1/clauses/create/#create-relationships) the CL_ITEM_CHILD_OF relationship. */
  AddChecklistItemChildren?: Maybe<_AddChecklistItemChildrenPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##add--remove-relationship) for [deleting](https://neo4j.com/docs/cypher-manual/4.1/clauses/delete/#delete-delete-relationships-only) the CL_ITEM_CHILD_OF relationship. */
  RemoveChecklistItemChildren?: Maybe<_RemoveChecklistItemChildrenPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##merge-relationship) for [merging](https://neo4j.com/docs/cypher-manual/4.1/clauses/merge/#query-merge-relationships) the CL_ITEM_CHILD_OF relationship. */
  MergeChecklistItemChildren?: Maybe<_MergeChecklistItemChildrenPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##add--remove-relationship) for [creating](https://neo4j.com/docs/cypher-manual/4.1/clauses/create/#create-relationships) the CL_ITEM_LINKED_CONCEPT relationship. */
  AddChecklistItemConcept?: Maybe<_AddChecklistItemConceptPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##add--remove-relationship) for [deleting](https://neo4j.com/docs/cypher-manual/4.1/clauses/delete/#delete-delete-relationships-only) the CL_ITEM_LINKED_CONCEPT relationship. */
  RemoveChecklistItemConcept?: Maybe<_RemoveChecklistItemConceptPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##merge-relationship) for [merging](https://neo4j.com/docs/cypher-manual/4.1/clauses/merge/#query-merge-relationships) the CL_ITEM_LINKED_CONCEPT relationship. */
  MergeChecklistItemConcept?: Maybe<_MergeChecklistItemConceptPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/#delete) for [deleting](https://neo4j.com/docs/cypher-manual/4.1/clauses/delete/#delete-delete-single-node) a ChecklistItem node. */
  DeleteChecklistItem?: Maybe<ChecklistItem>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/#merge) for [merging](https://neo4j.com/docs/cypher-manual/4.1/clauses/merge/#query-merge-node-derived) a ChecklistItem node. */
  MergeChecklistItem?: Maybe<ChecklistItem>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##add--remove-relationship) for [creating](https://neo4j.com/docs/cypher-manual/4.1/clauses/create/#create-relationships) the HAS_CONCEPT_LIST relationship. */
  AddConceptListUser?: Maybe<_AddConceptListUserPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##add--remove-relationship) for [deleting](https://neo4j.com/docs/cypher-manual/4.1/clauses/delete/#delete-delete-relationships-only) the HAS_CONCEPT_LIST relationship. */
  RemoveConceptListUser?: Maybe<_RemoveConceptListUserPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##merge-relationship) for [merging](https://neo4j.com/docs/cypher-manual/4.1/clauses/merge/#query-merge-relationships) the HAS_CONCEPT_LIST relationship. */
  MergeConceptListUser?: Maybe<_MergeConceptListUserPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##add--remove-relationship) for [creating](https://neo4j.com/docs/cypher-manual/4.1/clauses/create/#create-relationships) the HAS_WORKSPACE_INBOX relationship. */
  AddConceptListInboxUser?: Maybe<_AddConceptListInboxUserPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##add--remove-relationship) for [deleting](https://neo4j.com/docs/cypher-manual/4.1/clauses/delete/#delete-delete-relationships-only) the HAS_WORKSPACE_INBOX relationship. */
  RemoveConceptListInboxUser?: Maybe<_RemoveConceptListInboxUserPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##merge-relationship) for [merging](https://neo4j.com/docs/cypher-manual/4.1/clauses/merge/#query-merge-relationships) the HAS_WORKSPACE_INBOX relationship. */
  MergeConceptListInboxUser?: Maybe<_MergeConceptListInboxUserPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##add--remove-relationship) for [creating](https://neo4j.com/docs/cypher-manual/4.1/clauses/create/#create-relationships) the TEAM_CONCEPT_LIST relationship. */
  AddConceptListTeam?: Maybe<_AddConceptListTeamPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##add--remove-relationship) for [deleting](https://neo4j.com/docs/cypher-manual/4.1/clauses/delete/#delete-delete-relationships-only) the TEAM_CONCEPT_LIST relationship. */
  RemoveConceptListTeam?: Maybe<_RemoveConceptListTeamPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##merge-relationship) for [merging](https://neo4j.com/docs/cypher-manual/4.1/clauses/merge/#query-merge-relationships) the TEAM_CONCEPT_LIST relationship. */
  MergeConceptListTeam?: Maybe<_MergeConceptListTeamPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##add--remove-relationship) for [creating](https://neo4j.com/docs/cypher-manual/4.1/clauses/create/#create-relationships) the IN_LIST relationship. */
  AddConceptListConcepts?: Maybe<_AddConceptListConceptsPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##add--remove-relationship) for [deleting](https://neo4j.com/docs/cypher-manual/4.1/clauses/delete/#delete-delete-relationships-only) the IN_LIST relationship. */
  RemoveConceptListConcepts?: Maybe<_RemoveConceptListConceptsPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##merge-relationship) for [merging](https://neo4j.com/docs/cypher-manual/4.1/clauses/merge/#query-merge-relationships) the IN_LIST relationship. */
  MergeConceptListConcepts?: Maybe<_MergeConceptListConceptsPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##add--remove-relationship) for [creating](https://neo4j.com/docs/cypher-manual/4.1/clauses/create/#create-relationships) the HAS_CONCEPT_WORKSPACE relationship. */
  AddConceptListWorkspaceConcept?: Maybe<_AddConceptListWorkspaceConceptPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##add--remove-relationship) for [deleting](https://neo4j.com/docs/cypher-manual/4.1/clauses/delete/#delete-delete-relationships-only) the HAS_CONCEPT_WORKSPACE relationship. */
  RemoveConceptListWorkspaceConcept?: Maybe<_RemoveConceptListWorkspaceConceptPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##merge-relationship) for [merging](https://neo4j.com/docs/cypher-manual/4.1/clauses/merge/#query-merge-relationships) the HAS_CONCEPT_WORKSPACE relationship. */
  MergeConceptListWorkspaceConcept?: Maybe<_MergeConceptListWorkspaceConceptPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##add--remove-relationship) for [creating](https://neo4j.com/docs/cypher-manual/4.1/clauses/create/#create-relationships) the CHILD_CONCEPT_LIST relationship. */
  AddConceptListParentConcept?: Maybe<_AddConceptListParentConceptPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##add--remove-relationship) for [deleting](https://neo4j.com/docs/cypher-manual/4.1/clauses/delete/#delete-delete-relationships-only) the CHILD_CONCEPT_LIST relationship. */
  RemoveConceptListParentConcept?: Maybe<_RemoveConceptListParentConceptPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##merge-relationship) for [merging](https://neo4j.com/docs/cypher-manual/4.1/clauses/merge/#query-merge-relationships) the CHILD_CONCEPT_LIST relationship. */
  MergeConceptListParentConcept?: Maybe<_MergeConceptListParentConceptPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##add--remove-relationship) for [creating](https://neo4j.com/docs/cypher-manual/4.1/clauses/create/#create-relationships) the ON_BOARD relationship. */
  AddConceptListConceptBoard?: Maybe<_AddConceptListConceptBoardPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##add--remove-relationship) for [deleting](https://neo4j.com/docs/cypher-manual/4.1/clauses/delete/#delete-delete-relationships-only) the ON_BOARD relationship. */
  RemoveConceptListConceptBoard?: Maybe<_RemoveConceptListConceptBoardPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##merge-relationship) for [merging](https://neo4j.com/docs/cypher-manual/4.1/clauses/merge/#query-merge-relationships) the ON_BOARD relationship. */
  MergeConceptListConceptBoard?: Maybe<_MergeConceptListConceptBoardPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##add--remove-relationship) for [creating](https://neo4j.com/docs/cypher-manual/4.1/clauses/create/#create-relationships) the PERSONA_REQUIREMENTS relationship. */
  AddConceptListPersona?: Maybe<_AddConceptListPersonaPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##add--remove-relationship) for [deleting](https://neo4j.com/docs/cypher-manual/4.1/clauses/delete/#delete-delete-relationships-only) the PERSONA_REQUIREMENTS relationship. */
  RemoveConceptListPersona?: Maybe<_RemoveConceptListPersonaPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##merge-relationship) for [merging](https://neo4j.com/docs/cypher-manual/4.1/clauses/merge/#query-merge-relationships) the PERSONA_REQUIREMENTS relationship. */
  MergeConceptListPersona?: Maybe<_MergeConceptListPersonaPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/#create) for [creating](https://neo4j.com/docs/cypher-manual/4.1/clauses/create/#create-nodes) a ConceptList node. */
  CreateConceptList?: Maybe<ConceptList>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/#update) for [updating](https://neo4j.com/docs/cypher-manual/4.1/clauses/set/#set-update-a-property) a ConceptList node. */
  UpdateConceptList?: Maybe<ConceptList>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/#delete) for [deleting](https://neo4j.com/docs/cypher-manual/4.1/clauses/delete/#delete-delete-single-node) a ConceptList node. */
  DeleteConceptList?: Maybe<ConceptList>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/#merge) for [merging](https://neo4j.com/docs/cypher-manual/4.1/clauses/merge/#query-merge-node-derived) a ConceptList node. */
  MergeConceptList?: Maybe<ConceptList>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##add--remove-relationship) for [creating](https://neo4j.com/docs/cypher-manual/4.1/clauses/create/#create-relationships) the ON_BOARD relationship. */
  AddConceptBoardColumns?: Maybe<_AddConceptBoardColumnsPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##add--remove-relationship) for [deleting](https://neo4j.com/docs/cypher-manual/4.1/clauses/delete/#delete-delete-relationships-only) the ON_BOARD relationship. */
  RemoveConceptBoardColumns?: Maybe<_RemoveConceptBoardColumnsPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##merge-relationship) for [merging](https://neo4j.com/docs/cypher-manual/4.1/clauses/merge/#query-merge-relationships) the ON_BOARD relationship. */
  MergeConceptBoardColumns?: Maybe<_MergeConceptBoardColumnsPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##add--remove-relationship) for [creating](https://neo4j.com/docs/cypher-manual/4.1/clauses/create/#create-relationships) the HAS_CONCEPT_BOARD relationship. */
  AddConceptBoardUser?: Maybe<_AddConceptBoardUserPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##add--remove-relationship) for [deleting](https://neo4j.com/docs/cypher-manual/4.1/clauses/delete/#delete-delete-relationships-only) the HAS_CONCEPT_BOARD relationship. */
  RemoveConceptBoardUser?: Maybe<_RemoveConceptBoardUserPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##merge-relationship) for [merging](https://neo4j.com/docs/cypher-manual/4.1/clauses/merge/#query-merge-relationships) the HAS_CONCEPT_BOARD relationship. */
  MergeConceptBoardUser?: Maybe<_MergeConceptBoardUserPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/#create) for [creating](https://neo4j.com/docs/cypher-manual/4.1/clauses/create/#create-nodes) a ConceptBoard node. */
  CreateConceptBoard?: Maybe<ConceptBoard>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/#update) for [updating](https://neo4j.com/docs/cypher-manual/4.1/clauses/set/#set-update-a-property) a ConceptBoard node. */
  UpdateConceptBoard?: Maybe<ConceptBoard>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/#delete) for [deleting](https://neo4j.com/docs/cypher-manual/4.1/clauses/delete/#delete-delete-single-node) a ConceptBoard node. */
  DeleteConceptBoard?: Maybe<ConceptBoard>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/#merge) for [merging](https://neo4j.com/docs/cypher-manual/4.1/clauses/merge/#query-merge-node-derived) a ConceptBoard node. */
  MergeConceptBoard?: Maybe<ConceptBoard>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/#create) for [creating](https://neo4j.com/docs/cypher-manual/4.1/clauses/create/#create-nodes) a Link node. */
  CreateLink?: Maybe<Link>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/#update) for [updating](https://neo4j.com/docs/cypher-manual/4.1/clauses/set/#set-update-a-property) a Link node. */
  UpdateLink?: Maybe<Link>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/#delete) for [deleting](https://neo4j.com/docs/cypher-manual/4.1/clauses/delete/#delete-delete-single-node) a Link node. */
  DeleteLink?: Maybe<Link>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/#merge) for [merging](https://neo4j.com/docs/cypher-manual/4.1/clauses/merge/#query-merge-node-derived) a Link node. */
  MergeLink?: Maybe<Link>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##add--remove-relationship) for [creating](https://neo4j.com/docs/cypher-manual/4.1/clauses/create/#create-relationships) the LIKED relationship. */
  AddInitiativeLikedBy?: Maybe<_AddInitiativeLikedByPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##add--remove-relationship) for [deleting](https://neo4j.com/docs/cypher-manual/4.1/clauses/delete/#delete-delete-relationships-only) the LIKED relationship. */
  RemoveInitiativeLikedBy?: Maybe<_RemoveInitiativeLikedByPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##merge-relationship) for [merging](https://neo4j.com/docs/cypher-manual/4.1/clauses/merge/#query-merge-relationships) the LIKED relationship. */
  MergeInitiativeLikedBy?: Maybe<_MergeInitiativeLikedByPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##add--remove-relationship) for [creating](https://neo4j.com/docs/cypher-manual/4.1/clauses/create/#create-relationships) the TEAM_INITIATIVE relationship. */
  AddInitiativeTeam?: Maybe<_AddInitiativeTeamPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##add--remove-relationship) for [deleting](https://neo4j.com/docs/cypher-manual/4.1/clauses/delete/#delete-delete-relationships-only) the TEAM_INITIATIVE relationship. */
  RemoveInitiativeTeam?: Maybe<_RemoveInitiativeTeamPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##merge-relationship) for [merging](https://neo4j.com/docs/cypher-manual/4.1/clauses/merge/#query-merge-relationships) the TEAM_INITIATIVE relationship. */
  MergeInitiativeTeam?: Maybe<_MergeInitiativeTeamPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##add--remove-relationship) for [creating](https://neo4j.com/docs/cypher-manual/4.1/clauses/create/#create-relationships) the USER_INITIATIVE relationship. */
  AddInitiativeUser?: Maybe<_AddInitiativeUserPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##add--remove-relationship) for [deleting](https://neo4j.com/docs/cypher-manual/4.1/clauses/delete/#delete-delete-relationships-only) the USER_INITIATIVE relationship. */
  RemoveInitiativeUser?: Maybe<_RemoveInitiativeUserPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##merge-relationship) for [merging](https://neo4j.com/docs/cypher-manual/4.1/clauses/merge/#query-merge-relationships) the USER_INITIATIVE relationship. */
  MergeInitiativeUser?: Maybe<_MergeInitiativeUserPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##add--remove-relationship) for [creating](https://neo4j.com/docs/cypher-manual/4.1/clauses/create/#create-relationships) the CONNECTED relationship. */
  AddInitiativeConnections?: Maybe<_AddInitiativeConnectionsPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##add--remove-relationship) for [deleting](https://neo4j.com/docs/cypher-manual/4.1/clauses/delete/#delete-delete-relationships-only) the CONNECTED relationship. */
  RemoveInitiativeConnections?: Maybe<_RemoveInitiativeConnectionsPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##merge-relationship) for [merging](https://neo4j.com/docs/cypher-manual/4.1/clauses/merge/#query-merge-relationships) the CONNECTED relationship. */
  MergeInitiativeConnections?: Maybe<_MergeInitiativeConnectionsPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##add--remove-relationship) for [creating](https://neo4j.com/docs/cypher-manual/4.1/clauses/create/#create-relationships) the TAGGED relationship. */
  AddInitiativeTags?: Maybe<_AddInitiativeTagsPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##add--remove-relationship) for [deleting](https://neo4j.com/docs/cypher-manual/4.1/clauses/delete/#delete-delete-relationships-only) the TAGGED relationship. */
  RemoveInitiativeTags?: Maybe<_RemoveInitiativeTagsPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##merge-relationship) for [merging](https://neo4j.com/docs/cypher-manual/4.1/clauses/merge/#query-merge-relationships) the TAGGED relationship. */
  MergeInitiativeTags?: Maybe<_MergeInitiativeTagsPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##add--remove-relationship) for [creating](https://neo4j.com/docs/cypher-manual/4.1/clauses/create/#create-relationships) the COMMENT_ON relationship. */
  AddInitiativeComments?: Maybe<_AddInitiativeCommentsPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##add--remove-relationship) for [deleting](https://neo4j.com/docs/cypher-manual/4.1/clauses/delete/#delete-delete-relationships-only) the COMMENT_ON relationship. */
  RemoveInitiativeComments?: Maybe<_RemoveInitiativeCommentsPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##merge-relationship) for [merging](https://neo4j.com/docs/cypher-manual/4.1/clauses/merge/#query-merge-relationships) the COMMENT_ON relationship. */
  MergeInitiativeComments?: Maybe<_MergeInitiativeCommentsPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/#create) for [creating](https://neo4j.com/docs/cypher-manual/4.1/clauses/create/#create-nodes) a Initiative node. */
  CreateInitiative?: Maybe<Initiative>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/#update) for [updating](https://neo4j.com/docs/cypher-manual/4.1/clauses/set/#set-update-a-property) a Initiative node. */
  UpdateInitiative?: Maybe<Initiative>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/#delete) for [deleting](https://neo4j.com/docs/cypher-manual/4.1/clauses/delete/#delete-delete-single-node) a Initiative node. */
  DeleteInitiative?: Maybe<Initiative>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/#merge) for [merging](https://neo4j.com/docs/cypher-manual/4.1/clauses/merge/#query-merge-node-derived) a Initiative node. */
  MergeInitiative?: Maybe<Initiative>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##add--remove-relationship) for [creating](https://neo4j.com/docs/cypher-manual/4.1/clauses/create/#create-relationships) the TYPE_OF relationship. */
  AddCategoryParent?: Maybe<_AddCategoryParentPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##add--remove-relationship) for [deleting](https://neo4j.com/docs/cypher-manual/4.1/clauses/delete/#delete-delete-relationships-only) the TYPE_OF relationship. */
  RemoveCategoryParent?: Maybe<_RemoveCategoryParentPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##merge-relationship) for [merging](https://neo4j.com/docs/cypher-manual/4.1/clauses/merge/#query-merge-relationships) the TYPE_OF relationship. */
  MergeCategoryParent?: Maybe<_MergeCategoryParentPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##add--remove-relationship) for [creating](https://neo4j.com/docs/cypher-manual/4.1/clauses/create/#create-relationships) the TYPE_OF relationship. */
  AddCategoryChildren?: Maybe<_AddCategoryChildrenPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##add--remove-relationship) for [deleting](https://neo4j.com/docs/cypher-manual/4.1/clauses/delete/#delete-delete-relationships-only) the TYPE_OF relationship. */
  RemoveCategoryChildren?: Maybe<_RemoveCategoryChildrenPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##merge-relationship) for [merging](https://neo4j.com/docs/cypher-manual/4.1/clauses/merge/#query-merge-relationships) the TYPE_OF relationship. */
  MergeCategoryChildren?: Maybe<_MergeCategoryChildrenPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##add--remove-relationship) for [creating](https://neo4j.com/docs/cypher-manual/4.1/clauses/create/#create-relationships) the IN_CATEGORY relationship. */
  AddCategoryConcepts?: Maybe<_AddCategoryConceptsPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##add--remove-relationship) for [deleting](https://neo4j.com/docs/cypher-manual/4.1/clauses/delete/#delete-delete-relationships-only) the IN_CATEGORY relationship. */
  RemoveCategoryConcepts?: Maybe<_RemoveCategoryConceptsPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##merge-relationship) for [merging](https://neo4j.com/docs/cypher-manual/4.1/clauses/merge/#query-merge-relationships) the IN_CATEGORY relationship. */
  MergeCategoryConcepts?: Maybe<_MergeCategoryConceptsPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##add--remove-relationship) for [creating](https://neo4j.com/docs/cypher-manual/4.1/clauses/create/#create-relationships) the HAS_DEFAULT_CRITERIA relationship. */
  AddCategoryDefaultCriteria?: Maybe<_AddCategoryDefaultCriteriaPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##add--remove-relationship) for [deleting](https://neo4j.com/docs/cypher-manual/4.1/clauses/delete/#delete-delete-relationships-only) the HAS_DEFAULT_CRITERIA relationship. */
  RemoveCategoryDefaultCriteria?: Maybe<_RemoveCategoryDefaultCriteriaPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##merge-relationship) for [merging](https://neo4j.com/docs/cypher-manual/4.1/clauses/merge/#query-merge-relationships) the HAS_DEFAULT_CRITERIA relationship. */
  MergeCategoryDefaultCriteria?: Maybe<_MergeCategoryDefaultCriteriaPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##add--remove-relationship) for [creating](https://neo4j.com/docs/cypher-manual/4.1/clauses/create/#create-relationships) the CATEGORY_USES_CRITERIA relationship. */
  AddCategoryCriteria?: Maybe<_AddCategoryCriteriaPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##add--remove-relationship) for [deleting](https://neo4j.com/docs/cypher-manual/4.1/clauses/delete/#delete-delete-relationships-only) the CATEGORY_USES_CRITERIA relationship. */
  RemoveCategoryCriteria?: Maybe<_RemoveCategoryCriteriaPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##merge-relationship) for [merging](https://neo4j.com/docs/cypher-manual/4.1/clauses/merge/#query-merge-relationships) the CATEGORY_USES_CRITERIA relationship. */
  MergeCategoryCriteria?: Maybe<_MergeCategoryCriteriaPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##add--remove-relationship) for [creating](https://neo4j.com/docs/cypher-manual/4.1/clauses/create/#create-relationships) the USES_DEFAULT_DASHBOARD relationship. */
  AddCategoryDefaultDashboard?: Maybe<_AddCategoryDefaultDashboardPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##add--remove-relationship) for [deleting](https://neo4j.com/docs/cypher-manual/4.1/clauses/delete/#delete-delete-relationships-only) the USES_DEFAULT_DASHBOARD relationship. */
  RemoveCategoryDefaultDashboard?: Maybe<_RemoveCategoryDefaultDashboardPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##merge-relationship) for [merging](https://neo4j.com/docs/cypher-manual/4.1/clauses/merge/#query-merge-relationships) the USES_DEFAULT_DASHBOARD relationship. */
  MergeCategoryDefaultDashboard?: Maybe<_MergeCategoryDefaultDashboardPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##add--remove-relationship) for [creating](https://neo4j.com/docs/cypher-manual/4.1/clauses/create/#create-relationships) the DEFAULT_WORKFLOW_FOR_CATEGORY relationship. */
  AddCategoryWorkflow?: Maybe<_AddCategoryWorkflowPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##add--remove-relationship) for [deleting](https://neo4j.com/docs/cypher-manual/4.1/clauses/delete/#delete-delete-relationships-only) the DEFAULT_WORKFLOW_FOR_CATEGORY relationship. */
  RemoveCategoryWorkflow?: Maybe<_RemoveCategoryWorkflowPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##merge-relationship) for [merging](https://neo4j.com/docs/cypher-manual/4.1/clauses/merge/#query-merge-relationships) the DEFAULT_WORKFLOW_FOR_CATEGORY relationship. */
  MergeCategoryWorkflow?: Maybe<_MergeCategoryWorkflowPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/#create) for [creating](https://neo4j.com/docs/cypher-manual/4.1/clauses/create/#create-nodes) a Category node. */
  CreateCategory?: Maybe<Category>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/#update) for [updating](https://neo4j.com/docs/cypher-manual/4.1/clauses/set/#set-update-a-property) a Category node. */
  UpdateCategory?: Maybe<Category>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/#delete) for [deleting](https://neo4j.com/docs/cypher-manual/4.1/clauses/delete/#delete-delete-single-node) a Category node. */
  DeleteCategory?: Maybe<Category>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/#merge) for [merging](https://neo4j.com/docs/cypher-manual/4.1/clauses/merge/#query-merge-node-derived) a Category node. */
  MergeCategory?: Maybe<Category>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##add--remove-relationship) for [creating](https://neo4j.com/docs/cypher-manual/4.1/clauses/create/#create-relationships) the INPUT_USES_CRITERIA relationship. */
  AddInputCriteria?: Maybe<_AddInputCriteriaPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##add--remove-relationship) for [deleting](https://neo4j.com/docs/cypher-manual/4.1/clauses/delete/#delete-delete-relationships-only) the INPUT_USES_CRITERIA relationship. */
  RemoveInputCriteria?: Maybe<_RemoveInputCriteriaPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##merge-relationship) for [merging](https://neo4j.com/docs/cypher-manual/4.1/clauses/merge/#query-merge-relationships) the INPUT_USES_CRITERIA relationship. */
  MergeInputCriteria?: Maybe<_MergeInputCriteriaPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##add--remove-relationship) for [creating](https://neo4j.com/docs/cypher-manual/4.1/clauses/create/#create-relationships) the INPUT_FOR_CONCEPT relationship. */
  AddInputParentConcept?: Maybe<_AddInputParentConceptPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##add--remove-relationship) for [deleting](https://neo4j.com/docs/cypher-manual/4.1/clauses/delete/#delete-delete-relationships-only) the INPUT_FOR_CONCEPT relationship. */
  RemoveInputParentConcept?: Maybe<_RemoveInputParentConceptPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##merge-relationship) for [merging](https://neo4j.com/docs/cypher-manual/4.1/clauses/merge/#query-merge-relationships) the INPUT_FOR_CONCEPT relationship. */
  MergeInputParentConcept?: Maybe<_MergeInputParentConceptPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##add--remove-relationship) for [creating](https://neo4j.com/docs/cypher-manual/4.1/clauses/create/#create-relationships) the INPUT_INCLUDES_CONCEPT relationship. */
  AddInputIncludedConcepts?: Maybe<_AddInputIncludedConceptsPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##add--remove-relationship) for [deleting](https://neo4j.com/docs/cypher-manual/4.1/clauses/delete/#delete-delete-relationships-only) the INPUT_INCLUDES_CONCEPT relationship. */
  RemoveInputIncludedConcepts?: Maybe<_RemoveInputIncludedConceptsPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##merge-relationship) for [merging](https://neo4j.com/docs/cypher-manual/4.1/clauses/merge/#query-merge-relationships) the INPUT_INCLUDES_CONCEPT relationship. */
  MergeInputIncludedConcepts?: Maybe<_MergeInputIncludedConceptsPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##add--remove-relationship) for [creating](https://neo4j.com/docs/cypher-manual/4.1/clauses/create/#create-relationships) the CREATED_VIA_INPUT relationship. */
  AddInputConceptsCreated?: Maybe<_AddInputConceptsCreatedPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##add--remove-relationship) for [deleting](https://neo4j.com/docs/cypher-manual/4.1/clauses/delete/#delete-delete-relationships-only) the CREATED_VIA_INPUT relationship. */
  RemoveInputConceptsCreated?: Maybe<_RemoveInputConceptsCreatedPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##merge-relationship) for [merging](https://neo4j.com/docs/cypher-manual/4.1/clauses/merge/#query-merge-relationships) the CREATED_VIA_INPUT relationship. */
  MergeInputConceptsCreated?: Maybe<_MergeInputConceptsCreatedPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##add--remove-relationship) for [creating](https://neo4j.com/docs/cypher-manual/4.1/clauses/create/#create-relationships) the INPUT_HAS_CRITERIA_SCORE relationship. */
  AddInputCriteriaScores?: Maybe<_AddInputCriteriaScoresPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##add--remove-relationship) for [deleting](https://neo4j.com/docs/cypher-manual/4.1/clauses/delete/#delete-delete-relationships-only) the INPUT_HAS_CRITERIA_SCORE relationship. */
  RemoveInputCriteriaScores?: Maybe<_RemoveInputCriteriaScoresPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##merge-relationship) for [merging](https://neo4j.com/docs/cypher-manual/4.1/clauses/merge/#query-merge-relationships) the INPUT_HAS_CRITERIA_SCORE relationship. */
  MergeInputCriteriaScores?: Maybe<_MergeInputCriteriaScoresPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/#create) for [creating](https://neo4j.com/docs/cypher-manual/4.1/clauses/create/#create-nodes) a Input node. */
  CreateInput?: Maybe<Input>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/#update) for [updating](https://neo4j.com/docs/cypher-manual/4.1/clauses/set/#set-update-a-property) a Input node. */
  UpdateInput?: Maybe<Input>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/#delete) for [deleting](https://neo4j.com/docs/cypher-manual/4.1/clauses/delete/#delete-delete-single-node) a Input node. */
  DeleteInput?: Maybe<Input>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/#merge) for [merging](https://neo4j.com/docs/cypher-manual/4.1/clauses/merge/#query-merge-node-derived) a Input node. */
  MergeInput?: Maybe<Input>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##add--remove-relationship) for [creating](https://neo4j.com/docs/cypher-manual/4.1/clauses/create/#create-relationships) the INCLUDES_CRITERIA relationship. */
  AddExerciseCriteria?: Maybe<_AddExerciseCriteriaPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##add--remove-relationship) for [deleting](https://neo4j.com/docs/cypher-manual/4.1/clauses/delete/#delete-delete-relationships-only) the INCLUDES_CRITERIA relationship. */
  RemoveExerciseCriteria?: Maybe<_RemoveExerciseCriteriaPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##merge-relationship) for [merging](https://neo4j.com/docs/cypher-manual/4.1/clauses/merge/#query-merge-relationships) the INCLUDES_CRITERIA relationship. */
  MergeExerciseCriteria?: Maybe<_MergeExerciseCriteriaPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##add--remove-relationship) for [creating](https://neo4j.com/docs/cypher-manual/4.1/clauses/create/#create-relationships) the EXERCISE_TYPE_OF relationship. */
  AddExerciseType?: Maybe<_AddExerciseTypePayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##add--remove-relationship) for [deleting](https://neo4j.com/docs/cypher-manual/4.1/clauses/delete/#delete-delete-relationships-only) the EXERCISE_TYPE_OF relationship. */
  RemoveExerciseType?: Maybe<_RemoveExerciseTypePayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##merge-relationship) for [merging](https://neo4j.com/docs/cypher-manual/4.1/clauses/merge/#query-merge-relationships) the EXERCISE_TYPE_OF relationship. */
  MergeExerciseType?: Maybe<_MergeExerciseTypePayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##add--remove-relationship) for [creating](https://neo4j.com/docs/cypher-manual/4.1/clauses/create/#create-relationships) the EXERCISE_FACILITATED_BY relationship. */
  AddExerciseFacilitators?: Maybe<_AddExerciseFacilitatorsPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##add--remove-relationship) for [deleting](https://neo4j.com/docs/cypher-manual/4.1/clauses/delete/#delete-delete-relationships-only) the EXERCISE_FACILITATED_BY relationship. */
  RemoveExerciseFacilitators?: Maybe<_RemoveExerciseFacilitatorsPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##merge-relationship) for [merging](https://neo4j.com/docs/cypher-manual/4.1/clauses/merge/#query-merge-relationships) the EXERCISE_FACILITATED_BY relationship. */
  MergeExerciseFacilitators?: Maybe<_MergeExerciseFacilitatorsPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##add--remove-relationship) for [creating](https://neo4j.com/docs/cypher-manual/4.1/clauses/create/#create-relationships) the EXERCISE_INVITE_SENT_TO_TEAM relationship. */
  AddExerciseInvitedTeams?: Maybe<_AddExerciseInvitedTeamsPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##add--remove-relationship) for [deleting](https://neo4j.com/docs/cypher-manual/4.1/clauses/delete/#delete-delete-relationships-only) the EXERCISE_INVITE_SENT_TO_TEAM relationship. */
  RemoveExerciseInvitedTeams?: Maybe<_RemoveExerciseInvitedTeamsPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##merge-relationship) for [merging](https://neo4j.com/docs/cypher-manual/4.1/clauses/merge/#query-merge-relationships) the EXERCISE_INVITE_SENT_TO_TEAM relationship. */
  MergeExerciseInvitedTeams?: Maybe<_MergeExerciseInvitedTeamsPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##add--remove-relationship) for [creating](https://neo4j.com/docs/cypher-manual/4.1/clauses/create/#create-relationships) the EXERCISE_INVITE_SENT_TO_USER relationship. */
  AddExerciseInvitedUsers?: Maybe<_AddExerciseInvitedUsersPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##add--remove-relationship) for [deleting](https://neo4j.com/docs/cypher-manual/4.1/clauses/delete/#delete-delete-relationships-only) the EXERCISE_INVITE_SENT_TO_USER relationship. */
  RemoveExerciseInvitedUsers?: Maybe<_RemoveExerciseInvitedUsersPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##merge-relationship) for [merging](https://neo4j.com/docs/cypher-manual/4.1/clauses/merge/#query-merge-relationships) the EXERCISE_INVITE_SENT_TO_USER relationship. */
  MergeExerciseInvitedUsers?: Maybe<_MergeExerciseInvitedUsersPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##add--remove-relationship) for [creating](https://neo4j.com/docs/cypher-manual/4.1/clauses/create/#create-relationships) the EXERCISE_COMPLETED_BY relationship. */
  AddExerciseParticipants?: Maybe<_AddExerciseParticipantsPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##add--remove-relationship) for [deleting](https://neo4j.com/docs/cypher-manual/4.1/clauses/delete/#delete-delete-relationships-only) the EXERCISE_COMPLETED_BY relationship. */
  RemoveExerciseParticipants?: Maybe<_RemoveExerciseParticipantsPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##merge-relationship) for [merging](https://neo4j.com/docs/cypher-manual/4.1/clauses/merge/#query-merge-relationships) the EXERCISE_COMPLETED_BY relationship. */
  MergeExerciseParticipants?: Maybe<_MergeExerciseParticipantsPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##add--remove-relationship) for [creating](https://neo4j.com/docs/cypher-manual/4.1/clauses/create/#create-relationships) the EXERCISE_CREATED_BY relationship. */
  AddExerciseCreatedBy?: Maybe<_AddExerciseCreatedByPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##add--remove-relationship) for [deleting](https://neo4j.com/docs/cypher-manual/4.1/clauses/delete/#delete-delete-relationships-only) the EXERCISE_CREATED_BY relationship. */
  RemoveExerciseCreatedBy?: Maybe<_RemoveExerciseCreatedByPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##merge-relationship) for [merging](https://neo4j.com/docs/cypher-manual/4.1/clauses/merge/#query-merge-relationships) the EXERCISE_CREATED_BY relationship. */
  MergeExerciseCreatedBy?: Maybe<_MergeExerciseCreatedByPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##add--remove-relationship) for [creating](https://neo4j.com/docs/cypher-manual/4.1/clauses/create/#create-relationships) the EXERCISE_SUBMISSION_TO relationship. */
  AddExerciseSubmissions?: Maybe<_AddExerciseSubmissionsPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##add--remove-relationship) for [deleting](https://neo4j.com/docs/cypher-manual/4.1/clauses/delete/#delete-delete-relationships-only) the EXERCISE_SUBMISSION_TO relationship. */
  RemoveExerciseSubmissions?: Maybe<_RemoveExerciseSubmissionsPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##merge-relationship) for [merging](https://neo4j.com/docs/cypher-manual/4.1/clauses/merge/#query-merge-relationships) the EXERCISE_SUBMISSION_TO relationship. */
  MergeExerciseSubmissions?: Maybe<_MergeExerciseSubmissionsPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##add--remove-relationship) for [creating](https://neo4j.com/docs/cypher-manual/4.1/clauses/create/#create-relationships) the EXERCISE_DONE_FOR relationship. */
  AddExerciseParentConcept?: Maybe<_AddExerciseParentConceptPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##add--remove-relationship) for [deleting](https://neo4j.com/docs/cypher-manual/4.1/clauses/delete/#delete-delete-relationships-only) the EXERCISE_DONE_FOR relationship. */
  RemoveExerciseParentConcept?: Maybe<_RemoveExerciseParentConceptPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##merge-relationship) for [merging](https://neo4j.com/docs/cypher-manual/4.1/clauses/merge/#query-merge-relationships) the EXERCISE_DONE_FOR relationship. */
  MergeExerciseParentConcept?: Maybe<_MergeExerciseParentConceptPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##add--remove-relationship) for [creating](https://neo4j.com/docs/cypher-manual/4.1/clauses/create/#create-relationships) the EXERCISE_INCLUDES_CHECKLIST relationship. */
  AddExerciseIncludedChecklist?: Maybe<_AddExerciseIncludedChecklistPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##add--remove-relationship) for [deleting](https://neo4j.com/docs/cypher-manual/4.1/clauses/delete/#delete-delete-relationships-only) the EXERCISE_INCLUDES_CHECKLIST relationship. */
  RemoveExerciseIncludedChecklist?: Maybe<_RemoveExerciseIncludedChecklistPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##merge-relationship) for [merging](https://neo4j.com/docs/cypher-manual/4.1/clauses/merge/#query-merge-relationships) the EXERCISE_INCLUDES_CHECKLIST relationship. */
  MergeExerciseIncludedChecklist?: Maybe<_MergeExerciseIncludedChecklistPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##add--remove-relationship) for [creating](https://neo4j.com/docs/cypher-manual/4.1/clauses/create/#create-relationships) the EXERCISE_INCLUDES_CONCEPT relationship. */
  AddExerciseIncludedConcepts?: Maybe<_AddExerciseIncludedConceptsPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##add--remove-relationship) for [deleting](https://neo4j.com/docs/cypher-manual/4.1/clauses/delete/#delete-delete-relationships-only) the EXERCISE_INCLUDES_CONCEPT relationship. */
  RemoveExerciseIncludedConcepts?: Maybe<_RemoveExerciseIncludedConceptsPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##merge-relationship) for [merging](https://neo4j.com/docs/cypher-manual/4.1/clauses/merge/#query-merge-relationships) the EXERCISE_INCLUDES_CONCEPT relationship. */
  MergeExerciseIncludedConcepts?: Maybe<_MergeExerciseIncludedConceptsPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##add--remove-relationship) for [creating](https://neo4j.com/docs/cypher-manual/4.1/clauses/create/#create-relationships) the SUBMISSION_SPARKED_CONCEPT relationship. */
  AddExerciseConceptsCreated?: Maybe<_AddExerciseConceptsCreatedPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##add--remove-relationship) for [deleting](https://neo4j.com/docs/cypher-manual/4.1/clauses/delete/#delete-delete-relationships-only) the SUBMISSION_SPARKED_CONCEPT relationship. */
  RemoveExerciseConceptsCreated?: Maybe<_RemoveExerciseConceptsCreatedPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##merge-relationship) for [merging](https://neo4j.com/docs/cypher-manual/4.1/clauses/merge/#query-merge-relationships) the SUBMISSION_SPARKED_CONCEPT relationship. */
  MergeExerciseConceptsCreated?: Maybe<_MergeExerciseConceptsCreatedPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##add--remove-relationship) for [creating](https://neo4j.com/docs/cypher-manual/4.1/clauses/create/#create-relationships) the EXERCISE_HAS_CRITERIA_SCORE relationship. */
  AddExerciseCriteriaScores?: Maybe<_AddExerciseCriteriaScoresPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##add--remove-relationship) for [deleting](https://neo4j.com/docs/cypher-manual/4.1/clauses/delete/#delete-delete-relationships-only) the EXERCISE_HAS_CRITERIA_SCORE relationship. */
  RemoveExerciseCriteriaScores?: Maybe<_RemoveExerciseCriteriaScoresPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##merge-relationship) for [merging](https://neo4j.com/docs/cypher-manual/4.1/clauses/merge/#query-merge-relationships) the EXERCISE_HAS_CRITERIA_SCORE relationship. */
  MergeExerciseCriteriaScores?: Maybe<_MergeExerciseCriteriaScoresPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/#create) for [creating](https://neo4j.com/docs/cypher-manual/4.1/clauses/create/#create-nodes) a Exercise node. */
  CreateExercise?: Maybe<Exercise>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/#update) for [updating](https://neo4j.com/docs/cypher-manual/4.1/clauses/set/#set-update-a-property) a Exercise node. */
  UpdateExercise?: Maybe<Exercise>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/#delete) for [deleting](https://neo4j.com/docs/cypher-manual/4.1/clauses/delete/#delete-delete-single-node) a Exercise node. */
  DeleteExercise?: Maybe<Exercise>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/#merge) for [merging](https://neo4j.com/docs/cypher-manual/4.1/clauses/merge/#query-merge-node-derived) a Exercise node. */
  MergeExercise?: Maybe<Exercise>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##add--remove-relationship) for [creating](https://neo4j.com/docs/cypher-manual/4.1/clauses/create/#create-relationships) the TYPE_INCLUDES_CRITERIA relationship. */
  AddExerciseTypeCriteria?: Maybe<_AddExerciseTypeCriteriaPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##add--remove-relationship) for [deleting](https://neo4j.com/docs/cypher-manual/4.1/clauses/delete/#delete-delete-relationships-only) the TYPE_INCLUDES_CRITERIA relationship. */
  RemoveExerciseTypeCriteria?: Maybe<_RemoveExerciseTypeCriteriaPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##merge-relationship) for [merging](https://neo4j.com/docs/cypher-manual/4.1/clauses/merge/#query-merge-relationships) the TYPE_INCLUDES_CRITERIA relationship. */
  MergeExerciseTypeCriteria?: Maybe<_MergeExerciseTypeCriteriaPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##add--remove-relationship) for [creating](https://neo4j.com/docs/cypher-manual/4.1/clauses/create/#create-relationships) the EXERCISE_TYPE_CREATED_BY relationship. */
  AddExerciseTypeCreatedBy?: Maybe<_AddExerciseTypeCreatedByPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##add--remove-relationship) for [deleting](https://neo4j.com/docs/cypher-manual/4.1/clauses/delete/#delete-delete-relationships-only) the EXERCISE_TYPE_CREATED_BY relationship. */
  RemoveExerciseTypeCreatedBy?: Maybe<_RemoveExerciseTypeCreatedByPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##merge-relationship) for [merging](https://neo4j.com/docs/cypher-manual/4.1/clauses/merge/#query-merge-relationships) the EXERCISE_TYPE_CREATED_BY relationship. */
  MergeExerciseTypeCreatedBy?: Maybe<_MergeExerciseTypeCreatedByPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##add--remove-relationship) for [creating](https://neo4j.com/docs/cypher-manual/4.1/clauses/create/#create-relationships) the EXERCISE_TYPE_OF relationship. */
  AddExerciseTypeExercises?: Maybe<_AddExerciseTypeExercisesPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##add--remove-relationship) for [deleting](https://neo4j.com/docs/cypher-manual/4.1/clauses/delete/#delete-delete-relationships-only) the EXERCISE_TYPE_OF relationship. */
  RemoveExerciseTypeExercises?: Maybe<_RemoveExerciseTypeExercisesPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##merge-relationship) for [merging](https://neo4j.com/docs/cypher-manual/4.1/clauses/merge/#query-merge-relationships) the EXERCISE_TYPE_OF relationship. */
  MergeExerciseTypeExercises?: Maybe<_MergeExerciseTypeExercisesPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/#create) for [creating](https://neo4j.com/docs/cypher-manual/4.1/clauses/create/#create-nodes) a ExerciseType node. */
  CreateExerciseType?: Maybe<ExerciseType>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/#update) for [updating](https://neo4j.com/docs/cypher-manual/4.1/clauses/set/#set-update-a-property) a ExerciseType node. */
  UpdateExerciseType?: Maybe<ExerciseType>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/#delete) for [deleting](https://neo4j.com/docs/cypher-manual/4.1/clauses/delete/#delete-delete-single-node) a ExerciseType node. */
  DeleteExerciseType?: Maybe<ExerciseType>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##add--remove-relationship) for [creating](https://neo4j.com/docs/cypher-manual/4.1/clauses/create/#create-relationships) the EXERCISE_SUBMISSION_TO relationship. */
  AddExerciseSubmissionExercise?: Maybe<_AddExerciseSubmissionExercisePayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##add--remove-relationship) for [deleting](https://neo4j.com/docs/cypher-manual/4.1/clauses/delete/#delete-delete-relationships-only) the EXERCISE_SUBMISSION_TO relationship. */
  RemoveExerciseSubmissionExercise?: Maybe<_RemoveExerciseSubmissionExercisePayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##merge-relationship) for [merging](https://neo4j.com/docs/cypher-manual/4.1/clauses/merge/#query-merge-relationships) the EXERCISE_SUBMISSION_TO relationship. */
  MergeExerciseSubmissionExercise?: Maybe<_MergeExerciseSubmissionExercisePayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##add--remove-relationship) for [creating](https://neo4j.com/docs/cypher-manual/4.1/clauses/create/#create-relationships) the EXERCISE_RESULT_SUBMITTED_BY relationship. */
  AddExerciseSubmissionUser?: Maybe<_AddExerciseSubmissionUserPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##add--remove-relationship) for [deleting](https://neo4j.com/docs/cypher-manual/4.1/clauses/delete/#delete-delete-relationships-only) the EXERCISE_RESULT_SUBMITTED_BY relationship. */
  RemoveExerciseSubmissionUser?: Maybe<_RemoveExerciseSubmissionUserPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##merge-relationship) for [merging](https://neo4j.com/docs/cypher-manual/4.1/clauses/merge/#query-merge-relationships) the EXERCISE_RESULT_SUBMITTED_BY relationship. */
  MergeExerciseSubmissionUser?: Maybe<_MergeExerciseSubmissionUserPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##add--remove-relationship) for [creating](https://neo4j.com/docs/cypher-manual/4.1/clauses/create/#create-relationships) the CONTAINS_SCORES relationship. */
  AddExerciseSubmissionScores?: Maybe<_AddExerciseSubmissionScoresPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##add--remove-relationship) for [deleting](https://neo4j.com/docs/cypher-manual/4.1/clauses/delete/#delete-delete-relationships-only) the CONTAINS_SCORES relationship. */
  RemoveExerciseSubmissionScores?: Maybe<_RemoveExerciseSubmissionScoresPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##merge-relationship) for [merging](https://neo4j.com/docs/cypher-manual/4.1/clauses/merge/#query-merge-relationships) the CONTAINS_SCORES relationship. */
  MergeExerciseSubmissionScores?: Maybe<_MergeExerciseSubmissionScoresPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##add--remove-relationship) for [creating](https://neo4j.com/docs/cypher-manual/4.1/clauses/create/#create-relationships) the SUBMISSION_SPARKED_CONCEPT relationship. */
  AddExerciseSubmissionConceptCreated?: Maybe<_AddExerciseSubmissionConceptCreatedPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##add--remove-relationship) for [deleting](https://neo4j.com/docs/cypher-manual/4.1/clauses/delete/#delete-delete-relationships-only) the SUBMISSION_SPARKED_CONCEPT relationship. */
  RemoveExerciseSubmissionConceptCreated?: Maybe<_RemoveExerciseSubmissionConceptCreatedPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##merge-relationship) for [merging](https://neo4j.com/docs/cypher-manual/4.1/clauses/merge/#query-merge-relationships) the SUBMISSION_SPARKED_CONCEPT relationship. */
  MergeExerciseSubmissionConceptCreated?: Maybe<_MergeExerciseSubmissionConceptCreatedPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##add--remove-relationship) for [creating](https://neo4j.com/docs/cypher-manual/4.1/clauses/create/#create-relationships) the HAS_CRITERIA_SCORE relationship. */
  AddExerciseSubmissionCriteriaScores?: Maybe<_AddExerciseSubmissionCriteriaScoresPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##add--remove-relationship) for [deleting](https://neo4j.com/docs/cypher-manual/4.1/clauses/delete/#delete-delete-relationships-only) the HAS_CRITERIA_SCORE relationship. */
  RemoveExerciseSubmissionCriteriaScores?: Maybe<_RemoveExerciseSubmissionCriteriaScoresPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##merge-relationship) for [merging](https://neo4j.com/docs/cypher-manual/4.1/clauses/merge/#query-merge-relationships) the HAS_CRITERIA_SCORE relationship. */
  MergeExerciseSubmissionCriteriaScores?: Maybe<_MergeExerciseSubmissionCriteriaScoresPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/#create) for [creating](https://neo4j.com/docs/cypher-manual/4.1/clauses/create/#create-nodes) a ExerciseSubmission node. */
  CreateExerciseSubmission?: Maybe<ExerciseSubmission>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/#update) for [updating](https://neo4j.com/docs/cypher-manual/4.1/clauses/set/#set-update-a-property) a ExerciseSubmission node. */
  UpdateExerciseSubmission?: Maybe<ExerciseSubmission>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/#delete) for [deleting](https://neo4j.com/docs/cypher-manual/4.1/clauses/delete/#delete-delete-single-node) a ExerciseSubmission node. */
  DeleteExerciseSubmission?: Maybe<ExerciseSubmission>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/#merge) for [merging](https://neo4j.com/docs/cypher-manual/4.1/clauses/merge/#query-merge-node-derived) a ExerciseSubmission node. */
  MergeExerciseSubmission?: Maybe<ExerciseSubmission>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##add--remove-relationship) for [creating](https://neo4j.com/docs/cypher-manual/4.1/clauses/create/#create-relationships) the CONTAINS_SCORES relationship. */
  AddExerciseScoreSubmission?: Maybe<_AddExerciseScoreSubmissionPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##add--remove-relationship) for [deleting](https://neo4j.com/docs/cypher-manual/4.1/clauses/delete/#delete-delete-relationships-only) the CONTAINS_SCORES relationship. */
  RemoveExerciseScoreSubmission?: Maybe<_RemoveExerciseScoreSubmissionPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##merge-relationship) for [merging](https://neo4j.com/docs/cypher-manual/4.1/clauses/merge/#query-merge-relationships) the CONTAINS_SCORES relationship. */
  MergeExerciseScoreSubmission?: Maybe<_MergeExerciseScoreSubmissionPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##add--remove-relationship) for [creating](https://neo4j.com/docs/cypher-manual/4.1/clauses/create/#create-relationships) the GIVEN_SCORE_OF relationship. */
  AddExerciseScoreConcept?: Maybe<_AddExerciseScoreConceptPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##add--remove-relationship) for [deleting](https://neo4j.com/docs/cypher-manual/4.1/clauses/delete/#delete-delete-relationships-only) the GIVEN_SCORE_OF relationship. */
  RemoveExerciseScoreConcept?: Maybe<_RemoveExerciseScoreConceptPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##merge-relationship) for [merging](https://neo4j.com/docs/cypher-manual/4.1/clauses/merge/#query-merge-relationships) the GIVEN_SCORE_OF relationship. */
  MergeExerciseScoreConcept?: Maybe<_MergeExerciseScoreConceptPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/#create) for [creating](https://neo4j.com/docs/cypher-manual/4.1/clauses/create/#create-nodes) a ExerciseScore node. */
  CreateExerciseScore?: Maybe<ExerciseScore>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/#update) for [updating](https://neo4j.com/docs/cypher-manual/4.1/clauses/set/#set-update-a-property) a ExerciseScore node. */
  UpdateExerciseScore?: Maybe<ExerciseScore>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/#delete) for [deleting](https://neo4j.com/docs/cypher-manual/4.1/clauses/delete/#delete-delete-single-node) a ExerciseScore node. */
  DeleteExerciseScore?: Maybe<ExerciseScore>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/#merge) for [merging](https://neo4j.com/docs/cypher-manual/4.1/clauses/merge/#query-merge-node-derived) a ExerciseScore node. */
  MergeExerciseScore?: Maybe<ExerciseScore>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##add--remove-relationship) for [creating](https://neo4j.com/docs/cypher-manual/4.1/clauses/create/#create-relationships) the SCORE_FOR_CRITERIA relationship. */
  AddCriteriaScores?: Maybe<_AddCriteriaScoresPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##add--remove-relationship) for [deleting](https://neo4j.com/docs/cypher-manual/4.1/clauses/delete/#delete-delete-relationships-only) the SCORE_FOR_CRITERIA relationship. */
  RemoveCriteriaScores?: Maybe<_RemoveCriteriaScoresPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##merge-relationship) for [merging](https://neo4j.com/docs/cypher-manual/4.1/clauses/merge/#query-merge-relationships) the SCORE_FOR_CRITERIA relationship. */
  MergeCriteriaScores?: Maybe<_MergeCriteriaScoresPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##add--remove-relationship) for [creating](https://neo4j.com/docs/cypher-manual/4.1/clauses/create/#create-relationships) the CHILD_OF_CRITERIA relationship. */
  AddCriteriaParent?: Maybe<_AddCriteriaParentPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##add--remove-relationship) for [deleting](https://neo4j.com/docs/cypher-manual/4.1/clauses/delete/#delete-delete-relationships-only) the CHILD_OF_CRITERIA relationship. */
  RemoveCriteriaParent?: Maybe<_RemoveCriteriaParentPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##merge-relationship) for [merging](https://neo4j.com/docs/cypher-manual/4.1/clauses/merge/#query-merge-relationships) the CHILD_OF_CRITERIA relationship. */
  MergeCriteriaParent?: Maybe<_MergeCriteriaParentPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##add--remove-relationship) for [creating](https://neo4j.com/docs/cypher-manual/4.1/clauses/create/#create-relationships) the CHILD_OF_CRITERIA relationship. */
  AddCriteriaChildren?: Maybe<_AddCriteriaChildrenPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##add--remove-relationship) for [deleting](https://neo4j.com/docs/cypher-manual/4.1/clauses/delete/#delete-delete-relationships-only) the CHILD_OF_CRITERIA relationship. */
  RemoveCriteriaChildren?: Maybe<_RemoveCriteriaChildrenPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##merge-relationship) for [merging](https://neo4j.com/docs/cypher-manual/4.1/clauses/merge/#query-merge-relationships) the CHILD_OF_CRITERIA relationship. */
  MergeCriteriaChildren?: Maybe<_MergeCriteriaChildrenPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##add--remove-relationship) for [creating](https://neo4j.com/docs/cypher-manual/4.1/clauses/create/#create-relationships) the HAS_SUBFIELD relationship. */
  AddCriteriaParentFields?: Maybe<_AddCriteriaParentFieldsPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##add--remove-relationship) for [deleting](https://neo4j.com/docs/cypher-manual/4.1/clauses/delete/#delete-delete-relationships-only) the HAS_SUBFIELD relationship. */
  RemoveCriteriaParentFields?: Maybe<_RemoveCriteriaParentFieldsPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##merge-relationship) for [merging](https://neo4j.com/docs/cypher-manual/4.1/clauses/merge/#query-merge-relationships) the HAS_SUBFIELD relationship. */
  MergeCriteriaParentFields?: Maybe<_MergeCriteriaParentFieldsPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##add--remove-relationship) for [creating](https://neo4j.com/docs/cypher-manual/4.1/clauses/create/#create-relationships) the HAS_SUBFIELD relationship. */
  AddCriteriaSubfields?: Maybe<_AddCriteriaSubfieldsPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##add--remove-relationship) for [deleting](https://neo4j.com/docs/cypher-manual/4.1/clauses/delete/#delete-delete-relationships-only) the HAS_SUBFIELD relationship. */
  RemoveCriteriaSubfields?: Maybe<_RemoveCriteriaSubfieldsPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##merge-relationship) for [merging](https://neo4j.com/docs/cypher-manual/4.1/clauses/merge/#query-merge-relationships) the HAS_SUBFIELD relationship. */
  MergeCriteriaSubfields?: Maybe<_MergeCriteriaSubfieldsPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##add--remove-relationship) for [creating](https://neo4j.com/docs/cypher-manual/4.1/clauses/create/#create-relationships) the HAS_CRITERIA relationship. */
  AddCriteriaConcept?: Maybe<_AddCriteriaConceptPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##add--remove-relationship) for [deleting](https://neo4j.com/docs/cypher-manual/4.1/clauses/delete/#delete-delete-relationships-only) the HAS_CRITERIA relationship. */
  RemoveCriteriaConcept?: Maybe<_RemoveCriteriaConceptPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##merge-relationship) for [merging](https://neo4j.com/docs/cypher-manual/4.1/clauses/merge/#query-merge-relationships) the HAS_CRITERIA relationship. */
  MergeCriteriaConcept?: Maybe<_MergeCriteriaConceptPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##add--remove-relationship) for [creating](https://neo4j.com/docs/cypher-manual/4.1/clauses/create/#create-relationships) the HAS_DEFAULT_CRITERIA relationship. */
  AddCriteriaCategory?: Maybe<_AddCriteriaCategoryPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##add--remove-relationship) for [deleting](https://neo4j.com/docs/cypher-manual/4.1/clauses/delete/#delete-delete-relationships-only) the HAS_DEFAULT_CRITERIA relationship. */
  RemoveCriteriaCategory?: Maybe<_RemoveCriteriaCategoryPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##merge-relationship) for [merging](https://neo4j.com/docs/cypher-manual/4.1/clauses/merge/#query-merge-relationships) the HAS_DEFAULT_CRITERIA relationship. */
  MergeCriteriaCategory?: Maybe<_MergeCriteriaCategoryPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##add--remove-relationship) for [creating](https://neo4j.com/docs/cypher-manual/4.1/clauses/create/#create-relationships) the CATEGORY_USES_CRITERIA relationship. */
  AddCriteriaCategories?: Maybe<_AddCriteriaCategoriesPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##add--remove-relationship) for [deleting](https://neo4j.com/docs/cypher-manual/4.1/clauses/delete/#delete-delete-relationships-only) the CATEGORY_USES_CRITERIA relationship. */
  RemoveCriteriaCategories?: Maybe<_RemoveCriteriaCategoriesPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##merge-relationship) for [merging](https://neo4j.com/docs/cypher-manual/4.1/clauses/merge/#query-merge-relationships) the CATEGORY_USES_CRITERIA relationship. */
  MergeCriteriaCategories?: Maybe<_MergeCriteriaCategoriesPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##add--remove-relationship) for [creating](https://neo4j.com/docs/cypher-manual/4.1/clauses/create/#create-relationships) the INCLUDES_CRITERIA relationship. */
  AddCriteriaExercise?: Maybe<_AddCriteriaExercisePayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##add--remove-relationship) for [deleting](https://neo4j.com/docs/cypher-manual/4.1/clauses/delete/#delete-delete-relationships-only) the INCLUDES_CRITERIA relationship. */
  RemoveCriteriaExercise?: Maybe<_RemoveCriteriaExercisePayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##merge-relationship) for [merging](https://neo4j.com/docs/cypher-manual/4.1/clauses/merge/#query-merge-relationships) the INCLUDES_CRITERIA relationship. */
  MergeCriteriaExercise?: Maybe<_MergeCriteriaExercisePayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##add--remove-relationship) for [creating](https://neo4j.com/docs/cypher-manual/4.1/clauses/create/#create-relationships) the TYPE_INCLUDES_CRITERIA relationship. */
  AddCriteriaExerciseTypes?: Maybe<_AddCriteriaExerciseTypesPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##add--remove-relationship) for [deleting](https://neo4j.com/docs/cypher-manual/4.1/clauses/delete/#delete-delete-relationships-only) the TYPE_INCLUDES_CRITERIA relationship. */
  RemoveCriteriaExerciseTypes?: Maybe<_RemoveCriteriaExerciseTypesPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##merge-relationship) for [merging](https://neo4j.com/docs/cypher-manual/4.1/clauses/merge/#query-merge-relationships) the TYPE_INCLUDES_CRITERIA relationship. */
  MergeCriteriaExerciseTypes?: Maybe<_MergeCriteriaExerciseTypesPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##add--remove-relationship) for [creating](https://neo4j.com/docs/cypher-manual/4.1/clauses/create/#create-relationships) the INPUT_USES_CRITERIA relationship. */
  AddCriteriaInputs?: Maybe<_AddCriteriaInputsPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##add--remove-relationship) for [deleting](https://neo4j.com/docs/cypher-manual/4.1/clauses/delete/#delete-delete-relationships-only) the INPUT_USES_CRITERIA relationship. */
  RemoveCriteriaInputs?: Maybe<_RemoveCriteriaInputsPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##merge-relationship) for [merging](https://neo4j.com/docs/cypher-manual/4.1/clauses/merge/#query-merge-relationships) the INPUT_USES_CRITERIA relationship. */
  MergeCriteriaInputs?: Maybe<_MergeCriteriaInputsPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/#create) for [creating](https://neo4j.com/docs/cypher-manual/4.1/clauses/create/#create-nodes) a Criteria node. */
  CreateCriteria?: Maybe<Criteria>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/#update) for [updating](https://neo4j.com/docs/cypher-manual/4.1/clauses/set/#set-update-a-property) a Criteria node. */
  UpdateCriteria?: Maybe<Criteria>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/#delete) for [deleting](https://neo4j.com/docs/cypher-manual/4.1/clauses/delete/#delete-delete-single-node) a Criteria node. */
  DeleteCriteria?: Maybe<Criteria>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/#merge) for [merging](https://neo4j.com/docs/cypher-manual/4.1/clauses/merge/#query-merge-node-derived) a Criteria node. */
  MergeCriteria?: Maybe<Criteria>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##add--remove-relationship) for [creating](https://neo4j.com/docs/cypher-manual/4.1/clauses/create/#create-relationships) the SCORED_BY relationship. */
  AddCriteriaScoreUser?: Maybe<_AddCriteriaScoreUserPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##add--remove-relationship) for [deleting](https://neo4j.com/docs/cypher-manual/4.1/clauses/delete/#delete-delete-relationships-only) the SCORED_BY relationship. */
  RemoveCriteriaScoreUser?: Maybe<_RemoveCriteriaScoreUserPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##merge-relationship) for [merging](https://neo4j.com/docs/cypher-manual/4.1/clauses/merge/#query-merge-relationships) the SCORED_BY relationship. */
  MergeCriteriaScoreUser?: Maybe<_MergeCriteriaScoreUserPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##add--remove-relationship) for [creating](https://neo4j.com/docs/cypher-manual/4.1/clauses/create/#create-relationships) the SCORE_FOR_CRITERIA relationship. */
  AddCriteriaScoreCriteria?: Maybe<_AddCriteriaScoreCriteriaPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##add--remove-relationship) for [deleting](https://neo4j.com/docs/cypher-manual/4.1/clauses/delete/#delete-delete-relationships-only) the SCORE_FOR_CRITERIA relationship. */
  RemoveCriteriaScoreCriteria?: Maybe<_RemoveCriteriaScoreCriteriaPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##merge-relationship) for [merging](https://neo4j.com/docs/cypher-manual/4.1/clauses/merge/#query-merge-relationships) the SCORE_FOR_CRITERIA relationship. */
  MergeCriteriaScoreCriteria?: Maybe<_MergeCriteriaScoreCriteriaPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##add--remove-relationship) for [creating](https://neo4j.com/docs/cypher-manual/4.1/clauses/create/#create-relationships) the SCORE_FOR_CONCEPT relationship. */
  AddCriteriaScoreScoredConcept?: Maybe<_AddCriteriaScoreScoredConceptPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##add--remove-relationship) for [deleting](https://neo4j.com/docs/cypher-manual/4.1/clauses/delete/#delete-delete-relationships-only) the SCORE_FOR_CONCEPT relationship. */
  RemoveCriteriaScoreScoredConcept?: Maybe<_RemoveCriteriaScoreScoredConceptPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##merge-relationship) for [merging](https://neo4j.com/docs/cypher-manual/4.1/clauses/merge/#query-merge-relationships) the SCORE_FOR_CONCEPT relationship. */
  MergeCriteriaScoreScoredConcept?: Maybe<_MergeCriteriaScoreScoredConceptPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##add--remove-relationship) for [creating](https://neo4j.com/docs/cypher-manual/4.1/clauses/create/#create-relationships) the CONCEPTS_IN_SCORE relationship. */
  AddCriteriaScoreConcepts?: Maybe<_AddCriteriaScoreConceptsPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##add--remove-relationship) for [deleting](https://neo4j.com/docs/cypher-manual/4.1/clauses/delete/#delete-delete-relationships-only) the CONCEPTS_IN_SCORE relationship. */
  RemoveCriteriaScoreConcepts?: Maybe<_RemoveCriteriaScoreConceptsPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##merge-relationship) for [merging](https://neo4j.com/docs/cypher-manual/4.1/clauses/merge/#query-merge-relationships) the CONCEPTS_IN_SCORE relationship. */
  MergeCriteriaScoreConcepts?: Maybe<_MergeCriteriaScoreConceptsPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##add--remove-relationship) for [creating](https://neo4j.com/docs/cypher-manual/4.1/clauses/create/#create-relationships) the HAS_CRITERIA_SCORE relationship. */
  AddCriteriaScoreExerciseSubmission?: Maybe<_AddCriteriaScoreExerciseSubmissionPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##add--remove-relationship) for [deleting](https://neo4j.com/docs/cypher-manual/4.1/clauses/delete/#delete-delete-relationships-only) the HAS_CRITERIA_SCORE relationship. */
  RemoveCriteriaScoreExerciseSubmission?: Maybe<_RemoveCriteriaScoreExerciseSubmissionPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##merge-relationship) for [merging](https://neo4j.com/docs/cypher-manual/4.1/clauses/merge/#query-merge-relationships) the HAS_CRITERIA_SCORE relationship. */
  MergeCriteriaScoreExerciseSubmission?: Maybe<_MergeCriteriaScoreExerciseSubmissionPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##add--remove-relationship) for [creating](https://neo4j.com/docs/cypher-manual/4.1/clauses/create/#create-relationships) the EXERCISE_HAS_CRITERIA_SCORE relationship. */
  AddCriteriaScoreExercise?: Maybe<_AddCriteriaScoreExercisePayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##add--remove-relationship) for [deleting](https://neo4j.com/docs/cypher-manual/4.1/clauses/delete/#delete-delete-relationships-only) the EXERCISE_HAS_CRITERIA_SCORE relationship. */
  RemoveCriteriaScoreExercise?: Maybe<_RemoveCriteriaScoreExercisePayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##merge-relationship) for [merging](https://neo4j.com/docs/cypher-manual/4.1/clauses/merge/#query-merge-relationships) the EXERCISE_HAS_CRITERIA_SCORE relationship. */
  MergeCriteriaScoreExercise?: Maybe<_MergeCriteriaScoreExercisePayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##add--remove-relationship) for [creating](https://neo4j.com/docs/cypher-manual/4.1/clauses/create/#create-relationships) the INPUT_HAS_CRITERIA_SCORE relationship. */
  AddCriteriaScoreInput?: Maybe<_AddCriteriaScoreInputPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##add--remove-relationship) for [deleting](https://neo4j.com/docs/cypher-manual/4.1/clauses/delete/#delete-delete-relationships-only) the INPUT_HAS_CRITERIA_SCORE relationship. */
  RemoveCriteriaScoreInput?: Maybe<_RemoveCriteriaScoreInputPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##merge-relationship) for [merging](https://neo4j.com/docs/cypher-manual/4.1/clauses/merge/#query-merge-relationships) the INPUT_HAS_CRITERIA_SCORE relationship. */
  MergeCriteriaScoreInput?: Maybe<_MergeCriteriaScoreInputPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/#create) for [creating](https://neo4j.com/docs/cypher-manual/4.1/clauses/create/#create-nodes) a CriteriaScore node. */
  CreateCriteriaScore?: Maybe<CriteriaScore>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/#update) for [updating](https://neo4j.com/docs/cypher-manual/4.1/clauses/set/#set-update-a-property) a CriteriaScore node. */
  UpdateCriteriaScore?: Maybe<CriteriaScore>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/#delete) for [deleting](https://neo4j.com/docs/cypher-manual/4.1/clauses/delete/#delete-delete-single-node) a CriteriaScore node. */
  DeleteCriteriaScore?: Maybe<CriteriaScore>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/#merge) for [merging](https://neo4j.com/docs/cypher-manual/4.1/clauses/merge/#query-merge-node-derived) a CriteriaScore node. */
  MergeCriteriaScore?: Maybe<CriteriaScore>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##add--remove-relationship) for [creating](https://neo4j.com/docs/cypher-manual/4.1/clauses/create/#create-relationships) the HAS_TRANSLATION relationship. */
  AddTranslationConcept?: Maybe<_AddTranslationConceptPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##add--remove-relationship) for [deleting](https://neo4j.com/docs/cypher-manual/4.1/clauses/delete/#delete-delete-relationships-only) the HAS_TRANSLATION relationship. */
  RemoveTranslationConcept?: Maybe<_RemoveTranslationConceptPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##merge-relationship) for [merging](https://neo4j.com/docs/cypher-manual/4.1/clauses/merge/#query-merge-relationships) the HAS_TRANSLATION relationship. */
  MergeTranslationConcept?: Maybe<_MergeTranslationConceptPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/#create) for [creating](https://neo4j.com/docs/cypher-manual/4.1/clauses/create/#create-nodes) a Translation node. */
  CreateTranslation?: Maybe<Translation>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/#update) for [updating](https://neo4j.com/docs/cypher-manual/4.1/clauses/set/#set-update-a-property) a Translation node. */
  UpdateTranslation?: Maybe<Translation>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/#delete) for [deleting](https://neo4j.com/docs/cypher-manual/4.1/clauses/delete/#delete-delete-single-node) a Translation node. */
  DeleteTranslation?: Maybe<Translation>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/#merge) for [merging](https://neo4j.com/docs/cypher-manual/4.1/clauses/merge/#query-merge-node-derived) a Translation node. */
  MergeTranslation?: Maybe<Translation>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##add--remove-relationship) for [creating](https://neo4j.com/docs/cypher-manual/4.1/clauses/create/#create-relationships) the WORKFLOW_STAGE relationship. */
  AddWorkflowStages?: Maybe<_AddWorkflowStagesPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##add--remove-relationship) for [deleting](https://neo4j.com/docs/cypher-manual/4.1/clauses/delete/#delete-delete-relationships-only) the WORKFLOW_STAGE relationship. */
  RemoveWorkflowStages?: Maybe<_RemoveWorkflowStagesPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##merge-relationship) for [merging](https://neo4j.com/docs/cypher-manual/4.1/clauses/merge/#query-merge-relationships) the WORKFLOW_STAGE relationship. */
  MergeWorkflowStages?: Maybe<_MergeWorkflowStagesPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##add--remove-relationship) for [creating](https://neo4j.com/docs/cypher-manual/4.1/clauses/create/#create-relationships) the DEFAULT_WORKFLOW_FOR_CATEGORY relationship. */
  AddWorkflowCategories?: Maybe<_AddWorkflowCategoriesPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##add--remove-relationship) for [deleting](https://neo4j.com/docs/cypher-manual/4.1/clauses/delete/#delete-delete-relationships-only) the DEFAULT_WORKFLOW_FOR_CATEGORY relationship. */
  RemoveWorkflowCategories?: Maybe<_RemoveWorkflowCategoriesPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##merge-relationship) for [merging](https://neo4j.com/docs/cypher-manual/4.1/clauses/merge/#query-merge-relationships) the DEFAULT_WORKFLOW_FOR_CATEGORY relationship. */
  MergeWorkflowCategories?: Maybe<_MergeWorkflowCategoriesPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/#create) for [creating](https://neo4j.com/docs/cypher-manual/4.1/clauses/create/#create-nodes) a Workflow node. */
  CreateWorkflow?: Maybe<Workflow>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/#update) for [updating](https://neo4j.com/docs/cypher-manual/4.1/clauses/set/#set-update-a-property) a Workflow node. */
  UpdateWorkflow?: Maybe<Workflow>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/#delete) for [deleting](https://neo4j.com/docs/cypher-manual/4.1/clauses/delete/#delete-delete-single-node) a Workflow node. */
  DeleteWorkflow?: Maybe<Workflow>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/#merge) for [merging](https://neo4j.com/docs/cypher-manual/4.1/clauses/merge/#query-merge-node-derived) a Workflow node. */
  MergeWorkflow?: Maybe<Workflow>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##add--remove-relationship) for [creating](https://neo4j.com/docs/cypher-manual/4.1/clauses/create/#create-relationships) the WORKFLOW_STAGE relationship. */
  AddWorkflowStageWorkflow?: Maybe<_AddWorkflowStageWorkflowPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##add--remove-relationship) for [deleting](https://neo4j.com/docs/cypher-manual/4.1/clauses/delete/#delete-delete-relationships-only) the WORKFLOW_STAGE relationship. */
  RemoveWorkflowStageWorkflow?: Maybe<_RemoveWorkflowStageWorkflowPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##merge-relationship) for [merging](https://neo4j.com/docs/cypher-manual/4.1/clauses/merge/#query-merge-relationships) the WORKFLOW_STAGE relationship. */
  MergeWorkflowStageWorkflow?: Maybe<_MergeWorkflowStageWorkflowPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##add--remove-relationship) for [creating](https://neo4j.com/docs/cypher-manual/4.1/clauses/create/#create-relationships) the HAS_WORKFLOW_STAGE relationship. */
  AddWorkflowStageStatuses?: Maybe<_AddWorkflowStageStatusesPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##add--remove-relationship) for [deleting](https://neo4j.com/docs/cypher-manual/4.1/clauses/delete/#delete-delete-relationships-only) the HAS_WORKFLOW_STAGE relationship. */
  RemoveWorkflowStageStatuses?: Maybe<_RemoveWorkflowStageStatusesPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##merge-relationship) for [merging](https://neo4j.com/docs/cypher-manual/4.1/clauses/merge/#query-merge-relationships) the HAS_WORKFLOW_STAGE relationship. */
  MergeWorkflowStageStatuses?: Maybe<_MergeWorkflowStageStatusesPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##add--remove-relationship) for [creating](https://neo4j.com/docs/cypher-manual/4.1/clauses/create/#create-relationships) the NEXT_STAGE relationship. */
  AddWorkflowStageLastOptions?: Maybe<_AddWorkflowStageLastOptionsPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##add--remove-relationship) for [deleting](https://neo4j.com/docs/cypher-manual/4.1/clauses/delete/#delete-delete-relationships-only) the NEXT_STAGE relationship. */
  RemoveWorkflowStageLastOptions?: Maybe<_RemoveWorkflowStageLastOptionsPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##merge-relationship) for [merging](https://neo4j.com/docs/cypher-manual/4.1/clauses/merge/#query-merge-relationships) the NEXT_STAGE relationship. */
  MergeWorkflowStageLastOptions?: Maybe<_MergeWorkflowStageLastOptionsPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##add--remove-relationship) for [creating](https://neo4j.com/docs/cypher-manual/4.1/clauses/create/#create-relationships) the NEXT_STAGE relationship. */
  AddWorkflowStageNextOptions?: Maybe<_AddWorkflowStageNextOptionsPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##add--remove-relationship) for [deleting](https://neo4j.com/docs/cypher-manual/4.1/clauses/delete/#delete-delete-relationships-only) the NEXT_STAGE relationship. */
  RemoveWorkflowStageNextOptions?: Maybe<_RemoveWorkflowStageNextOptionsPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##merge-relationship) for [merging](https://neo4j.com/docs/cypher-manual/4.1/clauses/merge/#query-merge-relationships) the NEXT_STAGE relationship. */
  MergeWorkflowStageNextOptions?: Maybe<_MergeWorkflowStageNextOptionsPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/#create) for [creating](https://neo4j.com/docs/cypher-manual/4.1/clauses/create/#create-nodes) a WorkflowStage node. */
  CreateWorkflowStage?: Maybe<WorkflowStage>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/#update) for [updating](https://neo4j.com/docs/cypher-manual/4.1/clauses/set/#set-update-a-property) a WorkflowStage node. */
  UpdateWorkflowStage?: Maybe<WorkflowStage>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/#delete) for [deleting](https://neo4j.com/docs/cypher-manual/4.1/clauses/delete/#delete-delete-single-node) a WorkflowStage node. */
  DeleteWorkflowStage?: Maybe<WorkflowStage>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/#merge) for [merging](https://neo4j.com/docs/cypher-manual/4.1/clauses/merge/#query-merge-node-derived) a WorkflowStage node. */
  MergeWorkflowStage?: Maybe<WorkflowStage>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##add--remove-relationship) for [creating](https://neo4j.com/docs/cypher-manual/4.1/clauses/create/#create-relationships) the HAS_WORKFLOW_STAGE relationship. */
  AddConceptStatusStage?: Maybe<_AddConceptStatusStagePayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##add--remove-relationship) for [deleting](https://neo4j.com/docs/cypher-manual/4.1/clauses/delete/#delete-delete-relationships-only) the HAS_WORKFLOW_STAGE relationship. */
  RemoveConceptStatusStage?: Maybe<_RemoveConceptStatusStagePayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##merge-relationship) for [merging](https://neo4j.com/docs/cypher-manual/4.1/clauses/merge/#query-merge-relationships) the HAS_WORKFLOW_STAGE relationship. */
  MergeConceptStatusStage?: Maybe<_MergeConceptStatusStagePayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##add--remove-relationship) for [creating](https://neo4j.com/docs/cypher-manual/4.1/clauses/create/#create-relationships) the HAS_STATUS relationship. */
  AddConceptStatusConcept?: Maybe<_AddConceptStatusConceptPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##add--remove-relationship) for [deleting](https://neo4j.com/docs/cypher-manual/4.1/clauses/delete/#delete-delete-relationships-only) the HAS_STATUS relationship. */
  RemoveConceptStatusConcept?: Maybe<_RemoveConceptStatusConceptPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##merge-relationship) for [merging](https://neo4j.com/docs/cypher-manual/4.1/clauses/merge/#query-merge-relationships) the HAS_STATUS relationship. */
  MergeConceptStatusConcept?: Maybe<_MergeConceptStatusConceptPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/#create) for [creating](https://neo4j.com/docs/cypher-manual/4.1/clauses/create/#create-nodes) a ConceptStatus node. */
  CreateConceptStatus?: Maybe<ConceptStatus>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/#update) for [updating](https://neo4j.com/docs/cypher-manual/4.1/clauses/set/#set-update-a-property) a ConceptStatus node. */
  UpdateConceptStatus?: Maybe<ConceptStatus>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/#delete) for [deleting](https://neo4j.com/docs/cypher-manual/4.1/clauses/delete/#delete-delete-single-node) a ConceptStatus node. */
  DeleteConceptStatus?: Maybe<ConceptStatus>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/#merge) for [merging](https://neo4j.com/docs/cypher-manual/4.1/clauses/merge/#query-merge-node-derived) a ConceptStatus node. */
  MergeConceptStatus?: Maybe<ConceptStatus>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##add--remove-relationship) for [creating](https://neo4j.com/docs/cypher-manual/4.1/clauses/create/#create-relationships) the HAS_TRANSLATION relationship. */
  AddConceptTranslations?: Maybe<_AddConceptTranslationsPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##add--remove-relationship) for [deleting](https://neo4j.com/docs/cypher-manual/4.1/clauses/delete/#delete-delete-relationships-only) the HAS_TRANSLATION relationship. */
  RemoveConceptTranslations?: Maybe<_RemoveConceptTranslationsPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##merge-relationship) for [merging](https://neo4j.com/docs/cypher-manual/4.1/clauses/merge/#query-merge-relationships) the HAS_TRANSLATION relationship. */
  MergeConceptTranslations?: Maybe<_MergeConceptTranslationsPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##add--remove-relationship) for [creating](https://neo4j.com/docs/cypher-manual/4.1/clauses/create/#create-relationships) the HAS_ACCESS relationship. */
  AddConceptAddedUsers?: Maybe<_AddConceptAddedUsersPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##add--remove-relationship) for [deleting](https://neo4j.com/docs/cypher-manual/4.1/clauses/delete/#delete-delete-relationships-only) the HAS_ACCESS relationship. */
  RemoveConceptAddedUsers?: Maybe<_RemoveConceptAddedUsersPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##update-relationship) for [updating](https://neo4j.com/docs/cypher-manual/4.1/clauses/set/#set-update-a-property) the HAS_ACCESS relationship. */
  UpdateConceptAddedUsers?: Maybe<_UpdateConceptAddedUsersPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##merge-relationship) for [merging](https://neo4j.com/docs/cypher-manual/4.1/clauses/merge/#query-merge-relationships) the HAS_ACCESS relationship. */
  MergeConceptAddedUsers?: Maybe<_MergeConceptAddedUsersPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##add--remove-relationship) for [creating](https://neo4j.com/docs/cypher-manual/4.1/clauses/create/#create-relationships) the HAS_ACCESS relationship. */
  AddConceptAddedTeams?: Maybe<_AddConceptAddedTeamsPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##add--remove-relationship) for [deleting](https://neo4j.com/docs/cypher-manual/4.1/clauses/delete/#delete-delete-relationships-only) the HAS_ACCESS relationship. */
  RemoveConceptAddedTeams?: Maybe<_RemoveConceptAddedTeamsPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##update-relationship) for [updating](https://neo4j.com/docs/cypher-manual/4.1/clauses/set/#set-update-a-property) the HAS_ACCESS relationship. */
  UpdateConceptAddedTeams?: Maybe<_UpdateConceptAddedTeamsPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##merge-relationship) for [merging](https://neo4j.com/docs/cypher-manual/4.1/clauses/merge/#query-merge-relationships) the HAS_ACCESS relationship. */
  MergeConceptAddedTeams?: Maybe<_MergeConceptAddedTeamsPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##add--remove-relationship) for [creating](https://neo4j.com/docs/cypher-manual/4.1/clauses/create/#create-relationships) the USER_HOME_CONCEPT relationship. */
  AddConceptUserHomepage?: Maybe<_AddConceptUserHomepagePayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##add--remove-relationship) for [deleting](https://neo4j.com/docs/cypher-manual/4.1/clauses/delete/#delete-delete-relationships-only) the USER_HOME_CONCEPT relationship. */
  RemoveConceptUserHomepage?: Maybe<_RemoveConceptUserHomepagePayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##merge-relationship) for [merging](https://neo4j.com/docs/cypher-manual/4.1/clauses/merge/#query-merge-relationships) the USER_HOME_CONCEPT relationship. */
  MergeConceptUserHomepage?: Maybe<_MergeConceptUserHomepagePayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##add--remove-relationship) for [creating](https://neo4j.com/docs/cypher-manual/4.1/clauses/create/#create-relationships) the TEAM_HOME_CONCEPT relationship. */
  AddConceptTeamHomepage?: Maybe<_AddConceptTeamHomepagePayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##add--remove-relationship) for [deleting](https://neo4j.com/docs/cypher-manual/4.1/clauses/delete/#delete-delete-relationships-only) the TEAM_HOME_CONCEPT relationship. */
  RemoveConceptTeamHomepage?: Maybe<_RemoveConceptTeamHomepagePayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##merge-relationship) for [merging](https://neo4j.com/docs/cypher-manual/4.1/clauses/merge/#query-merge-relationships) the TEAM_HOME_CONCEPT relationship. */
  MergeConceptTeamHomepage?: Maybe<_MergeConceptTeamHomepagePayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##add--remove-relationship) for [creating](https://neo4j.com/docs/cypher-manual/4.1/clauses/create/#create-relationships) the IMPACTS_INDUSTRY relationship. */
  AddConceptIndustries?: Maybe<_AddConceptIndustriesPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##add--remove-relationship) for [deleting](https://neo4j.com/docs/cypher-manual/4.1/clauses/delete/#delete-delete-relationships-only) the IMPACTS_INDUSTRY relationship. */
  RemoveConceptIndustries?: Maybe<_RemoveConceptIndustriesPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##merge-relationship) for [merging](https://neo4j.com/docs/cypher-manual/4.1/clauses/merge/#query-merge-relationships) the IMPACTS_INDUSTRY relationship. */
  MergeConceptIndustries?: Maybe<_MergeConceptIndustriesPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##add--remove-relationship) for [creating](https://neo4j.com/docs/cypher-manual/4.1/clauses/create/#create-relationships) the UPDATED relationship. */
  AddConceptRecentUpdates?: Maybe<_AddConceptRecentUpdatesPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##add--remove-relationship) for [deleting](https://neo4j.com/docs/cypher-manual/4.1/clauses/delete/#delete-delete-relationships-only) the UPDATED relationship. */
  RemoveConceptRecentUpdates?: Maybe<_RemoveConceptRecentUpdatesPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##update-relationship) for [updating](https://neo4j.com/docs/cypher-manual/4.1/clauses/set/#set-update-a-property) the UPDATED relationship. */
  UpdateConceptRecentUpdates?: Maybe<_UpdateConceptRecentUpdatesPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##merge-relationship) for [merging](https://neo4j.com/docs/cypher-manual/4.1/clauses/merge/#query-merge-relationships) the UPDATED relationship. */
  MergeConceptRecentUpdates?: Maybe<_MergeConceptRecentUpdatesPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##add--remove-relationship) for [creating](https://neo4j.com/docs/cypher-manual/4.1/clauses/create/#create-relationships) the CREATED relationship. */
  AddConceptCreatedBy?: Maybe<_AddConceptCreatedByPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##add--remove-relationship) for [deleting](https://neo4j.com/docs/cypher-manual/4.1/clauses/delete/#delete-delete-relationships-only) the CREATED relationship. */
  RemoveConceptCreatedBy?: Maybe<_RemoveConceptCreatedByPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##merge-relationship) for [merging](https://neo4j.com/docs/cypher-manual/4.1/clauses/merge/#query-merge-relationships) the CREATED relationship. */
  MergeConceptCreatedBy?: Maybe<_MergeConceptCreatedByPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##add--remove-relationship) for [creating](https://neo4j.com/docs/cypher-manual/4.1/clauses/create/#create-relationships) the HAS_STATUS relationship. */
  AddConceptStatus?: Maybe<_AddConceptStatusPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##add--remove-relationship) for [deleting](https://neo4j.com/docs/cypher-manual/4.1/clauses/delete/#delete-delete-relationships-only) the HAS_STATUS relationship. */
  RemoveConceptStatus?: Maybe<_RemoveConceptStatusPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##add--remove-relationship) for [creating](https://neo4j.com/docs/cypher-manual/4.1/clauses/create/#create-relationships) the CHILD_OF relationship. */
  AddConceptParent?: Maybe<_AddConceptParentPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##add--remove-relationship) for [deleting](https://neo4j.com/docs/cypher-manual/4.1/clauses/delete/#delete-delete-relationships-only) the CHILD_OF relationship. */
  RemoveConceptParent?: Maybe<_RemoveConceptParentPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##merge-relationship) for [merging](https://neo4j.com/docs/cypher-manual/4.1/clauses/merge/#query-merge-relationships) the CHILD_OF relationship. */
  MergeConceptParent?: Maybe<_MergeConceptParentPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##add--remove-relationship) for [creating](https://neo4j.com/docs/cypher-manual/4.1/clauses/create/#create-relationships) the CHILD_OF relationship. */
  AddConceptChildren?: Maybe<_AddConceptChildrenPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##add--remove-relationship) for [deleting](https://neo4j.com/docs/cypher-manual/4.1/clauses/delete/#delete-delete-relationships-only) the CHILD_OF relationship. */
  RemoveConceptChildren?: Maybe<_RemoveConceptChildrenPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##merge-relationship) for [merging](https://neo4j.com/docs/cypher-manual/4.1/clauses/merge/#query-merge-relationships) the CHILD_OF relationship. */
  MergeConceptChildren?: Maybe<_MergeConceptChildrenPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##add--remove-relationship) for [creating](https://neo4j.com/docs/cypher-manual/4.1/clauses/create/#create-relationships) the IN_CATEGORY relationship. */
  AddConceptCategory?: Maybe<_AddConceptCategoryPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##add--remove-relationship) for [deleting](https://neo4j.com/docs/cypher-manual/4.1/clauses/delete/#delete-delete-relationships-only) the IN_CATEGORY relationship. */
  RemoveConceptCategory?: Maybe<_RemoveConceptCategoryPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##merge-relationship) for [merging](https://neo4j.com/docs/cypher-manual/4.1/clauses/merge/#query-merge-relationships) the IN_CATEGORY relationship. */
  MergeConceptCategory?: Maybe<_MergeConceptCategoryPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##add--remove-relationship) for [creating](https://neo4j.com/docs/cypher-manual/4.1/clauses/create/#create-relationships) the HAS_WEB_LINK relationship. */
  AddConceptWebLink?: Maybe<_AddConceptWebLinkPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##add--remove-relationship) for [deleting](https://neo4j.com/docs/cypher-manual/4.1/clauses/delete/#delete-delete-relationships-only) the HAS_WEB_LINK relationship. */
  RemoveConceptWebLink?: Maybe<_RemoveConceptWebLinkPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##merge-relationship) for [merging](https://neo4j.com/docs/cypher-manual/4.1/clauses/merge/#query-merge-relationships) the HAS_WEB_LINK relationship. */
  MergeConceptWebLink?: Maybe<_MergeConceptWebLinkPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##add--remove-relationship) for [creating](https://neo4j.com/docs/cypher-manual/4.1/clauses/create/#create-relationships) the LIKED relationship. */
  AddConceptLikedBy?: Maybe<_AddConceptLikedByPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##add--remove-relationship) for [deleting](https://neo4j.com/docs/cypher-manual/4.1/clauses/delete/#delete-delete-relationships-only) the LIKED relationship. */
  RemoveConceptLikedBy?: Maybe<_RemoveConceptLikedByPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##merge-relationship) for [merging](https://neo4j.com/docs/cypher-manual/4.1/clauses/merge/#query-merge-relationships) the LIKED relationship. */
  MergeConceptLikedBy?: Maybe<_MergeConceptLikedByPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##add--remove-relationship) for [creating](https://neo4j.com/docs/cypher-manual/4.1/clauses/create/#create-relationships) the HAS_CRITERIA relationship. */
  AddConceptCriteria?: Maybe<_AddConceptCriteriaPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##add--remove-relationship) for [deleting](https://neo4j.com/docs/cypher-manual/4.1/clauses/delete/#delete-delete-relationships-only) the HAS_CRITERIA relationship. */
  RemoveConceptCriteria?: Maybe<_RemoveConceptCriteriaPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##merge-relationship) for [merging](https://neo4j.com/docs/cypher-manual/4.1/clauses/merge/#query-merge-relationships) the HAS_CRITERIA relationship. */
  MergeConceptCriteria?: Maybe<_MergeConceptCriteriaPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##add--remove-relationship) for [creating](https://neo4j.com/docs/cypher-manual/4.1/clauses/create/#create-relationships) the SCORE_FOR_CONCEPT relationship. */
  AddConceptCriteriaScores?: Maybe<_AddConceptCriteriaScoresPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##add--remove-relationship) for [deleting](https://neo4j.com/docs/cypher-manual/4.1/clauses/delete/#delete-delete-relationships-only) the SCORE_FOR_CONCEPT relationship. */
  RemoveConceptCriteriaScores?: Maybe<_RemoveConceptCriteriaScoresPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##merge-relationship) for [merging](https://neo4j.com/docs/cypher-manual/4.1/clauses/merge/#query-merge-relationships) the SCORE_FOR_CONCEPT relationship. */
  MergeConceptCriteriaScores?: Maybe<_MergeConceptCriteriaScoresPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##add--remove-relationship) for [creating](https://neo4j.com/docs/cypher-manual/4.1/clauses/create/#create-relationships) the HAS_HOME_DASHBOARD relationship. */
  AddConceptHomeDashboard?: Maybe<_AddConceptHomeDashboardPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##add--remove-relationship) for [deleting](https://neo4j.com/docs/cypher-manual/4.1/clauses/delete/#delete-delete-relationships-only) the HAS_HOME_DASHBOARD relationship. */
  RemoveConceptHomeDashboard?: Maybe<_RemoveConceptHomeDashboardPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##merge-relationship) for [merging](https://neo4j.com/docs/cypher-manual/4.1/clauses/merge/#query-merge-relationships) the HAS_HOME_DASHBOARD relationship. */
  MergeConceptHomeDashboard?: Maybe<_MergeConceptHomeDashboardPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##add--remove-relationship) for [creating](https://neo4j.com/docs/cypher-manual/4.1/clauses/create/#create-relationships) the SAVED_DASHBOARD relationship. */
  AddConceptUsesDashboard?: Maybe<_AddConceptUsesDashboardPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##add--remove-relationship) for [deleting](https://neo4j.com/docs/cypher-manual/4.1/clauses/delete/#delete-delete-relationships-only) the SAVED_DASHBOARD relationship. */
  RemoveConceptUsesDashboard?: Maybe<_RemoveConceptUsesDashboardPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##merge-relationship) for [merging](https://neo4j.com/docs/cypher-manual/4.1/clauses/merge/#query-merge-relationships) the SAVED_DASHBOARD relationship. */
  MergeConceptUsesDashboard?: Maybe<_MergeConceptUsesDashboardPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##add--remove-relationship) for [creating](https://neo4j.com/docs/cypher-manual/4.1/clauses/create/#create-relationships) the COMMENT_ON relationship. */
  AddConceptComments?: Maybe<_AddConceptCommentsPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##add--remove-relationship) for [deleting](https://neo4j.com/docs/cypher-manual/4.1/clauses/delete/#delete-delete-relationships-only) the COMMENT_ON relationship. */
  RemoveConceptComments?: Maybe<_RemoveConceptCommentsPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##merge-relationship) for [merging](https://neo4j.com/docs/cypher-manual/4.1/clauses/merge/#query-merge-relationships) the COMMENT_ON relationship. */
  MergeConceptComments?: Maybe<_MergeConceptCommentsPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##add--remove-relationship) for [creating](https://neo4j.com/docs/cypher-manual/4.1/clauses/create/#create-relationships) the WATCHING relationship. */
  AddConceptWatchedBy?: Maybe<_AddConceptWatchedByPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##add--remove-relationship) for [deleting](https://neo4j.com/docs/cypher-manual/4.1/clauses/delete/#delete-delete-relationships-only) the WATCHING relationship. */
  RemoveConceptWatchedBy?: Maybe<_RemoveConceptWatchedByPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##merge-relationship) for [merging](https://neo4j.com/docs/cypher-manual/4.1/clauses/merge/#query-merge-relationships) the WATCHING relationship. */
  MergeConceptWatchedBy?: Maybe<_MergeConceptWatchedByPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##add--remove-relationship) for [creating](https://neo4j.com/docs/cypher-manual/4.1/clauses/create/#create-relationships) the CONCEPT_TRIGGERED_NOTIFICATION relationship. */
  AddConceptNotifications?: Maybe<_AddConceptNotificationsPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##add--remove-relationship) for [deleting](https://neo4j.com/docs/cypher-manual/4.1/clauses/delete/#delete-delete-relationships-only) the CONCEPT_TRIGGERED_NOTIFICATION relationship. */
  RemoveConceptNotifications?: Maybe<_RemoveConceptNotificationsPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##merge-relationship) for [merging](https://neo4j.com/docs/cypher-manual/4.1/clauses/merge/#query-merge-relationships) the CONCEPT_TRIGGERED_NOTIFICATION relationship. */
  MergeConceptNotifications?: Maybe<_MergeConceptNotificationsPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##add--remove-relationship) for [creating](https://neo4j.com/docs/cypher-manual/4.1/clauses/create/#create-relationships) the TAGGED relationship. */
  AddConceptTags?: Maybe<_AddConceptTagsPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##add--remove-relationship) for [deleting](https://neo4j.com/docs/cypher-manual/4.1/clauses/delete/#delete-delete-relationships-only) the TAGGED relationship. */
  RemoveConceptTags?: Maybe<_RemoveConceptTagsPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##merge-relationship) for [merging](https://neo4j.com/docs/cypher-manual/4.1/clauses/merge/#query-merge-relationships) the TAGGED relationship. */
  MergeConceptTags?: Maybe<_MergeConceptTagsPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##add--remove-relationship) for [creating](https://neo4j.com/docs/cypher-manual/4.1/clauses/create/#create-relationships) the CONNECTED relationship. */
  AddConceptConnections?: Maybe<_AddConceptConnectionsPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##add--remove-relationship) for [deleting](https://neo4j.com/docs/cypher-manual/4.1/clauses/delete/#delete-delete-relationships-only) the CONNECTED relationship. */
  RemoveConceptConnections?: Maybe<_RemoveConceptConnectionsPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##merge-relationship) for [merging](https://neo4j.com/docs/cypher-manual/4.1/clauses/merge/#query-merge-relationships) the CONNECTED relationship. */
  MergeConceptConnections?: Maybe<_MergeConceptConnectionsPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##add--remove-relationship) for [creating](https://neo4j.com/docs/cypher-manual/4.1/clauses/create/#create-relationships) the CONNECTED relationship. */
  AddConceptDetailedConnections?: Maybe<_AddConceptDetailedConnectionsPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##add--remove-relationship) for [deleting](https://neo4j.com/docs/cypher-manual/4.1/clauses/delete/#delete-delete-relationships-only) the CONNECTED relationship. */
  RemoveConceptDetailedConnections?: Maybe<_RemoveConceptDetailedConnectionsPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##update-relationship) for [updating](https://neo4j.com/docs/cypher-manual/4.1/clauses/set/#set-update-a-property) the CONNECTED relationship. */
  UpdateConceptDetailedConnections?: Maybe<_UpdateConceptDetailedConnectionsPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##merge-relationship) for [merging](https://neo4j.com/docs/cypher-manual/4.1/clauses/merge/#query-merge-relationships) the CONNECTED relationship. */
  MergeConceptDetailedConnections?: Maybe<_MergeConceptDetailedConnectionsPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##add--remove-relationship) for [creating](https://neo4j.com/docs/cypher-manual/4.1/clauses/create/#create-relationships) the TEAM_CONCEPT relationship. */
  AddConceptTeams?: Maybe<_AddConceptTeamsPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##add--remove-relationship) for [deleting](https://neo4j.com/docs/cypher-manual/4.1/clauses/delete/#delete-delete-relationships-only) the TEAM_CONCEPT relationship. */
  RemoveConceptTeams?: Maybe<_RemoveConceptTeamsPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##merge-relationship) for [merging](https://neo4j.com/docs/cypher-manual/4.1/clauses/merge/#query-merge-relationships) the TEAM_CONCEPT relationship. */
  MergeConceptTeams?: Maybe<_MergeConceptTeamsPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##add--remove-relationship) for [creating](https://neo4j.com/docs/cypher-manual/4.1/clauses/create/#create-relationships) the IN_LIST relationship. */
  AddConceptInLists?: Maybe<_AddConceptInListsPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##add--remove-relationship) for [deleting](https://neo4j.com/docs/cypher-manual/4.1/clauses/delete/#delete-delete-relationships-only) the IN_LIST relationship. */
  RemoveConceptInLists?: Maybe<_RemoveConceptInListsPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##merge-relationship) for [merging](https://neo4j.com/docs/cypher-manual/4.1/clauses/merge/#query-merge-relationships) the IN_LIST relationship. */
  MergeConceptInLists?: Maybe<_MergeConceptInListsPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##add--remove-relationship) for [creating](https://neo4j.com/docs/cypher-manual/4.1/clauses/create/#create-relationships) the HAS_CONCEPT_WORKSPACE relationship. */
  AddConceptWorkspace?: Maybe<_AddConceptWorkspacePayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##add--remove-relationship) for [deleting](https://neo4j.com/docs/cypher-manual/4.1/clauses/delete/#delete-delete-relationships-only) the HAS_CONCEPT_WORKSPACE relationship. */
  RemoveConceptWorkspace?: Maybe<_RemoveConceptWorkspacePayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##merge-relationship) for [merging](https://neo4j.com/docs/cypher-manual/4.1/clauses/merge/#query-merge-relationships) the HAS_CONCEPT_WORKSPACE relationship. */
  MergeConceptWorkspace?: Maybe<_MergeConceptWorkspacePayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##add--remove-relationship) for [creating](https://neo4j.com/docs/cypher-manual/4.1/clauses/create/#create-relationships) the CHILD_CONCEPT_LIST relationship. */
  AddConceptChildConceptLists?: Maybe<_AddConceptChildConceptListsPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##add--remove-relationship) for [deleting](https://neo4j.com/docs/cypher-manual/4.1/clauses/delete/#delete-delete-relationships-only) the CHILD_CONCEPT_LIST relationship. */
  RemoveConceptChildConceptLists?: Maybe<_RemoveConceptChildConceptListsPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##merge-relationship) for [merging](https://neo4j.com/docs/cypher-manual/4.1/clauses/merge/#query-merge-relationships) the CHILD_CONCEPT_LIST relationship. */
  MergeConceptChildConceptLists?: Maybe<_MergeConceptChildConceptListsPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##add--remove-relationship) for [creating](https://neo4j.com/docs/cypher-manual/4.1/clauses/create/#create-relationships) the CONCEPT_CHECKLIST relationship. */
  AddConceptChecklists?: Maybe<_AddConceptChecklistsPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##add--remove-relationship) for [deleting](https://neo4j.com/docs/cypher-manual/4.1/clauses/delete/#delete-delete-relationships-only) the CONCEPT_CHECKLIST relationship. */
  RemoveConceptChecklists?: Maybe<_RemoveConceptChecklistsPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##merge-relationship) for [merging](https://neo4j.com/docs/cypher-manual/4.1/clauses/merge/#query-merge-relationships) the CONCEPT_CHECKLIST relationship. */
  MergeConceptChecklists?: Maybe<_MergeConceptChecklistsPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##add--remove-relationship) for [creating](https://neo4j.com/docs/cypher-manual/4.1/clauses/create/#create-relationships) the CL_ITEM_LINKED_CONCEPT relationship. */
  AddConceptChecklistItemLink?: Maybe<_AddConceptChecklistItemLinkPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##add--remove-relationship) for [deleting](https://neo4j.com/docs/cypher-manual/4.1/clauses/delete/#delete-delete-relationships-only) the CL_ITEM_LINKED_CONCEPT relationship. */
  RemoveConceptChecklistItemLink?: Maybe<_RemoveConceptChecklistItemLinkPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##merge-relationship) for [merging](https://neo4j.com/docs/cypher-manual/4.1/clauses/merge/#query-merge-relationships) the CL_ITEM_LINKED_CONCEPT relationship. */
  MergeConceptChecklistItemLink?: Maybe<_MergeConceptChecklistItemLinkPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##add--remove-relationship) for [creating](https://neo4j.com/docs/cypher-manual/4.1/clauses/create/#create-relationships) the EXERCISE_DONE_FOR relationship. */
  AddConceptExercisesCreated?: Maybe<_AddConceptExercisesCreatedPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##add--remove-relationship) for [deleting](https://neo4j.com/docs/cypher-manual/4.1/clauses/delete/#delete-delete-relationships-only) the EXERCISE_DONE_FOR relationship. */
  RemoveConceptExercisesCreated?: Maybe<_RemoveConceptExercisesCreatedPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##merge-relationship) for [merging](https://neo4j.com/docs/cypher-manual/4.1/clauses/merge/#query-merge-relationships) the EXERCISE_DONE_FOR relationship. */
  MergeConceptExercisesCreated?: Maybe<_MergeConceptExercisesCreatedPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##add--remove-relationship) for [creating](https://neo4j.com/docs/cypher-manual/4.1/clauses/create/#create-relationships) the EXERCISE_INCLUDES_CONCEPT relationship. */
  AddConceptExercisesIncluding?: Maybe<_AddConceptExercisesIncludingPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##add--remove-relationship) for [deleting](https://neo4j.com/docs/cypher-manual/4.1/clauses/delete/#delete-delete-relationships-only) the EXERCISE_INCLUDES_CONCEPT relationship. */
  RemoveConceptExercisesIncluding?: Maybe<_RemoveConceptExercisesIncludingPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##merge-relationship) for [merging](https://neo4j.com/docs/cypher-manual/4.1/clauses/merge/#query-merge-relationships) the EXERCISE_INCLUDES_CONCEPT relationship. */
  MergeConceptExercisesIncluding?: Maybe<_MergeConceptExercisesIncludingPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##add--remove-relationship) for [creating](https://neo4j.com/docs/cypher-manual/4.1/clauses/create/#create-relationships) the SUBMISSION_SPARKED_CONCEPT relationship. */
  AddConceptCreatedForExerciseSubmission?: Maybe<_AddConceptCreatedForExerciseSubmissionPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##add--remove-relationship) for [deleting](https://neo4j.com/docs/cypher-manual/4.1/clauses/delete/#delete-delete-relationships-only) the SUBMISSION_SPARKED_CONCEPT relationship. */
  RemoveConceptCreatedForExerciseSubmission?: Maybe<_RemoveConceptCreatedForExerciseSubmissionPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##merge-relationship) for [merging](https://neo4j.com/docs/cypher-manual/4.1/clauses/merge/#query-merge-relationships) the SUBMISSION_SPARKED_CONCEPT relationship. */
  MergeConceptCreatedForExerciseSubmission?: Maybe<_MergeConceptCreatedForExerciseSubmissionPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##add--remove-relationship) for [creating](https://neo4j.com/docs/cypher-manual/4.1/clauses/create/#create-relationships) the INPUT_FOR_CONCEPT relationship. */
  AddConceptInputs?: Maybe<_AddConceptInputsPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##add--remove-relationship) for [deleting](https://neo4j.com/docs/cypher-manual/4.1/clauses/delete/#delete-delete-relationships-only) the INPUT_FOR_CONCEPT relationship. */
  RemoveConceptInputs?: Maybe<_RemoveConceptInputsPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##merge-relationship) for [merging](https://neo4j.com/docs/cypher-manual/4.1/clauses/merge/#query-merge-relationships) the INPUT_FOR_CONCEPT relationship. */
  MergeConceptInputs?: Maybe<_MergeConceptInputsPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##add--remove-relationship) for [creating](https://neo4j.com/docs/cypher-manual/4.1/clauses/create/#create-relationships) the INPUT_INCLUDES_CONCEPT relationship. */
  AddConceptIncludedForInputs?: Maybe<_AddConceptIncludedForInputsPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##add--remove-relationship) for [deleting](https://neo4j.com/docs/cypher-manual/4.1/clauses/delete/#delete-delete-relationships-only) the INPUT_INCLUDES_CONCEPT relationship. */
  RemoveConceptIncludedForInputs?: Maybe<_RemoveConceptIncludedForInputsPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##merge-relationship) for [merging](https://neo4j.com/docs/cypher-manual/4.1/clauses/merge/#query-merge-relationships) the INPUT_INCLUDES_CONCEPT relationship. */
  MergeConceptIncludedForInputs?: Maybe<_MergeConceptIncludedForInputsPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##add--remove-relationship) for [creating](https://neo4j.com/docs/cypher-manual/4.1/clauses/create/#create-relationships) the CREATED_VIA_INPUT relationship. */
  AddConceptCreatedViaInput?: Maybe<_AddConceptCreatedViaInputPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##add--remove-relationship) for [deleting](https://neo4j.com/docs/cypher-manual/4.1/clauses/delete/#delete-delete-relationships-only) the CREATED_VIA_INPUT relationship. */
  RemoveConceptCreatedViaInput?: Maybe<_RemoveConceptCreatedViaInputPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##merge-relationship) for [merging](https://neo4j.com/docs/cypher-manual/4.1/clauses/merge/#query-merge-relationships) the CREATED_VIA_INPUT relationship. */
  MergeConceptCreatedViaInput?: Maybe<_MergeConceptCreatedViaInputPayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##add--remove-relationship) for [creating](https://neo4j.com/docs/cypher-manual/4.1/clauses/create/#create-relationships) the CONCEPTS_IN_SCORE relationship. */
  AddConceptUsedByScore?: Maybe<_AddConceptUsedByScorePayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##add--remove-relationship) for [deleting](https://neo4j.com/docs/cypher-manual/4.1/clauses/delete/#delete-delete-relationships-only) the CONCEPTS_IN_SCORE relationship. */
  RemoveConceptUsedByScore?: Maybe<_RemoveConceptUsedByScorePayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/##merge-relationship) for [merging](https://neo4j.com/docs/cypher-manual/4.1/clauses/merge/#query-merge-relationships) the CONCEPTS_IN_SCORE relationship. */
  MergeConceptUsedByScore?: Maybe<_MergeConceptUsedByScorePayload>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/#delete) for [deleting](https://neo4j.com/docs/cypher-manual/4.1/clauses/delete/#delete-delete-single-node) a Concept node. */
  DeleteConcept?: Maybe<Concept>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/#merge) for [merging](https://neo4j.com/docs/cypher-manual/4.1/clauses/merge/#query-merge-node-derived) a Concept node. */
  MergeConcept?: Maybe<Concept>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/#create) for [creating](https://neo4j.com/docs/cypher-manual/4.1/clauses/create/#create-nodes) a File node. */
  CreateFile?: Maybe<File>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/#update) for [updating](https://neo4j.com/docs/cypher-manual/4.1/clauses/set/#set-update-a-property) a File node. */
  UpdateFile?: Maybe<File>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/#delete) for [deleting](https://neo4j.com/docs/cypher-manual/4.1/clauses/delete/#delete-delete-single-node) a File node. */
  DeleteFile?: Maybe<File>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/#merge) for [merging](https://neo4j.com/docs/cypher-manual/4.1/clauses/merge/#query-merge-node-derived) a File node. */
  MergeFile?: Maybe<File>;
};


export type MutationCreateConceptArgs = {
  concept?: Maybe<ConceptInput>;
  userId?: Maybe<Scalars['ID']>;
  tags?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type MutationUpdateConceptArgs = {
  concept?: Maybe<ConceptUpdateInput>;
  userId?: Maybe<Scalars['ID']>;
};


export type MutationGrantUserAccessArgs = {
  conceptId: Scalars['ID'];
  userId: Scalars['ID'];
  accessType: AccessType;
};


export type MutationRemoveUserAccessArgs = {
  conceptId: Scalars['ID'];
  userId: Scalars['ID'];
};


export type MutationGrantTeamAccessArgs = {
  conceptId: Scalars['ID'];
  teamId: Scalars['ID'];
  accessType: AccessType;
};


export type MutationRemoveTeamAccessArgs = {
  conceptId: Scalars['ID'];
  teamId: Scalars['ID'];
};


export type MutationCreateTeamWithUsersArgs = {
  team?: Maybe<TeamInput>;
};


export type MutationCreateCommentArgs = {
  conceptId?: Maybe<Scalars['ID']>;
  body?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['ID']>;
};


export type MutationCreateCommentReplyArgs = {
  parentId?: Maybe<Scalars['ID']>;
  body?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['ID']>;
};


export type MutationCreatePersonalBoardArgs = {
  userId?: Maybe<Scalars['ID']>;
};


export type MutationCreateUserWorkspaceArgs = {
  userId?: Maybe<Scalars['ID']>;
};


export type MutationCreateTeamWorkspaceArgs = {
  teamId?: Maybe<Scalars['ID']>;
};


export type MutationCreateChecklistArgs = {
  conceptId?: Maybe<Scalars['ID']>;
  checklist?: Maybe<ChecklistInput>;
  userId?: Maybe<Scalars['ID']>;
};


export type MutationUpdateChecklistArgs = {
  checklist?: Maybe<ChecklistUpdateInput>;
  userId?: Maybe<Scalars['ID']>;
};


export type MutationCreateChecklistItemArgs = {
  checklistId?: Maybe<Scalars['ID']>;
  title?: Maybe<Scalars['String']>;
  checked?: Maybe<Scalars['Boolean']>;
  userId?: Maybe<Scalars['ID']>;
};


export type MutationUpdateChecklistItemArgs = {
  checklistItem?: Maybe<ChecklistItemUpdate>;
  userId?: Maybe<Scalars['ID']>;
};


export type MutationLoginArgs = {
  email?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
};


export type MutationSignupArgs = {
  form?: Maybe<UserSignupInfo>;
};


export type MutationUploadProfileImageArgs = {
  file: Scalars['Upload'];
  imageName?: Maybe<Scalars['String']>;
};


export type MutationUploadConceptImageArgs = {
  file: Scalars['Upload'];
  imageName?: Maybe<Scalars['String']>;
};


export type MutationAddLinkArgs = {
  url?: Maybe<Scalars['String']>;
};


export type MutationAddUserLikedArgs = {
  userId: Scalars['ID'];
  conceptId: Scalars['ID'];
};


export type MutationSendMailArgs = {
  sendTo: Array<Maybe<Scalars['String']>>;
  subject: Scalars['String'];
  text: Scalars['String'];
  html?: Maybe<Scalars['String']>;
  separateEmails?: Maybe<Scalars['Boolean']>;
};


export type MutationGenerateAiArgs = {
  prompt?: Maybe<Scalars['String']>;
  maxLength?: Maybe<Scalars['Int']>;
};


export type MutationGenerateChatAiArgs = {
  systemPrompt?: Maybe<Scalars['String']>;
  messages?: Maybe<Array<Maybe<ChatMessage>>>;
  maxLength?: Maybe<Scalars['Int']>;
};


export type MutationAddUserRecentUpdatesArgs = {
  from: _UserInput;
  to: _ConceptInput;
  data: _ConceptUpdateInput;
};


export type MutationRemoveUserRecentUpdatesArgs = {
  from: _UserInput;
  to: _ConceptInput;
};


export type MutationUpdateUserRecentUpdatesArgs = {
  from: _UserInput;
  to: _ConceptInput;
  data: _ConceptUpdateInput;
};


export type MutationMergeUserRecentUpdatesArgs = {
  from: _UserInput;
  to: _ConceptInput;
  data: _ConceptUpdateInput;
};


export type MutationRemoveUserLikedArgs = {
  from: _UserInput;
  to: _ConceptInput;
};


export type MutationMergeUserLikedArgs = {
  from: _UserInput;
  to: _ConceptInput;
};


export type MutationAddUserAddedToConceptsArgs = {
  from: _UserInput;
  to: _ConceptInput;
  data: _UserAccessInput;
};


export type MutationRemoveUserAddedToConceptsArgs = {
  from: _UserInput;
  to: _ConceptInput;
};


export type MutationUpdateUserAddedToConceptsArgs = {
  from: _UserInput;
  to: _ConceptInput;
  data: _UserAccessInput;
};


export type MutationMergeUserAddedToConceptsArgs = {
  from: _UserInput;
  to: _ConceptInput;
  data: _UserAccessInput;
};


export type MutationAddUserHomeConceptArgs = {
  from: _UserInput;
  to: _ConceptInput;
};


export type MutationRemoveUserHomeConceptArgs = {
  from: _UserInput;
  to: _ConceptInput;
};


export type MutationMergeUserHomeConceptArgs = {
  from: _UserInput;
  to: _ConceptInput;
};


export type MutationAddUserConceptsCreatedArgs = {
  from: _UserInput;
  to: _ConceptInput;
};


export type MutationRemoveUserConceptsCreatedArgs = {
  from: _UserInput;
  to: _ConceptInput;
};


export type MutationMergeUserConceptsCreatedArgs = {
  from: _UserInput;
  to: _ConceptInput;
};


export type MutationAddUserActiveLightThemeArgs = {
  from: _UserInput;
  to: _DesignThemeInput;
};


export type MutationRemoveUserActiveLightThemeArgs = {
  from: _UserInput;
  to: _DesignThemeInput;
};


export type MutationMergeUserActiveLightThemeArgs = {
  from: _UserInput;
  to: _DesignThemeInput;
};


export type MutationAddUserActiveDarkThemeArgs = {
  from: _UserInput;
  to: _DesignThemeInput;
};


export type MutationRemoveUserActiveDarkThemeArgs = {
  from: _UserInput;
  to: _DesignThemeInput;
};


export type MutationMergeUserActiveDarkThemeArgs = {
  from: _UserInput;
  to: _DesignThemeInput;
};


export type MutationAddUserSavedThemesArgs = {
  from: _UserInput;
  to: _DesignThemeInput;
};


export type MutationRemoveUserSavedThemesArgs = {
  from: _UserInput;
  to: _DesignThemeInput;
};


export type MutationMergeUserSavedThemesArgs = {
  from: _UserInput;
  to: _DesignThemeInput;
};


export type MutationAddUserCreatedThemesArgs = {
  from: _UserInput;
  to: _DesignThemeInput;
};


export type MutationRemoveUserCreatedThemesArgs = {
  from: _UserInput;
  to: _DesignThemeInput;
};


export type MutationMergeUserCreatedThemesArgs = {
  from: _UserInput;
  to: _DesignThemeInput;
};


export type MutationAddUserSavedDashboardsArgs = {
  from: _UserInput;
  to: _DashboardViewInput;
};


export type MutationRemoveUserSavedDashboardsArgs = {
  from: _UserInput;
  to: _DashboardViewInput;
};


export type MutationMergeUserSavedDashboardsArgs = {
  from: _UserInput;
  to: _DashboardViewInput;
};


export type MutationAddUserCreatedDashboardArgs = {
  from: _UserInput;
  to: _DashboardViewInput;
};


export type MutationRemoveUserCreatedDashboardArgs = {
  from: _UserInput;
  to: _DashboardViewInput;
};


export type MutationMergeUserCreatedDashboardArgs = {
  from: _UserInput;
  to: _DashboardViewInput;
};


export type MutationAddUserTeamsArgs = {
  from: _UserInput;
  to: _TeamInput;
};


export type MutationRemoveUserTeamsArgs = {
  from: _UserInput;
  to: _TeamInput;
};


export type MutationMergeUserTeamsArgs = {
  from: _UserInput;
  to: _TeamInput;
};


export type MutationAddUserAdminArgs = {
  from: _UserInput;
  to: _TeamInput;
};


export type MutationRemoveUserAdminArgs = {
  from: _UserInput;
  to: _TeamInput;
};


export type MutationMergeUserAdminArgs = {
  from: _UserInput;
  to: _TeamInput;
};


export type MutationAddUserLeadsArgs = {
  from: _UserInput;
  to: _TeamInput;
};


export type MutationRemoveUserLeadsArgs = {
  from: _UserInput;
  to: _TeamInput;
};


export type MutationMergeUserLeadsArgs = {
  from: _UserInput;
  to: _TeamInput;
};


export type MutationAddUserCommentsArgs = {
  from: _UserInput;
  to: _CommentInput;
};


export type MutationRemoveUserCommentsArgs = {
  from: _UserInput;
  to: _CommentInput;
};


export type MutationMergeUserCommentsArgs = {
  from: _UserInput;
  to: _CommentInput;
};


export type MutationAddUserWatchedConceptsArgs = {
  from: _UserInput;
  to: _ConceptInput;
};


export type MutationRemoveUserWatchedConceptsArgs = {
  from: _UserInput;
  to: _ConceptInput;
};


export type MutationMergeUserWatchedConceptsArgs = {
  from: _UserInput;
  to: _ConceptInput;
};


export type MutationAddUserNotificationsTriggeredArgs = {
  from: _UserInput;
  to: _UserInput;
};


export type MutationRemoveUserNotificationsTriggeredArgs = {
  from: _UserInput;
  to: _UserInput;
};


export type MutationMergeUserNotificationsTriggeredArgs = {
  from: _UserInput;
  to: _UserInput;
};


export type MutationAddUserNotificationsArgs = {
  from: _NotificationInput;
  to: _UserInput;
};


export type MutationRemoveUserNotificationsArgs = {
  from: _NotificationInput;
  to: _UserInput;
};


export type MutationMergeUserNotificationsArgs = {
  from: _NotificationInput;
  to: _UserInput;
};


export type MutationAddUserWorkspaceInboxArgs = {
  from: _UserInput;
  to: _ConceptListInput;
};


export type MutationRemoveUserWorkspaceInboxArgs = {
  from: _UserInput;
  to: _ConceptListInput;
};


export type MutationMergeUserWorkspaceInboxArgs = {
  from: _UserInput;
  to: _ConceptListInput;
};


export type MutationAddUserConceptListsArgs = {
  from: _UserInput;
  to: _ConceptListInput;
};


export type MutationRemoveUserConceptListsArgs = {
  from: _UserInput;
  to: _ConceptListInput;
};


export type MutationMergeUserConceptListsArgs = {
  from: _UserInput;
  to: _ConceptListInput;
};


export type MutationAddUserConceptBoardArgs = {
  from: _UserInput;
  to: _ConceptBoardInput;
};


export type MutationRemoveUserConceptBoardArgs = {
  from: _UserInput;
  to: _ConceptBoardInput;
};


export type MutationMergeUserConceptBoardArgs = {
  from: _UserInput;
  to: _ConceptBoardInput;
};


export type MutationAddUserExercisesFacilitatedArgs = {
  from: _ExerciseInput;
  to: _UserInput;
};


export type MutationRemoveUserExercisesFacilitatedArgs = {
  from: _ExerciseInput;
  to: _UserInput;
};


export type MutationMergeUserExercisesFacilitatedArgs = {
  from: _ExerciseInput;
  to: _UserInput;
};


export type MutationAddUserExercisesInvitedToArgs = {
  from: _ExerciseInput;
  to: _UserInput;
};


export type MutationRemoveUserExercisesInvitedToArgs = {
  from: _ExerciseInput;
  to: _UserInput;
};


export type MutationMergeUserExercisesInvitedToArgs = {
  from: _ExerciseInput;
  to: _UserInput;
};


export type MutationAddUserExercisesParticipatedArgs = {
  from: _ExerciseInput;
  to: _UserInput;
};


export type MutationRemoveUserExercisesParticipatedArgs = {
  from: _ExerciseInput;
  to: _UserInput;
};


export type MutationMergeUserExercisesParticipatedArgs = {
  from: _ExerciseInput;
  to: _UserInput;
};


export type MutationAddUserExercisesCreatedArgs = {
  from: _ExerciseInput;
  to: _UserInput;
};


export type MutationRemoveUserExercisesCreatedArgs = {
  from: _ExerciseInput;
  to: _UserInput;
};


export type MutationMergeUserExercisesCreatedArgs = {
  from: _ExerciseInput;
  to: _UserInput;
};


export type MutationAddUserExerciseTypesCreatedArgs = {
  from: _ExerciseTypeInput;
  to: _UserInput;
};


export type MutationRemoveUserExerciseTypesCreatedArgs = {
  from: _ExerciseTypeInput;
  to: _UserInput;
};


export type MutationMergeUserExerciseTypesCreatedArgs = {
  from: _ExerciseTypeInput;
  to: _UserInput;
};


export type MutationAddUserExerciseSubmissionsArgs = {
  from: _ExerciseSubmissionInput;
  to: _UserInput;
};


export type MutationRemoveUserExerciseSubmissionsArgs = {
  from: _ExerciseSubmissionInput;
  to: _UserInput;
};


export type MutationMergeUserExerciseSubmissionsArgs = {
  from: _ExerciseSubmissionInput;
  to: _UserInput;
};


export type MutationAddUserCriteriaScoresArgs = {
  from: _UserInput;
  to: _CriteriaScoreInput;
};


export type MutationRemoveUserCriteriaScoresArgs = {
  from: _UserInput;
  to: _CriteriaScoreInput;
};


export type MutationMergeUserCriteriaScoresArgs = {
  from: _UserInput;
  to: _CriteriaScoreInput;
};


export type MutationCreateUserArgs = {
  userId?: Maybe<Scalars['ID']>;
  providerId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  username?: Maybe<Scalars['String']>;
  imageUrl?: Maybe<Scalars['String']>;
  company?: Maybe<Scalars['String']>;
  bio?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  language?: Maybe<LanguageString>;
  lastCheckedNotifications?: Maybe<_Neo4jDateTimeInput>;
  lastLogin?: Maybe<_Neo4jDateTimeInput>;
  createdDate?: Maybe<_Neo4jDateTimeInput>;
  role?: Maybe<SystemRole>;
  activeThemeType?: Maybe<ThemeType>;
};


export type MutationUpdateUserArgs = {
  userId: Scalars['ID'];
  providerId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  username?: Maybe<Scalars['String']>;
  imageUrl?: Maybe<Scalars['String']>;
  company?: Maybe<Scalars['String']>;
  bio?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  language?: Maybe<LanguageString>;
  lastCheckedNotifications?: Maybe<_Neo4jDateTimeInput>;
  lastLogin?: Maybe<_Neo4jDateTimeInput>;
  createdDate?: Maybe<_Neo4jDateTimeInput>;
  role?: Maybe<SystemRole>;
  activeThemeType?: Maybe<ThemeType>;
};


export type MutationDeleteUserArgs = {
  userId: Scalars['ID'];
};


export type MutationMergeUserArgs = {
  userId: Scalars['ID'];
  providerId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  username?: Maybe<Scalars['String']>;
  imageUrl?: Maybe<Scalars['String']>;
  company?: Maybe<Scalars['String']>;
  bio?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  language?: Maybe<LanguageString>;
  lastCheckedNotifications?: Maybe<_Neo4jDateTimeInput>;
  lastLogin?: Maybe<_Neo4jDateTimeInput>;
  createdDate?: Maybe<_Neo4jDateTimeInput>;
  role?: Maybe<SystemRole>;
  activeThemeType?: Maybe<ThemeType>;
};


export type MutationCreateEnvironmentSettingArgs = {
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};


export type MutationUpdateEnvironmentSettingArgs = {
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};


export type MutationDeleteEnvironmentSettingArgs = {
  id: Scalars['ID'];
};


export type MutationMergeEnvironmentSettingArgs = {
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};


export type MutationAddNotificationActionByArgs = {
  from: _UserInput;
  to: _NotificationInput;
};


export type MutationRemoveNotificationActionByArgs = {
  from: _UserInput;
  to: _NotificationInput;
};


export type MutationMergeNotificationActionByArgs = {
  from: _UserInput;
  to: _NotificationInput;
};


export type MutationAddNotificationConceptArgs = {
  from: _ConceptInput;
  to: _NotificationInput;
};


export type MutationRemoveNotificationConceptArgs = {
  from: _ConceptInput;
  to: _NotificationInput;
};


export type MutationMergeNotificationConceptArgs = {
  from: _ConceptInput;
  to: _NotificationInput;
};


export type MutationAddNotificationCommentArgs = {
  from: _CommentInput;
  to: _NotificationInput;
};


export type MutationRemoveNotificationCommentArgs = {
  from: _CommentInput;
  to: _NotificationInput;
};


export type MutationMergeNotificationCommentArgs = {
  from: _CommentInput;
  to: _NotificationInput;
};


export type MutationAddNotificationUsersArgs = {
  from: _NotificationInput;
  to: _UserInput;
};


export type MutationRemoveNotificationUsersArgs = {
  from: _NotificationInput;
  to: _UserInput;
};


export type MutationMergeNotificationUsersArgs = {
  from: _NotificationInput;
  to: _UserInput;
};


export type MutationCreateNotificationArgs = {
  id?: Maybe<Scalars['ID']>;
  content?: Maybe<Scalars['String']>;
  type?: Maybe<Notification_Type>;
  createdAt?: Maybe<_Neo4jDateTimeInput>;
};


export type MutationUpdateNotificationArgs = {
  id: Scalars['ID'];
  content?: Maybe<Scalars['String']>;
  type?: Maybe<Notification_Type>;
  createdAt?: Maybe<_Neo4jDateTimeInput>;
};


export type MutationDeleteNotificationArgs = {
  id: Scalars['ID'];
};


export type MutationMergeNotificationArgs = {
  id: Scalars['ID'];
  content?: Maybe<Scalars['String']>;
  type?: Maybe<Notification_Type>;
  createdAt?: Maybe<_Neo4jDateTimeInput>;
};


export type MutationAddDashboardViewActiveThemeArgs = {
  from: _DashboardViewInput;
  to: _DesignThemeInput;
};


export type MutationRemoveDashboardViewActiveThemeArgs = {
  from: _DashboardViewInput;
  to: _DesignThemeInput;
};


export type MutationMergeDashboardViewActiveThemeArgs = {
  from: _DashboardViewInput;
  to: _DesignThemeInput;
};


export type MutationAddDashboardViewCreatedByArgs = {
  from: _UserInput;
  to: _DashboardViewInput;
};


export type MutationRemoveDashboardViewCreatedByArgs = {
  from: _UserInput;
  to: _DashboardViewInput;
};


export type MutationMergeDashboardViewCreatedByArgs = {
  from: _UserInput;
  to: _DashboardViewInput;
};


export type MutationAddDashboardViewUsedByArgs = {
  from: _UserInput;
  to: _DashboardViewInput;
};


export type MutationRemoveDashboardViewUsedByArgs = {
  from: _UserInput;
  to: _DashboardViewInput;
};


export type MutationMergeDashboardViewUsedByArgs = {
  from: _UserInput;
  to: _DashboardViewInput;
};


export type MutationAddDashboardViewUsedByConceptsArgs = {
  from: _ConceptInput;
  to: _DashboardViewInput;
};


export type MutationRemoveDashboardViewUsedByConceptsArgs = {
  from: _ConceptInput;
  to: _DashboardViewInput;
};


export type MutationMergeDashboardViewUsedByConceptsArgs = {
  from: _ConceptInput;
  to: _DashboardViewInput;
};


export type MutationAddDashboardViewWidgetsArgs = {
  from: _DashboardViewInput;
  to: _WidgetInput;
};


export type MutationRemoveDashboardViewWidgetsArgs = {
  from: _DashboardViewInput;
  to: _WidgetInput;
};


export type MutationMergeDashboardViewWidgetsArgs = {
  from: _DashboardViewInput;
  to: _WidgetInput;
};


export type MutationAddDashboardViewConceptHomeArgs = {
  from: _ConceptInput;
  to: _DashboardViewInput;
};


export type MutationRemoveDashboardViewConceptHomeArgs = {
  from: _ConceptInput;
  to: _DashboardViewInput;
};


export type MutationMergeDashboardViewConceptHomeArgs = {
  from: _ConceptInput;
  to: _DashboardViewInput;
};


export type MutationAddDashboardViewCategoryDefaultArgs = {
  from: _CategoryInput;
  to: _DashboardViewInput;
};


export type MutationRemoveDashboardViewCategoryDefaultArgs = {
  from: _CategoryInput;
  to: _DashboardViewInput;
};


export type MutationMergeDashboardViewCategoryDefaultArgs = {
  from: _CategoryInput;
  to: _DashboardViewInput;
};


export type MutationCreateDashboardViewArgs = {
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  layout?: Maybe<Scalars['String']>;
  isTemplate?: Maybe<Scalars['Boolean']>;
  isPublic?: Maybe<Scalars['Boolean']>;
};


export type MutationUpdateDashboardViewArgs = {
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  layout?: Maybe<Scalars['String']>;
  isTemplate?: Maybe<Scalars['Boolean']>;
  isPublic?: Maybe<Scalars['Boolean']>;
};


export type MutationDeleteDashboardViewArgs = {
  id: Scalars['ID'];
};


export type MutationMergeDashboardViewArgs = {
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  layout?: Maybe<Scalars['String']>;
  isTemplate?: Maybe<Scalars['Boolean']>;
  isPublic?: Maybe<Scalars['Boolean']>;
};


export type MutationAddWidgetTypeArgs = {
  from: _WidgetInput;
  to: _WidgetTypeInput;
};


export type MutationRemoveWidgetTypeArgs = {
  from: _WidgetInput;
  to: _WidgetTypeInput;
};


export type MutationMergeWidgetTypeArgs = {
  from: _WidgetInput;
  to: _WidgetTypeInput;
};


export type MutationAddWidgetDashboardsArgs = {
  from: _DashboardViewInput;
  to: _WidgetInput;
};


export type MutationRemoveWidgetDashboardsArgs = {
  from: _DashboardViewInput;
  to: _WidgetInput;
};


export type MutationMergeWidgetDashboardsArgs = {
  from: _DashboardViewInput;
  to: _WidgetInput;
};


export type MutationCreateWidgetArgs = {
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  config?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
  isTemplate?: Maybe<Scalars['Boolean']>;
};


export type MutationUpdateWidgetArgs = {
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  config?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
  isTemplate?: Maybe<Scalars['Boolean']>;
};


export type MutationDeleteWidgetArgs = {
  id: Scalars['ID'];
};


export type MutationMergeWidgetArgs = {
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  config?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
  isTemplate?: Maybe<Scalars['Boolean']>;
};


export type MutationAddWidgetTypeWidgetsArgs = {
  from: _WidgetInput;
  to: _WidgetTypeInput;
};


export type MutationRemoveWidgetTypeWidgetsArgs = {
  from: _WidgetInput;
  to: _WidgetTypeInput;
};


export type MutationMergeWidgetTypeWidgetsArgs = {
  from: _WidgetInput;
  to: _WidgetTypeInput;
};


export type MutationCreateWidgetTypeArgs = {
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  component?: Maybe<Scalars['String']>;
  schema?: Maybe<Scalars['String']>;
  inputConfig?: Maybe<Scalars['String']>;
  defaultConfig?: Maybe<Scalars['String']>;
  defaultValue?: Maybe<Scalars['String']>;
};


export type MutationUpdateWidgetTypeArgs = {
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  component?: Maybe<Scalars['String']>;
  schema?: Maybe<Scalars['String']>;
  inputConfig?: Maybe<Scalars['String']>;
  defaultConfig?: Maybe<Scalars['String']>;
  defaultValue?: Maybe<Scalars['String']>;
};


export type MutationDeleteWidgetTypeArgs = {
  id: Scalars['ID'];
};


export type MutationAddDesignThemeUsedByDashboardsArgs = {
  from: _DashboardViewInput;
  to: _DesignThemeInput;
};


export type MutationRemoveDesignThemeUsedByDashboardsArgs = {
  from: _DashboardViewInput;
  to: _DesignThemeInput;
};


export type MutationMergeDesignThemeUsedByDashboardsArgs = {
  from: _DashboardViewInput;
  to: _DesignThemeInput;
};


export type MutationAddDesignThemeCreatedByArgs = {
  from: _UserInput;
  to: _DesignThemeInput;
};


export type MutationRemoveDesignThemeCreatedByArgs = {
  from: _UserInput;
  to: _DesignThemeInput;
};


export type MutationMergeDesignThemeCreatedByArgs = {
  from: _UserInput;
  to: _DesignThemeInput;
};


export type MutationAddDesignThemeSavedByArgs = {
  from: _UserInput;
  to: _DesignThemeInput;
};


export type MutationRemoveDesignThemeSavedByArgs = {
  from: _UserInput;
  to: _DesignThemeInput;
};


export type MutationMergeDesignThemeSavedByArgs = {
  from: _UserInput;
  to: _DesignThemeInput;
};


export type MutationAddDesignThemeUsedAsLightByArgs = {
  from: _UserInput;
  to: _DesignThemeInput;
};


export type MutationRemoveDesignThemeUsedAsLightByArgs = {
  from: _UserInput;
  to: _DesignThemeInput;
};


export type MutationMergeDesignThemeUsedAsLightByArgs = {
  from: _UserInput;
  to: _DesignThemeInput;
};


export type MutationAddDesignThemeUsedAsDarkByArgs = {
  from: _UserInput;
  to: _DesignThemeInput;
};


export type MutationRemoveDesignThemeUsedAsDarkByArgs = {
  from: _UserInput;
  to: _DesignThemeInput;
};


export type MutationMergeDesignThemeUsedAsDarkByArgs = {
  from: _UserInput;
  to: _DesignThemeInput;
};


export type MutationCreateDesignThemeArgs = {
  themeId?: Maybe<Scalars['ID']>;
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  darkMode?: Maybe<Scalars['Boolean']>;
  primary?: Maybe<Scalars['String']>;
  secondary?: Maybe<Scalars['String']>;
  primaryHue?: Maybe<Scalars['String']>;
  secondaryHue?: Maybe<Scalars['String']>;
  primaryShade?: Maybe<Scalars['Int']>;
  secondaryShade?: Maybe<Scalars['Int']>;
  background?: Maybe<Scalars['String']>;
};


export type MutationUpdateDesignThemeArgs = {
  themeId: Scalars['ID'];
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  darkMode?: Maybe<Scalars['Boolean']>;
  primary?: Maybe<Scalars['String']>;
  secondary?: Maybe<Scalars['String']>;
  primaryHue?: Maybe<Scalars['String']>;
  secondaryHue?: Maybe<Scalars['String']>;
  primaryShade?: Maybe<Scalars['Int']>;
  secondaryShade?: Maybe<Scalars['Int']>;
  background?: Maybe<Scalars['String']>;
};


export type MutationDeleteDesignThemeArgs = {
  themeId: Scalars['ID'];
};


export type MutationMergeDesignThemeArgs = {
  themeId: Scalars['ID'];
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  darkMode?: Maybe<Scalars['Boolean']>;
  primary?: Maybe<Scalars['String']>;
  secondary?: Maybe<Scalars['String']>;
  primaryHue?: Maybe<Scalars['String']>;
  secondaryHue?: Maybe<Scalars['String']>;
  primaryShade?: Maybe<Scalars['Int']>;
  secondaryShade?: Maybe<Scalars['Int']>;
  background?: Maybe<Scalars['String']>;
};


export type MutationAddCompanyEmployeesArgs = {
  from: _UserInput;
  to: _CompanyInput;
};


export type MutationRemoveCompanyEmployeesArgs = {
  from: _UserInput;
  to: _CompanyInput;
};


export type MutationMergeCompanyEmployeesArgs = {
  from: _UserInput;
  to: _CompanyInput;
};


export type MutationAddCompanyIndustriesArgs = {
  from: _CompanyInput;
  to: _IndustryInput;
};


export type MutationRemoveCompanyIndustriesArgs = {
  from: _CompanyInput;
  to: _IndustryInput;
};


export type MutationMergeCompanyIndustriesArgs = {
  from: _CompanyInput;
  to: _IndustryInput;
};


export type MutationAddCompanyTeamsArgs = {
  from: _TeamInput;
  to: _CompanyInput;
};


export type MutationRemoveCompanyTeamsArgs = {
  from: _TeamInput;
  to: _CompanyInput;
};


export type MutationMergeCompanyTeamsArgs = {
  from: _TeamInput;
  to: _CompanyInput;
};


export type MutationCreateCompanyArgs = {
  companyId?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
};


export type MutationUpdateCompanyArgs = {
  companyId: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
};


export type MutationDeleteCompanyArgs = {
  companyId: Scalars['ID'];
};


export type MutationMergeCompanyArgs = {
  companyId: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
};


export type MutationAddIndustryCompaniesArgs = {
  from: _CompanyInput;
  to: _IndustryInput;
};


export type MutationRemoveIndustryCompaniesArgs = {
  from: _CompanyInput;
  to: _IndustryInput;
};


export type MutationMergeIndustryCompaniesArgs = {
  from: _CompanyInput;
  to: _IndustryInput;
};


export type MutationAddIndustryConceptsArgs = {
  from: _ConceptInput;
  to: _IndustryInput;
};


export type MutationRemoveIndustryConceptsArgs = {
  from: _ConceptInput;
  to: _IndustryInput;
};


export type MutationMergeIndustryConceptsArgs = {
  from: _ConceptInput;
  to: _IndustryInput;
};


export type MutationCreateIndustryArgs = {
  industryId?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  marketSize?: Maybe<Scalars['Float']>;
};


export type MutationUpdateIndustryArgs = {
  industryId: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  marketSize?: Maybe<Scalars['Float']>;
};


export type MutationDeleteIndustryArgs = {
  industryId: Scalars['ID'];
};


export type MutationMergeIndustryArgs = {
  industryId: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  marketSize?: Maybe<Scalars['Float']>;
};


export type MutationAddTeamLeaderArgs = {
  from: _UserInput;
  to: _TeamInput;
};


export type MutationRemoveTeamLeaderArgs = {
  from: _UserInput;
  to: _TeamInput;
};


export type MutationMergeTeamLeaderArgs = {
  from: _UserInput;
  to: _TeamInput;
};


export type MutationAddTeamAdminArgs = {
  from: _UserInput;
  to: _TeamInput;
};


export type MutationRemoveTeamAdminArgs = {
  from: _UserInput;
  to: _TeamInput;
};


export type MutationMergeTeamAdminArgs = {
  from: _UserInput;
  to: _TeamInput;
};


export type MutationAddTeamMembersArgs = {
  from: _UserInput;
  to: _TeamInput;
};


export type MutationRemoveTeamMembersArgs = {
  from: _UserInput;
  to: _TeamInput;
};


export type MutationMergeTeamMembersArgs = {
  from: _UserInput;
  to: _TeamInput;
};


export type MutationAddTeamCompanyArgs = {
  from: _TeamInput;
  to: _CompanyInput;
};


export type MutationRemoveTeamCompanyArgs = {
  from: _TeamInput;
  to: _CompanyInput;
};


export type MutationMergeTeamCompanyArgs = {
  from: _TeamInput;
  to: _CompanyInput;
};


export type MutationAddTeamConceptsArgs = {
  from: _TeamInput;
  to: _ConceptInput;
};


export type MutationRemoveTeamConceptsArgs = {
  from: _TeamInput;
  to: _ConceptInput;
};


export type MutationMergeTeamConceptsArgs = {
  from: _TeamInput;
  to: _ConceptInput;
};


export type MutationAddTeamHomeConceptArgs = {
  from: _TeamInput;
  to: _ConceptInput;
};


export type MutationRemoveTeamHomeConceptArgs = {
  from: _TeamInput;
  to: _ConceptInput;
};


export type MutationMergeTeamHomeConceptArgs = {
  from: _TeamInput;
  to: _ConceptInput;
};


export type MutationAddTeamAddedToConceptsArgs = {
  from: _TeamInput;
  to: _ConceptInput;
  data: _TeamAccessInput;
};


export type MutationRemoveTeamAddedToConceptsArgs = {
  from: _TeamInput;
  to: _ConceptInput;
};


export type MutationUpdateTeamAddedToConceptsArgs = {
  from: _TeamInput;
  to: _ConceptInput;
  data: _TeamAccessInput;
};


export type MutationMergeTeamAddedToConceptsArgs = {
  from: _TeamInput;
  to: _ConceptInput;
  data: _TeamAccessInput;
};


export type MutationAddTeamConceptListsArgs = {
  from: _TeamInput;
  to: _ConceptListInput;
};


export type MutationRemoveTeamConceptListsArgs = {
  from: _TeamInput;
  to: _ConceptListInput;
};


export type MutationMergeTeamConceptListsArgs = {
  from: _TeamInput;
  to: _ConceptListInput;
};


export type MutationAddTeamExercisesInvitedToArgs = {
  from: _ExerciseInput;
  to: _TeamInput;
};


export type MutationRemoveTeamExercisesInvitedToArgs = {
  from: _ExerciseInput;
  to: _TeamInput;
};


export type MutationMergeTeamExercisesInvitedToArgs = {
  from: _ExerciseInput;
  to: _TeamInput;
};


export type MutationCreateTeamArgs = {
  teamId?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  imageUrl?: Maybe<Scalars['String']>;
};


export type MutationUpdateTeamArgs = {
  teamId: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  imageUrl?: Maybe<Scalars['String']>;
};


export type MutationDeleteTeamArgs = {
  teamId: Scalars['ID'];
};


export type MutationMergeTeamArgs = {
  teamId: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  imageUrl?: Maybe<Scalars['String']>;
};


export type MutationAddCommentUserArgs = {
  from: _UserInput;
  to: _CommentInput;
};


export type MutationRemoveCommentUserArgs = {
  from: _UserInput;
  to: _CommentInput;
};


export type MutationMergeCommentUserArgs = {
  from: _UserInput;
  to: _CommentInput;
};


export type MutationAddCommentConceptArgs = {
  from: _CommentInput;
  to: _ConceptInput;
};


export type MutationRemoveCommentConceptArgs = {
  from: _CommentInput;
  to: _ConceptInput;
};


export type MutationMergeCommentConceptArgs = {
  from: _CommentInput;
  to: _ConceptInput;
};


export type MutationAddCommentReplyToArgs = {
  from: _CommentInput;
  to: _CommentInput;
};


export type MutationRemoveCommentReplyToArgs = {
  from: _CommentInput;
  to: _CommentInput;
};


export type MutationMergeCommentReplyToArgs = {
  from: _CommentInput;
  to: _CommentInput;
};


export type MutationAddCommentRepliesArgs = {
  from: _CommentInput;
  to: _CommentInput;
};


export type MutationRemoveCommentRepliesArgs = {
  from: _CommentInput;
  to: _CommentInput;
};


export type MutationMergeCommentRepliesArgs = {
  from: _CommentInput;
  to: _CommentInput;
};


export type MutationAddCommentNotificationsArgs = {
  from: _CommentInput;
  to: _NotificationInput;
};


export type MutationRemoveCommentNotificationsArgs = {
  from: _CommentInput;
  to: _NotificationInput;
};


export type MutationMergeCommentNotificationsArgs = {
  from: _CommentInput;
  to: _NotificationInput;
};


export type MutationAddCommentLikedByArgs = {
  from: _UserInput;
  to: _CommentInput;
};


export type MutationRemoveCommentLikedByArgs = {
  from: _UserInput;
  to: _CommentInput;
};


export type MutationMergeCommentLikedByArgs = {
  from: _UserInput;
  to: _CommentInput;
};


export type MutationUpdateCommentArgs = {
  commentId: Scalars['ID'];
  createdAt?: Maybe<_Neo4jDateTimeInput>;
  body?: Maybe<Scalars['String']>;
};


export type MutationDeleteCommentArgs = {
  commentId: Scalars['ID'];
};


export type MutationMergeCommentArgs = {
  commentId: Scalars['ID'];
  createdAt?: Maybe<_Neo4jDateTimeInput>;
  body?: Maybe<Scalars['String']>;
};


export type MutationAddTagTaggedArgs = {
  from: _ConceptInput;
  to: _TagInput;
};


export type MutationRemoveTagTaggedArgs = {
  from: _ConceptInput;
  to: _TagInput;
};


export type MutationMergeTagTaggedArgs = {
  from: _ConceptInput;
  to: _TagInput;
};


export type MutationCreateTagArgs = {
  name?: Maybe<Scalars['ID']>;
};


export type MutationDeleteTagArgs = {
  name: Scalars['ID'];
};


export type MutationMergeTagArgs = {
  name: Scalars['ID'];
};


export type MutationAddChecklistCreatedByArgs = {
  from: _UserInput;
  to: _ChecklistInput;
};


export type MutationRemoveChecklistCreatedByArgs = {
  from: _UserInput;
  to: _ChecklistInput;
};


export type MutationMergeChecklistCreatedByArgs = {
  from: _UserInput;
  to: _ChecklistInput;
};


export type MutationAddChecklistTypeArgs = {
  from: _ChecklistInput;
  to: _ChecklistTypeInput;
};


export type MutationRemoveChecklistTypeArgs = {
  from: _ChecklistInput;
  to: _ChecklistTypeInput;
};


export type MutationMergeChecklistTypeArgs = {
  from: _ChecklistInput;
  to: _ChecklistTypeInput;
};


export type MutationAddChecklistItemsArgs = {
  from: _ChecklistInput;
  to: _ChecklistItemInput;
};


export type MutationRemoveChecklistItemsArgs = {
  from: _ChecklistInput;
  to: _ChecklistItemInput;
};


export type MutationMergeChecklistItemsArgs = {
  from: _ChecklistInput;
  to: _ChecklistItemInput;
};


export type MutationAddChecklistConceptArgs = {
  from: _ConceptInput;
  to: _ChecklistInput;
};


export type MutationRemoveChecklistConceptArgs = {
  from: _ConceptInput;
  to: _ChecklistInput;
};


export type MutationMergeChecklistConceptArgs = {
  from: _ConceptInput;
  to: _ChecklistInput;
};


export type MutationAddChecklistExerciseArgs = {
  from: _ExerciseInput;
  to: _ChecklistInput;
};


export type MutationRemoveChecklistExerciseArgs = {
  from: _ExerciseInput;
  to: _ChecklistInput;
};


export type MutationMergeChecklistExerciseArgs = {
  from: _ExerciseInput;
  to: _ChecklistInput;
};


export type MutationDeleteChecklistArgs = {
  id: Scalars['ID'];
};


export type MutationMergeChecklistArgs = {
  id: Scalars['ID'];
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  conceptBased?: Maybe<Scalars['Boolean']>;
  isOrdered?: Maybe<Scalars['Boolean']>;
  itemPrefixType?: Maybe<ItemPrefixType>;
  itemIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  listLengthLimit?: Maybe<Scalars['Int']>;
  lastUpdated?: Maybe<_Neo4jDateTimeInput>;
  updates?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdAt?: Maybe<_Neo4jDateTimeInput>;
};


export type MutationAddChecklistTypeChecklistsArgs = {
  from: _ChecklistInput;
  to: _ChecklistTypeInput;
};


export type MutationRemoveChecklistTypeChecklistsArgs = {
  from: _ChecklistInput;
  to: _ChecklistTypeInput;
};


export type MutationMergeChecklistTypeChecklistsArgs = {
  from: _ChecklistInput;
  to: _ChecklistTypeInput;
};


export type MutationCreateChecklistTypeArgs = {
  id?: Maybe<Scalars['ID']>;
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  itemPrefixType?: Maybe<ItemPrefixType>;
  conceptBased?: Maybe<Scalars['Boolean']>;
};


export type MutationUpdateChecklistTypeArgs = {
  id: Scalars['ID'];
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  itemPrefixType?: Maybe<ItemPrefixType>;
  conceptBased?: Maybe<Scalars['Boolean']>;
};


export type MutationDeleteChecklistTypeArgs = {
  id: Scalars['ID'];
};


export type MutationAddChecklistItemCreatedByArgs = {
  from: _UserInput;
  to: _ChecklistItemInput;
};


export type MutationRemoveChecklistItemCreatedByArgs = {
  from: _UserInput;
  to: _ChecklistItemInput;
};


export type MutationMergeChecklistItemCreatedByArgs = {
  from: _UserInput;
  to: _ChecklistItemInput;
};


export type MutationAddChecklistItemChecklistArgs = {
  from: _ChecklistInput;
  to: _ChecklistItemInput;
};


export type MutationRemoveChecklistItemChecklistArgs = {
  from: _ChecklistInput;
  to: _ChecklistItemInput;
};


export type MutationMergeChecklistItemChecklistArgs = {
  from: _ChecklistInput;
  to: _ChecklistItemInput;
};


export type MutationAddChecklistItemParentArgs = {
  from: _ChecklistItemInput;
  to: _ChecklistItemInput;
};


export type MutationRemoveChecklistItemParentArgs = {
  from: _ChecklistItemInput;
  to: _ChecklistItemInput;
};


export type MutationMergeChecklistItemParentArgs = {
  from: _ChecklistItemInput;
  to: _ChecklistItemInput;
};


export type MutationAddChecklistItemChildrenArgs = {
  from: _ChecklistItemInput;
  to: _ChecklistItemInput;
};


export type MutationRemoveChecklistItemChildrenArgs = {
  from: _ChecklistItemInput;
  to: _ChecklistItemInput;
};


export type MutationMergeChecklistItemChildrenArgs = {
  from: _ChecklistItemInput;
  to: _ChecklistItemInput;
};


export type MutationAddChecklistItemConceptArgs = {
  from: _ChecklistItemInput;
  to: _ConceptInput;
};


export type MutationRemoveChecklistItemConceptArgs = {
  from: _ChecklistItemInput;
  to: _ConceptInput;
};


export type MutationMergeChecklistItemConceptArgs = {
  from: _ChecklistItemInput;
  to: _ConceptInput;
};


export type MutationDeleteChecklistItemArgs = {
  id: Scalars['ID'];
};


export type MutationMergeChecklistItemArgs = {
  id: Scalars['ID'];
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  checked?: Maybe<Scalars['Boolean']>;
  lastUpdated?: Maybe<_Neo4jDateTimeInput>;
  displayState?: Maybe<ItemDisplayState>;
  listIndex?: Maybe<Scalars['Int']>;
  updates?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdAt?: Maybe<_Neo4jDateTimeInput>;
};


export type MutationAddConceptListUserArgs = {
  from: _UserInput;
  to: _ConceptListInput;
};


export type MutationRemoveConceptListUserArgs = {
  from: _UserInput;
  to: _ConceptListInput;
};


export type MutationMergeConceptListUserArgs = {
  from: _UserInput;
  to: _ConceptListInput;
};


export type MutationAddConceptListInboxUserArgs = {
  from: _UserInput;
  to: _ConceptListInput;
};


export type MutationRemoveConceptListInboxUserArgs = {
  from: _UserInput;
  to: _ConceptListInput;
};


export type MutationMergeConceptListInboxUserArgs = {
  from: _UserInput;
  to: _ConceptListInput;
};


export type MutationAddConceptListTeamArgs = {
  from: _TeamInput;
  to: _ConceptListInput;
};


export type MutationRemoveConceptListTeamArgs = {
  from: _TeamInput;
  to: _ConceptListInput;
};


export type MutationMergeConceptListTeamArgs = {
  from: _TeamInput;
  to: _ConceptListInput;
};


export type MutationAddConceptListConceptsArgs = {
  from: _ConceptInput;
  to: _ConceptListInput;
};


export type MutationRemoveConceptListConceptsArgs = {
  from: _ConceptInput;
  to: _ConceptListInput;
};


export type MutationMergeConceptListConceptsArgs = {
  from: _ConceptInput;
  to: _ConceptListInput;
};


export type MutationAddConceptListWorkspaceConceptArgs = {
  from: _ConceptInput;
  to: _ConceptListInput;
};


export type MutationRemoveConceptListWorkspaceConceptArgs = {
  from: _ConceptInput;
  to: _ConceptListInput;
};


export type MutationMergeConceptListWorkspaceConceptArgs = {
  from: _ConceptInput;
  to: _ConceptListInput;
};


export type MutationAddConceptListParentConceptArgs = {
  from: _ConceptInput;
  to: _ConceptListInput;
};


export type MutationRemoveConceptListParentConceptArgs = {
  from: _ConceptInput;
  to: _ConceptListInput;
};


export type MutationMergeConceptListParentConceptArgs = {
  from: _ConceptInput;
  to: _ConceptListInput;
};


export type MutationAddConceptListConceptBoardArgs = {
  from: _ConceptListInput;
  to: _ConceptBoardInput;
};


export type MutationRemoveConceptListConceptBoardArgs = {
  from: _ConceptListInput;
  to: _ConceptBoardInput;
};


export type MutationMergeConceptListConceptBoardArgs = {
  from: _ConceptListInput;
  to: _ConceptBoardInput;
};


export type MutationAddConceptListPersonaArgs = {
  from: _ConceptInput;
  to: _ConceptListInput;
};


export type MutationRemoveConceptListPersonaArgs = {
  from: _ConceptInput;
  to: _ConceptListInput;
};


export type MutationMergeConceptListPersonaArgs = {
  from: _ConceptInput;
  to: _ConceptListInput;
};


export type MutationCreateConceptListArgs = {
  id?: Maybe<Scalars['ID']>;
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  type?: Maybe<ConceptListType>;
  isOrdered?: Maybe<Scalars['Boolean']>;
  conceptIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
};


export type MutationUpdateConceptListArgs = {
  id: Scalars['ID'];
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  type?: Maybe<ConceptListType>;
  isOrdered?: Maybe<Scalars['Boolean']>;
  conceptIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
};


export type MutationDeleteConceptListArgs = {
  id: Scalars['ID'];
};


export type MutationMergeConceptListArgs = {
  id: Scalars['ID'];
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  type?: Maybe<ConceptListType>;
  isOrdered?: Maybe<Scalars['Boolean']>;
  conceptIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
};


export type MutationAddConceptBoardColumnsArgs = {
  from: _ConceptListInput;
  to: _ConceptBoardInput;
};


export type MutationRemoveConceptBoardColumnsArgs = {
  from: _ConceptListInput;
  to: _ConceptBoardInput;
};


export type MutationMergeConceptBoardColumnsArgs = {
  from: _ConceptListInput;
  to: _ConceptBoardInput;
};


export type MutationAddConceptBoardUserArgs = {
  from: _UserInput;
  to: _ConceptBoardInput;
};


export type MutationRemoveConceptBoardUserArgs = {
  from: _UserInput;
  to: _ConceptBoardInput;
};


export type MutationMergeConceptBoardUserArgs = {
  from: _UserInput;
  to: _ConceptBoardInput;
};


export type MutationCreateConceptBoardArgs = {
  id?: Maybe<Scalars['ID']>;
  title?: Maybe<Scalars['String']>;
  boardType?: Maybe<Scalars['String']>;
  columnOrder?: Maybe<Array<Maybe<Scalars['ID']>>>;
};


export type MutationUpdateConceptBoardArgs = {
  id: Scalars['ID'];
  title?: Maybe<Scalars['String']>;
  boardType?: Maybe<Scalars['String']>;
  columnOrder?: Maybe<Array<Maybe<Scalars['ID']>>>;
};


export type MutationDeleteConceptBoardArgs = {
  id: Scalars['ID'];
};


export type MutationMergeConceptBoardArgs = {
  id: Scalars['ID'];
  title?: Maybe<Scalars['String']>;
  boardType?: Maybe<Scalars['String']>;
  columnOrder?: Maybe<Array<Maybe<Scalars['ID']>>>;
};


export type MutationCreateLinkArgs = {
  id?: Maybe<Scalars['ID']>;
  url?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  icon?: Maybe<Scalars['String']>;
  keywords?: Maybe<Scalars['String']>;
  language?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  provider?: Maybe<Scalars['String']>;
  addedAt?: Maybe<_Neo4jDateTimeInput>;
  updatedAt?: Maybe<_Neo4jDateTimeInput>;
};


export type MutationUpdateLinkArgs = {
  id: Scalars['ID'];
  url?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  icon?: Maybe<Scalars['String']>;
  keywords?: Maybe<Scalars['String']>;
  language?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  provider?: Maybe<Scalars['String']>;
  addedAt?: Maybe<_Neo4jDateTimeInput>;
  updatedAt?: Maybe<_Neo4jDateTimeInput>;
};


export type MutationDeleteLinkArgs = {
  id: Scalars['ID'];
};


export type MutationMergeLinkArgs = {
  id: Scalars['ID'];
  url?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  icon?: Maybe<Scalars['String']>;
  keywords?: Maybe<Scalars['String']>;
  language?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  provider?: Maybe<Scalars['String']>;
  addedAt?: Maybe<_Neo4jDateTimeInput>;
  updatedAt?: Maybe<_Neo4jDateTimeInput>;
};


export type MutationAddInitiativeLikedByArgs = {
  from: _UserInput;
  to: _InitiativeInput;
};


export type MutationRemoveInitiativeLikedByArgs = {
  from: _UserInput;
  to: _InitiativeInput;
};


export type MutationMergeInitiativeLikedByArgs = {
  from: _UserInput;
  to: _InitiativeInput;
};


export type MutationAddInitiativeTeamArgs = {
  from: _TeamInput;
  to: _InitiativeInput;
};


export type MutationRemoveInitiativeTeamArgs = {
  from: _TeamInput;
  to: _InitiativeInput;
};


export type MutationMergeInitiativeTeamArgs = {
  from: _TeamInput;
  to: _InitiativeInput;
};


export type MutationAddInitiativeUserArgs = {
  from: _UserInput;
  to: _InitiativeInput;
};


export type MutationRemoveInitiativeUserArgs = {
  from: _UserInput;
  to: _InitiativeInput;
};


export type MutationMergeInitiativeUserArgs = {
  from: _UserInput;
  to: _InitiativeInput;
};


export type MutationAddInitiativeConnectionsArgs = {
  from: _InitiativeInput;
  to: _ConceptInput;
};


export type MutationRemoveInitiativeConnectionsArgs = {
  from: _InitiativeInput;
  to: _ConceptInput;
};


export type MutationMergeInitiativeConnectionsArgs = {
  from: _InitiativeInput;
  to: _ConceptInput;
};


export type MutationAddInitiativeTagsArgs = {
  from: _InitiativeInput;
  to: _TagInput;
};


export type MutationRemoveInitiativeTagsArgs = {
  from: _InitiativeInput;
  to: _TagInput;
};


export type MutationMergeInitiativeTagsArgs = {
  from: _InitiativeInput;
  to: _TagInput;
};


export type MutationAddInitiativeCommentsArgs = {
  from: _CommentInput;
  to: _InitiativeInput;
};


export type MutationRemoveInitiativeCommentsArgs = {
  from: _CommentInput;
  to: _InitiativeInput;
};


export type MutationMergeInitiativeCommentsArgs = {
  from: _CommentInput;
  to: _InitiativeInput;
};


export type MutationCreateInitiativeArgs = {
  id?: Maybe<Scalars['ID']>;
  title?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  summary?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  imageUrl?: Maybe<Scalars['String']>;
  createdAt?: Maybe<_Neo4jDateTimeInput>;
  startDate?: Maybe<_Neo4jDateTimeInput>;
  endDate?: Maybe<_Neo4jDateTimeInput>;
  duration?: Maybe<_Neo4jDateTimeInput>;
};


export type MutationUpdateInitiativeArgs = {
  id: Scalars['ID'];
  title?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  summary?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  imageUrl?: Maybe<Scalars['String']>;
  createdAt?: Maybe<_Neo4jDateTimeInput>;
  startDate?: Maybe<_Neo4jDateTimeInput>;
  endDate?: Maybe<_Neo4jDateTimeInput>;
  duration?: Maybe<_Neo4jDateTimeInput>;
};


export type MutationDeleteInitiativeArgs = {
  id: Scalars['ID'];
};


export type MutationMergeInitiativeArgs = {
  id: Scalars['ID'];
  title?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  summary?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  imageUrl?: Maybe<Scalars['String']>;
  createdAt?: Maybe<_Neo4jDateTimeInput>;
  startDate?: Maybe<_Neo4jDateTimeInput>;
  endDate?: Maybe<_Neo4jDateTimeInput>;
  duration?: Maybe<_Neo4jDateTimeInput>;
};


export type MutationAddCategoryParentArgs = {
  from: _CategoryInput;
  to: _CategoryInput;
};


export type MutationRemoveCategoryParentArgs = {
  from: _CategoryInput;
  to: _CategoryInput;
};


export type MutationMergeCategoryParentArgs = {
  from: _CategoryInput;
  to: _CategoryInput;
};


export type MutationAddCategoryChildrenArgs = {
  from: _CategoryInput;
  to: _CategoryInput;
};


export type MutationRemoveCategoryChildrenArgs = {
  from: _CategoryInput;
  to: _CategoryInput;
};


export type MutationMergeCategoryChildrenArgs = {
  from: _CategoryInput;
  to: _CategoryInput;
};


export type MutationAddCategoryConceptsArgs = {
  from: _ConceptInput;
  to: _CategoryInput;
};


export type MutationRemoveCategoryConceptsArgs = {
  from: _ConceptInput;
  to: _CategoryInput;
};


export type MutationMergeCategoryConceptsArgs = {
  from: _ConceptInput;
  to: _CategoryInput;
};


export type MutationAddCategoryDefaultCriteriaArgs = {
  from: _CategoryInput;
  to: _CriteriaInput;
};


export type MutationRemoveCategoryDefaultCriteriaArgs = {
  from: _CategoryInput;
  to: _CriteriaInput;
};


export type MutationMergeCategoryDefaultCriteriaArgs = {
  from: _CategoryInput;
  to: _CriteriaInput;
};


export type MutationAddCategoryCriteriaArgs = {
  from: _CategoryInput;
  to: _CriteriaInput;
};


export type MutationRemoveCategoryCriteriaArgs = {
  from: _CategoryInput;
  to: _CriteriaInput;
};


export type MutationMergeCategoryCriteriaArgs = {
  from: _CategoryInput;
  to: _CriteriaInput;
};


export type MutationAddCategoryDefaultDashboardArgs = {
  from: _CategoryInput;
  to: _DashboardViewInput;
};


export type MutationRemoveCategoryDefaultDashboardArgs = {
  from: _CategoryInput;
  to: _DashboardViewInput;
};


export type MutationMergeCategoryDefaultDashboardArgs = {
  from: _CategoryInput;
  to: _DashboardViewInput;
};


export type MutationAddCategoryWorkflowArgs = {
  from: _WorkflowInput;
  to: _CategoryInput;
};


export type MutationRemoveCategoryWorkflowArgs = {
  from: _WorkflowInput;
  to: _CategoryInput;
};


export type MutationMergeCategoryWorkflowArgs = {
  from: _WorkflowInput;
  to: _CategoryInput;
};


export type MutationCreateCategoryArgs = {
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  isRoot?: Maybe<Scalars['Boolean']>;
  isManaged?: Maybe<Scalars['Boolean']>;
  isFilterOption?: Maybe<Scalars['Boolean']>;
  isSystemDefault?: Maybe<Scalars['Boolean']>;
  accessRoles?: Maybe<Array<Maybe<SystemRole>>>;
  icon?: Maybe<Scalars['String']>;
  depth?: Maybe<Scalars['Int']>;
  conceptType?: Maybe<ConceptType>;
};


export type MutationUpdateCategoryArgs = {
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  isRoot?: Maybe<Scalars['Boolean']>;
  isManaged?: Maybe<Scalars['Boolean']>;
  isFilterOption?: Maybe<Scalars['Boolean']>;
  isSystemDefault?: Maybe<Scalars['Boolean']>;
  accessRoles?: Maybe<Array<Maybe<SystemRole>>>;
  icon?: Maybe<Scalars['String']>;
  depth?: Maybe<Scalars['Int']>;
  conceptType?: Maybe<ConceptType>;
};


export type MutationDeleteCategoryArgs = {
  id: Scalars['ID'];
};


export type MutationMergeCategoryArgs = {
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  isRoot?: Maybe<Scalars['Boolean']>;
  isManaged?: Maybe<Scalars['Boolean']>;
  isFilterOption?: Maybe<Scalars['Boolean']>;
  isSystemDefault?: Maybe<Scalars['Boolean']>;
  accessRoles?: Maybe<Array<Maybe<SystemRole>>>;
  icon?: Maybe<Scalars['String']>;
  depth?: Maybe<Scalars['Int']>;
  conceptType?: Maybe<ConceptType>;
};


export type MutationAddInputCriteriaArgs = {
  from: _InputInput;
  to: _CriteriaInput;
};


export type MutationRemoveInputCriteriaArgs = {
  from: _InputInput;
  to: _CriteriaInput;
};


export type MutationMergeInputCriteriaArgs = {
  from: _InputInput;
  to: _CriteriaInput;
};


export type MutationAddInputParentConceptArgs = {
  from: _InputInput;
  to: _ConceptInput;
};


export type MutationRemoveInputParentConceptArgs = {
  from: _InputInput;
  to: _ConceptInput;
};


export type MutationMergeInputParentConceptArgs = {
  from: _InputInput;
  to: _ConceptInput;
};


export type MutationAddInputIncludedConceptsArgs = {
  from: _InputInput;
  to: _ConceptInput;
};


export type MutationRemoveInputIncludedConceptsArgs = {
  from: _InputInput;
  to: _ConceptInput;
};


export type MutationMergeInputIncludedConceptsArgs = {
  from: _InputInput;
  to: _ConceptInput;
};


export type MutationAddInputConceptsCreatedArgs = {
  from: _InputInput;
  to: _ConceptInput;
};


export type MutationRemoveInputConceptsCreatedArgs = {
  from: _InputInput;
  to: _ConceptInput;
};


export type MutationMergeInputConceptsCreatedArgs = {
  from: _InputInput;
  to: _ConceptInput;
};


export type MutationAddInputCriteriaScoresArgs = {
  from: _InputInput;
  to: _CriteriaScoreInput;
};


export type MutationRemoveInputCriteriaScoresArgs = {
  from: _InputInput;
  to: _CriteriaScoreInput;
};


export type MutationMergeInputCriteriaScoresArgs = {
  from: _InputInput;
  to: _CriteriaScoreInput;
};


export type MutationCreateInputArgs = {
  id?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<_Neo4jDateTimeInput>;
  inputConfig?: Maybe<Scalars['String']>;
};


export type MutationUpdateInputArgs = {
  id: Scalars['ID'];
  createdAt?: Maybe<_Neo4jDateTimeInput>;
  inputConfig?: Maybe<Scalars['String']>;
};


export type MutationDeleteInputArgs = {
  id: Scalars['ID'];
};


export type MutationMergeInputArgs = {
  id: Scalars['ID'];
  createdAt?: Maybe<_Neo4jDateTimeInput>;
  inputConfig?: Maybe<Scalars['String']>;
};


export type MutationAddExerciseCriteriaArgs = {
  from: _ExerciseInput;
  to: _CriteriaInput;
};


export type MutationRemoveExerciseCriteriaArgs = {
  from: _ExerciseInput;
  to: _CriteriaInput;
};


export type MutationMergeExerciseCriteriaArgs = {
  from: _ExerciseInput;
  to: _CriteriaInput;
};


export type MutationAddExerciseTypeArgs = {
  from: _ExerciseInput;
  to: _ExerciseTypeInput;
};


export type MutationRemoveExerciseTypeArgs = {
  from: _ExerciseInput;
  to: _ExerciseTypeInput;
};


export type MutationMergeExerciseTypeArgs = {
  from: _ExerciseInput;
  to: _ExerciseTypeInput;
};


export type MutationAddExerciseFacilitatorsArgs = {
  from: _ExerciseInput;
  to: _UserInput;
};


export type MutationRemoveExerciseFacilitatorsArgs = {
  from: _ExerciseInput;
  to: _UserInput;
};


export type MutationMergeExerciseFacilitatorsArgs = {
  from: _ExerciseInput;
  to: _UserInput;
};


export type MutationAddExerciseInvitedTeamsArgs = {
  from: _ExerciseInput;
  to: _TeamInput;
};


export type MutationRemoveExerciseInvitedTeamsArgs = {
  from: _ExerciseInput;
  to: _TeamInput;
};


export type MutationMergeExerciseInvitedTeamsArgs = {
  from: _ExerciseInput;
  to: _TeamInput;
};


export type MutationAddExerciseInvitedUsersArgs = {
  from: _ExerciseInput;
  to: _UserInput;
};


export type MutationRemoveExerciseInvitedUsersArgs = {
  from: _ExerciseInput;
  to: _UserInput;
};


export type MutationMergeExerciseInvitedUsersArgs = {
  from: _ExerciseInput;
  to: _UserInput;
};


export type MutationAddExerciseParticipantsArgs = {
  from: _ExerciseInput;
  to: _UserInput;
};


export type MutationRemoveExerciseParticipantsArgs = {
  from: _ExerciseInput;
  to: _UserInput;
};


export type MutationMergeExerciseParticipantsArgs = {
  from: _ExerciseInput;
  to: _UserInput;
};


export type MutationAddExerciseCreatedByArgs = {
  from: _ExerciseInput;
  to: _UserInput;
};


export type MutationRemoveExerciseCreatedByArgs = {
  from: _ExerciseInput;
  to: _UserInput;
};


export type MutationMergeExerciseCreatedByArgs = {
  from: _ExerciseInput;
  to: _UserInput;
};


export type MutationAddExerciseSubmissionsArgs = {
  from: _ExerciseSubmissionInput;
  to: _ExerciseInput;
};


export type MutationRemoveExerciseSubmissionsArgs = {
  from: _ExerciseSubmissionInput;
  to: _ExerciseInput;
};


export type MutationMergeExerciseSubmissionsArgs = {
  from: _ExerciseSubmissionInput;
  to: _ExerciseInput;
};


export type MutationAddExerciseParentConceptArgs = {
  from: _ExerciseInput;
  to: _ConceptInput;
};


export type MutationRemoveExerciseParentConceptArgs = {
  from: _ExerciseInput;
  to: _ConceptInput;
};


export type MutationMergeExerciseParentConceptArgs = {
  from: _ExerciseInput;
  to: _ConceptInput;
};


export type MutationAddExerciseIncludedChecklistArgs = {
  from: _ExerciseInput;
  to: _ChecklistInput;
};


export type MutationRemoveExerciseIncludedChecklistArgs = {
  from: _ExerciseInput;
  to: _ChecklistInput;
};


export type MutationMergeExerciseIncludedChecklistArgs = {
  from: _ExerciseInput;
  to: _ChecklistInput;
};


export type MutationAddExerciseIncludedConceptsArgs = {
  from: _ExerciseInput;
  to: _ConceptInput;
};


export type MutationRemoveExerciseIncludedConceptsArgs = {
  from: _ExerciseInput;
  to: _ConceptInput;
};


export type MutationMergeExerciseIncludedConceptsArgs = {
  from: _ExerciseInput;
  to: _ConceptInput;
};


export type MutationAddExerciseConceptsCreatedArgs = {
  from: _ExerciseInput;
  to: _ConceptInput;
};


export type MutationRemoveExerciseConceptsCreatedArgs = {
  from: _ExerciseInput;
  to: _ConceptInput;
};


export type MutationMergeExerciseConceptsCreatedArgs = {
  from: _ExerciseInput;
  to: _ConceptInput;
};


export type MutationAddExerciseCriteriaScoresArgs = {
  from: _ExerciseInput;
  to: _CriteriaScoreInput;
};


export type MutationRemoveExerciseCriteriaScoresArgs = {
  from: _ExerciseInput;
  to: _CriteriaScoreInput;
};


export type MutationMergeExerciseCriteriaScoresArgs = {
  from: _ExerciseInput;
  to: _CriteriaScoreInput;
};


export type MutationCreateExerciseArgs = {
  id?: Maybe<Scalars['ID']>;
  url?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  instructions?: Maybe<Scalars['String']>;
  timerType?: Maybe<TimerType>;
  imageUrl?: Maybe<Scalars['String']>;
  duration?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<_Neo4jDateTimeInput>;
  startTime?: Maybe<_Neo4jDateTimeInput>;
  endTime?: Maybe<_Neo4jDateTimeInput>;
  status?: Maybe<ExerciseStatus>;
  isPublic?: Maybe<Scalars['Boolean']>;
  isTemplate?: Maybe<Scalars['Boolean']>;
  isConceptFeedback?: Maybe<Scalars['Boolean']>;
  exerciseConfig?: Maybe<Scalars['String']>;
};


export type MutationUpdateExerciseArgs = {
  id: Scalars['ID'];
  url?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  instructions?: Maybe<Scalars['String']>;
  timerType?: Maybe<TimerType>;
  imageUrl?: Maybe<Scalars['String']>;
  duration?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<_Neo4jDateTimeInput>;
  startTime?: Maybe<_Neo4jDateTimeInput>;
  endTime?: Maybe<_Neo4jDateTimeInput>;
  status?: Maybe<ExerciseStatus>;
  isPublic?: Maybe<Scalars['Boolean']>;
  isTemplate?: Maybe<Scalars['Boolean']>;
  isConceptFeedback?: Maybe<Scalars['Boolean']>;
  exerciseConfig?: Maybe<Scalars['String']>;
};


export type MutationDeleteExerciseArgs = {
  id: Scalars['ID'];
};


export type MutationMergeExerciseArgs = {
  id: Scalars['ID'];
  url?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  instructions?: Maybe<Scalars['String']>;
  timerType?: Maybe<TimerType>;
  imageUrl?: Maybe<Scalars['String']>;
  duration?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<_Neo4jDateTimeInput>;
  startTime?: Maybe<_Neo4jDateTimeInput>;
  endTime?: Maybe<_Neo4jDateTimeInput>;
  status?: Maybe<ExerciseStatus>;
  isPublic?: Maybe<Scalars['Boolean']>;
  isTemplate?: Maybe<Scalars['Boolean']>;
  isConceptFeedback?: Maybe<Scalars['Boolean']>;
  exerciseConfig?: Maybe<Scalars['String']>;
};


export type MutationAddExerciseTypeCriteriaArgs = {
  from: _ExerciseTypeInput;
  to: _CriteriaInput;
};


export type MutationRemoveExerciseTypeCriteriaArgs = {
  from: _ExerciseTypeInput;
  to: _CriteriaInput;
};


export type MutationMergeExerciseTypeCriteriaArgs = {
  from: _ExerciseTypeInput;
  to: _CriteriaInput;
};


export type MutationAddExerciseTypeCreatedByArgs = {
  from: _ExerciseTypeInput;
  to: _UserInput;
};


export type MutationRemoveExerciseTypeCreatedByArgs = {
  from: _ExerciseTypeInput;
  to: _UserInput;
};


export type MutationMergeExerciseTypeCreatedByArgs = {
  from: _ExerciseTypeInput;
  to: _UserInput;
};


export type MutationAddExerciseTypeExercisesArgs = {
  from: _ExerciseInput;
  to: _ExerciseTypeInput;
};


export type MutationRemoveExerciseTypeExercisesArgs = {
  from: _ExerciseInput;
  to: _ExerciseTypeInput;
};


export type MutationMergeExerciseTypeExercisesArgs = {
  from: _ExerciseInput;
  to: _ExerciseTypeInput;
};


export type MutationCreateExerciseTypeArgs = {
  id?: Maybe<Scalars['ID']>;
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  instructions?: Maybe<Scalars['String']>;
  timerType?: Maybe<TimerType>;
  imageUrl?: Maybe<Scalars['String']>;
  category?: Maybe<ExerciseCategory>;
  isConceptFeedback?: Maybe<Scalars['Boolean']>;
  exerciseTypeConfig?: Maybe<Scalars['String']>;
  criteriaIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
};


export type MutationUpdateExerciseTypeArgs = {
  id: Scalars['ID'];
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  instructions?: Maybe<Scalars['String']>;
  timerType?: Maybe<TimerType>;
  imageUrl?: Maybe<Scalars['String']>;
  category?: Maybe<ExerciseCategory>;
  isConceptFeedback?: Maybe<Scalars['Boolean']>;
  exerciseTypeConfig?: Maybe<Scalars['String']>;
  criteriaIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
};


export type MutationDeleteExerciseTypeArgs = {
  id: Scalars['ID'];
};


export type MutationAddExerciseSubmissionExerciseArgs = {
  from: _ExerciseSubmissionInput;
  to: _ExerciseInput;
};


export type MutationRemoveExerciseSubmissionExerciseArgs = {
  from: _ExerciseSubmissionInput;
  to: _ExerciseInput;
};


export type MutationMergeExerciseSubmissionExerciseArgs = {
  from: _ExerciseSubmissionInput;
  to: _ExerciseInput;
};


export type MutationAddExerciseSubmissionUserArgs = {
  from: _ExerciseSubmissionInput;
  to: _UserInput;
};


export type MutationRemoveExerciseSubmissionUserArgs = {
  from: _ExerciseSubmissionInput;
  to: _UserInput;
};


export type MutationMergeExerciseSubmissionUserArgs = {
  from: _ExerciseSubmissionInput;
  to: _UserInput;
};


export type MutationAddExerciseSubmissionScoresArgs = {
  from: _ExerciseSubmissionInput;
  to: _ExerciseScoreInput;
};


export type MutationRemoveExerciseSubmissionScoresArgs = {
  from: _ExerciseSubmissionInput;
  to: _ExerciseScoreInput;
};


export type MutationMergeExerciseSubmissionScoresArgs = {
  from: _ExerciseSubmissionInput;
  to: _ExerciseScoreInput;
};


export type MutationAddExerciseSubmissionConceptCreatedArgs = {
  from: _ExerciseSubmissionInput;
  to: _ConceptInput;
};


export type MutationRemoveExerciseSubmissionConceptCreatedArgs = {
  from: _ExerciseSubmissionInput;
  to: _ConceptInput;
};


export type MutationMergeExerciseSubmissionConceptCreatedArgs = {
  from: _ExerciseSubmissionInput;
  to: _ConceptInput;
};


export type MutationAddExerciseSubmissionCriteriaScoresArgs = {
  from: _ExerciseSubmissionInput;
  to: _CriteriaScoreInput;
};


export type MutationRemoveExerciseSubmissionCriteriaScoresArgs = {
  from: _ExerciseSubmissionInput;
  to: _CriteriaScoreInput;
};


export type MutationMergeExerciseSubmissionCriteriaScoresArgs = {
  from: _ExerciseSubmissionInput;
  to: _CriteriaScoreInput;
};


export type MutationCreateExerciseSubmissionArgs = {
  id?: Maybe<Scalars['ID']>;
  notes?: Maybe<Scalars['String']>;
  conceptIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  imageUrl?: Maybe<Scalars['String']>;
  status?: Maybe<ExerciseStatus>;
};


export type MutationUpdateExerciseSubmissionArgs = {
  id: Scalars['ID'];
  notes?: Maybe<Scalars['String']>;
  conceptIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  imageUrl?: Maybe<Scalars['String']>;
  status?: Maybe<ExerciseStatus>;
};


export type MutationDeleteExerciseSubmissionArgs = {
  id: Scalars['ID'];
};


export type MutationMergeExerciseSubmissionArgs = {
  id: Scalars['ID'];
  notes?: Maybe<Scalars['String']>;
  conceptIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  imageUrl?: Maybe<Scalars['String']>;
  status?: Maybe<ExerciseStatus>;
};


export type MutationAddExerciseScoreSubmissionArgs = {
  from: _ExerciseSubmissionInput;
  to: _ExerciseScoreInput;
};


export type MutationRemoveExerciseScoreSubmissionArgs = {
  from: _ExerciseSubmissionInput;
  to: _ExerciseScoreInput;
};


export type MutationMergeExerciseScoreSubmissionArgs = {
  from: _ExerciseSubmissionInput;
  to: _ExerciseScoreInput;
};


export type MutationAddExerciseScoreConceptArgs = {
  from: _ConceptInput;
  to: _ExerciseScoreInput;
};


export type MutationRemoveExerciseScoreConceptArgs = {
  from: _ConceptInput;
  to: _ExerciseScoreInput;
};


export type MutationMergeExerciseScoreConceptArgs = {
  from: _ConceptInput;
  to: _ExerciseScoreInput;
};


export type MutationCreateExerciseScoreArgs = {
  id?: Maybe<Scalars['ID']>;
  value?: Maybe<Scalars['Int']>;
  explanation?: Maybe<Scalars['String']>;
};


export type MutationUpdateExerciseScoreArgs = {
  id: Scalars['ID'];
  value?: Maybe<Scalars['Int']>;
  explanation?: Maybe<Scalars['String']>;
};


export type MutationDeleteExerciseScoreArgs = {
  id: Scalars['ID'];
};


export type MutationMergeExerciseScoreArgs = {
  id: Scalars['ID'];
  value?: Maybe<Scalars['Int']>;
  explanation?: Maybe<Scalars['String']>;
};


export type MutationAddCriteriaScoresArgs = {
  from: _CriteriaScoreInput;
  to: _CriteriaInput;
};


export type MutationRemoveCriteriaScoresArgs = {
  from: _CriteriaScoreInput;
  to: _CriteriaInput;
};


export type MutationMergeCriteriaScoresArgs = {
  from: _CriteriaScoreInput;
  to: _CriteriaInput;
};


export type MutationAddCriteriaParentArgs = {
  from: _CriteriaInput;
  to: _CriteriaInput;
};


export type MutationRemoveCriteriaParentArgs = {
  from: _CriteriaInput;
  to: _CriteriaInput;
};


export type MutationMergeCriteriaParentArgs = {
  from: _CriteriaInput;
  to: _CriteriaInput;
};


export type MutationAddCriteriaChildrenArgs = {
  from: _CriteriaInput;
  to: _CriteriaInput;
};


export type MutationRemoveCriteriaChildrenArgs = {
  from: _CriteriaInput;
  to: _CriteriaInput;
};


export type MutationMergeCriteriaChildrenArgs = {
  from: _CriteriaInput;
  to: _CriteriaInput;
};


export type MutationAddCriteriaParentFieldsArgs = {
  from: _CriteriaInput;
  to: _CriteriaInput;
};


export type MutationRemoveCriteriaParentFieldsArgs = {
  from: _CriteriaInput;
  to: _CriteriaInput;
};


export type MutationMergeCriteriaParentFieldsArgs = {
  from: _CriteriaInput;
  to: _CriteriaInput;
};


export type MutationAddCriteriaSubfieldsArgs = {
  from: _CriteriaInput;
  to: _CriteriaInput;
};


export type MutationRemoveCriteriaSubfieldsArgs = {
  from: _CriteriaInput;
  to: _CriteriaInput;
};


export type MutationMergeCriteriaSubfieldsArgs = {
  from: _CriteriaInput;
  to: _CriteriaInput;
};


export type MutationAddCriteriaConceptArgs = {
  from: _ConceptInput;
  to: _CriteriaInput;
};


export type MutationRemoveCriteriaConceptArgs = {
  from: _ConceptInput;
  to: _CriteriaInput;
};


export type MutationMergeCriteriaConceptArgs = {
  from: _ConceptInput;
  to: _CriteriaInput;
};


export type MutationAddCriteriaCategoryArgs = {
  from: _CategoryInput;
  to: _CriteriaInput;
};


export type MutationRemoveCriteriaCategoryArgs = {
  from: _CategoryInput;
  to: _CriteriaInput;
};


export type MutationMergeCriteriaCategoryArgs = {
  from: _CategoryInput;
  to: _CriteriaInput;
};


export type MutationAddCriteriaCategoriesArgs = {
  from: _CategoryInput;
  to: _CriteriaInput;
};


export type MutationRemoveCriteriaCategoriesArgs = {
  from: _CategoryInput;
  to: _CriteriaInput;
};


export type MutationMergeCriteriaCategoriesArgs = {
  from: _CategoryInput;
  to: _CriteriaInput;
};


export type MutationAddCriteriaExerciseArgs = {
  from: _ExerciseInput;
  to: _CriteriaInput;
};


export type MutationRemoveCriteriaExerciseArgs = {
  from: _ExerciseInput;
  to: _CriteriaInput;
};


export type MutationMergeCriteriaExerciseArgs = {
  from: _ExerciseInput;
  to: _CriteriaInput;
};


export type MutationAddCriteriaExerciseTypesArgs = {
  from: _ExerciseTypeInput;
  to: _CriteriaInput;
};


export type MutationRemoveCriteriaExerciseTypesArgs = {
  from: _ExerciseTypeInput;
  to: _CriteriaInput;
};


export type MutationMergeCriteriaExerciseTypesArgs = {
  from: _ExerciseTypeInput;
  to: _CriteriaInput;
};


export type MutationAddCriteriaInputsArgs = {
  from: _InputInput;
  to: _CriteriaInput;
};


export type MutationRemoveCriteriaInputsArgs = {
  from: _InputInput;
  to: _CriteriaInput;
};


export type MutationMergeCriteriaInputsArgs = {
  from: _InputInput;
  to: _CriteriaInput;
};


export type MutationCreateCriteriaArgs = {
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  isDefault?: Maybe<Scalars['Boolean']>;
  criteriaType?: Maybe<Scalars['String']>;
  criteriaOptions?: Maybe<Scalars['String']>;
  inputConfig?: Maybe<Scalars['String']>;
  isArchived?: Maybe<Scalars['Boolean']>;
  childIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
};


export type MutationUpdateCriteriaArgs = {
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  isDefault?: Maybe<Scalars['Boolean']>;
  criteriaType?: Maybe<Scalars['String']>;
  criteriaOptions?: Maybe<Scalars['String']>;
  inputConfig?: Maybe<Scalars['String']>;
  isArchived?: Maybe<Scalars['Boolean']>;
  childIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
};


export type MutationDeleteCriteriaArgs = {
  id: Scalars['ID'];
};


export type MutationMergeCriteriaArgs = {
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  isDefault?: Maybe<Scalars['Boolean']>;
  criteriaType?: Maybe<Scalars['String']>;
  criteriaOptions?: Maybe<Scalars['String']>;
  inputConfig?: Maybe<Scalars['String']>;
  isArchived?: Maybe<Scalars['Boolean']>;
  childIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
};


export type MutationAddCriteriaScoreUserArgs = {
  from: _CriteriaScoreInput;
  to: _UserInput;
};


export type MutationRemoveCriteriaScoreUserArgs = {
  from: _CriteriaScoreInput;
  to: _UserInput;
};


export type MutationMergeCriteriaScoreUserArgs = {
  from: _CriteriaScoreInput;
  to: _UserInput;
};


export type MutationAddCriteriaScoreCriteriaArgs = {
  from: _CriteriaScoreInput;
  to: _CriteriaInput;
};


export type MutationRemoveCriteriaScoreCriteriaArgs = {
  from: _CriteriaScoreInput;
  to: _CriteriaInput;
};


export type MutationMergeCriteriaScoreCriteriaArgs = {
  from: _CriteriaScoreInput;
  to: _CriteriaInput;
};


export type MutationAddCriteriaScoreScoredConceptArgs = {
  from: _CriteriaScoreInput;
  to: _ConceptInput;
};


export type MutationRemoveCriteriaScoreScoredConceptArgs = {
  from: _CriteriaScoreInput;
  to: _ConceptInput;
};


export type MutationMergeCriteriaScoreScoredConceptArgs = {
  from: _CriteriaScoreInput;
  to: _ConceptInput;
};


export type MutationAddCriteriaScoreConceptsArgs = {
  from: _CriteriaScoreInput;
  to: _ConceptInput;
};


export type MutationRemoveCriteriaScoreConceptsArgs = {
  from: _CriteriaScoreInput;
  to: _ConceptInput;
};


export type MutationMergeCriteriaScoreConceptsArgs = {
  from: _CriteriaScoreInput;
  to: _ConceptInput;
};


export type MutationAddCriteriaScoreExerciseSubmissionArgs = {
  from: _ExerciseSubmissionInput;
  to: _CriteriaScoreInput;
};


export type MutationRemoveCriteriaScoreExerciseSubmissionArgs = {
  from: _ExerciseSubmissionInput;
  to: _CriteriaScoreInput;
};


export type MutationMergeCriteriaScoreExerciseSubmissionArgs = {
  from: _ExerciseSubmissionInput;
  to: _CriteriaScoreInput;
};


export type MutationAddCriteriaScoreExerciseArgs = {
  from: _ExerciseInput;
  to: _CriteriaScoreInput;
};


export type MutationRemoveCriteriaScoreExerciseArgs = {
  from: _ExerciseInput;
  to: _CriteriaScoreInput;
};


export type MutationMergeCriteriaScoreExerciseArgs = {
  from: _ExerciseInput;
  to: _CriteriaScoreInput;
};


export type MutationAddCriteriaScoreInputArgs = {
  from: _InputInput;
  to: _CriteriaScoreInput;
};


export type MutationRemoveCriteriaScoreInputArgs = {
  from: _InputInput;
  to: _CriteriaScoreInput;
};


export type MutationMergeCriteriaScoreInputArgs = {
  from: _InputInput;
  to: _CriteriaScoreInput;
};


export type MutationCreateCriteriaScoreArgs = {
  id?: Maybe<Scalars['ID']>;
  label?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['Float']>;
  response?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  chatHistory?: Maybe<Scalars['String']>;
  status?: Maybe<ScoreStatus>;
  conceptDefault?: Maybe<Scalars['Boolean']>;
  lastUpdated?: Maybe<_Neo4jDateTimeInput>;
  isArchived?: Maybe<Scalars['Boolean']>;
  lastUpdatedByUserId?: Maybe<Scalars['String']>;
};


export type MutationUpdateCriteriaScoreArgs = {
  id: Scalars['ID'];
  label?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['Float']>;
  response?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  chatHistory?: Maybe<Scalars['String']>;
  status?: Maybe<ScoreStatus>;
  conceptDefault?: Maybe<Scalars['Boolean']>;
  lastUpdated?: Maybe<_Neo4jDateTimeInput>;
  isArchived?: Maybe<Scalars['Boolean']>;
  lastUpdatedByUserId?: Maybe<Scalars['String']>;
};


export type MutationDeleteCriteriaScoreArgs = {
  id: Scalars['ID'];
};


export type MutationMergeCriteriaScoreArgs = {
  id: Scalars['ID'];
  label?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['Float']>;
  response?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  chatHistory?: Maybe<Scalars['String']>;
  status?: Maybe<ScoreStatus>;
  conceptDefault?: Maybe<Scalars['Boolean']>;
  lastUpdated?: Maybe<_Neo4jDateTimeInput>;
  isArchived?: Maybe<Scalars['Boolean']>;
  lastUpdatedByUserId?: Maybe<Scalars['String']>;
};


export type MutationAddTranslationConceptArgs = {
  from: _ConceptInput;
  to: _TranslationInput;
};


export type MutationRemoveTranslationConceptArgs = {
  from: _ConceptInput;
  to: _TranslationInput;
};


export type MutationMergeTranslationConceptArgs = {
  from: _ConceptInput;
  to: _TranslationInput;
};


export type MutationCreateTranslationArgs = {
  id?: Maybe<Scalars['ID']>;
  languageString?: Maybe<LanguageString>;
  title?: Maybe<Scalars['String']>;
  summary?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
};


export type MutationUpdateTranslationArgs = {
  id: Scalars['ID'];
  languageString?: Maybe<LanguageString>;
  title?: Maybe<Scalars['String']>;
  summary?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
};


export type MutationDeleteTranslationArgs = {
  id: Scalars['ID'];
};


export type MutationMergeTranslationArgs = {
  id: Scalars['ID'];
  languageString?: Maybe<LanguageString>;
  title?: Maybe<Scalars['String']>;
  summary?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
};


export type MutationAddWorkflowStagesArgs = {
  from: _WorkflowInput;
  to: _WorkflowStageInput;
};


export type MutationRemoveWorkflowStagesArgs = {
  from: _WorkflowInput;
  to: _WorkflowStageInput;
};


export type MutationMergeWorkflowStagesArgs = {
  from: _WorkflowInput;
  to: _WorkflowStageInput;
};


export type MutationAddWorkflowCategoriesArgs = {
  from: _WorkflowInput;
  to: _CategoryInput;
};


export type MutationRemoveWorkflowCategoriesArgs = {
  from: _WorkflowInput;
  to: _CategoryInput;
};


export type MutationMergeWorkflowCategoriesArgs = {
  from: _WorkflowInput;
  to: _CategoryInput;
};


export type MutationCreateWorkflowArgs = {
  id?: Maybe<Scalars['ID']>;
  title?: Maybe<Scalars['String']>;
  icon?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  initialAction?: Maybe<Scalars['String']>;
  isDefault?: Maybe<Scalars['Boolean']>;
};


export type MutationUpdateWorkflowArgs = {
  id: Scalars['ID'];
  title?: Maybe<Scalars['String']>;
  icon?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  initialAction?: Maybe<Scalars['String']>;
  isDefault?: Maybe<Scalars['Boolean']>;
};


export type MutationDeleteWorkflowArgs = {
  id: Scalars['ID'];
};


export type MutationMergeWorkflowArgs = {
  id: Scalars['ID'];
  title?: Maybe<Scalars['String']>;
  icon?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  initialAction?: Maybe<Scalars['String']>;
  isDefault?: Maybe<Scalars['Boolean']>;
};


export type MutationAddWorkflowStageWorkflowArgs = {
  from: _WorkflowInput;
  to: _WorkflowStageInput;
};


export type MutationRemoveWorkflowStageWorkflowArgs = {
  from: _WorkflowInput;
  to: _WorkflowStageInput;
};


export type MutationMergeWorkflowStageWorkflowArgs = {
  from: _WorkflowInput;
  to: _WorkflowStageInput;
};


export type MutationAddWorkflowStageStatusesArgs = {
  from: _ConceptStatusInput;
  to: _WorkflowStageInput;
};


export type MutationRemoveWorkflowStageStatusesArgs = {
  from: _ConceptStatusInput;
  to: _WorkflowStageInput;
};


export type MutationMergeWorkflowStageStatusesArgs = {
  from: _ConceptStatusInput;
  to: _WorkflowStageInput;
};


export type MutationAddWorkflowStageLastOptionsArgs = {
  from: _WorkflowStageInput;
  to: _WorkflowStageInput;
};


export type MutationRemoveWorkflowStageLastOptionsArgs = {
  from: _WorkflowStageInput;
  to: _WorkflowStageInput;
};


export type MutationMergeWorkflowStageLastOptionsArgs = {
  from: _WorkflowStageInput;
  to: _WorkflowStageInput;
};


export type MutationAddWorkflowStageNextOptionsArgs = {
  from: _WorkflowStageInput;
  to: _WorkflowStageInput;
};


export type MutationRemoveWorkflowStageNextOptionsArgs = {
  from: _WorkflowStageInput;
  to: _WorkflowStageInput;
};


export type MutationMergeWorkflowStageNextOptionsArgs = {
  from: _WorkflowStageInput;
  to: _WorkflowStageInput;
};


export type MutationCreateWorkflowStageArgs = {
  id?: Maybe<Scalars['ID']>;
  title?: Maybe<Scalars['String']>;
  icon?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  isFirstStage?: Maybe<Scalars['Boolean']>;
  permissionRequired?: Maybe<AccessType>;
  justificationOptions?: Maybe<Scalars['String']>;
  justificationRequired?: Maybe<Scalars['Boolean']>;
};


export type MutationUpdateWorkflowStageArgs = {
  id: Scalars['ID'];
  title?: Maybe<Scalars['String']>;
  icon?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  isFirstStage?: Maybe<Scalars['Boolean']>;
  permissionRequired?: Maybe<AccessType>;
  justificationOptions?: Maybe<Scalars['String']>;
  justificationRequired?: Maybe<Scalars['Boolean']>;
};


export type MutationDeleteWorkflowStageArgs = {
  id: Scalars['ID'];
};


export type MutationMergeWorkflowStageArgs = {
  id: Scalars['ID'];
  title?: Maybe<Scalars['String']>;
  icon?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  isFirstStage?: Maybe<Scalars['Boolean']>;
  permissionRequired?: Maybe<AccessType>;
  justificationOptions?: Maybe<Scalars['String']>;
  justificationRequired?: Maybe<Scalars['Boolean']>;
};


export type MutationAddConceptStatusStageArgs = {
  from: _ConceptStatusInput;
  to: _WorkflowStageInput;
};


export type MutationRemoveConceptStatusStageArgs = {
  from: _ConceptStatusInput;
  to: _WorkflowStageInput;
};


export type MutationMergeConceptStatusStageArgs = {
  from: _ConceptStatusInput;
  to: _WorkflowStageInput;
};


export type MutationAddConceptStatusConceptArgs = {
  from: _ConceptInput;
  to: _ConceptStatusInput;
};


export type MutationRemoveConceptStatusConceptArgs = {
  from: _ConceptInput;
  to: _ConceptStatusInput;
};


export type MutationMergeConceptStatusConceptArgs = {
  from: _ConceptInput;
  to: _ConceptStatusInput;
};


export type MutationCreateConceptStatusArgs = {
  id?: Maybe<Scalars['ID']>;
  isActive?: Maybe<Scalars['Boolean']>;
  justification?: Maybe<Scalars['String']>;
  justificationNotes?: Maybe<Scalars['String']>;
  createdAt?: Maybe<_Neo4jDateTimeInput>;
};


export type MutationUpdateConceptStatusArgs = {
  id: Scalars['ID'];
  isActive?: Maybe<Scalars['Boolean']>;
  justification?: Maybe<Scalars['String']>;
  justificationNotes?: Maybe<Scalars['String']>;
  createdAt?: Maybe<_Neo4jDateTimeInput>;
};


export type MutationDeleteConceptStatusArgs = {
  id: Scalars['ID'];
};


export type MutationMergeConceptStatusArgs = {
  id: Scalars['ID'];
  isActive?: Maybe<Scalars['Boolean']>;
  justification?: Maybe<Scalars['String']>;
  justificationNotes?: Maybe<Scalars['String']>;
  createdAt?: Maybe<_Neo4jDateTimeInput>;
};


export type MutationAddConceptTranslationsArgs = {
  from: _ConceptInput;
  to: _TranslationInput;
};


export type MutationRemoveConceptTranslationsArgs = {
  from: _ConceptInput;
  to: _TranslationInput;
};


export type MutationMergeConceptTranslationsArgs = {
  from: _ConceptInput;
  to: _TranslationInput;
};


export type MutationAddConceptAddedUsersArgs = {
  from: _UserInput;
  to: _ConceptInput;
  data: _UserAccessInput;
};


export type MutationRemoveConceptAddedUsersArgs = {
  from: _UserInput;
  to: _ConceptInput;
};


export type MutationUpdateConceptAddedUsersArgs = {
  from: _UserInput;
  to: _ConceptInput;
  data: _UserAccessInput;
};


export type MutationMergeConceptAddedUsersArgs = {
  from: _UserInput;
  to: _ConceptInput;
  data: _UserAccessInput;
};


export type MutationAddConceptAddedTeamsArgs = {
  from: _TeamInput;
  to: _ConceptInput;
  data: _TeamAccessInput;
};


export type MutationRemoveConceptAddedTeamsArgs = {
  from: _TeamInput;
  to: _ConceptInput;
};


export type MutationUpdateConceptAddedTeamsArgs = {
  from: _TeamInput;
  to: _ConceptInput;
  data: _TeamAccessInput;
};


export type MutationMergeConceptAddedTeamsArgs = {
  from: _TeamInput;
  to: _ConceptInput;
  data: _TeamAccessInput;
};


export type MutationAddConceptUserHomepageArgs = {
  from: _UserInput;
  to: _ConceptInput;
};


export type MutationRemoveConceptUserHomepageArgs = {
  from: _UserInput;
  to: _ConceptInput;
};


export type MutationMergeConceptUserHomepageArgs = {
  from: _UserInput;
  to: _ConceptInput;
};


export type MutationAddConceptTeamHomepageArgs = {
  from: _TeamInput;
  to: _ConceptInput;
};


export type MutationRemoveConceptTeamHomepageArgs = {
  from: _TeamInput;
  to: _ConceptInput;
};


export type MutationMergeConceptTeamHomepageArgs = {
  from: _TeamInput;
  to: _ConceptInput;
};


export type MutationAddConceptIndustriesArgs = {
  from: _ConceptInput;
  to: _IndustryInput;
};


export type MutationRemoveConceptIndustriesArgs = {
  from: _ConceptInput;
  to: _IndustryInput;
};


export type MutationMergeConceptIndustriesArgs = {
  from: _ConceptInput;
  to: _IndustryInput;
};


export type MutationAddConceptRecentUpdatesArgs = {
  from: _UserInput;
  to: _ConceptInput;
  data: _ConceptUpdateInput;
};


export type MutationRemoveConceptRecentUpdatesArgs = {
  from: _UserInput;
  to: _ConceptInput;
};


export type MutationUpdateConceptRecentUpdatesArgs = {
  from: _UserInput;
  to: _ConceptInput;
  data: _ConceptUpdateInput;
};


export type MutationMergeConceptRecentUpdatesArgs = {
  from: _UserInput;
  to: _ConceptInput;
  data: _ConceptUpdateInput;
};


export type MutationAddConceptCreatedByArgs = {
  from: _UserInput;
  to: _ConceptInput;
};


export type MutationRemoveConceptCreatedByArgs = {
  from: _UserInput;
  to: _ConceptInput;
};


export type MutationMergeConceptCreatedByArgs = {
  from: _UserInput;
  to: _ConceptInput;
};


export type MutationAddConceptStatusArgs = {
  from: _ConceptInput;
  to: _ConceptStatusInput;
};


export type MutationRemoveConceptStatusArgs = {
  from: _ConceptInput;
  to: _ConceptStatusInput;
};


export type MutationAddConceptParentArgs = {
  from: _ConceptInput;
  to: _ConceptInput;
};


export type MutationRemoveConceptParentArgs = {
  from: _ConceptInput;
  to: _ConceptInput;
};


export type MutationMergeConceptParentArgs = {
  from: _ConceptInput;
  to: _ConceptInput;
};


export type MutationAddConceptChildrenArgs = {
  from: _ConceptInput;
  to: _ConceptInput;
};


export type MutationRemoveConceptChildrenArgs = {
  from: _ConceptInput;
  to: _ConceptInput;
};


export type MutationMergeConceptChildrenArgs = {
  from: _ConceptInput;
  to: _ConceptInput;
};


export type MutationAddConceptCategoryArgs = {
  from: _ConceptInput;
  to: _CategoryInput;
};


export type MutationRemoveConceptCategoryArgs = {
  from: _ConceptInput;
  to: _CategoryInput;
};


export type MutationMergeConceptCategoryArgs = {
  from: _ConceptInput;
  to: _CategoryInput;
};


export type MutationAddConceptWebLinkArgs = {
  from: _ConceptInput;
  to: _LinkInput;
};


export type MutationRemoveConceptWebLinkArgs = {
  from: _ConceptInput;
  to: _LinkInput;
};


export type MutationMergeConceptWebLinkArgs = {
  from: _ConceptInput;
  to: _LinkInput;
};


export type MutationAddConceptLikedByArgs = {
  from: _UserInput;
  to: _ConceptInput;
};


export type MutationRemoveConceptLikedByArgs = {
  from: _UserInput;
  to: _ConceptInput;
};


export type MutationMergeConceptLikedByArgs = {
  from: _UserInput;
  to: _ConceptInput;
};


export type MutationAddConceptCriteriaArgs = {
  from: _ConceptInput;
  to: _CriteriaInput;
};


export type MutationRemoveConceptCriteriaArgs = {
  from: _ConceptInput;
  to: _CriteriaInput;
};


export type MutationMergeConceptCriteriaArgs = {
  from: _ConceptInput;
  to: _CriteriaInput;
};


export type MutationAddConceptCriteriaScoresArgs = {
  from: _CriteriaScoreInput;
  to: _ConceptInput;
};


export type MutationRemoveConceptCriteriaScoresArgs = {
  from: _CriteriaScoreInput;
  to: _ConceptInput;
};


export type MutationMergeConceptCriteriaScoresArgs = {
  from: _CriteriaScoreInput;
  to: _ConceptInput;
};


export type MutationAddConceptHomeDashboardArgs = {
  from: _ConceptInput;
  to: _DashboardViewInput;
};


export type MutationRemoveConceptHomeDashboardArgs = {
  from: _ConceptInput;
  to: _DashboardViewInput;
};


export type MutationMergeConceptHomeDashboardArgs = {
  from: _ConceptInput;
  to: _DashboardViewInput;
};


export type MutationAddConceptUsesDashboardArgs = {
  from: _ConceptInput;
  to: _DashboardViewInput;
};


export type MutationRemoveConceptUsesDashboardArgs = {
  from: _ConceptInput;
  to: _DashboardViewInput;
};


export type MutationMergeConceptUsesDashboardArgs = {
  from: _ConceptInput;
  to: _DashboardViewInput;
};


export type MutationAddConceptCommentsArgs = {
  from: _CommentInput;
  to: _ConceptInput;
};


export type MutationRemoveConceptCommentsArgs = {
  from: _CommentInput;
  to: _ConceptInput;
};


export type MutationMergeConceptCommentsArgs = {
  from: _CommentInput;
  to: _ConceptInput;
};


export type MutationAddConceptWatchedByArgs = {
  from: _UserInput;
  to: _ConceptInput;
};


export type MutationRemoveConceptWatchedByArgs = {
  from: _UserInput;
  to: _ConceptInput;
};


export type MutationMergeConceptWatchedByArgs = {
  from: _UserInput;
  to: _ConceptInput;
};


export type MutationAddConceptNotificationsArgs = {
  from: _ConceptInput;
  to: _NotificationInput;
};


export type MutationRemoveConceptNotificationsArgs = {
  from: _ConceptInput;
  to: _NotificationInput;
};


export type MutationMergeConceptNotificationsArgs = {
  from: _ConceptInput;
  to: _NotificationInput;
};


export type MutationAddConceptTagsArgs = {
  from: _ConceptInput;
  to: _TagInput;
};


export type MutationRemoveConceptTagsArgs = {
  from: _ConceptInput;
  to: _TagInput;
};


export type MutationMergeConceptTagsArgs = {
  from: _ConceptInput;
  to: _TagInput;
};


export type MutationAddConceptConnectionsArgs = {
  from: _ConceptInput;
  to: _ConceptInput;
};


export type MutationRemoveConceptConnectionsArgs = {
  from: _ConceptInput;
  to: _ConceptInput;
};


export type MutationMergeConceptConnectionsArgs = {
  from: _ConceptInput;
  to: _ConceptInput;
};


export type MutationAddConceptDetailedConnectionsArgs = {
  from: _ConceptInput;
  to: _ConceptInput;
  data: _ConceptConnectionInput;
};


export type MutationRemoveConceptDetailedConnectionsArgs = {
  from: _ConceptInput;
  to: _ConceptInput;
};


export type MutationUpdateConceptDetailedConnectionsArgs = {
  from: _ConceptInput;
  to: _ConceptInput;
  data: _ConceptConnectionInput;
};


export type MutationMergeConceptDetailedConnectionsArgs = {
  from: _ConceptInput;
  to: _ConceptInput;
  data: _ConceptConnectionInput;
};


export type MutationAddConceptTeamsArgs = {
  from: _TeamInput;
  to: _ConceptInput;
};


export type MutationRemoveConceptTeamsArgs = {
  from: _TeamInput;
  to: _ConceptInput;
};


export type MutationMergeConceptTeamsArgs = {
  from: _TeamInput;
  to: _ConceptInput;
};


export type MutationAddConceptInListsArgs = {
  from: _ConceptInput;
  to: _ConceptListInput;
};


export type MutationRemoveConceptInListsArgs = {
  from: _ConceptInput;
  to: _ConceptListInput;
};


export type MutationMergeConceptInListsArgs = {
  from: _ConceptInput;
  to: _ConceptListInput;
};


export type MutationAddConceptWorkspaceArgs = {
  from: _ConceptInput;
  to: _ConceptListInput;
};


export type MutationRemoveConceptWorkspaceArgs = {
  from: _ConceptInput;
  to: _ConceptListInput;
};


export type MutationMergeConceptWorkspaceArgs = {
  from: _ConceptInput;
  to: _ConceptListInput;
};


export type MutationAddConceptChildConceptListsArgs = {
  from: _ConceptInput;
  to: _ConceptListInput;
};


export type MutationRemoveConceptChildConceptListsArgs = {
  from: _ConceptInput;
  to: _ConceptListInput;
};


export type MutationMergeConceptChildConceptListsArgs = {
  from: _ConceptInput;
  to: _ConceptListInput;
};


export type MutationAddConceptChecklistsArgs = {
  from: _ConceptInput;
  to: _ChecklistInput;
};


export type MutationRemoveConceptChecklistsArgs = {
  from: _ConceptInput;
  to: _ChecklistInput;
};


export type MutationMergeConceptChecklistsArgs = {
  from: _ConceptInput;
  to: _ChecklistInput;
};


export type MutationAddConceptChecklistItemLinkArgs = {
  from: _ChecklistItemInput;
  to: _ConceptInput;
};


export type MutationRemoveConceptChecklistItemLinkArgs = {
  from: _ChecklistItemInput;
  to: _ConceptInput;
};


export type MutationMergeConceptChecklistItemLinkArgs = {
  from: _ChecklistItemInput;
  to: _ConceptInput;
};


export type MutationAddConceptExercisesCreatedArgs = {
  from: _ExerciseInput;
  to: _ConceptInput;
};


export type MutationRemoveConceptExercisesCreatedArgs = {
  from: _ExerciseInput;
  to: _ConceptInput;
};


export type MutationMergeConceptExercisesCreatedArgs = {
  from: _ExerciseInput;
  to: _ConceptInput;
};


export type MutationAddConceptExercisesIncludingArgs = {
  from: _ExerciseInput;
  to: _ConceptInput;
};


export type MutationRemoveConceptExercisesIncludingArgs = {
  from: _ExerciseInput;
  to: _ConceptInput;
};


export type MutationMergeConceptExercisesIncludingArgs = {
  from: _ExerciseInput;
  to: _ConceptInput;
};


export type MutationAddConceptCreatedForExerciseSubmissionArgs = {
  from: _ExerciseSubmissionInput;
  to: _ConceptInput;
};


export type MutationRemoveConceptCreatedForExerciseSubmissionArgs = {
  from: _ExerciseSubmissionInput;
  to: _ConceptInput;
};


export type MutationMergeConceptCreatedForExerciseSubmissionArgs = {
  from: _ExerciseSubmissionInput;
  to: _ConceptInput;
};


export type MutationAddConceptInputsArgs = {
  from: _InputInput;
  to: _ConceptInput;
};


export type MutationRemoveConceptInputsArgs = {
  from: _InputInput;
  to: _ConceptInput;
};


export type MutationMergeConceptInputsArgs = {
  from: _InputInput;
  to: _ConceptInput;
};


export type MutationAddConceptIncludedForInputsArgs = {
  from: _InputInput;
  to: _ConceptInput;
};


export type MutationRemoveConceptIncludedForInputsArgs = {
  from: _InputInput;
  to: _ConceptInput;
};


export type MutationMergeConceptIncludedForInputsArgs = {
  from: _InputInput;
  to: _ConceptInput;
};


export type MutationAddConceptCreatedViaInputArgs = {
  from: _InputInput;
  to: _ConceptInput;
};


export type MutationRemoveConceptCreatedViaInputArgs = {
  from: _InputInput;
  to: _ConceptInput;
};


export type MutationMergeConceptCreatedViaInputArgs = {
  from: _InputInput;
  to: _ConceptInput;
};


export type MutationAddConceptUsedByScoreArgs = {
  from: _CriteriaScoreInput;
  to: _ConceptInput;
};


export type MutationRemoveConceptUsedByScoreArgs = {
  from: _CriteriaScoreInput;
  to: _ConceptInput;
};


export type MutationMergeConceptUsedByScoreArgs = {
  from: _CriteriaScoreInput;
  to: _ConceptInput;
};


export type MutationDeleteConceptArgs = {
  id: Scalars['ID'];
};


export type MutationMergeConceptArgs = {
  id: Scalars['ID'];
  slug?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  summary?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  username?: Maybe<Scalars['String']>;
  createdAt?: Maybe<_Neo4jDateTimeInput>;
  startDate?: Maybe<_Neo4jDateTimeInput>;
  endDate?: Maybe<_Neo4jDateTimeInput>;
  isArchived?: Maybe<Scalars['Boolean']>;
  isPublic?: Maybe<Scalars['Boolean']>;
  isPublicAccessType?: Maybe<AccessType>;
  source?: Maybe<Scalars['String']>;
  sourceId?: Maybe<Scalars['String']>;
  vis?: Maybe<Visibility>;
  checklistIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  dashboardIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  imageUrl?: Maybe<Scalars['String']>;
};


export type MutationCreateFileArgs = {
  filename?: Maybe<Scalars['String']>;
  mimetype?: Maybe<Scalars['String']>;
  encoding?: Maybe<Scalars['String']>;
  fileUrl?: Maybe<Scalars['String']>;
};


export type MutationUpdateFileArgs = {
  filename: Scalars['String'];
  mimetype?: Maybe<Scalars['String']>;
  encoding?: Maybe<Scalars['String']>;
  fileUrl?: Maybe<Scalars['String']>;
};


export type MutationDeleteFileArgs = {
  filename: Scalars['String'];
};


export type MutationMergeFileArgs = {
  filename: Scalars['String'];
  mimetype?: Maybe<Scalars['String']>;
  encoding?: Maybe<Scalars['String']>;
  fileUrl?: Maybe<Scalars['String']>;
};

export enum Notification_Type {
  CONCEPT_LIKED = 'CONCEPT_LIKED',
  CONCEPT_UPDATED = 'CONCEPT_UPDATED',
  CONCEPT_LINK_ADDED = 'CONCEPT_LINK_ADDED',
  CONCEPT_LINK_REMOVED = 'CONCEPT_LINK_REMOVED',
  CONCEPT_ADDED_TO_WORKSPACE = 'CONCEPT_ADDED_TO_WORKSPACE',
  CONCEPT_REMOVED_FROM_WORKSPACE = 'CONCEPT_REMOVED_FROM_WORKSPACE',
  COMMENT_ADDED = 'COMMENT_ADDED',
  COMMENT_REPLY_ADDED = 'COMMENT_REPLY_ADDED'
}

export type Notification = {
  __typename?: 'Notification';
  id?: Maybe<Scalars['ID']>;
  content?: Maybe<Scalars['String']>;
  type?: Maybe<Notification_Type>;
  createdAt?: Maybe<_Neo4jDateTime>;
  actionBy?: Maybe<User>;
  concept?: Maybe<Concept>;
  comment?: Maybe<Comment>;
  users?: Maybe<Array<Maybe<User>>>;
  message?: Maybe<Scalars['String']>;
  /** Generated field for querying the Neo4j [system id](https://neo4j.com/docs/cypher-manual/current/functions/scalar/#functions-id) of this node. */
  _id?: Maybe<Scalars['String']>;
};


export type NotificationActionByArgs = {
  filter?: Maybe<_UserFilter>;
};


export type NotificationConceptArgs = {
  filter?: Maybe<_ConceptFilter>;
};


export type NotificationCommentArgs = {
  filter?: Maybe<_CommentFilter>;
};


export type NotificationUsersArgs = {
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<Maybe<_UserOrdering>>>;
  filter?: Maybe<_UserFilter>;
};

export enum Priority {
  LOW = 'LOW',
  MEDIUM = 'MEDIUM',
  HIGH = 'HIGH',
  URGENT = 'URGENT'
}

export type Query = {
  __typename?: 'Query';
  me?: Maybe<User>;
  uploads?: Maybe<Array<Maybe<File>>>;
  fuzzyConceptSearch?: Maybe<Array<Maybe<Concept>>>;
  fuzzyConceptSearchByCategory?: Maybe<Array<Maybe<Concept>>>;
  trendsByLikeCount?: Maybe<Array<Maybe<Concept>>>;
  /** [Generated query](https://grandstack.io/docs/graphql-schema-generation-augmentation#generated-queries) for User type nodes. */
  User?: Maybe<Array<Maybe<User>>>;
  /** [Generated query](https://grandstack.io/docs/graphql-schema-generation-augmentation#generated-queries) for EnvironmentSetting type nodes. */
  EnvironmentSetting?: Maybe<Array<Maybe<EnvironmentSetting>>>;
  /** [Generated query](https://grandstack.io/docs/graphql-schema-generation-augmentation#generated-queries) for Notification type nodes. */
  Notification?: Maybe<Array<Maybe<Notification>>>;
  /** [Generated query](https://grandstack.io/docs/graphql-schema-generation-augmentation#generated-queries) for DashboardView type nodes. */
  DashboardView?: Maybe<Array<Maybe<DashboardView>>>;
  /** [Generated query](https://grandstack.io/docs/graphql-schema-generation-augmentation#generated-queries) for Widget type nodes. */
  Widget?: Maybe<Array<Maybe<Widget>>>;
  /** [Generated query](https://grandstack.io/docs/graphql-schema-generation-augmentation#generated-queries) for WidgetType type nodes. */
  WidgetType?: Maybe<Array<Maybe<WidgetType>>>;
  /** [Generated query](https://grandstack.io/docs/graphql-schema-generation-augmentation#generated-queries) for DesignTheme type nodes. */
  DesignTheme?: Maybe<Array<Maybe<DesignTheme>>>;
  /** [Generated query](https://grandstack.io/docs/graphql-schema-generation-augmentation#generated-queries) for Company type nodes. */
  Company?: Maybe<Array<Maybe<Company>>>;
  /** [Generated query](https://grandstack.io/docs/graphql-schema-generation-augmentation#generated-queries) for Industry type nodes. */
  Industry?: Maybe<Array<Maybe<Industry>>>;
  /** [Generated query](https://grandstack.io/docs/graphql-schema-generation-augmentation#generated-queries) for Team type nodes. */
  Team?: Maybe<Array<Maybe<Team>>>;
  /** [Generated query](https://grandstack.io/docs/graphql-schema-generation-augmentation#generated-queries) for Comment type nodes. */
  Comment?: Maybe<Array<Maybe<Comment>>>;
  /** [Generated query](https://grandstack.io/docs/graphql-schema-generation-augmentation#generated-queries) for Tag type nodes. */
  Tag?: Maybe<Array<Maybe<Tag>>>;
  /** [Generated query](https://grandstack.io/docs/graphql-schema-generation-augmentation#generated-queries) for Checklist type nodes. */
  Checklist?: Maybe<Array<Maybe<Checklist>>>;
  /** [Generated query](https://grandstack.io/docs/graphql-schema-generation-augmentation#generated-queries) for ChecklistType type nodes. */
  ChecklistType?: Maybe<Array<Maybe<ChecklistType>>>;
  /** [Generated query](https://grandstack.io/docs/graphql-schema-generation-augmentation#generated-queries) for ChecklistItem type nodes. */
  ChecklistItem?: Maybe<Array<Maybe<ChecklistItem>>>;
  /** [Generated query](https://grandstack.io/docs/graphql-schema-generation-augmentation#generated-queries) for ConceptList type nodes. */
  ConceptList?: Maybe<Array<Maybe<ConceptList>>>;
  /** [Generated query](https://grandstack.io/docs/graphql-schema-generation-augmentation#generated-queries) for ConceptBoard type nodes. */
  ConceptBoard?: Maybe<Array<Maybe<ConceptBoard>>>;
  /** [Generated query](https://grandstack.io/docs/graphql-schema-generation-augmentation#generated-queries) for Link type nodes. */
  Link?: Maybe<Array<Maybe<Link>>>;
  /** [Generated query](https://grandstack.io/docs/graphql-schema-generation-augmentation#generated-queries) for Initiative type nodes. */
  Initiative?: Maybe<Array<Maybe<Initiative>>>;
  /** [Generated query](https://grandstack.io/docs/graphql-schema-generation-augmentation#generated-queries) for Category type nodes. */
  Category?: Maybe<Array<Maybe<Category>>>;
  /** [Generated query](https://grandstack.io/docs/graphql-schema-generation-augmentation#generated-queries) for Input type nodes. */
  Input?: Maybe<Array<Maybe<Input>>>;
  /** [Generated query](https://grandstack.io/docs/graphql-schema-generation-augmentation#generated-queries) for Exercise type nodes. */
  Exercise?: Maybe<Array<Maybe<Exercise>>>;
  /** [Generated query](https://grandstack.io/docs/graphql-schema-generation-augmentation#generated-queries) for ExerciseType type nodes. */
  ExerciseType?: Maybe<Array<Maybe<ExerciseType>>>;
  /** [Generated query](https://grandstack.io/docs/graphql-schema-generation-augmentation#generated-queries) for ExerciseSubmission type nodes. */
  ExerciseSubmission?: Maybe<Array<Maybe<ExerciseSubmission>>>;
  /** [Generated query](https://grandstack.io/docs/graphql-schema-generation-augmentation#generated-queries) for ExerciseScore type nodes. */
  ExerciseScore?: Maybe<Array<Maybe<ExerciseScore>>>;
  /** [Generated query](https://grandstack.io/docs/graphql-schema-generation-augmentation#generated-queries) for Criteria type nodes. */
  Criteria?: Maybe<Array<Maybe<Criteria>>>;
  /** [Generated query](https://grandstack.io/docs/graphql-schema-generation-augmentation#generated-queries) for CriteriaScore type nodes. */
  CriteriaScore?: Maybe<Array<Maybe<CriteriaScore>>>;
  /** [Generated query](https://grandstack.io/docs/graphql-schema-generation-augmentation#generated-queries) for Translation type nodes. */
  Translation?: Maybe<Array<Maybe<Translation>>>;
  /** [Generated query](https://grandstack.io/docs/graphql-schema-generation-augmentation#generated-queries) for Workflow type nodes. */
  Workflow?: Maybe<Array<Maybe<Workflow>>>;
  /** [Generated query](https://grandstack.io/docs/graphql-schema-generation-augmentation#generated-queries) for WorkflowStage type nodes. */
  WorkflowStage?: Maybe<Array<Maybe<WorkflowStage>>>;
  /** [Generated query](https://grandstack.io/docs/graphql-schema-generation-augmentation#generated-queries) for ConceptStatus type nodes. */
  ConceptStatus?: Maybe<Array<Maybe<ConceptStatus>>>;
  /** [Generated query](https://grandstack.io/docs/graphql-schema-generation-augmentation#generated-queries) for Concept type nodes. */
  Concept?: Maybe<Array<Maybe<Concept>>>;
  /** [Generated query](https://grandstack.io/docs/graphql-schema-generation-augmentation#generated-queries) for File type nodes. */
  File?: Maybe<Array<Maybe<File>>>;
};


export type QueryMeArgs = {
  filter?: Maybe<_UserFilter>;
};


export type QueryUploadsArgs = {
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<Maybe<_FileOrdering>>>;
  filter?: Maybe<_FileFilter>;
};


export type QueryFuzzyConceptSearchArgs = {
  searchString?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<Maybe<_ConceptOrdering>>>;
};


export type QueryFuzzyConceptSearchByCategoryArgs = {
  searchString?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  categoryIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  orderBy?: Maybe<Array<Maybe<_ConceptOrdering>>>;
};


export type QueryTrendsByLikeCountArgs = {
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<Maybe<_ConceptOrdering>>>;
};


export type QueryUserArgs = {
  userId?: Maybe<Scalars['ID']>;
  providerId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  username?: Maybe<Scalars['String']>;
  imageUrl?: Maybe<Scalars['String']>;
  company?: Maybe<Scalars['String']>;
  bio?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  language?: Maybe<LanguageString>;
  lastCheckedNotifications?: Maybe<_Neo4jDateTimeInput>;
  lastLogin?: Maybe<_Neo4jDateTimeInput>;
  createdDate?: Maybe<_Neo4jDateTimeInput>;
  role?: Maybe<SystemRole>;
  activeThemeType?: Maybe<ThemeType>;
  _id?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<Maybe<_UserOrdering>>>;
  filter?: Maybe<_UserFilter>;
};


export type QueryEnvironmentSettingArgs = {
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
  _id?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<Maybe<_EnvironmentSettingOrdering>>>;
  filter?: Maybe<_EnvironmentSettingFilter>;
};


export type QueryNotificationArgs = {
  id?: Maybe<Scalars['ID']>;
  content?: Maybe<Scalars['String']>;
  type?: Maybe<Notification_Type>;
  createdAt?: Maybe<_Neo4jDateTimeInput>;
  _id?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<Maybe<_NotificationOrdering>>>;
  filter?: Maybe<_NotificationFilter>;
};


export type QueryDashboardViewArgs = {
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  layout?: Maybe<Scalars['String']>;
  isTemplate?: Maybe<Scalars['Boolean']>;
  isPublic?: Maybe<Scalars['Boolean']>;
  _id?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<Maybe<_DashboardViewOrdering>>>;
  filter?: Maybe<_DashboardViewFilter>;
};


export type QueryWidgetArgs = {
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  config?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
  isTemplate?: Maybe<Scalars['Boolean']>;
  _id?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<Maybe<_WidgetOrdering>>>;
  filter?: Maybe<_WidgetFilter>;
};


export type QueryWidgetTypeArgs = {
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  component?: Maybe<Scalars['String']>;
  schema?: Maybe<Scalars['String']>;
  inputConfig?: Maybe<Scalars['String']>;
  defaultConfig?: Maybe<Scalars['String']>;
  defaultValue?: Maybe<Scalars['String']>;
  _id?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<Maybe<_WidgetTypeOrdering>>>;
  filter?: Maybe<_WidgetTypeFilter>;
};


export type QueryDesignThemeArgs = {
  themeId?: Maybe<Scalars['ID']>;
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  darkMode?: Maybe<Scalars['Boolean']>;
  primary?: Maybe<Scalars['String']>;
  secondary?: Maybe<Scalars['String']>;
  primaryHue?: Maybe<Scalars['String']>;
  secondaryHue?: Maybe<Scalars['String']>;
  primaryShade?: Maybe<Scalars['Int']>;
  secondaryShade?: Maybe<Scalars['Int']>;
  background?: Maybe<Scalars['String']>;
  _id?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<Maybe<_DesignThemeOrdering>>>;
  filter?: Maybe<_DesignThemeFilter>;
};


export type QueryCompanyArgs = {
  companyId?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  _id?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<Maybe<_CompanyOrdering>>>;
  filter?: Maybe<_CompanyFilter>;
};


export type QueryIndustryArgs = {
  industryId?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  marketSize?: Maybe<Scalars['Float']>;
  _id?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<Maybe<_IndustryOrdering>>>;
  filter?: Maybe<_IndustryFilter>;
};


export type QueryTeamArgs = {
  teamId?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  imageUrl?: Maybe<Scalars['String']>;
  _id?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<Maybe<_TeamOrdering>>>;
  filter?: Maybe<_TeamFilter>;
};


export type QueryCommentArgs = {
  commentId?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<_Neo4jDateTimeInput>;
  body?: Maybe<Scalars['String']>;
  _id?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<Maybe<_CommentOrdering>>>;
  filter?: Maybe<_CommentFilter>;
};


export type QueryTagArgs = {
  name?: Maybe<Scalars['ID']>;
  _id?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<Maybe<_TagOrdering>>>;
  filter?: Maybe<_TagFilter>;
};


export type QueryChecklistArgs = {
  id?: Maybe<Scalars['ID']>;
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  conceptBased?: Maybe<Scalars['Boolean']>;
  isOrdered?: Maybe<Scalars['Boolean']>;
  itemPrefixType?: Maybe<ItemPrefixType>;
  itemIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  listLengthLimit?: Maybe<Scalars['Int']>;
  lastUpdated?: Maybe<_Neo4jDateTimeInput>;
  updates?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdAt?: Maybe<_Neo4jDateTimeInput>;
  _id?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<Maybe<_ChecklistOrdering>>>;
  filter?: Maybe<_ChecklistFilter>;
};


export type QueryChecklistTypeArgs = {
  id?: Maybe<Scalars['ID']>;
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  itemPrefixType?: Maybe<ItemPrefixType>;
  conceptBased?: Maybe<Scalars['Boolean']>;
  _id?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<Maybe<_ChecklistTypeOrdering>>>;
  filter?: Maybe<_ChecklistTypeFilter>;
};


export type QueryChecklistItemArgs = {
  id?: Maybe<Scalars['ID']>;
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  checked?: Maybe<Scalars['Boolean']>;
  lastUpdated?: Maybe<_Neo4jDateTimeInput>;
  displayState?: Maybe<ItemDisplayState>;
  listIndex?: Maybe<Scalars['Int']>;
  updates?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdAt?: Maybe<_Neo4jDateTimeInput>;
  _id?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<Maybe<_ChecklistItemOrdering>>>;
  filter?: Maybe<_ChecklistItemFilter>;
};


export type QueryConceptListArgs = {
  id?: Maybe<Scalars['ID']>;
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  type?: Maybe<ConceptListType>;
  isOrdered?: Maybe<Scalars['Boolean']>;
  conceptIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  _id?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<Maybe<_ConceptListOrdering>>>;
  filter?: Maybe<_ConceptListFilter>;
};


export type QueryConceptBoardArgs = {
  id?: Maybe<Scalars['ID']>;
  title?: Maybe<Scalars['String']>;
  boardType?: Maybe<Scalars['String']>;
  columnOrder?: Maybe<Array<Maybe<Scalars['ID']>>>;
  _id?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<Maybe<_ConceptBoardOrdering>>>;
  filter?: Maybe<_ConceptBoardFilter>;
};


export type QueryLinkArgs = {
  id?: Maybe<Scalars['ID']>;
  url?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  icon?: Maybe<Scalars['String']>;
  keywords?: Maybe<Scalars['String']>;
  language?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  provider?: Maybe<Scalars['String']>;
  addedAt?: Maybe<_Neo4jDateTimeInput>;
  updatedAt?: Maybe<_Neo4jDateTimeInput>;
  _id?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<Maybe<_LinkOrdering>>>;
  filter?: Maybe<_LinkFilter>;
};


export type QueryInitiativeArgs = {
  id?: Maybe<Scalars['ID']>;
  title?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  summary?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  imageUrl?: Maybe<Scalars['String']>;
  createdAt?: Maybe<_Neo4jDateTimeInput>;
  startDate?: Maybe<_Neo4jDateTimeInput>;
  endDate?: Maybe<_Neo4jDateTimeInput>;
  duration?: Maybe<_Neo4jDateTimeInput>;
  _id?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<Maybe<_InitiativeOrdering>>>;
  filter?: Maybe<_InitiativeFilter>;
};


export type QueryCategoryArgs = {
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  isRoot?: Maybe<Scalars['Boolean']>;
  isManaged?: Maybe<Scalars['Boolean']>;
  isFilterOption?: Maybe<Scalars['Boolean']>;
  isSystemDefault?: Maybe<Scalars['Boolean']>;
  accessRoles?: Maybe<Array<Maybe<SystemRole>>>;
  icon?: Maybe<Scalars['String']>;
  depth?: Maybe<Scalars['Int']>;
  conceptType?: Maybe<ConceptType>;
  _id?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<Maybe<_CategoryOrdering>>>;
  filter?: Maybe<_CategoryFilter>;
};


export type QueryInputArgs = {
  id?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<_Neo4jDateTimeInput>;
  inputConfig?: Maybe<Scalars['String']>;
  _id?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<Maybe<_InputOrdering>>>;
  filter?: Maybe<_InputFilter>;
};


export type QueryExerciseArgs = {
  id?: Maybe<Scalars['ID']>;
  url?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  instructions?: Maybe<Scalars['String']>;
  timerType?: Maybe<TimerType>;
  imageUrl?: Maybe<Scalars['String']>;
  duration?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<_Neo4jDateTimeInput>;
  startTime?: Maybe<_Neo4jDateTimeInput>;
  endTime?: Maybe<_Neo4jDateTimeInput>;
  status?: Maybe<ExerciseStatus>;
  isPublic?: Maybe<Scalars['Boolean']>;
  isTemplate?: Maybe<Scalars['Boolean']>;
  isConceptFeedback?: Maybe<Scalars['Boolean']>;
  exerciseConfig?: Maybe<Scalars['String']>;
  _id?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<Maybe<_ExerciseOrdering>>>;
  filter?: Maybe<_ExerciseFilter>;
};


export type QueryExerciseTypeArgs = {
  id?: Maybe<Scalars['ID']>;
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  instructions?: Maybe<Scalars['String']>;
  timerType?: Maybe<TimerType>;
  imageUrl?: Maybe<Scalars['String']>;
  category?: Maybe<ExerciseCategory>;
  isConceptFeedback?: Maybe<Scalars['Boolean']>;
  exerciseTypeConfig?: Maybe<Scalars['String']>;
  criteriaIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  _id?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<Maybe<_ExerciseTypeOrdering>>>;
  filter?: Maybe<_ExerciseTypeFilter>;
};


export type QueryExerciseSubmissionArgs = {
  id?: Maybe<Scalars['ID']>;
  notes?: Maybe<Scalars['String']>;
  conceptIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  imageUrl?: Maybe<Scalars['String']>;
  status?: Maybe<ExerciseStatus>;
  _id?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<Maybe<_ExerciseSubmissionOrdering>>>;
  filter?: Maybe<_ExerciseSubmissionFilter>;
};


export type QueryExerciseScoreArgs = {
  id?: Maybe<Scalars['ID']>;
  value?: Maybe<Scalars['Int']>;
  explanation?: Maybe<Scalars['String']>;
  _id?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<Maybe<_ExerciseScoreOrdering>>>;
  filter?: Maybe<_ExerciseScoreFilter>;
};


export type QueryCriteriaArgs = {
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  isDefault?: Maybe<Scalars['Boolean']>;
  criteriaType?: Maybe<Scalars['String']>;
  criteriaOptions?: Maybe<Scalars['String']>;
  inputConfig?: Maybe<Scalars['String']>;
  isArchived?: Maybe<Scalars['Boolean']>;
  childIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  _id?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<Maybe<_CriteriaOrdering>>>;
  filter?: Maybe<_CriteriaFilter>;
};


export type QueryCriteriaScoreArgs = {
  id?: Maybe<Scalars['ID']>;
  label?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['Float']>;
  response?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  chatHistory?: Maybe<Scalars['String']>;
  status?: Maybe<ScoreStatus>;
  conceptDefault?: Maybe<Scalars['Boolean']>;
  lastUpdated?: Maybe<_Neo4jDateTimeInput>;
  isArchived?: Maybe<Scalars['Boolean']>;
  lastUpdatedByUserId?: Maybe<Scalars['String']>;
  _id?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<Maybe<_CriteriaScoreOrdering>>>;
  filter?: Maybe<_CriteriaScoreFilter>;
};


export type QueryTranslationArgs = {
  id?: Maybe<Scalars['ID']>;
  languageString?: Maybe<LanguageString>;
  title?: Maybe<Scalars['String']>;
  summary?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  _id?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<Maybe<_TranslationOrdering>>>;
  filter?: Maybe<_TranslationFilter>;
};


export type QueryWorkflowArgs = {
  id?: Maybe<Scalars['ID']>;
  title?: Maybe<Scalars['String']>;
  icon?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  initialAction?: Maybe<Scalars['String']>;
  isDefault?: Maybe<Scalars['Boolean']>;
  _id?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<Maybe<_WorkflowOrdering>>>;
  filter?: Maybe<_WorkflowFilter>;
};


export type QueryWorkflowStageArgs = {
  id?: Maybe<Scalars['ID']>;
  title?: Maybe<Scalars['String']>;
  icon?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  isFirstStage?: Maybe<Scalars['Boolean']>;
  permissionRequired?: Maybe<AccessType>;
  justificationOptions?: Maybe<Scalars['String']>;
  justificationRequired?: Maybe<Scalars['Boolean']>;
  _id?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<Maybe<_WorkflowStageOrdering>>>;
  filter?: Maybe<_WorkflowStageFilter>;
};


export type QueryConceptStatusArgs = {
  id?: Maybe<Scalars['ID']>;
  isActive?: Maybe<Scalars['Boolean']>;
  justification?: Maybe<Scalars['String']>;
  justificationNotes?: Maybe<Scalars['String']>;
  createdAt?: Maybe<_Neo4jDateTimeInput>;
  _id?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<Maybe<_ConceptStatusOrdering>>>;
  filter?: Maybe<_ConceptStatusFilter>;
};


export type QueryConceptArgs = {
  id?: Maybe<Scalars['ID']>;
  slug?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  summary?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  username?: Maybe<Scalars['String']>;
  createdAt?: Maybe<_Neo4jDateTimeInput>;
  startDate?: Maybe<_Neo4jDateTimeInput>;
  endDate?: Maybe<_Neo4jDateTimeInput>;
  isArchived?: Maybe<Scalars['Boolean']>;
  isPublic?: Maybe<Scalars['Boolean']>;
  isPublicAccessType?: Maybe<AccessType>;
  source?: Maybe<Scalars['String']>;
  sourceId?: Maybe<Scalars['String']>;
  vis?: Maybe<Visibility>;
  checklistIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  dashboardIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  imageUrl?: Maybe<Scalars['String']>;
  _id?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<Maybe<_ConceptOrdering>>>;
  filter?: Maybe<_ConceptFilter>;
};


export type QueryFileArgs = {
  filename?: Maybe<Scalars['String']>;
  mimetype?: Maybe<Scalars['String']>;
  encoding?: Maybe<Scalars['String']>;
  fileUrl?: Maybe<Scalars['String']>;
  _id?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<Maybe<_FileOrdering>>>;
  filter?: Maybe<_FileFilter>;
};

export enum ScopingStatus {
  NOT_STARTED = 'NOT_STARTED',
  IN_PROGRESS = 'IN_PROGRESS',
  COMPLETE = 'COMPLETE'
}

export enum ScoreStatus {
  NOT_STARTED = 'NOT_STARTED',
  IN_PROGRESS = 'IN_PROGRESS',
  SUBMITTED = 'SUBMITTED',
  UPDATING = 'UPDATING'
}

export enum SystemRole {
  ADMIN = 'ADMIN',
  MANAGER = 'MANAGER',
  MEMBER = 'MEMBER',
  GUEST = 'GUEST',
  NONE = 'NONE'
}

export type Tag = {
  __typename?: 'Tag';
  name: Scalars['ID'];
  tagged?: Maybe<Array<Maybe<Concept>>>;
  related?: Maybe<Array<Maybe<Tag>>>;
  /** Generated field for querying the Neo4j [system id](https://neo4j.com/docs/cypher-manual/current/functions/scalar/#functions-id) of this node. */
  _id?: Maybe<Scalars['String']>;
};


export type TagTaggedArgs = {
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<Maybe<_ConceptOrdering>>>;
  filter?: Maybe<_ConceptFilter>;
};


export type TagRelatedArgs = {
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<Maybe<_TagOrdering>>>;
};

export type Team = {
  __typename?: 'Team';
  teamId?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  imageUrl?: Maybe<Scalars['String']>;
  leader?: Maybe<User>;
  admin?: Maybe<Array<Maybe<User>>>;
  members?: Maybe<Array<Maybe<User>>>;
  company?: Maybe<Company>;
  concepts?: Maybe<Array<Maybe<Concept>>>;
  homeConcept?: Maybe<Concept>;
  addedToConcepts?: Maybe<Array<Maybe<_TeamAddedToConcepts>>>;
  conceptLists?: Maybe<Array<Maybe<ConceptList>>>;
  exercisesInvitedTo?: Maybe<Array<Maybe<Exercise>>>;
  /** Generated field for querying the Neo4j [system id](https://neo4j.com/docs/cypher-manual/current/functions/scalar/#functions-id) of this node. */
  _id?: Maybe<Scalars['String']>;
};


export type TeamLeaderArgs = {
  filter?: Maybe<_UserFilter>;
};


export type TeamAdminArgs = {
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<Maybe<_UserOrdering>>>;
  filter?: Maybe<_UserFilter>;
};


export type TeamMembersArgs = {
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<Maybe<_UserOrdering>>>;
  filter?: Maybe<_UserFilter>;
};


export type TeamCompanyArgs = {
  filter?: Maybe<_CompanyFilter>;
};


export type TeamConceptsArgs = {
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<Maybe<_ConceptOrdering>>>;
  filter?: Maybe<_ConceptFilter>;
};


export type TeamHomeConceptArgs = {
  filter?: Maybe<_ConceptFilter>;
};


export type TeamAddedToConceptsArgs = {
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<Maybe<_TeamAccessOrdering>>>;
  filter?: Maybe<_TeamTeamAccessFilter>;
};


export type TeamConceptListsArgs = {
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<Maybe<_ConceptListOrdering>>>;
  filter?: Maybe<_ConceptListFilter>;
};


export type TeamExercisesInvitedToArgs = {
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<Maybe<_ExerciseOrdering>>>;
  filter?: Maybe<_ExerciseFilter>;
};

export type TeamAccess = {
  __typename?: 'TeamAccess';
  from?: Maybe<Team>;
  to?: Maybe<Concept>;
  type?: Maybe<AccessType>;
  updatedAt?: Maybe<_Neo4jDateTime>;
};

export type TeamInput = {
  teamId?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  memberIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  leaderId?: Maybe<Scalars['String']>;
  imageUrl?: Maybe<Scalars['String']>;
};

export enum ThemeType {
  LIGHT = 'LIGHT',
  DARK = 'DARK',
  DAYLIGHT = 'DAYLIGHT'
}

export enum TimerType {
  COUNT_UP = 'COUNT_UP',
  COUNT_DOWN = 'COUNT_DOWN',
  UNTIMED = 'UNTIMED'
}

export type Translation = {
  __typename?: 'Translation';
  id?: Maybe<Scalars['ID']>;
  languageString?: Maybe<LanguageString>;
  title?: Maybe<Scalars['String']>;
  summary?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  concept?: Maybe<Concept>;
  /** Generated field for querying the Neo4j [system id](https://neo4j.com/docs/cypher-manual/current/functions/scalar/#functions-id) of this node. */
  _id?: Maybe<Scalars['String']>;
};


export type TranslationConceptArgs = {
  filter?: Maybe<_ConceptFilter>;
};


export type User = {
  __typename?: 'User';
  userId?: Maybe<Scalars['ID']>;
  providerId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  fullName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  username?: Maybe<Scalars['String']>;
  imageUrl?: Maybe<Scalars['String']>;
  company?: Maybe<Scalars['String']>;
  bio?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  language?: Maybe<LanguageString>;
  lastCheckedNotifications?: Maybe<_Neo4jDateTime>;
  lastLogin?: Maybe<_Neo4jDateTime>;
  createdDate?: Maybe<_Neo4jDateTime>;
  role?: Maybe<SystemRole>;
  recentUpdates?: Maybe<Array<Maybe<_UserRecentUpdates>>>;
  liked?: Maybe<Array<Maybe<Concept>>>;
  addedToConcepts?: Maybe<Array<Maybe<_UserAddedToConcepts>>>;
  homeConcept?: Maybe<Concept>;
  conceptsCreated?: Maybe<Array<Maybe<Concept>>>;
  numConceptsCreated?: Maybe<Scalars['Int']>;
  activeThemeType?: Maybe<ThemeType>;
  activeLightTheme?: Maybe<DesignTheme>;
  activeDarkTheme?: Maybe<DesignTheme>;
  savedThemes?: Maybe<Array<Maybe<DesignTheme>>>;
  createdThemes?: Maybe<Array<Maybe<DesignTheme>>>;
  savedDashboards?: Maybe<DashboardView>;
  createdDashboard?: Maybe<DashboardView>;
  teams?: Maybe<Array<Maybe<Team>>>;
  admin?: Maybe<Array<Maybe<Team>>>;
  leads?: Maybe<Array<Maybe<Team>>>;
  comments?: Maybe<Array<Maybe<Comment>>>;
  watchedConcepts?: Maybe<Array<Maybe<Concept>>>;
  notificationsTriggered?: Maybe<User>;
  notifications?: Maybe<Array<Maybe<Notification>>>;
  workspaceInbox?: Maybe<ConceptList>;
  conceptLists?: Maybe<Array<Maybe<ConceptList>>>;
  conceptBoard?: Maybe<ConceptBoard>;
  exercises?: Maybe<Array<Maybe<Exercise>>>;
  exercisesFacilitated?: Maybe<Array<Maybe<Exercise>>>;
  exercisesInvitedTo?: Maybe<Array<Maybe<Exercise>>>;
  exercisesParticipated?: Maybe<Array<Maybe<Exercise>>>;
  exercisesCreated?: Maybe<Array<Maybe<Exercise>>>;
  exerciseTypesCreated?: Maybe<Array<Maybe<ExerciseType>>>;
  exerciseSubmissions?: Maybe<Array<Maybe<ExerciseSubmission>>>;
  criteriaScores?: Maybe<Array<Maybe<CriteriaScore>>>;
  /** Generated field for querying the Neo4j [system id](https://neo4j.com/docs/cypher-manual/current/functions/scalar/#functions-id) of this node. */
  _id?: Maybe<Scalars['String']>;
};


export type UserRecentUpdatesArgs = {
  limit?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<Maybe<_ConceptUpdateOrdering>>>;
  filter?: Maybe<_UserConceptUpdateFilter>;
};


export type UserLikedArgs = {
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<Maybe<_ConceptOrdering>>>;
  filter?: Maybe<_ConceptFilter>;
};


export type UserAddedToConceptsArgs = {
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<Maybe<_UserAccessOrdering>>>;
  filter?: Maybe<_UserUserAccessFilter>;
};


export type UserHomeConceptArgs = {
  filter?: Maybe<_ConceptFilter>;
};


export type UserConceptsCreatedArgs = {
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<Maybe<_ConceptOrdering>>>;
  filter?: Maybe<_ConceptFilter>;
};


export type UserActiveLightThemeArgs = {
  filter?: Maybe<_DesignThemeFilter>;
};


export type UserActiveDarkThemeArgs = {
  filter?: Maybe<_DesignThemeFilter>;
};


export type UserSavedThemesArgs = {
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<Maybe<_DesignThemeOrdering>>>;
  filter?: Maybe<_DesignThemeFilter>;
};


export type UserCreatedThemesArgs = {
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<Maybe<_DesignThemeOrdering>>>;
  filter?: Maybe<_DesignThemeFilter>;
};


export type UserSavedDashboardsArgs = {
  filter?: Maybe<_DashboardViewFilter>;
};


export type UserCreatedDashboardArgs = {
  filter?: Maybe<_DashboardViewFilter>;
};


export type UserTeamsArgs = {
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<Maybe<_TeamOrdering>>>;
  filter?: Maybe<_TeamFilter>;
};


export type UserAdminArgs = {
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<Maybe<_TeamOrdering>>>;
  filter?: Maybe<_TeamFilter>;
};


export type UserLeadsArgs = {
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<Maybe<_TeamOrdering>>>;
  filter?: Maybe<_TeamFilter>;
};


export type UserCommentsArgs = {
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<Maybe<_CommentOrdering>>>;
  filter?: Maybe<_CommentFilter>;
};


export type UserWatchedConceptsArgs = {
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<Maybe<_ConceptOrdering>>>;
  filter?: Maybe<_ConceptFilter>;
};


export type UserNotificationsTriggeredArgs = {
  filter?: Maybe<_UserFilter>;
};


export type UserNotificationsArgs = {
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<Maybe<_NotificationOrdering>>>;
  filter?: Maybe<_NotificationFilter>;
};


export type UserWorkspaceInboxArgs = {
  filter?: Maybe<_ConceptListFilter>;
};


export type UserConceptListsArgs = {
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<Maybe<_ConceptListOrdering>>>;
  filter?: Maybe<_ConceptListFilter>;
};


export type UserConceptBoardArgs = {
  filter?: Maybe<_ConceptBoardFilter>;
};


export type UserExercisesArgs = {
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<Maybe<_ExerciseOrdering>>>;
};


export type UserExercisesFacilitatedArgs = {
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<Maybe<_ExerciseOrdering>>>;
  filter?: Maybe<_ExerciseFilter>;
};


export type UserExercisesInvitedToArgs = {
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<Maybe<_ExerciseOrdering>>>;
  filter?: Maybe<_ExerciseFilter>;
};


export type UserExercisesParticipatedArgs = {
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<Maybe<_ExerciseOrdering>>>;
  filter?: Maybe<_ExerciseFilter>;
};


export type UserExercisesCreatedArgs = {
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<Maybe<_ExerciseOrdering>>>;
  filter?: Maybe<_ExerciseFilter>;
};


export type UserExerciseTypesCreatedArgs = {
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<Maybe<_ExerciseTypeOrdering>>>;
  filter?: Maybe<_ExerciseTypeFilter>;
};


export type UserExerciseSubmissionsArgs = {
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<Maybe<_ExerciseSubmissionOrdering>>>;
  filter?: Maybe<_ExerciseSubmissionFilter>;
};


export type UserCriteriaScoresArgs = {
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<Maybe<_CriteriaScoreOrdering>>>;
  filter?: Maybe<_CriteriaScoreFilter>;
};

export type UserAccess = {
  __typename?: 'UserAccess';
  from?: Maybe<User>;
  to?: Maybe<Concept>;
  type?: Maybe<AccessType>;
  updatedAt?: Maybe<_Neo4jDateTime>;
};

export type UserLoginInput = {
  email?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
};

export enum Visibility {
  PUBLIC = 'PUBLIC',
  PRIVATE = 'PRIVATE',
  TEAM = 'TEAM',
  COMPANY = 'COMPANY'
}

export type Widget = {
  __typename?: 'Widget';
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  config?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
  isTemplate?: Maybe<Scalars['Boolean']>;
  type?: Maybe<WidgetType>;
  dashboards?: Maybe<Array<Maybe<DashboardView>>>;
  /** Generated field for querying the Neo4j [system id](https://neo4j.com/docs/cypher-manual/current/functions/scalar/#functions-id) of this node. */
  _id?: Maybe<Scalars['String']>;
};


export type WidgetTypeArgs = {
  filter?: Maybe<_WidgetTypeFilter>;
};


export type WidgetDashboardsArgs = {
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<Maybe<_DashboardViewOrdering>>>;
  filter?: Maybe<_DashboardViewFilter>;
};

export type WidgetType = {
  __typename?: 'WidgetType';
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  component?: Maybe<Scalars['String']>;
  schema?: Maybe<Scalars['String']>;
  inputConfig?: Maybe<Scalars['String']>;
  defaultConfig?: Maybe<Scalars['String']>;
  defaultValue?: Maybe<Scalars['String']>;
  widgets?: Maybe<Array<Maybe<Widget>>>;
  /** Generated field for querying the Neo4j [system id](https://neo4j.com/docs/cypher-manual/current/functions/scalar/#functions-id) of this node. */
  _id?: Maybe<Scalars['String']>;
};


export type WidgetTypeWidgetsArgs = {
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<Maybe<_WidgetOrdering>>>;
  filter?: Maybe<_WidgetFilter>;
};

export type Workflow = {
  __typename?: 'Workflow';
  id?: Maybe<Scalars['ID']>;
  title?: Maybe<Scalars['String']>;
  icon?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  initialAction?: Maybe<Scalars['String']>;
  isDefault?: Maybe<Scalars['Boolean']>;
  stages?: Maybe<Array<Maybe<WorkflowStage>>>;
  categories?: Maybe<Array<Maybe<Category>>>;
  /** Generated field for querying the Neo4j [system id](https://neo4j.com/docs/cypher-manual/current/functions/scalar/#functions-id) of this node. */
  _id?: Maybe<Scalars['String']>;
};


export type WorkflowStagesArgs = {
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<Maybe<_WorkflowStageOrdering>>>;
  filter?: Maybe<_WorkflowStageFilter>;
};


export type WorkflowCategoriesArgs = {
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<Maybe<_CategoryOrdering>>>;
  filter?: Maybe<_CategoryFilter>;
};

export type WorkflowStage = {
  __typename?: 'WorkflowStage';
  id?: Maybe<Scalars['ID']>;
  title?: Maybe<Scalars['String']>;
  icon?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  isFirstStage?: Maybe<Scalars['Boolean']>;
  permissionRequired?: Maybe<AccessType>;
  justificationOptions?: Maybe<Scalars['String']>;
  justificationRequired?: Maybe<Scalars['Boolean']>;
  workflow?: Maybe<Workflow>;
  statuses?: Maybe<Array<Maybe<ConceptStatus>>>;
  lastOptions?: Maybe<Array<Maybe<WorkflowStage>>>;
  nextOptions?: Maybe<Array<Maybe<WorkflowStage>>>;
  /** Generated field for querying the Neo4j [system id](https://neo4j.com/docs/cypher-manual/current/functions/scalar/#functions-id) of this node. */
  _id?: Maybe<Scalars['String']>;
};


export type WorkflowStageWorkflowArgs = {
  filter?: Maybe<_WorkflowFilter>;
};


export type WorkflowStageStatusesArgs = {
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<Maybe<_ConceptStatusOrdering>>>;
  filter?: Maybe<_ConceptStatusFilter>;
};


export type WorkflowStageLastOptionsArgs = {
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<Maybe<_WorkflowStageOrdering>>>;
  filter?: Maybe<_WorkflowStageFilter>;
};


export type WorkflowStageNextOptionsArgs = {
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<Maybe<_WorkflowStageOrdering>>>;
  filter?: Maybe<_WorkflowStageFilter>;
};

export type _AddCategoryChildrenPayload = {
  __typename?: '_AddCategoryChildrenPayload';
  /** Field for the Category node this TYPE_OF [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<Category>;
  /** Field for the Category node this TYPE_OF [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<Category>;
};

export type _AddCategoryConceptsPayload = {
  __typename?: '_AddCategoryConceptsPayload';
  /** Field for the Concept node this IN_CATEGORY [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<Concept>;
  /** Field for the Category node this IN_CATEGORY [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<Category>;
};

export type _AddCategoryCriteriaPayload = {
  __typename?: '_AddCategoryCriteriaPayload';
  /** Field for the Category node this CATEGORY_USES_CRITERIA [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<Category>;
  /** Field for the Criteria node this CATEGORY_USES_CRITERIA [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<Criteria>;
};

export type _AddCategoryDefaultCriteriaPayload = {
  __typename?: '_AddCategoryDefaultCriteriaPayload';
  /** Field for the Category node this HAS_DEFAULT_CRITERIA [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<Category>;
  /** Field for the Criteria node this HAS_DEFAULT_CRITERIA [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<Criteria>;
};

export type _AddCategoryDefaultDashboardPayload = {
  __typename?: '_AddCategoryDefaultDashboardPayload';
  /** Field for the Category node this USES_DEFAULT_DASHBOARD [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<Category>;
  /** Field for the DashboardView node this USES_DEFAULT_DASHBOARD [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<DashboardView>;
};

export type _AddCategoryParentPayload = {
  __typename?: '_AddCategoryParentPayload';
  /** Field for the Category node this TYPE_OF [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<Category>;
  /** Field for the Category node this TYPE_OF [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<Category>;
};

export type _AddCategoryWorkflowPayload = {
  __typename?: '_AddCategoryWorkflowPayload';
  /** Field for the Workflow node this DEFAULT_WORKFLOW_FOR_CATEGORY [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<Workflow>;
  /** Field for the Category node this DEFAULT_WORKFLOW_FOR_CATEGORY [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<Category>;
};

export type _AddChecklistConceptPayload = {
  __typename?: '_AddChecklistConceptPayload';
  /** Field for the Concept node this CONCEPT_CHECKLIST [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<Concept>;
  /** Field for the Checklist node this CONCEPT_CHECKLIST [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<Checklist>;
};

export type _AddChecklistCreatedByPayload = {
  __typename?: '_AddChecklistCreatedByPayload';
  /** Field for the User node this CREATED [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<User>;
  /** Field for the Checklist node this CREATED [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<Checklist>;
};

export type _AddChecklistExercisePayload = {
  __typename?: '_AddChecklistExercisePayload';
  /** Field for the Exercise node this EXERCISE_INCLUDES_CHECKLIST [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<Exercise>;
  /** Field for the Checklist node this EXERCISE_INCLUDES_CHECKLIST [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<Checklist>;
};

export type _AddChecklistItemChecklistPayload = {
  __typename?: '_AddChecklistItemChecklistPayload';
  /** Field for the Checklist node this HAS_LIST_ITEM [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<Checklist>;
  /** Field for the ChecklistItem node this HAS_LIST_ITEM [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<ChecklistItem>;
};

export type _AddChecklistItemChildrenPayload = {
  __typename?: '_AddChecklistItemChildrenPayload';
  /** Field for the ChecklistItem node this CL_ITEM_CHILD_OF [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<ChecklistItem>;
  /** Field for the ChecklistItem node this CL_ITEM_CHILD_OF [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<ChecklistItem>;
};

export type _AddChecklistItemConceptPayload = {
  __typename?: '_AddChecklistItemConceptPayload';
  /** Field for the ChecklistItem node this CL_ITEM_LINKED_CONCEPT [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<ChecklistItem>;
  /** Field for the Concept node this CL_ITEM_LINKED_CONCEPT [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<Concept>;
};

export type _AddChecklistItemCreatedByPayload = {
  __typename?: '_AddChecklistItemCreatedByPayload';
  /** Field for the User node this CREATED [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<User>;
  /** Field for the ChecklistItem node this CREATED [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<ChecklistItem>;
};

export type _AddChecklistItemParentPayload = {
  __typename?: '_AddChecklistItemParentPayload';
  /** Field for the ChecklistItem node this CL_ITEM_CHILD_OF [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<ChecklistItem>;
  /** Field for the ChecklistItem node this CL_ITEM_CHILD_OF [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<ChecklistItem>;
};

export type _AddChecklistItemsPayload = {
  __typename?: '_AddChecklistItemsPayload';
  /** Field for the Checklist node this HAS_LIST_ITEM [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<Checklist>;
  /** Field for the ChecklistItem node this HAS_LIST_ITEM [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<ChecklistItem>;
};

export type _AddChecklistTypeChecklistsPayload = {
  __typename?: '_AddChecklistTypeChecklistsPayload';
  /** Field for the Checklist node this CHECKLIST_TYPE [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<Checklist>;
  /** Field for the ChecklistType node this CHECKLIST_TYPE [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<ChecklistType>;
};

export type _AddChecklistTypePayload = {
  __typename?: '_AddChecklistTypePayload';
  /** Field for the Checklist node this CHECKLIST_TYPE [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<Checklist>;
  /** Field for the ChecklistType node this CHECKLIST_TYPE [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<ChecklistType>;
};

export type _AddCommentConceptPayload = {
  __typename?: '_AddCommentConceptPayload';
  /** Field for the Comment node this COMMENT_ON [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<Comment>;
  /** Field for the Concept node this COMMENT_ON [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<Concept>;
};

export type _AddCommentLikedByPayload = {
  __typename?: '_AddCommentLikedByPayload';
  /** Field for the User node this LIKED_COMMENT [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<User>;
  /** Field for the Comment node this LIKED_COMMENT [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<Comment>;
};

export type _AddCommentNotificationsPayload = {
  __typename?: '_AddCommentNotificationsPayload';
  /** Field for the Comment node this COMMENT_TRIGGERED_NOTIFICATION [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<Comment>;
  /** Field for the Notification node this COMMENT_TRIGGERED_NOTIFICATION [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<Notification>;
};

export type _AddCommentRepliesPayload = {
  __typename?: '_AddCommentRepliesPayload';
  /** Field for the Comment node this REPLIED [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<Comment>;
  /** Field for the Comment node this REPLIED [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<Comment>;
};

export type _AddCommentReplyToPayload = {
  __typename?: '_AddCommentReplyToPayload';
  /** Field for the Comment node this REPLIED [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<Comment>;
  /** Field for the Comment node this REPLIED [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<Comment>;
};

export type _AddCommentUserPayload = {
  __typename?: '_AddCommentUserPayload';
  /** Field for the User node this COMMENTED [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<User>;
  /** Field for the Comment node this COMMENTED [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<Comment>;
};

export type _AddCompanyEmployeesPayload = {
  __typename?: '_AddCompanyEmployeesPayload';
  /** Field for the User node this WORKS_AT [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<User>;
  /** Field for the Company node this WORKS_AT [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<Company>;
};

export type _AddCompanyIndustriesPayload = {
  __typename?: '_AddCompanyIndustriesPayload';
  /** Field for the Company node this IN_INDUSTRY [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<Company>;
  /** Field for the Industry node this IN_INDUSTRY [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<Industry>;
};

export type _AddCompanyTeamsPayload = {
  __typename?: '_AddCompanyTeamsPayload';
  /** Field for the Team node this TEAM_AT [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<Team>;
  /** Field for the Company node this TEAM_AT [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<Company>;
};

export type _AddConceptAddedTeamsPayload = {
  __typename?: '_AddConceptAddedTeamsPayload';
  /** Field for the Team node this HAS_ACCESS [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<Team>;
  /** Field for the Concept node this HAS_ACCESS [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<Concept>;
  type?: Maybe<AccessType>;
  updatedAt?: Maybe<_Neo4jDateTime>;
  /** Generated field for querying the Neo4j [system id](https://neo4j.com/docs/cypher-manual/current/functions/scalar/#functions-id) of this relationship. */
  _id?: Maybe<Scalars['String']>;
};

export type _AddConceptAddedUsersPayload = {
  __typename?: '_AddConceptAddedUsersPayload';
  /** Field for the User node this HAS_ACCESS [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<User>;
  /** Field for the Concept node this HAS_ACCESS [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<Concept>;
  type?: Maybe<AccessType>;
  updatedAt?: Maybe<_Neo4jDateTime>;
  /** Generated field for querying the Neo4j [system id](https://neo4j.com/docs/cypher-manual/current/functions/scalar/#functions-id) of this relationship. */
  _id?: Maybe<Scalars['String']>;
};

export type _AddConceptBoardColumnsPayload = {
  __typename?: '_AddConceptBoardColumnsPayload';
  /** Field for the ConceptList node this ON_BOARD [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<ConceptList>;
  /** Field for the ConceptBoard node this ON_BOARD [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<ConceptBoard>;
};

export type _AddConceptBoardUserPayload = {
  __typename?: '_AddConceptBoardUserPayload';
  /** Field for the User node this HAS_CONCEPT_BOARD [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<User>;
  /** Field for the ConceptBoard node this HAS_CONCEPT_BOARD [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<ConceptBoard>;
};

export type _AddConceptCategoryPayload = {
  __typename?: '_AddConceptCategoryPayload';
  /** Field for the Concept node this IN_CATEGORY [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<Concept>;
  /** Field for the Category node this IN_CATEGORY [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<Category>;
};

export type _AddConceptChecklistItemLinkPayload = {
  __typename?: '_AddConceptChecklistItemLinkPayload';
  /** Field for the ChecklistItem node this CL_ITEM_LINKED_CONCEPT [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<ChecklistItem>;
  /** Field for the Concept node this CL_ITEM_LINKED_CONCEPT [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<Concept>;
};

export type _AddConceptChecklistsPayload = {
  __typename?: '_AddConceptChecklistsPayload';
  /** Field for the Concept node this CONCEPT_CHECKLIST [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<Concept>;
  /** Field for the Checklist node this CONCEPT_CHECKLIST [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<Checklist>;
};

export type _AddConceptChildConceptListsPayload = {
  __typename?: '_AddConceptChildConceptListsPayload';
  /** Field for the Concept node this CHILD_CONCEPT_LIST [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<Concept>;
  /** Field for the ConceptList node this CHILD_CONCEPT_LIST [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<ConceptList>;
};

export type _AddConceptChildrenPayload = {
  __typename?: '_AddConceptChildrenPayload';
  /** Field for the Concept node this CHILD_OF [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<Concept>;
  /** Field for the Concept node this CHILD_OF [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<Concept>;
};

export type _AddConceptCommentsPayload = {
  __typename?: '_AddConceptCommentsPayload';
  /** Field for the Comment node this COMMENT_ON [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<Comment>;
  /** Field for the Concept node this COMMENT_ON [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<Concept>;
};

export type _AddConceptConnectionsPayload = {
  __typename?: '_AddConceptConnectionsPayload';
  /** Field for the Concept node this CONNECTED [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<Concept>;
  /** Field for the Concept node this CONNECTED [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<Concept>;
};

export type _AddConceptCreatedByPayload = {
  __typename?: '_AddConceptCreatedByPayload';
  /** Field for the User node this CREATED [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<User>;
  /** Field for the Concept node this CREATED [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<Concept>;
};

export type _AddConceptCreatedForExerciseSubmissionPayload = {
  __typename?: '_AddConceptCreatedForExerciseSubmissionPayload';
  /** Field for the ExerciseSubmission node this SUBMISSION_SPARKED_CONCEPT [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<ExerciseSubmission>;
  /** Field for the Concept node this SUBMISSION_SPARKED_CONCEPT [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<Concept>;
};

export type _AddConceptCreatedViaInputPayload = {
  __typename?: '_AddConceptCreatedViaInputPayload';
  /** Field for the Input node this CREATED_VIA_INPUT [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<Input>;
  /** Field for the Concept node this CREATED_VIA_INPUT [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<Concept>;
};

export type _AddConceptCriteriaPayload = {
  __typename?: '_AddConceptCriteriaPayload';
  /** Field for the Concept node this HAS_CRITERIA [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<Concept>;
  /** Field for the Criteria node this HAS_CRITERIA [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<Criteria>;
};

export type _AddConceptCriteriaScoresPayload = {
  __typename?: '_AddConceptCriteriaScoresPayload';
  /** Field for the CriteriaScore node this SCORE_FOR_CONCEPT [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<CriteriaScore>;
  /** Field for the Concept node this SCORE_FOR_CONCEPT [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<Concept>;
};

export type _AddConceptDetailedConnectionsPayload = {
  __typename?: '_AddConceptDetailedConnectionsPayload';
  /** Field for the Concept node this CONNECTED [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<Concept>;
  /** Field for the Concept node this CONNECTED [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<Concept>;
  description?: Maybe<Scalars['String']>;
  type?: Maybe<ConnectionType>;
  username?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<_Neo4jDateTime>;
  updateValue?: Maybe<Scalars['String']>;
  /** Generated field for querying the Neo4j [system id](https://neo4j.com/docs/cypher-manual/current/functions/scalar/#functions-id) of this relationship. */
  _id?: Maybe<Scalars['String']>;
};

export type _AddConceptExercisesCreatedPayload = {
  __typename?: '_AddConceptExercisesCreatedPayload';
  /** Field for the Exercise node this EXERCISE_DONE_FOR [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<Exercise>;
  /** Field for the Concept node this EXERCISE_DONE_FOR [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<Concept>;
};

export type _AddConceptExercisesIncludingPayload = {
  __typename?: '_AddConceptExercisesIncludingPayload';
  /** Field for the Exercise node this EXERCISE_INCLUDES_CONCEPT [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<Exercise>;
  /** Field for the Concept node this EXERCISE_INCLUDES_CONCEPT [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<Concept>;
};

export type _AddConceptHomeDashboardPayload = {
  __typename?: '_AddConceptHomeDashboardPayload';
  /** Field for the Concept node this HAS_HOME_DASHBOARD [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<Concept>;
  /** Field for the DashboardView node this HAS_HOME_DASHBOARD [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<DashboardView>;
};

export type _AddConceptInListsPayload = {
  __typename?: '_AddConceptInListsPayload';
  /** Field for the Concept node this IN_LIST [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<Concept>;
  /** Field for the ConceptList node this IN_LIST [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<ConceptList>;
};

export type _AddConceptIncludedForInputsPayload = {
  __typename?: '_AddConceptIncludedForInputsPayload';
  /** Field for the Input node this INPUT_INCLUDES_CONCEPT [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<Input>;
  /** Field for the Concept node this INPUT_INCLUDES_CONCEPT [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<Concept>;
};

export type _AddConceptIndustriesPayload = {
  __typename?: '_AddConceptIndustriesPayload';
  /** Field for the Concept node this IMPACTS_INDUSTRY [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<Concept>;
  /** Field for the Industry node this IMPACTS_INDUSTRY [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<Industry>;
};

export type _AddConceptInputsPayload = {
  __typename?: '_AddConceptInputsPayload';
  /** Field for the Input node this INPUT_FOR_CONCEPT [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<Input>;
  /** Field for the Concept node this INPUT_FOR_CONCEPT [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<Concept>;
};

export type _AddConceptLikedByPayload = {
  __typename?: '_AddConceptLikedByPayload';
  /** Field for the User node this LIKED [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<User>;
  /** Field for the Concept node this LIKED [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<Concept>;
};

export type _AddConceptListConceptBoardPayload = {
  __typename?: '_AddConceptListConceptBoardPayload';
  /** Field for the ConceptList node this ON_BOARD [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<ConceptList>;
  /** Field for the ConceptBoard node this ON_BOARD [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<ConceptBoard>;
};

export type _AddConceptListConceptsPayload = {
  __typename?: '_AddConceptListConceptsPayload';
  /** Field for the Concept node this IN_LIST [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<Concept>;
  /** Field for the ConceptList node this IN_LIST [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<ConceptList>;
};

export type _AddConceptListInboxUserPayload = {
  __typename?: '_AddConceptListInboxUserPayload';
  /** Field for the User node this HAS_WORKSPACE_INBOX [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<User>;
  /** Field for the ConceptList node this HAS_WORKSPACE_INBOX [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<ConceptList>;
};

export type _AddConceptListParentConceptPayload = {
  __typename?: '_AddConceptListParentConceptPayload';
  /** Field for the Concept node this CHILD_CONCEPT_LIST [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<Concept>;
  /** Field for the ConceptList node this CHILD_CONCEPT_LIST [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<ConceptList>;
};

export type _AddConceptListPersonaPayload = {
  __typename?: '_AddConceptListPersonaPayload';
  /** Field for the Concept node this PERSONA_REQUIREMENTS [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<Concept>;
  /** Field for the ConceptList node this PERSONA_REQUIREMENTS [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<ConceptList>;
};

export type _AddConceptListTeamPayload = {
  __typename?: '_AddConceptListTeamPayload';
  /** Field for the Team node this TEAM_CONCEPT_LIST [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<Team>;
  /** Field for the ConceptList node this TEAM_CONCEPT_LIST [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<ConceptList>;
};

export type _AddConceptListUserPayload = {
  __typename?: '_AddConceptListUserPayload';
  /** Field for the User node this HAS_CONCEPT_LIST [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<User>;
  /** Field for the ConceptList node this HAS_CONCEPT_LIST [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<ConceptList>;
};

export type _AddConceptListWorkspaceConceptPayload = {
  __typename?: '_AddConceptListWorkspaceConceptPayload';
  /** Field for the Concept node this HAS_CONCEPT_WORKSPACE [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<Concept>;
  /** Field for the ConceptList node this HAS_CONCEPT_WORKSPACE [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<ConceptList>;
};

export type _AddConceptNotificationsPayload = {
  __typename?: '_AddConceptNotificationsPayload';
  /** Field for the Concept node this CONCEPT_TRIGGERED_NOTIFICATION [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<Concept>;
  /** Field for the Notification node this CONCEPT_TRIGGERED_NOTIFICATION [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<Notification>;
};

export type _AddConceptParentPayload = {
  __typename?: '_AddConceptParentPayload';
  /** Field for the Concept node this CHILD_OF [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<Concept>;
  /** Field for the Concept node this CHILD_OF [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<Concept>;
};

export type _AddConceptRecentUpdatesPayload = {
  __typename?: '_AddConceptRecentUpdatesPayload';
  /** Field for the User node this UPDATED [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<User>;
  /** Field for the Concept node this UPDATED [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<Concept>;
  updatedAt?: Maybe<_Neo4jDateTime>;
  updateValue?: Maybe<Scalars['String']>;
  /** Generated field for querying the Neo4j [system id](https://neo4j.com/docs/cypher-manual/current/functions/scalar/#functions-id) of this relationship. */
  _id?: Maybe<Scalars['String']>;
};

export type _AddConceptStatusConceptPayload = {
  __typename?: '_AddConceptStatusConceptPayload';
  /** Field for the Concept node this HAS_STATUS [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<Concept>;
  /** Field for the ConceptStatus node this HAS_STATUS [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<ConceptStatus>;
};

export type _AddConceptStatusPayload = {
  __typename?: '_AddConceptStatusPayload';
  /** Field for the Concept node this HAS_STATUS [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<Concept>;
  /** Field for the ConceptStatus node this HAS_STATUS [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<ConceptStatus>;
};

export type _AddConceptStatusStagePayload = {
  __typename?: '_AddConceptStatusStagePayload';
  /** Field for the ConceptStatus node this HAS_WORKFLOW_STAGE [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<ConceptStatus>;
  /** Field for the WorkflowStage node this HAS_WORKFLOW_STAGE [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<WorkflowStage>;
};

export type _AddConceptTagsPayload = {
  __typename?: '_AddConceptTagsPayload';
  /** Field for the Concept node this TAGGED [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<Concept>;
  /** Field for the Tag node this TAGGED [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<Tag>;
};

export type _AddConceptTeamHomepagePayload = {
  __typename?: '_AddConceptTeamHomepagePayload';
  /** Field for the Team node this TEAM_HOME_CONCEPT [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<Team>;
  /** Field for the Concept node this TEAM_HOME_CONCEPT [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<Concept>;
};

export type _AddConceptTeamsPayload = {
  __typename?: '_AddConceptTeamsPayload';
  /** Field for the Team node this TEAM_CONCEPT [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<Team>;
  /** Field for the Concept node this TEAM_CONCEPT [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<Concept>;
};

export type _AddConceptTranslationsPayload = {
  __typename?: '_AddConceptTranslationsPayload';
  /** Field for the Concept node this HAS_TRANSLATION [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<Concept>;
  /** Field for the Translation node this HAS_TRANSLATION [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<Translation>;
};

export type _AddConceptUsedByScorePayload = {
  __typename?: '_AddConceptUsedByScorePayload';
  /** Field for the CriteriaScore node this CONCEPTS_IN_SCORE [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<CriteriaScore>;
  /** Field for the Concept node this CONCEPTS_IN_SCORE [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<Concept>;
};

export type _AddConceptUserHomepagePayload = {
  __typename?: '_AddConceptUserHomepagePayload';
  /** Field for the User node this USER_HOME_CONCEPT [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<User>;
  /** Field for the Concept node this USER_HOME_CONCEPT [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<Concept>;
};

export type _AddConceptUsesDashboardPayload = {
  __typename?: '_AddConceptUsesDashboardPayload';
  /** Field for the Concept node this SAVED_DASHBOARD [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<Concept>;
  /** Field for the DashboardView node this SAVED_DASHBOARD [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<DashboardView>;
};

export type _AddConceptWatchedByPayload = {
  __typename?: '_AddConceptWatchedByPayload';
  /** Field for the User node this WATCHING [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<User>;
  /** Field for the Concept node this WATCHING [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<Concept>;
};

export type _AddConceptWebLinkPayload = {
  __typename?: '_AddConceptWebLinkPayload';
  /** Field for the Concept node this HAS_WEB_LINK [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<Concept>;
  /** Field for the Link node this HAS_WEB_LINK [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<Link>;
};

export type _AddConceptWorkspacePayload = {
  __typename?: '_AddConceptWorkspacePayload';
  /** Field for the Concept node this HAS_CONCEPT_WORKSPACE [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<Concept>;
  /** Field for the ConceptList node this HAS_CONCEPT_WORKSPACE [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<ConceptList>;
};

export type _AddCriteriaCategoriesPayload = {
  __typename?: '_AddCriteriaCategoriesPayload';
  /** Field for the Category node this CATEGORY_USES_CRITERIA [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<Category>;
  /** Field for the Criteria node this CATEGORY_USES_CRITERIA [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<Criteria>;
};

export type _AddCriteriaCategoryPayload = {
  __typename?: '_AddCriteriaCategoryPayload';
  /** Field for the Category node this HAS_DEFAULT_CRITERIA [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<Category>;
  /** Field for the Criteria node this HAS_DEFAULT_CRITERIA [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<Criteria>;
};

export type _AddCriteriaChildrenPayload = {
  __typename?: '_AddCriteriaChildrenPayload';
  /** Field for the Criteria node this CHILD_OF_CRITERIA [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<Criteria>;
  /** Field for the Criteria node this CHILD_OF_CRITERIA [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<Criteria>;
};

export type _AddCriteriaConceptPayload = {
  __typename?: '_AddCriteriaConceptPayload';
  /** Field for the Concept node this HAS_CRITERIA [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<Concept>;
  /** Field for the Criteria node this HAS_CRITERIA [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<Criteria>;
};

export type _AddCriteriaExercisePayload = {
  __typename?: '_AddCriteriaExercisePayload';
  /** Field for the Exercise node this INCLUDES_CRITERIA [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<Exercise>;
  /** Field for the Criteria node this INCLUDES_CRITERIA [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<Criteria>;
};

export type _AddCriteriaExerciseTypesPayload = {
  __typename?: '_AddCriteriaExerciseTypesPayload';
  /** Field for the ExerciseType node this TYPE_INCLUDES_CRITERIA [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<ExerciseType>;
  /** Field for the Criteria node this TYPE_INCLUDES_CRITERIA [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<Criteria>;
};

export type _AddCriteriaInputsPayload = {
  __typename?: '_AddCriteriaInputsPayload';
  /** Field for the Input node this INPUT_USES_CRITERIA [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<Input>;
  /** Field for the Criteria node this INPUT_USES_CRITERIA [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<Criteria>;
};

export type _AddCriteriaParentFieldsPayload = {
  __typename?: '_AddCriteriaParentFieldsPayload';
  /** Field for the Criteria node this HAS_SUBFIELD [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<Criteria>;
  /** Field for the Criteria node this HAS_SUBFIELD [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<Criteria>;
};

export type _AddCriteriaParentPayload = {
  __typename?: '_AddCriteriaParentPayload';
  /** Field for the Criteria node this CHILD_OF_CRITERIA [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<Criteria>;
  /** Field for the Criteria node this CHILD_OF_CRITERIA [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<Criteria>;
};

export type _AddCriteriaScoreConceptsPayload = {
  __typename?: '_AddCriteriaScoreConceptsPayload';
  /** Field for the CriteriaScore node this CONCEPTS_IN_SCORE [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<CriteriaScore>;
  /** Field for the Concept node this CONCEPTS_IN_SCORE [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<Concept>;
};

export type _AddCriteriaScoreCriteriaPayload = {
  __typename?: '_AddCriteriaScoreCriteriaPayload';
  /** Field for the CriteriaScore node this SCORE_FOR_CRITERIA [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<CriteriaScore>;
  /** Field for the Criteria node this SCORE_FOR_CRITERIA [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<Criteria>;
};

export type _AddCriteriaScoreExercisePayload = {
  __typename?: '_AddCriteriaScoreExercisePayload';
  /** Field for the Exercise node this EXERCISE_HAS_CRITERIA_SCORE [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<Exercise>;
  /** Field for the CriteriaScore node this EXERCISE_HAS_CRITERIA_SCORE [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<CriteriaScore>;
};

export type _AddCriteriaScoreExerciseSubmissionPayload = {
  __typename?: '_AddCriteriaScoreExerciseSubmissionPayload';
  /** Field for the ExerciseSubmission node this HAS_CRITERIA_SCORE [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<ExerciseSubmission>;
  /** Field for the CriteriaScore node this HAS_CRITERIA_SCORE [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<CriteriaScore>;
};

export type _AddCriteriaScoreInputPayload = {
  __typename?: '_AddCriteriaScoreInputPayload';
  /** Field for the Input node this INPUT_HAS_CRITERIA_SCORE [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<Input>;
  /** Field for the CriteriaScore node this INPUT_HAS_CRITERIA_SCORE [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<CriteriaScore>;
};

export type _AddCriteriaScoreScoredConceptPayload = {
  __typename?: '_AddCriteriaScoreScoredConceptPayload';
  /** Field for the CriteriaScore node this SCORE_FOR_CONCEPT [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<CriteriaScore>;
  /** Field for the Concept node this SCORE_FOR_CONCEPT [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<Concept>;
};

export type _AddCriteriaScoreUserPayload = {
  __typename?: '_AddCriteriaScoreUserPayload';
  /** Field for the CriteriaScore node this SCORED_BY [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<CriteriaScore>;
  /** Field for the User node this SCORED_BY [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<User>;
};

export type _AddCriteriaScoresPayload = {
  __typename?: '_AddCriteriaScoresPayload';
  /** Field for the CriteriaScore node this SCORE_FOR_CRITERIA [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<CriteriaScore>;
  /** Field for the Criteria node this SCORE_FOR_CRITERIA [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<Criteria>;
};

export type _AddCriteriaSubfieldsPayload = {
  __typename?: '_AddCriteriaSubfieldsPayload';
  /** Field for the Criteria node this HAS_SUBFIELD [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<Criteria>;
  /** Field for the Criteria node this HAS_SUBFIELD [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<Criteria>;
};

export type _AddDashboardViewActiveThemePayload = {
  __typename?: '_AddDashboardViewActiveThemePayload';
  /** Field for the DashboardView node this ACTIVE_THEME [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<DashboardView>;
  /** Field for the DesignTheme node this ACTIVE_THEME [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<DesignTheme>;
};

export type _AddDashboardViewCategoryDefaultPayload = {
  __typename?: '_AddDashboardViewCategoryDefaultPayload';
  /** Field for the Category node this USES_DEFAULT_DASHBOARD [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<Category>;
  /** Field for the DashboardView node this USES_DEFAULT_DASHBOARD [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<DashboardView>;
};

export type _AddDashboardViewConceptHomePayload = {
  __typename?: '_AddDashboardViewConceptHomePayload';
  /** Field for the Concept node this HAS_HOME_DASHBOARD [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<Concept>;
  /** Field for the DashboardView node this HAS_HOME_DASHBOARD [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<DashboardView>;
};

export type _AddDashboardViewCreatedByPayload = {
  __typename?: '_AddDashboardViewCreatedByPayload';
  /** Field for the User node this CREATED_DASHBOARD [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<User>;
  /** Field for the DashboardView node this CREATED_DASHBOARD [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<DashboardView>;
};

export type _AddDashboardViewUsedByConceptsPayload = {
  __typename?: '_AddDashboardViewUsedByConceptsPayload';
  /** Field for the Concept node this SAVED_DASHBOARD [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<Concept>;
  /** Field for the DashboardView node this SAVED_DASHBOARD [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<DashboardView>;
};

export type _AddDashboardViewUsedByPayload = {
  __typename?: '_AddDashboardViewUsedByPayload';
  /** Field for the User node this SAVED_DASHBOARD [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<User>;
  /** Field for the DashboardView node this SAVED_DASHBOARD [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<DashboardView>;
};

export type _AddDashboardViewWidgetsPayload = {
  __typename?: '_AddDashboardViewWidgetsPayload';
  /** Field for the DashboardView node this HAS_WIDGET [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<DashboardView>;
  /** Field for the Widget node this HAS_WIDGET [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<Widget>;
};

export type _AddDesignThemeCreatedByPayload = {
  __typename?: '_AddDesignThemeCreatedByPayload';
  /** Field for the User node this CREATED_THEME [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<User>;
  /** Field for the DesignTheme node this CREATED_THEME [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<DesignTheme>;
};

export type _AddDesignThemeSavedByPayload = {
  __typename?: '_AddDesignThemeSavedByPayload';
  /** Field for the User node this SAVED_THEME [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<User>;
  /** Field for the DesignTheme node this SAVED_THEME [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<DesignTheme>;
};

export type _AddDesignThemeUsedAsDarkByPayload = {
  __typename?: '_AddDesignThemeUsedAsDarkByPayload';
  /** Field for the User node this ACTIVE_DARK_THEME [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<User>;
  /** Field for the DesignTheme node this ACTIVE_DARK_THEME [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<DesignTheme>;
};

export type _AddDesignThemeUsedAsLightByPayload = {
  __typename?: '_AddDesignThemeUsedAsLightByPayload';
  /** Field for the User node this ACTIVE_LIGHT_THEME [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<User>;
  /** Field for the DesignTheme node this ACTIVE_LIGHT_THEME [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<DesignTheme>;
};

export type _AddDesignThemeUsedByDashboardsPayload = {
  __typename?: '_AddDesignThemeUsedByDashboardsPayload';
  /** Field for the DashboardView node this ACTIVE_THEME [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<DashboardView>;
  /** Field for the DesignTheme node this ACTIVE_THEME [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<DesignTheme>;
};

export type _AddExerciseConceptsCreatedPayload = {
  __typename?: '_AddExerciseConceptsCreatedPayload';
  /** Field for the Exercise node this SUBMISSION_SPARKED_CONCEPT [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<Exercise>;
  /** Field for the Concept node this SUBMISSION_SPARKED_CONCEPT [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<Concept>;
};

export type _AddExerciseCreatedByPayload = {
  __typename?: '_AddExerciseCreatedByPayload';
  /** Field for the Exercise node this EXERCISE_CREATED_BY [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<Exercise>;
  /** Field for the User node this EXERCISE_CREATED_BY [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<User>;
};

export type _AddExerciseCriteriaPayload = {
  __typename?: '_AddExerciseCriteriaPayload';
  /** Field for the Exercise node this INCLUDES_CRITERIA [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<Exercise>;
  /** Field for the Criteria node this INCLUDES_CRITERIA [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<Criteria>;
};

export type _AddExerciseCriteriaScoresPayload = {
  __typename?: '_AddExerciseCriteriaScoresPayload';
  /** Field for the Exercise node this EXERCISE_HAS_CRITERIA_SCORE [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<Exercise>;
  /** Field for the CriteriaScore node this EXERCISE_HAS_CRITERIA_SCORE [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<CriteriaScore>;
};

export type _AddExerciseFacilitatorsPayload = {
  __typename?: '_AddExerciseFacilitatorsPayload';
  /** Field for the Exercise node this EXERCISE_FACILITATED_BY [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<Exercise>;
  /** Field for the User node this EXERCISE_FACILITATED_BY [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<User>;
};

export type _AddExerciseIncludedChecklistPayload = {
  __typename?: '_AddExerciseIncludedChecklistPayload';
  /** Field for the Exercise node this EXERCISE_INCLUDES_CHECKLIST [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<Exercise>;
  /** Field for the Checklist node this EXERCISE_INCLUDES_CHECKLIST [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<Checklist>;
};

export type _AddExerciseIncludedConceptsPayload = {
  __typename?: '_AddExerciseIncludedConceptsPayload';
  /** Field for the Exercise node this EXERCISE_INCLUDES_CONCEPT [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<Exercise>;
  /** Field for the Concept node this EXERCISE_INCLUDES_CONCEPT [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<Concept>;
};

export type _AddExerciseInvitedTeamsPayload = {
  __typename?: '_AddExerciseInvitedTeamsPayload';
  /** Field for the Exercise node this EXERCISE_INVITE_SENT_TO_TEAM [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<Exercise>;
  /** Field for the Team node this EXERCISE_INVITE_SENT_TO_TEAM [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<Team>;
};

export type _AddExerciseInvitedUsersPayload = {
  __typename?: '_AddExerciseInvitedUsersPayload';
  /** Field for the Exercise node this EXERCISE_INVITE_SENT_TO_USER [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<Exercise>;
  /** Field for the User node this EXERCISE_INVITE_SENT_TO_USER [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<User>;
};

export type _AddExerciseParentConceptPayload = {
  __typename?: '_AddExerciseParentConceptPayload';
  /** Field for the Exercise node this EXERCISE_DONE_FOR [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<Exercise>;
  /** Field for the Concept node this EXERCISE_DONE_FOR [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<Concept>;
};

export type _AddExerciseParticipantsPayload = {
  __typename?: '_AddExerciseParticipantsPayload';
  /** Field for the Exercise node this EXERCISE_COMPLETED_BY [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<Exercise>;
  /** Field for the User node this EXERCISE_COMPLETED_BY [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<User>;
};

export type _AddExerciseScoreConceptPayload = {
  __typename?: '_AddExerciseScoreConceptPayload';
  /** Field for the Concept node this GIVEN_SCORE_OF [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<Concept>;
  /** Field for the ExerciseScore node this GIVEN_SCORE_OF [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<ExerciseScore>;
};

export type _AddExerciseScoreSubmissionPayload = {
  __typename?: '_AddExerciseScoreSubmissionPayload';
  /** Field for the ExerciseSubmission node this CONTAINS_SCORES [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<ExerciseSubmission>;
  /** Field for the ExerciseScore node this CONTAINS_SCORES [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<ExerciseScore>;
};

export type _AddExerciseSubmissionConceptCreatedPayload = {
  __typename?: '_AddExerciseSubmissionConceptCreatedPayload';
  /** Field for the ExerciseSubmission node this SUBMISSION_SPARKED_CONCEPT [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<ExerciseSubmission>;
  /** Field for the Concept node this SUBMISSION_SPARKED_CONCEPT [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<Concept>;
};

export type _AddExerciseSubmissionCriteriaScoresPayload = {
  __typename?: '_AddExerciseSubmissionCriteriaScoresPayload';
  /** Field for the ExerciseSubmission node this HAS_CRITERIA_SCORE [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<ExerciseSubmission>;
  /** Field for the CriteriaScore node this HAS_CRITERIA_SCORE [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<CriteriaScore>;
};

export type _AddExerciseSubmissionExercisePayload = {
  __typename?: '_AddExerciseSubmissionExercisePayload';
  /** Field for the ExerciseSubmission node this EXERCISE_SUBMISSION_TO [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<ExerciseSubmission>;
  /** Field for the Exercise node this EXERCISE_SUBMISSION_TO [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<Exercise>;
};

export type _AddExerciseSubmissionScoresPayload = {
  __typename?: '_AddExerciseSubmissionScoresPayload';
  /** Field for the ExerciseSubmission node this CONTAINS_SCORES [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<ExerciseSubmission>;
  /** Field for the ExerciseScore node this CONTAINS_SCORES [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<ExerciseScore>;
};

export type _AddExerciseSubmissionUserPayload = {
  __typename?: '_AddExerciseSubmissionUserPayload';
  /** Field for the ExerciseSubmission node this EXERCISE_RESULT_SUBMITTED_BY [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<ExerciseSubmission>;
  /** Field for the User node this EXERCISE_RESULT_SUBMITTED_BY [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<User>;
};

export type _AddExerciseSubmissionsPayload = {
  __typename?: '_AddExerciseSubmissionsPayload';
  /** Field for the ExerciseSubmission node this EXERCISE_SUBMISSION_TO [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<ExerciseSubmission>;
  /** Field for the Exercise node this EXERCISE_SUBMISSION_TO [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<Exercise>;
};

export type _AddExerciseTypeCreatedByPayload = {
  __typename?: '_AddExerciseTypeCreatedByPayload';
  /** Field for the ExerciseType node this EXERCISE_TYPE_CREATED_BY [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<ExerciseType>;
  /** Field for the User node this EXERCISE_TYPE_CREATED_BY [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<User>;
};

export type _AddExerciseTypeCriteriaPayload = {
  __typename?: '_AddExerciseTypeCriteriaPayload';
  /** Field for the ExerciseType node this TYPE_INCLUDES_CRITERIA [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<ExerciseType>;
  /** Field for the Criteria node this TYPE_INCLUDES_CRITERIA [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<Criteria>;
};

export type _AddExerciseTypeExercisesPayload = {
  __typename?: '_AddExerciseTypeExercisesPayload';
  /** Field for the Exercise node this EXERCISE_TYPE_OF [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<Exercise>;
  /** Field for the ExerciseType node this EXERCISE_TYPE_OF [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<ExerciseType>;
};

export type _AddExerciseTypePayload = {
  __typename?: '_AddExerciseTypePayload';
  /** Field for the Exercise node this EXERCISE_TYPE_OF [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<Exercise>;
  /** Field for the ExerciseType node this EXERCISE_TYPE_OF [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<ExerciseType>;
};

export type _AddIndustryCompaniesPayload = {
  __typename?: '_AddIndustryCompaniesPayload';
  /** Field for the Company node this IN_INDUSTRY [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<Company>;
  /** Field for the Industry node this IN_INDUSTRY [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<Industry>;
};

export type _AddIndustryConceptsPayload = {
  __typename?: '_AddIndustryConceptsPayload';
  /** Field for the Concept node this IMPACTS_INDUSTRY [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<Concept>;
  /** Field for the Industry node this IMPACTS_INDUSTRY [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<Industry>;
};

export type _AddInitiativeCommentsPayload = {
  __typename?: '_AddInitiativeCommentsPayload';
  /** Field for the Comment node this COMMENT_ON [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<Comment>;
  /** Field for the Initiative node this COMMENT_ON [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<Initiative>;
};

export type _AddInitiativeConnectionsPayload = {
  __typename?: '_AddInitiativeConnectionsPayload';
  /** Field for the Initiative node this CONNECTED [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<Initiative>;
  /** Field for the Concept node this CONNECTED [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<Concept>;
};

export type _AddInitiativeLikedByPayload = {
  __typename?: '_AddInitiativeLikedByPayload';
  /** Field for the User node this LIKED [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<User>;
  /** Field for the Initiative node this LIKED [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<Initiative>;
};

export type _AddInitiativeTagsPayload = {
  __typename?: '_AddInitiativeTagsPayload';
  /** Field for the Initiative node this TAGGED [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<Initiative>;
  /** Field for the Tag node this TAGGED [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<Tag>;
};

export type _AddInitiativeTeamPayload = {
  __typename?: '_AddInitiativeTeamPayload';
  /** Field for the Team node this TEAM_INITIATIVE [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<Team>;
  /** Field for the Initiative node this TEAM_INITIATIVE [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<Initiative>;
};

export type _AddInitiativeUserPayload = {
  __typename?: '_AddInitiativeUserPayload';
  /** Field for the User node this USER_INITIATIVE [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<User>;
  /** Field for the Initiative node this USER_INITIATIVE [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<Initiative>;
};

export type _AddInputConceptsCreatedPayload = {
  __typename?: '_AddInputConceptsCreatedPayload';
  /** Field for the Input node this CREATED_VIA_INPUT [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<Input>;
  /** Field for the Concept node this CREATED_VIA_INPUT [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<Concept>;
};

export type _AddInputCriteriaPayload = {
  __typename?: '_AddInputCriteriaPayload';
  /** Field for the Input node this INPUT_USES_CRITERIA [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<Input>;
  /** Field for the Criteria node this INPUT_USES_CRITERIA [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<Criteria>;
};

export type _AddInputCriteriaScoresPayload = {
  __typename?: '_AddInputCriteriaScoresPayload';
  /** Field for the Input node this INPUT_HAS_CRITERIA_SCORE [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<Input>;
  /** Field for the CriteriaScore node this INPUT_HAS_CRITERIA_SCORE [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<CriteriaScore>;
};

export type _AddInputIncludedConceptsPayload = {
  __typename?: '_AddInputIncludedConceptsPayload';
  /** Field for the Input node this INPUT_INCLUDES_CONCEPT [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<Input>;
  /** Field for the Concept node this INPUT_INCLUDES_CONCEPT [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<Concept>;
};

export type _AddInputParentConceptPayload = {
  __typename?: '_AddInputParentConceptPayload';
  /** Field for the Input node this INPUT_FOR_CONCEPT [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<Input>;
  /** Field for the Concept node this INPUT_FOR_CONCEPT [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<Concept>;
};

export type _AddNotificationActionByPayload = {
  __typename?: '_AddNotificationActionByPayload';
  /** Field for the User node this USER_TRIGGERED_NOTIFICATION [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<User>;
  /** Field for the Notification node this USER_TRIGGERED_NOTIFICATION [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<Notification>;
};

export type _AddNotificationCommentPayload = {
  __typename?: '_AddNotificationCommentPayload';
  /** Field for the Comment node this COMMENT_TRIGGERED_NOTIFICATION [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<Comment>;
  /** Field for the Notification node this COMMENT_TRIGGERED_NOTIFICATION [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<Notification>;
};

export type _AddNotificationConceptPayload = {
  __typename?: '_AddNotificationConceptPayload';
  /** Field for the Concept node this CONCEPT_TRIGGERED_NOTIFICATION [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<Concept>;
  /** Field for the Notification node this CONCEPT_TRIGGERED_NOTIFICATION [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<Notification>;
};

export type _AddNotificationUsersPayload = {
  __typename?: '_AddNotificationUsersPayload';
  /** Field for the Notification node this NOTIFIED [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<Notification>;
  /** Field for the User node this NOTIFIED [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<User>;
};

export type _AddTagTaggedPayload = {
  __typename?: '_AddTagTaggedPayload';
  /** Field for the Concept node this TAGGED [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<Concept>;
  /** Field for the Tag node this TAGGED [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<Tag>;
};

export type _AddTeamAddedToConceptsPayload = {
  __typename?: '_AddTeamAddedToConceptsPayload';
  /** Field for the Team node this HAS_ACCESS [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<Team>;
  /** Field for the Concept node this HAS_ACCESS [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<Concept>;
  type?: Maybe<AccessType>;
  updatedAt?: Maybe<_Neo4jDateTime>;
  /** Generated field for querying the Neo4j [system id](https://neo4j.com/docs/cypher-manual/current/functions/scalar/#functions-id) of this relationship. */
  _id?: Maybe<Scalars['String']>;
};

export type _AddTeamAdminPayload = {
  __typename?: '_AddTeamAdminPayload';
  /** Field for the User node this TEAM_ADMIN [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<User>;
  /** Field for the Team node this TEAM_ADMIN [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<Team>;
};

export type _AddTeamCompanyPayload = {
  __typename?: '_AddTeamCompanyPayload';
  /** Field for the Team node this TEAM_AT [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<Team>;
  /** Field for the Company node this TEAM_AT [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<Company>;
};

export type _AddTeamConceptListsPayload = {
  __typename?: '_AddTeamConceptListsPayload';
  /** Field for the Team node this TEAM_CONCEPT_LIST [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<Team>;
  /** Field for the ConceptList node this TEAM_CONCEPT_LIST [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<ConceptList>;
};

export type _AddTeamConceptsPayload = {
  __typename?: '_AddTeamConceptsPayload';
  /** Field for the Team node this TEAM_CONCEPT [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<Team>;
  /** Field for the Concept node this TEAM_CONCEPT [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<Concept>;
};

export type _AddTeamExercisesInvitedToPayload = {
  __typename?: '_AddTeamExercisesInvitedToPayload';
  /** Field for the Exercise node this EXERCISE_INVITE_SENT_TO_TEAM [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<Exercise>;
  /** Field for the Team node this EXERCISE_INVITE_SENT_TO_TEAM [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<Team>;
};

export type _AddTeamHomeConceptPayload = {
  __typename?: '_AddTeamHomeConceptPayload';
  /** Field for the Team node this TEAM_HOMEPAGE_CONCEPT [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<Team>;
  /** Field for the Concept node this TEAM_HOMEPAGE_CONCEPT [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<Concept>;
};

export type _AddTeamLeaderPayload = {
  __typename?: '_AddTeamLeaderPayload';
  /** Field for the User node this LEADS_TEAM [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<User>;
  /** Field for the Team node this LEADS_TEAM [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<Team>;
};

export type _AddTeamMembersPayload = {
  __typename?: '_AddTeamMembersPayload';
  /** Field for the User node this TEAM_MEMBER [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<User>;
  /** Field for the Team node this TEAM_MEMBER [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<Team>;
};

export type _AddTranslationConceptPayload = {
  __typename?: '_AddTranslationConceptPayload';
  /** Field for the Concept node this HAS_TRANSLATION [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<Concept>;
  /** Field for the Translation node this HAS_TRANSLATION [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<Translation>;
};

export type _AddUserActiveDarkThemePayload = {
  __typename?: '_AddUserActiveDarkThemePayload';
  /** Field for the User node this ACTIVE_DARK_THEME [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<User>;
  /** Field for the DesignTheme node this ACTIVE_DARK_THEME [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<DesignTheme>;
};

export type _AddUserActiveLightThemePayload = {
  __typename?: '_AddUserActiveLightThemePayload';
  /** Field for the User node this ACTIVE_LIGHT_THEME [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<User>;
  /** Field for the DesignTheme node this ACTIVE_LIGHT_THEME [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<DesignTheme>;
};

export type _AddUserAddedToConceptsPayload = {
  __typename?: '_AddUserAddedToConceptsPayload';
  /** Field for the User node this HAS_ACCESS [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<User>;
  /** Field for the Concept node this HAS_ACCESS [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<Concept>;
  type?: Maybe<AccessType>;
  updatedAt?: Maybe<_Neo4jDateTime>;
  /** Generated field for querying the Neo4j [system id](https://neo4j.com/docs/cypher-manual/current/functions/scalar/#functions-id) of this relationship. */
  _id?: Maybe<Scalars['String']>;
};

export type _AddUserAdminPayload = {
  __typename?: '_AddUserAdminPayload';
  /** Field for the User node this TEAM_ADMIN [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<User>;
  /** Field for the Team node this TEAM_ADMIN [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<Team>;
};

export type _AddUserCommentsPayload = {
  __typename?: '_AddUserCommentsPayload';
  /** Field for the User node this COMMENTED [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<User>;
  /** Field for the Comment node this COMMENTED [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<Comment>;
};

export type _AddUserConceptBoardPayload = {
  __typename?: '_AddUserConceptBoardPayload';
  /** Field for the User node this HAS_CONCEPT_BOARD [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<User>;
  /** Field for the ConceptBoard node this HAS_CONCEPT_BOARD [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<ConceptBoard>;
};

export type _AddUserConceptListsPayload = {
  __typename?: '_AddUserConceptListsPayload';
  /** Field for the User node this HAS_CONCEPT_LIST [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<User>;
  /** Field for the ConceptList node this HAS_CONCEPT_LIST [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<ConceptList>;
};

export type _AddUserConceptsCreatedPayload = {
  __typename?: '_AddUserConceptsCreatedPayload';
  /** Field for the User node this CREATED [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<User>;
  /** Field for the Concept node this CREATED [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<Concept>;
};

export type _AddUserCreatedDashboardPayload = {
  __typename?: '_AddUserCreatedDashboardPayload';
  /** Field for the User node this CREATED_DASHBOARD [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<User>;
  /** Field for the DashboardView node this CREATED_DASHBOARD [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<DashboardView>;
};

export type _AddUserCreatedThemesPayload = {
  __typename?: '_AddUserCreatedThemesPayload';
  /** Field for the User node this CREATED_THEME [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<User>;
  /** Field for the DesignTheme node this CREATED_THEME [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<DesignTheme>;
};

export type _AddUserCriteriaScoresPayload = {
  __typename?: '_AddUserCriteriaScoresPayload';
  /** Field for the User node this SCORED_BY [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<User>;
  /** Field for the CriteriaScore node this SCORED_BY [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<CriteriaScore>;
};

export type _AddUserExerciseSubmissionsPayload = {
  __typename?: '_AddUserExerciseSubmissionsPayload';
  /** Field for the ExerciseSubmission node this EXERCISE_RESULT_SUBMITTED_BY [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<ExerciseSubmission>;
  /** Field for the User node this EXERCISE_RESULT_SUBMITTED_BY [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<User>;
};

export type _AddUserExerciseTypesCreatedPayload = {
  __typename?: '_AddUserExerciseTypesCreatedPayload';
  /** Field for the ExerciseType node this EXERCISE_TYPE_CREATED_BY [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<ExerciseType>;
  /** Field for the User node this EXERCISE_TYPE_CREATED_BY [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<User>;
};

export type _AddUserExercisesCreatedPayload = {
  __typename?: '_AddUserExercisesCreatedPayload';
  /** Field for the Exercise node this EXERCISE_CREATED_BY [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<Exercise>;
  /** Field for the User node this EXERCISE_CREATED_BY [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<User>;
};

export type _AddUserExercisesFacilitatedPayload = {
  __typename?: '_AddUserExercisesFacilitatedPayload';
  /** Field for the Exercise node this EXERCISE_FACILITATED_BY [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<Exercise>;
  /** Field for the User node this EXERCISE_FACILITATED_BY [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<User>;
};

export type _AddUserExercisesInvitedToPayload = {
  __typename?: '_AddUserExercisesInvitedToPayload';
  /** Field for the Exercise node this EXERCISE_INVITE_SENT_TO_USER [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<Exercise>;
  /** Field for the User node this EXERCISE_INVITE_SENT_TO_USER [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<User>;
};

export type _AddUserExercisesParticipatedPayload = {
  __typename?: '_AddUserExercisesParticipatedPayload';
  /** Field for the Exercise node this EXERCISE_COMPLETED_BY [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<Exercise>;
  /** Field for the User node this EXERCISE_COMPLETED_BY [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<User>;
};

export type _AddUserHomeConceptPayload = {
  __typename?: '_AddUserHomeConceptPayload';
  /** Field for the User node this USER_HOME_CONCEPT [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<User>;
  /** Field for the Concept node this USER_HOME_CONCEPT [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<Concept>;
};

export type _AddUserLeadsPayload = {
  __typename?: '_AddUserLeadsPayload';
  /** Field for the User node this LEADS_TEAM [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<User>;
  /** Field for the Team node this LEADS_TEAM [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<Team>;
};

export type _AddUserNotificationsPayload = {
  __typename?: '_AddUserNotificationsPayload';
  /** Field for the Notification node this NOTIFIED [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<Notification>;
  /** Field for the User node this NOTIFIED [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<User>;
};

export type _AddUserNotificationsTriggeredPayload = {
  __typename?: '_AddUserNotificationsTriggeredPayload';
  /** Field for the User node this USER_TRIGGERED_NOTIFICATION [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<User>;
  /** Field for the User node this USER_TRIGGERED_NOTIFICATION [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<User>;
};

export type _AddUserRecentUpdatesPayload = {
  __typename?: '_AddUserRecentUpdatesPayload';
  /** Field for the User node this UPDATED [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<User>;
  /** Field for the Concept node this UPDATED [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<Concept>;
  updatedAt?: Maybe<_Neo4jDateTime>;
  updateValue?: Maybe<Scalars['String']>;
  /** Generated field for querying the Neo4j [system id](https://neo4j.com/docs/cypher-manual/current/functions/scalar/#functions-id) of this relationship. */
  _id?: Maybe<Scalars['String']>;
};

export type _AddUserSavedDashboardsPayload = {
  __typename?: '_AddUserSavedDashboardsPayload';
  /** Field for the User node this SAVED_DASHBOARD [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<User>;
  /** Field for the DashboardView node this SAVED_DASHBOARD [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<DashboardView>;
};

export type _AddUserSavedThemesPayload = {
  __typename?: '_AddUserSavedThemesPayload';
  /** Field for the User node this SAVED_THEME [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<User>;
  /** Field for the DesignTheme node this SAVED_THEME [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<DesignTheme>;
};

export type _AddUserTeamsPayload = {
  __typename?: '_AddUserTeamsPayload';
  /** Field for the User node this TEAM_MEMBER [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<User>;
  /** Field for the Team node this TEAM_MEMBER [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<Team>;
};

export type _AddUserWatchedConceptsPayload = {
  __typename?: '_AddUserWatchedConceptsPayload';
  /** Field for the User node this WATCHING [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<User>;
  /** Field for the Concept node this WATCHING [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<Concept>;
};

export type _AddUserWorkspaceInboxPayload = {
  __typename?: '_AddUserWorkspaceInboxPayload';
  /** Field for the User node this HAS_WORKSPACE_INBOX [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<User>;
  /** Field for the ConceptList node this HAS_WORKSPACE_INBOX [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<ConceptList>;
};

export type _AddWidgetDashboardsPayload = {
  __typename?: '_AddWidgetDashboardsPayload';
  /** Field for the DashboardView node this HAS_WIDGET [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<DashboardView>;
  /** Field for the Widget node this HAS_WIDGET [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<Widget>;
};

export type _AddWidgetTypePayload = {
  __typename?: '_AddWidgetTypePayload';
  /** Field for the Widget node this WIDGET_TYPE_OF [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<Widget>;
  /** Field for the WidgetType node this WIDGET_TYPE_OF [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<WidgetType>;
};

export type _AddWidgetTypeWidgetsPayload = {
  __typename?: '_AddWidgetTypeWidgetsPayload';
  /** Field for the Widget node this WIDGET_TYPE_OF [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<Widget>;
  /** Field for the WidgetType node this WIDGET_TYPE_OF [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<WidgetType>;
};

export type _AddWorkflowCategoriesPayload = {
  __typename?: '_AddWorkflowCategoriesPayload';
  /** Field for the Workflow node this DEFAULT_WORKFLOW_FOR_CATEGORY [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<Workflow>;
  /** Field for the Category node this DEFAULT_WORKFLOW_FOR_CATEGORY [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<Category>;
};

export type _AddWorkflowStageLastOptionsPayload = {
  __typename?: '_AddWorkflowStageLastOptionsPayload';
  /** Field for the WorkflowStage node this NEXT_STAGE [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<WorkflowStage>;
  /** Field for the WorkflowStage node this NEXT_STAGE [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<WorkflowStage>;
};

export type _AddWorkflowStageNextOptionsPayload = {
  __typename?: '_AddWorkflowStageNextOptionsPayload';
  /** Field for the WorkflowStage node this NEXT_STAGE [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<WorkflowStage>;
  /** Field for the WorkflowStage node this NEXT_STAGE [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<WorkflowStage>;
};

export type _AddWorkflowStageStatusesPayload = {
  __typename?: '_AddWorkflowStageStatusesPayload';
  /** Field for the ConceptStatus node this HAS_WORKFLOW_STAGE [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<ConceptStatus>;
  /** Field for the WorkflowStage node this HAS_WORKFLOW_STAGE [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<WorkflowStage>;
};

export type _AddWorkflowStageWorkflowPayload = {
  __typename?: '_AddWorkflowStageWorkflowPayload';
  /** Field for the Workflow node this WORKFLOW_STAGE [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<Workflow>;
  /** Field for the WorkflowStage node this WORKFLOW_STAGE [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<WorkflowStage>;
};

export type _AddWorkflowStagesPayload = {
  __typename?: '_AddWorkflowStagesPayload';
  /** Field for the Workflow node this WORKFLOW_STAGE [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<Workflow>;
  /** Field for the WorkflowStage node this WORKFLOW_STAGE [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<WorkflowStage>;
};

export type _CategoryFilter = {
  AND?: Maybe<Array<_CategoryFilter>>;
  OR?: Maybe<Array<_CategoryFilter>>;
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Scalars['ID']>>;
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  id_regexp?: Maybe<Scalars['ID']>;
  id_contains?: Maybe<Scalars['ID']>;
  id_not_contains?: Maybe<Scalars['ID']>;
  id_starts_with?: Maybe<Scalars['ID']>;
  id_not_starts_with?: Maybe<Scalars['ID']>;
  id_ends_with?: Maybe<Scalars['ID']>;
  id_not_ends_with?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  name_not?: Maybe<Scalars['String']>;
  name_in?: Maybe<Array<Scalars['String']>>;
  name_not_in?: Maybe<Array<Scalars['String']>>;
  name_regexp?: Maybe<Scalars['String']>;
  name_contains?: Maybe<Scalars['String']>;
  name_not_contains?: Maybe<Scalars['String']>;
  name_starts_with?: Maybe<Scalars['String']>;
  name_not_starts_with?: Maybe<Scalars['String']>;
  name_ends_with?: Maybe<Scalars['String']>;
  name_not_ends_with?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  description_not?: Maybe<Scalars['String']>;
  description_in?: Maybe<Array<Scalars['String']>>;
  description_not_in?: Maybe<Array<Scalars['String']>>;
  description_regexp?: Maybe<Scalars['String']>;
  description_contains?: Maybe<Scalars['String']>;
  description_not_contains?: Maybe<Scalars['String']>;
  description_starts_with?: Maybe<Scalars['String']>;
  description_not_starts_with?: Maybe<Scalars['String']>;
  description_ends_with?: Maybe<Scalars['String']>;
  description_not_ends_with?: Maybe<Scalars['String']>;
  isRoot?: Maybe<Scalars['Boolean']>;
  isRoot_not?: Maybe<Scalars['Boolean']>;
  isManaged?: Maybe<Scalars['Boolean']>;
  isManaged_not?: Maybe<Scalars['Boolean']>;
  isFilterOption?: Maybe<Scalars['Boolean']>;
  isFilterOption_not?: Maybe<Scalars['Boolean']>;
  isSystemDefault?: Maybe<Scalars['Boolean']>;
  isSystemDefault_not?: Maybe<Scalars['Boolean']>;
  accessRoles?: Maybe<Array<SystemRole>>;
  accessRoles_not?: Maybe<Array<SystemRole>>;
  icon?: Maybe<Scalars['String']>;
  icon_not?: Maybe<Scalars['String']>;
  icon_in?: Maybe<Array<Scalars['String']>>;
  icon_not_in?: Maybe<Array<Scalars['String']>>;
  icon_regexp?: Maybe<Scalars['String']>;
  icon_contains?: Maybe<Scalars['String']>;
  icon_not_contains?: Maybe<Scalars['String']>;
  icon_starts_with?: Maybe<Scalars['String']>;
  icon_not_starts_with?: Maybe<Scalars['String']>;
  icon_ends_with?: Maybe<Scalars['String']>;
  icon_not_ends_with?: Maybe<Scalars['String']>;
  depth?: Maybe<Scalars['Int']>;
  depth_not?: Maybe<Scalars['Int']>;
  depth_in?: Maybe<Array<Scalars['Int']>>;
  depth_not_in?: Maybe<Array<Scalars['Int']>>;
  depth_lt?: Maybe<Scalars['Int']>;
  depth_lte?: Maybe<Scalars['Int']>;
  depth_gt?: Maybe<Scalars['Int']>;
  depth_gte?: Maybe<Scalars['Int']>;
  conceptType?: Maybe<ConceptType>;
  conceptType_not?: Maybe<ConceptType>;
  conceptType_in?: Maybe<Array<ConceptType>>;
  conceptType_not_in?: Maybe<Array<ConceptType>>;
  parent?: Maybe<_CategoryFilter>;
  parent_not?: Maybe<_CategoryFilter>;
  parent_in?: Maybe<Array<_CategoryFilter>>;
  parent_not_in?: Maybe<Array<_CategoryFilter>>;
  children?: Maybe<_CategoryFilter>;
  children_not?: Maybe<_CategoryFilter>;
  children_in?: Maybe<Array<_CategoryFilter>>;
  children_not_in?: Maybe<Array<_CategoryFilter>>;
  children_some?: Maybe<_CategoryFilter>;
  children_none?: Maybe<_CategoryFilter>;
  children_single?: Maybe<_CategoryFilter>;
  children_every?: Maybe<_CategoryFilter>;
  concepts?: Maybe<_ConceptFilter>;
  concepts_not?: Maybe<_ConceptFilter>;
  concepts_in?: Maybe<Array<_ConceptFilter>>;
  concepts_not_in?: Maybe<Array<_ConceptFilter>>;
  concepts_some?: Maybe<_ConceptFilter>;
  concepts_none?: Maybe<_ConceptFilter>;
  concepts_single?: Maybe<_ConceptFilter>;
  concepts_every?: Maybe<_ConceptFilter>;
  defaultCriteria?: Maybe<_CriteriaFilter>;
  defaultCriteria_not?: Maybe<_CriteriaFilter>;
  defaultCriteria_in?: Maybe<Array<_CriteriaFilter>>;
  defaultCriteria_not_in?: Maybe<Array<_CriteriaFilter>>;
  defaultCriteria_some?: Maybe<_CriteriaFilter>;
  defaultCriteria_none?: Maybe<_CriteriaFilter>;
  defaultCriteria_single?: Maybe<_CriteriaFilter>;
  defaultCriteria_every?: Maybe<_CriteriaFilter>;
  criteria?: Maybe<_CriteriaFilter>;
  criteria_not?: Maybe<_CriteriaFilter>;
  criteria_in?: Maybe<Array<_CriteriaFilter>>;
  criteria_not_in?: Maybe<Array<_CriteriaFilter>>;
  criteria_some?: Maybe<_CriteriaFilter>;
  criteria_none?: Maybe<_CriteriaFilter>;
  criteria_single?: Maybe<_CriteriaFilter>;
  criteria_every?: Maybe<_CriteriaFilter>;
  defaultDashboard?: Maybe<_DashboardViewFilter>;
  defaultDashboard_not?: Maybe<_DashboardViewFilter>;
  defaultDashboard_in?: Maybe<Array<_DashboardViewFilter>>;
  defaultDashboard_not_in?: Maybe<Array<_DashboardViewFilter>>;
  workflow?: Maybe<_WorkflowFilter>;
  workflow_not?: Maybe<_WorkflowFilter>;
  workflow_in?: Maybe<Array<_WorkflowFilter>>;
  workflow_not_in?: Maybe<Array<_WorkflowFilter>>;
};

export type _CategoryInput = {
  id: Scalars['ID'];
};

export enum _CategoryOrdering {
  ID_ASC = 'id_asc',
  ID_DESC = 'id_desc',
  NAME_ASC = 'name_asc',
  NAME_DESC = 'name_desc',
  DESCRIPTION_ASC = 'description_asc',
  DESCRIPTION_DESC = 'description_desc',
  ISROOT_ASC = 'isRoot_asc',
  ISROOT_DESC = 'isRoot_desc',
  ISMANAGED_ASC = 'isManaged_asc',
  ISMANAGED_DESC = 'isManaged_desc',
  ISFILTEROPTION_ASC = 'isFilterOption_asc',
  ISFILTEROPTION_DESC = 'isFilterOption_desc',
  ISSYSTEMDEFAULT_ASC = 'isSystemDefault_asc',
  ISSYSTEMDEFAULT_DESC = 'isSystemDefault_desc',
  ICON_ASC = 'icon_asc',
  ICON_DESC = 'icon_desc',
  DEPTH_ASC = 'depth_asc',
  DEPTH_DESC = 'depth_desc',
  CONCEPTTYPE_ASC = 'conceptType_asc',
  CONCEPTTYPE_DESC = 'conceptType_desc',
  _ID_ASC = '_id_asc',
  _ID_DESC = '_id_desc'
}

export type _ChecklistFilter = {
  AND?: Maybe<Array<_ChecklistFilter>>;
  OR?: Maybe<Array<_ChecklistFilter>>;
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Scalars['ID']>>;
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  id_regexp?: Maybe<Scalars['ID']>;
  id_contains?: Maybe<Scalars['ID']>;
  id_not_contains?: Maybe<Scalars['ID']>;
  id_starts_with?: Maybe<Scalars['ID']>;
  id_not_starts_with?: Maybe<Scalars['ID']>;
  id_ends_with?: Maybe<Scalars['ID']>;
  id_not_ends_with?: Maybe<Scalars['ID']>;
  title?: Maybe<Scalars['String']>;
  title_not?: Maybe<Scalars['String']>;
  title_in?: Maybe<Array<Scalars['String']>>;
  title_not_in?: Maybe<Array<Scalars['String']>>;
  title_regexp?: Maybe<Scalars['String']>;
  title_contains?: Maybe<Scalars['String']>;
  title_not_contains?: Maybe<Scalars['String']>;
  title_starts_with?: Maybe<Scalars['String']>;
  title_not_starts_with?: Maybe<Scalars['String']>;
  title_ends_with?: Maybe<Scalars['String']>;
  title_not_ends_with?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  description_not?: Maybe<Scalars['String']>;
  description_in?: Maybe<Array<Scalars['String']>>;
  description_not_in?: Maybe<Array<Scalars['String']>>;
  description_regexp?: Maybe<Scalars['String']>;
  description_contains?: Maybe<Scalars['String']>;
  description_not_contains?: Maybe<Scalars['String']>;
  description_starts_with?: Maybe<Scalars['String']>;
  description_not_starts_with?: Maybe<Scalars['String']>;
  description_ends_with?: Maybe<Scalars['String']>;
  description_not_ends_with?: Maybe<Scalars['String']>;
  conceptBased?: Maybe<Scalars['Boolean']>;
  conceptBased_not?: Maybe<Scalars['Boolean']>;
  isOrdered?: Maybe<Scalars['Boolean']>;
  isOrdered_not?: Maybe<Scalars['Boolean']>;
  itemPrefixType?: Maybe<ItemPrefixType>;
  itemPrefixType_not?: Maybe<ItemPrefixType>;
  itemPrefixType_in?: Maybe<Array<ItemPrefixType>>;
  itemPrefixType_not_in?: Maybe<Array<ItemPrefixType>>;
  itemIds?: Maybe<Array<Scalars['ID']>>;
  itemIds_not?: Maybe<Array<Scalars['ID']>>;
  itemIds_regexp?: Maybe<Scalars['ID']>;
  itemIds_contains?: Maybe<Array<Scalars['ID']>>;
  itemIds_not_contains?: Maybe<Array<Scalars['ID']>>;
  itemIds_starts_with?: Maybe<Array<Scalars['ID']>>;
  itemIds_not_starts_with?: Maybe<Array<Scalars['ID']>>;
  itemIds_ends_with?: Maybe<Array<Scalars['ID']>>;
  itemIds_not_ends_with?: Maybe<Array<Scalars['ID']>>;
  listLengthLimit?: Maybe<Scalars['Int']>;
  listLengthLimit_not?: Maybe<Scalars['Int']>;
  listLengthLimit_in?: Maybe<Array<Scalars['Int']>>;
  listLengthLimit_not_in?: Maybe<Array<Scalars['Int']>>;
  listLengthLimit_lt?: Maybe<Scalars['Int']>;
  listLengthLimit_lte?: Maybe<Scalars['Int']>;
  listLengthLimit_gt?: Maybe<Scalars['Int']>;
  listLengthLimit_gte?: Maybe<Scalars['Int']>;
  lastUpdated?: Maybe<_Neo4jDateTimeInput>;
  lastUpdated_not?: Maybe<_Neo4jDateTimeInput>;
  lastUpdated_in?: Maybe<Array<_Neo4jDateTimeInput>>;
  lastUpdated_not_in?: Maybe<Array<_Neo4jDateTimeInput>>;
  lastUpdated_lt?: Maybe<_Neo4jDateTimeInput>;
  lastUpdated_lte?: Maybe<_Neo4jDateTimeInput>;
  lastUpdated_gt?: Maybe<_Neo4jDateTimeInput>;
  lastUpdated_gte?: Maybe<_Neo4jDateTimeInput>;
  updates?: Maybe<Array<Scalars['String']>>;
  updates_not?: Maybe<Array<Scalars['String']>>;
  updates_regexp?: Maybe<Scalars['String']>;
  updates_contains?: Maybe<Array<Scalars['String']>>;
  updates_not_contains?: Maybe<Array<Scalars['String']>>;
  updates_starts_with?: Maybe<Array<Scalars['String']>>;
  updates_not_starts_with?: Maybe<Array<Scalars['String']>>;
  updates_ends_with?: Maybe<Array<Scalars['String']>>;
  updates_not_ends_with?: Maybe<Array<Scalars['String']>>;
  createdAt?: Maybe<_Neo4jDateTimeInput>;
  createdAt_not?: Maybe<_Neo4jDateTimeInput>;
  createdAt_in?: Maybe<Array<_Neo4jDateTimeInput>>;
  createdAt_not_in?: Maybe<Array<_Neo4jDateTimeInput>>;
  createdAt_lt?: Maybe<_Neo4jDateTimeInput>;
  createdAt_lte?: Maybe<_Neo4jDateTimeInput>;
  createdAt_gt?: Maybe<_Neo4jDateTimeInput>;
  createdAt_gte?: Maybe<_Neo4jDateTimeInput>;
  createdBy?: Maybe<_UserFilter>;
  createdBy_not?: Maybe<_UserFilter>;
  createdBy_in?: Maybe<Array<_UserFilter>>;
  createdBy_not_in?: Maybe<Array<_UserFilter>>;
  type?: Maybe<_ChecklistTypeFilter>;
  type_not?: Maybe<_ChecklistTypeFilter>;
  type_in?: Maybe<Array<_ChecklistTypeFilter>>;
  type_not_in?: Maybe<Array<_ChecklistTypeFilter>>;
  items?: Maybe<_ChecklistItemFilter>;
  items_not?: Maybe<_ChecklistItemFilter>;
  items_in?: Maybe<Array<_ChecklistItemFilter>>;
  items_not_in?: Maybe<Array<_ChecklistItemFilter>>;
  items_some?: Maybe<_ChecklistItemFilter>;
  items_none?: Maybe<_ChecklistItemFilter>;
  items_single?: Maybe<_ChecklistItemFilter>;
  items_every?: Maybe<_ChecklistItemFilter>;
  concept?: Maybe<_ConceptFilter>;
  concept_not?: Maybe<_ConceptFilter>;
  concept_in?: Maybe<Array<_ConceptFilter>>;
  concept_not_in?: Maybe<Array<_ConceptFilter>>;
  concept_some?: Maybe<_ConceptFilter>;
  concept_none?: Maybe<_ConceptFilter>;
  concept_single?: Maybe<_ConceptFilter>;
  concept_every?: Maybe<_ConceptFilter>;
  exercise?: Maybe<_ExerciseFilter>;
  exercise_not?: Maybe<_ExerciseFilter>;
  exercise_in?: Maybe<Array<_ExerciseFilter>>;
  exercise_not_in?: Maybe<Array<_ExerciseFilter>>;
};

export type _ChecklistInput = {
  id: Scalars['ID'];
};

export type _ChecklistItemFilter = {
  AND?: Maybe<Array<_ChecklistItemFilter>>;
  OR?: Maybe<Array<_ChecklistItemFilter>>;
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Scalars['ID']>>;
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  id_regexp?: Maybe<Scalars['ID']>;
  id_contains?: Maybe<Scalars['ID']>;
  id_not_contains?: Maybe<Scalars['ID']>;
  id_starts_with?: Maybe<Scalars['ID']>;
  id_not_starts_with?: Maybe<Scalars['ID']>;
  id_ends_with?: Maybe<Scalars['ID']>;
  id_not_ends_with?: Maybe<Scalars['ID']>;
  title?: Maybe<Scalars['String']>;
  title_not?: Maybe<Scalars['String']>;
  title_in?: Maybe<Array<Scalars['String']>>;
  title_not_in?: Maybe<Array<Scalars['String']>>;
  title_regexp?: Maybe<Scalars['String']>;
  title_contains?: Maybe<Scalars['String']>;
  title_not_contains?: Maybe<Scalars['String']>;
  title_starts_with?: Maybe<Scalars['String']>;
  title_not_starts_with?: Maybe<Scalars['String']>;
  title_ends_with?: Maybe<Scalars['String']>;
  title_not_ends_with?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  description_not?: Maybe<Scalars['String']>;
  description_in?: Maybe<Array<Scalars['String']>>;
  description_not_in?: Maybe<Array<Scalars['String']>>;
  description_regexp?: Maybe<Scalars['String']>;
  description_contains?: Maybe<Scalars['String']>;
  description_not_contains?: Maybe<Scalars['String']>;
  description_starts_with?: Maybe<Scalars['String']>;
  description_not_starts_with?: Maybe<Scalars['String']>;
  description_ends_with?: Maybe<Scalars['String']>;
  description_not_ends_with?: Maybe<Scalars['String']>;
  checked?: Maybe<Scalars['Boolean']>;
  checked_not?: Maybe<Scalars['Boolean']>;
  lastUpdated?: Maybe<_Neo4jDateTimeInput>;
  lastUpdated_not?: Maybe<_Neo4jDateTimeInput>;
  lastUpdated_in?: Maybe<Array<_Neo4jDateTimeInput>>;
  lastUpdated_not_in?: Maybe<Array<_Neo4jDateTimeInput>>;
  lastUpdated_lt?: Maybe<_Neo4jDateTimeInput>;
  lastUpdated_lte?: Maybe<_Neo4jDateTimeInput>;
  lastUpdated_gt?: Maybe<_Neo4jDateTimeInput>;
  lastUpdated_gte?: Maybe<_Neo4jDateTimeInput>;
  displayState?: Maybe<ItemDisplayState>;
  displayState_not?: Maybe<ItemDisplayState>;
  displayState_in?: Maybe<Array<ItemDisplayState>>;
  displayState_not_in?: Maybe<Array<ItemDisplayState>>;
  listIndex?: Maybe<Scalars['Int']>;
  listIndex_not?: Maybe<Scalars['Int']>;
  listIndex_in?: Maybe<Array<Scalars['Int']>>;
  listIndex_not_in?: Maybe<Array<Scalars['Int']>>;
  listIndex_lt?: Maybe<Scalars['Int']>;
  listIndex_lte?: Maybe<Scalars['Int']>;
  listIndex_gt?: Maybe<Scalars['Int']>;
  listIndex_gte?: Maybe<Scalars['Int']>;
  updates?: Maybe<Array<Scalars['String']>>;
  updates_not?: Maybe<Array<Scalars['String']>>;
  updates_regexp?: Maybe<Scalars['String']>;
  updates_contains?: Maybe<Array<Scalars['String']>>;
  updates_not_contains?: Maybe<Array<Scalars['String']>>;
  updates_starts_with?: Maybe<Array<Scalars['String']>>;
  updates_not_starts_with?: Maybe<Array<Scalars['String']>>;
  updates_ends_with?: Maybe<Array<Scalars['String']>>;
  updates_not_ends_with?: Maybe<Array<Scalars['String']>>;
  createdAt?: Maybe<_Neo4jDateTimeInput>;
  createdAt_not?: Maybe<_Neo4jDateTimeInput>;
  createdAt_in?: Maybe<Array<_Neo4jDateTimeInput>>;
  createdAt_not_in?: Maybe<Array<_Neo4jDateTimeInput>>;
  createdAt_lt?: Maybe<_Neo4jDateTimeInput>;
  createdAt_lte?: Maybe<_Neo4jDateTimeInput>;
  createdAt_gt?: Maybe<_Neo4jDateTimeInput>;
  createdAt_gte?: Maybe<_Neo4jDateTimeInput>;
  createdBy?: Maybe<_UserFilter>;
  createdBy_not?: Maybe<_UserFilter>;
  createdBy_in?: Maybe<Array<_UserFilter>>;
  createdBy_not_in?: Maybe<Array<_UserFilter>>;
  checklist?: Maybe<_ChecklistFilter>;
  checklist_not?: Maybe<_ChecklistFilter>;
  checklist_in?: Maybe<Array<_ChecklistFilter>>;
  checklist_not_in?: Maybe<Array<_ChecklistFilter>>;
  parent?: Maybe<_ChecklistItemFilter>;
  parent_not?: Maybe<_ChecklistItemFilter>;
  parent_in?: Maybe<Array<_ChecklistItemFilter>>;
  parent_not_in?: Maybe<Array<_ChecklistItemFilter>>;
  children?: Maybe<_ChecklistItemFilter>;
  children_not?: Maybe<_ChecklistItemFilter>;
  children_in?: Maybe<Array<_ChecklistItemFilter>>;
  children_not_in?: Maybe<Array<_ChecklistItemFilter>>;
  children_some?: Maybe<_ChecklistItemFilter>;
  children_none?: Maybe<_ChecklistItemFilter>;
  children_single?: Maybe<_ChecklistItemFilter>;
  children_every?: Maybe<_ChecklistItemFilter>;
  concept?: Maybe<_ConceptFilter>;
  concept_not?: Maybe<_ConceptFilter>;
  concept_in?: Maybe<Array<_ConceptFilter>>;
  concept_not_in?: Maybe<Array<_ConceptFilter>>;
};

export type _ChecklistItemInput = {
  id: Scalars['ID'];
};

export enum _ChecklistItemOrdering {
  ID_ASC = 'id_asc',
  ID_DESC = 'id_desc',
  TITLE_ASC = 'title_asc',
  TITLE_DESC = 'title_desc',
  DESCRIPTION_ASC = 'description_asc',
  DESCRIPTION_DESC = 'description_desc',
  CHECKED_ASC = 'checked_asc',
  CHECKED_DESC = 'checked_desc',
  LASTUPDATED_ASC = 'lastUpdated_asc',
  LASTUPDATED_DESC = 'lastUpdated_desc',
  DISPLAYSTATE_ASC = 'displayState_asc',
  DISPLAYSTATE_DESC = 'displayState_desc',
  LISTINDEX_ASC = 'listIndex_asc',
  LISTINDEX_DESC = 'listIndex_desc',
  CREATEDAT_ASC = 'createdAt_asc',
  CREATEDAT_DESC = 'createdAt_desc',
  _ID_ASC = '_id_asc',
  _ID_DESC = '_id_desc'
}

export enum _ChecklistOrdering {
  ID_ASC = 'id_asc',
  ID_DESC = 'id_desc',
  TITLE_ASC = 'title_asc',
  TITLE_DESC = 'title_desc',
  DESCRIPTION_ASC = 'description_asc',
  DESCRIPTION_DESC = 'description_desc',
  CONCEPTBASED_ASC = 'conceptBased_asc',
  CONCEPTBASED_DESC = 'conceptBased_desc',
  ISORDERED_ASC = 'isOrdered_asc',
  ISORDERED_DESC = 'isOrdered_desc',
  ITEMPREFIXTYPE_ASC = 'itemPrefixType_asc',
  ITEMPREFIXTYPE_DESC = 'itemPrefixType_desc',
  LISTLENGTHLIMIT_ASC = 'listLengthLimit_asc',
  LISTLENGTHLIMIT_DESC = 'listLengthLimit_desc',
  LASTUPDATED_ASC = 'lastUpdated_asc',
  LASTUPDATED_DESC = 'lastUpdated_desc',
  CREATEDAT_ASC = 'createdAt_asc',
  CREATEDAT_DESC = 'createdAt_desc',
  _ID_ASC = '_id_asc',
  _ID_DESC = '_id_desc'
}

export type _ChecklistTypeFilter = {
  AND?: Maybe<Array<_ChecklistTypeFilter>>;
  OR?: Maybe<Array<_ChecklistTypeFilter>>;
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Scalars['ID']>>;
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  id_regexp?: Maybe<Scalars['ID']>;
  id_contains?: Maybe<Scalars['ID']>;
  id_not_contains?: Maybe<Scalars['ID']>;
  id_starts_with?: Maybe<Scalars['ID']>;
  id_not_starts_with?: Maybe<Scalars['ID']>;
  id_ends_with?: Maybe<Scalars['ID']>;
  id_not_ends_with?: Maybe<Scalars['ID']>;
  title?: Maybe<Scalars['String']>;
  title_not?: Maybe<Scalars['String']>;
  title_in?: Maybe<Array<Scalars['String']>>;
  title_not_in?: Maybe<Array<Scalars['String']>>;
  title_regexp?: Maybe<Scalars['String']>;
  title_contains?: Maybe<Scalars['String']>;
  title_not_contains?: Maybe<Scalars['String']>;
  title_starts_with?: Maybe<Scalars['String']>;
  title_not_starts_with?: Maybe<Scalars['String']>;
  title_ends_with?: Maybe<Scalars['String']>;
  title_not_ends_with?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  description_not?: Maybe<Scalars['String']>;
  description_in?: Maybe<Array<Scalars['String']>>;
  description_not_in?: Maybe<Array<Scalars['String']>>;
  description_regexp?: Maybe<Scalars['String']>;
  description_contains?: Maybe<Scalars['String']>;
  description_not_contains?: Maybe<Scalars['String']>;
  description_starts_with?: Maybe<Scalars['String']>;
  description_not_starts_with?: Maybe<Scalars['String']>;
  description_ends_with?: Maybe<Scalars['String']>;
  description_not_ends_with?: Maybe<Scalars['String']>;
  itemPrefixType?: Maybe<ItemPrefixType>;
  itemPrefixType_not?: Maybe<ItemPrefixType>;
  itemPrefixType_in?: Maybe<Array<ItemPrefixType>>;
  itemPrefixType_not_in?: Maybe<Array<ItemPrefixType>>;
  conceptBased?: Maybe<Scalars['Boolean']>;
  conceptBased_not?: Maybe<Scalars['Boolean']>;
  checklists?: Maybe<_ChecklistFilter>;
  checklists_not?: Maybe<_ChecklistFilter>;
  checklists_in?: Maybe<Array<_ChecklistFilter>>;
  checklists_not_in?: Maybe<Array<_ChecklistFilter>>;
  checklists_some?: Maybe<_ChecklistFilter>;
  checklists_none?: Maybe<_ChecklistFilter>;
  checklists_single?: Maybe<_ChecklistFilter>;
  checklists_every?: Maybe<_ChecklistFilter>;
};

export type _ChecklistTypeInput = {
  id: Scalars['ID'];
};

export enum _ChecklistTypeOrdering {
  ID_ASC = 'id_asc',
  ID_DESC = 'id_desc',
  TITLE_ASC = 'title_asc',
  TITLE_DESC = 'title_desc',
  DESCRIPTION_ASC = 'description_asc',
  DESCRIPTION_DESC = 'description_desc',
  ITEMPREFIXTYPE_ASC = 'itemPrefixType_asc',
  ITEMPREFIXTYPE_DESC = 'itemPrefixType_desc',
  CONCEPTBASED_ASC = 'conceptBased_asc',
  CONCEPTBASED_DESC = 'conceptBased_desc',
  _ID_ASC = '_id_asc',
  _ID_DESC = '_id_desc'
}

export type _CommentFilter = {
  AND?: Maybe<Array<_CommentFilter>>;
  OR?: Maybe<Array<_CommentFilter>>;
  commentId?: Maybe<Scalars['ID']>;
  commentId_not?: Maybe<Scalars['ID']>;
  commentId_in?: Maybe<Array<Scalars['ID']>>;
  commentId_not_in?: Maybe<Array<Scalars['ID']>>;
  commentId_regexp?: Maybe<Scalars['ID']>;
  commentId_contains?: Maybe<Scalars['ID']>;
  commentId_not_contains?: Maybe<Scalars['ID']>;
  commentId_starts_with?: Maybe<Scalars['ID']>;
  commentId_not_starts_with?: Maybe<Scalars['ID']>;
  commentId_ends_with?: Maybe<Scalars['ID']>;
  commentId_not_ends_with?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<_Neo4jDateTimeInput>;
  createdAt_not?: Maybe<_Neo4jDateTimeInput>;
  createdAt_in?: Maybe<Array<_Neo4jDateTimeInput>>;
  createdAt_not_in?: Maybe<Array<_Neo4jDateTimeInput>>;
  createdAt_lt?: Maybe<_Neo4jDateTimeInput>;
  createdAt_lte?: Maybe<_Neo4jDateTimeInput>;
  createdAt_gt?: Maybe<_Neo4jDateTimeInput>;
  createdAt_gte?: Maybe<_Neo4jDateTimeInput>;
  user?: Maybe<_UserFilter>;
  user_not?: Maybe<_UserFilter>;
  user_in?: Maybe<Array<_UserFilter>>;
  user_not_in?: Maybe<Array<_UserFilter>>;
  body?: Maybe<Scalars['String']>;
  body_not?: Maybe<Scalars['String']>;
  body_in?: Maybe<Array<Scalars['String']>>;
  body_not_in?: Maybe<Array<Scalars['String']>>;
  body_regexp?: Maybe<Scalars['String']>;
  body_contains?: Maybe<Scalars['String']>;
  body_not_contains?: Maybe<Scalars['String']>;
  body_starts_with?: Maybe<Scalars['String']>;
  body_not_starts_with?: Maybe<Scalars['String']>;
  body_ends_with?: Maybe<Scalars['String']>;
  body_not_ends_with?: Maybe<Scalars['String']>;
  concept?: Maybe<_ConceptFilter>;
  concept_not?: Maybe<_ConceptFilter>;
  concept_in?: Maybe<Array<_ConceptFilter>>;
  concept_not_in?: Maybe<Array<_ConceptFilter>>;
  replyTo?: Maybe<_CommentFilter>;
  replyTo_not?: Maybe<_CommentFilter>;
  replyTo_in?: Maybe<Array<_CommentFilter>>;
  replyTo_not_in?: Maybe<Array<_CommentFilter>>;
  replyTo_some?: Maybe<_CommentFilter>;
  replyTo_none?: Maybe<_CommentFilter>;
  replyTo_single?: Maybe<_CommentFilter>;
  replyTo_every?: Maybe<_CommentFilter>;
  replies?: Maybe<_CommentFilter>;
  replies_not?: Maybe<_CommentFilter>;
  replies_in?: Maybe<Array<_CommentFilter>>;
  replies_not_in?: Maybe<Array<_CommentFilter>>;
  replies_some?: Maybe<_CommentFilter>;
  replies_none?: Maybe<_CommentFilter>;
  replies_single?: Maybe<_CommentFilter>;
  replies_every?: Maybe<_CommentFilter>;
  notifications?: Maybe<_NotificationFilter>;
  notifications_not?: Maybe<_NotificationFilter>;
  notifications_in?: Maybe<Array<_NotificationFilter>>;
  notifications_not_in?: Maybe<Array<_NotificationFilter>>;
  notifications_some?: Maybe<_NotificationFilter>;
  notifications_none?: Maybe<_NotificationFilter>;
  notifications_single?: Maybe<_NotificationFilter>;
  notifications_every?: Maybe<_NotificationFilter>;
  likedBy?: Maybe<_UserFilter>;
  likedBy_not?: Maybe<_UserFilter>;
  likedBy_in?: Maybe<Array<_UserFilter>>;
  likedBy_not_in?: Maybe<Array<_UserFilter>>;
  likedBy_some?: Maybe<_UserFilter>;
  likedBy_none?: Maybe<_UserFilter>;
  likedBy_single?: Maybe<_UserFilter>;
  likedBy_every?: Maybe<_UserFilter>;
};

export type _CommentInput = {
  commentId: Scalars['ID'];
};

export enum _CommentOrdering {
  COMMENTID_ASC = 'commentId_asc',
  COMMENTID_DESC = 'commentId_desc',
  CREATEDAT_ASC = 'createdAt_asc',
  CREATEDAT_DESC = 'createdAt_desc',
  BODY_ASC = 'body_asc',
  BODY_DESC = 'body_desc',
  REPLYCOUNT_ASC = 'replyCount_asc',
  REPLYCOUNT_DESC = 'replyCount_desc',
  _ID_ASC = '_id_asc',
  _ID_DESC = '_id_desc'
}

export type _CompanyFilter = {
  AND?: Maybe<Array<_CompanyFilter>>;
  OR?: Maybe<Array<_CompanyFilter>>;
  companyId?: Maybe<Scalars['ID']>;
  companyId_not?: Maybe<Scalars['ID']>;
  companyId_in?: Maybe<Array<Scalars['ID']>>;
  companyId_not_in?: Maybe<Array<Scalars['ID']>>;
  companyId_regexp?: Maybe<Scalars['ID']>;
  companyId_contains?: Maybe<Scalars['ID']>;
  companyId_not_contains?: Maybe<Scalars['ID']>;
  companyId_starts_with?: Maybe<Scalars['ID']>;
  companyId_not_starts_with?: Maybe<Scalars['ID']>;
  companyId_ends_with?: Maybe<Scalars['ID']>;
  companyId_not_ends_with?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  name_not?: Maybe<Scalars['String']>;
  name_in?: Maybe<Array<Scalars['String']>>;
  name_not_in?: Maybe<Array<Scalars['String']>>;
  name_regexp?: Maybe<Scalars['String']>;
  name_contains?: Maybe<Scalars['String']>;
  name_not_contains?: Maybe<Scalars['String']>;
  name_starts_with?: Maybe<Scalars['String']>;
  name_not_starts_with?: Maybe<Scalars['String']>;
  name_ends_with?: Maybe<Scalars['String']>;
  name_not_ends_with?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  address_not?: Maybe<Scalars['String']>;
  address_in?: Maybe<Array<Scalars['String']>>;
  address_not_in?: Maybe<Array<Scalars['String']>>;
  address_regexp?: Maybe<Scalars['String']>;
  address_contains?: Maybe<Scalars['String']>;
  address_not_contains?: Maybe<Scalars['String']>;
  address_starts_with?: Maybe<Scalars['String']>;
  address_not_starts_with?: Maybe<Scalars['String']>;
  address_ends_with?: Maybe<Scalars['String']>;
  address_not_ends_with?: Maybe<Scalars['String']>;
  employees?: Maybe<_UserFilter>;
  employees_not?: Maybe<_UserFilter>;
  employees_in?: Maybe<Array<_UserFilter>>;
  employees_not_in?: Maybe<Array<_UserFilter>>;
  employees_some?: Maybe<_UserFilter>;
  employees_none?: Maybe<_UserFilter>;
  employees_single?: Maybe<_UserFilter>;
  employees_every?: Maybe<_UserFilter>;
  industries?: Maybe<_IndustryFilter>;
  industries_not?: Maybe<_IndustryFilter>;
  industries_in?: Maybe<Array<_IndustryFilter>>;
  industries_not_in?: Maybe<Array<_IndustryFilter>>;
  industries_some?: Maybe<_IndustryFilter>;
  industries_none?: Maybe<_IndustryFilter>;
  industries_single?: Maybe<_IndustryFilter>;
  industries_every?: Maybe<_IndustryFilter>;
  teams?: Maybe<_TeamFilter>;
  teams_not?: Maybe<_TeamFilter>;
  teams_in?: Maybe<Array<_TeamFilter>>;
  teams_not_in?: Maybe<Array<_TeamFilter>>;
  teams_some?: Maybe<_TeamFilter>;
  teams_none?: Maybe<_TeamFilter>;
  teams_single?: Maybe<_TeamFilter>;
  teams_every?: Maybe<_TeamFilter>;
};

export type _CompanyInput = {
  companyId: Scalars['ID'];
};

export enum _CompanyOrdering {
  COMPANYID_ASC = 'companyId_asc',
  COMPANYID_DESC = 'companyId_desc',
  NAME_ASC = 'name_asc',
  NAME_DESC = 'name_desc',
  ADDRESS_ASC = 'address_asc',
  ADDRESS_DESC = 'address_desc',
  _ID_ASC = '_id_asc',
  _ID_DESC = '_id_desc'
}

export type _ConceptAddedTeams = {
  __typename?: '_ConceptAddedTeams';
  type?: Maybe<AccessType>;
  updatedAt?: Maybe<_Neo4jDateTime>;
  /** Generated field for querying the Neo4j [system id](https://neo4j.com/docs/cypher-manual/current/functions/scalar/#functions-id) of this relationship. */
  _id?: Maybe<Scalars['String']>;
  /** Field for the Team node this HAS_ACCESS [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  Team?: Maybe<Team>;
};

export type _ConceptAddedUsers = {
  __typename?: '_ConceptAddedUsers';
  type?: Maybe<AccessType>;
  updatedAt?: Maybe<_Neo4jDateTime>;
  /** Generated field for querying the Neo4j [system id](https://neo4j.com/docs/cypher-manual/current/functions/scalar/#functions-id) of this relationship. */
  _id?: Maybe<Scalars['String']>;
  /** Field for the User node this HAS_ACCESS [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  User?: Maybe<User>;
};

export type _ConceptBoardFilter = {
  AND?: Maybe<Array<_ConceptBoardFilter>>;
  OR?: Maybe<Array<_ConceptBoardFilter>>;
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Scalars['ID']>>;
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  id_regexp?: Maybe<Scalars['ID']>;
  id_contains?: Maybe<Scalars['ID']>;
  id_not_contains?: Maybe<Scalars['ID']>;
  id_starts_with?: Maybe<Scalars['ID']>;
  id_not_starts_with?: Maybe<Scalars['ID']>;
  id_ends_with?: Maybe<Scalars['ID']>;
  id_not_ends_with?: Maybe<Scalars['ID']>;
  title?: Maybe<Scalars['String']>;
  title_not?: Maybe<Scalars['String']>;
  title_in?: Maybe<Array<Scalars['String']>>;
  title_not_in?: Maybe<Array<Scalars['String']>>;
  title_regexp?: Maybe<Scalars['String']>;
  title_contains?: Maybe<Scalars['String']>;
  title_not_contains?: Maybe<Scalars['String']>;
  title_starts_with?: Maybe<Scalars['String']>;
  title_not_starts_with?: Maybe<Scalars['String']>;
  title_ends_with?: Maybe<Scalars['String']>;
  title_not_ends_with?: Maybe<Scalars['String']>;
  boardType?: Maybe<Scalars['String']>;
  boardType_not?: Maybe<Scalars['String']>;
  boardType_in?: Maybe<Array<Scalars['String']>>;
  boardType_not_in?: Maybe<Array<Scalars['String']>>;
  boardType_regexp?: Maybe<Scalars['String']>;
  boardType_contains?: Maybe<Scalars['String']>;
  boardType_not_contains?: Maybe<Scalars['String']>;
  boardType_starts_with?: Maybe<Scalars['String']>;
  boardType_not_starts_with?: Maybe<Scalars['String']>;
  boardType_ends_with?: Maybe<Scalars['String']>;
  boardType_not_ends_with?: Maybe<Scalars['String']>;
  columns?: Maybe<_ConceptListFilter>;
  columns_not?: Maybe<_ConceptListFilter>;
  columns_in?: Maybe<Array<_ConceptListFilter>>;
  columns_not_in?: Maybe<Array<_ConceptListFilter>>;
  columns_some?: Maybe<_ConceptListFilter>;
  columns_none?: Maybe<_ConceptListFilter>;
  columns_single?: Maybe<_ConceptListFilter>;
  columns_every?: Maybe<_ConceptListFilter>;
  columnOrder?: Maybe<Array<Scalars['ID']>>;
  columnOrder_not?: Maybe<Array<Scalars['ID']>>;
  columnOrder_regexp?: Maybe<Scalars['ID']>;
  columnOrder_contains?: Maybe<Array<Scalars['ID']>>;
  columnOrder_not_contains?: Maybe<Array<Scalars['ID']>>;
  columnOrder_starts_with?: Maybe<Array<Scalars['ID']>>;
  columnOrder_not_starts_with?: Maybe<Array<Scalars['ID']>>;
  columnOrder_ends_with?: Maybe<Array<Scalars['ID']>>;
  columnOrder_not_ends_with?: Maybe<Array<Scalars['ID']>>;
  user?: Maybe<_UserFilter>;
  user_not?: Maybe<_UserFilter>;
  user_in?: Maybe<Array<_UserFilter>>;
  user_not_in?: Maybe<Array<_UserFilter>>;
};

export type _ConceptBoardInput = {
  id: Scalars['ID'];
};

export enum _ConceptBoardOrdering {
  ID_ASC = 'id_asc',
  ID_DESC = 'id_desc',
  TITLE_ASC = 'title_asc',
  TITLE_DESC = 'title_desc',
  BOARDTYPE_ASC = 'boardType_asc',
  BOARDTYPE_DESC = 'boardType_desc',
  _ID_ASC = '_id_asc',
  _ID_DESC = '_id_desc'
}

export type _ConceptConceptUpdateFilter = {
  AND?: Maybe<Array<_ConceptConceptUpdateFilter>>;
  OR?: Maybe<Array<_ConceptConceptUpdateFilter>>;
  updatedAt?: Maybe<_Neo4jDateTimeInput>;
  updatedAt_not?: Maybe<_Neo4jDateTimeInput>;
  updatedAt_in?: Maybe<Array<_Neo4jDateTimeInput>>;
  updatedAt_not_in?: Maybe<Array<_Neo4jDateTimeInput>>;
  updatedAt_lt?: Maybe<_Neo4jDateTimeInput>;
  updatedAt_lte?: Maybe<_Neo4jDateTimeInput>;
  updatedAt_gt?: Maybe<_Neo4jDateTimeInput>;
  updatedAt_gte?: Maybe<_Neo4jDateTimeInput>;
  updateValue?: Maybe<Scalars['String']>;
  updateValue_not?: Maybe<Scalars['String']>;
  updateValue_in?: Maybe<Array<Scalars['String']>>;
  updateValue_not_in?: Maybe<Array<Scalars['String']>>;
  updateValue_regexp?: Maybe<Scalars['String']>;
  updateValue_contains?: Maybe<Scalars['String']>;
  updateValue_not_contains?: Maybe<Scalars['String']>;
  updateValue_starts_with?: Maybe<Scalars['String']>;
  updateValue_not_starts_with?: Maybe<Scalars['String']>;
  updateValue_ends_with?: Maybe<Scalars['String']>;
  updateValue_not_ends_with?: Maybe<Scalars['String']>;
  User?: Maybe<_UserFilter>;
};

export type _ConceptConnectionDirectionsFilter = {
  from?: Maybe<_ConceptConnectionFilter>;
  to?: Maybe<_ConceptConnectionFilter>;
};

export type _ConceptConnectionFilter = {
  AND?: Maybe<Array<_ConceptConnectionFilter>>;
  OR?: Maybe<Array<_ConceptConnectionFilter>>;
  description?: Maybe<Scalars['String']>;
  description_not?: Maybe<Scalars['String']>;
  description_in?: Maybe<Array<Scalars['String']>>;
  description_not_in?: Maybe<Array<Scalars['String']>>;
  description_regexp?: Maybe<Scalars['String']>;
  description_contains?: Maybe<Scalars['String']>;
  description_not_contains?: Maybe<Scalars['String']>;
  description_starts_with?: Maybe<Scalars['String']>;
  description_not_starts_with?: Maybe<Scalars['String']>;
  description_ends_with?: Maybe<Scalars['String']>;
  description_not_ends_with?: Maybe<Scalars['String']>;
  type?: Maybe<ConnectionType>;
  type_not?: Maybe<ConnectionType>;
  type_in?: Maybe<Array<ConnectionType>>;
  type_not_in?: Maybe<Array<ConnectionType>>;
  username?: Maybe<Scalars['String']>;
  username_not?: Maybe<Scalars['String']>;
  username_in?: Maybe<Array<Scalars['String']>>;
  username_not_in?: Maybe<Array<Scalars['String']>>;
  username_regexp?: Maybe<Scalars['String']>;
  username_contains?: Maybe<Scalars['String']>;
  username_not_contains?: Maybe<Scalars['String']>;
  username_starts_with?: Maybe<Scalars['String']>;
  username_not_starts_with?: Maybe<Scalars['String']>;
  username_ends_with?: Maybe<Scalars['String']>;
  username_not_ends_with?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<_Neo4jDateTimeInput>;
  updatedAt_not?: Maybe<_Neo4jDateTimeInput>;
  updatedAt_in?: Maybe<Array<_Neo4jDateTimeInput>>;
  updatedAt_not_in?: Maybe<Array<_Neo4jDateTimeInput>>;
  updatedAt_lt?: Maybe<_Neo4jDateTimeInput>;
  updatedAt_lte?: Maybe<_Neo4jDateTimeInput>;
  updatedAt_gt?: Maybe<_Neo4jDateTimeInput>;
  updatedAt_gte?: Maybe<_Neo4jDateTimeInput>;
  updateValue?: Maybe<Scalars['String']>;
  updateValue_not?: Maybe<Scalars['String']>;
  updateValue_in?: Maybe<Array<Scalars['String']>>;
  updateValue_not_in?: Maybe<Array<Scalars['String']>>;
  updateValue_regexp?: Maybe<Scalars['String']>;
  updateValue_contains?: Maybe<Scalars['String']>;
  updateValue_not_contains?: Maybe<Scalars['String']>;
  updateValue_starts_with?: Maybe<Scalars['String']>;
  updateValue_not_starts_with?: Maybe<Scalars['String']>;
  updateValue_ends_with?: Maybe<Scalars['String']>;
  updateValue_not_ends_with?: Maybe<Scalars['String']>;
  Concept?: Maybe<_ConceptFilter>;
};

export type _ConceptConnectionInput = {
  description?: Maybe<Scalars['String']>;
  type?: Maybe<ConnectionType>;
  username?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<_Neo4jDateTimeInput>;
  updateValue?: Maybe<Scalars['String']>;
};

export enum _ConceptConnectionOrdering {
  DESCRIPTION_ASC = 'description_asc',
  DESCRIPTION_DESC = 'description_desc',
  TYPE_ASC = 'type_asc',
  TYPE_DESC = 'type_desc',
  USERNAME_ASC = 'username_asc',
  USERNAME_DESC = 'username_desc',
  UPDATEDAT_ASC = 'updatedAt_asc',
  UPDATEDAT_DESC = 'updatedAt_desc',
  UPDATEVALUE_ASC = 'updateValue_asc',
  UPDATEVALUE_DESC = 'updateValue_desc',
  _ID_ASC = '_id_asc',
  _ID_DESC = '_id_desc'
}

export type _ConceptDetailedConnections = {
  __typename?: '_ConceptDetailedConnections';
  description?: Maybe<Scalars['String']>;
  type?: Maybe<ConnectionType>;
  username?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<_Neo4jDateTime>;
  updateValue?: Maybe<Scalars['String']>;
  /** Generated field for querying the Neo4j [system id](https://neo4j.com/docs/cypher-manual/current/functions/scalar/#functions-id) of this relationship. */
  _id?: Maybe<Scalars['String']>;
  /** Field for the Concept node this CONNECTED [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  Concept?: Maybe<Concept>;
};

export type _ConceptDetailedConnectionsDirections = {
  __typename?: '_ConceptDetailedConnectionsDirections';
  /** Field for the Concept node this CONNECTED [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<Array<Maybe<_ConceptDetailedConnections>>>;
  /** Field for the Concept node this CONNECTED [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<Array<Maybe<_ConceptDetailedConnections>>>;
};


export type _ConceptDetailedConnectionsDirectionsFromArgs = {
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<Maybe<_ConceptConnectionOrdering>>>;
  filter?: Maybe<_ConceptConnectionFilter>;
};


export type _ConceptDetailedConnectionsDirectionsToArgs = {
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<Maybe<_ConceptConnectionOrdering>>>;
  filter?: Maybe<_ConceptConnectionFilter>;
};

export type _ConceptFilter = {
  AND?: Maybe<Array<_ConceptFilter>>;
  OR?: Maybe<Array<_ConceptFilter>>;
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Scalars['ID']>>;
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  id_regexp?: Maybe<Scalars['ID']>;
  id_contains?: Maybe<Scalars['ID']>;
  id_not_contains?: Maybe<Scalars['ID']>;
  id_starts_with?: Maybe<Scalars['ID']>;
  id_not_starts_with?: Maybe<Scalars['ID']>;
  id_ends_with?: Maybe<Scalars['ID']>;
  id_not_ends_with?: Maybe<Scalars['ID']>;
  slug?: Maybe<Scalars['String']>;
  slug_not?: Maybe<Scalars['String']>;
  slug_in?: Maybe<Array<Scalars['String']>>;
  slug_not_in?: Maybe<Array<Scalars['String']>>;
  slug_regexp?: Maybe<Scalars['String']>;
  slug_contains?: Maybe<Scalars['String']>;
  slug_not_contains?: Maybe<Scalars['String']>;
  slug_starts_with?: Maybe<Scalars['String']>;
  slug_not_starts_with?: Maybe<Scalars['String']>;
  slug_ends_with?: Maybe<Scalars['String']>;
  slug_not_ends_with?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  title_not?: Maybe<Scalars['String']>;
  title_in?: Maybe<Array<Scalars['String']>>;
  title_not_in?: Maybe<Array<Scalars['String']>>;
  title_regexp?: Maybe<Scalars['String']>;
  title_contains?: Maybe<Scalars['String']>;
  title_not_contains?: Maybe<Scalars['String']>;
  title_starts_with?: Maybe<Scalars['String']>;
  title_not_starts_with?: Maybe<Scalars['String']>;
  title_ends_with?: Maybe<Scalars['String']>;
  title_not_ends_with?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  type_not?: Maybe<Scalars['String']>;
  type_in?: Maybe<Array<Scalars['String']>>;
  type_not_in?: Maybe<Array<Scalars['String']>>;
  type_regexp?: Maybe<Scalars['String']>;
  type_contains?: Maybe<Scalars['String']>;
  type_not_contains?: Maybe<Scalars['String']>;
  type_starts_with?: Maybe<Scalars['String']>;
  type_not_starts_with?: Maybe<Scalars['String']>;
  type_ends_with?: Maybe<Scalars['String']>;
  type_not_ends_with?: Maybe<Scalars['String']>;
  summary?: Maybe<Scalars['String']>;
  summary_not?: Maybe<Scalars['String']>;
  summary_in?: Maybe<Array<Scalars['String']>>;
  summary_not_in?: Maybe<Array<Scalars['String']>>;
  summary_regexp?: Maybe<Scalars['String']>;
  summary_contains?: Maybe<Scalars['String']>;
  summary_not_contains?: Maybe<Scalars['String']>;
  summary_starts_with?: Maybe<Scalars['String']>;
  summary_not_starts_with?: Maybe<Scalars['String']>;
  summary_ends_with?: Maybe<Scalars['String']>;
  summary_not_ends_with?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  description_not?: Maybe<Scalars['String']>;
  description_in?: Maybe<Array<Scalars['String']>>;
  description_not_in?: Maybe<Array<Scalars['String']>>;
  description_regexp?: Maybe<Scalars['String']>;
  description_contains?: Maybe<Scalars['String']>;
  description_not_contains?: Maybe<Scalars['String']>;
  description_starts_with?: Maybe<Scalars['String']>;
  description_not_starts_with?: Maybe<Scalars['String']>;
  description_ends_with?: Maybe<Scalars['String']>;
  description_not_ends_with?: Maybe<Scalars['String']>;
  translations?: Maybe<_TranslationFilter>;
  translations_not?: Maybe<_TranslationFilter>;
  translations_in?: Maybe<Array<_TranslationFilter>>;
  translations_not_in?: Maybe<Array<_TranslationFilter>>;
  translations_some?: Maybe<_TranslationFilter>;
  translations_none?: Maybe<_TranslationFilter>;
  translations_single?: Maybe<_TranslationFilter>;
  translations_every?: Maybe<_TranslationFilter>;
  username?: Maybe<Scalars['String']>;
  username_not?: Maybe<Scalars['String']>;
  username_in?: Maybe<Array<Scalars['String']>>;
  username_not_in?: Maybe<Array<Scalars['String']>>;
  username_regexp?: Maybe<Scalars['String']>;
  username_contains?: Maybe<Scalars['String']>;
  username_not_contains?: Maybe<Scalars['String']>;
  username_starts_with?: Maybe<Scalars['String']>;
  username_not_starts_with?: Maybe<Scalars['String']>;
  username_ends_with?: Maybe<Scalars['String']>;
  username_not_ends_with?: Maybe<Scalars['String']>;
  addedUsers?: Maybe<_ConceptUserAccessFilter>;
  addedUsers_not?: Maybe<_ConceptUserAccessFilter>;
  addedUsers_in?: Maybe<Array<_ConceptUserAccessFilter>>;
  addedUsers_not_in?: Maybe<Array<_ConceptUserAccessFilter>>;
  addedUsers_some?: Maybe<_ConceptUserAccessFilter>;
  addedUsers_none?: Maybe<_ConceptUserAccessFilter>;
  addedUsers_single?: Maybe<_ConceptUserAccessFilter>;
  addedUsers_every?: Maybe<_ConceptUserAccessFilter>;
  addedTeams?: Maybe<_ConceptTeamAccessFilter>;
  addedTeams_not?: Maybe<_ConceptTeamAccessFilter>;
  addedTeams_in?: Maybe<Array<_ConceptTeamAccessFilter>>;
  addedTeams_not_in?: Maybe<Array<_ConceptTeamAccessFilter>>;
  addedTeams_some?: Maybe<_ConceptTeamAccessFilter>;
  addedTeams_none?: Maybe<_ConceptTeamAccessFilter>;
  addedTeams_single?: Maybe<_ConceptTeamAccessFilter>;
  addedTeams_every?: Maybe<_ConceptTeamAccessFilter>;
  userHomepage?: Maybe<_UserFilter>;
  userHomepage_not?: Maybe<_UserFilter>;
  userHomepage_in?: Maybe<Array<_UserFilter>>;
  userHomepage_not_in?: Maybe<Array<_UserFilter>>;
  teamHomepage?: Maybe<_TeamFilter>;
  teamHomepage_not?: Maybe<_TeamFilter>;
  teamHomepage_in?: Maybe<Array<_TeamFilter>>;
  teamHomepage_not_in?: Maybe<Array<_TeamFilter>>;
  industries?: Maybe<_IndustryFilter>;
  industries_not?: Maybe<_IndustryFilter>;
  industries_in?: Maybe<Array<_IndustryFilter>>;
  industries_not_in?: Maybe<Array<_IndustryFilter>>;
  industries_some?: Maybe<_IndustryFilter>;
  industries_none?: Maybe<_IndustryFilter>;
  industries_single?: Maybe<_IndustryFilter>;
  industries_every?: Maybe<_IndustryFilter>;
  recentUpdates?: Maybe<_ConceptConceptUpdateFilter>;
  recentUpdates_not?: Maybe<_ConceptConceptUpdateFilter>;
  recentUpdates_in?: Maybe<Array<_ConceptConceptUpdateFilter>>;
  recentUpdates_not_in?: Maybe<Array<_ConceptConceptUpdateFilter>>;
  recentUpdates_some?: Maybe<_ConceptConceptUpdateFilter>;
  recentUpdates_none?: Maybe<_ConceptConceptUpdateFilter>;
  recentUpdates_single?: Maybe<_ConceptConceptUpdateFilter>;
  recentUpdates_every?: Maybe<_ConceptConceptUpdateFilter>;
  createdBy?: Maybe<_UserFilter>;
  createdBy_not?: Maybe<_UserFilter>;
  createdBy_in?: Maybe<Array<_UserFilter>>;
  createdBy_not_in?: Maybe<Array<_UserFilter>>;
  createdAt?: Maybe<_Neo4jDateTimeInput>;
  createdAt_not?: Maybe<_Neo4jDateTimeInput>;
  createdAt_in?: Maybe<Array<_Neo4jDateTimeInput>>;
  createdAt_not_in?: Maybe<Array<_Neo4jDateTimeInput>>;
  createdAt_lt?: Maybe<_Neo4jDateTimeInput>;
  createdAt_lte?: Maybe<_Neo4jDateTimeInput>;
  createdAt_gt?: Maybe<_Neo4jDateTimeInput>;
  createdAt_gte?: Maybe<_Neo4jDateTimeInput>;
  startDate?: Maybe<_Neo4jDateTimeInput>;
  startDate_not?: Maybe<_Neo4jDateTimeInput>;
  startDate_in?: Maybe<Array<_Neo4jDateTimeInput>>;
  startDate_not_in?: Maybe<Array<_Neo4jDateTimeInput>>;
  startDate_lt?: Maybe<_Neo4jDateTimeInput>;
  startDate_lte?: Maybe<_Neo4jDateTimeInput>;
  startDate_gt?: Maybe<_Neo4jDateTimeInput>;
  startDate_gte?: Maybe<_Neo4jDateTimeInput>;
  endDate?: Maybe<_Neo4jDateTimeInput>;
  endDate_not?: Maybe<_Neo4jDateTimeInput>;
  endDate_in?: Maybe<Array<_Neo4jDateTimeInput>>;
  endDate_not_in?: Maybe<Array<_Neo4jDateTimeInput>>;
  endDate_lt?: Maybe<_Neo4jDateTimeInput>;
  endDate_lte?: Maybe<_Neo4jDateTimeInput>;
  endDate_gt?: Maybe<_Neo4jDateTimeInput>;
  endDate_gte?: Maybe<_Neo4jDateTimeInput>;
  isArchived?: Maybe<Scalars['Boolean']>;
  isArchived_not?: Maybe<Scalars['Boolean']>;
  isPublic?: Maybe<Scalars['Boolean']>;
  isPublic_not?: Maybe<Scalars['Boolean']>;
  isPublicAccessType?: Maybe<AccessType>;
  isPublicAccessType_not?: Maybe<AccessType>;
  isPublicAccessType_in?: Maybe<Array<AccessType>>;
  isPublicAccessType_not_in?: Maybe<Array<AccessType>>;
  source?: Maybe<Scalars['String']>;
  source_not?: Maybe<Scalars['String']>;
  source_in?: Maybe<Array<Scalars['String']>>;
  source_not_in?: Maybe<Array<Scalars['String']>>;
  source_regexp?: Maybe<Scalars['String']>;
  source_contains?: Maybe<Scalars['String']>;
  source_not_contains?: Maybe<Scalars['String']>;
  source_starts_with?: Maybe<Scalars['String']>;
  source_not_starts_with?: Maybe<Scalars['String']>;
  source_ends_with?: Maybe<Scalars['String']>;
  source_not_ends_with?: Maybe<Scalars['String']>;
  sourceId?: Maybe<Scalars['String']>;
  sourceId_not?: Maybe<Scalars['String']>;
  sourceId_in?: Maybe<Array<Scalars['String']>>;
  sourceId_not_in?: Maybe<Array<Scalars['String']>>;
  sourceId_regexp?: Maybe<Scalars['String']>;
  sourceId_contains?: Maybe<Scalars['String']>;
  sourceId_not_contains?: Maybe<Scalars['String']>;
  sourceId_starts_with?: Maybe<Scalars['String']>;
  sourceId_not_starts_with?: Maybe<Scalars['String']>;
  sourceId_ends_with?: Maybe<Scalars['String']>;
  sourceId_not_ends_with?: Maybe<Scalars['String']>;
  vis?: Maybe<Visibility>;
  vis_not?: Maybe<Visibility>;
  vis_in?: Maybe<Array<Visibility>>;
  vis_not_in?: Maybe<Array<Visibility>>;
  checklistIds?: Maybe<Array<Scalars['ID']>>;
  checklistIds_not?: Maybe<Array<Scalars['ID']>>;
  checklistIds_regexp?: Maybe<Scalars['ID']>;
  checklistIds_contains?: Maybe<Array<Scalars['ID']>>;
  checklistIds_not_contains?: Maybe<Array<Scalars['ID']>>;
  checklistIds_starts_with?: Maybe<Array<Scalars['ID']>>;
  checklistIds_not_starts_with?: Maybe<Array<Scalars['ID']>>;
  checklistIds_ends_with?: Maybe<Array<Scalars['ID']>>;
  checklistIds_not_ends_with?: Maybe<Array<Scalars['ID']>>;
  dashboardIds?: Maybe<Array<Scalars['ID']>>;
  dashboardIds_not?: Maybe<Array<Scalars['ID']>>;
  dashboardIds_regexp?: Maybe<Scalars['ID']>;
  dashboardIds_contains?: Maybe<Array<Scalars['ID']>>;
  dashboardIds_not_contains?: Maybe<Array<Scalars['ID']>>;
  dashboardIds_starts_with?: Maybe<Array<Scalars['ID']>>;
  dashboardIds_not_starts_with?: Maybe<Array<Scalars['ID']>>;
  dashboardIds_ends_with?: Maybe<Array<Scalars['ID']>>;
  dashboardIds_not_ends_with?: Maybe<Array<Scalars['ID']>>;
  status?: Maybe<_ConceptStatusFilter>;
  status_not?: Maybe<_ConceptStatusFilter>;
  status_in?: Maybe<Array<_ConceptStatusFilter>>;
  status_not_in?: Maybe<Array<_ConceptStatusFilter>>;
  status_some?: Maybe<_ConceptStatusFilter>;
  status_none?: Maybe<_ConceptStatusFilter>;
  status_single?: Maybe<_ConceptStatusFilter>;
  status_every?: Maybe<_ConceptStatusFilter>;
  parent?: Maybe<_ConceptFilter>;
  parent_not?: Maybe<_ConceptFilter>;
  parent_in?: Maybe<Array<_ConceptFilter>>;
  parent_not_in?: Maybe<Array<_ConceptFilter>>;
  children?: Maybe<_ConceptFilter>;
  children_not?: Maybe<_ConceptFilter>;
  children_in?: Maybe<Array<_ConceptFilter>>;
  children_not_in?: Maybe<Array<_ConceptFilter>>;
  children_some?: Maybe<_ConceptFilter>;
  children_none?: Maybe<_ConceptFilter>;
  children_single?: Maybe<_ConceptFilter>;
  children_every?: Maybe<_ConceptFilter>;
  category?: Maybe<_CategoryFilter>;
  category_not?: Maybe<_CategoryFilter>;
  category_in?: Maybe<Array<_CategoryFilter>>;
  category_not_in?: Maybe<Array<_CategoryFilter>>;
  imageUrl?: Maybe<Scalars['String']>;
  imageUrl_not?: Maybe<Scalars['String']>;
  imageUrl_in?: Maybe<Array<Scalars['String']>>;
  imageUrl_not_in?: Maybe<Array<Scalars['String']>>;
  imageUrl_regexp?: Maybe<Scalars['String']>;
  imageUrl_contains?: Maybe<Scalars['String']>;
  imageUrl_not_contains?: Maybe<Scalars['String']>;
  imageUrl_starts_with?: Maybe<Scalars['String']>;
  imageUrl_not_starts_with?: Maybe<Scalars['String']>;
  imageUrl_ends_with?: Maybe<Scalars['String']>;
  imageUrl_not_ends_with?: Maybe<Scalars['String']>;
  webLink?: Maybe<_LinkFilter>;
  webLink_not?: Maybe<_LinkFilter>;
  webLink_in?: Maybe<Array<_LinkFilter>>;
  webLink_not_in?: Maybe<Array<_LinkFilter>>;
  likedBy?: Maybe<_UserFilter>;
  likedBy_not?: Maybe<_UserFilter>;
  likedBy_in?: Maybe<Array<_UserFilter>>;
  likedBy_not_in?: Maybe<Array<_UserFilter>>;
  likedBy_some?: Maybe<_UserFilter>;
  likedBy_none?: Maybe<_UserFilter>;
  likedBy_single?: Maybe<_UserFilter>;
  likedBy_every?: Maybe<_UserFilter>;
  criteria?: Maybe<_CriteriaFilter>;
  criteria_not?: Maybe<_CriteriaFilter>;
  criteria_in?: Maybe<Array<_CriteriaFilter>>;
  criteria_not_in?: Maybe<Array<_CriteriaFilter>>;
  criteria_some?: Maybe<_CriteriaFilter>;
  criteria_none?: Maybe<_CriteriaFilter>;
  criteria_single?: Maybe<_CriteriaFilter>;
  criteria_every?: Maybe<_CriteriaFilter>;
  criteriaScores?: Maybe<_CriteriaScoreFilter>;
  criteriaScores_not?: Maybe<_CriteriaScoreFilter>;
  criteriaScores_in?: Maybe<Array<_CriteriaScoreFilter>>;
  criteriaScores_not_in?: Maybe<Array<_CriteriaScoreFilter>>;
  criteriaScores_some?: Maybe<_CriteriaScoreFilter>;
  criteriaScores_none?: Maybe<_CriteriaScoreFilter>;
  criteriaScores_single?: Maybe<_CriteriaScoreFilter>;
  criteriaScores_every?: Maybe<_CriteriaScoreFilter>;
  homeDashboard?: Maybe<_DashboardViewFilter>;
  homeDashboard_not?: Maybe<_DashboardViewFilter>;
  homeDashboard_in?: Maybe<Array<_DashboardViewFilter>>;
  homeDashboard_not_in?: Maybe<Array<_DashboardViewFilter>>;
  usesDashboard?: Maybe<_DashboardViewFilter>;
  usesDashboard_not?: Maybe<_DashboardViewFilter>;
  usesDashboard_in?: Maybe<Array<_DashboardViewFilter>>;
  usesDashboard_not_in?: Maybe<Array<_DashboardViewFilter>>;
  usesDashboard_some?: Maybe<_DashboardViewFilter>;
  usesDashboard_none?: Maybe<_DashboardViewFilter>;
  usesDashboard_single?: Maybe<_DashboardViewFilter>;
  usesDashboard_every?: Maybe<_DashboardViewFilter>;
  comments?: Maybe<_CommentFilter>;
  comments_not?: Maybe<_CommentFilter>;
  comments_in?: Maybe<Array<_CommentFilter>>;
  comments_not_in?: Maybe<Array<_CommentFilter>>;
  comments_some?: Maybe<_CommentFilter>;
  comments_none?: Maybe<_CommentFilter>;
  comments_single?: Maybe<_CommentFilter>;
  comments_every?: Maybe<_CommentFilter>;
  watchedBy?: Maybe<_UserFilter>;
  watchedBy_not?: Maybe<_UserFilter>;
  watchedBy_in?: Maybe<Array<_UserFilter>>;
  watchedBy_not_in?: Maybe<Array<_UserFilter>>;
  watchedBy_some?: Maybe<_UserFilter>;
  watchedBy_none?: Maybe<_UserFilter>;
  watchedBy_single?: Maybe<_UserFilter>;
  watchedBy_every?: Maybe<_UserFilter>;
  notifications?: Maybe<_NotificationFilter>;
  notifications_not?: Maybe<_NotificationFilter>;
  notifications_in?: Maybe<Array<_NotificationFilter>>;
  notifications_not_in?: Maybe<Array<_NotificationFilter>>;
  notifications_some?: Maybe<_NotificationFilter>;
  notifications_none?: Maybe<_NotificationFilter>;
  notifications_single?: Maybe<_NotificationFilter>;
  notifications_every?: Maybe<_NotificationFilter>;
  tags?: Maybe<_TagFilter>;
  tags_not?: Maybe<_TagFilter>;
  tags_in?: Maybe<Array<_TagFilter>>;
  tags_not_in?: Maybe<Array<_TagFilter>>;
  tags_some?: Maybe<_TagFilter>;
  tags_none?: Maybe<_TagFilter>;
  tags_single?: Maybe<_TagFilter>;
  tags_every?: Maybe<_TagFilter>;
  connections?: Maybe<_ConceptFilter>;
  connections_not?: Maybe<_ConceptFilter>;
  connections_in?: Maybe<Array<_ConceptFilter>>;
  connections_not_in?: Maybe<Array<_ConceptFilter>>;
  connections_some?: Maybe<_ConceptFilter>;
  connections_none?: Maybe<_ConceptFilter>;
  connections_single?: Maybe<_ConceptFilter>;
  connections_every?: Maybe<_ConceptFilter>;
  detailedConnections?: Maybe<_ConceptConnectionDirectionsFilter>;
  detailedConnections_not?: Maybe<_ConceptConnectionDirectionsFilter>;
  detailedConnections_in?: Maybe<Array<_ConceptConnectionDirectionsFilter>>;
  detailedConnections_not_in?: Maybe<Array<_ConceptConnectionDirectionsFilter>>;
  detailedConnections_some?: Maybe<_ConceptConnectionDirectionsFilter>;
  detailedConnections_none?: Maybe<_ConceptConnectionDirectionsFilter>;
  detailedConnections_single?: Maybe<_ConceptConnectionDirectionsFilter>;
  detailedConnections_every?: Maybe<_ConceptConnectionDirectionsFilter>;
  teams?: Maybe<_TeamFilter>;
  teams_not?: Maybe<_TeamFilter>;
  teams_in?: Maybe<Array<_TeamFilter>>;
  teams_not_in?: Maybe<Array<_TeamFilter>>;
  teams_some?: Maybe<_TeamFilter>;
  teams_none?: Maybe<_TeamFilter>;
  teams_single?: Maybe<_TeamFilter>;
  teams_every?: Maybe<_TeamFilter>;
  inLists?: Maybe<_ConceptListFilter>;
  inLists_not?: Maybe<_ConceptListFilter>;
  inLists_in?: Maybe<Array<_ConceptListFilter>>;
  inLists_not_in?: Maybe<Array<_ConceptListFilter>>;
  inLists_some?: Maybe<_ConceptListFilter>;
  inLists_none?: Maybe<_ConceptListFilter>;
  inLists_single?: Maybe<_ConceptListFilter>;
  inLists_every?: Maybe<_ConceptListFilter>;
  workspace?: Maybe<_ConceptListFilter>;
  workspace_not?: Maybe<_ConceptListFilter>;
  workspace_in?: Maybe<Array<_ConceptListFilter>>;
  workspace_not_in?: Maybe<Array<_ConceptListFilter>>;
  childConceptLists?: Maybe<_ConceptListFilter>;
  childConceptLists_not?: Maybe<_ConceptListFilter>;
  childConceptLists_in?: Maybe<Array<_ConceptListFilter>>;
  childConceptLists_not_in?: Maybe<Array<_ConceptListFilter>>;
  childConceptLists_some?: Maybe<_ConceptListFilter>;
  childConceptLists_none?: Maybe<_ConceptListFilter>;
  childConceptLists_single?: Maybe<_ConceptListFilter>;
  childConceptLists_every?: Maybe<_ConceptListFilter>;
  checklists?: Maybe<_ChecklistFilter>;
  checklists_not?: Maybe<_ChecklistFilter>;
  checklists_in?: Maybe<Array<_ChecklistFilter>>;
  checklists_not_in?: Maybe<Array<_ChecklistFilter>>;
  checklists_some?: Maybe<_ChecklistFilter>;
  checklists_none?: Maybe<_ChecklistFilter>;
  checklists_single?: Maybe<_ChecklistFilter>;
  checklists_every?: Maybe<_ChecklistFilter>;
  checklistItemLink?: Maybe<_ChecklistItemFilter>;
  checklistItemLink_not?: Maybe<_ChecklistItemFilter>;
  checklistItemLink_in?: Maybe<Array<_ChecklistItemFilter>>;
  checklistItemLink_not_in?: Maybe<Array<_ChecklistItemFilter>>;
  checklistItemLink_some?: Maybe<_ChecklistItemFilter>;
  checklistItemLink_none?: Maybe<_ChecklistItemFilter>;
  checklistItemLink_single?: Maybe<_ChecklistItemFilter>;
  checklistItemLink_every?: Maybe<_ChecklistItemFilter>;
  exercisesCreated?: Maybe<_ExerciseFilter>;
  exercisesCreated_not?: Maybe<_ExerciseFilter>;
  exercisesCreated_in?: Maybe<Array<_ExerciseFilter>>;
  exercisesCreated_not_in?: Maybe<Array<_ExerciseFilter>>;
  exercisesCreated_some?: Maybe<_ExerciseFilter>;
  exercisesCreated_none?: Maybe<_ExerciseFilter>;
  exercisesCreated_single?: Maybe<_ExerciseFilter>;
  exercisesCreated_every?: Maybe<_ExerciseFilter>;
  exercisesIncluding?: Maybe<_ExerciseFilter>;
  exercisesIncluding_not?: Maybe<_ExerciseFilter>;
  exercisesIncluding_in?: Maybe<Array<_ExerciseFilter>>;
  exercisesIncluding_not_in?: Maybe<Array<_ExerciseFilter>>;
  exercisesIncluding_some?: Maybe<_ExerciseFilter>;
  exercisesIncluding_none?: Maybe<_ExerciseFilter>;
  exercisesIncluding_single?: Maybe<_ExerciseFilter>;
  exercisesIncluding_every?: Maybe<_ExerciseFilter>;
  createdForExerciseSubmission?: Maybe<_ExerciseSubmissionFilter>;
  createdForExerciseSubmission_not?: Maybe<_ExerciseSubmissionFilter>;
  createdForExerciseSubmission_in?: Maybe<Array<_ExerciseSubmissionFilter>>;
  createdForExerciseSubmission_not_in?: Maybe<Array<_ExerciseSubmissionFilter>>;
  inputs?: Maybe<_InputFilter>;
  inputs_not?: Maybe<_InputFilter>;
  inputs_in?: Maybe<Array<_InputFilter>>;
  inputs_not_in?: Maybe<Array<_InputFilter>>;
  inputs_some?: Maybe<_InputFilter>;
  inputs_none?: Maybe<_InputFilter>;
  inputs_single?: Maybe<_InputFilter>;
  inputs_every?: Maybe<_InputFilter>;
  includedForInputs?: Maybe<_InputFilter>;
  includedForInputs_not?: Maybe<_InputFilter>;
  includedForInputs_in?: Maybe<Array<_InputFilter>>;
  includedForInputs_not_in?: Maybe<Array<_InputFilter>>;
  includedForInputs_some?: Maybe<_InputFilter>;
  includedForInputs_none?: Maybe<_InputFilter>;
  includedForInputs_single?: Maybe<_InputFilter>;
  includedForInputs_every?: Maybe<_InputFilter>;
  createdViaInput?: Maybe<_InputFilter>;
  createdViaInput_not?: Maybe<_InputFilter>;
  createdViaInput_in?: Maybe<Array<_InputFilter>>;
  createdViaInput_not_in?: Maybe<Array<_InputFilter>>;
  createdViaInput_some?: Maybe<_InputFilter>;
  createdViaInput_none?: Maybe<_InputFilter>;
  createdViaInput_single?: Maybe<_InputFilter>;
  createdViaInput_every?: Maybe<_InputFilter>;
  usedByScore?: Maybe<_CriteriaScoreFilter>;
  usedByScore_not?: Maybe<_CriteriaScoreFilter>;
  usedByScore_in?: Maybe<Array<_CriteriaScoreFilter>>;
  usedByScore_not_in?: Maybe<Array<_CriteriaScoreFilter>>;
  usedByScore_some?: Maybe<_CriteriaScoreFilter>;
  usedByScore_none?: Maybe<_CriteriaScoreFilter>;
  usedByScore_single?: Maybe<_CriteriaScoreFilter>;
  usedByScore_every?: Maybe<_CriteriaScoreFilter>;
};

export type _ConceptInput = {
  id: Scalars['ID'];
};

export type _ConceptListFilter = {
  AND?: Maybe<Array<_ConceptListFilter>>;
  OR?: Maybe<Array<_ConceptListFilter>>;
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Scalars['ID']>>;
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  id_regexp?: Maybe<Scalars['ID']>;
  id_contains?: Maybe<Scalars['ID']>;
  id_not_contains?: Maybe<Scalars['ID']>;
  id_starts_with?: Maybe<Scalars['ID']>;
  id_not_starts_with?: Maybe<Scalars['ID']>;
  id_ends_with?: Maybe<Scalars['ID']>;
  id_not_ends_with?: Maybe<Scalars['ID']>;
  title?: Maybe<Scalars['String']>;
  title_not?: Maybe<Scalars['String']>;
  title_in?: Maybe<Array<Scalars['String']>>;
  title_not_in?: Maybe<Array<Scalars['String']>>;
  title_regexp?: Maybe<Scalars['String']>;
  title_contains?: Maybe<Scalars['String']>;
  title_not_contains?: Maybe<Scalars['String']>;
  title_starts_with?: Maybe<Scalars['String']>;
  title_not_starts_with?: Maybe<Scalars['String']>;
  title_ends_with?: Maybe<Scalars['String']>;
  title_not_ends_with?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  description_not?: Maybe<Scalars['String']>;
  description_in?: Maybe<Array<Scalars['String']>>;
  description_not_in?: Maybe<Array<Scalars['String']>>;
  description_regexp?: Maybe<Scalars['String']>;
  description_contains?: Maybe<Scalars['String']>;
  description_not_contains?: Maybe<Scalars['String']>;
  description_starts_with?: Maybe<Scalars['String']>;
  description_not_starts_with?: Maybe<Scalars['String']>;
  description_ends_with?: Maybe<Scalars['String']>;
  description_not_ends_with?: Maybe<Scalars['String']>;
  type?: Maybe<ConceptListType>;
  type_not?: Maybe<ConceptListType>;
  type_in?: Maybe<Array<ConceptListType>>;
  type_not_in?: Maybe<Array<ConceptListType>>;
  isOrdered?: Maybe<Scalars['Boolean']>;
  isOrdered_not?: Maybe<Scalars['Boolean']>;
  user?: Maybe<_UserFilter>;
  user_not?: Maybe<_UserFilter>;
  user_in?: Maybe<Array<_UserFilter>>;
  user_not_in?: Maybe<Array<_UserFilter>>;
  inboxUser?: Maybe<_UserFilter>;
  inboxUser_not?: Maybe<_UserFilter>;
  inboxUser_in?: Maybe<Array<_UserFilter>>;
  inboxUser_not_in?: Maybe<Array<_UserFilter>>;
  team?: Maybe<_TeamFilter>;
  team_not?: Maybe<_TeamFilter>;
  team_in?: Maybe<Array<_TeamFilter>>;
  team_not_in?: Maybe<Array<_TeamFilter>>;
  concepts?: Maybe<_ConceptFilter>;
  concepts_not?: Maybe<_ConceptFilter>;
  concepts_in?: Maybe<Array<_ConceptFilter>>;
  concepts_not_in?: Maybe<Array<_ConceptFilter>>;
  concepts_some?: Maybe<_ConceptFilter>;
  concepts_none?: Maybe<_ConceptFilter>;
  concepts_single?: Maybe<_ConceptFilter>;
  concepts_every?: Maybe<_ConceptFilter>;
  workspaceConcept?: Maybe<_ConceptFilter>;
  workspaceConcept_not?: Maybe<_ConceptFilter>;
  workspaceConcept_in?: Maybe<Array<_ConceptFilter>>;
  workspaceConcept_not_in?: Maybe<Array<_ConceptFilter>>;
  parentConcept?: Maybe<_ConceptFilter>;
  parentConcept_not?: Maybe<_ConceptFilter>;
  parentConcept_in?: Maybe<Array<_ConceptFilter>>;
  parentConcept_not_in?: Maybe<Array<_ConceptFilter>>;
  conceptIds?: Maybe<Array<Scalars['ID']>>;
  conceptIds_not?: Maybe<Array<Scalars['ID']>>;
  conceptIds_regexp?: Maybe<Scalars['ID']>;
  conceptIds_contains?: Maybe<Array<Scalars['ID']>>;
  conceptIds_not_contains?: Maybe<Array<Scalars['ID']>>;
  conceptIds_starts_with?: Maybe<Array<Scalars['ID']>>;
  conceptIds_not_starts_with?: Maybe<Array<Scalars['ID']>>;
  conceptIds_ends_with?: Maybe<Array<Scalars['ID']>>;
  conceptIds_not_ends_with?: Maybe<Array<Scalars['ID']>>;
  conceptBoard?: Maybe<_ConceptBoardFilter>;
  conceptBoard_not?: Maybe<_ConceptBoardFilter>;
  conceptBoard_in?: Maybe<Array<_ConceptBoardFilter>>;
  conceptBoard_not_in?: Maybe<Array<_ConceptBoardFilter>>;
  persona?: Maybe<_ConceptFilter>;
  persona_not?: Maybe<_ConceptFilter>;
  persona_in?: Maybe<Array<_ConceptFilter>>;
  persona_not_in?: Maybe<Array<_ConceptFilter>>;
};

export type _ConceptListInput = {
  id: Scalars['ID'];
};

export enum _ConceptListOrdering {
  ID_ASC = 'id_asc',
  ID_DESC = 'id_desc',
  TITLE_ASC = 'title_asc',
  TITLE_DESC = 'title_desc',
  DESCRIPTION_ASC = 'description_asc',
  DESCRIPTION_DESC = 'description_desc',
  TYPE_ASC = 'type_asc',
  TYPE_DESC = 'type_desc',
  ISORDERED_ASC = 'isOrdered_asc',
  ISORDERED_DESC = 'isOrdered_desc',
  _ID_ASC = '_id_asc',
  _ID_DESC = '_id_desc'
}

export enum _ConceptOrdering {
  ID_ASC = 'id_asc',
  ID_DESC = 'id_desc',
  SLUG_ASC = 'slug_asc',
  SLUG_DESC = 'slug_desc',
  TITLE_ASC = 'title_asc',
  TITLE_DESC = 'title_desc',
  TYPE_ASC = 'type_asc',
  TYPE_DESC = 'type_desc',
  SUMMARY_ASC = 'summary_asc',
  SUMMARY_DESC = 'summary_desc',
  DESCRIPTION_ASC = 'description_asc',
  DESCRIPTION_DESC = 'description_desc',
  USERNAME_ASC = 'username_asc',
  USERNAME_DESC = 'username_desc',
  CREATEDAT_ASC = 'createdAt_asc',
  CREATEDAT_DESC = 'createdAt_desc',
  STARTDATE_ASC = 'startDate_asc',
  STARTDATE_DESC = 'startDate_desc',
  ENDDATE_ASC = 'endDate_asc',
  ENDDATE_DESC = 'endDate_desc',
  ISARCHIVED_ASC = 'isArchived_asc',
  ISARCHIVED_DESC = 'isArchived_desc',
  ISPUBLIC_ASC = 'isPublic_asc',
  ISPUBLIC_DESC = 'isPublic_desc',
  ISPUBLICACCESSTYPE_ASC = 'isPublicAccessType_asc',
  ISPUBLICACCESSTYPE_DESC = 'isPublicAccessType_desc',
  SOURCE_ASC = 'source_asc',
  SOURCE_DESC = 'source_desc',
  SOURCEID_ASC = 'sourceId_asc',
  SOURCEID_DESC = 'sourceId_desc',
  VIS_ASC = 'vis_asc',
  VIS_DESC = 'vis_desc',
  IMAGEURL_ASC = 'imageUrl_asc',
  IMAGEURL_DESC = 'imageUrl_desc',
  LIKECOUNT_ASC = 'likeCount_asc',
  LIKECOUNT_DESC = 'likeCount_desc',
  COMMENTCOUNT_ASC = 'commentCount_asc',
  COMMENTCOUNT_DESC = 'commentCount_desc',
  _ID_ASC = '_id_asc',
  _ID_DESC = '_id_desc'
}

export type _ConceptRecentUpdates = {
  __typename?: '_ConceptRecentUpdates';
  updatedAt?: Maybe<_Neo4jDateTime>;
  updateValue?: Maybe<Scalars['String']>;
  /** Generated field for querying the Neo4j [system id](https://neo4j.com/docs/cypher-manual/current/functions/scalar/#functions-id) of this relationship. */
  _id?: Maybe<Scalars['String']>;
  /** Field for the User node this UPDATED [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  User?: Maybe<User>;
};

export type _ConceptStatusFilter = {
  AND?: Maybe<Array<_ConceptStatusFilter>>;
  OR?: Maybe<Array<_ConceptStatusFilter>>;
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Scalars['ID']>>;
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  id_regexp?: Maybe<Scalars['ID']>;
  id_contains?: Maybe<Scalars['ID']>;
  id_not_contains?: Maybe<Scalars['ID']>;
  id_starts_with?: Maybe<Scalars['ID']>;
  id_not_starts_with?: Maybe<Scalars['ID']>;
  id_ends_with?: Maybe<Scalars['ID']>;
  id_not_ends_with?: Maybe<Scalars['ID']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isActive_not?: Maybe<Scalars['Boolean']>;
  stage?: Maybe<_WorkflowStageFilter>;
  stage_not?: Maybe<_WorkflowStageFilter>;
  stage_in?: Maybe<Array<_WorkflowStageFilter>>;
  stage_not_in?: Maybe<Array<_WorkflowStageFilter>>;
  justification?: Maybe<Scalars['String']>;
  justification_not?: Maybe<Scalars['String']>;
  justification_in?: Maybe<Array<Scalars['String']>>;
  justification_not_in?: Maybe<Array<Scalars['String']>>;
  justification_regexp?: Maybe<Scalars['String']>;
  justification_contains?: Maybe<Scalars['String']>;
  justification_not_contains?: Maybe<Scalars['String']>;
  justification_starts_with?: Maybe<Scalars['String']>;
  justification_not_starts_with?: Maybe<Scalars['String']>;
  justification_ends_with?: Maybe<Scalars['String']>;
  justification_not_ends_with?: Maybe<Scalars['String']>;
  justificationNotes?: Maybe<Scalars['String']>;
  justificationNotes_not?: Maybe<Scalars['String']>;
  justificationNotes_in?: Maybe<Array<Scalars['String']>>;
  justificationNotes_not_in?: Maybe<Array<Scalars['String']>>;
  justificationNotes_regexp?: Maybe<Scalars['String']>;
  justificationNotes_contains?: Maybe<Scalars['String']>;
  justificationNotes_not_contains?: Maybe<Scalars['String']>;
  justificationNotes_starts_with?: Maybe<Scalars['String']>;
  justificationNotes_not_starts_with?: Maybe<Scalars['String']>;
  justificationNotes_ends_with?: Maybe<Scalars['String']>;
  justificationNotes_not_ends_with?: Maybe<Scalars['String']>;
  createdAt?: Maybe<_Neo4jDateTimeInput>;
  createdAt_not?: Maybe<_Neo4jDateTimeInput>;
  createdAt_in?: Maybe<Array<_Neo4jDateTimeInput>>;
  createdAt_not_in?: Maybe<Array<_Neo4jDateTimeInput>>;
  createdAt_lt?: Maybe<_Neo4jDateTimeInput>;
  createdAt_lte?: Maybe<_Neo4jDateTimeInput>;
  createdAt_gt?: Maybe<_Neo4jDateTimeInput>;
  createdAt_gte?: Maybe<_Neo4jDateTimeInput>;
  concept?: Maybe<_ConceptFilter>;
  concept_not?: Maybe<_ConceptFilter>;
  concept_in?: Maybe<Array<_ConceptFilter>>;
  concept_not_in?: Maybe<Array<_ConceptFilter>>;
};

export type _ConceptStatusInput = {
  id: Scalars['ID'];
};

export enum _ConceptStatusOrdering {
  ID_ASC = 'id_asc',
  ID_DESC = 'id_desc',
  ISACTIVE_ASC = 'isActive_asc',
  ISACTIVE_DESC = 'isActive_desc',
  JUSTIFICATION_ASC = 'justification_asc',
  JUSTIFICATION_DESC = 'justification_desc',
  JUSTIFICATIONNOTES_ASC = 'justificationNotes_asc',
  JUSTIFICATIONNOTES_DESC = 'justificationNotes_desc',
  CREATEDAT_ASC = 'createdAt_asc',
  CREATEDAT_DESC = 'createdAt_desc',
  _ID_ASC = '_id_asc',
  _ID_DESC = '_id_desc'
}

export type _ConceptTeamAccessFilter = {
  AND?: Maybe<Array<_ConceptTeamAccessFilter>>;
  OR?: Maybe<Array<_ConceptTeamAccessFilter>>;
  type?: Maybe<AccessType>;
  type_not?: Maybe<AccessType>;
  type_in?: Maybe<Array<AccessType>>;
  type_not_in?: Maybe<Array<AccessType>>;
  updatedAt?: Maybe<_Neo4jDateTimeInput>;
  updatedAt_not?: Maybe<_Neo4jDateTimeInput>;
  updatedAt_in?: Maybe<Array<_Neo4jDateTimeInput>>;
  updatedAt_not_in?: Maybe<Array<_Neo4jDateTimeInput>>;
  updatedAt_lt?: Maybe<_Neo4jDateTimeInput>;
  updatedAt_lte?: Maybe<_Neo4jDateTimeInput>;
  updatedAt_gt?: Maybe<_Neo4jDateTimeInput>;
  updatedAt_gte?: Maybe<_Neo4jDateTimeInput>;
  Team?: Maybe<_TeamFilter>;
};

export type _ConceptUpdateInput = {
  updatedAt?: Maybe<_Neo4jDateTimeInput>;
  updateValue?: Maybe<Scalars['String']>;
};

export enum _ConceptUpdateOrdering {
  UPDATEDAT_ASC = 'updatedAt_asc',
  UPDATEDAT_DESC = 'updatedAt_desc',
  UPDATEVALUE_ASC = 'updateValue_asc',
  UPDATEVALUE_DESC = 'updateValue_desc',
  _ID_ASC = '_id_asc',
  _ID_DESC = '_id_desc'
}

export type _ConceptUserAccessFilter = {
  AND?: Maybe<Array<_ConceptUserAccessFilter>>;
  OR?: Maybe<Array<_ConceptUserAccessFilter>>;
  type?: Maybe<AccessType>;
  type_not?: Maybe<AccessType>;
  type_in?: Maybe<Array<AccessType>>;
  type_not_in?: Maybe<Array<AccessType>>;
  updatedAt?: Maybe<_Neo4jDateTimeInput>;
  updatedAt_not?: Maybe<_Neo4jDateTimeInput>;
  updatedAt_in?: Maybe<Array<_Neo4jDateTimeInput>>;
  updatedAt_not_in?: Maybe<Array<_Neo4jDateTimeInput>>;
  updatedAt_lt?: Maybe<_Neo4jDateTimeInput>;
  updatedAt_lte?: Maybe<_Neo4jDateTimeInput>;
  updatedAt_gt?: Maybe<_Neo4jDateTimeInput>;
  updatedAt_gte?: Maybe<_Neo4jDateTimeInput>;
  User?: Maybe<_UserFilter>;
};

export type _CriteriaFilter = {
  AND?: Maybe<Array<_CriteriaFilter>>;
  OR?: Maybe<Array<_CriteriaFilter>>;
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Scalars['ID']>>;
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  id_regexp?: Maybe<Scalars['ID']>;
  id_contains?: Maybe<Scalars['ID']>;
  id_not_contains?: Maybe<Scalars['ID']>;
  id_starts_with?: Maybe<Scalars['ID']>;
  id_not_starts_with?: Maybe<Scalars['ID']>;
  id_ends_with?: Maybe<Scalars['ID']>;
  id_not_ends_with?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  name_not?: Maybe<Scalars['String']>;
  name_in?: Maybe<Array<Scalars['String']>>;
  name_not_in?: Maybe<Array<Scalars['String']>>;
  name_regexp?: Maybe<Scalars['String']>;
  name_contains?: Maybe<Scalars['String']>;
  name_not_contains?: Maybe<Scalars['String']>;
  name_starts_with?: Maybe<Scalars['String']>;
  name_not_starts_with?: Maybe<Scalars['String']>;
  name_ends_with?: Maybe<Scalars['String']>;
  name_not_ends_with?: Maybe<Scalars['String']>;
  isDefault?: Maybe<Scalars['Boolean']>;
  isDefault_not?: Maybe<Scalars['Boolean']>;
  criteriaType?: Maybe<Scalars['String']>;
  criteriaType_not?: Maybe<Scalars['String']>;
  criteriaType_in?: Maybe<Array<Scalars['String']>>;
  criteriaType_not_in?: Maybe<Array<Scalars['String']>>;
  criteriaType_regexp?: Maybe<Scalars['String']>;
  criteriaType_contains?: Maybe<Scalars['String']>;
  criteriaType_not_contains?: Maybe<Scalars['String']>;
  criteriaType_starts_with?: Maybe<Scalars['String']>;
  criteriaType_not_starts_with?: Maybe<Scalars['String']>;
  criteriaType_ends_with?: Maybe<Scalars['String']>;
  criteriaType_not_ends_with?: Maybe<Scalars['String']>;
  criteriaOptions?: Maybe<Scalars['String']>;
  criteriaOptions_not?: Maybe<Scalars['String']>;
  criteriaOptions_in?: Maybe<Array<Scalars['String']>>;
  criteriaOptions_not_in?: Maybe<Array<Scalars['String']>>;
  criteriaOptions_regexp?: Maybe<Scalars['String']>;
  criteriaOptions_contains?: Maybe<Scalars['String']>;
  criteriaOptions_not_contains?: Maybe<Scalars['String']>;
  criteriaOptions_starts_with?: Maybe<Scalars['String']>;
  criteriaOptions_not_starts_with?: Maybe<Scalars['String']>;
  criteriaOptions_ends_with?: Maybe<Scalars['String']>;
  criteriaOptions_not_ends_with?: Maybe<Scalars['String']>;
  inputConfig?: Maybe<Scalars['String']>;
  inputConfig_not?: Maybe<Scalars['String']>;
  inputConfig_in?: Maybe<Array<Scalars['String']>>;
  inputConfig_not_in?: Maybe<Array<Scalars['String']>>;
  inputConfig_regexp?: Maybe<Scalars['String']>;
  inputConfig_contains?: Maybe<Scalars['String']>;
  inputConfig_not_contains?: Maybe<Scalars['String']>;
  inputConfig_starts_with?: Maybe<Scalars['String']>;
  inputConfig_not_starts_with?: Maybe<Scalars['String']>;
  inputConfig_ends_with?: Maybe<Scalars['String']>;
  inputConfig_not_ends_with?: Maybe<Scalars['String']>;
  isArchived?: Maybe<Scalars['Boolean']>;
  isArchived_not?: Maybe<Scalars['Boolean']>;
  scores?: Maybe<_CriteriaScoreFilter>;
  scores_not?: Maybe<_CriteriaScoreFilter>;
  scores_in?: Maybe<Array<_CriteriaScoreFilter>>;
  scores_not_in?: Maybe<Array<_CriteriaScoreFilter>>;
  scores_some?: Maybe<_CriteriaScoreFilter>;
  scores_none?: Maybe<_CriteriaScoreFilter>;
  scores_single?: Maybe<_CriteriaScoreFilter>;
  scores_every?: Maybe<_CriteriaScoreFilter>;
  childIds?: Maybe<Array<Scalars['ID']>>;
  childIds_not?: Maybe<Array<Scalars['ID']>>;
  childIds_regexp?: Maybe<Scalars['ID']>;
  childIds_contains?: Maybe<Array<Scalars['ID']>>;
  childIds_not_contains?: Maybe<Array<Scalars['ID']>>;
  childIds_starts_with?: Maybe<Array<Scalars['ID']>>;
  childIds_not_starts_with?: Maybe<Array<Scalars['ID']>>;
  childIds_ends_with?: Maybe<Array<Scalars['ID']>>;
  childIds_not_ends_with?: Maybe<Array<Scalars['ID']>>;
  parent?: Maybe<_CriteriaFilter>;
  parent_not?: Maybe<_CriteriaFilter>;
  parent_in?: Maybe<Array<_CriteriaFilter>>;
  parent_not_in?: Maybe<Array<_CriteriaFilter>>;
  children?: Maybe<_CriteriaFilter>;
  children_not?: Maybe<_CriteriaFilter>;
  children_in?: Maybe<Array<_CriteriaFilter>>;
  children_not_in?: Maybe<Array<_CriteriaFilter>>;
  children_some?: Maybe<_CriteriaFilter>;
  children_none?: Maybe<_CriteriaFilter>;
  children_single?: Maybe<_CriteriaFilter>;
  children_every?: Maybe<_CriteriaFilter>;
  parentFields?: Maybe<_CriteriaFilter>;
  parentFields_not?: Maybe<_CriteriaFilter>;
  parentFields_in?: Maybe<Array<_CriteriaFilter>>;
  parentFields_not_in?: Maybe<Array<_CriteriaFilter>>;
  parentFields_some?: Maybe<_CriteriaFilter>;
  parentFields_none?: Maybe<_CriteriaFilter>;
  parentFields_single?: Maybe<_CriteriaFilter>;
  parentFields_every?: Maybe<_CriteriaFilter>;
  subfields?: Maybe<_CriteriaFilter>;
  subfields_not?: Maybe<_CriteriaFilter>;
  subfields_in?: Maybe<Array<_CriteriaFilter>>;
  subfields_not_in?: Maybe<Array<_CriteriaFilter>>;
  subfields_some?: Maybe<_CriteriaFilter>;
  subfields_none?: Maybe<_CriteriaFilter>;
  subfields_single?: Maybe<_CriteriaFilter>;
  subfields_every?: Maybe<_CriteriaFilter>;
  concept?: Maybe<_ConceptFilter>;
  concept_not?: Maybe<_ConceptFilter>;
  concept_in?: Maybe<Array<_ConceptFilter>>;
  concept_not_in?: Maybe<Array<_ConceptFilter>>;
  category?: Maybe<_CategoryFilter>;
  category_not?: Maybe<_CategoryFilter>;
  category_in?: Maybe<Array<_CategoryFilter>>;
  category_not_in?: Maybe<Array<_CategoryFilter>>;
  categories?: Maybe<_CategoryFilter>;
  categories_not?: Maybe<_CategoryFilter>;
  categories_in?: Maybe<Array<_CategoryFilter>>;
  categories_not_in?: Maybe<Array<_CategoryFilter>>;
  categories_some?: Maybe<_CategoryFilter>;
  categories_none?: Maybe<_CategoryFilter>;
  categories_single?: Maybe<_CategoryFilter>;
  categories_every?: Maybe<_CategoryFilter>;
  exercise?: Maybe<_ExerciseFilter>;
  exercise_not?: Maybe<_ExerciseFilter>;
  exercise_in?: Maybe<Array<_ExerciseFilter>>;
  exercise_not_in?: Maybe<Array<_ExerciseFilter>>;
  exerciseTypes?: Maybe<_ExerciseTypeFilter>;
  exerciseTypes_not?: Maybe<_ExerciseTypeFilter>;
  exerciseTypes_in?: Maybe<Array<_ExerciseTypeFilter>>;
  exerciseTypes_not_in?: Maybe<Array<_ExerciseTypeFilter>>;
  exerciseTypes_some?: Maybe<_ExerciseTypeFilter>;
  exerciseTypes_none?: Maybe<_ExerciseTypeFilter>;
  exerciseTypes_single?: Maybe<_ExerciseTypeFilter>;
  exerciseTypes_every?: Maybe<_ExerciseTypeFilter>;
  inputs?: Maybe<_InputFilter>;
  inputs_not?: Maybe<_InputFilter>;
  inputs_in?: Maybe<Array<_InputFilter>>;
  inputs_not_in?: Maybe<Array<_InputFilter>>;
  inputs_some?: Maybe<_InputFilter>;
  inputs_none?: Maybe<_InputFilter>;
  inputs_single?: Maybe<_InputFilter>;
  inputs_every?: Maybe<_InputFilter>;
};

export type _CriteriaInput = {
  id: Scalars['ID'];
};

export enum _CriteriaOrdering {
  ID_ASC = 'id_asc',
  ID_DESC = 'id_desc',
  NAME_ASC = 'name_asc',
  NAME_DESC = 'name_desc',
  ISDEFAULT_ASC = 'isDefault_asc',
  ISDEFAULT_DESC = 'isDefault_desc',
  CRITERIATYPE_ASC = 'criteriaType_asc',
  CRITERIATYPE_DESC = 'criteriaType_desc',
  CRITERIAOPTIONS_ASC = 'criteriaOptions_asc',
  CRITERIAOPTIONS_DESC = 'criteriaOptions_desc',
  INPUTCONFIG_ASC = 'inputConfig_asc',
  INPUTCONFIG_DESC = 'inputConfig_desc',
  ISARCHIVED_ASC = 'isArchived_asc',
  ISARCHIVED_DESC = 'isArchived_desc',
  _ID_ASC = '_id_asc',
  _ID_DESC = '_id_desc'
}

export type _CriteriaScoreFilter = {
  AND?: Maybe<Array<_CriteriaScoreFilter>>;
  OR?: Maybe<Array<_CriteriaScoreFilter>>;
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Scalars['ID']>>;
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  id_regexp?: Maybe<Scalars['ID']>;
  id_contains?: Maybe<Scalars['ID']>;
  id_not_contains?: Maybe<Scalars['ID']>;
  id_starts_with?: Maybe<Scalars['ID']>;
  id_not_starts_with?: Maybe<Scalars['ID']>;
  id_ends_with?: Maybe<Scalars['ID']>;
  id_not_ends_with?: Maybe<Scalars['ID']>;
  label?: Maybe<Scalars['String']>;
  label_not?: Maybe<Scalars['String']>;
  label_in?: Maybe<Array<Scalars['String']>>;
  label_not_in?: Maybe<Array<Scalars['String']>>;
  label_regexp?: Maybe<Scalars['String']>;
  label_contains?: Maybe<Scalars['String']>;
  label_not_contains?: Maybe<Scalars['String']>;
  label_starts_with?: Maybe<Scalars['String']>;
  label_not_starts_with?: Maybe<Scalars['String']>;
  label_ends_with?: Maybe<Scalars['String']>;
  label_not_ends_with?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['Float']>;
  value_not?: Maybe<Scalars['Float']>;
  value_in?: Maybe<Array<Scalars['Float']>>;
  value_not_in?: Maybe<Array<Scalars['Float']>>;
  value_lt?: Maybe<Scalars['Float']>;
  value_lte?: Maybe<Scalars['Float']>;
  value_gt?: Maybe<Scalars['Float']>;
  value_gte?: Maybe<Scalars['Float']>;
  response?: Maybe<Scalars['String']>;
  response_not?: Maybe<Scalars['String']>;
  response_in?: Maybe<Array<Scalars['String']>>;
  response_not_in?: Maybe<Array<Scalars['String']>>;
  response_regexp?: Maybe<Scalars['String']>;
  response_contains?: Maybe<Scalars['String']>;
  response_not_contains?: Maybe<Scalars['String']>;
  response_starts_with?: Maybe<Scalars['String']>;
  response_not_starts_with?: Maybe<Scalars['String']>;
  response_ends_with?: Maybe<Scalars['String']>;
  response_not_ends_with?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  notes_not?: Maybe<Scalars['String']>;
  notes_in?: Maybe<Array<Scalars['String']>>;
  notes_not_in?: Maybe<Array<Scalars['String']>>;
  notes_regexp?: Maybe<Scalars['String']>;
  notes_contains?: Maybe<Scalars['String']>;
  notes_not_contains?: Maybe<Scalars['String']>;
  notes_starts_with?: Maybe<Scalars['String']>;
  notes_not_starts_with?: Maybe<Scalars['String']>;
  notes_ends_with?: Maybe<Scalars['String']>;
  notes_not_ends_with?: Maybe<Scalars['String']>;
  chatHistory?: Maybe<Scalars['String']>;
  chatHistory_not?: Maybe<Scalars['String']>;
  chatHistory_in?: Maybe<Array<Scalars['String']>>;
  chatHistory_not_in?: Maybe<Array<Scalars['String']>>;
  chatHistory_regexp?: Maybe<Scalars['String']>;
  chatHistory_contains?: Maybe<Scalars['String']>;
  chatHistory_not_contains?: Maybe<Scalars['String']>;
  chatHistory_starts_with?: Maybe<Scalars['String']>;
  chatHistory_not_starts_with?: Maybe<Scalars['String']>;
  chatHistory_ends_with?: Maybe<Scalars['String']>;
  chatHistory_not_ends_with?: Maybe<Scalars['String']>;
  status?: Maybe<ScoreStatus>;
  status_not?: Maybe<ScoreStatus>;
  status_in?: Maybe<Array<ScoreStatus>>;
  status_not_in?: Maybe<Array<ScoreStatus>>;
  conceptDefault?: Maybe<Scalars['Boolean']>;
  conceptDefault_not?: Maybe<Scalars['Boolean']>;
  lastUpdated?: Maybe<_Neo4jDateTimeInput>;
  lastUpdated_not?: Maybe<_Neo4jDateTimeInput>;
  lastUpdated_in?: Maybe<Array<_Neo4jDateTimeInput>>;
  lastUpdated_not_in?: Maybe<Array<_Neo4jDateTimeInput>>;
  lastUpdated_lt?: Maybe<_Neo4jDateTimeInput>;
  lastUpdated_lte?: Maybe<_Neo4jDateTimeInput>;
  lastUpdated_gt?: Maybe<_Neo4jDateTimeInput>;
  lastUpdated_gte?: Maybe<_Neo4jDateTimeInput>;
  isArchived?: Maybe<Scalars['Boolean']>;
  isArchived_not?: Maybe<Scalars['Boolean']>;
  lastUpdatedByUserId?: Maybe<Scalars['String']>;
  lastUpdatedByUserId_not?: Maybe<Scalars['String']>;
  lastUpdatedByUserId_in?: Maybe<Array<Scalars['String']>>;
  lastUpdatedByUserId_not_in?: Maybe<Array<Scalars['String']>>;
  lastUpdatedByUserId_regexp?: Maybe<Scalars['String']>;
  lastUpdatedByUserId_contains?: Maybe<Scalars['String']>;
  lastUpdatedByUserId_not_contains?: Maybe<Scalars['String']>;
  lastUpdatedByUserId_starts_with?: Maybe<Scalars['String']>;
  lastUpdatedByUserId_not_starts_with?: Maybe<Scalars['String']>;
  lastUpdatedByUserId_ends_with?: Maybe<Scalars['String']>;
  lastUpdatedByUserId_not_ends_with?: Maybe<Scalars['String']>;
  user?: Maybe<_UserFilter>;
  user_not?: Maybe<_UserFilter>;
  user_in?: Maybe<Array<_UserFilter>>;
  user_not_in?: Maybe<Array<_UserFilter>>;
  criteria?: Maybe<_CriteriaFilter>;
  criteria_not?: Maybe<_CriteriaFilter>;
  criteria_in?: Maybe<Array<_CriteriaFilter>>;
  criteria_not_in?: Maybe<Array<_CriteriaFilter>>;
  scoredConcept?: Maybe<_ConceptFilter>;
  scoredConcept_not?: Maybe<_ConceptFilter>;
  scoredConcept_in?: Maybe<Array<_ConceptFilter>>;
  scoredConcept_not_in?: Maybe<Array<_ConceptFilter>>;
  concepts?: Maybe<_ConceptFilter>;
  concepts_not?: Maybe<_ConceptFilter>;
  concepts_in?: Maybe<Array<_ConceptFilter>>;
  concepts_not_in?: Maybe<Array<_ConceptFilter>>;
  concepts_some?: Maybe<_ConceptFilter>;
  concepts_none?: Maybe<_ConceptFilter>;
  concepts_single?: Maybe<_ConceptFilter>;
  concepts_every?: Maybe<_ConceptFilter>;
  exerciseSubmission?: Maybe<_ExerciseSubmissionFilter>;
  exerciseSubmission_not?: Maybe<_ExerciseSubmissionFilter>;
  exerciseSubmission_in?: Maybe<Array<_ExerciseSubmissionFilter>>;
  exerciseSubmission_not_in?: Maybe<Array<_ExerciseSubmissionFilter>>;
  exercise?: Maybe<_ExerciseFilter>;
  exercise_not?: Maybe<_ExerciseFilter>;
  exercise_in?: Maybe<Array<_ExerciseFilter>>;
  exercise_not_in?: Maybe<Array<_ExerciseFilter>>;
  input?: Maybe<_InputFilter>;
  input_not?: Maybe<_InputFilter>;
  input_in?: Maybe<Array<_InputFilter>>;
  input_not_in?: Maybe<Array<_InputFilter>>;
};

export type _CriteriaScoreInput = {
  id: Scalars['ID'];
};

export enum _CriteriaScoreOrdering {
  ID_ASC = 'id_asc',
  ID_DESC = 'id_desc',
  LABEL_ASC = 'label_asc',
  LABEL_DESC = 'label_desc',
  VALUE_ASC = 'value_asc',
  VALUE_DESC = 'value_desc',
  RESPONSE_ASC = 'response_asc',
  RESPONSE_DESC = 'response_desc',
  NOTES_ASC = 'notes_asc',
  NOTES_DESC = 'notes_desc',
  CHATHISTORY_ASC = 'chatHistory_asc',
  CHATHISTORY_DESC = 'chatHistory_desc',
  STATUS_ASC = 'status_asc',
  STATUS_DESC = 'status_desc',
  CONCEPTDEFAULT_ASC = 'conceptDefault_asc',
  CONCEPTDEFAULT_DESC = 'conceptDefault_desc',
  LASTUPDATED_ASC = 'lastUpdated_asc',
  LASTUPDATED_DESC = 'lastUpdated_desc',
  ISARCHIVED_ASC = 'isArchived_asc',
  ISARCHIVED_DESC = 'isArchived_desc',
  LASTUPDATEDBYUSERID_ASC = 'lastUpdatedByUserId_asc',
  LASTUPDATEDBYUSERID_DESC = 'lastUpdatedByUserId_desc',
  _ID_ASC = '_id_asc',
  _ID_DESC = '_id_desc'
}

export type _DashboardViewFilter = {
  AND?: Maybe<Array<_DashboardViewFilter>>;
  OR?: Maybe<Array<_DashboardViewFilter>>;
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Scalars['ID']>>;
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  id_regexp?: Maybe<Scalars['ID']>;
  id_contains?: Maybe<Scalars['ID']>;
  id_not_contains?: Maybe<Scalars['ID']>;
  id_starts_with?: Maybe<Scalars['ID']>;
  id_not_starts_with?: Maybe<Scalars['ID']>;
  id_ends_with?: Maybe<Scalars['ID']>;
  id_not_ends_with?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  name_not?: Maybe<Scalars['String']>;
  name_in?: Maybe<Array<Scalars['String']>>;
  name_not_in?: Maybe<Array<Scalars['String']>>;
  name_regexp?: Maybe<Scalars['String']>;
  name_contains?: Maybe<Scalars['String']>;
  name_not_contains?: Maybe<Scalars['String']>;
  name_starts_with?: Maybe<Scalars['String']>;
  name_not_starts_with?: Maybe<Scalars['String']>;
  name_ends_with?: Maybe<Scalars['String']>;
  name_not_ends_with?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  description_not?: Maybe<Scalars['String']>;
  description_in?: Maybe<Array<Scalars['String']>>;
  description_not_in?: Maybe<Array<Scalars['String']>>;
  description_regexp?: Maybe<Scalars['String']>;
  description_contains?: Maybe<Scalars['String']>;
  description_not_contains?: Maybe<Scalars['String']>;
  description_starts_with?: Maybe<Scalars['String']>;
  description_not_starts_with?: Maybe<Scalars['String']>;
  description_ends_with?: Maybe<Scalars['String']>;
  description_not_ends_with?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  type_not?: Maybe<Scalars['String']>;
  type_in?: Maybe<Array<Scalars['String']>>;
  type_not_in?: Maybe<Array<Scalars['String']>>;
  type_regexp?: Maybe<Scalars['String']>;
  type_contains?: Maybe<Scalars['String']>;
  type_not_contains?: Maybe<Scalars['String']>;
  type_starts_with?: Maybe<Scalars['String']>;
  type_not_starts_with?: Maybe<Scalars['String']>;
  type_ends_with?: Maybe<Scalars['String']>;
  type_not_ends_with?: Maybe<Scalars['String']>;
  layout?: Maybe<Scalars['String']>;
  layout_not?: Maybe<Scalars['String']>;
  layout_in?: Maybe<Array<Scalars['String']>>;
  layout_not_in?: Maybe<Array<Scalars['String']>>;
  layout_regexp?: Maybe<Scalars['String']>;
  layout_contains?: Maybe<Scalars['String']>;
  layout_not_contains?: Maybe<Scalars['String']>;
  layout_starts_with?: Maybe<Scalars['String']>;
  layout_not_starts_with?: Maybe<Scalars['String']>;
  layout_ends_with?: Maybe<Scalars['String']>;
  layout_not_ends_with?: Maybe<Scalars['String']>;
  isTemplate?: Maybe<Scalars['Boolean']>;
  isTemplate_not?: Maybe<Scalars['Boolean']>;
  isPublic?: Maybe<Scalars['Boolean']>;
  isPublic_not?: Maybe<Scalars['Boolean']>;
  activeTheme?: Maybe<_DesignThemeFilter>;
  activeTheme_not?: Maybe<_DesignThemeFilter>;
  activeTheme_in?: Maybe<Array<_DesignThemeFilter>>;
  activeTheme_not_in?: Maybe<Array<_DesignThemeFilter>>;
  createdBy?: Maybe<_UserFilter>;
  createdBy_not?: Maybe<_UserFilter>;
  createdBy_in?: Maybe<Array<_UserFilter>>;
  createdBy_not_in?: Maybe<Array<_UserFilter>>;
  usedBy?: Maybe<_UserFilter>;
  usedBy_not?: Maybe<_UserFilter>;
  usedBy_in?: Maybe<Array<_UserFilter>>;
  usedBy_not_in?: Maybe<Array<_UserFilter>>;
  usedBy_some?: Maybe<_UserFilter>;
  usedBy_none?: Maybe<_UserFilter>;
  usedBy_single?: Maybe<_UserFilter>;
  usedBy_every?: Maybe<_UserFilter>;
  usedByConcepts?: Maybe<_ConceptFilter>;
  usedByConcepts_not?: Maybe<_ConceptFilter>;
  usedByConcepts_in?: Maybe<Array<_ConceptFilter>>;
  usedByConcepts_not_in?: Maybe<Array<_ConceptFilter>>;
  usedByConcepts_some?: Maybe<_ConceptFilter>;
  usedByConcepts_none?: Maybe<_ConceptFilter>;
  usedByConcepts_single?: Maybe<_ConceptFilter>;
  usedByConcepts_every?: Maybe<_ConceptFilter>;
  widgets?: Maybe<_WidgetFilter>;
  widgets_not?: Maybe<_WidgetFilter>;
  widgets_in?: Maybe<Array<_WidgetFilter>>;
  widgets_not_in?: Maybe<Array<_WidgetFilter>>;
  widgets_some?: Maybe<_WidgetFilter>;
  widgets_none?: Maybe<_WidgetFilter>;
  widgets_single?: Maybe<_WidgetFilter>;
  widgets_every?: Maybe<_WidgetFilter>;
  conceptHome?: Maybe<_ConceptFilter>;
  conceptHome_not?: Maybe<_ConceptFilter>;
  conceptHome_in?: Maybe<Array<_ConceptFilter>>;
  conceptHome_not_in?: Maybe<Array<_ConceptFilter>>;
  conceptHome_some?: Maybe<_ConceptFilter>;
  conceptHome_none?: Maybe<_ConceptFilter>;
  conceptHome_single?: Maybe<_ConceptFilter>;
  conceptHome_every?: Maybe<_ConceptFilter>;
  categoryDefault?: Maybe<_CategoryFilter>;
  categoryDefault_not?: Maybe<_CategoryFilter>;
  categoryDefault_in?: Maybe<Array<_CategoryFilter>>;
  categoryDefault_not_in?: Maybe<Array<_CategoryFilter>>;
};

export type _DashboardViewInput = {
  id: Scalars['ID'];
};

export enum _DashboardViewOrdering {
  ID_ASC = 'id_asc',
  ID_DESC = 'id_desc',
  NAME_ASC = 'name_asc',
  NAME_DESC = 'name_desc',
  DESCRIPTION_ASC = 'description_asc',
  DESCRIPTION_DESC = 'description_desc',
  TYPE_ASC = 'type_asc',
  TYPE_DESC = 'type_desc',
  LAYOUT_ASC = 'layout_asc',
  LAYOUT_DESC = 'layout_desc',
  ISTEMPLATE_ASC = 'isTemplate_asc',
  ISTEMPLATE_DESC = 'isTemplate_desc',
  ISPUBLIC_ASC = 'isPublic_asc',
  ISPUBLIC_DESC = 'isPublic_desc',
  _ID_ASC = '_id_asc',
  _ID_DESC = '_id_desc'
}

export type _DesignThemeFilter = {
  AND?: Maybe<Array<_DesignThemeFilter>>;
  OR?: Maybe<Array<_DesignThemeFilter>>;
  themeId?: Maybe<Scalars['ID']>;
  themeId_not?: Maybe<Scalars['ID']>;
  themeId_in?: Maybe<Array<Scalars['ID']>>;
  themeId_not_in?: Maybe<Array<Scalars['ID']>>;
  themeId_regexp?: Maybe<Scalars['ID']>;
  themeId_contains?: Maybe<Scalars['ID']>;
  themeId_not_contains?: Maybe<Scalars['ID']>;
  themeId_starts_with?: Maybe<Scalars['ID']>;
  themeId_not_starts_with?: Maybe<Scalars['ID']>;
  themeId_ends_with?: Maybe<Scalars['ID']>;
  themeId_not_ends_with?: Maybe<Scalars['ID']>;
  title?: Maybe<Scalars['String']>;
  title_not?: Maybe<Scalars['String']>;
  title_in?: Maybe<Array<Scalars['String']>>;
  title_not_in?: Maybe<Array<Scalars['String']>>;
  title_regexp?: Maybe<Scalars['String']>;
  title_contains?: Maybe<Scalars['String']>;
  title_not_contains?: Maybe<Scalars['String']>;
  title_starts_with?: Maybe<Scalars['String']>;
  title_not_starts_with?: Maybe<Scalars['String']>;
  title_ends_with?: Maybe<Scalars['String']>;
  title_not_ends_with?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  description_not?: Maybe<Scalars['String']>;
  description_in?: Maybe<Array<Scalars['String']>>;
  description_not_in?: Maybe<Array<Scalars['String']>>;
  description_regexp?: Maybe<Scalars['String']>;
  description_contains?: Maybe<Scalars['String']>;
  description_not_contains?: Maybe<Scalars['String']>;
  description_starts_with?: Maybe<Scalars['String']>;
  description_not_starts_with?: Maybe<Scalars['String']>;
  description_ends_with?: Maybe<Scalars['String']>;
  description_not_ends_with?: Maybe<Scalars['String']>;
  darkMode?: Maybe<Scalars['Boolean']>;
  darkMode_not?: Maybe<Scalars['Boolean']>;
  primary?: Maybe<Scalars['String']>;
  primary_not?: Maybe<Scalars['String']>;
  primary_in?: Maybe<Array<Scalars['String']>>;
  primary_not_in?: Maybe<Array<Scalars['String']>>;
  primary_regexp?: Maybe<Scalars['String']>;
  primary_contains?: Maybe<Scalars['String']>;
  primary_not_contains?: Maybe<Scalars['String']>;
  primary_starts_with?: Maybe<Scalars['String']>;
  primary_not_starts_with?: Maybe<Scalars['String']>;
  primary_ends_with?: Maybe<Scalars['String']>;
  primary_not_ends_with?: Maybe<Scalars['String']>;
  secondary?: Maybe<Scalars['String']>;
  secondary_not?: Maybe<Scalars['String']>;
  secondary_in?: Maybe<Array<Scalars['String']>>;
  secondary_not_in?: Maybe<Array<Scalars['String']>>;
  secondary_regexp?: Maybe<Scalars['String']>;
  secondary_contains?: Maybe<Scalars['String']>;
  secondary_not_contains?: Maybe<Scalars['String']>;
  secondary_starts_with?: Maybe<Scalars['String']>;
  secondary_not_starts_with?: Maybe<Scalars['String']>;
  secondary_ends_with?: Maybe<Scalars['String']>;
  secondary_not_ends_with?: Maybe<Scalars['String']>;
  primaryHue?: Maybe<Scalars['String']>;
  primaryHue_not?: Maybe<Scalars['String']>;
  primaryHue_in?: Maybe<Array<Scalars['String']>>;
  primaryHue_not_in?: Maybe<Array<Scalars['String']>>;
  primaryHue_regexp?: Maybe<Scalars['String']>;
  primaryHue_contains?: Maybe<Scalars['String']>;
  primaryHue_not_contains?: Maybe<Scalars['String']>;
  primaryHue_starts_with?: Maybe<Scalars['String']>;
  primaryHue_not_starts_with?: Maybe<Scalars['String']>;
  primaryHue_ends_with?: Maybe<Scalars['String']>;
  primaryHue_not_ends_with?: Maybe<Scalars['String']>;
  secondaryHue?: Maybe<Scalars['String']>;
  secondaryHue_not?: Maybe<Scalars['String']>;
  secondaryHue_in?: Maybe<Array<Scalars['String']>>;
  secondaryHue_not_in?: Maybe<Array<Scalars['String']>>;
  secondaryHue_regexp?: Maybe<Scalars['String']>;
  secondaryHue_contains?: Maybe<Scalars['String']>;
  secondaryHue_not_contains?: Maybe<Scalars['String']>;
  secondaryHue_starts_with?: Maybe<Scalars['String']>;
  secondaryHue_not_starts_with?: Maybe<Scalars['String']>;
  secondaryHue_ends_with?: Maybe<Scalars['String']>;
  secondaryHue_not_ends_with?: Maybe<Scalars['String']>;
  primaryShade?: Maybe<Scalars['Int']>;
  primaryShade_not?: Maybe<Scalars['Int']>;
  primaryShade_in?: Maybe<Array<Scalars['Int']>>;
  primaryShade_not_in?: Maybe<Array<Scalars['Int']>>;
  primaryShade_lt?: Maybe<Scalars['Int']>;
  primaryShade_lte?: Maybe<Scalars['Int']>;
  primaryShade_gt?: Maybe<Scalars['Int']>;
  primaryShade_gte?: Maybe<Scalars['Int']>;
  secondaryShade?: Maybe<Scalars['Int']>;
  secondaryShade_not?: Maybe<Scalars['Int']>;
  secondaryShade_in?: Maybe<Array<Scalars['Int']>>;
  secondaryShade_not_in?: Maybe<Array<Scalars['Int']>>;
  secondaryShade_lt?: Maybe<Scalars['Int']>;
  secondaryShade_lte?: Maybe<Scalars['Int']>;
  secondaryShade_gt?: Maybe<Scalars['Int']>;
  secondaryShade_gte?: Maybe<Scalars['Int']>;
  background?: Maybe<Scalars['String']>;
  background_not?: Maybe<Scalars['String']>;
  background_in?: Maybe<Array<Scalars['String']>>;
  background_not_in?: Maybe<Array<Scalars['String']>>;
  background_regexp?: Maybe<Scalars['String']>;
  background_contains?: Maybe<Scalars['String']>;
  background_not_contains?: Maybe<Scalars['String']>;
  background_starts_with?: Maybe<Scalars['String']>;
  background_not_starts_with?: Maybe<Scalars['String']>;
  background_ends_with?: Maybe<Scalars['String']>;
  background_not_ends_with?: Maybe<Scalars['String']>;
  usedByDashboards?: Maybe<_DashboardViewFilter>;
  usedByDashboards_not?: Maybe<_DashboardViewFilter>;
  usedByDashboards_in?: Maybe<Array<_DashboardViewFilter>>;
  usedByDashboards_not_in?: Maybe<Array<_DashboardViewFilter>>;
  usedByDashboards_some?: Maybe<_DashboardViewFilter>;
  usedByDashboards_none?: Maybe<_DashboardViewFilter>;
  usedByDashboards_single?: Maybe<_DashboardViewFilter>;
  usedByDashboards_every?: Maybe<_DashboardViewFilter>;
  createdBy?: Maybe<_UserFilter>;
  createdBy_not?: Maybe<_UserFilter>;
  createdBy_in?: Maybe<Array<_UserFilter>>;
  createdBy_not_in?: Maybe<Array<_UserFilter>>;
  savedBy?: Maybe<_UserFilter>;
  savedBy_not?: Maybe<_UserFilter>;
  savedBy_in?: Maybe<Array<_UserFilter>>;
  savedBy_not_in?: Maybe<Array<_UserFilter>>;
  savedBy_some?: Maybe<_UserFilter>;
  savedBy_none?: Maybe<_UserFilter>;
  savedBy_single?: Maybe<_UserFilter>;
  savedBy_every?: Maybe<_UserFilter>;
  usedAsLightBy?: Maybe<_UserFilter>;
  usedAsLightBy_not?: Maybe<_UserFilter>;
  usedAsLightBy_in?: Maybe<Array<_UserFilter>>;
  usedAsLightBy_not_in?: Maybe<Array<_UserFilter>>;
  usedAsLightBy_some?: Maybe<_UserFilter>;
  usedAsLightBy_none?: Maybe<_UserFilter>;
  usedAsLightBy_single?: Maybe<_UserFilter>;
  usedAsLightBy_every?: Maybe<_UserFilter>;
  usedAsDarkBy?: Maybe<_UserFilter>;
  usedAsDarkBy_not?: Maybe<_UserFilter>;
  usedAsDarkBy_in?: Maybe<Array<_UserFilter>>;
  usedAsDarkBy_not_in?: Maybe<Array<_UserFilter>>;
  usedAsDarkBy_some?: Maybe<_UserFilter>;
  usedAsDarkBy_none?: Maybe<_UserFilter>;
  usedAsDarkBy_single?: Maybe<_UserFilter>;
  usedAsDarkBy_every?: Maybe<_UserFilter>;
};

export type _DesignThemeInput = {
  themeId: Scalars['ID'];
};

export enum _DesignThemeOrdering {
  THEMEID_ASC = 'themeId_asc',
  THEMEID_DESC = 'themeId_desc',
  TITLE_ASC = 'title_asc',
  TITLE_DESC = 'title_desc',
  DESCRIPTION_ASC = 'description_asc',
  DESCRIPTION_DESC = 'description_desc',
  DARKMODE_ASC = 'darkMode_asc',
  DARKMODE_DESC = 'darkMode_desc',
  PRIMARY_ASC = 'primary_asc',
  PRIMARY_DESC = 'primary_desc',
  SECONDARY_ASC = 'secondary_asc',
  SECONDARY_DESC = 'secondary_desc',
  PRIMARYHUE_ASC = 'primaryHue_asc',
  PRIMARYHUE_DESC = 'primaryHue_desc',
  SECONDARYHUE_ASC = 'secondaryHue_asc',
  SECONDARYHUE_DESC = 'secondaryHue_desc',
  PRIMARYSHADE_ASC = 'primaryShade_asc',
  PRIMARYSHADE_DESC = 'primaryShade_desc',
  SECONDARYSHADE_ASC = 'secondaryShade_asc',
  SECONDARYSHADE_DESC = 'secondaryShade_desc',
  BACKGROUND_ASC = 'background_asc',
  BACKGROUND_DESC = 'background_desc',
  _ID_ASC = '_id_asc',
  _ID_DESC = '_id_desc'
}

export type _EnvironmentSettingFilter = {
  AND?: Maybe<Array<_EnvironmentSettingFilter>>;
  OR?: Maybe<Array<_EnvironmentSettingFilter>>;
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Scalars['ID']>>;
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  id_regexp?: Maybe<Scalars['ID']>;
  id_contains?: Maybe<Scalars['ID']>;
  id_not_contains?: Maybe<Scalars['ID']>;
  id_starts_with?: Maybe<Scalars['ID']>;
  id_not_starts_with?: Maybe<Scalars['ID']>;
  id_ends_with?: Maybe<Scalars['ID']>;
  id_not_ends_with?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  name_not?: Maybe<Scalars['String']>;
  name_in?: Maybe<Array<Scalars['String']>>;
  name_not_in?: Maybe<Array<Scalars['String']>>;
  name_regexp?: Maybe<Scalars['String']>;
  name_contains?: Maybe<Scalars['String']>;
  name_not_contains?: Maybe<Scalars['String']>;
  name_starts_with?: Maybe<Scalars['String']>;
  name_not_starts_with?: Maybe<Scalars['String']>;
  name_ends_with?: Maybe<Scalars['String']>;
  name_not_ends_with?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
  value_not?: Maybe<Scalars['String']>;
  value_in?: Maybe<Array<Scalars['String']>>;
  value_not_in?: Maybe<Array<Scalars['String']>>;
  value_regexp?: Maybe<Scalars['String']>;
  value_contains?: Maybe<Scalars['String']>;
  value_not_contains?: Maybe<Scalars['String']>;
  value_starts_with?: Maybe<Scalars['String']>;
  value_not_starts_with?: Maybe<Scalars['String']>;
  value_ends_with?: Maybe<Scalars['String']>;
  value_not_ends_with?: Maybe<Scalars['String']>;
};

export type _EnvironmentSettingInput = {
  id: Scalars['ID'];
};

export enum _EnvironmentSettingOrdering {
  ID_ASC = 'id_asc',
  ID_DESC = 'id_desc',
  NAME_ASC = 'name_asc',
  NAME_DESC = 'name_desc',
  VALUE_ASC = 'value_asc',
  VALUE_DESC = 'value_desc',
  _ID_ASC = '_id_asc',
  _ID_DESC = '_id_desc'
}

export type _ExerciseFilter = {
  AND?: Maybe<Array<_ExerciseFilter>>;
  OR?: Maybe<Array<_ExerciseFilter>>;
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Scalars['ID']>>;
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  id_regexp?: Maybe<Scalars['ID']>;
  id_contains?: Maybe<Scalars['ID']>;
  id_not_contains?: Maybe<Scalars['ID']>;
  id_starts_with?: Maybe<Scalars['ID']>;
  id_not_starts_with?: Maybe<Scalars['ID']>;
  id_ends_with?: Maybe<Scalars['ID']>;
  id_not_ends_with?: Maybe<Scalars['ID']>;
  url?: Maybe<Scalars['String']>;
  url_not?: Maybe<Scalars['String']>;
  url_in?: Maybe<Array<Scalars['String']>>;
  url_not_in?: Maybe<Array<Scalars['String']>>;
  url_regexp?: Maybe<Scalars['String']>;
  url_contains?: Maybe<Scalars['String']>;
  url_not_contains?: Maybe<Scalars['String']>;
  url_starts_with?: Maybe<Scalars['String']>;
  url_not_starts_with?: Maybe<Scalars['String']>;
  url_ends_with?: Maybe<Scalars['String']>;
  url_not_ends_with?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  title_not?: Maybe<Scalars['String']>;
  title_in?: Maybe<Array<Scalars['String']>>;
  title_not_in?: Maybe<Array<Scalars['String']>>;
  title_regexp?: Maybe<Scalars['String']>;
  title_contains?: Maybe<Scalars['String']>;
  title_not_contains?: Maybe<Scalars['String']>;
  title_starts_with?: Maybe<Scalars['String']>;
  title_not_starts_with?: Maybe<Scalars['String']>;
  title_ends_with?: Maybe<Scalars['String']>;
  title_not_ends_with?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  description_not?: Maybe<Scalars['String']>;
  description_in?: Maybe<Array<Scalars['String']>>;
  description_not_in?: Maybe<Array<Scalars['String']>>;
  description_regexp?: Maybe<Scalars['String']>;
  description_contains?: Maybe<Scalars['String']>;
  description_not_contains?: Maybe<Scalars['String']>;
  description_starts_with?: Maybe<Scalars['String']>;
  description_not_starts_with?: Maybe<Scalars['String']>;
  description_ends_with?: Maybe<Scalars['String']>;
  description_not_ends_with?: Maybe<Scalars['String']>;
  instructions?: Maybe<Scalars['String']>;
  instructions_not?: Maybe<Scalars['String']>;
  instructions_in?: Maybe<Array<Scalars['String']>>;
  instructions_not_in?: Maybe<Array<Scalars['String']>>;
  instructions_regexp?: Maybe<Scalars['String']>;
  instructions_contains?: Maybe<Scalars['String']>;
  instructions_not_contains?: Maybe<Scalars['String']>;
  instructions_starts_with?: Maybe<Scalars['String']>;
  instructions_not_starts_with?: Maybe<Scalars['String']>;
  instructions_ends_with?: Maybe<Scalars['String']>;
  instructions_not_ends_with?: Maybe<Scalars['String']>;
  timerType?: Maybe<TimerType>;
  timerType_not?: Maybe<TimerType>;
  timerType_in?: Maybe<Array<TimerType>>;
  timerType_not_in?: Maybe<Array<TimerType>>;
  imageUrl?: Maybe<Scalars['String']>;
  imageUrl_not?: Maybe<Scalars['String']>;
  imageUrl_in?: Maybe<Array<Scalars['String']>>;
  imageUrl_not_in?: Maybe<Array<Scalars['String']>>;
  imageUrl_regexp?: Maybe<Scalars['String']>;
  imageUrl_contains?: Maybe<Scalars['String']>;
  imageUrl_not_contains?: Maybe<Scalars['String']>;
  imageUrl_starts_with?: Maybe<Scalars['String']>;
  imageUrl_not_starts_with?: Maybe<Scalars['String']>;
  imageUrl_ends_with?: Maybe<Scalars['String']>;
  imageUrl_not_ends_with?: Maybe<Scalars['String']>;
  duration?: Maybe<Scalars['Int']>;
  duration_not?: Maybe<Scalars['Int']>;
  duration_in?: Maybe<Array<Scalars['Int']>>;
  duration_not_in?: Maybe<Array<Scalars['Int']>>;
  duration_lt?: Maybe<Scalars['Int']>;
  duration_lte?: Maybe<Scalars['Int']>;
  duration_gt?: Maybe<Scalars['Int']>;
  duration_gte?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<_Neo4jDateTimeInput>;
  createdAt_not?: Maybe<_Neo4jDateTimeInput>;
  createdAt_in?: Maybe<Array<_Neo4jDateTimeInput>>;
  createdAt_not_in?: Maybe<Array<_Neo4jDateTimeInput>>;
  createdAt_lt?: Maybe<_Neo4jDateTimeInput>;
  createdAt_lte?: Maybe<_Neo4jDateTimeInput>;
  createdAt_gt?: Maybe<_Neo4jDateTimeInput>;
  createdAt_gte?: Maybe<_Neo4jDateTimeInput>;
  startTime?: Maybe<_Neo4jDateTimeInput>;
  startTime_not?: Maybe<_Neo4jDateTimeInput>;
  startTime_in?: Maybe<Array<_Neo4jDateTimeInput>>;
  startTime_not_in?: Maybe<Array<_Neo4jDateTimeInput>>;
  startTime_lt?: Maybe<_Neo4jDateTimeInput>;
  startTime_lte?: Maybe<_Neo4jDateTimeInput>;
  startTime_gt?: Maybe<_Neo4jDateTimeInput>;
  startTime_gte?: Maybe<_Neo4jDateTimeInput>;
  endTime?: Maybe<_Neo4jDateTimeInput>;
  endTime_not?: Maybe<_Neo4jDateTimeInput>;
  endTime_in?: Maybe<Array<_Neo4jDateTimeInput>>;
  endTime_not_in?: Maybe<Array<_Neo4jDateTimeInput>>;
  endTime_lt?: Maybe<_Neo4jDateTimeInput>;
  endTime_lte?: Maybe<_Neo4jDateTimeInput>;
  endTime_gt?: Maybe<_Neo4jDateTimeInput>;
  endTime_gte?: Maybe<_Neo4jDateTimeInput>;
  status?: Maybe<ExerciseStatus>;
  status_not?: Maybe<ExerciseStatus>;
  status_in?: Maybe<Array<ExerciseStatus>>;
  status_not_in?: Maybe<Array<ExerciseStatus>>;
  isPublic?: Maybe<Scalars['Boolean']>;
  isPublic_not?: Maybe<Scalars['Boolean']>;
  isTemplate?: Maybe<Scalars['Boolean']>;
  isTemplate_not?: Maybe<Scalars['Boolean']>;
  isConceptFeedback?: Maybe<Scalars['Boolean']>;
  isConceptFeedback_not?: Maybe<Scalars['Boolean']>;
  exerciseConfig?: Maybe<Scalars['String']>;
  exerciseConfig_not?: Maybe<Scalars['String']>;
  exerciseConfig_in?: Maybe<Array<Scalars['String']>>;
  exerciseConfig_not_in?: Maybe<Array<Scalars['String']>>;
  exerciseConfig_regexp?: Maybe<Scalars['String']>;
  exerciseConfig_contains?: Maybe<Scalars['String']>;
  exerciseConfig_not_contains?: Maybe<Scalars['String']>;
  exerciseConfig_starts_with?: Maybe<Scalars['String']>;
  exerciseConfig_not_starts_with?: Maybe<Scalars['String']>;
  exerciseConfig_ends_with?: Maybe<Scalars['String']>;
  exerciseConfig_not_ends_with?: Maybe<Scalars['String']>;
  criteria?: Maybe<_CriteriaFilter>;
  criteria_not?: Maybe<_CriteriaFilter>;
  criteria_in?: Maybe<Array<_CriteriaFilter>>;
  criteria_not_in?: Maybe<Array<_CriteriaFilter>>;
  criteria_some?: Maybe<_CriteriaFilter>;
  criteria_none?: Maybe<_CriteriaFilter>;
  criteria_single?: Maybe<_CriteriaFilter>;
  criteria_every?: Maybe<_CriteriaFilter>;
  type?: Maybe<_ExerciseTypeFilter>;
  type_not?: Maybe<_ExerciseTypeFilter>;
  type_in?: Maybe<Array<_ExerciseTypeFilter>>;
  type_not_in?: Maybe<Array<_ExerciseTypeFilter>>;
  facilitators?: Maybe<_UserFilter>;
  facilitators_not?: Maybe<_UserFilter>;
  facilitators_in?: Maybe<Array<_UserFilter>>;
  facilitators_not_in?: Maybe<Array<_UserFilter>>;
  facilitators_some?: Maybe<_UserFilter>;
  facilitators_none?: Maybe<_UserFilter>;
  facilitators_single?: Maybe<_UserFilter>;
  facilitators_every?: Maybe<_UserFilter>;
  invitedTeams?: Maybe<_TeamFilter>;
  invitedTeams_not?: Maybe<_TeamFilter>;
  invitedTeams_in?: Maybe<Array<_TeamFilter>>;
  invitedTeams_not_in?: Maybe<Array<_TeamFilter>>;
  invitedTeams_some?: Maybe<_TeamFilter>;
  invitedTeams_none?: Maybe<_TeamFilter>;
  invitedTeams_single?: Maybe<_TeamFilter>;
  invitedTeams_every?: Maybe<_TeamFilter>;
  invitedUsers?: Maybe<_UserFilter>;
  invitedUsers_not?: Maybe<_UserFilter>;
  invitedUsers_in?: Maybe<Array<_UserFilter>>;
  invitedUsers_not_in?: Maybe<Array<_UserFilter>>;
  invitedUsers_some?: Maybe<_UserFilter>;
  invitedUsers_none?: Maybe<_UserFilter>;
  invitedUsers_single?: Maybe<_UserFilter>;
  invitedUsers_every?: Maybe<_UserFilter>;
  participants?: Maybe<_UserFilter>;
  participants_not?: Maybe<_UserFilter>;
  participants_in?: Maybe<Array<_UserFilter>>;
  participants_not_in?: Maybe<Array<_UserFilter>>;
  participants_some?: Maybe<_UserFilter>;
  participants_none?: Maybe<_UserFilter>;
  participants_single?: Maybe<_UserFilter>;
  participants_every?: Maybe<_UserFilter>;
  createdBy?: Maybe<_UserFilter>;
  createdBy_not?: Maybe<_UserFilter>;
  createdBy_in?: Maybe<Array<_UserFilter>>;
  createdBy_not_in?: Maybe<Array<_UserFilter>>;
  submissions?: Maybe<_ExerciseSubmissionFilter>;
  submissions_not?: Maybe<_ExerciseSubmissionFilter>;
  submissions_in?: Maybe<Array<_ExerciseSubmissionFilter>>;
  submissions_not_in?: Maybe<Array<_ExerciseSubmissionFilter>>;
  submissions_some?: Maybe<_ExerciseSubmissionFilter>;
  submissions_none?: Maybe<_ExerciseSubmissionFilter>;
  submissions_single?: Maybe<_ExerciseSubmissionFilter>;
  submissions_every?: Maybe<_ExerciseSubmissionFilter>;
  parentConcept?: Maybe<_ConceptFilter>;
  parentConcept_not?: Maybe<_ConceptFilter>;
  parentConcept_in?: Maybe<Array<_ConceptFilter>>;
  parentConcept_not_in?: Maybe<Array<_ConceptFilter>>;
  includedChecklist?: Maybe<_ChecklistFilter>;
  includedChecklist_not?: Maybe<_ChecklistFilter>;
  includedChecklist_in?: Maybe<Array<_ChecklistFilter>>;
  includedChecklist_not_in?: Maybe<Array<_ChecklistFilter>>;
  includedConcepts?: Maybe<_ConceptFilter>;
  includedConcepts_not?: Maybe<_ConceptFilter>;
  includedConcepts_in?: Maybe<Array<_ConceptFilter>>;
  includedConcepts_not_in?: Maybe<Array<_ConceptFilter>>;
  includedConcepts_some?: Maybe<_ConceptFilter>;
  includedConcepts_none?: Maybe<_ConceptFilter>;
  includedConcepts_single?: Maybe<_ConceptFilter>;
  includedConcepts_every?: Maybe<_ConceptFilter>;
  conceptsCreated?: Maybe<_ConceptFilter>;
  conceptsCreated_not?: Maybe<_ConceptFilter>;
  conceptsCreated_in?: Maybe<Array<_ConceptFilter>>;
  conceptsCreated_not_in?: Maybe<Array<_ConceptFilter>>;
  conceptsCreated_some?: Maybe<_ConceptFilter>;
  conceptsCreated_none?: Maybe<_ConceptFilter>;
  conceptsCreated_single?: Maybe<_ConceptFilter>;
  conceptsCreated_every?: Maybe<_ConceptFilter>;
  criteriaScores?: Maybe<_CriteriaScoreFilter>;
  criteriaScores_not?: Maybe<_CriteriaScoreFilter>;
  criteriaScores_in?: Maybe<Array<_CriteriaScoreFilter>>;
  criteriaScores_not_in?: Maybe<Array<_CriteriaScoreFilter>>;
  criteriaScores_some?: Maybe<_CriteriaScoreFilter>;
  criteriaScores_none?: Maybe<_CriteriaScoreFilter>;
  criteriaScores_single?: Maybe<_CriteriaScoreFilter>;
  criteriaScores_every?: Maybe<_CriteriaScoreFilter>;
};

export type _ExerciseInput = {
  id: Scalars['ID'];
};

export enum _ExerciseOrdering {
  ID_ASC = 'id_asc',
  ID_DESC = 'id_desc',
  URL_ASC = 'url_asc',
  URL_DESC = 'url_desc',
  TITLE_ASC = 'title_asc',
  TITLE_DESC = 'title_desc',
  DESCRIPTION_ASC = 'description_asc',
  DESCRIPTION_DESC = 'description_desc',
  INSTRUCTIONS_ASC = 'instructions_asc',
  INSTRUCTIONS_DESC = 'instructions_desc',
  TIMERTYPE_ASC = 'timerType_asc',
  TIMERTYPE_DESC = 'timerType_desc',
  IMAGEURL_ASC = 'imageUrl_asc',
  IMAGEURL_DESC = 'imageUrl_desc',
  DURATION_ASC = 'duration_asc',
  DURATION_DESC = 'duration_desc',
  CREATEDAT_ASC = 'createdAt_asc',
  CREATEDAT_DESC = 'createdAt_desc',
  STARTTIME_ASC = 'startTime_asc',
  STARTTIME_DESC = 'startTime_desc',
  ENDTIME_ASC = 'endTime_asc',
  ENDTIME_DESC = 'endTime_desc',
  STATUS_ASC = 'status_asc',
  STATUS_DESC = 'status_desc',
  ISPUBLIC_ASC = 'isPublic_asc',
  ISPUBLIC_DESC = 'isPublic_desc',
  ISTEMPLATE_ASC = 'isTemplate_asc',
  ISTEMPLATE_DESC = 'isTemplate_desc',
  ISCONCEPTFEEDBACK_ASC = 'isConceptFeedback_asc',
  ISCONCEPTFEEDBACK_DESC = 'isConceptFeedback_desc',
  EXERCISECONFIG_ASC = 'exerciseConfig_asc',
  EXERCISECONFIG_DESC = 'exerciseConfig_desc',
  _ID_ASC = '_id_asc',
  _ID_DESC = '_id_desc'
}

export type _ExerciseScoreFilter = {
  AND?: Maybe<Array<_ExerciseScoreFilter>>;
  OR?: Maybe<Array<_ExerciseScoreFilter>>;
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Scalars['ID']>>;
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  id_regexp?: Maybe<Scalars['ID']>;
  id_contains?: Maybe<Scalars['ID']>;
  id_not_contains?: Maybe<Scalars['ID']>;
  id_starts_with?: Maybe<Scalars['ID']>;
  id_not_starts_with?: Maybe<Scalars['ID']>;
  id_ends_with?: Maybe<Scalars['ID']>;
  id_not_ends_with?: Maybe<Scalars['ID']>;
  value?: Maybe<Scalars['Int']>;
  value_not?: Maybe<Scalars['Int']>;
  value_in?: Maybe<Array<Scalars['Int']>>;
  value_not_in?: Maybe<Array<Scalars['Int']>>;
  value_lt?: Maybe<Scalars['Int']>;
  value_lte?: Maybe<Scalars['Int']>;
  value_gt?: Maybe<Scalars['Int']>;
  value_gte?: Maybe<Scalars['Int']>;
  explanation?: Maybe<Scalars['String']>;
  explanation_not?: Maybe<Scalars['String']>;
  explanation_in?: Maybe<Array<Scalars['String']>>;
  explanation_not_in?: Maybe<Array<Scalars['String']>>;
  explanation_regexp?: Maybe<Scalars['String']>;
  explanation_contains?: Maybe<Scalars['String']>;
  explanation_not_contains?: Maybe<Scalars['String']>;
  explanation_starts_with?: Maybe<Scalars['String']>;
  explanation_not_starts_with?: Maybe<Scalars['String']>;
  explanation_ends_with?: Maybe<Scalars['String']>;
  explanation_not_ends_with?: Maybe<Scalars['String']>;
  submission?: Maybe<_ExerciseSubmissionFilter>;
  submission_not?: Maybe<_ExerciseSubmissionFilter>;
  submission_in?: Maybe<Array<_ExerciseSubmissionFilter>>;
  submission_not_in?: Maybe<Array<_ExerciseSubmissionFilter>>;
  concept?: Maybe<_ConceptFilter>;
  concept_not?: Maybe<_ConceptFilter>;
  concept_in?: Maybe<Array<_ConceptFilter>>;
  concept_not_in?: Maybe<Array<_ConceptFilter>>;
};

export type _ExerciseScoreInput = {
  id: Scalars['ID'];
};

export enum _ExerciseScoreOrdering {
  ID_ASC = 'id_asc',
  ID_DESC = 'id_desc',
  VALUE_ASC = 'value_asc',
  VALUE_DESC = 'value_desc',
  EXPLANATION_ASC = 'explanation_asc',
  EXPLANATION_DESC = 'explanation_desc',
  _ID_ASC = '_id_asc',
  _ID_DESC = '_id_desc'
}

export type _ExerciseSubmissionFilter = {
  AND?: Maybe<Array<_ExerciseSubmissionFilter>>;
  OR?: Maybe<Array<_ExerciseSubmissionFilter>>;
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Scalars['ID']>>;
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  id_regexp?: Maybe<Scalars['ID']>;
  id_contains?: Maybe<Scalars['ID']>;
  id_not_contains?: Maybe<Scalars['ID']>;
  id_starts_with?: Maybe<Scalars['ID']>;
  id_not_starts_with?: Maybe<Scalars['ID']>;
  id_ends_with?: Maybe<Scalars['ID']>;
  id_not_ends_with?: Maybe<Scalars['ID']>;
  notes?: Maybe<Scalars['String']>;
  notes_not?: Maybe<Scalars['String']>;
  notes_in?: Maybe<Array<Scalars['String']>>;
  notes_not_in?: Maybe<Array<Scalars['String']>>;
  notes_regexp?: Maybe<Scalars['String']>;
  notes_contains?: Maybe<Scalars['String']>;
  notes_not_contains?: Maybe<Scalars['String']>;
  notes_starts_with?: Maybe<Scalars['String']>;
  notes_not_starts_with?: Maybe<Scalars['String']>;
  notes_ends_with?: Maybe<Scalars['String']>;
  notes_not_ends_with?: Maybe<Scalars['String']>;
  conceptIds?: Maybe<Array<Scalars['ID']>>;
  conceptIds_not?: Maybe<Array<Scalars['ID']>>;
  conceptIds_regexp?: Maybe<Scalars['ID']>;
  conceptIds_contains?: Maybe<Array<Scalars['ID']>>;
  conceptIds_not_contains?: Maybe<Array<Scalars['ID']>>;
  conceptIds_starts_with?: Maybe<Array<Scalars['ID']>>;
  conceptIds_not_starts_with?: Maybe<Array<Scalars['ID']>>;
  conceptIds_ends_with?: Maybe<Array<Scalars['ID']>>;
  conceptIds_not_ends_with?: Maybe<Array<Scalars['ID']>>;
  imageUrl?: Maybe<Scalars['String']>;
  imageUrl_not?: Maybe<Scalars['String']>;
  imageUrl_in?: Maybe<Array<Scalars['String']>>;
  imageUrl_not_in?: Maybe<Array<Scalars['String']>>;
  imageUrl_regexp?: Maybe<Scalars['String']>;
  imageUrl_contains?: Maybe<Scalars['String']>;
  imageUrl_not_contains?: Maybe<Scalars['String']>;
  imageUrl_starts_with?: Maybe<Scalars['String']>;
  imageUrl_not_starts_with?: Maybe<Scalars['String']>;
  imageUrl_ends_with?: Maybe<Scalars['String']>;
  imageUrl_not_ends_with?: Maybe<Scalars['String']>;
  status?: Maybe<ExerciseStatus>;
  status_not?: Maybe<ExerciseStatus>;
  status_in?: Maybe<Array<ExerciseStatus>>;
  status_not_in?: Maybe<Array<ExerciseStatus>>;
  exercise?: Maybe<_ExerciseFilter>;
  exercise_not?: Maybe<_ExerciseFilter>;
  exercise_in?: Maybe<Array<_ExerciseFilter>>;
  exercise_not_in?: Maybe<Array<_ExerciseFilter>>;
  user?: Maybe<_UserFilter>;
  user_not?: Maybe<_UserFilter>;
  user_in?: Maybe<Array<_UserFilter>>;
  user_not_in?: Maybe<Array<_UserFilter>>;
  scores?: Maybe<_ExerciseScoreFilter>;
  scores_not?: Maybe<_ExerciseScoreFilter>;
  scores_in?: Maybe<Array<_ExerciseScoreFilter>>;
  scores_not_in?: Maybe<Array<_ExerciseScoreFilter>>;
  scores_some?: Maybe<_ExerciseScoreFilter>;
  scores_none?: Maybe<_ExerciseScoreFilter>;
  scores_single?: Maybe<_ExerciseScoreFilter>;
  scores_every?: Maybe<_ExerciseScoreFilter>;
  conceptCreated?: Maybe<_ConceptFilter>;
  conceptCreated_not?: Maybe<_ConceptFilter>;
  conceptCreated_in?: Maybe<Array<_ConceptFilter>>;
  conceptCreated_not_in?: Maybe<Array<_ConceptFilter>>;
  conceptCreated_some?: Maybe<_ConceptFilter>;
  conceptCreated_none?: Maybe<_ConceptFilter>;
  conceptCreated_single?: Maybe<_ConceptFilter>;
  conceptCreated_every?: Maybe<_ConceptFilter>;
  criteriaScores?: Maybe<_CriteriaScoreFilter>;
  criteriaScores_not?: Maybe<_CriteriaScoreFilter>;
  criteriaScores_in?: Maybe<Array<_CriteriaScoreFilter>>;
  criteriaScores_not_in?: Maybe<Array<_CriteriaScoreFilter>>;
  criteriaScores_some?: Maybe<_CriteriaScoreFilter>;
  criteriaScores_none?: Maybe<_CriteriaScoreFilter>;
  criteriaScores_single?: Maybe<_CriteriaScoreFilter>;
  criteriaScores_every?: Maybe<_CriteriaScoreFilter>;
};

export type _ExerciseSubmissionInput = {
  id: Scalars['ID'];
};

export enum _ExerciseSubmissionOrdering {
  ID_ASC = 'id_asc',
  ID_DESC = 'id_desc',
  NOTES_ASC = 'notes_asc',
  NOTES_DESC = 'notes_desc',
  IMAGEURL_ASC = 'imageUrl_asc',
  IMAGEURL_DESC = 'imageUrl_desc',
  STATUS_ASC = 'status_asc',
  STATUS_DESC = 'status_desc',
  _ID_ASC = '_id_asc',
  _ID_DESC = '_id_desc'
}

export type _ExerciseTypeFilter = {
  AND?: Maybe<Array<_ExerciseTypeFilter>>;
  OR?: Maybe<Array<_ExerciseTypeFilter>>;
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Scalars['ID']>>;
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  id_regexp?: Maybe<Scalars['ID']>;
  id_contains?: Maybe<Scalars['ID']>;
  id_not_contains?: Maybe<Scalars['ID']>;
  id_starts_with?: Maybe<Scalars['ID']>;
  id_not_starts_with?: Maybe<Scalars['ID']>;
  id_ends_with?: Maybe<Scalars['ID']>;
  id_not_ends_with?: Maybe<Scalars['ID']>;
  title?: Maybe<Scalars['String']>;
  title_not?: Maybe<Scalars['String']>;
  title_in?: Maybe<Array<Scalars['String']>>;
  title_not_in?: Maybe<Array<Scalars['String']>>;
  title_regexp?: Maybe<Scalars['String']>;
  title_contains?: Maybe<Scalars['String']>;
  title_not_contains?: Maybe<Scalars['String']>;
  title_starts_with?: Maybe<Scalars['String']>;
  title_not_starts_with?: Maybe<Scalars['String']>;
  title_ends_with?: Maybe<Scalars['String']>;
  title_not_ends_with?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  description_not?: Maybe<Scalars['String']>;
  description_in?: Maybe<Array<Scalars['String']>>;
  description_not_in?: Maybe<Array<Scalars['String']>>;
  description_regexp?: Maybe<Scalars['String']>;
  description_contains?: Maybe<Scalars['String']>;
  description_not_contains?: Maybe<Scalars['String']>;
  description_starts_with?: Maybe<Scalars['String']>;
  description_not_starts_with?: Maybe<Scalars['String']>;
  description_ends_with?: Maybe<Scalars['String']>;
  description_not_ends_with?: Maybe<Scalars['String']>;
  instructions?: Maybe<Scalars['String']>;
  instructions_not?: Maybe<Scalars['String']>;
  instructions_in?: Maybe<Array<Scalars['String']>>;
  instructions_not_in?: Maybe<Array<Scalars['String']>>;
  instructions_regexp?: Maybe<Scalars['String']>;
  instructions_contains?: Maybe<Scalars['String']>;
  instructions_not_contains?: Maybe<Scalars['String']>;
  instructions_starts_with?: Maybe<Scalars['String']>;
  instructions_not_starts_with?: Maybe<Scalars['String']>;
  instructions_ends_with?: Maybe<Scalars['String']>;
  instructions_not_ends_with?: Maybe<Scalars['String']>;
  timerType?: Maybe<TimerType>;
  timerType_not?: Maybe<TimerType>;
  timerType_in?: Maybe<Array<TimerType>>;
  timerType_not_in?: Maybe<Array<TimerType>>;
  imageUrl?: Maybe<Scalars['String']>;
  imageUrl_not?: Maybe<Scalars['String']>;
  imageUrl_in?: Maybe<Array<Scalars['String']>>;
  imageUrl_not_in?: Maybe<Array<Scalars['String']>>;
  imageUrl_regexp?: Maybe<Scalars['String']>;
  imageUrl_contains?: Maybe<Scalars['String']>;
  imageUrl_not_contains?: Maybe<Scalars['String']>;
  imageUrl_starts_with?: Maybe<Scalars['String']>;
  imageUrl_not_starts_with?: Maybe<Scalars['String']>;
  imageUrl_ends_with?: Maybe<Scalars['String']>;
  imageUrl_not_ends_with?: Maybe<Scalars['String']>;
  category?: Maybe<ExerciseCategory>;
  category_not?: Maybe<ExerciseCategory>;
  category_in?: Maybe<Array<ExerciseCategory>>;
  category_not_in?: Maybe<Array<ExerciseCategory>>;
  isConceptFeedback?: Maybe<Scalars['Boolean']>;
  isConceptFeedback_not?: Maybe<Scalars['Boolean']>;
  exerciseTypeConfig?: Maybe<Scalars['String']>;
  exerciseTypeConfig_not?: Maybe<Scalars['String']>;
  exerciseTypeConfig_in?: Maybe<Array<Scalars['String']>>;
  exerciseTypeConfig_not_in?: Maybe<Array<Scalars['String']>>;
  exerciseTypeConfig_regexp?: Maybe<Scalars['String']>;
  exerciseTypeConfig_contains?: Maybe<Scalars['String']>;
  exerciseTypeConfig_not_contains?: Maybe<Scalars['String']>;
  exerciseTypeConfig_starts_with?: Maybe<Scalars['String']>;
  exerciseTypeConfig_not_starts_with?: Maybe<Scalars['String']>;
  exerciseTypeConfig_ends_with?: Maybe<Scalars['String']>;
  exerciseTypeConfig_not_ends_with?: Maybe<Scalars['String']>;
  criteriaIds?: Maybe<Array<Scalars['ID']>>;
  criteriaIds_not?: Maybe<Array<Scalars['ID']>>;
  criteriaIds_regexp?: Maybe<Scalars['ID']>;
  criteriaIds_contains?: Maybe<Array<Scalars['ID']>>;
  criteriaIds_not_contains?: Maybe<Array<Scalars['ID']>>;
  criteriaIds_starts_with?: Maybe<Array<Scalars['ID']>>;
  criteriaIds_not_starts_with?: Maybe<Array<Scalars['ID']>>;
  criteriaIds_ends_with?: Maybe<Array<Scalars['ID']>>;
  criteriaIds_not_ends_with?: Maybe<Array<Scalars['ID']>>;
  criteria?: Maybe<_CriteriaFilter>;
  criteria_not?: Maybe<_CriteriaFilter>;
  criteria_in?: Maybe<Array<_CriteriaFilter>>;
  criteria_not_in?: Maybe<Array<_CriteriaFilter>>;
  criteria_some?: Maybe<_CriteriaFilter>;
  criteria_none?: Maybe<_CriteriaFilter>;
  criteria_single?: Maybe<_CriteriaFilter>;
  criteria_every?: Maybe<_CriteriaFilter>;
  createdBy?: Maybe<_UserFilter>;
  createdBy_not?: Maybe<_UserFilter>;
  createdBy_in?: Maybe<Array<_UserFilter>>;
  createdBy_not_in?: Maybe<Array<_UserFilter>>;
  exercises?: Maybe<_ExerciseFilter>;
  exercises_not?: Maybe<_ExerciseFilter>;
  exercises_in?: Maybe<Array<_ExerciseFilter>>;
  exercises_not_in?: Maybe<Array<_ExerciseFilter>>;
  exercises_some?: Maybe<_ExerciseFilter>;
  exercises_none?: Maybe<_ExerciseFilter>;
  exercises_single?: Maybe<_ExerciseFilter>;
  exercises_every?: Maybe<_ExerciseFilter>;
};

export type _ExerciseTypeInput = {
  id: Scalars['ID'];
};

export enum _ExerciseTypeOrdering {
  ID_ASC = 'id_asc',
  ID_DESC = 'id_desc',
  TITLE_ASC = 'title_asc',
  TITLE_DESC = 'title_desc',
  DESCRIPTION_ASC = 'description_asc',
  DESCRIPTION_DESC = 'description_desc',
  INSTRUCTIONS_ASC = 'instructions_asc',
  INSTRUCTIONS_DESC = 'instructions_desc',
  TIMERTYPE_ASC = 'timerType_asc',
  TIMERTYPE_DESC = 'timerType_desc',
  IMAGEURL_ASC = 'imageUrl_asc',
  IMAGEURL_DESC = 'imageUrl_desc',
  CATEGORY_ASC = 'category_asc',
  CATEGORY_DESC = 'category_desc',
  ISCONCEPTFEEDBACK_ASC = 'isConceptFeedback_asc',
  ISCONCEPTFEEDBACK_DESC = 'isConceptFeedback_desc',
  EXERCISETYPECONFIG_ASC = 'exerciseTypeConfig_asc',
  EXERCISETYPECONFIG_DESC = 'exerciseTypeConfig_desc',
  _ID_ASC = '_id_asc',
  _ID_DESC = '_id_desc'
}

export type _FileFilter = {
  AND?: Maybe<Array<_FileFilter>>;
  OR?: Maybe<Array<_FileFilter>>;
  filename?: Maybe<Scalars['String']>;
  filename_not?: Maybe<Scalars['String']>;
  filename_in?: Maybe<Array<Scalars['String']>>;
  filename_not_in?: Maybe<Array<Scalars['String']>>;
  filename_regexp?: Maybe<Scalars['String']>;
  filename_contains?: Maybe<Scalars['String']>;
  filename_not_contains?: Maybe<Scalars['String']>;
  filename_starts_with?: Maybe<Scalars['String']>;
  filename_not_starts_with?: Maybe<Scalars['String']>;
  filename_ends_with?: Maybe<Scalars['String']>;
  filename_not_ends_with?: Maybe<Scalars['String']>;
  mimetype?: Maybe<Scalars['String']>;
  mimetype_not?: Maybe<Scalars['String']>;
  mimetype_in?: Maybe<Array<Scalars['String']>>;
  mimetype_not_in?: Maybe<Array<Scalars['String']>>;
  mimetype_regexp?: Maybe<Scalars['String']>;
  mimetype_contains?: Maybe<Scalars['String']>;
  mimetype_not_contains?: Maybe<Scalars['String']>;
  mimetype_starts_with?: Maybe<Scalars['String']>;
  mimetype_not_starts_with?: Maybe<Scalars['String']>;
  mimetype_ends_with?: Maybe<Scalars['String']>;
  mimetype_not_ends_with?: Maybe<Scalars['String']>;
  encoding?: Maybe<Scalars['String']>;
  encoding_not?: Maybe<Scalars['String']>;
  encoding_in?: Maybe<Array<Scalars['String']>>;
  encoding_not_in?: Maybe<Array<Scalars['String']>>;
  encoding_regexp?: Maybe<Scalars['String']>;
  encoding_contains?: Maybe<Scalars['String']>;
  encoding_not_contains?: Maybe<Scalars['String']>;
  encoding_starts_with?: Maybe<Scalars['String']>;
  encoding_not_starts_with?: Maybe<Scalars['String']>;
  encoding_ends_with?: Maybe<Scalars['String']>;
  encoding_not_ends_with?: Maybe<Scalars['String']>;
  fileUrl?: Maybe<Scalars['String']>;
  fileUrl_not?: Maybe<Scalars['String']>;
  fileUrl_in?: Maybe<Array<Scalars['String']>>;
  fileUrl_not_in?: Maybe<Array<Scalars['String']>>;
  fileUrl_regexp?: Maybe<Scalars['String']>;
  fileUrl_contains?: Maybe<Scalars['String']>;
  fileUrl_not_contains?: Maybe<Scalars['String']>;
  fileUrl_starts_with?: Maybe<Scalars['String']>;
  fileUrl_not_starts_with?: Maybe<Scalars['String']>;
  fileUrl_ends_with?: Maybe<Scalars['String']>;
  fileUrl_not_ends_with?: Maybe<Scalars['String']>;
};

export type _FileInput = {
  filename: Scalars['String'];
};

export enum _FileOrdering {
  FILENAME_ASC = 'filename_asc',
  FILENAME_DESC = 'filename_desc',
  MIMETYPE_ASC = 'mimetype_asc',
  MIMETYPE_DESC = 'mimetype_desc',
  ENCODING_ASC = 'encoding_asc',
  ENCODING_DESC = 'encoding_desc',
  FILEURL_ASC = 'fileUrl_asc',
  FILEURL_DESC = 'fileUrl_desc',
  _ID_ASC = '_id_asc',
  _ID_DESC = '_id_desc'
}

export type _IndustryFilter = {
  AND?: Maybe<Array<_IndustryFilter>>;
  OR?: Maybe<Array<_IndustryFilter>>;
  industryId?: Maybe<Scalars['ID']>;
  industryId_not?: Maybe<Scalars['ID']>;
  industryId_in?: Maybe<Array<Scalars['ID']>>;
  industryId_not_in?: Maybe<Array<Scalars['ID']>>;
  industryId_regexp?: Maybe<Scalars['ID']>;
  industryId_contains?: Maybe<Scalars['ID']>;
  industryId_not_contains?: Maybe<Scalars['ID']>;
  industryId_starts_with?: Maybe<Scalars['ID']>;
  industryId_not_starts_with?: Maybe<Scalars['ID']>;
  industryId_ends_with?: Maybe<Scalars['ID']>;
  industryId_not_ends_with?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  name_not?: Maybe<Scalars['String']>;
  name_in?: Maybe<Array<Scalars['String']>>;
  name_not_in?: Maybe<Array<Scalars['String']>>;
  name_regexp?: Maybe<Scalars['String']>;
  name_contains?: Maybe<Scalars['String']>;
  name_not_contains?: Maybe<Scalars['String']>;
  name_starts_with?: Maybe<Scalars['String']>;
  name_not_starts_with?: Maybe<Scalars['String']>;
  name_ends_with?: Maybe<Scalars['String']>;
  name_not_ends_with?: Maybe<Scalars['String']>;
  marketSize?: Maybe<Scalars['Float']>;
  marketSize_not?: Maybe<Scalars['Float']>;
  marketSize_in?: Maybe<Array<Scalars['Float']>>;
  marketSize_not_in?: Maybe<Array<Scalars['Float']>>;
  marketSize_lt?: Maybe<Scalars['Float']>;
  marketSize_lte?: Maybe<Scalars['Float']>;
  marketSize_gt?: Maybe<Scalars['Float']>;
  marketSize_gte?: Maybe<Scalars['Float']>;
  companies?: Maybe<_CompanyFilter>;
  companies_not?: Maybe<_CompanyFilter>;
  companies_in?: Maybe<Array<_CompanyFilter>>;
  companies_not_in?: Maybe<Array<_CompanyFilter>>;
  companies_some?: Maybe<_CompanyFilter>;
  companies_none?: Maybe<_CompanyFilter>;
  companies_single?: Maybe<_CompanyFilter>;
  companies_every?: Maybe<_CompanyFilter>;
  concepts?: Maybe<_ConceptFilter>;
  concepts_not?: Maybe<_ConceptFilter>;
  concepts_in?: Maybe<Array<_ConceptFilter>>;
  concepts_not_in?: Maybe<Array<_ConceptFilter>>;
  concepts_some?: Maybe<_ConceptFilter>;
  concepts_none?: Maybe<_ConceptFilter>;
  concepts_single?: Maybe<_ConceptFilter>;
  concepts_every?: Maybe<_ConceptFilter>;
};

export type _IndustryInput = {
  industryId: Scalars['ID'];
};

export enum _IndustryOrdering {
  INDUSTRYID_ASC = 'industryId_asc',
  INDUSTRYID_DESC = 'industryId_desc',
  NAME_ASC = 'name_asc',
  NAME_DESC = 'name_desc',
  MARKETSIZE_ASC = 'marketSize_asc',
  MARKETSIZE_DESC = 'marketSize_desc',
  _ID_ASC = '_id_asc',
  _ID_DESC = '_id_desc'
}

export type _InitiativeFilter = {
  AND?: Maybe<Array<_InitiativeFilter>>;
  OR?: Maybe<Array<_InitiativeFilter>>;
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Scalars['ID']>>;
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  id_regexp?: Maybe<Scalars['ID']>;
  id_contains?: Maybe<Scalars['ID']>;
  id_not_contains?: Maybe<Scalars['ID']>;
  id_starts_with?: Maybe<Scalars['ID']>;
  id_not_starts_with?: Maybe<Scalars['ID']>;
  id_ends_with?: Maybe<Scalars['ID']>;
  id_not_ends_with?: Maybe<Scalars['ID']>;
  title?: Maybe<Scalars['String']>;
  title_not?: Maybe<Scalars['String']>;
  title_in?: Maybe<Array<Scalars['String']>>;
  title_not_in?: Maybe<Array<Scalars['String']>>;
  title_regexp?: Maybe<Scalars['String']>;
  title_contains?: Maybe<Scalars['String']>;
  title_not_contains?: Maybe<Scalars['String']>;
  title_starts_with?: Maybe<Scalars['String']>;
  title_not_starts_with?: Maybe<Scalars['String']>;
  title_ends_with?: Maybe<Scalars['String']>;
  title_not_ends_with?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  type_not?: Maybe<Scalars['String']>;
  type_in?: Maybe<Array<Scalars['String']>>;
  type_not_in?: Maybe<Array<Scalars['String']>>;
  type_regexp?: Maybe<Scalars['String']>;
  type_contains?: Maybe<Scalars['String']>;
  type_not_contains?: Maybe<Scalars['String']>;
  type_starts_with?: Maybe<Scalars['String']>;
  type_not_starts_with?: Maybe<Scalars['String']>;
  type_ends_with?: Maybe<Scalars['String']>;
  type_not_ends_with?: Maybe<Scalars['String']>;
  summary?: Maybe<Scalars['String']>;
  summary_not?: Maybe<Scalars['String']>;
  summary_in?: Maybe<Array<Scalars['String']>>;
  summary_not_in?: Maybe<Array<Scalars['String']>>;
  summary_regexp?: Maybe<Scalars['String']>;
  summary_contains?: Maybe<Scalars['String']>;
  summary_not_contains?: Maybe<Scalars['String']>;
  summary_starts_with?: Maybe<Scalars['String']>;
  summary_not_starts_with?: Maybe<Scalars['String']>;
  summary_ends_with?: Maybe<Scalars['String']>;
  summary_not_ends_with?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  description_not?: Maybe<Scalars['String']>;
  description_in?: Maybe<Array<Scalars['String']>>;
  description_not_in?: Maybe<Array<Scalars['String']>>;
  description_regexp?: Maybe<Scalars['String']>;
  description_contains?: Maybe<Scalars['String']>;
  description_not_contains?: Maybe<Scalars['String']>;
  description_starts_with?: Maybe<Scalars['String']>;
  description_not_starts_with?: Maybe<Scalars['String']>;
  description_ends_with?: Maybe<Scalars['String']>;
  description_not_ends_with?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  status_not?: Maybe<Scalars['String']>;
  status_in?: Maybe<Array<Scalars['String']>>;
  status_not_in?: Maybe<Array<Scalars['String']>>;
  status_regexp?: Maybe<Scalars['String']>;
  status_contains?: Maybe<Scalars['String']>;
  status_not_contains?: Maybe<Scalars['String']>;
  status_starts_with?: Maybe<Scalars['String']>;
  status_not_starts_with?: Maybe<Scalars['String']>;
  status_ends_with?: Maybe<Scalars['String']>;
  status_not_ends_with?: Maybe<Scalars['String']>;
  imageUrl?: Maybe<Scalars['String']>;
  imageUrl_not?: Maybe<Scalars['String']>;
  imageUrl_in?: Maybe<Array<Scalars['String']>>;
  imageUrl_not_in?: Maybe<Array<Scalars['String']>>;
  imageUrl_regexp?: Maybe<Scalars['String']>;
  imageUrl_contains?: Maybe<Scalars['String']>;
  imageUrl_not_contains?: Maybe<Scalars['String']>;
  imageUrl_starts_with?: Maybe<Scalars['String']>;
  imageUrl_not_starts_with?: Maybe<Scalars['String']>;
  imageUrl_ends_with?: Maybe<Scalars['String']>;
  imageUrl_not_ends_with?: Maybe<Scalars['String']>;
  createdAt?: Maybe<_Neo4jDateTimeInput>;
  createdAt_not?: Maybe<_Neo4jDateTimeInput>;
  createdAt_in?: Maybe<Array<_Neo4jDateTimeInput>>;
  createdAt_not_in?: Maybe<Array<_Neo4jDateTimeInput>>;
  createdAt_lt?: Maybe<_Neo4jDateTimeInput>;
  createdAt_lte?: Maybe<_Neo4jDateTimeInput>;
  createdAt_gt?: Maybe<_Neo4jDateTimeInput>;
  createdAt_gte?: Maybe<_Neo4jDateTimeInput>;
  startDate?: Maybe<_Neo4jDateTimeInput>;
  startDate_not?: Maybe<_Neo4jDateTimeInput>;
  startDate_in?: Maybe<Array<_Neo4jDateTimeInput>>;
  startDate_not_in?: Maybe<Array<_Neo4jDateTimeInput>>;
  startDate_lt?: Maybe<_Neo4jDateTimeInput>;
  startDate_lte?: Maybe<_Neo4jDateTimeInput>;
  startDate_gt?: Maybe<_Neo4jDateTimeInput>;
  startDate_gte?: Maybe<_Neo4jDateTimeInput>;
  endDate?: Maybe<_Neo4jDateTimeInput>;
  endDate_not?: Maybe<_Neo4jDateTimeInput>;
  endDate_in?: Maybe<Array<_Neo4jDateTimeInput>>;
  endDate_not_in?: Maybe<Array<_Neo4jDateTimeInput>>;
  endDate_lt?: Maybe<_Neo4jDateTimeInput>;
  endDate_lte?: Maybe<_Neo4jDateTimeInput>;
  endDate_gt?: Maybe<_Neo4jDateTimeInput>;
  endDate_gte?: Maybe<_Neo4jDateTimeInput>;
  duration?: Maybe<_Neo4jDateTimeInput>;
  duration_not?: Maybe<_Neo4jDateTimeInput>;
  duration_in?: Maybe<Array<_Neo4jDateTimeInput>>;
  duration_not_in?: Maybe<Array<_Neo4jDateTimeInput>>;
  duration_lt?: Maybe<_Neo4jDateTimeInput>;
  duration_lte?: Maybe<_Neo4jDateTimeInput>;
  duration_gt?: Maybe<_Neo4jDateTimeInput>;
  duration_gte?: Maybe<_Neo4jDateTimeInput>;
  likedBy?: Maybe<_UserFilter>;
  likedBy_not?: Maybe<_UserFilter>;
  likedBy_in?: Maybe<Array<_UserFilter>>;
  likedBy_not_in?: Maybe<Array<_UserFilter>>;
  likedBy_some?: Maybe<_UserFilter>;
  likedBy_none?: Maybe<_UserFilter>;
  likedBy_single?: Maybe<_UserFilter>;
  likedBy_every?: Maybe<_UserFilter>;
  team?: Maybe<_TeamFilter>;
  team_not?: Maybe<_TeamFilter>;
  team_in?: Maybe<Array<_TeamFilter>>;
  team_not_in?: Maybe<Array<_TeamFilter>>;
  user?: Maybe<_UserFilter>;
  user_not?: Maybe<_UserFilter>;
  user_in?: Maybe<Array<_UserFilter>>;
  user_not_in?: Maybe<Array<_UserFilter>>;
  connections?: Maybe<_ConceptFilter>;
  connections_not?: Maybe<_ConceptFilter>;
  connections_in?: Maybe<Array<_ConceptFilter>>;
  connections_not_in?: Maybe<Array<_ConceptFilter>>;
  connections_some?: Maybe<_ConceptFilter>;
  connections_none?: Maybe<_ConceptFilter>;
  connections_single?: Maybe<_ConceptFilter>;
  connections_every?: Maybe<_ConceptFilter>;
  tags?: Maybe<_TagFilter>;
  tags_not?: Maybe<_TagFilter>;
  tags_in?: Maybe<Array<_TagFilter>>;
  tags_not_in?: Maybe<Array<_TagFilter>>;
  tags_some?: Maybe<_TagFilter>;
  tags_none?: Maybe<_TagFilter>;
  tags_single?: Maybe<_TagFilter>;
  tags_every?: Maybe<_TagFilter>;
  comments?: Maybe<_CommentFilter>;
  comments_not?: Maybe<_CommentFilter>;
  comments_in?: Maybe<Array<_CommentFilter>>;
  comments_not_in?: Maybe<Array<_CommentFilter>>;
  comments_some?: Maybe<_CommentFilter>;
  comments_none?: Maybe<_CommentFilter>;
  comments_single?: Maybe<_CommentFilter>;
  comments_every?: Maybe<_CommentFilter>;
};

export type _InitiativeInput = {
  id: Scalars['ID'];
};

export enum _InitiativeOrdering {
  ID_ASC = 'id_asc',
  ID_DESC = 'id_desc',
  TITLE_ASC = 'title_asc',
  TITLE_DESC = 'title_desc',
  TYPE_ASC = 'type_asc',
  TYPE_DESC = 'type_desc',
  SUMMARY_ASC = 'summary_asc',
  SUMMARY_DESC = 'summary_desc',
  DESCRIPTION_ASC = 'description_asc',
  DESCRIPTION_DESC = 'description_desc',
  STATUS_ASC = 'status_asc',
  STATUS_DESC = 'status_desc',
  IMAGEURL_ASC = 'imageUrl_asc',
  IMAGEURL_DESC = 'imageUrl_desc',
  CREATEDAT_ASC = 'createdAt_asc',
  CREATEDAT_DESC = 'createdAt_desc',
  STARTDATE_ASC = 'startDate_asc',
  STARTDATE_DESC = 'startDate_desc',
  ENDDATE_ASC = 'endDate_asc',
  ENDDATE_DESC = 'endDate_desc',
  DURATION_ASC = 'duration_asc',
  DURATION_DESC = 'duration_desc',
  COMMENTCOUNT_ASC = 'commentCount_asc',
  COMMENTCOUNT_DESC = 'commentCount_desc',
  _ID_ASC = '_id_asc',
  _ID_DESC = '_id_desc'
}

export type _InputFilter = {
  AND?: Maybe<Array<_InputFilter>>;
  OR?: Maybe<Array<_InputFilter>>;
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Scalars['ID']>>;
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  id_regexp?: Maybe<Scalars['ID']>;
  id_contains?: Maybe<Scalars['ID']>;
  id_not_contains?: Maybe<Scalars['ID']>;
  id_starts_with?: Maybe<Scalars['ID']>;
  id_not_starts_with?: Maybe<Scalars['ID']>;
  id_ends_with?: Maybe<Scalars['ID']>;
  id_not_ends_with?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<_Neo4jDateTimeInput>;
  createdAt_not?: Maybe<_Neo4jDateTimeInput>;
  createdAt_in?: Maybe<Array<_Neo4jDateTimeInput>>;
  createdAt_not_in?: Maybe<Array<_Neo4jDateTimeInput>>;
  createdAt_lt?: Maybe<_Neo4jDateTimeInput>;
  createdAt_lte?: Maybe<_Neo4jDateTimeInput>;
  createdAt_gt?: Maybe<_Neo4jDateTimeInput>;
  createdAt_gte?: Maybe<_Neo4jDateTimeInput>;
  inputConfig?: Maybe<Scalars['String']>;
  inputConfig_not?: Maybe<Scalars['String']>;
  inputConfig_in?: Maybe<Array<Scalars['String']>>;
  inputConfig_not_in?: Maybe<Array<Scalars['String']>>;
  inputConfig_regexp?: Maybe<Scalars['String']>;
  inputConfig_contains?: Maybe<Scalars['String']>;
  inputConfig_not_contains?: Maybe<Scalars['String']>;
  inputConfig_starts_with?: Maybe<Scalars['String']>;
  inputConfig_not_starts_with?: Maybe<Scalars['String']>;
  inputConfig_ends_with?: Maybe<Scalars['String']>;
  inputConfig_not_ends_with?: Maybe<Scalars['String']>;
  criteria?: Maybe<_CriteriaFilter>;
  criteria_not?: Maybe<_CriteriaFilter>;
  criteria_in?: Maybe<Array<_CriteriaFilter>>;
  criteria_not_in?: Maybe<Array<_CriteriaFilter>>;
  parentConcept?: Maybe<_ConceptFilter>;
  parentConcept_not?: Maybe<_ConceptFilter>;
  parentConcept_in?: Maybe<Array<_ConceptFilter>>;
  parentConcept_not_in?: Maybe<Array<_ConceptFilter>>;
  includedConcepts?: Maybe<_ConceptFilter>;
  includedConcepts_not?: Maybe<_ConceptFilter>;
  includedConcepts_in?: Maybe<Array<_ConceptFilter>>;
  includedConcepts_not_in?: Maybe<Array<_ConceptFilter>>;
  includedConcepts_some?: Maybe<_ConceptFilter>;
  includedConcepts_none?: Maybe<_ConceptFilter>;
  includedConcepts_single?: Maybe<_ConceptFilter>;
  includedConcepts_every?: Maybe<_ConceptFilter>;
  conceptsCreated?: Maybe<_ConceptFilter>;
  conceptsCreated_not?: Maybe<_ConceptFilter>;
  conceptsCreated_in?: Maybe<Array<_ConceptFilter>>;
  conceptsCreated_not_in?: Maybe<Array<_ConceptFilter>>;
  conceptsCreated_some?: Maybe<_ConceptFilter>;
  conceptsCreated_none?: Maybe<_ConceptFilter>;
  conceptsCreated_single?: Maybe<_ConceptFilter>;
  conceptsCreated_every?: Maybe<_ConceptFilter>;
  criteriaScores?: Maybe<_CriteriaScoreFilter>;
  criteriaScores_not?: Maybe<_CriteriaScoreFilter>;
  criteriaScores_in?: Maybe<Array<_CriteriaScoreFilter>>;
  criteriaScores_not_in?: Maybe<Array<_CriteriaScoreFilter>>;
  criteriaScores_some?: Maybe<_CriteriaScoreFilter>;
  criteriaScores_none?: Maybe<_CriteriaScoreFilter>;
  criteriaScores_single?: Maybe<_CriteriaScoreFilter>;
  criteriaScores_every?: Maybe<_CriteriaScoreFilter>;
};

export type _InputInput = {
  id: Scalars['ID'];
};

export enum _InputOrdering {
  ID_ASC = 'id_asc',
  ID_DESC = 'id_desc',
  CREATEDAT_ASC = 'createdAt_asc',
  CREATEDAT_DESC = 'createdAt_desc',
  INPUTCONFIG_ASC = 'inputConfig_asc',
  INPUTCONFIG_DESC = 'inputConfig_desc',
  _ID_ASC = '_id_asc',
  _ID_DESC = '_id_desc'
}

export type _LinkFilter = {
  AND?: Maybe<Array<_LinkFilter>>;
  OR?: Maybe<Array<_LinkFilter>>;
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Scalars['ID']>>;
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  id_regexp?: Maybe<Scalars['ID']>;
  id_contains?: Maybe<Scalars['ID']>;
  id_not_contains?: Maybe<Scalars['ID']>;
  id_starts_with?: Maybe<Scalars['ID']>;
  id_not_starts_with?: Maybe<Scalars['ID']>;
  id_ends_with?: Maybe<Scalars['ID']>;
  id_not_ends_with?: Maybe<Scalars['ID']>;
  url?: Maybe<Scalars['String']>;
  url_not?: Maybe<Scalars['String']>;
  url_in?: Maybe<Array<Scalars['String']>>;
  url_not_in?: Maybe<Array<Scalars['String']>>;
  url_regexp?: Maybe<Scalars['String']>;
  url_contains?: Maybe<Scalars['String']>;
  url_not_contains?: Maybe<Scalars['String']>;
  url_starts_with?: Maybe<Scalars['String']>;
  url_not_starts_with?: Maybe<Scalars['String']>;
  url_ends_with?: Maybe<Scalars['String']>;
  url_not_ends_with?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  title_not?: Maybe<Scalars['String']>;
  title_in?: Maybe<Array<Scalars['String']>>;
  title_not_in?: Maybe<Array<Scalars['String']>>;
  title_regexp?: Maybe<Scalars['String']>;
  title_contains?: Maybe<Scalars['String']>;
  title_not_contains?: Maybe<Scalars['String']>;
  title_starts_with?: Maybe<Scalars['String']>;
  title_not_starts_with?: Maybe<Scalars['String']>;
  title_ends_with?: Maybe<Scalars['String']>;
  title_not_ends_with?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  description_not?: Maybe<Scalars['String']>;
  description_in?: Maybe<Array<Scalars['String']>>;
  description_not_in?: Maybe<Array<Scalars['String']>>;
  description_regexp?: Maybe<Scalars['String']>;
  description_contains?: Maybe<Scalars['String']>;
  description_not_contains?: Maybe<Scalars['String']>;
  description_starts_with?: Maybe<Scalars['String']>;
  description_not_starts_with?: Maybe<Scalars['String']>;
  description_ends_with?: Maybe<Scalars['String']>;
  description_not_ends_with?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  image_not?: Maybe<Scalars['String']>;
  image_in?: Maybe<Array<Scalars['String']>>;
  image_not_in?: Maybe<Array<Scalars['String']>>;
  image_regexp?: Maybe<Scalars['String']>;
  image_contains?: Maybe<Scalars['String']>;
  image_not_contains?: Maybe<Scalars['String']>;
  image_starts_with?: Maybe<Scalars['String']>;
  image_not_starts_with?: Maybe<Scalars['String']>;
  image_ends_with?: Maybe<Scalars['String']>;
  image_not_ends_with?: Maybe<Scalars['String']>;
  icon?: Maybe<Scalars['String']>;
  icon_not?: Maybe<Scalars['String']>;
  icon_in?: Maybe<Array<Scalars['String']>>;
  icon_not_in?: Maybe<Array<Scalars['String']>>;
  icon_regexp?: Maybe<Scalars['String']>;
  icon_contains?: Maybe<Scalars['String']>;
  icon_not_contains?: Maybe<Scalars['String']>;
  icon_starts_with?: Maybe<Scalars['String']>;
  icon_not_starts_with?: Maybe<Scalars['String']>;
  icon_ends_with?: Maybe<Scalars['String']>;
  icon_not_ends_with?: Maybe<Scalars['String']>;
  keywords?: Maybe<Scalars['String']>;
  keywords_not?: Maybe<Scalars['String']>;
  keywords_in?: Maybe<Array<Scalars['String']>>;
  keywords_not_in?: Maybe<Array<Scalars['String']>>;
  keywords_regexp?: Maybe<Scalars['String']>;
  keywords_contains?: Maybe<Scalars['String']>;
  keywords_not_contains?: Maybe<Scalars['String']>;
  keywords_starts_with?: Maybe<Scalars['String']>;
  keywords_not_starts_with?: Maybe<Scalars['String']>;
  keywords_ends_with?: Maybe<Scalars['String']>;
  keywords_not_ends_with?: Maybe<Scalars['String']>;
  language?: Maybe<Scalars['String']>;
  language_not?: Maybe<Scalars['String']>;
  language_in?: Maybe<Array<Scalars['String']>>;
  language_not_in?: Maybe<Array<Scalars['String']>>;
  language_regexp?: Maybe<Scalars['String']>;
  language_contains?: Maybe<Scalars['String']>;
  language_not_contains?: Maybe<Scalars['String']>;
  language_starts_with?: Maybe<Scalars['String']>;
  language_not_starts_with?: Maybe<Scalars['String']>;
  language_ends_with?: Maybe<Scalars['String']>;
  language_not_ends_with?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  type_not?: Maybe<Scalars['String']>;
  type_in?: Maybe<Array<Scalars['String']>>;
  type_not_in?: Maybe<Array<Scalars['String']>>;
  type_regexp?: Maybe<Scalars['String']>;
  type_contains?: Maybe<Scalars['String']>;
  type_not_contains?: Maybe<Scalars['String']>;
  type_starts_with?: Maybe<Scalars['String']>;
  type_not_starts_with?: Maybe<Scalars['String']>;
  type_ends_with?: Maybe<Scalars['String']>;
  type_not_ends_with?: Maybe<Scalars['String']>;
  provider?: Maybe<Scalars['String']>;
  provider_not?: Maybe<Scalars['String']>;
  provider_in?: Maybe<Array<Scalars['String']>>;
  provider_not_in?: Maybe<Array<Scalars['String']>>;
  provider_regexp?: Maybe<Scalars['String']>;
  provider_contains?: Maybe<Scalars['String']>;
  provider_not_contains?: Maybe<Scalars['String']>;
  provider_starts_with?: Maybe<Scalars['String']>;
  provider_not_starts_with?: Maybe<Scalars['String']>;
  provider_ends_with?: Maybe<Scalars['String']>;
  provider_not_ends_with?: Maybe<Scalars['String']>;
  addedAt?: Maybe<_Neo4jDateTimeInput>;
  addedAt_not?: Maybe<_Neo4jDateTimeInput>;
  addedAt_in?: Maybe<Array<_Neo4jDateTimeInput>>;
  addedAt_not_in?: Maybe<Array<_Neo4jDateTimeInput>>;
  addedAt_lt?: Maybe<_Neo4jDateTimeInput>;
  addedAt_lte?: Maybe<_Neo4jDateTimeInput>;
  addedAt_gt?: Maybe<_Neo4jDateTimeInput>;
  addedAt_gte?: Maybe<_Neo4jDateTimeInput>;
  updatedAt?: Maybe<_Neo4jDateTimeInput>;
  updatedAt_not?: Maybe<_Neo4jDateTimeInput>;
  updatedAt_in?: Maybe<Array<_Neo4jDateTimeInput>>;
  updatedAt_not_in?: Maybe<Array<_Neo4jDateTimeInput>>;
  updatedAt_lt?: Maybe<_Neo4jDateTimeInput>;
  updatedAt_lte?: Maybe<_Neo4jDateTimeInput>;
  updatedAt_gt?: Maybe<_Neo4jDateTimeInput>;
  updatedAt_gte?: Maybe<_Neo4jDateTimeInput>;
};

export type _LinkInput = {
  id: Scalars['ID'];
};

export enum _LinkOrdering {
  ID_ASC = 'id_asc',
  ID_DESC = 'id_desc',
  URL_ASC = 'url_asc',
  URL_DESC = 'url_desc',
  TITLE_ASC = 'title_asc',
  TITLE_DESC = 'title_desc',
  DESCRIPTION_ASC = 'description_asc',
  DESCRIPTION_DESC = 'description_desc',
  IMAGE_ASC = 'image_asc',
  IMAGE_DESC = 'image_desc',
  ICON_ASC = 'icon_asc',
  ICON_DESC = 'icon_desc',
  KEYWORDS_ASC = 'keywords_asc',
  KEYWORDS_DESC = 'keywords_desc',
  LANGUAGE_ASC = 'language_asc',
  LANGUAGE_DESC = 'language_desc',
  TYPE_ASC = 'type_asc',
  TYPE_DESC = 'type_desc',
  PROVIDER_ASC = 'provider_asc',
  PROVIDER_DESC = 'provider_desc',
  ADDEDAT_ASC = 'addedAt_asc',
  ADDEDAT_DESC = 'addedAt_desc',
  UPDATEDAT_ASC = 'updatedAt_asc',
  UPDATEDAT_DESC = 'updatedAt_desc',
  _ID_ASC = '_id_asc',
  _ID_DESC = '_id_desc'
}

export type _MergeCategoryChildrenPayload = {
  __typename?: '_MergeCategoryChildrenPayload';
  /** Field for the Category node this TYPE_OF [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<Category>;
  /** Field for the Category node this TYPE_OF [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<Category>;
};

export type _MergeCategoryConceptsPayload = {
  __typename?: '_MergeCategoryConceptsPayload';
  /** Field for the Concept node this IN_CATEGORY [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<Concept>;
  /** Field for the Category node this IN_CATEGORY [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<Category>;
};

export type _MergeCategoryCriteriaPayload = {
  __typename?: '_MergeCategoryCriteriaPayload';
  /** Field for the Category node this CATEGORY_USES_CRITERIA [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<Category>;
  /** Field for the Criteria node this CATEGORY_USES_CRITERIA [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<Criteria>;
};

export type _MergeCategoryDefaultCriteriaPayload = {
  __typename?: '_MergeCategoryDefaultCriteriaPayload';
  /** Field for the Category node this HAS_DEFAULT_CRITERIA [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<Category>;
  /** Field for the Criteria node this HAS_DEFAULT_CRITERIA [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<Criteria>;
};

export type _MergeCategoryDefaultDashboardPayload = {
  __typename?: '_MergeCategoryDefaultDashboardPayload';
  /** Field for the Category node this USES_DEFAULT_DASHBOARD [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<Category>;
  /** Field for the DashboardView node this USES_DEFAULT_DASHBOARD [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<DashboardView>;
};

export type _MergeCategoryParentPayload = {
  __typename?: '_MergeCategoryParentPayload';
  /** Field for the Category node this TYPE_OF [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<Category>;
  /** Field for the Category node this TYPE_OF [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<Category>;
};

export type _MergeCategoryWorkflowPayload = {
  __typename?: '_MergeCategoryWorkflowPayload';
  /** Field for the Workflow node this DEFAULT_WORKFLOW_FOR_CATEGORY [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<Workflow>;
  /** Field for the Category node this DEFAULT_WORKFLOW_FOR_CATEGORY [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<Category>;
};

export type _MergeChecklistConceptPayload = {
  __typename?: '_MergeChecklistConceptPayload';
  /** Field for the Concept node this CONCEPT_CHECKLIST [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<Concept>;
  /** Field for the Checklist node this CONCEPT_CHECKLIST [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<Checklist>;
};

export type _MergeChecklistCreatedByPayload = {
  __typename?: '_MergeChecklistCreatedByPayload';
  /** Field for the User node this CREATED [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<User>;
  /** Field for the Checklist node this CREATED [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<Checklist>;
};

export type _MergeChecklistExercisePayload = {
  __typename?: '_MergeChecklistExercisePayload';
  /** Field for the Exercise node this EXERCISE_INCLUDES_CHECKLIST [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<Exercise>;
  /** Field for the Checklist node this EXERCISE_INCLUDES_CHECKLIST [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<Checklist>;
};

export type _MergeChecklistItemChecklistPayload = {
  __typename?: '_MergeChecklistItemChecklistPayload';
  /** Field for the Checklist node this HAS_LIST_ITEM [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<Checklist>;
  /** Field for the ChecklistItem node this HAS_LIST_ITEM [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<ChecklistItem>;
};

export type _MergeChecklistItemChildrenPayload = {
  __typename?: '_MergeChecklistItemChildrenPayload';
  /** Field for the ChecklistItem node this CL_ITEM_CHILD_OF [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<ChecklistItem>;
  /** Field for the ChecklistItem node this CL_ITEM_CHILD_OF [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<ChecklistItem>;
};

export type _MergeChecklistItemConceptPayload = {
  __typename?: '_MergeChecklistItemConceptPayload';
  /** Field for the ChecklistItem node this CL_ITEM_LINKED_CONCEPT [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<ChecklistItem>;
  /** Field for the Concept node this CL_ITEM_LINKED_CONCEPT [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<Concept>;
};

export type _MergeChecklistItemCreatedByPayload = {
  __typename?: '_MergeChecklistItemCreatedByPayload';
  /** Field for the User node this CREATED [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<User>;
  /** Field for the ChecklistItem node this CREATED [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<ChecklistItem>;
};

export type _MergeChecklistItemParentPayload = {
  __typename?: '_MergeChecklistItemParentPayload';
  /** Field for the ChecklistItem node this CL_ITEM_CHILD_OF [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<ChecklistItem>;
  /** Field for the ChecklistItem node this CL_ITEM_CHILD_OF [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<ChecklistItem>;
};

export type _MergeChecklistItemsPayload = {
  __typename?: '_MergeChecklistItemsPayload';
  /** Field for the Checklist node this HAS_LIST_ITEM [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<Checklist>;
  /** Field for the ChecklistItem node this HAS_LIST_ITEM [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<ChecklistItem>;
};

export type _MergeChecklistTypeChecklistsPayload = {
  __typename?: '_MergeChecklistTypeChecklistsPayload';
  /** Field for the Checklist node this CHECKLIST_TYPE [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<Checklist>;
  /** Field for the ChecklistType node this CHECKLIST_TYPE [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<ChecklistType>;
};

export type _MergeChecklistTypePayload = {
  __typename?: '_MergeChecklistTypePayload';
  /** Field for the Checklist node this CHECKLIST_TYPE [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<Checklist>;
  /** Field for the ChecklistType node this CHECKLIST_TYPE [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<ChecklistType>;
};

export type _MergeCommentConceptPayload = {
  __typename?: '_MergeCommentConceptPayload';
  /** Field for the Comment node this COMMENT_ON [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<Comment>;
  /** Field for the Concept node this COMMENT_ON [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<Concept>;
};

export type _MergeCommentLikedByPayload = {
  __typename?: '_MergeCommentLikedByPayload';
  /** Field for the User node this LIKED_COMMENT [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<User>;
  /** Field for the Comment node this LIKED_COMMENT [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<Comment>;
};

export type _MergeCommentNotificationsPayload = {
  __typename?: '_MergeCommentNotificationsPayload';
  /** Field for the Comment node this COMMENT_TRIGGERED_NOTIFICATION [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<Comment>;
  /** Field for the Notification node this COMMENT_TRIGGERED_NOTIFICATION [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<Notification>;
};

export type _MergeCommentRepliesPayload = {
  __typename?: '_MergeCommentRepliesPayload';
  /** Field for the Comment node this REPLIED [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<Comment>;
  /** Field for the Comment node this REPLIED [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<Comment>;
};

export type _MergeCommentReplyToPayload = {
  __typename?: '_MergeCommentReplyToPayload';
  /** Field for the Comment node this REPLIED [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<Comment>;
  /** Field for the Comment node this REPLIED [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<Comment>;
};

export type _MergeCommentUserPayload = {
  __typename?: '_MergeCommentUserPayload';
  /** Field for the User node this COMMENTED [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<User>;
  /** Field for the Comment node this COMMENTED [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<Comment>;
};

export type _MergeCompanyEmployeesPayload = {
  __typename?: '_MergeCompanyEmployeesPayload';
  /** Field for the User node this WORKS_AT [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<User>;
  /** Field for the Company node this WORKS_AT [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<Company>;
};

export type _MergeCompanyIndustriesPayload = {
  __typename?: '_MergeCompanyIndustriesPayload';
  /** Field for the Company node this IN_INDUSTRY [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<Company>;
  /** Field for the Industry node this IN_INDUSTRY [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<Industry>;
};

export type _MergeCompanyTeamsPayload = {
  __typename?: '_MergeCompanyTeamsPayload';
  /** Field for the Team node this TEAM_AT [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<Team>;
  /** Field for the Company node this TEAM_AT [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<Company>;
};

export type _MergeConceptAddedTeamsPayload = {
  __typename?: '_MergeConceptAddedTeamsPayload';
  /** Field for the Team node this HAS_ACCESS [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<Team>;
  /** Field for the Concept node this HAS_ACCESS [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<Concept>;
  type?: Maybe<AccessType>;
  updatedAt?: Maybe<_Neo4jDateTime>;
  /** Generated field for querying the Neo4j [system id](https://neo4j.com/docs/cypher-manual/current/functions/scalar/#functions-id) of this relationship. */
  _id?: Maybe<Scalars['String']>;
};

export type _MergeConceptAddedUsersPayload = {
  __typename?: '_MergeConceptAddedUsersPayload';
  /** Field for the User node this HAS_ACCESS [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<User>;
  /** Field for the Concept node this HAS_ACCESS [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<Concept>;
  type?: Maybe<AccessType>;
  updatedAt?: Maybe<_Neo4jDateTime>;
  /** Generated field for querying the Neo4j [system id](https://neo4j.com/docs/cypher-manual/current/functions/scalar/#functions-id) of this relationship. */
  _id?: Maybe<Scalars['String']>;
};

export type _MergeConceptBoardColumnsPayload = {
  __typename?: '_MergeConceptBoardColumnsPayload';
  /** Field for the ConceptList node this ON_BOARD [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<ConceptList>;
  /** Field for the ConceptBoard node this ON_BOARD [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<ConceptBoard>;
};

export type _MergeConceptBoardUserPayload = {
  __typename?: '_MergeConceptBoardUserPayload';
  /** Field for the User node this HAS_CONCEPT_BOARD [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<User>;
  /** Field for the ConceptBoard node this HAS_CONCEPT_BOARD [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<ConceptBoard>;
};

export type _MergeConceptCategoryPayload = {
  __typename?: '_MergeConceptCategoryPayload';
  /** Field for the Concept node this IN_CATEGORY [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<Concept>;
  /** Field for the Category node this IN_CATEGORY [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<Category>;
};

export type _MergeConceptChecklistItemLinkPayload = {
  __typename?: '_MergeConceptChecklistItemLinkPayload';
  /** Field for the ChecklistItem node this CL_ITEM_LINKED_CONCEPT [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<ChecklistItem>;
  /** Field for the Concept node this CL_ITEM_LINKED_CONCEPT [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<Concept>;
};

export type _MergeConceptChecklistsPayload = {
  __typename?: '_MergeConceptChecklistsPayload';
  /** Field for the Concept node this CONCEPT_CHECKLIST [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<Concept>;
  /** Field for the Checklist node this CONCEPT_CHECKLIST [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<Checklist>;
};

export type _MergeConceptChildConceptListsPayload = {
  __typename?: '_MergeConceptChildConceptListsPayload';
  /** Field for the Concept node this CHILD_CONCEPT_LIST [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<Concept>;
  /** Field for the ConceptList node this CHILD_CONCEPT_LIST [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<ConceptList>;
};

export type _MergeConceptChildrenPayload = {
  __typename?: '_MergeConceptChildrenPayload';
  /** Field for the Concept node this CHILD_OF [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<Concept>;
  /** Field for the Concept node this CHILD_OF [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<Concept>;
};

export type _MergeConceptCommentsPayload = {
  __typename?: '_MergeConceptCommentsPayload';
  /** Field for the Comment node this COMMENT_ON [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<Comment>;
  /** Field for the Concept node this COMMENT_ON [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<Concept>;
};

export type _MergeConceptConnectionsPayload = {
  __typename?: '_MergeConceptConnectionsPayload';
  /** Field for the Concept node this CONNECTED [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<Concept>;
  /** Field for the Concept node this CONNECTED [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<Concept>;
};

export type _MergeConceptCreatedByPayload = {
  __typename?: '_MergeConceptCreatedByPayload';
  /** Field for the User node this CREATED [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<User>;
  /** Field for the Concept node this CREATED [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<Concept>;
};

export type _MergeConceptCreatedForExerciseSubmissionPayload = {
  __typename?: '_MergeConceptCreatedForExerciseSubmissionPayload';
  /** Field for the ExerciseSubmission node this SUBMISSION_SPARKED_CONCEPT [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<ExerciseSubmission>;
  /** Field for the Concept node this SUBMISSION_SPARKED_CONCEPT [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<Concept>;
};

export type _MergeConceptCreatedViaInputPayload = {
  __typename?: '_MergeConceptCreatedViaInputPayload';
  /** Field for the Input node this CREATED_VIA_INPUT [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<Input>;
  /** Field for the Concept node this CREATED_VIA_INPUT [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<Concept>;
};

export type _MergeConceptCriteriaPayload = {
  __typename?: '_MergeConceptCriteriaPayload';
  /** Field for the Concept node this HAS_CRITERIA [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<Concept>;
  /** Field for the Criteria node this HAS_CRITERIA [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<Criteria>;
};

export type _MergeConceptCriteriaScoresPayload = {
  __typename?: '_MergeConceptCriteriaScoresPayload';
  /** Field for the CriteriaScore node this SCORE_FOR_CONCEPT [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<CriteriaScore>;
  /** Field for the Concept node this SCORE_FOR_CONCEPT [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<Concept>;
};

export type _MergeConceptDetailedConnectionsPayload = {
  __typename?: '_MergeConceptDetailedConnectionsPayload';
  /** Field for the Concept node this CONNECTED [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<Concept>;
  /** Field for the Concept node this CONNECTED [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<Concept>;
  description?: Maybe<Scalars['String']>;
  type?: Maybe<ConnectionType>;
  username?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<_Neo4jDateTime>;
  updateValue?: Maybe<Scalars['String']>;
  /** Generated field for querying the Neo4j [system id](https://neo4j.com/docs/cypher-manual/current/functions/scalar/#functions-id) of this relationship. */
  _id?: Maybe<Scalars['String']>;
};

export type _MergeConceptExercisesCreatedPayload = {
  __typename?: '_MergeConceptExercisesCreatedPayload';
  /** Field for the Exercise node this EXERCISE_DONE_FOR [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<Exercise>;
  /** Field for the Concept node this EXERCISE_DONE_FOR [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<Concept>;
};

export type _MergeConceptExercisesIncludingPayload = {
  __typename?: '_MergeConceptExercisesIncludingPayload';
  /** Field for the Exercise node this EXERCISE_INCLUDES_CONCEPT [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<Exercise>;
  /** Field for the Concept node this EXERCISE_INCLUDES_CONCEPT [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<Concept>;
};

export type _MergeConceptHomeDashboardPayload = {
  __typename?: '_MergeConceptHomeDashboardPayload';
  /** Field for the Concept node this HAS_HOME_DASHBOARD [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<Concept>;
  /** Field for the DashboardView node this HAS_HOME_DASHBOARD [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<DashboardView>;
};

export type _MergeConceptInListsPayload = {
  __typename?: '_MergeConceptInListsPayload';
  /** Field for the Concept node this IN_LIST [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<Concept>;
  /** Field for the ConceptList node this IN_LIST [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<ConceptList>;
};

export type _MergeConceptIncludedForInputsPayload = {
  __typename?: '_MergeConceptIncludedForInputsPayload';
  /** Field for the Input node this INPUT_INCLUDES_CONCEPT [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<Input>;
  /** Field for the Concept node this INPUT_INCLUDES_CONCEPT [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<Concept>;
};

export type _MergeConceptIndustriesPayload = {
  __typename?: '_MergeConceptIndustriesPayload';
  /** Field for the Concept node this IMPACTS_INDUSTRY [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<Concept>;
  /** Field for the Industry node this IMPACTS_INDUSTRY [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<Industry>;
};

export type _MergeConceptInputsPayload = {
  __typename?: '_MergeConceptInputsPayload';
  /** Field for the Input node this INPUT_FOR_CONCEPT [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<Input>;
  /** Field for the Concept node this INPUT_FOR_CONCEPT [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<Concept>;
};

export type _MergeConceptLikedByPayload = {
  __typename?: '_MergeConceptLikedByPayload';
  /** Field for the User node this LIKED [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<User>;
  /** Field for the Concept node this LIKED [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<Concept>;
};

export type _MergeConceptListConceptBoardPayload = {
  __typename?: '_MergeConceptListConceptBoardPayload';
  /** Field for the ConceptList node this ON_BOARD [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<ConceptList>;
  /** Field for the ConceptBoard node this ON_BOARD [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<ConceptBoard>;
};

export type _MergeConceptListConceptsPayload = {
  __typename?: '_MergeConceptListConceptsPayload';
  /** Field for the Concept node this IN_LIST [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<Concept>;
  /** Field for the ConceptList node this IN_LIST [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<ConceptList>;
};

export type _MergeConceptListInboxUserPayload = {
  __typename?: '_MergeConceptListInboxUserPayload';
  /** Field for the User node this HAS_WORKSPACE_INBOX [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<User>;
  /** Field for the ConceptList node this HAS_WORKSPACE_INBOX [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<ConceptList>;
};

export type _MergeConceptListParentConceptPayload = {
  __typename?: '_MergeConceptListParentConceptPayload';
  /** Field for the Concept node this CHILD_CONCEPT_LIST [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<Concept>;
  /** Field for the ConceptList node this CHILD_CONCEPT_LIST [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<ConceptList>;
};

export type _MergeConceptListPersonaPayload = {
  __typename?: '_MergeConceptListPersonaPayload';
  /** Field for the Concept node this PERSONA_REQUIREMENTS [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<Concept>;
  /** Field for the ConceptList node this PERSONA_REQUIREMENTS [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<ConceptList>;
};

export type _MergeConceptListTeamPayload = {
  __typename?: '_MergeConceptListTeamPayload';
  /** Field for the Team node this TEAM_CONCEPT_LIST [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<Team>;
  /** Field for the ConceptList node this TEAM_CONCEPT_LIST [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<ConceptList>;
};

export type _MergeConceptListUserPayload = {
  __typename?: '_MergeConceptListUserPayload';
  /** Field for the User node this HAS_CONCEPT_LIST [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<User>;
  /** Field for the ConceptList node this HAS_CONCEPT_LIST [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<ConceptList>;
};

export type _MergeConceptListWorkspaceConceptPayload = {
  __typename?: '_MergeConceptListWorkspaceConceptPayload';
  /** Field for the Concept node this HAS_CONCEPT_WORKSPACE [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<Concept>;
  /** Field for the ConceptList node this HAS_CONCEPT_WORKSPACE [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<ConceptList>;
};

export type _MergeConceptNotificationsPayload = {
  __typename?: '_MergeConceptNotificationsPayload';
  /** Field for the Concept node this CONCEPT_TRIGGERED_NOTIFICATION [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<Concept>;
  /** Field for the Notification node this CONCEPT_TRIGGERED_NOTIFICATION [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<Notification>;
};

export type _MergeConceptParentPayload = {
  __typename?: '_MergeConceptParentPayload';
  /** Field for the Concept node this CHILD_OF [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<Concept>;
  /** Field for the Concept node this CHILD_OF [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<Concept>;
};

export type _MergeConceptRecentUpdatesPayload = {
  __typename?: '_MergeConceptRecentUpdatesPayload';
  /** Field for the User node this UPDATED [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<User>;
  /** Field for the Concept node this UPDATED [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<Concept>;
  updatedAt?: Maybe<_Neo4jDateTime>;
  updateValue?: Maybe<Scalars['String']>;
  /** Generated field for querying the Neo4j [system id](https://neo4j.com/docs/cypher-manual/current/functions/scalar/#functions-id) of this relationship. */
  _id?: Maybe<Scalars['String']>;
};

export type _MergeConceptStatusConceptPayload = {
  __typename?: '_MergeConceptStatusConceptPayload';
  /** Field for the Concept node this HAS_STATUS [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<Concept>;
  /** Field for the ConceptStatus node this HAS_STATUS [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<ConceptStatus>;
};

export type _MergeConceptStatusStagePayload = {
  __typename?: '_MergeConceptStatusStagePayload';
  /** Field for the ConceptStatus node this HAS_WORKFLOW_STAGE [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<ConceptStatus>;
  /** Field for the WorkflowStage node this HAS_WORKFLOW_STAGE [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<WorkflowStage>;
};

export type _MergeConceptTagsPayload = {
  __typename?: '_MergeConceptTagsPayload';
  /** Field for the Concept node this TAGGED [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<Concept>;
  /** Field for the Tag node this TAGGED [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<Tag>;
};

export type _MergeConceptTeamHomepagePayload = {
  __typename?: '_MergeConceptTeamHomepagePayload';
  /** Field for the Team node this TEAM_HOME_CONCEPT [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<Team>;
  /** Field for the Concept node this TEAM_HOME_CONCEPT [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<Concept>;
};

export type _MergeConceptTeamsPayload = {
  __typename?: '_MergeConceptTeamsPayload';
  /** Field for the Team node this TEAM_CONCEPT [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<Team>;
  /** Field for the Concept node this TEAM_CONCEPT [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<Concept>;
};

export type _MergeConceptTranslationsPayload = {
  __typename?: '_MergeConceptTranslationsPayload';
  /** Field for the Concept node this HAS_TRANSLATION [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<Concept>;
  /** Field for the Translation node this HAS_TRANSLATION [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<Translation>;
};

export type _MergeConceptUsedByScorePayload = {
  __typename?: '_MergeConceptUsedByScorePayload';
  /** Field for the CriteriaScore node this CONCEPTS_IN_SCORE [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<CriteriaScore>;
  /** Field for the Concept node this CONCEPTS_IN_SCORE [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<Concept>;
};

export type _MergeConceptUserHomepagePayload = {
  __typename?: '_MergeConceptUserHomepagePayload';
  /** Field for the User node this USER_HOME_CONCEPT [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<User>;
  /** Field for the Concept node this USER_HOME_CONCEPT [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<Concept>;
};

export type _MergeConceptUsesDashboardPayload = {
  __typename?: '_MergeConceptUsesDashboardPayload';
  /** Field for the Concept node this SAVED_DASHBOARD [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<Concept>;
  /** Field for the DashboardView node this SAVED_DASHBOARD [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<DashboardView>;
};

export type _MergeConceptWatchedByPayload = {
  __typename?: '_MergeConceptWatchedByPayload';
  /** Field for the User node this WATCHING [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<User>;
  /** Field for the Concept node this WATCHING [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<Concept>;
};

export type _MergeConceptWebLinkPayload = {
  __typename?: '_MergeConceptWebLinkPayload';
  /** Field for the Concept node this HAS_WEB_LINK [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<Concept>;
  /** Field for the Link node this HAS_WEB_LINK [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<Link>;
};

export type _MergeConceptWorkspacePayload = {
  __typename?: '_MergeConceptWorkspacePayload';
  /** Field for the Concept node this HAS_CONCEPT_WORKSPACE [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<Concept>;
  /** Field for the ConceptList node this HAS_CONCEPT_WORKSPACE [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<ConceptList>;
};

export type _MergeCriteriaCategoriesPayload = {
  __typename?: '_MergeCriteriaCategoriesPayload';
  /** Field for the Category node this CATEGORY_USES_CRITERIA [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<Category>;
  /** Field for the Criteria node this CATEGORY_USES_CRITERIA [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<Criteria>;
};

export type _MergeCriteriaCategoryPayload = {
  __typename?: '_MergeCriteriaCategoryPayload';
  /** Field for the Category node this HAS_DEFAULT_CRITERIA [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<Category>;
  /** Field for the Criteria node this HAS_DEFAULT_CRITERIA [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<Criteria>;
};

export type _MergeCriteriaChildrenPayload = {
  __typename?: '_MergeCriteriaChildrenPayload';
  /** Field for the Criteria node this CHILD_OF_CRITERIA [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<Criteria>;
  /** Field for the Criteria node this CHILD_OF_CRITERIA [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<Criteria>;
};

export type _MergeCriteriaConceptPayload = {
  __typename?: '_MergeCriteriaConceptPayload';
  /** Field for the Concept node this HAS_CRITERIA [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<Concept>;
  /** Field for the Criteria node this HAS_CRITERIA [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<Criteria>;
};

export type _MergeCriteriaExercisePayload = {
  __typename?: '_MergeCriteriaExercisePayload';
  /** Field for the Exercise node this INCLUDES_CRITERIA [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<Exercise>;
  /** Field for the Criteria node this INCLUDES_CRITERIA [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<Criteria>;
};

export type _MergeCriteriaExerciseTypesPayload = {
  __typename?: '_MergeCriteriaExerciseTypesPayload';
  /** Field for the ExerciseType node this TYPE_INCLUDES_CRITERIA [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<ExerciseType>;
  /** Field for the Criteria node this TYPE_INCLUDES_CRITERIA [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<Criteria>;
};

export type _MergeCriteriaInputsPayload = {
  __typename?: '_MergeCriteriaInputsPayload';
  /** Field for the Input node this INPUT_USES_CRITERIA [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<Input>;
  /** Field for the Criteria node this INPUT_USES_CRITERIA [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<Criteria>;
};

export type _MergeCriteriaParentFieldsPayload = {
  __typename?: '_MergeCriteriaParentFieldsPayload';
  /** Field for the Criteria node this HAS_SUBFIELD [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<Criteria>;
  /** Field for the Criteria node this HAS_SUBFIELD [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<Criteria>;
};

export type _MergeCriteriaParentPayload = {
  __typename?: '_MergeCriteriaParentPayload';
  /** Field for the Criteria node this CHILD_OF_CRITERIA [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<Criteria>;
  /** Field for the Criteria node this CHILD_OF_CRITERIA [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<Criteria>;
};

export type _MergeCriteriaScoreConceptsPayload = {
  __typename?: '_MergeCriteriaScoreConceptsPayload';
  /** Field for the CriteriaScore node this CONCEPTS_IN_SCORE [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<CriteriaScore>;
  /** Field for the Concept node this CONCEPTS_IN_SCORE [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<Concept>;
};

export type _MergeCriteriaScoreCriteriaPayload = {
  __typename?: '_MergeCriteriaScoreCriteriaPayload';
  /** Field for the CriteriaScore node this SCORE_FOR_CRITERIA [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<CriteriaScore>;
  /** Field for the Criteria node this SCORE_FOR_CRITERIA [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<Criteria>;
};

export type _MergeCriteriaScoreExercisePayload = {
  __typename?: '_MergeCriteriaScoreExercisePayload';
  /** Field for the Exercise node this EXERCISE_HAS_CRITERIA_SCORE [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<Exercise>;
  /** Field for the CriteriaScore node this EXERCISE_HAS_CRITERIA_SCORE [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<CriteriaScore>;
};

export type _MergeCriteriaScoreExerciseSubmissionPayload = {
  __typename?: '_MergeCriteriaScoreExerciseSubmissionPayload';
  /** Field for the ExerciseSubmission node this HAS_CRITERIA_SCORE [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<ExerciseSubmission>;
  /** Field for the CriteriaScore node this HAS_CRITERIA_SCORE [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<CriteriaScore>;
};

export type _MergeCriteriaScoreInputPayload = {
  __typename?: '_MergeCriteriaScoreInputPayload';
  /** Field for the Input node this INPUT_HAS_CRITERIA_SCORE [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<Input>;
  /** Field for the CriteriaScore node this INPUT_HAS_CRITERIA_SCORE [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<CriteriaScore>;
};

export type _MergeCriteriaScoreScoredConceptPayload = {
  __typename?: '_MergeCriteriaScoreScoredConceptPayload';
  /** Field for the CriteriaScore node this SCORE_FOR_CONCEPT [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<CriteriaScore>;
  /** Field for the Concept node this SCORE_FOR_CONCEPT [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<Concept>;
};

export type _MergeCriteriaScoreUserPayload = {
  __typename?: '_MergeCriteriaScoreUserPayload';
  /** Field for the CriteriaScore node this SCORED_BY [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<CriteriaScore>;
  /** Field for the User node this SCORED_BY [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<User>;
};

export type _MergeCriteriaScoresPayload = {
  __typename?: '_MergeCriteriaScoresPayload';
  /** Field for the CriteriaScore node this SCORE_FOR_CRITERIA [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<CriteriaScore>;
  /** Field for the Criteria node this SCORE_FOR_CRITERIA [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<Criteria>;
};

export type _MergeCriteriaSubfieldsPayload = {
  __typename?: '_MergeCriteriaSubfieldsPayload';
  /** Field for the Criteria node this HAS_SUBFIELD [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<Criteria>;
  /** Field for the Criteria node this HAS_SUBFIELD [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<Criteria>;
};

export type _MergeDashboardViewActiveThemePayload = {
  __typename?: '_MergeDashboardViewActiveThemePayload';
  /** Field for the DashboardView node this ACTIVE_THEME [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<DashboardView>;
  /** Field for the DesignTheme node this ACTIVE_THEME [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<DesignTheme>;
};

export type _MergeDashboardViewCategoryDefaultPayload = {
  __typename?: '_MergeDashboardViewCategoryDefaultPayload';
  /** Field for the Category node this USES_DEFAULT_DASHBOARD [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<Category>;
  /** Field for the DashboardView node this USES_DEFAULT_DASHBOARD [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<DashboardView>;
};

export type _MergeDashboardViewConceptHomePayload = {
  __typename?: '_MergeDashboardViewConceptHomePayload';
  /** Field for the Concept node this HAS_HOME_DASHBOARD [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<Concept>;
  /** Field for the DashboardView node this HAS_HOME_DASHBOARD [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<DashboardView>;
};

export type _MergeDashboardViewCreatedByPayload = {
  __typename?: '_MergeDashboardViewCreatedByPayload';
  /** Field for the User node this CREATED_DASHBOARD [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<User>;
  /** Field for the DashboardView node this CREATED_DASHBOARD [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<DashboardView>;
};

export type _MergeDashboardViewUsedByConceptsPayload = {
  __typename?: '_MergeDashboardViewUsedByConceptsPayload';
  /** Field for the Concept node this SAVED_DASHBOARD [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<Concept>;
  /** Field for the DashboardView node this SAVED_DASHBOARD [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<DashboardView>;
};

export type _MergeDashboardViewUsedByPayload = {
  __typename?: '_MergeDashboardViewUsedByPayload';
  /** Field for the User node this SAVED_DASHBOARD [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<User>;
  /** Field for the DashboardView node this SAVED_DASHBOARD [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<DashboardView>;
};

export type _MergeDashboardViewWidgetsPayload = {
  __typename?: '_MergeDashboardViewWidgetsPayload';
  /** Field for the DashboardView node this HAS_WIDGET [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<DashboardView>;
  /** Field for the Widget node this HAS_WIDGET [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<Widget>;
};

export type _MergeDesignThemeCreatedByPayload = {
  __typename?: '_MergeDesignThemeCreatedByPayload';
  /** Field for the User node this CREATED_THEME [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<User>;
  /** Field for the DesignTheme node this CREATED_THEME [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<DesignTheme>;
};

export type _MergeDesignThemeSavedByPayload = {
  __typename?: '_MergeDesignThemeSavedByPayload';
  /** Field for the User node this SAVED_THEME [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<User>;
  /** Field for the DesignTheme node this SAVED_THEME [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<DesignTheme>;
};

export type _MergeDesignThemeUsedAsDarkByPayload = {
  __typename?: '_MergeDesignThemeUsedAsDarkByPayload';
  /** Field for the User node this ACTIVE_DARK_THEME [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<User>;
  /** Field for the DesignTheme node this ACTIVE_DARK_THEME [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<DesignTheme>;
};

export type _MergeDesignThemeUsedAsLightByPayload = {
  __typename?: '_MergeDesignThemeUsedAsLightByPayload';
  /** Field for the User node this ACTIVE_LIGHT_THEME [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<User>;
  /** Field for the DesignTheme node this ACTIVE_LIGHT_THEME [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<DesignTheme>;
};

export type _MergeDesignThemeUsedByDashboardsPayload = {
  __typename?: '_MergeDesignThemeUsedByDashboardsPayload';
  /** Field for the DashboardView node this ACTIVE_THEME [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<DashboardView>;
  /** Field for the DesignTheme node this ACTIVE_THEME [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<DesignTheme>;
};

export type _MergeExerciseConceptsCreatedPayload = {
  __typename?: '_MergeExerciseConceptsCreatedPayload';
  /** Field for the Exercise node this SUBMISSION_SPARKED_CONCEPT [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<Exercise>;
  /** Field for the Concept node this SUBMISSION_SPARKED_CONCEPT [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<Concept>;
};

export type _MergeExerciseCreatedByPayload = {
  __typename?: '_MergeExerciseCreatedByPayload';
  /** Field for the Exercise node this EXERCISE_CREATED_BY [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<Exercise>;
  /** Field for the User node this EXERCISE_CREATED_BY [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<User>;
};

export type _MergeExerciseCriteriaPayload = {
  __typename?: '_MergeExerciseCriteriaPayload';
  /** Field for the Exercise node this INCLUDES_CRITERIA [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<Exercise>;
  /** Field for the Criteria node this INCLUDES_CRITERIA [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<Criteria>;
};

export type _MergeExerciseCriteriaScoresPayload = {
  __typename?: '_MergeExerciseCriteriaScoresPayload';
  /** Field for the Exercise node this EXERCISE_HAS_CRITERIA_SCORE [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<Exercise>;
  /** Field for the CriteriaScore node this EXERCISE_HAS_CRITERIA_SCORE [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<CriteriaScore>;
};

export type _MergeExerciseFacilitatorsPayload = {
  __typename?: '_MergeExerciseFacilitatorsPayload';
  /** Field for the Exercise node this EXERCISE_FACILITATED_BY [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<Exercise>;
  /** Field for the User node this EXERCISE_FACILITATED_BY [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<User>;
};

export type _MergeExerciseIncludedChecklistPayload = {
  __typename?: '_MergeExerciseIncludedChecklistPayload';
  /** Field for the Exercise node this EXERCISE_INCLUDES_CHECKLIST [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<Exercise>;
  /** Field for the Checklist node this EXERCISE_INCLUDES_CHECKLIST [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<Checklist>;
};

export type _MergeExerciseIncludedConceptsPayload = {
  __typename?: '_MergeExerciseIncludedConceptsPayload';
  /** Field for the Exercise node this EXERCISE_INCLUDES_CONCEPT [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<Exercise>;
  /** Field for the Concept node this EXERCISE_INCLUDES_CONCEPT [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<Concept>;
};

export type _MergeExerciseInvitedTeamsPayload = {
  __typename?: '_MergeExerciseInvitedTeamsPayload';
  /** Field for the Exercise node this EXERCISE_INVITE_SENT_TO_TEAM [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<Exercise>;
  /** Field for the Team node this EXERCISE_INVITE_SENT_TO_TEAM [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<Team>;
};

export type _MergeExerciseInvitedUsersPayload = {
  __typename?: '_MergeExerciseInvitedUsersPayload';
  /** Field for the Exercise node this EXERCISE_INVITE_SENT_TO_USER [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<Exercise>;
  /** Field for the User node this EXERCISE_INVITE_SENT_TO_USER [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<User>;
};

export type _MergeExerciseParentConceptPayload = {
  __typename?: '_MergeExerciseParentConceptPayload';
  /** Field for the Exercise node this EXERCISE_DONE_FOR [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<Exercise>;
  /** Field for the Concept node this EXERCISE_DONE_FOR [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<Concept>;
};

export type _MergeExerciseParticipantsPayload = {
  __typename?: '_MergeExerciseParticipantsPayload';
  /** Field for the Exercise node this EXERCISE_COMPLETED_BY [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<Exercise>;
  /** Field for the User node this EXERCISE_COMPLETED_BY [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<User>;
};

export type _MergeExerciseScoreConceptPayload = {
  __typename?: '_MergeExerciseScoreConceptPayload';
  /** Field for the Concept node this GIVEN_SCORE_OF [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<Concept>;
  /** Field for the ExerciseScore node this GIVEN_SCORE_OF [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<ExerciseScore>;
};

export type _MergeExerciseScoreSubmissionPayload = {
  __typename?: '_MergeExerciseScoreSubmissionPayload';
  /** Field for the ExerciseSubmission node this CONTAINS_SCORES [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<ExerciseSubmission>;
  /** Field for the ExerciseScore node this CONTAINS_SCORES [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<ExerciseScore>;
};

export type _MergeExerciseSubmissionConceptCreatedPayload = {
  __typename?: '_MergeExerciseSubmissionConceptCreatedPayload';
  /** Field for the ExerciseSubmission node this SUBMISSION_SPARKED_CONCEPT [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<ExerciseSubmission>;
  /** Field for the Concept node this SUBMISSION_SPARKED_CONCEPT [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<Concept>;
};

export type _MergeExerciseSubmissionCriteriaScoresPayload = {
  __typename?: '_MergeExerciseSubmissionCriteriaScoresPayload';
  /** Field for the ExerciseSubmission node this HAS_CRITERIA_SCORE [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<ExerciseSubmission>;
  /** Field for the CriteriaScore node this HAS_CRITERIA_SCORE [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<CriteriaScore>;
};

export type _MergeExerciseSubmissionExercisePayload = {
  __typename?: '_MergeExerciseSubmissionExercisePayload';
  /** Field for the ExerciseSubmission node this EXERCISE_SUBMISSION_TO [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<ExerciseSubmission>;
  /** Field for the Exercise node this EXERCISE_SUBMISSION_TO [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<Exercise>;
};

export type _MergeExerciseSubmissionScoresPayload = {
  __typename?: '_MergeExerciseSubmissionScoresPayload';
  /** Field for the ExerciseSubmission node this CONTAINS_SCORES [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<ExerciseSubmission>;
  /** Field for the ExerciseScore node this CONTAINS_SCORES [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<ExerciseScore>;
};

export type _MergeExerciseSubmissionUserPayload = {
  __typename?: '_MergeExerciseSubmissionUserPayload';
  /** Field for the ExerciseSubmission node this EXERCISE_RESULT_SUBMITTED_BY [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<ExerciseSubmission>;
  /** Field for the User node this EXERCISE_RESULT_SUBMITTED_BY [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<User>;
};

export type _MergeExerciseSubmissionsPayload = {
  __typename?: '_MergeExerciseSubmissionsPayload';
  /** Field for the ExerciseSubmission node this EXERCISE_SUBMISSION_TO [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<ExerciseSubmission>;
  /** Field for the Exercise node this EXERCISE_SUBMISSION_TO [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<Exercise>;
};

export type _MergeExerciseTypeCreatedByPayload = {
  __typename?: '_MergeExerciseTypeCreatedByPayload';
  /** Field for the ExerciseType node this EXERCISE_TYPE_CREATED_BY [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<ExerciseType>;
  /** Field for the User node this EXERCISE_TYPE_CREATED_BY [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<User>;
};

export type _MergeExerciseTypeCriteriaPayload = {
  __typename?: '_MergeExerciseTypeCriteriaPayload';
  /** Field for the ExerciseType node this TYPE_INCLUDES_CRITERIA [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<ExerciseType>;
  /** Field for the Criteria node this TYPE_INCLUDES_CRITERIA [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<Criteria>;
};

export type _MergeExerciseTypeExercisesPayload = {
  __typename?: '_MergeExerciseTypeExercisesPayload';
  /** Field for the Exercise node this EXERCISE_TYPE_OF [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<Exercise>;
  /** Field for the ExerciseType node this EXERCISE_TYPE_OF [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<ExerciseType>;
};

export type _MergeExerciseTypePayload = {
  __typename?: '_MergeExerciseTypePayload';
  /** Field for the Exercise node this EXERCISE_TYPE_OF [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<Exercise>;
  /** Field for the ExerciseType node this EXERCISE_TYPE_OF [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<ExerciseType>;
};

export type _MergeIndustryCompaniesPayload = {
  __typename?: '_MergeIndustryCompaniesPayload';
  /** Field for the Company node this IN_INDUSTRY [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<Company>;
  /** Field for the Industry node this IN_INDUSTRY [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<Industry>;
};

export type _MergeIndustryConceptsPayload = {
  __typename?: '_MergeIndustryConceptsPayload';
  /** Field for the Concept node this IMPACTS_INDUSTRY [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<Concept>;
  /** Field for the Industry node this IMPACTS_INDUSTRY [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<Industry>;
};

export type _MergeInitiativeCommentsPayload = {
  __typename?: '_MergeInitiativeCommentsPayload';
  /** Field for the Comment node this COMMENT_ON [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<Comment>;
  /** Field for the Initiative node this COMMENT_ON [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<Initiative>;
};

export type _MergeInitiativeConnectionsPayload = {
  __typename?: '_MergeInitiativeConnectionsPayload';
  /** Field for the Initiative node this CONNECTED [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<Initiative>;
  /** Field for the Concept node this CONNECTED [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<Concept>;
};

export type _MergeInitiativeLikedByPayload = {
  __typename?: '_MergeInitiativeLikedByPayload';
  /** Field for the User node this LIKED [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<User>;
  /** Field for the Initiative node this LIKED [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<Initiative>;
};

export type _MergeInitiativeTagsPayload = {
  __typename?: '_MergeInitiativeTagsPayload';
  /** Field for the Initiative node this TAGGED [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<Initiative>;
  /** Field for the Tag node this TAGGED [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<Tag>;
};

export type _MergeInitiativeTeamPayload = {
  __typename?: '_MergeInitiativeTeamPayload';
  /** Field for the Team node this TEAM_INITIATIVE [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<Team>;
  /** Field for the Initiative node this TEAM_INITIATIVE [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<Initiative>;
};

export type _MergeInitiativeUserPayload = {
  __typename?: '_MergeInitiativeUserPayload';
  /** Field for the User node this USER_INITIATIVE [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<User>;
  /** Field for the Initiative node this USER_INITIATIVE [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<Initiative>;
};

export type _MergeInputConceptsCreatedPayload = {
  __typename?: '_MergeInputConceptsCreatedPayload';
  /** Field for the Input node this CREATED_VIA_INPUT [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<Input>;
  /** Field for the Concept node this CREATED_VIA_INPUT [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<Concept>;
};

export type _MergeInputCriteriaPayload = {
  __typename?: '_MergeInputCriteriaPayload';
  /** Field for the Input node this INPUT_USES_CRITERIA [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<Input>;
  /** Field for the Criteria node this INPUT_USES_CRITERIA [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<Criteria>;
};

export type _MergeInputCriteriaScoresPayload = {
  __typename?: '_MergeInputCriteriaScoresPayload';
  /** Field for the Input node this INPUT_HAS_CRITERIA_SCORE [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<Input>;
  /** Field for the CriteriaScore node this INPUT_HAS_CRITERIA_SCORE [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<CriteriaScore>;
};

export type _MergeInputIncludedConceptsPayload = {
  __typename?: '_MergeInputIncludedConceptsPayload';
  /** Field for the Input node this INPUT_INCLUDES_CONCEPT [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<Input>;
  /** Field for the Concept node this INPUT_INCLUDES_CONCEPT [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<Concept>;
};

export type _MergeInputParentConceptPayload = {
  __typename?: '_MergeInputParentConceptPayload';
  /** Field for the Input node this INPUT_FOR_CONCEPT [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<Input>;
  /** Field for the Concept node this INPUT_FOR_CONCEPT [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<Concept>;
};

export type _MergeNotificationActionByPayload = {
  __typename?: '_MergeNotificationActionByPayload';
  /** Field for the User node this USER_TRIGGERED_NOTIFICATION [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<User>;
  /** Field for the Notification node this USER_TRIGGERED_NOTIFICATION [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<Notification>;
};

export type _MergeNotificationCommentPayload = {
  __typename?: '_MergeNotificationCommentPayload';
  /** Field for the Comment node this COMMENT_TRIGGERED_NOTIFICATION [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<Comment>;
  /** Field for the Notification node this COMMENT_TRIGGERED_NOTIFICATION [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<Notification>;
};

export type _MergeNotificationConceptPayload = {
  __typename?: '_MergeNotificationConceptPayload';
  /** Field for the Concept node this CONCEPT_TRIGGERED_NOTIFICATION [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<Concept>;
  /** Field for the Notification node this CONCEPT_TRIGGERED_NOTIFICATION [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<Notification>;
};

export type _MergeNotificationUsersPayload = {
  __typename?: '_MergeNotificationUsersPayload';
  /** Field for the Notification node this NOTIFIED [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<Notification>;
  /** Field for the User node this NOTIFIED [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<User>;
};

export type _MergeTagTaggedPayload = {
  __typename?: '_MergeTagTaggedPayload';
  /** Field for the Concept node this TAGGED [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<Concept>;
  /** Field for the Tag node this TAGGED [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<Tag>;
};

export type _MergeTeamAddedToConceptsPayload = {
  __typename?: '_MergeTeamAddedToConceptsPayload';
  /** Field for the Team node this HAS_ACCESS [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<Team>;
  /** Field for the Concept node this HAS_ACCESS [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<Concept>;
  type?: Maybe<AccessType>;
  updatedAt?: Maybe<_Neo4jDateTime>;
  /** Generated field for querying the Neo4j [system id](https://neo4j.com/docs/cypher-manual/current/functions/scalar/#functions-id) of this relationship. */
  _id?: Maybe<Scalars['String']>;
};

export type _MergeTeamAdminPayload = {
  __typename?: '_MergeTeamAdminPayload';
  /** Field for the User node this TEAM_ADMIN [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<User>;
  /** Field for the Team node this TEAM_ADMIN [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<Team>;
};

export type _MergeTeamCompanyPayload = {
  __typename?: '_MergeTeamCompanyPayload';
  /** Field for the Team node this TEAM_AT [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<Team>;
  /** Field for the Company node this TEAM_AT [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<Company>;
};

export type _MergeTeamConceptListsPayload = {
  __typename?: '_MergeTeamConceptListsPayload';
  /** Field for the Team node this TEAM_CONCEPT_LIST [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<Team>;
  /** Field for the ConceptList node this TEAM_CONCEPT_LIST [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<ConceptList>;
};

export type _MergeTeamConceptsPayload = {
  __typename?: '_MergeTeamConceptsPayload';
  /** Field for the Team node this TEAM_CONCEPT [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<Team>;
  /** Field for the Concept node this TEAM_CONCEPT [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<Concept>;
};

export type _MergeTeamExercisesInvitedToPayload = {
  __typename?: '_MergeTeamExercisesInvitedToPayload';
  /** Field for the Exercise node this EXERCISE_INVITE_SENT_TO_TEAM [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<Exercise>;
  /** Field for the Team node this EXERCISE_INVITE_SENT_TO_TEAM [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<Team>;
};

export type _MergeTeamHomeConceptPayload = {
  __typename?: '_MergeTeamHomeConceptPayload';
  /** Field for the Team node this TEAM_HOMEPAGE_CONCEPT [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<Team>;
  /** Field for the Concept node this TEAM_HOMEPAGE_CONCEPT [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<Concept>;
};

export type _MergeTeamLeaderPayload = {
  __typename?: '_MergeTeamLeaderPayload';
  /** Field for the User node this LEADS_TEAM [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<User>;
  /** Field for the Team node this LEADS_TEAM [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<Team>;
};

export type _MergeTeamMembersPayload = {
  __typename?: '_MergeTeamMembersPayload';
  /** Field for the User node this TEAM_MEMBER [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<User>;
  /** Field for the Team node this TEAM_MEMBER [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<Team>;
};

export type _MergeTranslationConceptPayload = {
  __typename?: '_MergeTranslationConceptPayload';
  /** Field for the Concept node this HAS_TRANSLATION [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<Concept>;
  /** Field for the Translation node this HAS_TRANSLATION [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<Translation>;
};

export type _MergeUserActiveDarkThemePayload = {
  __typename?: '_MergeUserActiveDarkThemePayload';
  /** Field for the User node this ACTIVE_DARK_THEME [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<User>;
  /** Field for the DesignTheme node this ACTIVE_DARK_THEME [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<DesignTheme>;
};

export type _MergeUserActiveLightThemePayload = {
  __typename?: '_MergeUserActiveLightThemePayload';
  /** Field for the User node this ACTIVE_LIGHT_THEME [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<User>;
  /** Field for the DesignTheme node this ACTIVE_LIGHT_THEME [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<DesignTheme>;
};

export type _MergeUserAddedToConceptsPayload = {
  __typename?: '_MergeUserAddedToConceptsPayload';
  /** Field for the User node this HAS_ACCESS [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<User>;
  /** Field for the Concept node this HAS_ACCESS [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<Concept>;
  type?: Maybe<AccessType>;
  updatedAt?: Maybe<_Neo4jDateTime>;
  /** Generated field for querying the Neo4j [system id](https://neo4j.com/docs/cypher-manual/current/functions/scalar/#functions-id) of this relationship. */
  _id?: Maybe<Scalars['String']>;
};

export type _MergeUserAdminPayload = {
  __typename?: '_MergeUserAdminPayload';
  /** Field for the User node this TEAM_ADMIN [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<User>;
  /** Field for the Team node this TEAM_ADMIN [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<Team>;
};

export type _MergeUserCommentsPayload = {
  __typename?: '_MergeUserCommentsPayload';
  /** Field for the User node this COMMENTED [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<User>;
  /** Field for the Comment node this COMMENTED [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<Comment>;
};

export type _MergeUserConceptBoardPayload = {
  __typename?: '_MergeUserConceptBoardPayload';
  /** Field for the User node this HAS_CONCEPT_BOARD [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<User>;
  /** Field for the ConceptBoard node this HAS_CONCEPT_BOARD [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<ConceptBoard>;
};

export type _MergeUserConceptListsPayload = {
  __typename?: '_MergeUserConceptListsPayload';
  /** Field for the User node this HAS_CONCEPT_LIST [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<User>;
  /** Field for the ConceptList node this HAS_CONCEPT_LIST [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<ConceptList>;
};

export type _MergeUserConceptsCreatedPayload = {
  __typename?: '_MergeUserConceptsCreatedPayload';
  /** Field for the User node this CREATED [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<User>;
  /** Field for the Concept node this CREATED [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<Concept>;
};

export type _MergeUserCreatedDashboardPayload = {
  __typename?: '_MergeUserCreatedDashboardPayload';
  /** Field for the User node this CREATED_DASHBOARD [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<User>;
  /** Field for the DashboardView node this CREATED_DASHBOARD [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<DashboardView>;
};

export type _MergeUserCreatedThemesPayload = {
  __typename?: '_MergeUserCreatedThemesPayload';
  /** Field for the User node this CREATED_THEME [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<User>;
  /** Field for the DesignTheme node this CREATED_THEME [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<DesignTheme>;
};

export type _MergeUserCriteriaScoresPayload = {
  __typename?: '_MergeUserCriteriaScoresPayload';
  /** Field for the User node this SCORED_BY [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<User>;
  /** Field for the CriteriaScore node this SCORED_BY [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<CriteriaScore>;
};

export type _MergeUserExerciseSubmissionsPayload = {
  __typename?: '_MergeUserExerciseSubmissionsPayload';
  /** Field for the ExerciseSubmission node this EXERCISE_RESULT_SUBMITTED_BY [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<ExerciseSubmission>;
  /** Field for the User node this EXERCISE_RESULT_SUBMITTED_BY [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<User>;
};

export type _MergeUserExerciseTypesCreatedPayload = {
  __typename?: '_MergeUserExerciseTypesCreatedPayload';
  /** Field for the ExerciseType node this EXERCISE_TYPE_CREATED_BY [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<ExerciseType>;
  /** Field for the User node this EXERCISE_TYPE_CREATED_BY [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<User>;
};

export type _MergeUserExercisesCreatedPayload = {
  __typename?: '_MergeUserExercisesCreatedPayload';
  /** Field for the Exercise node this EXERCISE_CREATED_BY [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<Exercise>;
  /** Field for the User node this EXERCISE_CREATED_BY [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<User>;
};

export type _MergeUserExercisesFacilitatedPayload = {
  __typename?: '_MergeUserExercisesFacilitatedPayload';
  /** Field for the Exercise node this EXERCISE_FACILITATED_BY [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<Exercise>;
  /** Field for the User node this EXERCISE_FACILITATED_BY [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<User>;
};

export type _MergeUserExercisesInvitedToPayload = {
  __typename?: '_MergeUserExercisesInvitedToPayload';
  /** Field for the Exercise node this EXERCISE_INVITE_SENT_TO_USER [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<Exercise>;
  /** Field for the User node this EXERCISE_INVITE_SENT_TO_USER [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<User>;
};

export type _MergeUserExercisesParticipatedPayload = {
  __typename?: '_MergeUserExercisesParticipatedPayload';
  /** Field for the Exercise node this EXERCISE_COMPLETED_BY [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<Exercise>;
  /** Field for the User node this EXERCISE_COMPLETED_BY [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<User>;
};

export type _MergeUserHomeConceptPayload = {
  __typename?: '_MergeUserHomeConceptPayload';
  /** Field for the User node this USER_HOME_CONCEPT [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<User>;
  /** Field for the Concept node this USER_HOME_CONCEPT [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<Concept>;
};

export type _MergeUserLeadsPayload = {
  __typename?: '_MergeUserLeadsPayload';
  /** Field for the User node this LEADS_TEAM [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<User>;
  /** Field for the Team node this LEADS_TEAM [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<Team>;
};

export type _MergeUserLikedPayload = {
  __typename?: '_MergeUserLikedPayload';
  /** Field for the User node this LIKED [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<User>;
  /** Field for the Concept node this LIKED [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<Concept>;
};

export type _MergeUserNotificationsPayload = {
  __typename?: '_MergeUserNotificationsPayload';
  /** Field for the Notification node this NOTIFIED [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<Notification>;
  /** Field for the User node this NOTIFIED [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<User>;
};

export type _MergeUserNotificationsTriggeredPayload = {
  __typename?: '_MergeUserNotificationsTriggeredPayload';
  /** Field for the User node this USER_TRIGGERED_NOTIFICATION [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<User>;
  /** Field for the User node this USER_TRIGGERED_NOTIFICATION [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<User>;
};

export type _MergeUserRecentUpdatesPayload = {
  __typename?: '_MergeUserRecentUpdatesPayload';
  /** Field for the User node this UPDATED [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<User>;
  /** Field for the Concept node this UPDATED [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<Concept>;
  updatedAt?: Maybe<_Neo4jDateTime>;
  updateValue?: Maybe<Scalars['String']>;
  /** Generated field for querying the Neo4j [system id](https://neo4j.com/docs/cypher-manual/current/functions/scalar/#functions-id) of this relationship. */
  _id?: Maybe<Scalars['String']>;
};

export type _MergeUserSavedDashboardsPayload = {
  __typename?: '_MergeUserSavedDashboardsPayload';
  /** Field for the User node this SAVED_DASHBOARD [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<User>;
  /** Field for the DashboardView node this SAVED_DASHBOARD [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<DashboardView>;
};

export type _MergeUserSavedThemesPayload = {
  __typename?: '_MergeUserSavedThemesPayload';
  /** Field for the User node this SAVED_THEME [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<User>;
  /** Field for the DesignTheme node this SAVED_THEME [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<DesignTheme>;
};

export type _MergeUserTeamsPayload = {
  __typename?: '_MergeUserTeamsPayload';
  /** Field for the User node this TEAM_MEMBER [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<User>;
  /** Field for the Team node this TEAM_MEMBER [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<Team>;
};

export type _MergeUserWatchedConceptsPayload = {
  __typename?: '_MergeUserWatchedConceptsPayload';
  /** Field for the User node this WATCHING [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<User>;
  /** Field for the Concept node this WATCHING [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<Concept>;
};

export type _MergeUserWorkspaceInboxPayload = {
  __typename?: '_MergeUserWorkspaceInboxPayload';
  /** Field for the User node this HAS_WORKSPACE_INBOX [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<User>;
  /** Field for the ConceptList node this HAS_WORKSPACE_INBOX [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<ConceptList>;
};

export type _MergeWidgetDashboardsPayload = {
  __typename?: '_MergeWidgetDashboardsPayload';
  /** Field for the DashboardView node this HAS_WIDGET [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<DashboardView>;
  /** Field for the Widget node this HAS_WIDGET [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<Widget>;
};

export type _MergeWidgetTypePayload = {
  __typename?: '_MergeWidgetTypePayload';
  /** Field for the Widget node this WIDGET_TYPE_OF [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<Widget>;
  /** Field for the WidgetType node this WIDGET_TYPE_OF [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<WidgetType>;
};

export type _MergeWidgetTypeWidgetsPayload = {
  __typename?: '_MergeWidgetTypeWidgetsPayload';
  /** Field for the Widget node this WIDGET_TYPE_OF [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<Widget>;
  /** Field for the WidgetType node this WIDGET_TYPE_OF [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<WidgetType>;
};

export type _MergeWorkflowCategoriesPayload = {
  __typename?: '_MergeWorkflowCategoriesPayload';
  /** Field for the Workflow node this DEFAULT_WORKFLOW_FOR_CATEGORY [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<Workflow>;
  /** Field for the Category node this DEFAULT_WORKFLOW_FOR_CATEGORY [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<Category>;
};

export type _MergeWorkflowStageLastOptionsPayload = {
  __typename?: '_MergeWorkflowStageLastOptionsPayload';
  /** Field for the WorkflowStage node this NEXT_STAGE [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<WorkflowStage>;
  /** Field for the WorkflowStage node this NEXT_STAGE [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<WorkflowStage>;
};

export type _MergeWorkflowStageNextOptionsPayload = {
  __typename?: '_MergeWorkflowStageNextOptionsPayload';
  /** Field for the WorkflowStage node this NEXT_STAGE [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<WorkflowStage>;
  /** Field for the WorkflowStage node this NEXT_STAGE [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<WorkflowStage>;
};

export type _MergeWorkflowStageStatusesPayload = {
  __typename?: '_MergeWorkflowStageStatusesPayload';
  /** Field for the ConceptStatus node this HAS_WORKFLOW_STAGE [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<ConceptStatus>;
  /** Field for the WorkflowStage node this HAS_WORKFLOW_STAGE [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<WorkflowStage>;
};

export type _MergeWorkflowStageWorkflowPayload = {
  __typename?: '_MergeWorkflowStageWorkflowPayload';
  /** Field for the Workflow node this WORKFLOW_STAGE [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<Workflow>;
  /** Field for the WorkflowStage node this WORKFLOW_STAGE [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<WorkflowStage>;
};

export type _MergeWorkflowStagesPayload = {
  __typename?: '_MergeWorkflowStagesPayload';
  /** Field for the Workflow node this WORKFLOW_STAGE [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<Workflow>;
  /** Field for the WorkflowStage node this WORKFLOW_STAGE [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<WorkflowStage>;
};

/** Generated Date object type for Neo4j [Temporal fields](https://grandstack.io/docs/graphql-temporal-types-datetime#using-temporal-fields-in-queries). */
export type _Neo4jDate = {
  __typename?: '_Neo4jDate';
  year?: Maybe<Scalars['Int']>;
  month?: Maybe<Scalars['Int']>;
  day?: Maybe<Scalars['Int']>;
  /** Outputs a Neo4j [Temporal](https://grandstack.io/docs/graphql-temporal-types-datetime#using-temporal-fields-in-queries) Date value as a String type by using the [toString](https://neo4j.com/docs/cypher-manual/current/functions/string/#functions-tostring) Cypher function. */
  formatted?: Maybe<Scalars['String']>;
};

/** Generated Date input object for Neo4j [Temporal field arguments](https://grandstack.io/docs/graphql-temporal-types-datetime/#temporal-query-arguments). */
export type _Neo4jDateInput = {
  year?: Maybe<Scalars['Int']>;
  month?: Maybe<Scalars['Int']>;
  day?: Maybe<Scalars['Int']>;
  /** Creates a Neo4j [Temporal](https://grandstack.io/docs/graphql-temporal-types-datetime/#using-temporal-fields-in-mutations) Date value using a [String format](https://neo4j.com/docs/cypher-manual/current/functions/temporal/date/#functions-date-create-string). */
  formatted?: Maybe<Scalars['String']>;
};

/** Generated DateTime object type for Neo4j [Temporal fields](https://grandstack.io/docs/graphql-temporal-types-datetime#using-temporal-fields-in-queries). */
export type _Neo4jDateTime = {
  __typename?: '_Neo4jDateTime';
  year?: Maybe<Scalars['Int']>;
  month?: Maybe<Scalars['Int']>;
  day?: Maybe<Scalars['Int']>;
  hour?: Maybe<Scalars['Int']>;
  minute?: Maybe<Scalars['Int']>;
  second?: Maybe<Scalars['Int']>;
  millisecond?: Maybe<Scalars['Int']>;
  microsecond?: Maybe<Scalars['Int']>;
  nanosecond?: Maybe<Scalars['Int']>;
  timezone?: Maybe<Scalars['String']>;
  /** Outputs a Neo4j [Temporal](https://grandstack.io/docs/graphql-temporal-types-datetime#using-temporal-fields-in-queries) DateTime value as a String type by using the [toString](https://neo4j.com/docs/cypher-manual/current/functions/string/#functions-tostring) Cypher function. */
  formatted?: Maybe<Scalars['String']>;
};

/** Generated DateTime input object for Neo4j [Temporal field arguments](https://grandstack.io/docs/graphql-temporal-types-datetime/#temporal-query-arguments). */
export type _Neo4jDateTimeInput = {
  year?: Maybe<Scalars['Int']>;
  month?: Maybe<Scalars['Int']>;
  day?: Maybe<Scalars['Int']>;
  hour?: Maybe<Scalars['Int']>;
  minute?: Maybe<Scalars['Int']>;
  second?: Maybe<Scalars['Int']>;
  millisecond?: Maybe<Scalars['Int']>;
  microsecond?: Maybe<Scalars['Int']>;
  nanosecond?: Maybe<Scalars['Int']>;
  timezone?: Maybe<Scalars['String']>;
  /** Creates a Neo4j [Temporal](https://grandstack.io/docs/graphql-temporal-types-datetime/#using-temporal-fields-in-mutations) DateTime value using a [String format](https://neo4j.com/docs/cypher-manual/current/functions/temporal/datetime/#functions-datetime-create-string). */
  formatted?: Maybe<Scalars['String']>;
};

/** Generated LocalDateTime object type for Neo4j [Temporal fields](https://grandstack.io/docs/graphql-temporal-types-datetime#using-temporal-fields-in-queries). */
export type _Neo4jLocalDateTime = {
  __typename?: '_Neo4jLocalDateTime';
  year?: Maybe<Scalars['Int']>;
  month?: Maybe<Scalars['Int']>;
  day?: Maybe<Scalars['Int']>;
  hour?: Maybe<Scalars['Int']>;
  minute?: Maybe<Scalars['Int']>;
  second?: Maybe<Scalars['Int']>;
  millisecond?: Maybe<Scalars['Int']>;
  microsecond?: Maybe<Scalars['Int']>;
  nanosecond?: Maybe<Scalars['Int']>;
  /** Outputs a Neo4j [Temporal](https://grandstack.io/docs/graphql-temporal-types-datetime#using-temporal-fields-in-queries) LocalDateTime value as a String type by using the [toString](https://neo4j.com/docs/cypher-manual/current/functions/string/#functions-tostring) Cypher function. */
  formatted?: Maybe<Scalars['String']>;
};

/** Generated LocalDateTime input object for Neo4j [Temporal field arguments](https://grandstack.io/docs/graphql-temporal-types-datetime/#temporal-query-arguments). */
export type _Neo4jLocalDateTimeInput = {
  year?: Maybe<Scalars['Int']>;
  month?: Maybe<Scalars['Int']>;
  day?: Maybe<Scalars['Int']>;
  hour?: Maybe<Scalars['Int']>;
  minute?: Maybe<Scalars['Int']>;
  second?: Maybe<Scalars['Int']>;
  millisecond?: Maybe<Scalars['Int']>;
  microsecond?: Maybe<Scalars['Int']>;
  nanosecond?: Maybe<Scalars['Int']>;
  /** Creates a Neo4j [Temporal](https://grandstack.io/docs/graphql-temporal-types-datetime/#using-temporal-fields-in-mutations) LocalDateTime value using a [String format](https://neo4j.com/docs/cypher-manual/current/functions/temporal/localdatetime/#functions-localdatetime-create-string). */
  formatted?: Maybe<Scalars['String']>;
};

/** Generated LocalTime object type for Neo4j [Temporal fields](https://grandstack.io/docs/graphql-temporal-types-datetime#using-temporal-fields-in-queries). */
export type _Neo4jLocalTime = {
  __typename?: '_Neo4jLocalTime';
  hour?: Maybe<Scalars['Int']>;
  minute?: Maybe<Scalars['Int']>;
  second?: Maybe<Scalars['Int']>;
  millisecond?: Maybe<Scalars['Int']>;
  microsecond?: Maybe<Scalars['Int']>;
  nanosecond?: Maybe<Scalars['Int']>;
  /** Outputs a Neo4j [Temporal](https://grandstack.io/docs/graphql-temporal-types-datetime#using-temporal-fields-in-queries) LocalTime value as a String type by using the [toString](https://neo4j.com/docs/cypher-manual/current/functions/string/#functions-tostring) Cypher function. */
  formatted?: Maybe<Scalars['String']>;
};

/** Generated LocalTime input object for Neo4j [Temporal field arguments](https://grandstack.io/docs/graphql-temporal-types-datetime/#temporal-query-arguments). */
export type _Neo4jLocalTimeInput = {
  hour?: Maybe<Scalars['Int']>;
  minute?: Maybe<Scalars['Int']>;
  second?: Maybe<Scalars['Int']>;
  millisecond?: Maybe<Scalars['Int']>;
  microsecond?: Maybe<Scalars['Int']>;
  nanosecond?: Maybe<Scalars['Int']>;
  /** Creates a Neo4j [Temporal](https://grandstack.io/docs/graphql-temporal-types-datetime/#using-temporal-fields-in-mutations) LocalTime value using a [String format](https://neo4j.com/docs/cypher-manual/current/functions/temporal/localtime/#functions-localtime-create-string). */
  formatted?: Maybe<Scalars['String']>;
};

/** Generated Point object type for Neo4j [Spatial fields](https://grandstack.io/docs/graphql-spatial-types#using-point-in-queries). */
export type _Neo4jPoint = {
  __typename?: '_Neo4jPoint';
  x?: Maybe<Scalars['Float']>;
  y?: Maybe<Scalars['Float']>;
  z?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
  latitude?: Maybe<Scalars['Float']>;
  height?: Maybe<Scalars['Float']>;
  crs?: Maybe<Scalars['String']>;
  srid?: Maybe<Scalars['Int']>;
};

export type _Neo4jPointDistanceFilter = {
  point: _Neo4jPointInput;
  distance: Scalars['Float'];
};

/** Generated Point input object for Neo4j [Spatial field arguments](https://grandstack.io/docs/graphql-spatial-types/#point-query-arguments). */
export type _Neo4jPointInput = {
  x?: Maybe<Scalars['Float']>;
  y?: Maybe<Scalars['Float']>;
  z?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
  latitude?: Maybe<Scalars['Float']>;
  height?: Maybe<Scalars['Float']>;
  crs?: Maybe<Scalars['String']>;
  srid?: Maybe<Scalars['Int']>;
};

/** Generated Time object type for Neo4j [Temporal fields](https://grandstack.io/docs/graphql-temporal-types-datetime#using-temporal-fields-in-queries). */
export type _Neo4jTime = {
  __typename?: '_Neo4jTime';
  hour?: Maybe<Scalars['Int']>;
  minute?: Maybe<Scalars['Int']>;
  second?: Maybe<Scalars['Int']>;
  millisecond?: Maybe<Scalars['Int']>;
  microsecond?: Maybe<Scalars['Int']>;
  nanosecond?: Maybe<Scalars['Int']>;
  timezone?: Maybe<Scalars['String']>;
  /** Outputs a Neo4j [Temporal](https://grandstack.io/docs/graphql-temporal-types-datetime#using-temporal-fields-in-queries) Time value as a String type by using the [toString](https://neo4j.com/docs/cypher-manual/current/functions/string/#functions-tostring) Cypher function. */
  formatted?: Maybe<Scalars['String']>;
};

/** Generated Time input object for Neo4j [Temporal field arguments](https://grandstack.io/docs/graphql-temporal-types-datetime/#temporal-query-arguments). */
export type _Neo4jTimeInput = {
  hour?: Maybe<Scalars['Int']>;
  minute?: Maybe<Scalars['Int']>;
  second?: Maybe<Scalars['Int']>;
  millisecond?: Maybe<Scalars['Int']>;
  microsecond?: Maybe<Scalars['Int']>;
  nanosecond?: Maybe<Scalars['Int']>;
  timezone?: Maybe<Scalars['String']>;
  /** Creates a Neo4j [Temporal](https://grandstack.io/docs/graphql-temporal-types-datetime/#using-temporal-fields-in-mutations) Time value using a [String format](https://neo4j.com/docs/cypher-manual/current/functions/temporal/time/#functions-time-create-string). */
  formatted?: Maybe<Scalars['String']>;
};

export type _NotificationFilter = {
  AND?: Maybe<Array<_NotificationFilter>>;
  OR?: Maybe<Array<_NotificationFilter>>;
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Scalars['ID']>>;
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  id_regexp?: Maybe<Scalars['ID']>;
  id_contains?: Maybe<Scalars['ID']>;
  id_not_contains?: Maybe<Scalars['ID']>;
  id_starts_with?: Maybe<Scalars['ID']>;
  id_not_starts_with?: Maybe<Scalars['ID']>;
  id_ends_with?: Maybe<Scalars['ID']>;
  id_not_ends_with?: Maybe<Scalars['ID']>;
  content?: Maybe<Scalars['String']>;
  content_not?: Maybe<Scalars['String']>;
  content_in?: Maybe<Array<Scalars['String']>>;
  content_not_in?: Maybe<Array<Scalars['String']>>;
  content_regexp?: Maybe<Scalars['String']>;
  content_contains?: Maybe<Scalars['String']>;
  content_not_contains?: Maybe<Scalars['String']>;
  content_starts_with?: Maybe<Scalars['String']>;
  content_not_starts_with?: Maybe<Scalars['String']>;
  content_ends_with?: Maybe<Scalars['String']>;
  content_not_ends_with?: Maybe<Scalars['String']>;
  type?: Maybe<Notification_Type>;
  type_not?: Maybe<Notification_Type>;
  type_in?: Maybe<Array<Notification_Type>>;
  type_not_in?: Maybe<Array<Notification_Type>>;
  createdAt?: Maybe<_Neo4jDateTimeInput>;
  createdAt_not?: Maybe<_Neo4jDateTimeInput>;
  createdAt_in?: Maybe<Array<_Neo4jDateTimeInput>>;
  createdAt_not_in?: Maybe<Array<_Neo4jDateTimeInput>>;
  createdAt_lt?: Maybe<_Neo4jDateTimeInput>;
  createdAt_lte?: Maybe<_Neo4jDateTimeInput>;
  createdAt_gt?: Maybe<_Neo4jDateTimeInput>;
  createdAt_gte?: Maybe<_Neo4jDateTimeInput>;
  actionBy?: Maybe<_UserFilter>;
  actionBy_not?: Maybe<_UserFilter>;
  actionBy_in?: Maybe<Array<_UserFilter>>;
  actionBy_not_in?: Maybe<Array<_UserFilter>>;
  concept?: Maybe<_ConceptFilter>;
  concept_not?: Maybe<_ConceptFilter>;
  concept_in?: Maybe<Array<_ConceptFilter>>;
  concept_not_in?: Maybe<Array<_ConceptFilter>>;
  comment?: Maybe<_CommentFilter>;
  comment_not?: Maybe<_CommentFilter>;
  comment_in?: Maybe<Array<_CommentFilter>>;
  comment_not_in?: Maybe<Array<_CommentFilter>>;
  users?: Maybe<_UserFilter>;
  users_not?: Maybe<_UserFilter>;
  users_in?: Maybe<Array<_UserFilter>>;
  users_not_in?: Maybe<Array<_UserFilter>>;
  users_some?: Maybe<_UserFilter>;
  users_none?: Maybe<_UserFilter>;
  users_single?: Maybe<_UserFilter>;
  users_every?: Maybe<_UserFilter>;
};

export type _NotificationInput = {
  id: Scalars['ID'];
};

export enum _NotificationOrdering {
  ID_ASC = 'id_asc',
  ID_DESC = 'id_desc',
  CONTENT_ASC = 'content_asc',
  CONTENT_DESC = 'content_desc',
  TYPE_ASC = 'type_asc',
  TYPE_DESC = 'type_desc',
  CREATEDAT_ASC = 'createdAt_asc',
  CREATEDAT_DESC = 'createdAt_desc',
  _ID_ASC = '_id_asc',
  _ID_DESC = '_id_desc'
}

export enum _RelationDirections {
  IN = 'IN',
  OUT = 'OUT'
}

export type _RemoveCategoryChildrenPayload = {
  __typename?: '_RemoveCategoryChildrenPayload';
  /** Field for the Category node this TYPE_OF [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<Category>;
  /** Field for the Category node this TYPE_OF [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<Category>;
};

export type _RemoveCategoryConceptsPayload = {
  __typename?: '_RemoveCategoryConceptsPayload';
  /** Field for the Concept node this IN_CATEGORY [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<Concept>;
  /** Field for the Category node this IN_CATEGORY [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<Category>;
};

export type _RemoveCategoryCriteriaPayload = {
  __typename?: '_RemoveCategoryCriteriaPayload';
  /** Field for the Category node this CATEGORY_USES_CRITERIA [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<Category>;
  /** Field for the Criteria node this CATEGORY_USES_CRITERIA [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<Criteria>;
};

export type _RemoveCategoryDefaultCriteriaPayload = {
  __typename?: '_RemoveCategoryDefaultCriteriaPayload';
  /** Field for the Category node this HAS_DEFAULT_CRITERIA [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<Category>;
  /** Field for the Criteria node this HAS_DEFAULT_CRITERIA [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<Criteria>;
};

export type _RemoveCategoryDefaultDashboardPayload = {
  __typename?: '_RemoveCategoryDefaultDashboardPayload';
  /** Field for the Category node this USES_DEFAULT_DASHBOARD [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<Category>;
  /** Field for the DashboardView node this USES_DEFAULT_DASHBOARD [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<DashboardView>;
};

export type _RemoveCategoryParentPayload = {
  __typename?: '_RemoveCategoryParentPayload';
  /** Field for the Category node this TYPE_OF [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<Category>;
  /** Field for the Category node this TYPE_OF [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<Category>;
};

export type _RemoveCategoryWorkflowPayload = {
  __typename?: '_RemoveCategoryWorkflowPayload';
  /** Field for the Workflow node this DEFAULT_WORKFLOW_FOR_CATEGORY [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<Workflow>;
  /** Field for the Category node this DEFAULT_WORKFLOW_FOR_CATEGORY [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<Category>;
};

export type _RemoveChecklistConceptPayload = {
  __typename?: '_RemoveChecklistConceptPayload';
  /** Field for the Concept node this CONCEPT_CHECKLIST [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<Concept>;
  /** Field for the Checklist node this CONCEPT_CHECKLIST [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<Checklist>;
};

export type _RemoveChecklistCreatedByPayload = {
  __typename?: '_RemoveChecklistCreatedByPayload';
  /** Field for the User node this CREATED [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<User>;
  /** Field for the Checklist node this CREATED [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<Checklist>;
};

export type _RemoveChecklistExercisePayload = {
  __typename?: '_RemoveChecklistExercisePayload';
  /** Field for the Exercise node this EXERCISE_INCLUDES_CHECKLIST [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<Exercise>;
  /** Field for the Checklist node this EXERCISE_INCLUDES_CHECKLIST [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<Checklist>;
};

export type _RemoveChecklistItemChecklistPayload = {
  __typename?: '_RemoveChecklistItemChecklistPayload';
  /** Field for the Checklist node this HAS_LIST_ITEM [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<Checklist>;
  /** Field for the ChecklistItem node this HAS_LIST_ITEM [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<ChecklistItem>;
};

export type _RemoveChecklistItemChildrenPayload = {
  __typename?: '_RemoveChecklistItemChildrenPayload';
  /** Field for the ChecklistItem node this CL_ITEM_CHILD_OF [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<ChecklistItem>;
  /** Field for the ChecklistItem node this CL_ITEM_CHILD_OF [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<ChecklistItem>;
};

export type _RemoveChecklistItemConceptPayload = {
  __typename?: '_RemoveChecklistItemConceptPayload';
  /** Field for the ChecklistItem node this CL_ITEM_LINKED_CONCEPT [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<ChecklistItem>;
  /** Field for the Concept node this CL_ITEM_LINKED_CONCEPT [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<Concept>;
};

export type _RemoveChecklistItemCreatedByPayload = {
  __typename?: '_RemoveChecklistItemCreatedByPayload';
  /** Field for the User node this CREATED [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<User>;
  /** Field for the ChecklistItem node this CREATED [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<ChecklistItem>;
};

export type _RemoveChecklistItemParentPayload = {
  __typename?: '_RemoveChecklistItemParentPayload';
  /** Field for the ChecklistItem node this CL_ITEM_CHILD_OF [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<ChecklistItem>;
  /** Field for the ChecklistItem node this CL_ITEM_CHILD_OF [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<ChecklistItem>;
};

export type _RemoveChecklistItemsPayload = {
  __typename?: '_RemoveChecklistItemsPayload';
  /** Field for the Checklist node this HAS_LIST_ITEM [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<Checklist>;
  /** Field for the ChecklistItem node this HAS_LIST_ITEM [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<ChecklistItem>;
};

export type _RemoveChecklistTypeChecklistsPayload = {
  __typename?: '_RemoveChecklistTypeChecklistsPayload';
  /** Field for the Checklist node this CHECKLIST_TYPE [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<Checklist>;
  /** Field for the ChecklistType node this CHECKLIST_TYPE [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<ChecklistType>;
};

export type _RemoveChecklistTypePayload = {
  __typename?: '_RemoveChecklistTypePayload';
  /** Field for the Checklist node this CHECKLIST_TYPE [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<Checklist>;
  /** Field for the ChecklistType node this CHECKLIST_TYPE [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<ChecklistType>;
};

export type _RemoveCommentConceptPayload = {
  __typename?: '_RemoveCommentConceptPayload';
  /** Field for the Comment node this COMMENT_ON [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<Comment>;
  /** Field for the Concept node this COMMENT_ON [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<Concept>;
};

export type _RemoveCommentLikedByPayload = {
  __typename?: '_RemoveCommentLikedByPayload';
  /** Field for the User node this LIKED_COMMENT [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<User>;
  /** Field for the Comment node this LIKED_COMMENT [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<Comment>;
};

export type _RemoveCommentNotificationsPayload = {
  __typename?: '_RemoveCommentNotificationsPayload';
  /** Field for the Comment node this COMMENT_TRIGGERED_NOTIFICATION [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<Comment>;
  /** Field for the Notification node this COMMENT_TRIGGERED_NOTIFICATION [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<Notification>;
};

export type _RemoveCommentRepliesPayload = {
  __typename?: '_RemoveCommentRepliesPayload';
  /** Field for the Comment node this REPLIED [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<Comment>;
  /** Field for the Comment node this REPLIED [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<Comment>;
};

export type _RemoveCommentReplyToPayload = {
  __typename?: '_RemoveCommentReplyToPayload';
  /** Field for the Comment node this REPLIED [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<Comment>;
  /** Field for the Comment node this REPLIED [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<Comment>;
};

export type _RemoveCommentUserPayload = {
  __typename?: '_RemoveCommentUserPayload';
  /** Field for the User node this COMMENTED [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<User>;
  /** Field for the Comment node this COMMENTED [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<Comment>;
};

export type _RemoveCompanyEmployeesPayload = {
  __typename?: '_RemoveCompanyEmployeesPayload';
  /** Field for the User node this WORKS_AT [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<User>;
  /** Field for the Company node this WORKS_AT [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<Company>;
};

export type _RemoveCompanyIndustriesPayload = {
  __typename?: '_RemoveCompanyIndustriesPayload';
  /** Field for the Company node this IN_INDUSTRY [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<Company>;
  /** Field for the Industry node this IN_INDUSTRY [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<Industry>;
};

export type _RemoveCompanyTeamsPayload = {
  __typename?: '_RemoveCompanyTeamsPayload';
  /** Field for the Team node this TEAM_AT [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<Team>;
  /** Field for the Company node this TEAM_AT [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<Company>;
};

export type _RemoveConceptAddedTeamsPayload = {
  __typename?: '_RemoveConceptAddedTeamsPayload';
  /** Field for the Team node this HAS_ACCESS [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<Team>;
  /** Field for the Concept node this HAS_ACCESS [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<Concept>;
};

export type _RemoveConceptAddedUsersPayload = {
  __typename?: '_RemoveConceptAddedUsersPayload';
  /** Field for the User node this HAS_ACCESS [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<User>;
  /** Field for the Concept node this HAS_ACCESS [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<Concept>;
};

export type _RemoveConceptBoardColumnsPayload = {
  __typename?: '_RemoveConceptBoardColumnsPayload';
  /** Field for the ConceptList node this ON_BOARD [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<ConceptList>;
  /** Field for the ConceptBoard node this ON_BOARD [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<ConceptBoard>;
};

export type _RemoveConceptBoardUserPayload = {
  __typename?: '_RemoveConceptBoardUserPayload';
  /** Field for the User node this HAS_CONCEPT_BOARD [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<User>;
  /** Field for the ConceptBoard node this HAS_CONCEPT_BOARD [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<ConceptBoard>;
};

export type _RemoveConceptCategoryPayload = {
  __typename?: '_RemoveConceptCategoryPayload';
  /** Field for the Concept node this IN_CATEGORY [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<Concept>;
  /** Field for the Category node this IN_CATEGORY [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<Category>;
};

export type _RemoveConceptChecklistItemLinkPayload = {
  __typename?: '_RemoveConceptChecklistItemLinkPayload';
  /** Field for the ChecklistItem node this CL_ITEM_LINKED_CONCEPT [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<ChecklistItem>;
  /** Field for the Concept node this CL_ITEM_LINKED_CONCEPT [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<Concept>;
};

export type _RemoveConceptChecklistsPayload = {
  __typename?: '_RemoveConceptChecklistsPayload';
  /** Field for the Concept node this CONCEPT_CHECKLIST [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<Concept>;
  /** Field for the Checklist node this CONCEPT_CHECKLIST [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<Checklist>;
};

export type _RemoveConceptChildConceptListsPayload = {
  __typename?: '_RemoveConceptChildConceptListsPayload';
  /** Field for the Concept node this CHILD_CONCEPT_LIST [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<Concept>;
  /** Field for the ConceptList node this CHILD_CONCEPT_LIST [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<ConceptList>;
};

export type _RemoveConceptChildrenPayload = {
  __typename?: '_RemoveConceptChildrenPayload';
  /** Field for the Concept node this CHILD_OF [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<Concept>;
  /** Field for the Concept node this CHILD_OF [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<Concept>;
};

export type _RemoveConceptCommentsPayload = {
  __typename?: '_RemoveConceptCommentsPayload';
  /** Field for the Comment node this COMMENT_ON [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<Comment>;
  /** Field for the Concept node this COMMENT_ON [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<Concept>;
};

export type _RemoveConceptConnectionsPayload = {
  __typename?: '_RemoveConceptConnectionsPayload';
  /** Field for the Concept node this CONNECTED [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<Concept>;
  /** Field for the Concept node this CONNECTED [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<Concept>;
};

export type _RemoveConceptCreatedByPayload = {
  __typename?: '_RemoveConceptCreatedByPayload';
  /** Field for the User node this CREATED [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<User>;
  /** Field for the Concept node this CREATED [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<Concept>;
};

export type _RemoveConceptCreatedForExerciseSubmissionPayload = {
  __typename?: '_RemoveConceptCreatedForExerciseSubmissionPayload';
  /** Field for the ExerciseSubmission node this SUBMISSION_SPARKED_CONCEPT [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<ExerciseSubmission>;
  /** Field for the Concept node this SUBMISSION_SPARKED_CONCEPT [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<Concept>;
};

export type _RemoveConceptCreatedViaInputPayload = {
  __typename?: '_RemoveConceptCreatedViaInputPayload';
  /** Field for the Input node this CREATED_VIA_INPUT [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<Input>;
  /** Field for the Concept node this CREATED_VIA_INPUT [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<Concept>;
};

export type _RemoveConceptCriteriaPayload = {
  __typename?: '_RemoveConceptCriteriaPayload';
  /** Field for the Concept node this HAS_CRITERIA [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<Concept>;
  /** Field for the Criteria node this HAS_CRITERIA [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<Criteria>;
};

export type _RemoveConceptCriteriaScoresPayload = {
  __typename?: '_RemoveConceptCriteriaScoresPayload';
  /** Field for the CriteriaScore node this SCORE_FOR_CONCEPT [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<CriteriaScore>;
  /** Field for the Concept node this SCORE_FOR_CONCEPT [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<Concept>;
};

export type _RemoveConceptDetailedConnectionsPayload = {
  __typename?: '_RemoveConceptDetailedConnectionsPayload';
  /** Field for the Concept node this CONNECTED [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<Concept>;
  /** Field for the Concept node this CONNECTED [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<Concept>;
};

export type _RemoveConceptExercisesCreatedPayload = {
  __typename?: '_RemoveConceptExercisesCreatedPayload';
  /** Field for the Exercise node this EXERCISE_DONE_FOR [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<Exercise>;
  /** Field for the Concept node this EXERCISE_DONE_FOR [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<Concept>;
};

export type _RemoveConceptExercisesIncludingPayload = {
  __typename?: '_RemoveConceptExercisesIncludingPayload';
  /** Field for the Exercise node this EXERCISE_INCLUDES_CONCEPT [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<Exercise>;
  /** Field for the Concept node this EXERCISE_INCLUDES_CONCEPT [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<Concept>;
};

export type _RemoveConceptHomeDashboardPayload = {
  __typename?: '_RemoveConceptHomeDashboardPayload';
  /** Field for the Concept node this HAS_HOME_DASHBOARD [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<Concept>;
  /** Field for the DashboardView node this HAS_HOME_DASHBOARD [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<DashboardView>;
};

export type _RemoveConceptInListsPayload = {
  __typename?: '_RemoveConceptInListsPayload';
  /** Field for the Concept node this IN_LIST [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<Concept>;
  /** Field for the ConceptList node this IN_LIST [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<ConceptList>;
};

export type _RemoveConceptIncludedForInputsPayload = {
  __typename?: '_RemoveConceptIncludedForInputsPayload';
  /** Field for the Input node this INPUT_INCLUDES_CONCEPT [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<Input>;
  /** Field for the Concept node this INPUT_INCLUDES_CONCEPT [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<Concept>;
};

export type _RemoveConceptIndustriesPayload = {
  __typename?: '_RemoveConceptIndustriesPayload';
  /** Field for the Concept node this IMPACTS_INDUSTRY [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<Concept>;
  /** Field for the Industry node this IMPACTS_INDUSTRY [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<Industry>;
};

export type _RemoveConceptInputsPayload = {
  __typename?: '_RemoveConceptInputsPayload';
  /** Field for the Input node this INPUT_FOR_CONCEPT [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<Input>;
  /** Field for the Concept node this INPUT_FOR_CONCEPT [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<Concept>;
};

export type _RemoveConceptLikedByPayload = {
  __typename?: '_RemoveConceptLikedByPayload';
  /** Field for the User node this LIKED [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<User>;
  /** Field for the Concept node this LIKED [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<Concept>;
};

export type _RemoveConceptListConceptBoardPayload = {
  __typename?: '_RemoveConceptListConceptBoardPayload';
  /** Field for the ConceptList node this ON_BOARD [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<ConceptList>;
  /** Field for the ConceptBoard node this ON_BOARD [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<ConceptBoard>;
};

export type _RemoveConceptListConceptsPayload = {
  __typename?: '_RemoveConceptListConceptsPayload';
  /** Field for the Concept node this IN_LIST [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<Concept>;
  /** Field for the ConceptList node this IN_LIST [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<ConceptList>;
};

export type _RemoveConceptListInboxUserPayload = {
  __typename?: '_RemoveConceptListInboxUserPayload';
  /** Field for the User node this HAS_WORKSPACE_INBOX [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<User>;
  /** Field for the ConceptList node this HAS_WORKSPACE_INBOX [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<ConceptList>;
};

export type _RemoveConceptListParentConceptPayload = {
  __typename?: '_RemoveConceptListParentConceptPayload';
  /** Field for the Concept node this CHILD_CONCEPT_LIST [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<Concept>;
  /** Field for the ConceptList node this CHILD_CONCEPT_LIST [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<ConceptList>;
};

export type _RemoveConceptListPersonaPayload = {
  __typename?: '_RemoveConceptListPersonaPayload';
  /** Field for the Concept node this PERSONA_REQUIREMENTS [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<Concept>;
  /** Field for the ConceptList node this PERSONA_REQUIREMENTS [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<ConceptList>;
};

export type _RemoveConceptListTeamPayload = {
  __typename?: '_RemoveConceptListTeamPayload';
  /** Field for the Team node this TEAM_CONCEPT_LIST [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<Team>;
  /** Field for the ConceptList node this TEAM_CONCEPT_LIST [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<ConceptList>;
};

export type _RemoveConceptListUserPayload = {
  __typename?: '_RemoveConceptListUserPayload';
  /** Field for the User node this HAS_CONCEPT_LIST [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<User>;
  /** Field for the ConceptList node this HAS_CONCEPT_LIST [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<ConceptList>;
};

export type _RemoveConceptListWorkspaceConceptPayload = {
  __typename?: '_RemoveConceptListWorkspaceConceptPayload';
  /** Field for the Concept node this HAS_CONCEPT_WORKSPACE [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<Concept>;
  /** Field for the ConceptList node this HAS_CONCEPT_WORKSPACE [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<ConceptList>;
};

export type _RemoveConceptNotificationsPayload = {
  __typename?: '_RemoveConceptNotificationsPayload';
  /** Field for the Concept node this CONCEPT_TRIGGERED_NOTIFICATION [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<Concept>;
  /** Field for the Notification node this CONCEPT_TRIGGERED_NOTIFICATION [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<Notification>;
};

export type _RemoveConceptParentPayload = {
  __typename?: '_RemoveConceptParentPayload';
  /** Field for the Concept node this CHILD_OF [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<Concept>;
  /** Field for the Concept node this CHILD_OF [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<Concept>;
};

export type _RemoveConceptRecentUpdatesPayload = {
  __typename?: '_RemoveConceptRecentUpdatesPayload';
  /** Field for the User node this UPDATED [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<User>;
  /** Field for the Concept node this UPDATED [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<Concept>;
};

export type _RemoveConceptStatusConceptPayload = {
  __typename?: '_RemoveConceptStatusConceptPayload';
  /** Field for the Concept node this HAS_STATUS [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<Concept>;
  /** Field for the ConceptStatus node this HAS_STATUS [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<ConceptStatus>;
};

export type _RemoveConceptStatusPayload = {
  __typename?: '_RemoveConceptStatusPayload';
  /** Field for the Concept node this HAS_STATUS [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<Concept>;
  /** Field for the ConceptStatus node this HAS_STATUS [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<ConceptStatus>;
};

export type _RemoveConceptStatusStagePayload = {
  __typename?: '_RemoveConceptStatusStagePayload';
  /** Field for the ConceptStatus node this HAS_WORKFLOW_STAGE [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<ConceptStatus>;
  /** Field for the WorkflowStage node this HAS_WORKFLOW_STAGE [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<WorkflowStage>;
};

export type _RemoveConceptTagsPayload = {
  __typename?: '_RemoveConceptTagsPayload';
  /** Field for the Concept node this TAGGED [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<Concept>;
  /** Field for the Tag node this TAGGED [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<Tag>;
};

export type _RemoveConceptTeamHomepagePayload = {
  __typename?: '_RemoveConceptTeamHomepagePayload';
  /** Field for the Team node this TEAM_HOME_CONCEPT [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<Team>;
  /** Field for the Concept node this TEAM_HOME_CONCEPT [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<Concept>;
};

export type _RemoveConceptTeamsPayload = {
  __typename?: '_RemoveConceptTeamsPayload';
  /** Field for the Team node this TEAM_CONCEPT [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<Team>;
  /** Field for the Concept node this TEAM_CONCEPT [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<Concept>;
};

export type _RemoveConceptTranslationsPayload = {
  __typename?: '_RemoveConceptTranslationsPayload';
  /** Field for the Concept node this HAS_TRANSLATION [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<Concept>;
  /** Field for the Translation node this HAS_TRANSLATION [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<Translation>;
};

export type _RemoveConceptUsedByScorePayload = {
  __typename?: '_RemoveConceptUsedByScorePayload';
  /** Field for the CriteriaScore node this CONCEPTS_IN_SCORE [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<CriteriaScore>;
  /** Field for the Concept node this CONCEPTS_IN_SCORE [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<Concept>;
};

export type _RemoveConceptUserHomepagePayload = {
  __typename?: '_RemoveConceptUserHomepagePayload';
  /** Field for the User node this USER_HOME_CONCEPT [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<User>;
  /** Field for the Concept node this USER_HOME_CONCEPT [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<Concept>;
};

export type _RemoveConceptUsesDashboardPayload = {
  __typename?: '_RemoveConceptUsesDashboardPayload';
  /** Field for the Concept node this SAVED_DASHBOARD [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<Concept>;
  /** Field for the DashboardView node this SAVED_DASHBOARD [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<DashboardView>;
};

export type _RemoveConceptWatchedByPayload = {
  __typename?: '_RemoveConceptWatchedByPayload';
  /** Field for the User node this WATCHING [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<User>;
  /** Field for the Concept node this WATCHING [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<Concept>;
};

export type _RemoveConceptWebLinkPayload = {
  __typename?: '_RemoveConceptWebLinkPayload';
  /** Field for the Concept node this HAS_WEB_LINK [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<Concept>;
  /** Field for the Link node this HAS_WEB_LINK [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<Link>;
};

export type _RemoveConceptWorkspacePayload = {
  __typename?: '_RemoveConceptWorkspacePayload';
  /** Field for the Concept node this HAS_CONCEPT_WORKSPACE [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<Concept>;
  /** Field for the ConceptList node this HAS_CONCEPT_WORKSPACE [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<ConceptList>;
};

export type _RemoveCriteriaCategoriesPayload = {
  __typename?: '_RemoveCriteriaCategoriesPayload';
  /** Field for the Category node this CATEGORY_USES_CRITERIA [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<Category>;
  /** Field for the Criteria node this CATEGORY_USES_CRITERIA [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<Criteria>;
};

export type _RemoveCriteriaCategoryPayload = {
  __typename?: '_RemoveCriteriaCategoryPayload';
  /** Field for the Category node this HAS_DEFAULT_CRITERIA [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<Category>;
  /** Field for the Criteria node this HAS_DEFAULT_CRITERIA [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<Criteria>;
};

export type _RemoveCriteriaChildrenPayload = {
  __typename?: '_RemoveCriteriaChildrenPayload';
  /** Field for the Criteria node this CHILD_OF_CRITERIA [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<Criteria>;
  /** Field for the Criteria node this CHILD_OF_CRITERIA [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<Criteria>;
};

export type _RemoveCriteriaConceptPayload = {
  __typename?: '_RemoveCriteriaConceptPayload';
  /** Field for the Concept node this HAS_CRITERIA [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<Concept>;
  /** Field for the Criteria node this HAS_CRITERIA [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<Criteria>;
};

export type _RemoveCriteriaExercisePayload = {
  __typename?: '_RemoveCriteriaExercisePayload';
  /** Field for the Exercise node this INCLUDES_CRITERIA [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<Exercise>;
  /** Field for the Criteria node this INCLUDES_CRITERIA [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<Criteria>;
};

export type _RemoveCriteriaExerciseTypesPayload = {
  __typename?: '_RemoveCriteriaExerciseTypesPayload';
  /** Field for the ExerciseType node this TYPE_INCLUDES_CRITERIA [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<ExerciseType>;
  /** Field for the Criteria node this TYPE_INCLUDES_CRITERIA [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<Criteria>;
};

export type _RemoveCriteriaInputsPayload = {
  __typename?: '_RemoveCriteriaInputsPayload';
  /** Field for the Input node this INPUT_USES_CRITERIA [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<Input>;
  /** Field for the Criteria node this INPUT_USES_CRITERIA [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<Criteria>;
};

export type _RemoveCriteriaParentFieldsPayload = {
  __typename?: '_RemoveCriteriaParentFieldsPayload';
  /** Field for the Criteria node this HAS_SUBFIELD [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<Criteria>;
  /** Field for the Criteria node this HAS_SUBFIELD [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<Criteria>;
};

export type _RemoveCriteriaParentPayload = {
  __typename?: '_RemoveCriteriaParentPayload';
  /** Field for the Criteria node this CHILD_OF_CRITERIA [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<Criteria>;
  /** Field for the Criteria node this CHILD_OF_CRITERIA [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<Criteria>;
};

export type _RemoveCriteriaScoreConceptsPayload = {
  __typename?: '_RemoveCriteriaScoreConceptsPayload';
  /** Field for the CriteriaScore node this CONCEPTS_IN_SCORE [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<CriteriaScore>;
  /** Field for the Concept node this CONCEPTS_IN_SCORE [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<Concept>;
};

export type _RemoveCriteriaScoreCriteriaPayload = {
  __typename?: '_RemoveCriteriaScoreCriteriaPayload';
  /** Field for the CriteriaScore node this SCORE_FOR_CRITERIA [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<CriteriaScore>;
  /** Field for the Criteria node this SCORE_FOR_CRITERIA [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<Criteria>;
};

export type _RemoveCriteriaScoreExercisePayload = {
  __typename?: '_RemoveCriteriaScoreExercisePayload';
  /** Field for the Exercise node this EXERCISE_HAS_CRITERIA_SCORE [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<Exercise>;
  /** Field for the CriteriaScore node this EXERCISE_HAS_CRITERIA_SCORE [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<CriteriaScore>;
};

export type _RemoveCriteriaScoreExerciseSubmissionPayload = {
  __typename?: '_RemoveCriteriaScoreExerciseSubmissionPayload';
  /** Field for the ExerciseSubmission node this HAS_CRITERIA_SCORE [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<ExerciseSubmission>;
  /** Field for the CriteriaScore node this HAS_CRITERIA_SCORE [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<CriteriaScore>;
};

export type _RemoveCriteriaScoreInputPayload = {
  __typename?: '_RemoveCriteriaScoreInputPayload';
  /** Field for the Input node this INPUT_HAS_CRITERIA_SCORE [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<Input>;
  /** Field for the CriteriaScore node this INPUT_HAS_CRITERIA_SCORE [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<CriteriaScore>;
};

export type _RemoveCriteriaScoreScoredConceptPayload = {
  __typename?: '_RemoveCriteriaScoreScoredConceptPayload';
  /** Field for the CriteriaScore node this SCORE_FOR_CONCEPT [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<CriteriaScore>;
  /** Field for the Concept node this SCORE_FOR_CONCEPT [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<Concept>;
};

export type _RemoveCriteriaScoreUserPayload = {
  __typename?: '_RemoveCriteriaScoreUserPayload';
  /** Field for the CriteriaScore node this SCORED_BY [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<CriteriaScore>;
  /** Field for the User node this SCORED_BY [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<User>;
};

export type _RemoveCriteriaScoresPayload = {
  __typename?: '_RemoveCriteriaScoresPayload';
  /** Field for the CriteriaScore node this SCORE_FOR_CRITERIA [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<CriteriaScore>;
  /** Field for the Criteria node this SCORE_FOR_CRITERIA [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<Criteria>;
};

export type _RemoveCriteriaSubfieldsPayload = {
  __typename?: '_RemoveCriteriaSubfieldsPayload';
  /** Field for the Criteria node this HAS_SUBFIELD [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<Criteria>;
  /** Field for the Criteria node this HAS_SUBFIELD [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<Criteria>;
};

export type _RemoveDashboardViewActiveThemePayload = {
  __typename?: '_RemoveDashboardViewActiveThemePayload';
  /** Field for the DashboardView node this ACTIVE_THEME [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<DashboardView>;
  /** Field for the DesignTheme node this ACTIVE_THEME [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<DesignTheme>;
};

export type _RemoveDashboardViewCategoryDefaultPayload = {
  __typename?: '_RemoveDashboardViewCategoryDefaultPayload';
  /** Field for the Category node this USES_DEFAULT_DASHBOARD [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<Category>;
  /** Field for the DashboardView node this USES_DEFAULT_DASHBOARD [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<DashboardView>;
};

export type _RemoveDashboardViewConceptHomePayload = {
  __typename?: '_RemoveDashboardViewConceptHomePayload';
  /** Field for the Concept node this HAS_HOME_DASHBOARD [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<Concept>;
  /** Field for the DashboardView node this HAS_HOME_DASHBOARD [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<DashboardView>;
};

export type _RemoveDashboardViewCreatedByPayload = {
  __typename?: '_RemoveDashboardViewCreatedByPayload';
  /** Field for the User node this CREATED_DASHBOARD [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<User>;
  /** Field for the DashboardView node this CREATED_DASHBOARD [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<DashboardView>;
};

export type _RemoveDashboardViewUsedByConceptsPayload = {
  __typename?: '_RemoveDashboardViewUsedByConceptsPayload';
  /** Field for the Concept node this SAVED_DASHBOARD [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<Concept>;
  /** Field for the DashboardView node this SAVED_DASHBOARD [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<DashboardView>;
};

export type _RemoveDashboardViewUsedByPayload = {
  __typename?: '_RemoveDashboardViewUsedByPayload';
  /** Field for the User node this SAVED_DASHBOARD [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<User>;
  /** Field for the DashboardView node this SAVED_DASHBOARD [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<DashboardView>;
};

export type _RemoveDashboardViewWidgetsPayload = {
  __typename?: '_RemoveDashboardViewWidgetsPayload';
  /** Field for the DashboardView node this HAS_WIDGET [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<DashboardView>;
  /** Field for the Widget node this HAS_WIDGET [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<Widget>;
};

export type _RemoveDesignThemeCreatedByPayload = {
  __typename?: '_RemoveDesignThemeCreatedByPayload';
  /** Field for the User node this CREATED_THEME [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<User>;
  /** Field for the DesignTheme node this CREATED_THEME [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<DesignTheme>;
};

export type _RemoveDesignThemeSavedByPayload = {
  __typename?: '_RemoveDesignThemeSavedByPayload';
  /** Field for the User node this SAVED_THEME [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<User>;
  /** Field for the DesignTheme node this SAVED_THEME [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<DesignTheme>;
};

export type _RemoveDesignThemeUsedAsDarkByPayload = {
  __typename?: '_RemoveDesignThemeUsedAsDarkByPayload';
  /** Field for the User node this ACTIVE_DARK_THEME [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<User>;
  /** Field for the DesignTheme node this ACTIVE_DARK_THEME [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<DesignTheme>;
};

export type _RemoveDesignThemeUsedAsLightByPayload = {
  __typename?: '_RemoveDesignThemeUsedAsLightByPayload';
  /** Field for the User node this ACTIVE_LIGHT_THEME [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<User>;
  /** Field for the DesignTheme node this ACTIVE_LIGHT_THEME [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<DesignTheme>;
};

export type _RemoveDesignThemeUsedByDashboardsPayload = {
  __typename?: '_RemoveDesignThemeUsedByDashboardsPayload';
  /** Field for the DashboardView node this ACTIVE_THEME [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<DashboardView>;
  /** Field for the DesignTheme node this ACTIVE_THEME [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<DesignTheme>;
};

export type _RemoveExerciseConceptsCreatedPayload = {
  __typename?: '_RemoveExerciseConceptsCreatedPayload';
  /** Field for the Exercise node this SUBMISSION_SPARKED_CONCEPT [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<Exercise>;
  /** Field for the Concept node this SUBMISSION_SPARKED_CONCEPT [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<Concept>;
};

export type _RemoveExerciseCreatedByPayload = {
  __typename?: '_RemoveExerciseCreatedByPayload';
  /** Field for the Exercise node this EXERCISE_CREATED_BY [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<Exercise>;
  /** Field for the User node this EXERCISE_CREATED_BY [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<User>;
};

export type _RemoveExerciseCriteriaPayload = {
  __typename?: '_RemoveExerciseCriteriaPayload';
  /** Field for the Exercise node this INCLUDES_CRITERIA [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<Exercise>;
  /** Field for the Criteria node this INCLUDES_CRITERIA [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<Criteria>;
};

export type _RemoveExerciseCriteriaScoresPayload = {
  __typename?: '_RemoveExerciseCriteriaScoresPayload';
  /** Field for the Exercise node this EXERCISE_HAS_CRITERIA_SCORE [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<Exercise>;
  /** Field for the CriteriaScore node this EXERCISE_HAS_CRITERIA_SCORE [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<CriteriaScore>;
};

export type _RemoveExerciseFacilitatorsPayload = {
  __typename?: '_RemoveExerciseFacilitatorsPayload';
  /** Field for the Exercise node this EXERCISE_FACILITATED_BY [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<Exercise>;
  /** Field for the User node this EXERCISE_FACILITATED_BY [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<User>;
};

export type _RemoveExerciseIncludedChecklistPayload = {
  __typename?: '_RemoveExerciseIncludedChecklistPayload';
  /** Field for the Exercise node this EXERCISE_INCLUDES_CHECKLIST [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<Exercise>;
  /** Field for the Checklist node this EXERCISE_INCLUDES_CHECKLIST [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<Checklist>;
};

export type _RemoveExerciseIncludedConceptsPayload = {
  __typename?: '_RemoveExerciseIncludedConceptsPayload';
  /** Field for the Exercise node this EXERCISE_INCLUDES_CONCEPT [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<Exercise>;
  /** Field for the Concept node this EXERCISE_INCLUDES_CONCEPT [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<Concept>;
};

export type _RemoveExerciseInvitedTeamsPayload = {
  __typename?: '_RemoveExerciseInvitedTeamsPayload';
  /** Field for the Exercise node this EXERCISE_INVITE_SENT_TO_TEAM [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<Exercise>;
  /** Field for the Team node this EXERCISE_INVITE_SENT_TO_TEAM [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<Team>;
};

export type _RemoveExerciseInvitedUsersPayload = {
  __typename?: '_RemoveExerciseInvitedUsersPayload';
  /** Field for the Exercise node this EXERCISE_INVITE_SENT_TO_USER [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<Exercise>;
  /** Field for the User node this EXERCISE_INVITE_SENT_TO_USER [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<User>;
};

export type _RemoveExerciseParentConceptPayload = {
  __typename?: '_RemoveExerciseParentConceptPayload';
  /** Field for the Exercise node this EXERCISE_DONE_FOR [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<Exercise>;
  /** Field for the Concept node this EXERCISE_DONE_FOR [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<Concept>;
};

export type _RemoveExerciseParticipantsPayload = {
  __typename?: '_RemoveExerciseParticipantsPayload';
  /** Field for the Exercise node this EXERCISE_COMPLETED_BY [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<Exercise>;
  /** Field for the User node this EXERCISE_COMPLETED_BY [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<User>;
};

export type _RemoveExerciseScoreConceptPayload = {
  __typename?: '_RemoveExerciseScoreConceptPayload';
  /** Field for the Concept node this GIVEN_SCORE_OF [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<Concept>;
  /** Field for the ExerciseScore node this GIVEN_SCORE_OF [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<ExerciseScore>;
};

export type _RemoveExerciseScoreSubmissionPayload = {
  __typename?: '_RemoveExerciseScoreSubmissionPayload';
  /** Field for the ExerciseSubmission node this CONTAINS_SCORES [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<ExerciseSubmission>;
  /** Field for the ExerciseScore node this CONTAINS_SCORES [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<ExerciseScore>;
};

export type _RemoveExerciseSubmissionConceptCreatedPayload = {
  __typename?: '_RemoveExerciseSubmissionConceptCreatedPayload';
  /** Field for the ExerciseSubmission node this SUBMISSION_SPARKED_CONCEPT [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<ExerciseSubmission>;
  /** Field for the Concept node this SUBMISSION_SPARKED_CONCEPT [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<Concept>;
};

export type _RemoveExerciseSubmissionCriteriaScoresPayload = {
  __typename?: '_RemoveExerciseSubmissionCriteriaScoresPayload';
  /** Field for the ExerciseSubmission node this HAS_CRITERIA_SCORE [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<ExerciseSubmission>;
  /** Field for the CriteriaScore node this HAS_CRITERIA_SCORE [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<CriteriaScore>;
};

export type _RemoveExerciseSubmissionExercisePayload = {
  __typename?: '_RemoveExerciseSubmissionExercisePayload';
  /** Field for the ExerciseSubmission node this EXERCISE_SUBMISSION_TO [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<ExerciseSubmission>;
  /** Field for the Exercise node this EXERCISE_SUBMISSION_TO [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<Exercise>;
};

export type _RemoveExerciseSubmissionScoresPayload = {
  __typename?: '_RemoveExerciseSubmissionScoresPayload';
  /** Field for the ExerciseSubmission node this CONTAINS_SCORES [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<ExerciseSubmission>;
  /** Field for the ExerciseScore node this CONTAINS_SCORES [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<ExerciseScore>;
};

export type _RemoveExerciseSubmissionUserPayload = {
  __typename?: '_RemoveExerciseSubmissionUserPayload';
  /** Field for the ExerciseSubmission node this EXERCISE_RESULT_SUBMITTED_BY [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<ExerciseSubmission>;
  /** Field for the User node this EXERCISE_RESULT_SUBMITTED_BY [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<User>;
};

export type _RemoveExerciseSubmissionsPayload = {
  __typename?: '_RemoveExerciseSubmissionsPayload';
  /** Field for the ExerciseSubmission node this EXERCISE_SUBMISSION_TO [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<ExerciseSubmission>;
  /** Field for the Exercise node this EXERCISE_SUBMISSION_TO [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<Exercise>;
};

export type _RemoveExerciseTypeCreatedByPayload = {
  __typename?: '_RemoveExerciseTypeCreatedByPayload';
  /** Field for the ExerciseType node this EXERCISE_TYPE_CREATED_BY [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<ExerciseType>;
  /** Field for the User node this EXERCISE_TYPE_CREATED_BY [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<User>;
};

export type _RemoveExerciseTypeCriteriaPayload = {
  __typename?: '_RemoveExerciseTypeCriteriaPayload';
  /** Field for the ExerciseType node this TYPE_INCLUDES_CRITERIA [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<ExerciseType>;
  /** Field for the Criteria node this TYPE_INCLUDES_CRITERIA [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<Criteria>;
};

export type _RemoveExerciseTypeExercisesPayload = {
  __typename?: '_RemoveExerciseTypeExercisesPayload';
  /** Field for the Exercise node this EXERCISE_TYPE_OF [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<Exercise>;
  /** Field for the ExerciseType node this EXERCISE_TYPE_OF [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<ExerciseType>;
};

export type _RemoveExerciseTypePayload = {
  __typename?: '_RemoveExerciseTypePayload';
  /** Field for the Exercise node this EXERCISE_TYPE_OF [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<Exercise>;
  /** Field for the ExerciseType node this EXERCISE_TYPE_OF [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<ExerciseType>;
};

export type _RemoveIndustryCompaniesPayload = {
  __typename?: '_RemoveIndustryCompaniesPayload';
  /** Field for the Company node this IN_INDUSTRY [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<Company>;
  /** Field for the Industry node this IN_INDUSTRY [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<Industry>;
};

export type _RemoveIndustryConceptsPayload = {
  __typename?: '_RemoveIndustryConceptsPayload';
  /** Field for the Concept node this IMPACTS_INDUSTRY [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<Concept>;
  /** Field for the Industry node this IMPACTS_INDUSTRY [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<Industry>;
};

export type _RemoveInitiativeCommentsPayload = {
  __typename?: '_RemoveInitiativeCommentsPayload';
  /** Field for the Comment node this COMMENT_ON [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<Comment>;
  /** Field for the Initiative node this COMMENT_ON [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<Initiative>;
};

export type _RemoveInitiativeConnectionsPayload = {
  __typename?: '_RemoveInitiativeConnectionsPayload';
  /** Field for the Initiative node this CONNECTED [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<Initiative>;
  /** Field for the Concept node this CONNECTED [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<Concept>;
};

export type _RemoveInitiativeLikedByPayload = {
  __typename?: '_RemoveInitiativeLikedByPayload';
  /** Field for the User node this LIKED [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<User>;
  /** Field for the Initiative node this LIKED [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<Initiative>;
};

export type _RemoveInitiativeTagsPayload = {
  __typename?: '_RemoveInitiativeTagsPayload';
  /** Field for the Initiative node this TAGGED [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<Initiative>;
  /** Field for the Tag node this TAGGED [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<Tag>;
};

export type _RemoveInitiativeTeamPayload = {
  __typename?: '_RemoveInitiativeTeamPayload';
  /** Field for the Team node this TEAM_INITIATIVE [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<Team>;
  /** Field for the Initiative node this TEAM_INITIATIVE [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<Initiative>;
};

export type _RemoveInitiativeUserPayload = {
  __typename?: '_RemoveInitiativeUserPayload';
  /** Field for the User node this USER_INITIATIVE [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<User>;
  /** Field for the Initiative node this USER_INITIATIVE [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<Initiative>;
};

export type _RemoveInputConceptsCreatedPayload = {
  __typename?: '_RemoveInputConceptsCreatedPayload';
  /** Field for the Input node this CREATED_VIA_INPUT [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<Input>;
  /** Field for the Concept node this CREATED_VIA_INPUT [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<Concept>;
};

export type _RemoveInputCriteriaPayload = {
  __typename?: '_RemoveInputCriteriaPayload';
  /** Field for the Input node this INPUT_USES_CRITERIA [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<Input>;
  /** Field for the Criteria node this INPUT_USES_CRITERIA [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<Criteria>;
};

export type _RemoveInputCriteriaScoresPayload = {
  __typename?: '_RemoveInputCriteriaScoresPayload';
  /** Field for the Input node this INPUT_HAS_CRITERIA_SCORE [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<Input>;
  /** Field for the CriteriaScore node this INPUT_HAS_CRITERIA_SCORE [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<CriteriaScore>;
};

export type _RemoveInputIncludedConceptsPayload = {
  __typename?: '_RemoveInputIncludedConceptsPayload';
  /** Field for the Input node this INPUT_INCLUDES_CONCEPT [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<Input>;
  /** Field for the Concept node this INPUT_INCLUDES_CONCEPT [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<Concept>;
};

export type _RemoveInputParentConceptPayload = {
  __typename?: '_RemoveInputParentConceptPayload';
  /** Field for the Input node this INPUT_FOR_CONCEPT [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<Input>;
  /** Field for the Concept node this INPUT_FOR_CONCEPT [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<Concept>;
};

export type _RemoveNotificationActionByPayload = {
  __typename?: '_RemoveNotificationActionByPayload';
  /** Field for the User node this USER_TRIGGERED_NOTIFICATION [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<User>;
  /** Field for the Notification node this USER_TRIGGERED_NOTIFICATION [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<Notification>;
};

export type _RemoveNotificationCommentPayload = {
  __typename?: '_RemoveNotificationCommentPayload';
  /** Field for the Comment node this COMMENT_TRIGGERED_NOTIFICATION [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<Comment>;
  /** Field for the Notification node this COMMENT_TRIGGERED_NOTIFICATION [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<Notification>;
};

export type _RemoveNotificationConceptPayload = {
  __typename?: '_RemoveNotificationConceptPayload';
  /** Field for the Concept node this CONCEPT_TRIGGERED_NOTIFICATION [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<Concept>;
  /** Field for the Notification node this CONCEPT_TRIGGERED_NOTIFICATION [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<Notification>;
};

export type _RemoveNotificationUsersPayload = {
  __typename?: '_RemoveNotificationUsersPayload';
  /** Field for the Notification node this NOTIFIED [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<Notification>;
  /** Field for the User node this NOTIFIED [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<User>;
};

export type _RemoveTagTaggedPayload = {
  __typename?: '_RemoveTagTaggedPayload';
  /** Field for the Concept node this TAGGED [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<Concept>;
  /** Field for the Tag node this TAGGED [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<Tag>;
};

export type _RemoveTeamAddedToConceptsPayload = {
  __typename?: '_RemoveTeamAddedToConceptsPayload';
  /** Field for the Team node this HAS_ACCESS [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<Team>;
  /** Field for the Concept node this HAS_ACCESS [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<Concept>;
};

export type _RemoveTeamAdminPayload = {
  __typename?: '_RemoveTeamAdminPayload';
  /** Field for the User node this TEAM_ADMIN [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<User>;
  /** Field for the Team node this TEAM_ADMIN [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<Team>;
};

export type _RemoveTeamCompanyPayload = {
  __typename?: '_RemoveTeamCompanyPayload';
  /** Field for the Team node this TEAM_AT [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<Team>;
  /** Field for the Company node this TEAM_AT [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<Company>;
};

export type _RemoveTeamConceptListsPayload = {
  __typename?: '_RemoveTeamConceptListsPayload';
  /** Field for the Team node this TEAM_CONCEPT_LIST [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<Team>;
  /** Field for the ConceptList node this TEAM_CONCEPT_LIST [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<ConceptList>;
};

export type _RemoveTeamConceptsPayload = {
  __typename?: '_RemoveTeamConceptsPayload';
  /** Field for the Team node this TEAM_CONCEPT [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<Team>;
  /** Field for the Concept node this TEAM_CONCEPT [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<Concept>;
};

export type _RemoveTeamExercisesInvitedToPayload = {
  __typename?: '_RemoveTeamExercisesInvitedToPayload';
  /** Field for the Exercise node this EXERCISE_INVITE_SENT_TO_TEAM [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<Exercise>;
  /** Field for the Team node this EXERCISE_INVITE_SENT_TO_TEAM [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<Team>;
};

export type _RemoveTeamHomeConceptPayload = {
  __typename?: '_RemoveTeamHomeConceptPayload';
  /** Field for the Team node this TEAM_HOMEPAGE_CONCEPT [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<Team>;
  /** Field for the Concept node this TEAM_HOMEPAGE_CONCEPT [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<Concept>;
};

export type _RemoveTeamLeaderPayload = {
  __typename?: '_RemoveTeamLeaderPayload';
  /** Field for the User node this LEADS_TEAM [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<User>;
  /** Field for the Team node this LEADS_TEAM [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<Team>;
};

export type _RemoveTeamMembersPayload = {
  __typename?: '_RemoveTeamMembersPayload';
  /** Field for the User node this TEAM_MEMBER [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<User>;
  /** Field for the Team node this TEAM_MEMBER [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<Team>;
};

export type _RemoveTranslationConceptPayload = {
  __typename?: '_RemoveTranslationConceptPayload';
  /** Field for the Concept node this HAS_TRANSLATION [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<Concept>;
  /** Field for the Translation node this HAS_TRANSLATION [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<Translation>;
};

export type _RemoveUserActiveDarkThemePayload = {
  __typename?: '_RemoveUserActiveDarkThemePayload';
  /** Field for the User node this ACTIVE_DARK_THEME [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<User>;
  /** Field for the DesignTheme node this ACTIVE_DARK_THEME [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<DesignTheme>;
};

export type _RemoveUserActiveLightThemePayload = {
  __typename?: '_RemoveUserActiveLightThemePayload';
  /** Field for the User node this ACTIVE_LIGHT_THEME [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<User>;
  /** Field for the DesignTheme node this ACTIVE_LIGHT_THEME [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<DesignTheme>;
};

export type _RemoveUserAddedToConceptsPayload = {
  __typename?: '_RemoveUserAddedToConceptsPayload';
  /** Field for the User node this HAS_ACCESS [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<User>;
  /** Field for the Concept node this HAS_ACCESS [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<Concept>;
};

export type _RemoveUserAdminPayload = {
  __typename?: '_RemoveUserAdminPayload';
  /** Field for the User node this TEAM_ADMIN [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<User>;
  /** Field for the Team node this TEAM_ADMIN [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<Team>;
};

export type _RemoveUserCommentsPayload = {
  __typename?: '_RemoveUserCommentsPayload';
  /** Field for the User node this COMMENTED [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<User>;
  /** Field for the Comment node this COMMENTED [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<Comment>;
};

export type _RemoveUserConceptBoardPayload = {
  __typename?: '_RemoveUserConceptBoardPayload';
  /** Field for the User node this HAS_CONCEPT_BOARD [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<User>;
  /** Field for the ConceptBoard node this HAS_CONCEPT_BOARD [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<ConceptBoard>;
};

export type _RemoveUserConceptListsPayload = {
  __typename?: '_RemoveUserConceptListsPayload';
  /** Field for the User node this HAS_CONCEPT_LIST [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<User>;
  /** Field for the ConceptList node this HAS_CONCEPT_LIST [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<ConceptList>;
};

export type _RemoveUserConceptsCreatedPayload = {
  __typename?: '_RemoveUserConceptsCreatedPayload';
  /** Field for the User node this CREATED [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<User>;
  /** Field for the Concept node this CREATED [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<Concept>;
};

export type _RemoveUserCreatedDashboardPayload = {
  __typename?: '_RemoveUserCreatedDashboardPayload';
  /** Field for the User node this CREATED_DASHBOARD [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<User>;
  /** Field for the DashboardView node this CREATED_DASHBOARD [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<DashboardView>;
};

export type _RemoveUserCreatedThemesPayload = {
  __typename?: '_RemoveUserCreatedThemesPayload';
  /** Field for the User node this CREATED_THEME [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<User>;
  /** Field for the DesignTheme node this CREATED_THEME [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<DesignTheme>;
};

export type _RemoveUserCriteriaScoresPayload = {
  __typename?: '_RemoveUserCriteriaScoresPayload';
  /** Field for the User node this SCORED_BY [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<User>;
  /** Field for the CriteriaScore node this SCORED_BY [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<CriteriaScore>;
};

export type _RemoveUserExerciseSubmissionsPayload = {
  __typename?: '_RemoveUserExerciseSubmissionsPayload';
  /** Field for the ExerciseSubmission node this EXERCISE_RESULT_SUBMITTED_BY [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<ExerciseSubmission>;
  /** Field for the User node this EXERCISE_RESULT_SUBMITTED_BY [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<User>;
};

export type _RemoveUserExerciseTypesCreatedPayload = {
  __typename?: '_RemoveUserExerciseTypesCreatedPayload';
  /** Field for the ExerciseType node this EXERCISE_TYPE_CREATED_BY [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<ExerciseType>;
  /** Field for the User node this EXERCISE_TYPE_CREATED_BY [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<User>;
};

export type _RemoveUserExercisesCreatedPayload = {
  __typename?: '_RemoveUserExercisesCreatedPayload';
  /** Field for the Exercise node this EXERCISE_CREATED_BY [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<Exercise>;
  /** Field for the User node this EXERCISE_CREATED_BY [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<User>;
};

export type _RemoveUserExercisesFacilitatedPayload = {
  __typename?: '_RemoveUserExercisesFacilitatedPayload';
  /** Field for the Exercise node this EXERCISE_FACILITATED_BY [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<Exercise>;
  /** Field for the User node this EXERCISE_FACILITATED_BY [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<User>;
};

export type _RemoveUserExercisesInvitedToPayload = {
  __typename?: '_RemoveUserExercisesInvitedToPayload';
  /** Field for the Exercise node this EXERCISE_INVITE_SENT_TO_USER [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<Exercise>;
  /** Field for the User node this EXERCISE_INVITE_SENT_TO_USER [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<User>;
};

export type _RemoveUserExercisesParticipatedPayload = {
  __typename?: '_RemoveUserExercisesParticipatedPayload';
  /** Field for the Exercise node this EXERCISE_COMPLETED_BY [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<Exercise>;
  /** Field for the User node this EXERCISE_COMPLETED_BY [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<User>;
};

export type _RemoveUserHomeConceptPayload = {
  __typename?: '_RemoveUserHomeConceptPayload';
  /** Field for the User node this USER_HOME_CONCEPT [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<User>;
  /** Field for the Concept node this USER_HOME_CONCEPT [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<Concept>;
};

export type _RemoveUserLeadsPayload = {
  __typename?: '_RemoveUserLeadsPayload';
  /** Field for the User node this LEADS_TEAM [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<User>;
  /** Field for the Team node this LEADS_TEAM [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<Team>;
};

export type _RemoveUserLikedPayload = {
  __typename?: '_RemoveUserLikedPayload';
  /** Field for the User node this LIKED [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<User>;
  /** Field for the Concept node this LIKED [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<Concept>;
};

export type _RemoveUserNotificationsPayload = {
  __typename?: '_RemoveUserNotificationsPayload';
  /** Field for the Notification node this NOTIFIED [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<Notification>;
  /** Field for the User node this NOTIFIED [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<User>;
};

export type _RemoveUserNotificationsTriggeredPayload = {
  __typename?: '_RemoveUserNotificationsTriggeredPayload';
  /** Field for the User node this USER_TRIGGERED_NOTIFICATION [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<User>;
  /** Field for the User node this USER_TRIGGERED_NOTIFICATION [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<User>;
};

export type _RemoveUserRecentUpdatesPayload = {
  __typename?: '_RemoveUserRecentUpdatesPayload';
  /** Field for the User node this UPDATED [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<User>;
  /** Field for the Concept node this UPDATED [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<Concept>;
};

export type _RemoveUserSavedDashboardsPayload = {
  __typename?: '_RemoveUserSavedDashboardsPayload';
  /** Field for the User node this SAVED_DASHBOARD [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<User>;
  /** Field for the DashboardView node this SAVED_DASHBOARD [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<DashboardView>;
};

export type _RemoveUserSavedThemesPayload = {
  __typename?: '_RemoveUserSavedThemesPayload';
  /** Field for the User node this SAVED_THEME [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<User>;
  /** Field for the DesignTheme node this SAVED_THEME [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<DesignTheme>;
};

export type _RemoveUserTeamsPayload = {
  __typename?: '_RemoveUserTeamsPayload';
  /** Field for the User node this TEAM_MEMBER [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<User>;
  /** Field for the Team node this TEAM_MEMBER [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<Team>;
};

export type _RemoveUserWatchedConceptsPayload = {
  __typename?: '_RemoveUserWatchedConceptsPayload';
  /** Field for the User node this WATCHING [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<User>;
  /** Field for the Concept node this WATCHING [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<Concept>;
};

export type _RemoveUserWorkspaceInboxPayload = {
  __typename?: '_RemoveUserWorkspaceInboxPayload';
  /** Field for the User node this HAS_WORKSPACE_INBOX [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<User>;
  /** Field for the ConceptList node this HAS_WORKSPACE_INBOX [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<ConceptList>;
};

export type _RemoveWidgetDashboardsPayload = {
  __typename?: '_RemoveWidgetDashboardsPayload';
  /** Field for the DashboardView node this HAS_WIDGET [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<DashboardView>;
  /** Field for the Widget node this HAS_WIDGET [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<Widget>;
};

export type _RemoveWidgetTypePayload = {
  __typename?: '_RemoveWidgetTypePayload';
  /** Field for the Widget node this WIDGET_TYPE_OF [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<Widget>;
  /** Field for the WidgetType node this WIDGET_TYPE_OF [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<WidgetType>;
};

export type _RemoveWidgetTypeWidgetsPayload = {
  __typename?: '_RemoveWidgetTypeWidgetsPayload';
  /** Field for the Widget node this WIDGET_TYPE_OF [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<Widget>;
  /** Field for the WidgetType node this WIDGET_TYPE_OF [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<WidgetType>;
};

export type _RemoveWorkflowCategoriesPayload = {
  __typename?: '_RemoveWorkflowCategoriesPayload';
  /** Field for the Workflow node this DEFAULT_WORKFLOW_FOR_CATEGORY [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<Workflow>;
  /** Field for the Category node this DEFAULT_WORKFLOW_FOR_CATEGORY [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<Category>;
};

export type _RemoveWorkflowStageLastOptionsPayload = {
  __typename?: '_RemoveWorkflowStageLastOptionsPayload';
  /** Field for the WorkflowStage node this NEXT_STAGE [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<WorkflowStage>;
  /** Field for the WorkflowStage node this NEXT_STAGE [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<WorkflowStage>;
};

export type _RemoveWorkflowStageNextOptionsPayload = {
  __typename?: '_RemoveWorkflowStageNextOptionsPayload';
  /** Field for the WorkflowStage node this NEXT_STAGE [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<WorkflowStage>;
  /** Field for the WorkflowStage node this NEXT_STAGE [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<WorkflowStage>;
};

export type _RemoveWorkflowStageStatusesPayload = {
  __typename?: '_RemoveWorkflowStageStatusesPayload';
  /** Field for the ConceptStatus node this HAS_WORKFLOW_STAGE [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<ConceptStatus>;
  /** Field for the WorkflowStage node this HAS_WORKFLOW_STAGE [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<WorkflowStage>;
};

export type _RemoveWorkflowStageWorkflowPayload = {
  __typename?: '_RemoveWorkflowStageWorkflowPayload';
  /** Field for the Workflow node this WORKFLOW_STAGE [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<Workflow>;
  /** Field for the WorkflowStage node this WORKFLOW_STAGE [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<WorkflowStage>;
};

export type _RemoveWorkflowStagesPayload = {
  __typename?: '_RemoveWorkflowStagesPayload';
  /** Field for the Workflow node this WORKFLOW_STAGE [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<Workflow>;
  /** Field for the WorkflowStage node this WORKFLOW_STAGE [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<WorkflowStage>;
};

export type _TagFilter = {
  AND?: Maybe<Array<_TagFilter>>;
  OR?: Maybe<Array<_TagFilter>>;
  name?: Maybe<Scalars['ID']>;
  name_not?: Maybe<Scalars['ID']>;
  name_in?: Maybe<Array<Scalars['ID']>>;
  name_not_in?: Maybe<Array<Scalars['ID']>>;
  name_regexp?: Maybe<Scalars['ID']>;
  name_contains?: Maybe<Scalars['ID']>;
  name_not_contains?: Maybe<Scalars['ID']>;
  name_starts_with?: Maybe<Scalars['ID']>;
  name_not_starts_with?: Maybe<Scalars['ID']>;
  name_ends_with?: Maybe<Scalars['ID']>;
  name_not_ends_with?: Maybe<Scalars['ID']>;
  tagged?: Maybe<_ConceptFilter>;
  tagged_not?: Maybe<_ConceptFilter>;
  tagged_in?: Maybe<Array<_ConceptFilter>>;
  tagged_not_in?: Maybe<Array<_ConceptFilter>>;
  tagged_some?: Maybe<_ConceptFilter>;
  tagged_none?: Maybe<_ConceptFilter>;
  tagged_single?: Maybe<_ConceptFilter>;
  tagged_every?: Maybe<_ConceptFilter>;
};

export type _TagInput = {
  name: Scalars['ID'];
};

export enum _TagOrdering {
  NAME_ASC = 'name_asc',
  NAME_DESC = 'name_desc',
  _ID_ASC = '_id_asc',
  _ID_DESC = '_id_desc'
}

export type _TeamAccessInput = {
  type?: Maybe<AccessType>;
  updatedAt?: Maybe<_Neo4jDateTimeInput>;
};

export enum _TeamAccessOrdering {
  TYPE_ASC = 'type_asc',
  TYPE_DESC = 'type_desc',
  UPDATEDAT_ASC = 'updatedAt_asc',
  UPDATEDAT_DESC = 'updatedAt_desc',
  _ID_ASC = '_id_asc',
  _ID_DESC = '_id_desc'
}

export type _TeamAddedToConcepts = {
  __typename?: '_TeamAddedToConcepts';
  type?: Maybe<AccessType>;
  updatedAt?: Maybe<_Neo4jDateTime>;
  /** Generated field for querying the Neo4j [system id](https://neo4j.com/docs/cypher-manual/current/functions/scalar/#functions-id) of this relationship. */
  _id?: Maybe<Scalars['String']>;
  /** Field for the Concept node this HAS_ACCESS [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  Concept?: Maybe<Concept>;
};

export type _TeamFilter = {
  AND?: Maybe<Array<_TeamFilter>>;
  OR?: Maybe<Array<_TeamFilter>>;
  teamId?: Maybe<Scalars['ID']>;
  teamId_not?: Maybe<Scalars['ID']>;
  teamId_in?: Maybe<Array<Scalars['ID']>>;
  teamId_not_in?: Maybe<Array<Scalars['ID']>>;
  teamId_regexp?: Maybe<Scalars['ID']>;
  teamId_contains?: Maybe<Scalars['ID']>;
  teamId_not_contains?: Maybe<Scalars['ID']>;
  teamId_starts_with?: Maybe<Scalars['ID']>;
  teamId_not_starts_with?: Maybe<Scalars['ID']>;
  teamId_ends_with?: Maybe<Scalars['ID']>;
  teamId_not_ends_with?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  name_not?: Maybe<Scalars['String']>;
  name_in?: Maybe<Array<Scalars['String']>>;
  name_not_in?: Maybe<Array<Scalars['String']>>;
  name_regexp?: Maybe<Scalars['String']>;
  name_contains?: Maybe<Scalars['String']>;
  name_not_contains?: Maybe<Scalars['String']>;
  name_starts_with?: Maybe<Scalars['String']>;
  name_not_starts_with?: Maybe<Scalars['String']>;
  name_ends_with?: Maybe<Scalars['String']>;
  name_not_ends_with?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  slug_not?: Maybe<Scalars['String']>;
  slug_in?: Maybe<Array<Scalars['String']>>;
  slug_not_in?: Maybe<Array<Scalars['String']>>;
  slug_regexp?: Maybe<Scalars['String']>;
  slug_contains?: Maybe<Scalars['String']>;
  slug_not_contains?: Maybe<Scalars['String']>;
  slug_starts_with?: Maybe<Scalars['String']>;
  slug_not_starts_with?: Maybe<Scalars['String']>;
  slug_ends_with?: Maybe<Scalars['String']>;
  slug_not_ends_with?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  description_not?: Maybe<Scalars['String']>;
  description_in?: Maybe<Array<Scalars['String']>>;
  description_not_in?: Maybe<Array<Scalars['String']>>;
  description_regexp?: Maybe<Scalars['String']>;
  description_contains?: Maybe<Scalars['String']>;
  description_not_contains?: Maybe<Scalars['String']>;
  description_starts_with?: Maybe<Scalars['String']>;
  description_not_starts_with?: Maybe<Scalars['String']>;
  description_ends_with?: Maybe<Scalars['String']>;
  description_not_ends_with?: Maybe<Scalars['String']>;
  imageUrl?: Maybe<Scalars['String']>;
  imageUrl_not?: Maybe<Scalars['String']>;
  imageUrl_in?: Maybe<Array<Scalars['String']>>;
  imageUrl_not_in?: Maybe<Array<Scalars['String']>>;
  imageUrl_regexp?: Maybe<Scalars['String']>;
  imageUrl_contains?: Maybe<Scalars['String']>;
  imageUrl_not_contains?: Maybe<Scalars['String']>;
  imageUrl_starts_with?: Maybe<Scalars['String']>;
  imageUrl_not_starts_with?: Maybe<Scalars['String']>;
  imageUrl_ends_with?: Maybe<Scalars['String']>;
  imageUrl_not_ends_with?: Maybe<Scalars['String']>;
  leader?: Maybe<_UserFilter>;
  leader_not?: Maybe<_UserFilter>;
  leader_in?: Maybe<Array<_UserFilter>>;
  leader_not_in?: Maybe<Array<_UserFilter>>;
  admin?: Maybe<_UserFilter>;
  admin_not?: Maybe<_UserFilter>;
  admin_in?: Maybe<Array<_UserFilter>>;
  admin_not_in?: Maybe<Array<_UserFilter>>;
  admin_some?: Maybe<_UserFilter>;
  admin_none?: Maybe<_UserFilter>;
  admin_single?: Maybe<_UserFilter>;
  admin_every?: Maybe<_UserFilter>;
  members?: Maybe<_UserFilter>;
  members_not?: Maybe<_UserFilter>;
  members_in?: Maybe<Array<_UserFilter>>;
  members_not_in?: Maybe<Array<_UserFilter>>;
  members_some?: Maybe<_UserFilter>;
  members_none?: Maybe<_UserFilter>;
  members_single?: Maybe<_UserFilter>;
  members_every?: Maybe<_UserFilter>;
  company?: Maybe<_CompanyFilter>;
  company_not?: Maybe<_CompanyFilter>;
  company_in?: Maybe<Array<_CompanyFilter>>;
  company_not_in?: Maybe<Array<_CompanyFilter>>;
  concepts?: Maybe<_ConceptFilter>;
  concepts_not?: Maybe<_ConceptFilter>;
  concepts_in?: Maybe<Array<_ConceptFilter>>;
  concepts_not_in?: Maybe<Array<_ConceptFilter>>;
  concepts_some?: Maybe<_ConceptFilter>;
  concepts_none?: Maybe<_ConceptFilter>;
  concepts_single?: Maybe<_ConceptFilter>;
  concepts_every?: Maybe<_ConceptFilter>;
  homeConcept?: Maybe<_ConceptFilter>;
  homeConcept_not?: Maybe<_ConceptFilter>;
  homeConcept_in?: Maybe<Array<_ConceptFilter>>;
  homeConcept_not_in?: Maybe<Array<_ConceptFilter>>;
  addedToConcepts?: Maybe<_TeamTeamAccessFilter>;
  addedToConcepts_not?: Maybe<_TeamTeamAccessFilter>;
  addedToConcepts_in?: Maybe<Array<_TeamTeamAccessFilter>>;
  addedToConcepts_not_in?: Maybe<Array<_TeamTeamAccessFilter>>;
  addedToConcepts_some?: Maybe<_TeamTeamAccessFilter>;
  addedToConcepts_none?: Maybe<_TeamTeamAccessFilter>;
  addedToConcepts_single?: Maybe<_TeamTeamAccessFilter>;
  addedToConcepts_every?: Maybe<_TeamTeamAccessFilter>;
  conceptLists?: Maybe<_ConceptListFilter>;
  conceptLists_not?: Maybe<_ConceptListFilter>;
  conceptLists_in?: Maybe<Array<_ConceptListFilter>>;
  conceptLists_not_in?: Maybe<Array<_ConceptListFilter>>;
  conceptLists_some?: Maybe<_ConceptListFilter>;
  conceptLists_none?: Maybe<_ConceptListFilter>;
  conceptLists_single?: Maybe<_ConceptListFilter>;
  conceptLists_every?: Maybe<_ConceptListFilter>;
  exercisesInvitedTo?: Maybe<_ExerciseFilter>;
  exercisesInvitedTo_not?: Maybe<_ExerciseFilter>;
  exercisesInvitedTo_in?: Maybe<Array<_ExerciseFilter>>;
  exercisesInvitedTo_not_in?: Maybe<Array<_ExerciseFilter>>;
  exercisesInvitedTo_some?: Maybe<_ExerciseFilter>;
  exercisesInvitedTo_none?: Maybe<_ExerciseFilter>;
  exercisesInvitedTo_single?: Maybe<_ExerciseFilter>;
  exercisesInvitedTo_every?: Maybe<_ExerciseFilter>;
};

export type _TeamInput = {
  teamId: Scalars['ID'];
};

export enum _TeamOrdering {
  TEAMID_ASC = 'teamId_asc',
  TEAMID_DESC = 'teamId_desc',
  NAME_ASC = 'name_asc',
  NAME_DESC = 'name_desc',
  SLUG_ASC = 'slug_asc',
  SLUG_DESC = 'slug_desc',
  DESCRIPTION_ASC = 'description_asc',
  DESCRIPTION_DESC = 'description_desc',
  IMAGEURL_ASC = 'imageUrl_asc',
  IMAGEURL_DESC = 'imageUrl_desc',
  _ID_ASC = '_id_asc',
  _ID_DESC = '_id_desc'
}

export type _TeamTeamAccessFilter = {
  AND?: Maybe<Array<_TeamTeamAccessFilter>>;
  OR?: Maybe<Array<_TeamTeamAccessFilter>>;
  type?: Maybe<AccessType>;
  type_not?: Maybe<AccessType>;
  type_in?: Maybe<Array<AccessType>>;
  type_not_in?: Maybe<Array<AccessType>>;
  updatedAt?: Maybe<_Neo4jDateTimeInput>;
  updatedAt_not?: Maybe<_Neo4jDateTimeInput>;
  updatedAt_in?: Maybe<Array<_Neo4jDateTimeInput>>;
  updatedAt_not_in?: Maybe<Array<_Neo4jDateTimeInput>>;
  updatedAt_lt?: Maybe<_Neo4jDateTimeInput>;
  updatedAt_lte?: Maybe<_Neo4jDateTimeInput>;
  updatedAt_gt?: Maybe<_Neo4jDateTimeInput>;
  updatedAt_gte?: Maybe<_Neo4jDateTimeInput>;
  Concept?: Maybe<_ConceptFilter>;
};

export type _TranslationFilter = {
  AND?: Maybe<Array<_TranslationFilter>>;
  OR?: Maybe<Array<_TranslationFilter>>;
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Scalars['ID']>>;
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  id_regexp?: Maybe<Scalars['ID']>;
  id_contains?: Maybe<Scalars['ID']>;
  id_not_contains?: Maybe<Scalars['ID']>;
  id_starts_with?: Maybe<Scalars['ID']>;
  id_not_starts_with?: Maybe<Scalars['ID']>;
  id_ends_with?: Maybe<Scalars['ID']>;
  id_not_ends_with?: Maybe<Scalars['ID']>;
  languageString?: Maybe<LanguageString>;
  languageString_not?: Maybe<LanguageString>;
  languageString_in?: Maybe<Array<LanguageString>>;
  languageString_not_in?: Maybe<Array<LanguageString>>;
  title?: Maybe<Scalars['String']>;
  title_not?: Maybe<Scalars['String']>;
  title_in?: Maybe<Array<Scalars['String']>>;
  title_not_in?: Maybe<Array<Scalars['String']>>;
  title_regexp?: Maybe<Scalars['String']>;
  title_contains?: Maybe<Scalars['String']>;
  title_not_contains?: Maybe<Scalars['String']>;
  title_starts_with?: Maybe<Scalars['String']>;
  title_not_starts_with?: Maybe<Scalars['String']>;
  title_ends_with?: Maybe<Scalars['String']>;
  title_not_ends_with?: Maybe<Scalars['String']>;
  summary?: Maybe<Scalars['String']>;
  summary_not?: Maybe<Scalars['String']>;
  summary_in?: Maybe<Array<Scalars['String']>>;
  summary_not_in?: Maybe<Array<Scalars['String']>>;
  summary_regexp?: Maybe<Scalars['String']>;
  summary_contains?: Maybe<Scalars['String']>;
  summary_not_contains?: Maybe<Scalars['String']>;
  summary_starts_with?: Maybe<Scalars['String']>;
  summary_not_starts_with?: Maybe<Scalars['String']>;
  summary_ends_with?: Maybe<Scalars['String']>;
  summary_not_ends_with?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  description_not?: Maybe<Scalars['String']>;
  description_in?: Maybe<Array<Scalars['String']>>;
  description_not_in?: Maybe<Array<Scalars['String']>>;
  description_regexp?: Maybe<Scalars['String']>;
  description_contains?: Maybe<Scalars['String']>;
  description_not_contains?: Maybe<Scalars['String']>;
  description_starts_with?: Maybe<Scalars['String']>;
  description_not_starts_with?: Maybe<Scalars['String']>;
  description_ends_with?: Maybe<Scalars['String']>;
  description_not_ends_with?: Maybe<Scalars['String']>;
  concept?: Maybe<_ConceptFilter>;
  concept_not?: Maybe<_ConceptFilter>;
  concept_in?: Maybe<Array<_ConceptFilter>>;
  concept_not_in?: Maybe<Array<_ConceptFilter>>;
};

export type _TranslationInput = {
  id: Scalars['ID'];
};

export enum _TranslationOrdering {
  ID_ASC = 'id_asc',
  ID_DESC = 'id_desc',
  LANGUAGESTRING_ASC = 'languageString_asc',
  LANGUAGESTRING_DESC = 'languageString_desc',
  TITLE_ASC = 'title_asc',
  TITLE_DESC = 'title_desc',
  SUMMARY_ASC = 'summary_asc',
  SUMMARY_DESC = 'summary_desc',
  DESCRIPTION_ASC = 'description_asc',
  DESCRIPTION_DESC = 'description_desc',
  _ID_ASC = '_id_asc',
  _ID_DESC = '_id_desc'
}

export type _UpdateConceptAddedTeamsPayload = {
  __typename?: '_UpdateConceptAddedTeamsPayload';
  /** Field for the Team node this HAS_ACCESS [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<Team>;
  /** Field for the Concept node this HAS_ACCESS [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<Concept>;
  type?: Maybe<AccessType>;
  updatedAt?: Maybe<_Neo4jDateTime>;
  /** Generated field for querying the Neo4j [system id](https://neo4j.com/docs/cypher-manual/current/functions/scalar/#functions-id) of this relationship. */
  _id?: Maybe<Scalars['String']>;
};

export type _UpdateConceptAddedUsersPayload = {
  __typename?: '_UpdateConceptAddedUsersPayload';
  /** Field for the User node this HAS_ACCESS [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<User>;
  /** Field for the Concept node this HAS_ACCESS [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<Concept>;
  type?: Maybe<AccessType>;
  updatedAt?: Maybe<_Neo4jDateTime>;
  /** Generated field for querying the Neo4j [system id](https://neo4j.com/docs/cypher-manual/current/functions/scalar/#functions-id) of this relationship. */
  _id?: Maybe<Scalars['String']>;
};

export type _UpdateConceptDetailedConnectionsPayload = {
  __typename?: '_UpdateConceptDetailedConnectionsPayload';
  /** Field for the Concept node this CONNECTED [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<Concept>;
  /** Field for the Concept node this CONNECTED [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<Concept>;
  description?: Maybe<Scalars['String']>;
  type?: Maybe<ConnectionType>;
  username?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<_Neo4jDateTime>;
  updateValue?: Maybe<Scalars['String']>;
  /** Generated field for querying the Neo4j [system id](https://neo4j.com/docs/cypher-manual/current/functions/scalar/#functions-id) of this relationship. */
  _id?: Maybe<Scalars['String']>;
};

export type _UpdateConceptRecentUpdatesPayload = {
  __typename?: '_UpdateConceptRecentUpdatesPayload';
  /** Field for the User node this UPDATED [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<User>;
  /** Field for the Concept node this UPDATED [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<Concept>;
  updatedAt?: Maybe<_Neo4jDateTime>;
  updateValue?: Maybe<Scalars['String']>;
  /** Generated field for querying the Neo4j [system id](https://neo4j.com/docs/cypher-manual/current/functions/scalar/#functions-id) of this relationship. */
  _id?: Maybe<Scalars['String']>;
};

export type _UpdateTeamAddedToConceptsPayload = {
  __typename?: '_UpdateTeamAddedToConceptsPayload';
  /** Field for the Team node this HAS_ACCESS [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<Team>;
  /** Field for the Concept node this HAS_ACCESS [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<Concept>;
  type?: Maybe<AccessType>;
  updatedAt?: Maybe<_Neo4jDateTime>;
  /** Generated field for querying the Neo4j [system id](https://neo4j.com/docs/cypher-manual/current/functions/scalar/#functions-id) of this relationship. */
  _id?: Maybe<Scalars['String']>;
};

export type _UpdateUserAddedToConceptsPayload = {
  __typename?: '_UpdateUserAddedToConceptsPayload';
  /** Field for the User node this HAS_ACCESS [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<User>;
  /** Field for the Concept node this HAS_ACCESS [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<Concept>;
  type?: Maybe<AccessType>;
  updatedAt?: Maybe<_Neo4jDateTime>;
  /** Generated field for querying the Neo4j [system id](https://neo4j.com/docs/cypher-manual/current/functions/scalar/#functions-id) of this relationship. */
  _id?: Maybe<Scalars['String']>;
};

export type _UpdateUserRecentUpdatesPayload = {
  __typename?: '_UpdateUserRecentUpdatesPayload';
  /** Field for the User node this UPDATED [relationship](https://grandstack.io/docs/graphql-relationship-types) is coming from. */
  from?: Maybe<User>;
  /** Field for the Concept node this UPDATED [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  to?: Maybe<Concept>;
  updatedAt?: Maybe<_Neo4jDateTime>;
  updateValue?: Maybe<Scalars['String']>;
  /** Generated field for querying the Neo4j [system id](https://neo4j.com/docs/cypher-manual/current/functions/scalar/#functions-id) of this relationship. */
  _id?: Maybe<Scalars['String']>;
};

export type _UserAccessInput = {
  type?: Maybe<AccessType>;
  updatedAt?: Maybe<_Neo4jDateTimeInput>;
};

export enum _UserAccessOrdering {
  TYPE_ASC = 'type_asc',
  TYPE_DESC = 'type_desc',
  UPDATEDAT_ASC = 'updatedAt_asc',
  UPDATEDAT_DESC = 'updatedAt_desc',
  _ID_ASC = '_id_asc',
  _ID_DESC = '_id_desc'
}

export type _UserAddedToConcepts = {
  __typename?: '_UserAddedToConcepts';
  type?: Maybe<AccessType>;
  updatedAt?: Maybe<_Neo4jDateTime>;
  /** Generated field for querying the Neo4j [system id](https://neo4j.com/docs/cypher-manual/current/functions/scalar/#functions-id) of this relationship. */
  _id?: Maybe<Scalars['String']>;
  /** Field for the Concept node this HAS_ACCESS [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  Concept?: Maybe<Concept>;
};

export type _UserConceptUpdateFilter = {
  AND?: Maybe<Array<_UserConceptUpdateFilter>>;
  OR?: Maybe<Array<_UserConceptUpdateFilter>>;
  updatedAt?: Maybe<_Neo4jDateTimeInput>;
  updatedAt_not?: Maybe<_Neo4jDateTimeInput>;
  updatedAt_in?: Maybe<Array<_Neo4jDateTimeInput>>;
  updatedAt_not_in?: Maybe<Array<_Neo4jDateTimeInput>>;
  updatedAt_lt?: Maybe<_Neo4jDateTimeInput>;
  updatedAt_lte?: Maybe<_Neo4jDateTimeInput>;
  updatedAt_gt?: Maybe<_Neo4jDateTimeInput>;
  updatedAt_gte?: Maybe<_Neo4jDateTimeInput>;
  updateValue?: Maybe<Scalars['String']>;
  updateValue_not?: Maybe<Scalars['String']>;
  updateValue_in?: Maybe<Array<Scalars['String']>>;
  updateValue_not_in?: Maybe<Array<Scalars['String']>>;
  updateValue_regexp?: Maybe<Scalars['String']>;
  updateValue_contains?: Maybe<Scalars['String']>;
  updateValue_not_contains?: Maybe<Scalars['String']>;
  updateValue_starts_with?: Maybe<Scalars['String']>;
  updateValue_not_starts_with?: Maybe<Scalars['String']>;
  updateValue_ends_with?: Maybe<Scalars['String']>;
  updateValue_not_ends_with?: Maybe<Scalars['String']>;
  Concept?: Maybe<_ConceptFilter>;
};

export type _UserFilter = {
  AND?: Maybe<Array<_UserFilter>>;
  OR?: Maybe<Array<_UserFilter>>;
  userId?: Maybe<Scalars['ID']>;
  userId_not?: Maybe<Scalars['ID']>;
  userId_in?: Maybe<Array<Scalars['ID']>>;
  userId_not_in?: Maybe<Array<Scalars['ID']>>;
  userId_regexp?: Maybe<Scalars['ID']>;
  userId_contains?: Maybe<Scalars['ID']>;
  userId_not_contains?: Maybe<Scalars['ID']>;
  userId_starts_with?: Maybe<Scalars['ID']>;
  userId_not_starts_with?: Maybe<Scalars['ID']>;
  userId_ends_with?: Maybe<Scalars['ID']>;
  userId_not_ends_with?: Maybe<Scalars['ID']>;
  providerId?: Maybe<Scalars['String']>;
  providerId_not?: Maybe<Scalars['String']>;
  providerId_in?: Maybe<Array<Scalars['String']>>;
  providerId_not_in?: Maybe<Array<Scalars['String']>>;
  providerId_regexp?: Maybe<Scalars['String']>;
  providerId_contains?: Maybe<Scalars['String']>;
  providerId_not_contains?: Maybe<Scalars['String']>;
  providerId_starts_with?: Maybe<Scalars['String']>;
  providerId_not_starts_with?: Maybe<Scalars['String']>;
  providerId_ends_with?: Maybe<Scalars['String']>;
  providerId_not_ends_with?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  firstName_not?: Maybe<Scalars['String']>;
  firstName_in?: Maybe<Array<Scalars['String']>>;
  firstName_not_in?: Maybe<Array<Scalars['String']>>;
  firstName_regexp?: Maybe<Scalars['String']>;
  firstName_contains?: Maybe<Scalars['String']>;
  firstName_not_contains?: Maybe<Scalars['String']>;
  firstName_starts_with?: Maybe<Scalars['String']>;
  firstName_not_starts_with?: Maybe<Scalars['String']>;
  firstName_ends_with?: Maybe<Scalars['String']>;
  firstName_not_ends_with?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  lastName_not?: Maybe<Scalars['String']>;
  lastName_in?: Maybe<Array<Scalars['String']>>;
  lastName_not_in?: Maybe<Array<Scalars['String']>>;
  lastName_regexp?: Maybe<Scalars['String']>;
  lastName_contains?: Maybe<Scalars['String']>;
  lastName_not_contains?: Maybe<Scalars['String']>;
  lastName_starts_with?: Maybe<Scalars['String']>;
  lastName_not_starts_with?: Maybe<Scalars['String']>;
  lastName_ends_with?: Maybe<Scalars['String']>;
  lastName_not_ends_with?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  email_not?: Maybe<Scalars['String']>;
  email_in?: Maybe<Array<Scalars['String']>>;
  email_not_in?: Maybe<Array<Scalars['String']>>;
  email_regexp?: Maybe<Scalars['String']>;
  email_contains?: Maybe<Scalars['String']>;
  email_not_contains?: Maybe<Scalars['String']>;
  email_starts_with?: Maybe<Scalars['String']>;
  email_not_starts_with?: Maybe<Scalars['String']>;
  email_ends_with?: Maybe<Scalars['String']>;
  email_not_ends_with?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  phoneNumber_not?: Maybe<Scalars['String']>;
  phoneNumber_in?: Maybe<Array<Scalars['String']>>;
  phoneNumber_not_in?: Maybe<Array<Scalars['String']>>;
  phoneNumber_regexp?: Maybe<Scalars['String']>;
  phoneNumber_contains?: Maybe<Scalars['String']>;
  phoneNumber_not_contains?: Maybe<Scalars['String']>;
  phoneNumber_starts_with?: Maybe<Scalars['String']>;
  phoneNumber_not_starts_with?: Maybe<Scalars['String']>;
  phoneNumber_ends_with?: Maybe<Scalars['String']>;
  phoneNumber_not_ends_with?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  country_not?: Maybe<Scalars['String']>;
  country_in?: Maybe<Array<Scalars['String']>>;
  country_not_in?: Maybe<Array<Scalars['String']>>;
  country_regexp?: Maybe<Scalars['String']>;
  country_contains?: Maybe<Scalars['String']>;
  country_not_contains?: Maybe<Scalars['String']>;
  country_starts_with?: Maybe<Scalars['String']>;
  country_not_starts_with?: Maybe<Scalars['String']>;
  country_ends_with?: Maybe<Scalars['String']>;
  country_not_ends_with?: Maybe<Scalars['String']>;
  username?: Maybe<Scalars['String']>;
  username_not?: Maybe<Scalars['String']>;
  username_in?: Maybe<Array<Scalars['String']>>;
  username_not_in?: Maybe<Array<Scalars['String']>>;
  username_regexp?: Maybe<Scalars['String']>;
  username_contains?: Maybe<Scalars['String']>;
  username_not_contains?: Maybe<Scalars['String']>;
  username_starts_with?: Maybe<Scalars['String']>;
  username_not_starts_with?: Maybe<Scalars['String']>;
  username_ends_with?: Maybe<Scalars['String']>;
  username_not_ends_with?: Maybe<Scalars['String']>;
  imageUrl?: Maybe<Scalars['String']>;
  imageUrl_not?: Maybe<Scalars['String']>;
  imageUrl_in?: Maybe<Array<Scalars['String']>>;
  imageUrl_not_in?: Maybe<Array<Scalars['String']>>;
  imageUrl_regexp?: Maybe<Scalars['String']>;
  imageUrl_contains?: Maybe<Scalars['String']>;
  imageUrl_not_contains?: Maybe<Scalars['String']>;
  imageUrl_starts_with?: Maybe<Scalars['String']>;
  imageUrl_not_starts_with?: Maybe<Scalars['String']>;
  imageUrl_ends_with?: Maybe<Scalars['String']>;
  imageUrl_not_ends_with?: Maybe<Scalars['String']>;
  company?: Maybe<Scalars['String']>;
  company_not?: Maybe<Scalars['String']>;
  company_in?: Maybe<Array<Scalars['String']>>;
  company_not_in?: Maybe<Array<Scalars['String']>>;
  company_regexp?: Maybe<Scalars['String']>;
  company_contains?: Maybe<Scalars['String']>;
  company_not_contains?: Maybe<Scalars['String']>;
  company_starts_with?: Maybe<Scalars['String']>;
  company_not_starts_with?: Maybe<Scalars['String']>;
  company_ends_with?: Maybe<Scalars['String']>;
  company_not_ends_with?: Maybe<Scalars['String']>;
  bio?: Maybe<Scalars['String']>;
  bio_not?: Maybe<Scalars['String']>;
  bio_in?: Maybe<Array<Scalars['String']>>;
  bio_not_in?: Maybe<Array<Scalars['String']>>;
  bio_regexp?: Maybe<Scalars['String']>;
  bio_contains?: Maybe<Scalars['String']>;
  bio_not_contains?: Maybe<Scalars['String']>;
  bio_starts_with?: Maybe<Scalars['String']>;
  bio_not_starts_with?: Maybe<Scalars['String']>;
  bio_ends_with?: Maybe<Scalars['String']>;
  bio_not_ends_with?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  token_not?: Maybe<Scalars['String']>;
  token_in?: Maybe<Array<Scalars['String']>>;
  token_not_in?: Maybe<Array<Scalars['String']>>;
  token_regexp?: Maybe<Scalars['String']>;
  token_contains?: Maybe<Scalars['String']>;
  token_not_contains?: Maybe<Scalars['String']>;
  token_starts_with?: Maybe<Scalars['String']>;
  token_not_starts_with?: Maybe<Scalars['String']>;
  token_ends_with?: Maybe<Scalars['String']>;
  token_not_ends_with?: Maybe<Scalars['String']>;
  language?: Maybe<LanguageString>;
  language_not?: Maybe<LanguageString>;
  language_in?: Maybe<Array<LanguageString>>;
  language_not_in?: Maybe<Array<LanguageString>>;
  lastCheckedNotifications?: Maybe<_Neo4jDateTimeInput>;
  lastCheckedNotifications_not?: Maybe<_Neo4jDateTimeInput>;
  lastCheckedNotifications_in?: Maybe<Array<_Neo4jDateTimeInput>>;
  lastCheckedNotifications_not_in?: Maybe<Array<_Neo4jDateTimeInput>>;
  lastCheckedNotifications_lt?: Maybe<_Neo4jDateTimeInput>;
  lastCheckedNotifications_lte?: Maybe<_Neo4jDateTimeInput>;
  lastCheckedNotifications_gt?: Maybe<_Neo4jDateTimeInput>;
  lastCheckedNotifications_gte?: Maybe<_Neo4jDateTimeInput>;
  lastLogin?: Maybe<_Neo4jDateTimeInput>;
  lastLogin_not?: Maybe<_Neo4jDateTimeInput>;
  lastLogin_in?: Maybe<Array<_Neo4jDateTimeInput>>;
  lastLogin_not_in?: Maybe<Array<_Neo4jDateTimeInput>>;
  lastLogin_lt?: Maybe<_Neo4jDateTimeInput>;
  lastLogin_lte?: Maybe<_Neo4jDateTimeInput>;
  lastLogin_gt?: Maybe<_Neo4jDateTimeInput>;
  lastLogin_gte?: Maybe<_Neo4jDateTimeInput>;
  createdDate?: Maybe<_Neo4jDateTimeInput>;
  createdDate_not?: Maybe<_Neo4jDateTimeInput>;
  createdDate_in?: Maybe<Array<_Neo4jDateTimeInput>>;
  createdDate_not_in?: Maybe<Array<_Neo4jDateTimeInput>>;
  createdDate_lt?: Maybe<_Neo4jDateTimeInput>;
  createdDate_lte?: Maybe<_Neo4jDateTimeInput>;
  createdDate_gt?: Maybe<_Neo4jDateTimeInput>;
  createdDate_gte?: Maybe<_Neo4jDateTimeInput>;
  role?: Maybe<SystemRole>;
  role_not?: Maybe<SystemRole>;
  role_in?: Maybe<Array<SystemRole>>;
  role_not_in?: Maybe<Array<SystemRole>>;
  recentUpdates?: Maybe<_UserConceptUpdateFilter>;
  recentUpdates_not?: Maybe<_UserConceptUpdateFilter>;
  recentUpdates_in?: Maybe<Array<_UserConceptUpdateFilter>>;
  recentUpdates_not_in?: Maybe<Array<_UserConceptUpdateFilter>>;
  recentUpdates_some?: Maybe<_UserConceptUpdateFilter>;
  recentUpdates_none?: Maybe<_UserConceptUpdateFilter>;
  recentUpdates_single?: Maybe<_UserConceptUpdateFilter>;
  recentUpdates_every?: Maybe<_UserConceptUpdateFilter>;
  liked?: Maybe<_ConceptFilter>;
  liked_not?: Maybe<_ConceptFilter>;
  liked_in?: Maybe<Array<_ConceptFilter>>;
  liked_not_in?: Maybe<Array<_ConceptFilter>>;
  liked_some?: Maybe<_ConceptFilter>;
  liked_none?: Maybe<_ConceptFilter>;
  liked_single?: Maybe<_ConceptFilter>;
  liked_every?: Maybe<_ConceptFilter>;
  addedToConcepts?: Maybe<_UserUserAccessFilter>;
  addedToConcepts_not?: Maybe<_UserUserAccessFilter>;
  addedToConcepts_in?: Maybe<Array<_UserUserAccessFilter>>;
  addedToConcepts_not_in?: Maybe<Array<_UserUserAccessFilter>>;
  addedToConcepts_some?: Maybe<_UserUserAccessFilter>;
  addedToConcepts_none?: Maybe<_UserUserAccessFilter>;
  addedToConcepts_single?: Maybe<_UserUserAccessFilter>;
  addedToConcepts_every?: Maybe<_UserUserAccessFilter>;
  homeConcept?: Maybe<_ConceptFilter>;
  homeConcept_not?: Maybe<_ConceptFilter>;
  homeConcept_in?: Maybe<Array<_ConceptFilter>>;
  homeConcept_not_in?: Maybe<Array<_ConceptFilter>>;
  conceptsCreated?: Maybe<_ConceptFilter>;
  conceptsCreated_not?: Maybe<_ConceptFilter>;
  conceptsCreated_in?: Maybe<Array<_ConceptFilter>>;
  conceptsCreated_not_in?: Maybe<Array<_ConceptFilter>>;
  conceptsCreated_some?: Maybe<_ConceptFilter>;
  conceptsCreated_none?: Maybe<_ConceptFilter>;
  conceptsCreated_single?: Maybe<_ConceptFilter>;
  conceptsCreated_every?: Maybe<_ConceptFilter>;
  activeThemeType?: Maybe<ThemeType>;
  activeThemeType_not?: Maybe<ThemeType>;
  activeThemeType_in?: Maybe<Array<ThemeType>>;
  activeThemeType_not_in?: Maybe<Array<ThemeType>>;
  activeLightTheme?: Maybe<_DesignThemeFilter>;
  activeLightTheme_not?: Maybe<_DesignThemeFilter>;
  activeLightTheme_in?: Maybe<Array<_DesignThemeFilter>>;
  activeLightTheme_not_in?: Maybe<Array<_DesignThemeFilter>>;
  activeDarkTheme?: Maybe<_DesignThemeFilter>;
  activeDarkTheme_not?: Maybe<_DesignThemeFilter>;
  activeDarkTheme_in?: Maybe<Array<_DesignThemeFilter>>;
  activeDarkTheme_not_in?: Maybe<Array<_DesignThemeFilter>>;
  savedThemes?: Maybe<_DesignThemeFilter>;
  savedThemes_not?: Maybe<_DesignThemeFilter>;
  savedThemes_in?: Maybe<Array<_DesignThemeFilter>>;
  savedThemes_not_in?: Maybe<Array<_DesignThemeFilter>>;
  savedThemes_some?: Maybe<_DesignThemeFilter>;
  savedThemes_none?: Maybe<_DesignThemeFilter>;
  savedThemes_single?: Maybe<_DesignThemeFilter>;
  savedThemes_every?: Maybe<_DesignThemeFilter>;
  createdThemes?: Maybe<_DesignThemeFilter>;
  createdThemes_not?: Maybe<_DesignThemeFilter>;
  createdThemes_in?: Maybe<Array<_DesignThemeFilter>>;
  createdThemes_not_in?: Maybe<Array<_DesignThemeFilter>>;
  createdThemes_some?: Maybe<_DesignThemeFilter>;
  createdThemes_none?: Maybe<_DesignThemeFilter>;
  createdThemes_single?: Maybe<_DesignThemeFilter>;
  createdThemes_every?: Maybe<_DesignThemeFilter>;
  savedDashboards?: Maybe<_DashboardViewFilter>;
  savedDashboards_not?: Maybe<_DashboardViewFilter>;
  savedDashboards_in?: Maybe<Array<_DashboardViewFilter>>;
  savedDashboards_not_in?: Maybe<Array<_DashboardViewFilter>>;
  createdDashboard?: Maybe<_DashboardViewFilter>;
  createdDashboard_not?: Maybe<_DashboardViewFilter>;
  createdDashboard_in?: Maybe<Array<_DashboardViewFilter>>;
  createdDashboard_not_in?: Maybe<Array<_DashboardViewFilter>>;
  teams?: Maybe<_TeamFilter>;
  teams_not?: Maybe<_TeamFilter>;
  teams_in?: Maybe<Array<_TeamFilter>>;
  teams_not_in?: Maybe<Array<_TeamFilter>>;
  teams_some?: Maybe<_TeamFilter>;
  teams_none?: Maybe<_TeamFilter>;
  teams_single?: Maybe<_TeamFilter>;
  teams_every?: Maybe<_TeamFilter>;
  admin?: Maybe<_TeamFilter>;
  admin_not?: Maybe<_TeamFilter>;
  admin_in?: Maybe<Array<_TeamFilter>>;
  admin_not_in?: Maybe<Array<_TeamFilter>>;
  admin_some?: Maybe<_TeamFilter>;
  admin_none?: Maybe<_TeamFilter>;
  admin_single?: Maybe<_TeamFilter>;
  admin_every?: Maybe<_TeamFilter>;
  leads?: Maybe<_TeamFilter>;
  leads_not?: Maybe<_TeamFilter>;
  leads_in?: Maybe<Array<_TeamFilter>>;
  leads_not_in?: Maybe<Array<_TeamFilter>>;
  leads_some?: Maybe<_TeamFilter>;
  leads_none?: Maybe<_TeamFilter>;
  leads_single?: Maybe<_TeamFilter>;
  leads_every?: Maybe<_TeamFilter>;
  comments?: Maybe<_CommentFilter>;
  comments_not?: Maybe<_CommentFilter>;
  comments_in?: Maybe<Array<_CommentFilter>>;
  comments_not_in?: Maybe<Array<_CommentFilter>>;
  comments_some?: Maybe<_CommentFilter>;
  comments_none?: Maybe<_CommentFilter>;
  comments_single?: Maybe<_CommentFilter>;
  comments_every?: Maybe<_CommentFilter>;
  watchedConcepts?: Maybe<_ConceptFilter>;
  watchedConcepts_not?: Maybe<_ConceptFilter>;
  watchedConcepts_in?: Maybe<Array<_ConceptFilter>>;
  watchedConcepts_not_in?: Maybe<Array<_ConceptFilter>>;
  watchedConcepts_some?: Maybe<_ConceptFilter>;
  watchedConcepts_none?: Maybe<_ConceptFilter>;
  watchedConcepts_single?: Maybe<_ConceptFilter>;
  watchedConcepts_every?: Maybe<_ConceptFilter>;
  notificationsTriggered?: Maybe<_UserFilter>;
  notificationsTriggered_not?: Maybe<_UserFilter>;
  notificationsTriggered_in?: Maybe<Array<_UserFilter>>;
  notificationsTriggered_not_in?: Maybe<Array<_UserFilter>>;
  notifications?: Maybe<_NotificationFilter>;
  notifications_not?: Maybe<_NotificationFilter>;
  notifications_in?: Maybe<Array<_NotificationFilter>>;
  notifications_not_in?: Maybe<Array<_NotificationFilter>>;
  notifications_some?: Maybe<_NotificationFilter>;
  notifications_none?: Maybe<_NotificationFilter>;
  notifications_single?: Maybe<_NotificationFilter>;
  notifications_every?: Maybe<_NotificationFilter>;
  workspaceInbox?: Maybe<_ConceptListFilter>;
  workspaceInbox_not?: Maybe<_ConceptListFilter>;
  workspaceInbox_in?: Maybe<Array<_ConceptListFilter>>;
  workspaceInbox_not_in?: Maybe<Array<_ConceptListFilter>>;
  conceptLists?: Maybe<_ConceptListFilter>;
  conceptLists_not?: Maybe<_ConceptListFilter>;
  conceptLists_in?: Maybe<Array<_ConceptListFilter>>;
  conceptLists_not_in?: Maybe<Array<_ConceptListFilter>>;
  conceptLists_some?: Maybe<_ConceptListFilter>;
  conceptLists_none?: Maybe<_ConceptListFilter>;
  conceptLists_single?: Maybe<_ConceptListFilter>;
  conceptLists_every?: Maybe<_ConceptListFilter>;
  conceptBoard?: Maybe<_ConceptBoardFilter>;
  conceptBoard_not?: Maybe<_ConceptBoardFilter>;
  conceptBoard_in?: Maybe<Array<_ConceptBoardFilter>>;
  conceptBoard_not_in?: Maybe<Array<_ConceptBoardFilter>>;
  exercisesFacilitated?: Maybe<_ExerciseFilter>;
  exercisesFacilitated_not?: Maybe<_ExerciseFilter>;
  exercisesFacilitated_in?: Maybe<Array<_ExerciseFilter>>;
  exercisesFacilitated_not_in?: Maybe<Array<_ExerciseFilter>>;
  exercisesFacilitated_some?: Maybe<_ExerciseFilter>;
  exercisesFacilitated_none?: Maybe<_ExerciseFilter>;
  exercisesFacilitated_single?: Maybe<_ExerciseFilter>;
  exercisesFacilitated_every?: Maybe<_ExerciseFilter>;
  exercisesInvitedTo?: Maybe<_ExerciseFilter>;
  exercisesInvitedTo_not?: Maybe<_ExerciseFilter>;
  exercisesInvitedTo_in?: Maybe<Array<_ExerciseFilter>>;
  exercisesInvitedTo_not_in?: Maybe<Array<_ExerciseFilter>>;
  exercisesInvitedTo_some?: Maybe<_ExerciseFilter>;
  exercisesInvitedTo_none?: Maybe<_ExerciseFilter>;
  exercisesInvitedTo_single?: Maybe<_ExerciseFilter>;
  exercisesInvitedTo_every?: Maybe<_ExerciseFilter>;
  exercisesParticipated?: Maybe<_ExerciseFilter>;
  exercisesParticipated_not?: Maybe<_ExerciseFilter>;
  exercisesParticipated_in?: Maybe<Array<_ExerciseFilter>>;
  exercisesParticipated_not_in?: Maybe<Array<_ExerciseFilter>>;
  exercisesParticipated_some?: Maybe<_ExerciseFilter>;
  exercisesParticipated_none?: Maybe<_ExerciseFilter>;
  exercisesParticipated_single?: Maybe<_ExerciseFilter>;
  exercisesParticipated_every?: Maybe<_ExerciseFilter>;
  exercisesCreated?: Maybe<_ExerciseFilter>;
  exercisesCreated_not?: Maybe<_ExerciseFilter>;
  exercisesCreated_in?: Maybe<Array<_ExerciseFilter>>;
  exercisesCreated_not_in?: Maybe<Array<_ExerciseFilter>>;
  exercisesCreated_some?: Maybe<_ExerciseFilter>;
  exercisesCreated_none?: Maybe<_ExerciseFilter>;
  exercisesCreated_single?: Maybe<_ExerciseFilter>;
  exercisesCreated_every?: Maybe<_ExerciseFilter>;
  exerciseTypesCreated?: Maybe<_ExerciseTypeFilter>;
  exerciseTypesCreated_not?: Maybe<_ExerciseTypeFilter>;
  exerciseTypesCreated_in?: Maybe<Array<_ExerciseTypeFilter>>;
  exerciseTypesCreated_not_in?: Maybe<Array<_ExerciseTypeFilter>>;
  exerciseTypesCreated_some?: Maybe<_ExerciseTypeFilter>;
  exerciseTypesCreated_none?: Maybe<_ExerciseTypeFilter>;
  exerciseTypesCreated_single?: Maybe<_ExerciseTypeFilter>;
  exerciseTypesCreated_every?: Maybe<_ExerciseTypeFilter>;
  exerciseSubmissions?: Maybe<_ExerciseSubmissionFilter>;
  exerciseSubmissions_not?: Maybe<_ExerciseSubmissionFilter>;
  exerciseSubmissions_in?: Maybe<Array<_ExerciseSubmissionFilter>>;
  exerciseSubmissions_not_in?: Maybe<Array<_ExerciseSubmissionFilter>>;
  exerciseSubmissions_some?: Maybe<_ExerciseSubmissionFilter>;
  exerciseSubmissions_none?: Maybe<_ExerciseSubmissionFilter>;
  exerciseSubmissions_single?: Maybe<_ExerciseSubmissionFilter>;
  exerciseSubmissions_every?: Maybe<_ExerciseSubmissionFilter>;
  criteriaScores?: Maybe<_CriteriaScoreFilter>;
  criteriaScores_not?: Maybe<_CriteriaScoreFilter>;
  criteriaScores_in?: Maybe<Array<_CriteriaScoreFilter>>;
  criteriaScores_not_in?: Maybe<Array<_CriteriaScoreFilter>>;
  criteriaScores_some?: Maybe<_CriteriaScoreFilter>;
  criteriaScores_none?: Maybe<_CriteriaScoreFilter>;
  criteriaScores_single?: Maybe<_CriteriaScoreFilter>;
  criteriaScores_every?: Maybe<_CriteriaScoreFilter>;
};

export type _UserInput = {
  userId: Scalars['ID'];
};

export enum _UserOrdering {
  USERID_ASC = 'userId_asc',
  USERID_DESC = 'userId_desc',
  PROVIDERID_ASC = 'providerId_asc',
  PROVIDERID_DESC = 'providerId_desc',
  FIRSTNAME_ASC = 'firstName_asc',
  FIRSTNAME_DESC = 'firstName_desc',
  LASTNAME_ASC = 'lastName_asc',
  LASTNAME_DESC = 'lastName_desc',
  EMAIL_ASC = 'email_asc',
  EMAIL_DESC = 'email_desc',
  PHONENUMBER_ASC = 'phoneNumber_asc',
  PHONENUMBER_DESC = 'phoneNumber_desc',
  COUNTRY_ASC = 'country_asc',
  COUNTRY_DESC = 'country_desc',
  USERNAME_ASC = 'username_asc',
  USERNAME_DESC = 'username_desc',
  IMAGEURL_ASC = 'imageUrl_asc',
  IMAGEURL_DESC = 'imageUrl_desc',
  COMPANY_ASC = 'company_asc',
  COMPANY_DESC = 'company_desc',
  BIO_ASC = 'bio_asc',
  BIO_DESC = 'bio_desc',
  TOKEN_ASC = 'token_asc',
  TOKEN_DESC = 'token_desc',
  LANGUAGE_ASC = 'language_asc',
  LANGUAGE_DESC = 'language_desc',
  LASTCHECKEDNOTIFICATIONS_ASC = 'lastCheckedNotifications_asc',
  LASTCHECKEDNOTIFICATIONS_DESC = 'lastCheckedNotifications_desc',
  LASTLOGIN_ASC = 'lastLogin_asc',
  LASTLOGIN_DESC = 'lastLogin_desc',
  CREATEDDATE_ASC = 'createdDate_asc',
  CREATEDDATE_DESC = 'createdDate_desc',
  ROLE_ASC = 'role_asc',
  ROLE_DESC = 'role_desc',
  NUMCONCEPTSCREATED_ASC = 'numConceptsCreated_asc',
  NUMCONCEPTSCREATED_DESC = 'numConceptsCreated_desc',
  ACTIVETHEMETYPE_ASC = 'activeThemeType_asc',
  ACTIVETHEMETYPE_DESC = 'activeThemeType_desc',
  _ID_ASC = '_id_asc',
  _ID_DESC = '_id_desc'
}

export type _UserRecentUpdates = {
  __typename?: '_UserRecentUpdates';
  updatedAt?: Maybe<_Neo4jDateTime>;
  updateValue?: Maybe<Scalars['String']>;
  /** Generated field for querying the Neo4j [system id](https://neo4j.com/docs/cypher-manual/current/functions/scalar/#functions-id) of this relationship. */
  _id?: Maybe<Scalars['String']>;
  /** Field for the Concept node this UPDATED [relationship](https://grandstack.io/docs/graphql-relationship-types) is going to. */
  Concept?: Maybe<Concept>;
};

export type _UserUserAccessFilter = {
  AND?: Maybe<Array<_UserUserAccessFilter>>;
  OR?: Maybe<Array<_UserUserAccessFilter>>;
  type?: Maybe<AccessType>;
  type_not?: Maybe<AccessType>;
  type_in?: Maybe<Array<AccessType>>;
  type_not_in?: Maybe<Array<AccessType>>;
  updatedAt?: Maybe<_Neo4jDateTimeInput>;
  updatedAt_not?: Maybe<_Neo4jDateTimeInput>;
  updatedAt_in?: Maybe<Array<_Neo4jDateTimeInput>>;
  updatedAt_not_in?: Maybe<Array<_Neo4jDateTimeInput>>;
  updatedAt_lt?: Maybe<_Neo4jDateTimeInput>;
  updatedAt_lte?: Maybe<_Neo4jDateTimeInput>;
  updatedAt_gt?: Maybe<_Neo4jDateTimeInput>;
  updatedAt_gte?: Maybe<_Neo4jDateTimeInput>;
  Concept?: Maybe<_ConceptFilter>;
};

export type _WidgetFilter = {
  AND?: Maybe<Array<_WidgetFilter>>;
  OR?: Maybe<Array<_WidgetFilter>>;
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Scalars['ID']>>;
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  id_regexp?: Maybe<Scalars['ID']>;
  id_contains?: Maybe<Scalars['ID']>;
  id_not_contains?: Maybe<Scalars['ID']>;
  id_starts_with?: Maybe<Scalars['ID']>;
  id_not_starts_with?: Maybe<Scalars['ID']>;
  id_ends_with?: Maybe<Scalars['ID']>;
  id_not_ends_with?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  name_not?: Maybe<Scalars['String']>;
  name_in?: Maybe<Array<Scalars['String']>>;
  name_not_in?: Maybe<Array<Scalars['String']>>;
  name_regexp?: Maybe<Scalars['String']>;
  name_contains?: Maybe<Scalars['String']>;
  name_not_contains?: Maybe<Scalars['String']>;
  name_starts_with?: Maybe<Scalars['String']>;
  name_not_starts_with?: Maybe<Scalars['String']>;
  name_ends_with?: Maybe<Scalars['String']>;
  name_not_ends_with?: Maybe<Scalars['String']>;
  config?: Maybe<Scalars['String']>;
  config_not?: Maybe<Scalars['String']>;
  config_in?: Maybe<Array<Scalars['String']>>;
  config_not_in?: Maybe<Array<Scalars['String']>>;
  config_regexp?: Maybe<Scalars['String']>;
  config_contains?: Maybe<Scalars['String']>;
  config_not_contains?: Maybe<Scalars['String']>;
  config_starts_with?: Maybe<Scalars['String']>;
  config_not_starts_with?: Maybe<Scalars['String']>;
  config_ends_with?: Maybe<Scalars['String']>;
  config_not_ends_with?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
  value_not?: Maybe<Scalars['String']>;
  value_in?: Maybe<Array<Scalars['String']>>;
  value_not_in?: Maybe<Array<Scalars['String']>>;
  value_regexp?: Maybe<Scalars['String']>;
  value_contains?: Maybe<Scalars['String']>;
  value_not_contains?: Maybe<Scalars['String']>;
  value_starts_with?: Maybe<Scalars['String']>;
  value_not_starts_with?: Maybe<Scalars['String']>;
  value_ends_with?: Maybe<Scalars['String']>;
  value_not_ends_with?: Maybe<Scalars['String']>;
  isTemplate?: Maybe<Scalars['Boolean']>;
  isTemplate_not?: Maybe<Scalars['Boolean']>;
  type?: Maybe<_WidgetTypeFilter>;
  type_not?: Maybe<_WidgetTypeFilter>;
  type_in?: Maybe<Array<_WidgetTypeFilter>>;
  type_not_in?: Maybe<Array<_WidgetTypeFilter>>;
  dashboards?: Maybe<_DashboardViewFilter>;
  dashboards_not?: Maybe<_DashboardViewFilter>;
  dashboards_in?: Maybe<Array<_DashboardViewFilter>>;
  dashboards_not_in?: Maybe<Array<_DashboardViewFilter>>;
  dashboards_some?: Maybe<_DashboardViewFilter>;
  dashboards_none?: Maybe<_DashboardViewFilter>;
  dashboards_single?: Maybe<_DashboardViewFilter>;
  dashboards_every?: Maybe<_DashboardViewFilter>;
};

export type _WidgetInput = {
  id: Scalars['ID'];
};

export enum _WidgetOrdering {
  ID_ASC = 'id_asc',
  ID_DESC = 'id_desc',
  NAME_ASC = 'name_asc',
  NAME_DESC = 'name_desc',
  CONFIG_ASC = 'config_asc',
  CONFIG_DESC = 'config_desc',
  VALUE_ASC = 'value_asc',
  VALUE_DESC = 'value_desc',
  ISTEMPLATE_ASC = 'isTemplate_asc',
  ISTEMPLATE_DESC = 'isTemplate_desc',
  _ID_ASC = '_id_asc',
  _ID_DESC = '_id_desc'
}

export type _WidgetTypeFilter = {
  AND?: Maybe<Array<_WidgetTypeFilter>>;
  OR?: Maybe<Array<_WidgetTypeFilter>>;
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Scalars['ID']>>;
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  id_regexp?: Maybe<Scalars['ID']>;
  id_contains?: Maybe<Scalars['ID']>;
  id_not_contains?: Maybe<Scalars['ID']>;
  id_starts_with?: Maybe<Scalars['ID']>;
  id_not_starts_with?: Maybe<Scalars['ID']>;
  id_ends_with?: Maybe<Scalars['ID']>;
  id_not_ends_with?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  name_not?: Maybe<Scalars['String']>;
  name_in?: Maybe<Array<Scalars['String']>>;
  name_not_in?: Maybe<Array<Scalars['String']>>;
  name_regexp?: Maybe<Scalars['String']>;
  name_contains?: Maybe<Scalars['String']>;
  name_not_contains?: Maybe<Scalars['String']>;
  name_starts_with?: Maybe<Scalars['String']>;
  name_not_starts_with?: Maybe<Scalars['String']>;
  name_ends_with?: Maybe<Scalars['String']>;
  name_not_ends_with?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isActive_not?: Maybe<Scalars['Boolean']>;
  component?: Maybe<Scalars['String']>;
  component_not?: Maybe<Scalars['String']>;
  component_in?: Maybe<Array<Scalars['String']>>;
  component_not_in?: Maybe<Array<Scalars['String']>>;
  component_regexp?: Maybe<Scalars['String']>;
  component_contains?: Maybe<Scalars['String']>;
  component_not_contains?: Maybe<Scalars['String']>;
  component_starts_with?: Maybe<Scalars['String']>;
  component_not_starts_with?: Maybe<Scalars['String']>;
  component_ends_with?: Maybe<Scalars['String']>;
  component_not_ends_with?: Maybe<Scalars['String']>;
  schema?: Maybe<Scalars['String']>;
  schema_not?: Maybe<Scalars['String']>;
  schema_in?: Maybe<Array<Scalars['String']>>;
  schema_not_in?: Maybe<Array<Scalars['String']>>;
  schema_regexp?: Maybe<Scalars['String']>;
  schema_contains?: Maybe<Scalars['String']>;
  schema_not_contains?: Maybe<Scalars['String']>;
  schema_starts_with?: Maybe<Scalars['String']>;
  schema_not_starts_with?: Maybe<Scalars['String']>;
  schema_ends_with?: Maybe<Scalars['String']>;
  schema_not_ends_with?: Maybe<Scalars['String']>;
  inputConfig?: Maybe<Scalars['String']>;
  inputConfig_not?: Maybe<Scalars['String']>;
  inputConfig_in?: Maybe<Array<Scalars['String']>>;
  inputConfig_not_in?: Maybe<Array<Scalars['String']>>;
  inputConfig_regexp?: Maybe<Scalars['String']>;
  inputConfig_contains?: Maybe<Scalars['String']>;
  inputConfig_not_contains?: Maybe<Scalars['String']>;
  inputConfig_starts_with?: Maybe<Scalars['String']>;
  inputConfig_not_starts_with?: Maybe<Scalars['String']>;
  inputConfig_ends_with?: Maybe<Scalars['String']>;
  inputConfig_not_ends_with?: Maybe<Scalars['String']>;
  defaultConfig?: Maybe<Scalars['String']>;
  defaultConfig_not?: Maybe<Scalars['String']>;
  defaultConfig_in?: Maybe<Array<Scalars['String']>>;
  defaultConfig_not_in?: Maybe<Array<Scalars['String']>>;
  defaultConfig_regexp?: Maybe<Scalars['String']>;
  defaultConfig_contains?: Maybe<Scalars['String']>;
  defaultConfig_not_contains?: Maybe<Scalars['String']>;
  defaultConfig_starts_with?: Maybe<Scalars['String']>;
  defaultConfig_not_starts_with?: Maybe<Scalars['String']>;
  defaultConfig_ends_with?: Maybe<Scalars['String']>;
  defaultConfig_not_ends_with?: Maybe<Scalars['String']>;
  defaultValue?: Maybe<Scalars['String']>;
  defaultValue_not?: Maybe<Scalars['String']>;
  defaultValue_in?: Maybe<Array<Scalars['String']>>;
  defaultValue_not_in?: Maybe<Array<Scalars['String']>>;
  defaultValue_regexp?: Maybe<Scalars['String']>;
  defaultValue_contains?: Maybe<Scalars['String']>;
  defaultValue_not_contains?: Maybe<Scalars['String']>;
  defaultValue_starts_with?: Maybe<Scalars['String']>;
  defaultValue_not_starts_with?: Maybe<Scalars['String']>;
  defaultValue_ends_with?: Maybe<Scalars['String']>;
  defaultValue_not_ends_with?: Maybe<Scalars['String']>;
  widgets?: Maybe<_WidgetFilter>;
  widgets_not?: Maybe<_WidgetFilter>;
  widgets_in?: Maybe<Array<_WidgetFilter>>;
  widgets_not_in?: Maybe<Array<_WidgetFilter>>;
  widgets_some?: Maybe<_WidgetFilter>;
  widgets_none?: Maybe<_WidgetFilter>;
  widgets_single?: Maybe<_WidgetFilter>;
  widgets_every?: Maybe<_WidgetFilter>;
};

export type _WidgetTypeInput = {
  id: Scalars['ID'];
};

export enum _WidgetTypeOrdering {
  ID_ASC = 'id_asc',
  ID_DESC = 'id_desc',
  NAME_ASC = 'name_asc',
  NAME_DESC = 'name_desc',
  ISACTIVE_ASC = 'isActive_asc',
  ISACTIVE_DESC = 'isActive_desc',
  COMPONENT_ASC = 'component_asc',
  COMPONENT_DESC = 'component_desc',
  SCHEMA_ASC = 'schema_asc',
  SCHEMA_DESC = 'schema_desc',
  INPUTCONFIG_ASC = 'inputConfig_asc',
  INPUTCONFIG_DESC = 'inputConfig_desc',
  DEFAULTCONFIG_ASC = 'defaultConfig_asc',
  DEFAULTCONFIG_DESC = 'defaultConfig_desc',
  DEFAULTVALUE_ASC = 'defaultValue_asc',
  DEFAULTVALUE_DESC = 'defaultValue_desc',
  _ID_ASC = '_id_asc',
  _ID_DESC = '_id_desc'
}

export type _WorkflowFilter = {
  AND?: Maybe<Array<_WorkflowFilter>>;
  OR?: Maybe<Array<_WorkflowFilter>>;
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Scalars['ID']>>;
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  id_regexp?: Maybe<Scalars['ID']>;
  id_contains?: Maybe<Scalars['ID']>;
  id_not_contains?: Maybe<Scalars['ID']>;
  id_starts_with?: Maybe<Scalars['ID']>;
  id_not_starts_with?: Maybe<Scalars['ID']>;
  id_ends_with?: Maybe<Scalars['ID']>;
  id_not_ends_with?: Maybe<Scalars['ID']>;
  title?: Maybe<Scalars['String']>;
  title_not?: Maybe<Scalars['String']>;
  title_in?: Maybe<Array<Scalars['String']>>;
  title_not_in?: Maybe<Array<Scalars['String']>>;
  title_regexp?: Maybe<Scalars['String']>;
  title_contains?: Maybe<Scalars['String']>;
  title_not_contains?: Maybe<Scalars['String']>;
  title_starts_with?: Maybe<Scalars['String']>;
  title_not_starts_with?: Maybe<Scalars['String']>;
  title_ends_with?: Maybe<Scalars['String']>;
  title_not_ends_with?: Maybe<Scalars['String']>;
  icon?: Maybe<Scalars['String']>;
  icon_not?: Maybe<Scalars['String']>;
  icon_in?: Maybe<Array<Scalars['String']>>;
  icon_not_in?: Maybe<Array<Scalars['String']>>;
  icon_regexp?: Maybe<Scalars['String']>;
  icon_contains?: Maybe<Scalars['String']>;
  icon_not_contains?: Maybe<Scalars['String']>;
  icon_starts_with?: Maybe<Scalars['String']>;
  icon_not_starts_with?: Maybe<Scalars['String']>;
  icon_ends_with?: Maybe<Scalars['String']>;
  icon_not_ends_with?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  description_not?: Maybe<Scalars['String']>;
  description_in?: Maybe<Array<Scalars['String']>>;
  description_not_in?: Maybe<Array<Scalars['String']>>;
  description_regexp?: Maybe<Scalars['String']>;
  description_contains?: Maybe<Scalars['String']>;
  description_not_contains?: Maybe<Scalars['String']>;
  description_starts_with?: Maybe<Scalars['String']>;
  description_not_starts_with?: Maybe<Scalars['String']>;
  description_ends_with?: Maybe<Scalars['String']>;
  description_not_ends_with?: Maybe<Scalars['String']>;
  initialAction?: Maybe<Scalars['String']>;
  initialAction_not?: Maybe<Scalars['String']>;
  initialAction_in?: Maybe<Array<Scalars['String']>>;
  initialAction_not_in?: Maybe<Array<Scalars['String']>>;
  initialAction_regexp?: Maybe<Scalars['String']>;
  initialAction_contains?: Maybe<Scalars['String']>;
  initialAction_not_contains?: Maybe<Scalars['String']>;
  initialAction_starts_with?: Maybe<Scalars['String']>;
  initialAction_not_starts_with?: Maybe<Scalars['String']>;
  initialAction_ends_with?: Maybe<Scalars['String']>;
  initialAction_not_ends_with?: Maybe<Scalars['String']>;
  isDefault?: Maybe<Scalars['Boolean']>;
  isDefault_not?: Maybe<Scalars['Boolean']>;
  stages?: Maybe<_WorkflowStageFilter>;
  stages_not?: Maybe<_WorkflowStageFilter>;
  stages_in?: Maybe<Array<_WorkflowStageFilter>>;
  stages_not_in?: Maybe<Array<_WorkflowStageFilter>>;
  stages_some?: Maybe<_WorkflowStageFilter>;
  stages_none?: Maybe<_WorkflowStageFilter>;
  stages_single?: Maybe<_WorkflowStageFilter>;
  stages_every?: Maybe<_WorkflowStageFilter>;
  categories?: Maybe<_CategoryFilter>;
  categories_not?: Maybe<_CategoryFilter>;
  categories_in?: Maybe<Array<_CategoryFilter>>;
  categories_not_in?: Maybe<Array<_CategoryFilter>>;
  categories_some?: Maybe<_CategoryFilter>;
  categories_none?: Maybe<_CategoryFilter>;
  categories_single?: Maybe<_CategoryFilter>;
  categories_every?: Maybe<_CategoryFilter>;
};

export type _WorkflowInput = {
  id: Scalars['ID'];
};

export enum _WorkflowOrdering {
  ID_ASC = 'id_asc',
  ID_DESC = 'id_desc',
  TITLE_ASC = 'title_asc',
  TITLE_DESC = 'title_desc',
  ICON_ASC = 'icon_asc',
  ICON_DESC = 'icon_desc',
  DESCRIPTION_ASC = 'description_asc',
  DESCRIPTION_DESC = 'description_desc',
  INITIALACTION_ASC = 'initialAction_asc',
  INITIALACTION_DESC = 'initialAction_desc',
  ISDEFAULT_ASC = 'isDefault_asc',
  ISDEFAULT_DESC = 'isDefault_desc',
  _ID_ASC = '_id_asc',
  _ID_DESC = '_id_desc'
}

export type _WorkflowStageFilter = {
  AND?: Maybe<Array<_WorkflowStageFilter>>;
  OR?: Maybe<Array<_WorkflowStageFilter>>;
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Scalars['ID']>>;
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  id_regexp?: Maybe<Scalars['ID']>;
  id_contains?: Maybe<Scalars['ID']>;
  id_not_contains?: Maybe<Scalars['ID']>;
  id_starts_with?: Maybe<Scalars['ID']>;
  id_not_starts_with?: Maybe<Scalars['ID']>;
  id_ends_with?: Maybe<Scalars['ID']>;
  id_not_ends_with?: Maybe<Scalars['ID']>;
  title?: Maybe<Scalars['String']>;
  title_not?: Maybe<Scalars['String']>;
  title_in?: Maybe<Array<Scalars['String']>>;
  title_not_in?: Maybe<Array<Scalars['String']>>;
  title_regexp?: Maybe<Scalars['String']>;
  title_contains?: Maybe<Scalars['String']>;
  title_not_contains?: Maybe<Scalars['String']>;
  title_starts_with?: Maybe<Scalars['String']>;
  title_not_starts_with?: Maybe<Scalars['String']>;
  title_ends_with?: Maybe<Scalars['String']>;
  title_not_ends_with?: Maybe<Scalars['String']>;
  icon?: Maybe<Scalars['String']>;
  icon_not?: Maybe<Scalars['String']>;
  icon_in?: Maybe<Array<Scalars['String']>>;
  icon_not_in?: Maybe<Array<Scalars['String']>>;
  icon_regexp?: Maybe<Scalars['String']>;
  icon_contains?: Maybe<Scalars['String']>;
  icon_not_contains?: Maybe<Scalars['String']>;
  icon_starts_with?: Maybe<Scalars['String']>;
  icon_not_starts_with?: Maybe<Scalars['String']>;
  icon_ends_with?: Maybe<Scalars['String']>;
  icon_not_ends_with?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  description_not?: Maybe<Scalars['String']>;
  description_in?: Maybe<Array<Scalars['String']>>;
  description_not_in?: Maybe<Array<Scalars['String']>>;
  description_regexp?: Maybe<Scalars['String']>;
  description_contains?: Maybe<Scalars['String']>;
  description_not_contains?: Maybe<Scalars['String']>;
  description_starts_with?: Maybe<Scalars['String']>;
  description_not_starts_with?: Maybe<Scalars['String']>;
  description_ends_with?: Maybe<Scalars['String']>;
  description_not_ends_with?: Maybe<Scalars['String']>;
  isFirstStage?: Maybe<Scalars['Boolean']>;
  isFirstStage_not?: Maybe<Scalars['Boolean']>;
  permissionRequired?: Maybe<AccessType>;
  permissionRequired_not?: Maybe<AccessType>;
  permissionRequired_in?: Maybe<Array<AccessType>>;
  permissionRequired_not_in?: Maybe<Array<AccessType>>;
  justificationOptions?: Maybe<Scalars['String']>;
  justificationOptions_not?: Maybe<Scalars['String']>;
  justificationOptions_in?: Maybe<Array<Scalars['String']>>;
  justificationOptions_not_in?: Maybe<Array<Scalars['String']>>;
  justificationOptions_regexp?: Maybe<Scalars['String']>;
  justificationOptions_contains?: Maybe<Scalars['String']>;
  justificationOptions_not_contains?: Maybe<Scalars['String']>;
  justificationOptions_starts_with?: Maybe<Scalars['String']>;
  justificationOptions_not_starts_with?: Maybe<Scalars['String']>;
  justificationOptions_ends_with?: Maybe<Scalars['String']>;
  justificationOptions_not_ends_with?: Maybe<Scalars['String']>;
  justificationRequired?: Maybe<Scalars['Boolean']>;
  justificationRequired_not?: Maybe<Scalars['Boolean']>;
  workflow?: Maybe<_WorkflowFilter>;
  workflow_not?: Maybe<_WorkflowFilter>;
  workflow_in?: Maybe<Array<_WorkflowFilter>>;
  workflow_not_in?: Maybe<Array<_WorkflowFilter>>;
  statuses?: Maybe<_ConceptStatusFilter>;
  statuses_not?: Maybe<_ConceptStatusFilter>;
  statuses_in?: Maybe<Array<_ConceptStatusFilter>>;
  statuses_not_in?: Maybe<Array<_ConceptStatusFilter>>;
  statuses_some?: Maybe<_ConceptStatusFilter>;
  statuses_none?: Maybe<_ConceptStatusFilter>;
  statuses_single?: Maybe<_ConceptStatusFilter>;
  statuses_every?: Maybe<_ConceptStatusFilter>;
  lastOptions?: Maybe<_WorkflowStageFilter>;
  lastOptions_not?: Maybe<_WorkflowStageFilter>;
  lastOptions_in?: Maybe<Array<_WorkflowStageFilter>>;
  lastOptions_not_in?: Maybe<Array<_WorkflowStageFilter>>;
  lastOptions_some?: Maybe<_WorkflowStageFilter>;
  lastOptions_none?: Maybe<_WorkflowStageFilter>;
  lastOptions_single?: Maybe<_WorkflowStageFilter>;
  lastOptions_every?: Maybe<_WorkflowStageFilter>;
  nextOptions?: Maybe<_WorkflowStageFilter>;
  nextOptions_not?: Maybe<_WorkflowStageFilter>;
  nextOptions_in?: Maybe<Array<_WorkflowStageFilter>>;
  nextOptions_not_in?: Maybe<Array<_WorkflowStageFilter>>;
  nextOptions_some?: Maybe<_WorkflowStageFilter>;
  nextOptions_none?: Maybe<_WorkflowStageFilter>;
  nextOptions_single?: Maybe<_WorkflowStageFilter>;
  nextOptions_every?: Maybe<_WorkflowStageFilter>;
};

export type _WorkflowStageInput = {
  id: Scalars['ID'];
};

export enum _WorkflowStageOrdering {
  ID_ASC = 'id_asc',
  ID_DESC = 'id_desc',
  TITLE_ASC = 'title_asc',
  TITLE_DESC = 'title_desc',
  ICON_ASC = 'icon_asc',
  ICON_DESC = 'icon_desc',
  DESCRIPTION_ASC = 'description_asc',
  DESCRIPTION_DESC = 'description_desc',
  ISFIRSTSTAGE_ASC = 'isFirstStage_asc',
  ISFIRSTSTAGE_DESC = 'isFirstStage_desc',
  PERMISSIONREQUIRED_ASC = 'permissionRequired_asc',
  PERMISSIONREQUIRED_DESC = 'permissionRequired_desc',
  JUSTIFICATIONOPTIONS_ASC = 'justificationOptions_asc',
  JUSTIFICATIONOPTIONS_DESC = 'justificationOptions_desc',
  JUSTIFICATIONREQUIRED_ASC = 'justificationRequired_asc',
  JUSTIFICATIONREQUIRED_DESC = 'justificationRequired_desc',
  _ID_ASC = '_id_asc',
  _ID_DESC = '_id_desc'
}

export type UserSignupInfo = {
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  username?: Maybe<Scalars['String']>;
};
