import React, { useRef, useState, useEffect } from "react"
import TextField from "@material-ui/core/TextField"
import Autocomplete from "@material-ui/lab/Autocomplete"
import { makeStyles } from "@material-ui/core/styles"
import { useLazyQuery } from "@apollo/client"
import { Box, Divider, IconButton } from "@material-ui/core"
import SearchIcon from "@material-ui/icons/Search"
import { Concept } from "../__generated__/types"
import { SEARCH_CONCEPTS } from "../graphql/conceptListQueries"
import { useAuth } from "../providers/AuthProvider"
import useAwaitTranslation from "../i18n/useAwaitTranslation"
import ClearIcon from "@material-ui/icons/Clear"
import OpenInBrowserIcon from "@material-ui/icons/OpenInBrowser"
import { InputAdornment, CircularProgress, Grow } from "@material-ui/core"
import SearchModal from "./SearchModal"
import SearchListItem from "./SearchListItem"
import {
    SearchConceptsQuery,
    SearchConceptsQueryVariables,
} from "../graphql/__generated__/conceptListQueries"
const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        flexGrow: 1,
        justifyContent: "flex-end",
        alignItems: "center",
        [theme.breakpoints.up("xs")]: {
            marginLeft: theme.spacing(1),
        },
    },
    searchBar: {
        width: 0,
        overflow: "hidden",
        // marginRight: 5,
        flexGrow: 0,
    },
    autoFocusedSearchBar: {
        flexGrow: 1,
        width: "100%",
    },
    searchBarFocused: {
        flexGrow: 1,
    },
    popper: {
        zIndex: 1500,
    },
    listbox: {
        overflow: "auto",
    },
    avatar: {
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.primary.main,
    },
    open: {
        width: "100%",
        transition: theme.transitions.create("width", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    close: {
        width: 0,
        transition: theme.transitions.create("width", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
}))

interface FuzzyConceptSearchProps {
    onSelection: (item: Concept) => void
    disabledOptionIds?: string[]
    disableAdvancedSearch?: boolean
    autoFocus?: boolean
    onAdvancedSearchOpen?: () => void
    onAdvancedSearchClose?: () => void
    inputAdornment?: JSX.Element
    endAdornment?: JSX.Element
    placeholder?: string
    variant?: "filled" | "standard" | "outlined"
}
export default function FuzzyConceptSearch(props: FuzzyConceptSearchProps) {
    const classes = useStyles()
    const [inputValue, setInputValue] = useState("")
    const [searchedValue, setSearchedValue] = useState("")
    const [options, setOptions] = useState<Concept[]>([])
    const [loadResults, { loading, data }] = useLazyQuery<
        SearchConceptsQuery,
        SearchConceptsQueryVariables
    >(SEARCH_CONCEPTS, {
        fetchPolicy: "no-cache",
    })
    const { currentUser } = useAuth()
    const [advancedSearchOpen, setAdvancedSearchOpen] = useState(false)
    const inputRef: any = useRef()
    const { t } = useAwaitTranslation("concepts")
    const AdornmentIcon = props.inputAdornment ?? <SearchIcon />
    useEffect(() => {
        let active = true
        if (active) {
            if (searchedValue !== inputValue) {
                if (!inputValue || inputValue === "") {
                    setOptions([])
                    return undefined
                }

                loadResults({
                    variables: {
                        searchString: inputValue ?? "*",
                        userId: currentUser.userId,
                    },
                })

                if (data && active) {
                    let newOptions = data.fuzzyConceptSearch ?? []
                    if (active) {
                        setSearchedValue(inputValue)
                        setOptions(newOptions)
                    }
                }
            }
        }
        return () => {
            active = false
        }
    }, [inputValue, loadResults, data, currentUser, searchedValue])
    useEffect(() => {
        if (!!props.autoFocus && !!inputRef?.current) {
            inputRef.current.focus()
        }
    }, [props.autoFocus])
    return (
        <>
            {!advancedSearchOpen && (
                <Autocomplete
                    id="concept-search"
                    classes={{
                        popper: classes.popper,
                        listbox: classes.listbox,
                    }}
                    getOptionDisabled={(option) =>
                        props.disabledOptionIds?.some((id) => id === option.id)
                    }
                    fullWidth
                    size="small"
                    getOptionLabel={(option) =>
                        typeof option === "string" ? option : option.title
                    }
                    disableClearable={true}
                    filterOptions={(x) => x}
                    options={options}
                    freeSolo
                    loading={loading}
                    noOptionsText="No results"
                    forcePopupIcon={false}
                    selectOnFocus
                    disableListWrap
                    includeInputInList
                    clearOnEscape
                    value={null}
                    filterSelectedOptions
                    blurOnSelect={true}
                    onChange={(event: any, newValue: Concept, reason: any) => {
                        if (reason === "select-option") {
                            props.onSelection(newValue)
                            setOptions(options)
                            setInputValue("")
                        } else if (
                            reason === "create-option" &&
                            !props.disableAdvancedSearch
                        ) {
                            setAdvancedSearchOpen(true)
                            if (!!props.onAdvancedSearchOpen) {
                                props.onAdvancedSearchOpen()
                            }
                        }
                    }}
                    inputValue={inputValue}
                    onInputChange={(event, newInputValue) => {
                        if (!advancedSearchOpen) {
                            setInputValue(newInputValue)
                        }
                    }}
                    renderInput={(params) => (
                        <TextField
                            inputRef={inputRef}
                            {...params}
                            fullWidth
                            value={inputValue}
                            variant={props.variant ?? "standard"}
                            placeholder={
                                props.placeholder ?? t("search", "Search")
                            }
                            InputProps={{
                                ...params.InputProps,
                                startAdornment: (
                                    <>
                                        <InputAdornment position="start">
                                            {AdornmentIcon}
                                        </InputAdornment>
                                        {params.InputProps.startAdornment}
                                    </>
                                ),
                                endAdornment: (
                                    <>
                                        {params.InputProps.endAdornment}
                                        <Box display="flex" alignItems="center">
                                            <Grow in={loading}>
                                                <CircularProgress
                                                    size={20}
                                                    disableShrink
                                                />
                                            </Grow>
                                            <Grow in={inputValue.length > 0}>
                                                <IconButton
                                                    style={{
                                                        marginRight: "5px",
                                                        marginLeft: "5px",
                                                    }}
                                                    onClick={() => {
                                                        setInputValue("")
                                                    }}
                                                    size="small"
                                                >
                                                    <ClearIcon />
                                                </IconButton>
                                            </Grow>
                                            {!!props.endAdornment ? (
                                                <InputAdornment position="end">
                                                    {props.endAdornment}
                                                </InputAdornment>
                                            ) : (
                                                !props.disableAdvancedSearch && (
                                                    <>
                                                        <Divider
                                                            orientation="vertical"
                                                            flexItem
                                                        />
                                                        <IconButton
                                                            style={{
                                                                marginLeft:
                                                                    "5px",
                                                            }}
                                                            onClick={() => {
                                                                setAdvancedSearchOpen(
                                                                    true
                                                                )
                                                                if (
                                                                    !!props.onAdvancedSearchOpen
                                                                ) {
                                                                    props.onAdvancedSearchOpen()
                                                                }
                                                            }}
                                                            size="small"
                                                        >
                                                            <OpenInBrowserIcon />
                                                        </IconButton>
                                                    </>
                                                )
                                            )}
                                        </Box>
                                    </>
                                ),
                            }}
                        />
                    )}
                    renderOption={(option) => {
                        return (
                            <SearchListItem
                                concept={option}
                                key={option.id}
                                inputValue={inputValue}
                            />
                        )
                    }}
                />
            )}
            {!!advancedSearchOpen && (
                <SearchModal
                    open={advancedSearchOpen}
                    onClose={() => {
                        setAdvancedSearchOpen(false)
                        if (!!props.onAdvancedSearchClose) {
                            props.onAdvancedSearchClose()
                        }
                    }}
                    onSelect={props.onSelection}
                    currentString={inputValue?.length > 0 ? inputValue : null}
                />
            )}
        </>
    )
}
