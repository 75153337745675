import React from "react"
import { useTheme } from "@material-ui/core/styles"

import { Box, Typography, ButtonBase } from "@material-ui/core"

import { Concept } from "../__generated__/types"

import ConceptAvatar from "./ConceptAvatar"

interface BreadcrumbProps {
    breadcrumb: Concept
    onClick: () => void
}
const Breadcrumb = (props: BreadcrumbProps) => {
    const theme = useTheme()
    const { breadcrumb, onClick } = props

    return (
        <ButtonBase
            key={breadcrumb.id}
            style={{
                backgroundColor: theme.palette.background.paper,
                borderRadius: theme.shape.borderRadius,
                border: `1px solid ${theme.palette.divider}`,
                padding: theme.spacing(0.75),
                margin: theme.spacing(0.5),
                display: "flex",
                alignItems: "center",
            }}
            onClick={onClick}
        >
            <ConceptAvatar concept={breadcrumb} variant="rounded" />

            <Box textAlign={"left"} ml={1}>
                <Typography
                    noWrap
                    variant="body2"
                    color="textSecondary"
                    style={{ fontSize: 12 }}
                >
                    {breadcrumb.category?.name}
                </Typography>

                <Box maxWidth="10em" display="flex" alignItems={"center"}>
                    <Typography noWrap variant="body2" style={{ fontSize: 12 }}>
                        {breadcrumb.title}
                    </Typography>
                </Box>
            </Box>
        </ButtonBase>
    )
}

export default Breadcrumb
