import React, { useCallback, useMemo } from "react"
import { Box, debounce } from "@material-ui/core"
import RichTextEditor, {
    BASE_INITIAL_TEXT,
} from "../../text-editor/RichTextEditor"
import { useParams } from "react-router-dom"
import { ChatMessage } from "../../../__generated__/types"
import { useInputTools } from "../useInputTools"
import { useApolloClient, useReactiveVar } from "@apollo/client"
import { CONCEPT_BY_ID } from "../../../graphql/queries"
import {
    ConceptQuery,
    ConceptQueryVariables,
} from "../../../graphql/__generated__/queries"
import { getFieldPrompt } from "../../../util/PromptFunctions"
import { TextResponseInterfaceProps } from "../../criteria/useCriteriaTypes"
import {
    allPageConceptConnectionsVar,
    myCurrentInputResponseLabelVar,
    myCurrentInputResponseVar,
    workspaceLoadTimeVar,
} from "../../../providers/GlobalState"

const TextResponseInterface: React.FunctionComponent<
    TextResponseInterfaceProps
> = ({ score, isDialog, config, multiInterface: isSubfield }) => {
    const { conceptId: pageConceptId } = useParams()
    const { currentResponseData } = useReactiveVar(myCurrentInputResponseVar)
    const { connections } = useReactiveVar(allPageConceptConnectionsVar)
    const initialWorkspaceLoadTime = useReactiveVar(workspaceLoadTimeVar)
    const currentLabel = useReactiveVar(myCurrentInputResponseLabelVar)
    const client = useApolloClient()
    const pageConcept =
        client.readQuery<ConceptQuery, ConceptQueryVariables>({
            query: CONCEPT_BY_ID,
            variables: {
                id: pageConceptId,
            },
        })?.Concept?.[0] ?? null
    let conceptToEvaluate
    if (isSubfield) {
        conceptToEvaluate = score?.scoredConcept ?? null
    } else {
        conceptToEvaluate = pageConcept
    }

    const { onEditResponse } = useInputTools({})

    const initialText = JSON.stringify(BASE_INITIAL_TEXT)

    const onChatChange = useCallback(
        (value: ChatMessage[]) => {
            if (!!score) {
                let updateData = {
                    chatHistory: JSON.stringify(value),
                }

                onEditResponse(
                    {
                        ...updateData,
                    },
                    score
                )
            }
        },
        [score, onEditResponse]
    )

    const currentChatMessages: ChatMessage[] = useMemo(() => {
        return JSON.parse(score?.chatHistory || "[]")
    }, [score?.chatHistory])

    const onResponseChange = debounce(
        useCallback(
            (value: string) => {
                if (!!score) {
                    let updateData = {
                        response: value,
                    }
                    onEditResponse(
                        {
                            ...updateData,
                        },
                        score
                    )
                }
            },
            [score, onEditResponse]
        ),
        1000
    )
    return (
        <Box
            display="flex"
            flexDirection={"column"}
            height="100%"
            key={score?.id}
            mt={2}
            style={{ overflowY: "auto" }}
        >
            <Box
                p={2}
                pt={0}
                pb={1}
                height="100%"
                display={"flex"}
                overflow="hidden"
                flexGrow={1}
                minHeight={100}
                id={score?.id}
            >
                <RichTextEditor
                    editable={true}
                    editorId={
                        (score?.id || config?.name) + JSON.stringify(!!isDialog)
                    }
                    initialValue={score?.response || initialText}
                    aiPrompt={
                        !!conceptToEvaluate
                            ? getFieldPrompt({
                                  pageConcept,
                                  conceptToEvaluate,
                                  config,
                                  workspaceFields: currentResponseData,
                                  currentLabel,
                                  connections,
                                  initialWorkspaceLoadTime,
                              })
                            : null
                    }
                    placeholderText="Please enter your response"
                    onChatMessage={onChatChange}
                    currentChatMessages={currentChatMessages}
                    updateWhileEditing={true}
                    onChange={onResponseChange}
                />
            </Box>
        </Box>
    )
}

export default TextResponseInterface
