import React from "react"
import { makeStyles, Link, Chip } from "@material-ui/core"
import { Concept } from "../../__generated__/types"

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
        display: "flex",
        flexWrap: "wrap",
    },
    tag: {
        fontWeight: theme.typography.fontWeightMedium as any,
        marginRight: theme.spacing(1),
        "&::before": {
            content: '"#"',
        },
    },
    chip: {
        backgroundColor: theme.palette.primary.main,
    },
}))

interface Props {
    concept?: Concept
    chip?: boolean
}

const TagList = (props: Props) => {
    const classes = useStyles()
    const [tags, setTags] = React.useState([])

    React.useEffect(() => {
        if (props.concept) {
            setTags(props.concept?.tags?.map((t) => t.name))
        }
    }, [setTags, props.concept])

    return (
        <div className={classes.root}>
            {tags.map((tag, idx) => {
                if (!!props.chip) {
                    return (
                        <Chip
                            key={`${tag}-${props.concept?.id}`}
                            color="primary"
                            label={
                                <span
                                    style={{ whiteSpace: "normal" }}
                                    className={classes.tag}
                                >
                                    {tag}
                                </span>
                            }
                            style={{
                                margin: "2px",
                            }}
                            size="small"
                        />
                    )
                } else {
                    return (
                        <Link
                            key={`${tag}-${props.concept?.id}`}
                            className={classes.tag}
                        >
                            {tag}
                        </Link>
                    )
                }
            })}
        </div>
    )
}

export default TagList
