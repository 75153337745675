import React, { useEffect, useState } from "react"

import { toCapitalizedWords } from "../../util/fns"
import {
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    Tooltip,
    Box,
} from "@material-ui/core"
import { useParams } from "react-router-dom"
interface SourceSelectorProps {
    onChange: (value: string) => void
    value: string
    options: string[]
}

export default function SourceSelector(props: SourceSelectorProps) {
    const { conceptId } = useParams()
    const [disabled, setDisabled] = useState(false)
    useEffect(() => {
        if (!conceptId && !disabled) {
            setDisabled(true)
            props.onChange("allConcepts")
        }
    }, [conceptId, props, disabled])
    return (
        <Tooltip
            title={
                disabled
                    ? "Connections currently not enabled for user and team concepts"
                    : ""
            }
        >
            <Box>
                <FormControl fullWidth variant="outlined">
                    <InputLabel id={`source-label`}>Source</InputLabel>
                    <Select
                        labelId={`source-label`}
                        id={`source-select`}
                        value={props.value ?? "allConcepts"}
                        label={"Source"}
                        disabled={!!disabled}
                        name={"Source"}
                        onChange={(e) =>
                            props.onChange(e.target.value as string)
                        }
                    >
                        {props.options?.map((value) => (
                            <MenuItem value={value} key={value}>
                                {toCapitalizedWords(value)}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Box>
        </Tooltip>
    )
}
