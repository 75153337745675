import React, { useState, useEffect, useCallback, useLayoutEffect } from "react"
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles"
import { Box, Grid, GridSize, IconButton } from "@material-ui/core"
import { useQuery } from "@apollo/client"
import { CONNECTION_WIDGET_QUERY } from "../../../graphql/queries"
import {
    ConnectionWidgetQueryQuery,
    ConnectionWidgetQueryQueryVariables,
} from "../../../graphql/__generated__/queries"
import {
    Concept,
    _ConceptFilter,
    _ConceptOrdering,
} from "../../../__generated__/types"
import ChevronRightIcon from "@material-ui/icons/ChevronRight"
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft"
import Fade from "@material-ui/core/Fade"
import useResizeObserver from "@react-hook/resize-observer"
import ErrorHandler from "../../ErrorHandler"
import ConceptCardBaseBody from "../../ConceptCardBaseBody"
import { useHistory } from "react-router-dom"
import { useAuth } from "../../../providers/AuthProvider"
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        conceptCard: {
            border: `1px solid ${theme.palette.divider}`,
            borderRadius: theme.shape.borderRadius,
            height: "100%",
        },
        conceptCardContainer: {
            padding: theme.spacing(0, 1),
            height: "100%",
            width: "100%",
            overflowY: "auto",
        },
    })
)

const useSize = (target) => {
    const [size, setSize] = useState(null)

    useLayoutEffect(() => {
        if (!!target?.current) {
            setSize(target.current.getBoundingClientRect())
        }
    }, [target])

    // Where the magic happens
    useResizeObserver(target, (entry) => setSize(entry.contentRect))
    return size
}

const INITIAL_PAGE_SIZE = 3
const BASE_CARD_WIDTH = 300
export default function GalleryView(props: {
    filter: _ConceptFilter
    orderBy: _ConceptOrdering
    fixedItems?: Concept[]
    skipQuery?: boolean
}) {
    //imported hooks, refs, etc.
    const classes = useStyles()
    const history = useHistory()
    const { isNonMember } = useAuth()
    //state
    const [target, setTarget] = useState(null)
    const size = useSize(target)
    const [filter, setFilter] = useState<_ConceptFilter>({})
    const [limit, setLimit] = useState(INITIAL_PAGE_SIZE)
    const [pageSize, setPageSize] = useState(INITIAL_PAGE_SIZE)
    const [offset, setOffset] = useState(0)
    const [overflowed, setOverflowed] = useState(false)

    //queries
    const { error, data, fetchMore } = useQuery<
        ConnectionWidgetQueryQuery,
        ConnectionWidgetQueryQueryVariables
    >(CONNECTION_WIDGET_QUERY, {
        variables: {
            offset: 0,
            first: limit + 1,
            connectionsFilter: filter,
            orderBy: [props.orderBy],
        },
        fetchPolicy: "cache-and-network",
        skip: !!props.skipQuery,
    })

    //variables
    const listData = [...(props.fixedItems ?? []), ...(data?.Concept ?? [])]

    const containsImage =
        listData.filter(
            (item, index) =>
                index >= offset && index < offset + pageSize && !!item.imageUrl
        )?.length > 0
    let cutoff = containsImage ? 450 : 300

    //use effects
    useEffect(() => {
        if (size?.height < cutoff) {
            setOverflowed(true)
        } else {
            setOverflowed(false)
        }
    }, [size?.height, cutoff])

    useEffect(() => {
        if (JSON.stringify(props.filter) !== JSON.stringify(filter)) {
            setFilter(props.filter)
            setOffset(0)
        }
    }, [props.filter, filter])

    useEffect(() => {
        if (!!size?.width) {
            const newPageSize = Math.round(size.width / BASE_CARD_WIDTH)
            setPageSize(newPageSize > 3 ? 3 : newPageSize)
        }
    }, [size?.width])

    //functions
    const handleNext = async () => {
        setOffset((prev) => prev + pageSize)

        await onLoadMore()
    }
    const handleBack = () => {
        setOffset((prev) => prev - pageSize)
    }
    const onLoadMore = useCallback(async () => {
        if (!fetchMore) return
        await fetchMore({
            variables: {
                offset: listData.length,
            },
        })
        setLimit(listData.length + pageSize)
    }, [fetchMore, listData.length, pageSize])
    if (error) {
        return <ErrorHandler message={error.message} showMessage={true} />
    }
    return (
        <Box height="100%" display="flex" flexDirection={"column"}>
            <Grid
                container
                className={classes.conceptCardContainer}
                ref={setTarget}
            >
                {listData?.map((item, index) => {
                    if (index >= offset && index < offset + pageSize) {
                        return (
                            <Fade in={true} key={item.id + "_fade"}>
                                <Grid
                                    item
                                    xs={Math.round(12 / pageSize) as GridSize}
                                    key={item.id}
                                    style={{ padding: 12 }}
                                >
                                    <Box className={classes.conceptCard}>
                                        <ConceptCardBaseBody
                                            item={item}
                                            condensed={overflowed}
                                            onClick={
                                                !isNonMember
                                                    ? (concept: Concept) =>
                                                          history.push(
                                                              `/concept/${concept.id}/home`
                                                          )
                                                    : undefined
                                            }
                                        />
                                    </Box>
                                </Grid>
                            </Fade>
                        )
                    } else {
                        return null
                    }
                })}
            </Grid>

            <Box display="flex" justifyContent="center" alignItems="center">
                <IconButton onClick={handleBack} disabled={offset === 0}>
                    <ChevronLeftIcon />
                </IconButton>
                <IconButton
                    onClick={handleNext}
                    disabled={listData.length <= offset + pageSize}
                >
                    <ChevronRightIcon />
                </IconButton>
            </Box>
        </Box>
    )
}
