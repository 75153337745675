import {
    Box,
    Button,
    TextField,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    CircularProgress,
    DialogContentText,
} from "@material-ui/core"
import { useEffect, useState } from "react"
import { Workflow } from "../../__generated__/types"

import useWorkflowTools from "./useWorkflowTools"
import DeleteForever from "@material-ui/icons/DeleteForever"

const WorkflowForm = (props: {
    workflow?: Workflow
    onSave?: () => Promise<void>
    onClose: () => void
}) => {
    const { onSave, onClose, workflow } = props
    const {
        onCreateWorkflow,
        onUpdateWorkflow,
        deleteWorkflow,
        deletingWorkflow,
        creatingWorkflow,
        updatingWorkflow,
    } = useWorkflowTools({
        fetch: false,
    })

    const [confirmDeletion, setConfirmDeletion] = useState(false)
    const [configuration, setConfiguration] = useState({
        title: "",
        description: "",
        initialAction: "",
    })
    const handleSubmission = async () => {
        if (!!workflow) {
            await onUpdateWorkflow({
                ...configuration,
                id: workflow.id,
            })
        } else {
            await onCreateWorkflow(configuration)
        }
        if (!!onSave) {
            await onSave()
        }

        onClose()
    }
    const handleDeletion = async () => {
        await deleteWorkflow({
            variables: {
                id: workflow.id,
            },
        })
        onClose()
    }
    useEffect(() => {
        if (!!workflow) {
            setConfiguration({
                title: workflow.title,
                description: workflow.description,
                initialAction: workflow.initialAction,
            })
        }
    }, [workflow])
    return (
        <Dialog fullWidth={!confirmDeletion} open={true}>
            {!!confirmDeletion ? (
                <DialogContent>
                    <DialogContentText>
                        Are you sure you want to delete this workflow?
                    </DialogContentText>
                    <DialogActions>
                        <Button
                            disabled={!!deletingWorkflow}
                            onClick={() => setConfirmDeletion(false)}
                        >
                            No
                        </Button>
                        <Button
                            disabled={!!deletingWorkflow}
                            onClick={handleDeletion}
                        >
                            {!!deletingWorkflow ? (
                                <CircularProgress size={20} disableShrink />
                            ) : (
                                "Yes"
                            )}
                        </Button>
                    </DialogActions>
                </DialogContent>
            ) : (
                <>
                    <DialogTitle>
                        {!!workflow ? "Workflow Editor" : "Workflow Form"}
                    </DialogTitle>
                    <DialogContent>
                        <Box p={2}>
                            <TextField
                                fullWidth
                                size="small"
                                variant="outlined"
                                onChange={(e) =>
                                    setConfiguration({
                                        ...configuration,
                                        title: e.target.value,
                                    })
                                }
                                required
                                label="Title"
                                value={configuration.title}
                            />
                        </Box>
                        <Box p={2}>
                            <TextField
                                fullWidth
                                variant="outlined"
                                size="small"
                                onChange={(e) =>
                                    setConfiguration({
                                        ...configuration,
                                        description: e.target.value,
                                    })
                                }
                                multiline
                                rows={3}
                                label="Description"
                                value={configuration.description}
                            />
                        </Box>
                        <Box p={2}>
                            <TextField
                                size="small"
                                fullWidth
                                required
                                variant="outlined"
                                onChange={(e) =>
                                    setConfiguration({
                                        ...configuration,
                                        initialAction: e.target.value,
                                    })
                                }
                                label="Initial Action Prompt"
                                value={configuration.initialAction}
                            />
                        </Box>
                    </DialogContent>
                    <DialogActions
                        style={{
                            display: "flex",
                            justifyContent: !!workflow
                                ? "space-between"
                                : "flex-end",
                            alignItems: "center",
                        }}
                    >
                        {!!workflow && (
                            <Button
                                onClick={() => setConfirmDeletion(true)}
                                startIcon={<DeleteForever color="error" />}
                            >
                                Delete
                            </Button>
                        )}
                        <Box>
                            <Box>
                                <Button
                                    disabled={
                                        updatingWorkflow || creatingWorkflow
                                    }
                                    onClick={onClose}
                                >
                                    Cancel
                                </Button>
                                <Button
                                    disabled={
                                        configuration.title.length === 0 ||
                                        configuration.initialAction.length === 0
                                    }
                                    color="primary"
                                    onClick={handleSubmission}
                                >
                                    {!!updatingWorkflow || creatingWorkflow ? (
                                        <CircularProgress
                                            size={20}
                                            disableShrink={true}
                                        />
                                    ) : (
                                        "Save"
                                    )}
                                </Button>
                            </Box>
                        </Box>
                    </DialogActions>
                </>
            )}
        </Dialog>
    )
}

export default WorkflowForm
