import {
    Box,
    IconButton,
    CircularProgress,
    FormControl,
    DialogTitle,
    Dialog,
    Button,
    DialogActions,
    DialogContent,
    Typography,
    useMediaQuery,
} from "@material-ui/core"
import { makeStyles, useTheme } from "@material-ui/core/styles"
import CloseIcon from "@material-ui/icons/Close"
import React, { useState } from "react"
import FetchLink from "./FetchLink"
import TagInput from "./tags/TagInput"
import Autocomplete from "@material-ui/lab/Autocomplete"
import TextField from "@material-ui/core/TextField"
import {
    AccessType,
    Category,
    Concept,
    LanguageString,
    Link,
    SystemRole,
} from "../__generated__/types"
import { Loading } from "./Loading"

import CategoryTreeSelector from "./categories/CategoryTreeSelector"
import ImageUploadDialog from "./images/ImageUploadDialog"
import ClearIcon from "@material-ui/icons/Clear"
import EditIcon from "@material-ui/icons/Edit"
import useAwaitTranslation from "../i18n/useAwaitTranslation"
import useConceptTools from "../util/useConceptTools"
import useEnvironmentSettingTools from "./settings/useEnvironmentSettingTools"
import Public from "@material-ui/icons/Public"
import VpnLock from "@material-ui/icons/VpnLock"
import Send from "@material-ui/icons/Send"
import useCategoryTools from "./categories/useCategoryTools"
import { useAuth } from "../providers/AuthProvider"
import { useMutation } from "@apollo/client"
import {
    ADD_CONCEPT_TRANSLATION,
    CREATE_CONCEPT_TRANSLATION,
} from "../graphql/mutations"
import {
    AddConceptTranslationMutation,
    AddConceptTranslationMutationVariables,
    CreateConceptTranslationMutation,
    CreateConceptTranslationMutationVariables,
} from "../graphql/__generated__/mutations"
import { LanguageDataObject, LanguageTitle } from "../util/types"
import TranslatableContentForm from "./forms/TranslatableContentForm"

const useStyles = makeStyles((theme) => ({
    titleContainer: {
        [theme.breakpoints.down("sm")]: {
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
        },
    },
    fieldContainer: {
        margin: theme.spacing(2),
    },
    richTextEditor: {
        margin: theme.spacing(2),
        display: "flex",
        flexDirection: "column",
        minHeight: "15vh",
        overflow: "hidden",
    },
    label: {
        fontSize: 12,
        color: theme.palette.text.secondary,
        padding: theme.spacing(0.5),
    },
    imageArea: {
        borderRadius: theme.shape.borderRadius,
        border: "1px solid",
        borderColor: theme.palette.divider,
        textAlign: "center",
    },

    imageContainer: {
        width: "50%",
        margin: "auto",
    },
}))

interface LongConceptFormProps {
    onClose: () => void
    openSnackbar: (concept: Concept, categoryId: string) => void
}
const LongConceptForm: React.FunctionComponent<LongConceptFormProps> = ({
    onClose,
    openSnackbar,
}) => {
    const classes = useStyles()
    const { t } = useAwaitTranslation("concepts")
    const theme = useTheme()
    const mobile = useMediaQuery(theme.breakpoints.down("sm"))
    const { ableToPublish } = useEnvironmentSettingTools()
    const { currentUser } = useAuth()
    const { environmentCategories } = useCategoryTools()
    const { onConceptCreation, getConceptFromLinkItem } = useConceptTools()
    const [submitting, setSubmitting] = useState(false)
    const [languages, setLanguages] = useState<LanguageTitle[]>([])
    const [createFromUrl, setCreateFromUrl] = useState(false)
    const [category, setCategory] = useState<Category>(null)
    const [imageOpen, setImageOpen] = useState(false)
    const [tags, setTags] = useState<Array<string>>([])
    const [linkItem, setLinkItem] = useState(null)
    const [linkLoading, setLinkLoading] = useState(false)
    const [isPublic, setIsPublic] = useState(true)
    const [english, setEnglish] = useState<LanguageDataObject>({
        title: "",
        summary: "",
        description: "",
        imageUrl: null,
    })
    const [german, setGerman] = useState<LanguageDataObject>({
        title: "",
        summary: "",
        description: "",
        imageUrl: null,
    })
    const [spanish, setSpanish] = useState<LanguageDataObject>({
        title: "",
        summary: "",
        description: "",
        imageUrl: null,
    })

    const [createTranslation] = useMutation<
        CreateConceptTranslationMutation,
        CreateConceptTranslationMutationVariables
    >(CREATE_CONCEPT_TRANSLATION)
    const [addTranslation] = useMutation<
        AddConceptTranslationMutation,
        AddConceptTranslationMutationVariables
    >(ADD_CONCEPT_TRANSLATION)

    const handleSubmission = async () => {
        setSubmitting(true)

        const newConcept = await onConceptCreation({
            data: {
                ...{
                    isPublic: !!isPublic && !!ableToPublish,
                    isPublicAccessType:
                        !!isPublic && !!ableToPublish
                            ? AccessType.VIEWER
                            : AccessType.NONE,
                },
                ...english,
            },
            linkItem: linkItem,
            category: category,
            openSnackbar,
            tags: tags,
        })
        if (!!newConcept) {
            if (languages.includes(LanguageTitle.de)) {
                const {
                    data: { CreateTranslation: NewTranslation },
                } = await createTranslation({
                    variables: {
                        languageString: LanguageString.DE,
                        title: german.title,
                        summary: german.summary,
                        description: german.description,
                    },
                })
                await addTranslation({
                    variables: {
                        conceptId: newConcept.id,
                        translationId: NewTranslation.id,
                    },
                })
            }
            if (languages.includes(LanguageTitle.es)) {
                const {
                    data: { CreateTranslation: NewTranslation },
                } = await createTranslation({
                    variables: {
                        languageString: LanguageString.ES,
                        title: spanish.title,
                        summary: spanish.summary,
                        description: spanish.description,
                    },
                })
                await addTranslation({
                    variables: {
                        conceptId: newConcept.id,
                        translationId: NewTranslation.id,
                    },
                })
            }
            setSubmitting(false)
            onClose()
        }
        onClose()
    }
    const handleLink = (linkData: Link) => {
        setLinkItem(linkData)
        setEnglish({ ...english, ...getConceptFromLinkItem(linkData) })
        setLinkLoading(false)
    }

    return (
        <Dialog open={true} fullScreen>
            {submitting ? (
                <Loading size={25} />
            ) : (
                <>
                    <DialogTitle>
                        <Box
                            display="flex"
                            justifyContent="space-between"
                            alignItems="center"
                            flexDirection={mobile ? "column" : "row"}
                        >
                            <Box
                                mb={mobile ? 2 : 0}
                                className={classes.titleContainer}
                            >
                                <Typography variant="h5">
                                    {t("conceptForm", "Concept Form")}
                                </Typography>
                                {mobile && (
                                    <IconButton onClick={() => onClose()}>
                                        <CloseIcon />
                                    </IconButton>
                                )}
                            </Box>
                            <Box
                                display="flex"
                                justifyContent="flex-end"
                                alignItems="center"
                            >
                                <Box
                                    display="flex"
                                    mr={2}
                                    width={mobile ? "100%" : "auto"}
                                >
                                    <Autocomplete
                                        size="small"
                                        style={{
                                            marginRight: 10,
                                            minWidth: "12rem",
                                        }}
                                        getOptionLabel={(option: string) =>
                                            option
                                        }
                                        fullWidth={mobile}
                                        multiple
                                        onChange={(
                                            _,
                                            newValue: LanguageTitle[]
                                        ) => {
                                            setLanguages(newValue)
                                        }}
                                        value={languages}
                                        options={[
                                            LanguageTitle.de,
                                            LanguageTitle.es,
                                        ]}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                fullWidth
                                                value={languages}
                                                variant="outlined"
                                                label={t(
                                                    "languages",
                                                    "Languages"
                                                )}
                                                placeholder={t(
                                                    "searchLanguages",
                                                    "Search Languages"
                                                )}
                                            />
                                        )}
                                    />
                                    <Button
                                        variant="outlined"
                                        onClick={() => setCreateFromUrl(true)}
                                    >
                                        {t("createFromURL", "Create from URL")}
                                    </Button>
                                </Box>
                                {!mobile && (
                                    <IconButton onClick={() => onClose()}>
                                        <CloseIcon />
                                    </IconButton>
                                )}
                            </Box>
                        </Box>
                    </DialogTitle>
                    <DialogContent dividers>
                        <Box overflow="hidden">
                            {createFromUrl && (
                                <Box className={classes.fieldContainer}>
                                    <Box display="flex" alignItems="center">
                                        <FetchLink
                                            setLoading={setLinkLoading}
                                            handleLink={(link: Link) => {
                                                handleLink(link)
                                            }}
                                            autoFocus={true}
                                        />
                                        <IconButton
                                            size="small"
                                            onClick={() => {
                                                setCreateFromUrl(false)
                                                setLinkItem(null)
                                            }}
                                        >
                                            <CloseIcon fontSize="small" />
                                        </IconButton>
                                    </Box>
                                    {linkLoading && (
                                        <Box
                                            m={1}
                                            display="flex"
                                            justifyContent="center"
                                            alignItems="center"
                                        >
                                            <CircularProgress
                                                disableShrink
                                                size="1em"
                                            />{" "}
                                            {t(
                                                "fetchingLinkDetails",
                                                "Fetching Link Details..."
                                            )}
                                        </Box>
                                    )}
                                </Box>
                            )}

                            <Box className={classes.fieldContainer}>
                                <FormControl
                                    variant="outlined"
                                    required
                                    fullWidth
                                >
                                    <CategoryTreeSelector
                                        value={category?.id ?? null}
                                        setValue={setCategory}
                                        hiddenOptions={
                                            environmentCategories
                                                ?.filter(
                                                    (x) =>
                                                        currentUser.role !==
                                                            SystemRole.ADMIN &&
                                                        !!x.isManaged
                                                )
                                                ?.map((o) => o.id) || []
                                        }
                                    />
                                </FormControl>
                            </Box>

                            <Box
                                display="flex"
                                mt={5}
                                mb={5}
                                flexDirection={mobile ? "column" : "row"}
                            >
                                <Box width="100%">
                                    <TranslatableContentForm
                                        setItem={setEnglish}
                                        item={english}
                                        language={LanguageTitle.en}
                                        categoryName={category?.name || "idea"}
                                        disabled={false}
                                    />
                                </Box>
                                {languages.length > 0 &&
                                    languages.map((item, index) => {
                                        const setItem =
                                            item === LanguageTitle.de
                                                ? setGerman
                                                : item === LanguageTitle.es
                                                ? setSpanish
                                                : null
                                        const currentItem =
                                            item === LanguageTitle.de
                                                ? german
                                                : item === LanguageTitle.es
                                                ? spanish
                                                : null

                                        return (
                                            <Box key={index} width="100%">
                                                <TranslatableContentForm
                                                    setItem={setItem}
                                                    item={currentItem}
                                                    language={item}
                                                    categoryName={
                                                        category?.name || "idea"
                                                    }
                                                    disabled={false}
                                                />
                                            </Box>
                                        )
                                    })}
                            </Box>
                            <Box className={classes.imageContainer}>
                                {!!english.imageUrl && (
                                    <Box
                                        display="flex"
                                        justifyContent="flex-end"
                                        alignItems="center"
                                    >
                                        <IconButton
                                            onClick={() => {
                                                setImageOpen(true)
                                            }}
                                        >
                                            <EditIcon />
                                        </IconButton>
                                        <IconButton
                                            onClick={(e) => {
                                                e.stopPropagation()
                                                setEnglish({
                                                    ...english,
                                                    imageUrl: null,
                                                })
                                            }}
                                        >
                                            <ClearIcon />
                                        </IconButton>
                                    </Box>
                                )}
                                <Box
                                    className={classes.imageArea}
                                    p={mobile ? 10 : 20}
                                    style={{
                                        backgroundSize: "cover",
                                        backgroundPosition: "center",
                                        backgroundImage: `url(${english.imageUrl})`,
                                        cursor: !!english.imageUrl
                                            ? "default"
                                            : "pointer",
                                    }}
                                    onClick={() => {
                                        if (!english.imageUrl) {
                                            setImageOpen(true)
                                        }
                                    }}
                                    position="relative"
                                >
                                    {!english.imageUrl?.length &&
                                        "Click to add image"}
                                </Box>
                            </Box>
                            <Box className={classes.fieldContainer}>
                                <TagInput
                                    setTags={setTags}
                                    disabled={false}
                                    clickable={false}
                                />
                            </Box>
                        </Box>
                    </DialogContent>
                    <DialogActions>
                        {!!ableToPublish && (
                            <Box mr={2}>
                                <Button
                                    onClick={() => setIsPublic((prev) => !prev)}
                                    variant="outlined"
                                    startIcon={
                                        !!isPublic ? (
                                            <Public color={"primary"} />
                                        ) : (
                                            <VpnLock color="disabled" />
                                        )
                                    }
                                >
                                    {!!isPublic ? "Public" : "Private"}
                                </Button>
                            </Box>
                        )}
                        <Button
                            disabled={english.title?.length === 0}
                            onClick={handleSubmission}
                            endIcon={<Send />}
                        >
                            Submit
                        </Button>
                    </DialogActions>
                </>
            )}
            {!!imageOpen && (
                <ImageUploadDialog
                    onClose={() => setImageOpen(false)}
                    onImageUploaded={(imageUrl: string) => {
                        setEnglish({
                            ...english,
                            imageUrl: imageUrl,
                        })
                        setImageOpen(false)
                    }}
                />
            )}
        </Dialog>
    )
}

export default LongConceptForm
