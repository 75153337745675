import React, { useState, useEffect } from "react"
import { useMutation } from "@apollo/client"
import {
    createStyles,
    Theme,
    makeStyles,
    useTheme,
} from "@material-ui/core/styles"
import Box from "@material-ui/core/Box"
import { ReactComponent as GlobalFilterIcon } from "../../styles/FilterIcon.svg"
import ClearIcon from "@material-ui/icons/Clear"
import SupervisedUserCircleIcon from "@material-ui/icons/SupervisedUserCircle"
import {
    DialogContent,
    Dialog,
    Tabs,
    Tab,
    DialogActions,
    Tooltip,
    TextField,
    CircularProgress,
    Button,
    DialogTitle,
    IconButton,
    DialogContentText,
    Divider,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    MenuList,
    ListItemIcon,
    ListItemText,
    ListItemSecondaryAction,
    Switch,
    Icon,
    Slide,
    InputAdornment,
} from "@material-ui/core"
import IconPicker, {
    DEFAULT_ICON_NAMESPACE,
    IconValue,
} from "../icons/IconPicker"
import {
    UPDATE_CATEGORY,
    DELETE_CATEGORY,
    ADD_CATEGORY_WORKFLOW,
    REMOVE_CATEGORY_WORKFLOW,
    ADD_CATEGORY_PARENT,
    REMOVE_CATEGORY_PARENT,
} from "./graphql"
import {
    UpdateCategoryMutation,
    UpdateCategoryMutationVariables,
    DeleteCategoryMutation,
    DeleteCategoryMutationVariables,
    AddCategoryWorkflowMutation,
    AddCategoryWorkflowMutationVariables,
    RemoveCategoryWorkflowMutation,
    RemoveCategoryWorkflowMutationVariables,
    AddCategoryParentMutation,
    AddCategoryParentMutationVariables,
    RemoveCategoryParentMutation,
    RemoveCategoryParentMutationVariables,
} from "./__generated__/graphql"
import { Category } from "../../__generated__/types"
import SaveIcon from "@material-ui/icons/Save"
import DeleteForeverIcon from "@material-ui/icons/DeleteForever"
import useAwaitTranslation from "../../i18n/useAwaitTranslation"
import CategoryCriteriaManager from "../criteria/CategoryCriteriaManager"
import useWorkflowTools from "../workflows/useWorkflowTools"
import CategoryTreeSelector from "./CategoryTreeSelector"
import useCategoryTools from "./useCategoryTools"
import ClickableRichTooltip from "../Popper/ClickableRichTooltip"
import { Alert } from "@material-ui/lab"
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        label: {
            fontSize: 12,
            color: theme.palette.text.hint,
            mb: theme.spacing(1),
        },
        container: {
            height: "100%",
            width: "100%",
        },
    })
)

interface CategoryManagerModalProps {
    category: Category
    onClose: () => void
}
const CategoryManagerModal = (props: CategoryManagerModalProps) => {
    const classes = useStyles()

    const theme = useTheme()
    const { t } = useAwaitTranslation("categories")
    const [saving, setSaving] = useState(false)
    const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false)
    const [generalInfo, setGeneralInfo] = useState(null)
    const [showSuccessAlert, setShowSuccessAlert] = useState(false)
    const { getCategoryDescendents, onRefetchCategories } = useCategoryTools()

    const descendentCategoryIds = getCategoryDescendents(
        props.category.children?.map((child) => child.id) ?? []
    )

    const [updateCategory] = useMutation<
        UpdateCategoryMutation,
        UpdateCategoryMutationVariables
    >(UPDATE_CATEGORY)
    const [addCategoryWorkflow] = useMutation<
        AddCategoryWorkflowMutation,
        AddCategoryWorkflowMutationVariables
    >(ADD_CATEGORY_WORKFLOW)
    const [removeCategoryWorkflow] = useMutation<
        RemoveCategoryWorkflowMutation,
        RemoveCategoryWorkflowMutationVariables
    >(REMOVE_CATEGORY_WORKFLOW)
    const [addCategoryParent] = useMutation<
        AddCategoryParentMutation,
        AddCategoryParentMutationVariables
    >(ADD_CATEGORY_PARENT, {
        ignoreResults: true,
    })
    const [removeCategoryParent] = useMutation<
        RemoveCategoryParentMutation,
        RemoveCategoryParentMutationVariables
    >(REMOVE_CATEGORY_PARENT, {
        ignoreResults: true,
    })
    const [deleteCategory, { loading: deleting }] = useMutation<
        DeleteCategoryMutation,
        DeleteCategoryMutationVariables
    >(DELETE_CATEGORY, {
        update(cache) {
            cache.evict({
                id: cache.identify(props.category),
            })
        },
    })
    const [tab, setTab] = useState(0)

    const handleTabChange = (
        event: React.ChangeEvent<{}>,
        newValue: number
    ) => {
        if (!!showSuccessAlert) {
            setShowSuccessAlert(false)
        }
        setTab(newValue)
    }
    const handleChange = (value) => {
        if (!!showSuccessAlert) {
            setShowSuccessAlert(false)
        }
        setGeneralInfo(value)
    }
    const initialInfo = {
        name: props.category.name ?? "",
        description: props.category.description ?? "",
        icon: props.category.icon
            ? JSON.parse(props.category.icon)
            : { name: DEFAULT_ICON_NAMESPACE },
        isManaged: props.category.isManaged,
        isFilterOption: props.category.isFilterOption,
        parentCategoryId: props.category.parent?.id ?? null,
        workflowId: props.category.workflow?.id ?? null,
    }
    useEffect(() => {
        setGeneralInfo({
            name: props.category.name ?? "",
            description: props.category.description ?? "",
            icon: props.category.icon
                ? JSON.parse(props.category.icon)
                : { name: DEFAULT_ICON_NAMESPACE },
            isManaged: props.category.isManaged,
            isFilterOption: props.category.isFilterOption,
            parentCategoryId: props.category.parent?.id ?? null,
            workflowId: props.category.workflow?.id ?? null,
        })
    }, [props.category])

    const handleSaving = async () => {
        setSaving(true)
        await updateCategory({
            variables: {
                id: props.category?.id,
                icon: JSON.stringify(generalInfo.icon),
                name: generalInfo.name,
                description: generalInfo?.description,
                isManaged: generalInfo.isManaged,
                isFilterOption: generalInfo.isFilterOption,
                isRoot: !generalInfo.parentCategoryId,
            },
        })
        if (generalInfo.parentCategoryId !== props.category.parent?.id) {
            if (!!props.category.parent) {
                await removeCategoryParent({
                    variables: {
                        childId: props.category.id,
                        parentId: props.category.parent.id,
                    },
                })
            }
            if (!!generalInfo.parentCategoryId) {
                await addCategoryParent({
                    variables: {
                        childId: props.category.id,
                        parentId: generalInfo.parentCategoryId,
                    },
                })
            }
        }
        if (generalInfo.workflowId !== props.category.workflow?.id) {
            if (!!props.category.workflow) {
                await removeCategoryWorkflow({
                    variables: {
                        categoryId: props.category.id,
                        workflowId: props.category.workflow?.id,
                    },
                })
            }
            if (!!generalInfo.workflowId) {
                await addCategoryWorkflow({
                    variables: {
                        categoryId: props.category.id,
                        workflowId: generalInfo.workflowId,
                    },
                })
            }
        }

        await onRefetchCategories()
        setShowSuccessAlert(true)
        setSaving(false)
    }
    const handleDeletion = async () => {
        await deleteCategory({
            variables: {
                id: props.category.id,
            },
        })
        props.onClose()
    }

    const isChanged =
        JSON.stringify(initialInfo) !== JSON.stringify(generalInfo)
    return (
        <Dialog
            open={true}
            onBackdropClick={() => {
                props.onClose()
            }}
            disableEnforceFocus
            fullWidth
            maxWidth="lg"
        >
            <DialogTitle>
                Category Manager - {props.category?.name}
                <IconButton
                    style={{ position: "absolute", top: 0, right: 0 }}
                    onClick={() => {
                        props.onClose()
                    }}
                >
                    <ClearIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent
                dividers
                style={{ padding: 0, height: 800, overflow: "hidden" }}
            >
                <Box
                    display="flex"
                    flexDirection={"column"}
                    width="100%"
                    height="100%"
                    overflow="hidden"
                >
                    <Box display="flex" flexShrink={0}>
                        <Tabs value={tab} onChange={handleTabChange}>
                            <Tab
                                label={t(
                                    "generalInformation",
                                    "General Information"
                                )}
                            />
                            <Tab label={t("fields", "Fields")} />
                        </Tabs>
                    </Box>
                    <Divider />
                    <Box
                        width="100%"
                        display="flex"
                        flexDirection="column"
                        height="100%"
                        overflow="hidden"
                    >
                        {tab === 0 && (
                            <Box
                                display="flex"
                                flexDirection="column"
                                height="100%"
                                m={1}
                            >
                                <Box className={classes.container} p={2}>
                                    <Box p={1}>
                                        <TextField
                                            name="Name"
                                            fullWidth
                                            variant="outlined"
                                            disabled={!!saving}
                                            value={generalInfo?.name}
                                            onChange={(e) => {
                                                handleChange({
                                                    ...generalInfo,
                                                    name: e.target.value,
                                                })
                                            }}
                                            label={t("name", "Name")}
                                            InputProps={{
                                                startAdornment: !!props.category
                                                    ?.isRoot ? (
                                                    <ClickableRichTooltip
                                                        placement="bottom-start"
                                                        arrow={false}
                                                        content={
                                                            <IconPicker
                                                                value={
                                                                    generalInfo?.icon
                                                                }
                                                                onChange={(
                                                                    icon: IconValue
                                                                ) => {
                                                                    handleChange(
                                                                        {
                                                                            ...generalInfo,
                                                                            icon,
                                                                        }
                                                                    )
                                                                }}
                                                            />
                                                        }
                                                    >
                                                        <InputAdornment position="start">
                                                            <IconButton
                                                                size="small"
                                                                disabled={
                                                                    !!saving
                                                                }
                                                            >
                                                                <Icon>
                                                                    {generalInfo
                                                                        ?.icon
                                                                        ?.name ??
                                                                        DEFAULT_ICON_NAMESPACE}
                                                                </Icon>
                                                            </IconButton>
                                                        </InputAdornment>
                                                    </ClickableRichTooltip>
                                                ) : null,
                                            }}
                                        />
                                    </Box>
                                    <Box p={1} mt={2}>
                                        <TextField
                                            name="Description"
                                            value={generalInfo?.description}
                                            fullWidth
                                            variant="outlined"
                                            disabled={!!saving}
                                            multiline
                                            rows={2}
                                            onChange={(e) => {
                                                handleChange({
                                                    ...generalInfo,
                                                    description: e.target.value,
                                                })
                                            }}
                                            label={t(
                                                "description",
                                                "Description"
                                            )}
                                        />
                                    </Box>
                                    <Box p={1} mt={2} mb={2}>
                                        <CategoryTreeSelector
                                            value={
                                                generalInfo?.parentCategoryId ||
                                                null
                                            }
                                            label="Parent Category"
                                            allowAll={true}
                                            variant="outlined"
                                            saving={!!saving}
                                            setValue={(category: Category) => {
                                                handleChange({
                                                    ...generalInfo,
                                                    parentCategoryId:
                                                        category?.id ?? null,
                                                })
                                            }}
                                            allowAllText={t("none", "None")}
                                            hiddenOptions={[
                                                ...descendentCategoryIds,
                                                props.category.id,
                                            ]}
                                        />
                                    </Box>

                                    <WorkflowSelector
                                        onChange={(value: string) => {
                                            handleChange({
                                                ...generalInfo,
                                                workflowId: value,
                                            })
                                        }}
                                        value={generalInfo?.workflowId || null}
                                        disabled={!!saving}
                                    />
                                    <MenuList>
                                        <MenuItem
                                            disabled={!!saving}
                                            button={true}
                                            onClick={(e) => {
                                                handleChange({
                                                    ...generalInfo,
                                                    isManaged:
                                                        !generalInfo.isManaged,
                                                })
                                            }}
                                        >
                                            <ListItemIcon>
                                                <SupervisedUserCircleIcon />
                                            </ListItemIcon>
                                            <ListItemText>
                                                {t(
                                                    "adminManagedContent",
                                                    "Admin Managed Content"
                                                )}
                                            </ListItemText>
                                            <ListItemSecondaryAction>
                                                <Switch
                                                    disabled={!!saving}
                                                    checked={
                                                        generalInfo?.isManaged
                                                    }
                                                    color="primary"
                                                    onChange={(e) => {
                                                        handleChange({
                                                            ...generalInfo,
                                                            isManaged:
                                                                e.target
                                                                    .checked,
                                                        })
                                                    }}
                                                />
                                            </ListItemSecondaryAction>
                                        </MenuItem>
                                        <MenuItem
                                            disabled={!!saving}
                                            button={true}
                                            onClick={(e) => {
                                                handleChange({
                                                    ...generalInfo,
                                                    isFilterOption:
                                                        !generalInfo.isFilterOption,
                                                })
                                            }}
                                        >
                                            <ListItemIcon>
                                                <GlobalFilterIcon
                                                    style={{
                                                        fill: theme.palette
                                                            .action.active,

                                                        width: "1.25em",
                                                        height: "1.25em",
                                                    }}
                                                />
                                            </ListItemIcon>
                                            <ListItemText>
                                                {t(
                                                    "filterOption",
                                                    "Filter Option"
                                                )}
                                            </ListItemText>
                                            <ListItemSecondaryAction>
                                                <Switch
                                                    disabled={!!saving}
                                                    checked={
                                                        generalInfo?.isFilterOption
                                                    }
                                                    color="primary"
                                                    onChange={(e) => {
                                                        handleChange({
                                                            ...generalInfo,
                                                            isFilterOption:
                                                                e.target
                                                                    .checked,
                                                        })
                                                    }}
                                                />
                                            </ListItemSecondaryAction>
                                        </MenuItem>
                                    </MenuList>
                                </Box>
                                <Box
                                    display="flex"
                                    justifyContent="space-between"
                                    alignItems="center"
                                    p={2}
                                >
                                    <Tooltip
                                        title={
                                            props.category?.children?.length > 0
                                                ? "You must delete all children categories first"
                                                : "Delete category"
                                        }
                                    >
                                        <Box>
                                            <Button
                                                onClick={() =>
                                                    setConfirmDeleteOpen(true)
                                                }
                                                disabled={
                                                    props.category?.children
                                                        ?.length > 0
                                                }
                                                startIcon={
                                                    <DeleteForeverIcon color="error" />
                                                }
                                            >
                                                {t("delete", "Delete")}
                                            </Button>
                                        </Box>
                                    </Tooltip>

                                    <Slide
                                        direction="up"
                                        mountOnEnter
                                        unmountOnExit
                                        in={!!isChanged || !!showSuccessAlert}
                                    >
                                        {!!showSuccessAlert ? (
                                            <Alert severity="success">
                                                Changes saved
                                            </Alert>
                                        ) : (
                                            <Box display="flex">
                                                <Button
                                                    disabled={
                                                        !!saving || !isChanged
                                                    }
                                                    onClick={() => {
                                                        handleChange(
                                                            initialInfo
                                                        )
                                                    }}
                                                    startIcon={<ClearIcon />}
                                                >
                                                    {t("discard", "Discard")}
                                                </Button>
                                                <Button
                                                    onClick={handleSaving}
                                                    disabled={
                                                        !!saving || !isChanged
                                                    }
                                                    startIcon={<SaveIcon />}
                                                    color="primary"
                                                    style={{
                                                        position: "relative",
                                                    }}
                                                >
                                                    {!!saving && (
                                                        <CircularProgress
                                                            size={20}
                                                            style={{
                                                                position:
                                                                    "absolute",
                                                            }}
                                                        />
                                                    )}
                                                    {t("save", "Save")}
                                                </Button>
                                            </Box>
                                        )}
                                    </Slide>
                                </Box>
                            </Box>
                        )}
                        {tab === 1 && (
                            <CategoryCriteriaManager
                                category={props.category}
                            />
                        )}
                    </Box>
                </Box>
            </DialogContent>
            {!!confirmDeleteOpen && (
                <Dialog open={true}>
                    <DialogContent>
                        <DialogContentText>
                            {t(
                                "areYouSureYouWantToDeleteThisCategory",
                                "Are you sure you want to delete this category"
                            )}
                            ?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={() => {
                                setConfirmDeleteOpen(false)
                            }}
                            disabled={saving}
                        >
                            {t("no", "No")}
                        </Button>
                        <Button disabled={saving} onClick={handleDeletion}>
                            {deleting ? (
                                <CircularProgress size={25} disableShrink />
                            ) : (
                                t("yes", "Yes")
                            )}
                        </Button>
                    </DialogActions>
                </Dialog>
            )}
        </Dialog>
    )
}

export default CategoryManagerModal

const WorkflowSelector = (props: {
    value: string
    onChange: (value: string) => void

    disabled: boolean
}) => {
    const { value, onChange, disabled } = props

    const { allSubWorkflows, environmentWorkflow, isInitialized } =
        useWorkflowTools({
            fetch: true,
        })
    const currentWorkflow = allSubWorkflows?.find(
        (workflow) => workflow.id === value
    )
    const { t } = useAwaitTranslation("workflow")
    if (!isInitialized) {
        return null
    }
    return (
        <Box p={1}>
            <FormControl disabled={!!disabled} fullWidth variant="outlined">
                <InputLabel>{t("workflow", "Workflow")}</InputLabel>
                <Select
                    label={t("workflow", "Workflow")}
                    onChange={(e) => {
                        onChange(e.target.value as string)
                    }}
                    value={currentWorkflow?.id ?? environmentWorkflow?.id}
                >
                    <MenuItem
                        selected={!currentWorkflow}
                        value={environmentWorkflow?.id}
                    >
                        {environmentWorkflow?.title}
                    </MenuItem>
                    {allSubWorkflows?.map((workflow, index) => {
                        return (
                            <MenuItem key={workflow.id} value={workflow.id}>
                                {workflow.title}
                            </MenuItem>
                        )
                    })}
                </Select>
            </FormControl>
        </Box>
    )
}
