import { _ConceptFilter } from "../../../__generated__/types"
import { FilterItem } from "./types"
export const getFilterObject = (filters: FilterItem[]) => {
    let filterObject: _ConceptFilter = {}
    if (filters && filters.length > 0) {
        filters.filter((filter) => {
            if (filter.value && filter.value.length !== 0) {
                if (
                    !!filter.firstLevelFilter &&
                    !filter.secondLevelFilter &&
                    !filter.secondLevelFilterFormatter
                ) {
                    filterObject[filter.firstLevelFilter] = filter.value
                } else {
                    filterObject[filter.firstLevelFilter] = {}
                    if (!!filter.secondLevelFilter) {
                        filterObject[filter.firstLevelFilter][
                            filter.secondLevelFilter
                        ] = filter.value
                    } else if (!!filter.secondLevelFilterFormatter) {
                        filterObject[filter.firstLevelFilter] = {
                            ...filterObject[filter.firstLevelFilter],
                            ...filter.secondLevelFilterFormatter(filter.value),
                        }
                    }
                }
            }
            return filterObject
        })
    }

    return filterObject
}
