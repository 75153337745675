import React, { useEffect, useRef, useState } from "react"
import {
    InputAdornment,
    TextField,
    Box,
    Typography,
    Icon,
    Tooltip,
} from "@material-ui/core"
import { makeStyles, Theme } from "@material-ui/core/styles"
import { MultipleChoiceType } from "../../../criteria/useCriteriaTypes"
import { useInputTools } from "../../useInputTools"
import { Autocomplete } from "@material-ui/lab"
import { IconValue } from "../../../icons/IconPicker"
const useStyles = makeStyles((theme: Theme) => ({
    disabled: {
        color: `${theme.palette.text.primary} !important`,
    },
}))
const MultipleChoiceCell = (props: {
    fieldId: string
    config: MultipleChoiceType
    conceptId: string
    editing: boolean
}) => {
    const { config, editing, conceptId, fieldId } = props
    const [value, setValue] = useState(null)
    const inputRef = useRef(null)
    const { options } = config
    const classes = useStyles()
    const { onEditResponse, onCreateNewResponse, scores } = useInputTools({
        criteriaId: fieldId,
        inputConfig: config,
    })
    const score =
        scores?.find((score) => score.scoredConcept?.id === conceptId) || null

    const selectedOption = options.find((op) => op.id === value) || null
    useEffect(() => {
        if (value === null) {
            setValue(score?.response || "")
        }
    }, [score?.response, value, options])
    useEffect(() => {
        if (!!editing) {
            inputRef?.current?.focus()
        }
    }, [editing])
    return (
        <Tooltip title={options?.find((x) => x.id === value)?.value || ""}>
            <Autocomplete
                value={selectedOption}
                options={options}
                disabled={!editing}
                openOnFocus
                onChange={(
                    e,
                    newValue: { value: string; id: string; icon?: IconValue }
                ) => {
                    if (!!editing) {
                        let updateData = {
                            response: newValue.id,
                        }
                        setValue(newValue.id)
                        if (!!score) {
                            onEditResponse(
                                {
                                    ...updateData,
                                },
                                score
                            )
                        } else {
                            onCreateNewResponse({ ...updateData }, conceptId)
                        }
                    }
                }}
                size="small"
                getOptionLabel={(option) => option.value}
                getOptionSelected={(option) => option.id === value}
                disableClearable
                forcePopupIcon={!!editing}
                renderOption={(option) => {
                    return (
                        <Box key={option.id} display="flex" alignItems="center">
                            {!!option.icon && (
                                <Box mr={1}>
                                    <Icon color="inherit" fontSize="small">
                                        {option.icon.name}
                                    </Icon>
                                </Box>
                            )}
                            <Typography variant="body2">
                                {option.value}
                            </Typography>
                        </Box>
                    )
                }}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        inputRef={inputRef}
                        variant="standard"
                        InputProps={{
                            ...params.InputProps,
                            disableUnderline: !editing,
                            startAdornment: !!selectedOption?.icon ? (
                                <InputAdornment position="start">
                                    <Icon color="inherit" fontSize="small">
                                        {selectedOption.icon.name}
                                    </Icon>
                                </InputAdornment>
                            ) : null,
                            style: {
                                fontSize: 14,
                                flexWrap: "nowrap",
                            },
                            classes: {
                                disabled: classes.disabled,
                            },
                        }}
                    />
                )}
            />
        </Tooltip>
    )
}

export default MultipleChoiceCell
