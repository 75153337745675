import {
    useState,
    forwardRef,
    useRef,
    useEffect,
    SetStateAction,
    Dispatch,
} from "react"
import {
    Box,
    TextField,
    IconButton,
    Fab,
    InputAdornment,
    Hidden,
    CircularProgress,
    ButtonGroup,
    Slide,
    Popper,
    Grow,
    Button,
    Dialog,
    Drawer,
    Avatar,
    Fade,
    useMediaQuery,
    Typography,
    Paper,
    ClickAwayListener,
    Tooltip,
} from "@material-ui/core"

import { useTheme } from "@material-ui/core/styles"
import PublicIcon from "@material-ui/icons/Public"
import { useMutation } from "@apollo/client"
import EditIcon from "@material-ui/icons/Edit"
import {
    CreateLinkMutation,
    CreateLinkMutationVariables,
} from "../graphql/__generated__/mutations"

import { CREATE_LINK } from "../graphql/mutations"

import { AccessType, Category, Concept, Link } from "../__generated__/types"
import { TransitionProps } from "@material-ui/core/transitions"
import { useAuth } from "../providers/AuthProvider"
import AccountTreeRoundedIcon from "@material-ui/icons/AccountTreeRounded"
import SyncAltIcon from "@material-ui/icons/SyncAlt"
import SendIcon from "@material-ui/icons/Send"
import CloseIcon from "@material-ui/icons/Close"
import QuickEntryCategorySelector from "./categories/QuickEntryCategorySelector"
import { useHotkeys } from "react-hotkeys-hook"
import { isValidHttpUrl } from "../util/fns"
import { Loading } from "./Loading"
import GenerateAI from "./GenerateAI"
import useConceptTools from "../util/useConceptTools"
import useCategoryTools from "./categories/useCategoryTools"

import useEnvironmentSettingTools from "./settings/useEnvironmentSettingTools"
import { ConceptFieldSettings } from "../util/SystemSettings"
import VpnLock from "@material-ui/icons/VpnLock"
import Public from "@material-ui/icons/Public"
import Add from "@material-ui/icons/Add"

const Transition = forwardRef(function Transition(
    props: TransitionProps & { children?: React.ReactElement<any, any> },
    ref: React.Ref<unknown>
) {
    return <Slide direction="up" ref={ref} {...props} />
})
interface QuickEntryProps {
    openSnackbar?: (concept: Concept, categoryId: string) => void
}
export default function QuickEntry(props: QuickEntryProps) {
    //imported hooks, etc.
    //had to do it this way due to component mounting outside of the react-router-dom routing
    const onConceptDetailsPage = window.location.href?.includes("concept")
    const pageConceptId = window.location.href.split("/")[4] ?? null
    const { currentUser } = useAuth()
    const { environmentCategories } = useCategoryTools()
    const theme = useTheme()
    const mobile = useMediaQuery(theme.breakpoints.down("sm"))
    useHotkeys("a,i", (ev) => {
        ev.preventDefault()
        setAnchorEl(fabRef.current)
    })
    const { onConceptCreation } = useConceptTools()
    // mutations

    //queries
    const [addLink] = useMutation<
        CreateLinkMutation,
        CreateLinkMutationVariables
    >(CREATE_LINK)

    //state
    const [inputValue, setInputValue] = useState("")
    const [isPublic, setIsPublic] = useState(true)
    const [linkLoading, setLinkLoading] = useState(false)
    const [isMobileOpen, setIsMobileOpen] = useState(false)
    const [category, setCategory] = useState(null)
    const [isChild, setIsChild] = useState(true)
    const [isConnected, setIsConnected] = useState(true)
    const [editingCategory, setEditingCategory] = useState(false)
    const [saving, setSaving] = useState(false)
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
    const [linkItem, setLinkItem] = useState<Link | null>(null)
    const { ableToConnect, ableToPublish } = useEnvironmentSettingTools()
    //refs
    const textfieldRef = useRef(null)
    const fabRef = useRef(null)

    //useEffects
    useEffect(() => {
        if (!!environmentCategories && !category) {
            setCategory(
                environmentCategories.filter((op) => op.name === "Idea")?.[0]
            )
        }
    }, [environmentCategories, setCategory, category])

    //functions
    const handleConceptCreation = async () => {
        setSaving(true)
        await onConceptCreation({
            data: {
                title: inputValue,
                type: category?.isRoot ? category.name : category.parent.name,
                isPublic: isPublic && !!ableToPublish,
                isPublicAccessType:
                    isPublic && !!ableToPublish
                        ? AccessType.VIEWER
                        : AccessType.NONE,
            },
            linkItem: linkItem,
            parentId: !!pageConceptId && !!isChild ? pageConceptId : null,
            connectedId:
                !!pageConceptId && !!isConnected ? pageConceptId : null,
            category: category,
            openSnackbar: props.openSnackbar,
            tags: [],
        })
        setSaving(false)
        setLinkItem(null)
        setInputValue("")
        if (mobile) {
            setIsMobileOpen(false)
        } else {
            setAnchorEl(null)
        }
    }
    if (!currentUser) {
        return null
    }
    const handleLinkPaste = async (e) => {
        let url = e.clipboardData.getData("Text")
        if (isValidHttpUrl(url)) {
            setLinkLoading(true)
            let res = await addLink({ variables: { url } })
            const linkData = res.data?.addLink
            setLinkItem(linkData)
            setInputValue(linkData.title ?? "")
            setLinkLoading(false)
        }
    }

    //variables
    let categoryOptions =
        environmentCategories.filter((cat) => !!cat.isRoot) ?? []

    const desktopOpen = Boolean(anchorEl)
    return (
        <>
            <Box
                style={{
                    position: "fixed",
                    right: 15,
                    bottom: 15,
                    zIndex: theme.zIndex.appBar - 1,
                }}
            >
                <Fab
                    ref={fabRef}
                    color="primary"
                    aria-label="add"
                    onClick={(e) => {
                        if (!!mobile) {
                            setIsMobileOpen((prev) => !prev)
                        } else {
                            setAnchorEl(e.currentTarget)
                        }
                    }}
                >
                    <Add />
                </Fab>
            </Box>

            {/* DESKTOP ENTRY */}
            <Hidden smDown>
                <Popper
                    open={desktopOpen}
                    anchorEl={anchorEl}
                    transition
                    style={{
                        zIndex: theme.zIndex.appBar - 1,
                    }}
                    key={`desktop-${currentUser?.userId}-${window.location.href}`}
                >
                    {({ TransitionProps }) => (
                        <Grow {...TransitionProps} timeout={350}>
                            <Paper
                                elevation={12}
                                style={{ width: 350, height: 450 }}
                            >
                                <ClickAwayListener
                                    onClickAway={() => {
                                        setAnchorEl(null)
                                    }}
                                >
                                    {editingCategory ? (
                                        <Fade in={editingCategory}>
                                            <Box
                                                height="100%"
                                                overflow="hidden"
                                            >
                                                <QuickEntryCategorySelector
                                                    value={category?.id ?? null}
                                                    setValue={(
                                                        category: Category
                                                    ) => {
                                                        setCategory(category)
                                                    }}
                                                    cancelEnabled={true}
                                                    onClose={() => {
                                                        setEditingCategory(
                                                            false
                                                        )
                                                        //the rerendering based off above state change causes need for timeout
                                                        setTimeout(() => {
                                                            textfieldRef?.current?.focus()
                                                        }, 100)
                                                    }}
                                                    rootOptions={
                                                        categoryOptions
                                                    }
                                                />
                                            </Box>
                                        </Fade>
                                    ) : (
                                        <Box
                                            p={2}
                                            pt={1}
                                            display="flex"
                                            flexDirection={"column"}
                                            height="100%"
                                        >
                                            <Box
                                                p={1}
                                                display="flex"
                                                alignItems="center"
                                                justifyContent="space-between"
                                            >
                                                <Typography variant="h6">
                                                    Quick Entry
                                                </Typography>
                                                <Box>
                                                    {!!ableToPublish && (
                                                        <Tooltip
                                                            placement="top"
                                                            arrow={true}
                                                            title={
                                                                isPublic
                                                                    ? "Click to make PRIVATE"
                                                                    : "Click to make PUBLIC"
                                                            }
                                                        >
                                                            <IconButton
                                                                onClick={() => {
                                                                    setIsPublic(
                                                                        (
                                                                            prev
                                                                        ) =>
                                                                            !prev
                                                                    )
                                                                }}
                                                            >
                                                                {!isPublic ? (
                                                                    <VpnLock color="disabled" />
                                                                ) : (
                                                                    <Public color="primary" />
                                                                )}
                                                            </IconButton>
                                                        </Tooltip>
                                                    )}
                                                    {!!ableToConnect &&
                                                        !!onConceptDetailsPage &&
                                                        !!pageConceptId && (
                                                            <>
                                                                <Tooltip
                                                                    placement="top"
                                                                    arrow={true}
                                                                    title={
                                                                        !!isChild
                                                                            ? "Click to REMOVE parent concept"
                                                                            : "Click to ADD parent concept"
                                                                    }
                                                                >
                                                                    <IconButton
                                                                        onClick={() => {
                                                                            setIsChild(
                                                                                (
                                                                                    prev
                                                                                ) =>
                                                                                    !prev
                                                                            )
                                                                        }}
                                                                    >
                                                                        <AccountTreeRoundedIcon
                                                                            color={
                                                                                !!isChild
                                                                                    ? "primary"
                                                                                    : "disabled"
                                                                            }
                                                                        />
                                                                    </IconButton>
                                                                </Tooltip>
                                                                <Tooltip
                                                                    placement="top"
                                                                    arrow={true}
                                                                    title={
                                                                        !!isConnected
                                                                            ? "Click to DISABLE automatic connection"
                                                                            : "Click to ENABLE automatic connection"
                                                                    }
                                                                >
                                                                    <IconButton
                                                                        onClick={() => {
                                                                            setIsConnected(
                                                                                (
                                                                                    prev
                                                                                ) =>
                                                                                    !prev
                                                                            )
                                                                        }}
                                                                    >
                                                                        <SyncAltIcon
                                                                            color={
                                                                                !!isConnected
                                                                                    ? "primary"
                                                                                    : "disabled"
                                                                            }
                                                                        />
                                                                    </IconButton>
                                                                </Tooltip>
                                                            </>
                                                        )}
                                                </Box>
                                            </Box>

                                            <TextField
                                                style={{ flexGrow: 1 }}
                                                variant="outlined"
                                                placeholder={
                                                    linkLoading
                                                        ? "Loading link data..."
                                                        : "Quick, what's on your mind?"
                                                }
                                                value={inputValue}
                                                onChange={(e) => {
                                                    setInputValue(
                                                        e.target.value
                                                    )
                                                }}
                                                onPaste={handleLinkPaste}
                                                onKeyDown={(e) => {
                                                    if (e.key === "Enter") {
                                                        handleConceptCreation()
                                                    }
                                                }}
                                                InputProps={{
                                                    style: {
                                                        height: "100%",
                                                    },
                                                    startAdornment: (
                                                        <InputAdornment
                                                            position="start"
                                                            style={{
                                                                height: "100%",
                                                                display: "flex",
                                                                alignSelf:
                                                                    "flex-start",
                                                                marginTop: -4,
                                                            }}
                                                        >
                                                            {linkLoading ? (
                                                                <Loading
                                                                    size={30}
                                                                    hideQuote={
                                                                        true
                                                                    }
                                                                />
                                                            ) : (
                                                                <Avatar
                                                                    style={{
                                                                        height: 30,
                                                                        width: 30,
                                                                    }}
                                                                    src={
                                                                        currentUser?.imageUrl
                                                                    }
                                                                />
                                                            )}
                                                        </InputAdornment>
                                                    ),
                                                    endAdornment: (
                                                        <InputAdornment
                                                            position="end"
                                                            style={{
                                                                marginBottom:
                                                                    "auto",
                                                                width: "16px",
                                                            }}
                                                        >
                                                            <GenerateAI
                                                                prompt={`Using the input text below, write a well-formatted, clear and concise description of a ${category?.name}, no more than ${ConceptFieldSettings.Title.maxLength} characters.\nInput: ${inputValue}\nResponse:`}
                                                                maxLength={
                                                                    ConceptFieldSettings
                                                                        .Title
                                                                        .maxLength
                                                                }
                                                                onGenerate={(
                                                                    res
                                                                ) =>
                                                                    setInputValue(
                                                                        res
                                                                    )
                                                                }
                                                                onGenerateError={(
                                                                    e
                                                                ) =>
                                                                    console.log(
                                                                        e
                                                                    )
                                                                }
                                                                disabled={
                                                                    inputValue.length ===
                                                                    0
                                                                }
                                                                tooltip={
                                                                    inputValue.length ===
                                                                    0
                                                                        ? "No text entered"
                                                                        : `Use artificial intelligence to generate a(n) ${
                                                                              category?.name?.toLowerCase() ||
                                                                              "idea"
                                                                          } using the text entered`
                                                                }
                                                            />
                                                        </InputAdornment>
                                                    ),
                                                }}
                                                inputProps={{
                                                    style: { height: "100%" },
                                                }}
                                                multiline
                                                autoFocus
                                                inputRef={textfieldRef}
                                                fullWidth
                                                disabled={saving || linkLoading}
                                            />
                                            <LinkItemPreview
                                                linkItem={linkItem}
                                                setLinkItem={setLinkItem}
                                            />

                                            <Box
                                                width="100%"
                                                display="flex"
                                                alignItems={"center"}
                                                justifyContent="space-between"
                                                mt={2}
                                                mb={0}
                                            >
                                                <Button
                                                    onClick={() => {
                                                        setAnchorEl(null)
                                                    }}
                                                >
                                                    Cancel
                                                </Button>
                                                <Box maxWidth={"50%"}>
                                                    <ButtonGroup
                                                        style={{
                                                            width: "100%",
                                                        }}
                                                    >
                                                        <Button
                                                            disabled={
                                                                inputValue.length ===
                                                                    0 || saving
                                                            }
                                                            style={{
                                                                flexGrow: 1,
                                                                overflow:
                                                                    "hidden",
                                                                display: "flex",
                                                            }}
                                                            onClick={
                                                                handleConceptCreation
                                                            }
                                                            color="primary"
                                                        >
                                                            {saving ? (
                                                                <CircularProgress
                                                                    size={25}
                                                                />
                                                            ) : (
                                                                <>
                                                                    <SendIcon
                                                                        fontSize="small"
                                                                        color={
                                                                            inputValue.length ===
                                                                            0
                                                                                ? "inherit"
                                                                                : "primary"
                                                                        }
                                                                    />
                                                                    <Box
                                                                        ml={1}
                                                                        overflow="hidden"
                                                                    >
                                                                        <Typography
                                                                            noWrap
                                                                            style={{
                                                                                textOverflow:
                                                                                    "ellipsis",
                                                                                overflow:
                                                                                    "hidden",
                                                                            }}
                                                                        >
                                                                            {
                                                                                category?.name
                                                                            }
                                                                        </Typography>
                                                                    </Box>
                                                                </>
                                                            )}
                                                        </Button>
                                                        <Button
                                                            color="primary"
                                                            size="small"
                                                            onClick={() => {
                                                                setEditingCategory(
                                                                    true
                                                                )
                                                            }}
                                                            disabled={saving}
                                                        >
                                                            <EditIcon fontSize="small" />
                                                        </Button>
                                                    </ButtonGroup>
                                                </Box>
                                            </Box>
                                        </Box>
                                    )}
                                </ClickAwayListener>
                            </Paper>
                        </Grow>
                    )}
                </Popper>
            </Hidden>

            {/* MOBILE ENTRY */}
            <Hidden smUp>
                <Dialog
                    key={`mobile-${currentUser?.userId}-${window.location.href}`}
                    open={isMobileOpen}
                    TransitionComponent={Transition}
                    fullScreen
                >
                    <Box
                        p={2}
                        display="flex"
                        flexDirection={"column"}
                        height="50vh"
                    >
                        <Box
                            p={1}
                            display="flex"
                            alignItems="center"
                            justifyContent="space-between"
                        >
                            <Typography variant="h6">Quick Entry</Typography>
                            <Box>
                                <IconButton
                                    onClick={() => {
                                        setIsPublic((prev) => !prev)
                                    }}
                                >
                                    <PublicIcon
                                        color={isPublic ? "primary" : "inherit"}
                                    />
                                </IconButton>
                                {!!onConceptDetailsPage && !!pageConceptId && (
                                    <>
                                        <IconButton
                                            onClick={() => {
                                                setIsChild((prev) => !prev)
                                            }}
                                        >
                                            <AccountTreeRoundedIcon
                                                color={
                                                    isChild
                                                        ? "primary"
                                                        : "inherit"
                                                }
                                            />
                                        </IconButton>
                                        <IconButton
                                            onClick={() => {
                                                setIsConnected((prev) => !prev)
                                            }}
                                        >
                                            <SyncAltIcon
                                                color={
                                                    isConnected
                                                        ? "primary"
                                                        : "inherit"
                                                }
                                            />
                                        </IconButton>
                                    </>
                                )}
                            </Box>
                        </Box>

                        <TextField
                            style={{ flexGrow: 1 }}
                            variant="outlined"
                            placeholder={
                                linkLoading
                                    ? "Loading link data..."
                                    : "Quick, what's on your mind?"
                            }
                            value={inputValue}
                            onPaste={handleLinkPaste}
                            onChange={(e) => {
                                setInputValue(e.target.value)
                            }}
                            onKeyDown={(e) => {
                                if (e.key === "Enter") {
                                    handleConceptCreation()
                                }
                            }}
                            InputProps={{
                                style: {
                                    height: "100%",
                                },
                                startAdornment: (
                                    <InputAdornment
                                        position="start"
                                        style={{
                                            height: "100%",
                                            display: "flex",
                                            alignSelf: "flex-start",
                                            marginTop: -4,
                                        }}
                                    >
                                        {linkLoading ? (
                                            <Loading
                                                size={30}
                                                hideQuote={true}
                                            />
                                        ) : (
                                            <Avatar
                                                style={{
                                                    height: 30,
                                                    width: 30,
                                                }}
                                                src={currentUser?.imageUrl}
                                            />
                                        )}
                                    </InputAdornment>
                                ),
                                endAdornment: (
                                    <InputAdornment
                                        position="end"
                                        style={{
                                            marginBottom: "auto",
                                            width: "16px",
                                        }}
                                    >
                                        <GenerateAI
                                            prompt={`Using the input text below, write a well-formatted, clear and concise description of a ${category?.name}, no more than ${ConceptFieldSettings.Title.maxLength} characters.\nInput: ${inputValue}\nResponse:`}
                                            maxLength={
                                                ConceptFieldSettings.Title
                                                    .maxLength
                                            }
                                            onGenerate={(res) =>
                                                setInputValue(res)
                                            }
                                            onGenerateError={(e) =>
                                                console.log(e)
                                            }
                                        />
                                    </InputAdornment>
                                ),
                            }}
                            inputProps={{
                                style: { height: "100%" },
                            }}
                            multiline
                            autoFocus
                            inputRef={textfieldRef}
                            fullWidth
                            disabled={saving}
                        />

                        <LinkItemPreview
                            linkItem={linkItem}
                            setLinkItem={setLinkItem}
                        />

                        <Box
                            width="100%"
                            display="flex"
                            alignItems={"center"}
                            justifyContent="space-between"
                            mt={2}
                            mb={0}
                        >
                            <Button
                                onClick={() => {
                                    setIsMobileOpen(false)
                                }}
                            >
                                Cancel
                            </Button>
                            <Box maxWidth={"50%"}>
                                <ButtonGroup style={{ width: "100%" }}>
                                    <Button
                                        disabled={
                                            inputValue.length === 0 || saving
                                        }
                                        style={{
                                            flexGrow: 1,
                                            overflow: "hidden",
                                            display: "flex",
                                        }}
                                        onClick={handleConceptCreation}
                                        color="primary"
                                    >
                                        {saving ? (
                                            <CircularProgress size={25} />
                                        ) : (
                                            <>
                                                <SendIcon
                                                    fontSize="small"
                                                    color={
                                                        inputValue.length === 0
                                                            ? "inherit"
                                                            : "primary"
                                                    }
                                                />
                                                <Box ml={1} overflow="hidden">
                                                    <Typography
                                                        noWrap
                                                        style={{
                                                            textOverflow:
                                                                "ellipsis",
                                                            overflow: "hidden",
                                                        }}
                                                    >
                                                        {category?.name}
                                                    </Typography>
                                                </Box>
                                            </>
                                        )}
                                    </Button>
                                    <Button
                                        color="primary"
                                        size="small"
                                        onClick={() => {
                                            setEditingCategory(true)
                                        }}
                                        disabled={saving}
                                    >
                                        <EditIcon fontSize="small" />
                                    </Button>
                                </ButtonGroup>
                            </Box>
                        </Box>
                    </Box>
                    <Drawer
                        onBackdropClick={() => {
                            if (!!editingCategory) {
                                setEditingCategory(false)
                            }
                        }}
                        anchor="bottom"
                        open={editingCategory}
                        keepMounted
                    >
                        <Box height={"45vh"} overflow="hidden">
                            <QuickEntryCategorySelector
                                value={category?.id ?? null}
                                setValue={(category: Category) => {
                                    setCategory(category)
                                }}
                                rootOptions={categoryOptions}
                                onClose={() => {
                                    setEditingCategory(false)
                                    //the rerendering based off above state change causes need for timeout
                                    setTimeout(() => {
                                        textfieldRef?.current?.focus()
                                    }, 100)
                                }}
                            />
                        </Box>
                    </Drawer>
                </Dialog>
            </Hidden>
        </>
    )
}

interface LinkItemPreviewProps {
    linkItem: Link
    setLinkItem: Dispatch<SetStateAction<Link>>
}
const LinkItemPreview = (props: LinkItemPreviewProps) => {
    const { linkItem, setLinkItem } = props
    if (linkItem) {
        return (
            <Box
                width="100%"
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                p={0.5}
            >
                {linkItem.image && (
                    <Avatar variant="rounded" src={linkItem.image} />
                )}
                <Tooltip title={linkItem.url}>
                    <Box p={0.5} ml={0.5}>
                        <Typography variant="caption">
                            {linkItem.title}
                        </Typography>
                    </Box>
                </Tooltip>

                <Tooltip title="Remove Link">
                    <IconButton
                        onClick={() => {
                            setLinkItem(null)
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </Tooltip>
            </Box>
        )
    } else {
        return null
    }
}
