import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import Tabs from "@material-ui/core/Tabs"
import Tab from "@material-ui/core/Tab"
import Box from "@material-ui/core/Box"
import IndustryManager from "../industries/IndustryManager"
import CategoryManager from "../categories/CategoryManager"
import ThemeManager from "../themes/ThemeManager"
import useAwaitTranslation from "../../i18n/useAwaitTranslation"
import UserManager from "../users/UserManager"
import { useAuth } from "../../providers/AuthProvider"
import { SystemRole } from "../../__generated__/types"
import { useHistory, useParams } from "react-router-dom"
import WorkflowsManager from "../workflows/WorkflowsManager"
import GeneralSettings from "./GeneralSettings"
import FieldsManager from "../criteria/FieldManager"
const useStyles = makeStyles((theme) => ({
    root: {
        height: "calc(100vh - 64px)",
        display: "flex",
        flexDirection: "column",
    },
    level2: {
        //@ts-ignore
        backgroundColor: theme.palette.background.level2,
        borderBottom: `1px solid ${theme.palette.divider}`,
    },
}))

const TABS = {
    general: "general",
    users: "users",
    categories: "categories",
    fields: "fields",
    industries: "industries",
    themes: "themes",
    criteria: "criteria",
    workflows: "workflows",
}
export default function GlobalSettings(props) {
    const { tabKey } = useParams()
    const classes = useStyles()
    const { currentUser } = useAuth()
    const history = useHistory()
    const handleChange = (event: React.ChangeEvent<{}>, newValue: string) => {
        history.replace(`/settings/${newValue}`)
    }
    React.useEffect(() => {
        if (
            !!currentUser &&
            !!history &&
            currentUser?.role !== SystemRole.ADMIN
        ) {
            history.push("/")
        }
    }, [currentUser, history])
    const { t } = useAwaitTranslation("global")
    if (currentUser?.role === SystemRole.ADMIN) {
        return (
            <Box className={classes.root}>
                <Box className={classes.level2}>
                    <Tabs
                        value={tabKey}
                        onChange={handleChange}
                        indicatorColor="primary"
                        variant="scrollable"
                        scrollButtons="auto"
                    >
                        <Tab
                            value={TABS.general}
                            label={t("general", "General")}
                        />
                        <Tab value={TABS.users} label={t("users", "Users")} />
                        <Tab
                            value={TABS.categories}
                            label={t("categories", "Categories")}
                        />
                        <Tab
                            value={TABS.fields}
                            label={t("fields", "Fields")}
                        />
                        <Tab
                            value={TABS.industries}
                            label={t("industries", "Industries")}
                        />

                        <Tab
                            value={TABS.themes}
                            label={t("themes", "Themes")}
                        />
                        <Tab
                            value={TABS.workflows}
                            label={t("workflows", "Workflows")}
                        />
                    </Tabs>
                </Box>
                {tabKey === TABS.general && <GeneralSettings />}
                {tabKey === TABS.users && <UserManager />}
                {tabKey === TABS.fields && <FieldsManager />}
                {tabKey === TABS.categories && <CategoryManager />}
                {tabKey === TABS.industries && <IndustryManager />}
                {tabKey === TABS.themes && <ThemeManager />}

                {tabKey === TABS.workflows && <WorkflowsManager />}
            </Box>
        )
    } else return null
}
