import { useState } from "react"
import {
    Box,
    TextField,
    Typography,
    Button,
    IconButton,
    ClickAwayListener,
} from "@material-ui/core"
import { BubbleAxisConfigurationType, ChartType } from "./useCriteriaTypes"
import { SketchPicker } from "react-color"
import { useTheme } from "@material-ui/core/styles"
import Clear from "@material-ui/icons/Clear"
import Add from "@material-ui/icons/Add"
const BubbleAxisConfiguration = (props: {
    value: ChartType
    onChange: (field: string, value: BubbleAxisConfigurationType) => void
}) => {
    const { value, onChange } = props

    return (
        <Box>
            <Box mb={2}>
                <Typography color="textSecondary" variant="subtitle2">
                    X Axis
                </Typography>
            </Box>
            <Box mb={2}>
                <TextField
                    label={"Positive Label"}
                    value={value.bubbleAxisConfiguration.positiveXAxisLabel}
                    fullWidth
                    size="small"
                    variant="outlined"
                    onChange={(e) => {
                        onChange("bubbleAxisConfiguration", {
                            ...value.bubbleAxisConfiguration,
                            positiveXAxisLabel: e.target.value,
                        })
                    }}
                />
            </Box>
            <Box>
                <TextField
                    label={"Negative Label"}
                    value={value.bubbleAxisConfiguration.negativeXAxisLabel}
                    size="small"
                    fullWidth
                    variant="outlined"
                    onChange={(e) => {
                        onChange("bubbleAxisConfiguration", {
                            ...value.bubbleAxisConfiguration,
                            negativeXAxisLabel: e.target.value,
                        })
                    }}
                />
            </Box>

            <Box mb={2} mt={3}>
                <Typography color="textSecondary" variant="subtitle2">
                    Y Axis
                </Typography>
            </Box>
            <Box mb={2}>
                <TextField
                    label={"Positive Label"}
                    value={value.bubbleAxisConfiguration.positiveYAxisLabel}
                    size="small"
                    fullWidth
                    variant="outlined"
                    onChange={(e) => {
                        onChange("bubbleAxisConfiguration", {
                            ...value.bubbleAxisConfiguration,
                            positiveYAxisLabel: e.target.value,
                        })
                    }}
                />
            </Box>
            <Box>
                <TextField
                    label={"Negative Label"}
                    value={value.bubbleAxisConfiguration.negativeYAxisLabel}
                    fullWidth
                    size="small"
                    variant="outlined"
                    onChange={(e) => {
                        onChange("bubbleAxisConfiguration", {
                            ...value.bubbleAxisConfiguration,
                            negativeYAxisLabel: e.target.value,
                        })
                    }}
                />
            </Box>

            <Box mb={2} mt={3}>
                <Typography color="textSecondary" variant="subtitle2">
                    Quadrant One
                </Typography>
            </Box>
            <Box mb={2}>
                <TextField
                    fullWidth
                    size="small"
                    variant="outlined"
                    value={value.bubbleAxisConfiguration.quandrantOneLabel}
                    label={"Label"}
                    onChange={(e) => {
                        onChange("bubbleAxisConfiguration", {
                            ...value.bubbleAxisConfiguration,
                            quandrantOneLabel: e.target.value,
                        })
                    }}
                />
                <Box mt={1.5}>
                    <ColorPicker
                        value={
                            value.bubbleAxisConfiguration
                                .quandrantOneBackgroundColor
                        }
                        onChange={(color) => {
                            onChange("bubbleAxisConfiguration", {
                                ...value.bubbleAxisConfiguration,
                                quandrantOneBackgroundColor: color,
                            })
                        }}
                        field="quandrantOneBackgroundColor"
                    />
                </Box>
            </Box>

            <Box>
                <Box mb={2}>
                    <Typography color="textSecondary" variant="subtitle2">
                        Quadrant Two
                    </Typography>
                </Box>
                <Box mb={3} mt={1}>
                    <TextField
                        fullWidth
                        size="small"
                        variant="outlined"
                        value={value.bubbleAxisConfiguration.quandrantTwoLabel}
                        label={"Label"}
                        onChange={(e) => {
                            onChange("bubbleAxisConfiguration", {
                                ...value.bubbleAxisConfiguration,
                                quandrantTwoLabel: e.target.value,
                            })
                        }}
                    />
                    <Box mt={1.5}>
                        <ColorPicker
                            value={
                                value.bubbleAxisConfiguration
                                    .quandrantTwoBackgroundColor
                            }
                            onChange={(color) => {
                                onChange("bubbleAxisConfiguration", {
                                    ...value.bubbleAxisConfiguration,
                                    quandrantTwoBackgroundColor: color,
                                })
                            }}
                            field="quandrantTwoBackgroundColor"
                        />
                    </Box>
                </Box>
            </Box>
            <Box>
                <Box mb={2}>
                    <Typography color="textSecondary" variant="subtitle2">
                        Quadrant Three
                    </Typography>
                </Box>
                <Box mb={3} mt={1}>
                    <TextField
                        fullWidth
                        size="small"
                        variant="outlined"
                        value={
                            value.bubbleAxisConfiguration.quandrantThreeLabel
                        }
                        label={"Label"}
                        onChange={(e) => {
                            onChange("bubbleAxisConfiguration", {
                                ...value.bubbleAxisConfiguration,
                                quandrantThreeLabel: e.target.value,
                            })
                        }}
                    />
                    <Box mt={1.5}>
                        <ColorPicker
                            value={
                                value.bubbleAxisConfiguration
                                    .quandrantThreeBackgroundColor
                            }
                            onChange={(color) => {
                                onChange("bubbleAxisConfiguration", {
                                    ...value.bubbleAxisConfiguration,
                                    quandrantThreeBackgroundColor: color,
                                })
                            }}
                            field="quandrantThreeBackgroundColor"
                        />
                    </Box>
                </Box>
            </Box>
            <Box>
                <Box mb={2}>
                    <Typography color="textSecondary" variant="subtitle2">
                        Quadrant Four
                    </Typography>
                </Box>
                <Box mb={3} mt={1}>
                    <TextField
                        fullWidth
                        size="small"
                        variant="outlined"
                        value={value.bubbleAxisConfiguration.quandrantFourLabel}
                        label={"Label"}
                        onChange={(e) => {
                            onChange("bubbleAxisConfiguration", {
                                ...value.bubbleAxisConfiguration,
                                quandrantFourLabel: e.target.value,
                            })
                        }}
                    />
                    <Box mt={1.5}>
                        <ColorPicker
                            value={
                                value.bubbleAxisConfiguration
                                    .quandrantFourBackgroundColor
                            }
                            onChange={(color) => {
                                onChange("bubbleAxisConfiguration", {
                                    ...value.bubbleAxisConfiguration,
                                    quandrantFourBackgroundColor: color,
                                })
                            }}
                            field="quandrantFourBackgroundColor"
                        />
                    </Box>
                </Box>
            </Box>
        </Box>
    )
}

interface ColorPopperProps {
    value: string
    onChange: (color: string | null) => void
    field: string
}

const ColorPicker = (props: ColorPopperProps) => {
    const [display, setDisplay] = useState(false)
    const theme = useTheme()

    return (
        <Box>
            {!!props.value ? (
                <Box width="100%">
                    <Box
                        onClick={() => setDisplay(true)}
                        display="flex"
                        position="relative"
                    >
                        <Box
                            borderRadius={theme.shape.borderRadius}
                            width="100%"
                            height={"2em"}
                            style={{
                                backgroundColor: props.value,
                                cursor: "pointer",
                            }}
                            border={`1px solid ${theme.palette.divider}`}
                        />
                        {display && (
                            <ClickAwayListener
                                onClickAway={() => setDisplay(false)}
                            >
                                <Box
                                    position="absolute"
                                    top={"2em"}
                                    zIndex={theme.zIndex.modal + 1}
                                >
                                    <SketchPicker
                                        color={props.value}
                                        onChange={(color) => {
                                            const adjustedRgba = `rgba(${color.rgb.r},${color.rgb.g},${color.rgb.b},${color.rgb.a})`

                                            props.onChange(adjustedRgba)
                                        }}
                                    />
                                </Box>
                            </ClickAwayListener>
                        )}
                        <IconButton
                            style={{ marginLeft: 4 }}
                            size="small"
                            onClick={() => props.onChange(null)}
                        >
                            <Clear fontSize="small" />
                        </IconButton>
                    </Box>
                </Box>
            ) : (
                <Button
                    style={{ width: "100%" }}
                    variant="outlined"
                    startIcon={<Add />}
                    onClick={() => {
                        setDisplay(true)
                        props.onChange("#fff")
                    }}
                >
                    Add Background Color
                </Button>
            )}
        </Box>
    )
}

export default BubbleAxisConfiguration
