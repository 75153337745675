import { Box, Paper } from "@material-ui/core"
import WidgetHeader from "./common/WidgetHeader"
import useWidget from "../useWidget"
import { WidgetTypeComponent } from "../useWidgetTypes"
import { useState, useMemo } from "react"

import { useQuery, useReactiveVar } from "@apollo/client"
import {
    FeedbackField,
    myCurrentInputResponseLabelVar,
    myCurrentInputResponseVar,
    VIEW_OPTIONS,
} from "../../../providers/GlobalState"
import { CONCEPT_WITH_CONNECTIONS_FILTERED } from "../../inputs/graphql"
import {
    ConceptWithConnectionsFilteredQuery,
    ConceptWithConnectionsFilteredQueryVariables,
} from "../../inputs/__generated__/graphql"
import { _ConceptFilter } from "../../../__generated__/types"
import GraphView, { getConceptTooltip } from "../../GraphView"
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab"
import { useTheme } from "@material-ui/core/styles"
import LoadingOverlayWrapper from "../../LoadingOverlayWrapper"
import useAwaitTranslation from "../../../i18n/useAwaitTranslation"
import { getSelectedResponseScores } from "../../inputs/util"
type WidgetConfig = {
    label: string
    criteriaIds: string[]
}

const GraphWidget: WidgetTypeComponent = (props) => {
    //hooks
    const { config, isInitialized } = useWidget<WidgetConfig>(props.widget.id)
    const [hovered, setHovered] = useState(false)
    const [viewMode, setViewMode] = useState<
        VIEW_OPTIONS.GRAPH_2D | VIEW_OPTIONS.GRAPH_3D
    >(VIEW_OPTIONS.GRAPH_2D)
    const { i18n } = useAwaitTranslation()
    const selectedLangString = i18n?.language
    const currentResponsesVar = useReactiveVar(myCurrentInputResponseVar)
    const currentLabel = useReactiveVar(myCurrentInputResponseLabelVar)
    const theme = useTheme()
    const fields: FeedbackField[] = useMemo(() => {
        return currentResponsesVar.currentResponseData
            .filter((item) =>
                config?.criteriaIds?.includes(item.input?.criteria?.id)
            )
            ?.map((field) => {
                return {
                    input: field.input,
                    scores: getSelectedResponseScores(field, currentLabel),
                }
            })
    }, [
        config?.criteriaIds,
        currentResponsesVar.currentResponseData,
        currentLabel,
    ])

    let scores = fields.flatMap((field) => field.scores)
    const conceptIds = scores
        .flatMap((score) => score.concepts)
        ?.map((concept) => concept.id)
    const filter: _ConceptFilter = {
        id_in: conceptIds ?? [],
    }
    const { data } = useQuery<
        ConceptWithConnectionsFilteredQuery,
        ConceptWithConnectionsFilteredQueryVariables
    >(CONCEPT_WITH_CONNECTIONS_FILTERED, {
        variables: {
            filter: filter,
            connectionsFilter: filter,
        },
    })
    const concepts = data?.Concept ?? null
    const formattedGraphData = useMemo(() => {
        const nodes: {
            id: string
            inputId: string
            title: string
            category?: string
            imageUrl: string | null
            __typename: "Concept" | "Input"
            fontSize?: number
            tooltip?: string
        }[] = [
            {
                id: props.concept.id,
                inputId: "null",
                title:
                    (props.concept?.translations.filter(
                        (t) => t.languageString === selectedLangString
                    )[0]?.title || props.concept.title) +
                    ` (${props.concept.category?.name})`,
                imageUrl: props.concept.imageUrl,
                __typename: props.concept.__typename,
                fontSize: 30,
                tooltip: getConceptTooltip(
                    props.concept?.translations.filter(
                        (t) => t.languageString === selectedLangString
                    )[0]?.title || props.concept.title,
                    `${props.concept.createdBy?.firstName} ${props.concept.createdBy?.lastName}`,
                    props.concept.category?.name,
                    props.concept?.translations.filter(
                        (t) => t.languageString === selectedLangString
                    )[0]?.summary || props.concept.summary,
                    theme
                ),
            },
        ]
        const links = []

        if (!concepts) {
            return { nodes, links }
        }

        fields.forEach((field) => {
            links.push({
                source: props.concept?.id,
                target: field.input?.id,
            })
            nodes.push({
                id: field.input?.id,
                inputId: field.input?.id,
                imageUrl: null,
                title:
                    JSON.parse(field.input?.inputConfig ?? "{}")?.name ||
                    field.input?.criteria?.name ||
                    "",
                __typename: field.input?.__typename,
            })

            field.scores
                ?.flatMap((score) => score.concepts)
                .forEach((concept) => {
                    nodes.push({
                        id: concept.id,
                        inputId: field.input?.id,
                        title:
                            (concept?.translations.filter(
                                (t) => t.languageString === selectedLangString
                            )[0]?.title || concept.title) +
                            ` (${concept.category?.name})`,

                        imageUrl: concept.imageUrl,
                        __typename: concept.__typename,
                        tooltip: getConceptTooltip(
                            concept?.translations.filter(
                                (t) => t.languageString === selectedLangString
                            )[0]?.title || concept.title,
                            `${concept.createdBy?.firstName} ${concept.createdBy?.lastName}`,
                            concept.category?.name,
                            concept?.translations.filter(
                                (t) => t.languageString === selectedLangString
                            )[0]?.summary || concept.summary,
                            theme
                        ),
                    })
                    links.push({
                        target: field?.input?.id,
                        source: concept.id,
                    })
                    concepts
                        ?.find((c) => c.id === concept.id)
                        ?.connections?.forEach((c) => {
                            links.push({
                                source: concept.id,
                                target: c.id,
                            })
                        })
                })
        })

        const nodeIds = []
        return {
            nodes: nodes.filter((n) =>
                nodeIds.indexOf(n.id) === -1 ? nodeIds.push(n.id) : false
            ),
            links,
        }
    }, [
        concepts,
        fields,
        theme,
        selectedLangString,
        props.concept.__typename,
        props.concept?.title,
        props.concept?.summary,
        props.concept?.createdBy,
        props.concept?.category,
        props.concept?.id,
        props.concept?.imageUrl,
        props.concept?.translations,
    ])

    return (
        <Box
            component={!props.isDialog ? Paper : "div"}
            display="flex"
            height="100%"
            width="100%"
            flexDirection="column"
            key={props.widget.id}
            onMouseOver={() => {
                if (!props.isDialog && !hovered) {
                    setHovered(true)
                }
            }}
            onMouseLeave={() => {
                if (!props.isDialog && !!hovered) {
                    setHovered(false)
                }
            }}
        >
            <WidgetHeader
                label={config?.label}
                interactionTools={[]}
                configurationTools={[]}
                hovered={hovered || !!props.isDialog}
                widget={props.widget}
                onWidgetDeletion={props.onWidgetDeletion}
                editing={props.editing}
            />
            <Box
                height="100%"
                width="100%"
                position={"relative"}
                overflow="hidden"
            >
                <LoadingOverlayWrapper loading={!isInitialized || !concepts}>
                    <Box key={props.widget.id} overflow="hidden" height="100%">
                        <Box position="absolute" right={8} top={8} zIndex={1}>
                            <ToggleButtonGroup
                                exclusive
                                value={viewMode}
                                size="small"
                                onChange={(event, value) => {
                                    setViewMode(value)
                                }}
                                style={{
                                    backgroundColor:
                                        theme.palette.type === "light"
                                            ? theme.palette.background.paper
                                            : "transparent",
                                }}
                            >
                                <ToggleButton value={VIEW_OPTIONS.GRAPH_2D}>
                                    2D
                                </ToggleButton>
                                <ToggleButton value={VIEW_OPTIONS.GRAPH_3D}>
                                    3D
                                </ToggleButton>
                            </ToggleButtonGroup>
                        </Box>
                        <GraphView
                            nodeAutoColorBy={"inputId"}
                            formattedData={formattedGraphData}
                            viewMode={viewMode}
                        />
                    </Box>
                </LoadingOverlayWrapper>
            </Box>
        </Box>
    )
}

export default GraphWidget
