import {
    Popper,
    ListItem,
    ListItemIcon,
    ListItemText,
    Box,
    ClickAwayListener,
    Typography,
    TextField,
    InputAdornment,
    Chip,
    IconButton,
} from "@material-ui/core"
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown"
import React from "react"
import {
    makeStyles,
    Theme,
    createStyles,
    useTheme,
} from "@material-ui/core/styles"
import ClearIcon from "@material-ui/icons/Clear"
import TreeView from "@material-ui/lab/TreeView"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import ChevronRightIcon from "@material-ui/icons/ChevronRight"
import TreeItem from "@material-ui/lab/TreeItem"
import { Category } from "../../__generated__/types"
import useAwaitTranslation from "../../i18n/useAwaitTranslation"
import HighlightOffIcon from "@material-ui/icons/HighlightOff"

import useCategoryTools from "./useCategoryTools"
import CategoryIcon from "./CategoryIcon"
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: "100%",
            fontSize: 13,
            position: "relative",
        },
        button: {
            width: "100%",
            padding: theme.spacing(2),
            border: `1px solid ${theme.palette.divider}`,
            borderRadius: theme.shape.borderRadius,
        },
        allContent: {
            "&:hover": {
                backgroundColor: theme.palette.action.hover + " !important",
            },
            cursor: "pointer",
        },
        clickable: {
            "&:hover": {
                backgroundColor: theme.palette.action.hover,
                borderRadius: "4px",
            },
            cursor: "pointer",
        },
        popper: {
            boxShadow: theme.shadows[10],
            borderRadius: 3,
            zIndex: 1800,
            fontSize: 13,
            backgroundColor: theme.palette.background.paper,
        },
        header: {
            borderBottom: "1px solid #e1e4e8",
            padding: "8px 10px",
            fontWeight: 600,
        },
        paper: {
            boxShadow: "none",
            margin: 0,
            color: "#586069",
            fontSize: 13,
        },

        popperDisablePortal: {
            position: "relative",
        },

        treeRoot: {
            display: "flex",
            flexDirection: "column",
            overflow: "hidden",
            padding: theme.spacing(1, 3),
        },
        treeContainer: {
            flexGrow: 1,
            overflowY: "auto",
            padding: theme.spacing(0, 1),
        },
        treeItem: {
            backgroundColor: "transparent !important",
            padding: 0,
        },
        iconContainer: {
            "&:hover": {
                backgroundColor: theme.palette.action.hover + " !important",
            },

            borderRadius: theme.shape.borderRadius,
            display: "flex",
            alignItems: "center",
            margin: 0,
            width: "18px",
        },
        inactiveIconContainer: {
            cursor: "default",
            margin: 0,
            width: "18px",
        },
        content: {
            alignItems: "stretch",
        },
        listContainer: {
            maxHeight: 400,
            overflowY: "auto",
        },
    })
)

interface MultiCategoryTreeSelectorProps {
    value: string[]
    setValue?: (value: Category[]) => void
    disabled?: boolean
    label?: string
}

export default function MultiCategoryTreeSelector(
    props: MultiCategoryTreeSelectorProps
) {
    const { value, setValue, disabled, label } = props
    const { environmentCategories } = useCategoryTools()
    const theme = useTheme()
    const ref = React.useRef(null)

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
    const [hovered, setHovered] = React.useState(false)
    let options = environmentCategories ?? []

    const classes = useStyles()
    const rootOptions = options.filter((category) => !!category.isRoot)
    let selectedCategories = []
    options.map((item) => {
        if (value?.includes(item.id)) {
            selectedCategories[value.indexOf(item.id)] = item
        }
        return selectedCategories
    })
    selectedCategories = selectedCategories.filter((x) => !!x)
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget)
    }

    const handleClose = (event: React.ChangeEvent<{}>) => {
        setAnchorEl(null)
    }

    const open = Boolean(anchorEl)
    const id = "category-selector"

    const position = ref?.current?.getBoundingClientRect() ?? null
    const { t } = useAwaitTranslation("concepts")
    const valNames = selectedCategories.map((category) => category.name)
    return disabled || !setValue ? (
        <Chip
            label={
                <Box>
                    <Box component="span" fontWeight="bold" mr={0.5}>
                        {t("categories", "Categories")}:
                    </Box>

                    {valNames.join("; ")}
                </Box>
            }
        />
    ) : (
        <div
            className={classes.root}
            ref={ref}
            onMouseEnter={(e) => {
                setHovered(true)
            }}
            onMouseLeave={() => {
                setHovered(false)
            }}
        >
            <TextField
                aria-describedby={id}
                onClick={handleClick}
                variant="outlined"
                size={"small"}
                label={
                    <Box display="flex" alignItems="center">
                        <Box>{label || t("categories", "Categories")}</Box>
                    </Box>
                }
                InputProps={{
                    style: {
                        paddingLeft: 0,
                    },
                    endAdornment:
                        selectedCategories.length === 0 ? (
                            <InputAdornment position="end">
                                <IconButton size="small">
                                    <ArrowDropDownIcon
                                        style={{
                                            transform: !!open
                                                ? "rotate(180deg)"
                                                : "none",
                                        }}
                                    />
                                </IconButton>
                            </InputAdornment>
                        ) : null,
                    startAdornment:
                        selectedCategories.length > 0 ? (
                            <InputAdornment
                                position="start"
                                style={{
                                    display: "flex",
                                    minWidth: "100%",
                                    height: "100%",
                                    maxHeight: "100%",
                                    width: "100%",
                                    cursor: "pointer",
                                    padding: theme.spacing(0.75),
                                }}
                            >
                                <Box
                                    display="flex"
                                    width="100%"
                                    overflow="hidden"
                                    flexWrap="wrap"
                                >
                                    {selectedCategories.map(
                                        (category, index) => {
                                            return (
                                                <Box key={category.id} p={0.25}>
                                                    <Chip
                                                        label={
                                                            <Typography
                                                                variant="body2"
                                                                style={{
                                                                    whiteSpace:
                                                                        "nowrap",
                                                                    textOverflow:
                                                                        "ellipsis",
                                                                    overflow:
                                                                        "hidden",
                                                                }}
                                                            >
                                                                {category.name}
                                                            </Typography>
                                                        }
                                                        style={{
                                                            height: "100%",
                                                        }}
                                                        icon={
                                                            <span
                                                                style={{
                                                                    color:
                                                                        theme
                                                                            .palette
                                                                            .action
                                                                            .active,
                                                                    display:
                                                                        "flex",
                                                                    alignItems:
                                                                        "center",
                                                                }}
                                                            >
                                                                <CategoryIcon
                                                                    fontSize="small"
                                                                    category={
                                                                        category
                                                                    }
                                                                />
                                                            </span>
                                                        }
                                                        deleteIcon={
                                                            <HighlightOffIcon fontSize="medium" />
                                                        }
                                                        onDelete={(event) => {
                                                            setValue(
                                                                selectedCategories.filter(
                                                                    (item) =>
                                                                        item.id !==
                                                                        category.id
                                                                )
                                                            )
                                                        }}
                                                    />
                                                </Box>
                                            )
                                        }
                                    )}
                                </Box>

                                <IconButton
                                    size="small"
                                    style={{
                                        visibility: hovered
                                            ? "visible"
                                            : "hidden",
                                    }}
                                    onClick={(e) => {
                                        e.stopPropagation()
                                        setValue([])
                                    }}
                                >
                                    <ClearIcon fontSize="small" />
                                </IconButton>

                                <IconButton size="small">
                                    <ArrowDropDownIcon
                                        style={{
                                            transform: !!open
                                                ? "rotate(180deg)"
                                                : "none",
                                        }}
                                    />
                                </IconButton>
                            </InputAdornment>
                        ) : null,
                }}
                fullWidth
                inputProps={{
                    readOnly: true,
                    style: { cursor: "pointer" },
                }}
            />

            <Popper
                id={id}
                open={open}
                anchorEl={anchorEl}
                placement="bottom-start"
                className={classes.popper}
                style={{
                    top: position?.top ?? 0,
                    right: position?.right ?? 0,
                    left: position?.left ?? 0,
                    width: position?.width ?? 0,
                    zIndex: theme.zIndex.modal + 1,
                }}
            >
                <ClickAwayListener onClickAway={handleClose}>
                    <Box className={classes.listContainer}>
                        {rootOptions.map((option, index) => {
                            return (
                                <TreeView
                                    key={option.id}
                                    defaultCollapseIcon={<ExpandMoreIcon />}
                                    defaultExpandIcon={<ChevronRightIcon />}
                                    defaultExpanded={
                                        rootOptions
                                            ?.map((item) =>
                                                item.isRoot ? item.id : null
                                            )
                                            ?.filter((x) => !!x) ?? []
                                    }
                                >
                                    <TreeItemContainer
                                        node={option}
                                        setValue={(value: Category[]) => {
                                            setValue(value)
                                        }}
                                        value={selectedCategories}
                                    />
                                </TreeView>
                            )
                        })}
                    </Box>
                </ClickAwayListener>
            </Popper>
        </div>
    )
}

interface TreeItemContainerProps {
    node: Category
    setValue: (value: Category[]) => void
    value: Category[]
}
const TreeItemContainer = (props: TreeItemContainerProps) => {
    const { node, setValue, value } = props
    const classes = useStyles()

    const { environmentCategories } = useCategoryTools()

    const selected = value.some((category) => category.id === node.id)
    const children = environmentCategories.filter(
        (cat) => cat.parent?.id === node.id
    )
    return (
        <Box key={node.id}>
            <TreeItem
                classes={{
                    label: classes.treeItem,
                    iconContainer:
                        children?.length > 0
                            ? classes.iconContainer
                            : classes.inactiveIconContainer,
                    content: classes.content,
                }}
                key={node.id}
                nodeId={node.id}
                onLabelClick={(e) => {
                    if (selected) {
                        setValue(value.filter((item) => item.id !== node.id))
                    } else {
                        setValue([...value, node])
                    }
                    e.preventDefault()
                }}
                label={
                    <ListItem dense button selected={selected}>
                        <ListItemText primary={node.name} />
                        {node.isRoot && (
                            <ListItemIcon>
                                <CategoryIcon
                                    category={node}
                                    fontSize="small"
                                />
                            </ListItemIcon>
                        )}
                    </ListItem>
                }
            >
                {Array.isArray(children)
                    ? children?.map((node) => (
                          <TreeItemContainer
                              key={node.id}
                              node={node}
                              setValue={setValue}
                              value={value}
                          />
                      ))
                    : null}
            </TreeItem>
        </Box>
    )
}
