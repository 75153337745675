import {
    useApolloClient,
    useMutation,
    useQuery,
    useReactiveVar,
} from "@apollo/client"
import { useEffect, useState, useCallback, useMemo } from "react"
import GridLayout, { Layout } from "react-grid-layout"
import { Loading } from "../Loading"
import { GET_DASHBOARD_BY_ID, UPDATE_DASHBOARD_LAYOUT } from "./graphql"
import { useWidgets } from "./useWidgets"
import ErrorHandler from "../ErrorHandler"
import {
    DashboardByIdQuery,
    DashboardByIdQueryVariables,
    UpdateDashboardLayoutMutation,
    UpdateDashboardLayoutMutationVariables,
} from "./__generated__/graphql"
import { Box, useMediaQuery } from "@material-ui/core"
import { Theme, useTheme } from "@material-ui/core/styles"
import "react-grid-layout/css/styles.css"
import "react-resizable/css/styles.css"
import "./Dashboard.css"
import {
    myCurrentInputResponseVar,
    currentWorkspaceWidgetsVar,
} from "../../providers/GlobalState"
import { WidgetTypeName } from "./useWidgetTypes"
import { Concept, SystemRole } from "../../__generated__/types"
import useWidget from "./useWidget"
import { useInputTools } from "../inputs/useInputTools"
import { useParams } from "react-router-dom"
import useCategoryTools from "../categories/useCategoryTools"
import { CONCEPT_INPUTS } from "../inputs/graphql"
import { useAuth } from "../../providers/AuthProvider"
import { getInputId } from "../inputs/util"
type GridLayoutItem = Layout

const ROW_HEIGHT = 30

type Props = {
    dashboardId: string
    width: number
    editing?: boolean
    concept?: Concept
    printView?: boolean
}

export default function Dashboard(props: Props) {
    const { conceptId } = useParams()
    const { currentUser } = useAuth()
    const currentResponsesVar = useReactiveVar(myCurrentInputResponseVar)
    const { allEnvironmentFieldIds } = useCategoryTools()
    const { data, error } = useQuery<
        DashboardByIdQuery,
        DashboardByIdQueryVariables
    >(GET_DASHBOARD_BY_ID, {
        variables: {
            id: props.dashboardId,
        },
        skip: !props.dashboardId,
    })
    const theme = useTheme()
    const client = useApolloClient()
    const mobile = useMediaQuery(
        (theme: Theme) => theme.breakpoints.down("xs"),
        { noSsr: true }
    )

    const [execute] = useMutation<
        UpdateDashboardLayoutMutation,
        UpdateDashboardLayoutMutationVariables
    >(UPDATE_DASHBOARD_LAYOUT)
    const [isInitialized, setIsInitialized] = useState(false)
    const { onDeleteWidget } = useWidget(null)
    const [creatingInputs, setCreatingInputs] = useState(false)
    const { onCreateNewInput } = useInputTools({})
    const [layout, setLayout] = useState<Array<GridLayoutItem>>(null)
    const dashboard = data?.DashboardView?.[0] ?? null

    const onLayoutChange = async (layout: Array<GridLayoutItem>) => {
        if (!mobile && props.editing) {
            setLayout(layout)
            await execute({
                variables: {
                    id: props.dashboardId,
                    layout: JSON.stringify(layout),
                },
            })
        }
    }
    const onWidgetDeletion = useCallback(
        async (props: { widgetId: string; inputId?: string }) => {
            await onDeleteWidget(props.widgetId)
        },
        [onDeleteWidget]
    )

    const {
        widgets,
        widgetTypes,
        isInitialized: isWidgetInitialized,
    } = useWidgets(props.dashboardId)
    const validWidgets = widgets.filter(
        (widget) => !!widgetTypes.find((w) => w.name === widget.type)
    )

    //dialog variables
    const sortedLayout = layout
        ?.map((item) => {
            let position = item.y + item.x / 100
            let widget = validWidgets.find((w) => w.id === item.i)
            let component = widgetTypes.find(
                (w) => w.name === widget?.type
            )?.component
            return { ...item, widget: { ...widget, component }, position }
        })
        ?.sort((a, b) => a.position - b.position)
        ?.filter((item) => validWidgets.some((widget) => widget.id === item.i))

    const createInputs = useCallback(
        async (items: { criteriaId: string; inputConfig: string }[]) => {
            setCreatingInputs(true)
            await Promise.all([
                ...items.map((item) =>
                    onCreateNewInput(item.criteriaId, item.inputConfig)
                ),
            ])
            await client.refetchQueries({
                include: [CONCEPT_INPUTS],
            })
            setCreatingInputs(false)
        },
        [client, onCreateNewInput]
    )

    useEffect(() => {
        //sorting the input widgets based off location of widget
        if (!!sortedLayout && !!validWidgets && !!currentResponsesVar) {
            const layoutItems = sortedLayout
                ?.filter(
                    (layoutItem) =>
                        !!validWidgets.find(
                            (validWidget) => validWidget.id === layoutItem.i
                        )
                )
                .map((layoutItem) => {
                    const widgetConfig = JSON.parse(
                        layoutItem.widget.config || "{}"
                    )

                    return {
                        ...layoutItem.widget,
                        fieldData:
                            layoutItem.widget.type === WidgetTypeName.Criteria
                                ? currentResponsesVar.currentResponseData.find(
                                      (item) =>
                                          getInputId(
                                              item.input.criteria?.id,
                                              conceptId,
                                              JSON.parse(
                                                  item.input.inputConfig ?? "{}"
                                              )
                                          ) ===
                                          getInputId(
                                              widgetConfig.criteriaId,
                                              conceptId,
                                              widgetConfig.inputConfig
                                          )
                                  ) || null
                                : null,
                    }
                })
            if (layoutItems.length > 0) {
                currentWorkspaceWidgetsVar({
                    conceptId: conceptId,
                    orderedWidgets: layoutItems,
                })
            }
        }
    }, [currentResponsesVar, sortedLayout, validWidgets, conceptId])

    useEffect(() => {
        if (
            !creatingInputs &&
            !!isWidgetInitialized &&
            currentResponsesVar.conceptId === conceptId
        ) {
            let inputsToCreate = []
            validWidgets
                .filter((widget) => widget.type === WidgetTypeName.Criteria)
                ?.map((widget) => {
                    const widgetConfig = JSON.parse(widget.config ?? "{}")
                    if (widgetConfig.inputConfig?.subfields?.length > 0) {
                        widgetConfig.inputConfig?.subfields.forEach((sub) => {
                            if (
                                allEnvironmentFieldIds.includes(sub.id) &&
                                !currentResponsesVar.currentResponseData.find(
                                    (item) =>
                                        getInputId(
                                            item.input?.criteria?.id,
                                            conceptId,
                                            JSON.parse(
                                                item.input?.inputConfig ?? "{}"
                                            )
                                        ) ===
                                        getInputId(
                                            sub.id,
                                            conceptId,
                                            sub.inputConfig
                                        )
                                )
                            ) {
                                inputsToCreate.push({
                                    criteriaId: sub.id,
                                    inputConfig: JSON.stringify({
                                        ...sub.inputConfig,
                                        source: {
                                            ...(sub.inputConfig?.source ?? {}),
                                            criteriaIds: [
                                                { id: widgetConfig.criteriaId },
                                            ],
                                        },
                                    }),
                                })
                            }
                        })
                    }

                    if (
                        allEnvironmentFieldIds.includes(
                            widgetConfig.criteriaId
                        ) &&
                        !currentResponsesVar.currentResponseData.find(
                            (item) =>
                                getInputId(
                                    item.input?.criteria?.id,
                                    conceptId,
                                    JSON.parse(item.input?.inputConfig ?? "{}")
                                ) ===
                                getInputId(
                                    widgetConfig.criteriaId,
                                    conceptId,
                                    widgetConfig.inputConfig
                                )
                        )
                    ) {
                        inputsToCreate.push({
                            criteriaId: widgetConfig.criteriaId,
                            inputConfig: JSON.stringify(
                                widgetConfig.inputConfig
                            ),
                        })
                    }

                    return inputsToCreate
                })

            if (inputsToCreate?.length > 0) {
                createInputs(inputsToCreate)
            }
        }
    }, [
        validWidgets,
        currentResponsesVar,
        conceptId,
        createInputs,
        isWidgetInitialized,
        creatingInputs,
        allEnvironmentFieldIds,
    ])

    useEffect(() => {
        if (!!data) {
            if (!!dashboard) {
                if (!!dashboard?.layout && !layout) {
                    const parsedLayout = JSON.parse(dashboard.layout)
                    setLayout(parsedLayout)
                    setIsInitialized(true)
                }
            } else {
                setIsInitialized(true)
            }
        }
    }, [dashboard, layout, mobile, data])
    //variables

    const children = useMemo(() => {
        return validWidgets.map((widget, idx) => {
            const type = widgetTypes.find((w) => w.name === widget.type)

            const Component = type.component
            const defaultLayoutConfig = Component?.defaultLayout ?? {}
            const editableDashboardCheck =
                (!!props.concept.homeDashboard ||
                    currentUser.role === SystemRole.ADMIN) &&
                !!props.dashboardId
            return (
                <Box
                    key={widget.id}
                    data-grid={{
                        w: 4,
                        h: 4,
                        x: 0,
                        y: Infinity,
                        ...defaultLayoutConfig,
                    }}
                    display="flex"
                    flexGrow={!!mobile ? 1 : 0}
                    borderRadius={theme.shape.borderRadius}
                >
                    {
                        //to fix the double rendering of same component
                        !!Component && (
                            <Component
                                widget={widget}
                                editing={
                                    props.editing && !!editableDashboardCheck
                                }
                                concept={props.concept}
                                onWidgetDeletion={onWidgetDeletion}
                            />
                        )
                    }
                </Box>
            )
        })
    }, [
        mobile,
        onWidgetDeletion,
        props.concept,
        props.editing,
        theme.shape.borderRadius,
        validWidgets,
        widgetTypes,
        currentUser.role,
        props.dashboardId,
    ])
    if (error) {
        return <ErrorHandler showMessage={true} message={error.message} />
    }

    return !layout ||
        !isInitialized ||
        (!isWidgetInitialized && !!props.dashboardId) ? (
        <Loading size={25} hideQuote={true} />
    ) : (
        <Box width="100%">
            {props.printView || mobile ? (
                <Box display="flex" flexDirection="column">
                    {sortedLayout.map((layoutItem) => {
                        const widget = children.find(
                            (widget) => widget.key === layoutItem.i
                        )
                        return (
                            <Box
                                key={layoutItem.i}
                                p={1}
                                minHeight={layoutItem.h * ROW_HEIGHT}
                                display="flex"
                                width="100%"
                            >
                                {widget}
                            </Box>
                        )
                    })}
                </Box>
            ) : (
                <GridLayout
                    layout={layout ?? []}
                    cols={12}
                    key={props.dashboardId}
                    rowHeight={ROW_HEIGHT}
                    onLayoutChange={onLayoutChange}
                    isDraggable={props.editing}
                    isResizable={props.editing}
                    draggableHandle=".widget-drag-handle"
                    autoSize={true}
                    width={props.width ?? 150}
                >
                    {children}
                </GridLayout>
            )}
        </Box>
    )
}
