import React, { useState, useEffect, useRef } from "react"
import {
    Box,
    useMediaQuery,
    Typography,
    Button,
    SwipeableDrawer,
    Toolbar,
} from "@material-ui/core"
import { makeStyles, Theme, useTheme } from "@material-ui/core/styles"
import ConceptDetailsHeader from "./ConceptDetailsHeader"
import { useQuery, useReactiveVar } from "@apollo/client"
import { useAuth } from "../providers/AuthProvider"
import { useParams } from "react-router-dom"
import { CONCEPT_BY_ID } from "../graphql/queries"
import { Loading } from "./Loading"
import Dashboard from "./dashboard/Dashboard"

import {
    ConceptQuery,
    ConceptQueryVariables,
} from "../graphql/__generated__/queries"
import ConceptDetailsToolbar from "./ConceptDetailsToolbar"
import { SizeMe } from "react-sizeme"
import { Concept } from "../__generated__/types"
import { getUserConceptPermission } from "../util/GetPermissions"
import {
    allPageConceptConnectionsVar,
    myCurrentInputResponseVar,
    userConceptPermissionsVar,
    workspaceLoadTimeVar,
} from "../providers/GlobalState"
import CommentsContainer from "./commenting/CommentsContainer"
import useAwaitTranslation from "../i18n/useAwaitTranslation"
import ConceptTranslationsModal from "./ConceptTranslationsModal"
import ErrorHandler from "./ErrorHandler"
import useCategoryTools from "./categories/useCategoryTools"
import { useHistory } from "react-router-dom"
import ConceptListItem from "./ConceptListItem"
import ArrowBack from "@material-ui/icons/ArrowBack"
import {
    ConceptConnectionsQuery,
    ConceptConnectionsQueryVariables,
} from "./inputs/__generated__/graphql"
import useFilters from "./filters/util/useFilters"
import { CONCEPT_CONNECTIONS } from "./inputs/graphql"
import PresentationMode from "./presentation/PresentationMode"

const drawerWidth = 260

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        flexGrow: 1,
        overflow: "hidden",
        maxWidth: "100%",
        width: "100%",
    },
    rootPreview: {
        flexGrow: 1,
        overflow: "hidden",
        width: "100%",
    },
    drawer: {
        zIndex: theme.zIndex.appBar - 1,
        width: drawerWidth,
        flexShrink: 0,
    },
    closedDrawer: {
        zIndex: theme.zIndex.appBar - 1,
        width: 0,
        flexShrink: 0,
    },
    drawerPaper: {
        width: drawerWidth,
        backgroundColor: theme.palette.background.default,
    },

    topToolbar: {
        position: "fixed",
        zIndex: theme.zIndex.appBar - 1,
    },
}))

interface ConceptDetailsProps {
    concept?: Concept
}
const ConceptDetails = (props: ConceptDetailsProps) => {
    //hooks
    const { conceptId: pageConceptId } = useParams()
    const theme = useTheme()
    const mobile = useMediaQuery(theme.breakpoints.down("sm"))
    const printView = useMediaQuery("print")
    const { getPermissionsFilter } = useFilters()
    const tabletOrBelow = !useMediaQuery(theme.breakpoints.up("md"))
    const classes = useStyles()
    const { currentUser, isNonMember } = useAuth()
    const userConceptPermissions = useReactiveVar(userConceptPermissionsVar)
    const currentResponsesVar = useReactiveVar(myCurrentInputResponseVar)
    const { conceptId: connectionsConceptId } = useReactiveVar(
        allPageConceptConnectionsVar
    )
    const [commentsOpen, setCommentsOpen] = useState(false)
    const { t } = useAwaitTranslation("concepts")
    const history = useHistory()
    //queries
    const { loading, error, data } = useQuery<
        ConceptQuery,
        ConceptQueryVariables
    >(CONCEPT_BY_ID, {
        variables: { id: pageConceptId },
        skip: !!props.concept,
    })
    const { data: { Concept: connections } = {} } = useQuery<
        ConceptConnectionsQuery,
        ConceptConnectionsQueryVariables
    >(CONCEPT_CONNECTIONS, {
        variables: {
            filter: {
                connections_some: {
                    id: pageConceptId,
                },
                ...getPermissionsFilter(),
            },
            pageConceptId: pageConceptId,
        },
        fetchPolicy: "network-only",
    })
    //state
    const [openTranslationsModal, setOpenTranslationsModal] = useState(false)

    const [editing, setEditing] = useState(false)

    //variables
    const item = props.concept ?? data?.Concept?.[0] ?? null

    const { environmentCategories } = useCategoryTools()
    let conceptIdRendered = useRef(null)

    //useEffects

    useEffect(() => {
        if (!!connections) {
            allPageConceptConnectionsVar({
                conceptId: pageConceptId,
                connections,
            })
        }
    }, [connections, pageConceptId])
    useEffect(() => {
        workspaceLoadTimeVar(Date.now())
    }, [])
    useEffect(() => {
        if (
            !!currentUser?.userId &&
            !!item?.id &&
            userConceptPermissions.conceptId !== item?.id
        ) {
            userConceptPermissionsVar(
                getUserConceptPermission(item, currentUser)
            )
        }
    }, [currentUser, item, userConceptPermissions.conceptId])

    useEffect(() => {
        if (!!item && item?.id !== conceptIdRendered?.current) {
            setEditing(false)
            conceptIdRendered.current = item?.id
        }
    }, [item, mobile])

    //query handling
    if (loading || !item || connectionsConceptId !== pageConceptId) {
        if (!!props.concept) {
            return null
        }
        return (
            <Box
                height={`calc(100vh - ${theme.spacing(8)}px)`}
                width="100%"
                display="flex"
                justifyContent={"center"}
                alignItems="center"
            >
                <Loading size={25} hideQuote={true} />{" "}
            </Box>
        )
    }
    if (error) {
        return <ErrorHandler message={error.message} />
    }

    // use default category dashboard if it does not have a custom concept one
    const dashboard =
        (item.homeDashboard ||
            environmentCategories?.find((o) => o.id === item?.category?.id)
                ?.defaultDashboard) ??
        null

    if (
        !userConceptPermissions.viewingPermission &&
        userConceptPermissions.conceptId === item.id
    ) {
        return (
            <Box
                display={"flex"}
                p={2}
                mt={3}
                flexDirection="column"
                alignItems="center"
                height="100%"
            >
                <ConceptListItem
                    disableClick={true}
                    hideDivider={true}
                    item={item}
                />

                <Box mt={2} mb={2}>
                    <Typography variant="body1" style={{ fontWeight: "bold" }}>
                        {t(
                            "noPermissionsToView",
                            "You currently do not have required permissions to view this concept"
                        )}
                    </Typography>
                </Box>
                <Button
                    size="small"
                    startIcon={<ArrowBack />}
                    onClick={() => history.goBack()}
                >
                    Go Back
                </Button>
            </Box>
        )
    }
    const hasInputs =
        currentResponsesVar.conceptId === pageConceptId &&
        currentResponsesVar.currentResponseData.length > 0

    return (
        <Box display="flex" key={item.id} height="100%">
            <SizeMe monitorWidth monitorHeight>
                {({ size }) => (
                    <div
                        className={
                            props.concept ? classes.rootPreview : classes.root
                        }
                    >
                        <Box
                            className={classes.topToolbar}
                            width={size.width}
                            displayPrint="none"
                        >
                            <ConceptDetailsToolbar
                                item={item}
                                setOpenTranslationsModal={
                                    setOpenTranslationsModal
                                }
                                commentsOpen={commentsOpen}
                                toggleCommentsOpen={() => {
                                    setCommentsOpen(!commentsOpen)
                                }}
                                selectedDashboardId={dashboard?.id}
                                editing={editing}
                                toggleEditing={() => setEditing(!editing)}
                            />
                        </Box>

                        <Box
                            width="100%"
                            height="100%"
                            marginTop={
                                !!printView
                                    ? 0
                                    : (!!mobile || !!tabletOrBelow) &&
                                      !isNonMember &&
                                      !!hasInputs
                                    ? 13
                                    : 8
                            }
                            display="flex"
                            flexDirection="column"
                        >
                            <Box p={1.25} mr={2.5} ml={2.5}>
                                <ConceptDetailsHeader
                                    item={item}
                                    preview={!!props.concept}
                                    setOpenTranslationsModal={
                                        setOpenTranslationsModal
                                    }
                                    openTranslationsModal={
                                        openTranslationsModal
                                    }
                                    editing={editing}
                                />
                            </Box>

                            <Box height="100%" mr={2.5} ml={2.5}>
                                {!!dashboard && (
                                    <Dashboard
                                        key={dashboard?.id + item.id}
                                        dashboardId={dashboard?.id}
                                        // # subtracted from width has to match the margins of the parent above
                                        width={
                                            size.width - theme.spacing(5) ?? 25
                                        }
                                        editing={editing}
                                        concept={item}
                                        printView={printView}
                                    />
                                )}
                            </Box>
                        </Box>
                    </div>
                )}
            </SizeMe>
            {!props.concept && (
                <SwipeableDrawer
                    open={commentsOpen}
                    anchor="right"
                    variant={mobile ? "temporary" : "persistent"}
                    className={
                        commentsOpen ? classes.drawer : classes.closedDrawer
                    }
                    onOpen={() => setCommentsOpen(true)}
                    onClose={() => setCommentsOpen(false)}
                    classes={{
                        paper: classes.drawerPaper,
                    }}
                    style={{ marginBottom: 16 }}
                >
                    {!mobile && <Toolbar />}

                    <CommentsContainer
                        onClose={() => setCommentsOpen(false)}
                        conceptId={pageConceptId}
                    />
                </SwipeableDrawer>
            )}

            {openTranslationsModal && (
                <ConceptTranslationsModal
                    concept={item}
                    onClose={() => {
                        setOpenTranslationsModal(false)
                    }}
                />
            )}
            <PresentationMode concept={item} />
        </Box>
    )
}

export default ConceptDetails
