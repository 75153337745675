import { Box, Divider, Paper } from "@material-ui/core"
import { _ConceptOrdering } from "../../../__generated__/types"
import WidgetHeader from "./common/WidgetHeader"
import useWidget from "../useWidget"
import { WidgetTypeComponent } from "../useWidgetTypes"
import ConceptInfiniteScroll from "../../ConceptInfiniteScroll"
import { useParams } from "react-router-dom"
import { FilterItem } from "../../filters/util/types"
import useFilters from "../../filters/util/useFilters"
import WidgetFilterToggle from "./common/WidgetFilterToggle"
import ConceptGallery from "../../ConceptGallery"
import ConceptChipArray from "../../ConceptChipArray"
import { useState } from "react"
export type ConceptFeedWidgetConfig = {
    label: string
    conceptId?: string
    categoryId: string
    orderBy: _ConceptOrdering
    filters?: FilterItem[]
    source: string
    view: string
}

const ConceptFeedWidget: WidgetTypeComponent = (props) => {
    //hooks
    const { conceptId } = useParams()

    //state
    const { config, isInitialized } = useWidget<ConceptFeedWidgetConfig>(
        props.widget.id
    )
    const conceptIdTofetch = !!config?.conceptId ? config.conceptId : conceptId
    const [hovered, setHovered] = useState(false)
    const { currentFeedVariables, setCurrentFeedVariables, filterObject } =
        useFilters({
            viewPrivate: true,
            filters: config?.filters,
            connectionsConceptId:
                config?.source === "connections" ? conceptIdTofetch : null,

            subConnectionCategoryId:
                config?.source === "connections" && config?.categoryId
                    ? config.categoryId
                    : null,
        })

    return (
        <Box
            component={!props.isDialog ? Paper : "div"}
            display="flex"
            height="100%"
            width="100%"
            flexDirection="column"
            key={props.widget.id}
            overflow="hidden"
            onMouseOver={() => {
                if (!props.isDialog && !hovered) {
                    setHovered(true)
                }
            }}
            onMouseLeave={() => {
                if (!props.isDialog && !!hovered) {
                    setHovered(false)
                }
            }}
        >
            <WidgetHeader
                hovered={hovered || !!props.isDialog}
                label={config?.label}
                onWidgetDeletion={props.onWidgetDeletion}
                interactionTools={[
                    <WidgetFilterToggle
                        key={`${conceptIdTofetch}_filter`}
                        feedVariables={currentFeedVariables}
                        size="medium"
                        isDialog={props.isDialog}
                        setFeedVariables={setCurrentFeedVariables}
                    />,
                ]}
                widget={props.widget}
                editing={props.editing}
            />
            {config?.view !== "gallery" && config?.view !== "chip" && (
                <Divider flexItem />
            )}
            {!!isInitialized && (
                <Box key={props.widget.id} overflow="hidden" flexGrow={1}>
                    {config?.view === "gallery" ? (
                        <ConceptGallery
                            filter={filterObject}
                            orderBy={
                                config?.orderBy ??
                                ("createdAt_desc" as _ConceptOrdering)
                            }
                        />
                    ) : config?.view === "chip" ? (
                        <ConceptChipArray
                            filter={filterObject}
                            orderBy={
                                config?.orderBy ??
                                ("createdAt_desc" as _ConceptOrdering)
                            }
                        />
                    ) : (
                        <ConceptInfiniteScroll
                            filter={filterObject}
                            orderBy={
                                config?.orderBy ??
                                ("createdAt_desc" as _ConceptOrdering)
                            }
                        />
                    )}
                </Box>
            )}
        </Box>
    )
}

export default ConceptFeedWidget
