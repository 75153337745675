import { Typography, Box } from "@material-ui/core"
import dayjs from "dayjs"

import { CriteriaScore } from "../../../__generated__/types"
import { DateType } from "../../criteria/useCriteriaTypes"
import SectionHeader from "../SectionHeader"

const DateDeserializer = (props: {
    score: CriteriaScore
    config: DateType
    hideHeader?: boolean
}) => {
    const { config, score, hideHeader } = props
    const dateArray = JSON.parse(score?.response ?? "[]")
    const startDate = dateArray?.[0]
    let endDate = null
    if (!!config.range) {
        endDate = dateArray?.[1]
    }

    return (
        <Box>
            {!hideHeader && <SectionHeader headerName={config.name} />}
            <Typography gutterBottom variant="body1">
                {`${dayjs(startDate).format("MM/DD/YY")}${
                    !!config.range ? " - " : ""
                }${!!config.range ? dayjs(endDate).format("MM/DD/YY") : ""}`}
            </Typography>
        </Box>
    )
}

export default DateDeserializer
