import { Concept, CriteriaScore } from "../../__generated__/types"
import { CriteriaType } from "../criteria/types"
import { RESPONSE_VIEWER_REPOSITORY } from "../criteria/useCriteriaTypes"
import { CriteriaScoreInputVariables } from "./types"

// These are all props provided to all response viewers
export default function BaseInputResponseViewer(props: {
    //criteria id of input
    fieldId: string
    //criteria type of input
    criteriaType: CriteriaType
    inputId: string
    //configuration of input based on criteriaType
    config: any
    //scores available based on response selected
    scores: CriteriaScore[]
    //concepts scored if its sub criteria
    conceptsScored?: Concept[]
    //
    onCreateNewResponse: (
        data: CriteriaScoreInputVariables,
        scoredConceptId?: string
    ) => Promise<void>
    editing: boolean
}) {
    const ResponseViewer = RESPONSE_VIEWER_REPOSITORY[props.criteriaType]
    if (!!ResponseViewer) {
        return <ResponseViewer {...props} />
    } else return null
}
