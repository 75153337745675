import React from "react"
import { makeStyles, Theme } from "@material-ui/core/styles"
import Box from "@material-ui/core/Box"
import Divider from "@material-ui/core/Divider"
import ViewModuleIcon from "@material-ui/icons/ViewModule"
import {
    IconButton,
    ListItemIcon,
    ListItemSecondaryAction,
    ListItemText,
    MenuItem,
    MenuList,
    Typography,
    Hidden,
    TextField,
} from "@material-ui/core"

import ChevronRightIcon from "@material-ui/icons/ChevronRight"
import SortIcon from "@material-ui/icons/Sort"
import useAwaitTranslation from "../../i18n/useAwaitTranslation"
import { FilterType, SortOption } from "./util/types"
import CategoryFilter from "./CategoryFilter"
import IndustryFilter from "./IndustryFilter"
import UserFilter from "./UserFilter"
import Switch from "@material-ui/core/Switch"
import TagsFilter from "./TagsFilter"
import ConceptFilter from "./ConceptFilter"
import NotificationTypeFilter from "./NotificationTypeFilter"
import StatusFilter from "./StatusFilter"
import VisibilityOff from "@material-ui/icons/VisibilityOff"
import { sortOptions } from "./util/types"
import { FeedVariables } from "../../providers/GlobalState"
import useFilters from "./util/useFilters"
import { Autocomplete } from "@material-ui/lab"

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        display: "flex",
        flexDirection: "column",
        padding: theme.spacing(1),
        height: "100%",
    },
    listbox: {
        overflowY: "auto",
        overflowX: "hidden",
    },
    label: {
        fontSize: 12,
        color: theme.palette.text.secondary,
        paddingBottom: theme.spacing(0.5),
    },
    popper: {
        zIndex: theme.zIndex.modal + 2,
    },
    input: {
        zIndex: theme.zIndex.modal + 3,
    },

    betweenFiltersText: {
        color: theme.palette.text.hint,
    },
}))

const getComponent = (item) => {
    if (item.type === FilterType.Category) {
        return CategoryFilter
    } else if (item.type === FilterType.User) {
        return UserFilter
    } else if (item.type === FilterType.Industry) {
        return IndustryFilter
    } else if (item.type === FilterType.Tags) {
        return TagsFilter
    } else if (item.type === FilterType.Concept) {
        return ConceptFilter
    } else if (item.type === FilterType.NotificationType) {
        return NotificationTypeFilter
    } else if (item.type === FilterType.Status) {
        return StatusFilter
    }
}
interface FilterContainerProps {
    hideHeading?: boolean
    feedVariables?: FeedVariables
    onChangeFeedVariables?: (value: FeedVariables) => void
    heading?: string
    onSidePin?: () => void
    excludeCategory?: boolean
}
export default function Filters(props: FilterContainerProps) {
    const {
        heading,
        feedVariables,
        onChangeFeedVariables,
        excludeCategory,
    } = props
    const classes = useStyles()

    const { defaultFilters } = useFilters()
    const { t } = useAwaitTranslation("global")
    let filters = [
        ...(!!props.feedVariables.filters &&
        props.feedVariables.filters.length > 0
            ? props.feedVariables.filters
            : defaultFilters),
    ]
    if (!!excludeCategory) {
        filters = [
            ...filters.filter((filter) => filter.type !== FilterType.Category),
        ]
    }
    const enabledFilters = filters?.filter((x) => !x.disabled) ?? []
    const disabledFilters = filters?.filter((x) => !!x.disabled) ?? []
    const filtersAppliedNum = filters.filter((x) => x.value?.length > 0)?.length

    return (
        <Box
            display="flex"
            width="100%"
            flexDirection="column"
            maxHeight="600px"
        >
            <Box display="flex" width="100%" flexDirection="column" p={1}>
                {!props.hideHeading && (
                    <Box
                        width="100%"
                        p={1}
                        display="flex"
                        alignItems={"center"}
                    >
                        <Box flexShrink={0} ml={1} flexGrow={1}>
                            <Typography variant="subtitle1">
                                {heading ||
                                    `${t(
                                        "activeFilters",
                                        "Active Filters"
                                    )} (${filtersAppliedNum})`}
                            </Typography>
                        </Box>
                        {!!props.onSidePin && (
                            <Box display="flex">
                                <IconButton onClick={props.onSidePin}>
                                    <ChevronRightIcon />
                                </IconButton>
                            </Box>
                        )}
                    </Box>
                )}
                {filters?.length > 0 && (
                    <Box
                        display="flex"
                        flexDirection="column"
                        className={classes.listbox}
                    >
                        <Box display="flex" flexWrap="wrap">
                            {disabledFilters.map((filterItem, idx) => {
                                const Component = getComponent(filterItem)
                                return (
                                    <Box key={idx}>
                                        <Box display="flex">
                                            <Box width="100%" p={1}>
                                                {Component && (
                                                    <Component
                                                        item={filterItem}
                                                        setFilters={(filters) =>
                                                            onChangeFeedVariables(
                                                                {
                                                                    ...feedVariables,
                                                                    filters: [
                                                                        ...filters,
                                                                    ],
                                                                }
                                                            )
                                                        }
                                                        filters={filters}
                                                    />
                                                )}
                                            </Box>
                                        </Box>
                                    </Box>
                                )
                            })}
                        </Box>

                        {enabledFilters.map((filterItem, idx) => {
                            const Component = getComponent(filterItem)
                            return (
                                <Box key={idx}>
                                    <Box display="flex">
                                        <Box width="100%" p={1}>
                                            {Component && (
                                                <Component
                                                    item={filterItem}
                                                    setFilters={(filters) =>
                                                        onChangeFeedVariables({
                                                            ...feedVariables,
                                                            filters: [
                                                                ...filters,
                                                            ],
                                                        })
                                                    }
                                                    filters={filters}
                                                />
                                            )}
                                        </Box>
                                    </Box>
                                </Box>
                            )
                        })}
                    </Box>
                )}
            </Box>
            {!!Object.keys(props.feedVariables).some((field) =>
                ["sortBy", "columnCount", "viewPrivate"].includes(field)
            ) && (
                <Box>
                    <Box>
                        <Divider />
                    </Box>
                    <MenuList disablePadding>
                        {Object.keys(props.feedVariables).includes(
                            "sortBy"
                        ) && (
                            <MenuItem divider button={false}>
                                <ListItemIcon>
                                    <SortIcon />
                                </ListItemIcon>
                                <ListItemText
                                    primary={t("sortBy", "Sort By")}
                                    primaryTypographyProps={{
                                        color: "textSecondary",
                                    }}
                                />
                                <ListItemSecondaryAction>
                                    <Autocomplete
                                        options={sortOptions}
                                        onChange={(
                                            _: React.ChangeEvent<{}>,
                                            newValue: SortOption
                                        ) => {
                                            onChangeFeedVariables({
                                                ...feedVariables,
                                                sortBy: newValue.value,
                                            })
                                        }}
                                        getOptionLabel={(option: SortOption) =>
                                            t(
                                                option.translationKey,
                                                option.label
                                            )
                                        }
                                        getOptionSelected={(option) =>
                                            option.value ===
                                            feedVariables.sortBy
                                        }
                                        disableClearable
                                        value={sortOptions.find(
                                            (item) =>
                                                item.value ===
                                                feedVariables.sortBy
                                        )}
                                        renderInput={(params) => (
                                            <TextField
                                                style={{ width: "9em" }}
                                                {...params}
                                                InputProps={{
                                                    ...params.InputProps,
                                                    disableUnderline: true,
                                                }}
                                            />
                                        )}
                                    />
                                </ListItemSecondaryAction>
                            </MenuItem>
                        )}
                        {Object.keys(props.feedVariables).includes(
                            "columnCount"
                        ) && (
                            <Hidden xsDown>
                                <MenuItem button={false} divider>
                                    <ListItemIcon>
                                        <ViewModuleIcon />
                                    </ListItemIcon>
                                    <ListItemText
                                        primary={t(
                                            "columnCount",
                                            "Column Count"
                                        )}
                                        primaryTypographyProps={{
                                            color: "textSecondary",
                                        }}
                                    />
                                    <ListItemSecondaryAction>
                                        <Autocomplete
                                            options={["1", "2", "3", "4"]}
                                            onChange={(
                                                _: React.ChangeEvent<{}>,
                                                newValue: string
                                            ) => {
                                                onChangeFeedVariables({
                                                    ...feedVariables,
                                                    columnCount: Number(
                                                        newValue
                                                    ),
                                                })
                                            }}
                                            disableClearable
                                            value={JSON.stringify(
                                                feedVariables.columnCount
                                            )}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    InputProps={{
                                                        ...params.InputProps,
                                                        disableUnderline: true,
                                                    }}
                                                />
                                            )}
                                        />
                                    </ListItemSecondaryAction>
                                </MenuItem>
                            </Hidden>
                        )}
                        {Object.keys(props.feedVariables).includes(
                            "viewPrivate"
                        ) && (
                            <MenuItem button={false}>
                                <ListItemIcon>
                                    <VisibilityOff
                                        color={
                                            !feedVariables.viewPrivate
                                                ? "primary"
                                                : "disabled"
                                        }
                                    />
                                </ListItemIcon>
                                <ListItemText
                                    primary={t("hidePrivate", "Hide Private")}
                                    primaryTypographyProps={{
                                        color: !feedVariables.viewPrivate
                                            ? "primary"
                                            : "textSecondary",
                                    }}
                                />

                                <ListItemSecondaryAction>
                                    <Switch
                                        onChange={(e) => {
                                            onChangeFeedVariables({
                                                ...feedVariables,
                                                viewPrivate: !feedVariables.viewPrivate,
                                            })
                                        }}
                                        size="small"
                                        checked={!feedVariables.viewPrivate}
                                        color="primary"
                                    />
                                </ListItemSecondaryAction>
                            </MenuItem>
                        )}
                    </MenuList>
                    <Box>
                        <Divider />
                    </Box>
                </Box>
            )}
        </Box>
    )
}
