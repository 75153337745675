export let CurveData = [
    {
        number: 0 as number,
        hypeLine: 100 as number,
        dataPoints: {} as any,
    },
    {
        number: 1 as number,
        hypeLine: 130 as number,
        dataPoints: {} as any,
    },
    {
        number: 2 as number,
        hypeLine: 170 as number,
        dataPoints: {} as any,
    },
    {
        number: 3 as number,
        hypeLine: 210 as number,
        dataPoints: {} as any,
    },
    {
        number: 4 as number,
        hypeLine: 250 as number,
        dataPoints: {} as any,
    },
    {
        number: 5 as number,
        hypeLine: 290 as number,
        dataPoints: {} as any,
    },
    {
        number: 6 as number,
        hypeLine: 340 as number,
        dataPoints: {} as any,
    },
    {
        number: 7 as number,
        hypeLine: 390 as number,
        dataPoints: {} as any,
    },
    {
        number: 8 as number,
        hypeLine: 460 as number,
        dataPoints: {} as any,
    },
    {
        number: 9 as number,
        hypeLine: 530 as number,
        dataPoints: {} as any,
    },
    {
        number: 10 as number,
        hypeLine: 620 as number,
        dataPoints: {} as any,
    },
    {
        number: 11 as number,
        hypeLine: 715 as number,
        dataPoints: {} as any,
    },
    {
        number: 12 as number,
        hypeLine: 815 as number,
        dataPoints: {} as any,
    },
    {
        number: 13 as number,
        hypeLine: 920 as number,
        dataPoints: {} as any,
    },
    {
        number: 14 as number,
        hypeLine: 1030 as number,
        dataPoints: {} as any,
    },
    {
        number: 15 as number,
        hypeLine: 1145 as number,
        dataPoints: {} as any,
    },
    {
        number: 16 as number,
        hypeLine: 1270 as number,
        dataPoints: {} as any,
    },
    {
        number: 17 as number,
        hypeLine: 1400 as number,
        dataPoints: {} as any,
    },
    {
        number: 18 as number,
        hypeLine: 1545 as number,
        dataPoints: {} as any,
    },
    {
        number: 19 as number,
        hypeLine: 1700 as number,
        dataPoints: {} as any,
    },
    {
        number: 20 as number,
        hypeLine: 1750 as number,
        dataPoints: {} as any,
    },
    {
        number: 21 as number,
        hypeLine: 1700 as number,
        dataPoints: {} as any,
    },
    {
        number: 22 as number,
        hypeLine: 1450 as number,
        dataPoints: {} as any,
    },
    {
        number: 23 as number,
        hypeLine: 1200 as number,
        dataPoints: {} as any,
    },
    {
        number: 24 as number,
        hypeLine: 950 as number,
        dataPoints: {} as any,
    },
    {
        number: 25 as number,
        hypeLine: 700 as number,
        dataPoints: {} as any,
    },
    {
        number: 26 as number,
        hypeLine: 500 as number,
        dataPoints: {} as any,
    },
    {
        number: 27 as number,
        hypeLine: 350 as number,
        dataPoints: {} as any,
    },
    {
        number: 28 as number,
        hypeLine: 250 as number,
        dataPoints: {} as any,
    },
    {
        number: 29 as number,
        hypeLine: 225 as number,
        dataPoints: {} as any,
    },
    {
        number: 30 as number,
        hypeLine: 200 as number,
        dataPoints: {} as any,
    },
    {
        number: 31 as number,
        hypeLine: 230 as number,
        dataPoints: {} as any,
    },
    {
        number: 32 as number,
        hypeLine: 250 as number,
        dataPoints: {} as any,
    },
    {
        number: 33 as number,
        hypeLine: 280 as number,
        dataPoints: {} as any,
    },
    {
        number: 34 as number,
        hypeLine: 310 as number,
        dataPoints: {} as any,
    },
    {
        number: 35 as number,
        hypeLine: 340 as number,
        dataPoints: {} as any,
    },
    {
        number: 36 as number,
        hypeLine: 370 as number,
        dataPoints: {} as any,
    },
    {
        number: 37 as number,
        hypeLine: 395 as number,
        dataPoints: {} as any,
    },
    {
        number: 38 as number,
        hypeLine: 420 as number,
        dataPoints: {} as any,
    },
    {
        number: 39 as number,
        hypeLine: 445 as number,
        dataPoints: {} as any,
    },
    {
        number: 40 as number,
        hypeLine: 470 as number,
        dataPoints: {} as any,
    },
    {
        number: 41 as number,
        hypeLine: 495 as number,
        dataPoints: {} as any,
    },
    {
        number: 42 as number,
        hypeLine: 520 as number,
        dataPoints: {} as any,
    },
    {
        number: 43 as number,
        hypeLine: 545 as number,
        dataPoints: {} as any,
    },
    {
        number: 44 as number,
        hypeLine: 565 as number,
        dataPoints: {} as any,
    },
    {
        number: 45 as number,
        hypeLine: 585 as number,
        dataPoints: {} as any,
    },
    {
        number: 46 as number,
        hypeLine: 605 as number,
        dataPoints: {} as any,
    },
    {
        number: 47 as number,
        hypeLine: 625 as number,
        dataPoints: {} as any,
    },
    {
        number: 48 as number,
        hypeLine: 635 as number,
        dataPoints: {} as any,
    },
    {
        number: 49 as number,
        hypeLine: 645 as number,
        dataPoints: {} as any,
    },
    {
        number: 50 as number,
        hypeLine: 655 as number,
        dataPoints: {} as any,
    },
    {
        number: 51 as number,
        hypeLine: 665 as number,
        dataPoints: {} as any,
    },
    {
        number: 52 as number,
        hypeLine: 675 as number,
        dataPoints: {} as any,
    },
    {
        number: 53 as number,
        hypeLine: 685 as number,
        dataPoints: {} as any,
    },
    {
        number: 54 as number,
        hypeLine: 695 as number,
        dataPoints: {} as any,
    },
    {
        number: 55 as number,
        hypeLine: 705 as number,
        dataPoints: {} as any,
    },
    {
        number: 56 as number,
        hypeLine: 715 as number,
        dataPoints: {} as any,
    },
    {
        number: 57 as number,
        hypeLine: 725 as number,
        dataPoints: {} as any,
    },
    {
        number: 58 as number,
        hypeLine: 730 as number,
        dataPoints: {} as any,
    },
    {
        number: 59 as number,
        hypeLine: 735 as number,
        dataPoints: {} as any,
    },
    {
        number: 60 as number,
        hypeLine: 745 as number,
        dataPoints: {} as any,
    },
    {
        number: 61 as number,
        hypeLine: 750 as number,
        dataPoints: {} as any,
    },
    {
        number: 62 as number,
        hypeLine: 755 as number,
        dataPoints: {} as any,
    },
    {
        number: 63 as number,
        hypeLine: 760 as number,
        dataPoints: {} as any,
    },
    {
        number: 64 as number,
        hypeLine: 765 as number,
        dataPoints: {} as any,
    },
    {
        number: 65 as number,
        hypeLine: 770 as number,
        dataPoints: {} as any,
    },
    {
        number: 66 as number,
        hypeLine: 775 as number,
        dataPoints: {} as any,
    },
    {
        number: 67 as number,
        hypeLine: 780 as number,
        dataPoints: {} as any,
    },
    {
        number: 68 as number,
        hypeLine: 785 as number,
        dataPoints: {} as any,
    },
    {
        number: 69 as number,
        hypeLine: 790 as number,
        dataPoints: {} as any,
    },
    {
        number: 70 as number,
        hypeLine: 795 as number,
        dataPoints: {} as any,
    },
    {
        number: 71 as number,
        hypeLine: 800 as number,
        dataPoints: {} as any,
    },
    {
        number: 72 as number,
        hypeLine: 810 as number,
        dataPoints: {} as any,
    },
    {
        number: 73 as number,
        hypeLine: 815 as number,
        dataPoints: {} as any,
    },
    {
        number: 74 as number,
        hypeLine: 820 as number,
        dataPoints: {} as any,
    },
    {
        number: 75 as number,
        hypeLine: 825 as number,
        dataPoints: {} as any,
    },
    {
        number: 76 as number,
        hypeLine: 830 as number,
        dataPoints: {} as any,
    },
    {
        number: 77 as number,
        hypeLine: 835 as number,
        dataPoints: {} as any,
    },
    {
        number: 78 as number,
        hypeLine: 840 as number,
        dataPoints: {} as any,
    },
    {
        number: 79 as number,
        hypeLine: 845 as number,
        dataPoints: {} as any,
    },
    {
        number: 80 as number,
        hypeLine: 850 as number,
        dataPoints: {} as any,
    },
    {
        number: 81 as number,
        hypeLine: 852 as number,
        dataPoints: {} as any,
    },
    {
        number: 82 as number,
        hypeLine: 854 as number,
        dataPoints: {} as any,
    },
    {
        number: 83 as number,
        hypeLine: 856 as number,
        dataPoints: {} as any,
    },
    {
        number: 84 as number,
        hypeLine: 858 as number,
        dataPoints: {} as any,
    },
    {
        number: 85 as number,
        hypeLine: 860 as number,
        dataPoints: {} as any,
    },
    {
        number: 86 as number,
        hypeLine: 862 as number,
        dataPoints: {} as any,
    },
    {
        number: 87 as number,
        hypeLine: 864 as number,
        dataPoints: {} as any,
    },
    {
        number: 88 as number,
        hypeLine: 866 as number,
        dataPoints: {} as any,
    },
    {
        number: 89 as number,
        hypeLine: 868 as number,
        dataPoints: {} as any,
    },
    {
        number: 90 as number,
        hypeLine: 869 as number,
        dataPoints: {} as any,
    },
    {
        number: 91 as number,
        hypeLine: 870 as number,
        dataPoints: {} as any,
    },
    {
        number: 92 as number,
        hypeLine: 871 as number,
        dataPoints: {} as any,
    },
    {
        number: 93 as number,
        hypeLine: 872 as number,
        dataPoints: {} as any,
    },
    {
        number: 94 as number,
        hypeLine: 873 as number,
        dataPoints: {} as any,
    },
    {
        number: 95 as number,
        hypeLine: 874 as number,
        dataPoints: {} as any,
    },
    {
        number: 96 as number,
        hypeLine: 875 as number,
        dataPoints: {} as any,
    },
    {
        number: 97 as number,
        hypeLine: 875 as number,
        dataPoints: {} as any,
    },
    {
        number: 98 as number,
        hypeLine: 875 as number,
        dataPoints: {} as any,
    },
    {
        number: 99 as number,
        hypeLine: 875 as number,
        dataPoints: {} as any,
    },
    {
        number: 100 as number,
        hypeLine: 875 as number,
        dataPoints: {} as any,
    },
]
export let AdoptionData = [
    {
        number: 0,
        hypeLine: 0,
    },
    {
        number: 1,
        hypeLine: 0.0772283,
    },
    {
        number: 2,
        hypeLine: 0.165247308,
    },
    {
        number: 3,
        hypeLine: 0.265213171,
    },
    {
        number: 4,
        hypeLine: 0.378347491,
    },
    {
        number: 5,
        hypeLine: 0.505931445,
    },
    {
        number: 6,
        hypeLine: 0.649297997,
    },
    {
        number: 7,
        hypeLine: 0.809822075,
    },
    {
        number: 8,
        hypeLine: 0.988908589,
    },
    {
        number: 9,
        hypeLine: 1.187978219,
    },
    {
        number: 10,
        hypeLine: 1.40845092,
    },
    {
        number: 11,
        hypeLine: 1.651727168,
    },
    {
        number: 12,
        hypeLine: 1.919166999,
    },
    {
        number: 13,
        hypeLine: 2.212066952,
    },
    {
        number: 14,
        hypeLine: 2.531635113,
    },
    {
        number: 15,
        hypeLine: 2.878964496,
    },
    {
        number: 16,
        hypeLine: 3.255005091,
    },
    {
        number: 17,
        hypeLine: 3.660534949,
    },
    {
        number: 18,
        hypeLine: 4.096130777,
    },
    {
        number: 19,
        hypeLine: 4.562138545,
    },
    {
        number: 20,
        hypeLine: 5.058644684,
    },
    {
        number: 21,
        hypeLine: 5.585448498,
    },
    {
        number: 22,
        hypeLine: 6.142036441,
    },
    {
        number: 23,
        hypeLine: 6.727558955,
    },
    {
        number: 24,
        hypeLine: 7.340810538,
    },
    {
        number: 25,
        hypeLine: 7.98021375,
    },
    {
        number: 26,
        hypeLine: 8.643807788,
    },
    {
        number: 27,
        hypeLine: 9.32924226,
    },
    {
        number: 28,
        hypeLine: 10.03377668,
    },
    {
        number: 29,
        hypeLine: 10.75428617,
    },
    {
        number: 30,
        hypeLine: 11.4872737,
    },
    {
        number: 31,
        hypeLine: 12.2288891,
    },
    {
        number: 32,
        hypeLine: 12.974955,
    },
    {
        number: 33,
        hypeLine: 13.72099958,
    },
    {
        number: 34,
        hypeLine: 14.46229595,
    },
    {
        number: 35,
        hypeLine: 15.19390783,
    },
    {
        number: 36,
        hypeLine: 15.91074103,
    },
    {
        number: 37,
        hypeLine: 16.60759994,
    },
    {
        number: 38,
        hypeLine: 17.27924839,
    },
    {
        number: 39,
        hypeLine: 17.92047375,
    },
    {
        number: 40,
        hypeLine: 18.52615332,
    },
    {
        number: 41,
        hypeLine: 19.09132179,
    },
    {
        number: 42,
        hypeLine: 19.61123863,
    },
    {
        number: 43,
        hypeLine: 20.08145396,
    },
    {
        number: 44,
        hypeLine: 20.49787184,
    },
    {
        number: 45,
        hypeLine: 20.85680959,
    },
    {
        number: 46,
        hypeLine: 21.15505193,
    },
    {
        number: 47,
        hypeLine: 21.38989898,
    },
    {
        number: 48,
        hypeLine: 21.5592069,
    },
    {
        number: 49,
        hypeLine: 21.6614205,
    },
    {
        number: 50,
        hypeLine: 21.695597,
    },
    {
        number: 51,
        hypeLine: 21.6614205,
    },
    {
        number: 52,
        hypeLine: 21.5592069,
    },
    {
        number: 53,
        hypeLine: 21.38989898,
    },
    {
        number: 54,
        hypeLine: 21.15505193,
    },
    {
        number: 55,
        hypeLine: 20.85680959,
    },
    {
        number: 56,
        hypeLine: 20.49787184,
    },
    {
        number: 57,
        hypeLine: 20.08145396,
    },
    {
        number: 58,
        hypeLine: 19.61123863,
    },
    {
        number: 59,
        hypeLine: 19.09132179,
    },
    {
        number: 60,
        hypeLine: 18.52615332,
    },
    {
        number: 61,
        hypeLine: 17.92047375,
    },
    {
        number: 62,
        hypeLine: 17.27924839,
    },
    {
        number: 63,
        hypeLine: 16.60759994,
    },
    {
        number: 64,
        hypeLine: 15.91074103,
    },
    {
        number: 65,
        hypeLine: 15.19390783,
    },
    {
        number: 66,
        hypeLine: 14.46229595,
    },
    {
        number: 67,
        hypeLine: 13.72099958,
    },
    {
        number: 68,
        hypeLine: 12.974955,
    },
    {
        number: 69,
        hypeLine: 12.2288891,
    },
    {
        number: 70,
        hypeLine: 11.4872737,
    },
    {
        number: 71,
        hypeLine: 10.75428617,
    },
    {
        number: 72,
        hypeLine: 10.03377668,
    },
    {
        number: 73,
        hypeLine: 9.32924226,
    },
    {
        number: 74,
        hypeLine: 8.643807788,
    },
    {
        number: 75,
        hypeLine: 7.98021375,
    },
    {
        number: 76,
        hypeLine: 7.340810538,
    },
    {
        number: 77,
        hypeLine: 6.727558955,
    },
    {
        number: 78,
        hypeLine: 6.142036441,
    },
    {
        number: 79,
        hypeLine: 5.585448498,
    },
    {
        number: 80,
        hypeLine: 5.058644684,
    },
    {
        number: 81,
        hypeLine: 4.562138545,
    },
    {
        number: 82,
        hypeLine: 4.096130777,
    },
    {
        number: 83,
        hypeLine: 3.660534949,
    },
    {
        number: 84,
        hypeLine: 3.255005091,
    },
    {
        number: 85,
        hypeLine: 2.878964496,
    },
    {
        number: 86,
        hypeLine: 2.531635113,
    },
    {
        number: 87,
        hypeLine: 2.212066952,
    },
    {
        number: 88,
        hypeLine: 1.919166999,
    },
    {
        number: 89,
        hypeLine: 1.651727168,
    },
    {
        number: 90,
        hypeLine: 1.40845092,
    },
    {
        number: 91,
        hypeLine: 1.187978219,
    },
    {
        number: 92,
        hypeLine: 0.988908589,
    },
    {
        number: 93,
        hypeLine: 0.809822075,
    },
    {
        number: 94,
        hypeLine: 0.649297997,
    },
    {
        number: 95,
        hypeLine: 0.505931445,
    },
    {
        number: 96,
        hypeLine: 0.378347491,
    },
    {
        number: 97,
        hypeLine: 0.265213171,
    },
    {
        number: 98,
        hypeLine: 0.165247308,
    },
    {
        number: 99,
        hypeLine: 0.0772283,
    },
    {
        number: 100,
        hypeLine: 0,
    },
]
