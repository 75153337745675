import React, { useState, useEffect } from "react"
import { Box, TextField, Chip, Typography, Tooltip } from "@material-ui/core"

import { useQuery } from "@apollo/client"
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles"
import Autocomplete, {
    createFilterOptions,
} from "@material-ui/lab/Autocomplete"
import { BASE_CONCEPT_FILTERED } from "../../graphql/queries"
import {
    BaseConceptFilteredQuery,
    BaseConceptFilteredQueryVariables,
} from "../../graphql/__generated__/queries"
import { _ConceptOrdering } from "../../__generated__/types"
import { FilterProps } from "./util/types"
import { getNewState } from "./util/getNewState"
import HighlightOffIcon from "@material-ui/icons/HighlightOff"
import useCategoryTools from "../categories/useCategoryTools"
import ConceptAvatar from "../ConceptAvatar"

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        chipIcon: {
            color: theme.palette.text.primary,
            backgroundColor: theme.palette.primary.main,
        },
        avatar: {
            color: theme.palette.text.primary,
            backgroundColor: theme.palette.primary.main,
        },
        optionAvatar: {
            width: theme.spacing(3),
            height: theme.spacing(3),
            marginRight: theme.spacing(1),
        },
        listbox: {
            overflow: "auto",
        },
        popper: {
            zIndex: theme.zIndex.modal + 4,
        },
        input: {
            zIndex: theme.zIndex.modal + 3,
        },
    })
)

const filterOptions = createFilterOptions({
    limit: 15,
})
export default function ConceptFilter(props: FilterProps) {
    const classes = useStyles()
    const { getCategoryArray } = useCategoryTools()
    let queryVariables = {
        orderBy: ["title_asc"] as [_ConceptOrdering],
    }
    if (!!props.item.optionsFilter) {
        queryVariables["filter"] = { ...props.item.optionsFilter }
    }
    const { data, loading } = useQuery<
        BaseConceptFilteredQuery,
        BaseConceptFilteredQueryVariables
    >(BASE_CONCEPT_FILTERED, {
        variables: {
            ...queryVariables,
        },
    })
    const [value, setValue] = useState([])
    useEffect(() => {
        if (data && props.item) {
            setValue(
                data.Concept.filter(
                    (x) => props.item.value.indexOf(x.id) !== -1
                )
            )
        }
    }, [data, props.item])
    const valNames = value?.map((item) => item.title) ?? []

    return !!props.item?.disabled ? (
        <Chip
            label={
                <Box>
                    <Box component="span" fontWeight="bold" mr={0.5}>
                        {props.item?.field ?? props.item.type}s:
                    </Box>
                    {valNames.join("; ")}
                </Box>
            }
        />
    ) : (
        <Autocomplete
            options={data?.Concept ?? []}
            value={value}
            multiple
            size="small"
            filterOptions={filterOptions}
            classes={{
                popper: classes.popper,
                listbox: classes.listbox,
                inputRoot: classes.input,
            }}
            style={{
                pointerEvents: !!props.item?.disabled ? "none" : "auto",
            }}
            freeSolo={!!props.item?.disabled} // to hide end adornment
            getOptionSelected={(option, value) => option.id === value.id}
            onChange={(_: React.ChangeEvent<{}>, newValue) => {
                if (typeof newValue !== "string") {
                    setValue(newValue)
                    const newState = getNewState(
                        props.item,
                        newValue,
                        props.filters,
                        "id"
                    )

                    if (newState) {
                        props.setFilters(newState)
                    }
                }
            }}
            loadingText="Loading concepts..."
            noOptionsText="No concepts that match..."
            loading={loading}
            getOptionLabel={(option) => option.title}
            renderOption={(option) => {
                return (
                    <Box alignItems="center" display="flex" key={option.id}>
                        <ConceptAvatar
                            concept={option}
                            variant="circle"
                            size="small"
                        />

                        <Box ml={1}>
                            <Typography variant="body1">
                                {option.title}
                            </Typography>
                        </Box>
                    </Box>
                )
            }}
            renderTags={(value, getTagProps) =>
                value.map((option, index) => {
                    return (
                        <Tooltip title={option.title} key={option.id}>
                            {!!props.item.disabled ? (
                                <Chip
                                    {...getTagProps({ index })}
                                    key={option.id}
                                    avatar={
                                        <ConceptAvatar
                                            concept={option}
                                            variant="circle"
                                            size="small"
                                        />
                                    }
                                    label={
                                        <Typography
                                            variant="body2"
                                            style={{
                                                whiteSpace: "nowrap",
                                                textOverflow: "ellipsis",
                                                overflow: "hidden",
                                            }}
                                        >
                                            {option.title}
                                        </Typography>
                                    }
                                    style={{ height: "100%" }}
                                />
                            ) : (
                                <Chip
                                    {...getTagProps({ index })}
                                    key={option.id}
                                    deleteIcon={<HighlightOffIcon />}
                                    avatar={
                                        <ConceptAvatar
                                            concept={option}
                                            variant="circle"
                                            size="small"
                                        />
                                    }
                                    label={
                                        <Typography
                                            variant="body2"
                                            style={{
                                                whiteSpace: "nowrap",
                                                textOverflow: "ellipsis",
                                                overflow: "hidden",
                                            }}
                                        >
                                            {option.title}
                                        </Typography>
                                    }
                                    style={{ height: "100%" }}
                                />
                            )}
                        </Tooltip>
                    )
                })
            }
            renderInput={(params) => (
                <TextField
                    {...params}
                    label={`${props.item.field ?? props.item.type}s`}
                    variant="outlined"
                />
            )}
        />
    )
}
