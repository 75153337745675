import { Box, ClickAwayListener } from "@material-ui/core"
import { useState } from "react"
import TagList from "./TagList"
import TagInput from "./TagInput"
import { Concept } from "../../__generated__/types"
import { makeStyles } from "@material-ui/core/styles"
import useAwaitTranslation from "../../i18n/useAwaitTranslation"
const useStyles = makeStyles((theme) => ({
    clickable: {
        "&:hover": {
            backgroundColor: theme.palette.action.hover,
            borderRadius: "4px",
        },
        cursor: "pointer",
        height: "100%",
        padding: theme.spacing(1),
        width: "100%",
    },
    locked: {
        height: "100%",
        padding: theme.spacing(1),
    },
    label: {
        fontSize: 12,
        color: theme.palette.text.secondary,
        paddingBottom: theme.spacing(0.5),
    },
}))
interface TagToggleProps {
    concept: Concept
    editable: boolean
}
export default function TagToggle(props: TagToggleProps) {
    const classes = useStyles()
    const [editing, setEditing] = useState(false)
    const { t } = useAwaitTranslation("concepts")
    return (
        <ClickAwayListener onClickAway={() => setEditing(false)}>
            <Box
                onClick={() => {
                    if (props.editable) {
                        setEditing(true)
                    }
                }}
                width="100%"
            >
                {editing ? (
                    <Box p={1}>
                        <TagInput
                            concept={props.concept}
                            clickable={true}
                            autoFocus={true}
                        />
                    </Box>
                ) : (
                    <Box
                        className={
                            props.editable ? classes.clickable : classes.locked
                        }
                    >
                        <Box className={classes.label}>{t("tags", "Tags")}</Box>
                        {props.concept.tags.length > 0 && (
                            <TagList concept={props.concept} chip={true} />
                        )}
                    </Box>
                )}
            </Box>
        </ClickAwayListener>
    )
}
