import { useQuery, useReactiveVar } from "@apollo/client"

import React, { useCallback, useMemo, useReducer, useRef } from "react"
import { FULL_CONCEPT_FILTERED } from "../graphql/queries"

import {
    FullConceptFilteredQuery,
    FullConceptFilteredQueryVariables,
} from "../graphql/__generated__/queries"
import { feedVariablesVar } from "../providers/GlobalState"
import { Concept } from "../__generated__/types"
import GraphView from "./GraphView"
import LoadingOverlayWrapper from "./LoadingOverlayWrapper"

function ExpandedConceptsReducer(
    state: { id: string; categoryId?: string }[],
    action: {
        id: string
    }
) {
    let newState = [...(state ?? [])]

    if (newState.find((item) => item.id === action.id)) {
        return newState.filter((item) => item.id !== action.id)
    } else {
        return [...newState, { id: action.id || null }]
    }
}

const ConceptFeedGraphView = (props: { feedData: Concept[] }) => {
    const { feedData } = props
    const dataRef = useRef(null)
    const currentFeedVariables = useReactiveVar(feedVariablesVar)
    const [state, dispatch] = useReducer(ExpandedConceptsReducer, [])

    const { data, loading } = useQuery<
        FullConceptFilteredQuery,
        FullConceptFilteredQueryVariables
    >(FULL_CONCEPT_FILTERED, {
        variables: {
            filter: {
                connections_some: {
                    id_in: state.map((item) => item.id),
                },
            },
        },
        skip: state.length === 0,
        fetchPolicy: "cache-and-network",
    })
    const connections = data?.Concept ?? null

    //all commented out needs to be un-commeneted - and add connections back to FULL_CONCEPT_FILTERED
    const formattedData = useMemo(() => {
        const nodes = []
        const links = []
        if (!feedData) {
            return { nodes, links }
        }
        feedData.forEach((concept) => {
            nodes.push({
                id: concept.id,
                title: concept.title + ` (${concept.category?.name})`,
                category: concept.category?.name,
                imageUrl: concept.imageUrl,
                __typename: concept.__typename,
            })
            if (!!concept?.category) {
                nodes.push({
                    id: concept.category.id,
                    title: concept.category.name,
                    category: concept.category.name,
                    __typename: concept.category.__typename,
                })
                links.push({
                    source: concept.id,
                    target: concept.category.id,
                })
            }
            // if (state.find((item) => item.id === concept.id)) {
            //     connections
            //         ?.filter(
            //             (c) => !!c.connections.find((o) => o.id === concept.id)
            //         )
            //         ?.forEach((connection) => {
            //             links.push({
            //                 source: concept.id,
            //                 target: connection.id,
            //             })
            //         })
            // }
        })
        connections?.forEach((connection) => {
            nodes.push({
                id: connection.id,
                title: connection.title + ` (${connection.category?.name})`,
                category: connection.category?.name,
                imageUrl: connection.imageUrl,
                __typename: connection.__typename,
            })
            if (!!connection?.category) {
                nodes.push({
                    id: connection.category.id,
                    title: connection.category.name,
                    category: connection.category.name,
                    __typename: connection.category.__typename,
                })
                links.push({
                    source: connection.id,
                    target: connection.category.id,
                })
            }

            // connection.connections.forEach((c) => {
            //     if (!!nodes.find((item) => item.id === c.id)) {
            //         links.push({
            //             source: connection.id,
            //             target: c.id,
            //         })
            //     }
            // })
        })

        const nodeIds = []
        return {
            nodes: nodes.filter((n) =>
                nodeIds.indexOf(n.id) === -1 ? nodeIds.push(n.id) : false
            ),
            links,
        }
    }, [connections, state, feedData])
    const onNodeClick = useCallback((node) => {
        dispatch({ id: node.id })
    }, [])
    if (!!feedData && !loading) {
        dataRef.current = formattedData
    }

    return (
        <LoadingOverlayWrapper loading={!!loading}>
            <GraphView
                viewMode={currentFeedVariables.displayType}
                formattedData={dataRef.current}
                onNodeClick={onNodeClick}
            />
        </LoadingOverlayWrapper>
    )
}

export default ConceptFeedGraphView
