import { useApolloClient, useMutation, gql } from "@apollo/client"
import {
    ADD_CONCEPT_STATUS,
    CREATE_CONCEPT_STATUS,
    UPDATE_CONCEPT_STATUS,
    ADD_STATUS_STAGE,
    CONCEPT_STATUS_HISTORY,
} from "../components/workflows/graphql"
import {
    AddConceptStatusMutation,
    AddConceptStatusMutationVariables,
    CreateConceptStatusMutation,
    CreateConceptStatusMutationVariables,
    UpdateConceptStatusMutation,
    UpdateConceptStatusMutationVariables,
    AddWorkflowStageStatusMutation,
    AddWorkflowStageStatusMutationVariables,
} from "../components/workflows/__generated__/graphql"
import {
    ADD_CONCEPT_WEB_LINK,
    CONNECT_CONCEPTS,
    CREATE_CONCEPT,
    DELETE_CONCEPT,
    DISCONNECT_CONCEPTS,
    UPDATE_CONCEPT,
} from "../graphql/mutations"
import { GRANT_USER_ACCESS } from "../components/permissions/graphql"
import {
    GrantUserAccessMutation,
    GrantUserAccessMutationVariables,
} from "../components/permissions/__generated__/graphql"
import {
    CreateConceptCompleteMutation,
    CreateConceptCompleteMutationVariables,
    AddConceptWebLinkMutation,
    AddConceptWebLinkMutationVariables,
    UpdateConceptMutation,
    UpdateConceptMutationVariables,
    AddConceptConnectionsMutation,
    AddConceptConnectionsMutationVariables,
    RemoveConceptConnectionsMutation,
    RemoveConceptConnectionsMutationVariables,
    DeleteConceptMutation,
    DeleteConceptMutationVariables,
} from "../graphql/__generated__/mutations"

import {
    ADD_CONCEPT_CATEGORY,
    REMOVE_CONCEPT_CATEGORY,
} from "../components/categories/graphql"
import {
    AddConceptChildMutation,
    AddConceptChildMutationVariables,
    RemoveConceptChildMutation,
    RemoveConceptChildMutationVariables,
} from "../components/workspace/__generated__/graphql"
import {
    ADD_CONCEPT_CHILD,
    REMOVE_CONCEPT_CHILD,
} from "../components/workspace/graphql"
import {
    AddConceptCategoryMutation,
    AddConceptCategoryMutationVariables,
    RemoveConceptCategoryMutation,
    RemoveConceptCategoryMutationVariables,
} from "../components/categories/__generated__/graphql"

import { useAuth } from "../providers/AuthProvider"

import {
    AccessType,
    Category,
    Concept,
    ConceptInput,
    ConceptUpdateInput,
    Link,
    WorkflowStage,
    _ConceptStatusOrdering,
} from "../__generated__/types"
import { ConceptFieldSettings } from "./SystemSettings"
import { CONCEPT_STATUS_PAGE_SIZE } from "../components/workflows/ConceptStatusHistory"
import { useCallback } from "react"
import { CONCEPT_BY_ID } from "../graphql/queries"
import useEnvironmentSettingTools from "../components/settings/useEnvironmentSettingTools"
export default function useConceptTools(item?: Concept) {
    const { currentUser } = useAuth()
    const client = useApolloClient()
    const { ableToPublish } = useEnvironmentSettingTools()
    const [addCategory] = useMutation<
        AddConceptCategoryMutation,
        AddConceptCategoryMutationVariables
    >(ADD_CONCEPT_CATEGORY)
    const [removeCategory] = useMutation<
        RemoveConceptCategoryMutation,
        RemoveConceptCategoryMutationVariables
    >(REMOVE_CONCEPT_CATEGORY)
    const [grantUserAccess] = useMutation<
        GrantUserAccessMutation,
        GrantUserAccessMutationVariables
    >(GRANT_USER_ACCESS, {
        refetchQueries: ["ConceptFeed", "UserConceptDailyProgress"],
    })
    const [deleteConcept, { loading: deleting }] = useMutation<
        DeleteConceptMutation,
        DeleteConceptMutationVariables
    >(DELETE_CONCEPT, {
        update(cache, { data: { DeleteConcept: deletedConcept } }) {
            cache.evict({
                id: cache.identify(deletedConcept),
            })
        },
        refetchQueries: ["UserConceptDailyProgress"],
    })
    const [createConcept] = useMutation<
        CreateConceptCompleteMutation,
        CreateConceptCompleteMutationVariables
    >(CREATE_CONCEPT)
    const [createConceptStatus] = useMutation<
        CreateConceptStatusMutation,
        CreateConceptStatusMutationVariables
    >(CREATE_CONCEPT_STATUS)
    const [addConceptStatus] = useMutation<
        AddConceptStatusMutation,
        AddConceptStatusMutationVariables
    >(ADD_CONCEPT_STATUS)
    const [updateConceptStatus] = useMutation<
        UpdateConceptStatusMutation,
        UpdateConceptStatusMutationVariables
    >(UPDATE_CONCEPT_STATUS)
    const [updateConcept] = useMutation<
        UpdateConceptMutation,
        UpdateConceptMutationVariables
    >(UPDATE_CONCEPT)
    const [disconnectConcepts, { loading: disconnecting }] = useMutation<
        RemoveConceptConnectionsMutation,
        RemoveConceptConnectionsMutationVariables
    >(DISCONNECT_CONCEPTS)
    const [connectConcept, { loading: connecting }] = useMutation<
        AddConceptConnectionsMutation,
        AddConceptConnectionsMutationVariables
    >(CONNECT_CONCEPTS)
    const [addStatusStage] = useMutation<
        AddWorkflowStageStatusMutation,
        AddWorkflowStageStatusMutationVariables
    >(ADD_STATUS_STAGE)
    const [addConceptParent] = useMutation<
        AddConceptChildMutation,
        AddConceptChildMutationVariables
    >(ADD_CONCEPT_CHILD)
    const [removeConceptParent] = useMutation<
        RemoveConceptChildMutation,
        RemoveConceptChildMutationVariables
    >(REMOVE_CONCEPT_CHILD)
    const [addWebLink] = useMutation<
        AddConceptWebLinkMutation,
        AddConceptWebLinkMutationVariables
    >(ADD_CONCEPT_WEB_LINK)

    const onCreateNewConceptStatus = useCallback(
        async (props: {
            statusStage: WorkflowStage
            concept: Concept
            justificationReason?: string
            justificationNotes?: string
            refetch?: boolean
        }) => {
            let promises = [
                createConceptStatus({
                    variables: {
                        isActive: true,
                        justification: props.justificationReason ?? null,
                        justificationNotes: props.justificationNotes ?? null,
                        createdAt: {
                            year: new Date().getUTCFullYear(),
                            month: new Date().getUTCMonth() + 1,
                            day: new Date().getUTCDate(),
                            hour: new Date().getUTCHours(),
                            minute: new Date().getUTCMinutes(),
                            second: new Date().getUTCSeconds(),
                        },
                    },
                }),
            ]
            const currentActiveStatuses =
                props.concept.status?.filter((status) => !!status.isActive) ??
                []
            if (currentActiveStatuses.length > 0) {
                promises = [
                    ...promises,
                    ...currentActiveStatuses.map((status) => {
                        return updateConceptStatus({
                            variables: {
                                id: status.id,
                                isActive: false,
                            },
                        })
                    }),
                ]
            }
            const promiseData = await Promise.all(promises)

            const newConceptStatus = promiseData?.[0]?.data?.CreateConceptStatus
            if (newConceptStatus) {
                await Promise.all([
                    addConceptStatus({
                        variables: {
                            conceptId: props.concept.id,
                            conceptStatusId: newConceptStatus.id,
                        },
                    }),
                    addStatusStage({
                        variables: {
                            statusId: newConceptStatus.id,
                            stageId: props.statusStage.id,
                        },
                    }),
                ])
            }

            if (!!item?.id && !!props.refetch) {
                await client.refetchQueries({
                    include: [
                        {
                            // @ts-ignore
                            query: CONCEPT_STATUS_HISTORY,
                            variables: {
                                filter: {
                                    concept: {
                                        id: item.id,
                                    },
                                },
                                orderBy: _ConceptStatusOrdering.CREATEDAT_DESC,
                                first: CONCEPT_STATUS_PAGE_SIZE,
                                offset: 0,
                            },
                        },
                    ],
                })
            }
            return
        },
        [
            addConceptStatus,
            client,
            createConceptStatus,
            updateConceptStatus,
            item?.id,
            addStatusStage,
        ]
    )

    const getConceptFromLinkItem = (linkItem) => {
        if (linkItem) {
            let summaryLength = ConceptFieldSettings.Summary.maxLength

            return {
                title: linkItem.title,
                summary: linkItem.description?.substring(0, summaryLength),
                description:
                    linkItem.description?.length > summaryLength
                        ? JSON.stringify([
                              {
                                  type: "paragraph",
                                  children: [{ text: linkItem.description }],
                              },
                          ])
                        : null,
                imageUrl: linkItem.image,
            }
        } else {
            return null
        }
    }

    const onConceptCreation = useCallback(
        async (props: {
            data
            category: Category
            tags: string[]
            parentId?: string
            connectedId?: string
            linkItem?: Link
            openSnackbar?: (newConcept: Concept, categoryId: string) => void
        }) => {
            const {
                data,
                category,
                linkItem,
                tags,
                parentId,
                connectedId,
                openSnackbar,
            } = props
            const conceptInput: ConceptInput = {
                ...getConceptFromLinkItem(linkItem),
                title:
                    data.title ?? getConceptFromLinkItem(linkItem)?.title ?? "",
                summary:
                    data.summary ??
                    getConceptFromLinkItem(linkItem)?.summary ??
                    "",
                type: category?.isRoot
                    ? category.name
                    : category.parent.name || "Idea",
                isPublic: !!ableToPublish && !!data.isPublic,
                isPublicAccessType: data.isPublic
                    ? AccessType.VIEWER
                    : AccessType.NONE,
                description:
                    data.description ??
                    getConceptFromLinkItem(linkItem)?.description ??
                    null,
            }
            const {
                data: { CreateConcept: NewConcept },
            } = await createConcept({
                variables: {
                    userId: currentUser?.userId,
                    concept: conceptInput,
                    tags: tags,
                },
            })
            if (!!NewConcept) {
                if (category) {
                    addCategory({
                        variables: {
                            id: NewConcept?.id,
                            categoryId: category?.id,
                        },
                    })
                }
                if (!!parentId) {
                    addConceptParent({
                        variables: {
                            parentId,
                            childId: NewConcept?.id,
                        },
                    })
                }

                if (linkItem) {
                    addWebLink({
                        variables: {
                            conceptId: NewConcept?.id,
                            linkId: linkItem?.id,
                        },
                    })
                }
                if (!!connectedId) {
                    connectConcept({
                        variables: {
                            fromId: connectedId,
                            toId: NewConcept.id,
                        },
                    })
                }
                grantUserAccess({
                    // so permissions are ready if the snackbar is clicked
                    variables: {
                        conceptId: NewConcept.id,
                        userId: currentUser?.userId,
                        accessType: AccessType.OWNER,
                    },
                }).then(() => {
                    if (!!openSnackbar) {
                        openSnackbar(NewConcept, category?.id)
                    }
                })

                return NewConcept
            }
            return
        },
        [
            createConcept,
            grantUserAccess,
            addWebLink,
            addConceptParent,
            addCategory,
            currentUser?.userId,
            ableToPublish,
            connectConcept,
        ]
    )

    const onUpdateConcept = useCallback(
        async (
            updateData: { userId: string; concept: ConceptUpdateInput },
            refetch?: boolean
        ) => {
            await updateConcept({
                variables: {
                    ...updateData,
                },
            })
            if (!!refetch) {
                client.refetchQueries({
                    include: [
                        {
                            // @ts-ignore
                            query: CONCEPT_BY_ID,
                            variables: {
                                id: updateData.concept.id,
                            },
                        },
                    ],
                })
            }
        },
        [updateConcept, client]
    )
    const onChangeConceptCategory = useCallback(
        async (props: { concept: Concept; category: Category }) => {
            const { concept, category } = props
            if (category.id !== concept?.category?.id) {
                if (!!concept?.category?.id) {
                    await removeCategory({
                        variables: {
                            id: concept.id,
                            categoryId: concept.category?.id,
                        },
                    })
                }

                onUpdateConcept({
                    userId: currentUser?.userId,
                    concept: {
                        id: concept.id,
                        type: category.isRoot
                            ? category.name
                            : category.parent?.name,
                    },
                })

                await addCategory({
                    variables: {
                        id: concept.id,
                        categoryId: category.id,
                    },
                })
            }
        },
        [addCategory, onUpdateConcept, removeCategory, currentUser?.userId]
    )

    const onConceptConnection = useCallback(
        async (fromId: string, toId: string, cacheToUpdate: "from" | "to") => {
            const cacheIdToUpdate = cacheToUpdate === "from" ? fromId : toId
            const cacheIdToUpdateWith = cacheToUpdate === "from" ? toId : fromId
            client.cache.modify({
                id: client.cache.identify({
                    id: cacheIdToUpdate,
                    __typename: "Concept",
                }),
                fields: {
                    connections(existingConnections = [], { readField }) {
                        const newConnectionRef = client.cache.writeFragment({
                            data: {
                                id: cacheIdToUpdateWith,
                                __typename: "Concept",
                            },
                            fragment: gql`
                                fragment NewConnection on Concept {
                                    id
                                }
                            `,
                        })
                        if (
                            existingConnections.some(
                                (ref) =>
                                    readField("id", ref) === cacheIdToUpdateWith
                            )
                        ) {
                            return existingConnections
                        }
                        return [...existingConnections, newConnectionRef]
                    },
                },
            })

            await connectConcept({
                variables: {
                    fromId,
                    toId,
                },
            })
        },
        [connectConcept, client.cache]
    )
    const onConceptDisconnection = useCallback(
        async (
            fromId: string,
            toId: string,
            directionToRemoveFrom: "from" | "to"
        ) => {
            const conceptIdToRemove =
                directionToRemoveFrom === "from" ? fromId : toId
            const conceptIdToRemoveFrom =
                directionToRemoveFrom === "from" ? toId : fromId
            client.cache.modify({
                id: client.cache.identify({
                    id: conceptIdToRemoveFrom,
                    __typename: "Concept",
                }),
                fields: {
                    connections(existingConnections = [], { readField }) {
                        return [
                            ...existingConnections.filter(
                                (ref) =>
                                    readField("id", ref) !== conceptIdToRemove
                            ),
                        ]
                    },
                },
            })

            await disconnectConcepts({
                variables: {
                    fromId,
                    toId,
                },
            })

            return
        },
        [disconnectConcepts, client.cache]
    )

    return {
        onConceptCreation,
        getConceptFromLinkItem,
        onCreateNewConceptStatus,
        onChangeConceptCategory,
        onConceptConnection,
        onConceptDisconnection,
        addConceptParent,
        removeConceptParent,
        connecting,
        disconnecting,
        onUpdateConcept,
        deleteConcept,
        deleting,
    }
}
