import React, { useEffect, useState } from "react"
import { makeStyles } from "@material-ui/core"
import Avatar from "@material-ui/core/Avatar"
import Button from "@material-ui/core/Button"
import CircularProgress from "@material-ui/core/CircularProgress"
import Container from "@material-ui/core/Container"
import CssBaseline from "@material-ui/core/CssBaseline"
import Dialog from "@material-ui/core/Dialog"
import TextField from "@material-ui/core/TextField"
import Typography from "@material-ui/core/Typography"
import LockOutlinedIcon from "@material-ui/icons/LockOutlined"

import { useHistory } from "react-router-dom"
import useAwaitTranslation from "../../i18n/useAwaitTranslation"
import { useAuth } from "../../providers/AuthProvider"
import { Loading } from "../Loading"
import ForgotPassword from "./ForgotPassword"

const useStyles = makeStyles((theme) => ({
    page: {
        width: "100vw",
        height: "100vh",
    },
    paper: {
        marginTop: theme.spacing(8),
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.primary.main,
    },
    form: {
        width: "100%",
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    customError: {
        color: "red",
        fontSize: "0.8rem",
        marginTop: 10,
    },
    progress: {
        position: "absolute",
    },
}))
const defaultErrorObj = {
    password: "",
    verifyPassword: "",
    general: "",
    pwReset: "",
}

interface ResetPasswordProps {
    actionCode: string | any
    continueUrl: string | any
}
export default function ResetPassword(props: ResetPasswordProps) {
    const { actionCode, continueUrl } = props
    const classes = useStyles()
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const [verifyPassword, setVerifyPassword] = useState("")
    const [errors, setErrors] = useState(defaultErrorObj)
    const [pwResetError, setPwResetError] = useState("")
    const [loading, setLoading] = useState(false)
    const history = useHistory()
    const { t } = useAwaitTranslation("login")
    const { auth } = useAuth()
    const handleSubmit = async (e) => {
        e.preventDefault()
        setErrors(defaultErrorObj)
        setLoading(true)
        auth.confirmPasswordReset(actionCode, password)
            .then(() => {
                setLoading(false)
                const goToPage = continueUrl || ""
                history.push(goToPage)
            })
            .catch((e) => {
                setPwResetError(e.message)
                setLoading(false)
            })
    }
    const pwMatch = !!password && password !== verifyPassword

    useEffect(() => {
        auth.verifyPasswordResetCode(actionCode)
            .then((email) => {
                setEmail(email)
            })
            .catch((e) => {
                // Invalid or expired action code. Ask user to try to reset the password
                // again.
                setPwResetError(e.message)
            })
    }, [actionCode, setPwResetError, auth])
    if (!!actionCode && !email && !pwResetError) {
        auth.verifyPasswordResetCode(actionCode)
            .then((email) => {
                setEmail(email)
            })
            .catch((e) => {
                // Invalid or expired action code. Ask user to try to reset the password
                // again.
                const message = { pwReset: e.message }
                setErrors({ ...errors, ...message })
            })
    }
    if (!actionCode || !!pwResetError) {
        return <ForgotPassword error={errors.pwReset} />
    } else if (!email) {
        return (
            <Dialog fullScreen open={true} style={{ zIndex: 99999 }}>
                <Loading />
            </Dialog>
        )
    } else {
        return (
            <Dialog fullScreen open={true} style={{ zIndex: 99999 }}>
                <Container
                    component="main"
                    maxWidth="xs"
                    className={classes.page}
                >
                    <CssBaseline />
                    <div className={classes.paper}>
                        <Avatar className={classes.avatar}>
                            <LockOutlinedIcon />
                        </Avatar>
                        <Typography component="h1" variant="h5">
                            {t("login", "Login")}
                        </Typography>
                        <form className={classes.form} noValidate>
                            <TextField
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                name="password"
                                label={t("password", "Password")}
                                type="password"
                                id="password"
                                autoComplete="current-password"
                                helperText={errors.password}
                                error={errors.password ? true : false}
                                disabled={loading}
                                onChange={(e) => setPassword(e.target.value)}
                            />
                            <TextField
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                name="password"
                                label={t("retypePassword", "Retype Password")}
                                type="password"
                                id="verify-password"
                                // autoComplete="current-password"
                                helperText={"Passwords must match"}
                                error={pwMatch}
                                disabled={loading}
                                onChange={(e) =>
                                    setVerifyPassword(e.target.value)
                                }
                            />
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                color="primary"
                                className={classes.submit}
                                onClick={handleSubmit}
                                disabled={loading || !password || pwMatch}
                            >
                                {t("submit", "Submit")}
                                {loading && (
                                    <CircularProgress
                                        disableShrink
                                        size={30}
                                        className={classes.progress}
                                    />
                                )}
                            </Button>
                            {errors.general && (
                                <Typography
                                    variant="body2"
                                    className={classes.customError}
                                >
                                    {errors.general}
                                </Typography>
                            )}
                        </form>
                    </div>
                </Container>
            </Dialog>
        )
    }
}
