import React, { useState, useCallback, useEffect } from "react"
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles"
import List from "@material-ui/core/List"
import { Box } from "@material-ui/core"
import { NetworkStatus, useQuery } from "@apollo/client"
import ClickAwayListener from "@material-ui/core/ClickAwayListener"
import InfiniteScroll from "react-infinite-scroller"
import CircularProgress from "@material-ui/core/CircularProgress"
import { CONNECTION_WIDGET_QUERY } from "../graphql/queries"
import {
    ConnectionWidgetQueryQuery,
    ConnectionWidgetQueryQueryVariables,
} from "../graphql/__generated__/queries"
import { Loading } from "./Loading"
import { _ConceptFilter, _ConceptOrdering } from "../__generated__/types"
import ConceptListItem from "./ConceptListItem"
import ErrorHandler from "./ErrorHandler"
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: "100%",
            backgroundColor: theme.palette.background.paper,
            overflow: "auto",
        },
        inline: {
            display: "inline",
        },
        container: {
            display: "flex",
            flexDirection: "column",
            width: "100%",
            height: "100%",
            overflow: "hidden",
        },
        searchContainer: {
            marginTop: theme.spacing(1),
            fontSize: "12px",
        },
        linkCount: {
            display: "flex",
            alignItems: "center",
            color: theme.palette.text.secondary,
        },
        toolIcon: {
            height: "0.8em",
            width: "0.8em",
        },
        loaderContainer: {
            textAlign: "center",
        },
        greyed: {
            fontSize: 12,
            color: theme.palette.text.hint,
        },
    })
)
interface ConceptInfiniteScrollProps {
    filter: _ConceptFilter
    orderBy: _ConceptOrdering
}

const PAGE_SIZE = 10
export default function ConceptInfiniteScroll(
    props: ConceptInfiniteScrollProps
) {
    const classes = useStyles()
    const [search, showSearch] = useState(false)
    const [edit, setEdit] = useState(null)
    const [loadMore, setLoadMore] = useState(true)
    const [limit, setLimit] = useState(PAGE_SIZE)
    const [filter, setFilter] = useState<_ConceptFilter>({})
    const { error, data, fetchMore, networkStatus } = useQuery<
        ConnectionWidgetQueryQuery,
        ConnectionWidgetQueryQueryVariables
    >(CONNECTION_WIDGET_QUERY, {
        variables: {
            offset: 0,
            first: limit,
            connectionsFilter: filter,
            orderBy: [props.orderBy],
        },
        fetchPolicy: "cache-and-network",
        nextFetchPolicy: "cache-first",
    })
    const listData = data?.Concept ?? null
    useEffect(() => {
        if (JSON.stringify(props.filter) !== JSON.stringify(filter)) {
            setFilter(props.filter)
            setLimit(PAGE_SIZE)
            setLoadMore(true)
        }
    }, [props.filter, filter])
    useEffect(() => {
        if (!!data && listData?.length === 0) {
            setLoadMore(false)
        }
    }, [listData?.length, data])
    const handleClickAway = () => {
        if (search) {
            showSearch(false)
        }
        if (edit) {
            setEdit(false)
        }
    }

    const onLoadMore = useCallback(
        async (page) => {
            if (!fetchMore) return
            const result = await fetchMore({
                variables: {
                    offset: page * PAGE_SIZE,
                },
            })
            setLimit(page * PAGE_SIZE + PAGE_SIZE)
            setLoadMore(!(result.data.Concept.length < page * PAGE_SIZE))
        },
        [fetchMore]
    )
    if (error) {
        return <ErrorHandler message={error.message} showMessage={false} />
    }

    return (
        <ClickAwayListener onClickAway={handleClickAway}>
            <div className={classes.container}>
                {networkStatus === NetworkStatus.loading ? (
                    <Loading size={25} hideQuote={true} />
                ) : !!listData ? (
                    <List className={classes.root} disablePadding>
                        <InfiniteScroll
                            pageStart={0}
                            loadMore={onLoadMore}
                            hasMore={loadMore}
                            loader={
                                <div
                                    key={0}
                                    className={classes.loaderContainer}
                                    style={{ padding: 4 }}
                                >
                                    <CircularProgress disableShrink size={25} />
                                </div>
                            }
                            useWindow={false}
                        >
                            {listData.map((item, idx) => {
                                return (
                                    <Box key={item.id}>
                                        <ConceptListItem
                                            item={item}
                                            showSummary={true}
                                            hideDivider={true}
                                        />
                                    </Box>
                                )
                            })}
                        </InfiniteScroll>
                    </List>
                ) : null}
            </div>
        </ClickAwayListener>
    )
}
