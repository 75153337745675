import {
    Box,
    FormControl,
    Select,
    InputLabel,
    MenuItem,
    Typography,
    lighten,
    IconButton,
} from "@material-ui/core"
import { useState } from "react"
import { useParams } from "react-router-dom"
import ToggleButton from "@material-ui/lab/ToggleButton"
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup"
import { toCapitalizedWords } from "../../util/fns"
import {
    CollectionType,
    DefaultInputSource,
    InputSource,
    InputSourceValue,
} from "../criteria/useCriteriaTypes"
import { useReactiveVar } from "@apollo/client"
import CheckIcon from "@material-ui/icons/Check"
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles"
import {
    FeedVariables,
    myCurrentInputResponseLabelVar,
} from "../../providers/GlobalState"
import {
    _ConceptFilter,
    Concept,
    _ConceptOrdering,
    AccessType,
} from "../../__generated__/types"
import { useAuth } from "../../providers/AuthProvider"
import SelectableList from "../SelectableList"
import Filters from "../filters/Filters"
import ClickableRichTooltip from "../Popper/ClickableRichTooltip"
import { ReactComponent as GlobalFilterIcon } from "../../styles/FilterIcon.svg"
import useAwaitTranslation from "../../i18n/useAwaitTranslation"
import { getSourceFilters } from "./util"
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            border: `1px solid ${theme.palette.divider}`,
            borderRadius: theme.shape.borderRadius,
        },
        container: {
            maxHeight: 300,
            minHeight: 200,
            borderTop: `1px solid ${theme.palette.divider}`,
        },
        toolbarRoot: {
            padding: theme.spacing(0, 2),

            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
        },
        highlight:
            theme.palette.type === "light"
                ? {
                      color: theme.palette.secondary.main,
                      backgroundColor: lighten(
                          theme.palette.secondary.light,
                          0.85
                      ),
                  }
                : {
                      color: theme.palette.text.primary,
                      backgroundColor: theme.palette.secondary.dark,
                  },
        title: {
            flexGrow: 1,
        },
        filterContainer: {
            border: `1px solid ${theme.palette.divider}`,
            borderRadius: theme.shape.borderRadius,
        },
        previewContainer: {
            height: 400,
            overflow: "hidden",
            display: "flex",
            width: "100%",
            border: `1px solid ${theme.palette.divider}`,
            borderRadius: theme.shape.borderRadius,
            position: "relative",
        },
    })
)

interface SourceOptionsProps {
    currentConfig: CollectionType
    onChange: (value: CollectionType) => void
}
const SourceOptions = (props: SourceOptionsProps) => {
    const { currentConfig, onChange } = props
    const { t } = useAwaitTranslation("criteria")
    const currentSource =
        typeof currentConfig?.source !== "string"
            ? {
                  ...DefaultInputSource,
                  ...currentConfig?.source,
              }
            : DefaultInputSource

    return (
        <Box>
            <Box ml={2} mt={1}>
                <Typography variant="subtitle1" color="textSecondary">
                    {t("collectionSource", "Collection Source")}
                </Typography>
            </Box>
            <Box p={2}>
                <FormControl fullWidth variant="outlined" size="small">
                    <InputLabel>{t("sourceType", "Source Type")}</InputLabel>
                    <Select
                        value={currentSource.type}
                        label={t("sourceType", "Source Type")}
                        onChange={(event) => {
                            const value = event.target.value as InputSourceValue
                            let updateObject = {
                                ...currentSource,
                                criteriaIds: [],
                            }

                            onChange({
                                ...currentConfig,
                                source: {
                                    ...currentSource,
                                    ...updateObject,
                                    manuallySelectedConceptIds: null,
                                    type: value,
                                },
                            })
                        }}
                    >
                        {Object.keys(InputSourceValue).map((value) => (
                            <MenuItem value={value} key={value}>
                                {t(value, toCapitalizedWords(value))}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Box>

            <Box p={2} width="100%">
                <ToggleButtonGroup
                    size="small"
                    style={{ width: "100%" }}
                    value={!!currentSource.manuallySelect}
                    exclusive
                    onChange={(e, newValue) => {
                        onChange({
                            ...currentConfig,
                            source: {
                                ...currentSource,
                                manuallySelect: newValue,
                                manuallySelectedConceptIds: [],
                            },
                        })
                    }}
                >
                    <ToggleButton
                        color="primary"
                        size="small"
                        value={false}
                        style={{ width: "100%" }}
                    >
                        <Box>
                            <Box display="flex" justifyContent={"center"}>
                                {!currentSource.manuallySelect && (
                                    <CheckIcon style={{ marginRight: 6 }} />
                                )}
                                <Typography>
                                    {t("configureResults", "Configure Results")}
                                </Typography>
                            </Box>

                            <Typography
                                variant="caption"
                                color="textSecondary"
                                style={{ textTransform: "none" }}
                            >
                                {t(
                                    "optionsAreNotStatic",
                                    "Options are not static and based off selected properties"
                                )}
                            </Typography>
                        </Box>
                    </ToggleButton>
                    <ToggleButton
                        size="small"
                        color="primary"
                        value={true}
                        style={{
                            width: "100%",
                        }}
                    >
                        <Box>
                            <Box display="flex" justifyContent={"center"}>
                                {!!currentSource.manuallySelect && (
                                    <CheckIcon style={{ marginRight: 6 }} />
                                )}
                                <Typography>
                                    {t("manualSelection", "Manual Selection")}
                                </Typography>
                            </Box>

                            <Typography
                                variant="caption"
                                color="textSecondary"
                                style={{ textTransform: "none" }}
                            >
                                {t(
                                    "optionsAreStatic",
                                    "Options are static and based off selected concepts"
                                )}
                            </Typography>
                        </Box>
                    </ToggleButton>
                </ToggleButtonGroup>
            </Box>
            {!currentSource.manuallySelect && (
                <SourceFields
                    currentSource={currentSource}
                    onChange={(value: InputSource) => {
                        onChange({
                            ...currentConfig,
                            source: {
                                ...value,
                            },
                        })
                    }}
                />
            )}

            <Box p={2} pt={0} height="100%" width="100%" overflow="hidden">
                {!!currentSource.manuallySelect && (
                    <ManualSelector
                        currentConfig={currentConfig}
                        onChange={onChange}
                    />
                )}
            </Box>
        </Box>
    )
}

const ManualSelector = (props: {
    onChange: (value: any) => void
    currentConfig: CollectionType
}) => {
    const { onChange, currentConfig } = props
    const { currentUser } = useAuth()
    const { conceptId } = useParams()

    const classes = useStyles()
    const currentLabel = useReactiveVar(myCurrentInputResponseLabelVar)
    const [filters, setFilters] = useState([])
    const { t } = useAwaitTranslation("criteria")
    let filter: _ConceptFilter = {
        ...getSourceFilters({
            inputConfig: currentConfig,
            userId: currentUser.userId,
            conceptId,
            filters,
            currentLabel,
        }),
        isPublicAccessType_not: AccessType.NONE,
    }

    return (
        <Box>
            <Box display="flex">
                <Box display="flex" width="100%" mt={0.5}>
                    <Box
                        p={0.5}
                        pt={0}
                        flexGrow={1}
                        display="flex"
                        alignItems={"end"}
                    >
                        <Typography variant="subtitle1" color="textSecondary">
                            {t("selectableConcepts", "Selectable Concepts")}
                        </Typography>
                    </Box>
                    <ClickableRichTooltip
                        content={
                            <Box minWidth="30em" height="100%">
                                <Filters
                                    feedVariables={{
                                        filters: filters ?? [],
                                    }}
                                    onChangeFeedVariables={(
                                        feedVariables: FeedVariables
                                    ) => {
                                        setFilters(feedVariables.filters)
                                    }}
                                    excludeCategory={true}
                                />
                            </Box>
                        }
                    >
                        <IconButton>
                            <GlobalFilterIcon
                                style={{
                                    width: "1em",
                                    height: "1em",
                                    fill: "currentColor",
                                }}
                            />
                        </IconButton>
                    </ClickableRichTooltip>
                </Box>
            </Box>

            <Box className={classes.previewContainer}>
                <SelectableList
                    filter={filter}
                    orderBy={_ConceptOrdering.CREATEDAT_DESC}
                    infiniteScroll={true}
                    currentSelectionIds={
                        currentConfig?.source.manuallySelectedConceptIds
                            ?.map((item) => item.id)
                            ?.filter((x) => !!x) ?? []
                    }
                    onSelection={(concept: Concept) => {
                        if (!currentConfig?.source.manuallySelectedConceptIds) {
                            onChange({
                                ...currentConfig,
                                source: {
                                    ...currentConfig?.source,
                                    manuallySelectedConceptIds: [
                                        { id: concept.id },
                                    ],
                                },
                            })
                        } else if (
                            !!currentConfig?.source?.manuallySelectedConceptIds.find(
                                (item) => item.id === concept.id
                            )
                        ) {
                            onChange({
                                ...currentConfig,
                                source: {
                                    ...currentConfig?.source,
                                    manuallySelectedConceptIds:
                                        currentConfig?.source?.manuallySelectedConceptIds.filter(
                                            (item) => item.id !== concept.id
                                        ),
                                },
                            })
                        } else {
                            onChange({
                                ...currentConfig,
                                source: {
                                    ...currentConfig?.source,
                                    manuallySelectedConceptIds: [
                                        ...currentConfig?.source?.manuallySelectedConceptIds?.filter(
                                            (x) => !!x.id
                                        ),
                                        { id: concept.id },
                                    ],
                                },
                            })
                        }
                    }}
                />
            </Box>
        </Box>
    )
}

const SourceFields = (props: {
    currentSource: InputSource
    onChange: (value: InputSource) => void
}) => {
    const classes = useStyles()
    // const { defaultValue, defaultOptions } = useFilters()
    const { currentSource, onChange } = props
    return (
        <Box p={2} pb={0}>
            <Box className={classes.filterContainer} mb={2}>
                <Filters
                    feedVariables={{
                        filters: currentSource.filters ?? [],
                    }}
                    onChangeFeedVariables={(feedVariables: FeedVariables) => {
                        onChange({
                            ...currentSource,
                            filters: feedVariables.filters,
                        })
                    }}
                    excludeCategory={true}
                />
            </Box>
        </Box>
    )
}

export default SourceOptions
