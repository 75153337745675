import React from "react"
import {
    BalloonToolbar,
    getPluginType,
    MARK_BOLD,
    MARK_ITALIC,
    MARK_UNDERLINE,
    MarkToolbarButton,
} from "@udecode/plate"
import { useMyPlateEditorRef } from "../typescript/plateTypes"
import { ListToolbarButtons } from "./ListToolbarButtons"
import { getElementIcon } from "./getElementIcon"
import { useTheme } from "@material-ui/core/styles"
export const MarkBalloonToolbar = () => {
    const editor = useMyPlateEditorRef()
    const theme = useTheme()
    const arrow = false

    return (
        <BalloonToolbar
            styles={{
                root: {
                    zIndex: theme.zIndex.tooltip + 1,
                },
            }}
            theme={"dark"}
            arrow={arrow}
        >
            <MarkToolbarButton
                type={getPluginType(editor, MARK_BOLD)}
                icon={getElementIcon(MARK_BOLD)}
            />
            <MarkToolbarButton
                type={getPluginType(editor, MARK_ITALIC)}
                icon={getElementIcon(MARK_ITALIC)}
            />
            <MarkToolbarButton
                type={getPluginType(editor, MARK_UNDERLINE)}
                icon={getElementIcon(MARK_UNDERLINE)}
            />
            <ListToolbarButtons />
        </BalloonToolbar>
    )
}
