import React from "react"
import { TextField, Box, Typography } from "@material-ui/core"
import { NumberType } from "../useCriteriaTypes"
import useAwaitTranslation from "../../../i18n/useAwaitTranslation"

const PrefixSuffix = (props: {
    config: NumberType
    onChange: (field: string, value: string) => void
}) => {
    const { config, onChange } = props
    const { prefix, suffix } = config
    const { t } = useAwaitTranslation("criteria")
    return (
        <Box width="100%">
            <Box mb={0.5}>
                <Typography color="textSecondary">
                    {t("prefix", "Prefix")} / {t("suffix", "Suffix")}
                </Typography>
            </Box>
            <Box display="flex" width="100%">
                <Box pr={0.5} width="100%">
                    <TextField
                        onChange={(e) => onChange("prefix", e.target.value)}
                        size="small"
                        variant="outlined"
                        fullWidth
                        placeholder="$, etc."
                        value={prefix}
                    />
                </Box>

                <Box pl={0.5} width="100%">
                    <TextField
                        size="small"
                        placeholder="%, hours, per day, etc."
                        onChange={(e) => onChange("suffix", e.target.value)}
                        variant="outlined"
                        fullWidth
                        value={suffix}
                    />
                </Box>
            </Box>
        </Box>
    )
}

export default PrefixSuffix
