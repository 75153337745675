import React, { useState, useEffect } from "react"
import {
    Box,
    Divider,
    Paper,
    Fade,
    Popper,
    Typography,
    Grid,
    IconButton,
    Tooltip,
    useTheme,
    Tabs,
    Tab,
    ButtonBase,
} from "@material-ui/core"
import { ICONS_REPOSITORY } from "./useWidgetTypes"
import { makeStyles } from "@material-ui/core/styles"
import { useWidgets } from "./useWidgets"
import AddCircleIcon from "@material-ui/icons/AddCircle"
import { WidgetTypeName } from "./useWidgetTypes"
import { getTranslatedWidgetName } from "../../util/fns"
import useAwaitTranslation from "../../i18n/useAwaitTranslation"
import { useParams } from "react-router-dom"
import WidgetConfigModal from "./widgets/common/WidgetConfigModal"
import InputWidgetOptions from "./InputWidgetOptions"
import { Category } from "../../__generated__/types"
import Clear from "@material-ui/icons/Clear"

const useStyles = makeStyles((theme) => ({
    popper: {
        zIndex: theme.zIndex.drawer + 1,
    },

    selected: {
        backgroundColor: theme.palette.action.selected,
    },
    paper: {
        width: "50em",

        display: "flex",
        flexDirection: "column",
        overflow: "hidden",
    },
    tile: {
        "&:hover": {
            backgroundColor: theme.palette.action.hover,
            borderRadius: "4px",
        },
    },
}))

type Props = {
    dashboardId: string | null
    enableEditing: () => void
    conceptCategory?: Category
}

export const WidgetStore = (props: Props) => {
    const FEEDBACK_RESULTS_WIDGETS = [
        WidgetTypeName.ScoringResultsBubbleChart,
        WidgetTypeName.Table,
        WidgetTypeName.Graph,
    ]

    const INFORMATION_WIDGETS = [
        WidgetTypeName.Heading,
        WidgetTypeName.BlankNotes,
        WidgetTypeName.Concept,
        WidgetTypeName.ConceptsFeed,
        WidgetTypeName.ConceptList,
        WidgetTypeName.BubbleChart,
        WidgetTypeName.HypeChart,
        WidgetTypeName.BubbleChart,
        WidgetTypeName.CriteriaRadar,
        WidgetTypeName.ProductChart,
        WidgetTypeName.Video,
    ]
    const classes = useStyles()
    const { conceptId } = useParams()
    const handleButtonClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(anchorEl ? null : event.currentTarget)
    }
    const theme = useTheme()
    const [typeToConfigure, setTypeToConfigure] = useState(null)

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
    const { widgetTypes, onAddNewWidget } = useWidgets(props.dashboardId)
    const [tab, setTab] = useState(0)
    const open = Boolean(anchorEl)
    const handleTabChange = (
        event: React.ChangeEvent<{}>,
        newValue: number
    ) => {
        //setBrowseCriteria(false)
        setTypeToConfigure(null)
        setTab(newValue)
    }
    const initialSelection = widgetTypes?.filter(
        (type) => type.component?.name === WidgetTypeName.BlankNotes
    )?.[0]
    const { t } = useAwaitTranslation("widgets")
    useEffect(() => {
        if (initialSelection && !typeToConfigure) {
            setTypeToConfigure(initialSelection)
        }
    }, [initialSelection, typeToConfigure])

    const informationOptions = widgetTypes?.filter((item) =>
        INFORMATION_WIDGETS.includes(item.name)
    )

    const resultsOptions = widgetTypes?.filter((item) =>
        FEEDBACK_RESULTS_WIDGETS.includes(item.name)
    )
    const criteriaWidgetsEnabled = widgetTypes.find(
        (item) => item.name === WidgetTypeName.Criteria
    )
    const handleWidgetAddition = (type) => {
        if (type.schema?.required?.length > 0) {
            setAnchorEl(null)
            setTypeToConfigure(type)
        } else {
            onAddNewWidget(type.name, type.defaultConfig)
        }
    }
    const defaultSelectedTypeConfig = JSON.parse(
        typeToConfigure?.defaultConfig ?? "{}"
    )
    return (
        <>
            <Tooltip title={t("addWidgets", "Add Widgets")}>
                <IconButton onClick={handleButtonClick}>
                    <AddCircleIcon />
                </IconButton>
            </Tooltip>
            <Popper
                placement="left-start"
                anchorEl={anchorEl}
                open={open}
                transition
                className={classes.popper}
            >
                {({ TransitionProps }) => (
                    <Fade {...TransitionProps} timeout={350}>
                        <Paper className={classes.paper} elevation={8}>
                            <Box
                                p={1}
                                display="flex"
                                justifyContent={"space-between"}
                            >
                                <Typography
                                    variant="body1"
                                    style={{ flexGrow: 1 }}
                                >
                                    {t("widgetOptions", "Widget Options")}
                                </Typography>
                                <IconButton
                                    size="small"
                                    onClick={() => setAnchorEl(null)}
                                >
                                    <Clear fontSize="small" />
                                </IconButton>
                            </Box>
                            <Divider />
                            {!!criteriaWidgetsEnabled && (
                                <>
                                    <Box>
                                        <Tabs
                                            variant="fullWidth"
                                            onChange={handleTabChange}
                                            value={tab}
                                        >
                                            <Tab
                                                label={t(
                                                    "feedback",
                                                    "Feedback"
                                                )}
                                            />
                                            <Tab
                                                label={t(
                                                    "information",
                                                    "Information"
                                                )}
                                            />
                                        </Tabs>
                                    </Box>
                                    <Divider />
                                </>
                            )}
                            {tab === 1 && (
                                <Box
                                    flexShrink={0}
                                    flexGrow={1}
                                    display="flex"
                                    style={{
                                        borderLeft: `1px solid ${theme.palette.divider}`,
                                    }}
                                >
                                    <Grid container>
                                        {informationOptions
                                            ?.filter((item) => {
                                                if (!conceptId) {
                                                    return !item.schema?.options
                                                        ?.conceptOnly
                                                } else return item
                                            })
                                            .map((item, index) => {
                                                const parsedProperties = JSON.parse(
                                                    item.defaultConfig
                                                )
                                                const icon =
                                                    ICONS_REPOSITORY[item?.name]
                                                return (
                                                    <Grid
                                                        item
                                                        xs={4}
                                                        key={index}
                                                        onClick={() =>
                                                            handleWidgetAddition(
                                                                item
                                                            )
                                                        }
                                                        className={classes.tile}
                                                    >
                                                        <ButtonBase
                                                            component="div"
                                                            style={{
                                                                width: "100%",
                                                                height: "100%",
                                                            }}
                                                        >
                                                            <Box
                                                                p={2}
                                                                display="flex"
                                                                flexDirection={
                                                                    "column"
                                                                }
                                                                alignItems="center"
                                                                justifyContent="center"
                                                            >
                                                                <Box>
                                                                    {icon}
                                                                </Box>
                                                                <Typography variant="body2">
                                                                    {getTranslatedWidgetName(
                                                                        parsedProperties.label,
                                                                        t
                                                                    )}
                                                                </Typography>
                                                            </Box>
                                                        </ButtonBase>
                                                    </Grid>
                                                )
                                            })}
                                        {resultsOptions
                                            ?.filter((item) => {
                                                if (!conceptId) {
                                                    return !item.schema?.options
                                                        ?.conceptOnly
                                                } else return item
                                            })
                                            .map((item, index) => {
                                                const parsedProperties = JSON.parse(
                                                    item.defaultConfig
                                                )
                                                const icon =
                                                    ICONS_REPOSITORY[item?.name]
                                                return (
                                                    <Grid
                                                        item
                                                        xs={4}
                                                        key={index}
                                                        onClick={() =>
                                                            handleWidgetAddition(
                                                                item
                                                            )
                                                        }
                                                        className={classes.tile}
                                                    >
                                                        <ButtonBase
                                                            component="div"
                                                            style={{
                                                                width: "100%",
                                                                height: "100%",
                                                            }}
                                                        >
                                                            <Box
                                                                p={2}
                                                                display="flex"
                                                                flexDirection={
                                                                    "column"
                                                                }
                                                                alignItems="center"
                                                                justifyContent="center"
                                                            >
                                                                <Box>
                                                                    {icon}
                                                                </Box>
                                                                <Typography variant="body2">
                                                                    {getTranslatedWidgetName(
                                                                        parsedProperties.label,
                                                                        t
                                                                    )}
                                                                </Typography>
                                                            </Box>
                                                        </ButtonBase>
                                                    </Grid>
                                                )
                                            })}
                                    </Grid>
                                </Box>
                            )}
                            {tab === 0 && (
                                <Box
                                    flexShrink={0}
                                    flexGrow={1}
                                    pt={1}
                                    display="flex"
                                    flexDirection={"column"}
                                    overflow="hidden"
                                >
                                    <InputWidgetOptions
                                        onAddNewWidget={(config: any) => {
                                            const newConfig = JSON.stringify({
                                                ...defaultSelectedTypeConfig,
                                                ...config,
                                            })
                                            onAddNewWidget(
                                                WidgetTypeName.Criteria,
                                                newConfig
                                            )
                                        }}
                                        conceptCategory={props.conceptCategory}
                                    />
                                </Box>
                            )}
                        </Paper>
                    </Fade>
                )}
            </Popper>
            {!!typeToConfigure &&
                (INFORMATION_WIDGETS.includes(typeToConfigure.name) ||
                    FEEDBACK_RESULTS_WIDGETS.includes(
                        typeToConfigure.name
                    )) && (
                    <WidgetConfigModal
                        open={true}
                        widgetType={typeToConfigure}
                        onClose={() => {
                            setTypeToConfigure(null)
                        }}
                    />
                )}
        </>
    )
}
