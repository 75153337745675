import React, { useEffect, useState } from "react"
import Button from "@material-ui/core/Button"
import TextField from "@material-ui/core/TextField"
import { CONCEPT_BY_ID } from "../../../../graphql/queries"
import {
    ConceptQuery,
    ConceptQueryVariables,
} from "../../../../graphql/__generated__/queries"
import { useApolloClient } from "@apollo/client"
import Dialog from "@material-ui/core/Dialog"
import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent"
import DialogTitle from "@material-ui/core/DialogTitle"
import { Widget } from "../../useWidgets"
import useWidget from "../../useWidget"
import { WidgetTypeName } from "../../useWidgetTypes"
import { toCapitalizedWords } from "../../../../util/fns"
import Filters from "../../../filters/Filters"
import { makeStyles } from "@material-ui/core/styles"
import {
    CircularProgress,
    FormControl,
    FormControlLabel,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    Switch,
    Box,
} from "@material-ui/core"
import { useParams } from "react-router-dom"
import { FilterItem } from "../../../filters/util/types"
import {
    Category,
    Concept,
    Criteria,
    WidgetType,
} from "../../../../__generated__/types"
import CriteriaSelector from "../../../criteria/CriteriaSelector"
import { useWidgets } from "../../useWidgets"
import { Loading } from "../../../Loading"
import useAwaitTranslation from "../../../../i18n/useAwaitTranslation"
import SourceSelector from "../../SourceSelector"
import ConceptSelector from "../../../ConceptSelector"
import useFilters from "../../../filters/util/useFilters"
import CategoryTreeSelector from "../../../categories/CategoryTreeSelector"
import CollectionCriteriaField from "../../CollectionCriteriaField"
import useCategoryTools from "../../../categories/useCategoryTools"
const useStyles = makeStyles((theme) => ({
    filtersContainer: {
        borderRadius: theme.shape.borderRadius,
        border: "1px solid " + theme.palette.divider,
    },
    buttonProgress: {
        position: "absolute",
        top: "50%",
        left: "50%",
        marginTop: -12,
        marginLeft: -12,
    },
    wrapper: {
        margin: theme.spacing(1),
        position: "relative",
    },
}))
type Props = {
    widget?: Widget
    widgetType?: WidgetType
    onClose: () => void
    open: boolean
}
/// if a prop for widget is sent, the configuration of the widget is being edited
/// if a prop for widget type is sent, the widget is yet to be created
export default function WidgetConfigModal(props: Props) {
    const classes = useStyles()
    const { conceptId } = useParams()
    const [isSaving, setIsSaving] = React.useState(false)

    const client = useApolloClient()

    const concept = client.readQuery<ConceptQuery, ConceptQueryVariables>({
        query: CONCEPT_BY_ID,
        variables: {
            id: conceptId,
        },
    })?.Concept?.[0]
    const { getCategoryArray } = useCategoryTools()
    const categoryArray = getCategoryArray(concept?.category?.id)
    const { widgetTypes, onAddNewWidget } = useWidgets(
        concept?.homeDashboard?.id ||
            categoryArray?.[categoryArray?.length - 1]?.defaultDashboard?.id
    )
    const {
        widget,
        config: originalConfig,
        isInitialized,
        onEditConfig,
    } = useWidget(props.widget?.id ?? null)
    const { getCleanedFilterItems } = useFilters()

    const widgetType =
        props.widgetType ??
        widgetTypes?.find((wt) => wt.name === props.widget.type)

    const [config, setConfig] = useState(originalConfig)

    const { t } = useAwaitTranslation("widgets")
    useEffect(() => {
        if (!!props.widget && originalConfig) {
            setConfig(originalConfig)
        } else if (!!props.widgetType && props.widgetType.defaultConfig) {
            setConfig(JSON.parse(props.widgetType.defaultConfig ?? "{}"))
        }
    }, [originalConfig, props.widget, props.widgetType])

    const schema = widgetType?.schema?.properties ?? {}
    const onChangeProperty = (property: string, value: unknown) => {
        setConfig((config) => {
            return {
                ...config,
                [property]: value,
            }
        })
    }
    const onSave = async () => {
        setIsSaving(true)

        if (!!props.widget) {
            await onEditConfig(config)
            setIsSaving(false)
            props.onClose()
        } else {
            await onAddNewWidget(
                widgetType?.name as WidgetTypeName,
                JSON.stringify(config)
            )
            setIsSaving(false)
            props.onClose()
        }
    }

    return (
        <Dialog
            onMouseDown={(e) => e.stopPropagation()}
            open={props.open}
            onClose={() => props.onClose()}
            fullWidth
            disableEnforceFocus
            aria-labelledby="modal-dialog-widget-configuration"
        >
            <DialogTitle id="modal-dialog-widget-configuration">
                {widgetType &&
                    toCapitalizedWords(widgetType?.name as WidgetTypeName)}
                {widget?.isTemplate
                    ? t("template", "Template")
                    : " " + t("widget", "Widget")}{" "}
                {t("configuration", "Configuration")}
            </DialogTitle>
            <DialogContent>
                {isInitialized && config ? (
                    <Grid container direction="column" spacing={3}>
                        {Object.keys(schema).map((field: string, index) => {
                            const fieldObject = schema[field]

                            if (fieldObject.options?.hide) {
                                return null
                            }
                            if (
                                fieldObject.options?.requiredForDisplaying
                                    ?.length > 0 &&
                                !!fieldObject.options?.requiredForDisplaying.some(
                                    (x) => {
                                        if (x.requiredValues !== undefined) {
                                            return !x.requiredValues.includes(
                                                config[x.field]
                                            )
                                        } else {
                                            return !config?.[x.field]
                                        }
                                    }
                                )
                            ) {
                                return null
                            }
                            if (fieldObject.type === "source") {
                                return (
                                    <Grid item xs={12} key={index}>
                                        <SourceSelector
                                            value={config?.[field] ?? null}
                                            onChange={(value: string) => {
                                                onChangeProperty(field, value)
                                            }}
                                            options={fieldObject.enum}
                                        />
                                    </Grid>
                                )
                            }
                            if (fieldObject.type === "collectionCriteria") {
                                return (
                                    <Grid item xs={12} key={index}>
                                        <CollectionCriteriaField
                                            widgetTypeName={widgetType?.name}
                                            onChange={(value) => {
                                                onChangeProperty(field, value)
                                            }}
                                            value={
                                                config?.[field] ?? {
                                                    collectionCriteriaId: null,
                                                    subCriteriaIds: [],
                                                }
                                            }
                                        />
                                    </Grid>
                                )
                            }
                            if (fieldObject.type === "conceptSingle") {
                                return (
                                    <Grid item xs={12} key={index}>
                                        <ConceptSelector
                                            conceptId={config?.[field] ?? null}
                                            onChange={(
                                                concept: Concept | null
                                            ) => {
                                                onChangeProperty(
                                                    field,
                                                    concept?.id ?? null
                                                )
                                            }}
                                        />
                                    </Grid>
                                )
                            }
                            if (fieldObject.type === "categorySingle") {
                                return (
                                    <Grid item xs={12} key={index}>
                                        <CategoryTreeSelector
                                            value={config?.[field] ?? null}
                                            label={t(
                                                "subConnectionCategory",
                                                "Sub Connection Category"
                                            )}
                                            setValue={(category?: Category) => {
                                                let newConfig = {
                                                    ...config,
                                                }
                                                if (!!category) {
                                                    newConfig[field] =
                                                        category.id
                                                } else {
                                                    newConfig[field] = undefined
                                                }

                                                onChangeProperty(
                                                    field,
                                                    category?.id ?? undefined
                                                )
                                            }}
                                            allowAllText="None"
                                            allowAll={true}
                                        />
                                    </Grid>
                                )
                            }
                            if (fieldObject.type === "filters") {
                                return (
                                    <Grid item xs={12} key={index}>
                                        <Box
                                            className={classes.filtersContainer}
                                        >
                                            <Filters
                                                feedVariables={{
                                                    filters:
                                                        getCleanedFilterItems(
                                                            config?.[field],
                                                            false
                                                        ),
                                                }}
                                                onChangeFeedVariables={(value: {
                                                    filters: FilterItem[]
                                                }) => {
                                                    onChangeProperty(
                                                        field,
                                                        value.filters
                                                    )
                                                }}
                                            />
                                        </Box>
                                    </Grid>
                                )
                            }
                            if (fieldObject.type === "criteriaMultiple") {
                                return (
                                    <Grid item xs={12} key={index}>
                                        <CriteriaSelector
                                            onChange={(
                                                value: {
                                                    field: Criteria
                                                    subfields: Criteria[]
                                                }[]
                                            ) => {
                                                onChangeProperty(
                                                    field,
                                                    value.map(
                                                        (item) => item.field.id
                                                    )
                                                )
                                            }}
                                            value={
                                                config?.[field]?.map((o) => ({
                                                    id: o,
                                                })) ?? []
                                            }
                                            criteriaFilter={{
                                                AND: [
                                                    {
                                                        criteriaType:
                                                            fieldObject.options
                                                                .criteriaType,
                                                    },
                                                    {
                                                        categories_some: {
                                                            id_in: categoryArray?.map(
                                                                (cat) => cat.id
                                                            ),
                                                        },
                                                    },
                                                ],
                                            }}
                                        />
                                    </Grid>
                                )
                            }

                            if (
                                fieldObject.type === "string" &&
                                !fieldObject.enum
                            ) {
                                return (
                                    <Grid item xs={12} key={index}>
                                        <TextField
                                            id={`${field}-text-field`}
                                            label={
                                                toCapitalizedWords(field) ===
                                                "Label"
                                                    ? t("label", "Label")
                                                    : toCapitalizedWords(field)
                                            }
                                            variant="outlined"
                                            value={config?.[field] ?? ""}
                                            onChange={(e) =>
                                                onChangeProperty(
                                                    field,
                                                    e.currentTarget.value
                                                )
                                            }
                                            type="text"
                                            fullWidth
                                        />
                                    </Grid>
                                )
                            }
                            if (
                                fieldObject.type === "string" &&
                                fieldObject.enum
                            ) {
                                return (
                                    <Grid item xs={12} key={index}>
                                        <FormControl
                                            fullWidth
                                            variant="outlined"
                                        >
                                            <InputLabel id={`${field}-label`}>
                                                {toCapitalizedWords(field) ===
                                                "Source"
                                                    ? t("source", "Source")
                                                    : toCapitalizedWords(field)}
                                            </InputLabel>
                                            <Select
                                                labelId={`${field}-label`}
                                                id={`${field}-select`}
                                                value={config[field] ?? ""}
                                                label={toCapitalizedWords(
                                                    field
                                                )}
                                                name={field}
                                                onChange={(e) =>
                                                    onChangeProperty(
                                                        field,
                                                        e.target.value
                                                    )
                                                }
                                            >
                                                {fieldObject.enum?.map(
                                                    (value) => (
                                                        <MenuItem
                                                            value={value}
                                                            key={value}
                                                        >
                                                            {toCapitalizedWords(
                                                                value
                                                            ) === "Connections"
                                                                ? t(
                                                                      "connections",
                                                                      "Connections"
                                                                  )
                                                                : toCapitalizedWords(
                                                                      value
                                                                  ) ===
                                                                  "All Concepts"
                                                                ? t(
                                                                      "allConcepts",
                                                                      "All Concepts"
                                                                  )
                                                                : toCapitalizedWords(
                                                                      value
                                                                  )}
                                                        </MenuItem>
                                                    )
                                                )}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                )
                            }
                            if (fieldObject.type === "boolean") {
                                return (
                                    <Grid item xs={12} key={index}>
                                        <FormControlLabel
                                            control={
                                                <Switch
                                                    color="primary"
                                                    checked={
                                                        config
                                                            ? config[field]
                                                            : false
                                                    }
                                                    onChange={(e, checked) =>
                                                        // @ts-expect-error
                                                        console.log(checked) ||
                                                        onChangeProperty(
                                                            field,
                                                            checked
                                                        )
                                                    }
                                                    name={field}
                                                />
                                            }
                                            labelPlacement="end"
                                            label={toCapitalizedWords(field)}
                                        />
                                    </Grid>
                                )
                            }
                            return null
                        })}
                    </Grid>
                ) : (
                    <Loading padding={3} hideQuote={true} size={50} />
                )}
            </DialogContent>
            <DialogActions
                style={{
                    display: "flex",
                    justifyContent: "space-between",
                }}
            >
                <Box display="flex" justifyContent="flex-end" flexGrow={1}>
                    <Button onClick={() => props.onClose()}>
                        {t("cancel", "Cancel")}
                    </Button>
                    <Button
                        onClick={onSave}
                        color="primary"
                        variant="contained"
                        disabled={isSaving}
                    >
                        {isSaving ? (
                            <CircularProgress disableShrink={true} size={25} />
                        ) : (
                            t("save", "Save")
                        )}
                    </Button>
                </Box>
            </DialogActions>
        </Dialog>
    )
}
