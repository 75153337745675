import { Box } from "@material-ui/core"
import NumericInputSlider from "../../NumericInputSlider"
import { NumberType } from "../../../criteria/useCriteriaTypes"
import { useInputTools } from "../../useInputTools"

const NumberCell = (props: {
    fieldId: string
    conceptId: string
    config: NumberType
    editing: boolean
}) => {
    const { config, editing, conceptId, fieldId } = props
    const { onEditResponse, onCreateNewResponse, scores, mutating } =
        useInputTools({
            criteriaId: fieldId,
            inputConfig: config,
        })
    const score =
        scores?.find((score) => score.scoredConcept?.id === conceptId) || null
    const value = score?.response
    return (
        <Box
            display={"flex"}
            flexDirection="column"
            flexGrow={1}
            style={{ overflowY: "auto" }}
            overflow="hidden"
        >
            <NumericInputSlider
                autoFocus={true}
                value={+value || 0}
                editing={editing}
                handleChange={(value: number) => {
                    if (!mutating) {
                        let updateData = {
                            response: JSON.stringify(value),
                        }

                        if (!!score) {
                            onEditResponse(
                                {
                                    ...updateData,
                                },
                                score
                            )
                        } else {
                            onCreateNewResponse({ ...updateData }, conceptId)
                        }
                    }
                }}
                hideEndAdornment={true}
                config={config}
            />
        </Box>
    )
}

export default NumberCell
