import React from "react"
import { Box, Typography, InputAdornment } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import { ConceptFieldSettings } from "../../util/SystemSettings"
import RichTextEditor from "../text-editor/RichTextEditor"
import TextField from "@material-ui/core/TextField"
import useAwaitTranslation from "../../i18n/useAwaitTranslation"
import GenerateAI, { getSummaryPrompt, getTitlePrompt } from "../GenerateAI"
import { LanguageDataObject, LanguageTitle } from "../../util/types"

const useStyles = makeStyles((theme) => ({
    fieldContainer: {
        margin: theme.spacing(2),
    },
    richTextEditor: {
        margin: theme.spacing(2),
        display: "flex",
        flexDirection: "column",
        minHeight: "15vh",
        overflow: "hidden",
        flexGrow: 1,
    },
    label: {
        fontSize: 12,
        color: theme.palette.text.secondary,
        padding: theme.spacing(0.5),
    },
}))

interface TranslatableContentFormProps {
    setItem: (data: LanguageDataObject) => void
    language: LanguageTitle
    item: LanguageDataObject
    categoryName: string
    disabled?: boolean
}
const TranslatableContentForm: React.FC<TranslatableContentFormProps> = ({
    setItem,
    item,
    language,
    categoryName,
    disabled,
}) => {
    const classes = useStyles()
    const { t } = useAwaitTranslation("concepts")
    return (
        <Box display="flex" flexDirection="column" height="100%" width="100%">
            <Box mr={2} ml={2}>
                <Typography variant="h6">{language}</Typography>
            </Box>
            <Box className={classes.fieldContainer}>
                <TextField
                    inputProps={{
                        maxLength: ConceptFieldSettings.Title.maxLength,
                    }}
                    onChange={(e) => {
                        setItem({ ...item, title: e.target.value })
                    }}
                    fullWidth
                    variant="outlined"
                    name="title"
                    value={item?.title}
                    label={t("title", "Title")}
                    disabled={!!disabled}
                    required
                    InputProps={{
                        endAdornment:
                            language === LanguageTitle.en && !disabled ? (
                                <InputAdornment position="end">
                                    <GenerateAI
                                        onGenerate={(text: string) => {
                                            setItem({
                                                ...item,
                                                title: text,
                                            })
                                        }}
                                        maxLength={
                                            ConceptFieldSettings.Title.maxLength
                                        }
                                        onGenerateError={(error) =>
                                            console.log(error)
                                        }
                                        prompt={getTitlePrompt(
                                            item?.title,
                                            categoryName
                                        )}
                                        disabled={
                                            item?.title?.length === 0 ||
                                            !item?.title
                                        }
                                        tooltip={
                                            item?.title?.length === 0 ||
                                            !item?.title
                                                ? "No text entered"
                                                : `Use artifical intelligence to generate a(n) ${
                                                      categoryName.toLowerCase() ||
                                                      "idea"
                                                  } using the text entered`
                                        }
                                    />
                                </InputAdornment>
                            ) : null,
                    }}
                />
            </Box>
            <Box className={classes.fieldContainer}>
                <TextField
                    multiline
                    fullWidth
                    minRows={2}
                    onChange={(e) => {
                        setItem({ ...item, summary: e.target.value })
                    }}
                    inputProps={{
                        maxLength: ConceptFieldSettings.Summary.maxLength,
                    }}
                    value={item?.summary}
                    variant="outlined"
                    name="summary"
                    disabled={disabled}
                    label={t("summaryTagline", "Summary (Tagline)")}
                    InputProps={{
                        endAdornment:
                            language === LanguageTitle.en && !disabled ? (
                                <InputAdornment position="end">
                                    <GenerateAI
                                        onGenerate={(text: string) =>
                                            setItem({
                                                ...item,
                                                summary: text,
                                            })
                                        }
                                        maxLength={
                                            ConceptFieldSettings.Summary
                                                .maxLength
                                        }
                                        onGenerateError={(error) =>
                                            console.log(error)
                                        }
                                        prompt={getSummaryPrompt(
                                            item?.title,
                                            categoryName
                                        )}
                                        disabled={
                                            item?.title?.length === 0 ||
                                            !item?.title
                                        }
                                        tooltip={
                                            item?.title?.length === 0 ||
                                            !item?.title
                                                ? "No text for title entered"
                                                : `Use artificial intelligence to generate a summary (tagline) for the ${
                                                      categoryName?.toLowerCase() ||
                                                      "idea"
                                                  } entered in the title field`
                                        }
                                    />
                                </InputAdornment>
                            ) : null,
                    }}
                />
            </Box>
            <Box className={classes.richTextEditor}>
                <Box className={classes.label}>
                    {t("description", "Description")}
                </Box>
                <Box
                    display="flex"
                    flexDirection="column"
                    overflow="hidden"
                    height="100%"
                >
                    <RichTextEditor
                        editorId={language}
                        editable={!disabled}
                        initialValue={item?.description}
                        onChange={(val) =>
                            setItem({ ...item, description: val })
                        }
                    />
                </Box>
            </Box>
        </Box>
    )
}

export default TranslatableContentForm
