import React, { useContext, useState, useEffect } from "react"
import { useMutation, useApolloClient } from "@apollo/client"
import { useHistory, useLocation } from "react-router-dom"
import firebase from "firebase/app"
import { auth } from "../util/firebase"
import {
    ADMIN_EMAILS,
    MERGE_USER,
    UPDATE_USER,
    USER_FILTERED,
} from "../components/users/graphql"
import { CREATE_USER_WORKSPACE } from "../graphql/conceptListQueries"
import { useTranslation } from "react-i18next"
import { SEND_EMAIL } from "../graphql/mutations"
import { SystemRole } from "../__generated__/types"

const AuthContext = React.createContext()

export function useAuth() {
    return useContext(AuthContext)
}

const provider = new firebase.auth.GoogleAuthProvider()
const microsoftProvider = new firebase.auth.OAuthProvider("microsoft.com")

export function AuthProvider({ children }) {
    const [currentUser, setCurrentUser] = useState()
    const [firebaseUser, setFirebaseUser] = useState()
    const [newUserSignUp, setNewUserSignUp] = useState(false)
    const [isSignedIn, setIsSignedIn] = useState(false)
    const [hasAccess, setHasAccess] = useState(false)
    const [isNonMember, setIsNonMember] = useState(false)
    const [loading, setLoading] = useState(true)
    const [mergeUser] = useMutation(MERGE_USER)
    const [updateUser] = useMutation(UPDATE_USER)
    const [createWorkspace] = useMutation(CREATE_USER_WORKSPACE)
    const [sendEmail] = useMutation(SEND_EMAIL)
    const location = useLocation()
    const queryParams = new URLSearchParams(location.search)
    const continueUrl = queryParams.get("continueUrl")
    const history = useHistory()
    const { i18n } = useTranslation()
    const client = useApolloClient()

    const signup = async ({ email, password, userData }) => {
        const {
            data: { User: UserCheck },
        } = await client.query({
            query: USER_FILTERED,
            variables: {
                filter: {
                    email,
                },
            },
            networkPolicy: "network-only",
        })
        let role = userData.role
        if (UserCheck?.length > 0) {
            role = UserCheck[0].role
        }
        const {
            data: { MergeUser: mergedUser },
        } = await mergeUser({
            variables: {
                ...userData,
                userId: email,
                role,
            },
        })

        // only will be member if their email domain matches the env company domain and if signing up via SSO and their email has been verified
        if (userData.role === "MEMBER") {
            const { data: { User: admins = [] } = {} } = await client.query({
                query: ADMIN_EMAILS,
                fetchPolicy: "network-only",
            })
            const emails = admins.map((item) => item.email)
            sendEmail({
                variables: {
                    sendTo: emails,
                    subject: "New Amble Member Signed Up",
                    text: "<i></i>",
                    separateEmails: false,
                    html: `<div>
                                <h2>
                                ${userData.lastName || ""}, ${
                        userData.firstName || ""
                    } has signed up for Amble Ideation
                                </h2>
                                <p style="color: rgba(0, 0, 0, 0.54)">
                                First Name 
                                </p>
                                <p>
                                    ${userData.firstName || ""}
                                </p>
                                <p style="color: rgba(0, 0, 0, 0.54)">
                                Last Name 
                                </p>
                                <p>
                                    ${userData.lastName || ""}
                                </p>
                                <p style="color: rgba(0, 0, 0, 0.54)">
                                    Email   
                                </p>
                                <p>
                                    ${userData.email || ""}
                                </p>
                                <p style="color: rgba(0, 0, 0, 0.54)">
                                Username  
                                </p>
                                <p>
                                    ${userData.username || ""}
                                </p>
                                <p style="color: rgba(0, 0, 0, 0.54)">
                                Company 
                                </p>
                                <p>
                                    ${userData.company || ""}
                                </p>
                                <p>
                                <i>This is a system-generated notification email from Amble Ideation. If you have any questions about the authenticity of this email, please contact your Amble system administrator.</i>
                                </p>
                            </div>`,
                },
            })
        }
        const hasWorkspace = !!mergedUser?.conceptLists?.find(
            (list) => list.type === "USER_WORKSPACE"
        )
        if (!hasWorkspace) {
            await createWorkspace({
                variables: {
                    userId: email,
                },
            })
        }

        if (!currentUser?.uid) {
            await auth.createUserWithEmailAndPassword(email, password)
        }
        setNewUserSignUp(true)
        return currentUser
    }

    function login(email, password) {
        return auth.signInWithEmailAndPassword(email, password)
    }

    function loginGoogle() {
        return auth.signInWithPopup(provider)
    }

    function loginMicrosoft() {
        return auth.signInWithPopup(microsoftProvider)
    }

    function logout() {
        setIsSignedIn(false)
        setHasAccess(false)
        setCurrentUser(null)
        setFirebaseUser(null)
        return auth.signOut()
    }

    function resetPassword(email) {
        return auth.sendPasswordResetEmail(email)
    }

    function updateEmail(email) {
        return firebaseUser.updateEmail(email)
    }

    function updatePassword(password) {
        return firebaseUser.updatePassword(password)
    }

    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged((user) => {
            if (user) {
                setCurrentUser(user)
                setFirebaseUser(user)
                const lastSignIn = new Date(user.metadata.lastSignInTime)
                const created = new Date(user.metadata.creationTime)
                updateUser({
                    variables: {
                        userId: user.email,
                        providerId: user.uid,
                        email: user.email,
                        lastLogin: {
                            year: lastSignIn.getFullYear(),
                            month: lastSignIn.getMonth() + 1,
                            day: lastSignIn.getDate(),
                        },
                        createdDate: {
                            year: created.getFullYear(),
                            month: created.getMonth() + 1,
                            day: created.getDate(),
                        },
                    },
                })
                    .then((userResult) => {
                        const userData = userResult.data.UpdateUser
                        //the language can only be filled out via the sign up form
                        if (!userData) {
                            history.push(
                                !!continueUrl
                                    ? `/signup?continueUrl=${continueUrl}`
                                    : "/signup"
                            )
                            return
                        }
                        setCurrentUser({ ...user, ...userData })

                        setIsSignedIn(true)

                        const allowGuests =
                            !!process.env.REACT_APP_ALLOW_UNINVITED_GUESTS &&
                            process.env.REACT_APP_ALLOW_UNINVITED_GUESTS?.toLowerCase() ===
                                "true"

                        if (
                            !!userData &&
                            (allowGuests ||
                                (!!userData.role && userData.role !== "NONE"))
                        ) {
                            setHasAccess(true)
                        }
                        setIsNonMember(
                            !userData?.role ||
                                [SystemRole.NONE, SystemRole.GUEST].includes(
                                    userData?.role
                                )
                        )
                        if (userData.language) {
                            i18n.changeLanguage(userData.language)
                        }
                        if (
                            typeof window !== "undefined" &&
                            !!window.FS &&
                            !!window.FS.identify
                        ) {
                            window.FS.identify(user.uid, {
                                email: userData.email,
                                username: userData.username,
                                firstName: userData.firstName,
                                lastName: userData.lastName,
                            })
                        }
                        if (!!newUserSignUp) {
                            setNewUserSignUp(false)
                            history.push(continueUrl || "/")
                        }
                    })
                    .catch((error) => {
                        console.log(error)
                    })
                    .finally(() => {
                        setLoading(false)
                    })
                return
            }

            setLoading(false)
        })

        return unsubscribe
    }, [history, updateUser, i18n, client, newUserSignUp, continueUrl])

    const value = {
        auth,
        currentUser,
        login,
        loginGoogle,
        loginMicrosoft,
        isSignedIn,
        hasAccess,
        isNonMember,
        signup,
        logout,
        resetPassword,
        updateEmail,
        updatePassword,
    }

    return (
        <AuthContext.Provider value={value}>
            {!loading && children}
        </AuthContext.Provider>
    )
}
