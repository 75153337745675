import React from "react"
import { Chip, ListItemIcon } from "@material-ui/core"
import ListItem from "@material-ui/core/ListItem"
import ListItemAvatar from "@material-ui/core/ListItemAvatar"
import ListItemText from "@material-ui/core/ListItemText"
import { makeStyles } from "@material-ui/core/styles"
import Typography from "@material-ui/core/Typography"
import { Concept } from "../../../__generated__/types"
import ConceptAvatar from "../../ConceptAvatar"
import { useHistory } from "react-router-dom"
import useAwaitTranslation from "../../../i18n/useAwaitTranslation"
import ConceptPreviewPopper from "../../ConceptPreviewPopper"
const useStyles = makeStyles((theme) => ({
    hover: {
        "&:hover": {
            backgroundColor: theme.palette.action.hover,
        },
    },
}))
interface RankingItemProps {
    concept: Concept
    showPosition: boolean
    position: number
    draggable: boolean
}

export default function RankingItem(props: RankingItemProps) {
    const { concept, position, showPosition, draggable } = props
    const classes = useStyles()
    const history = useHistory()
    const { i18n, t } = useAwaitTranslation("feedback")
    const selectedLangString = i18n?.language
    let translatedContent = concept?.translations.filter(
        (t) => t.languageString === selectedLangString
    )[0]
    return (
        <ListItem
            className={classes.hover}
            key={concept.id}
            button={!!draggable ? undefined : true}
            onClick={() =>
                !!draggable ? null : history.push(`/concept/${concept.id}/home`)
            }
        >
            {!!showPosition && (
                <ListItemIcon>
                    <Chip size="small" label={position} />
                </ListItemIcon>
            )}

            <ListItemAvatar style={{ display: "flex" }}>
                <ConceptAvatar
                    variant="rounded"
                    size="large"
                    concept={concept}
                />
            </ListItemAvatar>
            <ListItemText
                primary={
                    <ConceptPreviewPopper conceptId={concept.id}>
                        <Typography variant="body2">
                            {translatedContent?.title || concept.title}
                        </Typography>
                    </ConceptPreviewPopper>
                }
            />
        </ListItem>
    )
}
