import {
    Avatar,
    List,
    Typography,
    LinearProgress,
    ListItem,
} from "@material-ui/core"
import Box from "@material-ui/core/Box"
import {
    makeStyles,
    useTheme,
    Theme,
    createStyles,
    withStyles,
} from "@material-ui/core/styles"
import ClickableRichTooltip from "../Popper/ClickableRichTooltip"
import TextField from "@material-ui/core/TextField"
import Autocomplete from "@material-ui/lab/Autocomplete"
import React, { useState } from "react"
import ConceptListItem from "../ConceptListItem"
import {
    CartesianGrid,
    ResponsiveContainer,
    Scatter,
    ScatterChart,
    XAxis,
    YAxis,
    ZAxis,
} from "recharts"
import useAwaitTranslation from "../../i18n/useAwaitTranslation"
import { useEffect } from "react"
import { Concept } from "../../__generated__/types"
const useStyles = makeStyles((theme) => ({
    container: {
        padding: theme.spacing(1),
        height: "100%",
        display: "flex",
        flexDirection: "column",
    },
    flex: {
        height: "90%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-around",
        width: "100%",
    },
    axisSelector: {
        width: "100%",
        margin: theme.spacing(1),
    },
    categoryLabel: {
        color: theme.palette.text.hint,
        fontSize: theme.typography.body2.fontSize,
    },
}))

type BubbleChartDataObjectValue = {
    criteriaName: string
    value: number
}

type BubbleChartDataObject = {
    item: Concept
    values: BubbleChartDataObjectValue[]
}
interface BubbleChartProps {
    axisOptions: string[]
    data: BubbleChartDataObject[]
}

export default function BubbleChart(props: BubbleChartProps) {
    /// hooks
    const theme = useTheme()
    const classes = useStyles()
    const { t } = useAwaitTranslation("trends")

    //state
    const [x, setX] = useState(null)
    const [y, setY] = useState(null)
    const [z, setZ] = useState(null)

    //variables
    const data =
        props.data?.map((x) => {
            let object = {
                ...x.item,
            }

            x.values.map((val) => {
                object[val.criteriaName] = val.value > 10 ? 10 : val.value
                return object
            })
            return object
        }) ?? []

    // useEffects
    useEffect(() => {
        if (props.axisOptions.length > 0) {
            setX(props.axisOptions[0])
            setY(props.axisOptions[1] ?? props.axisOptions[0])
            setZ(
                props.axisOptions[2] ??
                    props.axisOptions[1] ??
                    props.axisOptions[0]
            )
        }
    }, [props.axisOptions])
    return (
        <Box display="flex" height="100%">
            <div className={classes.container} style={{ width: "100%" }}>
                <Box
                    display="flex"
                    justifyContent="flex-start"
                    alignItems="center"
                >
                    <Box mt={2} ml={2} fontWeight="bold">
                        {`Y: ${y}`}
                    </Box>
                </Box>
                <Box flexGrow={1} width="100%" overflow="hidden">
                    <ResponsiveContainer height="100%" width={"100%"}>
                        <ScatterChart
                            margin={{
                                left: 0,
                                right: theme.spacing(5),
                                top: theme.spacing(4.25),
                                bottom: theme.spacing(3),
                            }}
                            style={{ overflow: "visible" }}
                        >
                            <CartesianGrid strokeDasharray="3 3" />

                            <XAxis
                                type="number"
                                dataKey={x}
                                name={x + "-x-axis"}
                                ticks={[0, 2, 4, 6, 8, 10]}
                                domain={[0, 10]}
                                xAxisId={x + "-x-axis"}
                                tick={{ fill: theme.palette.text.primary }}
                            />
                            <YAxis
                                type="number"
                                dataKey={y}
                                name={y + "-y-axis"}
                                domain={[0, 10]}
                                ticks={[0, 2, 4, 6, 8, 10]}
                                yAxisId={y + "-y-axis"}
                                tick={{ fill: theme.palette.text.primary }}
                            />

                            <ZAxis
                                dataKey={z}
                                range={[0, 10]}
                                name={z + "-z-axis"}
                                zAxisId={z + "-z-axis"}
                                unit={2}
                            />

                            <Scatter
                                style={{ cursor: "pointer" }}
                                data={data}
                                xAxisId={x + "-x-axis"}
                                yAxisId={y + "-y-axis"}
                                zAxisId={z + "-z-axis"}
                                overflow="visible"
                                shape={<CustomDot theme={theme} />}
                                fill={theme.palette.primary.main}
                            />
                        </ScatterChart>
                    </ResponsiveContainer>
                </Box>
                <Box
                    display="flex"
                    justifyContent="space-between"
                    mr={1}
                    ml={1}
                    fontWeight="bold"
                    mb={2}
                >
                    <Box>{`Size: ${z}`}</Box>
                    <Box>{`X: ${x}`}</Box>
                </Box>
                <Box display="flex" flexShrink={0}>
                    <Autocomplete<typeof props.axisOptions[0], false, boolean>
                        className={classes.axisSelector}
                        options={props.axisOptions}
                        getOptionLabel={(option) => option}
                        value={x}
                        size="small"
                        disableClearable
                        onChange={(
                            _: React.ChangeEvent<{}>,
                            newValue: string
                        ) => {
                            setX(newValue)
                        }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label={t("bubbleChart.xAxis", "X Axis")}
                                variant="outlined"
                            />
                        )}
                    />
                    <Autocomplete<typeof props.axisOptions[0], false, boolean>
                        className={classes.axisSelector}
                        options={props.axisOptions}
                        getOptionLabel={(option) => option}
                        value={y}
                        size="small"
                        disableClearable
                        onChange={(
                            _: React.ChangeEvent<{}>,
                            newValue: string
                        ) => {
                            setY(newValue)
                        }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label={t("bubbleChart.yAxis", "Y Axis")}
                                variant="outlined"
                            />
                        )}
                    />
                    <Autocomplete<typeof props.axisOptions[0], false, boolean>
                        className={classes.axisSelector}
                        options={props.axisOptions}
                        size="small"
                        getOptionLabel={(option) => option}
                        value={z}
                        disableClearable
                        onChange={(
                            _: React.ChangeEvent<{}>,
                            newValue: string
                        ) => {
                            setZ(newValue)
                        }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label={t("bubbleChart.size", "Size")}
                                variant="outlined"
                            />
                        )}
                    />
                </Box>
            </div>
        </Box>
    )
}

//no typing due to unknown typing of tooltip

const BorderLinearProgress = withStyles((theme: Theme) =>
    createStyles({
        root: {
            height: 15,
            borderRadius: theme.shape.borderRadius,
        },
        colorPrimary: {
            backgroundColor:
                theme.palette.grey[theme.palette.type === "light" ? 200 : 700],
        },
        bar: {
            borderRadius: theme.shape.borderRadius,
            backgroundColor:
                theme.palette.type === "light"
                    ? theme.palette.primary.light
                    : theme.palette.primary.dark,
        },
    })
)(LinearProgress)

const CustomDot = (props) => {
    const { cx, cy, node, payload, theme, tooltipPayload } = props
    const concept = payload
    const dotWidthAndHeight = isNaN(node.z) ? 0 : node.z * 6.5
    return (
        <foreignObject
            x={cx - dotWidthAndHeight / 2}
            y={cy - dotWidthAndHeight / 2}
            width={dotWidthAndHeight}
            height={dotWidthAndHeight}
            key={concept?.id + "-foreign-object"}
            overflow="visible"
        >
            <ClickableRichTooltip
                placement="left"
                content={
                    <Box
                        key={concept?.id + "-tooltip"}
                        minWidth={300}
                        maxWidth={400}
                        overflow="hidden"
                    >
                        <ConceptListItem item={concept} showSummary={false} />
                        <List style={{ overflowY: "auto" }} dense>
                            {tooltipPayload
                                ?.sort((a, b) => b.dataKey - a.dataKey)
                                ?.map((item, index) => {
                                    return (
                                        <ListItem key={index}>
                                            <Box
                                                key={index}
                                                display="flex"
                                                flexDirection="column"
                                                width="100%"
                                            >
                                                <Box>
                                                    <Box p={0.5}>
                                                        <Typography variant="body2">
                                                            {item.dataKey}
                                                        </Typography>
                                                    </Box>
                                                    <Box
                                                        display="flex"
                                                        alignItems="center"
                                                    >
                                                        <Box
                                                            p={0.5}
                                                            flexGrow={1}
                                                        >
                                                            <BorderLinearProgress
                                                                value={
                                                                    item.value *
                                                                    10
                                                                }
                                                                variant="determinate"
                                                            />
                                                        </Box>
                                                        <Box p={0.5}>
                                                            {item.value}
                                                        </Box>
                                                    </Box>
                                                </Box>
                                            </Box>
                                        </ListItem>
                                    )
                                })}
                        </List>
                    </Box>
                }
            >
                <Avatar
                    style={{
                        width: dotWidthAndHeight,
                        height: dotWidthAndHeight,
                        cursor: "pointer",
                        border: `2px solid ${theme.palette.divider}`,
                        backgroundColor: !concept.imageUrl
                            ? theme.palette.primary.main
                            : "inherit",
                    }}
                    src={concept?.imageUrl}
                >
                    <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                    >
                        {concept?.title?.[0]}
                    </Box>
                </Avatar>
            </ClickableRichTooltip>
        </foreignObject>
    )
}
