import React from "react"
import { Box, Typography, Avatar, Chip } from "@material-ui/core"
import { alpha, makeStyles, useTheme } from "@material-ui/core/styles"
import { useEffect, useRef, useState } from "react"
import {
    XAxis,
    YAxis,
    BarChart,
    Bar,
    CartesianGrid,
    ResponsiveContainer,
} from "recharts"
import ArrowRightIcon from "@material-ui/icons/ArrowRight"
import Pagination from "@material-ui/lab/Pagination"
import { Concept, CriteriaScore } from "../../__generated__/types"
import { red, orange, yellow, green, purple } from "@material-ui/core/colors"
import useCategoryTools from "../categories/useCategoryTools"
import CategoryIcon from "../categories/CategoryIcon"
const useStyles = makeStyles((theme) => ({
    container: {
        padding: theme.spacing(1),
        height: "100%",
        display: "flex",
        flexDirection: "column",
        width: "100%",
        overflow: "hidden",
    },
    flex: {
        height: "90%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-around",
        width: "100%",
    },
    axisSelector: {
        width: "100%",
        margin: theme.spacing(1),
    },
    categoryLabel: {
        color: theme.palette.text.hint,
        fontSize: theme.typography.body2.fontSize,
    },
}))

type RankingResultsBarChartObject = {
    name: string
    concept: Concept
    scores: CriteriaScore[]
    averageRank: number
}
type RangeItem = {
    label: string
    max: number
    min: number
}
interface RankingResultsBarChartProps {
    data: RankingResultsBarChartObject[]
    rangeArray: RangeItem[]
}

const MIN_ROW_HEIGHT = 75
function RankingResultsBarChart(props: RankingResultsBarChartProps) {
    const { data, rangeArray } = props

    //ref
    const sizeRef = useRef(null)

    /// hooks
    const theme = useTheme()
    const classes = useStyles()
    const { getCategoryArray } = useCategoryTools()
    //local state
    const [rowsPerPage, setRowsPerPage] = useState(8)
    const [page, setPage] = useState(1)
    const barData = [
        ...data
            .sort(function (a, b) {
                return a.averageRank - b.averageRank
            })
            .filter((item, index) => {
                return (
                    index + 1 <= page * rowsPerPage &&
                    index + 1 > (page - 1) * rowsPerPage
                )
            }),
    ]
    const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
        setPage(value)
    }
    // variables
    const colorOptions = [green, yellow, orange, red, purple]
    const currentHeight = sizeRef?.current?.clientHeight
    const totalPages = Math.ceil(data.length / rowsPerPage)
    useEffect(() => {
        setRowsPerPage(Math.floor(currentHeight / MIN_ROW_HEIGHT))
    }, [currentHeight])
    useEffect(() => {
        if (page > totalPages) {
            setPage(totalPages)
        }
    }, [page, totalPages])

    return (
        <Box
            display="flex"
            flexDirection={"column"}
            height="100%"
            overflow="hidden"
        >
            <Box
                display="flex"
                justifyContent={"space-between"}
                alignItems="center"
                width="100%"
                pl={6}
                pt={1}
                pr={1}
                flexWrap={"wrap-reverse"}
            >
                <Box
                    display="flex"
                    alignItems="center"
                    flexGrow={1}
                    flexWrap="wrap"
                    mt={1}
                >
                    {rangeArray.map((range, index) => {
                        return (
                            <Box
                                m={1}
                                display="flex"
                                flexShrink={0}
                                alignItems="center"
                                key={index}
                            >
                                <Chip
                                    style={{
                                        width: theme.spacing(2.5),
                                        height: theme.spacing(2.5),
                                        backgroundColor:
                                            colorOptions[index]?.["200"],
                                    }}
                                    label={""}
                                    size="small"
                                />
                                <Box ml={1}>
                                    <Typography variant="caption">
                                        {range.label}
                                    </Typography>
                                </Box>
                            </Box>
                        )
                    })}
                </Box>
                {data.length > rowsPerPage && (
                    <Box
                        display="flex"
                        justifyContent={"flex-end"}
                        flexGrow={1}
                    >
                        <Pagination
                            count={totalPages}
                            page={page}
                            onChange={handleChange}
                        />
                    </Box>
                )}
            </Box>
            <div className={classes.container} ref={sizeRef}>
                <ResponsiveContainer height="100%" width="100%">
                    <BarChart
                        margin={{
                            bottom: theme.spacing(4),
                            right: theme.spacing(2),
                        }}
                        data={barData}
                        layout="vertical"
                    >
                        <CartesianGrid strokeDasharray="3 3" />
                        <YAxis
                            tick={
                                <CustomAxisTick
                                    data={data}
                                    theme={theme}
                                    page={page}
                                    rowsPerPage={rowsPerPage}
                                />
                            }
                            width={50}
                            type="category"
                            dataKey={"name"}
                            tickLine={false}
                        />
                        <XAxis
                            domain={[0, 100]}
                            type="number"
                            label={{
                                value: "% of Responses",
                                position: "insideBottom",
                                offset: -theme.spacing(3),
                                fill: theme.palette.text.primary,
                            }}
                            tick={{ fill: theme.palette.text.primary }}
                            tickFormatter={(value) => `${value}%`}
                        />

                        {rangeArray.map((item, index) => {
                            return (
                                <Bar
                                    dataKey={item.label}
                                    key={item.label + index}
                                    stackId={"stack-all"}
                                    maxBarSize={75}
                                    isAnimationActive={false}
                                    shape={({
                                        x,
                                        y,
                                        width,
                                        height,
                                        value,
                                        concept,
                                        averageRank,
                                    }) => {
                                        return (
                                            <>
                                                <foreignObject
                                                    x={x}
                                                    y={y}
                                                    width={width}
                                                    height={height}
                                                    overflow={"visible"}
                                                >
                                                    <Box
                                                        style={{
                                                            backgroundColor:
                                                                alpha(
                                                                    colorOptions[
                                                                        index
                                                                    ]?.["200"],
                                                                    0.5
                                                                ),
                                                        }}
                                                        width="100%"
                                                        height="100%"
                                                    />
                                                </foreignObject>
                                                {index + 1 ===
                                                    rangeArray.length && (
                                                    <foreignObject
                                                        x={50}
                                                        y={y}
                                                        width={
                                                            sizeRef?.current
                                                                ?.clientWidth -
                                                            50 -
                                                            theme.spacing(4)
                                                        }
                                                        height={height}
                                                        overflow={"visible"}
                                                    >
                                                        <Box
                                                            display="flex"
                                                            height={"100%"}
                                                            width="100%"
                                                            alignItems={
                                                                "center"
                                                            }
                                                            pr={1}
                                                            pl={1}
                                                            overflow="hidden"
                                                        >
                                                            <Chip
                                                                style={{
                                                                    backgroundColor:
                                                                        theme
                                                                            .palette
                                                                            .background
                                                                            .paper,
                                                                    border: `1px solid ${theme.palette.divider}`,
                                                                    overflow:
                                                                        "hidden",
                                                                }}
                                                                label={
                                                                    <Box
                                                                        width="100%"
                                                                        height="100%"
                                                                        display="flex"
                                                                        alignItems={
                                                                            "center"
                                                                        }
                                                                    >
                                                                        <Avatar
                                                                            src={
                                                                                concept.imageUrl
                                                                            }
                                                                            style={{
                                                                                width: theme.spacing(
                                                                                    3.5
                                                                                ),
                                                                                height: theme.spacing(
                                                                                    3.5
                                                                                ),
                                                                                border: `1px solid ${theme.palette.divider}`,

                                                                                color: theme
                                                                                    .palette
                                                                                    .text
                                                                                    .primary,
                                                                            }}
                                                                        >
                                                                            <CategoryIcon
                                                                                styles={{
                                                                                    fontSize:
                                                                                        "0.9em",
                                                                                }}
                                                                                category={
                                                                                    concept.category
                                                                                }
                                                                            />
                                                                        </Avatar>
                                                                        <Box
                                                                            flexGrow={
                                                                                1
                                                                            }
                                                                            pl={
                                                                                1
                                                                            }
                                                                            overflow="hidden"
                                                                            maxWidth={
                                                                                300
                                                                            }
                                                                        >
                                                                            <Typography
                                                                                variant="body2"
                                                                                noWrap
                                                                            >
                                                                                {
                                                                                    concept.title
                                                                                }
                                                                            </Typography>
                                                                        </Box>
                                                                        <ArrowRightIcon fontSize="small" />
                                                                        <Box fontWeight="bold">
                                                                            {Math.round(
                                                                                averageRank *
                                                                                    10
                                                                            ) /
                                                                                10}
                                                                        </Box>
                                                                    </Box>
                                                                }
                                                            />
                                                        </Box>
                                                    </foreignObject>
                                                )}
                                            </>
                                        )
                                    }}
                                ></Bar>
                            )
                        })}
                    </BarChart>
                </ResponsiveContainer>
            </div>
        </Box>
    )
}

const CustomAxisTick = (props) => {
    const { x, y, index, page, rowsPerPage } = props
    return (
        <foreignObject x={x - 35} y={y - 25} width={50} height={50}>
            <Box height="100%" display="flex" alignItems={"center"}>
                <Chip
                    label={
                        page > 1
                            ? index + 1 + (page - 1) * rowsPerPage
                            : index + 1
                    }
                />
            </Box>
        </foreignObject>
    )
}

export default React.memo(RankingResultsBarChart)
