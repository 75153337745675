import {
    Box,
    ClickAwayListener,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TableSortLabel,
} from "@material-ui/core"
import { ChatRole, Concept, CriteriaScore } from "../../../__generated__/types"
import { CollectionType } from "../../criteria/useCriteriaTypes"
import { useApolloClient, useMutation, useReactiveVar } from "@apollo/client"
import {
    FeedbackField,
    myCurrentInputResponseLabelVar,
    myCurrentInputResponseVar,
} from "../../../providers/GlobalState"
import { CriteriaType } from "../../criteria/types"
import { memo, useCallback, useEffect, useMemo, useState } from "react"
import { UPDATE_CRITERIA_SCORE } from "../../criteria/graphql"
import { useParams } from "react-router-dom"
import useTableTools from "../../table/useTableTools"
import { getComparator, stableSort } from "../../table/Sorting"
import InputConfigForm from "../InputConfigForm"
import TableViewRow from "./TableViewRow"
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd"
import CollectionQuickAdd from "./CollectionQuickAdd"
import useAwaitTranslation from "../../../i18n/useAwaitTranslation"
import { reorderArray } from "../../../util/fns"
import {
    getCleanedFieldConfig,
    getInputId,
    getSelectedResponseScores,
} from "../util"
import HeaderCell from "./TableCells/HeaderCell"
import {
    GenerateChatAiMutation,
    GenerateChatAiMutationVariables,
} from "../../../graphql/__generated__/mutations"
import { GENERATE_CHAT_AI } from "../../../graphql/mutations"
import { useInputTools } from "../useInputTools"
import {
    ConceptQuery,
    ConceptQueryVariables,
} from "../../../graphql/__generated__/queries"
import { CONCEPT_BY_ID } from "../../../graphql/queries"
import useMountedState from "../../../util/useMountedState"
import {
    UpdateCriteriaScoreMutation,
    UpdateCriteriaScoreMutationVariables,
} from "../../criteria/__generated__/graphql"
import { useAuth } from "../../../providers/AuthProvider"
import LoadingOverlayWrapper from "../../LoadingOverlayWrapper"
import { getFieldPrompt } from "../../../util/PromptFunctions"

// Using a table as the portal so that we do not get react
// warnings when mounting a tr element
const table: HTMLElement = document.createElement("table")
table.classList.add("table-portal")
Object.assign(table.style, {
    margin: "0",
    padding: "0",
    border: "0",
    height: "0",
    width: "0",
})
const tbody: HTMLElement = document.createElement("tbody")
table.appendChild(tbody)

if (!document.body) {
    throw new Error("document.body required for example")
}
document.body.appendChild(table)

interface TableViewRowProps {
    inputId: string
    config: CollectionType
    concepts: Concept[]
    editingConfig: boolean
    editable: boolean
    onChangeRowOrder?: (concepts: Concept[], updateScore: boolean) => void
    onConceptToggle?: (concept: Concept, selected: boolean) => Promise<void>
    onConceptCreation?: (title: string, categoryId: string) => Promise<void>
    addingNewLineItem?: boolean
    onCancelNewLineItem?: () => void
    categoryToAdd?: string
}

const TableView: React.FunctionComponent<TableViewRowProps> = ({
    config,
    concepts,
    editingConfig,
    onChangeRowOrder,
    inputId,
    onConceptToggle,
    editable,
    addingNewLineItem,
    onConceptCreation,
    onCancelNewLineItem,
    categoryToAdd,
}) => {
    const { conceptId: pageConceptId } = useParams()

    const client = useApolloClient()
    const { currentUser } = useAuth()
    const isMounted = useMountedState()
    const pageConcept =
        client.readQuery<ConceptQuery, ConceptQueryVariables>({
            query: CONCEPT_BY_ID,
            variables: {
                id: pageConceptId,
            },
        })?.Concept?.[0] ?? null
    const { onCreateNewResponse } = useInputTools({})
    const [mutatingRows, setMutatingRows] = useState(false)
    const [generateChatAI] = useMutation<
        GenerateChatAiMutation,
        GenerateChatAiMutationVariables
    >(GENERATE_CHAT_AI)
    const [updateCriteriaScore] = useMutation<
        UpdateCriteriaScoreMutation,
        UpdateCriteriaScoreMutationVariables
    >(UPDATE_CRITERIA_SCORE)

    const {
        order,
        orderBy,
        setOrder,
        setOrderBy,
        classes: tableClasses,
        formatField,
    } = useTableTools()
    const { t } = useAwaitTranslation("feedback")
    const { currentResponseData } = useReactiveVar(myCurrentInputResponseVar)
    const currentLabel = useReactiveVar(myCurrentInputResponseLabelVar)
    const [selectedId, setSelectedId] = useState(null)

    const onCellSelectionChange = useCallback((value: string | null) => {
        setSelectedId(value)
    }, [])
    const [editingField, setEditingField] = useState<FeedbackField>(null)
    const [newLineItemValue, setNewLineItemValue] = useState("")
    const columns = useMemo(() => {
        return config.subfields
            ?.map((sub) =>
                currentResponseData?.find(
                    (item) =>
                        getInputId(sub.id, pageConceptId, sub.inputConfig) ===
                        getInputId(
                            item.input.criteria?.id,
                            pageConceptId,
                            JSON.parse(item.input.inputConfig)
                        )
                )
            )
            ?.filter((x) => !!x)
    }, [config?.subfields, currentResponseData, pageConceptId])

    const data: any[] = useMemo(() => {
        return (
            concepts?.map((concept) => {
                let obj = {}
                columns.map((field) => {
                    const cleanedConfig = getCleanedFieldConfig(field)
                    const score =
                        getSelectedResponseScores(field, currentLabel)?.find(
                            (score) => score.scoredConcept?.id === concept.id
                        ) || null

                    obj[cleanedConfig.name] = formatField(
                        field.input?.criteria?.criteriaType as CriteriaType,
                        cleanedConfig,
                        score?.response
                    )

                    return obj
                })
                obj["title"] = concept.title
                obj["conceptId"] = concept.id
                return obj
            }) || []
        )
    }, [columns, concepts, currentLabel, formatField])

    const onDragEnd = (result) => {
        if (!result.destination) {
            return
        }
        const conceptArray: Concept[] = reorderArray(
            concepts,
            result.source.index,
            result.destination.index
        )
        onChangeRowOrder(conceptArray, true)
        setOrderBy(null)
    }
    const onSortByColumns = (property) => {
        const isAsc = orderBy === property && order === "asc"

        onChangeRowOrder(
            stableSort(
                data,
                getComparator(isAsc ? "desc" : "asc", property)
            ).map((item) => concepts.find((o) => o.id === item.conceptId)),
            !!editable
        )
        setOrder(isAsc ? "desc" : "asc")
        setOrderBy(property)
    }

    const onGenerateValues = useCallback(
        async (
            field: FeedbackField,
            rowsToGenerate: {
                score?: CriteriaScore
                concept: Concept
            }[]
        ) => {
            setMutatingRows(true)
            const chatPromises = rowsToGenerate.map((row) => {
                const fieldConfig = getCleanedFieldConfig(field)

                // Getting fields that have the same source as the current field and are also prompt fields

                // Do not need to send connections or initialWorkspaceLoadTime due to collections not being able to be subfields so they wont be prompt fields

                const prompt = getFieldPrompt({
                    pageConcept,
                    conceptToEvaluate: row.concept,
                    config: fieldConfig,
                    workspaceFields: currentResponseData,
                    currentLabel,
                })

                return generateChatAI({
                    variables: {
                        systemPrompt: prompt.basePrompt,
                        messages: [
                            {
                                content: prompt.firstMessage,
                                role: ChatRole.USER,
                            },
                        ],
                        maxLength: 300,
                    },
                })
            })
            const chatData = await Promise.all(chatPromises)
            const chats = chatData?.map(
                (chatPromise) => chatPromise.data.generateChatAI
            )
            if (chats?.length > 0) {
                const responseToGenerate = rowsToGenerate
                    .map((row, index) => {
                        if (!row.score) {
                            return onCreateNewResponse(
                                {
                                    response: JSON.stringify([
                                        {
                                            children: [
                                                { text: chats[index] || "" },
                                            ],
                                            type: "p",
                                        },
                                    ]),
                                    label: currentLabel.label,
                                },
                                row.concept?.id,
                                field
                            )
                        } else {
                            return updateCriteriaScore({
                                variables: {
                                    id: row.score.id,

                                    response: JSON.stringify([
                                        {
                                            children: [
                                                { text: chats[index] || "" },
                                            ],
                                            type: "p",
                                        },
                                    ]),

                                    lastUpdatedByUserId: currentUser.userId,
                                    lastUpdated: {
                                        year: new Date().getUTCFullYear(),
                                        month: new Date().getUTCMonth() + 1,
                                        day: new Date().getUTCDate(),
                                        hour: new Date().getUTCHours(),
                                        minute: new Date().getUTCMinutes(),
                                        second: new Date().getUTCSeconds(),
                                    },
                                },
                            })
                        }
                    })
                    ?.filter((x) => !!x)
                await Promise.all(responseToGenerate)
            }
            if (isMounted()) {
                setMutatingRows(false)
            }
        },
        [
            generateChatAI,
            pageConcept,
            onCreateNewResponse,
            currentResponseData,
            isMounted,
            updateCriteriaScore,
            currentUser.userId,
            currentLabel,
        ]
    )
    useEffect(() => {
        if (!!selectedId) {
            if (order !== null) {
                setOrder(null)
            }
            if (orderBy !== null) {
                setOrderBy(null)
            }
        }
    }, [selectedId, order, orderBy, setOrder, setOrderBy])
    return (
        <>
            <LoadingOverlayWrapper
                loading={mutatingRows}
                message={"Generating values..."}
            >
                <TableContainer
                    style={{ height: "100%", fontSize: 14 }}
                    key={JSON.stringify(mutatingRows)}
                >
                    <Table stickyHeader size="small">
                        <TableHead>
                            <TableRow>
                                {!!editable && (
                                    <TableCell
                                        padding="checkbox"
                                        size="small"
                                    />
                                )}
                                <TableCell>
                                    <TableSortLabel
                                        active={orderBy === "title"}
                                        direction={
                                            orderBy === "title" ? order : "asc"
                                        }
                                        onClick={() => onSortByColumns("title")}
                                    >
                                        Title
                                        {orderBy === "title" && (
                                            <span
                                                className={
                                                    tableClasses.visuallyHidden
                                                }
                                            >
                                                {order === "desc"
                                                    ? "sorted descending"
                                                    : "sorted ascending"}
                                            </span>
                                        )}
                                    </TableSortLabel>
                                </TableCell>
                                {columns.map((col) => (
                                    <HeaderCell
                                        key={col.input?.id}
                                        field={col}
                                        order={order}
                                        orderBy={orderBy}
                                        onSortBy={onSortByColumns}
                                        setEditingField={setEditingField}
                                        editingConfig={editingConfig}
                                        concepts={concepts}
                                        editable={editable}
                                        onGenerateValues={onGenerateValues}
                                    />
                                ))}
                                {!!editable && (
                                    <TableCell
                                        style={{
                                            position: "sticky",
                                            right: 0,
                                            zIndex: 0,
                                        }}
                                        padding="checkbox"
                                        size="small"
                                    />
                                )}
                            </TableRow>
                        </TableHead>
                        <DragDropContext onDragEnd={onDragEnd}>
                            <Droppable
                                droppableId={inputId}
                                //@ts-ignore used HTMLElement in docs
                                getContainerForClone={() => tbody}
                                renderClone={(provided, snapshot, rubric) => (
                                    <TableViewRow
                                        draggableRef={provided.innerRef}
                                        draggableProps={provided.draggableProps}
                                        dragHandleProps={
                                            provided.dragHandleProps
                                        }
                                        key={concepts[rubric.source.index]?.id}
                                        columns={columns}
                                        concept={concepts[rubric.source.index]}
                                        selectedId={selectedId}
                                        editable={editable}
                                        isDragging={snapshot.isDragging}
                                        setSelectedId={onCellSelectionChange}
                                    />
                                )}
                            >
                                {(provided) => (
                                    <TableBody
                                        {...provided.droppableProps}
                                        ref={provided.innerRef}
                                    >
                                        {!!addingNewLineItem && (
                                            <ClickAwayListener
                                                onClickAway={() => {
                                                    if (
                                                        newLineItemValue.length ===
                                                        0
                                                    ) {
                                                        onCancelNewLineItem()
                                                    }
                                                }}
                                            >
                                                <TableRow>
                                                    <TableCell
                                                        size="small"
                                                        padding="checkbox"
                                                    />
                                                    <TableCell
                                                        colSpan={
                                                            columns.length +
                                                            (!!editable ? 2 : 1)
                                                        }
                                                    >
                                                        <Box p={0.5}>
                                                            <CollectionQuickAdd
                                                                setSearchString={
                                                                    setNewLineItemValue
                                                                }
                                                                searchString={
                                                                    newLineItemValue
                                                                }
                                                                inputId={
                                                                    inputId
                                                                }
                                                                onSelection={
                                                                    onConceptToggle
                                                                }
                                                                onCreation={
                                                                    onConceptCreation
                                                                }
                                                                variant="standard"
                                                                disabledOptionIds={concepts.map(
                                                                    (item) =>
                                                                        item.id
                                                                )}
                                                                placeholder={`${t(
                                                                    "typeToAdd",
                                                                    "Type to add to"
                                                                )} '${
                                                                    config.name
                                                                }'`}
                                                                categoryToAdd={
                                                                    categoryToAdd
                                                                }
                                                                hint={
                                                                    config.allowConceptCreation
                                                                        ? t(
                                                                              "enterToCreateNew",
                                                                              "Enter to create new"
                                                                          )
                                                                        : ""
                                                                }
                                                                disableCreation={
                                                                    !config.allowConceptCreation
                                                                }
                                                            />
                                                        </Box>
                                                    </TableCell>
                                                </TableRow>
                                            </ClickAwayListener>
                                        )}
                                        {data.map((item, index) => {
                                            const concept = concepts.find(
                                                (o) => o.id === item.conceptId
                                            )
                                            return (
                                                <Draggable
                                                    isDragDisabled={!editable}
                                                    draggableId={concept?.id}
                                                    key={concept?.id}
                                                    index={index}
                                                >
                                                    {(provided, snapshot) => (
                                                        <TableViewRow
                                                            draggableRef={
                                                                provided.innerRef
                                                            }
                                                            editable={editable}
                                                            draggableProps={
                                                                provided.draggableProps
                                                            }
                                                            dragHandleProps={
                                                                provided.dragHandleProps
                                                            }
                                                            isDragging={
                                                                snapshot.isDragging
                                                            }
                                                            key={concept?.id}
                                                            columns={columns}
                                                            concept={concept}
                                                            selectedId={
                                                                selectedId
                                                            }
                                                            setSelectedId={
                                                                onCellSelectionChange
                                                            }
                                                            onRemoval={
                                                                onConceptToggle
                                                            }
                                                        />
                                                    )}
                                                </Draggable>
                                            )
                                        })}
                                        {provided.placeholder}
                                    </TableBody>
                                )}
                            </Droppable>
                        </DragDropContext>
                    </Table>
                </TableContainer>
            </LoadingOverlayWrapper>
            {!!editingField && (
                <InputConfigForm
                    input={editingField.input}
                    criteria={editingField.input.criteria}
                    onClose={() => setEditingField(null)}
                    isTableField={true}
                />
            )}
        </>
    )
}

export default memo(TableView)
