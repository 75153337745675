import React, { useEffect, useRef, useState } from "react"
import { TextField } from "@material-ui/core"
import { useLazyQuery } from "@apollo/client"
import { Concept } from "../../../__generated__/types"

import { Autocomplete } from "@material-ui/lab"
import { COLLECTION_FUZZY_SEARCH } from "../../../graphql/queries"
import {
    FuzzySearchByCategoryForCollectionsQuery,
    FuzzySearchByCategoryForCollectionsQueryVariables,
} from "../../../graphql/__generated__/queries"
import { useAuth } from "../../../providers/AuthProvider"
import SearchListItem from "../../SearchListItem"
import useMountedState from "../../../util/useMountedState"

const ITEMS_ALLOWED_PER_PASTE = 20

interface CollectionQuickAddProps {
    searchString: string
    setSearchString: (value: string) => void
    inputId: string
    onSelection: (concept: Concept, selected: boolean) => Promise<void>
    onCreation: (title: string, categoryId: string) => Promise<void>
    disabledOptionIds?: string[]
    hint?: string
    variant?: "standard" | "outlined" | "filled"
    placeholder?: string
    categoryToAdd: string
    disableCreation?: boolean
}

const CollectionQuickAdd: React.FunctionComponent<CollectionQuickAddProps> = ({
    inputId,
    onSelection,
    disabledOptionIds,
    onCreation,
    variant,
    placeholder,
    searchString,
    setSearchString,
    categoryToAdd,
    hint,
    disableCreation,
}) => {
    const { currentUser } = useAuth()
    const isMounted = useMountedState()
    const inputRef = useRef(null)
    const [searchedValue, setSearchedValue] = useState("")
    const [creating, setCreating] = useState(false)
    const [options, setOptions] = useState([])

    const [loadResults, { loading, data }] = useLazyQuery<
        FuzzySearchByCategoryForCollectionsQuery,
        FuzzySearchByCategoryForCollectionsQueryVariables
    >(COLLECTION_FUZZY_SEARCH, {
        variables: {
            searchString: searchString,
            offset: 0,
            first: 20,
            categoryIds: [categoryToAdd],
            inputId,
            userId: currentUser.userId,
        },
    })
    useEffect(() => {
        let active = true
        if (active) {
            if (searchedValue !== searchString) {
                if (!searchString || searchString === "") {
                    setOptions([])
                    return undefined
                }

                loadResults({
                    variables: {
                        searchString: searchString ?? "*",
                        userId: currentUser.userId,
                    },
                })

                if (data && active) {
                    let newOptions = data.fuzzyConceptSearchByCategory ?? []
                    if (active) {
                        setSearchedValue(searchString)
                        setOptions(newOptions)
                    }
                }
            }
        }
        return () => {
            active = false
        }
    }, [searchString, loadResults, data, currentUser, searchedValue])

    const handleSelection = (concept: Concept) => {
        setSearchString("")
        inputRef?.current?.focus()
        onSelection(concept, false)
    }
    const handleCreation = () => {
        setSearchString("")
        inputRef?.current?.focus()
        onCreation(searchString, categoryToAdd)
    }

    const handlePaste = async (e) => {
        if (!disableCreation) {
            let stringArray: string[] =
                e.clipboardData
                    .getData("text/plain")
                    ?.split(/\r?\n|\r|\n/g)
                    ?.map((item) => item?.replace(/^.*\t+/g, "")?.trim())
                    ?.filter((x) => !!x && x?.length > 0) || []

            if (stringArray.length > 1) {
                e.preventDefault()
                setCreating(true)
                await Promise.all([
                    stringArray
                        .filter((_, index) => index < ITEMS_ALLOWED_PER_PASTE)
                        .map((title) => onCreation(title, categoryToAdd)),
                ])
                if (!!isMounted()) {
                    setCreating(false)
                    setSearchString("")
                }
            }
        }
    }
    return (
        <Autocomplete
            onPaste={handlePaste}
            fullWidth
            loading={!!loading || !!creating}
            size="small"
            loadingText={!!creating ? "Creating..." : "Loading..."}
            options={options}
            getOptionDisabled={(option) =>
                disabledOptionIds?.includes(option.id)
            }
            value={null}
            includeInputInList
            freeSolo
            onChange={(event: any, newValue: Concept, reason: any) => {
                if (reason === "select-option") {
                    handleSelection(newValue)
                } else if (reason === "create-option" && !disableCreation) {
                    handleCreation()
                }
            }}
            inputValue={searchString}
            getOptionLabel={(option) =>
                typeof option === "string" ? option : option.title
            }
            onInputChange={(event, value, reason) => {
                setSearchString(value)
            }}
            renderOption={(option) => {
                return (
                    <SearchListItem
                        concept={option}
                        key={option.id}
                        inputValue={searchString}
                    />
                )
            }}
            renderInput={(params) => (
                <TextField
                    {...params}
                    autoFocus={true}
                    inputRef={inputRef}
                    variant={variant || "outlined"}
                    placeholder={placeholder || ""}
                    helperText={hint || ""}
                />
            )}
        />
    )
}

export default CollectionQuickAdd
