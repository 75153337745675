import { Box, Paper, Typography } from "@material-ui/core"
import WidgetHeader from "./common/WidgetHeader"
import useWidget from "../useWidget"
import { WidgetTypeComponent } from "../useWidgetTypes"

import UserScoreBubbleChart, {
    AxisOption,
} from "../../charts/UserScoreBubbleChart"
import { Loading } from "../../Loading"

import { useState } from "react"
import { CollectionCriteria } from "../CollectionCriteriaField"
import InputWrapper from "../../inputs/InputWrapper"
import { useResultsVisTools } from "../../inputs/useResultsVisTools"
import useEnvironmentSettingTools from "../../settings/useEnvironmentSettingTools"
type WidgetConfig = {
    label: string
    collectionCriteria?: CollectionCriteria
}

const ScoringResultsBubbleChartWidget: WidgetTypeComponent = (props) => {
    //hooks
    const { primaryResponseText } = useEnvironmentSettingTools()
    const { config, isInitialized } = useWidget<WidgetConfig>(props.widget.id)
    const [hovered, setHovered] = useState(false)
    const {
        subCriteriaFields: fields,
        subCriteriaScores: scores,
        responseStatusMessageInterface,
    } = useResultsVisTools(
        props.widget.type,
        config?.collectionCriteria || null
    )

    let bubbleChartData = []
    let labelOptions = []

    scores?.forEach((score) => {
        if (
            !!score.conceptDefault &&
            !bubbleChartData.some(
                (obj) =>
                    !!obj.primary &&
                    obj.scoredConcept?.id === score.scoredConcept?.id
            )
        ) {
            bubbleChartData.push({
                label: primaryResponseText,
                scoredConcept: score.scoredConcept,
                primary: true,
                values: scores
                    .filter(
                        (x) =>
                            !!x.conceptDefault &&
                            x.scoredConcept?.id === score.scoredConcept?.id
                    )
                    .map((x) => ({
                        value: +x.response,
                        criteriaName:
                            JSON.parse(
                                fields?.find(
                                    (field) => field.input?.id === x.input?.id
                                )?.input?.inputConfig ?? "{}"
                            )?.name ?? x.criteria?.name,
                    })),
            })
        } else if (
            !score.conceptDefault &&
            !bubbleChartData.some(
                (item) =>
                    !item.primary &&
                    (!score.label
                        ? !item.label &&
                          item.user?.userId === score.user?.userId &&
                          item.scoredConcept?.id === score.scoredConcept?.id
                        : score.label === item.label &&
                          item.user?.userId === score.user?.userId &&
                          item.scoredConcept?.id === score.scoredConcept?.id)
            )
        ) {
            bubbleChartData.push({
                label: score.label ?? null,
                user: score.user,
                scoredConcept: score.scoredConcept,
                primary: false,
                values: scores
                    .filter(
                        (x) =>
                            !x.conceptDefault &&
                            (!score.label
                                ? !x.label &&
                                  x.user?.userId === score?.user?.userId &&
                                  x.scoredConcept?.id ===
                                      score.scoredConcept?.id
                                : score.label === x.label &&
                                  x.user?.userId === score?.user?.userId &&
                                  x.scoredConcept?.id ===
                                      score.scoredConcept?.id)
                    )
                    ?.map((x) => ({
                        value: +x.response,
                        criteriaName:
                            JSON.parse(
                                fields?.find(
                                    (field) => field.input?.id === x.input?.id
                                )?.input?.inputConfig ?? "{}"
                            )?.name ?? x.criteria?.name,
                    })),
            })
        }

        if (
            !score.conceptDefault &&
            !labelOptions.some((x) =>
                !score.label
                    ? !x.label && score.user?.userId === x.user?.userId
                    : x.label === score.label &&
                      score.user?.userId === x.user?.userId
            )
        ) {
            labelOptions.push({
                label: score.label ?? null,
                user: score.user,
            })
        }
    })

    if (!!bubbleChartData.some((item) => item.primary)) {
        labelOptions.push({
            label: primaryResponseText as string,
            user: null,
        })
    }
    labelOptions = labelOptions.sort((a, b) => {
        // sort by label
        if (a.label === b.label) {
            return 0
        } else if (a.label === null) {
            return 1
        } else if (b.label === null) {
            return -1
        } else {
            return a.label > b.label ? 1 : -1
        }
    })

    let axisOptions: AxisOption[] =
        fields?.map((item) => {
            const config = JSON.parse(item.input.inputConfig ?? "{}")
            let max = 0
            if (!config.maximum) {
                item.scores.map((score) => {
                    if (Number(score?.response ?? 0) > max) {
                        max = Number(score?.response ?? 0)
                    }

                    return max
                })
            }

            return {
                name: config?.name ?? item.input.criteria?.name,
                max: config?.maximum ?? Math.ceil(max / 10) + max,
                prefix: config.prefix,
                suffix: config.suffix,
                maxEnforced: !!config.maximum,
            }
        }) ?? []

    return (
        <Box
            component={!props.isDialog ? Paper : "div"}
            display="flex"
            height="100%"
            width="100%"
            flexDirection="column"
            key={props.widget.id}
            onMouseOver={() => {
                if (!props.isDialog && !hovered) {
                    setHovered(true)
                }
            }}
            onMouseLeave={() => {
                if (!props.isDialog && !!hovered) {
                    setHovered(false)
                }
            }}
        >
            <WidgetHeader
                label={config?.label}
                interactionTools={[]}
                configurationTools={[]}
                hovered={hovered || !!props.isDialog}
                widget={props.widget}
                onWidgetDeletion={props.onWidgetDeletion}
                editing={props.editing}
            />
            <InputWrapper
                loading={false}
                message={responseStatusMessageInterface}
            >
                {!!scores && isInitialized ? (
                    <Box key={props.widget.id} overflow="hidden" height="100%">
                        {!config.collectionCriteria ||
                        config.collectionCriteria?.subCriteriaIds?.length ===
                            0 ? (
                            <Box
                                p={1}
                                display="flex"
                                alignItems={"center"}
                                width="100%"
                                justifyContent={"center"}
                            >
                                No fields selected
                            </Box>
                        ) : bubbleChartData.length > 0 ? (
                            <Box
                                display={"flex"}
                                height="100%"
                                overflow="hidden"
                            >
                                <UserScoreBubbleChart
                                    data={bubbleChartData}
                                    axisOptions={axisOptions}
                                    labelOptions={labelOptions}
                                />
                            </Box>
                        ) : (
                            <Box
                                p={1}
                                width="100%"
                                height="100%"
                                textAlign="center"
                            >
                                <Typography color="textSecondary">
                                    No Responses to Display
                                </Typography>
                            </Box>
                        )}
                    </Box>
                ) : (
                    <Loading size={25} hideQuote={true} />
                )}
            </InputWrapper>
        </Box>
    )
}

export default ScoringResultsBubbleChartWidget
