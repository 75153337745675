import { Box, Fade } from "@material-ui/core"
import { makeStyles, Theme } from "@material-ui/core"
import { Loading } from "../Loading"

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        position: "relative",
        height: "100%",
        width: "100%",
        overflow: "hidden",
    },
    blur: {
        position: "absolute",
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        transition: theme.transitions.easing.easeInOut,
        transitionDuration: `${theme.transitions.duration.standard}ms`,
    },
    absolute: {
        position: "absolute",
        left: 0,
        right: 0,
        bottom: 0,
        top: 0,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    message: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "100%",
        width: "100%",
    },
    loaderContainer: {
        display: "flex",
        height: "100%",
        width: "100%",
        justifyContent: "center",
        alignItems: "center",
    },
}))

interface InputWrapperProps {
    children: React.ReactElement
    loading?: boolean
    message?: React.ReactElement
}
const InputWrapper = (props: InputWrapperProps) => {
    const {
        children,
        message,

        loading,
    } = props
    const classes = useStyles()
    return (
        <Box className={classes.root}>
            <Fade in={!!loading} mountOnEnter unmountOnExit>
                <Box className={classes.loaderContainer}>
                    <Loading size={25} hideQuote={true} zIndex={1} />
                </Box>
            </Fade>
            <Fade in={!!message && !loading} mountOnEnter unmountOnExit>
                {<Box className={classes.absolute}>{message}</Box>}
            </Fade>

            <Box
                className={classes.blur}
                style={{
                    filter: !!loading || !!message ? "blur(5px)" : "none",
                    pointerEvents: !!loading || !!message ? "none" : "auto",
                }}
                onClick={(e) => {
                    e.stopPropagation()
                }}
            >
                {children}
            </Box>
        </Box>
    )
}

export default InputWrapper
