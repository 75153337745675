import React, { useState, useEffect } from "react"
import { Box } from "@material-ui/core"
import Container from "@material-ui/core/Container"
import { ReactComponent as AmbleLogo } from "../logo.svg"
import { useHistory } from "react-router-dom"
import { useAuth } from "../providers/AuthProvider"
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles"
import MicrosoftCompanyLogin from "../components/auth/logins/MicrosoftCompanyLogin"
import BaseLogin from "../components/auth/logins/BaseLogin"
import { useParams, useLocation } from "react-router-dom"
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        page: {
            width: "100vw",
            height: "100vh",
        },
        paper: {
            marginTop: theme.spacing(8),
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
        },
        box: {
            position: "absolute",
            left: 0,
            top: 0,
            right: 0,
            bottom: 0,
            backgroundColor: theme.palette.background.paper,
        },
    })
)

const Login = () => {
    const { loginType } = useParams()
    const classes = useStyles()

    const [overflow, setOverflow] = useState<"auto" | "hidden">(null)
    const history = useHistory()
    const location = useLocation()
    const queryParams = new URLSearchParams(location.search)
    const continueUrl = queryParams.get("continueUrl")

    const { isSignedIn } = useAuth()

    useEffect(() => {
        if (isSignedIn) {
            history.push(continueUrl || "/")
        }
    }, [isSignedIn, history, continueUrl])
    useEffect(() => {
        function handleResize() {
            setOverflow(
                ((window.outerHeight - 10) / window.innerHeight) * 100 > 110
                    ? "auto"
                    : "hidden"
            )
        }
        window.addEventListener("resize", handleResize)
        return () => {
            window.removeEventListener("resize", handleResize)
        }
    }, [])

    useEffect(() => {
        if (!overflow) {
            setOverflow(
                ((window.outerHeight - 10) / window.innerHeight) * 100 > 110
                    ? "auto"
                    : "hidden"
            )
        }
    }, [overflow])

    return (
        <Box className={classes.box} style={{ overflowY: overflow }}>
            <Container component="main" maxWidth="xs" className={classes.page}>
                <div className={classes.paper}>
                    <Box
                        display="flex"
                        width="50%"
                        mb={2}
                        justifyContent="center"
                    >
                        <AmbleLogo style={{ height: "70px" }} />
                    </Box>
                    {!!process.env.REACT_APP_LOGIN_COMPANY_PROVIDER &&
                    process.env.REACT_APP_LOGIN_COMPANY_PROVIDER ===
                        "microsoft" &&
                    loginType !== "base" ? (
                        <MicrosoftCompanyLogin />
                    ) : (
                        <BaseLogin />
                    )}
                </div>
            </Container>
        </Box>
    )
}

export default Login
