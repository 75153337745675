import { gql } from "@apollo/client"

export const INPUT_SOURCE_CONCEPT_FRAGMENT = gql`
    fragment InputSourceConcept on Concept {
        id
        title
        __typename
        translations {
            id
            __typename
            languageString
            title
            summary
            description
        }
        summary
        imageUrl
        type
        category {
            id
            __typename
            name
            isRoot
        }
        createdAt {
            formatted
            __typename
        }
        createdBy {
            userId
            firstName
            lastName
            imageUrl
        }
        isPublic
        isPublicAccessType
        addedUsers {
            type
            User {
                userId
            }
        }
        addedTeams {
            type
            Team {
                teamId
                members {
                    userId
                }
            }
        }
        parent {
            id
        }
    }
`

export const INPUT_SCORE_FRAGMENT = gql`
    fragment InputCriteriaScore on CriteriaScore {
        id
        response
        __typename
        label
        status
        isArchived
        notes
        chatHistory
        lastUpdated {
            formatted
        }
        lastUpdatedByUserId
        criteria {
            id
            __typename
            name
        }
        value
        conceptDefault
        user {
            userId
            __typename
            firstName
            lastName
            imageUrl
        }
        input {
            id
            __typename
        }
        scoredConcept {
            ...InputSourceConcept
        }
        concepts {
            ...InputSourceConcept
        }
    }
    ${INPUT_SOURCE_CONCEPT_FRAGMENT}
`

export const MERGE_INPUT = gql`
    mutation MergeInput($id: ID!, $inputConfig: String) {
        MergeInput(id: $id, inputConfig: $inputConfig) {
            id
            inputConfig
        }
    }
`

export const UPDATE_INPUT = gql`
    mutation UpdateInput($id: ID!, $inputConfig: String) {
        UpdateInput(id: $id, inputConfig: $inputConfig) {
            id
            inputConfig
        }
    }
`
export const DELETE_INPUT = gql`
    mutation DeleteInput($id: ID!) {
        DeleteInput(id: $id) {
            id
            parentConcept {
                id
                inputs {
                    id
                }
            }
        }
    }
`
export const ADD_INPUT_CRITERIA = gql`
    mutation AddInputCriteria($inputId: ID!, $criteriaId: ID!) {
        AddInputCriteria(from: { id: $inputId }, to: { id: $criteriaId }) {
            from {
                id
            }
            to {
                id
            }
        }
    }
`

export const ADD_INPUT_CRITERIA_SCORES = gql`
    mutation AddInputCriteriaScores($inputId: ID!, $criteriaScoreId: ID!) {
        AddInputCriteriaScores(
            from: { id: $inputId }
            to: { id: $criteriaScoreId }
        ) {
            from {
                id
                inputConfig
            }
            to {
                ...InputCriteriaScore
            }
        }
    }
    ${INPUT_SCORE_FRAGMENT}
`

export const ADD_INPUT_PARENT_CONCEPT = gql`
    mutation AddInputParentConcept($inputId: ID!, $parentConceptId: ID!) {
        AddInputParentConcept(
            from: { id: $inputId }
            to: { id: $parentConceptId }
        ) {
            from {
                id
            }
            to {
                id
            }
        }
    }
`

export const DELETE_WIDGET_AND_INPUT = gql`
    mutation DeleteWidgetAndInput($inputId: ID!, $widgetId: ID!) {
        DeleteInput(id: $inputId) {
            id
        }
        DeleteWidget(id: $widgetId) {
            id
        }
    }
`

export const ADD_INPUT_CONCEPTS_CREATED = gql`
    mutation AddInputConceptsCreated(
        $inputId: ID!
        $conceptCreatedId: ID!
        $userId: ID!
        $orderBy: [_ConceptOrdering]
    ) {
        AddInputConceptsCreated(
            from: { id: $inputId }
            to: { id: $conceptCreatedId }
        ) {
            from {
                id
                conceptsCreated(
                    filter: { createdBy: { userId: $userId } }
                    orderBy: $orderBy
                ) {
                    id
                    title
                    summary
                    imageUrl
                    isPublic
                    isPublicAccessType
                    description
                    createdBy {
                        userId
                        firstName
                        lastName
                    }

                    category {
                        id
                        name
                        isRoot
                    }
                }
            }
            to {
                id
            }
        }
    }
`

export const CONCEPT_INPUTS = gql`
    query ConceptInputs($filter: _InputFilter) {
        Input(filter: $filter) {
            id
            inputConfig
            createdAt {
                formatted
            }
            criteria {
                id
                name
                criteriaType
                criteriaOptions
                isArchived
            }
        }
    }
`

export const RESPONSE_POLLING = gql`
    query ResponsePolling($filter: _CriteriaScoreFilter) {
        CriteriaScore(filter: $filter) {
            id
            ...InputCriteriaScore
        }
    }
    ${INPUT_SCORE_FRAGMENT}
`

/// have to add field on top of fragment due to apollo graphql bug
/// https://github.com/apollographql/apollo-client/issues/3402

export const CREATE_COLLECTION_CONCEPT = gql`
    mutation CreateCollectionConcept(
        $concept: ConceptInput
        $userId: ID!
        $tags: [String]
    ) {
        CreateConcept(concept: $concept, userId: $userId, tags: $tags) {
            id
            ...InputSourceConcept
        }
    }
    ${INPUT_SOURCE_CONCEPT_FRAGMENT}
`
export const COLLECTION_OPTIONS_PAGINATED = gql`
    query CollectionOptionsPaginated(
        $orderBy: _ConceptOrdering
        $offset: Int
        $first: Int
        $filter: _ConceptFilter
    ) {
        Concept(
            orderBy: [$orderBy]
            filter: $filter
            first: $first
            offset: $offset
        ) {
            id
            ...InputSourceConcept
        }
    }
    ${INPUT_SOURCE_CONCEPT_FRAGMENT}
`

export const CONCEPT_WITH_CONNECTIONS_FILTERED = gql`
    query ConceptWithConnectionsFiltered(
        $filter: _ConceptFilter
        $connectionsFilter: _ConceptFilter
    ) {
        Concept(filter: $filter) {
            id
            ...InputSourceConcept
            connections(filter: $connectionsFilter) {
                id
                ...InputSourceConcept
            }
        }
    }
    ${INPUT_SOURCE_CONCEPT_FRAGMENT}
`

export const INHERITED_CONNECTIONS = gql`
    query InheritedConnections($filter: _ConceptFilter) {
        Concept(filter: $filter, orderBy: createdAt_desc) {
            id
            ...InputSourceConcept
        }
    }
    ${INPUT_SOURCE_CONCEPT_FRAGMENT}
`

export const CONCEPT_CONNECTIONS = gql`
    query ConceptConnections($filter: _ConceptFilter, $pageConceptId: ID!) {
        Concept(filter: $filter) {
            ...InputSourceConcept
            detailedConnections {
                from(filter: { Concept: { id: $pageConceptId } }) {
                    Concept {
                        id
                    }
                }
                to(filter: { Concept: { id: $pageConceptId } }) {
                    Concept {
                        id
                    }
                }
            }
        }
    }
    ${INPUT_SOURCE_CONCEPT_FRAGMENT}
`
