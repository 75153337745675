import React, { useRef, useState } from "react"
import { Box, Paper, Popper } from "@material-ui/core"
import { useTheme } from "@material-ui/core/styles"
import {
    ConceptPreviewQuery,
    ConceptPreviewQueryVariables,
} from "../graphql/__generated__/queries"
import { useQuery } from "@apollo/client"
import { makeStyles } from "@material-ui/core/styles"
import { CONCEPT_PREVIEW } from "../graphql/queries"
import ConceptDetailsHeader from "./ConceptDetailsHeader"
import useMountedState from "../util/useMountedState"
const useStyles = makeStyles((theme) => ({
    hover: {
        cursor: "pointer",
        "&:hover": {
            textDecoration: "underline",
        },
        width: "fit-content",
    },
}))
const POPPER_DELAY = 1500
const ConceptPreviewPopper = (props: {
    conceptId: string
    children: React.ReactElement
}) => {
    const isMounted = useMountedState()
    const { conceptId, children } = props
    const theme = useTheme()
    const classes = useStyles()
    const [hovered, setHovered] = useState(false)
    const enterTimer = useRef(null)
    const anchorEl = useRef(null)
    const { data } = useQuery<
        ConceptPreviewQuery,
        ConceptPreviewQueryVariables
    >(CONCEPT_PREVIEW, {
        variables: {
            id: conceptId,
        },
        skip: !hovered,
        fetchPolicy: "network-only",
    })
    const handleEnter = (event) => {
        clearTimeout(enterTimer.current)
        event.persist()
        enterTimer.current = setTimeout(() => {
            if (!!isMounted()) {
                setHovered(true)
            }
        }, POPPER_DELAY)
    }

    const handleLeave = (event) => {
        setHovered(false)
        clearTimeout(enterTimer.current)
        event.persist()
    }
    const concept = data?.Concept?.[0] ?? null

    return (
        <span
            onMouseOver={handleEnter}
            ref={anchorEl}
            onMouseLeave={handleLeave}
            style={{ width: "fit-content" }}
        >
            <span className={classes.hover}>{children}</span>
            <Popper
                style={{
                    zIndex: theme.zIndex.modal + 1,
                }}
                placement="bottom-start"
                anchorEl={anchorEl.current}
                open={!!hovered && !!concept}
                modifiers={{
                    flip: {
                        enabled: true,
                    },
                    preventOverflow: {
                        enabled: true,
                        boundariesElement: "viewport",
                    },
                }}
            >
                <Paper elevation={11} style={{ width: 600 }}>
                    <Box height="100%" width="100%">
                        <ConceptDetailsHeader
                            item={concept}
                            preview={true}
                            editing={false}
                        />
                    </Box>
                </Paper>
            </Popper>
        </span>
    )
}

export default ConceptPreviewPopper
