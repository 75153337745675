import React from "react"
import { BorderAll } from "@styled-icons/material/BorderAll"
import { BorderBottom } from "@styled-icons/material/BorderBottom"
import { BorderClear } from "@styled-icons/material/BorderClear"
import { BorderLeft } from "@styled-icons/material/BorderLeft"
import { BorderRight } from "@styled-icons/material/BorderRight"
import { BorderTop } from "@styled-icons/material/BorderTop"
import {
    deleteColumn,
    deleteRow,
    deleteTable,
    insertTable,
    insertTableColumn,
    insertTableRow,
    TableToolbarButton,
} from "@udecode/plate"
import { Tooltip } from "@material-ui/core"

export const TableToolbarButtons = () => (
    <>
        <TableToolbarButton
            icon={
                <Tooltip title={"Insert table"}>
                    <BorderAll />
                </Tooltip>
            }
            transform={insertTable}
        />
        <TableToolbarButton
            icon={
                <Tooltip title={"Remove table"}>
                    <BorderClear />
                </Tooltip>
            }
            transform={deleteTable}
        />
        <TableToolbarButton
            icon={
                <Tooltip title={"Add table row"}>
                    <BorderBottom />
                </Tooltip>
            }
            transform={insertTableRow}
        />
        <TableToolbarButton
            icon={
                <Tooltip title={"Remove table row"}>
                    <BorderTop />
                </Tooltip>
            }
            transform={deleteRow}
        />
        <TableToolbarButton
            icon={
                <Tooltip title="Add table column">
                    <BorderLeft />
                </Tooltip>
            }
            transform={insertTableColumn}
        />
        <TableToolbarButton
            icon={
                <Tooltip title="Remove table column">
                    <BorderRight />
                </Tooltip>
            }
            transform={deleteColumn}
        />
    </>
)
