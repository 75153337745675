import React, { useCallback, useEffect, useRef, useState } from "react"
import debounce from "p-debounce"
import { makeStyles } from "@material-ui/core/styles"
import { Box, Collapse, Divider, Button } from "@material-ui/core"
import RichTextEditor, {
    BASE_INITIAL_TEXT,
    IsPlateStringEmpty,
} from "./text-editor/RichTextEditor"
import useAwaitTranslation from "../i18n/useAwaitTranslation"
import { UPDATE_CONCEPT } from "../graphql/mutations"
import {
    UpdateConceptMutation,
    UpdateConceptMutationVariables,
} from "../graphql/__generated__/mutations"
import { useParams } from "react-router-dom"
import { gql, useApolloClient, useMutation, useQuery } from "@apollo/client"
import { useAuth } from "../providers/AuthProvider"

import { useTheme } from "@material-ui/core/styles"
import ConceptTranslationsModal from "./ConceptTranslationsModal"
import { CONCEPT_DESCRIPTION } from "../graphql/queries"
import {
    ConceptDescriptionQuery,
    ConceptDescriptionQueryVariables,
} from "../graphql/__generated__/queries"
import { Concept } from "../__generated__/types"
import { BASE_PROMPT } from "../util/PromptFunctions"
import { LanguageTitle } from "../util/types"
const useStyles = makeStyles((theme) => ({
    root: {
        height: "100%",
        width: "100%",
        overflow: "hidden",
        position: "relative",
        display: "flex",
        flexDirection: "column",
    },

    clickable: {
        cursor: "pointer",
        "&:hover": {
            backgroundColor: theme.palette.action.hover,
        },
    },
}))

interface ConceptDescriptionProps {
    item: Concept
    editable?: boolean
    preview?: boolean
}
const ConceptDescription = (props: ConceptDescriptionProps) => {
    const { conceptId } = useParams()
    const { item, editable, preview } = props
    //hooks
    const { currentUser } = useAuth()
    const theme = useTheme()
    const classes = useStyles()
    const textEditorRenderingRef = useRef(0)
    const textContainerRef = useRef(null)
    const [renderedBodyHeight, setRenderedBodyHeight] = useState(0)
    const { i18n, t } = useAwaitTranslation("concepts")
    const client = useApolloClient()
    const [openTranslations, setOpenTranslations] = useState(false)
    const [showExtended, setShowExtended] = useState(false)
    const { data } = useQuery<
        ConceptDescriptionQuery,
        ConceptDescriptionQueryVariables
    >(CONCEPT_DESCRIPTION, {
        variables: {
            id: conceptId,
        },
        fetchPolicy: "cache-and-network",
        nextFetchPolicy: "cache-first",
        skip: !!preview,
    })
    const concept = !!props.preview ? item : data?.Concept?.[0]
    //mutations
    const [updateConcept] = useMutation<
        UpdateConceptMutation,
        UpdateConceptMutationVariables
    >(UPDATE_CONCEPT)
    //callbacks
    const onEditValue = debounce(
        useCallback(
            async (value: string) => {
                client.writeFragment({
                    id: client.cache.identify({
                        id: conceptId,
                        __typename: "Concept",
                    }),
                    fragment: gql`
                        fragment NewConceptDescription on Concept {
                            description
                        }
                    `,
                    data: {
                        description: value,
                    },
                })
                await updateConcept({
                    variables: {
                        userId: currentUser.userId,
                        concept: {
                            id: conceptId,
                            description: value,
                        },
                    },
                })
            },
            [updateConcept, currentUser?.userId, conceptId, client]
        ),
        500
    )

    //variables
    const selectedLangString = i18n?.language
    let translatedContent = concept?.translations.filter(
        (t) => t.languageString === selectedLangString
    )[0]
    let currentText
    let emptyString = false
    if (selectedLangString === "en") {
        if (
            !concept?.description ||
            !!IsPlateStringEmpty(concept.description)
        ) {
            currentText = JSON.stringify(BASE_INITIAL_TEXT)
            emptyString = true
        } else {
            currentText = concept.description
        }
    } else {
        if (
            !translatedContent?.description ||
            IsPlateStringEmpty(translatedContent.description)
        ) {
            if (
                !concept?.description ||
                !!IsPlateStringEmpty(concept.description)
            ) {
                currentText = JSON.stringify(BASE_INITIAL_TEXT)
                emptyString = true
            } else {
                currentText = concept.description
            }
        } else {
            currentText = translatedContent.description
        }
    }

    const textEditorKey = `Idea_Description_${
        item?.id + selectedLangString + (translatedContent?.id || "")
    }_${JSON.stringify(textEditorRenderingRef.current)}_${JSON.stringify(
        editable
    )}`

    const measuredBodyHeight =
        textContainerRef?.current?.getBoundingClientRect()?.height
    useEffect(() => {
        setRenderedBodyHeight(measuredBodyHeight)
    }, [measuredBodyHeight])

    if (!editable && !!emptyString) {
        return null
    }
    return (
        <Box
            display="flex"
            flexDirection="column"
            overflow="hidden"
            position="relative"
            height="fit-content"
            minHeight={
                !!editable &&
                LanguageTitle[selectedLangString] === LanguageTitle.en
                    ? 100
                    : "auto"
            }
            key={textEditorKey}
            className={
                !!editable && selectedLangString !== "en"
                    ? classes.clickable
                    : ""
            }
        >
            <Collapse
                in={!!showExtended || !!editable}
                collapsedSize={
                    renderedBodyHeight > 200 ? 200 : renderedBodyHeight
                }
                style={{ overflow: "visible" }}
            >
                <div
                    style={{
                        width: "100%",
                        display: "flex",
                        flexDirection: "column",
                        overflow: "hidden",
                        height: "100%",
                    }}
                >
                    <Divider />
                    <div
                        className={classes.root}
                        ref={textContainerRef}
                        onClick={() => {
                            if (!!editable && selectedLangString !== "en") {
                                setOpenTranslations(true)
                            }
                        }}
                        // required for getting value for AI
                        id={concept?.id + "_description"}
                    >
                        <RichTextEditor
                            editorId={textEditorKey}
                            onChange={(text: string) => {
                                onEditValue(text)
                            }}
                            initialValue={currentText}
                            editable={editable && selectedLangString === "en"}
                            disableEffects={true}
                            placeholderText={t(
                                "enterDescription",
                                "Enter Description"
                            )}
                            aiPrompt={{
                                basePrompt:
                                    BASE_PROMPT +
                                    ` We're populating the section called description for a ${
                                        item?.category?.name
                                    } with the following info: 
                                Title: "${item?.title || ""}"\n
                                Summary: "${item?.summary || ""}"\n
                                `,
                                firstMessage: `Please write a description of the ${item?.category?.name} based off values provided in the summary and title.`,
                            }}
                        />
                    </div>
                    {!!openTranslations && (
                        <ConceptTranslationsModal
                            concept={concept}
                            onClose={() => {
                                textEditorRenderingRef.current++
                                setOpenTranslations(false)
                            }}
                        />
                    )}
                </div>
            </Collapse>
            {renderedBodyHeight > 200 && !editable && (
                <Box
                    display="flex"
                    alignItems="flex-end"
                    justifyContent="center"
                    position="absolute"
                    left={0}
                    right={0}
                    bottom={0}
                >
                    <Box
                        position="absolute"
                        right={0}
                        top={0}
                        bottom={0}
                        left={0}
                        style={{
                            backgroundColor: "transparent",
                            background: `linear-gradient(0deg, ${theme.palette.background.paper}, transparent)`,
                        }}
                    />
                    {!!preview ? (
                        <Box width="100%" height={theme.spacing(5)} />
                    ) : (
                        <Button
                            onClick={() => {
                                setShowExtended((prev) => !prev)
                            }}
                            style={{
                                width: "100%",
                                fontWeight: "bold",
                                zIndex: theme.zIndex.appBar - 2,
                            }}
                            color="secondary"
                        >
                            {!!showExtended
                                ? t("clickToCollapse", "Click to Collapse")
                                : t("clickToExpand", "Click to Expand")}
                        </Button>
                    )}
                </Box>
            )}
        </Box>
    )
}

export default ConceptDescription
