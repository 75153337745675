export enum EnvironmentSettingName {
    RestrictConnectionsToAdmins = "RestrictConnectionsToAdmins",
    RestrictPublishingToAdmins = "RestrictPublishingToAdmins",
    PrimaryResponseText = "PrimaryResponseText",
}

export enum BooleanValues {
    TRUE = "TRUE",
    FALSE = "FALSE",
}
