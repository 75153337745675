import { useCallback, useState } from "react"
import { Order } from "./Sorting"
import { Theme, makeStyles, createStyles } from "@material-ui/core/styles"
import { CriteriaType } from "../criteria/types"
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        visuallyHidden: {
            border: 0,
            clip: "rect(0 0 0 0)",
            height: 1,
            margin: -1,
            overflow: "hidden",
            padding: 0,
            position: "absolute",
            top: 20,
            width: 1,
        },
    })
)
export default function useTableTools() {
    const classes = useStyles()
    const [order, setOrder] = useState<Order>("asc")
    const [orderBy, setOrderBy] = useState(null)
    const handleRequestSort = (property) => {
        const isAsc = orderBy === property && order === "asc"
        setOrder(isAsc ? "desc" : "asc")
        setOrderBy(property)
    }

    const formatField = useCallback(
        (type: CriteriaType, config: any, value: string) => {
            switch (type) {
                case CriteriaType.Number: {
                    return Number(value || "0")
                }
                case CriteriaType.MultipleChoice: {
                    return (
                        config.options.find((op) => op.id === value)?.value ??
                        ""
                    )
                }
                case CriteriaType.Date: {
                    if (!value) {
                        return 0
                    } else {
                        const dateArray = JSON.parse(value)
                        return new Date(dateArray?.[0])?.getTime()
                    }
                }
                default: {
                    return value || ""
                }
            }
        },
        []
    )

    return {
        order,
        setOrder,
        orderBy,
        setOrderBy,
        handleRequestSort,
        classes,
        formatField,
    }
}
