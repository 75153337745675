import {
    Box,
    ListItemText,
    Typography,
    ListItem,
    ListItemSecondaryAction,
    IconButton,
    ListItemIcon,
} from "@material-ui/core"
import { Criteria } from "../../__generated__/types"
import useCategoryTools from "../categories/useCategoryTools"
import CollectionCategorySubList from "./CollectionCategorySubList"
import { CriteriaType } from "./types"
import { CRITERIA_TYPE_ICONS } from "./useCriteriaTypes"
import { useTheme } from "@material-ui/core/styles"
import Clear from "@material-ui/icons/Clear"
import parse from "autosuggest-highlight/parse"
import match from "autosuggest-highlight/match"
import Checkbox from "@material-ui/core/Checkbox"
import React from "react"

interface FieldListItemProps {
    field: Criteria
    onRemoval?: () => void
    inputValue?: string
    added?: boolean
    onToggle?: () => void
}

const FieldListItem: React.FunctionComponent<FieldListItemProps> = ({
    field,
    onRemoval,
    inputValue,
    onToggle,
    added,
}) => {
    const { environmentCategories } = useCategoryTools()
    const theme = useTheme()
    const categories = JSON.parse(field.criteriaOptions ?? "{}")
        ?.categories?.map((item) => {
            return environmentCategories.find(
                (category) => category.id === item.id
            )
        })
        ?.filter((x) => !!x)
    const icon = CRITERIA_TYPE_ICONS[field.criteriaType]

    const highlightedName = parse(
        field.name,
        match(field.name, inputValue || "")
    )
    return (
        <ListItem key={field.id} component={"div"} style={{ padding: 0 }}>
            {!!onToggle && !onRemoval && (
                <ListItemIcon onClick={onToggle}>
                    <Checkbox size="small" checked={added} />
                </ListItemIcon>
            )}
            <ListItemText
                primary={
                    <Box display="flex" alignItems={"center"}>
                        <span
                            style={{
                                display: "flex",
                                alignItems: "center",
                                color: theme.palette.action.active,
                                marginRight: 10,
                            }}
                        >
                            {icon && icon}
                        </span>
                        <Typography variant="body1" style={{ marginRight: 8 }}>
                            {!inputValue
                                ? field.name
                                : highlightedName.map((part, index) => (
                                      <span
                                          key={index}
                                          style={{
                                              fontWeight: part.highlight
                                                  ? 800
                                                  : 400,
                                          }}
                                      >
                                          {part.text}
                                      </span>
                                  ))}
                        </Typography>
                        {field.criteriaType === CriteriaType.Collection &&
                            categories?.length > 0 && (
                                <CollectionCategorySubList
                                    categoryIds={categories.map(
                                        (cat) => cat.id
                                    )}
                                />
                            )}
                    </Box>
                }
            />
            {!!onRemoval && !onToggle && (
                <ListItemSecondaryAction onClick={onRemoval}>
                    <IconButton size="small">
                        <Clear fontSize="small" />
                    </IconButton>
                </ListItemSecondaryAction>
            )}
        </ListItem>
    )
}

export default FieldListItem
