import React, { useState } from "react"
import { useTheme } from "@material-ui/core/styles"
import { Box, Button, decomposeColor } from "@material-ui/core"
import Tooltip from "@material-ui/core/Tooltip"
import IconButton from "@material-ui/core/IconButton"
import { useAuth } from "../providers/AuthProvider"
import { useHotkeys } from "react-hotkeys-hook"
import {
    Dialog,
    DialogContent,
    DialogTitle,
    DialogActions,
    useMediaQuery,
} from "@material-ui/core"
import LikeToggle from "./liking/LikeToggle"
import SyncAltIcon from "@material-ui/icons/SyncAlt"
import LockIcon from "@material-ui/icons/Lock"
import LockOpenIcon from "@material-ui/icons/LockOpen"
import { useGlobalStyles } from "../styles/globalStyles"
import useAwaitTranslation from "../i18n/useAwaitTranslation"
import ConceptPermissions from "./permissions/ConceptPermissions"
import { Concept, SystemRole } from "../__generated__/types"
import DetailedConnectionPreviewDialog from "./DetailedConnectionPreviewDialog"
import ConceptOptions from "./ConceptOptions"
import { WidgetStore } from "./dashboard/WidgetStore"
import { useReactiveVar } from "@apollo/client"
import { userConceptPermissionsVar } from "../providers/GlobalState"
import Comment from "@material-ui/icons/Comment"
import InputResponseTracker from "./inputs/InputResponseTracker"

interface ConceptDetailsToolbarProps {
    item: Concept
    commentsOpen: boolean
    toggleCommentsOpen: () => void
    toggleEditing: () => void
    editing: boolean
    setOpenTranslationsModal: (boolean) => void
    selectedDashboardId: string
}

const ConceptDetailsToolbar: React.FunctionComponent<
    ConceptDetailsToolbarProps
> = ({
    item,
    commentsOpen,
    toggleCommentsOpen,
    toggleEditing,
    editing,
    setOpenTranslationsModal,
    selectedDashboardId,
}) => {
    //hooks
    const { t } = useAwaitTranslation("widgets")
    const theme = useTheme()
    const mobile = useMediaQuery(theme.breakpoints.down("xs"))
    const tabletOrBelow = !useMediaQuery(theme.breakpoints.up("md"))
    const { currentUser, isNonMember } = useAuth()

    const globalStyles = useGlobalStyles()
    useHotkeys("l, k", (ev) => handleHotkey(ev))
    useHotkeys(
        "ctrl+l,command+l,ctrl+k,command+k",
        (event) => handleHotkey(event),
        { enableOnTags: ["INPUT", "SELECT", "TEXTAREA"] }
    )

    //local state
    const [options, openOptions] = useState(false)
    const [confirm, openConfirm] = useState(false)

    const [connectionPreviewOpen, setConnectionPreviewOpen] = useState(false)

    //global state
    const { editingPermission } = useReactiveVar(userConceptPermissionsVar)

    //functions
    const handleHotkey = (event: KeyboardEvent) => {
        event.preventDefault()
        setConnectionPreviewOpen(true)
    }
    const handleDelete = () => {
        openConfirm(false)
    }

    //variables
    const editableDashboardCheck =
        (!!item.homeDashboard || currentUser.role === SystemRole.ADMIN) &&
        !!selectedDashboardId

    return (
        <>
            <Box
                minHeight={60}
                display="flex"
                alignItems="center"
                flexDirection="column"
                justifyContent={"center"}
                style={{
                    backdropFilter: "blur(12px)",
                    width: "100%",

                    backgroundColor: `rgba(${
                        //@ts-ignore
                        decomposeColor(theme.palette.background.default).values
                    }, .25)`,
                }}
            >
                <Box display="flex" width="100%" alignItems="center">
                    {!tabletOrBelow ? (
                        <InputResponseTracker concept={item} />
                    ) : (
                        <Box width="100%" />
                    )}
                    {!isNonMember && (
                        <Box
                            width="100%"
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            height="100%"
                            flexGrow={1}
                        >
                            <Tooltip
                                title={t(
                                    "viewAllConnections",
                                    "View all connections"
                                )}
                            >
                                <IconButton
                                    onClick={() =>
                                        setConnectionPreviewOpen(true)
                                    }
                                >
                                    <SyncAltIcon
                                        className={globalStyles.labelStylesIcon}
                                    />
                                    <span className={globalStyles.count}>
                                        {item.connections.length}
                                    </span>
                                </IconButton>
                            </Tooltip>

                            <LikeToggle
                                location={"IdeaHeader"}
                                item={item}
                                size="medium"
                            />
                            <Tooltip title={t("viewComments", "View comments")}>
                                <IconButton
                                    onClick={toggleCommentsOpen}
                                    color={
                                        !!commentsOpen ? "primary" : "default"
                                    }
                                >
                                    <Comment
                                        className={globalStyles.labelStylesIcon}
                                    />
                                    <span className={globalStyles.count}>
                                        {item.commentCount}
                                    </span>
                                </IconButton>
                            </Tooltip>

                            <ConceptOptions
                                item={item}
                                setOpenTranslationsModal={
                                    setOpenTranslationsModal
                                }
                            />
                        </Box>
                    )}
                    <Box
                        height="100%"
                        width="100%"
                        display="flex"
                        justifyContent="flex-end"
                        alignItems="center"
                    >
                        {!isNonMember && <ConceptPermissions item={item} />}
                        {!!editableDashboardCheck &&
                            !mobile &&
                            !!editing &&
                            !!editingPermission && (
                                <WidgetStore
                                    dashboardId={selectedDashboardId}
                                    enableEditing={() => {
                                        if (!editing) {
                                            toggleEditing()
                                        }
                                    }}
                                    conceptCategory={item?.category}
                                />
                            )}

                        {!!editingPermission && (
                            <Tooltip
                                title={
                                    editing
                                        ? t("lockEditing", "Lock Editing")
                                        : t("unlockEditing", "Unlock Editing")
                                }
                            >
                                <IconButton
                                    onClick={() => toggleEditing()}
                                    style={{
                                        marginRight: theme.spacing(1.5),
                                    }}
                                >
                                    {editing ? <LockOpenIcon /> : <LockIcon />}
                                </IconButton>
                            </Tooltip>
                        )}
                    </Box>
                </Box>
                {!!tabletOrBelow && <InputResponseTracker concept={item} />}
            </Box>

            {connectionPreviewOpen && (
                <DetailedConnectionPreviewDialog
                    itemId={item.id}
                    onClose={() => {
                        setConnectionPreviewOpen(false)
                    }}
                />
            )}

            <Dialog open={options}>
                <DialogTitle>{t("options", "Options")}</DialogTitle>
                <DialogContent style={{ textAlign: "center" }}>
                    <Button
                        style={{ color: "red" }}
                        onClick={() => {
                            openOptions(false)
                            openConfirm(true)
                        }}
                        variant="outlined"
                    >
                        {t("delete", "Delete")}
                    </Button>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => {
                            openOptions(false)
                        }}
                    >
                        {t("close", "Close")}
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog open={confirm}>
                <DialogContent>
                    {t(
                        "confirmDelete",
                        "Are you sure you want to delete this? You will not be able to recover it."
                    )}
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => {
                            openConfirm(false)
                            openOptions(true)
                        }}
                    >
                        {t("no", "No")}
                    </Button>
                    <Button onClick={handleDelete}>{t("yes", "Yes")}</Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default ConceptDetailsToolbar
