import { useCallback, useEffect, useLayoutEffect, useReducer } from "react"
import { useVirtual } from "react-virtual"

function rectReducer(
    state: { width: number; height: number },
    action: { rect: { width: number; height: number } }
) {
    const rect = action.rect
    if (!state || state.height !== rect.height || state.width !== rect.width) {
        return rect
    }
    return state
}
function useWindowRect(windowObj: typeof window) {
    const [rect, dispatch] = useReducer(rectReducer, null)
    useLayoutEffect(() => {
        dispatch({
            rect: {
                height: windowObj.innerHeight,
                width: windowObj.innerWidth,
            },
        })
    }, [windowObj])

    useEffect(() => {
        const resizeHandler = () => {
            dispatch({
                rect: {
                    height: windowObj.innerHeight,
                    width: windowObj.innerWidth,
                },
            })
        }
        resizeHandler()
        windowObj.addEventListener("resize", resizeHandler)
        return () => {
            windowObj.removeEventListener("resize", resizeHandler)
        }
    }, [windowObj])

    return rect || { width: 0, height: 0 }
}

export default function useVirtualWindow<T>({
    windowRef,
    scrollToFn,
    horizontal,
    parentRef,
    ...rest
}: {
    windowRef: React.RefObject<HTMLElement>
    parentRef: React.RefObject<T>
} & Parameters<typeof useVirtual>[0]) {
    const scrollKey = horizontal ? "scrollX" : "scrollY"
    const defaultScrollToFn = useCallback(
        (offset) => {
            if (windowRef.current) {
                windowRef.current[scrollKey] = offset
            }
        },
        [scrollKey, windowRef]
    )

    return useVirtual({
        ...rest,
        horizontal,
        parentRef,
        scrollToFn: scrollToFn || defaultScrollToFn,
        onScrollElement: windowRef,
        scrollOffsetFn() {
            if (!parentRef.current) return null
            // @ts-expect-error
            const bounds = parentRef.current.getBoundingClientRect()
            return horizontal ? bounds.left * -1 : bounds.top * -1
        },

        // @ts-expect-error
        useObserver: () => useWindowRect(windowRef.current),
    })
}
