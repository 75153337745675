import React, { useState } from "react"
import {
    ListItemAvatar,
    ListItem,
    ListItemText,
    Box,
    ListItemIcon,
    IconButton,
    Fade,
    Tooltip,
} from "@material-ui/core"
import { Concept } from "../../../__generated__/types"
import ConceptAvatar from "../../ConceptAvatar"
import { decomposeColor, makeStyles, useTheme } from "@material-ui/core/styles"
import ConceptPreviewPopper from "../../ConceptPreviewPopper"
import useAwaitTranslation from "../../../i18n/useAwaitTranslation"
import DragIndicator from "@material-ui/icons/DragIndicator"
import {
    DraggableProvidedDraggableProps,
    DraggableProvidedDragHandleProps,
} from "react-beautiful-dnd"
import DeleteForever from "@material-ui/icons/DeleteForever"
import Clear from "@material-ui/icons/Clear"
import useConceptTools from "../../../util/useConceptTools"
import { getUserConceptPermission } from "../../../util/GetPermissions"
import { useAuth } from "../../../providers/AuthProvider"
import { useHistory } from "react-router-dom"
import ConfirmConceptDeletion from "../../dialogs/ConfirmConceptDeletion"
import { useParams } from "react-router-dom"
const useStyles = makeStyles((theme) => ({
    hover: {
        "&:hover": {
            backgroundColor: theme.palette.action.hover,
        },
    },
    isDragging: {
        border: `1px solid ${theme.palette.divider}`,
        borderRadius: theme.shape.borderRadius,
        backgroundColor: theme.palette.background.paper,
    },
}))

interface CollectionItemInterface {
    item: Concept
    editable: boolean
    draggableProps: DraggableProvidedDraggableProps
    dragHandleProps: DraggableProvidedDragHandleProps
    draggableRef: any
    isDragging: boolean
    onRemoval?: (concept: Concept, selected: boolean) => Promise<void>
}

const CollectionListItem: React.FunctionComponent<CollectionItemInterface> = ({
    item,
    editable,
    isDragging,
    dragHandleProps,
    draggableProps,
    draggableRef,
    onRemoval,
}) => {
    //hooks
    const { conceptId: pageConceptId } = useParams()
    const { i18n, t } = useAwaitTranslation("feedback")
    const classes = useStyles()
    const { currentUser } = useAuth()
    const history = useHistory()
    const { deleteConcept, deleting } = useConceptTools(item)
    const theme = useTheme()

    //state
    const [confirmDelete, setConfirmDelete] = useState(false)
    const [hovered, setHovered] = useState(false)

    //variables
    const selectedLangString = i18n?.language
    let translatedContent = item?.translations.filter(
        (t) => t.languageString === selectedLangString
    )[0]
    const title = translatedContent?.title || item.title || ""
    const summary = translatedContent?.summary || item.summary || ""
    const permissionToDelete =
        !!getUserConceptPermission(item, currentUser)?.owningPermission &&
        pageConceptId === item.parent?.id

    //functions
    const handleDeletion = async () => {
        await deleteConcept({
            variables: {
                conceptId: item.id,
            },
        })
        onRemoval(item, true)
    }
    return (
        <ListItem
            onMouseOver={() => {
                if (!hovered && !isDragging) {
                    setHovered(true)
                }
            }}
            onMouseLeave={() => {
                if (!!hovered && !isDragging) {
                    setHovered(false)
                }
            }}
            className={!!isDragging ? classes.isDragging : classes.hover}
            ContainerComponent="div"
            {...draggableProps}
            ref={draggableRef}
        >
            <Box display="flex" width="100%" alignItems={"center"}>
                {editable && (
                    <ListItemIcon style={{ minWidth: theme.spacing(4) }}>
                        <IconButton size="small" {...dragHandleProps}>
                            <Fade in={!!hovered}>
                                <DragIndicator fontSize="small" />
                            </Fade>
                        </IconButton>
                    </ListItemIcon>
                )}
                <ListItemAvatar style={{ display: "flex" }}>
                    <ConceptAvatar
                        concept={item}
                        variant="rounded"
                        size="large"
                    />
                </ListItemAvatar>
                <ListItemText
                    primary={
                        <ConceptPreviewPopper conceptId={item.id}>
                            <Box
                                width="fit-content"
                                onClick={() =>
                                    history.push(`/concept/${item.id}/home`)
                                }
                            >
                                {title}
                            </Box>
                        </ConceptPreviewPopper>
                    }
                    primaryTypographyProps={{
                        variant: "body2",
                        style: {
                            width: "fit-content",
                        },
                    }}
                    secondary={summary}
                />
                {!!hovered && !!editable && (
                    <Box
                        position="absolute"
                        right={0}
                        top={0}
                        bottom={0}
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        pr={1}
                        pl={1}
                        style={{
                            backgroundColor: !!hovered
                                ? `rgba(${
                                      decomposeColor(
                                          theme.palette.background.default
                                      ).values
                                  }, .75)`
                                : "transparent",
                        }}
                    >
                        <Fade in={!!hovered}>
                            <Box display="flex">
                                {!!permissionToDelete && (
                                    <Tooltip
                                        title={t(
                                            "deletePermanently",
                                            "Delete permanently"
                                        )}
                                    >
                                        <IconButton
                                            onClick={() =>
                                                setConfirmDelete(true)
                                            }
                                            size="small"
                                            style={{ marginRight: 4 }}
                                        >
                                            <DeleteForever color={"error"} />
                                        </IconButton>
                                    </Tooltip>
                                )}
                                <Tooltip
                                    title={t(
                                        "removeFromCollection",
                                        "Remove from collection"
                                    )}
                                >
                                    <IconButton
                                        size="small"
                                        onClick={() => onRemoval(item, true)}
                                    >
                                        <Clear />
                                    </IconButton>
                                </Tooltip>
                            </Box>
                        </Fade>
                    </Box>
                )}
            </Box>
            {!!confirmDelete && (
                <ConfirmConceptDeletion
                    concept={item}
                    onDeletion={handleDeletion}
                    deleting={!!deleting}
                    onClose={() => setConfirmDelete(false)}
                />
            )}
        </ListItem>
    )
}

export default React.memo(CollectionListItem)
