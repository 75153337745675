import { useApolloClient, useMutation, useReactiveVar } from "@apollo/client"
import { useCallback, useEffect, useMemo, useReducer } from "react"
import { CriteriaScore, Concept } from "../../../__generated__/types"
import {
    UPDATE_CRITERIA_SCORE,
    UPDATE_CRITERIA_SCORE_NO_RETURN,
} from "../../criteria/graphql"
import {
    UpdateCriteriaScoreMutation,
    UpdateCriteriaScoreMutationVariables,
    UpdateCriteriaScoreNoReturnMutation,
    UpdateCriteriaScoreNoReturnMutationVariables,
} from "../../criteria/__generated__/graphql"
import {
    ADD_CRITERIA_SCORE_CONCEPT,
    REMOVE_CRITERIA_SCORE_CONCEPT,
} from "../../criteria/graphql"
import {
    AddCriteriaScoreConceptsMutation,
    AddCriteriaScoreConceptsMutationVariables,
    RemoveCriteriaScoreConceptsMutation,
    RemoveCriteriaScoreConceptsMutationVariables,
} from "../../criteria/__generated__/graphql"
import { CollectionType } from "../../criteria/useCriteriaTypes"
import { useInputTools } from "../useInputTools"
import { INPUT_SOURCE_CONCEPT_FRAGMENT } from "../graphql"
import { useParams } from "react-router-dom"
import { ActionValue } from "../../../util/fns"
import {
    allPageConceptConnectionsVar,
    myCurrentInputResponseLabelVar,
    myCurrentInputResponseVar,
    workspaceLoadTimeVar,
} from "../../../providers/GlobalState"
import { ScoreSelectorDefaultValues } from "../types"
import { InputSourceConceptFragment } from "../__generated__/graphql"

import { useAuth } from "../../../providers/AuthProvider"
import { scoreUpdatedSinceInitialLoad } from "../util"

function CollectionReducer(
    state: {
        scoreId: string
        concepts: Concept[]
    },
    action: {
        scoreId: string
        value: ActionValue
        concepts: Concept[]
    }
) {
    let newConceptOrder = [...(state?.concepts ?? [])]
    if (action.value === ActionValue.remove) {
        newConceptOrder = [
            ...newConceptOrder.filter(
                (item) => !action.concepts.find((x) => x.id === item.id)
            ),
        ]
    } else if (
        action.value === ActionValue.set ||
        action.scoreId !== state?.scoreId ||
        !state
    ) {
        newConceptOrder = [...action.concepts]
    } else if (action.value === ActionValue.add) {
        newConceptOrder = [
            ...action.concepts.filter(
                (concept) =>
                    !newConceptOrder.find((item) => item.id === concept.id)
            ),
            ...newConceptOrder,
        ]
    } else if (action.value === ActionValue.updateProperties) {
        newConceptOrder = newConceptOrder.map((item, index) => ({
            ...item,
            ...(action.concepts.find((o) => o.id === item.id) ?? {}),
        }))
    }
    return {
        scoreId: action.scoreId,
        concepts: newConceptOrder,
    }
}

export default function useCollectionTools(
    score: CriteriaScore,
    config: CollectionType
) {
    const { conceptId: pageConceptId } = useParams()
    const {
        onCreateNewCollectionConcept,
        onConnectConcept,
        onDisconnectConcept,
    } = useInputTools({})
    const { currentUser } = useAuth()
    const client = useApolloClient()
    const [state, dispatch] = useReducer(
        CollectionReducer,
        null as { scoreId: string; concepts: Concept[] } | null
    )
    const { connections } = useReactiveVar(allPageConceptConnectionsVar)
    const { conceptId: currentFieldDataConceptId } = useReactiveVar(
        myCurrentInputResponseVar
    )
    const initialWorkspaceLoadTime = useReactiveVar(workspaceLoadTimeVar)

    const { label, user: responseAuthor } = useReactiveVar(
        myCurrentInputResponseLabelVar
    )
    const viewingPrimaryResponse =
        label === ScoreSelectorDefaultValues.primaryResponse && !responseAuthor
    const collectionConcepts = useMemo(() => {
        if (!config.requirePrimaryResponse && !!viewingPrimaryResponse) {
            return [
                ...(score?.concepts ?? []),
                ...connections.filter(
                    (connection) =>
                        !score?.concepts?.find((c) => c.id === connection.id) &&
                        !!config.categories?.find(
                            (o) => o.id === connection.category?.id
                        )
                ),
            ]
        } else {
            return [...(score?.concepts ?? [])]
        }
    }, [
        config.requirePrimaryResponse,
        score?.concepts,
        connections,
        config.categories,
        viewingPrimaryResponse,
    ])

    //mutations
    const [addConceptToScore] = useMutation<
        AddCriteriaScoreConceptsMutation,
        AddCriteriaScoreConceptsMutationVariables
    >(ADD_CRITERIA_SCORE_CONCEPT)
    const [removeConceptToScore] = useMutation<
        RemoveCriteriaScoreConceptsMutation,
        RemoveCriteriaScoreConceptsMutationVariables
    >(REMOVE_CRITERIA_SCORE_CONCEPT)
    const [updateCriteriaScore] = useMutation<
        UpdateCriteriaScoreMutation,
        UpdateCriteriaScoreMutationVariables
    >(UPDATE_CRITERIA_SCORE)
    const [updateCriteriaScoreNoReturn] = useMutation<
        UpdateCriteriaScoreNoReturnMutation,
        UpdateCriteriaScoreNoReturnMutationVariables
    >(UPDATE_CRITERIA_SCORE_NO_RETURN)

    useEffect(() => {
        if (collectionConcepts && currentFieldDataConceptId === pageConceptId) {
            let orderedConcepts = []
            const responseArray = JSON.parse(score?.response ?? "[]")
            collectionConcepts
                ?.filter((item) => responseArray.includes(item.id))
                .map((concept) => {
                    orderedConcepts[responseArray.indexOf(concept.id)] = concept
                    return orderedConcepts
                })
            // if the score was updated after the initial load time, then we take the response array as only source of truth
            if (
                !scoreUpdatedSinceInitialLoad(
                    score?.lastUpdated.formatted,
                    initialWorkspaceLoadTime
                )
            ) {
                orderedConcepts = [
                    ...orderedConcepts,
                    ...(collectionConcepts.filter(
                        (item) => !responseArray.includes(item.id)
                    ) ?? []),
                ]
            }

            dispatch({
                scoreId: score?.id || currentUser?.userId + pageConceptId,
                value: ActionValue.updateProperties,
                concepts: orderedConcepts.filter((x) => !!x),
            })
        }
    }, [
        collectionConcepts,
        score?.response,
        score?.id,
        score?.lastUpdated,
        currentFieldDataConceptId,
        pageConceptId,
        currentUser?.userId,
        initialWorkspaceLoadTime,

        score?.lastUpdated?.formatted,
    ])

    const onConceptToggle = useCallback(
        async (
            concept: InputSourceConceptFragment,
            selected: boolean,
            conceptToRemove?: InputSourceConceptFragment
        ) => {
            const connectedConcept = connections.find(
                (connection) => connection.id === concept.id
            )
            let newResponse = []
            if (selected) {
                newResponse =
                    state?.concepts
                        ?.filter((o) => o.id !== concept.id)
                        .map((item) => item.id)
                        ?.filter((x) => !!x) ?? []
                dispatch({
                    scoreId: score?.id,
                    value: ActionValue.remove,
                    concepts: [concept],
                })
                if (!!connectedConcept && !!viewingPrimaryResponse) {
                    onDisconnectConcept(connectedConcept)
                }
                await removeConceptToScore({
                    variables: {
                        conceptId: concept.id,
                        criteriaScoreId: score?.id,
                    },
                })
            } else {
                newResponse = [
                    concept.id,
                    ...(state?.concepts

                        .map((item) => item.id)
                        ?.filter((x) => !!x) ?? []),
                ]
                dispatch({
                    scoreId: score?.id,
                    value: !!config?.singleSelection
                        ? ActionValue.set
                        : ActionValue.add,
                    concepts: [concept],
                })

                if (!!config?.singleSelection && !!conceptToRemove) {
                    await removeConceptToScore({
                        variables: {
                            conceptId: conceptToRemove.id,
                            criteriaScoreId: score?.id,
                        },
                    })
                }
                await addConceptToScore({
                    variables: {
                        conceptId: concept.id,
                        criteriaScoreId: score?.id,
                    },
                })
                if (!connectedConcept && !!viewingPrimaryResponse) {
                    onConnectConcept(concept)
                }
            }
            updateCriteriaScoreNoReturn({
                variables: {
                    id: score?.id,
                    lastUpdatedByUserId: currentUser.userId,
                    lastUpdated: {
                        year: new Date().getUTCFullYear(),
                        month: new Date().getUTCMonth() + 1,
                        day: new Date().getUTCDate(),
                        hour: new Date().getUTCHours(),
                        minute: new Date().getUTCMinutes(),
                        second: new Date().getUTCSeconds(),
                    },
                    response: JSON.stringify(newResponse),
                },
            })
            return
        },
        [
            addConceptToScore,
            removeConceptToScore,
            score?.id,
            config.singleSelection,
            connections,
            viewingPrimaryResponse,
            updateCriteriaScoreNoReturn,
            currentUser?.userId,
            state?.concepts,
            onConnectConcept,
            onDisconnectConcept,
        ]
    )
    const onConceptCreation = useCallback(
        async (title: string, categoryId: string) => {
            const NewConcept = await onCreateNewCollectionConcept({
                title,
                categoryId: categoryId,
                parentId: pageConceptId,
            })
            const fullConceptFragment = client.readFragment({
                id: client.cache.identify(NewConcept),
                fragment: INPUT_SOURCE_CONCEPT_FRAGMENT,
            })
            await onConceptToggle(fullConceptFragment, false)
        },
        [onCreateNewCollectionConcept, onConceptToggle, pageConceptId, client]
    )

    const onUpdateConceptOrder = useCallback(
        (orderedConcepts: Concept[], updateScore: boolean) => {
            if (!!updateScore) {
                updateCriteriaScore({
                    variables: {
                        id: score?.id,
                        response: JSON.stringify(
                            orderedConcepts?.map((item) => item.id) || []
                        ),
                        lastUpdatedByUserId: currentUser.userId,
                        lastUpdated: {
                            year: new Date().getUTCFullYear(),
                            month: new Date().getUTCMonth() + 1,
                            day: new Date().getUTCDate(),
                            hour: new Date().getUTCHours(),
                            minute: new Date().getUTCMinutes(),
                            second: new Date().getUTCSeconds(),
                        },
                    },
                })
            }
            dispatch({
                scoreId: score?.id,
                value: ActionValue.set,
                concepts: orderedConcepts,
            })
        },
        [updateCriteriaScore, score?.id, currentUser?.userId]
    )
    return {
        state,
        dispatch,
        onUpdateConceptOrder,
        onConceptToggle,
        onConceptCreation,
    }
}
