import Container from "@material-ui/core/Container"
import { makeStyles, useTheme } from "@material-ui/core/styles"
import { useEffect } from "react"
import { Box, Button, Link, Typography } from "@material-ui/core"
import Email from "@material-ui/icons/Email"
import { ReactComponent as AmbleLogo } from "../logo.svg"
import { useAuth } from "../providers/AuthProvider"
import { useHistory, useLocation } from "react-router-dom"
import { ReactComponent as GoogleIcon } from "../styles/icons/google-logo.svg"
import { ReactComponent as MicrosoftIcon } from "../styles/icons/microsoft-logo.svg"
import useAwaitTranslation from "../i18n/useAwaitTranslation"

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
    },
    root: {
        position: "absolute",
        left: 0,
        top: 0,
        right: 0,
        bottom: 0,
        backgroundColor: theme.palette.background.paper,
    },
    button: {
        height: "100%",
        width: "100%",
        padding: theme.spacing(2.5),
    },
}))

export default function SignupOptions() {
    const classes = useStyles()
    const location = useLocation()
    const queryParams = new URLSearchParams(location.search)
    const continueUrl = queryParams.get("continueUrl")
    const { isSignedIn, loginMicrosoft, loginGoogle } = useAuth()
    const history = useHistory()
    const theme = useTheme()
    const { t } = useAwaitTranslation("login")
    useEffect(() => {
        if (isSignedIn) {
            history.push(continueUrl || "/")
        }
    }, [isSignedIn, history, continueUrl])
    return (
        <Box className={classes.root}>
            <Container component="main" maxWidth="xs">
                <div className={classes.paper}>
                    <Box
                        display="flex"
                        width="50%"
                        mb={2}
                        justifyContent="center"
                    >
                        <AmbleLogo style={{ height: "70px" }} />
                    </Box>

                    <Typography variant="subtitle1">Signup Options</Typography>

                    <Box
                        width={"100%"}
                        mt={2}
                        border={`1px solid ${theme.palette.divider}`}
                        borderRadius={theme.shape.borderRadius}
                    >
                        <Box
                            display="flex"
                            flexDirection="column"
                            alignItems={"center"}
                        >
                            <Button
                                className={classes.button}
                                onClick={() => {
                                    history.push(
                                        continueUrl
                                            ? `/signup?continueUrl=${continueUrl}`
                                            : "/signup"
                                    )
                                }}
                                startIcon={<Email />}
                            >
                                Continue with email
                            </Button>

                            <Button
                                className={classes.button}
                                startIcon={<GoogleIcon />}
                                onClick={loginGoogle}
                            >
                                Continue with Google
                            </Button>

                            <Button
                                className={classes.button}
                                startIcon={<MicrosoftIcon />}
                                onClick={loginMicrosoft}
                            >
                                Continue with Microsoft
                            </Button>
                        </Box>
                    </Box>
                    <Box p={1} textAlign="right" width="100%">
                        <Link
                            onClick={() =>
                                history.push(
                                    continueUrl
                                        ? `/login?continueUrl=${continueUrl}`
                                        : "/login"
                                )
                            }
                            variant="body2"
                            component="button"
                        >
                            {t(
                                "signInMessage",
                                "Already have an account? Sign in"
                            )}
                        </Link>
                    </Box>
                </div>
            </Container>
        </Box>
    )
}
