import React, { useEffect, useState } from "react"
import { Box } from "@material-ui/core"

import { DateType } from "../../../criteria/useCriteriaTypes"
import {
    KeyboardDatePicker,
    MuiPickersUtilsProvider,
} from "@material-ui/pickers"
import DateFnsUtils from "@date-io/date-fns"
import { useInputTools } from "../../useInputTools"
import { isValidDate } from "../../../../util/fns"
import dayjs from "dayjs"
import ArrowRightAlt from "@material-ui/icons/ArrowRightAlt"

const datePattern =
    /\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d\.\d+([+-][0-2]\d:[0-5]\d|Z)/

interface DateCellProps {
    conceptId: string
    fieldId: string
    config: DateType
    editing: boolean
}

const DateCell = (props: DateCellProps) => {
    const { conceptId, fieldId, config, editing } = props

    const { onEditResponse, scores, onCreateNewResponse } = useInputTools({
        criteriaId: fieldId,
        inputConfig: config,
    })
    const [start, setStart] = useState(null)
    const [end, setEnd] = useState(null)
    const score =
        scores?.find((score) => score.scoredConcept?.id === conceptId) || null

    useEffect(() => {
        if (start === null || (!!config.range && end === null)) {
            const dateArray = JSON.parse(score?.response ?? "[]")
            const startDate: Date =
                !!dateArray[0] && datePattern.exec(dateArray[0])
                    ? new Date(dateArray[0])
                    : new Date()
            const endDate: Date = !!config.range
                ? !!dateArray[1] && datePattern.exec(dateArray[1])
                    ? new Date(dateArray[1])
                    : new Date()
                : null
            setStart(startDate)
            if (!!endDate) {
                setEnd(endDate)
            }
        }
    }, [score?.response, config.range, start, end])

    if (!score?.response && !editing) {
        return <Box />
    }

    const handleChange = (value, field: "start" | "end") => {
        if (!!isValidDate(value)) {
            if (field === "start") {
                setStart(value)
            } else if (field === "end") {
                setEnd(value)
            }
            let updateData = {
                response: JSON.stringify(
                    field === "start"
                        ? !!config.range
                            ? [
                                  value.toISOString(),
                                  end?.toISOString() ||
                                      new Date().toISOString(),
                              ]
                            : [value.toISOString()]
                        : JSON.stringify([
                              start?.toISOString() ||
                                  new Date().toISOString() ||
                                  new Date().toISOString(),
                              value.toISOString(),
                          ])
                ),
            }

            if (!!score) {
                onEditResponse(
                    {
                        ...updateData,
                    },
                    score
                )
            } else {
                onCreateNewResponse({ ...updateData }, conceptId)
            }
        }
    }
    return (
        <Box
            display={"flex"}
            flexDirection="column"
            flexGrow={1}
            style={{ overflowY: "auto" }}
            key={score?.id}
        >
            <Box
                flexGrow={1}
                display="flex"
                alignItems="center"
                key={score?.id}
                pr={0.5}
                pl={0.5}
            >
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <Box display="flex" width="100%" alignItems="center">
                        <Box pr={!!config.range ? 0.5 : 0} width="100%">
                            {!!editing ? (
                                <KeyboardDatePicker
                                    size="small"
                                    variant="inline"
                                    InputAdornmentProps={{
                                        position: "start",
                                        style: {
                                            padding: 0.25,
                                        },
                                    }}
                                    fullWidth
                                    style={{ minWidth: "8rem" }}
                                    InputProps={{
                                        style: {
                                            paddingLeft: 0,
                                            fontSize: "inherit",
                                        },
                                        disableUnderline: !editing,
                                    }}
                                    allowKeyboardControl
                                    format="MM/dd/yy"
                                    readOnly={!editing}
                                    value={start.toString()}
                                    onChange={(value) => {
                                        handleChange(value, "start")
                                    }}
                                    inputVariant={
                                        !!editing ? "outlined" : "standard"
                                    }
                                />
                            ) : (
                                <Box>{dayjs(start).format("MM/DD/YY")}</Box>
                            )}
                        </Box>

                        {!!config.range && (
                            <>
                                <ArrowRightAlt fontSize="small" />
                                <Box pl={0.5} width="100%">
                                    {!!editing ? (
                                        <KeyboardDatePicker
                                            size="small"
                                            readOnly={!editing}
                                            variant="inline"
                                            format="MM/dd/yy"
                                            fullWidth
                                            style={{ minWidth: "8rem" }}
                                            InputProps={{
                                                style: {
                                                    paddingLeft: 0,
                                                    fontSize: "inherit",
                                                },
                                                disableUnderline: !editing,
                                            }}
                                            minDate={start}
                                            allowKeyboardControl
                                            value={end.toString()}
                                            InputAdornmentProps={{
                                                position: "start",
                                            }}
                                            onChange={(value) => {
                                                handleChange(value, "end")
                                            }}
                                            inputVariant={
                                                !!editing
                                                    ? "outlined"
                                                    : "standard"
                                            }
                                        />
                                    ) : (
                                        <Box>
                                            {dayjs(end).format("MM/DD/YY")}
                                        </Box>
                                    )}
                                </Box>
                            </>
                        )}
                    </Box>
                </MuiPickersUtilsProvider>
            </Box>
        </Box>
    )
}

export default DateCell
