import React, { useEffect, useState } from "react"
import { Box, IconButton, Paper, Fade, Tooltip } from "@material-ui/core"

import WidgetHeader from "./common/WidgetHeader"
import useWidget from "../useWidget"
import { WidgetTypeComponent } from "../useWidgetTypes"
import { useQuery, useReactiveVar } from "@apollo/client"
import CriteriaRadar from "../../charts/CriteriaRadar"
import { CONCEPT_WITH_CRITERIA } from "../../criteria/graphql"
import {
    ConceptWithCriteriaQuery,
    ConceptWithCriteriaQueryVariables,
} from "../../criteria/__generated__/graphql"
import { useParams } from "react-router-dom"
import { RadarDataObject } from "../../charts/CriteriaRadar"
import EditIcon from "@material-ui/icons/Edit"
import DefaultScoringCriteriaEditor from "../../criteria/DefaultScoringCriteriaEditor"
import { Loading } from "../../Loading"
import ErrorHandler from "../../ErrorHandler"
import { userConceptPermissionsVar } from "../../../providers/GlobalState"

type WidgetConfig = {
    label: string
    criteriaIds: string[]
    conceptIds?: string[]
}

const CriteriaRadarWidget: WidgetTypeComponent = (props) => {
    //hooks
    const { config, isInitialized } = useWidget<WidgetConfig>(props.widget.id)
    const { conceptId } = useParams()

    const [hovered, setHovered] = useState(false)
    //queries
    const { data, refetch, error } = useQuery<
        ConceptWithCriteriaQuery,
        ConceptWithCriteriaQueryVariables
    >(CONCEPT_WITH_CRITERIA, {
        variables: {
            criteriaScoreFilter: {
                scoredConcept: {
                    id: conceptId,
                },
                criteria: {
                    id_in: config?.criteriaIds ?? [],
                },
                conceptDefault: true,
            },
            criteriaFilter: {
                id_in: config?.criteriaIds ?? [],
            },
            conceptFilter: {
                id: conceptId,
            },
        },
    })

    //state
    const [editingScores, setEditingScores] = useState(false)

    //variables
    const criteria = data?.Criteria ?? null
    const concepts = [data?.Concept?.[0]].filter((x) => !!x)
    const ownerPermissions = useReactiveVar(
        userConceptPermissionsVar
    ).owningPermission

    //setting up to allow multiple concepts to be added to a radar, temporarily using above code to reduce chance of duplicates
    const radarData = criteria?.map((criteria) => {
        let criteriaObject: RadarDataObject = {
            criteriaName: criteria?.name ?? "",
            values: [],
        }
        criteriaObject["values"] =
            concepts?.map((x) => {
                return {
                    id: x?.id as string,
                    label: x?.title as string,
                    value: Number(
                        x?.criteriaScores?.filter(
                            (x) =>
                                x.criteria?.name === criteria.name &&
                                !!x.response
                        )?.[0]?.response ?? 0
                    ),
                }
            }) ?? []
        return criteriaObject
    })
    //useEffects
    useEffect(() => {
        if (!props.editing && !!editingScores) {
            setEditingScores(false)
        }
    }, [props.editing, editingScores])

    let configurationTools = []

    if (!!ownerPermissions && !!props.editing) {
        configurationTools.push(
            <Tooltip
                title="Edit assigned scores"
                key={"editing-" + props.widget.id}
            >
                <IconButton
                    key={"editing-" + props.widget.id}
                    onClick={() => {
                        setEditingScores((prev) => !prev)
                    }}
                    color={!!editingScores ? "primary" : "default"}
                >
                    <EditIcon />
                </IconButton>
            </Tooltip>
        )
    }
    if (error) {
        return <ErrorHandler message={error.message} showMessage={true} />
    }
    return (
        <Box
            component={!props.isDialog ? Paper : "div"}
            display="flex"
            height="100%"
            flexDirection={"column"}
            width="100%"
            key={props.widget.id}
            overflow={"hidden"}
            onMouseOver={() => {
                if (!props.isDialog && !hovered) {
                    setHovered(true)
                }
            }}
            onMouseLeave={() => {
                if (!props.isDialog && !!hovered) {
                    setHovered(false)
                }
            }}
        >
            <WidgetHeader
                label={config?.label}
                onWidgetDeletion={props.onWidgetDeletion}
                configurationTools={configurationTools}
                widget={props.widget}
                hovered={hovered || !!props.isDialog}
                editing={props.editing}
            />
            <Box display="flex" height="100%" width="100%" overflow={"hidden"}>
                {!!isInitialized && !!data ? (
                    criteria.length > 0 ? (
                        <Box
                            height="100%"
                            width="100%"
                            display="flex"
                            overflow="hidden"
                        >
                            <Fade
                                in={!!editingScores}
                                unmountOnExit
                                mountOnEnter
                            >
                                <Box
                                    flexGrow={1}
                                    overflow="hidden"
                                    display="flex"
                                    flexDirection="column"
                                >
                                    <DefaultScoringCriteriaEditor
                                        criteria={criteria}
                                        concept={concepts?.[0]}
                                        onRefetch={async () => {
                                            await refetch()
                                        }}
                                    />
                                </Box>
                            </Fade>
                            {!editingScores && (
                                <Box
                                    width="100%"
                                    key={JSON.stringify(editingScores)}
                                >
                                    <CriteriaRadar data={radarData} />
                                </Box>
                            )}
                        </Box>
                    ) : (
                        <Box p={2} textAlign="center">
                            No criteria selected...
                        </Box>
                    )
                ) : (
                    <Loading size={25} hideQuote={true} />
                )}
            </Box>
        </Box>
    )
}

export default CriteriaRadarWidget
