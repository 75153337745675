import React from "react"
import {
    IconButton,
    Box,
    Typography,
    Button,
    Popper,
    ClickAwayListener,
    Paper,
    Tooltip,
} from "@material-ui/core"
import { Alert } from "@material-ui/lab"
import useAwaitTranslation from "../../i18n/useAwaitTranslation"
import { useTheme } from "@material-ui/core/styles"
import DeleteSweepIcon from "@material-ui/icons/DeleteSweep"
interface ResponseResetButtonProps {
    onReset: () => void
    loading: boolean
}

const ResponseResetButton: React.FunctionComponent<
    ResponseResetButtonProps
> = ({ onReset, loading }) => {
    const { t } = useAwaitTranslation("feedback")
    const theme = useTheme()
    const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null)

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget)
    }

    const handleClose = () => {
        setAnchorEl(null)
    }

    const handleReset = () => {
        onReset()
        handleClose()
    }

    const open = Boolean(anchorEl)

    return (
        <Box key={"score_options"}>
            <Tooltip title={t("resetFieldResponse", "Reset field response")}>
                <span>
                    <IconButton disabled={loading} onClick={handleClick}>
                        <DeleteSweepIcon />
                    </IconButton>
                </span>
            </Tooltip>
            <Popper
                open={open}
                anchorEl={anchorEl}
                placement="bottom-end"
                style={{
                    zIndex: theme.zIndex.modal + 1,
                }}
                disablePortal
            >
                <ClickAwayListener onClickAway={handleClose}>
                    <Paper
                        elevation={10}
                        style={{
                            width: 300,
                        }}
                    >
                        <Box p={1.5}>
                            <Typography variant="body1">
                                {t(
                                    "areYouSureYouWantToResetThisField",
                                    "Are you sure you want to reset this field?"
                                )}
                            </Typography>
                        </Box>
                        <Alert severity="warning">
                            {t(
                                "youWillNotBeAbleToRecoverIt",
                                "You will not be able to recover it"
                            )}
                        </Alert>
                        <Box display="flex" justifyContent="flex-end" p={0.5}>
                            <Button size="small" onClick={handleClose}>
                                {t("no", "No")}
                            </Button>
                            <Button size="small" onClick={handleReset}>
                                {t("yes", "Yes")}
                            </Button>
                        </Box>
                    </Paper>
                </ClickAwayListener>
            </Popper>
        </Box>
    )
}

export default ResponseResetButton
