import { Box, Typography } from "@material-ui/core"

interface ErrorHandlerProps {
    message?: string
    showMessage?: boolean
}
export default function ErrorHandler(props: ErrorHandlerProps) {
    return !!props.showMessage ? (
        <Box
            p={2}
            height="100%"
            width="100%"
            display="flex"
            justifyContent="center"
            alignItems="flex-start"
        >
            <Typography variant="caption" color="error">
                Sorry, there was an error!
            </Typography>
        </Box>
    ) : null
}
