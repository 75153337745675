import React, { useEffect, useMemo, useState } from "react"
import {
    Box,
    Divider,
    Typography,
    ButtonBase,
    IconButton,
    Collapse,
} from "@material-ui/core"
import ListIcon from "@material-ui/icons/List"
import { Category } from "../../../__generated__/types"
import MenuOpenIcon from "@material-ui/icons/MenuOpen"
import useAwaitTranslation from "../../../i18n/useAwaitTranslation"
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles"

import {
    CollectionInterfaceProps,
    CollectionView,
} from "../../criteria/useCriteriaTypes"
import CollectionBrowser from "./CollectionBrowser"

import ExpandMore from "@material-ui/icons/ExpandMore"
import TableView from "./TableView"
import DraggableCollection from "./DraggableCollection"
import CollectionToolbar from "./CollectionToolbar"
import useCollectionTools from "./useCollectionTools"
import ConceptChip from "../../ConceptChip"
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        collapse: {
            width: "100%",
            overflow: "hidden",
        },
        hovered: {
            "&:hover": {
                backgroundColor: theme.palette.action.hover,
            },
        },
    })
)

const CollectionInterface: React.FunctionComponent<
    CollectionInterfaceProps
> = ({ score, config, inputId, editingConfig }) => {
    const classes = useStyles()
    //hooks
    const { t } = useAwaitTranslation("feedback")
    const { state, onUpdateConceptOrder, onConceptToggle, onConceptCreation } =
        useCollectionTools(score, config)

    //state
    const [browsingAll, setBrowsingAll] = useState(null)
    const [addingInline, setAddingInline] = useState(false)
    const [categoryToAdd, setCategoryToAdd] = useState(null)
    const [collectionIsHovered, setCollectionIsHovered] = useState(false)
    const [showCollectionPreview, setShowCollectionPreview] = useState(true)

    useEffect(() => {
        if (browsingAll === null && state !== null) {
            if (state?.concepts?.length > 0) {
                setBrowsingAll(false)
            } else {
                setBrowsingAll(true)
            }
        }
    }, [browsingAll, state])

    const disabledMessage = useMemo(() => {
        return score?.concepts.length === config.maximum
            ? t(
                  "maxSelectionsAllowed",
                  "You have made the maximum amount of selections allowed"
              )
            : null
    }, [config?.maximum, score?.concepts.length, t])
    const currentSelectedIds = useMemo(() => {
        return state?.concepts?.map((concept) => concept.id) || []
    }, [state?.concepts])

    return (
        <Box height="100%" overflow="hidden">
            {!!browsingAll && (
                <Box
                    height="100%"
                    display="flex"
                    flexDirection={"column"}
                    overflow="hidden"
                >
                    <Box position="relative" width="100%">
                        <Box
                            overflow="hidden"
                            width="100%"
                            display={"flex"}
                            flexDirection="column"
                            alignItems="flex-start"
                            component={ButtonBase}
                            className={classes.hovered}
                            onMouseOver={() => {
                                if (!collectionIsHovered) {
                                    setCollectionIsHovered(true)
                                }
                            }}
                            onMouseLeave={() => {
                                if (!!collectionIsHovered) {
                                    setCollectionIsHovered(false)
                                }
                            }}
                            onClick={() => setBrowsingAll(false)}
                        >
                            <Box
                                p={1}
                                display="flex"
                                justifyContent="flex-start"
                                width="100%"
                            >
                                {!!collectionIsHovered ? (
                                    <MenuOpenIcon
                                        fontSize="small"
                                        color="primary"
                                        style={{
                                            marginRight: 4,
                                            transform: "rotate(180deg)",
                                        }}
                                    />
                                ) : (
                                    <ListIcon
                                        fontSize="small"
                                        color="action"
                                        style={{ marginRight: 4 }}
                                    />
                                )}
                                <Typography
                                    variant="body2"
                                    color={
                                        !!collectionIsHovered
                                            ? "primary"
                                            : "textSecondary"
                                    }
                                >
                                    {t(
                                        "currentCollection",
                                        "Current Collection"
                                    )}
                                    {"  "}({currentSelectedIds?.length})
                                </Typography>
                            </Box>
                            <Collapse
                                in={!!showCollectionPreview}
                                mountOnEnter
                                unmountOnExit
                                classes={{
                                    root: classes.collapse,
                                }}
                            >
                                <Box
                                    display="flex"
                                    justifyContent="flex-start"
                                    width="100%"
                                    style={{
                                        overflowX: "auto",
                                        height: 45,
                                        overflowY: "hidden",
                                    }}
                                >
                                    {state?.concepts?.length === 0 ? (
                                        <Box
                                            display="flex"
                                            alignItems="center"
                                            ml={1.5}
                                        >
                                            <Typography
                                                variant="caption"
                                                color="textSecondary"
                                            >
                                                {t(
                                                    "noneSelected",
                                                    "None Selected"
                                                )}
                                            </Typography>
                                        </Box>
                                    ) : (
                                        state?.concepts?.map((concept) => (
                                            <Box
                                                p={0.5}
                                                key={concept.id}
                                                component="span"
                                            >
                                                <ConceptChip
                                                    item={concept}
                                                    disableClick={true}
                                                />
                                            </Box>
                                        ))
                                    )}
                                </Box>
                            </Collapse>
                        </Box>
                        <span
                            style={{
                                position: "absolute",
                                right: 8,
                                top: 4,
                            }}
                        >
                            <IconButton
                                size="small"
                                onClick={(e) => {
                                    e.stopPropagation()
                                    setShowCollectionPreview((prev) => !prev)
                                }}
                            >
                                <ExpandMore
                                    style={{
                                        transform: !!showCollectionPreview
                                            ? "rotate(180deg)"
                                            : "none",
                                    }}
                                />
                            </IconButton>
                        </span>
                    </Box>

                    <CollectionBrowser
                        disabledMessage={disabledMessage}
                        onConceptToggle={onConceptToggle}
                        onCreation={onConceptCreation}
                        inputId={inputId}
                        config={config}
                        currentSelectedIds={currentSelectedIds}
                    />
                </Box>
            )}

            {!browsingAll && (
                <Box
                    height="100%"
                    display="flex"
                    flexDirection={"column"}
                    overflow="hidden"
                >
                    <CollectionToolbar
                        setAdding={setAddingInline}
                        onSetCategoryToAdd={(item: Category) =>
                            setCategoryToAdd(item.id)
                        }
                        categoryToAdd={
                            categoryToAdd || config?.categories?.[0]?.id
                        }
                        onBrowse={() => setBrowsingAll(true)}
                        config={config}
                    />
                    <Divider />
                    <Box
                        height="100%"
                        overflow="hidden"
                        display="flex"
                        flexDirection="column"
                    >
                        {config.view === CollectionView.table ? (
                            <TableView
                                inputId={inputId}
                                editingConfig={editingConfig}
                                editable={true}
                                config={config}
                                concepts={state?.concepts}
                                onChangeRowOrder={onUpdateConceptOrder}
                                onConceptToggle={onConceptToggle}
                                onConceptCreation={onConceptCreation}
                                addingNewLineItem={addingInline}
                                onCancelNewLineItem={() => {
                                    setAddingInline(false)
                                }}
                                categoryToAdd={
                                    categoryToAdd || config?.categories?.[0]?.id
                                }
                            />
                        ) : (
                            <DraggableCollection
                                inputId={inputId}
                                editable={true}
                                config={config}
                                concepts={state?.concepts}
                                onChangeRowOrder={onUpdateConceptOrder}
                                onConceptToggle={onConceptToggle}
                                onConceptCreation={onConceptCreation}
                                addingNewLineItem={addingInline}
                                onCancelNewLineItem={() => {
                                    setAddingInline(false)
                                }}
                                categoryToAdd={
                                    categoryToAdd || config?.categories?.[0]?.id
                                }
                            />
                        )}
                    </Box>
                </Box>
            )}
        </Box>
    )
}

export default CollectionInterface
