import dayjs from "dayjs"
import {
    AccessType,
    Category,
    Concept,
    CriteriaScore,
    DashboardView,
} from "../../__generated__/types"
import { CriteriaType } from "../criteria/types"
import { WidgetTypeName } from "../dashboard/useWidgetTypes"
import { ScoreSelectorDefaultValues } from "../inputs/types"
import {
    getCleanedFieldConfig,
    getInputId,
    getWorkspaceCompletionData,
} from "../inputs/util"
import CollectionDeserializer from "./deserializers/CollectionDeserializer"
import DateDeserializer from "./deserializers/DateDeserializer"
import MultipleChoiceDeserializer from "./deserializers/MultipleChoiceDeserializer"
import NumberDeserializer from "./deserializers/NumberDeserializer"
import RichTextDeserializer from "./deserializers/RichTextDeserializer"
import { WorkspaceWidgetItem } from "../../providers/GlobalState"
export const TEXT_EXPORTABLE_FIELDS = [
    CriteriaType.Number,
    CriteriaType.Collection,
    CriteriaType.MultipleChoice,
    CriteriaType.TextResponse,
    CriteriaType.Date,
]

export const getSerializer = (
    criteriaType,
    score: CriteriaScore,
    config: any,
    hideHeader?: boolean
) => {
    switch (criteriaType) {
        case CriteriaType.Number:
            return (
                <NumberDeserializer
                    score={score}
                    config={config}
                    hideHeader={hideHeader}
                />
            )
        case CriteriaType.TextResponse:
            return (
                <RichTextDeserializer
                    headerName={!!hideHeader ? null : config.name}
                    editorId={score?.id + "_deserialized"}
                    value={score?.response}
                />
            )
        case CriteriaType.MultipleChoice:
            return (
                <MultipleChoiceDeserializer
                    hideHeader={hideHeader}
                    score={score}
                    config={config}
                />
            )
        case CriteriaType.Collection:
            return <CollectionDeserializer score={score} config={config} />
        case CriteriaType.Date:
            return (
                <DateDeserializer
                    hideHeader={hideHeader}
                    score={score}
                    config={config}
                />
            )
        default:
            return null
    }
}

export const formatCsvData = (
    items: {
        concept: Concept
        dashboard?: DashboardView
    }[],
    categories: Category[],
    languages?: { [key in string]: { name: string } }
) => {
    return items.map((item) => {
        const { concept, dashboard } = item

        let obj = {}

        if (languages) {
            Object.keys(languages).map((x) => {
                const translatedTitle =
                    (x === "en"
                        ? concept.title || ""
                        : concept.translations?.find(
                              (translation) => translation.languageString === x
                          )?.title || ""
                    ).replace(/[^a-zA-Z0-9 -]/g, "") || ""
                obj[`Title (${languages[x].name})`] = translatedTitle || ""
                return obj
            })
        }
        obj = {
            ...obj,
            WorkspaceURL:
                window.location.origin + `/concept/${concept.id}/home`,
            // Only allowing text and numerica numbers
            Author: `${concept.createdBy?.lastName || ""}, ${
                concept.createdBy?.firstName || ""
            }`,
            "Date Created": dayjs(concept.createdAt?.formatted).format(
                "MMM D, YYYY h:mm A"
            ),
            "Has Image": !!concept.imageUrl,
            Category: concept.category?.name,
            Public:
                !!concept.isPublic &&
                concept.isPublicAccessType !== AccessType.NONE,
            "Current Status": concept.status?.[0]?.stage?.title || "",
            "Status Last Edited": !!concept.status?.[0]
                ? dayjs(concept.status?.[0]?.createdAt?.formatted).format(
                      "MMM D, YYYY h:mm A"
                  )
                : null,
            Tags: concept.tags.length,
            Industries: concept.industries.length,
        }
        const widgetsWithFieldData: WorkspaceWidgetItem[] =
            dashboard?.widgets.map((widget) => {
                const input = concept.inputs?.find(
                    (input) =>
                        getInputId(
                            input.criteria?.id,
                            concept.id,
                            getCleanedFieldConfig({ input, scores: [] })
                        ) ===
                        getInputId(
                            JSON.parse(widget.config ?? "{}").criteriaId,
                            concept.id,
                            JSON.parse(widget.config ?? "{}").inputConfig
                        )
                )
                return {
                    id: widget.id,
                    name: widget.name,
                    type: WidgetTypeName.Criteria,
                    config: widget.config,
                    fieldData: {
                        input,
                        scores: input?.criteriaScores.filter(
                            (score) => !!score.conceptDefault
                        ),
                    },
                }
            })

        const { totalFields, orderedFields, percentComplete } =
            getWorkspaceCompletionData(
                widgetsWithFieldData
                    ?.filter((x) => !!x.fieldData)
                    ?.map((o) => o.fieldData),
                {
                    user: null,
                    label: ScoreSelectorDefaultValues.primaryResponse,
                },
                concept.connections,
                null
            )
        const fieldsWithResponses =
            orderedFields.filter((item) => !!item.isCompleted)?.length || 0

        obj["Total Fields"] = totalFields
        obj["Total Fields Complete"] = fieldsWithResponses
        obj["Percent Complete"] = percentComplete
        if (obj["Percent Complete"] > 100) {
            obj["Percent Complete"] = 100
        }
        categories.map((cat) => {
            obj = {
                ...obj,
                [cat.name.trim()]: concept.connections.filter(
                    (o) => o.category?.id === cat.id
                )?.length,
            }
            return obj
        })
        return obj
    })
}
