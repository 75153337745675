import { Box } from "@material-ui/core"
import Button from "@material-ui/core/Button"
import { useHistory, useLocation } from "react-router-dom"
import { useAuth } from "../../../providers/AuthProvider"
import AccountBoxIcon from "@material-ui/icons/AccountBox"
const MicrosoftCompanyLogin = () => {
    const { loginMicrosoft } = useAuth()
    const location = useLocation()
    const queryParams = new URLSearchParams(location.search)
    const continueUrl = queryParams.get("continueUrl")
    const history = useHistory()

    return (
        <Box p={1} width={500} textAlign="center">
            <Box p={1} mt={1}>
                <Button
                    style={{ width: "17.5rem" }}
                    variant="contained"
                    startIcon={<AccountBoxIcon />}
                    onClick={loginMicrosoft}
                >
                    {process.env.REACT_APP_COMPANY_NAME || ""} Employee
                </Button>
            </Box>
            <Box
                p={1}
                mt={3}
                onClick={() =>
                    history.push(
                        continueUrl
                            ? `/login-options?continueUrl=${continueUrl}`
                            : "/login-options"
                    )
                }
            >
                <Button style={{ width: "17.5rem" }} variant="contained">
                    Non {process.env.REACT_APP_COMPANY_NAME || ""} Employee
                </Button>
            </Box>
        </Box>
    )
}

export default MicrosoftCompanyLogin
