import { useEffect, useRef, useState } from "react"
import {
    Box,
    Slider,
    InputAdornment,
    FormControl,
    Input,
} from "@material-ui/core"
import {
    withStyles,
    Theme,
    createStyles,
    makeStyles,
} from "@material-ui/core/styles"
import { NumberType } from "../criteria/useCriteriaTypes"

const NonEditableSlider = withStyles((theme: Theme) =>
    createStyles({
        root: {
            color: theme.palette.primary.light,
            height: 10,
            padding: 0,
        },
        thumb: {
            display: "none !important",
        },
        disabled: {
            "& .MuiSlider-thumb": {
                display: "none",
            },
        },

        track: {
            height: 10,
            borderRadius: 4,
        },
        rail: {
            height: 10,
            borderRadius: 4,
        },
    })
)(Slider)
const EditableSlider = withStyles((theme: Theme) =>
    createStyles({
        root: {
            color: theme.palette.primary.main,
            height: 10,
            padding: 0,
        },
        thumb: {
            height: 15,
            width: 15,
            backgroundColor: theme.palette.secondary.main,
            border: "2px solid currentColor",
            marginLeft: -10,
            marginTop: -7.5 / 3,
            "&:focus, &:hover, &$active": {
                boxShadow: "inherit",
            },
            "& .MuiSlider-thumb.Mui-disabled": {
                display: "none !important",
            },
        },
        disabled: {
            "& .MuiSlider-thumb": {
                display: "none",
            },
        },
        valueLabel: {
            left: "calc(-50% + 4px)",
        },
        track: {
            height: 10,
            borderRadius: 4,
        },
        rail: {
            height: 10,
            borderRadius: 4,
        },
    })
)(Slider)
const useStyles = makeStyles((theme: Theme) => ({
    disabled: {
        color: `${theme.palette.text.primary} !important`,
    },
}))
interface NumericInputSliderProps {
    value: number
    handleChange: (value: number) => void
    config: NumberType
    editing: boolean
    disabled?: boolean
    hideEndAdornment?: boolean
    autoFocus?: boolean
}
const NumericInputSlider = (props: NumericInputSliderProps) => {
    const {
        value,
        handleChange,
        config,
        disabled,
        editing,
        hideEndAdornment,
        autoFocus,
    } = props
    const classes = useStyles()
    const inputRef = useRef(null)

    ///state
    const [inputValue, setInputValue] = useState(null)

    //functions

    const handleBlur = () => {
        if (!!editing) {
            if (
                !!config.minimum &&
                Number(inputValue ?? "0") < config.minimum
            ) {
                handleChange(config.minimum)
            } else if (
                !!config.maximum &&
                Number(inputValue ?? "0") > config.maximum
            ) {
                handleChange(config.maximum)
            } else {
                handleChange(Number(inputValue))
            }
        }
    }

    //useEffects
    useEffect(() => {
        if (inputValue === null) {
            if (!isNaN(value)) {
                setInputValue(JSON.stringify(value))
            } else {
                setInputValue(JSON.stringify(config.minimum ?? "0"))
            }
        }
    }, [value, config.minimum, inputValue])
    const hideSlider = !config.maximum || !config.slider
    useEffect(() => {
        if (!!editing && !!autoFocus) {
            inputRef?.current?.focus()
        }
    }, [editing, autoFocus])
    if (inputValue === null) {
        return null
    }
    return !hideSlider ? (
        <Box display="flex" overflow="hidden">
            <Box
                display="flex"
                alignItems="center"
                flexGrow={1}
                pr={1}
                pl={1.5}
                overflow="hidden"
                minWidth="8rem"
            >
                {!!editing ? (
                    <EditableSlider
                        onChange={(e: any, newValue: number) => {
                            setInputValue(JSON.stringify(newValue))
                        }}
                        disabled={!!disabled}
                        value={Number(inputValue ?? "0")}
                        onChangeCommitted={(event, newValue) => {
                            setInputValue(newValue)
                            handleBlur()
                        }}
                        aria-labelledby="input-slider"
                        min={config.minimum ? +config.minimum : 0}
                        max={config.maximum ? +config.maximum : 5}
                        step={1}
                    />
                ) : (
                    <NonEditableSlider
                        value={Number(inputValue ?? "0")}
                        aria-labelledby="input-slider"
                        min={config.minimum ? +config.minimum : 0}
                        max={config.maximum ? +config.maximum : 5}
                        step={1}
                    />
                )}
            </Box>
            <FormControl
                variant={!!editing ? "outlined" : "standard"}
                size="small"
                style={{ minWidth: "3rem" }}
            >
                <Input
                    value={inputValue}
                    margin="dense"
                    disabled={!!disabled || !editing}
                    onChange={(event) => {
                        if (!isNaN(Number(event.target.value)) && !!editing) {
                            setInputValue(event.target.value)
                        }
                    }}
                    onBlur={handleBlur}
                    disableUnderline={!editing}
                    endAdornment={
                        !!config.suffix &&
                        !hideEndAdornment && (
                            <InputAdornment position="end">
                                {config.suffix}
                            </InputAdornment>
                        )
                    }
                    inputRef={inputRef}
                    startAdornment={
                        !!config.prefix && (
                            <InputAdornment position="start">
                                {config.prefix}
                            </InputAdornment>
                        )
                    }
                    inputProps={{
                        step: 1,
                        min: config.minimum,
                        max: config.maximum,
                        type: "number",
                        "aria-labelledby": "input-slider",
                    }}
                    classes={{
                        disabled: classes.disabled,
                    }}
                />
            </FormControl>
        </Box>
    ) : (
        <FormControl
            variant={!!editing ? "outlined" : "standard"}
            size="small"
            style={{ alignItems: "center" }}
        >
            <Input
                value={inputValue}
                disabled={!!disabled || !editing}
                onChange={(event) => {
                    if (!isNaN(Number(event.target.value)) && !!editing) {
                        setInputValue(event.target.value)
                    }
                }}
                inputProps={{
                    style: {
                        width: `${
                            inputValue?.length > 3 ? inputValue?.length : 3
                        }ch`,
                    },
                }}
                disableUnderline={!editing}
                onBlur={handleBlur}
                inputRef={inputRef}
                endAdornment={
                    !!config.suffix && !hideEndAdornment ? (
                        <InputAdornment position="end">
                            {config.suffix}
                        </InputAdornment>
                    ) : null
                }
                startAdornment={
                    !!config.prefix ? (
                        <InputAdornment position="start">
                            {config.prefix}
                        </InputAdornment>
                    ) : null
                }
                classes={{
                    disabled: classes.disabled,
                }}
            />
        </FormControl>
    )
}

export default NumericInputSlider
