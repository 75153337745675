import { useReactiveVar } from "@apollo/client"
import {
    Dialog,
    Typography,
    Box,
    Divider,
    Button,
    Grow,
    IconButton,
} from "@material-ui/core"
import { useParams } from "react-router-dom"
import useAwaitTranslation from "../../i18n/useAwaitTranslation"
import {
    currentWorkspaceWidgetsVar,
    myCurrentInputResponseLabelVar,
} from "../../providers/GlobalState"
import { Concept } from "../../__generated__/types"
import CategoryLabel from "../CategoryLabel"
import RichTextEditor from "../text-editor/RichTextEditor"
import { useAuth } from "../../providers/AuthProvider"
import { useRef, useState } from "react"
import FileCopyIcon from "@material-ui/icons/FileCopy"
import useMountedState from "../../util/useMountedState"
import CheckCircle from "@material-ui/icons/CheckCircle"
import { getSerializer, TEXT_EXPORTABLE_FIELDS } from "./util"
import TableDeserializer from "./deserializers/TableDeserializer"
import Clear from "@material-ui/icons/Clear"
import { useTheme } from "@material-ui/core/styles"
import useMediaQuery from "@material-ui/core/useMediaQuery"
import Header from "../dialogs/Header"
import { CriteriaType } from "../criteria/types"
import { WidgetTypeName } from "../dashboard/useWidgetTypes"
import ConceptFeedDeserializer from "./deserializers/ConceptFeedDeserializer"
import RichTextDeserializer from "./deserializers/RichTextDeserializer"
import {
    getCleanedFieldConfig,
    getSelectedResponseScores,
} from "../inputs/util"
export default function ConceptToText(props: {
    concept: Concept
    onClose: () => void
}) {
    const isMounted = useMountedState()
    const theme = useTheme()
    const mobile = useMediaQuery(theme.breakpoints.down("xs"))
    const { concept, onClose } = props
    const { currentUser } = useAuth()
    const { conceptId } = useParams()
    const { t, i18n } = useAwaitTranslation("exporting")
    const selectedLangString = i18n?.language
    let translatedContent =
        concept?.translations?.filter(
            (t) => t.languageString === selectedLangString
        )?.[0] || null

    const currentWorkspaceWidgets = useReactiveVar(currentWorkspaceWidgetsVar)
    const currentLabel = useReactiveVar(myCurrentInputResponseLabelVar)
    const textRef = useRef(null)
    const [copied, setCopied] = useState(false)

    const onCopy = () => {
        setCopied(true)
        try {
            const content = textRef.current?.innerHTML
            const blobInput = new Blob([content], { type: "text/html" })
            const clipboardItemInput = new ClipboardItem({
                "text/html": blobInput,
            })
            navigator.clipboard.write([clipboardItemInput])
        } catch (e) {
            console.log(e)
        }
        setTimeout(() => {
            if (!!isMounted()) {
                setCopied(false)
            }
        }, 3000)
    }

    const title = translatedContent?.title || concept.title || ""
    const summary = translatedContent?.summary || concept.summary || ""
    const description = translatedContent?.description || concept.description
    return (
        <Dialog
            maxWidth={"lg"}
            fullScreen={!!mobile}
            fullWidth
            open={true}
            onClose={(_, reason) => {
                if (reason === "backdropClick") {
                    onClose()
                }
            }}
        >
            <Box overflow="hidden" display="flex" flexDirection="column">
                <Header
                    title={title}
                    subtitle={t("textFormat", "Text Format")}
                    actions={
                        <Box display="flex">
                            {!!mobile ? (
                                <IconButton
                                    color={!!copied ? "primary" : "default"}
                                    onClick={onCopy}
                                >
                                    {!!copied ? (
                                        <Grow in={true}>
                                            <CheckCircle />
                                        </Grow>
                                    ) : (
                                        <FileCopyIcon />
                                    )}
                                </IconButton>
                            ) : (
                                <Button
                                    color={!!copied ? "primary" : "default"}
                                    variant={"outlined"}
                                    startIcon={
                                        !!copied ? (
                                            <Grow in={true}>
                                                <CheckCircle />
                                            </Grow>
                                        ) : (
                                            <FileCopyIcon />
                                        )
                                    }
                                    onClick={onCopy}
                                >
                                    {!!copied
                                        ? t(
                                              "copiedToClipboard",
                                              "Copied to Clipboard"
                                          )
                                        : t(
                                              "copyToClipboard",
                                              "Copy to Clipboard"
                                          )}
                                </Button>
                            )}
                            <IconButton
                                style={{ marginLeft: 20 }}
                                onClick={() => onClose()}
                            >
                                <Clear />
                            </IconButton>
                        </Box>
                    }
                />

                <Divider />

                <span ref={textRef} style={{ overflowY: "auto" }}>
                    <Box style={{ overflowX: "hidden" }} p={2}>
                        <CategoryLabel
                            size="medium"
                            conceptCategoryId={concept?.category?.id}
                        />
                        <Typography variant="h5" component="h1">
                            {title}
                        </Typography>
                        <Typography variant="body2" color="textSecondary">
                            Shared by{" "}
                            {`${currentUser.firstName} ${currentUser.lastName}`}
                        </Typography>
                        <Typography
                            style={{ fontWeight: "bold" }}
                            variant="subtitle2"
                            component="h2"
                            gutterBottom
                        >
                            {t("summary", "Summary")}
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                            {summary}
                        </Typography>
                        <Typography
                            style={{ fontWeight: "bold" }}
                            variant="subtitle2"
                            component="h2"
                        >
                            {t("description", "Description")}
                        </Typography>
                        <RichTextEditor
                            editable={false}
                            initialValue={description}
                            editorId={conceptId + "_description_preview"}
                        />
                        {currentWorkspaceWidgets.orderedWidgets.map(
                            (widgetItem) => {
                                if (
                                    TEXT_EXPORTABLE_FIELDS.includes(
                                        widgetItem.fieldData?.input?.criteria
                                            ?.criteriaType as CriteriaType
                                    ) &&
                                    widgetItem.type === WidgetTypeName.Criteria
                                ) {
                                    const { fieldData: field } = widgetItem
                                    const config = getCleanedFieldConfig(field)
                                    const isSubfield =
                                        config.source?.criteriaIds?.length > 0
                                    if (!!isSubfield) {
                                        const parentField =
                                            currentWorkspaceWidgets.orderedWidgets.find(
                                                (x) =>
                                                    config.source?.criteriaIds?.find(
                                                        (o) =>
                                                            o.id ===
                                                            x.fieldData?.input
                                                                ?.criteria?.id
                                                    )
                                            )?.fieldData
                                        const parentConfig =
                                            getCleanedFieldConfig(parentField)
                                        const score = getSelectedResponseScores(
                                            parentField,
                                            currentLabel
                                        )?.[0]
                                        const response = JSON.parse(
                                            score?.response ?? "[]"
                                        )
                                        let orderedConcepts = response
                                            .map((itemId) =>
                                                score?.concepts?.find(
                                                    (concept) =>
                                                        concept.id === itemId
                                                )
                                            )
                                            ?.filter((x) => !!x)
                                        orderedConcepts = [
                                            ...orderedConcepts,
                                            ...(score?.concepts?.filter(
                                                (o) =>
                                                    !response.find(
                                                        (x) => x === o.id
                                                    )
                                            ) ?? []),
                                        ]
                                        return (
                                            <Box key={widgetItem.id}>
                                                <Typography
                                                    style={{
                                                        fontWeight: "bold",
                                                    }}
                                                    gutterBottom
                                                    variant="subtitle2"
                                                    component="h2"
                                                >
                                                    {`${parentConfig.name}
                                                (${score?.concepts.length || 0})
                                                 -
                                                ${config.name}`}
                                                </Typography>

                                                <TableDeserializer
                                                    orderedConcepts={
                                                        orderedConcepts
                                                    }
                                                    columnFields={[
                                                        widgetItem.fieldData,
                                                    ]}
                                                />
                                            </Box>
                                        )
                                    } else {
                                        const score = getSelectedResponseScores(
                                            field,
                                            currentLabel
                                        )?.[0]

                                        const serializer = getSerializer(
                                            field.input.criteria?.criteriaType,
                                            score,
                                            config
                                        )

                                        return (
                                            <Box key={widgetItem.id}>
                                                {serializer}
                                            </Box>
                                        )
                                    }
                                } else {
                                    const widgetConfig = JSON.parse(
                                        widgetItem.config ?? "{}"
                                    )
                                    const widgetValue = JSON.parse(
                                        widgetItem.value ?? "{}"
                                    )
                                    switch (widgetItem.type) {
                                        case WidgetTypeName.ConceptsFeed:
                                            return (
                                                <Box key={widgetItem.id}>
                                                    <ConceptFeedDeserializer
                                                        widgetConfig={
                                                            widgetConfig
                                                        }
                                                    />
                                                </Box>
                                            )
                                        case WidgetTypeName.BlankNotes:
                                            return (
                                                <Box key={widgetItem.id}>
                                                    <RichTextDeserializer
                                                        value={widgetValue.text}
                                                        editorId={
                                                            widgetItem.id +
                                                            "_deserialized"
                                                        }
                                                        headerName={
                                                            widgetConfig?.label
                                                        }
                                                    />
                                                </Box>
                                            )
                                        case WidgetTypeName.Heading:
                                            const elementTag =
                                                widgetConfig.size === "large"
                                                    ? "h3"
                                                    : widgetConfig.size ===
                                                      "medium"
                                                    ? "h4"
                                                    : "h5"
                                            return (
                                                <Box key={widgetItem.id}>
                                                    <Typography
                                                        variant={elementTag}
                                                        gutterBottom
                                                        component={elementTag}
                                                    >
                                                        {widgetConfig?.label}
                                                    </Typography>
                                                </Box>
                                            )
                                        default:
                                            return null
                                    }
                                }
                            }
                        )}
                    </Box>
                </span>
            </Box>
        </Dialog>
    )
}
